import { DataTable } from '@/components/DataTable/DataTable';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, Fragment, useEffect, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import {
  PersonAvatar,
  Tag,
  InputText,
  Tabs,
  FilterList,
} from '@teo/components';
import { Search } from '@teo/components/icons';
import { T } from '@/components/T';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import OptionsAction from '@/components/DataTable/OptionsAction';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { newUid } from '@/util';

function FollowUpUsersPage({ tab }: { tab?: string }) {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('tab');
  const [filterTeams, setFilterTeams] = useState('');
  const [filterUser, setFilterUser] = useState('');

  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [selectedStatusTeams, setSelectedStatusTeams] = useState([
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
  ]);
  const [filterStatusTeams, setFilterStatusTeams] = useState<any>();

  //const [tab, setTab] = useState<string>(tab);
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const { organisationId } = useParams();

  const basePath = window.location.pathname;
  const teamPath = basePath.slice(0, basePath.lastIndexOf('/')) + '/teams';
  const userPath = basePath.slice(0, basePath.lastIndexOf('/')) + '/users';
  const pathManage =
    basePath.slice(0, basePath.lastIndexOf('/f')) + '/manage/' + organisationId;

  const FILTERS_STATUS = [
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
    {
      label: t('pages.follow_up_courses.status_inactive'),
      value: 'disabled,hidden',
    },
  ];

  useEffect(() => {
    if (selectedStatusTeams.length == 0) {
      setFilterStatusTeams(null);
    }
    if (selectedStatusTeams.length == 1) {
      setFilterStatusTeams(`${selectedStatusTeams[0].value}`);
    }
    if (selectedStatusTeams.length > 1) {
      const select = selectedStatusTeams?.map((el) => el.value);
      setFilterStatusTeams(`${select}`);
    }
    setRefetchTable(newUid(20));
  }, [selectedStatusTeams]);

  const userColumns: any = [
    {
      title: t('columns.username'),
      id: 'username',
      className: 'col-start-1 col-end-9 break-all',
      render: (usr: any) => (
        <div className="flex items-center" data-testId="handleClickBtn">
          <PersonAvatar
            inline
            id={usr.id}
            name={usr.username}
            imageSrc={usr.picture}
          ></PersonAvatar>
          <div className="flex flex-col">
            <span className="font-medium">{usr.username}</span>
            <span className="text-xs font-normal text-[#7D7D81]">
              {usr.email}
            </span>
          </div>
        </div>
      ),
    },
    {
      addTitleOnMobile: true,
      className: 'col-start-6 col-end-10 row-start-2 justify-end',
      id: 'created',
      title: t('columns.status'),
      align: 'right',
      render: (usr: any) => (
        <Tag
          title={
            usr.disabled
              ? t('pages.manage_users.disabled')
              : usr.created
              ? t('pages.manage_users.active')
              : t('pages.manage_users.not_active')
          }
          variant={usr.created && !usr.disabled ? 'success' : 'error'}
        />
      ),
    },
    {
      addTitleOnMobile: true,
      className: 'col-start-1 col-end-6 row-start-2',
      id: 'lastActive',
      title: t('columns.lastActive'),
      align: 'right',
      render: (usr: any) => format(new Date(usr.lastActive), 'dd/MM/yyyy'),
    },
  ];

  const teamColumns: any = [
    {
      title: t('columns.teamName'),
      id: 'teamName',
      className: 'col-start-1 col-end-8 row-start-1',
      render: (team: any) => (
        <div
          style={{
            marginLeft: '' + (team?.teamPath?.split(';;')?.length - 1) + 'rem',
          }}
          className="flex items-center"
        >
          <PersonAvatar
            inline
            id={team.id}
            name={team.teamName}
            bgColor={team.color ? team.color : null}
          ></PersonAvatar>
          <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
            {team.teamName}
          </p>
        </div>
      ),
    },
    {
      addTitleOnMobile: true,
      className: 'col-start-1 col-end-8 row-start-2',
      id: 'nrOfTeamMembers',
      title: t('columns.nrOfTeamMembers'),
      align: 'right',
    },
  ];

  if (
    Math.max(authUser?.role || 0, authUser?.activeOrganisationRole || 0) >= 90
  ) {
    teamColumns.push({
      id: 'actionButton',
      sortable: false,
      title: '',
      className: 'col-start-9 row-start-1 justify-end',
      render: (team: any) => (
        <OptionsAction
          menuItems={[
            {
              addIcon: true,
              id: '1',
              title: t('pages.follow_up_users.manage_team'),
              onClick: () => navigate(`${pathManage}/teams/${team.id}`),
            },
            /*
            ,{
              id: '2',
              title: t('pages.follow_up_users.track_team'),
            },
            */
          ]}
          rowInfo={team}
          screensUpdateTable="mobile"
          pageDetail={{
            title: t('pages.follow_up_users.manage_team'),
            rowPath: (team) => `${teamPath}/${team.id}`,
          }}
          mobileHeader={{
            render: (team: any) => (
              <div className="flex items-center">
                <PersonAvatar
                  inline
                  id={team.id}
                  name={team.teamName}
                ></PersonAvatar>
                <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                  {team.teamName}
                </p>
              </div>
            ),
          }}
        />
      ),
    });
    userColumns.push({
      id: 'actionButton',
      sortable: false,
      title: '',
      className: 'col-start-9 row-start-1 justify-end',
      render: (usr: any) => (
        <OptionsAction
          menuItems={[
            {
              addIcon: true,
              id: '1',
              title: t('pages.follow_up_users.manage_user'),
              onClick: () => navigate(`${pathManage}/users/${usr.id}`),
            },
            /*   {
              id: '2',
              title: t('pages.follow_up_users.track_user'),
            },*/
          ]}
          rowInfo={usr}
          pageDetail={{
            title: t('pages.follow_up_users.manage_user'),
            rowPath: (usr) => `${userPath}/${usr.id}`,
          }}
          mobileHeader={{
            render: (usr: any) => (
              <div className="flex items-center">
                <PersonAvatar
                  inline
                  id={usr.id}
                  name={usr.username}
                  imageSrc={usr.picture}
                ></PersonAvatar>
                <div className="flex flex-col">
                  <span className="font-medium">{usr.username}</span>
                  <span className="text-xs font-normal text-[#7D7D81]">
                    {usr.email}
                  </span>
                </div>
              </div>
            ),
          }}
        />
      ),
    });
  } else {
    //teamColumns.push({ sortable: false });
    //userColumns.push({ sortable: false });
  }

  /*
  useEffect(() => {
    setTab(activeTab ? activeTab : 'teams');
  }, [activeTab]);
  */

  return (
    <div className="max-w-6xl pb-8">
      <h1 className="mt-8 mb-4 text-xl font-semibold text-black">
        <T>{t('pages.follow_up_users.follow_up_users_title')}</T>
      </h1>
      <div className="font-medium">
        <Tabs
          activeName={tab}
          onChange={(tab: any) => {
            //setTab(tab);
            navigate(basePath.slice(0, basePath.lastIndexOf('/')) + '/' + tab);
            setFilterTeams('');
            setFilterUser('');
          }}
        >
          <Tabs.TabButton name="teams">
            {t('pages.manage_users.team_tab')}
          </Tabs.TabButton>
          <Tabs.TabButton name="users">
            {t('pages.manage_users.users_tab')}
          </Tabs.TabButton>
        </Tabs>
      </div>
      {tab === 'teams' && (
        <Fragment>
          <div className="py-8 md:w-1/2">
            <InputText
              value={filterTeams}
              onChange={(ev: any) => setFilterTeams(ev.target.value)}
              Icon={Search}
              placeholder={t('search.team')}
            />
          </div>
          <div className="mb-5 flex items-center">
            <FilterList
              filters={FILTERS_STATUS}
              onSelect={setSelectedStatusTeams}
              selected={selectedStatusTeams}
            />
          </div>
          <DataTable
            filter={filterTeams}
            key={refetchTable}
            rowPath={(team) => `${teamPath}/${team.id}`}
            defaultSort={[{ id: 'teamPath', desc: false }]}
            fetch={useGet.bind(
              null,
              (authUser?.role || 0) >= 90 ? '/teams' : '/myteams',
              {
                userId: (authUser?.role || 0) >= 90 ? undefined : authUser?.id,
                organisationId,
                teamStatus: filterStatusTeams,
              }
            )}
            empty={{
              template: 1,
              text: t('empty_tenplate.team_list'),
            }}
            screensUpdateTable="mobile"
            indent={(team: any) => {
              return team?.teamPath?.split(';;')?.length - 1;
            }}
            columns={teamColumns}
          />
        </Fragment>
      )}
      {tab === 'users' && (
        <Fragment>
          <div className="py-8 md:w-1/2">
            <InputText
              value={filterUser}
              onChange={(ev: any) => setFilterUser(ev.target.value)}
              Icon={Search}
              placeholder={t('search.user')}
            />
          </div>
          <DataTable
            filter={filterUser}
            rowPath={(usr) => `${userPath}/${usr.id}`}
            defaultSort={[{ id: 'username', desc: false }]}
            fetch={useGet.bind(
              null,
              (authUser?.role || 0) >= 90 ? '/memberdetails' : '/mymembers',
              {
                userId: (authUser?.role || 0) >= 90 ? undefined : authUser?.id,
                organisationId,
              }
            )}
            empty={{
              template: 1,
              text: t('empty_tenplate.user_list'),
            }}
            columns={userColumns}
          />
        </Fragment>
      )}
    </div>
  );
}

export default FollowUpUsersPage;

import { useState, useContext, useEffect } from 'react';
import { Button, Modal, RadioGroup, Spinner } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';

import { AuthContext } from '@/components/Contexts/AuthContext';

const RedoModal = ({
  closeModal,
  setIsOpenRedoModal,
  usrSelected,
  courseId,
  setResetUserTable,
  newUid,
  isCourse,
  lessonStatus,
  widgets_id,
  handleResetTimeSpent,
  lessonId,
  studentId,
  onSubmit,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const [isChecked, setIsChecked] = useState('complete');
  const [isReset, setIsReset] = useState<any>();
  const [preloader, setPreloader] = useState(false);

  const handleRedo = async () => {
    setPreloader(true);
    const reset = await axios.post(
      `${config.teodoc}/corrections/reset/${courseId}/${
        usrSelected?.studentId || usrSelected?.userId
      }/?keepResults=${isChecked === 'complete' ? true : false}`
    );
    // setResetUserTable(newUid(20));
    // setIsOpenRedoModal(false);
    setIsReset(reset);
  };

  useEffect(() => {
    if (isReset?.data) {
      setResetUserTable(newUid(20));
      setPreloader(false);
      setIsOpenRedoModal(false);
    }
  }, [isReset?.data]);

  const handleRedoLesson = async () => {
    setPreloader(true);
    lessonStatus?.status === 'submitted' &&
      (await axios.delete(
        `${config.teodoc}/results/${widgets_id}?keepResult=${
          isChecked === 'complete' ? true : false
        }`
      ));
    lessonStatus?.status === 'corrected' &&
      (await axios.delete(
        `${config.teodoc}/corrections/${widgets_id}?keepResult=${
          isChecked === 'complete' ? true : false
        }`
      ));
    lessonStatus?.status === 'submitted' && (await handleResetTimeSpent());
    await axios.post(`${config.backend}/follows/progress`, {
      courseId: courseId && parseInt(courseId, 10),
      coursecontentId: lessonStatus?.coursecontentId,
      lessonId: lessonId && parseInt(lessonId, 10),
      status: isChecked === 'complete' ? 'stored' : 'started',
      version: lessonStatus?.version,
      userId: studentId && parseInt(studentId, 10),
    });
    setPreloader(false);
    setIsOpenRedoModal(false);
    onSubmit();
  };

  return (
    <div className="bg-white">
      {preloader && (
        <div className="fixed inset-0 z-[101] flex bg-white/75">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-16 !w-16 border-grey-08"
            />
          </div>
        </div>
      )}
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-xl  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            <h2 className="text-sm font-semibold">
              {t('pages.follow_up_courses.redo_modal.title')}
            </h2>
          </Modal.Header>

          <Modal.Body>
            <div className="flex flex-col gap-3">
              <div>
                <h3 className="mb-3 text-lg">
                  {t('pages.follow_up_courses.redo_modal.heading')}
                </h3>
                <p className="text-sm text-grey-06">
                  {t('pages.follow_up_courses.redo_modal.text')}
                </p>
              </div>
              <RadioGroup
                label={''}
                onChange={(value: string) => {
                  setIsChecked(value);
                }}
                value={isChecked}
              >
                <div className="mb-3 flex w-full flex-col gap-3">
                  <div
                    className={`w-full rounded-lg border border-grey-02 p-3 ${
                      isChecked == 'complete' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="complete">
                      <span className="text-sm">
                        {t('pages.follow_up_courses.redo_modal.checkbox_1')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                  <div
                    className={`w-full rounded-lg border border-grey-02 p-3 ${
                      isChecked == 're-enter' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="re-enter">
                      <span className="text-sm" data-testid="radio_btn">
                        {t('pages.follow_up_courses.redo_modal.checkbox_2')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                </div>
              </RadioGroup>
              <div className="flex justify-between">
                <Button
                  variant="ghost"
                  onClick={() => setIsOpenRedoModal(false)}
                >
                  {t('button.cancel')}
                </Button>
                <Button
                  variant="destruct"
                  onClick={() => (isCourse ? handleRedo() : handleRedoLesson())}
                >
                  {t('button.confirm')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </div>
  );
};

export default RedoModal;

import { useState, useContext } from 'react';
import { Button, InputText, Label, Modal } from '@teo/components';
import { useTranslation } from 'react-i18next';

const IncompleteModal = ({
  setIsIncomplete,
  submit,
  incompleteWidgets,
  setIsLessonLoadedSub,
}) => {
  const { t } = useTranslation();

  const handleWidgets = () => {
    incompleteWidgets?.incompleteWidgets?.map((el) => {
      const node = document.querySelector(`#${CSS.escape(el)}`);
      node.style.cssText += 'border-radius:8px;border:1px solid #fec0ad';
      //   node.className += ' rounded-lg border-error-02 border';
    });
    setIsIncomplete(false);
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-lg  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={() => setIsIncomplete(false)}>
            <h2 className="text-sm font-semibold">
              {t('incomplete_modal.title')}
            </h2>
          </Modal.Header>

          <Modal.Body>
            <div className="flex flex-col gap-5">
              <div>
                <p className="text-base text-grey-06">
                  {t('incomplete_modal.message')}
                </p>
              </div>
              <div className="flex justify-between">
                <Button variant="ghost" onClick={() => handleWidgets()}>
                  {t('button.cancel')}
                </Button>
                <Button
                  variant="destruct"
                  onClick={() => {
                    submit(true);
                    setIsLessonLoadedSub(true);
                  }}
                >
                  {t('pages.course_landing.submit')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </div>
  );
};

export default IncompleteModal;

import { axios } from '@/axios';
import { MainNavLink } from '@/components/MainNavLink';
import { config } from '@/config';
import { Userwithorganisationwithidps } from '@/models';
import {
  CompanyAvatar,
  Dropdown,
  Header,
  MobileHeader,
  PersonAvatar,
  Button,
  IconButton,
} from '@teo/components';
import { NotificationButton } from '@/components/Notifications/NotificationButton';
import {
  ChevronDown,
  ChevronRight,
  ArrowLeft,
  CrossFilled,
} from '@teo/components/icons';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { NavContext } from '@/components/NavContext';
import { useMobile } from '@/util';
import HeaderMobile from '@/components/HeaderMobile/HeaderMobile';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { OrganisationContext } from '@/components/Contexts/OrganisationContext';
import { HintsContext } from '@/components/Contexts/HintsContext';
import NotificationMessage from '@/components/NotificationMessage/NotificationMessage';
import { OrganizationsHeader } from '@/components/OrganizationsHeader';
import { useGet } from '@/query/fetchHooks';
import Hint from '@/components/Hints/Hints';
import HintOverlay from '@/components/Hints/HintOverlay';
import { OnboardingStudent } from '@/components/OnboardingStudent/OnboardingStudent';
import { SearchComponenet } from '@/components/GlobalSearch/SearchComponenet';

const ProtectedPage: FC<PropsWithChildren> = ({ children }) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { organisationId } = useParams();

  const { collapsed, setCollapsed, navDisabled, submenu, setSubmenu } =
    useContext(NavContext);

  const { notification } = useContext(NotificationContext);

  const isMobile = useMobile();
  const showMobileNav = !collapsed && isMobile;
  const { newPage, setNewPage } = useContext(PageUrlContext);
  const { auth, setAuth } = useContext(AuthContext);
  const { hints, setHints } = useContext(HintsContext);
  const { setMyOrganisations } = useContext(OrganisationContext);

  const authUser = auth?.user as Userwithorganisationwithidps;

  const [organisations, setOrganisations] = useState<any>([]);
  const userRole = authUser?.role || 0;
  const userId = authUser?.id;
  const role = Math.max(authUser?.activeOrganisationRole || 0, userRole);

  const [headerTitle, setHeaderTitle] = useState<string>(t('links.account'));
  const [showOnboardingStudent, setShowOnboardingStudent] = useState(
    authUser?.introComplete
  );

  const [hidePrevEl, setHidePrevEl] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);

  useGet(`/memberorganisations`, {
    userId: userId,
    onSuccess: (result: any) => {
      setOrganisations(result?.data || null);
      setMyOrganisations(result?.data || null);
    },
  });

  const handleReplaceUrl = (newId: any) => {
    navigate(`/${i18n.language}`);
    axios.get<any>(`${config.backend}/auth/token`).then((response) => {
      setAuth(response.data);
    });
  };

  const updateActiveOrganisation = (organisation: any) => {
    return axios.put(`${config.backend}/user/${authUser?.id}`, {
      activeOrganisationId: organisation.id,
      id: authUser?.id,
    });
  };

  // useEffect(() => {
  //   if (authUser?.id) {
  //     axios.put(`${config.backend}/user/${authUser?.id}`, {
  //       hintMessage: false,
  //       introComplete: false,
  //     });
  //     setAuth({
  //       user: Object.assign({}, auth?.user, {
  //         hintMessage: false,
  //         introComplete: false,
  //       }),
  //       token: auth?.token,
  //     });
  //   }
  // }, [authUser?.id]);

  useEffect(() => {
    if (authUser?.id) {
      const myHints: any = auth?.user?.hintFlags?.split('');
      if (myHints[0] === '0' && authUser?.hintMessage) {
        if (showOnboardingStudent || userRole > 21) {
          setHints((prev: any) => ({
            ...prev,
            id: 'hint_00',
            active: 1,
            allHints: auth?.user?.hintFlags?.split(''),
          }));
        }
      } else {
        setHints((prev: any) => ({
          ...prev,
          allHints: auth?.user?.hintFlags?.split(''),
        }));
      }
    }
  }, [authUser?.id, showOnboardingStudent, authUser?.hintMessage]);

  const handleNaw = () => {
    if (collapsed) {
      setCollapsed(!collapsed);
    } else {
      if (submenu) {
        setSubmenu(!submenu);
        setHeaderTitle(t('links.account'));
      } else {
        setCollapsed(!collapsed);
      }
    }
  };

  const handleSubNaw = (e: string) => {
    setSubmenu(!submenu);
    setHeaderTitle(e);
  };

  useEffect(() => {
    async function updateActiveOrganisation() {
      if (
        organisationId &&
        parseInt(organisationId, 10) !== authUser?.activeOrganisationId
      ) {
        try {
          await axios.put(`${config.backend}/user/${authUser?.id}`, {
            activeOrganisationId: organisationId,
            id: authUser?.id,
          });
        } catch (error) {
          console.error('Error updating active organisation:', error);
        }
      }
    }

    updateActiveOrganisation();
  }, [organisationId, authUser]);

  useEffect(() => {
    if (hints?.active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [hints]);

  const personAvatar = (
    <PersonAvatar
      id={authUser.id}
      name={authUser.username as string}
      imageSrc={authUser.picture || undefined}
    />
  );

  if (navDisabled)
    return (
      <div className="flex min-h-screen flex-col">
        {children}
        {(showOnboardingStudent || userRole > 21) &&
          (hints?.active === 1 || !authUser?.hintMessage) &&
          !isMobile && <HintOverlay setHints={setHints} hints={hints} />}
      </div>
    );

  return (
    <div className="relative flex min-h-screen flex-col">
      {activeSearch && !isMobile && (
        <div className="absolute inset-0 z-[101] bg-black/25 backdrop-blur-md"></div>
      )}
      {isMobile ? (
        <>
          <MobileHeader
            imgSrc="/images/TEO_logo.svg"
            title={`${!collapsed ? headerTitle : ''}`}
            leftComponent={
              <div onClick={handleNaw}>
                {showMobileNav ? (
                  <ArrowLeft className="h-6 w-8" />
                ) : (
                  personAvatar
                )}
              </div>
            }
            rightComponent={
              <>
                {!activeSearch ? (
                  <NotificationButton />
                ) : (
                  <IconButton
                    className="!p-[10px]"
                    Icon={CrossFilled}
                    variant="secondary"
                    onClick={() => {}}
                  />
                )}
              </>
            }
            searchBar={
              <>
                {authUser.activeOrganisationRole &&
                  authUser?.activeOrganisationRole > 10 && (
                    <SearchComponenet
                      isMobile={isMobile}
                      activeSearch={activeSearch}
                      setActiveSearch={setActiveSearch}
                    />
                  )}
              </>
            }
          />

          {showMobileNav && !submenu && (
            <HeaderMobile
              authUser={authUser}
              organisations={organisations}
              updateActiveOrganisation={updateActiveOrganisation}
              handleSubNaw={handleSubNaw}
              handleReplaceUrl={handleReplaceUrl}
              isMobile={isMobile}
            />
          )}
        </>
      ) : (
        <Header
          imgSrc="/images/TEO_logo.svg"
          navigation={
            <div className="-ml-10 mr-auto flex w-full gap-2 lg:ml-0 lg:mr-0 lg:gap-4">
              <>
                <MainNavLink
                  to={'./learn/' + authUser?.activeOrganisationId}
                  hint={'hint_00'}
                  activeLearn={
                    window.location.pathname
                      .split('/')
                      .filter(Boolean)
                      .pop() === 'learn' ||
                    window.location.pathname.includes('/learn/')
                  }
                >
                  <Hint
                    key={`hint_00_${newPage}`}
                    id={'hint_00'}
                    leftDots={'-left-2 top-2 z-[100]'}
                    title={t('links.learn')}
                    order={0}
                    style={{
                      left: '2px',
                      top: '70px',
                    }}
                  />
                  {t('links.learn')}
                </MainNavLink>
                {role >= 30 && (
                  <MainNavLink
                    to={'./create/' + authUser?.activeOrganisationId}
                    hint={'hint_01'}
                  >
                    <Hint
                      key={`hint_01_${newPage}`}
                      id={'hint_01'}
                      leftDots={'-left-2 top-2 z-[100]'}
                      title={t('links.create')}
                      order={1}
                      style={{
                        left: '2px',
                        top: '70px',
                      }}
                    />
                    {t('links.create')}
                  </MainNavLink>
                )}
                {role >= 40 && role != 45 && (
                  <MainNavLink
                    to={'./follow-up/' + authUser?.activeOrganisationId}
                    hint={'hint_02'}
                  >
                    <Hint
                      key={`hint_02_${newPage}`}
                      id={'hint_02'}
                      leftDots={'-left-2 top-2 z-[100]'}
                      title={t('links.follow_up')}
                      order={2}
                      style={{
                        left: '2px',
                        top: '70px',
                      }}
                    />
                    {t('links.follow_up')}
                  </MainNavLink>
                )}
                {role > 70 && (
                  <MainNavLink
                    to={'./manage/' + authUser?.activeOrganisationId}
                    hint={'hint_03'}
                  >
                    <Hint
                      key={`hint_03_${newPage}`}
                      id={'hint_03'}
                      leftDots={'-left-2 top-2 z-[100]'}
                      title={t('links.manage')}
                      order={3}
                      style={{
                        left: '2px',
                        top: '70px',
                      }}
                    />
                    {t('links.manage')}
                  </MainNavLink>
                )}
              </>
            </div>
          }
          rightComponent={
            <div className="flex items-center gap-2 lg:gap-4">
              <NotificationButton newPage={newPage} hints={hints} />
              <Dropdown>
                <Dropdown.Trigger
                  className={`${
                    hints?.id == 'hint_05' &&
                    'active-hint-parent active-hint-main-menu'
                  }`}
                >
                  <Hint
                    key={`hint_05_${newPage}`}
                    id={'hint_05'}
                    leftDots={'-left-2 top-[9px]'}
                    title={t('hint.title_5')}
                    order={5}
                    style={{
                      right: '0',
                      top: '71px',
                    }}
                  />
                  <div className="p-1">{personAvatar}</div>
                </Dropdown.Trigger>
                <Dropdown.Content>
                  <Dropdown.Section className="flex items-center gap-3">
                    {personAvatar}
                    {authUser?.username}
                  </Dropdown.Section>
                  <Dropdown.Actions>
                    <Dropdown.Link to="profile/details">
                      {t('links.profile')}
                    </Dropdown.Link>
                    <Dropdown.Link
                      to="help"
                      // href={getSupportLink()}
                    >
                      {t('links.help')}
                    </Dropdown.Link>
                    <Dropdown.Button
                      onClick={() => {
                        localStorage.removeItem('token');
                        setAuth(null);
                        navigate('/');
                      }}
                    >
                      {t('links.logout')}
                    </Dropdown.Button>
                  </Dropdown.Actions>
                </Dropdown.Content>
              </Dropdown>

              <Dropdown>
                <Dropdown.Trigger
                  className={clsx(
                    'flex h-14 items-center gap-3 rounded-lg border border-grey-02 px-3 transition-colors',
                    'focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-0 focus-visible:outline-primary-02',
                    'hover:border-grey-04',
                    'active:bg-grey-01',
                    hints?.id == 'hint_06' &&
                      'active-hint-parent active-hint-main-menu'
                  )}
                >
                  <Hint
                    key={`hint_06_${newPage}`}
                    id={'hint_06'}
                    leftDots={'-left-2 top-4'}
                    title={t('hint.title_6')}
                    order={6}
                    style={{
                      right: '0px',
                      top: '77px',
                    }}
                  />
                  {authUser?.organisationName && (
                    <CompanyAvatar
                      imageSrc={authUser?.organisationPicture || ''}
                      name={authUser?.organisationName}
                      objectContain={true}
                    />
                  )}
                  <div className="max-w-[200px] line-clamp-2">
                    {authUser?.organisationName}
                  </div>
                  <ChevronDown className="h-4 w-4 text-grey-07" />
                </Dropdown.Trigger>
                {userRole >= 90 ? (
                  <OrganizationsHeader
                    authUser={authUser}
                    updateActiveOrganisation={updateActiveOrganisation}
                    handleReplaceUrl={handleReplaceUrl}
                  />
                ) : (
                  <Dropdown.Content>
                    <Dropdown.Actions>
                      {organisations?.map((organisation: any) => (
                        <Dropdown.Button
                          className="flex items-center gap-3"
                          key={organisation.id}
                          onClick={async () => {
                            await updateActiveOrganisation(organisation);
                            handleReplaceUrl(organisation.id);
                          }}
                        >
                          <CompanyAvatar
                            imageSrc={organisation.picture || ''}
                            name={organisation.organisationName || ''}
                            objectContain={true}
                          />
                          <span className="line-clamp-2">
                            {organisation.organisationName}
                          </span>
                        </Dropdown.Button>
                      ))}
                      {userRole >= 90 && (
                        <Dropdown.Link
                          to={
                            'organisation/' +
                            authUser?.activeOrganisationId +
                            '/create'
                          }
                          className="mt-2 !h-10 rounded-lg border border-grey-03"
                        >
                          {t('links.organisation_create')}
                        </Dropdown.Link>
                      )}
                    </Dropdown.Actions>
                    {((authUser?.role || 0) >= 95 ||
                      (authUser?.activeOrganisationRole || 0) >= 95) && (
                      <Dropdown.Actions>
                        <Dropdown.Link
                          to={'organisation/' + authUser?.activeOrganisationId}
                        >
                          {t('links.organisation_details')}
                        </Dropdown.Link>
                      </Dropdown.Actions>
                    )}
                  </Dropdown.Content>
                )}
              </Dropdown>
            </div>
          }
          searchBar={
            <>
              {authUser.activeOrganisationRole &&
                authUser?.activeOrganisationRole > 10 && (
                  <SearchComponenet
                    isMobile={isMobile}
                    activeSearch={activeSearch}
                    setActiveSearch={setActiveSearch}
                  />
                )}
            </>
          }
        />
      )}
      {notification && <NotificationMessage />}
      <div className={`${hidePrevEl && !showOnboardingStudent && 'hidden'}`}>
        {children}
      </div>
      {showMobileNav && !submenu && (
        <>
          <div className="mb-10 mt-5 px-5">
            <div onClick={() => handleSubNaw(t('links.learn'))}>
              <MainNavLink to="profile/details">
                {t('links.profile')}{' '}
                <ChevronRight className="ml-auto h-6 w-6" />
              </MainNavLink>
            </div>
            <div onClick={() => handleSubNaw(t('links.learn'))}>
              <MainNavLink to="help">
                {t('links.help')} <ChevronRight className="ml-auto h-6 w-6" />
              </MainNavLink>
            </div>
            {role >= 90 && (
              <div onClick={() => handleSubNaw(t('links.learn'))}>
                <MainNavLink
                  to={'organisation/' + authUser?.activeOrganisationId}
                >
                  {t('links.organisation_details')}{' '}
                  <ChevronRight className="ml-auto h-6 w-6" />
                </MainNavLink>
              </div>
            )}
            {userRole >= 90 && (
              <div onClick={handleNaw}>
                <MainNavLink
                  to={
                    'organisation/' + authUser?.activeOrganisationId + '/create'
                  }
                >
                  {t('links.organisation_create')}
                  <ChevronRight className="ml-auto h-6 w-6" />
                </MainNavLink>
              </div>
            )}
          </div>
          <div className="mx-auto mt-auto mb-10">
            <Button
              variant="outline"
              onClick={() => {
                localStorage.removeItem('token');
                setAuth(null);
                navigate('/');
              }}
            >
              {t('links.logout')}
            </Button>
          </div>
        </>
      )}
      {(showOnboardingStudent || userRole > 21) &&
        (hints?.active === 1 || !authUser?.hintMessage) &&
        !isMobile && <HintOverlay setHints={setHints} hints={hints} />}
      {userId && userRole < 21 && !showOnboardingStudent && (
        <OnboardingStudent
          setShowOnboardingStudent={setShowOnboardingStudent}
          setHidePrevEl={setHidePrevEl}
          authUser={authUser}
        />
      )}
    </div>
  );
};

export default ProtectedPage;

import { useState, useEffect, useRef } from 'react';
import { Spinner, IconButton } from '@teo/components';
import { ArrowRight, CrossFilled } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { newUid } from '@/util';
import { axios } from '@/axios';
import { config } from '@/config';

const SentField = ({
  setIsLoading,
  setArrPrompt,
  isLoading,
  setCloseChat,
  userId,
  setActiveContinue,
  reload,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [partialAnswer, setPartialAnswer] = useState('');
  const [uidChat, setUidChat] = useState();

  const classInput =
    'px-11 h-10 w-full rounded-md border border-solid border-transparent py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02';

  useEffect(() => {
    if (inputRef.current && !reload) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  async function handleSubmit() {
    setIsLoading(true);
    setArrPrompt((prev) => [...prev, '']);
    try {
      const dataOpenAI = await axios.post(`${config.backend}/openai`, {
        messages: [
          {
            role: 'system',
            content: t('chat_gpt.system_messages'),
          },
          { role: 'user', content: prompt },
        ],
        model: 'gpt-4-1106-preview',
        temperature: 0.8,
        max_tokens: 3000,
        user: `${userId}`,
      });
      const fullAnswer = dataOpenAI?.data?.choices[0]?.message?.content || '';
      setActiveContinue(fullAnswer);
      setUidChat(newUid(20));
      setIsLoading(false);

      for (let i = 0; i < fullAnswer.length; i += 10) {
        const part = fullAnswer.substring(i, i + 10);
        setPartialAnswer((prev) => prev + part);
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    } catch (e) {
      alert('Error: ', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (partialAnswer) {
      setArrPrompt((prev) =>
        prev?.map((el, i) =>
          prev.length - 1 == i ? { uid: uidChat, text: partialAnswer } : el
        )
      );
      setActiveContinue({ id: uidChat, text: partialAnswer });
    }
  }, [partialAnswer]);

  const handleKeyPress = (e) => {
    if (!isLoading) {
      if (e.keyCode === 13 && prompt.length > 2) {
        setPartialAnswer('');
        handleSubmit();
        setPrompt('');
      }
    }
  };

  const handleSend = (e) => {
    if (!isLoading) {
      setPartialAnswer('');
      handleSubmit();
      setPrompt('');
    }
  };
  const handleChange = (e) => {
    if (!isLoading) {
      setPrompt(e.target.value);
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="spinner-chat mt-3">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )}
      <div className="relative my-3 w-full">
        <div className="relative flex w-full">
          <div className="relative mr-16 w-full">
            <img
              src="/images/icons/chatgpt.svg"
              alt="chatgpt"
              className="!absolute top-3 left-2 z-[1] w-6"
            />
            <input
              disabled={isLoading}
              id="chat-gpt"
              value={prompt}
              onChange={(e) => handleChange(e)}
              onKeyUp={handleKeyPress}
              placeholder={t('chat_gpt.placeholder')}
              className={classInput}
              ref={inputRef}
            />
            {isLoading ? (
              <div className="absolute top-2 right-3">
                <Spinner
                  ariaLabel="Loading spinner"
                  className="!h-6 !w-6 border-grey-08"
                />
              </div>
            ) : (
              <div
                className="absolute top-2.5 right-2 z-[1] cursor-pointer"
                onClick={() => handleSend()}
              >
                <ArrowRight
                  className={` w-6 pl-2 pr-0 text-grey-05 sm:w-9 sm:w-9 sm:pr-2`}
                />
              </div>
            )}
          </div>

          <IconButton
            className="!absolute top-0.5 right-1"
            Icon={CrossFilled}
            variant="ghost"
            onClick={() => {
              setCloseChat(false);
            }}
          />
        </div>

        <p className="mt-3 text-sm text-grey-05">
          {t('chat_gpt.help_message')}
        </p>
      </div>
    </div>
  );
};
export default SentField;

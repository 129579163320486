import { clsx } from 'clsx';
import { MagnifyImage } from './MagnifyImage';

export const TextWithImage = (props) => {
  return (
    <div className={clsx('flex h-full w-full', props.className)}>
      <div
        className="xsmall justify-stretch inline flex flex-col items-center gap-2"
        style={{
          overflow: 'auto',
          wordWrap: 'break-word',
          flex: 1,
          textAlign: props.reverse ? 'right' : 'left',
          marginTop: 'auto',
          marginBottom: 'auto',
          lineHeight: 'normal',
          minWidth: '40%',
          ...props.textstyle,
        }}
      >
        {props.data.file && (
          <MagnifyImage
            size="sm"
            className={`max-h-[100px] min-w-[30%] object-contain ${props?.imgClass}`}
            src={props.data.file}
            draggable={false}
            onLoad={props.onLoad}
            showIcon={true}
          />
        )}
        {props.data.text && (
          <div className="min-w-[30%] flex-1">{props.data.text}</div>
        )}
      </div>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Multiselect,
  Switch,
  PersonAvatar,
  IconButton,
} from '@teo/components';
import { useState, useEffect, useContext, useReducer } from 'react';
import { axios } from '@/axios';
import { config } from '@/config';
import { useGet } from '@/query/fetchHooks';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { parseFilter } from '@/util';
import { CrossFilled } from '@teo/components/icons';
import LibraryAddOrganizations from '@/pages/Protected/Create/PublishCourse/LibraryAddOrganizations';
import { OrganisationContext } from '@/components/Contexts/OrganisationContext';

function ShareModal({
  isOpenShareSettings,
  closeModal,
  publication,
  setPublication,
  publicationType,
}: any) {
  const initialState = {
    isProtected: publication?.publicationAccess === 'protected',
    users: [],
    allOrganizations: publication?.publicationAccess === 'public',
    organizations: [],
  };

  const [shareState, setShareState] = useReducer(
    (state: any, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  const [initialContentOrganizations, setinitialContentOrganizations] =
    useState<any>([]);
  const [initialUsers, setInitialUsers] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const activeOrganisationId = auth?.user?.activeOrganisationId;
  const [usersData, setUsersData] = useState<any>([]);
  const [filterUsers, setFilterUsers] = useState('');

  const { myOrganisations } = useContext(OrganisationContext);
  let isAdminSomewhereElse = false,
    adminOrganisations = [];
  try {
    adminOrganisations = myOrganisations.filter(
      (org: any) =>
        org.id !== auth?.user?.activeOrganisationId && org.role >= 90
    );
    isAdminSomewhereElse = adminOrganisations.length > 0;
  } catch (e) {
    console.error(e);
  }

  const orgs = useGet(`/coursecontentorganisationdetails`, {
    coursecontentId: publication?.id,
    onSuccess: (result: any) => {
      setinitialContentOrganizations(
        result?.data?.map((item: any) => ({
          ...item,
          key: item.id,
        })) || []
      );
    },
  });

  useEffect(() => {
    if (orgs?.data) {
      const data =
        (orgs?.data?.data as any[]).map((item: any) => ({
          ...item,
          key: item.id,
        })) || [];
      setShareState({ organizations: data });
    }
  }, [orgs?.data]);

  const contentcreators = useGet(`/contentcreators`, {
    coursecontentId: publication?.id,
    count: 1000,
  });

  useEffect(() => {
    if (contentcreators?.data) {
      const data = (contentcreators?.data?.data as any[])?.map((usr) => ({
        key: usr.id,
        label: usr.username,
        username: usr.username,
        userId: usr.id,
      }));
      setShareState({ users: data });
      setInitialUsers(data);
    }
  }, [contentcreators?.data]);

  const creators = useGet(`/invitecontentcreators`, {
    coursecontentId: publication?.id,
    organisationId: activeOrganisationId,
    count: 10,
    minrole: 70,
    sort: 'username',
    q: parseFilter(filterUsers),
  });

  useEffect(() => {
    if (creators?.data) {
      const data = (creators?.data?.data as any[]).map((usr) => ({
        key: usr.id,
        label: usr.username,
        username: usr.username,
        userId: usr.id,
      }));
      setUsersData(data);
    }
  }, [creators?.data]);

  const handleAddUser = () => {
    const newUsers = [...shareState.users];
    for (const obj2 of users) {
      const existingObj = newUsers.find((obj1) => obj1.key === obj2.key);
      if (!existingObj) {
        newUsers.push(obj2);
      }
    }
    setShareState({ users: newUsers });
    setUsers([]);
  };

  const handleRemoveUser = (id: any) => {
    const newUsers = shareState.users.filter((item: any) => item.key !== id);
    setShareState({ users: newUsers });
  };

  const handleSave = () => {
    const added = [];
    const deleted = [];
    const changed = [];
    const addedUsers = [];
    const deletedUsers = [];

    const oldUsers: any = new Map(
      initialUsers.map((item: any) => [item.key, item])
    );
    const newUsers: any = new Map(
      shareState.users.map((item: any) => [item.key, item])
    );

    for (const [id, newItem] of newUsers.entries()) {
      if (!oldUsers.has(id)) {
        addedUsers.push(newItem);
      }
    }

    for (const [id, oldItem] of oldUsers.entries()) {
      if (!newUsers.has(id)) {
        deletedUsers.push(oldItem);
      }
    }

    addedUsers.forEach((user: any) => {
      axios.post(`${config.backend}/ownedcourses`, {
        coursecontentId: publication?.id,
        userId: user.userId,
        role: 70,
      });
    });

    deletedUsers.forEach((user: any) => {
      axios.delete(
        `${config.backend}/ownedcourse/${publication?.id}/${user.userId}`,
        {}
      );
    });

    const oldMap: any = new Map(
      initialContentOrganizations.map((item: any) => [item.key, item])
    );
    const newMap: any = new Map(
      shareState.organizations.map((item: any) => [item.key, item])
    );

    for (const [id, newItem] of newMap.entries()) {
      if (!oldMap.has(id)) {
        added.push(newItem);
      } else {
        if (oldMap.get(id).role !== newItem.role) {
          changed.push(newItem);
        }
      }
    }
    for (const [id, oldItem] of oldMap.entries()) {
      if (!newMap.has(id)) {
        deleted.push(oldItem);
      }
    }

    added.forEach((org: any) => {
      axios.post(`${config.backend}/membercoursecontents`, {
        coursecontentId: publication?.id,
        organisationId: org.id,
        role: org.role,
      });
    });

    deleted.forEach((org: any) => {
      axios.delete(
        `${config.backend}/membercoursecontent/${org.id}/${publication?.id}`,
        {}
      );
    });

    changed.forEach((org: any) => {
      axios.put(
        `${config.backend}/membercoursecontent/${org.id}/${publication?.id}`,
        {
          role: org.role,
        }
      );
    });

    let pubAccess: string;

    if (shareState.isProtected) {
      pubAccess = 'protected';
    }
    if (shareState.allOrganizations) {
      pubAccess = 'public';
    }
    if (!shareState.isProtected && !shareState.allOrganizations) {
      pubAccess = 'private';
    }

    setPublication((prev: any) => ({
      ...prev,
      publicationAccess: pubAccess,
    }));
    axios.put(`${config.backend}/coursecontent/${publication?.id}`, {
      publicationAccess: publication.publicationAccess,
    });

    closeModal();
  };

  return (
    <div className="overflow-hidden bg-white">
      <Modal isOpen={isOpenShareSettings}>
        <Modal.Header onClose={closeModal}>
          <h2 className="text-sm font-semibold">
            {t('pages.create_edit_course.share_modal_title')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <div className="-mx-6 max-h-[70vh] overflow-y-scroll px-6">
            <h1 className="text-base font-semibold">
              {t(
                `pages.create_edit_course.type_${publicationType}.share_title`
              )}
            </h1>
            <p className="mt-2 text-sm font-normal text-[#7d7d81]">
              {t(
                `pages.create_edit_course.type_${publicationType}.share_subtitle`
              )}
            </p>

            <div
            // data-testid="share_course"
            >
              <div className="mt-6 flex flex-col gap-4 rounded-lg border border-grey-02 p-4">
                <div className="flex flex-row items-center gap-4">
                  <Switch
                    checked={shareState.isProtected}
                    onChange={() => {
                      setShareState({ isProtected: !shareState.isProtected });
                    }}
                  />
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t(
                        'pages.create_edit_course.share_details.switch_organizations'
                      )}
                      {auth?.user?.organisationName}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t(
                        `pages.create_edit_course.type_${publicationType}.share_details.switch_organizations_description`
                      )}
                    </p>
                  </div>
                </div>
                {!shareState.isProtected && (
                  <div>
                    <h4 className="mt-1 mb-2">
                      {t(
                        `pages.create_edit_course.type_${publicationType}.share_details.choose_user_title`
                      )}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t(
                        `pages.create_edit_course.type_${publicationType}.share_details.choose_user_description`
                      )}
                    </p>
                    <div>
                      <div>
                        <div className="my-6">
                          <div
                            id="invite_author"
                            className="ali relative z-[11] flex"
                          >
                            <span className="w-full" data-testid="usersSelect">
                              <Multiselect
                                data-testid="userInviteMultiselect"
                                removeButtonLabel="clear"
                                toggleSelectionLabel="toggle"
                                onChange={(param) => {
                                  setFilterUsers('');
                                  setUsers(param);
                                }}
                                onCommitQuery={(email) => {
                                  setFilterUsers('');
                                }}
                                options={usersData}
                                query={filterUsers}
                                setQuery={(query) => setFilterUsers(query)}
                                value={users || []}
                                placeholder={t(
                                  'pages.create_edit_course.multiselect_placeholder'
                                )}
                              />
                            </span>

                            <Button
                              className="ml-3 h-[36px] text-base font-medium"
                              variant="primary"
                              disabled={users.length === 0}
                              onClick={handleAddUser}
                            >
                              {t('button.add_btn')}
                            </Button>
                          </div>
                          <div className="mt-7">
                            {shareState.users?.map((item: any) => (
                              <div
                                key={item.userId}
                                className="mb-4 flex items-center justify-between"
                                data-testid={item?.username}
                              >
                                <div className="flex items-center">
                                  <PersonAvatar
                                    inline
                                    id={item.userId}
                                    name={item.username}
                                    imageSrc={item.picture}
                                  ></PersonAvatar>

                                  <div className="flex flex-col">
                                    <span className="text-sm font-medium text-[#231f20]">
                                      {item.username}
                                    </span>
                                  </div>
                                </div>

                                <IconButton
                                  className="my-auto mx-6"
                                  Icon={CrossFilled}
                                  variant="ghost"
                                  onClick={() => handleRemoveUser(item.key)}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-24 mt-6">
                <LibraryAddOrganizations
                  publication={publication}
                  state={shareState}
                  updateState={setShareState}
                  auth={auth}
                  getOrg={'/coursecontentorganisationdetails'}
                  payload={{ coursecontentId: publication?.id }}
                  publicationType={publicationType}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('button.cancel')}
          onCancel={closeModal}
        >
          <div>
            <Button onClick={() => handleSave()}>{t('button.save')}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShareModal;

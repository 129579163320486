import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FilterDropdown,
  IconButton,
  InputText,
  Tag,
} from '@teo/components';
import { Search, Pencil, ChevronUp, ChevronDown } from '@teo/components/icons';
import { useEffect, useState } from 'react';
import { DataTable } from '@/components/DataTable/DataTable';
import { useGet } from '@/query';
import { useParams } from 'react-router-dom';
import { newUid, parseFilter } from '@/util';
import { axios } from '@/axios';
import { config } from '@/config';
import { sectorsData } from '@/mocks/competencesOptionsData';
import Pagination from '@/components/DataTable/Pagination';
import EmptyTable from '@/components/DataTable/EmptyTable';

import TextWithTooltip from './TextWithTooltip';

const CompetencyProfiles = ({
  categories,
  setIsOpenAdd,
  setIsOpenEdit,
  setActiveProfile,
  setRefetchTable,
  refetchTable,
}: any) => {
  const { t } = useTranslation();
  const { organisationId } = useParams();
  const [count, setCount] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState('');
  const [sector, setSector] = useState<any>();
  const [result, setResult] = useState<any>(null);
  const [professions, setProfessions] = useState<any>(null);
  const [isRowOpen, setIsRowOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [selectedRow, setSelectedRow] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [selectMode, setSelectMode] = useState(true);

  // const [refetchTable, setRefetchTable] = useState<string>(newUid(20));

  const { refetch: refetchProfessions } = useGet('/professions', {
    organisationId: `${organisationId},OR,organisationId=null`,
    categoryId: sector?.id,
    count,
    offset,
    sort: 'name',
    q: parseFilter(filter),
    onSuccess: (result: any) => {
      setResult(result || {});
    },
  });

  useEffect(() => {
    if (result) {
      setProfessions(result?.data || {});
      const contentRange = result?.headers?.['content-range']
        ?.split(' ')?.[1]
        ?.split('/');
      const maxCount = contentRange ? Math.ceil(contentRange?.[1]) : 1;
      setMaxPage(Math.ceil(maxCount / count));
    }
  }, [result]);

  const page = Math.floor(offset / count) + 1;

  useEffect(() => {
    refetchProfessions();
  }, [filter, refetchTable, offset]);

  if (!professions) return null;

  const handleItemSelect = (key: any) => {
    const newSelected = new Set(selectedRow);
    if (
      (newSelected.has(key) && selectMode) ||
      (!newSelected.has(key) && !selectMode)
    ) {
      newSelected.delete(key);
    } else {
      newSelected.add(key);
    }
    setSelectedRow(newSelected);
  };

  const deleteCompetencyProfile = () => {
    [...selectedRow]?.map((professionId: any) => {
      axios
        .delete(`${config.backend}/profession/${professionId}`)
        .then(() => setRefetchTable(newUid(20)));
    });
  };

  const sectorValue = (id: any) => {
    if (id) {
      const sector = sectorsData.find((el) => el.id == id);
      if (sector) {
        return sector?.name;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  // console.log(refetchTable);

  return (
    <>
      <div className="w-[50%] py-8">
        <InputText
          value={filter}
          onChange={(ev) => {
            setOffset(0);
            setFilter(ev.target.value);
          }}
          Icon={Search}
          placeholder={t('pages.manage_competencies.search_placeholder_1')}
        />
      </div>

      <div className="relative z-[1] mb-6 flex flex-col justify-between lg:flex-row lg:items-center">
        <div className="flex flex-row">
          <span className="pr-2">
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                setOffset(0);
                setSector(value);
              }}
              buttonText={`${t('pages.manage_competencies.all_sectors')}`}
              options={sectorsData}
              searchable={true}
              value={sector}
            />
          </span>
        </div>

        <div className="mt-6 flex flex-row lg:mt-0">
          <Button
            onClick={deleteCompetencyProfile}
            size="sm"
            disabled={selectedRow?.size < 1}
            variant="outline"
            className="!text-error-04"
          >
            {t('pages.manage_competencies.delete_button')}
          </Button>
          <Button
            onClick={() => {
              //   navigate(`${basePath}/addCompetencyProfile`);
              setIsOpenAdd(true);
            }}
            size="sm"
            className="ml-2 flex items-center"
            variant="outline"
          >
            {t('pages.manage_competencies.add_competency_profile')}
          </Button>
        </div>
      </div>

      {professions?.length ? (
        <>
          <div key={refetchTable}>
            {professions?.map((profession: any) => (
              <div
                key={refetchTable + '_' + profession.id}
                className="mb-3 rounded-xl bg-grey-01"
              >
                <div className="grid grid-cols-3 items-center py-4 px-6">
                  <div className="flex items-center">
                    <Checkbox
                      id="selectedRow"
                      ariaLabel="test"
                      onChange={() => handleItemSelect(profession.id)}
                      checked={selectedRow.has(profession.id)}
                      key={profession.id + '_' + selectedRow.has(profession.id)}
                    />
                    <span className="overflow-hidden text-ellipsis pl-6 font-medium sm:text-xs md:text-base lg:text-base">
                      {profession.name}
                    </span>
                  </div>

                  <div className="flex justify-center font-medium sm:text-xs md:text-base lg:text-base">
                    {sectorValue(profession?.categoryId)}
                  </div>

                  <div className="flex items-center justify-end">
                    <IconButton
                      Icon={Pencil}
                      variant="ghost"
                      onClick={() => {
                        setActiveProfile(profession);
                        setIsOpenEdit(true);
                      }}
                      className="hidden lg:block"
                    />

                    <IconButton
                      Icon={
                        isRowOpen && rowIndex === profession.id
                          ? ChevronUp
                          : ChevronDown
                      }
                      variant="ghost"
                      onClick={() => {
                        setRowIndex(profession.id);
                        setIsRowOpen(!isRowOpen);
                      }}
                    />
                  </div>
                </div>

                {isRowOpen && rowIndex === profession.id && (
                  <div className="bg-grey-01 px-6 pt-2 pb-3">
                    <div className="bg-white pb-2">
                      <DataTable
                        key={refetchTable}
                        fetch={useGet.bind(null, '/professionQualifications', {
                          professionId: profession.id,
                        })}
                        columns={[
                          {
                            addTitleOnMobile: true,
                            title: t('columns.category'),
                            id: 'categoryId',
                            className:
                              'col-start-1 col-end-6 break-all row-start-1',
                            sortable: false,
                            render: (item: any) => {
                              const cat =
                                item?.categoryId &&
                                categories?.find(
                                  (el: any) => el.id == item?.categoryId
                                );
                              return (
                                <>
                                  {cat ? (
                                    cat?.name
                                  ) : (
                                    <p className="text-xs text-grey-05">
                                      {t(
                                        'pages.manage_competencies.category_no'
                                      )}
                                    </p>
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            addTitleOnMobile: true,
                            title: t('columns.competence'),
                            id: 'competence',
                            className: 'col-start-1 col-end-10 row-start-2',
                            classNameDesktop: 'max-w-[250px]',
                            sortable: false,
                            render: (item: any) => {
                              return item.path.split(';;').length > 2 ? (
                                <TextWithTooltip
                                  text={item.path.split(';;')[1]}
                                />
                              ) : (
                                <TextWithTooltip
                                  text={item.path.split(';;')[0]}
                                />
                              );
                            },
                          },
                          {
                            addTitleOnMobile: true,
                            title: t('columns.sub_competence'),
                            id: 'subCompetence',
                            className: 'col-start-1 col-end-10 row-start-3',
                            classNameDesktop: 'max-w-[250px]',
                            sortable: false,
                            render: (item: any) => {
                              let replacedString = item.path.replace(
                                item.path.split(';;').length > 2
                                  ? /^[^;]*;;([^;]+;;)/
                                  : /^[^;]*;;/,
                                ''
                              );
                              replacedString = replacedString.replace(
                                /;;+$/,
                                ''
                              );
                              replacedString = replacedString.replace(
                                /;;+/g,
                                ' / '
                              );
                              return <TextWithTooltip text={replacedString} />;
                            },
                          },
                          {
                            addTitleOnMobile: true,
                            id: 'type',
                            title: t('columns.type'),
                            sortable: false,
                            className:
                              'col-start-6 col-end-10 row-start-1 justify-end text-right',
                            classNameDesktop: 'min-w-[90px]',
                            render: (item: any) => {
                              let typeLabel;
                              let typeVariant = true;
                              switch (item?.type) {
                                case 'skill':
                                  typeLabel = t(
                                    'pages.manage_competencies.type_skill'
                                  );
                                  break;
                                case 'attitude':
                                  typeLabel = t(
                                    'pages.manage_competencies.type_attitude'
                                  );
                                  break;
                                case 'knowledge':
                                  typeLabel = t(
                                    'pages.manage_competencies.type_knowledge'
                                  );
                                  break;
                                default:
                                  typeLabel = t(
                                    'pages.manage_competencies.type_no'
                                  );
                                  typeVariant = false;
                                  break;
                              }

                              return (
                                <Tag
                                  title={typeLabel}
                                  variant={typeVariant ? 'success' : 'error'}
                                />
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {maxPage > 1 && (
            <Pagination
              setOffset={setOffset}
              offset={offset}
              count={count}
              page={page}
              maxPage={maxPage}
            />
          )}
        </>
      ) : (
        <EmptyTable
          template={2}
          text={t('empty_tenplate.competencies_list')}
          btn_text={t('empty_tenplate.btn_add_competencies')}
          btnClick={() => setIsOpenAdd(true)}
          filter={filter}
        />
      )}
    </>
  );
};

export default CompetencyProfiles;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@teo/components';

function UploadedPhotoContainer({
  photoInfo,
  fileInputRef,
  handleUploadPhoto,
  uploadImg,
}: any) {
  const { t } = useTranslation();

  if (!photoInfo && !uploadImg) {
    return null;
  }

  return (
    <div
      className="
        flex
        h-44
        flex-col
        items-center
        justify-center
        rounded-lg
        bg-grey-02
        px-3 py-8
    "
    >
      {/* <img
        alt="image"
        src={uploadImg ? uploadImg : URL.createObjectURL(photoInfo)}
        className="mr-3 h-full w-full flex-[1_0_64px] rounded object-cover object-center"
      /> */}
      <Spinner
        ariaLabel="Loading spinner"
        className="h-8 w-8 border-secondary-04"
      />
    </div>
  );
}

export default UploadedPhotoContainer;

import { useGet } from '@/query/fetchHooks';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { PersonAvatar, PageHeader, Tabs } from '@teo/components';
import { parseRole } from '@/util.js';
import { useTranslation } from 'react-i18next';
import AccountPage from './Account';
import EducationAndWorkPage from './EducationAndWorkPage';
import Certificates from './Certificates';
import { axios } from '@/axios';
import { config } from '@/config';

function UserDetails() {
  const { organisationId, userId } = useParams();
  const [student, setStudent] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>();
  const [organisation, setOrganisation] = useState<any>(null);
  const [tab, setTab] = useState('account');
  const { t } = useTranslation();

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  useGet('/memberdetails', {
    organisationId,
    userId,
    onSuccess: (result: any) => {
      setStudent(result?.data?.[0] || {});
    },
  });

  useGet(`/userinfo/${userId}`, {
    onSuccess: (result: any) => {
      setUserInfo(result?.data || null);
    },
  });

  useGet(`/userinfo/${userId}`, {
    onSuccess: (result: any) => {
      setUserInfo(result?.data || null);
    },
  });

  useEffect(() => {
    if (organisationId) {
      axios
        .get<any>(`${config.backend}/organisation/${organisationId}`)
        .then((response) => {
          setOrganisation(response?.data || {});
        });
    } else {
      setOrganisation({});
    }
  }, [organisationId]);

  if (!organisation) return null;

  if (!student) return null;

  return (
    <div className="max-w-6xl flex-col pb-8">
      <div className="py-6">
        <PageHeader /* @ts-expect-error eslint-disable-next-line */
          to={-1}
          ariaLabel={'placeholder'}
        >
          <div className="flex items-center">
            <PersonAvatar
              inline
              id={student.id}
              name={student.username}
              imageSrc={student.picture}
            ></PersonAvatar>
            <div className="mx-4 flex gap-4">
              <div>
                <h1 className="break-all text-lg font-semibold text-black sm:text-xl">
                  {student.username}
                </h1>
                <div className="flex flex-col sm:flex-row sm:gap-3">
                  <p className="text-xs text-grey-05">
                    {t(parseRole(student.role))}
                  </p>
                  <span className="hidden text-xs text-grey-03 sm:inline-block">
                    |
                  </span>
                  {student?.email && (
                    <p className="text-xs text-tint-blue">{student?.email}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageHeader>
      </div>
      <div className="w-full rounded-lg border border-grey-02 p-5">
        <h3 className="mb-5 text-xl">
          {t('pages.manage_users.user_detail_page.title')}
        </h3>
        <div className="flex flex-col lg:flex-row">
          <div>
            <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
              <div className="flex flex-col gap-3 sm:flex-row">
                <Tabs.TabButton name="account">
                  <p className="text-base font-medium">{t('links.account')}</p>
                </Tabs.TabButton>
                {(organisation?.educationPreferences ||
                  organisation?.workPreferences) && (
                  <Tabs.TabButton name="education">
                    <p className="text-base font-medium">
                      {t('links.education_work')}
                    </p>
                  </Tabs.TabButton>
                )}
                <Tabs.TabButton name="certificates">
                  <p className="text-base font-medium">
                    {t('links.certificates')}
                  </p>
                </Tabs.TabButton>
              </div>
            </Tabs>
          </div>
        </div>
        <div className="mt-5">
          {tab === 'account' && (
            <AccountPage
              userData={student}
              setUserData={setStudent}
              userInfo={userInfo}
            />
          )}
          {tab === 'education' && (
            <EducationAndWorkPage
              userInfo={userInfo}
              organisationSettings={organisation}
            />
          )}
          {tab === 'certificates' && <Certificates />}
        </div>
      </div>
    </div>
  );
}

export default UserDetails;

import { clsx } from 'clsx';
import { FC } from 'react';

type srting = 'facebook' | 'google' | 'linkedin';

interface SocialLoginButtonProps {
  loginMethodName: string;
  img?: string | undefined;
  onClick: () => void;
}

const LOGIN_METHOD_IMAGE_URL: Record<string, string> = {
  facebook: '/images/components/auth/social-login-button/facebook.svg',
  google: '/images/components/auth/social-login-button/google.svg',
  linkedin: '/images/components/auth/social-login-button/linkedin.svg',
};

const SocialLoginButton: FC<SocialLoginButtonProps> = ({
  loginMethodName,
  img,
  onClick,
}) => {
  const loginMethodImageUrl = img || LOGIN_METHOD_IMAGE_URL[loginMethodName];

  return (
    <button
      className={clsx(
        'flex h-14 w-full items-center justify-center',
        'rounded-lg border-2 border-grey-02 bg-white transition-colors',
        'focus-visible:outline focus-visible:outline-4 focus-visible:outline-primary-02',
        'hover:bg-grey-01 active:border-grey-03'
      )}
      onClick={onClick}
      type="button"
    >
      <img className="h-8 w-auto" src={loginMethodImageUrl} />
    </button>
  );
};

export default SocialLoginButton;

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PlayFilled,
  TimeFilled,
  Eye,
  DocumentsFilled,
} from '@teo/components/icons';
import { convertToTime } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { useGet } from '@/query/fetchHooks';
import LibraryOverview from '@/pages/Protected/Create/Library/LibraryOverview';
import DuplicationOverview from './DuplicationOverview';
import { axios } from '@/axios';
import { config } from '@/config';

export const DuplicateCourse = ({
  refresh,
  loadLessons,
  setLessons,
  setLoadLessons,
  showLast,
  filter,
  selectLes,
  selectChapter,
  category,
  setCategory,
  setMoreFilter,
  tab,
  allCategories,
  authUser,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  return (
    <>
      {tab === 'all' && (
        <DuplicationOverview
          setCategory={setCategory}
          category={category}
          allCategories={allCategories}
          authUser={authUser}
          setMoreFilter={setMoreFilter}
          filter={filter}
          refresh={refresh}
          setLoadLessons={setLoadLessons}
          loadLessons={loadLessons}
          showLast={showLast}
          selectLes={selectLes}
          selectChapter={selectChapter}
        />
      )}
      {tab === 'lessons' && (
        <PaginationAnything
          key={refresh}
          className={`mt-3 -ml-2 -mr-2 flex min-h-[660px] min-w-full flex-wrap content-baseline ${
            loadLessons === true && ' hidden '
          }`}
          fetch={useGet.bind(null, `/lessonimports`, {
            organisationId: `${auth?.user?.activeOrganisationId},OR,organisationId=null`,
            qtag:
              category &&
              `%25category:${encodeURIComponent(category?.name)}%25`,
            onSuccess: (result: any) => {
              setLessons(result?.data || {});
              setLoadLessons(false);
            },
          })}
          defaultSort={
            showLast ? [{ id: '-createdAt' }] : [{ id: 'createdAt' }]
          }
          empty={{
            template: 2,
            loadContent: loadLessons,
            text: t('pages.create_library.library_empty'),
          }}
          page={0}
          pageSize={12}
          filter={filter}
          render={(lesson: any) => (
            <div
              key={lesson?.id}
              className="max-h-[220px] min-h-[220px] basis-1/2 overflow-hidden px-2 md:basis-1/3 lg:basis-1/4"
            >
              <div
                className="group cursor-pointer pb-4"
                onClick={async () => {
                  await axios.post(`${config.backend}/libraryactivity`, {
                    lessonId: lesson?.lessonId,
                    organisationId: authUser?.activeOrganisationId,
                    coursecontentId: lesson?.coursecontentId,
                    userId: authUser.id,
                    authorId: lesson?.authorId,
                  });
                  selectLes(lesson);
                }}
              >
                <div className="flex flex-col">
                  <div className="relative mb-2 overflow-hidden rounded-lg">
                    <img
                      src={
                        lesson?.publicationImage
                          ? lesson?.publicationImage
                          : '/assets/images/opleidingen.jpg'
                      }
                      alt={lesson?.name}
                      className="h-28 w-full rounded-md object-cover"
                    />
                    {(lesson?.organisationId === 18 ||
                      lesson?.organisationId == null) && (
                      <img
                        src="/images/TEO_logo.svg"
                        className="absolute bottom-1 right-1 h-8 w-8"
                      />
                    )}
                    <div className="absolute inset-0 h-full w-full rounded-lg group-hover:backdrop-blur-sm"></div>
                    <span className="absolute inset-0 hidden items-center justify-center group-hover:flex">
                      <span className="rounded-md bg-grey-01 p-1 text-grey-09">
                        <Eye className="h-5 w-5 "></Eye>
                      </span>
                    </span>
                  </div>

                  <h3 className="text-base-semibold mb-2 break-words">
                    {lesson?.name || lesson?.assetName}
                  </h3>
                  <div className="flex flex-row items-center gap-3">
                    <span className="text-sm capitalize text-grey-04">
                      {lesson?.lessonType || lesson?.assetType}
                    </span>
                    {lesson?.estimatedTime > 0 && (
                      <span className="flex flex-row items-center gap-1 text-sm text-grey-04">
                        <TimeFilled className="inline h-5 w-5" />
                        <p className="pl-1">
                          {convertToTime(
                            Math.abs(lesson?.estimatedTime),
                            t('pages.learn_index.hour'),
                            t('pages.learn_index.minute')
                          )}
                        </p>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      )}

      {tab == 'chapters' && (
        <PaginationAnything
          key={refresh}
          className={`mt-3 -ml-2 -mr-2 flex min-h-[660px] min-w-full flex-wrap content-baseline ${
            loadLessons === true && ' hidden '
          }`}
          fetch={useGet.bind(null, `/directoryimports`, {
            organisationId: `${auth?.user?.activeOrganisationId},OR,organisationId=null`,
            qtag:
              category &&
              `%25category:${encodeURIComponent(category?.name)}%25`,
            onSuccess: (result: any) => {
              setLessons(result?.data || {});
              setLoadLessons(false);
            },
          })}
          defaultSort={
            showLast ? [{ id: '-createdAt' }] : [{ id: 'createdAt' }]
          }
          empty={{
            template: 2,
            loadContent: loadLessons,
            text: t('pages.create_library.library_empty'),
          }}
          page={0}
          pageSize={12}
          filter={filter}
          render={(folder: any) => (
            <div
              key={folder?.id}
              className="max-h-[220px] min-h-[220px] basis-1/2 overflow-hidden px-2 md:basis-1/3 lg:basis-1/4"
            >
              <div
                className="group cursor-pointer pb-4"
                onClick={async () => {
                  await axios.post(`${config.backend}/libraryactivity`, {
                    directoryId: folder?.directoryId,
                    organisationId: authUser?.activeOrganisationId,
                    coursecontentId: folder?.coursecontentId,
                    userId: authUser.id,
                    authorId: folder?.authorId,
                  });
                  selectChapter(folder);
                }}
              >
                <div className="flex flex-col">
                  <div className="relative mb-2 overflow-hidden rounded-lg">
                    <img
                      src={
                        folder?.publicationImage
                          ? folder?.publicationImage
                          : '/assets/images/opleidingen.jpg'
                      }
                      alt={folder?.name}
                      className="h-28 w-full rounded-md object-cover"
                    />
                    {(folder?.organisationId === 18 ||
                      folder?.organisationId == null) && (
                      <img
                        src="/images/TEO_logo.svg"
                        className="absolute bottom-1 right-1 h-8 w-8"
                      />
                    )}
                    <div className="absolute inset-0 h-full w-full rounded-lg group-hover:backdrop-blur-sm"></div>
                    <span className="absolute inset-0 hidden items-center justify-center group-hover:flex">
                      <span className="rounded-md bg-grey-01 p-1 text-grey-09">
                        <Eye className="h-5 w-5 "></Eye>
                      </span>
                    </span>
                  </div>

                  <p className="mb-2 text-base font-semibold text-black">
                    {folder?.name}
                  </p>
                  <div className="flex text-grey-04">
                    <div className="mr-3 flex">
                      <DocumentsFilled className="w-5" />

                      <span>
                        {folder?.nrOfDirectoryLessons ? (
                          <>
                            {' '}
                            {folder?.nrOfDirectoryLessons}{' '}
                            {t('pages.create_library.lessons')}
                          </>
                        ) : (
                          <>0 {t('pages.create_library.lessons')}</>
                        )}
                      </span>
                    </div>

                    {folder?.directoryEstimatedTime > 0 && (
                      <div className="flex">
                        <TimeFilled className="w-5" />
                        <span>
                          {convertToTime(
                            Math.abs(folder?.directoryEstimatedTime),
                            t('pages.learn_index.hour'),
                            t('pages.learn_index.minute')
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      )}
    </>
  );
};

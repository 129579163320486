import { useEffect, useRef, useState, useCallback } from 'react';

export function parseQueryString(str) {
  str = str.split('#')[0];
  if (str[0] === '?') str = str.slice(1);
  let pairs = str.split('&');
  let result = {};
  for (let pair of pairs) {
    if (pair === '') continue;
    let keyValue = pair.split('=');
    result[keyValue[0]] = keyValue[1];
  }
  return result;
}

export function deep_copy(o) {
  return JSON.parse(JSON.stringify(o));
}

export function isEmpty(obj) {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
}

export function isObject(x) {
  return typeof x === 'object' && !Array.isArray(x) && x !== null;
}

export const cleverClone = (o) => {
  if (!o) return o;
  return JSON.parse(JSON.stringify(o, cloneReplacer), cloneReviver(o));
};

const cloneReplacer = (_key, value) => {
  if (value instanceof Object && value.serverLength !== undefined) {
    const { serverLength } = value;
    return { ...value, serverLength };
  }
  return value;
};

const cloneReviver = () => (key, value) => {
  if (
    value !== null &&
    typeof value === 'object' &&
    Object.prototype.hasOwnProperty.call(value, 'serverLength')
  ) {
    const { serverLength } = value;
    delete value.serverLength;
    Object.defineProperty(value, 'serverLength', {
      enumerable: false,
      value: serverLength,
      writable: true,
    });
    return value;
  }
  return value;
};

export class Deferred {
  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.then = this._promise.then.bind(this._promise);
    this.catch = this._promise.catch.bind(this._promise);
  }
  _resolve = () => {
    let temp = new Deferred();
    temp.resolve();
    return temp;
  };
}

export function isString(str) {
  return typeof str === 'string' || str instanceof String;
}

export function parseRole(role) {
  switch (role) {
    case 10:
      return 'role.CANDIDATE';
    case 20:
      return 'role.STUDENT';
    case 40:
      return 'role.TEAMLEAD';
    case 45:
      return 'role.CONTENT_CREATOR';
    case 60:
      return 'role.MENTOR';
    case 70:
      return 'role.TEACHER';
    case 75:
      return 'role.RECRUITER';
    case 90:
      return 'role.ADMIN';
    case 95:
      return 'role.SUPER_ADMIN';
    default:
      return 'role.UNKOWN';
  }
}

export function mapRole(role) {
  if (role >= 95) return 95;
  else if (role >= 90) return 90;
  else if (role >= 75) return 75;
  else if (role >= 70) return 70;
  else if (role >= 40) return 40;
  else if (role >= 30) return 30;
  else if (role >= 20) return 20;
  else if (role >= 10) return 10;
}

import { useMediaQuery } from 'react-responsive';

export const useMobile = () => {
  return useMediaQuery({ query: '(max-width: 767px)' });
};

export function newUid(n) {
  const valid_chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var text = valid_chars.charAt(
    Math.floor(Math.random() * (valid_chars.length - 10) + 10)
  );
  for (var i = 1; i < n; i++) {
    text += valid_chars.charAt(Math.floor(Math.random() * valid_chars.length));
  }
  return text;
}

/** builds a pretty tree based lessons, directories
 *
 * returns [prettyTree, lessonLst, directoryLst]
 *
 * prettyTree: Recursive structure of course
 * lessonLst: All lessons in DFS order
 * directoryLst: All directories in DFS order
 *
 */
export function buildTree(lessons, directories) {
  directories = directories.reduce((obj, el) => {
    obj[el.path.slice(el.path.lastIndexOf('/') + 1)] = el;
    return obj;
  }, {});

  let tree = {};
  for (let lesson of lessons) {
    let paths = lesson.path.split('/');
    paths = paths.filter((x) => x);
    let currentPath = tree;
    for (let path of paths) {
      if (!currentPath[path]) currentPath[path] = {};
      currentPath = currentPath[path];
    }
    Object.assign(currentPath, lesson);
  }

  let prettyTree = Object.assign({}, { children: [] });

  let lessonLst = [];
  let directoryLst = [];

  let parse = (node, path, prettyTree) => {
    let lst = Object.entries(node);
    lst = lst.sort((a, b) => {
      a =
        typeof a[1].yindex !== 'undefined'
          ? a[1].yindex
          : directories[a[0]]?.yindex;
      b =
        typeof b[1].yindex !== 'undefined'
          ? b[1].yindex
          : directories[b[0]]?.yindex;
      return a - b;
    });
    for (let node of lst) {
      if (node[1].lessonId) {
        let lesson = node[1];
        let newPath = path + lesson.name;
        let newNode = Object.assign({ pathStr: newPath }, lesson);
        prettyTree.push(newNode);
        lessonLst.push(newNode);
      } else {
        let directory = directories[node[0]];
        let newPath = path + directory.name;
        let newNode = Object.assign({ pathStr: newPath }, directory);
        newNode.children = [];
        prettyTree.push(newNode);
        directoryLst.push(newNode);
        parse(node[1], newPath + '/', newNode.children);
      }
    }
  };

  // console.log(prettyTree, lessonLst, directoryLst);

  parse(tree, '/', prettyTree.children);
  return [prettyTree, lessonLst, directoryLst];
}

/** builds a pretty tree structured for react-sortable-tree
 *
 *     treeData: [
 *       { title: 'Chicken', children: [{ title: 'Egg' }] },
 *       { title: 'Fish', children: [{ title: 'fingerline' }] },
 *     ],
 *
 * returns [prettyTree, lessonLst, directoryLst]
 *
 * prettyTree: Recursive structure of course
 * lessonLst: All lessons in DFS order
 * directoryLst: All directories in DFS order
 *
 */
export function buildSortedTree(lessons, directories) {
  let nodes = [...lessons, ...directories];
  let tree = {};
  for (let node of nodes) {
    let paths = node.path.split('/');
    paths = paths.filter((x) => x);
    let currentPath = tree;
    for (let path of paths) {
      if (!currentPath[path]) currentPath[path] = {};
      currentPath = currentPath[path];
    }
  }

  let indexedNodes = nodes.reduce((obj, node) => {
    obj[node.path] = node;
    return obj;
  }, {});

  let prettyTree = { children: [] };
  let parse = (node, path, prettyTree) => {
    let lst = Object.keys(node);
    let nodes = lst.map((x) => {
      if (indexedNodes[path + x] === undefined) {
        indexedNodes[path + x] = {
          name: 'broken, pls remove',
          path: path + x,
          id: newUid(20),
          yindex: 0,
        };
      }
      return indexedNodes[path + x];
    });

    prettyTree.push(...nodes);
    for (let i in nodes) {
      if (!nodes[i]) nodes[i] = { id: newUid(20) };
      nodes[i].children = [];
      parse(node[lst[i]], nodes[i].path + '/', nodes[i].children);
    }
    prettyTree.sort((a, b) => a.yindex - b.yindex);

    let order = 1;
    prettyTree.forEach((node) => {
      if (node.numbered) {
        node.order = order++; // Присвоюємо порядковий номер та збільшуємо лічильник
      }
    });
  };
  parse(tree, '/', prettyTree.children);
  return prettyTree;
}

export function walk(root, fn, i = 0, parent = {}) {
  if (!root) return;
  fn(root, i, parent);
  if (root.children) {
    let j = 0;
    for (let child of root.children) {
      walk(child, fn, j, root);
      j++;
    }
  }
}

export function firstOfDfs(root, fn, i = 0, parent = {}) {
  if (!root) return;
  if (fn(root, i, parent)) return root;
  if (root.children) {
    let j = 0;
    for (let child of root.children) {
      let result = firstOfDfs(child, fn, j, root);
      if (result) return result;
      j++;
    }
  }
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function uniqueBy(arr, fn) {
  let unique = {};
  let distinct = [];
  arr.forEach(function (x) {
    let key;
    if (fn) key = fn(x);
    else key = x;
    if (!unique[key]) {
      distinct.push(x);
      unique[key] = true;
    }
  });
  return distinct;
}

export function unique(arr) {
  return uniqueBy(arr, (x) => x);
}

/** builds a pretty tree structured for react-dnd-treeview
 *
    [
      {
        "id": 1,
        "parent": 0,
        "droppable": true,
        "text": "Folder 1"
      },
      {
        "id": 2,
        "parent": 1,
        "text": "File 1-1"
      },
      {
        "id": 3,
        "parent": 1,
        "text": "File 1-2"
      },
      {
        "id": 4,
        "parent": 0,
        "droppable": true,
        "text": "Folder 2"
      },
      {
        "id": 5,
        "parent": 4,
        "droppable": true,
        "text": "Folder 2-1"
      },
      {
        "id": 6,
        "parent": 5,
        "text": "File 2-1-1"
      }
    ]
 *
 * returns [prettyTree, lessonLst, directoryLst]
 *
 * prettyTree: Recursive structure of course
 * lessonLst: All lessons in DFS order
 * directoryLst: All directories in DFS order
 *
 */
export function buildTreeFlat(lessons, directories) {
  const prettyTree = buildSortedTree(lessons, directories);

  let flatTree = [];
  prettyTree.id = 0;
  prettyTree.path = '/';

  walk(prettyTree, (node, i, parent) => {
    flatTree.push({
      id: node.lessonId || node?.assetId ? node.usesId : node.id,
      parent: parent.id,
      droppable: node.lessonId || node?.assetId ? false : true,
      text: node?.name || node?.assetName || 'Broken node',
      isOpen: true,
      data: node,
    });
  });

  return [flatTree.slice(1)];
}

export function loadRawScript(src) {
  return new Promise((resolve, reject) => {
    var tag = window.document.createElement('script');
    tag.type = 'text/javascript';
    tag.text = src;
    /*
    tag.onload = function () {
      resolve();
    };
    */
    window.document.getElementsByTagName('body')[0].appendChild(tag);
    resolve();
  });
}

export function loadStyle(src) {
  var tag = window.document.createElement('link');
  tag.rel = 'stylesheet';
  tag.type = 'text/css';
  tag.href = src;
  window.document.getElementsByTagName('body')[0].appendChild(tag);
}

const s3Alphabet =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ/!-_.*'()";
//see https://github.com/GeorgePhillips/node-s3-url-encode/blob/master/index.js
export function encodeS3URI(filename) {
  let safeName = '';
  for (let i = 0; i < filename.length; i++) {
    if (s3Alphabet.includes(filename[i])) safeName += filename[i];
  }
  return safeName;
}

export function encodeRandomS3URI(filename) {
  let splitted = encodeS3URI(filename).split('.');
  if (splitted.length > 1) splitted[splitted.length - 2] += '-' + newUid(20);
  return splitted.join('.');
}

export function getSafe(fn, defaultValue) {
  try {
    let result = fn();
    return result === null || result === undefined ? defaultValue : result;
  } catch (e) {
    return defaultValue;
  }
}

export function last(arr) {
  return arr[arr.length - 1];
}

export const round = (n, d) => Number(Math.round(n + 'e' + d) + 'e-' + d);
export const round2 = (n) => round(n, 2);

export function shuffle(arr) {
  let random_array = arr.map((x) => [x, Math.random()]);
  random_array.sort((a, b) => a[1] - b[1]);
  return random_array.map((x) => x[0]);
}

export function kendallsTau(arr) {
  let sum = 0;
  for (let i = 0; i < arr.length - 1; ++i) {
    for (let j = i; j < arr.length; ++j) {
      sum += arr[i] < arr[j] ? 1 : 0;
    }
  }
  return sum / ((arr.length * (arr.length - 1)) / 2);
}

export function kendallsTauAccuracy(arr) {
  let acc = [];
  for (let i = 0; i < arr.length; ++i) {
    let sum = 0;
    for (let j = 0; j < arr.length; ++j) {
      if (i < j && arr[i] < arr[j]) {
        sum += 1;
      } else if (i > j && arr[i] > arr[j]) {
        sum += 1;
      }
    }
    acc.push(sum / (arr.length - 1));
  }
  return acc;
}

export function rgInterpolate(percent) {
  //not a stric interpolation as that's visually unappealing
  const r = Math.round(255 * (percent <= 0.5 ? 1 : 1 - (percent - 0.5) * 2)); // Red decreases after 0.5
  const g = Math.round(255 * (percent >= 0.5 ? 1 : percent * 2)); // Green increases before 0.5

  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}00`;
}

export function findElementsByKey(arr, key) {
  let results = [];

  arr?.forEach((item) => {
    if (item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        results.push(item[key]);
      }
      Object.values(item).forEach((value) => {
        if (Array.isArray(value)) {
          const nestedResults = findElementsByKey(value, key);
          results = results.concat(nestedResults);
        }
      });
    }
  });

  return results;
}

export function findOnjectByKey(arr, key) {
  let results = [];

  arr?.forEach((item) => {
    if (item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        results.push(item);
      }
      Object.values(item).forEach((value) => {
        if (Array.isArray(value)) {
          const nestedResults = findOnjectByKey(value, key);
          results = results.concat(nestedResults);
        }
      });
    }
  });

  return results;
}

export function convertToTime(number, h, m) {
  const hours = Math.floor(number / 3600);
  const minutes = Math.round((number / 3600 - hours) * 60);
  if (hours === 0 && minutes === 0) return null;
  if (hours > 0) {
    return hours + h + ' ' + minutes + m;
  } else {
    return minutes + m;
  }
}
export function convertToTimeSec(number, h, m) {
  const hours = Math.floor(number / 3600);
  const minutes = Math.floor((number / 3600 - hours) * 60);
  const sec = Math.floor(number - (hours * 3600 + minutes * 60));
  if (hours === 0 && minutes === 0 && sec === 0) return null;
  if (hours > 0) {
    return hours + h + ' ' + minutes + m + ' ' + sec + 's';
  } else {
    if (minutes > 0) {
      return minutes + m + ' ' + sec + 's';
    } else {
      return sec + 's';
    }
  }
}

export function relativePos(child, parent) {
  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();
  return {
    top: childRect.top - parentRect.top,
    left: childRect.left - parentRect.left,
    width: childRect.width,
    height: childRect.height,
  };
}

export function v2equal(a, b) {
  return a[0] == b[0] && a[1] == b[1];
}

// Compute the edit distance between the two given strings
export function stringDistance(a, b) {
  a = a.toLowerCase();
  b = b.toLowerCase();

  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  var matrix = [];

  // increment along the first column of each row
  var i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  var j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1
          )
        ); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
}

export function stringRelativeDistance(a, b) {
  return stringDistance(a, b) / b.length;
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function uploadFile(multiple = false, accept) {
  return new Promise((resolve, reject) => {
    let input = document.createElement('input');
    input.type = 'file';
    input.visbility = 'hidden';
    if (multiple) {
      input.multiple = true;
    }
    if (accept) {
      input.accept = accept;
    }
    input.onchange = (e) => {
      let files;
      if (e.target.files) files = e.target.files;
      else files = [e.target.file];
      resolve(files);
      document.body.removeChild(input);
    };
    document.body.appendChild(input);
    input.click();
  });
}

export function realParseFloat(value) {
  if (value == null || value == undefined) return '';
  return value
    .toString()
    .replace(/[^\d\-.,]/g, '') // Basic sanitization. Allows '-' for negative numbers.
    .replace(',', '.') // Change all commas to periods.
    .replace(/\.(?=.*\.)/g, ''); // Remove all periods except the last one.
}

//extension of str.lastIndexOf
export function nthLastIndexOf(str, searchString, n) {
  if (str === null) return -1;
  if (!n || isNaN(n) || n <= 1) return str.lastIndexOf(searchString);
  n--;
  return str.lastIndexOf(
    searchString,
    nthLastIndexOf(str, searchString, n) - 1
  );
}

//extension of str.indexOf
export function nthIndexOf(str, pattern, n) {
  let index = 0;
  for (let i = 0; i < n; i += 1) {
    if (index !== -1) index = str.indexOf(pattern, index + 1);
  }
  return index;
}

export function getCharForNumber(n) {
  return String.fromCharCode(65 + n);
}

export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.warn('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export const calculateUnit = (raw, unit = 'volt', maxVolt = 24) => {
  let value;
  let volt;
  let amp;
  switch (unit) {
    case 'volt':
      value = raw;
      return value.toFixed(2) + 'V';

    case 'ampere':
      volt = raw * maxVolt;

      //Todo: choose correct Ohm
      // volt devided with ohm equals ampere
      value = volt / 500;
      return value.toFixed(2) + 'A';

    case 'watt':
      volt = raw * maxVolt;

      //Todo: choose correct Ohm
      // volt devided with ohm equals ampere
      amp = volt / 500;

      // volt multiplied with ampere equals watts
      value = volt * amp;
      return value.toFixed(2) + ' Watt';

    case 'degrees':
      volt = (raw * maxVolt) / 800;

      value = volt * 10;
      return value.toFixed(2) + ' °C';

    case 'farhenheit':
      break;
    case 'bar':
      value = volt * 10;
      return value.toFixed(2) + ' Bar';
  }
  return value;
};

export async function sleep(ms) {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export function array_difference(a, b) {
  let temp = new Set(b);
  return [...a].filter((x) => !temp.has(x));
}

export function array_intersect(a, b) {
  return a.filter(Set.prototype.has, new Set(b));
}

export function swap(arr, i, j) {
  var temp = arr[i];
  arr[i] = arr[j];
  arr[j] = temp;
}

export function useStateAndRef(initial) {
  const [value, setValue] = useState(initial);
  const valueRef = useRef(value);
  valueRef.current = value;
  return [value, setValue, valueRef];
}

export function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

//takes a filter str from a earch bar and translates it into a url that can be passed
//to a list endpoint
export function parseFilter(filter) {
  if (!filter) return undefined;
  const splittedFilterValue = filter?.split(/\s+/);
  const filterValue = `%${splittedFilterValue?.join('%,AND,%')}%`;
  return encodeURIComponent(filterValue);
}

export function parseSelectedTags(filter) {
  if (!filter) return undefined;
  const selectedTags = filter?.map((el) => el.value);
  const strWithSelectedTags = selectedTags.toString();
  return 'tag:' + strWithSelectedTags;
}

export function parseTypeCourse(typeCourse) {
  switch (typeCourse) {
    case 'courses':
      return 'course';
    case 'assessments':
      return 'assessment';
    case 'vacancies':
      return 'leervacature';
    case 'instructions':
      return 'instruction';
    default:
      return 'course';
  }
}

export function parseCourseType(courseType) {
  switch (courseType) {
    case 'course':
      return 'courses';
    case 'assessment':
      return 'assessments';
    case 'leervacature':
      return 'vacancies';
    case 'instruction':
      return 'instructions';
    default:
      return 'courses';
  }
}

//examples:
//  toPercent(2, 3) => 66
//  toPercent(2, 3, 2) => 66.66
//  toPercent(0.5) => 50
//  toPercent(0.3333333) => 33
//  toPercent(0.3333333, null, 2) => 33.33
export function toPercent(score, maxScore = 0, significantDigits = 0) {
  if (!score && !maxScore) return 100;
  if (!score) return '0';
  if (!maxScore) return round(score || 0, significantDigits);
  return parseFloat(
    round(((score || 0) / (maxScore || 1)) * 100, significantDigits)
  );
}

export function parseContentRange(axiosResult) {
  const contentRange = axiosResult?.headers?.['content-range']
    ?.split(' ')?.[1]
    ?.split('/');
  const maxCount = contentRange ? Math.ceil(contentRange?.[1]) : 0;
  return maxCount;
}

export function downloadLink(href) {
  var link = document.createElement('a');
  link.href = href;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const clearCache = (reloadAfterClear = true) => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach(async (name) => {
        await caches.delete(name);
      });
    });

    if (reloadAfterClear) window.location.reload();
  }
};

export const useAsyncState = (initialState) => {
  const [state, setState] = useState(initialState);

  const asyncSetState = (value) => {
    return new Promise((resolve) => {
      setState(value);
      setState((current) => {
        resolve(current);
        return current;
      });
    });
  };

  return [state, asyncSetState];
};

export const scrollIntoViewWithOffset = (selector, offset) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      document.querySelector(selector).getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
};

export const fancyWrapper = (node) => {
  window
    .$(node)
    .find('img')
    .each(function () {
      if (!window.$(this).hasClass('nowrap')) {
        if (window.$(this).parent().attr('data-fancybox')) {
          window.$(this).unwrap();
        }
        if (!window.$(this).parent().attr('href')) {
          window
            .$(this)
            .wrap(
              '<a class="fancybox" data-fancybox href="' +
                window.$(this).attr('src') +
                '"/>'
            );
        }
      }
    });
  window.Fancybox.bind('[data-fancybox]', {
    hideOnContentClick: true,
    hideScrollbar: false,
  });
};

export function simplifyPath(str) {
  if (!str) return '';
  return str
    .replace(/ +(?= )/g, '')
    .replace(/%/g, '')
    .replace(/\?/g, '')
    .replace(/\|/g, '')
    .replace(/>/g, '')
    .replace(/</g, '')
    .replace(/\*/g, '')
    .replace(/:/g, '')
    .replace(/"/g, '')
    .replace(/\//g, '')
    .replace(/ë/g, '')
    .replace(/\\/g, '')
    .replace(/&/g, '')
    .replace(/\s/g, '_')
    .trim();
}

export function hasParentWithClass(element, className) {
  let parent = element.parentElement;
  while (parent) {
    if (parent.classList.contains(className)) {
      return true;
    }
    parent = parent.parentElement;
  }
  return false;
}

export function isEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (
      typeof obj1[key] === 'object' &&
      obj1[key] !== null &&
      typeof obj2[key] === 'object' &&
      obj2[key] !== null
    ) {
      if (!isEqual(obj1[key], obj2[key])) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export function orderWidgets() {
  const widgets = document?.querySelectorAll('.widget_practical');
  let index = 1;
  if (widgets?.length > 0) {
    widgets.forEach((widget) => {
      const label = widget.querySelector('.number_widget');
      if (label) {
        label.innerHTML = index;
        ++index;
      }
    });
  }
}

export function focusWidget(uid) {
  const node = document.querySelector(`#${CSS.escape(uid)}`);

  const currentStyle = node?.getAttribute('style');

  if (currentStyle && currentStyle.includes('border')) {
    node.style.border = '1px solid #f8f8f9';
  }
}

export function getInitialsFromName(name) {
  return name
    .split(' ')
    .map((word) => word[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();
}

export function mongoEncodeKey(key) {
  return key
    .replace(/\./g, '\uff0e')
    .replace(/\$/g, '\uff04')
    .replace(/\\/g, '\uff3c');
}

export function mongoDecodeKey(key) {
  return key
    .replace('\\u002e', '.')
    .replace('\\u0024', '$')
    .replace('\\\\', '\\');
}

export function convertToPlain(html, limit, elipsis = '...') {
  let tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = html;
  const temp = tempDivElement.textContent || tempDivElement.innerText || '';
  const truncated = temp.slice(0, limit) + '...';
  if (truncated.length < temp.length) return truncated;
  else return temp;
}

export function parseDate(date) {
  if (!date) return '';
  date = new Date(date);
  if (isNaN(date.getTime())) return '';
  var dd = date.getDate(); // Compute the edit distance between the two given strings
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  let result = dd + '/' + mm + '/' + yyyy;
  return result;
}

export function parseDateTime(date) {
  if (!date) return '';
  date = new Date(date);
  if (isNaN(date.getTime())) return '';
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var h = date.getHours();
  var m = date.getMinutes();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (h < 10) {
    h = '0' + h;
  }
  if (m < 10) {
    m = '0' + m;
  }
  return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m;
}

export function walkExtra(root, fn, i = 0, parent = {}) {
  if (!root) return;
  fn(root, i, parent);
  if (root.children) {
    let j = 0;
    for (let child of root.children) {
      walkExtra(child, fn, j, root);
      j++;
    }
  }
  if (root.extraInfo) {
    let j = 0;
    for (let child of root.extraInfo) {
      walkExtra(child, fn, j, root);
      j++;
    }
  }
}

export function bfsWalkExtra(root, fn) {
  const queue = [root];
  while (queue.length > 0) {
    const current = queue.shift();
    if (current === null) continue;
    fn(current);
    if (current.children) {
      for (const child of current.children) {
        queue.push(child);
      }
    }
    if (current.extraInfo) {
      for (const child of current.extraInfo) {
        queue.push(child);
      }
    }
  }
}

export function retryWithExponentialBackoff(
  fn,
  maxAttempts = 5,
  baseDelayMs = 100,
  exponent = 1.5
) {
  let attempt = 1;

  const execute = async () => {
    try {
      return await fn();
    } catch (error) {
      if (attempt >= maxAttempts) {
        throw error;
      }
      const delayMs = baseDelayMs * Math.pow(exponent, attempt);
      await new Promise((resolve) => setTimeout(resolve, delayMs));
      attempt++;
      return execute();
    }
  };
  return execute();
}

export function extractTextFromElement(element) {
  let result = '';
  element?.childNodes?.forEach((child) => {
    if (child.nodeType === Node.TEXT_NODE) {
      result += child.textContent;
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      const tagName = child.tagName.toLowerCase();
      if (['p', 'div', 'li'].includes(tagName)) {
        result += '\n';
      }
      if (tagName === 'li') {
        result += '- ';
      }
      if (tagName === 'a') {
        result += child.textContent + ` (${child.getAttribute('href')})`;
      } else if (tagName === 'span') {
        result += ' ' + extractTextFromElement(child) + ' ';
      } else {
        result += extractTextFromElement(child);
      }
      if (['p', 'div', 'li', 'ul', 'ol'].includes(tagName)) {
        result += '\n';
      }
    }
  });
  return result;
}

export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

export function secondsToIsoDuration(seconds) {
  const hours = Math.floor(seconds / 3600); // Calculate hours
  seconds %= 3600; // Remainder after hours
  const minutes = Math.floor(seconds / 60); // Calculate minutes
  seconds %= 60; // Remaining seconds

  // Build the duration string in ISO 8601 format
  let duration = 'PT';
  if (hours > 0) duration += `${hours}H`;
  if (minutes > 0) duration += `${minutes}M`;
  if (seconds > 0 || duration === 'PT') duration += `${seconds}S`;

  return duration;
}

export function parseNumber(n, fallback) {
  let number = parseFloat(n);
  if (!number && number !== 0) return fallback;
  return number;
}

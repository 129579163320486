import { useState, useContext } from 'react';
import { Button, InputText, Label, Modal } from '@teo/components';
import ColorPicker from '@/components/ColorPicker';
import IconPicker from '@/components/IconPicker';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { iconPickerOptionsData } from '@/mocks/iconPickerOptionsData';

function EditCategoryModal({ closeModal, onChange, category }: any) {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [categoryName, setCategoryName] = useState(category?.tag || '');
  const [colorPicker, setColorPicker] = useState(category?.color || '#ff0000');
  const [iconPicker, setIconPicker] = useState(category?.icon || 'milieu');

  function editNewCategory() {
    const update: any = {
      color: colorPicker,
      icon: iconPicker,
      tag: categoryName,
    };
    if (category) {
      axios
        .put(`${config.backend}/categories/${category.id}`, update)
        .then((data) => onChange(data));
    } else {
      update.organisationId = auth?.user?.activeOrganisationId;
      update.lang = auth?.user?.lang;
      axios
        .post(`${config.backend}/categories`, update)
        .then((data) => onChange(data));
    }
  }

  return (
    <div className="bg-white">
      <Modal isOpen>
        <Modal.Header onClose={closeModal}>
          <h2
            className="text-sm font-semibold"
            data-testid="editCategoryHeader"
          >
            {t('pages.manage_categories.edit_category')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <form
            className="w-full max-w-[440px] flex-col max-md:h-full max-md:overflow-hidden"
            data-testid="editCategoryForm"
          >
            <div>
              <Label htmlFor="categoryName">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_categories.category_name_label')}
                </p>
              </Label>
              <InputText
                id="categoryName"
                type="text"
                onChange={(e) => setCategoryName(e.target.value)}
                placeholder={
                  category?.tag?.charAt?.(0)?.toUpperCase() +
                  category?.tag?.slice(1)
                }
                value={categoryName}
              />
            </div>

            <div>
              <Label htmlFor="colour">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_categories.colour_label')}
                </p>
              </Label>
              <div id="colour">
                <ColorPicker
                  colorPicker={!colorPicker ? category.color : colorPicker}
                  setColorPicker={setColorPicker}
                />
              </div>
            </div>

            <div>
              <Label htmlFor="icon">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_categories.icon_label')}
                </p>
              </Label>
              <div id="icon">
                <IconPicker
                  iconPicker={iconPicker}
                  setIconPicker={setIconPicker}
                />
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('pages.manage_categories.cancel_button')}
          onCancel={closeModal}
        >
          <div data-testid="editCategoryFooter">
            <Button type="button" onClick={() => editNewCategory()}>
              {t('pages.manage_categories.add_category_button')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditCategoryModal;

import { config } from '@/config';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
    defaultNS: 'common';
    resources: {
      [key: string]: {
        [key: string]: string;
      };
    };
  }
}

function changeLanguage(lang: string) {
  i18n.changeLanguage(lang);
  const locationWithCorrectLanguage = window.location.pathname.replace(
    new RegExp(`^/(${config.langs.join('|')})`),
    `/${lang}`
  );

  if (locationWithCorrectLanguage !== window.location.pathname) {
    window.history.replaceState(
      '',
      '',
      locationWithCorrectLanguage + window.location.search
    );
  }
}

const languageDetector = new LanguageDetector();

languageDetector.detectors['path'].cacheUserLanguage = (lang: string) => {
  const hasLang = /^\/(fr|nl|en|es).*/.test(window.location.pathname);

  let locationWithCorrectLanguage = window.location.pathname;
  if (!hasLang) {
    locationWithCorrectLanguage = `/${lang}${window.location.pathname}`;

    if (locationWithCorrectLanguage !== window.location.pathname) {
      window.location.replace(
        locationWithCorrectLanguage + window.location.search
      );
    }

    if (!locationWithCorrectLanguage.startsWith(`/${lang}`)) {
      window.location.replace(
        `/${lang}${locationWithCorrectLanguage}` + window.location.search
      );
    }
  }
};

const initLanguage = () => {
  i18n
    .use(ICU)
    .use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `/locales/${config.version}{{lng}}.json`,
      },
      debug: config.debug,
      detection: {
        caches: ['localStorage', 'path'],
        lookupFromPathIndex: 0,
        order: ['path', 'localStorage', 'navigator', 'htmlTag'],
      },
      fallbackLng: 'nl',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      returnNull: false,
      supportedLngs: config.langs,
    });

  i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
  });
};

(window as any).i18n = i18n;

export { changeLanguage, initLanguage };

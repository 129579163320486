import { useState, useEffect } from 'react';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';

export const LessonExtraInfoView = ({ activeExtraInfo, pageBreak = true }) => {
  const { t } = useTranslation();
  const [splittedContent, setSplittedContent] = useState(null);

  useGet(activeExtraInfo?.getData, {
    enabled: !!activeExtraInfo?.getData,
    onSuccess: (result) => {
      let splitted_html;
      if (pageBreak) {
        splitted_html = result?.data.split(
          /<div class="pageBreak[\s\S]*?<\/div>/gms
        );
      } else {
        splitted_html = [result?.data];
      }
      setSplittedContent(splitted_html);
    },
  });

  return <FroalaTextareaView value={splittedContent} />;
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@teo/components';

function RegisterSucessPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="h-full overflow-hidden md:flex md:items-center">
      <div className="m-8 flex h-full flex-col justify-center gap-8 overflow-hidden text-center md:w-1/2 md:items-center md:overflow-auto">
        <div className="flex h-full overflow-hidden md:w-1/2 md:items-center md:overflow-auto">
          <div className="m-auto flex w-full max-w-[420px] flex-col max-md:h-full max-md:overflow-hidden">
            <div className="space-y-6 px-5 pt-8 max-md:flex-grow max-md:overflow-auto md:space-y-8">
              <img
                className="h-12 w-12 md:h-16 md:w-16"
                src="/TEO_logo.svg"
                alt="Logo"
              />
              <h1 className="text-3xl-semibold text-left">
                {t('pages.auth.forgot_password.got_mail_title')}
              </h1>
              <div className="space-y-6 md:space-y-8">
                <p className="text-left text-sm text-grey-05">
                  {t('pages.auth.register_success.description')}
                </p>
                <p className="text-left text-sm text-grey-05">
                  {t('pages.auth.register_success.didnt_receive_msg')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 max-md:hidden md:flex md:h-[100vh] md:items-center md:justify-center">
        <img
          className="h-full w-full object-cover"
          role="presentation"
          src="/images/interface/auth/login.jpg"
          alt="login_image"
        />
      </div>
    </div>
  );
}

export default RegisterSucessPage;

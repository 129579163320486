import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { Button, PersonAvatar, IconButton } from '@teo/components';
import { useGet } from '@/query/fetchHooks';
import { ArrowLeft, Tag, CrossFilled } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { nthLastIndexOf, convertToTime } from '@/util';
// import UseChapterModal from './UseChapterModal';
import { format } from 'date-fns';
import AddLibraryStepsContainer from './AddLibraryStepsContainer/AddLibraryStepsContainer';
import ChapterTreeChildren from './ChapterTreeChildren';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';

const LibraryDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { chapterId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const prevPage = queryParams.get('type');
  // const [isOpenUseModal, setIsOpenUseModal] = useState(false);
  const [isAddLabrary, setIsAddLabrary] = useState(false);
  const [chapter, setChapter] = useState<any>(null);
  const [tags, setTags] = useState<any>();
  const [numLes, setNumLes] = useState<any>(null);
  const [chapterTree, setChapterTree] = useState<any>(null);
  const [firstLesson, setFirstLesson] = useState<any>(null);
  const [chapterExtraInfo, setChapterExtraInfo] = useState<any>(null);

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 2)
  );
  const examplePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 3)
  );

  useGet(`/directoryimports`, {
    organisationId: `${authUser.activeOrganisationId},OR,organisationId=null`,
    directoryId: chapterId,
    onSuccess: (result: any) => {
      setChapter(result?.data[0] || null);
    },
  });

  useEffect(() => {
    if (chapter?.tag) {
      const data = chapter?.tag?.split(';;');
      const filteredTags = data.filter(
        (item: any) => item.toLowerCase() !== chapter?.name?.toLowerCase()
      );
      setTags(
        filteredTags
          ?.filter((x: any) => x?.startsWith('tag:'))
          ?.map((el: any) => el?.split('tag:')[1])
      );
    }
  }, [chapter?.tag]);

  const handleGoBack = () => {
    navigate(
      prevPage == 'chapter' ? `${basePath}?type=chapter` : `${basePath}`
    );
  };

  if (!chapter) return null;

  if (isAddLabrary) {
    return (
      <div className="fixed top-0 left-0 z-[100] h-screen w-screen overflow-y-auto bg-white">
        <div className="flex min-h-20 flex-col justify-start py-2 pr-2 sm:flex-row sm:items-center">
          <div
            data-testid="clouseBtn"
            className="flex min-h-20 items-center justify-start"
          >
            <IconButton
              className="my-auto mx-3 sm:mx-6"
              Icon={CrossFilled}
              variant="ghost"
              onClick={() => {
                setIsAddLabrary(false);
              }}
            />
            <div className="flex items-center">
              <div className="mr-5 w-20 min-w-20">
                <img
                  src={
                    chapter?.publicationImage
                      ? chapter?.publicationImage
                      : '/assets/images/opleidingen.jpg'
                  }
                  alt={chapter?.name}
                  className="h-12 w-full max-w-[72px] rounded-md object-cover"
                />
              </div>
              <div className="flex flex-wrap">
                <p>
                  {t(`pages.create_library.title`)}:
                  <span className="ml-2 font-semibold">{chapter?.name}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <AddLibraryStepsContainer
          node={chapter}
          chapterTree={chapterTree}
          chapterExtraInfo={chapterExtraInfo}
        />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="my-8 flex">
          <Button
            className="flex items-center gap-3"
            variant="text"
            onClick={() => handleGoBack()}
          >
            <ArrowLeft className="h-5 w-5" />
            <p>{t(`pages.create_library.back_to_library`)}</p>
          </Button>

          <div className="ml-auto flex gap-2">
            <Button
              variant="outline"
              disabled={!firstLesson}
              onClick={() =>
                window.open(
                  `${examplePath}/courses/${chapter?.coursecontentId}/preview?les=${firstLesson?.usesId}&new_tab=true`,
                  '_blank'
                )
              }
            >
              {t('button.example')}
            </Button>
            <Button onClick={() => setIsAddLabrary(true)}>
              {t(`pages.create_library.use_chapter`)}
            </Button>
          </div>
        </div>
        <div className="mb-6">
          <img
            src={
              chapter?.publicationImage
                ? chapter?.publicationImage
                : '/assets/images/opleidingen.jpg'
            }
            alt={chapter?.name}
            className="max-h-60 w-full rounded-md object-cover"
          />
        </div>
        <div className="flex flex-col">
          <h1 className="mb-6 text-xl">
            <span className="pr-2 font-normal text-grey-06">
              {t(`pages.create_library.chapter`)}:
            </span>
            {chapter?.name}
          </h1>
          <div className="mb-6 flex flex-wrap items-center">
            <PersonAvatar
              inline
              id={chapter?.authorId}
              name={chapter?.authorName}
              imageSrc={chapter?.authorPicture ? chapter?.authorPicture : ''}
            ></PersonAvatar>
            <div>
              <p className="text-xs text-grey-07">
                {chapter?.authorName && (
                  <>
                    {t('pages.create_edit_course.library.written_by')}{' '}
                    {chapter?.authorName}
                  </>
                )}
                <span className="-mt-1 px-2">|</span>
              </p>
            </div>
            <p className="text-xs text-grey-07">
              {chapter?.updatedAt &&
                t('pages.create_edit_course.library.last_updated') +
                  ' ' +
                  format(new Date(chapter?.updatedAt), 'dd MMM yyyy')}
            </p>
            {chapter?.directoryEstimatedTime > 0 && (
              <span className="flex flex-row items-center gap-1 text-xs text-grey-07">
                <span className="-mt-1 px-2">|</span>
                <p>
                  <span className="pr-1">
                    {t('pages.create_edit_course.library.reading_time')}
                  </span>
                  {convertToTime(
                    Math.abs(chapter?.directoryEstimatedTime),
                    t('pages.learn_index.hour'),
                    t('pages.learn_index.minute')
                  )}
                </p>
              </span>
            )}
          </div>
          {tags?.length > 0 && (
            <div className="mb-5 flex flex-wrap items-center gap-3">
              <Tag className="h-5 w-5 text-grey-07" />
              {tags?.map((el: any, i: any) => {
                return (
                  <div
                    key={i}
                    className="inline-flex items-center gap-1 rounded bg-grey-transparent-02 py-0.5 px-2 text-sm font-medium text-grey-08"
                  >
                    {el}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="px-3">
          <p className="mt-6 mb-3 text-sm font-semibold">
            {t('extra_info.contents')}
            <span className="pl-3 text-xs font-normal text-grey-07">
              {numLes ? (
                <>
                  {' '}
                  {numLes} {t('pages.create_library.lessons')}
                </>
              ) : (
                <>0 {t('pages.create_library.lessons')}</>
              )}
            </span>
          </p>
          <ChapterTreeChildren
            directoryId={chapter?.directoryId}
            directoryPath={chapter?.path}
            setNumLes={setNumLes}
            setChapterTree={setChapterTree}
            setFirstLesson={setFirstLesson}
            setChapterExtraInfo={setChapterExtraInfo}
          />
        </div>
      </div>
    </>
  );
};

export default LibraryDetail;

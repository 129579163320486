import { useState, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useParams } from 'react-router-dom';
import { axios } from '@/axios';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { NavContext } from '@/components/NavContext';
import { useTranslation } from 'react-i18next';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
import { config } from '@/config';
import { useMediaQuery } from 'react-responsive';

function Onboarding() {
  const { auth } = useContext(AuthContext);
  const { setNavDisabled } = useContext(NavContext);
  const { courseId } = useParams();
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });

  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const [info, setInfo] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  const [courseInfo, setCourseInfo] = useState<any>();
  const [lessonInfo, setLessonInfo] = useState<any>();
  const [followInfo, setFollowInfo] = useState<any>();
  const [user, setUser] = useState<any>(auth?.user);

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  useGet(`/userinfo/${user?.id}`, {
    onSuccess: (result: any) => {
      setUserInfo(result?.data || null);
    },
    onError: () => {
      axios
        .post(`${config.backend}/userinfos`, {
          userId: user?.id,
          id: user?.id,
        })
        .then((result: any) => {
          setUserInfo(result?.data || null);
        });
    },
  });

  useGet('/usedcourselessons', {
    count: 10000,
    courseId,
    onSuccess: (result: any) => {
      setLessonInfo(result?.data || null);
    },
  });

  useGet(`/follow/${courseId}/${auth?.user?.id}`, {
    onSuccess: (result: any) => {
      setFollowInfo(result?.data || {});
    },
  });

  useGet(`/courseinfo/${courseId}`, {
    onSuccess: (result: any) => {
      setCourseInfo(result?.data || {});
    },
  });

  return (
    <div
      className="h-full overflow-hidden md:flex"
      style={
        isDesktopOrLaptop
          ? {
              minHeight: 'calc(100vh - 150px)',
            }
          : {
              minHeight: 'calc(100vh - 206px)',
            }
      }
    >
      <div className="my-auto h-full  p-6 md:w-1/2">
        <div className="mx-auto flex max-w-lg flex-col" id="userForm">
          <div className="mb-6 text-3xl font-semibold">
            {t(`pages.onboarding.step_${step}.title`)}
          </div>
          <Step1
            step={step}
            setStep={setStep}
            setInfo={setInfo}
            user={user}
            userInfo={userInfo}
            courseInfo={courseInfo}
          />
          <Step2
            step={step}
            setStep={setStep}
            setInfo={setInfo}
            userInfo={userInfo}
            info={info}
            courseInfo={courseInfo}
          />
          <Step3
            step={step}
            setStep={setStep}
            setInfo={setInfo}
            userInfo={userInfo}
            courseInfo={courseInfo}
          />
          <Step4
            step={step}
            setStep={setStep}
            setInfo={setInfo}
            followInfo={followInfo}
            courseInfo={courseInfo}
          />
          <Step5
            step={step}
            setStep={setStep}
            setInfo={setInfo}
            userInfo={userInfo}
            courseInfo={courseInfo}
          />
          <Step6
            step={step}
            setStep={setStep}
            setInfo={setInfo}
            userInfo={userInfo}
            courseInfo={courseInfo}
          />
          <Step7
            step={step}
            setStep={setStep}
            setInfo={setInfo}
            user={user}
            info={info}
            userInfo={userInfo}
            lessonInfo={lessonInfo}
            followInfo={followInfo}
            courseInfo={courseInfo}
          />
        </div>
      </div>
      <div className="relative w-1/2 overflow-hidden max-md:hidden">
        <img
          className="t-0 l-0 absolute h-full w-full object-cover object-center"
          role="presentation"
          src="/images/interface/onboarding/onboarding.png"
          alt="login_image"
        />
      </div>
    </div>
  );
}

export default Onboarding;

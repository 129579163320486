import { Memberdetails } from '@/models';
import { faker } from '@faker-js/faker';
import { Factory } from './types';

export const memberFactory: Factory<Memberdetails> = (overrides) => ({
  firstName: faker.name.firstName(),
  id: faker.datatype.number(),
  lastName: faker.name.lastName(),
  username: faker.internet.userName(),
  ...overrides,
});

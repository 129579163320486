import React, { useRef, useState, useCallback, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TargetBox } from '@/components/UploadDropzoneSection/TargetBox';
import { useTranslation } from 'react-i18next';
import { uploadImage } from '@/query/documents';
import UploadMainImageContainer from '@/components/UploadMainImage/UploadMainImageContainer';
import UploadMainImageFileContainer from '@/components/UploadMainImage/UploadMainImageFileContainer';
import { LibraryModal } from '../LibraryModal/LibraryModal';
const UploadMainImage = ({
  setUploadLogo,
  uploadLogo,
  courseImg,
  setLoadingImgSpinner,
  loadingImgSpinner,
}: any) => {
  const [photoInfo, setPhotoInfo] = useState();
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const [isOpenLibraryModal, setIsOpenLibraryModal] = useState(false);

  useEffect(() => {
    if (uploadLogo) {
      setLoadingImgSpinner(false);
    }
  }, [uploadLogo, setLoadingImgSpinner]);

  const handleFileDrop = useCallback(
    async (item: { files: any }) => {
      setLoadingImgSpinner(true);
      if (item) {
        const files = item.files;
        setDroppedFiles(files);
        const size = courseImg ? 920 : 680;
        const data = await uploadImage(item.files[0], size, size, 'jpeg');
        setUploadLogo(courseImg ? data : 'https://' + data);
      }
    },
    [setDroppedFiles, setUploadLogo]
  );
  const handleUploadPhoto = async (event: any): Promise<any> => {
    setLoadingImgSpinner(true);
    const img = event.target.files[0];
    setPhotoInfo(img);
    const size = courseImg ? 920 : 680;
    const data = await uploadImage(img, size, size, 'jpeg');
    setUploadLogo(courseImg ? data : 'https://' + data);
  };

  const handleUploadPhotoLibrary = async (image: any): Promise<any> => {
    setLoadingImgSpinner(true);
    const res = await fetch(image);
    const blob = await res.blob();
    const mime = blob.type;
    const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);
    const img: any = new File([blob], `${blob.size}.${ext}`, {
      type: mime,
    });
    setPhotoInfo(img);
    const size = courseImg ? 920 : 680;
    const data = await uploadImage(img, size, size, 'jpeg');
    setUploadLogo(courseImg ? data : 'https://' + data);
  };

  return (
    <>
      <div className={`${loadingImgSpinner ? 'hidden' : 'visible'}`}>
        <div id="uploadDropzone" className="mt-3">
          <DndProvider backend={HTML5Backend}>
            {!photoInfo && droppedFiles.length === 0 && !uploadLogo && (
              <TargetBox
                onDrop={handleFileDrop}
                fileInputRef={fileInputRef}
                handleUploadPhoto={handleUploadPhoto}
                courseImg={courseImg}
                setIsOpenLibraryModal={setIsOpenLibraryModal}
              />
            )}
            {droppedFiles.length > 0 && !photoInfo && (
              <UploadMainImageFileContainer
                files={droppedFiles}
                fileInputRef={fileInputRef}
                handleUploadPhoto={handleUploadPhoto}
                courseImg={courseImg}
              />
            )}
            {(photoInfo || uploadLogo) && (
              <UploadMainImageContainer
                photoInfo={photoInfo}
                fileInputRef={fileInputRef}
                handleUploadPhoto={handleUploadPhoto}
                uploadLogo={uploadLogo}
                courseImg={courseImg}
                setIsOpenLibraryModal={setIsOpenLibraryModal}
              />
            )}
          </DndProvider>
        </div>
      </div>
      {isOpenLibraryModal && (
        <LibraryModal
          setIsOpenLibraryModal={setIsOpenLibraryModal}
          isOpenLibraryModal={isOpenLibraryModal}
          lessonPage={false}
          setUploadFile={handleUploadPhotoLibrary}
        />
      )}
    </>
  );
};
export default UploadMainImage;

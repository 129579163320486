import { UserInvite } from './UserInvite';
import { useState, useEffect, forwardRef, useContext } from 'react';
import { Button, IconButton, Modal, PersonAvatar } from '@teo/components';
import { CrossFilled, Document } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';

const UserInviteComplete = (props: any) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<any>([]);
  //const [newUser, setNewUser] = useState<any>([]);

  const handleAddUserToList = (newValue: any[]) => {
    for (const key in newValue) {
      newValue[key] = Object.assign({}, newValue[key], props.extra || {});
    }
    props.setNewUser([...props.newUser, ...newValue]);
    setValue([]);
  };

  const handleRemove = (id: any) => {
    const newList = props.newUser.filter((item: any) => item.key !== id);
    props.setNewUser(newList);
  };

  return (
    <div className="my-6">
      <div>
        <div id="invite_author" className="ali relative z-[11] flex w-full">
          <span className="w-full">
            <UserInvite
              keyFn={(usr: any) => usr?.id}
              defaultSort={[{ id: 'username', asc: true }]}
              render={(usr: any) => usr?.username || usr?.email}
              fetch={props.fetch}
              inviteButtonText={
                props.inviteButtonText || t('pages.manage_add_users.add_button')
              }
              value={value}
              setValue={setValue}
              handleAddUserToList={handleAddUserToList}
              addValue={(email: string) => {
                const newValue = [
                  ...value,
                  {
                    ...(props.extra || {}),
                    email: email,
                    username: email,
                    label: email,
                    key: email,
                    id: email,
                    userId: email,
                    newUser: true,
                  },
                ];
                setValue(newValue);
                return newValue;
              }}
            />
          </span>
        </div>
      </div>
      <div>
        {props.newUser.map((item: any) => (
          <div
            key={item.userId !== null ? item.key : item.teamId}
            className="mb-4 flex items-center justify-between"
          >
            <div className="flex items-center">
              <PersonAvatar
                id={item?.id}
                name={item?.username}
                imageSrc={item?.picture}
              />

              <div className="flex flex-col pl-4">
                <span className="text-sm font-medium text-[#231f20]">
                  {item.username || item.email}
                </span>
              </div>
            </div>
            <div data-testid="daleteItem" className="my-auto mx-6">
              <IconButton
                Icon={CrossFilled}
                variant="ghost"
                onClick={() => handleRemove(item.key)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { UserInviteComplete };

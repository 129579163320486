import { useTranslation } from 'react-i18next';
import React, { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { IconButton, TeamAvatar, Stepper, Button } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { Teams } from '@/models';
import AddUsersStepsContainer from '@/pages/Protected/Manage/AddUsersStepsContainer/AddUsersStepsContainer';
import { UserInviteComplete } from '@/components/UserInvite/UserInviteComplete';
import { useMobile } from '@/util';
import { T } from '@/components/T';

function AddUsersToTeamManagePage() {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const { auth } = useContext(AuthContext);
  const { setNavDisabled } = useContext(NavContext);
  const { organisationId, teamId } = useParams();

  const navigate = useNavigate();

  const [value, setValue] = useState<any>([]);
  const [teacherValue, setTeacherValue] = useState<any>([]);

  const [newUser, setNewUser] = useState<any>([]);

  const [data, setData] = useState<any>([]);
  const [team, setTeam] = useState<Teams | null>(null);

  const activeOrganisationId = auth?.user?.activeOrganisationId;
  const activeLang = auth?.user?.lang;

  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(1);

  const [newStudent, setNewStudent] = useState<any>([]);
  const [newTeacher, setNewTeacher] = useState<any>([]);

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  useGet(`/team/${organisationId}/${teamId}`, {
    onSuccess: (result: any) => {
      setTeam(result?.data || null);
    },
  });

  const handleAddUserToList = (newValue: any) => {
    const newValues = [...newUser];

    for (const obj2 of newValue) {
      const existingObj = newValues.find(
        (obj1) =>
          (obj1?.userId && obj1?.userId === obj2?.userId) ||
          (obj1?.teamId && obj1?.teamId === obj2?.teamId)
      );
      if (!existingObj) {
        newValues.push(obj2);
      }
    }

    setNewUser(newValues);
    setValue([]);
  };

  if (!team) return null;

  const handleAddStudentToOrganization = async () => {
    const provisionResults = [...newStudent, ...newTeacher].map(
      async (item: any) => {
        if (item.newUser) {
          return new Promise((resolve, reject) =>
            axios
              .post(
                `${config.backend}/invite/provision?lang=${auth?.user?.lang}`,
                {
                  organisationId: auth?.user?.activeOrganisationId,
                  email: item.label,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  phone: item.phone,
                  role: item.role || 20,
                }
              )
              .then((result) => {
                resolve(
                  Object.assign({}, result?.data, {
                    key: result?.data.id,
                    role: item.role || 20,
                    newUser: true,
                  })
                );
              })
              .catch(reject)
          );
        } else {
          return Promise.resolve(item);
        }
      }
    );
    Promise.allSettled(provisionResults).then((results) => {
      const promises = [];
      for (const result of results) {
        if (result.status === 'fulfilled') {
          promises.push(
            new Promise((resolve) => {
              const minRole = result.value.role || 20;
              axios
                .post(`${config.backend}/members`, {
                  organisationId: organisationId,
                  userId: result.value.key,
                  role: result.value.role || 20,
                })
                .catch(() =>
                  axios.get(
                    `${config.backend}/member/${organisationId}/${result.value.key}`
                  )
                )
                .then((member) => {
                  if (minRole <= (member?.data?.role || 0)) return member;
                  return axios.put(
                    `${config.backend}/member/${organisationId}/${result.value.key}`,
                    {
                      role: minRole,
                    }
                  );
                })
                .then((member) => {
                  return axios
                    .post(
                      `${config.backend}/${
                        result.value.role === 40
                          ? 'teamteachers'
                          : 'teammembers'
                      }`,
                      {
                        teamId: teamId,
                        userId: result.value.key,
                        role: result.value.role,
                      }
                    )
                    .catch((e) => console.error(e));
                })
                .then(resolve);
            })
          );
        }
      }
      Promise.allSettled(promises).then(() => {
        navigate(-1);
      });
    });
  };

  const handleRemove = (id: any) => {
    const newList = newUser.filter((item: any) => item.key !== id);
    setNewUser(newList);
  };

  return (
    <div className="fixed z-10 flex h-full min-h-[100vh] min-w-[100vw] flex-col bg-white">
      <div className="flex min-h-16 items-center justify-start ">
        <IconButton
          className="my-auto mx-6"
          Icon={CrossFilled}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <div className="flex items-center">
          <TeamAvatar
            inline
            id={team.id}
            name={team.teamName || ''}
          ></TeamAvatar>
          <span>{t('pages.manage_add_users.add_users_to')}</span>
          <span className="pl-2 font-semibold">{team.teamName}</span>
        </div>
      </div>

      <div className="flex h-full flex-col border-t border-grey-02">
        <Stepper>
          <Stepper.Item
            isActive={step === 1}
            isDone={step > 1}
            onClick={() => setStep(1)}
            step="1"
          >
            {(!isMobile || step === 1) && (
              <T>{t('pages.manage_add_users.add_users_step_1')}</T>
            )}
          </Stepper.Item>
        </Stepper>
        <div className="h-full max-h-[calc(100vh_-_225px)] overflow-y-auto py-8">
          <div className="mx-auto flex max-w-screen-md flex-col justify-center px-3">
            <p className="text-base font-semibold">
              {t('pages.manage_add_users.add_student')}
            </p>
            <p className="text-[#7d7d81]">
              {t('pages.manage_add_users.subtitle')}
            </p>

            <UserInviteComplete
              extra={{ role: 20 }}
              fetch={useGet.bind(null, '/memberdetails', {
                organisationId: activeOrganisationId,
              })}
              newUser={newStudent}
              setNewUser={setNewStudent}
            />

            <p className="text-base font-semibold">
              {t('pages.manage_add_users.add_teacher')}
            </p>
            <p className="text-[#7d7d81]">
              {t('pages.manage_add_users.subtitle')}
            </p>

            <UserInviteComplete
              extra={{ role: 40 }}
              fetch={useGet.bind(null, '/memberdetails', {
                organisationId: activeOrganisationId,
              })}
              newUser={newTeacher}
              setNewUser={setNewTeacher}
            />
          </div>
        </div>

        <div className="mt-auto flex border-t border-grey-02 py-5">
          <div className="mx-auto flex w-full max-w-screen-md justify-end px-3">
            {
              <Button
                disabled={newStudent.length === 0 && newTeacher.length === 0}
                onClick={() => handleAddStudentToOrganization()}
                data-testId="addUsersButton"
              >
                {t('pages.manage_add_users.add_users_button')}
              </Button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUsersToTeamManagePage;

import { Slider } from '@teo/components';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LesoonExtraInfo } from '@/components/ExtraInfo/LesoonExtraInfo';

export const ImageZoom = (props) => {
  const {
    activeExtraInfo,
    extraInfoFullScreen,
    setImageZoom,
    imageZoom,
    onLoad,
  } = props;
  const [zoomValue, setZoomValue] = useState(false);
  const image = useRef();

  useEffect(() => {
    if (image.current.complete && onLoad) onLoad();
  }, []);

  useEffect(() => {
    setZoomValue(100);
  }, [imageZoom]);

  useEffect(() => {
    setImageZoom(false);
    setZoomValue(100);
  }, [extraInfoFullScreen]);

  useEffect(() => {
    if (imageZoom) {
      const img = document.getElementById('zoom-image');
      if (img) {
        img.style.transform = `scale(${zoomValue / 100})`;
      }
    }
  }, [zoomValue]);

  return (
    <div className="mx-auto">
      <img
        ref={image}
        src={activeExtraInfo?.assetPath}
        alt={activeExtraInfo?.assetName}
        className={`mx-auto max-h-[100vh] w-full object-contain ${
          extraInfoFullScreen &&
          (imageZoom ? 'cursor-zoom-out' : 'cursor-zoom-in')
        }`}
        onLoad={onLoad ? onLoad : undefined}
        onClick={() => extraInfoFullScreen && setImageZoom(!imageZoom)}
      />
      {imageZoom && (
        <div className="fixed inset-0 z-[20] flex h-full max-h-[100vh] w-full max-w-[100vw] overflow-auto bg-black/50 p-10">
          <img
            id="zoom-image"
            src={activeExtraInfo?.assetPath}
            alt={activeExtraInfo?.assetName}
            className={`mx-auto max-h-[100vh] w-full object-contain ${
              extraInfoFullScreen &&
              (imageZoom ? 'cursor-zoom-out' : 'cursor-zoom-in')
            }`}
            onLoad={onLoad ? onLoad : undefined}
            onClick={() => extraInfoFullScreen && setImageZoom(!imageZoom)}
          />
          <div className="absolute bottom-5 left-1/2 -translate-x-1/2 rounded-lg bg-white p-3">
            <Slider
              max={200}
              min={50}
              onSlide={(value) => setZoomValue(value)}
              step={5}
              value={zoomValue}
              withInput
            />
          </div>
        </div>
      )}
    </div>
  );
};

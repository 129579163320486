import { useGet } from '@/query/fetchHooks';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import {
  PersonAvatar,
  PageHeader,
  Button,
  Tag,
  Tabs,
  InputText,
  FilterList,
} from '@teo/components';
import { ArrowRight, Search } from '@teo/components/icons';
import { DataTable } from '@/components/DataTable/DataTable';
import { parseRole, newUid, toPercent, round2 } from '@/util.js';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import OptionsAction from '@/components/DataTable/OptionsAction';
import { axios } from '@/axios';
import { config } from '@/config';
import { AuthContext } from '@/components/Contexts/AuthContext';
import i18n from 'i18next';
import { Userwithorganisationwithidps } from '@/models';
import Hint from '@/components/Hints/Hints';
import { HintsContext } from '@/components/Contexts/HintsContext';

export interface Filter {
  label: string;
  value: string;
}

function UserDetailsPage({ student, setIsOpenAddContentModal, action }: any) {
  const { userId, organisationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hints } = useContext(HintsContext);
  const [tab, setTab] = useState('courses');
  const [contentRange, setContentRange] = useState(0);
  const [courseInpFilter, setCourseInpFilter] = useState('');

  const [resetCourseTable, setResetCourseTable] = useState(newUid(20));

  const [filterStatus, setFilterStatus] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<Filter[]>([
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
  ]);
  const [isFollow, setIsFollow] = useState(false);

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  useGet(`/trackuserdetails`, {
    organisationId: organisationId,
    userId: auth?.user?.id,
    studentId: userId,
    onSuccess: (result: any) => {
      result && result?.data?.length > 0 && setIsFollow(true);
    },
  });

  useEffect(() => {
    if (student && action) {
      axios.post(config.backend + '/activity', {
        userId: auth?.user?.id,
        subjectId: student.id,
        activityType: 'user',
        subject: student.username,
        link: window.location.pathname,
      });
    }
  }, [student]);

  const FILTERS_STATUS = [
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
    {
      label: t('pages.follow_up_courses.status_inactive'),
      value: 'disabled,hidden',
    },
  ];

  const coursePath =
    basePath.slice(0, basePath.lastIndexOf('/')) + '/courses/details';
  useEffect(() => {
    if (selectedStatus.length == 0) {
      setFilterStatus(null);
    }
    if (selectedStatus.length == 1) {
      setFilterStatus(`${selectedStatus[0].value}`);
    }
    if (selectedStatus.length > 1) {
      const select = selectedStatus?.map((el) => el.value);
      setFilterStatus(`${select}`);
    }
    setResetCourseTable(newUid(20));
  }, [selectedStatus]);

  if (!student) return null;

  const followedCoursePath =
    (auth?.user?.role || 0) >= 90
      ? '/followedcourses'
      : '/followedmembercourses';

  let followCourseType: any;
  const courseType = authUser?.useCourse;
  const assessmentType = authUser?.useAssessment;
  const vacancyType = authUser?.useLeervacature;

  const caseKey = `${courseType}-${assessmentType}-${vacancyType}`;

  switch (caseKey) {
    case 'false-true-true':
      followCourseType = 'assessment,OR,courseType=leervacature';
      break;
    case 'true-true-false':
      followCourseType = 'course,OR,courseType=assessment';
      break;
    case 'true-false-true':
      followCourseType = 'course,OR,courseType=leervacature';
      break;
    case 'true-false-false':
      followCourseType = 'course';
      break;
    case 'false-true-false':
      followCourseType = 'assessment';
      break;
    case 'false-false-true':
      followCourseType = 'leervacature';
      break;
    default:
      followCourseType =
        'course,OR,courseType=assessment,OR,courseType=leervacature';
      break;
  }

  return (
    <div className="max-w-6xl flex-col pb-8">
      <div className="pb-2 pt-6">
        {/*<PageHeader to={`${basePath}?tab=users`} ariaLabel={'placeholder'}>*/}
        <PageHeader /* @ts-expect-error eslint-disable-next-line */
          to={-1}
          ariaLabel={'placeholder'}
        >
          <div className="flex w-full items-center">
            <div className="flex items-center">
              <PersonAvatar
                inline
                id={student.id}
                name={student.username}
                imageSrc={student.picture}
              ></PersonAvatar>
            </div>

            <div className="mx-2 flex w-full flex-col justify-between gap-4 xl:mx-4 xl:flex-row">
              <div className="flex flex-col content-center">
                <h1 className="break-all pb-1.5 text-lg font-semibold text-black sm:text-xl">
                  {student.username}
                </h1>
                <div className="flex flex-wrap content-center text-xs text-[#919193]">
                  <p className="mr-2 border-r border-[#919193] pr-2">
                    {t(parseRole(student.role))}
                  </p>
                  <span>
                    <Link
                      className="inline-block pr-2 text-xs text-tint-blue hover:underline"
                      to="../#"
                    >
                      {student.email}
                    </Link>
                  </span>
                </div>
              </div>

              <div className="flex flex-wrap items-center gap-3">
                <div
                  className={`${
                    hints?.id == 'hint_16' && 'active-hint-parent'
                  }`}
                >
                  <Button
                    variant="outline"
                    size="md"
                    containsOnlyIcon={true}
                    className="relative"
                    onClick={async () => {
                      if (isFollow) {
                        await axios.delete(
                          `${config.backend}/trackuser/${auth?.user?.id}/${student.id}`
                        );
                        setIsFollow(!isFollow);
                      } else {
                        await axios.post(`${config.backend}/trackusers`, {
                          userId: auth?.user?.id,
                          studentId: student.id,
                        });
                        setIsFollow(!isFollow);
                      }
                    }}
                  >
                    <Hint
                      key={`hint_16`}
                      id={'hint_16'}
                      leftDots={'-left-2 leading-[26px] top-1.5'}
                      title={t('hint.title_16')}
                      order={16}
                      style={{
                        right: '-10px',
                        top: '60px',
                      }}
                    />
                    {isFollow ? (
                      <img
                        src="/images/components/follow_up_courses/star_icon.svg"
                        alt="star_icon"
                        className="h-5"
                      />
                    ) : (
                      <img
                        src="/images/components/follow_up_courses/star_white.svg"
                        alt="star_icon"
                        className="h-5"
                      />
                    )}
                  </Button>
                </div>

                {Math.max(
                  authUser?.role || 0,
                  authUser?.activeOrganisationRole || 0
                ) >= 90 && (
                  <div className="">
                    <Button
                      onClick={() => {
                        navigate(
                          `/${i18n.language}/manage/${organisationId}/users/${userId}`
                        );
                      }}
                      // to="../#"
                      variant="outline"
                      size="md"
                      className="flex items-center"
                    >
                      {t('pages.follow_up_users.manage_user')}
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </Button>
                  </div>
                )}

                <div
                  className={`${
                    hints?.id == 'hint_17' && 'active-hint-parent'
                  }`}
                >
                  <Button
                    onClick={() => setIsOpenAddContentModal(true)}
                    size="md"
                    className="relative"
                  >
                    <Hint
                      key={`hint_17`}
                      id={'hint_17'}
                      leftDots={'-left-2 leading-[26px] top-1.5'}
                      title={t('hint.title_17')}
                      order={17}
                      style={{
                        right: '-10px',
                        top: '60px',
                      }}
                    />
                    {t('pages.follow_up_users.add_to_content')}
                  </Button>
                </div>
                {/* <div className="pl-2">
                  <IconButton
                    Icon={MoreVertical}
                    variant="ghost"
                    className="text-[#919193]"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </PageHeader>

        <div className="mt-6 mb-6 flex">
          <p>{t('pages.follow_up_users.teams')}</p>

          <div className="ml-2">
            <Tag title={student.teamNames?.join(', ')} size="md" />
          </div>
        </div>

        <div className="my-6 flex flex-wrap justify-between gap-2 rounded-lg border border-grey-02 py-7 px-9">
          {authUser?.useCourse && (
            <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
              <span className="text-base font-semibold">
                {student.nrOfMemberCourses}
              </span>
              <span className="text-xs font-normal text-[#7d7d81]">
                {t('pages.follow_up_users.active_courses')}
              </span>
            </div>
          )}
          {authUser?.useAssessment && (
            <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
              <span className="text-base font-semibold">
                {student.nrOfMemberAssessments}
              </span>
              <span className="text-xs font-normal text-[#7d7d81]">
                {t('pages.follow_up_users.active_assessments')}
              </span>
            </div>
          )}
          {authUser?.useLeervacature && (
            <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
              <span className="text-base font-semibold">
                {student.nrOfMemberLearningVacancies}
              </span>
              <span className="text-xs font-normal text-[#7d7d81]">
                {t('pages.follow_up_users.active_vacancies')}
              </span>
            </div>
          )}
          {/* {authUser?.useInstruction && (
            <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
            <span className="text-base font-semibold">
              {student.nrOfMemberLearningVacancies}
            </span>
            <span className="text-xs font-normal text-[#7d7d81]">
              {t('pages.follow_up_users.active_vacancies')}
            </span>
          </div>
          )} */}
        </div>
      </div>
      <div className="mb-6 mt-3">
        <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
          <Tabs.TabButton name="courses">
            {t('pages.follow_up_users.courses')}
            <span className="ml-2 text-base text-[#919193]">
              {contentRange ? contentRange : 0}
            </span>
            {tab === 'courses' && (
              <span className="ml-2 h-2 w-2 rounded-full bg-[#fc6232]"></span>
            )}
          </Tabs.TabButton>
          {/*           
          <Tabs.TabButton name="growth_tracks">
            {t('pages.follow_up_users.growth_tracks')}
            <span className="ml-2 text-base text-[#919193]">4</span>
          </Tabs.TabButton>
          */}
        </Tabs>
      </div>
      {tab === 'courses' && (
        <>
          <div className="pb-4 pt-2 md:w-1/2 xl:w-1/3">
            <InputText
              value={courseInpFilter}
              onChange={(ev) => setCourseInpFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.course')}
            />
          </div>

          <div className="z-[21] flex flex-col pb-4 sm:flex-row">
            <span className="pr-2">
              <FilterList
                filters={FILTERS_STATUS}
                onSelect={setSelectedStatus}
                selected={selectedStatus}
              />
            </span>
          </div>

          <DataTable
            filter={courseInpFilter}
            key={resetCourseTable}
            setContentRange={setContentRange}
            rowPath={(course) => `${coursePath}/${course.id}`}
            defaultSort={[
              { id: 'updatedAt', desc: false },
              { id: 'courseName', desc: false },
            ]}
            fetch={useGet.bind(
              null,
              (authUser?.role || 0) >= 90
                ? '/followedcourses'
                : '/myfollowedmembercourses',
              {
                userId: (authUser?.role || 0) >= 90 ? userId : authUser?.id,
                studentId: userId,
                organisationId: organisationId,
                courseStatus: filterStatus,
                courseType: followCourseType,
              }
            )}
            empty={{
              template: 2,
              text: t('empty_tenplate.course_list'),
              btn_text: t('pages.follow_up_users.add_to_content'),
              btnClick: () => setIsOpenAddContentModal(true),
              // btn_path: userPath,
            }}
            screensUpdateTable="desktop"
            columns={[
              {
                title: t('columns.courseName'),
                id: 'courseName',
                className: 'col-start-1 col-end-9',
                render: (course: any) => (
                  <div className="flex items-center">
                    <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                      <img
                        src={course?.courseImage || course?.image || '/images/courseFallback.png'}
                        alt={course.courseName || course.name}
                        className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                      />
                    </div>
                    {course.progress > 0.99 ? (
                      <div className="ml-3 break-all font-medium text-[#919193]">
                        {course.courseName || course.name}
                      </div>
                    ) : (
                      <div className="ml-3 break-all font-medium">
                        {course.courseName || course.name}
                      </div>
                    )}
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-1 col-end-4 row-start-2',
                id: 'followsCreatedAt',
                title: t('columns.added'),
                align: 'right',
                render: (course: any) => (
                  <div>
                    {course.progress > 0.99 ? (
                      <div className="text-[#919193]">
                        <div>
                          {format(
                            new Date(course.followsCreatedAt),
                            'dd/MM/yyyy'
                          )}
                        </div>
                        {/* to do: add team info */}
                        {/* <div className="text-xs font-normal text-[#7d7d81]">
                        Onderhoud groep A
                      </div> */}
                      </div>
                    ) : (
                      <div>
                        <div>
                          {format(
                            new Date(course.followsCreatedAt),
                            'dd/MM/yyyy'
                          )}
                        </div>
                        {/* to do: add team info */}
                        {/* <div className="text-xs font-normal text-[#7d7d81]">
                        Onderhoud groep A
                      </div> */}
                      </div>
                    )}
                  </div>
                ),
              },
              {
                id: 'startDate',
                title: t('columns.started'),
                align: 'right',
                addTitleOnMobile: true,
                className:
                  'col-start-4 col-end-7 row-start-2 justify-center text-center',
                render: (course: any) =>
                  course.progress === 0 ? (
                    <p className="text-[#fc6232]">
                      {t('pages.follow_up_users.not_started_yet')}
                    </p>
                  ) : (
                    <div>
                      {course.progress > 0.99 ? (
                        <div className="text-[#919193]">
                          {format(new Date(course.startDate), 'dd/MM/yyyy')}
                        </div>
                      ) : (
                        <div>
                          {format(new Date(course.startDate), 'dd/MM/yyyy')}
                        </div>
                      )}
                    </div>
                  ),
              },
              {
                id: 'followsUpdatedAt',
                title: t('columns.last_activity'),
                align: 'right',
                addTitleOnMobile: true,
                className:
                  'col-start-7 col-end-10 row-start-2 text-right justify-end',
                render: (course: any) =>
                  course.progress === 0 ? (
                    <p>-</p>
                  ) : (
                    <div>
                      {course.progress > 0.99 ? (
                        <div className="text-[#919193]">
                          {format(
                            new Date(course.followsUpdatedAt || course.endDate),
                            'dd/MM/yyyy'
                          )}
                        </div>
                      ) : (
                        <div>
                          {format(
                            new Date(course.followsUpdatedAt || course.endDate),
                            'dd/MM/yyyy'
                          )}
                        </div>
                      )}
                    </div>
                  ),
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-1 col-end-4 row-start-3',
                id: 'progress',
                title: t('columns.progress'),
                align: 'right',
                render: (course: any) => (
                  <div>
                    {course.progress > 0.99 ? (
                      <div className="text-[#919193]">
                        {Math.round(course.progress * 100)}%
                      </div>
                    ) : (
                      <div>{Math.round(course.progress * 100)}%</div>
                    )}
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                className:
                  'col-start-4 col-end-7 row-start-3 justify-center text-center',
                id: 'scoreRatio',
                title: t('columns.score'),
                align: 'right',
                render: (course: any) => (
                  <div>
                    {!course?.maxScore ? (
                      <Tag
                        size="sm"
                        variant="success"
                        title={t('pages.follow_up_courses.score_status')}
                      ></Tag>
                    ) : (
                      <span
                        className={`${
                          +toPercent(course.score, course.maxScore) < 50
                            ? 'text-error-05'
                            : +toPercent(course.score, course.maxScore) > 49 &&
                              +toPercent(course.score, course.maxScore) <
                                course?.minScore * 100
                            ? 'text-warning-04'
                            : 'text-success-05'
                        }`}
                      >
                        {toPercent(course.score, course.maxScore)
                          ? toPercent(course.score, course.maxScore)
                          : 0}
                        % ({round2(course.score ? course.score : 0)}/
                        {round2(course.maxScore)})
                      </span>
                    )}
                  </div>
                ),
              },
              // todo implement notification/reminder
              // {
              //   id: 'button',
              //   sortable: false,
              //   title: '',
              //   align: 'right',
              //   className: 'col-start-4 col-end-10 row-start-3  justify-end',
              //   render: (course) => (
              //     <div>
              //       {course.startDate > format(new Date(), 'dd/MM/yy') && (
              //         <Button onClick={() => {}} size="md" variant="outline">
              //           {t('pages.follow_up_users.send_reminder')}
              //         </Button>
              //       )}
              //     </div>
              //   ),
              // },
              {
                id: 'actionButton',
                sortable: false,
                title: '',
                className: 'col-start-9 row-start-1 justify-end',
                render: (course: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        id: '1',
                        title: t('pages.follow_up_users.share_settings'),
                        onClick: (ev) => {
                          const resultPath = `/${i18n.language}/follow-up/${organisationId}/result/${course?.id}/${student?.userId}/lesson`;
                          navigate(resultPath + '?details=user');
                        },
                      },
                      {
                        addOnMobile: true,
                        itemDelete: true,
                        id: '3',
                        onClick: async (ev) => {
                          await axios.delete(
                            `${config.backend}/follow/${course?.id}/${student?.userId}/`
                          );
                          setResetCourseTable(newUid(20));
                        },
                        title: t(
                          'pages.follow_up_users.remove_user_from_course'
                        ),
                      },
                    ]}
                    pageDetail={{
                      title: t('pages.follow_up_users.share_settings'),
                      rowPath: (course) => `${coursePath}/${course.id}`,
                    }}
                    screensUpdateTable="desktop"
                    rowInfo={course}
                    mobileHeader={{
                      render: (course: any) => (
                        <div className="flex items-center">
                          <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                            <img
                              src={
                                course.courseImage ||
                                '/images/courseFallback.png'
                              }
                              alt={course.name}
                              className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                            />
                          </div>
                          {Math.round(course.progress * 100) === 99 ? (
                            <div className="ml-3 font-medium text-[#919193]">
                              {course.courseName}
                            </div>
                          ) : (
                            <div className="ml-3 font-medium">
                              {course.courseName}
                            </div>
                          )}
                        </div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </>
      )}
      {tab === 'growth_tracks' && <div>growth_tracks</div>}
    </div>
  );
}

export default UserDetailsPage;

import { useEffect, useRef } from 'react';
import { Button, IconButton, Dropdown } from '@teo/components';
import { Document, Image, Play, Plus } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import ExtraInfoCustonNode from './ExtraInfoCustonNode';

import { DndProvider } from 'react-dnd';
import { Tree, MultiBackend, getBackendOptions } from 'react-dnd-treeview';
import { put } from '@/query/fetchHooks';
import styles from '../LessonTree/App.module.css';
import { Placeholder } from '../LessonTree/Placeholder';

const NewTreeList = ({
  activeNode,
  treeRef,
  extraInfoTree,
  setExtraInfoTree,
  setLessons,
  handleDelete,
  setActiveNode,
  setMobileSidebar,
  setExtraInfoEdit,
  extraInfoEdit,
  saveParentNode,
  isMobile,
  setNewActiveExtra,
  newActiveExtra,
  isFroalaLoaded,
}) => {
  return (
    <Tree
      ref={treeRef}
      initialOpen={true}
      tree={extraInfoTree}
      rootId={0}
      render={(node, { depth, isOpen, onToggle }) => (
        <ExtraInfoCustonNode
          isMobile={isMobile}
          active={activeNode && activeNode.id === node.id}
          node={node}
          setNewActiveExtra={setNewActiveExtra}
          newActiveExtra={newActiveExtra}
          handleDelete={handleDelete}
          setLessons={setLessons}
          setTree={setExtraInfoTree}
          depth={depth}
          isOpen={isOpen}
          onToggle={onToggle}
          onClick={() => {
            saveParentNode();
            setExtraInfoEdit(true);
            window.scrollTo(0, 0);
            setActiveNode(node);
            setMobileSidebar(false);
          }}
          extraInfoEdit={extraInfoEdit}
          isFroalaLoaded={isFroalaLoaded}
        />
      )}
      dragPreviewRender={(monitorProps) => {
        const width = monitorProps.item.ref.current?.firstChild?.offsetWidth;
        return (
          <ExtraInfoCustonNode
            node={monitorProps.item}
            className="preview -translate-y-[50%] bg-white"
            preview
            style={{
              width: `${width}px`,
              boxShadow: '0 12px 24px -6px rgba(0, 0, 0, 0.25)',
            }}
          />
        );
      }}
      onDrop={(
        treeData,
        { dropTargetId, dragSource, dropTarget, destinationIndex }
      ) => {
        const siblings = treeData.filter(
          (x) => x.parent === treeData[destinationIndex || 0].parent
        );
        for (const i in siblings) {
          const sibling = siblings[i];
          const yindex = parseFloat(i) + 1;

          if (yindex === sibling.data.yindex) continue;
          sibling.data.yindex = yindex;
          put(`/use/${sibling.data.usesId}`, {
            yindex,
          });
        }
        setExtraInfoTree(treeData);
        setTimeout(() => {
          treeRef.current.open(dropTargetId);
        });
      }}
      classes={{
        root: styles.treeRoot,
        draggingSource: styles.draggingSource,
        placeholder: styles.placeholderContainer,
      }}
      sort={false}
      insertDroppableFirst={false}
      canDrop={(tree, { dragSource, dropTargetId }) => {
        if (dragSource?.parent === dropTargetId) {
          return true;
        }
      }}
      dropTargetOffset={5}
      placeholderRender={(node, { depth }) => (
        <Placeholder node={node} depth={depth} />
      )}
    />
  );
};

const ExtraInfoList = ({
  activeNode,
  extraInfoLessons,
  handleNewExtraIndo,
  handleDelete,
  setLessons,
  setAddLesson,
  parentNode,
  handleOpenLibrary,
  setExtraInfoTree,
  extraInfoTree,
  setActiveNode,
  setParentNode,
  setMobileSidebar,
  setExtraInfoEdit,
  extraInfoEdit,
  setShowExtraInfo,
  isMobile,
  fileInputRef,
  fileInputVideoRef,
  setIsOpenLibraryModal,
  setNewActiveExtra,
  newActiveExtra,
  isAddButtonBottom,
  isFroalaLoaded,
}) => {
  const { t } = useTranslation();
  const treeRef = useRef(null);

  useEffect(() => {
    if (extraInfoLessons) {
      const newTree = extraInfoLessons?.map((les) => ({
        id: les.usesId,
        parent: 0,
        droppable: les?.lessonId || les?.assetId ? false : true,
        text: les?.name || les?.assetName || 'Broken node',
        isOpen: true,
        typeLes: les?.lessonId ? true : false,
        data: les,
      }));

      setExtraInfoTree(
        newTree?.sort((a, b) => a?.data?.yindex - b?.data?.yindex)
      );
    }
  }, [extraInfoLessons]);

  const saveParentNode = () => {
    if (!extraInfoEdit) {
      setParentNode(parentNode);
      setShowExtraInfo(false);
    }
  };

  if (!extraInfoTree) return null;

  return (
    <div id="extra-lessons-list" className="px-2">
      <div className="flex flex-col gap-5">
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <NewTreeList
            activeNode={activeNode}
            treeRef={treeRef}
            setAddLesson={setAddLesson}
            extraInfoTree={extraInfoTree}
            setExtraInfoTree={setExtraInfoTree}
            setLessons={setLessons}
            handleDelete={handleDelete}
            setActiveNode={setActiveNode}
            setMobileSidebar={setMobileSidebar}
            setExtraInfoEdit={setExtraInfoEdit}
            extraInfoEdit={extraInfoEdit}
            saveParentNode={saveParentNode}
            isMobile={isMobile}
            setNewActiveExtra={setNewActiveExtra}
            newActiveExtra={newActiveExtra}
            isFroalaLoaded={isFroalaLoaded}
          />
        </DndProvider>
      </div>
      <div className="my-3 flex gap-2 pl-8">
        <Dropdown>
          <Dropdown.Trigger>
            <IconButton
              Icon={Plus}
              variant="secondary"
              className="mr-2 bg-grey-02 text-[#231f20]"
            />
          </Dropdown.Trigger>
          <Dropdown.Content
            style={{
              top: isAddButtonBottom ? 'auto' : '25px',
              bottom: isAddButtonBottom ? '50px' : 'auto',
              left: '0',
            }}
          >
            <Dropdown.Actions>
              <Dropdown.Button
                onClick={() => {
                  handleNewExtraIndo();
                }}
              >
                <IconButton
                  Icon={Document}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.new_lesson')}</p>
              </Dropdown.Button>

              <Dropdown.Button
                onClick={() => {
                  handleOpenLibrary();
                }}
              >
                <IconButton
                  Icon={Document}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.les_library')}</p>
              </Dropdown.Button>
              <Dropdown.Button onClick={() => setIsOpenLibraryModal(true)}>
                <IconButton
                  Icon={Image}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.upload_img')}</p>
              </Dropdown.Button>
              <Dropdown.Button
                onClick={() => {
                  fileInputVideoRef?.current?.click();
                }}
              >
                <IconButton
                  Icon={Play}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.upload_video')}</p>
              </Dropdown.Button>
            </Dropdown.Actions>
          </Dropdown.Content>
        </Dropdown>
        <Button
          variant="outline"
          onClick={() => {
            fileInputRef?.current?.click();
          }}
        >
          {t('extra_info.btn_upload')}
        </Button>
      </div>
    </div>
  );
};

export default ExtraInfoList;

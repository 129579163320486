import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { IconButton, Stepper } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useMobile } from '@/util';
import StepBasicSettings from './StepBasicSettings';
import StepPublishToLibrary from './StepPublishToLibrary';
import StepAddVersion from './StepAddVersion';
import StepUpdateContent from './StepUpdateContent';

const PublishCourse = ({
  publication,
  data,
  setIsOpenPublish,
  setIsPiblished,
  publicationType,
  contentCreators,
  publicationName,
  publicationImage,
}: any) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [step, setStep] = useState(1);
  const [globalState, setGlobalState] = useState<any>({});

  const [professions, setProfessions] = useState([]);
  const [prevContentCreators, setPrevContentCreators] = useState<any>([]);
  const [prevContentOrganisation, setPrevContentOrganisation] = useState<any>(
    []
  );

  return (
    <div
      style={{ zIndex: 102 }}
      className="fixed top-0 left-0 z-10 h-screen w-screen overflow-y-auto bg-white"
    >
      <div className="flex min-h-20 flex-col justify-start py-2 pr-2 sm:flex-row sm:items-center">
        <div
          data-testid="clouseBtn"
          className="flex min-h-20 items-center justify-start"
        >
          <IconButton
            className="my-auto mx-3 sm:mx-6"
            Icon={CrossFilled}
            variant="ghost"
            onClick={() => {
              setIsOpenPublish(false);
            }}
          />
          <div className="flex items-center">
            <div className="mr-5 w-20 min-w-20">
              <img
                src={publication?.image || '/images/courseFallback.png'}
                alt="courseFallback"
                className="rounded-lg"
              />
            </div>
            <div className="flex flex-wrap">
              <span className="pr-2">
                {t(
                  `pages.create_edit_course.type_${publicationType}.publish_course_header`
                )}
              </span>
              <span className="font-semibold">{publication?.name}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full max-h-[calc(100vh_-_96px)] border-t border-grey-02">
        <Stepper>
          <Stepper.Item
            isActive={step === 1}
            isDone={step > 1}
            onClick={() => setStep(1)}
            step="1"
          >
            {!isMobile && <>{t('pages.publish_course.steps_1.title')}</>}
          </Stepper.Item>
          {/* <Stepper.Item
            isActive={step === 2}
            isDone={step > 2}
            onClick={() => setStep(2)}
            step="2"
          >
            {!isMobile && <>{t('pages.publish_course.steps_2.title')}</>}
          </Stepper.Item> */}
          <Stepper.Item
            isActive={step === 2}
            isDone={step > 2}
            onClick={() => setStep(2)}
            step="2"
          >
            {!isMobile && <>{t('pages.publish_course.steps_3.title')}</>}
          </Stepper.Item>
          <Stepper.Item
            isActive={step === 3}
            isDone={step > 3}
            onClick={() => setStep(3)}
            step="3"
          >
            {!isMobile && <>{t('pages.publish_course.steps_4.title')}</>}
          </Stepper.Item>
        </Stepper>
        <div className="flex h-full max-h-[calc(100vh_-_180px)] flex-col">
          {step === 1 && (
            <StepBasicSettings
              publication={publication}
              data={data}
              setStep={setStep}
              setGlobalState={setGlobalState}
              globalState={globalState}
              setProfessions={setProfessions}
              publicationType={publicationType}
            />
          )}
          {/* {step === 2 && (
            <StepPublishToLibrary
              publication={publication}
              data={data}
              setStep={setStep}
              setGlobalState={setGlobalState}
              globalState={globalState}
              setPrevContentCreators={setPrevContentCreators}
              prevContentOrganisation={prevContentOrganisation}
              setPrevContentOrganisation={setPrevContentOrganisation}
            />
          )} */}
          {step === 2 && (
            <StepAddVersion
              setStep={setStep}
              setGlobalState={setGlobalState}
              globalState={globalState}
              publication={publication}
              publicationType={publicationType}
            />
          )}
          {step === 3 && (
            <StepUpdateContent
              setStep={setStep}
              setGlobalState={setGlobalState}
              globalState={globalState}
              publication={publication}
              professions={professions}
              prevContentCreators={prevContentCreators}
              prevContentOrganisation={prevContentOrganisation}
              setIsOpenPublish={setIsOpenPublish}
              setIsPiblished={setIsPiblished}
              publicationType={publicationType}
              contentCreators={contentCreators}
              publicationName={publicationName}
              publicationImage={publicationImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PublishCourse;

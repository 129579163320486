import i18n from 'i18next';
import { useState, useContext, useEffect } from 'react';
import { axios } from '@/axios';
import { ArrowLeft, ChapterFilled } from '@teo/components/icons';
import { Button } from '@teo/components';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { TutorialTree } from './TutorialTree';
import { CourseInfo } from '@/pages/Protected/Learn/Courses/CourseInfo';

function Tutorial() {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [course, setCourse] = useState<any>(null);
  const [courseInfo, setCourseInfo] = useState<any>(null);
  const [courseId, setCourseId] = useState<any>(null);
  const [courseLink, setCourseLink] = useState<any>(null);
  const [chapters, setChapters] = useState<any>(null);
  const [numLesAs, setNumLesAs] = useState<any>(0);

  const getSupportIdCourse = () => {
    let supportIdCourse;
    let supportLink;
    switch (i18n.language) {
      case 'en':
        supportIdCourse = '2628';
        supportLink = 'https://teo.training/en/preview/2628/lesson/29068';
        break;
      case 'fr':
        supportIdCourse = '2640';
        supportLink = 'https://teo.training/fr/preview/2640/lesson/29093';
        break;
      case 'es':
        supportIdCourse = '2628';
        supportLink = 'https://teo.training/en/preview/2628/lesson/29068';
        break;
      case 'nl':
      default:
        supportIdCourse = '2639';
        supportLink = 'https://teo.training/nl/preview/2639/lesson/28547';
        break;
    }
    return {
      supportIdCourse,
      supportLink,
    };
  };

  useEffect(() => {
    const support = getSupportIdCourse();
    setCourseId(support.supportIdCourse);
    setCourseLink(support.supportLink);
  }, []);

  useEffect(() => {
    if (courseId) {
      axios
        .get<any>(`https://teo.training/api/coursedetail/${courseId}`)
        .then((response) => {
          setCourse(response.data);
        });

      axios
        .get<any>(`https://teo.training/api/courseinfo/${courseId}`)
        .then((response) => {
          setCourseInfo(response.data);
        });
    }
  }, [courseId]);

  return (
    <>
      <h1 className="my-8 text-xl font-semibold text-black">
        {i18n.t('pages.tutorial.title')}
      </h1>
      <div className="w-full max-w-6xl flex-col">
        <div className="mt-6 mb-4">
          <img
            src={courseInfo?.picture || course?.courseImage || course?.image || '/images/courseFallback.png'}
            className="max-h-80 w-full rounded-md object-cover"
          />
        </div>
        <div>
          <h1 className="mb-4 text-xl">
            {course?.courseName || course?.name || ''}
          </h1>

          <p
            className="text-sm text-grey-08 sm:text-base"
            dangerouslySetInnerHTML={{
              __html:
                course?.hasCustomLanding && courseInfo?.abstract
                  ? courseInfo?.abstract
                  : course?.description,
            }}
          />
        </div>
        <div className="my-4 flex items-center gap-4">
          <span className="text-sm text-grey-04">
            <ChapterFilled className="inline h-4 w-4" />
            {chapters ? (
              <span className="pr-2 text-sm text-grey-04">
                {chapters} {i18n.t('pages.course_landing.chapters')},
              </span>
            ) : null}
            {numLesAs ? numLesAs : 0} {i18n.t('pages.course_landing.lessons')}
          </span>
        </div>
        <div className="text-center sm:text-left">
          {auth && (
            <Button
              onClick={() => window.open(courseLink, '_blank')}
              size="md"
              className="w-full max-w-xs sm:w-auto"
            >
              {i18n.t('pages.tutorial.btn_go')}
            </Button>
          )}
        </div>
        <div>
          {auth && (
            <>
              <p className="mt-7 mb-4 text-base font-medium">
                {i18n.t('pages.course_landing.contents')}
              </p>
              {course && (
                <TutorialTree
                  coursecontentId={course?.coursecontentId}
                  courseId={courseId}
                  setNumLesAs={setNumLesAs}
                  setChapters={setChapters}
                />
              )}
            </>
          )}
          {courseInfo && <CourseInfo courseInfo={courseInfo} />}
        </div>
      </div>
    </>
  );
}

export default Tutorial;

import { IconPickerOption } from '@/models/iconPickerOption.interface';

export const iconPickerOptionsData: IconPickerOption[] = [
  {
    id: 1,
    label: 'Elektriciteit',
    icon: 'elektriciteit',
  },
  {
    id: 2,
    label: 'Techniek',
    icon: 'techniek',
  },
  {
    id: 3,
    label: 'Veiligheid',
    icon: 'veiligheid',
  },
  {
    id: 4,
    label: 'Chemie',
    icon: 'chemie',
  },
  {
    id: 5,
    label: 'Informatica',
    icon: 'informatica',
  },
  {
    id: 6,
    label: 'Milieu',
    icon: 'milieu',
  },
  {
    id: 7,
    label: 'Pneumatica',
    icon: 'pneumatica',
  },
  {
    id: 8,
    label: 'Mechanica',
    icon: 'mechanica',
  },
  {
    id: 9,
    label: 'Management',
    icon: 'management',
  },
];

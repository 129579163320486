import { useGet } from '@/query/fetchHooks';
import { Usedlessondetails, Directories } from '@/models/Database';
import { buildSortedTree, buildTreeFlat, simplifyPath, walk } from '@/util';
import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  ChevronUp,
  ChevronDown,
  ArrowRight,
  PlayFilled,
  Image,
  Play,
} from '@teo/components/icons';

const TreeItem = ({
  node,
  indent = 0,
  index,
  currentLessonId,
  activeLessonNode,
  setActiveNode,
  setHideContent,
  setIsLoaded,
}: any) => {
  const [isOpen, setIsOpen] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 640px)' });
  const spacing = isDesktopOrLaptop ? 53 : 40;
  const handleLesson = () => {
    if (currentLessonId != node?.id) {
      setIsLoaded(true);
    }
    setActiveNode(node);
    setHideContent(false);
  };

  return (
    <div>
      <div
        data-path={
          node?.lessonId || node?.assetId
            ? `${node?.simplifiedPath?.slice(1)}_${
                node?.lessonId || node?.assetId
              }_${node?.usesId}.html`
            : undefined
        }
        className={`my-1 flex items-center justify-between ${
          node.onlyTheory ? 'onlyTheory' : ''
        }`}
      >
        <div
          className="flex min-h-[40px] w-full items-center "
          style={{ paddingLeft: `${indent * spacing}px` }}
        >
          {(!node?.lessonId && !node?.assetId) || indent === 0 ? (
            <div
              className={`flex w-full cursor-pointer items-center rounded-md p-1 sm:p-2  ${
                (node?.lessonId || node?.assetId) &&
                activeLessonNode?.usesId === node?.usesId
                  ? 'bg-primary-03'
                  : 'sm:hover:bg-grey-transparent-01'
              }`}
              onClick={() =>
                node.lessonId || node?.assetId
                  ? handleLesson()
                  : setIsOpen(!isOpen)
              }
              data-testid="chapter"
            >
              <div className="relative mr-2 flex h-8 w-8 rounded-full border-2 border-[#1e1e2014] pl-0.5 text-center sm:mr-4 sm:h-9 sm:w-9">
                <PlayFilled className="m-auto w-4 text-secondary-04" />
              </div>

              <p
                className={`w-full max-w-[180px] flex-1 pr-2 text-xs font-medium text-black sm:text-sm ${
                  activeLessonNode?.usesId === node?.usesId && 'font-semibold'
                }`}
              >
                {node?.numbered && (
                  <span className="pr-2 sm:pr-4">{node?.order}.</span>
                )}
                {node.name || node.assetName}
              </p>
              {!node.lessonId &&
                !node?.assetId &&
                (isOpen ? (
                  <ChevronUp
                    className={`w-6 p-2 pr-0 !text-grey-06 sm:w-9 sm:pr-2`}
                  />
                ) : (
                  <ChevronDown
                    className={`w-6 p-2 pr-0 !text-grey-06 sm:w-9 sm:pr-2`}
                  />
                ))}
            </div>
          ) : (
            <div
              className={`flex w-full cursor-pointer items-center justify-between rounded-md p-1 sm:p-2 ${
                activeLessonNode?.usesId === node?.usesId
                  ? 'bg-primary-03'
                  : 'sm:hover:bg-grey-transparent-01'
              }`}
              onClick={() => handleLesson()}
            >
              <p
                className={`false w-full max-w-[180px] flex-1 text-sm font-normal text-grey-08  ${
                  activeLessonNode?.usesId === node?.usesId &&
                  'font-semibold !text-black'
                }`}
              >
                {node?.numbered && (
                  <span className="pr-2 sm:pr-4">{node?.order}.</span>
                )}

                {node.name || node.assetName}
              </p>

              <ArrowRight
                className={` w-6 pl-2 pr-0 text-grey-05 sm:w-9 sm:w-9 sm:pr-2`}
              />
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div>
          {node.children &&
            node.children.map((x: any, subIndex: number) => (
              <TreeItem
                key={x.id}
                node={x}
                indent={indent + 1}
                index={subIndex}
                currentLessonId={currentLessonId}
                activeLessonNode={activeLessonNode}
                setActiveNode={setActiveNode}
                setHideContent={setHideContent}
                setIsLoaded={setIsLoaded}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export const PreviewTree = ({
  coursecontentId,
  instructions,
  courseId,
  lessonId,
  activeNode,
  setActiveNode,
  setHideContent,
  setAllExtraInfo,
  setRootCourseId,
  setAllDirectories,
  setNextLesson,
  setPreviousLesson,
  setIsLoaded,
}: {
  courseId?: number | string | undefined;
  coursecontentId: number | string;
  lessonId?: number | string | undefined;
  instructions?: boolean;
  activeNode?: any;
  setActiveNode?: any;
  setHideContent?: any;
  setAllExtraInfo?: any;
  setRootCourseId?: any;
  setAllDirectories?: any;
  setNextLesson?: any;
  setPreviousLesson?: any;
  setIsLoaded?: any;
}) => {
  const [tree, setTree] = useState<any>(null);
  const [treeFlat, setTreeFlat] = useState<any>(null);
  const [lessons, setLessons] = useState<any>(null);
  const [directories, setDirectories] = useState<any>(null);

  let lessonPath = '/usedlessonassets';
  if (courseId) lessonPath = '/usedcourselessonassets';

  const lessonResult = useGet<Array<Usedlessondetails>>(lessonPath, {
    enabled: !!coursecontentId,
    count: 10000,
    courseId,
    coursecontentId,
  });
  const directoriesResult = useGet<Array<Directories>>('/directories', {
    enabled: !!coursecontentId,
    count: 10000,
    coursecontentId,
  });

  useEffect(() => {
    if (lessonResult?.data && directoriesResult?.data) {
      const resLessons: Array<Usedlessondetails> | undefined =
        lessonResult?.data?.data || undefined;
      const resDirectories: Array<Directories> | undefined =
        directoriesResult?.data?.data || undefined;
      setLessons(resLessons);
      setDirectories(resDirectories);
    }
  }, [lessonResult?.data, directoriesResult?.data]);

  useEffect(() => {
    if (lessons && directories) {
      const courseLessons = lessons.filter(
        (les: any) => !les?.rootLessonId && !les?.rootDirectoryId
      );
      setAllExtraInfo(
        lessons.filter((les: any) => les?.rootLessonId || les?.rootDirectoryId)
      );
      setRootCourseId(directories?.find((el: any) => el.path === '/'));
      setAllDirectories(directories?.filter((el: any) => el.path !== '/'));
      const newTree = buildSortedTree(courseLessons, directories);
      setTree(newTree);
      const [newTreeFlat] = buildTreeFlat(courseLessons, directories) as any;
      setTreeFlat(newTreeFlat);
    }
  }, [lessons, directories]);

  useEffect(() => {
    if (treeFlat && !activeNode) {
      if (treeFlat?.length > 0) {
        const queryParams = new URLSearchParams(window.location.search);
        const activeLes: any = queryParams.get('les');

        if (activeLes) {
          setActiveNode(
            activeLes != 0
              ? treeFlat?.find((el: any) => el?.data?.id == activeLes)?.data
              : treeFlat?.find((el: any) => el.data?.lessonId)?.data
          );
        }
        if (lessonId) {
          setActiveNode(
            treeFlat?.find(
              (el: any) =>
                el?.data?.lessonId == lessonId || el?.data?.assetId == lessonId
            )?.data
          );
        }
      }
    }
  }, [treeFlat]);

  useEffect(() => {
    if (tree) {
      const parseTreePath = (node: any, path: string) => {
        if (node.lessonId) {
          node.simplifiedPath = path + simplifyPath(node.name);
        } else if (node.assetId) {
          node.simplifiedPath = path + simplifyPath(node.assetName);
        } else {
          node.simplifiedPath = path + simplifyPath(node.name) + '/';
        }
        if (node.children) {
          for (const child of node.children) {
            parseTreePath(child, node.simplifiedPath);
          }
        }
      };
      parseTreePath(tree, '');
    }
  }, [tree]);

  useEffect(() => {
    const lessonId = activeNode?.lessonId || activeNode?.assetId;
    if (tree && lessonId) {
      const lessonArray: any[] = [];
      walk(tree, (node: any) => {
        if (node?.lessonId || node?.assetId) {
          lessonArray.push(node);
        }
      });
      const targetIndex = lessonArray.findIndex(
        (node) => node?.lessonId == lessonId || node?.assetId == lessonId
      );
      if (targetIndex == -1) {
        setNextLesson(null);
        setPreviousLesson(null);
      } else {
        //find next lesson that is not onlyThory
        let tempNext = targetIndex + 1;
        let tempPrev = targetIndex - 1;
        while (tempNext < lessonArray.length) {
          if (
            lessonArray[tempNext]?.onlyTheory &&
            !lessonArray[tempNext]?.assetId
          ) {
            tempNext++;
          } else {
            break;
          }
        }
        if (tempNext < lessonArray.length) {
          setNextLesson(lessonArray[tempNext] || lessonArray[tempNext]);
        } else if (targetIndex < lessonArray.length - 1) {
          //setlle for an onlyTheory lesson
          setNextLesson(
            lessonArray[targetIndex + 1] || lessonArray[targetIndex + 1]
          );
        } else {
          setNextLesson(null);
        }

        while (tempPrev > 0) {
          if (
            lessonArray[tempPrev]?.onlyTheory &&
            !lessonArray[tempPrev]?.assetId
          ) {
            tempPrev--;
          } else {
            break;
          }
        }
        if (tempPrev > 0) {
          setPreviousLesson(lessonArray[tempPrev] || lessonArray[tempPrev]);
        } else if (targetIndex > 0) {
          //setlle for an onlyTheory lesson
          setPreviousLesson(
            lessonArray[targetIndex - 1] || lessonArray[targetIndex - 1]
          );
        } else {
          setPreviousLesson(null);
        }
      }
    }
  }, [tree, activeNode]);

  if (!tree) return null;

  return (tree as any)?.children?.map((x: any, index: number) => (
    <TreeItem
      key={x?.usesId + '_' + index}
      node={x}
      indent={0}
      index={index}
      currentLessonId={activeNode?.id}
      activeLessonNode={activeNode}
      setActiveNode={setActiveNode}
      setHideContent={setHideContent}
      setIsLoaded={setIsLoaded}
    />
  ));
};

import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import EditCompetencies from './EditCompetencies';

const WidgetCompetencies = ({ state, setCompetencies, competencies }) => {
  const { t } = useTranslation();
  const [isOpenCompetencies, setIsOpenCompetencies] = useState(false);

  useEffect(() => {
    if (state?.competences?.length > 0) {
      setCompetencies(state?.competences);
    }
  }, []);

  return (
    <>
      <div
        contentEditable={false}
        ref={(el) => {
          if (el) {
            el.style.setProperty('display', 'none', 'important');
          }
        }}
        id={`${state?.uid}_competencies`}
        onClick={() => {
          setIsOpenCompetencies(true);
          let placeholder = document.getElementById(state.uid);
          const divsToRemove =
            placeholder.getElementsByClassName('active-widget');
          Array.from(divsToRemove).forEach((div) => {
            div.remove();
          });
        }}
      ></div>

      {isOpenCompetencies && (
        <EditCompetencies
          state={state}
          setIsOpenCompetencies={setIsOpenCompetencies}
          isOpenCompetencies={isOpenCompetencies}
          competencies={competencies}
          setCompetencies={setCompetencies}
        />
      )}
    </>
  );
};

export default WidgetCompetencies;

export const languages = [
  {
    label: 'langs.nl',
    value: 'nl',
  },
  {
    label: 'langs.en',
    value: 'en',
  },
  {
    label: 'langs.fr',
    value: 'fr',
  },
  {
    label: 'langs.es',
    value: 'es',
  },
];

import React, { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadImage } from '@/query/documents';

// import { Label } from '@teo/components';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import UploadedPhoto from '@/components/UploadDropzoneSection/UploadedPhoto';
import { TargetBoxForUpload } from '@/components/UploadDropzoneImage/TargetBoxForUpload';
import UploadedPhotoContainer from '@/components/UploadDropzoneImage/UploadedPhotoContainer';
import FileContainerDropzone from '@/components/UploadDropzoneImage/FileContainerDropzone';

const UploadDropzoneImage = ({ setUploadImg, uploadImg }: any) => {
  const [photoInfo, setPhotoInfo] = useState();
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  const handleFileDrop = async (item: { files: any }) => {
    if (item) {
      const files = item.files;
      setDroppedFiles(files);
      const data = await uploadImage(item.files[0], 1200, 1000, 'jpeg');
      setUploadImg(data);
    }
  };

  const handleUploadPhoto = async (event: any): Promise<any> => {
    const img = event.target.files[0];
    setPhotoInfo(img);
    const data = await uploadImage(img, 1200, 1000, 'jpeg');
    // setUploadImg('https://' + data);
    setUploadImg(data);
  };

  return (
    <>
      <div id="uploadDropzone" className="mt-3" contentEditable="false">
        {/*<DndProvider backend={HTML5Backend}>*/}
        {!photoInfo && droppedFiles.length === 0 && !uploadImg && (
          <TargetBoxForUpload
            onDrop={handleFileDrop}
            fileInputRef={fileInputRef}
            handleUploadPhoto={handleUploadPhoto}
          />
        )}

        {droppedFiles.length > 0 && !photoInfo && (
          <FileContainerDropzone
            files={droppedFiles}
            fileInputRef={fileInputRef}
            handleUploadPhoto={handleUploadPhoto}
          />
        )}

        {(photoInfo || uploadImg) && (
          <UploadedPhotoContainer
            photoInfo={photoInfo}
            fileInputRef={fileInputRef}
            handleUploadPhoto={handleUploadPhoto}
            uploadLogo={uploadImg}
          />
        )}
        {/*</DndProvider>*/}
      </div>
    </>
  );
};

export default UploadDropzoneImage;

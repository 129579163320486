import { useTranslation } from 'react-i18next';
import LearnCourseOverview from '@/pages/Protected/Learn/LearnCourseOverview/LearnCourseOverview';

function LearnAssessmentsPage() {
  const { t } = useTranslation();

  return (
    <LearnCourseOverview
      courseType={'assessment'}
      title={t('pages.learn_assessments.title')}
    />
  );
}

export default LearnAssessmentsPage;

import { CompanyAvatar, Dropdown, InputText, Button } from '@teo/components';
import { Search } from '@teo/components/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { parseFilter } from '@/util';
import Pagination from './DataTable/Pagination';

export const OrganizationsHeader = ({
  authUser,
  updateActiveOrganisation,
  handleReplaceUrl,
  setMyOrganisations,
  myOrganisations,
  isMobile,
}: any) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [count, setCount] = useState(isMobile ? 5 : 10);
  const [offset, setOffset] = useState(0);
  const [organizations, setOrganizations] = useState<any>(null);
  const [maxPage, setMaxPage] = useState(1);

  const result: any = useGet(`/organisationdetails`, {
    count,
    offset,
    sort: 'organisationName,id',
    q: parseFilter(filter),
  });

  useEffect(() => {
    setOrganizations(result?.data?.data);
    const contentRange = result?.data?.headers?.['content-range']
      ?.split(' ')?.[1]
      ?.split('/');
    const maxCount = contentRange ? Math.ceil(contentRange?.[1]) : 1;
    setMaxPage(Math.ceil(maxCount / count));
  }, [result?.data]);

  const page = Math.floor(offset / count) + 1;

  return (
    <>
      {isMobile ? (
        <div>
          <div className="w-auto pb-5">
            <InputText
              value={filter}
              onChange={(ev: any) => setFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.user')}
            />
          </div>
          {organizations?.map((organisation: any) => (
            <Button
              variant="ghost"
              className={`my-1 flex w-full !min-w-full items-center gap-3 !px-1 !text-left md:flex-wrap ${
                organisation?.id === authUser?.activeOrganisationId &&
                '!bg-grey-02'
              }`}
              key={organisation.id}
              onClick={async () => {
                await updateActiveOrganisation(organisation);
                handleReplaceUrl(organisation.id);
                setMyOrganisations(!myOrganisations);
              }}
            >
              <CompanyAvatar
                imageSrc={organisation.picture || ''}
                name={organisation.organisationName || ''}
              />
              <div className="max-w-xs">{organisation.organisationName}</div>
            </Button>
          ))}
          {maxPage > 1 && (
            <Pagination
              setOffset={setOffset}
              offset={offset}
              count={count}
              page={page}
              maxPage={maxPage}
              organisation={true}
            />
          )}
        </div>
      ) : (
        <Dropdown.Content>
          {authUser?.role >= 90 && (
            <Dropdown.Actions>
              <Dropdown.Link
                to={
                  'organisation/' + authUser?.activeOrganisationId + '/create'
                }
                className="mt-2 !h-10 rounded-lg border border-grey-03"
              >
                {t('links.organisation_create')}
              </Dropdown.Link>
            </Dropdown.Actions>
          )}

          {(authUser?.role >= 90 ||
            (authUser?.activeOrganisationRole || 0) >= 95) && (
            <Dropdown.Actions>
              <Dropdown.Link
                to={'organisation/' + authUser?.activeOrganisationId}
              >
                {t('links.organisation_details')}
              </Dropdown.Link>
            </Dropdown.Actions>
          )}

          <Dropdown.Actions>
            <div className="w-96 px-2 pb-5 pt-2">
              <InputText
                value={filter}
                onKeyDown={(e) =>
                  (e.key == ' ' || e.code == 'Space' || e.keyCode == 32) &&
                  e.stopPropagation()
                }
                onChange={(ev: any) => {
                  setOffset(0);
                  setFilter(ev.target.value);
                }}
                Icon={Search}
                placeholder={t('search.user')}
              />
            </div>
            {organizations?.map((organisation: any) => (
              <Dropdown.Button
                className="flex h-auto min-h-[48px] items-center gap-3 py-1"
                key={organisation.id}
                onClick={async () => {
                  await updateActiveOrganisation(organisation);
                  handleReplaceUrl(organisation.id);
                }}
              >
                <CompanyAvatar
                  imageSrc={organisation.picture || ''}
                  name={organisation.organisationName || ''}
                  objectContain={true}
                />
                <span className="line-clamp-2">
                  {organisation.organisationName}
                </span>
              </Dropdown.Button>
            ))}
            {maxPage > 1 && (
              <Pagination
                setOffset={setOffset}
                offset={offset}
                count={count}
                page={page}
                maxPage={maxPage}
                organisation={true}
              />
            )}
          </Dropdown.Actions>
        </Dropdown.Content>
      )}
    </>
  );
};

import { useGet } from '@/query/fetchHooks';
import { useParams } from 'react-router-dom';
import AddContentModal from '@/components/AddContentModal/AddContentModal';
import UserDetailsPage from './UserDetailsPage/UserDetailsPage';
import AddCourse from './AddCourse/AddCoures';
import { useState } from 'react';

function UserDetails() {
  const { organisationId, userId } = useParams();
  const [student, setStudent] = useState<any>(null);
  const [isOpenAddContentModal, setIsOpenAddContentModal] = useState(false);
  const [isOpenAddContent, setIsOpenAddContent] = useState(false);
  const [activeCourse, setActiveCourse] = useState();
  const [courseType, setCourseType] = useState();

  useGet('/memberdetails', {
    organisationId,
    userId,
    onSuccess: (result: any) => {
      setStudent(result?.data?.[0] || {});
    },
  });

  return (
    <div>
      {isOpenAddContent && activeCourse ? (
        <AddCourse
          activeCourse={activeCourse}
          setActiveCourse={setActiveCourse}
          setIsOpenAddContent={setIsOpenAddContent}
          student={student}
          setIsOpenAddContentModal={setIsOpenAddContentModal}
          activeCourseType={courseType}
        />
      ) : (
        <UserDetailsPage
          student={student}
          setIsOpenAddContentModal={setIsOpenAddContentModal}
          action={true}
        />
      )}
      <AddContentModal
        isOpenAddContentModal={isOpenAddContentModal}
        closeModal={() => setIsOpenAddContentModal(false)}
        setIsOpenAddContent={setIsOpenAddContent}
        setActiveCourse={setActiveCourse}
        activeCourse={activeCourse}
        setCourseType={setCourseType}
      />
    </div>
  );
}

export default UserDetails;

import { useState, Fragment, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import { Button, IconButton, InputText, Table } from '@teo/components';
import { Search, MoreVertical, Pencil } from '@teo/components/icons';
import { T } from '@/components/T';
import { DataTable } from '@/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import AddCategoryModal from '@/pages/Protected/Manage/AddCategoryModal/AddCategoryModal';
import { axios } from '@/axios';
import { config } from '@/config';
import EditCategoryModal from '@/pages/Protected/Manage/EditCategoryModal/EditCategoryModal';
import { newUid } from '@/util';
import OptionsAction from '@/components/DataTable/OptionsAction';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';

const ManageCategoriesPage = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [editCategory, setEditCategory] = useState<any>(null);
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const { auth } = useContext(AuthContext);

  return (
    <div className="min-h-[100vh] max-w-6xl">
      <div className="flex w-full items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{t('pages.manage_categories.title')}</T>
        </h1>

        <div className="flex items-center">
          <Button
            data-testid="addCategoryModal"
            onClick={() => setEditCategory(true)}
            size="md"
          >
            {t('pages.manage_categories.add_category_button')}
          </Button>
          {/* <div className="pl-2">
            <IconButton
              Icon={MoreVertical}
              variant="ghost"
              className="text-[#919193]"
            />
          </div> */}
        </div>
      </div>

      <Fragment>
        <div className="pb-8 sm:w-2/3 md:w-1/2" data-testid="categoriesTable">
          <InputText
            value={filter}
            onChange={(ev) => setFilter(ev.target.value)}
            Icon={Search}
            placeholder={t('search.course')}
          />
        </div>
        <DataTable
          filter={filter}
          fetch={useGet.bind(
            null,
            `/categories?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
          )}
          key={refetchTable}
          defaultSort={[
            { id: 'tag', desc: false },
            { id: 'createdAt', desc: false },
          ]}
          screensUpdateTable="mobile"
          columns={[
            {
              title: t('columns.category'),
              id: 'tag',
              className: 'col-start-1 col-end-9',
              render: (category: any) => (
                <div className="flex items-center">
                  <div
                    style={{
                      backgroundColor: `${category.color}`,
                      width: 32,
                      height: 32,
                      border: '2px solid white',
                      borderRadius: '50%',
                    }}
                    className="flex items-center justify-center"
                  >
                    <img
                      src={`/images/icons/${category.icon}.svg`}
                      alt="categoryIcon"
                      className="h-[16px] w-[16px] fill-white"
                    />
                  </div>

                  <div className="ml-3 flex items-center text-sm font-medium">
                    {category?.tag}
                  </div>
                </div>
              ),
            },
            {
              addTitleOnMobile: true,
              className: 'col-start-1 col-end-9 row-start-2',
              id: 'createdAt',
              title: t('columns.date_created'),
              align: 'right',
              render: (category: any) =>
                format(new Date(category.createdAt), 'dd/MM/yyyy'),
            },
            {
              title: '',
              id: 'edit',
              sortable: false,
              className: 'col-start-9 row-start-1 justify-end',
              render: (category: any) => (
                <div className="flex justify-end">
                  <OptionsAction
                    delete={{
                      title: t('pages.manage_categories.delete_category'),
                      request: `${config.backend}/categories/${category.id}`,
                    }}
                    edit={{
                      title: t('pages.manage_categories.edit_category'),
                      request: () => {
                        setEditCategory(category);
                      },
                    }}
                    rowInfo={category}
                    screensUpdateTable="mobile"
                    setRefetchTable={setRefetchTable}
                    mobileHeader={{
                      render: (category: any) => (
                        <div className="flex items-center">
                          <div
                            style={{
                              backgroundColor: `${category.color}`,
                              width: 32,
                              height: 32,
                              border: '2px solid white',
                              borderRadius: '50%',
                            }}
                            className="flex items-center justify-center"
                          >
                            <img
                              src={`/images/icons/${category.icon}.svg`}
                              alt="categoryIcon"
                              className="h-[16px] w-[16px] fill-white"
                            />
                          </div>

                          <div className="ml-3 flex items-center text-sm font-medium">
                            {category?.tag}
                          </div>
                        </div>
                      ),
                    }}
                  />
                </div>
              ),
            },
          ]}
        />
      </Fragment>

      {editCategory && (
        <EditCategoryModal
          closeModal={() => setEditCategory(null)}
          onChange={() => {
            setRefetchTable(newUid(20));
            setEditCategory(null);
          }}
          category={editCategory !== true ? editCategory : null}
        />
      )}
    </div>
  );
};

export default ManageCategoriesPage;

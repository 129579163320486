import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useNavigate } from 'react-router-dom';
import { TimeFilled, DocumentsFilled, Eye } from '@teo/components/icons';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { convertToTime } from '@/util';
import { axios } from '@/axios';
import { config } from '@/config';

const DuplicationOverview = ({
  setCategory,
  category,
  allCategories,
  authUser,
  setMoreFilter,
  filter,
  refresh,
  setLoadLessons,
  loadLessons,
  showLast,
  selectLes,
  selectChapter,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listCategories, setListCategories] = useState<any>(null);
  const [recentlyList, setRecentlyList] = useState<any>(null);
  const basePath = window.location.pathname;

  useGet(`/libraryactivitydetails`, {
    userId: authUser.id,
    sort: showLast ? 'createdAt' : '-createdAt',
    count: 4,
    onSuccess: (result: any) => {
      setRecentlyList(result?.data || null);
      setLoadLessons(false);
    },
  });

  useEffect(() => {
    if (allCategories?.data) {
      setListCategories(allCategories?.data?.data);
    }
  }, [allCategories?.data]);

  return (
    <>
      {category || !!filter ? (
        <>
          <PaginationAnything
            key={refresh}
            className={`mt-3 -ml-2 -mr-2 flex max-w-6xl flex-wrap ${
              loadLessons === true && ' hidden '
            }`}
            fetch={useGet.bind(null, `/allimports`, {
              organisationId: `${authUser.activeOrganisationId},OR,organisationId=null`,
              qtag:
                category &&
                `%25category:${encodeURIComponent(category?.name)}%25`,
              onSuccess: async (result: any) => {
                setLoadLessons(false);
              },
            })}
            defaultSort={
              showLast ? [{ id: '-createdAt' }] : [{ id: 'createdAt' }]
            }
            empty={{
              template: 2,
              loadContent: loadLessons,
              text: t('pages.create_library.library_empty'),
            }}
            page={0}
            pageSize={12}
            filter={filter}
            render={(item: any) => (
              <div
                key={item?.id}
                className="basis-1/2 px-2 md:basis-1/3 lg:basis-1/4"
              >
                <div
                  className="group cursor-pointer pb-3"
                  onClick={async () => {
                    if (item?.lessonId) {
                      await axios.post(`${config.backend}/libraryactivity`, {
                        lessonId: item?.lessonId,
                        organisationId: authUser?.activeOrganisationId,
                        coursecontentId: item?.coursecontentId,
                        userId: authUser.id,
                        authorId: item?.authorId,
                      });
                      selectLes(item);
                    } else {
                      await axios.post(`${config.backend}/libraryactivity`, {
                        directoryId: item?.directoryId,
                        organisationId: authUser?.activeOrganisationId,
                        coursecontentId: item?.coursecontentId,
                        userId: authUser.id,
                        authorId: item?.authorId,
                      });
                      selectChapter(item);
                    }
                  }}
                >
                  <div className="flex flex-col">
                    <div className="relative mb-2 overflow-hidden rounded-lg">
                      <img
                        src={
                          item?.publicationImage
                            ? item?.publicationImage
                            : '/assets/images/opleidingen.jpg'
                        }
                        alt={item?.name}
                        className="h-28 w-full rounded-md object-cover"
                      />
                      {(item?.organisationId === 18 ||
                        item?.organisationId == null) && (
                        <img
                          src="/images/TEO_logo.svg"
                          className="absolute bottom-1 right-1 h-8 w-8"
                        />
                      )}
                      <div className="absolute inset-0 h-full w-full rounded-lg group-hover:backdrop-blur-sm"></div>
                      <span className="absolute inset-0 hidden items-center justify-center group-hover:flex">
                        <span className="rounded-md bg-grey-01 p-1 text-grey-09">
                          <Eye className="h-5 w-5 "></Eye>
                        </span>
                      </span>
                    </div>

                    <h3 className="text-base-semibold mb-2 break-words">
                      {item?.name || item?.assetName}
                    </h3>
                    {item?.lessonId ? (
                      <div className="flex flex-row items-center gap-3">
                        <span className="text-sm capitalize text-grey-04">
                          {item?.lessonType || item?.assetType}
                        </span>
                        {item?.estimatedTime > 0 && (
                          <span className="flex flex-row items-center gap-1 text-sm text-grey-04">
                            <TimeFilled className="inline h-5 w-5" />
                            <p className="pl-1">
                              {convertToTime(
                                Math.abs(item?.estimatedTime),
                                t('pages.learn_index.hour'),
                                t('pages.learn_index.minute')
                              )}
                            </p>
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="flex text-grey-04">
                        <div className="mr-3 flex">
                          <DocumentsFilled className="w-5" />

                          <span>
                            {item?.nrOfDirectoryLessons ? (
                              <>
                                {' '}
                                {item?.nrOfDirectoryLessons}{' '}
                                {t('pages.create_library.lessons')}
                              </>
                            ) : (
                              <>0 {t('pages.create_library.lessons')}</>
                            )}
                          </span>
                        </div>

                        {item?.directoryEstimatedTime > 0 && (
                          <div className="flex">
                            <TimeFilled className="w-5" />
                            <span>
                              {convertToTime(
                                Math.abs(item?.directoryEstimatedTime),
                                t('pages.learn_index.hour'),
                                t('pages.learn_index.minute')
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          />
        </>
      ) : (
        <>
          {recentlyList && recentlyList?.length > 0 && !filter && (
            <>
              <div className="mt-3 mb-8 -ml-2 -mr-2 flex max-w-6xl flex-wrap">
                {recentlyList?.map((el: any) => {
                  return (
                    <div
                      key={el?.id}
                      className="basis-1/2 px-2 md:basis-1/3 lg:basis-1/4"
                    >
                      <div
                        className="group w-full cursor-pointer pb-3"
                        onClick={async () => {
                          if (el?.lessonId) {
                            await axios.post(
                              `${config.backend}/libraryactivity`,
                              {
                                lessonId: el?.lessonId,
                                organisationId: authUser?.activeOrganisationId,
                                coursecontentId: el?.coursecontentId,
                                userId: authUser.id,
                                authorId: el?.authorId,
                              }
                            );
                            selectLes(el);
                          } else {
                            await axios.post(
                              `${config.backend}/libraryactivity`,
                              {
                                directoryId: el?.directoryId,
                                organisationId: authUser?.activeOrganisationId,
                                coursecontentId: el?.coursecontentId,
                                userId: authUser.id,
                                authorId: el?.authorId,
                              }
                            );
                            selectChapter(el);
                          }
                        }}
                      >
                        <div className="flex flex-col">
                          <div className="relative mb-2 overflow-hidden rounded-lg">
                            <img
                              src={
                                el?.publicationImage
                                  ? el?.publicationImage
                                  : '/assets/images/opleidingen.jpg'
                              }
                              alt={el?.name}
                              className="h-28 w-full rounded-md object-cover"
                            />
                            {(el?.organisationId === 18 ||
                              el?.organisationId == null) && (
                              <img
                                src="/images/TEO_logo.svg"
                                className="absolute bottom-1 right-1 h-8 w-8"
                              />
                            )}
                            <div className="absolute inset-0 h-full w-full rounded-lg group-hover:backdrop-blur-sm"></div>
                            <span className="absolute inset-0 hidden items-center justify-center group-hover:flex">
                              <span className="rounded-md bg-grey-01 p-1 text-grey-09">
                                <Eye className="h-5 w-5 "></Eye>
                              </span>
                            </span>
                          </div>

                          <h3 className="text-base-semibold mb-2 break-words">
                            {el?.name || el?.assetName}
                          </h3>
                          {el?.lessonId ? (
                            <div className="flex flex-row items-center gap-3">
                              <span className="text-sm capitalize text-grey-04">
                                {el?.lessonType || el?.assetType}
                              </span>
                              {el?.estimatedTime > 0 && (
                                <span className="flex flex-row items-center gap-1 text-sm text-grey-04">
                                  <TimeFilled className="inline h-5 w-5" />
                                  <p className="pl-1">
                                    {convertToTime(
                                      Math.abs(el?.estimatedTime),
                                      t('pages.learn_index.hour'),
                                      t('pages.learn_index.minute')
                                    )}
                                  </p>
                                </span>
                              )}
                            </div>
                          ) : (
                            <div className="flex text-grey-04">
                              <div className="mr-3 flex">
                                <DocumentsFilled className="w-5" />

                                <span>
                                  {el?.nrOfDirectoryLessons ? (
                                    <>
                                      {' '}
                                      {el?.nrOfDirectoryLessons}{' '}
                                      {t('pages.create_library.lessons')}
                                    </>
                                  ) : (
                                    <>0 {t('pages.create_library.lessons')}</>
                                  )}
                                </span>
                              </div>

                              {el?.directoryEstimatedTime > 0 && (
                                <div className="flex">
                                  <TimeFilled className="w-5" />
                                  <span>
                                    {convertToTime(
                                      Math.abs(el?.directoryEstimatedTime),
                                      t('pages.learn_index.hour'),
                                      t('pages.learn_index.minute')
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {listCategories && (
            <>
              <p className="mb-3 text-base font-semibold text-black ">
                {t(`pages.create_library.categories`)}
              </p>
              <div className="mt-3 -ml-2 -mr-2 flex max-w-6xl flex-wrap">
                {listCategories?.map((cat: any) => {
                  return (
                    <div
                      key={cat?.id}
                      className="basis-1/2 px-2 pb-4 md:basis-1/3 lg:basis-1/3"
                    >
                      <div
                        className={`group relative min-h-32 cursor-pointer rounded-lg px-5 py-4 pb-3`}
                        onClick={() => {
                          setMoreFilter(true);
                          setLoadLessons(true);
                          setCategory({ id: cat?.id, name: cat?.tag });
                        }}
                        style={{
                          backgroundColor: `${cat.color}`,
                        }}
                      >
                        <img
                          src={`/images/icons/list-categories/${
                            cat.icon ? cat.icon : 'techniek'
                          }.svg`}
                          alt="categoryIcon"
                          className="absolute right-5 bottom-4 h-14 w-14 transform transition duration-500 group-hover:scale-110"
                        />
                        <p className="font-semibold text-white">{cat?.tag}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DuplicationOverview;

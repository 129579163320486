import { config } from '@/config';
import { axios, publicAxios } from '@/axios';

export function uploadFile(path, file) {
  return new Promise((resolve, reject) => {
    axios
      .post(config.backend + '/document/postdata', {
        path: path,
        mimetype: file.type ? file.type : undefined,
      })
      .then((response0) => {
        _uploadFile(response0.data, file, (e, response) => {
          if (e) return reject(e);
          resolve(response0.data.downloadUrl);
        });
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

export function uploadString(path, content, mimetype) {
  if (!mimetype) mimetype = 'text/plain';
  let blob = new Blob([content], { type: mimetype });
  let path_splitted = path.split('/');
  blob.name = path_splitted[path_splitted.length - 1];
  return uploadFile(path, blob);
}

export async function uploadImage(file, width, height, format, preferredPath) {
  var data = new FormData();
  data.append('image', file);
  let path = config.backend + '/s3/upload/';
  if (width) {
    path += width;
  }
  path += '/';
  if (height) {
    path += height;
  }
  path += '/';
  if (format) {
    path += format;
  }
  return new Promise((resolve, reject) => {
    publicAxios
      .post(path, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          path: preferredPath ? preferredPath : undefined,
        },
      })
      .then((response) => {
        resolve(response.data.url);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

function _uploadFile(postData, file, cb, mimetype) {
  let form = new FormData();
  for (let key in postData.fields) {
    form.append(key, postData.fields[key]);
  }
  form.append('file', file);
  publicAxios
    .post(postData.url, form)
    .then((response) => {
      cb(null, response);
    })
    .catch((e) => {
      cb(e);
    });
}

export function uploadLesson(postData, content) {
  return new Promise((resolve, reject) => {
    let blob = new Blob([content], { type: 'text/html' });
    let path_splitted = postData?.fields?.key.split('/');
    blob.filename = path_splitted[path_splitted?.length - 1];
    return _uploadFile(postData, blob, (e, data) => {
      if (e) reject(e);
      else resolve(data);
    });
  });
}

import { useState, useRef, useEffect } from 'react';
import { axios } from '@/axios';
import { newUid } from '@/util';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Dropdown, ButtonLink } from '@teo/components';
import { MoreVertical, Pencil, ArrowRight, Trash } from '@teo/components/icons';
import { useMediaQuery } from 'react-responsive';

type Screens = 'mobile' | 'planshet' | 'desktop';
export interface OptionsActionPropsType {
  menuItems?: Array<{
    addOnMobile?: boolean;
    action?: (...args: any[]) => void;
    addIcon?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    id: number | string;
    itemDelete?: boolean;
    itemDetail?: boolean;
    title: string;
    onClick?: (...args: any[]) => void;
  }>;
  delete?: {
    disabled?: boolean;
    title: string;
    request?: string;
    selected?: any;
    setSelected?: any;
    onClick?: (...args: any[]) => void;
  };
  edit?: {
    title: string;
    request?: () => void;
  };
  styleDropdown?: any;
  screensUpdateTable?: Screens;
  setRefetchTable?: any;
  rowInfo: any;
  pageDetail?: {
    title: string;
    rowPath?: (arg0: any) => string;
  };
  mobileHeader?: {
    render?: JSX.Element | string | ((row: any) => JSX.Element | string);
  };
  positionDropdown?: boolean;
}

const OptionsAction = (props: OptionsActionPropsType): JSX.Element => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isItemBottom, setIsItemBottom] = useState(false);

  const itemRef = useRef<HTMLDivElement | null>(null);

  const screenUpdate = (screensUpdateTable: string | null) => {
    switch (screensUpdateTable) {
      case 'mobile':
        return '639px';
      case 'planshet':
        return '1199px';
      case 'desktop':
        return '1279px';
      default:
        return '1023px';
    }
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: `(max-width: ${
      props?.screensUpdateTable
        ? screenUpdate(props?.screensUpdateTable)
        : '1023px'
    })`,
  });

  const handleToggle = () => {
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    if (itemRef.current && props?.positionDropdown) {
      const currentBlock = itemRef.current;
      if (currentBlock) {
        const { bottom } = currentBlock.getBoundingClientRect();
        const fullPageHeight = document.documentElement.scrollHeight;
        if (fullPageHeight - bottom < 250) {
          setIsItemBottom(true);
        }
      }
    }
  }, [itemRef]);

  const content = props?.mobileHeader?.render
    ? typeof props?.mobileHeader?.render === 'function'
      ? props?.mobileHeader?.render(props.rowInfo)
      : props?.mobileHeader.render
    : 'defaultRender(row, col)';
  return (
    <>
      {!isDesktopOrLaptop ? (
        <div
          ref={itemRef}
          className="flex justify-end"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {props.edit && (
            <IconButton
              Icon={Pencil}
              variant="ghost"
              size="sm"
              onClick={() => {
                props?.edit?.request && props?.edit?.request();
              }}
            />
          )}
          {props?.delete && (
            <Button
              disabled={props?.delete?.disabled}
              size="sm"
              className="!px-2"
              onClick={() => {
                if (props?.delete?.request) {
                  axios
                    .delete(props?.delete?.request)
                    .then(() => props.setRefetchTable(newUid(20)));
                  const deleteSelected = new Set(props?.delete?.selected);
                  if (props?.delete?.selected) {
                    deleteSelected.delete(props?.rowInfo?.id);
                    props?.delete?.setSelected(deleteSelected);
                  }
                }
                if (!props?.delete?.request && props?.delete?.onClick) {
                  props?.delete?.onClick();
                }
              }}
              variant="ghost"
            >
              <Trash
                className={`h-5 w-5 ${
                  props?.delete?.disabled && ' opacity-30'
                }`}
              />
              {/* <img src="/images/icons/trash_icon.svg" alt="delete button" /> */}
            </Button>
          )}
          {props?.menuItems && (
            <Dropdown>
              <Dropdown.Trigger>
                <Button variant="ghost" size="sm" className='!px-1 !py-1'>
                  <MoreVertical className="h-5 w-5" />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content
                style={
                  isItemBottom
                    ? { top: 'auto', left: 'auto', right: '115%', bottom: '0' }
                    : props?.styleDropdown
                }
              >
                <Dropdown.Actions>
                  {props?.menuItems?.map(
                    (item) =>
                      !item?.hidden &&
                      (item?.itemDetail ? (
                        props?.pageDetail?.rowPath &&
                        props?.rowInfo && (
                          <Dropdown.Button
                            key={item.id}
                            className="mb-2"
                            onClick={() => {
                              navigate(
                                `${props?.pageDetail?.rowPath?.(props.rowInfo)}`
                              );
                            }}
                          >
                            {props?.pageDetail?.title}
                            {item?.addIcon && (
                              <ArrowRight className="ml-2 h-5 w-5" />
                            )}
                          </Dropdown.Button>
                        )
                      ) : (
                        <Dropdown.Button
                          onClick={item?.onClick}
                          key={item.id}
                          disabled={item.disabled}
                          id={`${item.disabled}`}
                          className={`${
                            item.disabled && ' pointer-events-none opacity-20 '
                          }`}
                        >
                          <p className="whitespace-nowrap">{item?.title}</p>
                          {item?.addIcon && (
                            <ArrowRight className="ml-2 h-5 w-5" />
                          )}
                        </Dropdown.Button>
                      ))
                  )}
                </Dropdown.Actions>
              </Dropdown.Content>
            </Dropdown>
          )}
        </div>
      ) : (
        <>
          <IconButton
            Icon={MoreVertical}
            variant="ghost"
            className="z-[2] w-7 py-1 px-1"
            size="sm"
            onClick={handleToggle}
          />
          {isOpenModal && (
            <div className="fixed inset-0 z-50 flex h-screen w-screen bg-[#00000040]">
              <div
                onClick={handleToggle}
                className="absolute inset-0 h-screen w-screen"
              ></div>
              <div className="z-10 mx-auto mt-auto flex flex w-full max-w-[600px] bg-white p-6">
                <div className="m-auto w-full max-w-[375px]">
                  {props?.mobileHeader?.render && props?.rowInfo && (
                    <div className="mb-6">{content}</div>
                  )}
                  {props?.pageDetail?.rowPath && props?.rowInfo && (
                    <ButtonLink
                      to={`${props?.pageDetail?.rowPath?.(props.rowInfo)}`}
                      variant="primary"
                      className="mb-2"
                    >
                      {props?.pageDetail?.title}
                    </ButtonLink>
                  )}
                  {props.edit && (
                    <Button
                      variant="outline"
                      onClick={() => {
                        props?.edit?.request && props?.edit?.request();
                        handleToggle();
                      }}
                      className="mb-2 w-full"
                    >
                      {props?.edit?.title}
                    </Button>
                  )}
                  {props?.menuItems?.map(
                    (item) =>
                      item.addOnMobile && (
                        <Button
                          variant={`${
                            !item.itemDelete ? 'outline' : 'destruct'
                          }`}
                          onClick={() => {
                            item?.onClick && item?.onClick();
                            setIsOpenModal(false);
                          }}
                          className="mb-2 w-full"
                          key={item.id}
                          disabled={item?.disabled}
                        >
                          {item?.title}
                        </Button>
                      )
                  )}
                  {props?.delete && (
                    <Button
                      variant="destruct"
                      className="w-full"
                      disabled={props?.delete?.disabled}
                      onClick={() => {
                        if (props?.delete?.request) {
                          axios
                            .delete(props?.delete?.request)
                            .then(() => props.setRefetchTable(newUid(20)));
                          const deleteSelected = new Set(
                            props?.delete?.selected
                          );
                          if (props?.delete?.selected) {
                            deleteSelected.delete(props?.rowInfo?.id);
                            props?.delete?.setSelected(deleteSelected);
                          }
                        }
                        if (!props?.delete?.request && props?.delete?.onClick) {
                          props?.delete?.onClick();
                        }

                        setIsOpenModal(false);
                      }}
                    >
                      {props?.delete?.title}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OptionsAction;

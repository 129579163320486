import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Label,
  InputDate,
  InputTextarea,
} from '@teo/components';
import { useState } from 'react';
import { config } from '@/config';
import { axios } from '@/axios';
import { getSafe } from '@/util';

function CreateOverviewModal({
  setIsOpenModal,
  isOpenModal,
  organisationId,
  onChange,
}: any) {
  const { t } = useTranslation();

  const [startDay, setStartDay] = useState<any>();
  const [endDay, setEndDay] = useState<any>();
  const [description, setDescription] = useState<string>('');

  function submitNewTag() {
    axios
      .post(`${config.backend}/supportinvoices`, {
        description,
        organisationId,
        startTime: getSafe(() =>
          new Date(new Date(startDay).getTime()).toISOString().substring(0, 10)
        ),
        endTime: getSafe(() =>
          new Date(new Date(endDay).getTime()).toISOString().substring(0, 10)
        ),
      })
      .then((data) => onChange(data));
  }

  const handleButton = () => {
    setIsOpenModal(false);
    submitNewTag();
  };

  return (
    <div className="bg-white">
      {isOpenModal && (
        <Modal isOpen={isOpenModal}>
          <Modal.Header onClose={() => setIsOpenModal(false)}>
            <h2 className="text-sm font-semibold" data-testid="addTagsHeader">
              {t(
                'pages.organisation_licensing_and_billing.overviews_modal.title'
              )}
            </h2>
          </Modal.Header>

          <Modal.Body>
            <p className="mt-2 text-sm font-normal text-[#7d7d81]">
              {t(
                'pages.organisation_licensing_and_billing.overviews_modal.subtitle'
              )}
            </p>

            <form
              className="w-full max-w-[440px] flex-col max-md:h-full max-md:overflow-hidden"
              data-testid="addOverviewForm"
            >
              <div>
                <Label htmlFor="dateStart">
                  <p className="mt-6 text-sm font-semibold">
                    {t(
                      'pages.organisation_licensing_and_billing.overviews_modal.start'
                    )}
                  </p>
                </Label>
                <InputDate
                  id="dateStart"
                  onChange={(date) => {
                    setStartDay(date);
                  }}
                  value={startDay ? new Date(startDay as Date) : undefined}
                  variant="date"
                />
              </div>
              <div>
                <Label htmlFor="dateEnd">
                  <p className="mt-6 text-sm font-semibold">
                    {t(
                      'pages.organisation_licensing_and_billing.overviews_modal.end'
                    )}
                  </p>
                </Label>
                <InputDate
                  id="dateEnd"
                  onChange={(date) => {
                    setEndDay(date);
                  }}
                  value={endDay ? new Date(endDay as Date) : undefined}
                  variant="date"
                />
              </div>

              <div className="mt-6">
                <InputTextarea
                  data-testid="description"
                  id="description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                  placeholder={t(
                    'pages.organisation_licensing_and_billing.overviews_modal.description'
                  )}
                />
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <div data-testid="addTagsFooter">
              <Button type="button" onClick={handleButton} data-testid="addBtn">
                {t(
                  'pages.organisation_licensing_and_billing.overviews_modal.button_create'
                )}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default CreateOverviewModal;

import { useGet } from '@/query/fetchHooks';
import { useParams } from 'react-router-dom';
import AddContentModal from '@/components/AddContentModal/AddContentModal';
import TeamDetailsPage from './TeamDetailsPage/TeamDetailsPage';
import AddCourse from './AddCourse/AddCoures';
import { useState, useContext } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';

function TeamDetails() {
  const { teamId, organisationId } = useParams();
  const [teamInfo, setTeamInfo] = useState<any>(null);
  const [isOpenAddContentModal, setIsOpenAddContentModal] = useState(false);
  const [isOpenAddContent, setIsOpenAddContent] = useState(false);
  const [activeCourse, setActiveCourse] = useState();
  const [courseType, setCourseType] = useState();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  useGet(`/team/${authUser?.activeOrganisationId}/${teamId}`, {
    onSuccess: (result: any) => {
      setTeamInfo(result?.data || {});
    },
  });

  return (
    <div>
      {isOpenAddContent && activeCourse ? (
        <AddCourse
          activeCourse={activeCourse}
          setActiveCourse={setActiveCourse}
          setIsOpenAddContent={setIsOpenAddContent}
          teamInfo={teamInfo}
          setIsOpenAddContentModal={setIsOpenAddContentModal}
          activeCourseType={courseType}
        />
      ) : (
        <TeamDetailsPage
          teamInfo={teamInfo}
          setIsOpenAddContentModal={setIsOpenAddContentModal}
        />
      )}
      <AddContentModal
        isOpenAddContentModal={isOpenAddContentModal}
        closeModal={() => setIsOpenAddContentModal(false)}
        setIsOpenAddContent={setIsOpenAddContent}
        setActiveCourse={setActiveCourse}
        activeCourse={activeCourse}
        setCourseType={setCourseType}
      />
    </div>
  );
}

export default TeamDetails;

import { useParams, useNavigate } from 'react-router-dom';
import { DataTable } from '@/components/DataTable/DataTable';
import { useState, Fragment, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import {
  PersonAvatar,
  Tag,
  InputText,
  Tabs,
  Button,
  FilterDropdown,
  InputSelect,
  Modal,
  FilterList,
} from '@teo/components';
import { Search } from '@teo/components/icons';
import { T } from '@/components/T';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';
import { newUid, parseRole, mapRole } from '@/util';
import OptionsAction from '@/components/DataTable/OptionsAction';
import AddNewTeamModal from '@/pages/Protected/Manage/AddNewTeamModal/AddNewTeamModal';
import EditTeamModal from '@/pages/Protected/Manage/EditTeamModal/EditTeamModal';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import DeleteTeamModal from '@/components/DeleteModal/DeleteTeamModal';
import { format } from 'date-fns';
import Hint from '@/components/Hints/Hints';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { SingleUserInvite } from '@/components/UserInvite/SingleUserInvite';

const userRoles = [
  {
    value: 10,
    label: 'role.CANDIDATE',
  },
  {
    value: 20,
    label: 'role.STUDENT',
  },
  {
    value: 40,
    label: 'role.TEAMLEAD',
  },
  {
    value: 45,
    label: 'role.CONTENT_CREATOR',
  },
  {
    value: 70,
    label: 'role.TEACHER',
  },
  {
    value: 90,
    label: 'role.ADMIN',
  },
  {
    value: 95,
    label: 'role.SUPER_ADMIN',
  },
];

function ManageUsersTeams({ tab }: any) {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('tab');
  const { hints } = useContext(HintsContext);
  const { newPage } = useContext(PageUrlContext);
  const [filter, setFilter] = useState('');
  const [userInpFilter, setUserInpFilter] = useState('');
  //const [tab, setTab] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [teamStatus, setTeamStatus] = useState();
  const [allTeams, setAllTeams] = useState();
  const [role, setRole] = useState<any>();
  const [created, setCreated] = useState<any>();
  const [isOpenAddNewTeamModal, setIsOpenAddNewTeamModal] =
    useState<any>(false);
  const [isOpenEditTeamModal, setIsOpenEditTeamModal] = useState(false);
  const [isOpenTeamsDeleteModal, setIsOpenTeamsDeleteModal] = useState(false);
  const [isOpenUsersDeleteModal, setIsOpenUsersDeleteModal] = useState(false);
  const [invitationSentModal, setInvitationSentModal] = useState(false);

  const [transferUser, setTransferUser] = useState<any>(false);
  const [transferToUser, setTransferToUser] = useState<any>(null);

  const [isTeamIndex, setIsTeamIndex] = useState();
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [selectedUser, setSelectedUser] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselectUser, setDeselectUser] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const [selectedTeam, setSelectedTeam] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselectTeam, setDeselectTeam] = useState<boolean>(false);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenDeleteTeam, setIsOpenDeleteTeam] = useState(false);
  const [teamDelete, setTeamDelete] = useState<any>();
  const [userDelete, setUserDelete] = useState<any>();
  const [teamOrg, setTeamOrg] = useState<any>(null);
  const [teamDeleteDisable, setTeamDeleteDisable] = useState<any>(false);
  const [selectedStatusTeams, setSelectedStatusTeams] = useState([
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
  ]);
  const [filterStatusTeams, setFilterStatusTeams] = useState<any>();

  const { organisationId, userId } = useParams();
  const navigate = useNavigate();

  const basePath = window.location.pathname;

  const teamPath = basePath.slice(0, basePath.lastIndexOf('/')) + '/teams';
  const userPath = basePath.slice(0, basePath.lastIndexOf('/')) + '/users';

  /*
  useEffect(() => {
    setTab(activeTab ? activeTab : 'teams');
  }, [activeTab]);
  */

  const FILTERS_STATUS = [
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
    {
      label: t('pages.follow_up_courses.status_inactive'),
      value: 'disabled,hidden',
    },
  ];

  const today = new Date();
  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);
  const lastYear = new Date(today);
  lastYear.setFullYear(today.getFullYear() - 1);

  const filterUser = {
    status: [
      {
        id: '1',
        name: t('pages.manage_users.active'),
        value: 'true',
      },
      {
        id: '2',
        name: t('pages.manage_users.not_active'),
        value: 'false',
      },
    ],
    roles: [
      {
        id: 10,
        value: 10,
        name: t('role.CANDIDATE'),
      },
      {
        id: 20,
        value: 20,
        name: t('role.STUDENT'),
      },
      {
        id: 40,
        value: 40,
        name: t('role.TEAMLEAD'),
      },
      {
        id: 45,
        value: 45,
        name: t('role.CONTENT_CREATOR'),
      },
      {
        id: 70,
        value: 70,
        name: t('role.TEACHER'),
      },
      {
        id: 90,
        value: 90,
        name: t('role.ADMIN'),
      },
      {
        id: 95,
        value: 95,
        name: t('role.SUPER_ADMIN'),
      },
    ],
    created: [
      {
        id: 1,
        name: t('pages.manage_users.filter_date.last_week'),
        value: lastWeek.toISOString(),
      },
      {
        id: 2,
        name: t('pages.manage_users.filter_date.last_month'),
        value: lastMonth.toISOString(),
      },
      {
        id: 3,
        name: t('pages.manage_users.filter_date.last_year'),
        value: lastYear.toISOString(),
      },
      {
        id: 4,
        name: '2022',
        value: '2022-01-01T00:00:00.000Z',
        value_2: '2022-12-31T23:59:59.999Z',
      },
      {
        id: 5,
        name: '2021',
        value: '2021-01-01T00:00:00.000Z',
        value_2: '2021-12-31T23:59:59.999Z',
      },
    ],
  };

  const handleDeleteTeams = (teams: any) => {
    [...teams]?.map((el: any) => {
      axios
        .delete(`${config.backend}/team/${el}`)
        .then(() => setRefetchTable(newUid(20)));
    });
    setSelectedTeam(new Set());
    setIsOpenTeamsDeleteModal(false);
  };

  const handleDeleteUser = (usr: any) => {
    axios
      .delete(`${config.backend}/member/${usr.organisationId}/${usr.userId}`)
      .then(() => setRefetchTable(newUid(20)));
    setIsOpenDeleteModal(false);
  };

  const handleStatusTeam = async (team: any) => {
    const promises = [];
    const subTeams = await axios.get(
      `${config.backend}/teams?organisationId=${organisationId}&searchPath=${team?.teamPath}%25`
    );
    const teamsChange = subTeams?.data || [];
    if (teamsChange?.length > 0) {
      for (const sub of teamsChange) {
        promises.push(
          axios.put(`${config.backend}/team/${organisationId}/${sub.id}`, {
            teamStatus: team?.teamStatus === 'active' ? 'disabled' : 'active',
            updatedAt: new Date().toJSON(),
          })
        );
      }
      Promise.allSettled(promises).then(() => {
        setRefetchTable(newUid(20));
      });
    }
  };

  useEffect(() => {
    if (selectedTeam.size > 0) {
      if (selectedTeam.has(teamOrg)) {
        setTeamDeleteDisable(true);
      } else {
        setTeamDeleteDisable(false);
      }
    }
  }, [selectedTeam, teamOrg]);

  useEffect(() => {
    if (selectedStatusTeams.length == 0) {
      setFilterStatusTeams(null);
    }
    if (selectedStatusTeams.length == 1) {
      setFilterStatusTeams(`${selectedStatusTeams[0].value}`);
    }
    if (selectedStatusTeams.length > 1) {
      const select = selectedStatusTeams?.map((el) => el.value);
      setFilterStatusTeams(`${select}`);
    }
    setRefetchTable(newUid(20));
  }, [selectedStatusTeams]);

  return (
    <div className="w-full max-w-6xl pb-8">
      <div className="flex items-center justify-between">
        {tab === 'teams' && (
          <>
            <h1 className="my-8 text-xl font-semibold text-black">
              <T>{t('pages.manage_teams.title')}</T>
            </h1>

            <div className="flex items-center">
              {/* <Button
                onClick={() => {}}
                size="md"
                variant="outline"
                className="mr-3"
              >
                {t('pages.manage_teams.team_settings')}
              </Button> */}

              <Button
                data-testid="addNewTeamModal"
                onClick={() => {
                  setIsOpenAddNewTeamModal(true);
                }}
                size="md"
              >
                {t('pages.manage_teams.add_team_button')}
              </Button>

              {/* <IconButton
                Icon={MoreVertical}
                variant="ghost"
                className="text-[#919193]"
              /> */}
            </div>
          </>
        )}

        {tab === 'users' && (
          <>
            <h1 className="my-8 text-xl font-semibold text-black">
              <T>{t('pages.manage_users.title')}</T>
            </h1>

            <div className="flex items-center">
              <Button
                onClick={() => {
                  navigate(`${basePath}/addUser`);
                }}
                size="md"
              >
                {t('pages.manage_users.add_users_button')}
              </Button>

              {/* <IconButton
                Icon={MoreVertical}
                variant="ghost"
                className="text-[#919193]"
              /> */}
            </div>
          </>
        )}
      </div>

      <Tabs
        activeName={tab}
        onChange={(tab: any) => {
          //setTab(tab);
          navigate(basePath.slice(0, basePath.lastIndexOf('/')) + '/' + tab);
          setFilter('');
          setUserInpFilter('');
        }}
      >
        <div
          className={`relative ${
            hints?.id == 'hint_23' && 'active-hint-parent'
          }`}
        >
          <Tabs.TabButton name="teams">
            <Hint
              key={`hint_23_${newPage}`}
              id={'hint_23'}
              leftDots={'-left-2 top-1.5'}
              title={t('hint.title_23')}
              order={23}
              style={{
                left: '-10px',
                top: '60px',
              }}
            />
            <p className="text-base font-medium">
              {t('pages.manage_users.team_tab')}
            </p>
          </Tabs.TabButton>
        </div>
        <div
          className={`relative ${
            hints?.id == 'hint_24' && 'active-hint-parent'
          }`}
        >
          <Tabs.TabButton name="users">
            <Hint
              key={`hint_24_${newPage}`}
              id={'hint_24'}
              leftDots={'-left-2 top-1.5'}
              title={t('hint.title_24')}
              order={24}
              style={{
                left: '-10px',
                top: '60px',
              }}
            />
            <p className="text-base font-medium">
              {t('pages.manage_users.users_tab')}
            </p>
          </Tabs.TabButton>
        </div>
      </Tabs>

      {tab === 'teams' && (
        <Fragment>
          <div className="py-8 md:w-1/2 xl:w-1/3">
            <InputText
              value={filter}
              onChange={(ev) => setFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.team')}
            />
          </div>

          <div className="mb-6 flex flex-col justify-between lg:flex-row lg:items-center">
            {/* <div className="flex flex-col sm:flex-row">
              <span className="mr-3 mb-4 sm:mb-0">
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setAllTeams(value);
                  }}
                  buttonText={`${t('pages.manage_teams.all_teams_dropdown')}`}
                  options={allTeamsData}
                  searchable={true}
                  value={allTeams}
                />
              </span>

              <span>
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setTeamStatus(value);
                  }}
                  buttonText={`${t('pages.manage_users.all_statuses')}`}
                  options={teamsStatusesData}
                  searchable={false}
                  value={teamStatus}
                />
              </span>
            </div> */}

            <div className="mt-4 flex flex-row lg:mt-0">
              {selectedTeam.size > 0 && (
                <Button
                  onClick={() => setIsOpenTeamsDeleteModal(true)}
                  // onClick={() => {
                  //   [...selectedTeam]?.map((el: any) => {
                  //     axios
                  //       .delete(
                  //         `${config.backend}/team/${organisationId}/${el}`
                  //       )
                  //       .then(() => setRefetchTable(newUid(20)));
                  //   });
                  // }}
                  size="sm"
                  variant="outline"
                  className="mr-3 !text-error-04"
                  disabled={teamDeleteDisable}
                >
                  {t('pages.manage_users.delete_team')}
                </Button>
              )}
              <Button
                onClick={() => {
                  setIsOpenAddNewTeamModal([...selectedTeam][0]);
                }}
                size="sm"
                variant="outline"
                className="mr-3"
              >
                {t('pages.manage_teams.add_sub_team_button')}
              </Button>

              {/*     <Button
                onClick={() => {
                  navigate(
                    `${rootPath}/follow-up/18/teams/${[...selectedTeam][0]}`
                  );
                }}
                size="sm"
                variant="outline"
              >
                {t('pages.manage_teams.follow_up_team_button')}
              </Button>*/}
            </div>
          </div>

          <div className="mb-5 flex items-center">
            <FilterList
              filters={FILTERS_STATUS}
              onSelect={setSelectedStatusTeams}
              selected={selectedStatusTeams}
            />
          </div>

          <DataTable
            filter={filter}
            key={refetchTable}
            rowPath={(team) => `${teamPath}/${team.id}`}
            defaultSort={[{ id: 'teamPath', desc: false }]}
            fetch={useGet.bind(null, '/teams', {
              organisationId,
              teamStatus: filterStatusTeams,
            })}
            empty={{
              template: 1,
              text: t('empty_tenplate.team_list'),
              btn_text: t('pages.manage_teams.add_team_button'),
              btnClick: () => setIsOpenAddNewTeamModal(true),
            }}
            selectable={{
              selected: selectedTeam,
              setSelected: setSelectedTeam,
              deselect: deselectTeam,
              setDeselect: setDeselectTeam,
              checkboxIconMobileType: 'circle',
            }}
            indent={(team: any) => {
              return team.teamPath.split(';;').length - 1;
            }}
            columns={[
              {
                title: t('columns.teamName'),
                id: 'teamPath',
                className: 'col-start-1 col-end-8 row-start-1',
                classNameDesktop: 'max-w-[300px] w-[40%]',
                render: (team: any) => {
                  team?.autoGenerated && setTeamOrg(team?.id);
                  return (
                    <div
                      style={{
                        marginLeft:
                          '' +
                          (team?.teamPath &&
                            team?.teamPath?.split(';;').length - 1) +
                          'rem',
                      }}
                      className="flex items-center"
                    >
                      <PersonAvatar
                        inline
                        id={team?.id}
                        name={team?.teamName}
                        bgColor={team?.color ? team?.color : null}
                      ></PersonAvatar>
                      <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                        {team?.teamName}
                      </p>
                    </div>
                  );
                },
              },
              {
                addTitleOnMobile: true,
                id: 'nrOfTeamMembers',
                title: t('columns.nrOfTeamMembers'),
                className: 'col-start-1 col-end-6 row-start-2',
                align: 'right',
              },
              // {
              //   addTitleOnMobile: true,
              //   id: 'verified',
              //   title: t('columns.status'),
              //   sortable: false,
              //   className:
              //     'col-start-6 col-end-10 row-start-2 text-right justify-end',
              //   align: 'right',
              //   render: (team: any) => (
              //     <Tag
              //       title={
              //         team.created
              //           ? t('pages.manage_users.active')
              //           : t('pages.manage_users.not_active')
              //       }
              //       variant={team.created ? 'success' : 'error'}
              //     />
              //   ),
              // },
              {
                addTitleOnMobile: true,
                id: 'createdAt',
                title: t('columns.date_created'),
                align: 'right',
                className:
                  'col-start-7 col-end-10 row-start-2 justify-end text-center',
                render: (team: any) =>
                  format(new Date(team?.createdAt), 'dd/MM/yyyy'),
              },
              {
                title: '',
                id: 'edit',
                sortable: false,
                className: 'col-start-9 row-start-1 justify-end',
                render: (team: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        id: team.id + 'status',
                        title:
                          team?.teamStatus === 'active'
                            ? t('pages.manage_teams.team_close_button')
                            : t('pages.manage_teams.team_open_button'),
                        disabled: team.teamPath === '_organisation_',
                        addOnMobile: true,
                        onClick: () => {
                          handleStatusTeam(team);
                        },
                      },
                    ]}
                    delete={{
                      disabled: team?.autoGenerated,
                      title: t('pages.manage_teams.delete_team'),
                      // request: `${config.backend}/team/${team.id}`,
                      // request: `${config.backend}/team/${organisationId}/${team.id}`,
                      selected: selectedTeam,
                      setSelected: setSelectedTeam,
                      onClick: () => {
                        setIsOpenDeleteTeam(true);
                        setTeamDelete(team);
                      },
                    }}
                    // edit={{
                    //   title: t('pages.manage_teams.edit_team'),
                    //   request: () => {
                    //     setIsOpenEditTeamModal(true);
                    //     setIsTeamIndex(team.id);
                    //   },
                    // }}
                    setRefetchTable={setRefetchTable}
                    rowInfo={team}
                    pageDetail={{
                      title: t('pages.manage_teams.team_detail'),
                      rowPath: (team) => `${teamPath}/${team.id}`,
                    }}
                    mobileHeader={{
                      render: (team: any) => (
                        <div className="flex items-center">
                          <PersonAvatar
                            inline
                            id={team.id}
                            name={team.teamName}
                          ></PersonAvatar>
                          <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                            {team.teamName}
                          </p>
                        </div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </Fragment>
      )}

      {tab === 'users' && (
        <Fragment>
          <div className="py-8 md:w-1/2 xl:w-1/3">
            <InputText
              value={userInpFilter}
              onChange={(ev) => setUserInpFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.user')}
            />
          </div>

          <div className="mb-6 flex flex-col justify-between lg:flex-row lg:items-center">
            <div className="z-[9] flex flex-col sm:flex-row">
              <span className="mb-4 pr-2 sm:mb-0">
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setStatus(value);
                    setRefetchTable(newUid(20));
                  }}
                  buttonText={`${t('pages.manage_users.all_statuses')}`}
                  options={filterUser?.status}
                  searchable={true}
                  value={status}
                />
              </span>

              <span className="mb-4 pr-2 sm:mb-0">
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setRole(value);
                    setRefetchTable(newUid(20));
                  }}
                  buttonText={`${t('pages.manage_users.all_roles')}`}
                  options={filterUser?.roles}
                  searchable={true}
                  value={role}
                />
              </span>

              <span className="pr-2">
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setCreated(value);
                    setRefetchTable(newUid(20));
                  }}
                  buttonText={`${t('pages.manage_users.created')}`}
                  options={filterUser?.created}
                  searchable={false}
                  value={created}
                  dropdownPositionMobileRight={true}
                />
              </span>
            </div>
            {selectedUser?.size > 0 && (
              <div className="mt-6 flex flex-row lg:mt-0">
                <Button
                  onClick={() => setIsOpenUsersDeleteModal(true)}
                  size="sm"
                  disabled={selectedUser?.size < 1}
                  variant="outline"
                  className="!text-error-04"
                >
                  {t('pages.manage_users.delete_user')}
                </Button>
                {/* <Button
                onClick={() => {}}
                size="sm"
                disabled={selectedUser?.size < 1}
                className="ml-2 flex items-center"
                variant="outline"
              >
                {t('pages.manage_users.follow_up_user_button')}
                <ArrowRight className="ml-2 w-3" />
              </Button> */}
              </div>
            )}
          </div>

          <DataTable
            filter={userInpFilter}
            rowPath={(usr) => `${userPath}/${usr.id}`}
            key={refetchTable}
            defaultSort={[
              { id: 'username', desc: false },
              { id: 'role', desc: false },
              { id: 'createdAt', desc: false },
            ]}
            fetch={useGet.bind(null, '/memberdetails', {
              organisationId,
              created: status?.value,
              role: role?.value,
              since: created?.value,
              until: created?.value_2,
            })}
            empty={{
              template: 1,
              text: t('empty_tenplate.user_list'),
              btn_text: t('pages.manage_users.add_users_button'),
              btnClick: () => navigate(`${basePath}/addUser`),
            }}
            screensUpdateTable="desktop"
            selectable={{
              selected: selectedUser,
              setSelected: setSelectedUser,
              deselect: deselectUser,
              setDeselect: setDeselectUser,
              checkboxIconMobileType: 'circle',
            }}
            columns={[
              {
                title: t('columns.username'),
                id: 'username',
                className: 'col-start-1 col-end-6 break-all row-start-1',
                classNameDesktop: 'max-w-[310px] w-[310px]',
                render: (usr: any) => (
                  <div className="flex items-center">
                    <PersonAvatar
                      inline
                      id={usr.id}
                      name={usr.username}
                      imageSrc={usr.picture}
                    ></PersonAvatar>

                    <div>
                      <p className="text-sm font-medium text-[#231f20]">
                        {usr.username}
                      </p>
                      <p className="text-xs font-normal text-[#7d7d81]">
                        {usr.email}
                      </p>
                    </div>
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                id: 'phone',
                title: t('columns.phone'),
                sortable: false,
                className: 'col-start-1 col-end-4 row-start-2',
                classNameDesktop: 'max-w-[180px] w-[180px]',
                align: 'right',
                render: (usr: any) => usr.phone && <p>{usr.phone}</p>,
              },
              {
                id: 'role',
                title: t('columns.role'),
                className: 'col-start-6 col-end-9 row-start-1 justify-end',
                align: 'left',
                render: (usr: any) => {
                  let customButton;
                  switch (usr.role) {
                    case 20:
                      customButton = (
                        <Tag
                          className="block min-w-[100px]"
                          title={t(parseRole(usr.role))}
                          variant="secondary"
                        />
                      );
                      break;
                    case 10:
                      customButton = (
                        <Tag
                          className="block min-w-[100px]"
                          title={t(parseRole(usr.role))}
                          variant="primary"
                        />
                      );
                      break;
                    case 70:
                      customButton = (
                        <Tag
                          className="block min-w-[100px]"
                          title={t(parseRole(usr.role))}
                          variant="warning"
                        />
                      );
                      break;
                    case 90:
                      customButton = (
                        <Tag
                          className="block min-w-[100px] bg-orange-300/50"
                          title={t(parseRole(usr.role))}
                          variant="error"
                        />
                      );
                      break;
                    case 95:
                      customButton = (
                        <Tag
                          className="block min-w-[100px]"
                          title={t(parseRole(usr.role))}
                          variant="error"
                        />
                      );
                      break;
                    case 75:
                      customButton = (
                        <Tag
                          className="block min-w-[100px]"
                          title={t(parseRole(usr.role))}
                          variant="success"
                        />
                      );
                      break;
                    case 60:
                      customButton = (
                        <Tag
                          className="block min-w-[100px]"
                          title={t(parseRole(usr.role))}
                          variant="document"
                        />
                      );
                      break;
                    default:
                      customButton = (
                        <Tag
                          className="block min-w-[100px]"
                          title={t(parseRole(usr.role))}
                          variant="secondary"
                        />
                      );
                      break;
                  }

                  return (
                    <div
                      onClick={(ev) => {
                        ev.stopPropagation();
                      }}
                    >
                      <InputSelect
                        customButton={customButton}
                        placeholder={t(parseRole(usr.role))}
                        options={userRoles.filter(
                          (x) =>
                            x.value <=
                            Math.max(
                              authUser?.role || 0,
                              authUser?.activeOrganisationRole || 0
                            )
                        )}
                        onChange={(option) => {
                          axios
                            .put(
                              `${config.backend}/member/${authUser?.activeOrganisationId}/${usr.userId}`,
                              { role: option.value }
                            )
                            .then(() => {
                              setRefetchTable(newUid(20));
                            });
                        }}
                        value={userRoles.find(
                          (x) => x.value === mapRole(usr.role)
                        )}
                      >
                        {(option) => <>{t(parseRole(option.value))}</>}
                      </InputSelect>
                    </div>
                  );
                  /*
                  switch (usr.role || usr.role === role) {
                    case 20:
                      return (
                        <Tag
                          title={t(parseRole(usr.role))}
                          variant="secondary"
                        />
                      );
                    case 10:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="primary" />
                      );
                    case 70:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="warning" />
                      );
                    case 90:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="error" />
                      );
                    case 75:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="success" />
                      );
                    case 60:
                      return (
                        <Tag
                          title={t(parseRole(usr.role))}
                          variant="document"
                        />
                      );
                    default:
                      return (
                        <Tag
                          title={t(parseRole(usr.role))}
                          variant="secondary"
                        />
                      );
                  }
                  */
                },
              },
              {
                addTitleOnMobile: true,
                id: 'createdAt',
                title: t('columns.date_created'),
                align: 'right',
                className:
                  'col-start-4 col-end-7 row-start-2 justify-center text-center',
                classNameDesktop: 'max-w-[140px] w-[140px]',
                render: (usr: any) =>
                  format(new Date(usr.createdAt), 'dd/MM/yyyy'),
              },
              {
                addTitleOnMobile: true,
                id: 'verified',
                title: t('columns.status'),
                sortable: false,
                className:
                  'col-start-7 col-end-10 row-start-2 text-right justify-end',
                align: 'right',
                classNameDesktop: 'max-w-[95px] w-[95px]',
                render: (usr: any) => (
                  <Tag
                    title={
                      usr.disabled
                        ? t('pages.manage_users.disabled')
                        : usr.created
                        ? t('pages.manage_users.active')
                        : t('pages.manage_users.not_active')
                    }
                    variant={usr.created && !usr.disabled ? 'success' : 'error'}
                  />
                ),
              },
              {
                title: '',
                id: 'edit',
                sortable: false,
                className: 'col-start-9 row-start-1 justify-end',
                render: (usr: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        addIcon: true,
                        id: usr.id + 'manage',
                        title: t('pages.manage_users.manage_user_button'),
                        itemDetail: true,
                        onClick: () => navigate(`${userPath}/${usr.id}`),
                      },
                      {
                        id: usr.id + 'invite',
                        addOnMobile: true,
                        title: t('pages.manage_users.resend_invite'),
                        onClick: () => {
                          axios
                            .post(`${config.backend}/invite/member_invite`, {
                              userId: usr.userId,
                              organisationId: organisationId,
                            })
                            .then(() => {
                              setInvitationSentModal(true);
                            });
                        },
                      },
                      {
                        id: usr.id + 'transfer',
                        addOnMobile: true,
                        title: t('pages.manage_users.transfer'),
                        onClick: () => {
                          setTransferUser(usr);
                        },
                      },
                    ]}
                    delete={{
                      title: t('pages.manage_users.delete_user'),
                      // request: `${config.backend}/member/${usr.organisationId}/${usr.userId}`,
                      onClick: () => {
                        setIsOpenDeleteModal(true);
                        setUserDelete(usr);
                      },
                      selected: selectedUser,
                      setSelected: setSelectedUser,
                    }}
                    // edit={{
                    //   title: t('pages.manage_users.edit_user'),
                    // }}
                    screensUpdateTable="desktop"
                    setRefetchTable={setRefetchTable}
                    pageDetail={{
                      title: t('pages.manage_users.user_detail'),
                      rowPath: (usr) => `${userPath}/${usr.id}`,
                    }}
                    rowInfo={usr}
                    mobileHeader={{
                      render: (usr: any) => (
                        <div className="flex items-center">
                          <PersonAvatar
                            inline
                            id={usr.id}
                            name={usr.username}
                            imageSrc={usr.picture}
                          ></PersonAvatar>

                          <div>
                            <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                              {usr.username}
                            </p>
                          </div>
                        </div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </Fragment>
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          closeModal={() => setIsOpenDeleteModal(false)}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          title={t('delete_modal.title_user') + userDelete?.username}
          heading={t('delete_modal.heading_user')}
          text={t('delete_modal.text_user_org')}
          handleDelete={() => handleDeleteUser(userDelete)}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setRefetchTable={setRefetchTable}
        />
      )}

      {isOpenUsersDeleteModal && (
        <DeleteModal
          closeModal={() => setIsOpenUsersDeleteModal(false)}
          setIsOpenDeleteModal={setIsOpenUsersDeleteModal}
          title={t('delete_modal.title_users')}
          heading={t('delete_modal.heading_users')}
          text={t('delete_modal.text_users_org')}
          organisationId={authUser?.activeOrganisationId}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setRefetchTable={setRefetchTable}
          deleteUsers={true}
        />
      )}

      {isOpenDeleteTeam && (
        <DeleteTeamModal
          closeModal={() => setIsOpenDeleteTeam(false)}
          setIsOpenDeleteModal={setIsOpenDeleteTeam}
          title={t('delete_modal.title_team') + teamDelete?.teamName}
          heading={t('delete_modal.heading_team')}
          text={t('delete_modal.text_team')}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedItem={teamDelete}
          setRefetchTable={setRefetchTable}
        />
      )}

      {isOpenTeamsDeleteModal && (
        <DeleteTeamModal
          closeModal={() => setIsOpenTeamsDeleteModal(false)}
          setIsOpenDeleteModal={setIsOpenTeamsDeleteModal}
          title={t('delete_modal.title_teams')}
          heading={t('delete_modal.heading_teams')}
          text={t('delete_modal.text_teams')}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          setRefetchTable={setRefetchTable}
        />
      )}

      {isOpenAddNewTeamModal && (
        <AddNewTeamModal
          isOpenAddTeam={isOpenAddNewTeamModal}
          closeModal={() => setIsOpenAddNewTeamModal(false)}
          onChange={() => {
            setRefetchTable(newUid(20));
          }}
        />
      )}

      {!!transferUser && (
        <Modal isOpen={true}>
          <Modal.Header
            onClose={() => {
              setTransferUser(null);
              setTransferToUser(null);
            }}
          >
            <h2
              className="text-sm font-semibold"
              data-testid="confirmInviteHeader"
            >
              {t('pages.manage_users.transfer_user')}
            </h2>
          </Modal.Header>
          <Modal.Body>
            <p className="text-sm font-normal">
              {t('pages.manage_users.transfer_user_explain')}
            </p>
            <div className="my-3 rounded-lg bg-error-01 p-4">
              <p className="text-sm text-error-05">
                {t('pages.manage_users.transfer_user_warning')}
              </p>
            </div>
            <div className="align-center mt-6 flex flex-row">
              <PersonAvatar
                inline
                id={transferUser?.id}
                name={transferUser?.username}
              ></PersonAvatar>
              <span className="my-auto">
                {transferUser.username} ({transferUser.email})
              </span>
            </div>

            <SingleUserInvite
              fetch={useGet.bind(null, '/memberdetails', {
                organisationId: authUser?.activeOrganisationId,
              })}
              multiselectLabel={t('pages.manage_users.select_user')}
              inviteButtonText={t('pages.manage_users.transfer')}
              value={transferToUser}
              setValue={setTransferToUser}
              handleAddUserToList={async (user: any) => {
                let newUser = user;
                if (user.newUser) {
                  newUser = (
                    await axios.post(
                      `${config.backend}/invite/provision?lang=${authUser?.lang}`,
                      {
                        organisationId: authUser?.activeOrganisationId,
                        email: user.email,
                      }
                    )
                  ).data;
                }
                await axios.post(
                  `${config.backend}/user/transfer/${transferUser.id}/${newUser.id}`
                );
                //await axios.delete(`${config.backend}/member/${organisationId}/${transferUser.id}`)
                setRefetchTable(newUid(20));
                setTransferUser(null);
                setTransferToUser(null);
              }}
            />
          </Modal.Body>
        </Modal>
      )}

      {invitationSentModal && (
        <Modal isOpen={invitationSentModal}>
          <Modal.Header onClose={() => setInvitationSentModal(false)}>
            <h2
              className="text-sm font-semibold"
              data-testid="confirmInviteHeader"
            >
              {t('pages.manage_users.invitation_send')}
            </h2>
          </Modal.Header>
          <Modal.Body>
            <p className="mt-2 text-sm font-normal text-[#7d7d81]">
              {t('pages.manage_users.invitation_send_explain')}
            </p>
            <div className="mt-5 flex justify-between">
              <Button onClick={() => setInvitationSentModal(false)}>
                {t('button.close')}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isTeamIndex && (
        <EditTeamModal
          isOpenEditTeam={isOpenEditTeamModal}
          closeModal={() => setIsOpenEditTeamModal(false)}
          onChange={() => {
            setRefetchTable(newUid(20));
          }}
          isTeamIndex={isTeamIndex}
        />
      )}
    </div>
  );
}

export default ManageUsersTeams;

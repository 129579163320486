import { useState, useContext } from 'react';
import { Tabs } from '@teo/components';
import { T } from '@/components/T';
import { useTranslation } from 'react-i18next';
import TeamsFollow from './TeamsFollow';
import UsersFollow from './UsersFollow';
import CoursesFollow from './CoursesFollow';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
// import AccountPage from './Account';
// import EducationAndWorkPage from '@/pages/Protected/Profile/EducationAndWorkPage';

function ProfileFollowUpPage() {
  const { t } = useTranslation();
  const [tab, setTab] = useState('teams');

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{t('pages.profile_follow.title')}</T>
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div>
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <div className="flex flex-col gap-3 sm:flex-row">
              <div className="flex flex-row gap-3">
                <Tabs.TabButton name="teams">
                  <p className="text-xs font-medium md:text-base lg:text-base">
                    {t('links.teams')}
                  </p>
                </Tabs.TabButton>
                <Tabs.TabButton name="users">
                  <p className="text-xs font-medium md:text-base lg:text-base">
                    {t('links.users')}
                  </p>
                </Tabs.TabButton>
                {authUser?.useCourse && (
                  <Tabs.TabButton name="courses">
                    <p className="text-xs font-medium md:text-base lg:text-base">
                      {t('links.courses')}
                    </p>
                  </Tabs.TabButton>
                )}
              </div>
              <div className="flex flex-row gap-3">
                {authUser?.useAssessment && (
                  <Tabs.TabButton name="assessments">
                    <p className="text-xs font-medium md:text-base lg:text-base">
                      {t('links.assessments')}
                    </p>
                  </Tabs.TabButton>
                )}
                {authUser?.useLeervacature && (
                  <Tabs.TabButton name="vacancies">
                    <p className="text-xs font-medium md:text-base lg:text-base">
                      {t('links.vacancies')}
                    </p>
                  </Tabs.TabButton>
                )}
              </div>
            </div>
          </Tabs>
        </div>
      </div>
      <div className="mt-6">{tab === 'teams' && <TeamsFollow />}</div>
      <div className="mt-6">{tab === 'users' && <UsersFollow />}</div>
      <div className="mt-6">
        {tab === 'courses' && <CoursesFollow courseType={'course'} />}
      </div>
      <div className="mt-6">
        {tab === 'assessments' && <CoursesFollow courseType={'assessment'} />}
      </div>
      <div className="mt-6">
        {tab === 'vacancies' && <CoursesFollow courseType={'leervacature'} />}
      </div>
    </div>
  );
}

export default ProfileFollowUpPage;

import { Suspense, useEffect, useState, Fragment } from 'react';
import { Expand } from '@teo/components/icons';
import { Button, IconButton } from '@teo/components';

/** TODO: Enlarge icon doesn't look quite right there */

export const MagnifyImage = (props) => {
  const [maginify, setMagnify] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const fullScreenStyleBlock = {
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 11,
    position: 'fixed',
    minHeight: '100vh',
    minWidth: '100vw',
    display: 'flex',
    padding: '25px',
  };

  const fullScreenStyleImg = {
    margin: 'auto',
    minHeight: '80vh',
    minWidth: '80vw',
    objectFit: 'contain',
  };

  const handleMouseDown = () => {
    props?.setHoverImage && props?.setHoverImage(true);
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    props?.setHoverImage && props?.setHoverImage(false);
    setIsMouseDown(false);
  };

  return (
    <>
      <div
        className="relative z-[2] overflow-hidden rounded"
        onClick={(ev) => {
          if (maginify) {
            ev.preventDefault();
            setMagnify(false);
          } else if (!props.showIcon) {
            setMagnify(!maginify);
          }
        }}
        onMouseEnter={handleMouseDown}
        onMouseLeave={handleMouseUp}
      >
        <img
          onLoad={props.onload}
          {...props}
          className={'nowrap ' + props.className}
        />
        {props.showIcon && (
          <div
            className={`absolute right-0 top-0 flex h-full w-full cursor-pointer bg-black/10 ${
              isMouseDown ? 'opacity-100' : 'opacity-0'
            }`}
            onClick={(ev) => {
              setMagnify(!maginify);
            }}
          >
            <Expand className="m-auto w-4 text-grey-05" />
          </div>
        )}
        {/*<Expand className="p-1 h-6 w-6 absolute right-0 top-0 bg-white" style={{border: '1px solid rgba(0,0,0,.1)', borderRaduis: '4px'}} />*/}
      </div>
      {maginify && (
        <div
          style={maginify ? fullScreenStyleBlock : {}}
          onClick={(ev) => {
            ev.preventDefault();
            if (maginify) {
              setMagnify(false);
            } else if (!props.showIcon) {
              setMagnify(!maginify);
            }
          }}
          onMouseEnter={() =>
            props?.setHoverImage && props?.setHoverImage(true)
          }
        >
          <img
            onLoad={props.onload}
            {...props}
            className={'nowrap ' + props.className}
            style={maginify ? fullScreenStyleImg : {}}
          />
        </div>
      )}
    </>
  );
};

import { Table, Checkbox } from '@teo/components';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import { useMediaQuery } from 'react-responsive';
import MobileDataTable from './MobileDataTable';
import { parseFilter } from '@/util';
import EmptyTable from './EmptyTable';

export const PaginationAnything = ({
  fetch,
  filter,
  defaultSort = [],
  page = 0,
  pageSize = 10,
  setContentRange,
  render,
  empty,
  ...other
}: any) => {
  const [selectMode, setSelectMode] = useState(true);

  const [offset, setOffset] = useState(page * pageSize);
  const [count, setCount] = useState(pageSize);

  const [data, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);

  const [sorted, setSorted] = useState(defaultSort || []);
  const [sortQuery, setSortQuery] = useState(
    sorted.map((sort: any) => (sort.desc ? '-' : '') + sort.id).join(',')
  );

  useEffect(() => {
    setSorted(defaultSort);
    setSortQuery(
      defaultSort.map((sort: any) => (sort.desc ? '-' : '') + sort.id).join(',')
    );
  }, [defaultSort]);

  page = Math.floor(offset / count) + 1;

  const addSort = (colId: number | string) => {
    const index = sorted.findIndex((x: any) => x.id === colId);
    if (index > -1) {
      const sort = sorted[index];
      sorted.splice(index, 1);
      sorted.unshift({ id: colId, desc: !sort.desc });
    } else {
      sorted.unshift({ id: colId, desc: true });
    }
    setSorted(sorted);
    setSortQuery(
      sorted.map((sort: any) => (sort.desc ? '-' : '') + sort.id).join(',')
    );
  };

  const result: any = fetch({
    offset,
    count,
    sort: sortQuery ? sortQuery : undefined,
    q: parseFilter(filter),
  });

  useEffect(() => {
    if (result?.data) {
      setData(result?.data?.data);
      const contentRange = result?.data?.headers?.['content-range']
        ?.split(' ')?.[1]
        ?.split('/');
      const maxCount = contentRange ? Math.ceil(contentRange?.[1]) : 1;
      setMaxPage(Math.ceil(maxCount / count));
      setContentRange && setContentRange(contentRange?.[1]);
    }
  }, [result?.data]);

  //console.log("data", data)

  return (
    <>
      {data?.length ? (
        <>
          <div {...other}>{data?.map((el: any) => render(el))}</div>
          {maxPage > 1 && (
            <Pagination
              setOffset={setOffset}
              offset={offset}
              count={count}
              page={page}
              maxPage={maxPage}
            />
          )}
        </>
      ) : (
        <div className={`${empty?.loadContent === true && ' hidden '}`}>
          <EmptyTable
            template={empty?.template}
            text={empty?.text}
            btn_text={empty?.btn_text}
            btnClick={() => empty?.btnClick && empty?.btnClick()}
            filter={filter}
          />
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@teo/components';
import { Information } from '@teo/components/icons';
import { NotificationContext } from '@/components/Contexts/NotificationContext';

function NotificationMessage() {
  const { t } = useTranslation();
  const { notification, setShowNotification } = useContext(NotificationContext);

  useEffect(() => {
    const stopNotificationTimeout = setTimeout(() => {
      setShowNotification(null);
    }, notification?.delay || 2000);
    return () => clearTimeout(stopNotificationTimeout);
  }, []);

  if (!notification) return null;

  return (
    <>
      <div
        style={{ zIndex: 1001 }}
        className="notificationBox fixed top-0 flex w-full flex-col items-center justify-center bg-grey-10 p-4 text-white md:flex-row lg:flex-row"
      >
        <div className="flex grow items-center justify-center text-base font-medium">
          <IconButton
            Icon={Information}
            size="sm"
            variant="ghost"
            className="text-white"
          />
          <p>{notification?.message || notification}</p>
        </div>
        {notification?.errorCode && (
          <Button
            variant="outline"
            size="sm"
            className="mt-4 mr-0 text-sm font-medium text-white md:mr-4 md:mt-0 lg:mr-4 lg:mt-0"
          >
            {t('notifications.show_error_codes')}
          </Button>
        )}
      </div>
    </>
  );
}

export default NotificationMessage;

import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonLink,
  Checkbox,
  InputText,
  Label,
  ErrorMessage,
  IconButton,
} from '@teo/components';
import { CloseCircle, TaskCheckmark, EyeOff, Eye } from '@teo/components/icons';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '@/query/auth';
import { emailRegex, passwordRegex } from '@/constants/regex.constants';
import { AxiosError } from 'axios';
import { ErrorBox } from '@/components/ErrorBox';
import i18n from 'i18next';

function AuthRegisterPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreedToConditions, setAgreedToConditions] = useState(false);
  const [mailingConfirmation, setMailingConfirmation] = useState('');
  const [hasAuthError, setAuthError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [emailWasTouched, setEmailWasTouched] = useState(false);
  const [passwordWasTouched, setPasswordWasTouched] = useState(false);
  const [confirmPasswordWasTouched, setConfirmPasswordWasTouched] =
    useState(false);

  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [requiredLength] = useState(8);
  const [validEmail, setValidEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setValidLength(password.length >= requiredLength);
    setUpperCase(password.toLowerCase() !== password);
    setLowerCase(password.toUpperCase() !== password);
    setHasNumber(/\d/.test(password));
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
    setValidEmail(emailRegex.test(email));
  }, [password, requiredLength, email]);

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  const backToTop = () => {
    (document.getElementById('userForm') as HTMLFormElement).scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function registerUser() {
    if (!agreedToConditions) {
      setAuthError(true);
      backToTop();
      setErrorText(t('pages.auth.register.agreedToConditionsError'));
    } else if (
      !(
        passwordRegex &&
        validLength &&
        hasNumber &&
        upperCase &&
        lowerCase &&
        requiredLength &&
        specialChar
      ) ||
      !validEmail ||
      email === '' ||
      password === '' ||
      confirmPassword === ''
    ) {
      setErrorText(t('pages.auth.register.password_and_email_ErrorMassage'));
    } else if (confirmPassword !== password) {
      setAuthError(true);
      backToTop();
      setErrorText(t('pages.auth.register.password_mismatch'));
    } else {
      register({
        firstName: firstname,
        lastName: lastname,
        username: `${firstname} ${lastname}`,
        email: email,
        password: password,
        repassword: confirmPassword,
        mailingConfirmation: mailingConfirmation,
        agreedToConditions: agreedToConditions,
        lang: i18n.language,
      })
        .then(() => {
          navigate(`${basePath}/register-success`);
        })
        .catch((e: AxiosError<{ message: string }>) => {
          setAuthError(true);

          const message = e?.response?.data?.message || e.toString();
          switch (message) {
            case 'User with this email already exists':
              setErrorText(t('pages.auth.register.registrationErrorMassage'));
              break;
            default:
              setErrorText(message);
              break;
          }

          console.error('Error', e);
          console.error('redirecting');
        });
    }
  }

  return (
    <div className="h-full overflow-hidden md:flex">
      <div
        className="flex h-full overflow-hidden md:w-1/2 md:items-center md:overflow-auto"
        id="userForm"
      >
        <form
          className="m-auto flex w-full max-w-[420px] flex-col max-md:h-full max-md:overflow-hidden"
          onSubmit={(event) => {
            event.preventDefault();
            registerUser();
          }}
        >
          <div className="space-y-6 px-5 pt-8 max-md:flex-grow max-md:overflow-auto md:space-y-8">
            <img
              className="h-12 w-12 md:h-16 md:w-16"
              src="/TEO_logo.svg"
              alt="Logo"
            />

            <h1 className="text-3xl-semibold">
              {t('pages.auth.register.title')}
            </h1>

            <div className="space-y-6 md:space-y-8">
              {hasAuthError && <ErrorBox text={errorText} />}

              <p className="text-sm text-grey-05">
                {t('pages.auth.register.subtitle')}
              </p>

              <div>
                <Label htmlFor="firstname">
                  {t('pages.auth.register.firstName_label')}
                </Label>
                <InputText
                  id="firstname"
                  onChange={(event) => {
                    setFirstname(event.target.value);
                  }}
                  placeholder={t('pages.auth.register.firstName_placeholder')}
                  required
                />
              </div>

              <div>
                <Label htmlFor="lastname">
                  {t('pages.auth.register.lastname_label')}
                </Label>
                <InputText
                  id="lastname"
                  onChange={(event) => {
                    setLastname(event.target.value);
                  }}
                  placeholder={t('pages.auth.register.lastname_placeholder')}
                  required
                />
              </div>

              <div>
                <Label htmlFor="email">
                  {t('pages.auth.login.email_label')}*
                </Label>
                <InputText
                  id="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder={t('pages.auth.register.email_placeholder')}
                  required
                  onBlur={(e) => {
                    if (!validEmail || e.target.value === '') {
                      setEmailWasTouched(true);
                    } else {
                      setEmailWasTouched(false);
                    }
                  }}
                  hasError={emailWasTouched}
                />
              </div>

              <div>
                <Label htmlFor="password">
                  {t('pages.auth.register.password_label')}
                </Label>
                <div className="relative space-y-2 text-grey-08">
                  <IconButton
                    Icon={showPassword ? Eye : EyeOff}
                    variant="ghost"
                    size="sm"
                    className="absolute right-[7px] top-[13px] z-10"
                    onClick={() => {
                      if (!showPassword) {
                        setShowPassword(true);
                      } else {
                        setShowPassword(false);
                      }
                    }}
                  />
                  <input
                    id="password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    placeholder={t('pages.auth.register.password_placeholder')}
                    type={showPassword ? 'text' : 'password'}
                    required
                    className="h-10 w-full rounded-md border border-solid border-transparent px-3 py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02"
                    onBlur={(e) => {
                      if (
                        !(
                          passwordRegex &&
                          validLength &&
                          hasNumber &&
                          upperCase &&
                          lowerCase &&
                          requiredLength &&
                          specialChar
                        ) ||
                        e.target.value === ''
                      ) {
                        setPasswordWasTouched(true);
                      } else {
                        setPasswordWasTouched(false);
                      }
                    }}
                    // hasError={passwordWasTouched}
                  />
                </div>

                {!passwordWasTouched ||
                  (!(passwordRegex && validLength && requiredLength) && (
                    <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                      <ErrorMessage id="errorMessage">
                        {t('pages.auth.register.errorMassage')}
                        &nbsp;
                        {t('pages.auth.register.characters_long')}
                      </ErrorMessage>
                    </div>
                  ))}
                {!passwordWasTouched ||
                  (!lowerCase && (
                    <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                      <ErrorMessage id="errorMessage">
                        {t('pages.auth.register.errorMassage')}
                        &nbsp;
                        {t('pages.auth.register.lowercase_letter')}
                      </ErrorMessage>
                    </div>
                  ))}
                {!passwordWasTouched ||
                  (!upperCase && (
                    <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                      <ErrorMessage id="errorMessage">
                        {t('pages.auth.register.errorMassage')}
                        &nbsp;
                        {t('pages.auth.register.capital_letter')}
                      </ErrorMessage>
                    </div>
                  ))}
                {!passwordWasTouched ||
                  (!hasNumber && (
                    <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                      <ErrorMessage id="errorMessage">
                        {t('pages.auth.register.errorMassage')}
                        &nbsp;
                        {t('pages.auth.register.digit_number')}
                      </ErrorMessage>
                    </div>
                  ))}
                {!passwordWasTouched ||
                  (!specialChar && (
                    <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                      <ErrorMessage id="errorMessage">
                        {t('pages.auth.register.errorMassage')}
                        &nbsp;
                        {t('pages.auth.register.symbol_text')}
                      </ErrorMessage>
                    </div>
                  ))}

                <div className="pt-3 pb-5 text-xs text-grey-07">
                  {t('pages.auth.register.password_must')}
                  <div className="mt-2 flex list-none flex-col">
                    <div className="flex items-center pt-2">
                      {passwordWasTouched &&
                        (!validLength || !requiredLength ? (
                          <IconButton
                            Icon={CloseCircle}
                            variant="ghost"
                            size="sm"
                            className="text-tint-red-dark"
                          />
                        ) : (
                          <IconButton
                            Icon={TaskCheckmark}
                            variant="ghost"
                            size="sm"
                            className="text-success-05"
                          />
                        ))}

                      {!passwordWasTouched && !password && (
                        <span className="pr-2">-</span>
                      )}
                      <span>{t('pages.auth.register.characters_long')}</span>
                    </div>
                    <div className="flex items-center pt-2">
                      {passwordWasTouched &&
                        (!lowerCase ? (
                          <IconButton
                            Icon={CloseCircle}
                            variant="ghost"
                            size="sm"
                            className="text-tint-red-dark"
                          />
                        ) : (
                          <IconButton
                            Icon={TaskCheckmark}
                            variant="ghost"
                            size="sm"
                            className="text-success-05"
                          />
                        ))}

                      {!password && <span className="pr-2">-</span>}
                      <span>{t('pages.auth.register.lowercase_letter')}</span>
                    </div>
                    <div className="flex items-center pt-2">
                      {passwordWasTouched &&
                        (!upperCase ? (
                          <IconButton
                            Icon={CloseCircle}
                            variant="ghost"
                            size="sm"
                            className="text-tint-red-dark"
                          />
                        ) : (
                          <IconButton
                            Icon={TaskCheckmark}
                            variant="ghost"
                            size="sm"
                            className="text-success-05"
                          />
                        ))}

                      {!password && <span className="pr-2">-</span>}
                      <span>{t('pages.auth.register.capital_letter')}</span>
                    </div>
                    <div className="flex items-center pt-2">
                      {passwordWasTouched &&
                        (!hasNumber ? (
                          <IconButton
                            Icon={CloseCircle}
                            variant="ghost"
                            size="sm"
                            className="text-tint-red-dark"
                          />
                        ) : (
                          <IconButton
                            Icon={TaskCheckmark}
                            variant="ghost"
                            size="sm"
                            className="text-success-05"
                          />
                        ))}

                      {!password && <span className="pr-2">-</span>}
                      <span>{t('pages.auth.register.digit_number')}</span>
                    </div>
                    <div className="flex items-center pt-2">
                      {passwordWasTouched &&
                        (!specialChar ? (
                          <IconButton
                            Icon={CloseCircle}
                            variant="ghost"
                            size="sm"
                            className="text-tint-red-dark"
                          />
                        ) : (
                          <IconButton
                            Icon={TaskCheckmark}
                            variant="ghost"
                            size="sm"
                            className="text-success-05"
                          />
                        ))}

                      {!password && <span className="pr-2">-</span>}
                      <span>{t('pages.auth.register.symbol_text')}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <Label htmlFor="confirmPassword">
                    {t('pages.auth.register.confirm_password_label')}
                  </Label>
                  <div className="space-y-2">
                    <InputText
                      id="confirmPassword"
                      onChange={(event) => {
                        setConfirmPassword(event.target.value);
                      }}
                      placeholder={t(
                        'pages.auth.register.confirm_password_placeholder'
                      )}
                      type="password"
                      required
                      onBlur={(e) => {
                        if (
                          confirmPassword !== password ||
                          e.target.value === ''
                        ) {
                          setConfirmPasswordWasTouched(true);
                          setErrorText(
                            t('pages.auth.register.password_mismatch')
                          );
                        } else {
                          setConfirmPasswordWasTouched(false);
                        }
                      }}
                      hasError={confirmPasswordWasTouched}
                    />
                  </div>
                </div>

                <div>
                  <p className="mt-5 mb-2 text-sm">
                    {t('pages.auth.register.mailing_confirmation')}
                  </p>

                  <div className="pb-2">
                    <Checkbox
                      id="mailingConfirmationYes"
                      ariaLabel="test"
                      onChange={() => {
                        setMailingConfirmation('Yes');
                      }}
                      checked={mailingConfirmation === 'Yes'}
                    />

                    <label
                      htmlFor="mailingConfirmationYes"
                      className="pl-2.5 text-sm text-grey-08"
                    >
                      {t('pages.auth.register.yes')}
                    </label>
                  </div>

                  <div>
                    <Checkbox
                      id="mailingConfirmationNo"
                      ariaLabel="test"
                      onChange={() => {
                        setMailingConfirmation('No');
                      }}
                      checked={mailingConfirmation === 'No'}
                    />

                    <label
                      htmlFor="mailingConfirmationNo"
                      className="pl-2.5 text-sm text-grey-08"
                    >
                      {t('pages.auth.register.no')}
                    </label>
                  </div>
                </div>

                <div>
                  <p className="pt-4 pb-2 text-sm">
                    {t('pages.auth.register.agreement_title')}
                  </p>

                  <div className="flex items-center">
                    <Checkbox
                      id="agreedToConditions"
                      ariaLabel="test"
                      onChange={() =>
                        setAgreedToConditions(!agreedToConditions)
                      }
                      checked={agreedToConditions}
                    />

                    <label
                      htmlFor="agreedToConditions"
                      className="pl-2.5 text-sm text-grey-08"
                    >
                      {t('pages.auth.register.agreement_text')}
                      <Link
                        className="inline-block pl-2 pr-2 text-sm text-tint-blue hover:underline"
                        to="../privacy"
                      >
                        {t('pages.auth.register.privacy')}
                      </Link>
                      {t('pages.auth.register.agreement_text2')}
                      <Link
                        className="inline-block text-sm text-tint-blue hover:underline"
                        to="../voorwaarden"
                      >
                        {t('pages.auth.register.terms_of_use')}
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-5 py-8 max-md:flex max-md:flex-col max-md:space-y-3 md:grid md:grid-cols-2 md:gap-4">
            <Button type="submit">
              {t('pages.auth.register.register_button')}
            </Button>

            <Button onClick={() => navigate('../login')} variant="outline">
              {t('pages.auth.register.login_button')}
            </Button>
          </div>
        </form>
      </div>

      <div className="w-1/2 max-md:hidden">
        <img
          className="h-full w-full object-cover"
          role="presentation"
          src="/images/interface/auth/login.jpg"
          alt="login_image"
        />
      </div>
    </div>
  );
}

export default AuthRegisterPage;

import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { Switch } from '@teo/components';
import { axios } from '@/axios';
import { config } from '@/config';

function EmailsAndNotifications() {
  const { t } = useTranslation();
  const { auth, setAuth } = useContext(AuthContext);
  const authUser: any = auth?.user;

  const userRole: any = authUser?.role || 0;

  const [emailCourseInvite, setEmailCourseInvite] = useState(
    authUser?.emailSettings?.emailCourseInvite === false ||
      authUser?.sendCourseInvite === false
      ? false
      : true
  );
  const [emailCourseDone, setEmailCourseDone] = useState(
    authUser?.emailSettings?.emailCourseDone === false ||
      authUser?.sendDoneEmail === false
      ? false
      : true
  );

  const [generalInformation, setGeneralInformation] = useState(
    authUser?.notifcationSettings?.message === false ? false : true
  );
  const [newPlatformFeatures, setNewPlatformFeatures] = useState(
    authUser?.notifcationSettings?.changelog === false ? false : true
  );
  const [addedToCourse, setAddedToCourse] = useState(
    authUser?.notifcationSettings?.newcourse === false ? false : true
  );
  const [teacherCorrectedLesson, setTeacherCorrectedLesson] = useState(
    authUser?.notifcationSettings?.lessoncorrected === false ? false : true
  );
  const [teacherCorrectedCourse, setTeacherCorrectedCourse] = useState(
    authUser?.notifcationSettings?.coursecorrected === false ? false : true
  );
  const [newCorrectionTasks, setNewCorrectionTasks] = useState(
    authUser?.notifcationSettings?.tocorrect === false ? false : true
  );
  const [publicationUpdated, setPublicationUpdated] = useState(
    authUser?.notifcationSettings?.publicationupdated === false ? false : true
  );

  const [notifcationSettings, setNotifcationSettings] = useState(
    authUser?.notifcationSettings
  );
  const [emailSettings, setEmailSettings] = useState(authUser?.emailSettings);

  const handleEmailSettings = (check: any, type: any) => {
    let updatedEmailSettings = emailSettings;
    if (check) {
      updatedEmailSettings = Object.fromEntries(
        Object.entries(updatedEmailSettings).filter(([key]) => key !== type)
      );
    } else {
      updatedEmailSettings[type] = false;
    }
    setEmailSettings(updatedEmailSettings);

    axios.put(`${config.backend}/user/${authUser?.id}`, {
      id: authUser?.id,
      emailSettings: updatedEmailSettings,
    });

    setAuth({
      user: Object.assign({}, auth?.user, {
        emailSettings: updatedEmailSettings,
      }),
      token: auth?.token,
    });
  };

  const handleNotifications = (check: any, type: any) => {
    let updatedSettings = notifcationSettings;
    if (check) {
      updatedSettings = Object.fromEntries(
        Object.entries(updatedSettings).filter(([key]) => key !== type)
      );
    } else {
      updatedSettings[type] = false;
    }
    setNotifcationSettings(updatedSettings);

    axios.put(`${config.backend}/user/${authUser?.id}`, {
      id: authUser?.id,
      notifcationSettings: updatedSettings,
    });

    setAuth({
      user: Object.assign({}, auth?.user, {
        notifcationSettings: updatedSettings,
      }),
      token: auth?.token,
    });
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-6 text-xl font-semibold text-black">
          {t('links.emails_notifications')}
        </h1>
      </div>
      <div className="flex flex-col">
        <div className="rounded-lg border border-grey-02 p-6">
          <div className="flex max-w-xl flex-col">
            <p className="pb-6 text-base font-semibold text-black">
              {t('pages.profile_emails_notifications.title')}
            </p>
            <p className="pb-2 text-base font-semibold text-black">
              {t('pages.profile_emails_notifications.email_settings')}
            </p>
            <div className="mt-2 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="product-ccourse">
                <Switch
                  disabled={authUser?.sendCourseInvite === false}
                  checked={emailCourseInvite}
                  onChange={(isCheck) => {
                    handleEmailSettings(isCheck, 'emailCourseInvite');
                    setEmailCourseInvite(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="font-normal">
                  {t('pages.profile_emails_notifications.added_to_course')}
                </h4>
              </div>
            </div>
            {userRole > 21 && (
              <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div data-testid="product-assessment">
                  <Switch
                    disabled={authUser?.sendDoneEmail === false}
                    checked={emailCourseDone}
                    onChange={(isCheck) => {
                      handleEmailSettings(isCheck, 'emailCourseDone');
                      setEmailCourseDone(isCheck);
                    }}
                  />
                </div>
                <div>
                  <h4 className="font-normal">
                    {t(
                      'pages.profile_emails_notifications.student_completed_course'
                    )}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div className="my-8 h-[1px] w-full bg-grey-02"></div>
          <div className="flex max-w-xl flex-col">
            <p className="pb-2 text-base font-semibold text-black">
              {t('pages.profile_emails_notifications.notification_settings')}
            </p>
            <div className="mt-2 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="product-ccourse">
                <Switch
                  checked={generalInformation}
                  onChange={(isCheck) => {
                    handleNotifications(isCheck, 'message');
                    setGeneralInformation(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="font-normal">
                  {t('pages.profile_emails_notifications.general_information')}
                </h4>
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="product-assessment">
                <Switch
                  checked={newPlatformFeatures}
                  onChange={(isCheck) => {
                    handleNotifications(isCheck, 'changelog');
                    setNewPlatformFeatures(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="font-normal">
                  {t(
                    'pages.profile_emails_notifications.new_platform_features'
                  )}
                </h4>
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="product-assessment">
                <Switch
                  checked={addedToCourse}
                  onChange={(isCheck) => {
                    handleNotifications(isCheck, 'newcourse');
                    setAddedToCourse(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="font-normal">
                  {t('pages.profile_emails_notifications.added_to_course')}
                </h4>
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="product-assessment">
                <Switch
                  checked={teacherCorrectedLesson}
                  onChange={(isCheck) => {
                    handleNotifications(isCheck, 'lessoncorrected');
                    setTeacherCorrectedLesson(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="font-normal">
                  {t('pages.profile_emails_notifications.corrected_lessons')}
                </h4>
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="product-assessment">
                <Switch
                  checked={teacherCorrectedCourse}
                  onChange={(isCheck) => {
                    handleNotifications(isCheck, 'coursecorrected');
                    setTeacherCorrectedCourse(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="font-normal">
                  {t('pages.profile_emails_notifications.corrected_course')}
                </h4>
              </div>
            </div>
            {userRole > 21 && (
              <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div data-testid="product-assessment">
                  <Switch
                    checked={newCorrectionTasks}
                    onChange={(isCheck) => {
                      handleNotifications(isCheck, 'tocorrect');
                      setNewCorrectionTasks(isCheck);
                    }}
                  />
                </div>
                <div>
                  <h4 className="font-normal">
                    {t(
                      'pages.profile_emails_notifications.new_correction_tasks'
                    )}
                  </h4>
                </div>
              </div>
            )}
            {userRole > 21 && (
              <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div data-testid="product-assessment">
                  <Switch
                    checked={publicationUpdated}
                    onChange={(isCheck) => {
                      handleNotifications(isCheck, 'publicationupdated');
                      setPublicationUpdated(isCheck);
                    }}
                  />
                </div>
                <div>
                  <h4 className="font-normal">
                    {t(
                      'pages.profile_emails_notifications.publication_updated'
                    )}
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailsAndNotifications;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CompanyAvatar, PersonAvatar } from '@teo/components';
import { ChevronDown, ChevronRight } from '@teo/components/icons';
import { MainNavLink } from '@/components/MainNavLink';
import { OrganizationsHeader } from '../OrganizationsHeader';

const HeaderMobile = ({
  authUser,
  organisations,
  updateActiveOrganisation,
  handleSubNaw,
  handleReplaceUrl,
  isMobile,
}: any) => {
  const [myOrganisations, setMyOrganisations] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleToggle = () => {
    setMyOrganisations(!myOrganisations);
  };

  const userRole = authUser?.role || 0;
  const role = Math.max(authUser?.activeOrganisationRole || 0, userRole);

  return (
    <>
      <div className="flex items-center p-5">
        <PersonAvatar
          id={authUser.id}
          name={authUser.username as string}
          imageSrc={authUser.picture || undefined}
        />
        <p className="ml-5 font-semibold text-grey-10">{authUser?.username}</p>
      </div>
      <div className="mb-5 px-5">
        <div
          className="flex items-center rounded-lg border border-grey-02 p-3"
          onClick={handleToggle}
          data-testid="my-organisations"
        >
          {authUser?.organisationName && (
            <CompanyAvatar
              imageSrc={authUser?.organisationPicture || ''}
              name={authUser?.organisationName}
            />
          )}
          <p className="ml-5 font-medium text-grey-09 line-clamp-2">
            {authUser?.organisationName}
          </p>
          <ChevronDown className="ml-auto h-5 w-5 text-grey-07" />
        </div>
      </div>
      <div className="border-y border-grey-01 px-5 py-3 ">
        <>
          <div onClick={() => handleSubNaw(t('links.learn'))}>
            <MainNavLink
              to={'./learn/' + authUser?.activeOrganisationId}
              activeLearn={
                window.location.pathname.split('/').filter(Boolean).pop() ===
                  'learn' || window.location.pathname.includes('/learn/')
              }
            >
              {t('links.learn')} <ChevronRight className="ml-auto h-6 w-6" />
            </MainNavLink>
          </div>
          {role >= 30 && (
            <div onClick={() => handleSubNaw(t('links.create'))}>
              <MainNavLink to={'./create/' + authUser?.activeOrganisationId}>
                {t('links.create')}
                <ChevronRight className="ml-auto h-6 w-6" />
              </MainNavLink>
            </div>
          )}
          {role >= 40 && role != 45 && (
            <div onClick={() => handleSubNaw(t('links.follow_up'))}>
              <MainNavLink to={'./follow-up/' + authUser?.activeOrganisationId}>
                {t('links.follow_up')}
                <ChevronRight className="ml-auto h-6 w-6" />
              </MainNavLink>
            </div>
          )}
          {role > 70 && (
            <div onClick={() => handleSubNaw(t('links.manage'))}>
              <MainNavLink to={'./manage/' + authUser?.activeOrganisationId}>
                {t('links.manage')}
                <ChevronRight className="ml-auto h-6 w-6" />
              </MainNavLink>
            </div>
          )}
        </>
      </div>
      {myOrganisations && (
        <div className="fixed inset-0 z-50 flex h-screen w-screen bg-[#00000040]">
          <div
            onClick={handleToggle}
            className="absolute inset-0 h-screen w-screen"
          ></div>
          <div className="z-10 mx-auto mt-auto flex flex max-h-[70%] w-full max-w-[600px] overflow-y-auto bg-white p-3">
            <div className="m-auto w-full max-w-[375px]">
              {role >= 90 ? (
                <OrganizationsHeader
                  authUser={authUser}
                  updateActiveOrganisation={updateActiveOrganisation}
                  handleReplaceUrl={handleReplaceUrl}
                  setMyOrganisations={setMyOrganisations}
                  myOrganisations={myOrganisations}
                  isMobile={isMobile}
                />
              ) : (
                organisations?.map((organisation: any) => (
                  <Button
                    variant="ghost"
                    className={`my-2 flex w-full !min-w-full items-center gap-3 md:flex-wrap ${
                      organisation?.id === authUser?.activeOrganisationId &&
                      '!bg-grey-02'
                    }`}
                    key={organisation.id}
                    onClick={async () => {
                      await updateActiveOrganisation(organisation);
                      handleReplaceUrl(organisation.id);
                      setMyOrganisations(!myOrganisations);
                    }}
                  >
                    <CompanyAvatar
                      imageSrc={organisation.picture || ''}
                      name={organisation.organisationName || ''}
                    />
                    <div className="max-w-xs">
                      {organisation.organisationName}
                    </div>
                  </Button>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderMobile;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExplanationSection = ({ state }) => {
  const { t } = useTranslation();

  const [valueAntwoord, setValueAntwoord] = useState(
    state?.antwoord ? state?.antwoord : ''
  );
  useEffect(() => {
    if (state) {
      const value = valueAntwoord;
      setValueAntwoord(value.replace(/ contenteditable="true"/g, ''));
    }
  }, [state]);

  return (
    <>
      {state?.showAnswer &&
        state?.antwoord &&
        valueAntwoord !== '<p></p>' &&
        state?.type !== 'OpenVraag' && (
          <div className="mt-[-1rem] rounded-lg bg-[#f8f8f9] pt-4">
            <p className="text-sm font-semibold text-black">
              {t('widgets.type.explanation')}:
            </p>

            <div
              className="text-sm font-normal text-grey-05"
              dangerouslySetInnerHTML={{
                __html: valueAntwoord,
              }}
            ></div>
          </div>
        )}
    </>
  );
};

export { ExplanationSection };

import { useState, useEffect, useRef } from 'react';
import { newUid, deep_copy, isString } from '@/util';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import {
  Switch,
  InputScore,
  Radio,
  Checkbox,
  InputText,
  IconButton,
  Button,
  Spinner,
} from '@teo/components';
import { Cross, Plus, Image } from '@teo/components/icons';
import { uploadImage } from '@/query/documents';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialMatrixVraag2 = () => ({
  score: 1,
  vraag: '',
  antwoord: '',
  showAnswer: false,
  radioMode: false,
  radioScoreRow: false,
  type: 'MatrixVraag2',
  rows: [],
  cols: [],
  data: [],
});

const RowColHead = ({
  index,
  label,
  file,
  closeBtnLeft,
  setCols,
  setRows,
  setCorrectionData,
}) => {
  const [showImagIcon, setShowImagIcon] = useState(false);
  const [showUploadS, setShowUploadS] = useState(file ? true : false);
  const [showDelete, setShowDelete] = useState(false);
  const [loadedImage, setLoadedImage] = useState(false);

  const fileInputRef = useRef(null);
  const handleAddText = (value) => {
    closeBtnLeft
      ? setRows((prev) =>
          prev.map((el, i) =>
            i == index ? { ...el, label: { ...el.label, text: value } } : el
          )
        )
      : setCols((prev) =>
          prev.map((el, i) => (i == index ? { ...el, text: value } : el))
        );
  };
  const handleDeleteCol = () => {
    setCorrectionData((prev) =>
      prev.map((arr) =>
        arr?.filter((el, i) => {
          return i !== index;
        })
      )
    );
    setCols((prev) =>
      prev?.filter((el, i) => {
        return i !== index;
      })
    );
  };

  const handleDeleteRow = () => {
    setCorrectionData((prev) =>
      prev?.filter((el, i) => {
        return i !== index;
      })
    );
    setRows((prev) =>
      prev?.filter((el, i) => {
        return i !== index;
      })
    );
  };
  const handleUploadImage = async (event) => {
    setLoadedImage(true);
    const img = event.target.files[0];
    const data = await uploadImage(img, 900, 900, 'jpeg');

    closeBtnLeft
      ? setRows((prev) =>
          prev.map((el, i) =>
            i == index ? { ...el, label: { ...el.label, file: data } } : el
          )
        )
      : setCols((prev) =>
          prev.map((el, i) => (i == index ? { ...el, file: data } : el))
        );
  };

  const handleDeleteImage = (e) => {
    setLoadedImage(false);
    e.stopPropagation();
    e.preventDefault();
    closeBtnLeft
      ? setRows((prev) =>
          prev.map((el, i) =>
            i == index ? { ...el, label: { ...el.label, file: null } } : el
          )
        )
      : setCols((prev) =>
          prev.map((el, i) => (i == index ? { ...el, file: null } : el))
        );
  };

  return (
    <div
      className={`flex items-center ${closeBtnLeft ? 'flex-row' : 'flex-col'}`}
    >
      {closeBtnLeft ? (
        (!showImagIcon || showUploadS || file) && (
          <IconButton
            Icon={Cross}
            ariaLabel="delete"
            onClick={handleDeleteRow}
            variant="ghost"
            className={`${
              closeBtnLeft && 'ml-2'
            } hover:bg-transparent active:bg-transparent`}
          />
        )
      ) : (
        <IconButton
          Icon={Cross}
          ariaLabel="delete"
          onClick={handleDeleteCol}
          variant="ghost"
          className={`${
            closeBtnLeft && 'ml-2'
          } hover:bg-transparent active:bg-transparent`}
        />
      )}

      <div
        className="flex flex-col gap-2 p-2"
        onMouseEnter={() => setShowImagIcon(true)}
        onMouseLeave={() => setShowImagIcon(false)}
      >
        {showUploadS && (
          <div
            className={`relative flex flex-row items-center justify-center gap-2 rounded bg-grey-01 px-1 ${
              file ? 'py-1' : 'py-5'
            }`}
          >
            {file ? (
              <div
                className="flex h-full w-full"
                onMouseEnter={() => setShowDelete(true)}
                onMouseLeave={() => setShowDelete(false)}
              >
                <img
                  alt={label}
                  src={file}
                  className="m-auto h-16 max-w-[90px] rounded object-cover object-center"
                />
                {showDelete && (
                  <div className="absolute inset-0 flex rounded bg-black/10">
                    <Button
                      size="md"
                      className="m-auto bg-white !p-2"
                      onClick={(e) => handleDeleteImage(e)}
                      variant="secondary"
                    >
                      <img
                        src="/images/icons/trash_icon_red.svg"
                        alt="delete button"
                      />
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <>
                {loadedImage ? (
                  <div className="relative flex flex-row items-center gap-2 p-1">
                    <div className="flex h-7 w-7">
                      <Spinner
                        ariaLabel="Loading spinner"
                        className="m-auto h-6 w-6 border-grey-05"
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <IconButton
                      Icon={Image}
                      ariaLabel="add image"
                      onClick={() => {
                        fileInputRef.current?.click();
                      }}
                      variant="ghost"
                      className="!text-grey-07"
                    />

                    <Button
                      size="sm"
                      className="!p-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setShowUploadS(false);
                      }}
                      variant="ghost"
                    >
                      <img
                        src="/images/icons/trash_icon.svg"
                        alt="delete button"
                      />
                    </Button>
                  </>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUploadImage}
                  accept="image/*"
                  style={{ display: 'none' }}
                  multiple={false}
                />
              </>
            )}
          </div>
        )}
        <div className="flex flex-row gap-2">
          <InputText
            id="text-input"
            onChange={({ target: { value } }) => {
              handleAddText(value);
            }}
            value={label}
            inputClassName=""
          />
          {showImagIcon && !showUploadS && (
            <IconButton
              Icon={Image}
              ariaLabel="add image"
              onClick={() => {
                setShowUploadS(true);
              }}
              variant="ghost"
              className="!text-grey-07"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const MatrixEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );

  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );

  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : false
  );
  const [oneAnswer, setOneAnswer] = useState(state.radioMode);
  const [scoreRow, setScoreRow] = useState(state.radioScoreRow);
  const [changeScoreRow, setChangeScoreRow] = useState(false);
  const [score, setScore] = useState(state.score || 0);

  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.matrix_question')
  );

  const [cols, setCols] = useState(
    state?.cols?.map((col) => {
      if (isString(col)) {
        //import old style cols
        return { text: col };
      }
      return col;
    })
  );
  const [rows, setRows] = useState(
    state?.rows?.map((row) => {
      if (isString(row.label)) {
        //import old style rows
        row.label = { text: row.label };
      }
      return row;
    })
  );

  const [correctionData, setCorrectionData] = useState(state?.data);
  const [competencies, setCompetencies] = useState([]);

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.score = score;
    newState.radioMode = oneAnswer;
    newState.cols = cols;
    newState.rows = rows;
    newState.data = correctionData;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  let CheckBoxType = oneAnswer ? Radio : Checkbox;

  const colWidth = 'calc(100% /' + (cols.length + 1) + ')';

  useEffect(() => {
    scoreRow &&
      setScore(
        rows.reduce(
          (accumulator, el) => accumulator + (el.score ? el.score : 0),
          0
        )
      );
  }, [changeScoreRow]);

  const handleAddCol = () => {
    setCorrectionData((prev) => prev.map((arr) => [...arr, false]));
    setCols((prev) => [...prev, { text: '' }]);
  };

  const handleAddRow = () => {
    setCorrectionData((prev) => [...prev, cols.map((el) => false)]);
    setRows((prev) => [...prev, { label: { text: '' } }]);
  };

  const handleChecked = (indexRow, indexCol) => {
    oneAnswer
      ? setCorrectionData((prev) =>
          prev.map((arr, i) =>
            i == indexRow
              ? arr.map((el, j) => (j == indexCol ? true : false))
              : arr
          )
        )
      : setCorrectionData((prev) =>
          prev.map((arr, i) =>
            i == indexRow ? arr.map((el, j) => (j == indexCol ? !el : el)) : arr
          )
        );
  };

  const handleTypeOfAnswer = (isCheck) => {
    isCheck &&
      setCorrectionData((prev) =>
        prev.map((arr) => arr.map((el, j) => (j == 0 ? true : false)))
      );
    setOneAnswer(isCheck);
  };

  const handleScoreRow = (value, index) => {
    setRows((prev) =>
      prev.map((el, s) => (s == index ? { ...el, score: value } : el))
    );
    setChangeScoreRow(!changeScoreRow);
  };

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100% - 84px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />

      <div>
        {/* <span className="text-lg font-semibold text-grey-04">
          {t('widgets.edit.matrix_question.question')} (
          {t('widgets.edit.matrix_question.optional')})
        </span> */}
        <div className="wg-question">
          <QuestionSection questionVraag={questionVraag} />
        </div>
        <div className="my-4 flex gap-3">
          <Switch
            checked={showAnswer}
            onChange={(isCheck) => setShowAnswer(isCheck)}
          />
          <div className="mr-4">
            {t('widgets.edit.matrix_question.explain_after_submit')}
          </div>
        </div>

        <div className={`${!showAnswer ? 'hidden' : ''}`}>
          {/* <span className="text-lg font-semibold text-grey-04">
            {t('widgets.edit.matrix_question.example_answer')} (
            {t('widgets.edit.matrix_question.optional')})
          </span> */}
          <div className="wg-answer">
            <QuestionSection
              questionVraag={antwoordVraag}
              exampleAnswer={true}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="teo-scrollbar relative overflow-auto pb-2">
          <table
            className="widget-item w-full !border-separate !border-spacing-0 rounded-[10px] border-r border-grey-02"
            style={{ minWidth: `${state.cols.length * 130}px` }}
          >
            <thead>
              <tr>
                <td
                  className="!min-w-[160px] border-y border-l border-grey-02 bg-white"
                  style={{ width: colWidth }}
                ></td>
                {scoreRow && (
                  <td className="w-24 !max-w-[100px] border-y border-l border-grey-02 bg-white p-2">
                    {t('widgets.edit.matrix_question.score_per_row')}
                  </td>
                )}
                {cols?.map((col, j) => (
                  <td
                    key={state.uid + 'col_' + j}
                    className="relative !min-w-[150px] border-y border-l border-grey-02 bg-white p-1 text-xs font-medium"
                    style={{ width: colWidth }}
                  >
                    <RowColHead
                      index={j}
                      label={col?.text}
                      file={col?.file}
                      setCols={setCols}
                      setCorrectionData={setCorrectionData}
                    />
                  </td>
                ))}
                <td className="min-w-[100px] border-y border-l border-grey-02 bg-white px-5">
                  <IconButton
                    Icon={Plus}
                    ariaLabel="Add"
                    onClick={handleAddCol}
                    variant="secondary"
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              {rows?.map((row, i) => (
                <tr key={state.uid + 'row_' + i}>
                  <td className="relative border-l border-b border-grey-02 bg-white text-xs font-medium">
                    <RowColHead
                      index={i}
                      label={row?.label?.text}
                      file={row?.label?.file}
                      closeBtnLeft={true}
                      setRows={setRows}
                      setCorrectionData={setCorrectionData}
                    />
                  </td>
                  {scoreRow && (
                    <td className="border-l border-b border-grey-02 bg-white p-2 text-xs font-medium">
                      <InputScore
                        value={row?.score ? row?.score : 0}
                        onChange={(value) => handleScoreRow(value, i)}
                      />
                    </td>
                  )}
                  {cols?.map((col, j) => {
                    return (
                      <td
                        key={state.uid + 'row_' + i + '_col_' + j}
                        className="bg-white"
                      >
                        <label
                          className="flex cursor-pointer justify-center"
                          htmlFor={state.uid + '_' + i + '_' + j}
                        >
                          <CheckBoxType
                            className="m-auto"
                            onChange={() => handleChecked(i, j)}
                            key={state.uid + i + '___' + j}
                            checked={correctionData[i][j]}
                            name={state.uid + '_' + i + '_' + j}
                          />
                        </label>
                      </td>
                    );
                  })}
                  <td className="bg-grey-02"></td>
                </tr>
              ))}
              <tr>
                <td className="bg-white px-5 py-2 text-center">
                  <IconButton
                    Icon={Plus}
                    ariaLabel="Add"
                    onClick={handleAddRow}
                    variant="secondary"
                  />
                </td>
                {scoreRow && <td></td>}
                {cols?.map((col, i) => (
                  <td
                    key={state.uid + 'empty_' + i}
                    className="bg-grey-02"
                  ></td>
                ))}
                <td className="bg-grey-02"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div className="mt-4 flex flex-row items-center border-t border-b border-grey-02 py-2">
          <Switch
            checked={oneAnswer}
            onChange={(isCheck) => {
              handleTypeOfAnswer(isCheck);
            }}
          />
          <div className="mr-4 border-r-2 border-grey-02 px-4">
            {t('widgets.edit.matrix_question.one_answer_per_row')}
          </div>
          <Switch
            checked={scoreRow}
            onChange={(isCheck) => setScoreRow(isCheck)}
          />
          <div className="mr-4 border-r-2 border-grey-02 px-4">
            {t('widgets.edit.matrix_question.score_per_row')}
          </div>
          <div className="mr-2">
            {t('widgets.edit.matrix_question.max_score')}
          </div>
          <InputScore
            value={score}
            onChange={(value) => !scoreRow && setScore(value)}
            disabled
          />
        </div>
        {/*
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>
      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

import { Button, Modal } from '@teo/components';
import { useTranslation } from 'react-i18next';

import { axios } from '@/axios';
import { config } from '@/config';
import { newUid } from '@/util';

const DeleteModal = ({
  closeModal,
  setIsOpenDeleteModal,
  title,
  heading,
  text,
  organisationId,
  handleDelete,
  selectedUser,
  setSelectedUser,
  setRefetchTable,
  deleteUsers,
}: any) => {
  const { t } = useTranslation();
  const handleDeleteUser = () => {
    if (deleteUsers) {
      const promises: any = [];
      [...selectedUser]?.map((team: any) => {
        promises.push(
          axios.delete(`${config.backend}/member/${organisationId}/${team}`)
        );
      });
      Promise.allSettled(promises).then(() => {
        setRefetchTable(newUid(20));
        setSelectedUser(new Set());
        setIsOpenDeleteModal(false);
      });
    } else {
      handleDelete();
    }
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-lg  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            <h2 className="text-sm font-semibold">{title}</h2>
          </Modal.Header>

          <Modal.Body>
            <div className="flex flex-col gap-5">
              <div>
                <h3 className="mb-3 text-lg">{heading}</h3>
                <p className="text-base text-grey-06">{text}</p>
              </div>
              <div className="flex justify-between">
                <Button
                  variant="ghost"
                  onClick={() => setIsOpenDeleteModal(false)}
                >
                  {t('button.cancel')}
                </Button>
                <Button variant="destruct" onClick={() => handleDeleteUser()}>
                  {t('button.delete')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;

import { Modal, Button, PersonAvatar, Dropdown } from '@teo/components';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { ArrowLeft, Tag, MoreVertical } from '@teo/components/icons';
import { format } from 'date-fns';
import { convertToTime, newUid } from '@/util';
import { axios } from '@/axios';
import { config } from '@/config';
import { uploadLesson } from '@/query/documents';
import { ImportAction } from './ImportAction';

export const ActiveLesson = ({
  closeModal,
  activeLesson,
  setLessonSelected,
  setIsOpenLibrary,
  coursecontentId,
  prevTree,
  filterInput,
  setAddLesson,
  parentPath,
  parentId,
  setTree,
  isExtraInfo,
  isHomePage,
  droppable,
  extraInfoTree,
  publication,
  currentId,
  auth,
}: any) => {
  const { t } = useTranslation();

  const [organisation, setOrganisation] = useState<any>();
  const [author, setAuthor] = useState<any>();
  const [isOrganisation, setIsOrganisation] = useState<any>(true);
  const [tags, setTags] = useState<any>();
  //const [createLessonId, setCreateLessonId] = useState();
  const [createCopyId, setCreateCopyId] = useState<any>();
  const [newLesson, setNewLesson] = useState<any>();
  const [content, setContent] = useState<any>();
  const [extraLes, setExtraLes] = useState<any>(null);

  const [imageZoom, setImageZoom] = useState(false);
  const basePath = window.location.href.slice(
    0,
    window.location.href.lastIndexOf('/')
  );

  useGet(`/lessonextrainfos`, {
    enabled: !isExtraInfo && !!activeLesson?.lessonId,
    rootId: activeLesson?.lessonId,
    onSuccess: (result: any) => {
      setExtraLes(result?.data || null);
    },
  });

  useGet(
    `/organisation/${
      currentId
        ? auth?.user?.activeOrganisationId
        : activeLesson?.organisationId
    }`,
    {
      enabled: currentId
        ? !!auth?.user?.activeOrganisationId
        : !!activeLesson?.organisationId,
      onSuccess: (result: any) => {
        setOrganisation(result?.data || null);
      },
      onError: (res: any) => {
        setIsOrganisation(false);
      },
    }
  );

  useGet('/memberdetails', {
    organisationId: auth?.user?.activeOrganisationId,
    userId: publication?.authorId,
    onSuccess: (result: any) => {
      setAuthor(result?.data?.[0] || {});
    },
  });

  useEffect(() => {
    if (activeLesson?.tag) {
      const data = activeLesson?.tag?.split(';;');
      const filteredTags = data.filter(
        (item: any) => item.toLowerCase() !== activeLesson?.name?.toLowerCase()
      );

      setTags(
        filteredTags
          ?.filter((x: any) => x?.startsWith('tag:'))
          ?.map((el: any) => el?.split('tag:')[1])
      );
    }
  }, [activeLesson?.tag]);

  const handleNewLesson = async (edit: any) => {
    let lesson;
    if (activeLesson?.lessonId) {
      lesson = await axios.get(
        `${config.backend}/lesson/${activeLesson?.lessonId}?write_access=true`
      );
    } else {
      lesson = await axios.get(
        `${config.backend}/asset/${activeLesson?.assetId}`
      );
    }
    setNewLesson({ les: lesson?.data, edit });
  };

  useEffect(() => {
    if (newLesson?.les?.id) {
      if (newLesson?.edit) {
        copyLesson();
      } else {
        if (activeLesson?.lessonId) {
          createLesson(activeLesson?.lessonId, newLesson?.edit);
        } else {
          createLesson(activeLesson?.assetId, newLesson?.edit);
        }
      }
    }
  }, [newLesson?.les?.id]);

  const copyLesson = async () => {
    let data;
    if (activeLesson?.lessonId) {
      data = await axios.post(`${config.backend}/lessons`, {
        name: activeLesson?.name,
        canEdit: true,
        content: '',
        description: '',
      });
    } else {
      data = await axios.post(`${config.backend}/assets`, {
        assetName: activeLesson?.assetName,
        canEdit: true,
        assetType: activeLesson?.assetType,
        assetPath: activeLesson?.assetPath,
      });
    }
    setCreateCopyId(data?.data);
  };

  useEffect(() => {
    if (createCopyId?.id && content && activeLesson?.lessonId) {
      createLesson(createCopyId?.id, true);
      uploadLesson(createCopyId?.postData, content);
    }
    if (createCopyId?.id && activeLesson?.assetId) {
      createLesson(createCopyId?.id, true);
    }
  }, [createCopyId?.id, content]);

  const createLesson = async (id: any, edit: any) => {
    let data;
    if (activeLesson?.lessonId) {
      data = await axios.post(`${config.backend}/uses`, {
        canEdit: edit,
        coursecontentId: parseInt(coursecontentId, 10),
        lessonId: id,
        path: `/${id}`,
        yindex: 0,
      });
    } else {
      data = await axios.post(`${config.backend}/uses`, {
        canEdit: edit,
        coursecontentId: parseInt(coursecontentId, 10),
        assetId: id,
      });
    }

    const createExtraInfoImageId = data?.data?.assetId;
    const createLessonId = data?.data?.lessonId;
    const createExtraInfoUsesId = data?.data?.id;

    const newPath = parentPath
      ? `${parentPath}/${
          edit
            ? activeLesson?.lessonId
              ? createLessonId
              : createExtraInfoImageId
            : createExtraInfoUsesId
        }`
      : `/${
          edit
            ? activeLesson?.lessonId
              ? createLessonId
              : createExtraInfoImageId
            : createExtraInfoUsesId
        }`;

    let newYindex: any;
    let parentTree: any;

    if (isExtraInfo) {
      parentTree = extraInfoTree;
    } else {
      if (parentId) {
        parentTree = prevTree.filter((item: any) => item?.parent === parentId);
      } else {
        parentTree = prevTree.filter((item: any) => item?.parent === 0);
      }
    }

    if (parentTree?.length) {
      newYindex =
        parentTree?.reduce((max: any, obj: any) => {
          return obj?.data?.yindex > max ? obj?.data?.yindex : max;
        }, parentTree[0]?.data?.yindex) + 1;
    } else {
      newYindex = 0;
    }

    if (isExtraInfo) {
      axios.put(`${config.backend}/use/${data?.data?.id}`, {
        assetId: createExtraInfoImageId,
        coursecontentId: parseInt(coursecontentId, 10),
        lessonId: createLessonId,
        onlyTheory: true,
        rootDirectoryId: isHomePage ? parentId : droppable ? parentId : null,
        rootLessonId: !isHomePage && !droppable ? parentId : null,
        path: newPath,
        yindex: newYindex,
      });
      if (activeLesson?.lessonId || activeLesson?.assetId) {
        setAddLesson({
          ...newLesson?.les,
          assetId: createExtraInfoImageId,
          canEdit: newLesson?.edit,
          coursecontentId: parseInt(coursecontentId, 10),
          onlyTheory: true,
          id: activeLesson?.lessonId ? createLessonId : createExtraInfoImageId,
          lessonId: createLessonId,
          rootDirectoryId: isHomePage ? parentId : droppable ? parentId : null,
          rootLessonId: !isHomePage && !droppable ? parentId : null,
          path: newPath,
          usesId: createExtraInfoUsesId,
          yindex: newYindex,
        });
      }
    } else {
      if (extraLes && extraLes?.length > 0) {
        for (const eInfo of extraLes) {
          if (eInfo?.lessonId || eInfo?.assetId) {
            if (eInfo?.lessonId) {
              const lesson = await axios.get(
                `${config.backend}/lesson/${eInfo?.lessonId}?write_access=true`
              );
              const linkLes = await axios.post(`${config.backend}/uses`, {
                canEdit: false,
                coursecontentId: parseInt(coursecontentId, 10),
                lessonId: eInfo?.lessonId,
                path: `/${eInfo?.lessonId}`,
                yindex: 0,
              });

              await setAddLesson({
                ...linkLes?.data,
                canEdit: false,
                coursecontentId: parseInt(coursecontentId, 10),
                id: linkLes?.data?.id,
                lessonId: eInfo?.lessonId,
                name: eInfo?.name,
                postData: lesson?.data.postData,
                getData: lesson?.data?.getData,
                usesId: linkLes?.data?.id,
                path: `${newPath}/${linkLes?.data?.id}`,
                yindex: eInfo?.data?.yindex,
                rootLessonId: createLessonId,
              });

              await axios.put(`${config.backend}/use/${linkLes?.data?.id}`, {
                coursecontentId: parseInt(coursecontentId, 10),
                lessonId: eInfo?.lessonId,
                path: `${newPath}/${linkLes?.data?.id}`,
                yindex: eInfo?.data?.yindex,
                rootLessonId: createLessonId,
              });
            } else {
              const newImg = await axios.post(`${config.backend}/assets`, {
                assetName: eInfo?.assetName,
                canEdit: true,
                assetType: eInfo?.assetType,
                assetPath: eInfo?.assetPath,
              });
              const linkImg = await axios.post(`${config.backend}/uses`, {
                canEdit: false,
                coursecontentId: parseInt(coursecontentId, 10),
                assetId: newImg?.data?.id,
              });

              await setAddLesson({
                ...linkImg?.data,
                assetId: newImg?.data?.id,
                assetType: eInfo?.assetType,
                assetPath: eInfo?.assetPath,
                assetName: eInfo?.assetName,
                canEdit: false,
                coursecontentId: parseInt(coursecontentId, 10),
                onlyTheory: true,
                id: newImg?.data?.id,
                path: `${newPath}/${newImg?.data?.id}`,
                yindex: eInfo?.data?.yindex,
                rootLessonId: createLessonId,
                usesId: linkImg?.data?.id,
              });
              await axios.put(`${config.backend}/use/${linkImg?.data?.id}`, {
                coursecontentId: parseInt(coursecontentId, 10),
                assetId: newImg?.data?.assetId,
                path: `${newPath}/${newImg?.data?.id}`,
                yindex: eInfo?.data?.yindex,
                rootLessonId: createLessonId,
              });
            }
          }
        }
      }

      axios.put(`${config.backend}/use/${data?.data?.id}`, {
        coursecontentId: parseInt(coursecontentId, 10),
        lessonId: createLessonId,
        path: newPath,
        yindex: newYindex,
      });
      await setAddLesson({
        ...newLesson?.les,
        canEdit: newLesson?.edit,
        coursecontentId: parseInt(coursecontentId, 10),
        id: createLessonId,
        numbered: true,
        lessonId: createLessonId,
        path: newPath,
        usesId: createExtraInfoUsesId,
        yindex: newYindex,
      });
    }
    setTree(null);
    setIsOpenLibrary(false);
    setLessonSelected(false);
  };

  return (
    <div>
      <Modal.Header onClose={closeModal}>
        <div className="flex w-full flex-row pr-3">
          <Button
            className="flex items-center gap-3"
            variant="text"
            onClick={() => setLessonSelected(false)}
          >
            <ArrowLeft className="h-5 w-5" />
            <p>{t('pages.create_edit_course.library.back_library')}</p>
          </Button>
          <div className="ml-auto flex flex-row gap-3">
            {!activeLesson?.assetId ? (
              <>
                <Button
                  variant="outline"
                  onClick={() =>
                    window.open(
                      `${basePath}/${activeLesson?.coursecontentId}/lesson/${activeLesson?.lessonId}/preview?new_tab=true`
                    )
                  }
                >
                  {t('pages.create_edit_course.library.preview')}
                </Button>

                <Button
                  variant="primary"
                  onClick={() => handleNewLesson(false)}
                  disabled={
                    isExtraInfo
                      ? parentId === activeLesson?.rootLessonId ||
                        parentId === activeLesson?.rootDirectoryId
                      : publication?.id === activeLesson?.coursecontentId
                  }
                >
                  {t('pages.create_edit_course.library.linking')}
                </Button>
              </>
            ) : (
              <Button variant="primary" onClick={() => handleNewLesson(true)}>
                {t('pages.create_edit_course.library.duplicate')}
              </Button>
            )}
          </div>
        </div>
      </Modal.Header>
      <div className="max-h-[75vh] p-6">
        <div className="flex flex-col">
          <div>
            {activeLesson?.lessonId ? (
              <img
                src={
                  activeLesson?.publicationImage
                    ? activeLesson?.publicationImage
                    : '/assets/images/opleidingen.jpg'
                }
                alt={activeLesson?.name}
                className="max-h-80 w-full rounded-md object-cover"
              />
            ) : activeLesson?.assetType === 'image' ? (
              <div className="flex">
                <img
                  src={activeLesson?.assetPath}
                  alt={activeLesson?.assetName || activeLesson?.name}
                  className="m-auto max-h-[50vh] cursor-zoom-in rounded-md object-contain"
                  onClick={() => setImageZoom(!imageZoom)}
                />
                {imageZoom && (
                  <div
                    className="fixed inset-0 z-[20] flex h-[100vh] max-h-[100vh] w-[100vw] max-w-[100vw] overflow-auto bg-black/50 p-8"
                    style={{
                      transform: 'translate(calc((-100vw + 800px)/2), -32px)',
                    }}
                  >
                    <img
                      id="zoom-image"
                      src={activeLesson?.assetPath}
                      alt={activeLesson?.assetName}
                      className={`mx-auto max-h-[100vh] w-full object-contain ${
                        imageZoom ? 'cursor-zoom-out' : 'cursor-zoom-in'
                      }`}
                      onClick={() => setImageZoom(!imageZoom)}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div id={`${newUid(20)}_embed`} className="mx-auto">
                <iframe
                  id={`${newUid(20)}_placeholder`}
                  width="640"
                  height="360"
                  className="m-auto"
                  src={activeLesson?.assetPath}
                ></iframe>
              </div>
            )}
          </div>
          <div className="flex flex-row items-center py-5">
            <p className="pr-2 text-xl text-grey-06">
              {t('pages.create_edit_course.library.lesson')}:
            </p>
            <h1 className="text-xl">
              {activeLesson?.name || activeLesson?.assetName}
            </h1>
            <div className="ml-auto">
              <Dropdown>
                <Dropdown.Trigger>
                  <div className="rounded-md p-1 hover:bg-grey-transparent-01">
                    <MoreVertical className="h-5 w-5" />
                  </div>
                </Dropdown.Trigger>
                <Dropdown.Content>
                  <Dropdown.Actions>
                    {!activeLesson?.assetId && (
                      <>
                        <Button
                          className="w-full !rounded !p-3 !text-left font-normal"
                          size="lg"
                          variant="ghost"
                          onClick={() => handleNewLesson(false)}
                          disabled={
                            isExtraInfo
                              ? parentId === activeLesson?.rootLessonId ||
                                parentId === activeLesson?.rootDirectoryId
                              : publication?.id ===
                                activeLesson?.coursecontentId
                          }
                        >
                          {t('pages.create_edit_course.library.linking')}
                        </Button>

                        <Button
                          className="w-full !rounded !p-3 !text-left font-normal"
                          size="lg"
                          variant="ghost"
                          onClick={() =>
                            window.open(
                              `${basePath}/${activeLesson?.coursecontentId}/lesson/${activeLesson?.id}/preview`
                            )
                          }
                        >
                          {t('pages.create_edit_course.library.preview')}
                        </Button>
                      </>
                    )}
                    <Button
                      className="w-full !rounded !p-3 !text-left font-normal"
                      size="lg"
                      variant="ghost"
                      onClick={() => handleNewLesson(true)}
                    >
                      {t('pages.create_edit_course.library.duplicate')}
                    </Button>
                  </Dropdown.Actions>
                </Dropdown.Content>
              </Dropdown>
            </div>
          </div>
          <div className="mb-5 flex flex-wrap items-center">
            {isOrganisation && (
              <div
                className="'h-8 mr-3 inline-flex min-h-8 w-8 min-w-8 items-center justify-center overflow-hidden"
                title={organisation?.organisationName}
              >
                <span className="z-10">
                  {' '}
                  <img
                    className="h-full object-contain"
                    src={organisation?.picture || '/images/TEO_logo.svg'}
                    alt={organisation?.organisationName || ''}
                  />
                </span>
              </div>
            )}
            {(activeLesson?.authorId || author?.id) && (
              <PersonAvatar
                inline
                id={currentId ? author?.id : activeLesson?.authorId}
                name={currentId ? author?.username : activeLesson?.authorName}
                imageSrc={
                  currentId
                    ? author?.picture
                      ? author?.picture
                      : ''
                    : activeLesson?.authorPicture
                    ? activeLesson?.authorPicture
                    : ''
                }
              ></PersonAvatar>
            )}
            <div>
              <p className="text-xs text-grey-07">
                {(activeLesson?.authorName || author?.id) && (
                  <>
                    {t('pages.create_edit_course.library.written_by')}{' '}
                    {currentId ? author?.username : activeLesson?.authorName}{' '}
                    {t('pages.create_edit_course.library.from')}{' '}
                  </>
                )}
                <span className="font-semibold">
                  {organisation?.organisationName}
                </span>
                <span className="-mt-1 px-2">|</span>
              </p>
            </div>
            <p className="text-xs text-grey-07">
              {activeLesson?.updatedAt &&
                t('pages.create_edit_course.library.last_updated') +
                  ' ' +
                  format(new Date(activeLesson?.updatedAt), 'dd MMM yyyy')}
            </p>
            {activeLesson?.estimatedTime > 0 && (
              <span className="flex flex-row items-center gap-1 text-xs text-grey-07">
                <span className="-mt-1 px-2">|</span>
                <p>
                  <span className="pr-1">
                    {t('pages.create_edit_course.library.reading_time')}
                  </span>
                  {convertToTime(
                    Math.abs(activeLesson?.estimatedTime),
                    t('pages.learn_index.hour'),
                    t('pages.learn_index.minute')
                  )}
                </p>
              </span>
            )}
          </div>
          {tags?.length > 0 && (
            <div className="mb-5 flex flex-wrap items-center gap-3">
              <Tag className="h-5 w-5 text-grey-07" />
              {tags?.map((el: any, i: any) => {
                return (
                  <div
                    key={i}
                    className="inline-flex items-center gap-1 rounded bg-grey-transparent-02 py-0.5 px-2 text-sm font-medium text-grey-08"
                  >
                    {el}
                  </div>
                );
              })}
            </div>
          )}

          <p className="mb-5 text-sm text-grey-08">
            {activeLesson?.description}
          </p>
        </div>
      </div>
      {newLesson?.les?.id && (
        <ImportAction les={newLesson?.les} setContent={setContent} />
      )}
    </div>
  );
};

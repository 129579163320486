import { Outlet, useNavigate } from 'react-router-dom';
import Protected from './Protected';
import UnProtected from './UnProtected';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { NavContext } from '@/components/NavContext';
import Footer from '@/components/Footer/Footer';
import CookieWarning from '@/components/CookieWarning/CookieWarning';
import i18n from 'i18next';
import { KioskNav } from '@/components/KioskNav';
import UpdateModal from '@/components/UpdateModal/UpdateModal';

function RootPage() {
  const { auth } = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [navDisabled, setNavDisabled] = useState<boolean>(false);
  const [submenu, setSubmenu] = useState<boolean>(false);
  const navigate = useNavigate();

  const isUpdate = false;

  /*
  useEffect(() => {
    if (
      !auth &&
      /.*?\/create|\/follow-up|\/manage.*?/.test(window.location.pathname)
    ) {
      console.log("navigate to login")
      navigate(`/${i18n.language}/login`);
      return;
    }
  }, []);
  */

  return (
    <NavContext.Provider
      value={{
        collapsed,
        setCollapsed,
        navDisabled,
        setNavDisabled,
        submenu,
        setSubmenu,
      }}
    >
      <>
        {localStorage.getItem('koffer') && <KioskNav />}
        {auth ? (
          <Protected>
            <Outlet />
            <Footer />
            <CookieWarning />
          </Protected>
        ) : (
          <UnProtected>
            <Outlet />
            <CookieWarning />
          </UnProtected>
        )}
        {isUpdate && <UpdateModal />}
      </>
    </NavContext.Provider>
  );
}

export default RootPage;

import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Label,
  InputText,
  Switch,
  RadioGroup,
} from '@teo/components';
import { useState, useEffect } from 'react';
import ColorPicker from '@/components/ColorPicker';
import { config } from '@/config';
import { axios } from '@/axios';
import { useParams } from 'react-router-dom';
import { useGet } from '@/query/fetchHooks';
import { ParentSelectList } from './ParentSelectList';

function EditTeamModal({
  isOpenEditTeam,
  closeModal,
  isTeamIndex,
  onChange,
}: any) {
  const { t } = useTranslation();
  const [team, setTeam] = useState<any>(null);

  const [teamName, setTeamName] = useState('');
  const [teamStatus, setTeamStatus] = useState<any>(null);
  const [colorPicker, setColorPicker] = useState<any>(null);
  const { teamId, organisationId } = useParams();
  const [mainTeam, setMainTeam] = useState<any>(null);
  const [prevParent, setPrevParent] = useState<any>(null);
  const [mainSubTeam, setMainSubTeam] = useState<any>(null);
  const [disabledList, setDisabledList] = useState<any>(null);
  const [isCoursesInactive, setIsCoursesInactive] = useState<any>('yes');
  const [listCourse, setListCourse] = useState<any>([]);

  useGet(`/team/${organisationId}/${isTeamIndex}`, {
    onSuccess: (result: any) => {
      setTeam(result?.data || null);
    },
  });

  useEffect(() => {
    if (team) {
      const parentPath = team?.teamPath.lastIndexOf(';;');
      if (parentPath !== -1) {
        const result = team?.teamPath.substring(0, parentPath);
        const searchPath = encodeURIComponent(result);
        axios
          .get(
            `${config.backend}/teams?organisationId=${team?.organisationId}&searchPath=${searchPath}&sort=teamPath`
          )
          .then((result: any) => {
            const parenTeam = result?.data[0];
            parenTeam.label = parenTeam.teamName;
            setMainTeam(parenTeam);
            setPrevParent(parenTeam);
          });
      }

      const modifiedTeamPath = encodeURIComponent(team?.teamPath + '%');
      axios
        .get(
          `${config.backend}/teams?organisationId=${team?.organisationId}&searchPath=${modifiedTeamPath}&sort=teamPath`
        )
        .then((result: any) => {
          setDisabledList(result?.data);
          setMainSubTeam(result?.data?.filter((el: any) => el.id !== team.id));
        });
    }
  }, [team]);

  useEffect(() => {
    if (team) {
      setColorPicker(team?.color ? team?.color : '#009e7a');
      setTeamStatus({
        isCheck: team?.teamStatus === 'active' ? true : false,
        status: team?.teamStatus,
      });
    }
  }, [team]);

  // function editTeam() {
  //   let prevPath;
  //   const teamPath = team?.teamPath;
  //   const delimiter = ';;';
  //   const parentPath = teamPath.lastIndexOf(delimiter);
  //   if (parentPath !== -1 && parentPath + delimiter.length < teamPath.length) {
  //     prevPath = teamPath.substring(parentPath + delimiter.length);
  //   } else {
  //     prevPath = teamPath;
  //   }

  //   const newPath =
  //     (mainTeam?.teamPath ? mainTeam?.teamPath + ';;' : '') + prevPath;

  //   const promises = [];

  //   for (const sub of mainSubTeam) {
  //     const subPath = sub?.teamPath;
  //     const newSubPath = subPath.replace(teamPath, newPath);
  //     promises.push(
  //       axios.put(`${config.backend}/team/${organisationId}/${sub.id}`, {
  //         teamPath: newSubPath,
  //         teamStatus: teamStatus?.status,
  //         updatedAt: new Date().toJSON(),
  //       })
  //     );
  //   }
  //   Promise.allSettled(promises).then(() => {
  //     axios
  //       .put(`${config.backend}/team/${organisationId}/${team.id}`, {
  //         teamName: teamName ? teamName : team.teamName,
  //         color: colorPicker ? colorPicker : team.color,
  //         teamPath: newPath,
  //         teamStatus: teamStatus?.status,
  //         updatedAt: new Date().toJSON(),
  //       })
  //       .then((data) => onChange(data))
  //       .then(() => closeModal(true))
  //       .then(() => window.location.reload());
  //   });
  // }

  async function editTeam() {
    let prevPath;
    const teamPath = team?.teamPath;
    const delimiter = ';;';
    const parentPath = teamPath.lastIndexOf(delimiter);

    if (parentPath !== -1 && parentPath + delimiter.length < teamPath.length) {
      prevPath = teamPath.substring(parentPath + delimiter.length);
    } else {
      prevPath = teamPath;
    }

    const newPath =
      (mainTeam?.teamPath ? mainTeam?.teamPath + ';;' : '') + prevPath;

    try {
      if (
        listCourse.length > 0 &&
        isCoursesInactive === 'yes' &&
        teamStatus?.status === 'disabled'
      ) {
        await Promise.allSettled(
          listCourse.map((id: any) =>
            axios.put(`${config.backend}/course/${id}`, {
              courseStatus: 'disabled',
            })
          )
        );
      }

      const promises = mainSubTeam.map((sub: any) => {
        const subPath = sub?.teamPath;
        const newSubPath = subPath.replace(teamPath, newPath);
        return axios.put(`${config.backend}/team/${organisationId}/${sub.id}`, {
          teamPath: newSubPath,
          teamStatus: teamStatus?.status,
          updatedAt: new Date().toJSON(),
        });
      });

      await Promise.allSettled(promises);

      await axios.put(`${config.backend}/team/${organisationId}/${team.id}`, {
        teamName: teamName ? teamName : team.teamName,
        color: colorPicker ? colorPicker : team.color,
        teamPath: newPath,
        teamStatus: teamStatus?.status,
        updatedAt: new Date().toJSON(),
      });

      onChange();
      closeModal(true);
      window.location.reload();
    } catch (error) {
      console.error('Помилка оновлення команди та курсів:', error);
    }
  }

  const findAllCourse = async (teamIds: number[]) => {
    if (!teamIds || teamIds.length === 0) return;

    try {
      const requests = teamIds.map((id) =>
        axios.get(
          `${config.backend}/teamcoursedetails?teamId=${id}&courseStatus=active`
        )
      );

      const responses = await Promise.all(requests);

      const allCourses = responses.flatMap((response) => response.data);

      const uniqueCourseIds = [
        ...new Set(allCourses.map((course) => course.id)),
      ];

      setListCourse(uniqueCourseIds);
    } catch (error) {
      console.error('Помилка отримання курсів:', error);
    }
  };

  useEffect(() => {
    if (team && mainSubTeam) {
      const allSelectTeam = mainSubTeam?.concat(team)?.map((el: any) => el?.id);

      findAllCourse(allSelectTeam);
    }
  }, [team, mainSubTeam]);

  return (
    <div className="bg-white">
      <Modal isOpen={isOpenEditTeam}>
        <Modal.Header onClose={closeModal}>
          <h2
            className="text-sm font-semibold"
            data-testid="editCategoriesHeader"
          >
            {t('pages.manage_teams.team_settings')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <h1 className="text-base font-semibold">
            {t('pages.manage_teams.team_settings')}
          </h1>
          <p className="mt-2 text-sm font-normal text-[#7d7d81]">
            {t('pages.manage_teams.edit_team_subtitle')}
          </p>

          <form
            className="w-full max-w-[440px] flex-col max-md:h-full max-md:overflow-hidden"
            data-testid="editTeamForm"
          >
            {team ? (
              <>
                <div>
                  <Label htmlFor="teamName">
                    <p className="mt-6 text-sm font-semibold">
                      {t('pages.manage_teams.team_name_label')}
                    </p>
                  </Label>
                  <InputText
                    id="teamName"
                    type="text"
                    onChange={(e) => setTeamName(e.target.value)}
                    placeholder={team.teamName}
                    value={teamName}
                  />
                </div>

                <div>
                  <Label htmlFor="teamName">
                    <p className="mt-6 text-sm font-semibold">
                      {t('pages.manage_teams.parent_team_label')}
                    </p>
                  </Label>
                  <ParentSelectList
                    value={mainTeam}
                    onChange={(value: any) => {
                      setMainTeam(value);
                    }}
                    fetch={useGet.bind(null, '/teams', {
                      organisationId,
                    })}
                    defaultSort={[{ id: 'teamPath', desc: false }]}
                    render={(row: any) => {
                      return row?.teamName;
                    }}
                    disabledList={disabledList}
                  />
                </div>
                <div>
                  <Label htmlFor="colour">
                    <p className="mt-6 text-sm font-semibold">
                      {t('pages.manage_teams.colour_label')}
                    </p>
                  </Label>
                  <div id="colour">
                    <ColorPicker
                      colorPicker={colorPicker}
                      setColorPicker={setColorPicker}
                    />
                  </div>
                </div>
                <div className="mt-4 flex flex-col gap-3 rounded-lg border border-grey-02 py-4">
                  <div className="flex flex-row items-center gap-4 px-4">
                    <div data-testid="teamStatys">
                      <Switch
                        checked={teamStatus?.isCheck}
                        onChange={(isCheck) => {
                          setTeamStatus({
                            isCheck: isCheck,
                            status: isCheck ? 'active' : 'disabled',
                          });
                        }}
                      />
                    </div>
                    <div>
                      <h4 className="font-normal">
                        {t('pages.manage_teams.team_active')}
                      </h4>
                    </div>
                  </div>
                  {!teamStatus?.isCheck && (
                    <div className="flex flex-col border-t border-t px-4 pt-4">
                      <h4 className="text-sm font-semibold">
                        {t('pages.manage_teams.inactive_course')}
                      </h4>

                      <RadioGroup
                        label={''}
                        onChange={(value: string) => {
                          setIsCoursesInactive(value);
                        }}
                        value={isCoursesInactive}
                      >
                        <div className="flex w-full flex-col gap-3">
                          <div
                            className={`w-full rounded-lg border border-grey-02 p-3 ${
                              isCoursesInactive == 'yes' &&
                              'border-secondary-04'
                            }`}
                          >
                            <RadioGroup.Option value="yes">
                              <span className="text-sm" data-testid="radio_btn">
                                {t('pages.manage_teams.yes')}
                              </span>
                            </RadioGroup.Option>
                          </div>
                          <div
                            className={`w-full rounded-lg border border-grey-02 p-3 ${
                              isCoursesInactive == 'no' && 'border-secondary-04'
                            }`}
                          >
                            <RadioGroup.Option value="no">
                              <span className="text-sm">
                                {t('pages.manage_teams.no')}
                              </span>
                            </RadioGroup.Option>
                          </div>
                        </div>
                      </RadioGroup>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </form>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('pages.manage_teams.cancel_button')}
          onCancel={closeModal}
        >
          <div data-testid="editTeamFooter">
            <Button
              type="button"
              onClick={() => editTeam()}
              disabled={
                colorPicker === '#009e7a' &&
                teamName.length < 1 &&
                prevParent?.id == mainTeam?.id &&
                teamStatus?.status == team?.teamStatus
              }
            >
              {t('button.save')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditTeamModal;

import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Tabs } from '@teo/components';
import { useGet } from '@/query';
import { T } from '@/components/T';
import { useTranslation } from 'react-i18next';
import AccountPage from './Account';
import EducationAndWorkPage from '@/pages/Protected/Profile/EducationAndWorkPage';
import { axios } from '@/axios';
import { config } from '@/config';

function ProfileDetailsPage() {
  const { t } = useTranslation();
  const [tab, setTab] = useState('account');
  const [organisation, setOrganisation] = useState<any>(null);

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;
  const organisationId = authUser?.activeOrganisationId;

  useEffect(() => {
    if (organisationId) {
      axios
        .get<any>(`${config.backend}/organisation/${organisationId}`)
        .then((response) => {
          setOrganisation(response?.data || {});
        });
    } else {
      setOrganisation({});
    }
  }, [organisationId]);

  if (!organisation) return null;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{t('pages.profile_details.title')}</T>
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div>
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.TabButton name="account">
              <p className="text-xs font-medium md:text-base lg:text-base">
                {t('links.account')}
              </p>
            </Tabs.TabButton>
            {(organisation?.educationPreferences ||
              organisation?.workPreferences ||
              !organisationId) && (
              <Tabs.TabButton name="education">
                <p className="text-xs font-medium md:text-base lg:text-base">
                  {t('links.education_work')}
                </p>
              </Tabs.TabButton>
            )}
            <Tabs.TabButton name="certificates">
              <p className="text-xs font-medium md:text-base lg:text-base">
                {t('links.certificates')}
              </p>
            </Tabs.TabButton>
          </Tabs>
        </div>
      </div>
      <div className="mt-6">{tab === 'account' && <AccountPage />}</div>
      <div>
        {tab === 'education' && (
          <EducationAndWorkPage
            organisationSettings={organisation}
            organisationId={organisationId}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileDetailsPage;

import {
  Button,
  InputSelect,
  InputText,
  Label,
  Stepper,
} from '@teo/components';
import { T } from '@/components/T';
import { useGet } from '@/query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMobile } from '@/util';
import { CompetencesOptionsInterface } from '@/models/competencesOptions.interface';
import { sectorsData } from '@/mocks/competencesOptionsData';
import AddCompetencyStep from '../AddCompetencyProfilePage/AddCompetencyStep';
import { useParams } from 'react-router-dom';

const MAXSTEP = 2;

function EditCompetencyProfileStepsContainer(props: any) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { organisationId } = useParams();

  const [step, setStep] = useState(1);

  return (
    <>
      <Stepper>
        <Stepper.Item
          isActive={step === 1}
          isDone={step > 1}
          onClick={() => setStep(1)}
          step="1"
        >
          {(!isMobile || step === 1) && (
            <T>{t('pages.manage_competencies.competency_profile_details')}</T>
          )}
        </Stepper.Item>
        <Stepper.Item
          isActive={step === 2}
          isDone={step > 2}
          onClick={() => setStep(2)}
          step="2"
        >
          {(!isMobile || step === 2) && (
            <T>{t('pages.manage_competencies.add_competencies')}</T>
          )}
        </Stepper.Item>
      </Stepper>

      <div className="mx-auto mt-8 flex max-w-screen-md flex-col justify-center px-2">
        {step === 1 && (
          <>
            <h1 className="text-base font-semibold">
              {t('pages.manage_competencies.competency_profile_details')}
            </h1>

            <form
              className="w-full flex-col max-md:h-full max-md:overflow-hidden"
              data-testid="editCompetencyProfileForm"
            >
              <div>
                <Label htmlFor="competence">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.manage_competencies.profiel_label')}
                  </p>
                </Label>
                <InputText
                  id="competence"
                  type="text"
                  onChange={(e) => props.setCompetence(e.target.value)}
                  placeholder={
                    props.professions.name
                      ? props.professions.name
                      : t('pages.manage_competencies.description_placeholder')
                  }
                  value={props.competence}
                  data-testid="editCompetenceName"
                />
              </div>

              <div className="max-w-[380px]">
                <Label htmlFor="sector">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.manage_competencies.sector')}
                  </p>
                </Label>
                <div id="sector">
                  <InputSelect<CompetencesOptionsInterface>
                    onChange={(sector) => {
                      props.setSectors(sector);
                    }}
                    placeholder={t(
                      'pages.manage_competencies.no_sector_selected'
                    )}
                    value={
                      props.professions.sectors
                        ? props.professions.sectors
                        : props.sectors
                    }
                    options={sectorsData}
                  >
                    {(options) => <span>{options.name}</span>}
                  </InputSelect>
                </div>
              </div>
            </form>
          </>
        )}

        {step === 2 && (
          <AddCompetencyStep
            props={props}
            fetch={useGet.bind(null, '/qualifications', {
              type: props.allTypes?.label,
              organisationId: `${organisationId},OR,organisationId=null`,
            })}
          />
        )}

        <div className="mt-8 mb-4 flex min-w-full justify-end">
          {step > 1 && (
            <Button
              variant="secondary"
              onClick={() => setStep(step - 1)}
              className="mr-auto"
            >
              {t('button.previous')}
            </Button>
          )}
          {step < MAXSTEP && (
            <Button onClick={() => setStep(step + 1)}>
              {t('pages.manage_add_users.next_button')}
            </Button>
          )}
          {step === MAXSTEP && (
            <Button
              onClick={() => {
                setStep(step + 1);
                props.handleEditCompetencies();
              }}
            >
              {t('pages.manage_competencies.add_button')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default EditCompetencyProfileStepsContainer;

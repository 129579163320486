import { useGet } from '@/query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Label, InputText, InputSelect } from '@teo/components';
import { config } from '@/config';
import { axios } from '@/axios';
import {
  categoriesData,
  moduleData,
  typeData,
} from '@/mocks/competencesOptionsData';
import { CompetencesOptionsInterface } from '@/models/competencesOptions.interface';

function AllCompetenciesModal({
  setIsOpenAllCompetenciesModal,
  isOpenAllCompetenciesModal,
  closeModal,
  onChange,
  isAddModal,
  isAllCompetenciesIndex,
}: any) {
  const { t } = useTranslation();

  const { organisationId } = useParams();
  const [qualifications, setQualifications] = useState<any>(null);
  const [competence, setCompetence] = useState('');
  const [categories, setCategories] = useState<any>(null);
  const [module, setModule] = useState<any>(null);
  const [type, setType] = useState<any>(null);

  useGet('/qualifications', {
    organisationId,
    onSuccess: (result: any) => {
      setQualifications(result?.data || {});
    },
  });

  function submitNewCompetencies() {}

  const allCompetenciesEdit = isAllCompetenciesIndex
    ? qualifications.find((el: any) => el.id === isAllCompetenciesIndex)
    : '';

  function editNewCompetencies() {}

  const handleButton = () => {
    setIsOpenAllCompetenciesModal(false);
    isAddModal ? submitNewCompetencies() : editNewCompetencies();
  };

  if (!qualifications) return null;

  return (
    <div className="bg-white">
      <Modal isOpen={isOpenAllCompetenciesModal}>
        <Modal.Header onClose={closeModal}>
          <h2
            className="text-sm font-semibold"
            data-testid="addCompetenciesHeader"
          >
            {isAddModal
              ? t('pages.manage_competencies.add_competence')
              : t('pages.manage_competencies.edit_competence')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <h1 className="text-base font-semibold">
            {isAddModal
              ? t('pages.manage_competencies.add_title')
              : t('pages.manage_competencies.edit_title')}
          </h1>
          <p className="mt-2 text-sm font-normal text-[#7d7d81]">
            {isAddModal
              ? t('pages.manage_competencies.add_subtitle')
              : t('pages.manage_competencies.add_subtitle')}
          </p>

          <form
            className="w-full max-w-[440px] flex-col max-md:h-full max-md:overflow-hidden"
            data-testid="addCompetenciesForm"
          >
            <div>
              <Label htmlFor="competence">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_competencies.competence_label')}
                </p>
              </Label>
              <InputText
                id="competence"
                type="text"
                onChange={(e) => setCompetence(e.target.value)}
                placeholder={
                  isAddModal
                    ? t('pages.manage_competencies.competence_placeholder')
                    : allCompetenciesEdit?.id
                }
                value={competence}
                data-testid="addCompetenceName"
              />
            </div>

            <div>
              <Label htmlFor="category">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_competencies.category_label')}
                </p>
              </Label>
              <div id="category">
                <InputSelect<CompetencesOptionsInterface>
                  onChange={(category) => {
                    setCategories(category);
                  }}
                  // value={
                  //     category
                  //         ? category
                  //         : categoriesData.find(
                  //             (option: CompetencesOptionsInterface) =>
                  //                 option.value === publication.lang
                  //         )
                  // }
                  value={categories}
                  options={categoriesData}
                >
                  {(options) => <span>{options.name}</span>}
                </InputSelect>
              </div>
            </div>

            <div>
              <Label htmlFor="module">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_competencies.module_label')}
                </p>
              </Label>
              <div id="module">
                <InputSelect<CompetencesOptionsInterface>
                  onChange={(module) => {
                    setModule(module);
                  }}
                  value={module}
                  options={moduleData}
                >
                  {(options) => <span>{options.name}</span>}
                </InputSelect>
              </div>
            </div>

            <div>
              <Label htmlFor="type">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_competencies.type_label')}
                </p>
              </Label>
              <div id="type">
                <InputSelect<CompetencesOptionsInterface>
                  onChange={(type) => {
                    setType(type);
                  }}
                  value={type}
                  options={typeData}
                >
                  {(options) => <span>{options.name}</span>}
                </InputSelect>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('pages.manage_tags.cancel_button')}
          onCancel={closeModal}
        >
          <div data-testid="addCompetencesFooter">
            <Button type="button" onClick={handleButton} data-testid="addBtn">
              {isAddModal
                ? t('pages.manage_competencies.add_button')
                : t('pages.manage_competencies.edit_button')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllCompetenciesModal;

import React, { Component } from 'react';
import { Container, Sprite, withPixiApp, Ticker, Text } from '@pixi/react';
import * as PIXI from 'pixi.js';
import { BoxIoService } from '../BoxIoService';
import { calculateUnit } from '@/util';

const arrowImg =
  'https://teo-s3-prod.s3.eu-west-1.amazonaws.com/PIXI/arrow.png';
const gaugeImg =
  'https://teo-s3-prod.s3.eu-west-1.amazonaws.com/PIXI/gauge.png';
const ANGLE_COEFFICIENT = 209;

// Min 0 degrees to 300 degrees

//  Text style for the unit symbol at the center of the gauge
const styleUnit = new PIXI.TextStyle({
  fontFamily: 'Arial',
  fontSize: 80,
  fontStyle: 'italic',
  fontWeight: 'bold',
  strokeThickness: 1,
  wordWrap: true,
  wordWrapWidth: 440,
});

//  Text style for around the gauge
const styleMarker = new PIXI.TextStyle({
  fontFamily: 'Arial',
  fontSize: 80,
  fontStyle: 'italic',
  fontWeight: 'bold',
  strokeThickness: 1,
  wordWrap: true,
  wordWrapWidth: 440,
});

const CircularGauge = withPixiApp(
  class extends React.Component {
    displayName = 'CircularGauge';

    constructor(props) {
      super();
      this.state = {
        angle: 0,
        showValue: 0,
        unit: '',
        text: [],
        circle: [],
      };

      //gets value of analog and calculates the right direction
      this.service = BoxIoService.getInstance();
      this.service.onAnalog.subscribe((msg) => {
        if (msg.name === props.name) {
          const vmin = this.props.vmin || 0;
          const vmax = this.props.vmax || 24;
          const valuemin = this.props.valuemin || 0;
          const valuemax = this.props.valuemax || 24;

          const value = (msg.value - vmin) / (vmax - vmin);
          const showValue = valuemin + value * (valuemax - valuemin);
          this.setState({ angle: 300 * value, showValue });
        }
      });
    }

    subscriptions = [];
    componentWillUnmount() {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
      this.subscriptions = [];
    }

    componentDidMount() {
      // Calculates spread value for min/max pixi Text
      const min = this.props.valuemin;
      const max = this.props.valuemax;
      const items = 10;
      const increments = (max - min) / items;

      const result = [...Array(items + 1)].map((x, y) =>
        (min + increments * y).toFixed(2)
      );
      let circle = [];
      for (let angle = 0; angle < 1.8 * Math.PI; angle += (2 * Math.PI) / 12) {
        circle.push(angle);
      }
      this.setState({ text: result, circle });
    }

    render() {
      const { unit, scale } = this.props;
      const { text, circle, showValue } = this.state;
      //let circle = []

      return (
        <Container
          {...this.props}
          scale={scale}
          pivot={{ y: -900, x: 500 }}
          width={126}
          height={100}
        >
          <Sprite image={gaugeImg} anchor={0.5} x={0} y={0} />

          <Text
            anchor={0.5}
            x={0}
            y={-200}
            text={showValue.toFixed(2) + ' ' + unit}
            style={styleUnit}
          />

          <Text anchor={0.5} x={0} y={200} text={unit} style={styleUnit} />

          <Sprite
            scale={1.2}
            image={arrowImg}
            anchor={{ x: 0.5, y: 0.847 }}
            angle={this.state.angle + ANGLE_COEFFICIENT}
            x={0}
            y={0}
          />

          {circle.map((angle, index) => (
            <Text
              key={index}
              style={styleMarker}
              anchor={0.5}
              x={630 * Math.cos(2.1 + angle)}
              y={630 * Math.sin(2.1 + angle)}
              text={text[index]}
            />
          ))}
        </Container>
      );
    }
  }
);

export default CircularGauge;

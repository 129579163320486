import { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { useGet } from '@/query/fetchHooks';
import { walkExtra, bfsWalkExtra, walk } from '@/util';
import { useParams } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';
import { format } from 'date-fns';
import { T } from '@/components/T';

function PdfTitle() {
  const { setNavDisabled } = useContext(NavContext);
  const [course, setCourse] = useState<any>(null);
  const [version, setVersion] = useState<any>(null);

  const { t } = useTranslation();

  const { auth } = useContext(AuthContext);

  const authUser: any = auth?.user;

  const { coursecontentId } = useParams();

  useGet('/coursecontentdetails', {
    count: 1,
    //userId: authUser.id,
    id: coursecontentId,
    //organisationId: authUser?.activeOrganisationId,
    onSuccess: (result: any) => {
      if (result?.data[0].masterId) {
        setVersion(result?.data[0].version);
      } else {
        axios
          .get(`${config.backend}/publish/lastversion/${coursecontentId}`)
          .then((result) => {
            setVersion(result.data.version + 1);
          });
      }
      setCourse(result?.data[0] || null);
    },
  });

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  }, []);

  if (!course) return <></>;

  return (
    <div className="m-5 mt-[25vh] flex flex-col">
      <h2 className="m-auto">{course.name} </h2>
      <span className=" mx-auto my-1 text-base font-normal lowercase text-grey-08">
        <T>columns.version</T>: {version}
      </span>
      <span className="m-auto my-5 flex flex-col items-center text-base text-grey-08">
        {/*<img className='w-32' src={authUser.organisationPicture} />*/}
        {authUser.organisationName}
      </span>
      <span className="m-auto text-base text-grey-08">
        {course.firstName} {course.lastName}
      </span>

      <span className="m-auto text-base text-grey-08">
        {format(new Date(), 'dd/LL/yyyy (HH:mm)')}
      </span>
      <div className="page-loaded"></div>
    </div>
  );
}

export default PdfTitle;

import { useMatches, useParams } from 'react-router-dom';
import { BreadCrumbs } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { ActiveStudentContext } from '@/components/Contexts/ActiveStudentContext';
import { ActiveLessonContext } from '@/components/Contexts/ActiveLessonContext';
import { useContext } from 'react';
import { ActiveCourseContext } from '@/components/Contexts/ActiveCourseContext';

function BreadCrumbsHeader() {
  const { t } = useTranslation();
  const matches = useMatches();

  const { auth } = useContext(AuthContext);
  const { course } = useContext(ActiveCourseContext);
  const { student } = useContext(ActiveStudentContext);
  const { lesson } = useContext(ActiveLessonContext);

  const params = useParams();

  return (
    <BreadCrumbs>
      {matches
        .filter((match) => (match?.handle as any)?.name)
        .map((match) => {
          const pathname = match.pathname;
          const name =
            typeof (match?.handle as any).name === 'function'
              ? (match?.handle as any)?.name(
                  { auth: auth?.user, course, student, lesson },
                  params
                )
              : t((match?.handle as any)?.name);
          return (
            <BreadCrumbs.Item key={(match?.handle as any)?.name} to={pathname}>
              {name}
            </BreadCrumbs.Item>
          );
        })}
    </BreadCrumbs>
  );
}

export default BreadCrumbsHeader;

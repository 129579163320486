import { useState, useEffect, useRef } from 'react';
import { IconButton, Dropdown } from '@teo/components';
import {
  Document,
  MoreHorizontal,
  Pencil,
  DragHandle,
  Image,
  Play,
} from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';

const ExtraInfoCustonNode = ({
  isMobile,
  active,
  node,
  setNewActiveExtra,
  newActiveExtra,
  className,
  preview,
  style,
  handleDelete,
  setLessons,
  setTree,
  extraInfoEdit,
  isFroalaLoaded,
  ...other
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState(node?.text);

  const { data } = node;

  const handleBlur = () => {
    if (node?.typeLes) {
      axios.put(`${config.backend}/lesson/${data?.lessonId}`, {
        id: data?.lessonId,
        name: newName,
      }),
        setLessons((prev) =>
          prev?.map((el) =>
            el.id === data?.id ? { ...el, name: newName } : el
          )
        );
    } else {
      axios.put(`${config.backend}/asset/${data?.assetId}`, {
        id: data?.assetId,
        assetName: newName,
      }),
        setLessons((prev) =>
          prev?.map((el) =>
            el.id === data?.id ? { ...el, assetName: newName } : el
          )
        );
    }
    setNewActiveExtra(null);
    setEditName(false);
    setTree(null);
  };

  const handleEditClick = () => {
    setEditName(true);
  };

  useEffect(() => {
    if (extraInfoEdit) {
      if (node?.data?.lessonId) {
        if (isFroalaLoaded) {
          if (
            newActiveExtra &&
            node?.id === newActiveExtra &&
            newActiveExtra === isFroalaLoaded
          ) {
            handleEditClick();
          }
        }
      } else {
        if (newActiveExtra && node?.id === newActiveExtra) {
          handleEditClick();
        }
      }
    } else {
      if (newActiveExtra && node?.id === newActiveExtra) {
        handleEditClick();
      }
    }
  }, [isFroalaLoaded, newActiveExtra]);

  useEffect(() => {
    if (editName && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editName]);

  return (
    <div
      className={
        `relative my-1 flex cursor-pointer items-center justify-between rounded-md py-1 pr-2 pl-8 ` +
        className +
        (active ? ' bg-[#f8f8f9]' : '')
      }
      style={{ ...style }}
      {...other}
    >
      <div
        className={
          'flex w-full items-center lg:min-w-max' +
          (preview
            ? ''
            : !isMobile && ' hidefirstchildonhover hidelastchildonhover')
        }
      >
        <DragHandle className="absolute left-2 w-5 min-w-5 text-[#dfdfe2]" />

        {node?.typeLes ? (
          <Document className="my-2 mr-3 w-5 text-grey-07 " />
        ) : data?.assetType === 'image' ? (
          <Image className="my-2 mr-3 w-5 text-grey-07 " />
        ) : (
          <Play className="my-2 mr-3 w-5 text-grey-07 " />
        )}
        {!editName ? (
          <p
            className={`w-full max-w-[180px] flex-1 text-sm font-normal text-[#231f20] ${
              !data?.canEdit && 'text-grey-05'
            } `}
          >
            {newName}
          </p>
        ) : (
          <input
            ref={inputRef}
            id={`edit_${node?.id}`}
            onChange={({ target: { value } }) => {
              setNewName(value);
            }}
            onBlur={() => handleBlur()}
            onKeyPress={(event) => {
              if (event.charCode === 13) {
                handleBlur();
              }
            }}
            value={newName}
            className="h-8 rounded-md border border-solid px-3 py-0 text-sm font-normal text-grey-08 !shadow-none outline outline-2 !outline-offset-0 !ring-0 focus:border-secondary-04 focus:bg-white focus:outline-secondary-01"
          />
        )}

        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="relative ml-auto inline-block"
        >
          <Dropdown>
            <Dropdown.Trigger>
              <IconButton
                className="w-5 min-w-5 text-[#7d7d81]"
                Icon={MoreHorizontal}
                variant="ghost"
                size="sm"
              />
            </Dropdown.Trigger>
            <Dropdown.Content
              style={{
                visibility: 'visible',
                right: '0',
                top: '20px',
                position: 'absolute',
              }}
            >
              <Dropdown.Actions>
                {data.canEdit && ( //directories and non linked lessons
                  <Dropdown.Button
                    onClick={() => {
                      handleEditClick();
                    }}
                  >
                    <Pencil className="mr-4 w-5 text-[#231f20]" />
                    <p>{t('pages.create_edit_course.rename')}</p>
                  </Dropdown.Button>
                )}

                <Dropdown.Button
                  onClick={() => {
                    handleDelete(node);
                  }}
                >
                  <img
                    src="/images/icons/trash_icon_red.svg"
                    alt="delete button"
                    className="mr-5 w-4"
                  />
                  <p>{t('pages.create_edit_course.remove')}</p>
                </Dropdown.Button>
              </Dropdown.Actions>
            </Dropdown.Content>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default ExtraInfoCustonNode;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Spinner } from '@teo/components';
import { Cross } from '@teo/components/icons';

function InfoMessage({
  title,
  description,
  uploading,
  onClick,
  onClickClose,
  bgColor = '#f8f8f9',
  actionLabel = 'notifications.understood_button',
}: any) {
  //#E4F1FF
  const { t } = useTranslation();
  const [closeInfoMessage, setCloseInfoMessage] = useState<boolean>(false);

  return (
    <div
      style={{ backgroundColor: `${bgColor}` }}
      className={`${
        closeInfoMessage ? 'hidden' : 'flex'
      } relative flex-col flex-wrap rounded-md p-6`}
    >
      <div className="flex flex-wrap items-center justify-between">
        <p className="text-base font-semibold">{title}</p>
        <IconButton
          className="absolute right-[-18px] top-[6px] my-auto mx-3 sm:mx-6"
          Icon={Cross}
          variant="ghost"
          onClick={() => {
            onClickClose ? onClickClose() : setCloseInfoMessage(true);
          }}
        />
      </div>
      <div className="mt-4 text-sm font-normal text-[#4d4d50]">
        {description}
      </div>
      <div className="align-center mt-4 flex">
        <Button
          variant="outline"
          size="md"
          className="mr-2 w-[10%]"
          onClick={() => (onClick ? onClick() : setCloseInfoMessage(true))}
        >
          {t(actionLabel)}
        </Button>
        {uploading && (
          <Spinner
            ariaLabel="Loading spinner"
            className="!h-10 !w-10 border-grey-05"
          />
        )}
      </div>
    </div>
  );
}
export default InfoMessage;

import { useParams, useNavigate } from 'react-router-dom';
import { useState, Fragment, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { InputText, FilterDropdown, Button } from '@teo/components';
import { Search } from '@teo/components/icons';
import { T } from '@/components/T';
import { useTranslation } from 'react-i18next';
import { PublicationTable } from '@/components/PublicationTable';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import { newUid } from '@/util';
import i18n from 'i18next';

const PublicationCourses = ({
  title,
  courseType,
}: {
  courseType: string;
  title: string;
}) => {
  const [filter, setFilter] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [listCategories, setListCategories] = useState<any>([]);
  const [author, setAuthor] = useState();
  const [category, setCategory] = useState<any>();
  const [coursesType, setCoursesType] = useState<any>();
  const [selected, setSelected] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselect, setDeselect] = useState<boolean>(false);
  const { organisationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const coursePath = window.location.pathname;

  const { auth, setAuth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));

  const courseTypeData = [
    {
      id: 'theory',
      name: t('pages.create_edit_course.library.theory'),
    },
    {
      id: 'practice',
      name: t('pages.create_edit_course.library.practice'),
    },
  ];

  const allCategories: any = useGet(`/categories`, {
    count: 1000,
    sort: 'tag',
    lang: auth?.user?.lang,
  });

  useEffect(() => {
    if (allCategories?.data) {
      const data = (allCategories?.data?.data as any[]).map((tag) => ({
        id: tag?.id,
        name: tag?.tag,
      }));
      setListCategories(data);
    }
  }, [allCategories?.data]);

  useEffect(() => {
    const myFilter = filterInput + ' ' + (category?.name ? category?.name : '');
    setFilter(myFilter);
  }, [filterInput, category]);

  useGet(`/membercoursedetails`, {
    organisationId: authUser?.activeOrganisationId,
    publicationCourseType: 'instruction',
  });

  return (
    <div className="w-full max-w-6xl pb-8">
      <div className="my-8 flex justify-between">
        <h1 className="text-xl font-semibold text-black">
          <T>{t(title)}</T>
        </h1>

        <div>
          {/* todo: do we need this button? */}
          {/* <Button
            onClick={() => {
              navigate(
                `/${i18n.language}/create/${organisationId}?courseType=${courseType}`
              );
            }}
            size="md"
          >
            {t('pages.follow_up_courses.create_new_course')}
          </Button> */}
        </div>
      </div>

      <Fragment>
        <div className="pb-8 sm:w-2/3 md:w-1/2">
          <InputText
            value={filterInput}
            onChange={(ev) => setFilterInput(ev.target.value)}
            Icon={Search}
            placeholder={t('search.course')}
          />
        </div>
        <div className="mb-8 flex flex-col sm:flex-row">
          {/* <span className="mb-4 pr-2 sm:mb-0">
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                setAuthor(value);
              }}
              buttonText={`${t('pages.follow_up.all_authors_tag')}`}
              options={authorsData}
              searchable={true}
              value={author}
            />
          </span> */}

          <span className="mb-4 pr-2 sm:mb-0">
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                setCategory(value);
              }}
              buttonText={`${t('pages.follow_up.all_categories_tag')}`}
              options={listCategories}
              searchable={true}
              value={category}
            />
          </span>
          {courseType !== 'instruction' && (
            <span className="pr-2">
              <FilterDropdown
                clearButtonLabel="clear"
                onChange={(value: any) => {
                  setCoursesType(value);
                  setRefetchTable(newUid(20));
                }}
                buttonText={`${t('pages.follow_up.course_type_tag')}`}
                options={courseTypeData}
                value={coursesType}
                dropdownPositionMobileRight={true}
              />
            </span>
          )}
        </div>
        <PublicationTable
          courseType={courseType}
          filter={filter}
          rowPath={(course: any) =>
            courseType === 'instruction'
              ? `${coursePath}/details/${course.id}`
              : `${coursePath}/${course.id}`
          }
          key={refetchTable}
          fetch={
            courseType === 'instruction'
              ? useGet.bind(
                  null,
                  (authUser?.role || 0) >= 90
                    ? '/membercoursedetails'
                    : '/myteachedcourses',
                  {
                    userId:
                      (authUser?.role || 0) >= 90 ? undefined : authUser?.id,
                    organisationId: authUser?.activeOrganisationId,
                    courseType: courseType,
                    publicationStatus:
                      'published,OR,publicationStatus=concept,OR,publicationStatus=template',
                  }
                )
              : useGet.bind(
                  null,
                  (authUser?.role || 0) >= 90
                    ? '/usedpublications'
                    : '/myusedpublications',
                  {
                    userId:
                      (authUser?.role || 0) >= 90 ? undefined : authUser?.id,
                    organisationId: organisationId,
                    publicationCourseType: courseType,
                    publicationStatus:
                      'published,OR,publicationStatus=concept,OR,publicationStatus=template',
                    template: coursesType?.id,
                  }
                )
          }
          empty={{
            template: 2,
            text: t('empty_tenplate.course_list'),
            // btn_text: t('empty_tenplate.btn_creat_course'),
            // btnClick: () =>
            //   navigate(`/${i18n.language}/create/${organisationId}`),
          }}
        />
      </Fragment>
    </div>
  );
};

export default PublicationCourses;

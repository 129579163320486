import { useTranslation } from 'react-i18next';
import React, { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { CompanyAvatar, IconButton } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { Userwithorganisationwithidps } from '@/models';
import AddUsersStepsContainer from '@/pages/Protected/Manage/AddUsersStepsContainer/AddUsersStepsContainer';

function AddUsersToOrganizationManagePage() {
  const { t } = useTranslation();
  const { setNavDisabled } = useContext(NavContext);
  const { organisationId } = useParams();

  const navigate = useNavigate();

  const [value, setValue] = useState<any>([]);
  const [newUser, setNewUser] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [role, setRole] = useState<any>(20);
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');

  const { auth } = useContext(AuthContext);

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  const authUser = auth?.user as Userwithorganisationwithidps;

  const handleAddUserToList = (newValue: any) => {
    const newValues = [...newUser];

    for (const obj2 of newValue) {
      const existingObj = newValues.find(
        (obj1) =>
          (obj1?.userId && obj1?.userId === obj2?.userId) ||
          (obj1?.teamId && obj1?.teamId === obj2?.teamId)
      );
      if (!existingObj) {
        newValues.push(obj2);
      }
    }

    setNewUser(newValues);
    setValue([]);
    return newValue;
  };

  const handleAddUserToOrganization = async () => {
    const provisionResults = newUser.map(async (item: any) => {
      if (item.newUser) {
        return new Promise((resolve, reject) =>
          axios
            .post(`${config.backend}/invite/provision?lang=${item?.lang}`, {
              organisationId: auth?.user?.activeOrganisationId,
              email: item.label,
              firstName: item.firstName,
              lastName: item.lastName,
              phone: item.phone,
              role: Math.min(item.role, 50),
              lang: item?.lang,
            })
            .then((result) => {
              resolve(
                Object.assign({}, result?.data, {
                  key: result?.data.id,
                  role: item.role,
                })
              );
            })
            .catch(reject)
        );
      } else {
        return Promise.resolve(item);
      }
    });
    Promise.allSettled(provisionResults).then((results) => {
      const promises = [];
      for (const result of results) {
        if (result.status === 'fulfilled') {
          promises.push(
            axios.post(`${config.backend}/members`, {
              organisationId: organisationId,
              userId: result.value.key,
              role: result.value.role || 20,
            })
          );
        }
      }
      Promise.allSettled(promises).then(() => {
        navigate(`${basePath}`);
      });
    });
  };

  const handleRemove = (id: any) => {
    const newList = newUser.filter((item: any) => item.key !== id);
    setNewUser(newList);
  };

  return (
    <div className="fixed z-10 flex h-full min-h-[100vh] min-w-[100vw] flex-col bg-white">
      <div className="flex min-h-16 items-center justify-start ">
        <IconButton
          className="my-auto mx-6"
          Icon={CrossFilled}
          variant="ghost"
          onClick={() => navigate(`${basePath}`)}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {authUser?.organisationName && (
            <CompanyAvatar
              imageSrc={authUser?.organisationPicture || ''}
              name={authUser?.organisationName}
            />
          )}
          <span className="pl-2">
            {t('pages.manage_add_users.add_users_to')}
          </span>
          <span className="pl-2 font-semibold">
            {authUser?.organisationName}
          </span>
        </div>
      </div>

      <AddUsersStepsContainer
        value={value}
        setValue={setValue}
        data={data}
        setData={setData}
        newUser={newUser}
        setNewUser={setNewUser}
        handleRemove={handleRemove}
        handleAddUserToList={handleAddUserToList}
        setRole={setRole}
        role={role}
        email={email}
        setEmail={setEmail}
        firstname={firstname}
        setFirstname={setFirstname}
        lastname={lastname}
        setLastname={setLastname}
        phone={phone}
        setPhone={setPhone}
        handleAddUserToOrganization={handleAddUserToOrganization}
      />
    </div>
  );
}

export default AddUsersToOrganizationManagePage;

import { useTranslation } from 'react-i18next';
import { TheoSearch } from '@teo/components/illustrations';
import { parseQueryString } from '@/util';
import { useEffect, useState } from 'react';
import { decode } from '@/base64';

function Four04() {
  const { t } = useTranslation();
  const [error, setError] = useState<string | false>(false);

  useEffect(() => {
    const queries = parseQueryString(document.location.search) as {
      [key: string]: string;
    };

    if (queries['error']) {
      setError('errors.' + JSON.parse(decode(queries['error'])).status);
    }
  }, []);

  return (
    <div className="mx-auto mt-10 flex min-h-[calc(100vh_-_150px)] flex-col items-center justify-center">
      <TheoSearch className="mb-4 w-96" />
      {!error && (
        <>
          <h1 className="mr-20">404</h1>
          <div className="mr-16">{t('pages.not_found.title')}</div>
        </>
      )}
      {error && (
        <>
          <div className="mr-16">{t(error)}</div>
        </>
      )}
    </div>
  );
}

export default Four04;

import { useState, useEffect, useRef } from 'react';
import { IconButton } from '@teo/components';
import {
  CrossFilled,
  Document,
  Information,
  Chapter,
} from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import ExtraInfoEmpty from './ExtraInfoEmpty';
import { axios } from '@/axios';
import { config } from '@/config';
import { uploadLesson, uploadImage } from '@/query/documents';
import ExtraInfoList from './ExtraInfoList';
import { DuplicateLibrary } from '@/components/DuplicateLessons/DuplicateLibrary';
import { useMediaQuery } from 'react-responsive';
import { LibraryModal } from '../LibraryModal/LibraryModal';
import { uploadVideo } from '@/query/vimeo';

const ExtraInfo = ({
  setShowExtraInfo,
  activeNode,
  node,
  publication,
  prevTree,
  setTree,
  setAddLesson,
  setLessons,
  nameItem,
  extraInfoLessons,
  setActiveNode,
  setParentNode,
  setMobileSidebar,
  setExtraInfoEdit,
  extraInfoEdit,
  isFroalaLoaded,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const fileInputRef = useRef(null);
  const fileInputVideoRef = useRef(null);

  const [isHomePage, setIsHomePage] = useState(false);
  const [newExtraInfo, setNewExtraInfo] = useState();
  const [newAsset, setNewAsset] = useState();
  const [courseContentId, setCourseContentId] = useState();
  const { droppable, data } = node;
  const [nodeId, setNodeId] = useState();
  const [nodePath, setNodePath] = useState(null);
  const [isOpenLibrary, setIsOpenLibrary] = useState(false);
  const [extraInfoTree, setExtraInfoTree] = useState(null);
  const [isOpenLibraryModal, setIsOpenLibraryModal] = useState(false);
  const [newActiveExtra, setNewActiveExtra] = useState(null);
  const [isAddButtonBottom, setIsAddButtonBottom] = useState(false);

  useEffect(() => {
    if (node?.path === '/') {
      setIsHomePage(true);
      setCourseContentId(node?.coursecontentId);
      setNodeId(node?.id);
      setNodePath(null);
    } else {
      setIsHomePage(false);
      setCourseContentId(data?.coursecontentId);
      setNodeId(data?.lessonId ? data?.lessonId : data?.id);
      setNodePath(data?.path);
    }
  }, [node, data]);

  const handleScroll = () => {
    const scrollSection = document.getElementById('extra-info');
    const scrollTreeSection = document.getElementById('extra-info-tree');
    const scrollY = window.scrollY;
    if (scrollSection) {
      if (isMobile) {
        if (scrollY < 66) {
          scrollSection.style.marginTop = 65 - scrollY + 'px';
          const heightTop = 65 - scrollY + 53;
          scrollTreeSection.style.maxHeight = `calc(100vh - ${heightTop}px)`;
        } else {
          scrollSection.style.marginTop = '0px';
          scrollTreeSection.style.maxHeight = `calc(100vh - 53px)`;
        }
      } else {
        if (scrollY < 81) {
          scrollSection.style.marginTop = 80 - scrollY + 'px';
          const heightTop = 80 - scrollY + 53;
          scrollTreeSection.style.maxHeight = `calc(100vh - ${heightTop}px)`;
        } else {
          scrollSection.style.marginTop = '0px';
          scrollTreeSection.style.maxHeight = `calc(100vh - 53px)`;
        }
      }
    }
  };

  useEffect(() => {
    if (!extraInfoEdit) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const checkPositionAddButton = () => {
    const blockTree = document.getElementById('course-lessons');
    const contentTree = extraInfoEdit
      ? document.getElementById('extra-info')
      : document.getElementById('extra-lessons-list');
    if (blockTree && contentTree) {
      const blockTreeHeight = blockTree.offsetHeight;
      const contentTreeHeight = contentTree.offsetHeight;
      const space = extraInfoEdit ? 220 : 240;
      if (blockTreeHeight > contentTreeHeight + space) {
        setIsAddButtonBottom(false);
      } else {
        setIsAddButtonBottom(true);
      }
    }
  };

  useEffect(() => {
    if (newActiveExtra && extraInfoTree && extraInfoEdit) {
      const newNode = extraInfoTree?.find((el) => el?.id === newActiveExtra);
      setActiveNode(newNode);
      window.scrollTo(0, 0);
    }
    checkPositionAddButton();
  }, [extraInfoTree]);

  const handleNewExtraIndo = async () => {
    const data = await axios.post(`${config.backend}/lessons`, {
      name: t('extra_info.title'),
      canEdit: true,
      content: '',
      description: '',
    });
    setNewExtraInfo(data?.data);
  };

  const createLesson = async () => {
    const result = await axios.post(`${config.backend}/uses`, {
      canEdit: true,
      coursecontentId: parseInt(courseContentId, 10),
      lessonId: newExtraInfo?.id,
    });

    const createExtraInfoId = result?.data?.lessonId;
    const createExtraInfoUsesId = result?.data?.id;

    let newYindex;
    if (extraInfoTree?.length) {
      newYindex =
        extraInfoTree?.reduce((max, obj) => {
          return obj?.data?.yindex > max ? obj?.data?.yindex : max;
        }, extraInfoTree[0]?.data?.yindex) + 1;
    } else {
      newYindex = 0;
    }
    axios.put(`${config.backend}/use/${result?.data?.id}`, {
      coursecontentId: parseInt(courseContentId, 10),
      lessonId: createExtraInfoId,
      onlyTheory: true,
      rootDirectoryId: isHomePage ? nodeId : droppable ? nodeId : null,
      rootLessonId: !isHomePage && !droppable ? nodeId : null,
      path: data?.path
        ? `${data?.path}/${createExtraInfoId}`
        : `/${createExtraInfoId}`,
      yindex: newYindex,
    });
    setAddLesson({
      ...newExtraInfo,
      canEdit: true,
      coursecontentId: parseInt(courseContentId, 10),
      onlyTheory: true,
      lessonId: createExtraInfoId,
      rootDirectoryId: isHomePage ? nodeId : droppable ? nodeId : null,
      rootLessonId: !isHomePage && !droppable ? nodeId : null,
      path: node?.data?.path
        ? `${node?.data?.path}/${createExtraInfoId}`
        : `/${createExtraInfoId}`,
      usesId: createExtraInfoUsesId,
      yindex: newYindex,
    });
    setNewActiveExtra(createExtraInfoUsesId);
    setTree(null);
    setNewExtraInfo(null);
  };

  useEffect(() => {
    if (newExtraInfo?.id) {
      uploadLesson(newExtraInfo?.postData, '');
      createLesson();
    }
  }, [newExtraInfo]);

  const handleDelete = (les) => {
    axios.delete(`${config.backend}/use/${les?.data?.usesId}`);

    if (les?.id) {
      setLessons((prev) =>
        prev?.filter((el) => {
          return el?.usesId !== les?.id;
        })
      );
    }
  };

  useEffect(() => {
    if (newAsset) {
      createImageInfo();
    }
  }, [newAsset]);

  const createImageInfo = async () => {
    const result = await axios.post(`${config.backend}/uses`, {
      coursecontentId: parseInt(courseContentId, 10),
      assetId: newAsset?.id,
    });

    const createExtraInfoImageId = result?.data?.assetId;
    const createExtraInfoImageUsesId = result?.data?.id;

    if (createExtraInfoImageId) {
      let newYindex;
      if (extraInfoTree?.length) {
        newYindex =
          extraInfoTree?.reduce((max, obj) => {
            return obj?.data?.yindex > max ? obj?.data?.yindex : max;
          }, extraInfoTree[0]?.data?.yindex) + 1;
      } else {
        newYindex = 0;
      }
      axios.put(`${config.backend}/use/${result?.data?.id}`, {
        assetId: createExtraInfoImageId,
        canEdit: true,
        coursecontentId: parseInt(courseContentId, 10),
        onlyTheory: true,
        rootDirectoryId: isHomePage ? nodeId : droppable ? nodeId : null,
        rootLessonId: !isHomePage && !droppable ? nodeId : null,
        path: data?.path
          ? `${data?.path}/${createExtraInfoImageId}`
          : `/${createExtraInfoImageId}`,
        yindex: newYindex,
      });
      setAddLesson({
        ...newAsset,
        canEdit: true,
        coursecontentId: parseInt(courseContentId, 10),
        onlyTheory: true,
        assetId: createExtraInfoImageId,
        rootDirectoryId: isHomePage ? nodeId : droppable ? nodeId : null,
        rootLessonId: !isHomePage && !droppable ? nodeId : null,
        path: node?.data?.path
          ? `${node?.data?.path}/${createExtraInfoImageId}`
          : `/${createExtraInfoImageId}`,
        usesId: createExtraInfoImageUsesId,
        yindex: newYindex,
      });
      setNewActiveExtra(createExtraInfoImageUsesId);
      setTree(null);
      setNewAsset(null);
    }
  };

  const handleUploadImage = async (event) => {
    const img = event.target.files[0];
    const size = 900;
    const data = await uploadImage(img, size, size, 'jpeg');

    const dataImage = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'image',
      assetPath: data,
    });
    setNewAsset(dataImage?.data);
  };

  const handleUploadImageLibrary = async (image) => {
    const res = await fetch(image);
    const blob = await res.blob();
    const mime = blob.type;
    const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);
    const img = new File([blob], `${blob.size}.${ext}`, {
      type: mime,
    });
    const size = 900;
    const data = await uploadImage(img, size, size, 'jpeg');

    const dataImage = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'image',
      assetPath: data,
    });
    setNewAsset(dataImage?.data);
  };

  const handleUploadVideo = async (event) => {
    const file = event.target.files[0];
    let title;
    try {
      title = file.name.split('.').slice(0, -1).join('.');
    } catch (e) {
      title = document.title;
    }
    const data = await uploadVideo(
      file,
      (progress, percent) => {
        console.warn(percent, '%');
      },
      () => {
        console.warn('All done');
      },
      title
    );
    const dataVideo = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'video',
      assetPath: data,
    });
    setNewAsset(dataVideo?.data);
  };

  const handleOpenLibrary = () => {
    setIsOpenLibrary(true);
  };

  const handleClose = async () => {
    if (extraInfoEdit) {
      setActiveNode(isHomePage ? null : node);
      setExtraInfoEdit(false);
    } else {
      setShowExtraInfo(false);
    }
  };

  return (
    <div
      className={`${
        !extraInfoEdit && 'fixed inset-0 z-[10] flex bg-black/50 '
      } ${!extraInfoEdit ? (isMobile ? ' mt-[60px]' : ' mt-[80px]') : ' '}`}
      id="extra-info"
    >
      {!extraInfoEdit && (
        <div className="flex-auto" onClick={() => handleClose()}></div>
      )}
      <div
        className={`${
          !extraInfoEdit && 'h-full w-full max-w-[380px] bg-white '
        } flex  flex-col 
        ${!extraInfoEdit ? (isMobile ? ' max-w-full' : ' max-w-[380px]') : ' '}
        `}
      >
        <div className="flex items-center border-b px-3 py-2">
          <h4>{t('extra_info.title')}</h4>
          <IconButton
            name="close_extra_info"
            className="my-auto ml-auto"
            Icon={CrossFilled}
            variant="ghost"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <div
          className={`flex h-full flex-col p-3 ${
            !extraInfoEdit && 'overflow-y-auto '
          }`}
          id="extra-info-tree"
        >
          <div className="flex items-center">
            {isHomePage ? (
              <Information className="m-2 w-6 min-w-6 text-grey-07" />
            ) : droppable ? (
              <Chapter className="m-2 w-6 min-w-6 text-grey-07" />
            ) : (
              <Document className="m-2 w-6 min-w-6 text-grey-07" />
            )}

            <h4>{nameItem}</h4>
          </div>
          {extraInfoLessons?.length > 0 ? (
            <ExtraInfoList
              activeNode={activeNode}
              extraInfoLessons={extraInfoLessons}
              handleNewExtraIndo={handleNewExtraIndo}
              handleDelete={handleDelete}
              setLessons={setLessons}
              setAddLesson={setAddLesson}
              parentNode={node}
              handleOpenLibrary={handleOpenLibrary}
              setExtraInfoTree={setExtraInfoTree}
              extraInfoTree={extraInfoTree}
              setActiveNode={setActiveNode}
              setParentNode={setParentNode}
              setMobileSidebar={setMobileSidebar}
              setExtraInfoEdit={setExtraInfoEdit}
              extraInfoEdit={extraInfoEdit}
              setShowExtraInfo={setShowExtraInfo}
              isMobile={isMobile}
              fileInputRef={fileInputRef}
              fileInputVideoRef={fileInputVideoRef}
              setIsOpenLibraryModal={setIsOpenLibraryModal}
              setNewActiveExtra={setNewActiveExtra}
              newActiveExtra={newActiveExtra}
              isAddButtonBottom={isAddButtonBottom}
              isFroalaLoaded={isFroalaLoaded}
            />
          ) : (
            <ExtraInfoEmpty
              handleNewExtraIndo={handleNewExtraIndo}
              handleOpenLibrary={handleOpenLibrary}
              fileInputRef={fileInputRef}
              fileInputVideoRef={fileInputVideoRef}
              setIsOpenLibraryModal={setIsOpenLibraryModal}
            />
          )}
        </div>
      </div>
      {isOpenLibrary && (
        <DuplicateLibrary
          closeModal={() => setIsOpenLibrary(false)}
          coursecontentId={courseContentId}
          prevTree={prevTree}
          setAddLesson={setAddLesson}
          // setAsset={setAsset}
          setIsOpenLibrary={setIsOpenLibrary}
          parentPath={nodePath}
          parentId={nodeId}
          setTree={setTree}
          extraInfo={true}
          isHomePage={isHomePage}
          droppable={droppable}
          extraInfoTree={extraInfoTree}
          publication={publication}
        />
      )}

      {isOpenLibraryModal && (
        <LibraryModal
          setIsOpenLibraryModal={setIsOpenLibraryModal}
          isOpenLibraryModal={isOpenLibraryModal}
          lessonPage={false}
          setUploadFile={handleUploadImageLibrary}
        />
      )}

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadImage}
        accept="image/*"
        style={{ display: 'none' }}
        multiple={false}
      />

      <input
        type="file"
        ref={fileInputVideoRef}
        onChange={handleUploadVideo}
        accept="video/mp4,video/x-m4v,video/*"
        style={{ display: 'none' }}
        multiple={false}
      />
    </div>
  );
};

export default ExtraInfo;

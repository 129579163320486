import { useTranslation } from 'react-i18next';
import { Checkbox } from '@teo/components';

const MobileDataTable = ({
  setings,
  data,
  defaultRender,
  handleItemSelect,
  goRouteId,
  props,
}: any) => {
  const { t } = useTranslation();

  const checkType = (type: string | null) => {
    switch (type) {
      case 'circle':
        return 'absolute z-20 h-8 w-8 flex items-center rounded-full opacity-0';
      case 'square':
        return 'absolute z-20 w-16 min-w-16 sm:w-20 sm:min-w-20 h-12 min-h-12 sm:h-14 sm:min-h-14 flex items-center rounded-lg opacity-0';
      default:
        return '';
    }
  };
  return (
    <div className="text-sm text-black">
      {data?.map((row: any) => {
        const key =
          typeof setings.uniqueKey === 'function'
            ? setings.uniqueKey(row)
            : row[setings.uniqueKey];
        const rowPath = setings?.rowPath?.(row);
        return (
          <div
            key={key}
            className={`relative mb-2 grid grid-cols-9 grid-rows-2 gap-2 rounded-lg border border-grey-02 p-5 ${
              setings?.selectable?.selected?.has(key) && ' border-[#3257FC] '
            }
            ${props?.selectableRow?.selectedRow == row?.id && 'bg-grey-01'}
              `}
          >
            <div
              className="absolute inset-0 z-[1]"
              onClick={() =>
                rowPath
                  ? goRouteId(rowPath)
                  : props?.selectableRow?.selected
                  ? props?.selectableRow?.setSelected(row?.id)
                  : ''
              }
            ></div>
            {setings?.selectable?.selected && (
              <div className="relative col-start-1 col-end-1 row-start-1 flex items-center">
                <Checkbox
                  className={`z-[2] 
                    ${
                      setings?.selectable?.checkboxIconMobileType &&
                      checkType(setings?.selectable?.checkboxIconMobileType)
                    } 
                    ${setings?.selectable?.selected?.has(key) && 'opacity-100'}
                  `}
                  checked={
                    setings?.selectable?.selected.has(key) ? true : false
                  }
                  key={key + '_' + setings?.selectable?.selected.has(key)}
                  onChange={() => handleItemSelect(key)}
                />
              </div>
            )}
            {setings.columns.map((col: any) => {
              if (col?.hidden) return null;
              const content = col?.render
                ? typeof col?.render === 'function'
                  ? col.render(row)
                  : col.render
                : defaultRender(row, col);
              return (
                <div
                  key={`${row.id}_${col.id}` as string}
                  className={`${col.className} flex items-center`}
                >
                  {(content || content === 0) && (
                    <>
                      {col.addTitleOnMobile ? (
                        <div className="text-xs font-semibold text-[#7d7d81] sm:text-sm">
                          {col.title}
                          <div className="font-normal">{content}</div>
                        </div>
                      ) : (
                        content
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default MobileDataTable;

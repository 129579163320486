/* eslint-disable import/namespace */
import { useEffect, useState, createRef, useRef, useMemo } from 'react';
import {
  isEmpty,
  unique,
  relativePos,
  v2equal,
  newUid,
  shuffle,
  round2,
  focusWidget,
  useStateAndRef,
} from '@/util';

import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { TextWithImage } from './TextWithImage';
import * as d3 from 'd3';
import { encode } from '@/base64';
import { useMediaQuery } from 'react-responsive';
import { MobileConnectView } from './MobileConnectView';
import { MagnifyImage } from './MagnifyImage';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { XAPIService } from '@/services/xapi.service.js';

const pastels = [
  '#C25FF4',
  '#E3BF00',
  '#6EC6C0',
  '#F25850',
  '#29BAFF',
  '#AE7226',
  '#1173DD',
  '#9AD055',
  '#807EFF',
  '#FF8EA4',
];

export const ConnectView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setXApiSubmitFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  ConnectView.syncStates(state, answer, correction);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [score, setScore] = useState(correction?.score);

  useEffect(() => {
    if (submitted) {
      let answer = getAnswer();
      let correction = {};
      ConnectView.syncStates(state, answer, correction);
      if (!answer?.answered) {
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  const answered = submitted || isCorrected;

  var isDesktop = true;

  //no hover === no mouse detected
  if (window.matchMedia('(any-hover: none)').matches) {
    isDesktop = false;
  }

  const queryParams = new URLSearchParams(window.location.search);
  const viewVersion = queryParams.get('view');
  if (viewVersion === 'mobile') {
    isDesktop = false;
  }

  //small screen
  if (!useMediaQuery({ query: '(min-width: 768px)' })) {
    isDesktop = false;
  }

  const [randomOrder, setRandomOrder] = useState(
    !showAnswers &&
      !answered &&
      (state.randomOrder !== undefined ? state.randomOrder : true)
  );

  const [leftRandom] = useState(randomOrder ? shuffle(state.left) : state.left);
  const [rightRandom] = useState(
    randomOrder ? shuffle(state.right) : state.right
  );

  const [modified, setModified] = useState(null);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  const parent = createRef();
  /*
  let [connections, setConnections] = useState(
    answered ? answer?.connections : state?.connections
  );
  */
  let [connections, setConnections] = useState(
    showAnswers ? state?.connections : answer?.connections
  );
  let [correctConnections, setCorrectConnections] = useState(
    state?.connections
  );
  let [redraw, setRedraw] = useState(newUid(20));
  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    setRedraw(newUid(20));
  }, [answered]);

  //generate the answer
  const getAnswer = () => {
    answer.connections = connections;
    answer.incomplete = !submitted && connections?.length < 1;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    let correction = {};
    ConnectView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = (lessonId, lang, minScore = 0.5) => {
    let correction = {};
    let answer = getAnswer();
    ConnectView.syncStates(state, answer, correction);
    const testId = lessonId;
    const questionId = state.uid;

    let leftByUid = state.left.reduce((obj, x) => {
      obj[x.uid] = x;
      return obj;
    }, {});
    let rightByUid = state.right.reduce((obj, x) => {
      obj[x.uid] = x;
      return obj;
    }, {});

    let answers = answer.connections.map((connection) => {
      let left = leftByUid[connection[0]] || leftByUid[connection[1]];
      let right = rightByUid[connection[0]] || rightByUid[connection[1]];
      return { [left.uid]: right.uid };
    });
    let correctAnswers = state.connections.map((connection) => {
      let left = leftByUid[connection[0]] || leftByUid[connection[1]];
      let right = rightByUid[connection[0]] || rightByUid[connection[1]];
      return { [left.uid]: right.uid };
    });

    const source = Object.values(leftByUid).map((x) => ({
      id: x.uid,
      description: { [lang]: x.text },
    }));
    const target = Object.values(rightByUid).map((x) => ({
      id: x.uid,
      description: { [lang]: x.text },
    }));

    const success = correction.score >= state.score * minScore;
    const name = {
      [lang]: state?.titleWidget || t('widgets.type.multiple_choice_question'),
    };
    const description = {
      [lang]: state?.vraag,
    };

    let xapi = XAPIService.getInstance();
    xapi.cmi5.interactionMatching(
      testId,
      questionId,
      answers,
      correctAnswers,
      source,
      target,
      name,
      description,
      success
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  /*
  useEffect(() => {
    getAnswer();
  }, [connections]);
  */

  const FunUniqueAnswer = () => {
    let connectionsPerLeft = {};

    leftRandom.map((leftitems) => {
      connectionsPerLeft[leftitems.uid] = 0;
    });
    for (let connection of state.connections) {
      if (connectionsPerLeft[connection[0]] !== undefined)
        connectionsPerLeft[connection[0]] += 1;
      if (connectionsPerLeft[connection[1]] !== undefined)
        connectionsPerLeft[connection[1]] += 1;
    }

    let uniqueAnswer = true;
    for (let uid of Object.keys(connectionsPerLeft)) {
      if (connectionsPerLeft[uid] > 1) uniqueAnswer = false;
    }
    return uniqueAnswer;
  };

  let uniqueAnswer = FunUniqueAnswer();

  const redrawFn = () => {
    let myParent = parent.current;
    try {
      document.querySelector(`#svg_${state.uid}`).remove();
    } catch (e) {
      /*ignore*/
    }

    const svg = d3
      .select(myParent)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('id', `svg_${state.uid}`)
      .style('position', 'absolute');

    const connectPath = (p1, p2) => {
      const midX = p1[0] + (p2[0] - p1[0]) / 2;
      return `M${p1[0]},${p1[1]}C${midX},${p1[1]},${midX},${p2[1]},${p2[0]},${p2[1]}`;
    };

    const connect = (uid1, uid2) => {
      let newConnection = [uid1, uid2].sort();
      if (connections.findIndex((x) => v2equal(x, newConnection)) !== -1)
        return; //no duplicates
      connections.push(newConnection);
      connections = connections.map((x) => x.sort());
      drawConnection(newConnection);
      setConnections(connections);
      setModified(newUid(20));
    };

    const mousemove = (ev) => {
      if (!mousedown_node) return;
      let svgRect = myParent.getBoundingClientRect();

      const pX = ev.clientX || ev.touches[0].clientX;
      const pY = ev.clientY || ev.touches[0].clientY;

      //calculate mousePos relative to svg
      let mousePos = [pX - svgRect.left, pY - svgRect.top];
      drag_line.attr('d', connectPath(mousedown_node.circlePos, mousePos));
    };

    const mouseup = (ev) => {
      if (!mousedown_node) return;
      document.onmousemove = undefined;
      document.onmouseup = undefined;
      document.ontouchend = undefined;
      document.ontouchmove = undefined;

      let conn0 = document.querySelector(
        `.conn_${state.uid}_${mousedown_node.uid}`
      );
      if (!conn0) {
        //nothing connected to mousedown_node, hide the dot
        document
          .querySelector(`#dot_${state.uid}_${mousedown_node.uid}`)
          .classList.add('hidden');
      }
      drag_line.classed('hidden', true);

      //we handle touch end here
      let quit = ev.changedTouches;
      if (quit) {
        var elem = document.elementFromPoint(quit[0].clientX, quit[0].clientY);
        if (elem) {
          let splitted = elem.id.split('_');
          if (
            (splitted[0] === 'circle' || splitted[0] === 'dot') &&
            elem.classList.contains('right')
          ) {
            connect(mousedown_node.uid, splitted[2]);
          }
        }
      }
      mousedown_node = null;
    };

    // line displayed when dragging new nodes
    const drag_line = svg
      .append('svg:path')
      .attr('id', 'drag_line')
      .attr('stroke', 'black')
      .style('stroke-width', '2')
      .attr('fill', 'transparent')
      .attr('class', 'link dragline hidden');

    let mousedown_node = null;

    const calcCirclePos = (node, align) => {
      const pos = isDesktop && relativePos(node, myParent);
      return [
        align === 'left' ? pos.left : pos.left + pos.width,
        pos.top + pos.height / 2,
      ];
    };

    //draws the little circle
    const renderNode = (uid, align, color = '#919193', className) => {
      let node = document.querySelector(
        `#${CSS.escape(state.uid)} .${CSS.escape(uid)}`
      );

      //let node = document.querySelector(`#${CSS.escape(uid)}`);
      let strokeColor;

      if (!node) return;
      if (answered) {
        strokeColor = color;
        if (node.classList.contains('correct')) {
          strokeColor = '#42E466';
        }
        if (node.classList.contains('notCorrect')) {
          strokeColor = isMouseDown ? ' #3257fc' : '#FC6232';
        }
      } else {
        strokeColor = color;
      }
      const circlePos = calcCirclePos(node, align, color);
      const g = svg
        .append('svg:g')
        .attr('ondragstart', 'return false')
        .attr('user-select', 'none')
        .style('z-index', 50)
        .attr('transform', `translate(${circlePos[0]}, ${circlePos[1]})`);

      const circle = g
        .append('svg:circle')
        .attr('id', `circle_${state.uid}_${uid}`)
        .attr('class', `node ${className}`)
        .attr('r', 8)
        .style('fill', 'white')
        .style('stroke-width', '2')
        .style('stroke', strokeColor)
        .on('mouseover', function (d) {
          //hover effect
          d3.select(this).attr('transform', 'scale(1.1)');
        })
        .on('mouseout', function (d) {
          //hover effect
          d3.select(this).attr('transform', '');
        });

      g.append('svg:circle')
        .style('fill', strokeColor)
        .attr('class', `hidden ${className}`)
        .attr('id', `dot_${state.uid}_${uid}`)
        .style('pointer-events', 'none')
        .attr('r', 5);

      circle.circlePos = circlePos;
      circle.uid = uid;
      circle.color = strokeColor;
      return circle;
    };

    let leftNodes = {},
      rightNodes = {};
    leftRandom.forEach((x, i) => {
      let node = renderNode(
        x.uid,
        'right',
        pastels[i % pastels.length],
        'left'
      );

      if (!node) return;
      leftNodes[node.uid] = node;
      !answered &&
        node
          .on('mousedown touchstart', function (d) {
            focusWidget(state?.uid);
            FunUniqueAnswer();
            let conns = document.querySelectorAll(
              `.conn_${state.uid}_${node.uid}`
            );
            if (conns.length > 0 && uniqueAnswer) {
              let toRemoveLine = conns[conns.length - 1];
              toRemoveLine.remove();
              removeConnection([
                toRemoveLine.dataset.con0,
                toRemoveLine.dataset.con1,
              ]);
            }
            //remove the last added line

            //pick up line
            d.preventDefault();
            mousedown_node = node;
            document
              .querySelector(`#dot_${state.uid}_${node.uid}`)
              .classList.remove('hidden');

            // reposition drag line
            drag_line
              .style('marker-end', 'url(#end-arrow)')
              .attr('stroke', node.color)
              .classed('hidden', false);

            document.onmousemove = mousemove;
            document.onmouseup = mouseup;
            document.ontouchmove = mousemove;
            document.ontouchend = mouseup;

            mousemove(d);
          })
          .on('mouseup touchend', function (d) {
            mouseup(d);
          });
    });

    //draw the line between the nodes
    const drawConnection = (connection, corColor, corValue) => {
      try {
        const con0 = document.querySelector(
          `#circle_${state.uid}_${connection[0]}`
        );
        const con1 = document.querySelector(
          `#circle_${state.uid}_${connection[1]}`
        );

        if (!con0 || !con1) return;

        const con0Box = con0.getBoundingClientRect();
        const con1Box = con1.getBoundingClientRect();

        let svgRect = myParent.getBoundingClientRect();

        //calculate center of circles relative to parent
        const centerLeft = [
          con0Box.left + con0Box.width / 2 - svgRect.left,
          con0Box.top + con0Box.height / 2 - svgRect.top,
        ];
        const centerRight = [
          con1Box.left + con1Box.width / 2 - svgRect.left,
          con1Box.top + con1Box.height / 2 - svgRect.top,
        ];
        let computedStyles;

        if (con0.classList.contains('left')) {
          computedStyles = window.getComputedStyle(con0);
          answered &&
            (corValue === 'correct'
              ? document
                  .querySelector(`#${state.uid} .${CSS.escape(connection[0])}`)
                  .classList.add('!border-success-04', 'correct')
              : (document
                  .querySelector(`#${state.uid} .${CSS.escape(connection[0])}`)
                  .classList.remove(
                    !isMouseDown ? '!border-secondary-04' : '!border-error-04',
                    'notCorrect'
                  ),
                document
                  .querySelector(`#${state.uid} .${CSS.escape(connection[0])}`)
                  .classList.add(
                    isMouseDown ? '!border-secondary-04' : '!border-error-04',
                    'notCorrect'
                  )));
        } else {
          computedStyles = window.getComputedStyle(con1);
          answered &&
            (corValue === 'correct'
              ? document
                  .querySelector(`#${state.uid} .${CSS.escape(connection[1])}`)
                  .classList.add('!border-success-04', 'correct')
              : (document
                  .querySelector(`#${state.uid} .${CSS.escape(connection[1])}`)
                  .classList.remove(
                    !isMouseDown ? '!border-secondary-04' : '!border-error-04',
                    'notCorrect'
                  ),
                document
                  .querySelector(`#${state.uid} .${CSS.escape(connection[1])}`)
                  .classList.add(
                    isMouseDown ? '!border-secondary-04' : '!border-error-04',
                    'notCorrect'
                  )));
        }

        document
          .querySelector(`#dot_${state.uid}_${connection[0]}`)
          .classList.remove('hidden');
        document
          .querySelector(`#dot_${state.uid}_${connection[1]}`)
          .classList.remove('hidden');

        svg
          .insert('svg:path', '#drag_line') //connect behind the dots
          .attr(
            'stroke',
            corColor ? corColor : computedStyles.getPropertyValue('stroke')
          )
          // .attr('stroke', 'transparent')
          .attr('fill', 'transparent')
          .attr('data-con0', connection[0])
          .style('stroke-width', '2')
          .attr('data-con1', connection[1])
          .attr(
            'class',
            `link dragline conn_${state.uid}_${connection[1]} conn_${state.uid}_${connection[0]}`
          )
          .attr('d', connectPath(centerLeft, centerRight));
      } catch (e) {
        console.error(e);
      }
    };

    const removeConnection = (connection) => {
      connections = connections.filter((x) => !v2equal(x, connection));
      setConnections(connections);
      setModified(newUid(20));

      //hides the dot if no connections are connected to the endpoints
      let conn0 = document.querySelector(`.conn_${state.uid}_${connection[0]}`);
      let conn1 = document.querySelector(`.conn_${state.uid}_${connection[1]}`);
      if (!conn0) {
        document
          .querySelector(`#dot_${state.uid}_${connection[0]}`)
          .classList.add('hidden');
      }
      if (!conn1) {
        document
          .querySelector(`#dot_${state.uid}_${connection[1]}`)
          .classList.add('hidden');
      }
    };

    rightRandom.forEach((x) => {
      let node = renderNode(x.uid, 'left', '#919193', 'right');
      if (!node) return;
      rightNodes[node.uid] = node;
      !answered &&
        node
          .on('mouseup', function (d) {
            //drop line (connect)
            let newConnection = [mousedown_node.uid, node.uid].sort();
            if (connections.findIndex((x) => v2equal(x, newConnection)) !== -1)
              return; //no duplicates
            connections.push(newConnection);
            connections = connections.map((x) => x.sort());
            drawConnection(newConnection);

            setConnections(connections);
            setModified(newUid(20));
            //mouseup is executed after this to clean up the drag line
          })
          .on('mousedown touchstart', function (d) {
            //pick up existing line from endpoint
            let conns = document.querySelectorAll(
              `.conn_${state.uid}_${node.uid}`
            );
            if (conns.length === 0) return;
            d.preventDefault();

            //remove the last added line
            let toRemoveLine = conns[conns.length - 1];
            let lastLeftNode =
              leftNodes[toRemoveLine.dataset.con0] ||
              leftNodes[toRemoveLine.dataset.con1];
            toRemoveLine.remove();
            removeConnection([
              toRemoveLine.dataset.con0,
              toRemoveLine.dataset.con1,
            ]);

            //Start new drag from lastLeftNode
            document
              .querySelector(`#dot_${state.uid}_${lastLeftNode.uid}`)
              .classList.remove('hidden');
            mousedown_node = lastLeftNode;
            drag_line
              .classed('hidden', false)
              .attr('stroke', mousedown_node.color);

            document.onmousemove = mousemove;
            document.onmouseup = mouseup;
            document.ontouchmove = mousemove;
            document.ontouchend = mouseup;
          })
          .on('mouseover', function (d) {
            document.querySelector(
              `#circle_${state.uid}_${node.uid}`
            ).style.stroke = mousedown_node?.color;
            document.querySelector(
              `.${CSS.escape(node.uid)}`
            ).style.borderColor = mousedown_node?.color;
          })
          .on('mouseout', function (d) {
            document.querySelector(
              `#circle_${state.uid}_${node.uid}`
            ).style.stroke = node?.color;
            document.querySelector(
              `.${CSS.escape(node.uid)}`
            ).style.borderColor = '#e5e7eb';
          });
    });

    let equal = (a, b) => a[0] == b[0] && a[1] == b[1];

    let missing = correctConnections.filter(
      (x) => !connections.find((y) => equal(x, y))
    );

    for (let connection of connections) {
      if (!answered) {
        drawConnection(connection);
      } else {
        let equal = (a, b) => a[0] == b[0] && a[1] == b[1];
        let color;
        let answerCorrect;
        if (correctConnections.find((x) => equal(x, connection))) {
          color = '#42E466';
          answerCorrect = 'correct';
        } else {
          color = isMouseDown ? 'transparent' : '#FC6232';
          answerCorrect = 'notCorrect';
        }
        drawConnection(connection, color, answerCorrect);
      }
    }
    for (let connection of missing) {
      answered &&
        drawConnection(
          connection,
          !isMouseDown ? 'transparent' : '#3257fc',
          'notCorrect'
        );
    }
  };

  const observer = useRef(null);
  const observer2 = useRef(null);

  useEffect(() => {
    let myParent = parent.current;
    observer.current && observer.current.disconnect();
    observer.current = new ResizeObserver(() => {
      setRedraw(newUid(20));
    });

    observer2.current && observer.current.disconnect();
    observer2.current = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          setRedraw(newUid(20));
        }
      });
    });

    isDesktop && observer.current.observe(myParent);
    isDesktop && observer2.current.observe(myParent);

    return () => {
      observer.current && observer.current.disconnect();
      observer2.current && observer2.current.disconnect();
    };
  }, [parent.current, isDesktop]);

  useEffect(() => {
    redrawFn();
  }, [redraw, isMouseDown]);

  const handleMouseDown = () => {
    answered && setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    answered && setIsMouseDown(false);
  };

  if (window.matchMedia('(any-hover: none)').matches) {
    // do something
  }

  return (
    <>
      <div
        className={`flex flex-col gap-4 py-4 ${
          !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
        }`}
        style={{ maxWidth: 'calc(100vw - 32px)' }}
        data-state={encode(JSON.stringify(state))}
      >
        {!resultPages && (
          <WidgetHeader
            index={index}
            score={round2(correction.score)}
            maxScore={state.score}
            answered={answered}
            titleWidget={state.titleWidget || t('widgets.type.link_question')}
          />
        )}
        <FroalaTextareaView value={state.vraag} />
        {isDesktop ? (
          <>
            {answered ? (
              <>
                {correction?.score < state?.score ? (
                  <p className="text-xs font-medium text-error-04">
                    {t('pages.correction_result.order_widget_hint')}
                  </p>
                ) : null}
              </>
            ) : null}

            <div
              ref={parent}
              className="relative flex flex-row justify-between gap-20"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <div className="grid-col grid w-fit min-w-[20%] max-w-full gap-2">
                {leftRandom.map((leftItem, i) => (
                  <div
                    key={leftItem.uid}
                    //id={leftItem.uid}
                    className={`${leftItem.uid} flex min-h-16 w-full min-w-20 items-center break-all rounded-lg border bg-white p-3 text-[#231f20]`}
                  >
                    <TextWithImage
                      noMaxHeight
                      textstyle={{ textAlign: 'center' }}
                      data={leftItem}
                      onLoad={() => {
                        setRedraw(newUid(20));
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="grid-col grid w-fit min-w-[20%] max-w-full gap-2">
                {rightRandom.map((rightItem, i) => (
                  <div
                    key={rightItem.uid}
                    //id={rightItem.uid}
                    className={`${rightItem.uid} flex min-h-16 w-full min-w-20 items-center break-all rounded-lg border bg-white p-3 text-[#231f20]`}
                  >
                    <div className="flex h-full w-full flex-col">
                      {rightItem.file ? (
                        <div className=" h-full">
                          <MagnifyImage
                            onLoad={() => {
                              setRedraw(newUid(20));
                            }}
                            showIcon={true}
                            className="h-full max-w-[150px] object-cover object-center"
                            src={rightItem.file}
                            draggable={false}
                          />
                          {/* <img
                              alt={rightItem?.text}
                              src={rightItem.file}
                              className="m-auto h-full max-w-[150px] rounded object-cover object-center"
                            /> */}
                        </div>
                      ) : null}
                      {rightItem?.text ? (
                        <div className="my-auto text-xs text-grey-09">
                          {rightItem?.text}
                        </div>
                      ) : null}
                    </div>
                    {/* <TextWithImage
                    noMaxHeight
                    textstyle={{ textAlign: 'center' }}
                    data={rightItem}
                  /> */}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <MobileConnectView
            state={state}
            answered={answered}
            correction={correction}
            correctionType={correctionType}
            pastels={pastels}
            setConnections={setConnections}
            connections={connections}
            uniqueAnswer={uniqueAnswer}
            randomOrder={randomOrder}
            showAnswers={showAnswers}
          />
        )}
        {(state.immediateFeedback || correctionType === 'autofeedback') && (
          <PartialSubmitSection
            setSubmitted={setSubmitted}
            setModified={setModified}
            answered={answered}
            onSave={onSave.bind(null, false, false, state)}
          />
        )}
        {(resultPages || showAnswers || answered || submitted) &&
        state?.antwoord ? (
          <ExplanationSection state={state} />
        ) : null}
      </div>
    </>
  );
};

ConnectView.uniqueAnswer = (state) => {
  let connectionsPerLeft = {};

  state.left.map((leftitems) => {
    connectionsPerLeft[leftitems.uid] = 0;
  });

  for (let connection of state.connections) {
    if (connectionsPerLeft[connection[0]] !== undefined)
      connectionsPerLeft[connection[0]] += 1;
    if (connectionsPerLeft[connection[1]] !== undefined)
      connectionsPerLeft[connection[1]] += 1;
  }

  let uniqueAnswer = true;
  for (let uid of Object.keys(connectionsPerLeft)) {
    if (connectionsPerLeft[uid] > 1) uniqueAnswer = false;
  }
  return uniqueAnswer;
};

ConnectView.syncStates = (state, answer, correction) => {
  let leftUids = new Set(state.left.map((x) => x.uid));
  let rightUids = new Set(state.right.map((x) => x.uid));

  state.connections = state.connections.filter(
    (connection) =>
      (leftUids.has(connection[0]) && rightUids.has(connection[1])) ||
      (rightUids.has(connection[0]) && leftUids.has(connection[1]))
  );

  let uniqueAnswer = ConnectView.uniqueAnswer(state);

  if (isEmpty(answer)) {
    answer.uid = state.uid;
    answer.connections = [];
  }

  //if (isEmpty(correction)) {
  correction.uid = state.uid;
  if (!correction.score) {
    //autocorrect
    let connections = answer.connections;
    let correctConnections = state.connections;

    //sort
    connections = connections.map((x) => x.sort());
    correctConnections = correctConnections.map((x) => x.sort());

    let equal = (a, b) => a[0] == b[0] && a[1] == b[1];

    //remove duplicates
    connections = unique(connections, equal);
    correctConnections = unique(correctConnections, equal);

    //remove loops
    connections = connections.filter((x) => x[0] !== x[1]);
    correctConnections = correctConnections.filter((x) => x[0] !== x[1]);

    //Ignore connections to non-exiting nodes
    let allIdSet = new Set(
      state.left.map((x) => x.uid).concat(state.right.map((x) => x.uid))
    );
    connections = connections.filter(
      (x) => allIdSet.has(x[0]) && allIdSet.has(x[1])
    );
    correctConnections = correctConnections.filter(
      (x) => allIdSet.has(x[0]) && allIdSet.has(x[1])
    );

    let missing = correctConnections.filter(
      (x) => !connections.find((y) => equal(x, y))
    );
    let incorrect = connections.filter(
      (x) => !correctConnections.find((y) => equal(x, y))
    );
    let correct = connections.filter((x) =>
      correctConnections.find((y) => equal(x, y))
    );

    if (correctConnections.length === 0) correction.score = state.score;
    else {
      let baseScore = uniqueAnswer
        ? correct.length
        : correct.length - incorrect.length;
      correction.score = Math.max(
        (baseScore / correctConnections.length) * state.score,
        0
      );
    }

    if (state.strictCorrection && correction.score !== state.score) {
      correction.score = 0;
    }

    //}
  }
};

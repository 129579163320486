import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import ResultatField from './ResultatField';
import SentField from './SentField';
import OptionsChat from './OptionsChat';
import 'isomorphic-fetch';
import './ChatGPT.css';

export const ChatGpt = ({ reload, chatUid, chatContent }) => {
  const { auth } = useContext(AuthContext);
  const [arrPrompt, setArrPrompt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [closeChat, setCloseChat] = useState(true);
  const [activeContinue, setActiveContinue] = useState();

  const userId = auth?.user?.id;

  useEffect(() => {
    if (!closeChat) {
      const myChat = document.getElementById(chatUid);
      if (myChat) {
        myChat.classList.add('close-chat');
      }
    }
  }, [closeChat]);

  return (
    <div>
      <div>
        <ResultatField arrPrompt={arrPrompt} chatContent={chatContent} />
      </div>
      {closeChat && (
        <>
          <div
            contentEditable={false}
            className="chat-action remove_me_gEE8QIPZ6LEay22SdSCU"
          >
            <SentField
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setArrPrompt={setArrPrompt}
              setCloseChat={setCloseChat}
              userId={userId}
              setActiveContinue={setActiveContinue}
              reload={reload}
            />
          </div>
          <div
            contentEditable={false}
            className="chat-action remove_me_gEE8QIPZ6LEay22SdSCU"
          >
            <OptionsChat
              userId={userId}
              setActiveContinue={setActiveContinue}
              activeContinue={activeContinue}
            />
          </div>
        </>
      )}
    </div>
  );
};

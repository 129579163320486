import React, { Component, Fragment } from 'react';
import { Portal } from '@/components/Portal';

class KofferDisplay extends React.PureComponent {
  state = {
    fullScreen: false,
    src: '',
  };

  onFullScreen = (ev) => {
    if (ev.data === 'toggleFullscreen') {
      this.setState({ fullScreen: !this.state.fullScreen });
    }
    if (this.state.fullScreen) {
      document.getElementById('app').style.display = 'none';
    } else {
      document.getElementById('app').style.display = 'block';
    }
  };

  componentDidMount() {
    this.onFullScreenRef = this.onFullScreen.bind(this);
    window.addEventListener('message', this.onFullScreenRef);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onFullScreenRef);
  }

  render() {
    let iframe = (
      <iframe
        style={{ minHeight: '36.7vh', width: '100%' }}
        className={this.state.fullScreen ? 'fullScreen' : ''}
        src="http://127.0.0.1:6080/vnc.html?host=127.0.0.1&reconnect=true&reconnect_delay=500&autoconnect=1&resize=scale&port=6080&password=Maseos1"
        allowFullScreen
      />
    );
    if (!this.state.fullScreen)
      return (
        <div style={{ marginTop: 20, minHeight: '40vh' }} className="video">
          {iframe}
        </div>
      );
    else return <Portal root="body">{iframe}</Portal>;
  }
}

export { KofferDisplay };

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputText, Label, Modal } from '@teo/components';
import { newUid } from '@/util';
const PartialSubmitSection = ({
  sync,
  setModified,
  setSubmitted,
  onSave,
  answered,
  handlePrevOrder,
}) => {
  const { t } = useTranslation();

  const [forceSave, setForceSave] = useState(false);

  useEffect(() => {
    if (forceSave) {
      onSave();
      setForceSave(false);
    }
  }, [forceSave]);

  if (answered) return null;

  const submitWidget = async () => {
    handlePrevOrder && handlePrevOrder();
    sync && sync();
    setSubmitted(true);
    setModified(newUid(20));
    setForceSave(true);
  };

  return (
    <>
      <div className="mt-4 flex justify-end">
        <Button onClick={() => submitWidget()}>
          {t('pages.course_landing.submit')}
        </Button>
      </div>
    </>
  );
};

export { PartialSubmitSection };

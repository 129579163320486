import { useState, useEffect } from 'react';

import { findElementsByKey, buildSortedTree } from '@/util';
import { useTranslation } from 'react-i18next';
import {
  ChevronUp,
  ChevronDown,
  ArrowRight,
  PlayFilled,
} from '@teo/components/icons';
import { useMediaQuery } from 'react-responsive';

import { axios } from '@/axios';

export const TreeItem = ({ node, indent = 0, courseId }: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 640px)' });

  let lessonsAssetsCourse: any;

  if (!node?.lessonId) {
    lessonsAssetsCourse = findElementsByKey(node?.children, 'lessonId');
  }

  const openLessonsInCourse = (node: any) => {
    node?.lessonId
      ? goRouteId(node?.lessonId)
      : node?.assetId
      ? goRouteId(node?.assetId)
      : setIsOpen(!isOpen);
  };
  const spacing = isDesktopOrLaptop ? 53 : 40;
  const goRouteId = (les: any) => {
    window.open(
      `https://teo.training/nl/preview/${courseId}/lesson/${les}`,
      '_blank'
    );
  };

  return (
    <div>
      <div className="my-1 flex items-center justify-between">
        <div
          className="flex min-h-[40px] w-full items-center "
          style={{ paddingLeft: `${indent * spacing}px` }}
        >
          {(!node?.lessonId && !node?.assetId) || indent === 0 ? (
            <div
              className={`flex w-full cursor-pointer items-center rounded-md p-1 sm:p-2  
                `}
              onClick={() => {
                openLessonsInCourse(node);
              }}
              data-testid="chapter"
            >
              <div className="relative mr-2 flex h-8 w-8 rounded-full border-2 border-[#1e1e2014] pl-0.5 text-center sm:mr-4 sm:h-9 sm:w-9">
                <PlayFilled className="m-auto w-4 text-secondary-04" />
              </div>

              <p
                className={`w-full flex-1 pr-2 text-xs	font-medium text-black sm:text-sm`}
              >
                {node?.numbered && (
                  <span className="pr-2 sm:pr-4">{node?.order}.</span>
                )}
                {node?.name || node?.assetName}
              </p>
              <div className={`flex text-xs font-normal sm:text-sm`}>
                {lessonsAssetsCourse?.length > 0 && (
                  <p>
                    {lessonsAssetsCourse.length}{' '}
                    {t('pages.course_landing.lessons')}
                  </p>
                )}
              </div>
              {!node?.lessonId &&
                !node?.assetId &&
                (isOpen ? (
                  <ChevronUp
                    className={`w-6 p-2 pr-0 !text-grey-06 sm:w-9 sm:pr-2`}
                  />
                ) : (
                  <ChevronDown
                    className={`w-6 p-2 pr-0 !text-grey-06 sm:w-9 sm:pr-2`}
                  />
                ))}
            </div>
          ) : (
            <div
              className={`flex w-full cursor-pointer items-center justify-between rounded-md p-1 sm:p-2`}
              onClick={() => {
                return (
                  (node?.lessonId || node?.assetId) &&
                  goRouteId(node?.lessonId || node?.assetId)
                );
              }}
            >
              <p className={`w-full flex-1  text-xs font-normal sm:text-sm `}>
                <span className="pr-2 sm:pr-4">{node?.order}.</span>
                {node.name || node?.assetName}
              </p>
              <ArrowRight
                className={` w-6 pl-2 pr-0 text-grey-05 sm:w-9 sm:w-9 sm:pr-2`}
              />
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div>
          {node?.children &&
            node?.children?.map((x: any, subIndex: number) => (
              <TreeItem
                key={x?.id}
                node={x}
                indent={indent + 1}
                index={subIndex}
                courseId={courseId}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export const TutorialTree = ({
  coursecontentId,
  courseId,
  setNumLesAs,
  setChapters,
}: any) => {
  const [lessons, setLessons] = useState<any>(null);
  const [directories, setDirectories] = useState<any>(null);
  const [courseDirectories, setCourseDirectories] = useState<any>([]);
  const [courseLessons, setCourseLessons] = useState<any>([]);
  const [tree, setTree] = useState<any>(null);

  useEffect(() => {
    if (courseId) {
      axios
        .get<any>(
          `https://teo.training/api/usedcourselessonassets?count=10000&courseId=${courseId}&coursecontentId=${coursecontentId}`
        )
        .then((response) => {
          setLessons(response.data);
        });

      axios
        .get<any>(
          `https://teo.training/api/directories?count=10000&coursecontentId=${coursecontentId}`
        )
        .then((response) => {
          setDirectories(response.data);
        });
    }
  }, [courseId]);

  useEffect(() => {
    if (directories) {
      setCourseDirectories(directories?.filter((el: any) => el.path !== '/'));
      setChapters && setChapters(directories?.length);
    }

    if (lessons) {
      const allLesson = lessons.filter(
        (les: any) => !les?.rootLessonId && !les?.rootDirectoryId
      );
      setCourseLessons(allLesson);
      setNumLesAs(allLesson?.length || 0);
    }
  }, [directories, lessons]);

  useEffect(() => {
    if (courseLessons && courseDirectories) {
      const tree = buildSortedTree(courseLessons, courseDirectories || []);
      setTree(tree);
    }
  }, [courseLessons, courseDirectories]);

  if (!lessons || !directories) return null;

  return (
    <>
      {(tree as any)?.children?.map((x: any, index: number) => (
        <TreeItem
          key={x?.usesId ? x?.usesId : `d_${index}`}
          node={x}
          indent={0}
          index={index}
          courseId={courseId}
        />
      ))}
    </>
  );
};

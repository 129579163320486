import { useState, useEffect, useReducer, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Label,
  InputText,
  RadioGroup,
  InputSelect,
  ErrorMessage,
  InputTextarea,
  Multiselect,
  Button,
} from '@teo/components';
import { LangData } from '@/models/langData.interface';
import { useGet } from '@/query/fetchHooks';
import { BasicSettings } from '@/models/publishCourse.interdace';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { parseFilter } from '@/util';

const StepBasicSettings = ({
  publication,
  data,
  setStep,
  setGlobalState,
  globalState,
  setProfessions,
  publicationType,
}: any) => {
  const { t } = useTranslation();
  const [checkValid, setCheckValid] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const [competences, setCompetences] = useState<any>([]);
  const [filterCategories, setFilterCategories] = useState('');
  const [filterCompetences, setFilterCompetences] = useState('');
  const [addCompetencyProfiles, setAddCompetencyProfiles] = useState(false);
  const { auth } = useContext(AuthContext);

  useGet(`/coursecontenttagsdetails`, {
    coursecontentId: publication?.id,
    onSuccess: (result: any) => {
      const data = result?.data
        .filter((x: any) => x?.tag?.startsWith('category:'))
        .map((x: any) => ({
          tag: x.tag.split(':')[1],
          label: x.tag.split(':')[1],
          key: x.tag.split(':')[1],
        }));
      setCategories(data);
    },
  });

  useEffect(() => {
    updateState({
      selectCategories: categories,
    });
  }, [categories]);

  //const [courseCategories, setCourseCategories] = useState<any>([]);

  const allCategories: any = useGet(`/categories`, {
    count: 10,
    sort: 'tag',
    q: parseFilter(filterCategories),
  });

  const infoCategories: any = useGet(`/categories`, {
    count: 1000,
    sort: 'tag',
  });

  const allCompetences: any = useGet(`/professions`, {
    count: 10,
    sort: 'name',
    q: parseFilter(filterCompetences),
  });
  const infoCompetences: any = useGet(`/professions`, {
    count: 1000,
    sort: 'name',
  });
  const courseCompetences: any = useGet(`/coursecontentprofessions`, {
    coursecontentId: publication?.id,
  });

  const initialState = globalState?.basicSettingd
    ? {
        name: globalState?.basicSettingd?.name,
        description: globalState?.basicSettingd?.description,
        typeCourse: globalState?.basicSettingd?.typeCourse,
        lang: data?.find(
          (option: LangData) =>
            option?.value === globalState?.basicSettingd?.lang?.value
        ),
        selectCategories: globalState?.basicSettingd?.selectCategories,
        selectCompetences: globalState?.basicSettingd?.selectCompetences,
      }
    : {
        name: publication?.name ? publication?.name : '',
        description: publication?.description ? publication?.description : '',
        typeCourse: publication?.template ? publication?.template : 'theory',
        lang: data?.find(
          (option: LangData) => option?.value === publication?.lang
        ),
        selectCategories: [],
        selectCompetences: [],
      };

  const [state, updateState] = useReducer(
    (state: BasicSettings, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  /*
  useEffect(() => {
    if (allCategories?.data) {
      const data = (allCategories?.data?.data as any[]).map((category) => ({
        key: category?.id,
        label: category?.tag.charAt(9).toUpperCase() + category?.tag.slice(11),
        tag: category?.tag,
      }));
      setCategories(data);
    }
  }, [allCategories?.data]);
  */

  useEffect(() => {
    if (allCompetences?.data) {
      const data = (allCompetences?.data?.data as any[]).map((profession) => ({
        key: profession?.id,
        label: profession?.name,
      }));
      setCompetences(data);
    }
  }, [allCompetences?.data]);

  /*
  useEffect(() => {
    if (globalState?.basicSettingd?.selectCategories?.length < 1) {
      if (infoCategories?.data && courseCategories) {
        const tags = courseCategories?.filter((x: any) => x?.tag);
        const prevCategories = tags
          .filter((x: any) => x?.tag)
          .map((x: any) =>
            infoCategories?.data?.data?.find((y: any) => y?.tag == x?.tag)
          );
        const prevCategory = prevCategories?.filter((el: any) => el);
        const prev: any = [];
        prevCategory?.map((x: any) => {
          const val = state?.selectCategories?.find(
            (el: any) => el.key === x?.id
          );
          if (!val) {
            prev.push({
              key: x?.id,
              label: x?.tag.charAt(9).toUpperCase() + x?.tag.slice(11),
              tag: x?.tag,
            });
          }
        });
        updateState({
          selectCategories: prev,
        });
      }
    }
  }, [courseCategories, infoCategories?.data]);
  */

  useEffect(() => {
    if (globalState?.basicSettingd?.selectCompetences?.length < 1) {
      if (infoCompetences?.data && courseCompetences?.data) {
        const prev: any = [];
        (courseCompetences?.data?.data as any[]).map((profession) => {
          const prevProfessions = infoCompetences?.data?.data?.find(
            (el: any) => el.id === profession?.professionId
          );
          const val = state?.selectCompetences?.find(
            (el: any) => el.key === profession?.professionId
          );
          if (!val) {
            prev.push({
              key: prevProfessions?.id,
              label: prevProfessions?.name,
            });
          }
        });
        updateState({
          selectCompetences: prev,
        });
      }
      setProfessions(courseCompetences);
    }

    courseCompetences?.data?.data?.length > 0 && setAddCompetencyProfiles(true);
  }, [courseCompetences?.data, infoCompetences?.data]);

  const handleError = (el: string) => (el ? false : checkValid ? true : false);

  const handleCategories = (value: any) => {
    setFilterCategories('');
    const prevState = state?.selectCategories?.find(
      (el: any) => el?.key === value[value.length - 1]?.key
    );
    prevState
      ? updateState({
          selectCategories: state?.selectCategories?.filter(
            (item: any) => item?.key !== prevState?.key
          ),
        })
      : updateState({ selectCategories: value });
  };

  const handleProfiles = (value: any) => {
    setFilterCompetences('');
    const prevState = state?.selectCompetences?.find(
      (el: any) => el?.key === value[value.length - 1]?.key
    );
    prevState
      ? updateState({
          selectCompetences: state?.selectCompetences?.filter(
            (item: any) => item?.key !== prevState?.key
          ),
        })
      : updateState({ selectCompetences: value });
  };

  const handleNextStep = () => {
    setCheckValid(true);
    if (
      state?.name &&
      state?.description
      /*state?.selectCategories?.length > 0*/
    ) {
      setStep(2);
    }
  };

  useEffect(() => {
    setGlobalState((prev: any) => ({
      ...prev,
      basicSettingd: state,
    }));
  }, [state]);

  return (
    <>
      <div className="h-full max-h-[calc(100vh_-_250px)] overflow-y-auto py-8">
        <div className="mx-auto flex max-w-screen-md flex-col justify-center px-3">
          <article className="prose flex flex-col ">
            <h3 className="text-base">
              {t('pages.publish_course.steps_1.title')}
            </h3>
            <p className="pt-3 text-sm text-grey-07">
              {t(
                `pages.create_edit_course.type_${publicationType}.modal_subtitle_course`
              )}
            </p>
          </article>
          <div className="flex h-full flex-col md:w-full">
            <div>
              <Label htmlFor="courseTitle">
                <p className="mt-6 text-sm font-semibold">
                  {t(
                    `pages.create_edit_course.type_${publicationType}.title_course`
                  )}
                </p>
              </Label>
              <InputText
                id="courseTitle"
                type="text"
                onChange={(event) =>
                  updateState({ name: event.currentTarget.value })
                }
                value={state?.name}
                hasError={handleError(state?.name)}
              />
              {handleError(state?.name) && (
                <div className="mt-0 mb-2 text-sm text-tint-red-dark">
                  <ErrorMessage id="errorMessage">
                    {t('pages.create_edit_course.course_title_error')}
                  </ErrorMessage>
                </div>
              )}
            </div>

            <div>
              <Label htmlFor="courseDescription">
                <p className="mt-6 text-sm font-semibold">
                  {t(
                    `pages.create_edit_course.type_${publicationType}.course_description`
                  )}
                </p>
              </Label>

              <InputTextarea
                id="courseDescription"
                placeholder={t(
                  `pages.create_edit_course.type_${publicationType}.course_description_placeholder`
                )}
                onChange={(event) =>
                  updateState({ description: event.currentTarget.value })
                }
                limit={300}
                value={state?.description}
                hasError={handleError(state?.description)}
              />

              {handleError(state?.description) && (
                <div className="mt-0 mb-2 text-sm text-tint-red-dark">
                  <ErrorMessage id="errorMessage">
                    {t('pages.create_edit_course.course_description_error')}
                  </ErrorMessage>
                </div>
              )}
            </div>
            {publication?.publicationCourseType !== 'instruction' && (
              <RadioGroup
                label={t(
                  `pages.create_edit_course.type_${publicationType}.type_course`
                )}
                onChange={(value: string) => {
                  updateState({ typeCourse: value });
                }}
                value={state?.typeCourse}
              >
                <div className="flex flex-col gap-5 sm:flex-row">
                  <div
                    className={`basis-1/2 rounded-lg border border-grey-02 p-3 ${
                      state?.typeCourse == 'theory' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="theory">
                      <span className="text-sm">
                        {t('pages.create_edit_course.library.theory')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                  <div
                    className={`basis-1/2 rounded-lg border border-grey-02 p-3 ${
                      state?.typeCourse == 'practice' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="practice">
                      <span className="text-sm" data-testid="radio_btn">
                        {t('pages.create_edit_course.library.practice')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                </div>
              </RadioGroup>
            )}

            <div className="w-[192px]">
              <Label htmlFor="courseLang">
                <p className="mt-6 text-sm font-semibold">
                  {t(
                    `pages.create_edit_course.type_${publicationType}.language_course_select`
                  )}
                </p>
              </Label>
              <div id="courseLang">
                <InputSelect<LangData>
                  onChange={(lang) => {
                    updateState({ lang: lang });
                  }}
                  value={
                    state?.lang
                      ? state?.lang
                      : data?.find(
                          (option: LangData) =>
                            option?.value === publication?.lang
                        )
                  }
                  options={data}
                >
                  {(options) => <span>{t(`langs.${options?.value}`)} </span>}
                </InputSelect>
              </div>
            </div>

            <div data-testid="categories">
              <Label htmlFor="categories">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.create_edit_course.categories')}
                </p>
              </Label>

              <PaginationMultiSelect
                value={categories}
                onChange={(value: any) => {
                  setCategories(value);
                }}
                keyFn={(row: any) => row?.tag}
                fetch={useGet.bind(
                  null,
                  `/categories?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                )}
                defaultSort={[{ id: 'tag', desc: false }]}
                render={(row: any) => {
                  return row?.tag;
                }}
              />

              {/** 
          <Multiselect
            removeButtonLabel="clear"
            toggleSelectionLabel="toggle"
            placeholder={t('pages.create_edit_course.categories_placeholder')}
            options={categories}
            onChange={(value: any) => handleCategories(value)}
            onRemoved={(value) => {
              setFilterCategories('');
              updateState({ selectCategories: value });
            }}
            value={state?.selectCategories}
            query={filterCategories}
            setQuery={(query) => setFilterCategories(query)}
            positionDropdown="top"
            hasError={state?.selectCategories?.length < 1 && checkValid}
          />
          */}
            </div>

            {checkValid && (
              <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                <ErrorMessage id="errorMessage">
                  {t('pages.create_edit_course.categories_error')}
                </ErrorMessage>
              </div>
            )}
            {publication?.publicationCourseType !== 'instruction' && (
              <div data-testid="competency">
                <Label htmlFor="competencyProfiles">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.create_edit_course.competency_profiles')}
                    {!addCompetencyProfiles && (
                      <span className="font-normal text-grey-05">
                        {' '}
                        ({t('pages.create_edit_course.optional')})
                      </span>
                    )}
                  </p>
                </Label>

                {addCompetencyProfiles ? (
                  <Multiselect
                    removeButtonLabel="clear"
                    toggleSelectionLabel="toggle"
                    placeholder={t(
                      'pages.create_edit_course.competency_profiles_placeholder'
                    )}
                    options={competences}
                    onChange={(value: any) => handleProfiles(value)}
                    onRemoved={(value) => {
                      setFilterCompetences('');
                      updateState({ selectCompetences: value });
                    }}
                    value={state?.selectCompetences}
                    query={filterCompetences}
                    positionDropdown="top"
                    setQuery={(query) => setFilterCompetences(query)}
                  />
                ) : (
                  <Button
                    onClick={() => setAddCompetencyProfiles(true)}
                    variant="outline"
                  >
                    {t('pages.create_edit_course.add_competency_profiles')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-auto flex border-t border-grey-02 py-5">
        <div className=" mx-auto flex w-full max-w-screen-md justify-end px-3">
          <Button
            onClick={() => handleNextStep()}
            data-testid="nextBtn"
            className="ml-auto"
          >
            {t('button.next')}
          </Button>
        </div>
      </div>
    </>
  );
};
export default StepBasicSettings;

import { useTranslation } from 'react-i18next';
import LearnCourseOverview from './LearnCourseOverview/LearnCourseOverview';

function LearnInstructionsPage() {
  const { t } = useTranslation();

  return (
    <LearnCourseOverview
      courseType={'instruction'}
      title={t('pages.learn_instructions.title')}
    />
  );
}

export default LearnInstructionsPage;

import { useGet, put } from '@/query/fetchHooks';
import { Usedlessondetails, Directories } from '@/models/Database';
import { buildTreeFlat, newUid } from '@/util';
import { CustomNode } from './CustomNode';
import { Placeholder } from './Placeholder';
import styles from './App.module.css';
import { useEffect, useState, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { Tree, MultiBackend, getBackendOptions } from 'react-dnd-treeview';

const NewTreeList = ({
  coursecontentId,
  activeNode,
  onClickNode,
  treeRef,
  setAddLesson,
  setAddFolder,
  tree,
  setTree,
  setLessons,
  lessons,
  setDirectories,
  onRefresh,
  handleOpenLibrary,
  setMoveAcrion,
  setUpdateLesInfo,
  setLoadVersion,
  loadVersion,
  setNewActiveNode,
  newActiveNode,
  isFroalaLoaded,
}: any) => {
  return (
    <Tree
      ref={treeRef}
      initialOpen={true}
      tree={tree}
      rootId={0}
      render={(node, { depth, isOpen, onToggle }) => (
        <CustomNode
          onRefresh={onRefresh}
          active={activeNode && activeNode.id === node.id}
          node={node}
          depth={depth}
          isOpen={isOpen}
          onToggle={onToggle}
          onClick={(ev: any) => onClickNode?.(ev, node)}
          coursecontentId={coursecontentId}
          newTree={tree}
          setTree={setTree}
          setAddLesson={setAddLesson}
          setAddFolder={setAddFolder}
          setLessons={setLessons}
          lessons={lessons}
          setDirectories={setDirectories}
          handleOpenLibrary={handleOpenLibrary}
          setUpdateLesInfo={setUpdateLesInfo}
          setLoadVersion={setLoadVersion}
          loadVersion={loadVersion}
          setNewActiveNode={setNewActiveNode}
          newActiveNode={newActiveNode}
          isFroalaLoaded={isFroalaLoaded}
        />
      )}
      dragPreviewRender={(monitorProps) => {
        const width = (monitorProps.item.ref.current?.firstChild as HTMLElement)
          ?.offsetWidth;
        return (
          <CustomNode
            onRefresh={onRefresh}
            className="preview -translate-y-[50%] bg-white"
            preview
            node={monitorProps.item}
            style={{
              width: `${width}px`,
              boxShadow: '0 12px 24px -6px rgba(0, 0, 0, 0.25)',
            }}
            setAddLesson={setAddLesson}
            setAddFolder={setAddFolder}
          />
        );
      }}
      onDrop={(
        treeData,
        {
          dragSourceId,
          dropTargetId,
          dragSource,
          dropTarget,
          destinationIndex,
          relativeIndex,
        }
      ) => {
        const node = treeData[destinationIndex || 0] as any;

        /* This code is a little tricky, basically I make a list of descendants
         *  and siblings (including the node that is moved)
         *  - Descendants need to have their path adjusted (sometimes)
         *  - siblings need to have their yindex adjusted (sometimes)
         *  - current node needs both
         */

        //find siblings after node being moved
        const siblings = treeData.filter(
          (x) => x.parent === treeData[destinationIndex || 0].parent
        );

        //find decendants
        const parentList = [node.id];
        const decendants = treeData.filter((x) => {
          if (parentList.includes(x.parent)) {
            parentList.push(x.id);
            return true;
          }
          return false;
        });

        const extraLessons = lessons?.filter(
          (les: any) =>
            (les?.rootLessonId || les?.rootDirectoryId) &&
            les?.path?.includes(node?.data?.path)
        );

        const srcPath = `${(dragSource?.data as any)?.path || '/'}`;
        const dstPath =
          `${(dropTarget?.data as any)?.path || ''}` +
          node.data.path.slice(node.data.path.lastIndexOf('/'));

        for (const i in extraLessons) {
          const extraLesson = extraLessons[i] as any;
          const lessonId = extraLesson.lessonId;
          const assetId = extraLesson.assetId;
          const path = extraLesson.path.replace(srcPath, dstPath);
          if (path === extraLesson.path) continue;
          extraLesson.path = path;
          if (lessonId || assetId) {
            put(`/use/${extraLesson.usesId}`, { path });
          }
        }

        //re-path descendants
        for (const i in decendants) {
          const decendant = decendants[i] as any;
          const lessonId = decendant.data.lessonId;
          const assetId = decendant.data.assetId;
          const path = decendant.data.path.replace(srcPath, dstPath);
          if (path === decendant.data.path) continue;
          decendant.data.path = path;
          if (lessonId || assetId) {
            put(`/use/${decendant.data.usesId}`, { path });
          } else {
            const directoryId = decendant.data.id;
            put(`/directory/${directoryId}`, { path });
          }
        }

        //re-index siblings and I
        for (const i in siblings) {
          const sibling = siblings[i] as any;
          const lessonId = sibling.data.lessonId;
          const assetId = sibling.data.assetId;
          const yindex = parseFloat(i) + 1;
          const path = sibling.id === node.id ? dstPath : sibling.data.path;
          if (path === sibling.data.path && yindex === sibling.data.yindex)
            continue;
          sibling.data.yindex = yindex;
          sibling.data.path = path;
          if (lessonId || assetId) {
            put(`/use/${sibling.data.usesId}`, {
              yindex,
              path: sibling.id === node.id ? dstPath : undefined,
            });
          } else {
            const directoryId = sibling.data.id;
            put(`/directory/${directoryId}`, {
              yindex,
              path: sibling.id === node.id ? dstPath : undefined,
            });
          }
        }
        // console.log('move');
        setMoveAcrion(newUid(20));
        setTree(treeData);
        setTimeout(() => {
          (treeRef.current as any).open(dropTargetId);
        });
      }}
      classes={{
        root: `${styles.treeRoot} pb-3`,
        draggingSource: styles.draggingSource,
        placeholder: styles.placeholderContainer,
      }}
      sort={false}
      insertDroppableFirst={false}
      canDrop={(tree, { dragSource, dropTargetId }) => {
        if (dragSource?.parent === dropTargetId) {
          return true;
        }
      }}
      dropTargetOffset={5}
      placeholderRender={(node, { depth }) => (
        <Placeholder node={node} depth={depth} />
      )}
    />
  );
};

export const LessonTreeEdit = ({
  coursecontentId,
  courseId,
  activeNode,
  onClickNode,
  setPrevTree,
  addLesson,
  setAddLesson,
  addFolder,
  setAddFolder,
  isRefreshPage,
  onRefresh,
  setTree,
  tree,
  handleOpenLibrary,
  setDirectories,
  directories,
  setLessons,
  lessons,
  setUpdateLesInfo,
  setLoadVersion,
  loadVersion,
  setNewActiveNode,
  newActiveNode,
  isFroalaLoaded,
}: {
  courseId?: number | string | undefined;
  coursecontentId: number | string;
  activeNode?: any;
  onClickNode?: (event: any, node: any) => void;
  setPrevTree?: any;
  addLesson?: any;
  setAddLesson?: any;
  addFolder?: any;
  setAddFolder?: any;
  isRefreshPage?: boolean;
  onRefresh?: any;
  setTree?: any;
  tree?: any;
  handleOpenLibrary?: any;
  setDirectories?: any;
  directories?: any;
  setLessons?: any;
  lessons?: any;
  setUpdateLesInfo?: any;
  setLoadVersion?: any;
  loadVersion?: any;
  setNewActiveNode?: any;
  newActiveNode?: any;
  isFroalaLoaded?: any;
}) => {
  const treeRef = useRef(null);

  const [lessonResult, setLessonResult] = useState<any>(null);
  const [directoriesResult, setDirectoriesResult] = useState<any>(null);
  const [moveAcrion, setMoveAcrion] = useState<any>('');
  const [loadeTree, setLoadeTree] = useState<any>(newUid(20));

  let lessonPath = '/usedlessonassets';
  if (courseId) lessonPath = '/usedlessonassets';

  useGet<Array<Usedlessondetails>>(lessonPath, {
    count: 10000,
    courseId,
    coursecontentId,
    onSuccess: (result: any) => {
      setLessonResult(result?.data || null);
    },
  });
  useGet<Array<Directories>>('/directoriedetails', {
    count: 10000,
    coursecontentId,
    onSuccess: (result: any) => {
      setDirectoriesResult(result?.data || null);
    },
  });

  useEffect(() => {
    if (lessonResult && directoriesResult) {
      const resLessons: Array<Usedlessondetails> | undefined =
        lessonResult || undefined;
      const resDirectories: Array<Directories> | undefined =
        directoriesResult || undefined;
      setLessons(resLessons);
      setDirectories(resDirectories);
    }
  }, [lessonResult, directoriesResult]);

  useEffect(() => {
    if (addLesson) {
      setLessons((prev: any) =>
        addLesson && prev ? [...prev, addLesson] : prev
      );
    }
  }, [addLesson]);

  useEffect(() => {
    if (addFolder) {
      setDirectories((prev: any) => (addFolder ? [...prev, addFolder] : prev));
    }
  }, [addFolder]);

  useEffect(() => {
    if (lessons && directories) {
      const courseLessons = lessons.filter(
        (les: any) => !les?.rootLessonId && !les?.rootDirectoryId
      );

      const [newTree] = buildTreeFlat(courseLessons, directories) as any;

      setTree(newTree);
      setPrevTree(newTree);
    }
  }, [lessons, directories, moveAcrion]);

  useEffect(() => {
    if (isRefreshPage) {
      window.location.reload();
    }
  }, [isRefreshPage]);

  useEffect(() => {
    setLoadeTree(newUid(20));
  }, [tree?.length]);

  if (!tree) return null;

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <NewTreeList
        key={loadeTree}
        onRefresh={onRefresh}
        coursecontentId={coursecontentId}
        activeNode={activeNode}
        onClickNode={onClickNode}
        treeRef={treeRef}
        setAddLesson={setAddLesson}
        setAddFolder={setAddFolder}
        tree={tree}
        setTree={setTree}
        setLessons={setLessons}
        lessons={lessons}
        setDirectories={setDirectories}
        handleOpenLibrary={handleOpenLibrary}
        setMoveAcrion={setMoveAcrion}
        setUpdateLesInfo={setUpdateLesInfo}
        setLoadVersion={setLoadVersion}
        loadVersion={loadVersion}
        setNewActiveNode={setNewActiveNode}
        newActiveNode={newActiveNode}
        isFroalaLoaded={isFroalaLoaded}
      />
    </DndProvider>
  );
};

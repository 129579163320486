import { useGet } from '@/query/fetchHooks';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { Panel, IconButton, Button, Switch } from '@teo/components';
import { Pencil, ArrowRight } from '@teo/components/icons';
import { T } from '@/components/T';
import {
  parseDateTime,
  parseDate,
  convertToPlain,
  newUid,
  parseContentRange,
} from '@/util';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { hintsData } from '@/components/Hints/hintsData';
import { axios } from '@/axios';
import { config } from '@/config';

import i18n from 'i18next';
import meta from '@/meta.json';

function HelpIndexPage() {
  const [updates, setUpdates] = useState<any[]>([]);
  const [examples, setExamples] = useState([]);
  const [disabledHint, setDisabledHint] = useState(false);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;
  const { hints, setHints } = useContext(HintsContext);
  const { setNewPage } = useContext(PageUrlContext);

  useGet('/teoupdates', {
    count: 3,
    lang: i18n.language,
    sort: '-createdAt',
    onSuccess: async (result: any) => {
      let mostRecentVersion = parseContentRange(result);
      for (let i = 0; i < result.data.length; i++) {
        result.data[i].version = mostRecentVersion--;
      }
      setUpdates(result?.data);
    },
  });

  useGet('/faqs', {
    count: 3,
    lang: i18n.language,
    onSuccess: async (result: any) => {
      setExamples(result?.data);
    },
  });

  useEffect(() => {
    if (hints?.allHints?.length) {
      const allHits = hintsData?.length;
      const disabledAll = hints?.allHints
        ?.slice(0, allHits)
        .every((element) => element === '1');
      setDisabledHint(disabledAll);
    }
  }, [hints]);

  const handleHints = async (isCheck: any) => {
    const allHits = hintsData?.length;
    const updatedHintFlags = hints?.allHints?.map((value: any, index: any) => {
      if (isCheck) {
        if (index < allHits && value === '0') {
          return '1';
        }
      } else {
        if (index < allHits && value === '1') {
          return '0';
        }
      }
      return value;
    });

    const promises: any = [];
    promises.push(
      axios.put(`${config.backend}/user/${authUser?.id}`, {
        hintFlags: updatedHintFlags?.join(''),
        id: authUser?.id,
      })
    );
    await Promise.allSettled(promises).then(() => {
      setHints((prev: any) => ({
        ...prev,
        id: null,
        active: null,
        allHints: updatedHintFlags,
        pageHints: [],
      }));
      setNewPage(newUid(20));
    });
  };

  const isAdmin = (authUser?.role || 0) >= 90;

  return (
    <>
      <h1 className="my-8 flex justify-between text-xl font-semibold text-black">
        {i18n.t('pages.help.title')}
        {isAdmin && (
          <Button
            onClick={(ev) => setTimeout(() => navigate('./updates/new'), 0)}
          >
            <T>button.add_btn</T>
          </Button>
        )}
      </h1>
      <Panel background="grey" className="relative p-8 min-[768px]:pr-[200px]">
        <img
          className="p-4 min-[768px]:absolute min-[768px]:right-0 min-[768px]:top-0"
          src={'/images/teofly.svg'}
        ></img>
        <h3>
          <T>pages.help.update_title</T>
        </h3>
        <span className="text-sm font-normal text-grey-08">
          <T>pages.help.current_version</T> {meta.version}
        </span>
        <div className="fr-view mt-4 flex flex-col gap-4">
          {updates.map((update) => (
            <div key={update.id}>
              <div className="flex items-center gap-4">
                <div className="text-sm">{parseDate(update.createdAt)}</div>
                {isAdmin && (
                  <IconButton
                    Icon={Pencil}
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setTimeout(() => navigate(`./updates/${update.id}`), 0);
                    }}
                  />
                )}
              </div>
              <div
                className="fr-view sane-lists"
                dangerouslySetInnerHTML={{ __html: update.text }}
              />
            </div>
          ))}
        </div>
        <Link
          to={`./updates/overview`}
          className="align-center mt-8 flex text-sm font-normal text-[#1173dd]"
        >
          <span className="text-[#7d7d81]">
            {i18n.t('pages.faq.read_more')}
          </span>
          <ArrowRight className="ml-4 w-6 text-[#7D7D81]" />
        </Link>
      </Panel>
      <div className="mt-4 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
        <div data-testid="reportEmail">
          <Switch
            checked={disabledHint}
            onChange={(isCheck) => {
              setDisabledHint(isCheck);
              handleHints(isCheck);
            }}
          />
        </div>
        <div>
          <h4 className="font-normal">{i18n.t('pages.faq.disabled_hint')}</h4>
        </div>
      </div>
      <Panel className="mt-4">
        <h1 className="m-4 text-xl font-semibold text-black">
          <T>pages.faq.title</T>
        </h1>
        <section className="m-4 grid grid-cols-3 gap-4 max-[767px]:grid-cols-1">
          {examples.map((faq: any) => (
            <Panel
              key={faq.id}
              className="flex flex-col justify-between gap-4 break-words"
            >
              <div className="flex flex-col gap-4 break-words p-4">
                <h3>{faq.question}</h3>
                <p className="flex: 1">{convertToPlain(faq.answer, 175)}</p>
              </div>
              <Link
                to={`/${i18n.language}/help/faqs/${faq.id}`}
                className="align-center flex p-4 text-sm font-normal text-[#1173dd]"
              >
                <span className="text-[#7d7d81]">
                  {i18n.t('pages.faq.read_more')}
                </span>
                <ArrowRight className="ml-4 w-6 text-[#7D7D81]" />
              </Link>
            </Panel>
          ))}
        </section>
      </Panel>
    </>
  );
}

export default HelpIndexPage;

import {
  Document,
  Chapter,
  Image,
  Play,
  ChevronUp,
  ChevronDown,
  ChevronDownFilled,
} from '@teo/components/icons';
import {
  Listbox,
  Transition,
  ListboxOptions,
  ListboxButton,
  ListboxOption,
} from '@headlessui/react';
import clsx from 'clsx';
import { Label } from '@teo/components';
import { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const LessonsQRCode = ({ tree, setActiveLesson }: any) => {
  const { t } = useTranslation();
  const [selectList, setSelectList] = useState<any>(null);
  const [selectedLesson, setSelectedLesson] = useState<any>(null);
  useEffect(() => {
    const newTree = tree?.map((el: any) => ({
      id: el?.id,
      label: el?.data?.name || el?.data?.assetName,
      el: el,
    }));
    setSelectList(newTree);
  }, [tree]);

  if (!selectList) return null;

  return (
    <div>
      <Label htmlFor="selectedLesson">
        <p className="mt-6 text-sm font-semibold">
          {t('pages.follow_up_courses.qr_modal.select_lesson')}
        </p>
      </Label>
      <Listbox
        value={selectedLesson?.label}
        onChange={(e: any) => {
          setSelectedLesson(e);
          setActiveLesson(e?.el?.data);
        }}
      >
        {({ open }) => (
          <div>
            <ListboxButton
              aria-label={'Toggle Options'}
              className={clsx(
                'flex items-center justify-between',
                'rounded-md border border-solid outline outline-2',
                'min-h-10 w-full px-3 text-base',
                {
                  'border-secondary-04 bg-white outline-secondary-01': open,
                  'border-transparent bg-grey-transparent-01 text-grey-08 outline-transparent hover:border-grey-transparent-03 hover:bg-white hover:outline-grey-transparent-01 focus:border-secondary-04 focus:bg-white focus:outline-secondary-01':
                    !open,
                }
              )}
            >
              <span
                className={`mr-2 block truncate ${
                  !selectedLesson?.label && 'text-grey-04'
                }`}
              >
                {!selectedLesson?.label
                  ? t('pages.follow_up_courses.qr_modal.no_select_lesson')
                  : selectedLesson?.label}
              </span>
              <ChevronDownFilled
                className={clsx(
                  'h-5 w-5',
                  'transition-transform',
                  open && 'rotate-180'
                )}
              />
            </ListboxButton>

            <Transition
              as={Fragment}
              leave="transition-opacity"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="listbox relative z-10">
                <ListboxOptions className="absolute mt-1 flex max-h-60 w-full flex-col gap-1 overflow-auto rounded-lg bg-white p-1 drop-shadow-s focus:outline-none">
                  {selectList?.map((option: any, optionIndex: any) => (
                    <ListboxOption
                      key={optionIndex}
                      className={({ active }: any) =>
                        clsx(
                          'rounded-md px-3 py-2 text-base text-black',
                          active && 'bg-grey-transparent-02',
                          option?.el?.data?.lessonId ||
                            option?.el?.data?.assetId
                            ? 'cursor-pointer'
                            : 'pointer-events-none'
                        )
                      }
                      value={option}
                    >
                      <div
                        className={`flex items-center ${
                          option?.id === selectedLesson?.id && 'opacity-40'
                        }`}
                        style={{
                          marginLeft: `${
                            (option?.el?.data?.path?.split('/').length - 2) * 24
                          }px`,
                        }}
                      >
                        {!option?.el?.data?.lessonId || false ? (
                          option?.el?.data?.assetId ? (
                            option?.el?.data?.assetType === 'image' ? (
                              <Image className="m-1 w-5 min-w-5 " />
                            ) : (
                              <Play className="m-1 w-5 min-w-5 " />
                            )
                          ) : (
                            <Chapter className="m-1 w-5 min-w-5" />
                          )
                        ) : (
                          <Document className={`m-1 w-5 min-w-5`} />
                        )}
                        <p className={'ml-1 block'}>{option?.label}</p>
                      </div>
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default LessonsQRCode;

import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import { Label, Button, Switch } from '@teo/components';
import { ReactSVG } from 'react-svg';
import UploadDropzoneSection from '@/components/UploadDropzoneSection/UploadDropzoneSection';
import ColorPicker from '@/components/ColorPicker';
import { Lerningpathorganisationdetails } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';

function ManageCertificatesPage() {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const defaultColor = '#FDCA33';
  const defaultColor2 = '#FFF9E9';
  const defaultColor3 = '#FEEDBB';

  const initialOrg: Lerningpathorganisationdetails = {
    organisationName: 'TEO-Training BV',
    entityNumber: '0697.679.725',
    picture: '/images/TEO_logo.svg',
    useCertificates: true,
    contact: {
      name: 'Sabien Vanderstappen',
      function: 'Bestuurder',
    },
  };

  // const [svgLoaded, setSvgLoaded] = useState(false);
  const [stroke, setStroke] = useState('black');
  const [uploadLogo, setUploadLogo] = useState(initialOrg.picture);
  const [colorPicker, setColorPicker] = useState(defaultColor);
  const [fill, setFill] = useState(defaultColor2);
  const [fill2, setFill2] = useState(defaultColor3);
  const [isTeo, setIsTeo] = useState<boolean>();
  const [organisation, setOrganisation] = useState(initialOrg);
  const [loaded, setLoaded] = useState(false);

  const { data, status } = useGet<Lerningpathorganisationdetails>(
    `/organisation/${auth?.user?.activeOrganisationId}`,
    {
      onSuccess: (result: any) => {
        if (result.data?.issueCertificates) {
          setOrganisation({
            organisationName: result.data?.organisationName,
            entityNumber: result.data?.entityNumber,
            picture: result.data?.picture,
            contact: {
              name: result.data?.contact.name,
              function: result.data?.contact.function,
            },
            useCertificates: result.data?.useCertificates,
            issueCertificates: result.data?.issueCertificates,
            certificateColor: result.data?.certificateColor,
          });
          setUploadLogo(result.data?.picture);
        } else {
          setOrganisation({
            ...initialOrg,
            useCertificates: result.data?.useCertificates,
            issueCertificates: result.data?.issueCertificates,
            certificateColor: result.data?.certificateColor,
          });
        }
        setIsTeo(!result.data?.issueCertificates);
        setColorPicker(result.data?.certificateColor);
      },
    }
  );

  useEffect(() => {
    if (colorPicker) {
      setFill(lightenHexColor(colorPicker, 0.9));
      setFill2(lightenHexColor(colorPicker, 0.7));
    }
  }, [colorPicker]);

  useEffect(() => {
    if (data?.data && !isTeo) {
      setOrganisation((prev: any) => ({
        ...prev,
        organisationName: data?.data?.organisationName,
        entityNumber: data?.data?.entityNumber,
        picture: data?.data?.picture,
        contact: {
          name: data?.data?.contact.name,
          function: data?.data?.contact.function,
        },
      }));
      setUploadLogo(data?.data?.picture);
    }
    if (data?.data && isTeo) {
      setOrganisation({
        ...initialOrg,
        useCertificates: organisation.useCertificates,
        certificateColor: data?.data?.certificateColor,
      });
      setUploadLogo(initialOrg.picture);
    }
    setLoaded(true);
  }, [isTeo]);

  const handleSave = () => {
    // success indication?
    if (!isTeo) {
      axios.put(`${config.backend}/organisation/${data?.data?.id}`, {
        picture: uploadLogo ? uploadLogo : null,
        useCertificates: organisation.useCertificates,
        issueCertificates: !isTeo,
        certificateColor: colorPicker ? colorPicker : null,
      });
    }

    if (isTeo) {
      axios.put(`${config.backend}/organisation/${data?.data?.id}`, {
        useCertificates: organisation.useCertificates,
        issueCertificates: !isTeo,
        certificateColor: colorPicker ? colorPicker : null,
      });
    }
  };

  const lightenHexColor = (hex: string, percent: number): string => {
    hex = hex.replace('#', '');

    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    r = Math.min(255, Math.floor(r + (255 - r) * percent));
    g = Math.min(255, Math.floor(g + (255 - g) * percent));
    b = Math.min(255, Math.floor(b + (255 - b) * percent));

    const toHex = (n: number) => n.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  if (!loaded) return null;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          {t('pages.manage_certificates.title')}
        </h1>
      </div>
      <div className="gap- flex flex-col">
        <div className="mt-6 rounded-lg border border-grey-02 p-6">
          <div className="flex max-w-xl flex-col">
            <p className="pb-2 text-base font-semibold text-black">
              {t('pages.manage_certificates.settings')}
            </p>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="reportEmail">
                <Switch
                  checked={organisation.useCertificates}
                  onChange={(isCheck) =>
                    setOrganisation((prev: any) => ({
                      ...prev,
                      useCertificates: isCheck,
                    }))
                  }
                />
              </div>
              <div>
                <h4 className="pb-2 font-normal">
                  {t('pages.manage_certificates.cert_switch_title')}
                </h4>
                <p className="text-xs text-grey-05">
                  {t('pages.manage_certificates.cert_switch_subtitle')}
                </p>
              </div>
            </div>
          </div>
          <div className="my-8 border-b border-grey-02"></div>
          <div className="flex max-w-xl flex-col ">
            <p className="pb-2 text-base font-semibold text-black">
              {t('pages.manage_certificates.modify')}
            </p>
          </div>
          {/* certificate*/}
          <div className="relative mt-5 min-h-[250px] max-w-[842px] sm:min-h-[350px] md:min-h-[420px] lg:min-h-[595px]">
            <ReactSVG
              className="absolute inset-0 z-0"
              src={'/images/components/organisation/teo_cert.svg'}
              renumerateIRIElements={false}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 100%; height: 100%; ');
                // const rect = svg.querySelector('rect') as SVGRectElement;

                const rect = svg.querySelector('#Frame_2') as SVGRectElement;
                rect.setAttribute('fill', fill);
                rect.setAttribute('stroke', fill2);

                const rect2 = svg.querySelector('#Frame_3') as SVGRectElement;
                rect2.setAttribute('stroke', fill2);

                const rects = svg.querySelectorAll('[name="cover"]');
                rects.forEach((rect) => {
                  rect.setAttribute('fill', fill2);
                });

                const elements = svg.querySelectorAll('[name="letter"]');
                elements.forEach((el) => {
                  el.setAttribute('fill', colorPicker);
                });
              }}
              onError={(error) => {
                console.error(error);
              }}
              // todo: more efficient way after injection and useState?
              // afterInjection={(svg) => {
              // if (svgLoaded) return;
              // setSvg(svg);
              // }} px-14
            />
            <div className="z-1 relative py-12 px-[50px] ">
              <div className="grid grid-cols-5 gap-1">
                <div className="col-span-3 md:pl-6 md:pt-2 lg:pl-8 lg:pt-12 xl:pl-16 xl:pt-10 ">
                  <p className="text-2xl font-medium text-grey-08">
                    {t('pages.manage_certificates.example.certificate')}
                  </p>
                  <h2 className="py-4 text-xs sm:text-base md:text-lg xl:text-3xl">
                    {t('pages.manage_certificates.example.course')}
                  </h2>
                  <p className="hidden text-base font-normal text-grey-08 lg:block">
                    {organisation.organisationName +
                      ' ' +
                      t('pages.manage_certificates.example.confirm')}
                  </p>
                  <h3 className="py-4 text-base md:text-lg xl:text-2xl">
                    Georg Ohm
                  </h3>
                  <p className="hidden text-xs text-grey-08 lg:block">
                    {t('pages.manage_certificates.example.success')} <br />
                    {'"' + t('pages.manage_certificates.example.course') + '"'}
                    <br />
                    {t('pages.manage_certificates.example.modules_info')}
                  </p>
                  <p className=" hidden py-2 text-xs font-semibold text-grey-08 lg:block">
                    {t('pages.manage_certificates.example.modules_1')}
                    <br />
                    {t('pages.manage_certificates.example.modules_2')}
                    <br />
                    {t('pages.manage_certificates.example.modules_3')}
                  </p>
                </div>
                <div className=" col-span-2">
                  <div
                    className={`float-right h-3/6 w-2/4 bg-contain  bg-no-repeat px-0.5 opacity-10 grayscale`}
                  >
                    {uploadLogo && <img src={uploadLogo} className="w-full" />}
                  </div>
                  {/* <div className={`bg-[url('/images/TEO_logo.svg')] bg-no-repeat bg-contain h-3/6 w-2/4  opacity-10 grayscale float-right`}>
                  </div> */}
                  {isTeo && (
                    <p className="clear-right hidden text-xs text-grey-05 lg:block lg:pt-36">
                      {t('pages.manage_certificates.example.signature')}
                    </p>
                  )}
                </div>
              </div>
              <p className="hidden text-xs font-normal  text-grey-05 sm:pt-0 md:block md:pl-8 xl:pt-24 xl:pl-16">
                {t('pages.manage_certificates.example.issuance') +
                  ' ' +
                  organisation.contact.name +
                  ' - ' +
                  organisation.contact.function +
                  ' ' +
                  organisation.organisationName +
                  ' ' +
                  organisation.entityNumber}
              </p>
            </div>
          </div>
          <div className="flex max-w-xl flex-col">
            <div>
              <Label htmlFor="upload">
                <p className="mt-6 text-sm font-semibold">
                  {uploadLogo
                    ? t('pages.manage_certificates.update_logo')
                    : t('pages.manage_certificates.add_logo')}
                </p>
              </Label>
              <div id="upload">
                <UploadDropzoneSection
                  uploadLogo={uploadLogo}
                  setUploadLogo={setUploadLogo}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="colour">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_certificates.colour_label')}
                </p>
              </Label>
              <div id="colour">
                <ColorPicker
                  colorPicker={colorPicker}
                  setColorPicker={setColorPicker}
                />
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="certDownload">
                <Switch
                  checked={isTeo}
                  onChange={(isCheck) => setIsTeo(isCheck)}
                />
              </div>
              <div>
                <h4 className="pb-2 font-normal">
                  {t('pages.manage_certificates.teo_switch_title')}
                </h4>
                <p className="text-xs text-grey-05">
                  {t('pages.manage_certificates.teo_switch_subtitle')}
                </p>
              </div>
            </div>
            <div className="flex py-6">
              <Button onClick={handleSave}>
                {t('pages.manage_certificates.button_confirm')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageCertificatesPage;

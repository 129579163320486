import { DataTable } from '@/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, Tag } from '@teo/components';
import OptionsAction from './DataTable/OptionsAction';
import { axios, publicAxios } from '@/axios';
import { config } from '@/config';
import { useNavigate, useMatches, useParams } from 'react-router-dom';
import i18n from 'i18next';
import React from 'react';
import { newUid } from '@/util';
import { useMediaQuery } from 'react-responsive';

export const CourseTable = (props: any) => {
  const { t } = useTranslation();
  const { organisationId } = useParams();
  const navigate = useNavigate();
  const basePath = window.location.pathname;
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  const goRouteId = (url: any) => {
    navigate(url);
  };

  return (
    <DataTable
      key={props.refetchTable}
      defaultSort={[
        { id: 'courseStatus', desc: false },
        { id: 'updatedAt', desc: false },
        { id: 'name', desc: false },
      ]}
      columns={[
        {
          title: t('columns.courseName'),
          id: 'name',
          className: 'col-start-1 col-end-8 row-start-1',
          render: (course: any) => (
            <div className="flex items-center">
              <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                <img
                  src={
                    course?.courseImage ||
                    course?.image ||
                    '/images/courseFallback.png'
                  }
                  alt={course.name}
                  className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                />
              </div>
              {course?.courseName || course?.name}
            </div>
          ),
        },
        {
          addTitleOnMobile: true,
          id: 'version',
          title: t('columns.version'),
          className: 'col-start-9 col-end-10 row-start-1  justify-end',
          align: 'right',
        },
        {
          addTitleOnMobile: true,
          id: 'nrOfStudents',
          title: t('columns.nrOfStudents'),
          className:
            'col-start-4 col-end-7 row-start-2 justify-center text-center',
          align: 'right',
        },
        {
          addTitleOnMobile: true,
          id: 'nrOfTeams',
          title: t('columns.nrOfTeams'),
          className: 'col-start-1 col-end-4 row-start-2',
          align: 'right',
          sortable: false,
          render: (course: any) => (
            <div>
              {course?.nrOfTeamsInCourse ? course?.nrOfTeamsInCourse : 0}
            </div>
          ),
        },
        {
          addTitleOnMobile: true,
          className: 'col-start-8 col-end-10 row-start-2 justify-end',
          id: 'createdAt',
          title: t('columns.created'),
          render: (course: any) =>
            format(new Date(course.createdAt), 'dd/MM/yyyy'),
          align: 'right',
        },
        {
          addTitleOnMobile: true,
          id: 'courseStatus',
          title: t('columns.status'),
          className: 'col-start-1 col-end-4 row-start-3',
          align: 'right',
          render: (course: any) => (
            <Tag
              title={t(course.courseStatus)}
              variant={course.courseStatus === 'active' ? 'success' : 'default'}
            />
          ),
        },
        {
          addTitleOnMobile: true,
          id: 'closeTeam',
          title: '',
          className: 'col-start-5 col-end-10 row-start-3 justify-end',
          align: 'right',
          render: (course: any) => (
            <>
              {isDesktop ? (
                course?.courseStatus !== 'disabled' && (
                  <Button
                    variant="destruct"
                    size="md"
                    className="flex items-center"
                    onClick={async (event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      const result = await axios
                        .put(`${config.backend}/course/${course.id}`, {
                          courseStatus: 'disabled',
                        })
                        .then(() => props.setRefetchTable(newUid(20)));
                      props.setCourse(Object.assign({}, course, result?.data));
                    }}
                  >
                    {course?.courseType == 'assessment'
                      ? t('pages.follow_up_teams.close_assessment')
                      : course?.courseType == 'leervacature'
                      ? t('pages.follow_up_teams.close_vacancy')
                      : course?.courseType == 'instruction'
                      ? t('pages.follow_up_teams.close_instruction')
                      : t('pages.follow_up_teams.close_course')}
                  </Button>
                )
              ) : (
                <Button
                  className="relative z-[2] flex items-center"
                  variant="outline"
                  size="sm"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const rowPath = props?.rowPath?.(course);
                    goRouteId(rowPath);
                    window.scrollTo(0, 0);
                  }}
                >
                  {t('pages.follow_up.to_details')}
                </Button>
              )}
            </>
          ),
        },
        // {
        /*
          id: 'actionButton',
          className: 'col-start-9 row-start-1 justify-end',
          sortable: false,
          render: (course: any) => (
            <OptionsAction
              menuItems={[
                {
                  addIcon: true,
                  id: '1',
                  title: t('pages.follow_up.go_to_career_courseAA'),
                  onClick: (ev) => {
                    navigate(`/${i18n.language}/create/${organisationId}/${props.courseType}s/${course?.id}`)
                  },
                },
                // {
                //   id: '2',
                //   title: t('pages.follow_up.follow_courses'),
                // },
                {
                  addOnMobile: true,
                  itemDelete: true,
                  id: '3',
                  onClick: (ev) => {
                    await axios.delete(`${config.backend}/courseteam/${course?.id}/${usr?.userId}/`);

                    /courseteam/:courseId/:teamId

                    setResetUserTable(newUid(20));
                  },
                  title: t('pages.follow_up.delete_course'),
                },
              ]}
              pageDetail={{
                title: t('pages.follow_up.go_to_career_course'),
                rowPath: (course) => `${basePath}/${course.id}`,
              }}
              rowInfo={course}
              mobileHeader={{
                render: (course: any) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                      <img
                        src={course.courseImage || '/images/courseFallback.png'}
                        alt={course.name}
                        className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                      />
                    </div>
                    {course.name}
                  </div>
                ),
              }}
            />
          ),
        */
        // },
      ]}
      {...props}
    />
  );
};

import React, { useState, useEffect } from 'react';
import { isEmpty, newUid, round2 } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import { BoxGUI } from '@/components/Froala/widgets/teo_box/BoxGUI';
import { Button, Switch } from '@teo/components';
import { T } from '@/components/T';
import { BoxIoService } from '@/components/Froala/widgets/teo_box/BoxIoService';
import { Check } from '@teo/components/icons';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';

export const BoxErrorView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  BoxErrorView.syncStates(state, answer, correction);
  const [modified, setModified] = useState(null);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const answered = submitted || isCorrected;

  const [ref, setRef] = useState(newUid(20));
  const [loading, setLoading] = useState(false);
  const service = BoxIoService.getInstance();

  const [initialised, setInitialised] = useState(false);
  const [boxConnected, setBoxConnected] = useState(false);
  const [ovlWarning, setOvlWarning] = useState(false);
  const [evaluate, setEvaluate] = useState(null);
  const [attempts, setAttempts] = useState(answer.attempts);
  const [correct, setCorrect] = useState(answer.correct);
  const [tempCorrect, setTempCorrect] = useState(answer.correct);

  const [faultEnabled, setFaultEnabled] = useState(false);

  useEffect(() => {
    if (faultEnabled) {
      service.queueTriggers(state.uid, state.triggers);
      service.sendReset();
    } else {
      service.queueTriggers(state.uid, []);
      service.sendReset();
    }
  }, [faultEnabled]);

  //generate the answer
  const getAnswer = () => {
    answer.attempts = attempts;
    answer.correct = correct;
    answer.incomplete = !submitted && !correct && attempts < state.nrOfAttempts;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    correction = {};
    BoxErrorView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  useEffect(() => {
    const initializedSubscription = service.onInitialize.subscribe(
      (initialized) => {
        setInitialised(initialized);
      }
    );
    const boxConnectedSubscription = service.boxConnected.subscribe(
      (boxConnected) => {
        setBoxConnected(boxConnected);
      }
    );
    const ovlSubscription = service.OVLWarning.subscribe((ovlWarning) => {
      setOvlWarning(ovlWarning);
    });
    return () => {
      service.stopTriggers(state.uid);
      initializedSubscription.unsubscribe();
      boxConnectedSubscription.unsubscribe();
      ovlSubscription.unsubscribe();
    };
  }, []);

  const evaluateDisabled = false;

  if (state.hidden) return null;

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          //score={round2(correction.score)}
          //maxScore={state.score}
          answered={answered}
        >
          <div className="flex w-full items-center justify-between">
            {state.titleWidget || t('widgets.type.box_error')}
          </div>
        </WidgetHeader>
      )}
      <div>
        <FroalaTextarea mode="view" value={state.vraag}></FroalaTextarea>
      </div>
      <div
        className={`flex gap-4 ${initialised || boxConnected ? '' : 'm-auto'}`}
      >
        {!boxConnected && (
          <Button
            className="box-connect-button mt-1"
            onClick={async () => {
              setLoading(true);
              await service.netWorkService.Connect();
              setLoading(false);
            }}
          >
            <T>widgets.box.connect</T>
          </Button>
        )}
        {initialised && (
          <div className="mt-1 flex gap-3">
            <Switch
              checked={faultEnabled}
              onChange={(isCheck) => setFaultEnabled(isCheck)}
            />
            <div className="mr-4">{t('widgets.box.find_error')}</div>
          </div>
        )}
      </div>
      {showAnswers && (
        <>
          <span className="text-lg font-semibold text-grey-04">
            {t('widgets.box.corrections')}
          </span>
          <table
            className="borderless-table"
            style={{ borderCollapse: 'collapse' }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '20%' }}>
                  <T>widgets.box.name</T>
                </th>
                <th style={{ textAlign: 'left' }}>
                  <T>widgets.box.description</T>
                </th>
                <th style={{ width: '40px' }} title={t('COUNT_TOWARDS_SCORE')}>
                  <T>widgets.box.free</T>
                </th>
              </tr>
            </thead>
            <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
              {state?.corrections?.map((corr, i) => (
                <tr key={'correction_' + i} style={{ alignItems: 'center' }}>
                  <td style={{ verticalAlign: 'middle', width: '250px' }}>
                    {corr.name}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {corr.description}
                  </td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    {corr.free ? 'x' : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {(resultPages ||
        submitted ||
        showAnswers ||
        answered ||
        correct ||
        (attempts && attempts >= state.nrOfAttempts)) &&
      state?.antwoord ? (
        <ExplanationSection state={state} />
      ) : null}
    </div>
  );
};

BoxErrorView.syncStates = (state, answer, correction) => {
  if (isEmpty(answer)) {
    answer.uid = state.uid;
    answer.answered = false;
    answer.correct = false;
    answer.attempts = 0;
  }
  if (isEmpty(correction)) {
    if (BoxGUI.instances[state.uid]) {
      BoxGUI.instances[state.uid].evaluate(state.incorrectMessage, false);
    }

    correction.uid = state.uid;

    if (answer.correct) {
      if (
        state.nrOfAttempts &&
        Number.isInteger(parseFloat(state.nrOfAttempts))
      ) {
        correction.score =
          (state.score / state.nrOfAttempts) *
          (state.nrOfAttempts - Math.min(answer.attempts, state.nrOfAttempts));
      } else {
        correction.score = state.score;
      }
    } else {
      correction.score = 0;
    }
  }
};

export const CourseInfo = ({ courseInfo }: any) => {
  const combinedSubInfo = courseInfo?.subTitles.map(
    (title: any, index: any) => ({
      title,
      text: courseInfo?.subTexts[index],
      image: courseInfo?.subImages[index],
    })
  );

  return (
    <div className="mt-3 flex flex-col">
      {combinedSubInfo?.map((info: any, index: any) => {
        return (
          <div key={index} className="my-4 flex w-full flex-col gap-2">
            {info?.image && (
              <div>
                <img
                  src={info?.image}
                  className="w-full rounded-md object-cover"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = '/assets/images/opleidingen.jpg';
                  }}
                />
              </div>
            )}
            {info?.title && (
              <h3 className="text-xl text-grey-08">{info?.title}</h3>
            )}
            {info?.text && (
              <p
                className="text-sm text-grey-08 sm:text-base"
                dangerouslySetInnerHTML={{ __html: info?.text }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

import { useState, createRef, useEffect, useCallback } from 'react';
import { isEmpty, loadRawScript, copyToClipboard, deep_copy } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { initMath, MathView } from './MathView';
import {
  InputSelect,
  InputText,
  InputTextarea,
  Modal,
  Button,
} from '@teo/components';
import { Question } from '@teo/components/icons';
import { ErrorBox } from '@/components/ErrorBox';
import './MathEdit.css';

export const initialMath = () => ({
  noSubmit: true,
  type: 'Math',
  latex: '',
});

export const initialInlineMath = () => ({
  noSubmit: true,
  type: 'Math',
  latex: '',
  inline: true,
});

export const MathEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();
  const [latex, setLatex] = useState(state.latex);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(state.title);

  const editorRef = createRef();
  const myRef = createRef();
  let mathField;

  const getState = async () => {
    let newState = deep_copy(state);
    newState.latex = latex;
    newState.title = title;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    initMath().then(() => {
      try {
        window.katex.render(latex, myRef.current, {
          displayMode: true,
        });
        if (mathField && latex !== mathField.latex()) {
          mathField.latex(latex);
        }
        setError(null);
      } catch (e) {
        setError(e);
      }
    });
  }, [latex, isOpen]);

  return (
    <>
      <Modal isOpen={isOpen}>
        <Modal.Header onClose={() => setIsOpen(false)}>
          {t(
            !state.inline
              ? 'widgets.math.title_block'
              : 'widgets.math.title_inline'
          )}
        </Modal.Header>
        <Modal.Body>
          <div className={`flex flex-col gap-4 lg:p-4`}>
            {!state.inline && (
              <>
                <span className="text-lg font-semibold text-grey-04">
                  {t('widgets.math.formula_title')}
                </span>
                <InputText
                  onChange={(ev) => setTitle(ev.target.value)}
                  value={title}
                />
              </>
            )}
            <span className="text-lg font-semibold text-grey-04">
              {t('widgets.math.simple_editor')}
            </span>
            <div
              ref={async (node) => {
                await initMath();
                if (node) {
                  let MQ = window.MathQuill.getInterface(2);
                  mathField = MQ.MathField(node, {
                    handlers: {
                      edit: function () {
                        setLatex(mathField.latex());
                      },
                    },
                  });
                  if (mathField && latex !== mathField.latex()) {
                    mathField.latex(latex);
                  }
                  editorRef.current = node;
                }
              }}
              className="textarea _katex"
              style={{
                boxShadow: 'none',
                display: 'block',
                height: '100px',
                borderRadius: '6px',
                borderWidth: '1px',
                backgroundColor: 'rgba(30, 30, 32, 0.05) !important',
              }}
              id={state.uid + '_mq_editcontent'}
            ></div>
            <span className="text-lg font-semibold text-grey-04">
              {t('widgets.math.advanced_editor')}
              <a
                href="https://katex.org/docs/supported.html"
                target="_blanl"
                className=""
                title="Supported Functions"
              >
                <Question className="ml-1 inline-block h-6 w-6 text-secondary-04" />
              </a>
            </span>
            <InputTextarea
              id="outlined-multiline-static"
              label={t('Script')}
              value={latex}
              onChange={(e) => {
                setLatex(e.target.value);
              }}
              multiline
              rows={4}
            />
            <span className="text-lg font-semibold text-grey-04">
              {t('widgets.math.preview')}
            </span>
            {error && <ErrorBox text={error.message} />}

            <div
              className="_katex"
              style={{ padding: 20, justifyContent: 'center' }}
            >
              <span style={{ minHeight: '20px' }} ref={myRef}></span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex w-full justify-between">
            <Button variant="ghost" onClick={() => setIsOpen(false)}>
              {t('forms.cancel')}
            </Button>
            <Button onClick={() => setIsOpen(false)}>
              {t('forms.confirm_formal')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <MathView
        // state={{ latex: latex, inline: state.inline, title: title }}
        state={{ ...state, latex: latex }}
        onClick={() => setIsOpen(true)}
      />
    </>
  );
};

MathEdit.syncStates = (state, answer, correction) => {}; //nothing to sync, but here for compatibility reasons

import { useState, useEffect, useRef, useCallback } from 'react';
import { newUid, deep_copy } from '@/util';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import {
  Switch,
  InputScore,
  IconButton,
  Button,
  Spinner,
} from '@teo/components';
import { DragHandle, Image, Cross, Plus } from '@teo/components/icons';
import { uploadImage } from '@/query/documents';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useMediaQuery } from 'react-responsive';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import OrderAI from './AIWidget/OrderAI/OrderAI';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialOrderVraag = () => ({
  score: 1,
  vraag: '',
  type: 'OrderVraag',
  orderQuestions: [
    {
      file: null,
      text: '',
      index: 0,
    },
    {
      file: null,
      text: '',
      index: 1,
    },
  ],
});

const CustomDragCard = (props) => {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: 'calc(100% - 64px)',
    maxWidth: '640px',
    height: '100%',
  };
  function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      test: monitor,
    }));

  function renderItem() {
    switch (itemType) {
      case 'card':
        return (
          <div
            className={`fr-draggable flex w-full flex-row items-center gap-3 rounded-lg border border-grey-02 bg-white px-4 ${
              item.file ? 'py-1' : 'py-3'
            }`}
          >
            <DragHandle className="mx-5 w-5 min-w-5" />
            <div className="mr-auto">
              <input
                type="text"
                value={item.text}
                onChange={() => {}}
                className="w-full border-0 border-b border-transparent p-1 text-sm !shadow-none !ring-0 focus:border-0 focus:border-b focus:border-grey-02"
              />
            </div>

            {item.file ? (
              <div className="relative flex flex-row items-center gap-2 rounded border border-grey-02 bg-grey-02 p-1">
                <Button
                  size="sm"
                  className="!px-1"
                  onClick={() => {}}
                  variant="ghost"
                >
                  <img src="/images/icons/trash_icon.svg" alt="delete button" />
                </Button>
                <Button
                  variant="ghost"
                  className="!bg-white !p-0"
                  onClick={() => {}}
                >
                  <img
                    alt={item.text}
                    src={item.file}
                    className="h-14 w-14 rounded border border-tint-indigo object-cover object-center"
                  />
                </Button>
              </div>
            ) : (
              <>
                <IconButton
                  Icon={Image}
                  ariaLabel="add image"
                  onClick={() => {}}
                  variant="ghost"
                  className="!text-grey-07"
                />
              </>
            )}
            <IconButton
              Icon={Cross}
              ariaLabel="delete order"
              onClick={() => {}}
              variant="ghost"
              className="!text-grey-07"
            />
          </div>
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}
      >
        {renderItem()}
      </div>
    </div>
  );
};

const CardOrder = ({
  order,
  index,
  setOrderQuestions,
  orderQuestions,
  moveCard,
  uidState,
}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const fileInputRef = useRef(null);
  const [maginify, setMagnify] = useState(false);
  const [oredrText, setOrderText] = useState(order?.text);
  const [loadedImage, setLoadedImage] = useState(false);

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY * 0.1) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY * 1.9) {
        return;
      }
      isDesktop && moveCard(dragIndex, hoverIndex);

      isDesktop && (item.index = hoverIndex);
      if (!isDesktop) {
        hoverIndex !== 0 &&
          document
            .querySelector(`.OI_${hoverIndex - 1}`)
            .classList.remove('pt-[76px]', 'pb-[76px]');
        hoverIndex !== orderQuestions.length - 1 &&
          document
            .querySelector(`.OI_${hoverIndex + 1}`)
            .classList.remove('pt-[76px]', 'pb-[76px]');

        hoverIndex > 1 &&
          document
            .querySelector(`.OI_${hoverIndex - 2}`)
            .classList.remove('pt-[76px]', 'pb-[76px]');
        hoverIndex < orderQuestions.length - 2 &&
          document
            .querySelector(`.OI_${hoverIndex + 2}`)
            .classList.remove('pt-[76px]', 'pb-[76px]');

        if (dragIndex > hoverIndex) {
          document
            .querySelector(`.OI_${hoverIndex}`)
            .classList.add('pt-[76px]');
        } else {
          document
            .querySelector(`.OI_${hoverIndex}`)
            .classList.add('pb-[76px]');
        }
      }
    },
    drop: (item) => {
      // console.log(item);
      !isDesktop && moveCard(item.index, index);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return {
        id: order.index,
        index: index,
        text: order.text,
        file: order.file,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging(monitor) {
      return order.index === monitor.getItem().id;
    },
  });
  drag(drop(ref));

  const handleChangeText = (value) => {
    setOrderText(value);
    // setOrderQuestions((prev) =>
    //   prev.map((el, i) => (i == index ? { ...el, text: value } : el))
    // );
  };

  const handleBlur = () => {
    setOrderQuestions((prev) =>
      prev.map((el, i) => (i == index ? { ...el, text: oredrText } : el))
    );
  };

  const handleDeleteItem = (index) => {
    const newOrderList = orderQuestions.map((el, i) => {
      if (i !== index) {
        if (el?.index > order?.index) {
          return { ...el, index: el?.index - 1 };
        } else {
          return el;
        }
      }
    });

    setOrderQuestions(
      newOrderList?.filter((el, i) => {
        return i !== index;
      })
    );
  };

  const handleUploadPhoto = async (event) => {
    setLoadedImage(true);
    const img = event.target.files[0];
    const data = await uploadImage(img, 900, 900, 'jpeg');

    setOrderQuestions((prev) =>
      prev.map((el, i) => (i == index ? { ...el, file: data } : el))
    );
  };

  const handleDeleteImg = () => {
    setLoadedImage(false);
    setOrderQuestions((prev) =>
      prev.map((el, i) => (i == index ? { ...el, file: null } : el))
    );
  };

  const handleOpenImage = () => {
    setMagnify(!maginify);
    const editorElement = document.querySelector('.fr-editor-block');
    const editorToolbar = document.querySelector('.fr-toolbar');

    if (editorElement) {
      editorElement.style.setProperty('z-index', '11', 'important');
    }
    if (editorElement) {
      editorToolbar.style.setProperty('z-index', '0', 'important');
      editorToolbar.style.setProperty('opacity', '0', 'important');
    }
  };
  const handleCloseImage = () => {
    setMagnify(!maginify);

    const editorElement = document.querySelector('.fr-editor-block');
    const editorToolbar = document.querySelector('.fr-toolbar');
    if (editorElement) {
      editorElement.style.setProperty('z-index', '0', 'important');
    }
    if (editorElement) {
      editorToolbar.style.setProperty('z-index', '5', 'important');
      editorToolbar.style.setProperty('opacity', '1', 'important');
    }
  };

  return (
    <div
      className={`fr-draggable flex w-full OI_${index}`}
      ref={ref}
      data-handler-id={handlerId}
    >
      <div
        className={`fr-draggable flex w-full flex-row items-center gap-3 rounded-lg border border-grey-02 bg-white px-4 ${
          order.file ? 'py-1' : 'py-3'
        } ${isDragging && 'opacity-0'} ${
          isDragging && (isDesktop ? 'h-[76px]' : 'hidden	')
        }`}
      >
        <DragHandle className="w-6" />
        <div className="w-full">
          <label htmlFor={'uid_' + uidState + '_' + index}></label>
          <input
            type="text"
            id={'uid_' + uidState + '_' + index}
            value={oredrText}
            onChange={({ target: { value } }) => {
              handleChangeText(value);
            }}
            onBlur={() => handleBlur()}
            className="w-full border-0 border-b border-transparent p-1 text-sm !shadow-none !ring-0 focus:border-0 focus:border-b focus:border-grey-02"
          />
        </div>

        {order.file ? (
          <div className="relative flex flex-row items-center gap-2 rounded border border-grey-02 bg-grey-02 p-1">
            <Button
              size="sm"
              className="!px-1"
              onClick={() => handleDeleteImg()}
              variant="ghost"
            >
              <img src="/images/icons/trash_icon.svg" alt="delete button" />
            </Button>
            <Button
              variant="ghost"
              className="!bg-white !p-0"
              onClick={() => handleOpenImage()}
            >
              <img
                alt={order.text}
                src={order.file}
                className="h-14 w-14 rounded border border-tint-indigo object-cover object-center"
              />
            </Button>
            {maginify && (
              <div
                className="fixed inset-0 z-10 flex bg-[#000000cc]"
                onClick={() => handleCloseImage()}
              >
                <img
                  alt={order.text}
                  src={order.file}
                  className="m-auto h-3/4 w-3/4 object-contain object-center sm:h-1/2 sm:w-1/2"
                />
              </div>
            )}
          </div>
        ) : (
          <>
            {loadedImage ? (
              <div className="relative flex flex-row items-center gap-2 rounded border border-grey-02 bg-grey-02 p-1">
                <div className="flex h-11 w-11">
                  <Spinner
                    ariaLabel="Loading spinner"
                    className="m-auto h-6 w-6 border-grey-05"
                  />
                </div>
              </div>
            ) : (
              <IconButton
                Icon={Image}
                ariaLabel="add image"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
                variant="ghost"
                className="!text-grey-07"
              />
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleUploadPhoto}
              accept="image/*"
              style={{ display: 'none' }}
              multiple={false}
            />
          </>
        )}
        <IconButton
          Icon={Cross}
          ariaLabel="delete order"
          onClick={() => handleDeleteItem(index)}
          variant="ghost"
          className="!text-grey-07"
        />
      </div>
    </div>
  );
};

export const OrderVraagEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state?.showAnswer : false
  );
  const [score, setScore] = useState(state?.score || 0);
  const [orderQuestions, setOrderQuestions] = useState(state?.orderQuestions);
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.order_question')
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoadingAI, setIsLoadingAI] = useState(false);
  const [isLoadingAIAnswer, setIsLoadingAIAnswer] = useState(false);
  const [competencies, setCompetencies] = useState([]);

  // console.log(state);

  // generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.orderQuestions = orderQuestions;
    newState.score = score;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  const handleAddItem = () => {
    if (orderQuestions?.length) {
      const newItem = { file: null, text: '', index: orderQuestions.length };
      setOrderQuestions((prev) => [...prev, newItem]);
    } else {
      const newItem = { text: '', file: null, index: 0 };
      setOrderQuestions((prev) => [...prev, newItem]);
    }

    setTimeout(() => {
      let placeholder = document.getElementById(state.uid);
      const labelElement = placeholder.querySelector(
        `[for=uid_${state.uid}_${orderQuestions?.length}]`
      );
      if (labelElement) {
        labelElement.click();
      }
    }, 0);
  };

  const Container = () => {
    {
      const moveCard = useCallback((dragIndex, hoverIndex) => {
        let sliced = orderQuestions
          .slice(0, dragIndex)
          .concat(orderQuestions.slice(dragIndex + 1));
        let newOrderQuestions = [
          ...sliced.slice(0, hoverIndex),
          orderQuestions[dragIndex],
          ...sliced.slice(hoverIndex, sliced.length),
        ];
        setOrderQuestions(newOrderQuestions);
      }, []);

      const renderCard = useCallback((card, index) => {
        return (
          <CardOrder
            key={index}
            order={card}
            index={index}
            setOrderQuestions={setOrderQuestions}
            orderQuestions={orderQuestions}
            moveCard={moveCard}
            uidState={state.uid}
          />
        );
      }, []);

      return (
        <DndProvider backend={HTML5Backend}>
          {orderQuestions?.map((card, i) => {
            return renderCard(card, i);
          })}
        </DndProvider>
      );
    }
  };

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />
      {isLoadingAI ? (
        <div className="spinner-chat mt-3">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      ) : (
        <>
          <div className="">
            <div className="wg-question">
              <QuestionSection questionVraag={questionVraag} />
            </div>
            <div className="my-4 flex gap-3">
              <Switch
                checked={showAnswer}
                onChange={(isCheck) => setShowAnswer(isCheck)}
              />
              <div className="mr-4">
                {t('widgets.widgets_edit.explain_after_submit')}
              </div>
            </div>
            <div className={`${!showAnswer ? 'hidden' : ''}`}>
              <div className="wg-answer">
                <QuestionSection
                  questionVraag={antwoordVraag}
                  exampleAnswer={true}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <p>{t('widgets.edit.order_message')}</p>
            <Container />
            {!isDesktop && <CustomDragCard />}
            {isLoadingAIAnswer ? (
              <div className="spinner-chat mt-3">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            ) : (
              <div
                className="flex w-full cursor-pointer flex-row items-center gap-3 rounded-lg border border-grey-02 bg-white px-4 py-2.5"
                onClick={() => handleAddItem()}
              >
                <Plus className="w-8 text-grey-06" />
                <div className="mr-auto text-sm text-grey-06">
                  {t('widgets.widgets_edit.add_option')}
                </div>
              </div>
            )}

            <div
              contentEditable={false}
              ref={(el) => {
                if (el) {
                  el.style.setProperty('display', 'none', 'important');
                }
              }}
              id={`${state?.uid}_gpt_open`}
              onClick={() => {
                setIsChatOpen(!isChatOpen);
              }}
            ></div>
          </div>
        </>
      )}

      {isChatOpen && (
        <OrderAI
          setQuestionVraag={setQuestionVraag}
          setOrderQuestions={setOrderQuestions}
          orderQuestions={orderQuestions}
          setIsLoadingAI={setIsLoadingAI}
          isLoadingAI={isLoadingAI}
          setIsLoadingAIAnswer={setIsLoadingAIAnswer}
          isLoadingAIAnswer={isLoadingAIAnswer}
          setIsChatOpen={setIsChatOpen}
          stateUid={state?.uid}
          questionVraag={questionVraag}
          state={state}
        />
      )}

      <div>
        <div className="mt-4 flex flex-row items-center border-t border-b border-grey-02 py-2">
          <div className="mr-2">{t('widgets.widgets_edit.max_score')}</div>
          <InputScore value={score} onChange={(value) => setScore(value)} />
        </div>
        {/*
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>

      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { Chapter, Document, Image, Play } from '@teo/components/icons';

const TreeItem = ({ node, depth }: any) => {
  const { t } = useTranslation();
  return node?.data?.newNode ? (
    node?.data?.yindex == -1 ? (
      <>
        <div
          className={`flex cursor-move items-center rounded-lg border border-secondary-04 px-4 py-3`}
        >
          <img
            src={`/images/icons/drug_dark.svg`}
            className="mr-3 h-6 w-6"
            alt="icon"
          />
          <p>{node?.text}</p>
        </div>
        <p className={`my-2 text-sm font-semibold text-black`}>
          {t(`pages.create_library.step_add.course_content`)}
        </p>
      </>
    ) : (
      <div
        className="mb-2 flex cursor-move items-center rounded-lg border border-secondary-04 px-4 py-3"
        style={{ marginLeft: `${depth * 24}px` }}
      >
        <img
          src={`/images/icons/drug_dark.svg`}
          className="mr-3 h-6 w-6"
          alt="icon"
        />
        <p>{node?.text}</p>
      </div>
    )
  ) : (
    <div
      className={`relative mb-2 flex items-center rounded-lg border border-grey-02 px-4 py-3`}
      style={{ marginLeft: `${depth * 24}px` }}
    >
      <span className="flex w-full items-center ">
        {node?.droppable || false ? (
          <Chapter className="mr-2 w-6 min-w-6" />
        ) : node?.data?.lessonId ? (
          <Document
            className={`mr-2 w-6 min-w-6 ${
              !node?.data?.canEdit && node?.data?.lessonId && 'text-grey-05'
            } `}
          />
        ) : node?.data?.assetType === 'image' ? (
          <Image className="mr-2 w-6 min-w-6 " />
        ) : (
          <Play className="mr-2 w-6 min-w-6 " />
        )}
        <p>{node?.text}</p>
      </span>
    </div>
  );
};

export default TreeItem;

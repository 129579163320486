import React, { Component, useEffect, useState, useContext } from 'react';
import i18n from 'i18next';
import {
  InputText,
  InputTextarea,
  InputDate,
  Label,
  Button,
  InputSelect,
} from '@teo/components';
import { useStateAndRef } from '@/util';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import FroalaTextAreaEdit from '@/components/Froala/FroalaTextAreaEdit';
import { useNavigate, useMatches, useParams } from 'react-router-dom';
import { axios } from '@/axios';
import { config } from '@/config';
import { userRoles } from '@/constants/userRoles.constants';
import { ConfirmModal } from '@/components/ConfirmModal';
import { languages } from '@/constants/languages';

function Notification() {
  const t = i18n.t;
  const navigate = useNavigate();
  const [ref, setRef, refRef] = useStateAndRef(null);
  const [globalLink, setGlobalLink] = useState('');
  const [releaseDate, setReleaseDate] = useState(undefined);
  const [text, setText] = useState('');
  const [confirmSend, setConfirmSend] = useState(false);
  const [minRole, setMinRole] = useState(userRoles.find((x) => x.value === 20));
  const [lang, setLang] = useState('nl');

  const { setShowNotification } = useContext(NotificationContext);

  /*
  useEffect(() => {
    if (!selected) {
      axios
        .post(`${config.backend}/faqs`, {
          lang: i18n.language,
          topic: topic ? topic : 'general',
          question: '',
          answer: '',
        })
        .then((result) => {
          setSelected(result.data);
        });
    }
  }, [selected]);
  */

  const saveContent = async () => {
    const content = await refRef.current.getContent();
    /*
    if (content === selected.answer) return;
    await axios
      .put(`${config.backend}/faq/${selected.id}`, {
        answer: content,
      })
      .catch((e) => {
        setShowNotification(i18n.t('errors.network_error'));
      });
    await setSelected({
      ...selected,
      answer: content,
    });
    setShowNotification(i18n.t('notifications.faq_saved'));
    */
  };

  return (
    <div className="flex flex-col gap-4">
      {confirmSend && (
        <ConfirmModal
          open
          title="pages.notification.send_title"
          description="pages.notification.send_description"
          onConfirm={() => setConfirmSend(false)}
        />
      )}
      <h1 className="mt-8 mb-4 text-xl font-semibold text-black">
        {i18n.t('pages.notification.title')}
      </h1>
      <div>
        <Label htmlFor="minrole">{t('pages.notification.min_role')}</Label>
        <InputSelect
          key="minrole"
          onChange={async (role) => {
            setMinRole(role);
          }}
          value={minRole}
          options={userRoles}
        >
          {(role) => t(role.label)}
        </InputSelect>
      </div>
      <div>
        <p className="mb-1 text-sm font-semibold text-black">
          {t('pages.notification.date_release')}
        </p>
        <InputDate
          id="date-input"
          onChange={(e: any) => {
            setReleaseDate(e);
          }}
          value={releaseDate}
          variant="datetime-local"
        />
      </div>
      <div>
        <Label htmlFor="language">{t('pages.profile_account.language')}</Label>
        <div id="language">
          <InputSelect
            onChange={async (language) => {
              setLang(language);
            }}
            value={lang}
            options={languages.map((lang) => lang.value)}
          >
            {(lang) => t(`langs.${lang}`)}
          </InputSelect>
        </div>
      </div>
      <div>
        <Label htmlFor="notification_link">
          {t('pages.notification.link')}
        </Label>
        <div className="mr-2 w-full">
          <InputText
            id="notification_link"
            value={globalLink}
            onChange={async (ev) => {
              setGlobalLink(ev.target.value);
            }}
            placeholder={i18n.t('pages.notification.link')}
          />
        </div>
      </div>
      <div>
        <Label htmlFor="notification_text">
          {t('pages.notification.text')}
        </Label>
        <InputTextarea
          id="notification_text"
          value={text}
          onChange={(ev: any) => setText(ev.target.value)}
        />
        {/** 
        <FroalaTextAreaEdit
          id="notification_text"
          mode="edit"
          modus="lessonEdit"
          setRef={(ref:any) => {
            setRef(ref);
          }}
          placeholder={i18n.t('pages.faq.answer')}
          //value={selected.answer}
          style={{ minHeight: '350px' }}
          onSave={async () => {
            await saveContent();
          }}
        />
        */}
      </div>
      <div>
        <Button
          value={text}
          onClick={async (ev: any) => {
            await axios.post(`${config.backend}/notifications/sendglobal`, {
              globalLink,
              releaseDate,
              text,
              lang,
              minRole: minRole?.value,
            });
            setConfirmSend(true);
          }}
        >
          {t('pages.notification.send')}
        </Button>
      </div>
    </div>
  );
}

export default Notification;

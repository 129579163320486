import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { useTranslation } from 'react-i18next';

const FeedbackSection = ({ feedback, setFeedback, viewOnly }) => {
  const { t } = useTranslation();

  // console.log(feedback);
  return (
    <>
      {feedback && viewOnly ? (
        <div className="border-transparen mb-2 rounded-md border border-solid bg-grey-transparent-01 p-3 text-sm text-grey-08">
          <p className="mb-2 uppercase text-grey-05">
            {t('pages.correction_result.teacher_feedback')}
          </p>
          <FroalaTextarea mode="view" value={feedback} />
        </div>
      ) : null}
      {!viewOnly ? (
        <div className="min-h-[200px] w-full text-sm">
          <p className="mt-3 mb-2 font-semibold">
            {t('pages.correction_result.comment_question')}
          </p>
          <FroalaTextarea
            mode={'edit'}
            value={feedback}
            onChange={(ev) => {
              setFeedback(ev.target.value);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export { FeedbackSection };

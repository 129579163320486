import { useState, useEffect } from 'react';
import { Multiselect, Label, Button, ComboBox } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { emailRegex } from '@/constants/regex.constants';
import { parseFilter } from '@/util';

const PaginationSingleSelect = ({
  fetch,
  defaultSort,
  render,
  onChange,
  value,
  setFilter,
  filter,
  ...other
}: any) => {
  const { t } = useTranslation();

  const [localFilter, setLocalFilter] = useState('');
  const myFilter = filter || localFilter;
  const setMyFilter = setFilter || setLocalFilter;

  const [data, setData] = useState<any[]>([]);

  function parseFilterTeam(filter: any) {
    if (!filter) return undefined;
    const splittedFilterValue = filter?.split(/\s+/);
    const filterValue = `%${splittedFilterValue?.join('%')}%`;
    return encodeURIComponent(filterValue);
  }

  const result: any = fetch({
    offset: 0,
    count: 10,
    sort: defaultSort
      .map((sort: any) => (sort.desc ? '-' : '') + sort.id)
      .join(','),
    q: parseFilterTeam(myFilter),
  });

  useEffect(() => {
    if (result?.data) {
      const newData = (result?.data?.data as any[]).map((x) => {
        Object.assign(x, { label: render(x) });
        return x;
      });
      setData(newData);
    }
  }, [result?.data]);

  return (
    <ComboBox
      onChange={(param) => {
        setMyFilter('');
        onChange(param);
      }}
      onCommitQuery={(email) => {
        setMyFilter('');
      }}
      options={data}
      query={myFilter}
      setQuery={(query) => setMyFilter(query)}
      value={value || []}
      {...other}
    />
  );
};

export { PaginationSingleSelect };

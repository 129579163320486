import { useContext, useState, useEffect } from 'react';
import { InputText, Button, IconButton } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight, Tag } from '@teo/components/icons';
import { axios } from '@/axios';
import { config } from '@/config';
import { useGet } from '@/query/fetchHooks';
import { PaginationMultiselectWithTags } from '@/components/PaginationMultiselectWithTags';
import { AuthContext } from '@/components/Contexts/AuthContext';
import i18n from 'i18next';
import Hint from './Hints/Hints';

export const LessonEditTitleBar = ({
  node,
  publicationName,
  prevTree,
  setLessons,
  setShowExtraInfo,
  extraInfoLessons,
  setActiveNode,
  hints,
  newPage,
}) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const [name, setName] = useState(
    node?.data?.name || node?.data?.assetName || ''
  );
  const [parentDirectories, setParentDirectories] = useState([]);
  const [nodeIndex, setNodeIndex] = useState(0);
  const [lesTags, setLesTags] = useState([]);
  const [filter, setFilter] = useState('');
  const [selectTags, setSelectTags] = useState([]);
  const [activeTags, setActiveTags] = useState([]);

  useEffect(() => {
    const newNode = prevTree?.find((el) => el.id === node.id);
    if (newNode?.data?.name !== name) {
      setName(newNode?.data?.name || newNode?.data?.assetName);
    }

    prevTree?.map((el, index) => el.id === node.id && setNodeIndex(index));
  }, [prevTree, node]);

  const saveLessonName = async () => {
    const newNode = prevTree?.find((el) => el.id === node.id);

    if (node?.data?.lessonId && name !== newNode?.data?.name) {
      axios.put(`${config.backend}/lesson/${node?.data?.lessonId}`, {
        id: node?.data?.lessonId,
        name: name,
      });
      setLessons((prev) =>
        prev?.map((el) =>
          el.id === node?.data?.id ? { ...el, name: name } : el
        )
      );
    }
    if (node?.data?.assetId && name !== newNode?.data?.assetName) {
      axios.put(`${config.backend}/asset/${node?.data?.assetId}`, {
        id: node?.data?.assetId,
        assetName: name,
      });
      setLessons((prev) =>
        prev?.map((el) =>
          el.id === node?.data?.id ? { ...el, assetName: name } : el
        )
      );
    }
  };

  const findParents = (array, targetId) => {
    const parents = [];

    const findParent = (parent) => {
      const parentObj = array.find((obj) => obj.id === parent);
      if (parentObj) {
        parents.push(parentObj);
        if (parentObj.parent !== 0) {
          findParent(parentObj.parent);
        }
      }
    };

    const targetObj = array.find((obj) => obj.id === targetId);
    if (targetObj) {
      findParent(targetObj.parent);
    }

    parents.sort((a, b) => a?.data?.path?.length - b?.data?.path?.length);

    return parents;
  };

  useEffect(() => {
    const parentFolder = findParents(prevTree, node?.id);
    setParentDirectories(parentFolder);
    setSelectTags([]);
    setActiveTags([]);
    if (node?.data?.lessonId) {
      axios
        .get(
          `${config.backend}/lessontags/?lessonId=${node?.data?.lessonId}&search=tag:%`
        )
        .then((response) => {
          setLesTags(response.data || []);
        });
    }
  }, [node]);

  useEffect(() => {
    if (lesTags?.length > 0) {
      lesTags?.map((tag) => {
        const val = selectTags?.find((el) => el.id === tag?.id);
        if (!val) {
          const lesTag = {
            id: tag?.id,
            key: tag?.tag.split('tag:')[1],
            label: tag?.tag.split('tag:')[1],
            tag: tag?.tag.split('tag:')[1],
          };
          setActiveTags((prev) => [...prev, lesTag]);
          setSelectTags((prev) => [...prev, lesTag]);
        }
      });
    }
  }, [lesTags]);

  useEffect(() => {
    handleTags();
  }, [selectTags]);

  const handleTags = () => {
    if (selectTags?.length > 0) {
      for (const addObj2 of selectTags) {
        const existingObj = activeTags?.find(
          (obj1) => obj1?.id === addObj2?.id
        );
        if (!existingObj) {
          axios.post(`${config.backend}/lessontags`, {
            lessonId: node?.data?.lessonId,
            tag: 'tag:' + addObj2?.label,
            id: addObj2?.id,
          });
          setActiveTags((prev) => [
            ...prev,
            {
              id: addObj2?.id,
              key: addObj2?.label,
              label: addObj2?.label,
              tag: addObj2?.label,
            },
          ]);
        }
      }
    }

    if (activeTags?.length > 0) {
      for (const removedTag of activeTags) {
        const existingObj = selectTags?.find(
          (obj1) => obj1?.id === removedTag?.id
        );
        if (!existingObj) {
          axios.delete(
            `${config.backend}/lessontag/${node?.data?.lessonId}/${removedTag?.id}`
          );
          setActiveTags((prev) =>
            prev?.filter((el) => el?.id !== removedTag?.id)
          );
        }
      }
    }
  };

  const handleAddTag = () => {
    const promises = [];
    promises.push(
      axios.post(`${config.backend}/tags`, {
        tag: filter,
        label: filter,
        key: Math.floor(Math.random() * 9999999999999),
        organisationId: auth?.user?.activeOrganisationId,
        lang: auth?.user?.lang,
      })
    );
    Promise.allSettled(promises).then((results) => {
      setSelectTags((prev) => [
        ...prev,
        {
          key: filter,
          label: filter,
          tag: filter,
          id: results[0].value?.data?.id,
        },
      ]);
      setFilter('');
    });
  };

  return (
    <div className="flex w-full pt-10 pb-7">
      <div className="mx-auto flex w-full max-w-[1044px] flex-col">
        <div className="flex items-start px-3">
          <div className="inline-block text-sm text-grey-06">
            <div
              className="my-2 inline-block cursor-pointer hover:text-grey-08"
              onClick={() => {
                setActiveNode(null);
              }}
            >
              {publicationName}
            </div>
            <span className="px-2">/</span>
            {parentDirectories?.length > 0 &&
              parentDirectories?.map((direct) => {
                return (
                  <div key={direct?.id} className="my-2 inline-block">
                    <div
                      className="inline-block cursor-pointer hover:text-grey-08"
                      onClick={() => setActiveNode(direct)}
                    >
                      {direct?.data?.name}
                    </div>
                    <span className="px-2">/</span>
                  </div>
                );
              })}
            <div className="my-2 inline-block text-black">{name}</div>
          </div>
          <div className="ml-auto flex gap-3 pl-6 pt-2">
            <IconButton
              className=""
              disabled={nodeIndex == 0}
              Icon={ChevronLeft}
              variant="outline"
              size="sm"
              onClick={() => setActiveNode(prevTree[nodeIndex - 1])}
            />
            <IconButton
              className=""
              disabled={prevTree?.length - 1 === nodeIndex}
              Icon={ChevronRight}
              variant="outline"
              size="sm"
              onClick={() => setActiveNode(prevTree[nodeIndex + 1])}
            />
          </div>
        </div>

        <div className="mt-6 mb-6 flex pr-3">
          <div className="flex-auto pr-6">
            <InputText
              value={name}
              onChange={(ev) => setName(ev.target.value)}
              onBlur={saveLessonName}
              customStyles="bg-white inputWhiteBg !text-2xl !text-black placeholder:text-xl"
              placeholder={t('pages.create_edit_course.course_title_chapter')}
              disabled={!node?.data?.canEdit}
            />
          </div>
          {node?.data?.lessonId && (
            <Button
              variant="secondary"
              onClick={() => setShowExtraInfo(true)}
              className={`relative ${
                hints?.id == 'hint_44' &&
                'active-hint-parent active-hint-main-menu'
              }`}
            >
              <Hint
                key={`hint_44_${newPage}`}
                id={'hint_44'}
                leftDots={'-left-2 top-[11px]'}
                title={t('hint.title_44')}
                order={44}
                style={{
                  right: '2px',
                  top: '70px',
                }}
              />
              {t('extra_info.title')}
              <span className="pl-1 text-grey-05">
                ({extraInfoLessons?.length})
              </span>
            </Button>
          )}
        </div>

        {node?.data?.lessonId && (
          <div className="relative z-[10] flex items-center px-4">
            <Tag className="mr-2 h-[17.5px] w-[17.5px] text-[#6d6d72]" />

            <div className="w-full">
              <PaginationMultiselectWithTags
                value={selectTags}
                onChange={(value) => {
                  setSelectTags(value);
                  handleTags(value);
                }}
                filter={filter}
                setFilter={setFilter}
                keyFn={(row) => row?.tag}
                fetch={useGet.bind(
                  null,
                  `/tags?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                )}
                defaultSort={[{ id: 'tag', desc: false }]}
                render={(row) => {
                  return row?.tag;
                }}
                customStyles="bg-white"
                addTagLabel={`${filter} ${t(
                  'pages.create_edit_course.addTagLabel'
                )}`}
                handleAddTag={handleAddTag}
                placeholder={
                  selectTags?.length > 0
                    ? ''
                    : t('pages.create_edit_course.tags_placeholder')
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { ArrowLeft } from '@teo/components/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Dropdown, PersonAvatar, Button } from '@teo/components';
import { NotificationButton } from '@/components/Notifications/NotificationButton';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { NavContext } from '@/components/NavContext';
import i18n from 'i18next';
import { LessonExtraInfoView } from '@/components/ExtraInfo/LessonExtraInfoView';
import { newUid } from '@/util';

const PreviewExtraInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const { setNavDisabled } = useContext(NavContext);
  const authUser = auth?.user;
  const { courseId, lessonId, extraInfoId } = useParams();
  const [allLessons, setAllLessons] = useState(null);
  const [course, setCourse] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/extra')
  );

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  useGet(`/coursedetail/${courseId}`, {
    onSuccess: (result) => {
      setCourse(result?.data || {});
    },
  });

  let lessonPath = '/usedLessons';
  if (courseId) lessonPath = '/usedcourselessons';

  const lessonResult = useGet(lessonPath, {
    count: 10000,
    courseId,
    coursecontentId: course?.coursecontentId,
  });

  const lessons = lessonResult?.data?.data;

  const assetResult = useGet('/usedassets', {
    count: 10000,
    coursecontentId: course?.coursecontentId,
  });

  const assets = assetResult?.data?.data || undefined;

  useEffect(() => {
    let resLessons = [];
    let resAssets = [];
    if (lessons?.length > 0) {
      resLessons = lessons || [];
    }
    if (assets?.length > 0) {
      resAssets = assets || [];
    }
    setAllLessons([...resLessons, ...resAssets]);
  }, [lessons, assets]);

  useEffect(() => {
    if (allLessons) {
      setExtraInfo(allLessons?.find((el) => el?.id == extraInfoId));
      setLesson(allLessons?.find((el) => el?.id == lessonId));
    }
  }, [allLessons]);

  return (
    <>
      <header
        className="sticky top-0 z-[11] flex h-[80px] min-h-16 items-center justify-start border-b border-solid border-grey-02 bg-white p-3"
        data-testid="header"
      >
        <Button
          className="flex items-center gap-3"
          variant="text"
          onClick={() => navigate(basePath)}
        >
          <ArrowLeft className="h-5 w-5" />
          <p>
            {t('extra_info.back_to_les')} {lesson?.name}
          </p>
        </Button>

        {authUser && (
          <div className="ml-auto flex flex-row gap-4">
            <NotificationButton />
            <Dropdown>
              <Dropdown.Trigger>
                <PersonAvatar
                  id={authUser.id}
                  name={authUser.username}
                  imageSrc={authUser.picture || undefined}
                />
              </Dropdown.Trigger>
              <Dropdown.Content>
                <Dropdown.Section className="flex items-center gap-3">
                  <PersonAvatar
                    id={authUser.id}
                    name={authUser.username}
                    imageSrc={authUser.picture || undefined}
                  />
                  {authUser?.username}
                </Dropdown.Section>
                <Dropdown.Actions>
                  <Dropdown.Link to={'/' + i18n.language + '/profile/details'}>
                    {t('links.profile')}
                  </Dropdown.Link>
                  <Dropdown.ExternalLink
                    href={t('external_links.help')}
                    target="_blank"
                  >
                    {t('links.help')}
                  </Dropdown.ExternalLink>
                  <Dropdown.Button
                    onClick={() => {
                      localStorage.removeItem('token');
                      navigate('/');
                    }}
                  >
                    {t('links.logout')}
                  </Dropdown.Button>
                </Dropdown.Actions>
              </Dropdown.Content>
            </Dropdown>
          </div>
        )}
      </header>
      {extraInfo && (
        <div className={`mx-auto w-full max-w-2xl p-6`}>
          <div className="mb-4 flex">
            <h3>
              {extraInfo?.name || extraInfo?.assetName || t('extra_info.title')}
            </h3>
          </div>
          {extraInfo?.lessonId ? (
            <LessonExtraInfoView activeExtraInfo={extraInfo} />
          ) : (
            <div className="flex h-full w-full items-start py-3">
              {extraInfo?.assetType == 'image' ? (
                <img
                  src={extraInfo?.assetPath}
                  alt={extraInfo?.assetName}
                  className="mx-auto max-h-[100vh] w-full object-contain"
                />
              ) : (
                <div id={`${newUid(20)}_embed`} className="mx-auto">
                  <iframe
                    id={`${newUid(20)}_placeholder`}
                    width="640"
                    height="360"
                    className=""
                    src={extraInfo?.assetPath}
                  ></iframe>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PreviewExtraInfo;

import { axios } from '@/axios';
import { config } from '@/config';
import { newUid } from '@/util';
import { initTus } from '@/components/Froala/tus.js';

export function uploadVideo(file, onProgress, done, title) {
  return new Promise((resolve, reject) => {
    initTus()
      .catch((e) => console.error(e))
      .then(() => {
        axios
          .post(config.backend + '/vimeo', {
            path: newUid(20) + encodeURIComponent(file.name),
            size: file.size,
            title: title,
          })
          .then((response) => {
            let data = response.data;
            let tus = window.tus;
            // let headers = {
            //   'Tus-Resumable': '1.0.0',
            //   'Upload-Offset': 0,
            //   'Content-Type': 'application/offset+octet-stream',
            //   'Accept': 'application/vnd.vimeo.*+json;version=3.4'
            // }
            // Create a new tus upload
            resolve(
              'https://player.vimeo.com' +
                data.uri.replace(/\/videos\//, '/video/')
            );
            try {
              let upload = new tus.Upload(file, {
                uploadUrl: data.upload.upload_link,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                  filename: file.name,
                  filetype: file.type,
                },
                onError: function (error) {
                  console.error('Failed because: ' + error);
                },
                onProgress: function (bytesUploaded, bytesTotal) {
                  var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(
                    2
                  );
                  onProgress &&
                    onProgress(bytesUploaded / bytesTotal, percentage);
                },
                onSuccess: function () {
                  done && done();
                },
              });
              upload.start();
            } catch (e) {
              console.error('error initialising tus', e);
            }
          })
          .catch(reject);
      })
      .catch(reject);

    /*
    response = await axios.patch(data.upload.upload_link, arrayBuffer(), {headers: headers});
    */
  });
}

import { useTranslation } from 'react-i18next';
import { Button, Modal, Label, Multiselect } from '@teo/components';
import { useState, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { parseFilter } from '@/util';

function CompetencyProfilesModal({
  setIsOpenCompetency,
  setSelectCompetency,
  selectCompetency,
}: any) {
  const { t } = useTranslation();
  const [competencyProfiles, setCompetencyProfiles] = useState<any>([]);
  const [filterCompetency, setFilterCompetency] = useState('');
  const [savePrevState, setSavePrevState] = useState([]);

  const allCompetency: any = useGet(`/professions`, {
    count: 10,
    sort: 'name',
    q: parseFilter(filterCompetency),
  });

  useEffect(() => {
    selectCompetency?.length > 0 && setSavePrevState(selectCompetency);
  }, []);

  useEffect(() => {
    if (allCompetency?.data) {
      const data = (allCompetency?.data?.data as any[]).map((profession) => ({
        key: profession?.id,
        label: profession?.name,
      }));
      setCompetencyProfiles(data);
    }
  }, [allCompetency?.data]);

  const handleSave = () => {
    setIsOpenCompetency(false);
  };

  const closeModal = () => {
    savePrevState?.length < 1
      ? setSelectCompetency([])
      : setSelectCompetency(savePrevState);
    setIsOpenCompetency(false);
  };

  const handleProfiles = (value: any) => {
    setFilterCompetency('');
    const prevState = selectCompetency?.find(
      (el: any) => el?.key === value[value.length - 1]?.key
    );
    prevState
      ? setSelectCompetency((prev: any) =>
          prev?.filter((item: any) => item?.key !== prevState?.key)
        )
      : setSelectCompetency(value);
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[101] m-auto my-8 max-w-[800px] rounded-xl bg-white/75 px-3">
        <div className="w-full rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            <h2 className="text-sm font-semibold">
              {t('pages.create_publication.competency_modal_heading')}
            </h2>
          </Modal.Header>

          <Modal.Body>
            <div className="-mx-6 px-6">
              <h1 className="text-base font-semibold">
                {t('pages.create_publication.competency_modal_title')}
              </h1>
              <p className="mt-2 text-sm font-normal text-[#7d7d81]">
                {t('pages.create_publication.competency_modal_description')}
              </p>

              <div className="m-auto flex w-full flex-col max-md:h-full">
                <div className="mb-1 space-y-2 md:space-y-4">
                  <div data-testid="competency">
                    <Label htmlFor="competencyProfiles">
                      <p className="mt-6 text-sm font-semibold">
                        {t('pages.create_publication.competency_modal_heading')}
                      </p>
                    </Label>

                    <Multiselect
                      removeButtonLabel="clear"
                      toggleSelectionLabel="toggle"
                      placeholder={t(
                        'pages.create_edit_course.competency_profiles_placeholder'
                      )}
                      options={competencyProfiles}
                      onChange={(value: any) => handleProfiles(value)}
                      onRemoved={(value) => {
                        setFilterCompetency('');
                        setSelectCompetency(value);
                      }}
                      value={selectCompetency}
                      query={filterCompetency}
                      setQuery={(query) => setFilterCompetency(query)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="flex w-full">
              {savePrevState?.length < 1 && (
                <Button variant="ghost" onClick={() => closeModal()}>
                  {t('button.cancel')}
                </Button>
              )}
              <Button onClick={() => handleSave()} className="ml-auto">
                {t('button.finished')}
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </div>
    </div>
  );
}

export default CompetencyProfilesModal;

import { useTranslation } from 'react-i18next';
import { Modal } from '@teo/components';
import PreviewMail from '@/pages/Protected/FollowUp/AddCourse/PreviewMail/PreviewMail';

function WelcomeMessageModal({
  isOpenWelcomeMessageModal,
  closeModal,
  courseId,
  template,
}: any) {
  const { t } = useTranslation();

  return (
    <div className="bg-white" data-testid="welcomeMessageModal">
      <Modal isOpen={isOpenWelcomeMessageModal}>
        <Modal.Header onClose={closeModal}></Modal.Header>
        <Modal.Body>
          <PreviewMail courseId={courseId} template={template} />
        </Modal.Body>
        <Modal.Footer
          cancelButtonLabel={t('pages.manage_teams.cancel_button')}
          onCancel={closeModal}
        ></Modal.Footer>
      </Modal>
    </div>
  );
}

export default WelcomeMessageModal;

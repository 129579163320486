import { axios } from '@/axios';
import { config } from '@/config';

export async function handleGeneration(
  setIsLoadingAI,
  userLang,
  userId,
  prompt,
  setPrompt,
  setCompletion,
  existingObject,
  setPrevResult
) {
  setIsLoadingAI(true);

  const jsonString = JSON.stringify(existingObject);
  const compactAntwoorden = jsonString;

  let context = document.querySelector('.fr-view');
  const children = context.children;
  let extractedText = '';
  for (const child of children) {
    if (!child.classList.contains('widget-placeholder')) {
      extractedText += child.textContent.trim() + ' ';
    }
  }
  let query = '';
  if (extractedText.length > 2000) {
    query += extractedText.slice(2500);
  } else {
    query += extractedText;
  }

  let systemPromt;
  switch (userLang) {
    case 'nl':
      systemPromt = `Je bent een behulpzame assistent die is ontworpen om JSON uit te voeren.
      U krijgt een hoofdtaak die tussen dubbele aanhalingstekens wordt gemarkeerd. Dit wordt uw hoofdtaak en een hulptekst die tussen drievoudige aanhalingstekens wordt gemarkeerd - ondersteunende tekst.
      U moet JSON genereren voor de hoofdtaak.
      JSON moet dit formaat hebben: {
        score: 1,
        vraag: question,
        type: 'OpenVraag',
      }
      Waar is de 'vraag' is een vraag(formaat string maar leesbare tekst).
      Je krijgt ook een bestaand JSON-object: ${compactAntwoorden}.
      van de hoofdtaak die u wordt gegeven, moet u begrijpen wat er moet gebeuren:
      - of u het reeds bestaande JSON-object (${compactAntwoorden}) wilt wijzigen, dat wil zeggen: uit de hoofdtaak moet duidelijk zijn wat u moet doen, en na de hoofdtaak wijzigt u het bestaande object (${compactAntwoorden}) of de elementen die erin zitten, objecten, d.w.z. vragen ('vraag'), ze kunnen je bijvoorbeeld vertellen de vraag moeilijker te maken ('vraag') of de vraag gemakkelijker te maken ('vraag'), of de vraag opnieuw te formuleren zonder het veranderen van de context, of om de vraag te vertalen of iets soortgelijks met de vraag te doen. U hoeft alleen het object (${compactAntwoorden}) te wijzigen in de vraag ('vraag' ), wat een string maar leesbare tekst moet zijn, wat betekent dat u wordt verteld wat u moet doen en dat u de vraag ('vraag') al moet wijzigen '). Wanneer u een bestaand JSON-object wijzigt, moet de door de gebruiker aangeleverde helptekst tussen drie aanhalingstekens worden genegeerd en nergens voor worden gebruikt, tenzij de helptekst iets gemeen heeft met, of hetzelfde onderwerp heeft als, de hoofdvraag of vraag ('vraag'). ) in een bestaand object (${compactAntwoorden}) kan het worden gebruikt als helptekst om de context van de informatie te begrijpen, wat soms kan worden gebruikt om de vragen te wijzigen.
      - of u een nieuw JSON-object moet maken - u moet alleen een nieuw object maken als u zich realiseert dat het niet nodig is om het bestaande JSON-object te wijzigen, d.w.z. als er geen instructies in de hoofdtaak staan over wat u met de vraag moet doen (' vraag') d.w.z. bijwerken, verwijderen, moeilijkheidsgraad, vertaling of andere acties of iets dergelijks wijzigen, dan maakt u een nieuw JSON-object en voert u dergelijke acties alleen uit wanneer u een nieuw JSON-object maakt en niet wijzigt: dat wil zeggen dat een vraag moet zijn aangemaakt ("vraag" is een opmaaktekenreeks, maar leesbare tekst) die moet worden gegenereerd op basis van de hoofdtaak die de gebruiker u heeft gegeven, met behulp van de meegeleverde ondersteunende tekst, die kan worden gebruikt als basis voor het maken van de vraag, maar als de ondersteunende tekst op geen enkele manier gerelateerd is aan de hoofdtaak, negeer dan de ondersteunende tekst.`;
      break;
    case 'fr':
      systemPromt = `Vous êtes un assistant utile conçu pour générer du JSON.
      Vous recevrez une tâche principale qui sera mise en évidence entre guillemets doubles, ce sera votre tâche principale et un texte d'aide qui sera mis en évidence entre guillemets triples - texte de support.
      Vous devez générer du JSON pour la tâche principale.
      JSON doit avoir ce format:{
        score: 1,
        vraag: question,
        type: 'OpenVraag',
      }
      Où est le 'vraag' est une question (chaîne de format mais texte lisible).
      Vous recevez également un objet JSON existant: ${compactAntwoorden}.
      à partir de la tâche principale qui vous sera confiée, vous devez comprendre ce qui doit être fait :
      - s'il faut modifier l'objet JSON déjà existant (${compactAntwoorden}), c'est-à-dire que la tâche principale doit indiquer clairement ce que vous devez faire, et après la tâche principale, vous modifiez l'objet existant (${compactAntwoorden}) ou le les éléments qui y sont des objets, c'est-à-dire des questions ('vraag'), par exemple ils peuvent vous dire de rendre la question plus difficile ('vraag') ou de rendre la question plus facile ('vraag'), ou de reformuler la question sans changer le contexte, ou traduire la question ou faire quelque chose de similaire avec la question. Vous n'aurez qu'à remplacer l'objet (${compactAntwoorden}) par la question ('vraag'), qui doit être une chaîne mais un texte lisible, ce qui signifie qu'on vous dira quoi faire et vous devriez déjà changer la question ('vraag'). Lorsque vous modifiez un objet JSON existant, le texte d'aide fourni par l'utilisateur entre guillemets triples doit être ignoré et ne doit être utilisé pour rien, sauf si le texte d'aide a quelque chose en commun avec, ou a le même sujet, la question ou la question principale ('vraag') dans un objet existant (${compactAntwoorden}), il peut être utilisé comme texte d'aide pour comprendre le contexte de l'information, qui peut parfois être utilisé pour modifier les questions.
      - s'il faut créer un nouvel objet JSON - vous ne devez créer un nouvel objet que lorsque vous réalisez qu'il n'est pas nécessaire de modifier l'objet JSON existant, c'est-à-dire s'il n'y a pas d'instructions dans la tâche principale sur ce qu'il faut faire avec la question ('vraag'), c'est-à-dire mettre à jour, supprimer, changer le niveau de difficulté, la traduction ou d'autres actions ou quelque chose comme ça, alors vous créez un nouvel objet JSON et n'effectuez de telles actions que lorsque vous créez un nouvel objet JSON et ne le modifiez pas: c'est-à-dire qu'une question doit être créé ("vraag" est une chaîne de format, mais un texte lisible) qui doit être généré à partir de la tâche principale qui vous est confiée par l'utilisateur, en utilisant le texte de support fourni, qui peut servir de base pour créer la question, mais si le le texte de support n'est en aucun cas lié à la tâche principale, alors ignorez le texte de support.`;
      break;
    default:
      systemPromt = `You are a helpful assistant designed to output JSON. 
          You will be given a main task which will be highlighted in double quotes this will be your main task and a helper text which will be highlighted in triple quotes - supporting text.
          You need to generate JSON for the main task.
          JSON should be of this format: {
            score: 1,
            vraag: question,
            type: 'OpenVraag',
          }
          Where is the 'vraag is a question(format string but readable text).
          You are also given an existing JSON object: ${compactAntwoorden}.
          from the main task that will be given to you, you need to understand what needs to be done:
          - whether to change the already existing JSON object (${compactAntwoorden}) that is, it should be clear from the main task what you need to do, and following the main task you change the existing object (${compactAntwoorden}) or the elements that are in it objects, i.e. questions ('vraag'), for example they can tell you to make the question more difficult ('vraag') or to make the question easier ('vraag'), or to rephrase the question without changing the context, or to translate the question or do something similar with the question. You will only need to change the object (${compactAntwoorden}) to the question ('vraag' ), which should be a string but readable text, meaning you will be told what to do and you should already change the question ('vraag'). When you modify an existing JSON object, user-supplied help text in triple quotes should be ignored and not used for anything unless the help text has something in common with, or has the same topic as, the main question or question ('vraag') in to an existing object (${compactAntwoorden}) it can be used as a help text to understand the context of the information, which can sometimes be used to change the questions.
          - whether to create a new JSON object - you should create a new object only when you realize that there is no need to change the existing JSON object, i.e. if there are no instructions in the main task about what to do with the question ('vraag') i.e. update, delete, change difficulty level, translation or other actions or something like that, then you create a new JSON object and only perform such actions when you create a new JSON object and not modify it: ie a question must be created ("vraag" is a format string, but readable text) that must be generated from the main task given to you by the user, using the supporting text provided, which can be used as the basis for creating the question, but if the supporting text is not related to main task in any way, then ignore the supporting text.
          `;
      break;
  }

  try {
    const dataOpenAI = await axios.post(`${config.backend}/openai`, {
      response_format: { type: 'json_object' },
      messages: [
        {
          role: 'system',
          content: systemPromt,
        },
        {
          role: 'user',
          content: `main task:""${prompt}""
            helper text:"""${query}"""`,
        },
      ],
      model: 'gpt-4-1106-preview',
      temperature: 0.8,
      max_tokens: 3000,
      user: `${userId}`,
    });

    const fullAnswer = dataOpenAI?.data?.choices[0]?.message?.content || '';
    const jsonObject = JSON.parse(fullAnswer);

    setPrompt('');

    if (jsonObject) {
      setCompletion(jsonObject);
      setPrevResult({
        systemContent: systemPromt,
        userContent: `main task:""${prompt}""
        helper text:"""${query}"""`,
      });
    }
  } catch (e) {
    setIsLoadingAI(false);
    alert('Error: ', e);
  }
}

export async function handleAgainGeneration(
  setIsLoadingAI,
  userId,
  setCompletion,
  prevResult
) {
  setIsLoadingAI(true);
  try {
    const dataOpenAI = await axios.post(`${config.backend}/openai`, {
      response_format: { type: 'json_object' },
      messages: [
        {
          role: 'system',
          content: prevResult?.systemContent,
        },
        {
          role: 'user',
          content: prevResult?.userContent,
        },
      ],
      model: 'gpt-4-1106-preview',
      temperature: 1,
      max_tokens: 3200,
      user: `${userId}`,
    });

    const fullAnswer = dataOpenAI?.data?.choices[0]?.message?.content || '';
    const jsonObject = JSON.parse(fullAnswer);

    if (jsonObject) {
      setCompletion(jsonObject);
    }
  } catch (e) {
    setIsLoadingAI(false);
    alert('Error: ', e);
  }
}

export async function handleMoreDifficult(
  setIsLoadingAI,
  userId,
  userLang,
  setCompletion,
  existingObject,
  setPrevResult
) {
  setIsLoadingAI(true);

  let context = document.querySelector('.fr-view');
  const children = context.children;
  let extractedText = '';
  for (const child of children) {
    if (!child.classList.contains('widget-placeholder')) {
      extractedText += child.textContent.trim() + ' ';
    }
  }
  let query = '';
  if (extractedText.length > 2000) {
    query += extractedText.slice(2500);
  } else {
    query += extractedText;
  }

  let systemPromt;
  switch (userLang) {
    case 'nl':
      systemPromt = `Je bent een behulpzame assistent die is ontworpen om JSON uit te voeren.
      U krijgt een vraag die tussen dubbele aanhalingstekens wordt gemarkeerd, deze vraag zal moeten worden getransformeerd, d.w.z. veel moeilijker gemaakt, en er zal ook een hulptekst worden verstrekt die tussen drievoudige aanhalingstekens wordt gemarkeerd.
      U moet JSON genereren.
      JSON moet dit formaat hebben: {
        score: ${existingObject?.score ? existingObject?.score : 1},
        vraag: question,
        type: 'OpenVraag',
          }
          Waar 'vraag' een vraag is (opmaakreeks, maar leesbare tekst) die u moet genereren door de vraag te nemen die u door de gebruiker wordt gegeven en die tussen dubbele aanhalingstekens wordt gemarkeerd, d.w.z. maak het veel ingewikkelder maar zonder de essentie van deze vraag veranderen, maar deze veel ingewikkelder, slimmer en mogelijk groter maken, ook de helpertekst die tussen drie aanhalingstekens wordt gemarkeerd, kan worden gebruikt om een nieuwe moeilijke vraag te genereren, maar als de helptekst niet over hetzelfde onderwerp gaat of niet gerelateerd is aan de vraag die u moet verbeteren, dan moet de helptekst worden genegeerd.`;
      break;
    case 'fr':
      systemPromt = `Vous êtes un assistant utile conçu pour générer du JSON.
      Vous recevrez une question qui sera mise en évidence entre guillemets doubles, cette question devra être transformée, c'est-à-dire rendue beaucoup plus difficile, et un texte d'aide sera également fourni qui sera mis en évidence entre guillemets triples.
      Vous devez générer du JSON.
      JSON doit avoir ce format:{
        score: ${existingObject?.score ? existingObject?.score : 1},
        vraag: question,
        type: 'OpenVraag',
          }
          Où 'vraag' est une question (chaîne de format, mais texte lisible) que vous devez générer en prenant la question qui vous sera donnée par l'utilisateur et qui sera mise en évidence entre guillemets doubles, c'est-à-dire rendre les choses beaucoup plus compliquées mais sans changer l'essence de cette question mais la rendre beaucoup plus compliquée, plus intelligente et éventuellement plus grande, à condition également qu'un texte d'aide qui sera mis en évidence entre guillemets triples puisse être utilisé pour générer une nouvelle question difficile, mais si le texte d'aide n'est pas sur le même sujet ou n'est pas lié à la question que vous devez améliorer, le texte d'aide doit être ignoré.`;
      break;
    default:
      systemPromt = `You are a helpful assistant designed to output JSON.
      You will be given a question that will be highlighted in double quotes, this question will need to be transformed, i.e. made much more difficult, and a helper text will also be provided that will be highlighted in triple quotes.
      You need to generate JSON.    
      JSON should be of this format: {
            score: ${existingObject?.score ? existingObject?.score : 1},
            vraag: question,
            type: 'OpenVraag',
          }
          Where 'vraag' is a question (format string, but readable text) that you need to generate by taking the question that will be given to you by the user and that will be highlighted in double quotes, i.e. make it much more complicated but without changing the essence of this question but make it much more complicated, smarter and possible larger, also provided helper text that will be highlighted in triple quotes can be used to generate a new difficult question, but if the helper text is not on the same topic or is not related to the question you need to improve then the helper text should be ignored.
          `;
      break;
  }

  try {
    const dataOpenAI = await axios.post(`${config.backend}/openai`, {
      response_format: { type: 'json_object' },
      messages: [
        {
          role: 'system',
          content: systemPromt,
        },
        {
          role: 'user',
          content: `question:""${existingObject?.vraag}""
            helper text:"""${query}"""`,
        },
      ],
      model: 'gpt-4-1106-preview',
      temperature: 1,
      max_tokens: 2500,
      user: `${userId}`,
    });

    const fullAnswer = dataOpenAI?.data?.choices[0]?.message?.content || '';
    const jsonObject = JSON.parse(fullAnswer);

    if (jsonObject) {
      setCompletion(jsonObject);
      setPrevResult({
        systemContent: systemPromt,
        userContent: `question:""${existingObject?.vraag}""
        helper text:"""${query}"""`,
      });
    }
  } catch (e) {
    setIsLoadingAI(false);
    alert('Error: ', e);
  }
}

export async function handleMoreEasier(
  setIsLoadingAI,
  userId,
  userLang,
  setCompletion,
  existingObject,
  setPrevResult
) {
  setIsLoadingAI(true);

  let context = document.querySelector('.fr-view');
  const children = context.children;
  let extractedText = '';
  for (const child of children) {
    if (!child.classList.contains('widget-placeholder')) {
      extractedText += child.textContent.trim() + ' ';
    }
  }
  let query = '';
  if (extractedText.length > 2000) {
    query += extractedText.slice(2500);
  } else {
    query += extractedText;
  }

  let systemPromt;
  switch (userLang) {
    case 'nl':
      systemPromt = `Je bent een behulpzame assistent die is ontworpen om JSON uit te voeren.
      U krijgt een vraag die tussen dubbele aanhalingstekens wordt gemarkeerd, deze vraag moet worden getransformeerd, d.w.z. iets eenvoudiger worden gemaakt, en er zal ook een hulptekst worden verstrekt die tussen drievoudige aanhalingstekens wordt gemarkeerd. U moet JSON genereren.
      JSON moet dit formaat hebben: {
        score: ${existingObject?.score ? existingObject?.score : 1},
        vraag: question,
        type: 'OpenVraag',
          }
          Waarbij 'vraag' een vraag is (opmaakregel, maar leesbare tekst) die u moet genereren door de vraag te nemen die de gebruiker u zal geven en die tussen dubbele aanhalingstekens zal worden gemarkeerd, dat wil zeggen, maak het een beetje gemakkelijker , maar zonder de essentie van deze vraag te veranderen, maar het gemakkelijker, redelijker, eenvoudiger en mogelijk kleiner te maken, kan ook de meegeleverde helptekst die tussen drie aanhalingstekens wordt gemarkeerd, worden gebruikt om een nieuwe moeilijke vraag te genereren, maar als de helptekst is niet over hetzelfde onderwerp gaat of geen verband houdt met de vraag die u moet verbeteren, dan moet de hulptekst worden genegeerd.`;
      break;
    case 'fr':
      systemPromt = `Vous êtes un assistant utile conçu pour générer du JSON.
      Vous recevrez une question qui sera mise en évidence entre guillemets doubles, cette question devra être transformée, c'est-à-dire rendue un peu plus facile, et un texte d'aide sera également fourni qui sera mis en évidence entre guillemets triples. Vous devez générer du JSON.
      JSON doit avoir ce format: {
        score: ${existingObject?.score ? existingObject?.score : 1},
        vraag: question,
        type: 'OpenVraag',
          }
          Où 'vraag' est une question (ligne de format, mais texte lisible) que vous devez générer en prenant la question qui vous sera donnée par l'utilisateur et qui sera mise en évidence entre guillemets doubles, c'est-à-dire rendre les choses un peu plus faciles , mais sans changer l'essence de cette question, mais la rendre plus facile, plus raisonnable, plus simple et éventuellement plus petite, à condition également qu'un texte d'aide qui sera mis en évidence entre guillemets triples puisse être utilisé pour générer une nouvelle question difficile, mais si le texte d'aide est pas sur le même sujet ou n'a aucun rapport avec la question que vous devez améliorer, alors le texte d'aide doit être ignoré.`;
      break;
    default:
      systemPromt = `You are a helpful assistant designed to output JSON.
        You will be given a question that will be highlighted in double quotes, this question will need to be transformed, i.e. made a little easier, and a helper text will also be provided that will be highlighted in triple quotes.        You need to generate JSON.    
        JSON should be of this format: {
              score: ${existingObject?.score ? existingObject?.score : 1},
              vraag: question,
              type: 'OpenVraag',
            }
            Where 'vraag' is a question (format line, but readable text) that you need to generate by taking the question that will be given to you by the user and that will be highlighted in double quotes, that is, make it a little easier, but without changing the essence of this question, but make it easier, more reasonable, simpler and possibly smaller, also provided helper text that will be highlighted in triple quotes can be used to generate a new difficult question, but if the helper text is not on the same topic or has no relation to the question you need to improve then the helper text should be ignored.
            `;
      break;
  }

  try {
    const dataOpenAI = await axios.post(`${config.backend}/openai`, {
      response_format: { type: 'json_object' },
      messages: [
        {
          role: 'system',
          content: systemPromt,
        },
        {
          role: 'user',
          content: `question:""${existingObject?.vraag}""
              helper text:"""${query}"""`,
        },
      ],
      model: 'gpt-4-1106-preview',
      temperature: 1,
      max_tokens: 3500,
      user: `${userId}`,
    });

    const fullAnswer = dataOpenAI?.data?.choices[0]?.message?.content || '';
    const jsonObject = JSON.parse(fullAnswer);

    if (jsonObject) {
      setCompletion(jsonObject);
      setPrevResult({
        systemContent: systemPromt,
        userContent: `question:""${existingObject?.vraag}""
          helper text:"""${query}"""`,
      });
    }
  } catch (e) {
    setIsLoadingAI(false);
    alert('Error: ', e);
  }
}

import { useContext, useState, useRef, useEffect } from 'react';
import { ViewUplaodElement } from '@/components/ViewUplaodElement';
import { TargetBoxCoures } from '@/components/UploadDropzoneSection/TargetBoxCoures';

export function UploadFileBox({
  multiple = false,
  accept = '*',
  files,
  setFiles,
}: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileDrop = (drop: any) => {
    setFiles(drop.files);
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={(ev) => {
          setFiles([...(ev?.target?.files || [])]);
        }}
        accept={accept}
        style={{ display: 'none' }}
        multiple={multiple}
      />
      <div id="uploadDropzone" className="mt-3">
        {files.length > 0 ? (
          <ViewUplaodElement
            uploadFile={fileInputRef?.current?.files?.[0]}
            onDelete={() => setFiles([])}
            el={files[0]}
          />
        ) : (
          <TargetBoxCoures
            onDrop={handleFileDrop}
            multiple={multiple}
            onChange={(ev: any) => setFiles(ev.target.files)}
            fileInputRef={fileInputRef}
            state={{
              supportedFormats: [
                { label: 'All', value: 'all', key: 'all', filter: '*' },
              ],
            }}
          />
        )}
      </div>
    </>
  );
}

import { useTranslation } from 'react-i18next';
import { Button, Switch } from '@teo/components';
import { ChapterFilled, TimeFilled } from '@teo/components/icons';
import { useState, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Lerningpathorganisationdetails } from '@/models';
import UploadDropzoneSection from '@/components/UploadDropzoneSection/UploadDropzoneSection';
import { axios } from '@/axios';
import { config } from '@/config';

function OrganisationCommunicationPage() {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const [sentFollowsEmail, setSentFollowsEmail] = useState<any>();
  const [sentDoneEmail, setSentDoneEmail] = useState<any>();
  const [sentMemberInviteEmail, setSentMemberInviteEmail] = useState<any>();
  const [uploadLogo, setUploadLogo] = useState();
  const [loadedData, setLoadedData] = useState(false);

  useGet<Lerningpathorganisationdetails>(
    `/organisation/${auth?.user?.activeOrganisationId}`,
    {
      onSuccess: (result: any) => {
        setUploadLogo(result.data?.picture ? result.data?.picture : null);
        setSentFollowsEmail(result.data?.sendCourseInvite);
        setSentDoneEmail(result.data?.sendDoneEmail);
        setSentMemberInviteEmail(result.data?.sendMemberInvite);
        setTimeout(() => {
          setLoadedData(true);
        });
      },
    }
  );

  const handleEmailSettings = () => {
    axios.put(
      `${config.backend}/organisation/${auth?.user?.activeOrganisationId}`,
      {
        picture: uploadLogo ? uploadLogo : null,
        sendCourseInvite: sentFollowsEmail,
        sendDoneEmail: sentDoneEmail,
        sendMemberInvite: sentMemberInviteEmail,
      }
    );
  };

  useEffect(() => {
    if (loadedData) {
      handleEmailSettings();
    }
  }, [uploadLogo, sentFollowsEmail, sentDoneEmail, sentMemberInviteEmail]);

  if (!loadedData) return null;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-6 text-xl font-semibold text-black">
          {t('pages.organisation_communication.title')}
        </h1>
      </div>
      <div className="flex flex-col">
        <div className="rounded-lg border border-grey-02 p-6">
          <div className="divide-y divide-solid divide-grey-02">
            <div className="pb-8">
              <div className="flex max-w-xl flex-col">
                <p className="pb-2 text-base font-semibold text-black">
                  {t('pages.organisation_communication.email_settings')}
                </p>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="reportEmail">
                    <Switch
                      checked={sentFollowsEmail}
                      onChange={(isCheck) => setSentFollowsEmail(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t('pages.organisation_communication.follow_send')}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t('pages.organisation_communication.follow_send_sub')}
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="reportEmail">
                    <Switch
                      checked={sentDoneEmail}
                      onChange={(isCheck) => setSentDoneEmail(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t('pages.organisation_communication.done_send')}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t('pages.organisation_communication.done_send_sub')}
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="reportEmail">
                    <Switch
                      checked={sentMemberInviteEmail}
                      onChange={(isCheck) => setSentMemberInviteEmail(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t('pages.organisation_communication.invite_send')}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t('pages.organisation_communication.invite_send_sub')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-8">
              <div className="flex flex-col">
                <p className="pb-2 text-base font-semibold text-black">
                  {t('pages.organisation_communication.email_template')}
                </p>
                <div className="max-w-xl">
                  <p className="mb-1 mt-6 text-sm font-semibold text-black">
                    {t('pages.organisation_communication.logo')}
                  </p>
                  <UploadDropzoneSection
                    setUploadLogo={setUploadLogo}
                    uploadLogo={
                      uploadLogo ? uploadLogo : '/images/TEO_logo.svg'
                    }
                  />
                </div>
                <div className="mt-6 w-full bg-grey-01 p-4 sm:p-8 lg:p-16">
                  <div className="w-full bg-white p-6 sm:p-10">
                    {uploadLogo && (
                      <img
                        alt="logo"
                        src={uploadLogo ? uploadLogo : '/images/TEO_logo.svg'}
                        className={`mr-3 h-16 flex-[1_0_64px] rounded object-cover object-center`}
                      />
                    )}
                    <h3 className="py-6 text-2xl">
                      {t('pages.organisation_communication.template_title')}
                    </h3>
                    <p className="pb-6 text-grey-08">
                      {t('pages.organisation_communication.template_subtitle')}
                    </p>
                    <div className="my-6 flex max-w-full flex-col items-center gap-6 rounded-3xl bg-grey-01 p-4 lg:flex-row">
                      <div className="flex-[1_0_auto] xl:w-56 xl:max-w-[224px]">
                        <img
                          src={'/images/courseFallback.png'}
                          alt="courseFallback"
                          className="w-full rounded-lg object-cover"
                        />
                      </div>
                      <div>
                        <h5 className="pb-1 font-semibold text-grey-08">
                          {t(
                            'pages.organisation_communication.template_course_title'
                          )}
                        </h5>
                        <p className="text-sm text-grey-05">
                          {t('pages.organisation_communication.latest')}
                        </p>
                        <p className="max-w-[500px] py-2 text-sm text-grey-08">
                          {t(
                            'pages.organisation_communication.template_course_subtitle'
                          )}
                        </p>
                        <div className="flex items-center gap-3 group-hover:hidden">
                          <span className="align-middle text-sm text-grey-04">
                            <ChapterFilled className="inline h-5 w-5" /> 6
                          </span>
                          <span className="align-middle text-sm text-grey-04">
                            <TimeFilled className="inline h-5 w-5" />{' '}
                            {t('pages.organisation_communication.time')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Button>
                        {t('pages.organisation_communication.go_course')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganisationCommunicationPage;

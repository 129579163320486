import { clsx } from 'clsx';
import { FC, PropsWithChildren, useContext } from 'react';
import { NavLink, To } from 'react-router-dom';
import { HintsContext } from '@/components/Contexts/HintsContext';

interface MainNavLinkProps extends PropsWithChildren {
  to: To;
  hint?: string;
  activeLearn?: boolean;
}

const MainNavLink: FC<MainNavLinkProps> = (props) => {
  const { hints } = useContext(HintsContext);
  return (
    <NavLink
      className={({ isActive }) =>
        clsx(
          'relative my-2 flex h-12 items-center rounded-lg px-4 font-medium transition sm:my-0 sm:h-10 sm:px-3 lg:px-4',
          'whitespace-nowrap focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-0 focus-visible:outline-primary-02',
          isActive || props?.activeLearn
            ? ' bg-primary-02 '
            : ' hover:bg-grey-01 ',
          props?.hint == hints?.id && 'active-hint-parent active-hint-main-menu'
        )
      }
      to={props.to}
      onClick={() => {
        sessionStorage.removeItem('offsetLoc');
        sessionStorage.removeItem('prevPathname');
      }}
    >
      {props.children}
    </NavLink>
  );
};

export { MainNavLink };

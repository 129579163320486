import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

const Portal = (props: any) => {
  const { root, rootNode, empty, children, ...other } = props;
  const [innerHtmlEmptied, setInnerHtmlEmptied] = React.useState(false);
  const myRoot = props.rootNode || document.getElementsByTagName(root)[0];

  React.useEffect(() => {
    if (empty) myRoot.innerHTML = '';
    setInnerHtmlEmptied(true);
  }, []);

  if (!setInnerHtmlEmptied) return null;

  return ReactDOM.createPortal(
    <Fragment {...other}>{children}</Fragment>,
    myRoot
  );
};

export { Portal };

import { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@teo/components';
import {
  CrossFilled,
  IdeaFilled,
  ChevronLeft,
  ChevronRight,
} from '@teo/components/icons';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { AuthContext } from '@/components/Contexts/AuthContext';
// import { hintsData } from './hintsData';
import './HintsDotAnimation.css';
import { useMobile } from '@/util';
import { axios } from '@/axios';
import { config } from '@/config';

const Hint = ({ id, leftDots, title, order, overlaySecond, ...other }: any) => {
  const { t } = useTranslation();
  const { hints, setHints } = useContext(HintsContext);
  const { auth } = useContext(AuthContext);
  const [showHint, setShowHint] = useState(false);
  const [showHintDots, setShowHintDots] = useState<any>(null);
  const [orderHint, setOrderHint] = useState<any>(null);
  const [hintFlags, setHintFlags] = useState<any>(null);
  const ref = useRef(null);
  const refHint = useRef(null);

  const isMobile = useMobile();

  const authUser: any = auth?.user;

  useEffect(() => {
    if (hints?.id === id) {
      setShowHint(true);
    } else {
      setShowHint(false);
    }
    hints?.pageHints?.map((el: any, i: any) => {
      if (el.id === hints?.id) {
        setOrderHint(i);
      }
    });
    setHintFlags(hintFlags ? hintFlags : hints?.allHints);
  }, [hints]);

  useEffect(() => {
    if (showHint && hintFlags && hintFlags[order] != '1') {
      const updatedHintFlags = hintFlags;
      updatedHintFlags[order] = '1';
      const newHintFlags = updatedHintFlags.join('');
      updateHintFlags(newHintFlags, updatedHintFlags);
    }
  }, [showHint]);

  useEffect(() => {
    if (hintFlags && !showHint) {
      setShowHintDots(hintFlags[order] == 0);
    }

    // setShowHintDots(!hintsData?.find((el) => el.id === id)?.completed);
  }, [hintFlags, order, showHint]);

  const updateHintFlags = async (newHintFlags: any, updatedHintFlags: any) => {
    const promises: any = [];
    promises.push(
      axios.put(`${config.backend}/user/${authUser?.id}`, {
        hintFlags: newHintFlags,
        id: authUser?.id,
      })
    );
    await Promise.allSettled(promises).then(() => {
      setHints((prev: any) => ({
        ...prev,
        allHints: updatedHintFlags,
      }));
    });
  };

  const handleNext = () => {
    const nextHint: any = hints?.pageHints?.find(
      (el, i) => i === orderHint + 1
    );
    setHints((prev: any) => ({
      ...prev,
      id: nextHint?.id,
    }));
  };

  const handlePrev = () => {
    const prevHint: any = hints?.pageHints?.find(
      (el, i) => i === orderHint - 1
    );
    setHints((prev: any) => ({ ...prev, id: prevHint?.id }));
  };

  const useOutsideClick = (ref: any, callback: any) => {
    const handleClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };

  useOutsideClick(ref, () => {
    handleDot(null, null);
  });

  const handleDot = (id: any, active: any) => {
    setHints((prev: any) => ({
      ...prev,
      id: id,
      active: active,
      pageHints: [],
    }));
  };

  if (isMobile) return null;

  return (
    <>
      {/* {authUser?.activeOrganisationRole > 20 ? ( */}
      <div
        id={id}
        className={`hint-info pointer-events-none  ${
          showHint && 'active-hint'
        }`}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        data-order={order}
        ref={refHint}
      >
        {(showHintDots || hints?.id === id) && (
          <div className={`absolute ${leftDots}`}>
            <span
              className="hint-dot pointer-events-auto relative z-[9] inline-block h-3 w-3 cursor-pointer rounded-full bg-tint-indigo"
              onClick={() => {
                !showHint && handleDot(id, overlaySecond ? 2 : 1);
              }}
            >
              <span className="circle-1"></span>
              <span className="circle-2"></span>
              <span className="circle-3"></span>
            </span>
          </div>
        )}
        {showHint && (
          <div
            ref={ref}
            className="pointer-events-auto absolute z-[103] flex cursor-default flex-row overflow-hidden whitespace-normal rounded-lg bg-white text-left"
            {...other}
          >
            <IconButton
              Icon={CrossFilled}
              className="absolute top-3 right-3"
              variant="ghost"
              onClick={() => {
                handleDot(null, null);
              }}
            />
            <div className=" flex w-max min-w-[374px] max-w-[420px] flex-col bg-grey-01 px-14 py-10">
              <div className="mb-3 flex flex-row items-center">
                <IdeaFilled className="mr-3 w-6 min-w-6 text-[#807EFF]" />
                <h3 className="flex text-xl text-tint-indigo-dark">{title}</h3>
                <p className="ml-auto min-w-max pl-2 text-sm font-normal text-grey-05">
                  {t('hint.number_tips', {
                    activeNumber: orderHint + 1,
                    lengthTips: hints?.pageHints?.length,
                  })}
                </p>
              </div>

              <p className=" mb-6 text-sm font-normal text-[#231F20]">
                {t(`hint.${id}`)}
              </p>

              <div className="mt-auto flex gap-1">
                <IconButton
                  className=""
                  disabled={orderHint == 0}
                  Icon={ChevronLeft}
                  variant="outline"
                  onClick={() => handlePrev()}
                />
                <IconButton
                  className=""
                  disabled={hints?.pageHints?.length === orderHint + 1}
                  Icon={ChevronRight}
                  variant="outline"
                  onClick={() => handleNext()}
                />
                {hints?.pageHints?.length !== orderHint + 1 && (
                  <Button
                    className="ml-auto"
                    variant="outline"
                    onClick={() => {
                      setHints((prev: any) => ({
                        ...prev,
                        id: null,
                        active: overlaySecond ? 2 : 1,
                      }));
                    }}
                  >
                    {t('hint.btn_skip')}
                  </Button>
                )}
              </div>
            </div>
            <div className="flex min-w-[210px] py-10">
              <img
                className="m-auto w-full max-w-[70px]"
                role="hint"
                src="/images/interface/hint/hint.png"
                alt="hint"
              />
            </div>
          </div>
        )}
      </div>
      {/* ) : (
        ''
      )} */}
    </>
  );
};
export default Hint;

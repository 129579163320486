import { Link } from 'react-router-dom';
import CookiePolicy from './Cookies';
import { useEffect } from 'react';

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="mb-8 break-words">
        <h2 className="mt-6 text-center" id="privacypolicy">
          PRIVACY POLICY
        </h2>
        <p className="mb-6 text-center">29/10/2024</p>

        <div className="m-auto w-[80%]">
          <div className="sane-lists sane-paragraphs">
            <h3 className="my-4">Version History</h3>
            <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
              <thead>
                <tr>
                  <th className="border border-gray-400 px-4 py-2">Date</th>
                  <th className="border border-gray-400 px-4 py-2">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    29/10/2024
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Updated privacy policy with new processing activities and
                    details.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    10/06/2022
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Updated privacy policy with new processing activities and
                    details.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    18/12/2018
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Initial version of the Privacy Policy by TEO.
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="my-4">Introduction and scope</h3>

            <p>
              This privacy policy (hereinafter referred to as{' '}
              <strong>“Privacy Policy”</strong>) describes the processing of
              your personal data by <strong>TEO-Training</strong>, a private
              limited company incorporated and existing under the laws of
              Belgium, with registered office at Schaarbeekstraat 42A, 9120
              Beveren, registered in the Register of Legal Entities of Ghent,
              section Dendermonde, under number 0697.679.725, and known to the
              VAT-administration under number BE 0697.679.725 (hereinafter
              referred to as <strong>“TEO”</strong> or <strong>“we”</strong>).
            </p>

            <p>
              At TEO, we value your trust in our services and are therefore
              committed to protecting your personal data with the greatest care
              as possible and in a fair, transparent, and lawful manner.
            </p>

            <p>This Privacy Policy applies to:</p>
            <ul>
              <li>
                Visitors of our website{' '}
                <a href="https://teo.training/">https://teo.training/</a>{' '}
                (hereinafter referred to as the <strong>“Website”</strong>);
              </li>
              <li>
                Users of our TEO software platform (hereinafter referred to as
                the <strong>“Platform”</strong>); and
              </li>
              <li>
                Any (commercial) relationships between TEO and its customers,
                prospects, and (potential) business partners.
              </li>
            </ul>

            <p>
              TEO wishes to emphasize that it consistently strives to act in
              accordance with:
            </p>
            <ul>
              <li>
                the Belgian Act of July 30, 2018 on the protection of
                individuals with regard to the processing of personal data;
              </li>
              <li>
                the Regulation (EU) 2016/679 of April 27, 2016 on the protection
                of natural persons with regard to the processing of personal
                data and on the free movement of such data, and repealing
                Directive 95/46/EC; and
              </li>
              <li>
                the Directive (EU) 2002/58/EC of the European Parliament and of
                the Council of 12 July 2002 concerning the processing of
                personal data and the protection of privacy in the electronic
                communications sector (Directive on privacy and electronic
                communications).
              </li>
            </ul>

            <p>
              Visiting the Website, filling out the contact form on the Website,
              requesting a quotation or demo of the Platform, visiting and using
              the Platform, subscribing to a newsletter, participating in an
              event or webinar, purchasing any of TEO’s products or services, or
              communicating with TEO implies your consent to the Privacy Policy
              (through disclosure of your personal data or an opt-in) and thus
              to how TEO collects, uses, and processes your personal data (as
              defined hereafter). We encourage you to read this Privacy Policy
              in conjunction with its version history and TEO’s{' '}
              <Link
                onClick={(ev) => {
                  const element = document.getElementById('cookiepolicy');
                  element?.scrollIntoView();
                  ev.preventDefault();
                }}
                to="../cookies"
              >
                <strong>cookie policy</strong>
              </Link>
              .
            </p>

            <h3 className="my-4">General</h3>
            <p>
              Personal data can be defined as any information that allows a
              natural person to be identified, directly or indirectly. Providing
              certain personal data is sometimes a prerequisite to benefit from
              our services (for example, to gain access to certain parts of the
              Website, to use our Platform, to buy our products, etc.). In this
              Privacy Policy, you can find an overview of the types of personal
              data we may request or receive from you, the purposes for which we
              will process this personal data, the legal basis of this
              processing activity, and the retention period of the personal
              data.
            </p>

            <p>
              If a processing activity is based on TEO’s legitimate interest as
              shown in the tables below, TEO will always:
            </p>
            <ul>
              <li>
                assess the necessity of the processing activity in relation to
                the purpose of the processing (proportionality test); and
              </li>
              <li>
                assess whether you, the data subject, could reasonably expect
                the specific processing operation to take place, so we can
                estimate the possible effects of the processing operation on
                your fundamental rights and freedoms.
              </li>
            </ul>

            <p>
              TEO always strives to minimize the impact on your fundamental
              rights and freedoms as much as possible. We warrant that we will
              process your personal data based on a legitimate interest only if
              we are absolutely sure that a balance can be found between your
              rights and freedoms and our interests. If a balance cannot be
              guaranteed, TEO:
            </p>
            <ul>
              <li>
                will no longer process your personal data in that specific
                situation (for that specific purpose); or
              </li>
              <li>
                will rely on another legal ground to process your personal data
                (e.g. consent).
              </li>
            </ul>

            <h3 className="my-4">Processing Activities by TEO</h3>

            <h4 className="my-4">Concerning the Use of the Website:</h4>
            <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
              <thead>
                <tr>
                  <th className="border border-gray-400 px-4 py-2">
                    Collection Method
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Personal Data
                  </th>
                  <th className="border border-gray-400 px-4 py-2">Purposes</th>
                  <th className="border border-gray-400 px-4 py-2">
                    Legal Ground
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Retention Period
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Filling out the contact form
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact information, such as first name, surname, email,
                    phone, and other data provided in the “free field”
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Responding to questions and requests
                  </td>
                  <td className="border border-gray-400 px-4 py-2">Consent</td>
                  <td className="border border-gray-400 px-4 py-2">
                    3 years after receiving the personal data
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Subscribing to the newsletter
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact information, such as first name, surname, and email
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Informing about products, services, operations, or events
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Consent (withdrawn via opt-out)
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Until unsubscribed
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Cookies and tracking methods
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    IP address, session information, and technical data
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    See{' '}
                    <Link
                      onClick={(ev) => {
                        const element = document.getElementById('cookiepolicy');
                        element?.scrollIntoView();
                        ev.preventDefault();
                      }}
                      to="../cookies"
                    >
                      <strong>cookie policy</strong>
                    </Link>
                  </td>
                  <td className="border border-gray-400 px-4 py-2">—</td>
                  <td className="border border-gray-400 px-4 py-2">—</td>
                </tr>
              </tbody>
            </table>

            <h4 className="my-4">
              Processing Activities Concerning the Access and Use of the
              Platform:
            </h4>

            <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
              <thead>
                <tr>
                  <th className="border border-gray-400 px-4 py-2">
                    Collection Method
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Personal Data
                  </th>
                  <th className="border border-gray-400 px-4 py-2">Purposes</th>
                  <th className="border border-gray-400 px-4 py-2">
                    Legal Ground
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Retention Period
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    User account registration and completing your profile in the
                    Platform
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details, such as first name, surname, phone number,
                    and email address. Professional information, such as
                    employer, company name, experience, expertise, and
                    education.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Registration and verification of the access to and use of
                    the Platform
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Performance of a contract
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    For employees or jobseekers using the Platform via an
                    employment agency, data is deleted or anonymized upon
                    termination of the agreement. For independent users, data is
                    deleted or anonymized five years after the last Platform
                    usage.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Training progress in the Platform
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details, such as first name, surname, phone number,
                    and email address. TEO-trainings information, such as
                    gender, age, languages, driver&apos;s license, diplomas, and
                    competencies.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Following and monitoring the progress of TEO-trainings
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Performance of a contract
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Same as user account registration: deleted or anonymized
                    upon agreement termination or five years after last usage
                    for independent users.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Personal data entered into or submitted to the Platform
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Professional information, such as employer, company name,
                    experience, expertise, and education.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Improving the functioning of our Platform (e.g., offering
                    jobs and training programs)
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Legitimate interest
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Same as user account registration: deleted or anonymized
                    upon agreement termination or five years after last usage
                    for independent users.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Personal data entered into or submitted to the Platform
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details, such as first name, surname, phone number,
                    and email address. TEO-trainings information (gender, age,
                    languages, driver&apos;s license, diplomas, competencies).
                    Professional information, such as employer, company name,
                    experience, expertise, and education.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Matching profiles to vacancies or training programs (of
                    third parties)
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Performance of a contract or legitimate interest
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Personal data will be deleted or anonymized five years after
                    the last Platform usage.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Support requests
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details, such as first name, surname, phone number,
                    and email address.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Reacting to support requests from users of the Platform
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Performance of a contract
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    For the duration of the contract and one year after its
                    termination.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Cookies and other tracking methods in the Platform
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    IP address, session information, and other technical data
                    gathered by cookies and tracking methods.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Statistical purposes and improving your user experience
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Consent or legitimate interest
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Same as user account registration: deleted or anonymized
                    upon agreement termination or five years after last usage
                    for independent users.
                  </td>
                </tr>
              </tbody>
            </table>

            <h4 className="my-4">Other Processing Activities by TEO:</h4>
            <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
              <thead>
                <tr>
                  <th className="border border-gray-400 px-4 py-2">
                    Collection Method
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Personal Data
                  </th>
                  <th className="border border-gray-400 px-4 py-2">Purposes</th>
                  <th className="border border-gray-400 px-4 py-2">
                    Legal Ground
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Retention Period
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Communication with (possible) clients, commercial/business
                    partners, and service providers/freelancers
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details and professional information, such as first
                    name, surname, email, phone number, job title, company name
                    and address, invoicing address, and any other data
                    voluntarily provided.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Correspondence via email, phone, social media, in writing,
                    or other means of communication, whether or not to respond
                    to questions or requests.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Performance of the agreement with TEO or responding to a
                    question/request prior to the conclusion of an agreement.
                    Legitimate interest for identifying and reaching out to
                    potential customers, business partners, and service
                    providers.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    For the duration of the business relation and one year after
                    termination. If no relationship is established, data
                    retention is limited to one year after receiving the
                    personal data.
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Invoicing and collecting
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details and professional information, such as first
                    name, surname, email, phone number, job title, company name,
                    VAT number, invoicing address, payment status, and financial
                    details.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Invoicing and collecting amounts owed to TEO
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Performance of a contract
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    7 years (minimum statutory retention period)
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Supply of TEO-hardware
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details and professional information, such as first
                    name, surname, job title, email address, and place of
                    delivery.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Delivery of hardware sold or rented by TEO to its customers
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Performance of a contract
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    For the duration of the contract and one year after
                    termination
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Training certificates
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details, such as first name, surname, job title,
                    email address. Professional information, such as employer,
                    company name, experience, expertise, and education.
                    Attendance certificates.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Monitoring and awarding of training certificates for courses
                  </td>
                  <td className="border border-gray-400 px-4 py-2">Consent</td>
                  <td className="border border-gray-400 px-4 py-2">
                    For the duration of the validity of the certificate
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">Grants</td>
                  <td className="border border-gray-400 px-4 py-2">
                    Contact details, such as first name, surname, job title,
                    email address. Professional information, such as employer,
                    company name, experience, expertise, and education.
                    Payslips.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Preparing, submitting, and monitoring grant applications as
                    a promotor
                  </td>
                  <td className="border border-gray-400 px-4 py-2">Consent</td>
                  <td className="border border-gray-400 px-4 py-2">
                    For the duration of the grant application and audit process
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Recruitment
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Identification and contact details such as name, first name,
                    position, phone number, place of residence, email address,
                    education, professional information (CV), and cover letters.
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Searching, interviewing, and selecting job applicants
                  </td>
                  <td className="border border-gray-400 px-4 py-2">Consent</td>
                  <td className="border border-gray-400 px-4 py-2">
                    One year after receiving the personal data
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="my-4">
              Disclosure of Personal Data to Third Parties
            </h3>
            <p>
              TEO will not disclose your personal data to third parties unless
              necessary for the performance and optimization of our services and
              products. For this purpose, TEO may disclose your personal data to
              its affiliates, payment providers, software providers, cloud and
              hosting partners, data centers, transport partners, external
              consultants, financial institutes, and other service providers. If
              TEO has to disclose your personal data to third parties for this
              purpose, the third party concerned will be required to use your
              personal data in accordance with the provisions of this Privacy
              Policy. Furthermore, TEO may disclose your personal data:
            </p>
            <ul>
              <li>
                to the competent authorities (i) if TEO is obliged to do so by
                law or in the context of legal proceedings and (ii) to safeguard
                and defend its rights;
              </li>
              <li>
                if TEO or the majority of its assets are acquired by a third
                party, in which case your personal data collected by TEO will be
                one of the transferred assets.
              </li>
            </ul>
            <p>
              In all other situations, TEO will not sell, rent out, or transfer
              your personal data to third parties, unless it has (i) obtained
              your consent for this purpose and (ii) concluded a data processing
              or data transfer agreement with the third party concerned that
              includes the necessary warranties on confidentiality and
              privacy-compliant treatment of your personal data.
            </p>

            <h3 className="my-4">Transfer of Personal Data Outside the EEA</h3>
            <p>
              Any transfer of personal data outside the European Economic Area
              (EEA) to a recipient residing or having its corporate domicile in
              a country not covered by an adequacy decision issued by the
              European Commission will be governed by the provisions of a data
              transfer agreement, which will contain the standard contractual
              clauses as stipulated in the ‘European Commission Decision of June
              4, 2021 (Decision 2021/914)’ and/or other appropriate safeguards
              which will ensure an adequate level of data protection.
            </p>

            <h3 className="my-4">Retention Period</h3>
            <p>
              Unless a longer retention period is required or justified by law
              or through complying with any other statutory obligation, TEO will
              store your personal data for the period necessary to achieve and
              fulfil the purposes of the processing activity, as described in
              this Privacy Policy under ‘2. Processing activities by TEO’.
            </p>

            <h3 className="my-4">Your Privacy Rights</h3>
            <p>
              In relation to the processing of your personal data, you can
              exercise the following rights:
            </p>

            <h3 className="my-4">Your Privacy Rights</h3>
            <ul className="list-disc pl-5">
              <li>
                <strong>Right of access to your personal data:</strong> You may
                always access and inspect your personal data that TEO has
                processed. TEO will provide a copy of these personal data on
                your request.
              </li>
              <li>
                <strong>
                  Right to rectify, supplement or update your personal data:
                </strong>{' '}
                You may always have incorrect, incomplete, inappropriate, or
                outdated personal data removed or rectified.
              </li>
              <li>
                <strong>
                  Right to erasure of your personal data (‘right to be
                  forgotten’):
                </strong>{' '}
                In this context, TEO wishes to point out that certain services
                will no longer be accessible or can no longer be provided if you
                delete or have certain necessary personal data deleted. The
                request to erase your personal data cannot always be granted due
                to contractual or legal obligations.
              </li>
              <li>
                <strong>
                  Right to restrict the processing of your personal data:
                </strong>{' '}
                You may restrict the processing of your personal data if:
                <ul className="list-disc pl-5">
                  <li>
                    you dispute the accuracy of the data, for a period that
                    allows TEO to verify the accuracy;
                  </li>
                  <li>
                    the processing is unlawful, and you oppose the erasure of
                    your personal data and request the restriction of their use
                    instead;
                  </li>
                  <li>
                    TEO no longer needs your personal data for processing
                    purposes, but still needs them to institute, exercise, or
                    substantiate legal claims;
                  </li>
                  <li>
                    you have objected to a processing operation and are waiting
                    for an answer on whether your objection is well-founded.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Right to data portability:</strong> You may obtain the
                personal data that TEO has processed in a structured, commonly
                used, and digital format so they can be saved for personal use
                or reuse, or to send them directly to another controller. This
                right applies insofar as it is technically possible for TEO to
                do this.
              </li>
              <li>
                <strong>
                  Right to object to/oppose the further processing of personal
                  data:
                </strong>
                <ul className="list-disc pl-5">
                  <li>
                    You may always object to the processing of your personal
                    data for reasons relating to your specific situation.
                  </li>
                  <li>
                    TEO will immediately cease processing your personal data,
                    unless we can put forward compelling legitimate grounds for
                    the processing that override your interests, rights, and
                    freedoms or that relate to instituting, exercising, or
                    substantiating legal claims.
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              If you wish to exercise any of your privacy rights, contact{' '}
              <a href="mailto:info@teo.training">info@teo.training</a> or send
              your request on which right you want to exercise to TEO by email
              or post to the address indicated below (see contact details under
              ‘10. Contact TEO’). If there is reasonable doubt about your
              identity, TEO may request additional information confirming your
              identity before being able to answer your question or request.
            </p>
            <p>
              Except when your requests are manifestly unfounded or excessive
              (e.g., repeated requests), you can exercise your privacy rights
              free of charge using the aforementioned form.
            </p>
            <p>
              If and insofar as permitted under the applicable law, TEO may
              process your contact data for direct marketing purposes. If you no
              longer wish to receive newsletters or information about TEO’s
              products or services, you can always unsubscribe by clicking the
              ‘unsubscribe’ button at the bottom of each email from TEO.
            </p>

            <h3 className="my-4">Security of Personal Data</h3>
            <p>
              TEO undertakes to implement appropriate and reasonable physical,
              technological, and organizational security measures to prevent (i)
              unauthorized or unlawful access to your personal data, and (ii)
              loss, misuse, or alteration of your personal data.
            </p>
            <p>
              TEO will store all personal data collected on-site and in the
              cloud with data centers in the EU.
            </p>
            <p>
              Notwithstanding TEO’s security policy and the controls and actions
              it performs in this regard, an infallible level of security cannot
              be guaranteed. As no method of transmission or transfer via the
              internet or any electronic storage method is 100% secure, TEO
              cannot guarantee absolute security in this context.
            </p>

            <h3 className="my-4">Updates to Privacy Policy</h3>
            <p>
              TEO may update this Privacy Policy by posting a new version on the
              Website. We therefore strongly recommend that you regularly
              consult the Website and the relevant page of the Privacy Policy to
              ensure that you are aware of any amendments.
            </p>
            <p>
              The most recent version of this Privacy Policy is always available
              on the Website. Any updates and corresponding change date will be
              explained and displayed in the Privacy Policy version history,
              which can be accessed using this link:{' '}
              <a href="https://teo.training/en/privacy">
                https://teo.training/en/privacy
              </a>
              .
            </p>

            <h3 className="my-4">Links to Other Websites</h3>
            <p>
              The Website may contain hyperlinks to other websites. When you
              click on one of these links, you could be directed to another
              website or internet source that might collect information about
              you intentionally or through cookies or other technologies. TEO
              has no responsibility, liability, or ability to control these
              other websites or internet sources, or their collection, use, and
              dissemination of your personal data. You should check the privacy
              policies of these other websites and internet resources to
              determine whether they comply with the applicable privacy
              legislation.
            </p>

            <h3 className="my-4">Contact TEO</h3>
            <p>
              If you have questions about the Privacy Policy or the way TEO
              collects, uses, and/or processes your personal data, please
              contact us via email:{' '}
              <a href="mailto:info@teo.training">info@teo.training</a>. If you
              prefer to contact us by post, you can do so at the following
              address:
            </p>
            <p>Schaarbeekstraat 42A, 9120 Beveren, Belgium.</p>
            <p>
              If you are dissatisfied with how TEO deals with these questions or
              comments, or if you have concerns about how TEO collects, uses, or
              processes your personal data, you have the right to lodge a
              complaint with the competent supervisory authority. This may be
              the supervisory authority of (i) your usual place of residence,
              (ii) your place of work, or (iii) the location of the alleged
              breach of the Privacy Legislation.
            </p>
            <p>Contact details of the Belgian Data Protection Authority:</p>
            <p>
              <strong>Data Protection Authority</strong>
              <br />
              Rue de la Presse/Drukpersstraat 35, 1000 Brussels, Belgium
              <br />
              Tel.: +32 (0)2/274 48 00
              <br />
              Email: <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>
            </p>
          </div>
        </div>
      </div>
      <div id="cookiepolicy">
        <CookiePolicy />
      </div>
    </>
  );
}

export default Privacy;

import { Button, Stepper, Spinner } from '@teo/components';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMobile, newUid } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PaginationSingleSelect } from '@/components/PaginationSingleSelect';
import { useGet } from '@/query/fetchHooks';
import LibraryCourseTree from './LibraryCourseTree';
import { axios } from '@/axios';
import { config } from '@/config';
import ModalRedirect from './ModalRedirect';

const MAXSTEP = 2;

function AddLibraryStepsContainer({
  node,
  chapterTree,
  chapterExtraInfo,
  lesExtraInfo,
}: any) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { organisationId } = useParams();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;

  const [step, setStep] = useState(1);
  const [selectCourse, setselectCourse] = useState<any>(null);
  const [checkAdd, setCheckAdd] = useState(true);
  const [oldTree, setOldTree] = useState<any>(null);
  const [tree, setTree] = useState<any>(null);
  const [isAdd, setIsAdd] = useState(false);

  const [nodePath, setNodePath] = useState<any>(null);

  const [loadeAdd, setLoadeAdd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newNodeInfo, setNewNodeInfo] = useState({
    ...node,
    id: newUid(20),
  });

  const handleAddChapter = async () => {
    setLoadeAdd(true);

    const newChapter = tree?.find((el: any) => el?.id === newNodeInfo?.id);
    const prevTree = new Map(
      oldTree?.map((item: any) => [item.id, item.data.yindex])
    );
    const levelTree = tree?.filter(
      (el: any) =>
        el?.parent === newChapter?.parent && el?.id !== newChapter?.id
    );
    const newYindexList = levelTree.filter((item: any) => {
      return (
        prevTree.has(item.id) && prevTree.get(item.id) !== item.data.yindex
      );
    });

    const folderExtraInfo = chapterExtraInfo?.filter(
      (el: any) => el?.rootDirectoryId === node?.id
    );

    const createDirectoryResponse = await axios.post(
      `${config.backend}/directories`,
      {
        coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
      }
    );

    const newIdFolder = createDirectoryResponse?.data?.id;
    const newPath = `${newChapter?.data?.path}`;
    setNodePath(newChapter?.data?.path);

    if (newIdFolder && newChapter) {
      await axios.put(`${config.backend}/directory/${newIdFolder}`, {
        coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
        id: newIdFolder,
        name: newChapter?.data?.name,
        path: newPath,
        yindex: newChapter?.data?.yindex,
      });

      for (const el of folderExtraInfo) {
        if (el?.lessonId || el?.assetId) {
          if (el?.lessonId) {
            const linkLes = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              lessonId: el?.lessonId,
              path: `/${el?.lessonId}`,
              yindex: 0,
            });

            await axios.put(`${config.backend}/use/${linkLes?.data?.id}`, {
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              lessonId: el?.lessonId,
              path: `${newPath}/${linkLes?.data?.id}`,
              yindex: el?.data?.yindex,
              rootDirectoryId: newIdFolder,
            });
          } else {
            const newImg = await axios.post(`${config.backend}/assets`, {
              assetName: el?.assetName,
              canEdit: true,
              assetType: el?.assetType,
              assetPath: el?.assetPath,
            });
            const linkImg = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              assetId: newImg?.data?.id,
            });
            await axios.put(`${config.backend}/use/${linkImg?.data?.id}`, {
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              assetId: newImg?.data?.id,
              path: `${newPath}/${newImg?.data?.id}`,
              yindex: el?.data?.yindex,
              rootDirectoryId: newIdFolder,
            });
          }
        }
      }

      for (const el of newYindexList) {
        if (el?.data?.lessonId || el?.data?.assetId) {
          await axios.put(`${config.backend}/use/${el.data.usesId}`, {
            yindex: el?.data?.yindex,
          });
        } else {
          const directoryId = el.data.id;
          await axios.put(`${config.backend}/directory/${directoryId}`, {
            yindex: el?.data?.yindex,
          });
        }
      }

      setIsAdd(true);
    }
  };

  const handleAddSubChapter = async () => {
    if (chapterTree?.length > 0) {
      chapterTree.map(async (el: any, index: any) => {
        if (el?.data?.lessonId || el?.data?.assetId) {
          if (el?.data?.lessonId) {
            const folderExtraInfo = chapterExtraInfo?.filter((extreI: any) => {
              return extreI?.rootLessonId === el?.data?.lessonId;
            });

            const linkLes = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              lessonId: el?.data?.lessonId,
              path: `/${el?.data?.lessonId}`,
              yindex: 0,
            });

            axios.put(`${config.backend}/use/${linkLes?.data?.id}`, {
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              lessonId: el?.data?.lessonId,
              path: `${nodePath}${el?.data?.path?.replace(
                el?.data?.id,
                linkLes?.data?.id
              )}`,
              yindex: el?.data?.yindex,
            });

            for (const eInfo of folderExtraInfo) {
              if (eInfo?.lessonId || eInfo?.assetId) {
                if (eInfo?.lessonId) {
                  const linkExtraLes = await axios.post(
                    `${config.backend}/uses`,
                    {
                      canEdit: false,
                      coursecontentId: parseInt(
                        selectCourse?.coursecontentId,
                        10
                      ),
                      lessonId: eInfo?.lessonId,
                      path: `/${eInfo?.lessonId}`,
                      yindex: 0,
                    }
                  );

                  await axios.put(
                    `${config.backend}/use/${linkExtraLes?.data?.id}`,
                    {
                      coursecontentId: parseInt(
                        selectCourse?.coursecontentId,
                        10
                      ),
                      lessonId: eInfo?.lessonId,
                      path: `${nodePath}${el?.data?.path?.replace(
                        el?.data?.id,
                        linkLes?.data?.id
                      )}/${linkExtraLes?.data?.id}`,
                      yindex: eInfo?.data?.yindex,
                      rootLessonId: el?.data?.lessonId,
                    }
                  );
                } else {
                  const newImg = await axios.post(`${config.backend}/assets`, {
                    assetName: eInfo?.assetName,
                    canEdit: true,
                    assetType: eInfo?.assetType,
                    assetPath: eInfo?.assetPath,
                  });
                  const linkImg = await axios.post(`${config.backend}/uses`, {
                    canEdit: false,
                    coursecontentId: parseInt(
                      selectCourse?.coursecontentId,
                      10
                    ),
                    assetId: newImg?.data?.id,
                  });
                  await axios.put(
                    `${config.backend}/use/${linkImg?.data?.id}`,
                    {
                      coursecontentId: parseInt(
                        selectCourse?.coursecontentId,
                        10
                      ),
                      assetId: newImg?.data?.id,
                      path: `${nodePath}${el?.data?.path?.replace(
                        el?.data?.id,
                        linkLes?.data?.id
                      )}/${newImg?.data?.id}`,
                      yindex: eInfo?.data?.yindex,
                      rootLessonId: el?.data?.lessonId,
                    }
                  );
                }
              }
            }
          } else {
            const newImg = await axios.post(`${config.backend}/assets`, {
              assetName: el?.data?.assetName,
              canEdit: true,
              assetType: el?.data?.assetType,
              assetPath: el?.data?.assetPath,
            });
            const linkImg = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              assetId: newImg?.data?.id,
            });
            axios.put(`${config.backend}/use/${linkImg?.data?.id}`, {
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
              assetId: newImg?.data?.assetId,
              path: `${nodePath}${el?.data?.path?.replace(
                el?.data?.assetId,
                linkImg?.data?.assetId
              )}`,
              yindex: el?.data?.yindex,
            });
          }
        } else {
          const folderExtraInfo = chapterExtraInfo?.filter((extreI: any) => {
            return (
              extreI?.rootDirectoryId === el?.data?.id && !el?.data?.lessonId
            );
          });

          const newSubChapter = await axios.post(
            `${config.backend}/directories`,
            {
              coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
            }
          );

          axios.put(`${config.backend}/directory/${newSubChapter?.data?.id}`, {
            coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
            id: newSubChapter?.data?.id,
            name: el?.data?.name,
            path: `${nodePath}${el?.data?.path}`,
            yindex: el?.data?.yindex,
          });

          for (const eInfo of folderExtraInfo) {
            if (eInfo?.lessonId || eInfo?.assetId) {
              if (eInfo?.lessonId) {
                const linkLes = await axios.post(`${config.backend}/uses`, {
                  canEdit: false,
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  lessonId: eInfo?.lessonId,
                  path: `/${eInfo?.lessonId}`,
                  yindex: 0,
                });

                await axios.put(`${config.backend}/use/${linkLes?.data?.id}`, {
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  lessonId: eInfo?.lessonId,
                  path: `${nodePath}${el?.data?.path}/${linkLes?.data?.id}`,
                  yindex: eInfo?.data?.yindex,
                  rootDirectoryId: newSubChapter?.data?.id,
                });
              } else {
                const newImg = await axios.post(`${config.backend}/assets`, {
                  assetName: eInfo?.assetName,
                  canEdit: true,
                  assetType: eInfo?.assetType,
                  assetPath: eInfo?.assetPath,
                });
                const linkImg = await axios.post(`${config.backend}/uses`, {
                  canEdit: false,
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  assetId: newImg?.data?.id,
                });
                await axios.put(`${config.backend}/use/${linkImg?.data?.id}`, {
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  assetId: newImg?.data?.id,
                  path: `${nodePath}${el?.data?.path}/${newImg?.data?.id}`,
                  yindex: eInfo?.data?.yindex,
                  rootDirectoryId: newSubChapter?.data?.id,
                });
              }
            }
          }
        }
        if (index === chapterTree?.length - 1) {
          setLoadeAdd(false);
          setOpenModal(true);
        }
      });
    } else {
      setLoadeAdd(false);
      setOpenModal(true);
    }
    setIsAdd(false);
  };

  useEffect(() => {
    if (isAdd) {
      handleAddSubChapter();
    }
  }, [isAdd]);

  const handleAddLesson = async () => {
    setLoadeAdd(true);
    const newLesson = tree?.find((el: any) => el?.id === newNodeInfo?.id);
    const prevTree = new Map(
      oldTree?.map((item: any) => [item.id, item.data.yindex])
    );
    const levelTree = tree?.filter(
      (el: any) => el?.parent === newLesson?.parent && el?.id !== newLesson?.id
    );
    const newYindexList = levelTree.filter((item: any) => {
      return (
        prevTree.has(item.id) && prevTree.get(item.id) !== item.data.yindex
      );
    });

    try {
      const addLessonResponse = await axios.post(`${config.backend}/uses`, {
        coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
        canEdit: false,
        lessonId: newLesson?.data?.lessonId,
        path: `/${newLesson?.data?.lessonId}`,
        yindex: 0,
      });

      const newIdLesson = addLessonResponse?.data?.id;
      const newPath = `${newLesson?.data?.path?.replace(newLesson?.id, '')}`;

      if (newIdLesson && newLesson) {
        if (lesExtraInfo && lesExtraInfo?.length > 0) {
          for (const eInfo of lesExtraInfo) {
            if (eInfo?.lessonId || eInfo?.assetId) {
              if (eInfo?.lessonId) {
                const linkLes = await axios.post(`${config.backend}/uses`, {
                  canEdit: false,
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  lessonId: eInfo?.lessonId,
                  path: `/${eInfo?.lessonId}`,
                  yindex: 0,
                });

                await axios.put(`${config.backend}/use/${linkLes?.data?.id}`, {
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  lessonId: eInfo?.lessonId,
                  path: `${newPath}${newIdLesson}/${linkLes?.data?.id}`,
                  yindex: eInfo?.data?.yindex,
                  rootLessonId: newLesson?.data?.lessonId,
                });
              } else {
                const newImg = await axios.post(`${config.backend}/assets`, {
                  assetName: eInfo?.assetName,
                  canEdit: true,
                  assetType: eInfo?.assetType,
                  assetPath: eInfo?.assetPath,
                });
                const linkImg = await axios.post(`${config.backend}/uses`, {
                  canEdit: false,
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  assetId: newImg?.data?.id,
                });

                await axios.put(`${config.backend}/use/${linkImg?.data?.id}`, {
                  coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
                  assetId: newImg?.data?.id,
                  path: `${newPath}${newIdLesson}/${newImg?.data?.id}`,
                  yindex: eInfo?.data?.yindex,
                  rootLessonId: newLesson?.data?.lessonId,
                });
              }
            }
          }
        }

        await axios.put(`${config.backend}/use/${newIdLesson}`, {
          coursecontentId: parseInt(selectCourse?.coursecontentId, 10),
          id: newIdLesson,
          name: newLesson?.data?.name,
          path: `${newPath}${newIdLesson}`,
          yindex: newLesson?.data?.yindex,
        });

        for (const el of newYindexList) {
          if (el?.data?.lessonId || el?.data?.assetId) {
            await axios.put(`${config.backend}/use/${el.data.usesId}`, {
              yindex: el?.data?.yindex,
            });
          } else {
            const directoryId = el.data.id;
            await axios.put(`${config.backend}/directory/${directoryId}`, {
              yindex: el?.data?.yindex,
            });
          }
        }
      }

      setLoadeAdd(false);
      setOpenModal(true);
    } catch (error) {
      console.error('Error adding lesson:', error);
      setLoadeAdd(false);
    }
  };

  return (
    <>
      {loadeAdd && (
        <div className="fixed inset-0 z-[11] z-10 flex bg-white/75">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-14 !w-14 border-grey-06"
            />
          </div>
        </div>
      )}
      <Stepper>
        <Stepper.Item
          isActive={step === 1}
          isDone={step > 1}
          onClick={() => setStep(1)}
          step="1"
        >
          {(!isMobile || step === 1) &&
            t(`pages.create_library.step_select.title`)}
        </Stepper.Item>
        <Stepper.Item
          isActive={step === 2}
          isDone={step > 2}
          onClick={() => setStep(2)}
          step="2"
        >
          {(!isMobile || step === 2) &&
            t(`pages.create_library.step_add.title`)}
        </Stepper.Item>
      </Stepper>

      <div className="mx-auto mt-8 flex max-w-xl flex-col justify-center px-4 md:px-2">
        {step === 1 && (
          <>
            <p className="mb-2 text-base font-semibold">
              {t(`pages.create_library.step_select.heading`)}
            </p>
            <p className="text-sm text-[#7d7d81]">
              {t(`pages.create_library.step_select.text`)}
            </p>
            <div className="mt-8 rounded-lg border border-grey-02 px-6 pt-4 pb-6">
              <p className="mb-1 text-sm font-semibold text-black">
                {t(`pages.create_library.step_select.select_course`)}
              </p>
              <PaginationSingleSelect
                value={selectCourse}
                onChange={(value: any) => {
                  setselectCourse(value);
                }}
                fetch={useGet.bind(null, '/myowedpublications', {
                  organisationId,
                  userId: authUser?.id,
                })}
                defaultSort={[{ id: '-createdAt' }]}
                render={(row: any) => {
                  return row?.name;
                }}
              />
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <p className="mb-2 text-base font-semibold">
              {t(`pages.create_library.step_add.heading`)}
            </p>
            <p className="text-sm text-[#7d7d81]">
              {t(`pages.create_library.step_add.text`)}
            </p>
            <LibraryCourseTree
              course={selectCourse}
              node={newNodeInfo}
              setCheckAdd={setCheckAdd}
              checkAdd={checkAdd}
              setTree={setTree}
              tree={tree}
              setOldTree={setOldTree}
            />
          </>
        )}

        <div className="mt-8 mb-4 flex min-w-full justify-end">
          {step > 1 && (
            <Button
              variant="secondary"
              onClick={() => setStep(step - 1)}
              className="mr-auto"
            >
              {t('button.previous')}
            </Button>
          )}
          {step < MAXSTEP && (
            <Button disabled={!selectCourse} onClick={() => setStep(step + 1)}>
              {t('button.next')}
            </Button>
          )}
          {step === MAXSTEP && (
            <Button
              onClick={() => {
                node?.lessonId ? handleAddLesson() : handleAddChapter();
              }}
              disabled={checkAdd}
            >
              {t('button.add_btn')}
            </Button>
          )}
        </div>
      </div>
      {openModal && selectCourse && <ModalRedirect course={selectCourse} />}
    </>
  );
}

export default AddLibraryStepsContainer;

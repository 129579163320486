import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { useEffect, useState } from 'react';
import { buildSortedTree, buildTreeFlat, newUid } from '@/util';
import { Image, Play, Chapter, Document } from '@teo/components/icons';

export const TreeItem = ({ node, indent = 0 }: any) => {
  const spacing = 25;

  return (
    <>
      <div className="pb-3" style={{ paddingLeft: `${indent * spacing}px` }}>
        <div className="flex items-center rounded-lg bg-grey-01 px-4 py-2 text-sm text-grey-08">
          {node?.lessonId ? (
            <Document className={`m-1 w-5 min-w-5`} />
          ) : node?.assetId ? (
            node?.assetType === 'image' ? (
              <Image className="m-1 w-5 min-w-5 " />
            ) : (
              <Play className="m-1 w-5 min-w-5 " />
            )
          ) : (
            <Chapter className="m-1 w-5 min-w-5 " />
          )}
          <p className="pl-2">{node?.name || node?.assetName}</p>
        </div>
      </div>
      {node?.children &&
        node?.children?.map((x: any, subIndex: number) => (
          <TreeItem key={x?.id} node={x} indent={indent + 1} index={subIndex} />
        ))}
    </>
  );
};

const ChapterTreeChildren = ({
  directoryId,
  directoryPath,
  setNumLes,
  setChapterTree,
  setFirstLesson,
  setChapterExtraInfo,
}: any) => {
  const { t } = useTranslation();

  const [directories, setDirectories] = useState<any>(null);
  const [treeLessons, setTreeLessons] = useState<any>(null);
  const [tree, setTree] = useState<any>(null);

  useGet(`/directorylessons`, {
    rootId: directoryId,
    onSuccess: (result: any) => {
      setTreeLessons(result?.data || []);
    },
  });

  useGet(`/directorydirectories`, {
    rootId: directoryId,
    onSuccess: (result: any) => {
      setDirectories(result?.data || []);
    },
  });

  const findFirstWithLessonId = (items: any) => {
    for (const item of items) {
      if (item.lessonId) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found: any = findFirstWithLessonId(item.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (treeLessons && directories) {
      const courseLessons = treeLessons.filter(
        (les: any) => !les?.rootLessonId && !les?.rootDirectoryId
      );

      const onlyLessons = treeLessons.filter(
        (les: any) =>
          !les?.rootLessonId && !les?.rootDirectoryId && !les?.assetId
      );

      const onlyExtraInfo = treeLessons.filter(
        (les: any) => les?.rootLessonId || les?.rootDirectoryId
      );

      setChapterExtraInfo(onlyExtraInfo);

      setNumLes(onlyLessons?.length);

      const lesTree = courseLessons?.map((les: any) => ({
        ...les,
        path: les?.path?.replace(directoryPath, ''),
      }));

      const lesDir = directories?.map((dir: any) => ({
        ...dir,
        path: dir?.path?.replace(directoryPath, ''),
      }));

      const newTree = buildSortedTree(lesTree, lesDir || []);
      const [importChapterTree] = buildTreeFlat(lesTree, lesDir) as any;

      setTree(newTree);

      const map = new Map();

      const newChapterTree = importChapterTree.map((obj: any) => {
        const newPath = obj.data.path
          .split('/')
          .map((part: any) => {
            if (part.match(/^[a-zA-Z]/)) {
              if (!map.has(part)) {
                map.set(part, newUid(20));
              }
              return map.get(part);
            }
            return part;
          })
          .join('/');
        return {
          ...obj,
          data: {
            ...obj.data,
            path: newPath,
          },
        };
      });
      setChapterTree(newChapterTree);
      setFirstLesson(findFirstWithLessonId(newTree?.children));
    }
  }, [treeLessons, directories]);

  return (
    <>
      {(tree as any)?.children?.map((x: any, index: number) => (
        <TreeItem
          key={x?.usesId ? x?.usesId : `d_${index}`}
          node={x}
          indent={0}
          index={index}
        />
      ))}
    </>
  );
};

export default ChapterTreeChildren;

import { config } from '@/config';
import { axios } from '@/axios';
import i18n from 'i18next';

export function register(data) {
  return axios.post(
    `${config.teologin}/local/register` +
      (localStorage.getItem('invite')
        ? '?token=' + localStorage.getItem('invite')
        : ''),
    data
  );
}

export function resetPassword(email) {
  return axios.post(
    `${config.teologin}/local/resetpassword?lang=${i18n.language}`,
    { email }
  );
}

export function confirmResetPassword(password, token) {
  return axios.post(`${config.teologin}/local/resetpasswordconfirm`, {
    password,
    token,
    lang: i18n.language,
  });
}

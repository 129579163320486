import { useState, Fragment, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@/components/DataTable/DataTable';
import { format } from 'date-fns';
import {
  Tag,
  AvatarStack,
  PersonAvatar,
  Dropdown,
  Button,
} from '@teo/components';
import OptionsAction from '@/components/DataTable/OptionsAction';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import AutoReportModal from '@/pages/Protected/Organisation/ReportPage/Courses/AutoReportModalCourse';
import { axios } from '@/axios';
import { config } from '@/config';
import { newUid, downloadLink } from '@/util';

function ReportCoursesDataTable({ reportTypeList, courseType }: any) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [isOpenAutoReport, setIsOpenAutoReport] = useState(false);
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));

  const [selected, setSelected] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselect, setDeselect] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);

  const handleDeleteCourses = async () => {
    const promises: any = [];
    if (deselect) {
      const reports = await axios.get(
        `${config.backend}/autoreportdetails?organisationId=${auth?.user?.activeOrganisationId}&courseType=${courseType}&teamIds=null&userIds=null`
      );
      const filteredArray = reports?.data?.filter(
        (primaryObj: any) =>
          ![...selected].some((secondaryObj) => secondaryObj === primaryObj.id)
      );
      filteredArray?.map((course: any) => {
        promises.push(
          axios.delete(`${config.backend}/autoreport/${course?.id}`)
        );
      });
      Promise.allSettled(promises).then(() => {
        setRefetchTable(newUid(20));
        setSelected(new Set());
        setDeselect(false);
      });
    } else {
      [...selected]?.map((course: any) => {
        promises.push(axios.delete(`${config.backend}/autoreport/${course}`));
      });
      Promise.allSettled(promises).then(() => {
        setRefetchTable(newUid(20));
        setSelected(new Set());
      });
    }
  };

  const downloadLinkList = (url: string) => {
    return new Promise<void>((resolve, reject) => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve();
    });
  };

  const handledDownloadUsers = async () => {
    if (deselect) {
      const reports = await axios.get(
        `${config.backend}/autoreportdetails?organisationId=${auth?.user?.activeOrganisationId}&courseType=${courseType}&teamIds=null&userIds=null`
      );
      const filteredArray = reports?.data?.filter(
        (primaryObj: any) =>
          ![...selected].some((secondaryObj) => secondaryObj === primaryObj.id)
      );
      try {
        for (const course of filteredArray) {
          const response = await axios.get(
            `${config.backend}/autoreport/${course?.id}`
          );
          const link = response.data.link;
          if (link) {
            await downloadLinkList(link);
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        }
      } catch (error) {
        console.error('Error downloading course reports:', error);
      }
    } else {
      try {
        for (const course of selected) {
          const response = await axios.get(
            `${config.backend}/autoreport/${course}`
          );
          const link = response.data.link;
          if (link) {
            await downloadLinkList(link);
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        }
      } catch (error) {
        console.error('Error downloading course reports:', error);
      }
    }
  };

  return (
    <div className="mt-6 rounded-lg border border-grey-02 p-6">
      <div className="flex max-w-xl flex-col">
        <p className="pb-2 text-base font-semibold text-black">
          {t('pages.report_page.teams_tab.title_2')}
        </p>
        <p className="text-base text-grey-06">
          {t('pages.report_page.user_tab.subtitle_2')}
        </p>
      </div>
      <div className="mt-4 flex justify-end">
        {(selected?.size > 0 || deselect) && (
          <div className="mr-3 flex flex-row gap-3">
            <Button
              onClick={() => handledDownloadUsers()}
              size="sm"
              disabled={selected?.size > 0 || deselect ? false : true}
            >
              {t('pages.report_page.teams_tab.btn_download')}
            </Button>
            <Button
              onClick={() => handleDeleteCourses()}
              size="sm"
              disabled={selected?.size > 0 || deselect ? false : true}
              variant="outline"
              className="!text-error-04"
            >
              {t('pages.report_page.teams_tab.delete_report')}
            </Button>
          </div>
        )}
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsOpenAutoReport(true)}
        >
          {t('pages.report_page.teams_tab.add_auto_report')}
        </Button>
      </div>
      <div className="mt-5 max-w-6xl">
        <Fragment>
          <DataTable
            filter={filter}
            fetch={useGet.bind(
              null,
              `/autoreportdetails?organisationId=${auth?.user?.activeOrganisationId}&courseType=${courseType}&teamIds=null&userIds=null`
            )}
            key={refetchTable}
            selectable={{
              selected: selected,
              setSelected: setSelected,
              deselect: deselect,
              setDeselect: setDeselect,
            }}
            defaultSort={[
              { id: 'name', desc: false },
              { id: 'updatedAt', desc: false },
            ]}
            columns={[
              {
                title: t('pages.report_page.teams_tab.table_title'),
                id: 'name',
                className: 'col-start-2 col-end-9  row-start-1',
                render: (report: any) => (
                  <div className="flex items-center text-sm font-medium">
                    {report.name}
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                id: 'course',
                sortable: false,
                title: t(`pages.report_page.${courseType}`),
                align: 'right',
                className: 'col-start-1 col-end-10 row-start-3',
                render: (report: any) => (
                  <Dropdown>
                    <Dropdown.Trigger>
                      <AvatarStack>
                        {report?.courseIds?.map((course: any, i: any) => (
                          <PersonAvatar
                            key={course}
                            id={course}
                            name={report?.courseNames[i]}
                          />
                        ))}
                      </AvatarStack>
                    </Dropdown.Trigger>
                    <Dropdown.Content
                      style={{
                        transform: 'translateY(-10px)',
                      }}
                    >
                      <Dropdown.Section>
                        <div className="flex flex-col gap-3">
                          {report?.courseIds?.map((course: any, i: any) => (
                            <div
                              key={course}
                              className="flex items-center gap-3"
                            >
                              <p className="text-left text-[12px] font-medium text-[#231f20] sm:text-sm">
                                {report?.courseNames[i]}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Section>
                    </Dropdown.Content>
                  </Dropdown>
                ),
              },
              {
                addTitleOnMobile: true,
                id: 'startDate',
                title: t('pages.report_page.teams_tab.from'),
                align: 'right',
                className: 'col-start-1 col-end-3 row-start-2',
                render: (report: any) =>
                  report?.startDate &&
                  format(new Date(report?.startDate), 'dd/MM/yyyy'),
              },
              {
                addTitleOnMobile: true,
                id: 'interval',
                title: t('pages.report_page.teams_tab.table_frequency'),
                align: 'right',
                className:
                  'col-start-4 col-end-7 row-start-2 justify-center text-center',
                render: (report: any) => {
                  let typeLabel;

                  switch (report?.interval) {
                    case 86400:
                      typeLabel = 'Daily';
                      break;
                    case 604800:
                      typeLabel = 'Weekly';
                      break;
                    case 2592000:
                      typeLabel = 'Monthly';
                      break;
                    default:
                      typeLabel = 'Yearly';
                      break;
                  }
                  return <p>{typeLabel}</p>;
                },
              },
              {
                addTitleOnMobile: true,
                id: 'email',
                sortable: false,
                title: t('pages.report_page.teams_tab.email_report'),
                align: 'right',
                className:
                  'col-start-8 col-end-10 row-start-2 justify-end text-right',
                render: (report: any) => (
                  <Tag
                    title={report?.email?.length > 0 ? 'Yes' : 'No'}
                    variant={report?.email?.length > 0 ? 'success' : 'error'}
                  />
                ),
              },
              {
                id: 'actionButton',
                sortable: false,
                title: '',
                className: 'col-start-9 row-start-1 justify-end',
                render: (report: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        addIcon: true,
                        addOnMobile: true,
                        id: '1',
                        title: t('pages.report_page.teams_tab.btn_download'),
                        onClick: () => {
                          report?.link && downloadLink(report?.link);
                        },
                      },
                    ]}
                    delete={{
                      title: t('pages.manage_tags.delete_tag'),
                      request: `${config.backend}/autoreport/${report.id}`,
                      selected: selected,
                      setSelected: setSelected,
                    }}
                    rowInfo={report}
                    setRefetchTable={setRefetchTable}
                    mobileHeader={{
                      render: (report: any) => (
                        <div className="flex items-center">{report.name}</div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </Fragment>
      </div>
      {isOpenAutoReport && (
        <AutoReportModal
          report={'users'}
          setIsOpenAutoReport={setIsOpenAutoReport}
          closeModal={() => setIsOpenAutoReport(false)}
          user={auth?.user}
          reportTypeList={reportTypeList}
          setRefetchTable={setRefetchTable}
          courseType={courseType}
        />
      )}
    </div>
  );
}

export default ReportCoursesDataTable;

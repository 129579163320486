import React, { Fragment, useState, Component } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { getSafe } from '@/util';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './KioskNav.css';

const KioskNav = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  console;

  let homeUrl = getSafe(() => {
    let temp = new URL(props.loginData.homeUrl);
    return temp.pathname + temp.search + temp.hash;
  }, '/dashboard');

  let loginUrl = getSafe(() => {
    let temp = new URL(props.loginData.loginUrl);
    return temp.pathname + temp.search + temp.hash;
  }, '/login');

  return (
    <div
      className="center flex"
      style={{
        padding: '12px 20px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#333333',
        minWidth: '100%',
        zIndex: 2147483646,
        gap: 15,
      }}
    >
      <a
        onClick={() => {
          navigate(-1);
        }}
        href="javascript:void(0)"
      >
        <img style={{ height: '40px' }} src="/assets/images/back.png"></img>
      </a>
      <a
        onClick={() => {
          navigate(homeUrl);
        }}
        style={{ margin: '0 60px' }}
        href="javascript:void(0)"
      >
        <img style={{ height: '40px' }} src="/assets/images/home.png"></img>
      </a>
      <a
        style={{ display: 'none', flex: 1 }}
        onClick={() => {
          navigate(1);
        }}
        href="javascript:void(0)"
      >
        <img
          style={{ height: '40px', transform: 'scaleX(-1)' }}
          src="/assets/images/back.png"
        ></img>
      </a>
      <div className="flex" style={{ alignItems: 'flex-end', gap: 15 }}></div>
    </div>
  );
};

export { KioskNav };

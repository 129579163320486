import React, {
  Component,
  Fragment,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  isEmpty,
  deep_copy,
  newUid,
  getSafe,
  isString,
  shuffle,
  kendallsTau,
  round2,
  isEqual,
  focusWidget,
  kendallsTauAccuracy,
  rgInterpolate,
} from '@/util';
import { Radio, Checkbox } from '@teo/components';
import { MagnifyImage } from './MagnifyImage';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragHandle, Check, Cross } from '@teo/components/icons';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { XAPIService } from '@/services/xapi.service.js';
import update from 'immutability-helper';

const CustomDragCard = (props) => {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: 'calc(100% - 64px)',
    maxWidth: '640px',
    height: '100%',
  };
  function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      test: monitor,
    }));

  function renderItem() {
    switch (itemType) {
      case 'card':
        return (
          <div
            className={`flex min-h-16 w-full cursor-move items-center rounded-lg bg-white text-[#231f20]`}
          >
            <DragHandle className="mx-5 w-5 min-w-5" />
            <p className={` ${item?.text ? 'mr-auto' : ''}`}>{item?.text}</p>
            {item?.file ? (
              <div
                className={` min-w-max overflow-hidden rounded-lg ${
                  item?.text ? 'm-2' : 'mx-auto my-2'
                }`}
              >
                <MagnifyImage
                  className={` ${
                    item?.text
                      ? 'h-[80px] w-[140px] object-contain'
                      : 'h-[100px] w-auto object-contain'
                  }`}
                  src={item?.file}
                  draggable={false}
                  showIcon={true}
                />
              </div>
            ) : null}
          </div>
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}
      >
        {renderItem()}
      </div>
    </div>
  );
};

export const Card = ({
  id,
  text,
  index,
  file,
  moveCard,
  answered,
  setIsMouseDown,
  isMouseDown,
  correctCardText,
  correctCardFile,
  correctIndex,
  stateUid,
  correctionColor,
  correctionScore,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY * 0.1) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY * 1.9) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      focusWidget(stateUid);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    canDrag: !answered,
    item: () => {
      return { id, index, text, file };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging(monitor) {
      return id === monitor.getItem().id;
    },
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const [hoverImage, setHoverImage] = useState(false);

  const handleMouseDown = () => {
    !hoverImage && answered && setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    !hoverImage && answered && setIsMouseDown(false);
  };

  let correctClass;
  if (answered) {
    if (id === correctIndex) {
      correctClass = 'border !border-success-04 !cursor-default';
    } else {
      correctClass = `border !border-error-04 !cursor-default ${
        isMouseDown ? '!border-secondary-04' : '!border-error-04'
      }`;
    }
  }

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      data-index={id}
    >
      <div
        className={`flex min-h-16 w-full cursor-move items-center rounded-lg bg-white text-[#231f20]`}
        style={
          answered && !isMouseDown
            ? { borderWidth: '1px', borderColor: correctionColor }
            : {}
        }
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
      >
        {answered ? (
          correctionScore > 0.5 ? (
            <Check className="mx-6 w-6 min-w-6 text-success-05" />
          ) : isMouseDown ? (
            <div className="ml-7 mr-6 h-5 min-h-5 w-5 min-w-5 rounded-full border border-secondary-04">
              <Check className="mt-px ml-px w-4 min-w-4 text-secondary-04" />
            </div>
          ) : (
            <Cross className="mx-6 w-6 min-w-6 text-error-05" />
          )
        ) : (
          <DragHandle className="mx-5 w-5 min-w-5" />
        )}
        <p className={` ${text ? 'mr-auto' : ''}`}>
          {answered
            ? id == correctIndex
              ? text
              : isMouseDown
              ? correctCardText
              : text
            : text}
        </p>
        {file ? (
          <div
            className={`m-2 min-w-max overflow-hidden rounded-lg ${
              text ? 'm-2' : 'mx-auto my-2'
            }`}
          >
            <MagnifyImage
              className={` ${
                text
                  ? 'h-[80px] w-[140px] object-contain'
                  : 'h-[100px] w-auto object-contain'
              }`}
              src={
                answered
                  ? id == correctIndex
                    ? file
                    : isMouseDown
                    ? correctCardFile
                    : file
                  : file
              }
              draggable={false}
              showIcon={true}
              setHoverImage={setHoverImage}
              setIsMouseDown={setIsMouseDown}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const Container = ({
  orderQuestions,
  setOrderQuestions,
  setModified,
  answered,
  setIsMouseDown,
  isMouseDown,
  state,
  answer,
}) => {
  {
    const answerScores = kendallsTauAccuracy(answer.orderQuestions);
    const answerColors = answerScores.map((x) => rgInterpolate(x));

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setOrderQuestions((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
      setModified(newUid(20));
    }, []);

    const renderCard = useCallback(
      (card, index) => {
        return (
          <Card
            key={card.index}
            index={index}
            id={card.index}
            text={card.text}
            file={card.file}
            moveCard={moveCard}
            answered={answered}
            correctionColor={answerColors[index]}
            correctionScore={answerScores[index]}
            setIsMouseDown={setIsMouseDown}
            isMouseDown={isMouseDown}
            correctCardText={state?.orderQuestions[index]?.text}
            correctCardFile={state?.orderQuestions[index]?.file}
            correctIndex={state?.orderQuestions[index]?.index}
            stateUid={state?.uid}
          />
        );
      },
      [isMouseDown]
    );

    return (
      <DndProvider backend={HTML5Backend}>
        {orderQuestions.map((card, i) => {
          return renderCard(state.orderQuestions[card], i);
        })}
      </DndProvider>
    );
  }
};

export const OrderView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setCorrectionFn = undefined,
  setXApiSubmitFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  OrderView.syncStates(state, answer, correction);

  const [orderQuestions, setOrderQuestions] = useState(
    getSafe(
      () =>
        showAnswers
          ? answer.orderQuestions.map((x, i) => i)
          : answer.orderQuestions,
      []
    )
  );

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [modified, setModified] = useState(null);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [score, setScore] = useState(correction?.score);
  const answered = submitted || isCorrected;

  useEffect(() => {
    if (submitted) {
      let answer = getAnswer();
      let correction = {};
      OrderView.syncStates(state, answer, correction);
      if (!answer?.answered) {
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  const [prevOrderQuestions, setPrevOrderQuestions] = useState(
    answer.orderQuestions
  );

  //generate the answer
  const getAnswer = () => {
    answer.orderQuestions = orderQuestions;
    const correctionOrder = state?.orderQuestions?.map((_x, i) => i);
    const isMoved =
      isEqual(orderQuestions, prevOrderQuestions) &&
      !isEqual(orderQuestions, correctionOrder);
    answer.incomplete = !submitted && isMoved;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    let correction = {};
    OrderView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = (lessonId, lang) => {
    let correction = {};
    let answer = getAnswer();
    OrderView.syncStates(state, answer, correction);
    const testId = lessonId;
    const questionId = state.uid;

    const choices = state.orderQuestions.map((x, i) => ({
      id: `${i}`,
      description: {
        [lang]: x.text,
      },
    }));
    const answerIds = answer.orderQuestions.map((x, i) => `${i}`);
    const correctAnswerIds = state.orderQuestions.map((x, i) => `${i}`);

    const success = correction.score >= state.score * 0.5;
    const name = {
      [lang]: state?.titleWidget || t('widgets.type.multiple_choice_question'),
    };
    const description = {
      [lang]: state?.vraag,
    };

    let xapi = XAPIService.getInstance();
    xapi.cmi5.interactionSequencing(
      testId,
      questionId,
      answerIds,
      correctAnswerIds,
      choices,
      name,
      description,
      success
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(score)}
          maxScore={state.score}
          answered={answered}
          titleWidget={state.titleWidget || t('widgets.type.order_question')}
        />
      )}
      <FroalaTextareaView value={state.vraag} className="mb-2" />
      {answered ? (
        score < state?.score ? (
          <p className="text-xs font-medium text-error-04">
            {t('pages.correction_result.order_widget_hint')}
          </p>
        ) : null
      ) : null}
      <Container
        orderQuestions={orderQuestions}
        setOrderQuestions={setOrderQuestions}
        setModified={setModified}
        answered={answered}
        setIsMouseDown={setIsMouseDown}
        isMouseDown={isMouseDown}
        state={state}
        answer={answer}
      />
      {!answered && <CustomDragCard />}
      {(state.immediateFeedback || correctionType === 'autofeedback') && (
        <PartialSubmitSection
          setSubmitted={setSubmitted}
          setModified={setModified}
          answered={answered}
          onSave={onSave.bind(null, false, false, state)}
        />
      )}
      {(resultPages || showAnswers || answered || submitted) &&
      state?.antwoord ? (
        <ExplanationSection state={state} />
      ) : null}
    </div>
  );
};

OrderView.syncStates = (state, answer, correction) => {
  if (!answer.orderQuestions) answer.orderQuestions = [];
  if (
    isEmpty(answer) ||
    answer.orderQuestions.length !== state.orderQuestions.length
  ) {
    answer.uid = state.uid;
    answer.orderQuestions = shuffle(state.orderQuestions.map((_x, i) => i));
    // answer.orderQuestions = state.orderQuestions.map((_x, i) => i);
  }
  if (isEmpty(correction)) {
    correction.uid = state.uid;
    if (!correction.score) {
      //autocorrect
      if (answer.orderQuestions) {
        correction.score =
          Math.max(0, (kendallsTau(answer.orderQuestions) - 0.5) * 2) *
          state.score;
      }
    }
  }
};

import { useMembers } from '@/query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const MemberPage: React.FC = () => {
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const { data: members } = useMembers({
    count: 10,
    offset,
  });

  return (
    <div>
      <table>
        <tbody>
          {members?.map((member) => (
            <tr key={member.id}>
              <td>{member.id}</td>
              <td>{member.firstName}</td>
              <td>{member.lastName}</td>
              <td>{member.username}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => setOffset(offset + 10)}>
        {t('pages.members.more')}
      </button>
    </div>
  );
};

export default MemberPage;

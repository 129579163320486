import { useGet } from '@/query/fetchHooks';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Fragment, useState, useContext, useEffect } from 'react';
import {
  PersonAvatar,
  PageHeader,
  IconButton,
  Button,
  ButtonLink,
  Tabs,
  InputText,
  FilterList,
} from '@teo/components';
import { Search, ArrowRight, MoreVertical, Eye } from '@teo/components/icons';
import { DataTable } from '@/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import {
  Coursedetails,
  Studentwithteams,
  Courseteamdetails,
  Userwithorganisationwithidps,
} from '@/models';
import { format } from 'date-fns';
import OptionsAction from '@/components/DataTable/OptionsAction';
import { CourseTable } from '@/components/CourseTable';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { TeamsTable } from '@/components/TeamsTable';
import { axios } from '@/axios';
import { config } from '@/config';
import AddCourse from './AddCourse/AddCoures';
import i18n from 'i18next';
import { newUid } from '@/util';

export interface Filter {
  label: string;
  value: string;
}

function FollowUpCourseDetails({ courseType = 'course' }) {
  const { coursecontentId, organisationId } = useParams();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const [course, setCourse] = useState<Coursedetails | null>(null);
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [canEdit, setCanEdit] = useState<boolean>((authUser?.role || 0) >= 90);
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [tab, setTab] = useState('versions');
  const [selectedStatus, setSelectedStatus] = useState<Filter[]>([
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
  ]);
  const [filterStatus, setFilterStatus] = useState<any>();

  const [isOpenAddContent, setIsOpenAddContent] = useState<any>(false);

  const navigate = useNavigate();
  const managePath = `/${i18n.language}/manage/${organisationId}/users`;

  const isScorm = course?.publicationType?.startsWith?.('scorm') || false;

  useEffect(() => {
    if (course) {
      axios.post(config.backend + '/activity', {
        userId: auth?.user?.id,
        subjectId: coursecontentId,
        activityType: courseType,
        subject: course.courseName || course.name,
        logo: course.courseImage || course.image,
        link: window.location.pathname,
      });
    }
  }, [course]);

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  const userPath = basePath.slice(0, basePath.lastIndexOf('/')) + '/users';
  const teamPath = basePath.slice(0, basePath.lastIndexOf('/')) + '/teams';

  useGet(`/coursecontentdetail/${coursecontentId}`, {
    onSuccess: (result: any) => {
      //
      if (
        (result?.data?.courseAccess === 'protected' ||
          result?.data?.courseAccess === 'public') &&
        (authUser?.activeOrganisationRole || 0) > 50
      ) {
        //I have access through organisation
        setCanEdit(true);
      } else {
        //check my personal access
        axios
          .get(
            `${config.backend}/mycoursecontent/${result?.data?.id}/${authUser?.id}`
            // `${config.backend}/mycoursecontent/${result?.data?.id}/44444`
          )
          .then(() => {
            setCanEdit(true);
          });
      }
      setCourse(result?.data || null);
    },
  });

  const FILTERS_STATUS = [
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
    {
      label: t('pages.follow_up_courses.status_inactive'),
      value: 'disabled,hidden',
    },
  ];

  useEffect(() => {
    if (selectedStatus.length == 0) {
      setFilterStatus(null);
    }
    if (selectedStatus.length == 1) {
      setFilterStatus(`${selectedStatus[0].value}`);
    }
    if (selectedStatus.length > 1) {
      const select = selectedStatus?.map((el) => el.value);
      setFilterStatus(`${select}`);
    }
    setRefetchTable(newUid(20));
  }, [selectedStatus]);

  const coursePath = basePath;

  const userColumns: any = [
    {
      title: t('columns.username'),
      id: 'username',
      className: 'col-start-1 col-end-9',
      render: (usr: Studentwithteams) => (
        <div className="flex items-center">
          <PersonAvatar
            inline
            id={usr.id}
            name={usr.username || ''}
            imageSrc={usr.picture || undefined}
          ></PersonAvatar>
          <div>
            <div className="text-sm font-medium">{usr.username}</div>
            <div className="text-xs text-[#7d7d81]">{usr.email}</div>
          </div>
        </div>
      ),
    },
  ];

  if (
    Math.max(authUser?.role || 0, authUser?.activeOrganisationRole || 0) >= 90
  ) {
    userColumns.push({
      id: 'actionButton',
      sortable: false,
      title: '',
      className: 'col-start-9 row-start-1 justify-end',
      render: (usr: any) => (
        <OptionsAction
          menuItems={[
            {
              addIcon: true,
              id: '2',
              title: t('pages.follow_up_courses.manage_user'),
              onClick: () => navigate(`${managePath}/${usr?.id}`),
            },
          ]}
          screensUpdateTable="planshet"
          pageDetail={{
            title: t('pages.follow_up_courses.manage_user'),
            rowPath: (usr) => `${userPath}/${usr.id}`,
          }}
          rowInfo={usr}
          mobileHeader={{
            render: (usr: any) => (
              <div className="flex items-center">
                <PersonAvatar
                  inline
                  id={usr.id}
                  name={usr.username || ''}
                  imageSrc={usr.picture || undefined}
                ></PersonAvatar>
                <div>
                  <div className="text-sm font-medium">{usr.username}</div>
                  <div className="text-xs text-[#7d7d81]">{usr.email}</div>
                </div>
              </div>
            ),
          }}
        />
      ),
    });
  }

  if (!course) return null;

  return (
    <div className="w-full max-w-6xl flex-col pb-8">
      <div className="pb-2">
        {/*<PageHeader to={basePath} ariaLabel={'placeholder'}>*/}
        <PageHeader /* @ts-expect-error eslint-disable-next-line */
          to={-1}
          ariaLabel={'placeholder'}
        >
          <div className="flex w-full items-center py-4 lg:py-6">
            <div className="flex items-center">
              <div className="w-16 min-w-16 lg:w-24 lg:min-w-24">
                <img
                  src={course.image || '/images/courseFallback.png'}
                  alt="courseFallback"
                  className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                />
              </div>
            </div>

            <div className="mx-4 flex w-full flex-col justify-between gap-4 xl:flex-row">
              <div className="flex flex-col content-center">
                <h1 className="break-all pb-1.5 text-base font-semibold text-black md:text-lg lg:text-xl">
                  {course.name}
                </h1>
                <div className="flex flex-wrap content-center text-xs text-[#919193]">
                  <p className="mr-3 border-r border-[#919193] pr-3 ">
                    {t(`courseTypes.${courseType}`)}
                  </p>
                  <span className="">
                    {t('pages.follow_up_courses.courses_author')}
                    <Link
                      className="inline-block pl-2 pr-2 text-xs text-tint-blue hover:underline"
                      to="../#"
                    >
                      {course.username}
                    </Link>
                  </span>
                </div>
              </div>

              <div className="flex flex-wrap items-center gap-3">
                {/*
                <div className="rounded-lg border border-grey-02">
                  <Button
                    variant="ghost"
                    size="md"
                    containsOnlyIcon={true}
                    onClick={() => {}}
                  >
                    <img
                      src="/images/components/follow_up_courses/star_icon.svg"
                      alt="star_icon"
                      className="h5"
                    />
                  </Button>
                </div>
                */}
                {!isScorm && (
                  <div className="rounded-lg shadow-[inset_0_0_0_1px] shadow-grey-03">
                    {/* todo: only use correction key access? */}
                    <ButtonLink
                      to={
                        // canEdit
                        //   ? `/${i18n.language}/create/${
                        //       authUser.activeOrganisationId
                        //     }/${
                        //       courseType == 'leervacature'
                        //         ? 'vacancie'
                        //         : courseType
                        //     }s/${course.id}`
                        //   : `/${i18n.language}/create/${organisationId}/courses/${course.id}/previewanswers?les=0`
                        `/${i18n.language}/create/${organisationId}/courses/${course.id}/previewanswers?les=0`
                      }
                      variant="outline"
                      size="md"
                      className="flex items-center shadow-[inset_0_0_0_1px]"
                    >
                      {/* {canEdit
                        ? courseType == 'assessment'
                          ? t('pages.follow_up_courses.create_assessment')
                          : courseType == 'leervacature'
                          ? t('pages.follow_up_courses.create_vacancy')
                          : t('pages.follow_up_courses.create_course')
                        : t('pages.follow_up_courses.correction_key')} */}
                      {t('pages.follow_up_courses.correction_key')}
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </ButtonLink>
                  </div>
                )}

                {!isScorm && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/${i18n.language}/create/${organisationId}/courses/${course.id}/preview?les=0&new_tab=true`}
                  >
                    <Button className="flex items-center">
                      {t('button.preview')}
                      <Eye className="ml-2 h-5 w-5" />
                    </Button>
                  </a>
                )}
                {/*
                <div>
                  <Button
                    onClick={() => {
                      setIsOpenAddContent(true);
                      //navigate(`${basePath}/${course?.id}/addUsers`);
                    }}
                    size="md"
                  >
                    {t('pages.follow_up_courses.add_students')}
                  </Button>
                </div>
                */}

                {isOpenAddContent && (
                  <AddCourse
                    activeCourse={course.id}
                    setIsOpenAddContent={setIsOpenAddContent}
                  />
                )}
                {/*
                <div>
                  <IconButton
                    Icon={MoreVertical}
                    variant="ghost"
                    className="text-[#919193]"
                    onClick={() => {
                      navigate(`${basePath}/${course?.id}/settings`);
                    }}
                  />
                </div>
                */}
              </div>
            </div>
          </div>
        </PageHeader>

        {/*
        <div className="mb-6 flex flex-wrap justify-between gap-2 rounded-lg border border-grey-02 py-7 px-9">
          <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
            <span className="text-base font-semibold">
              {course.nrOfStudents}
            </span>
            <span className="text-xs font-normal text-[#7d7d81]">
              {t('pages.follow_up_courses.signed_up')}
            </span>
          </div>
          <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
            <span className="text-base font-semibold">
              {course.nrOfStudentsPassed}
            </span>
            <span className="text-xs font-normal text-[#7d7d81]">
              {t('pages.follow_up_courses.completed')}
            </span>
          </div>
          <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
            <span className="text-base font-semibold">81%</span>
            <span className="text-xs font-normal text-[#7d7d81]">
              {t('pages.follow_up_courses.average_score')}
            </span>
          </div>
          <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
            <span className="text-base font-semibold">4u36</span>
            <span className="text-xs font-normal text-[#7d7d81]">
              {t('pages.follow_up_courses.average_time')}
            </span>
          </div>
        </div>
        */}
      </div>
      <div className="mt-6 font-medium">
        <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
          <Tabs.TabButton name="versions">
            {t('pages.follow_up_courses.versions')}
          </Tabs.TabButton>
          <Tabs.TabButton name="users">
            <span>{t('pages.follow_up_courses.users')}</span>
            <span className="ml-2 text-base text-[#919193]">
              {course.nrOfStudents}
            </span>
            {(course?.tasksRemaining || 0) > 0 && (
              <span className="ml-2 h-2 w-2 rounded-full bg-[#fc6232]"></span>
            )}
          </Tabs.TabButton>
          <Tabs.TabButton name="teams">
            {t('pages.follow_up_courses.teams')}
          </Tabs.TabButton>
        </Tabs>
      </div>
      {tab === 'users' && (
        <Fragment>
          <div className="py-8 md:w-1/2 xl:w-1/3">
            <InputText
              value={filter}
              onChange={(ev) => setFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.user')}
            />
          </div>

          <DataTable
            filter={filter}
            rowPath={(user) => `${userPath}/${user.id}`}
            defaultSort={[{ id: 'username', desc: false }]}
            fetch={useGet.bind(null, '/publicationusers', {
              masterId: coursecontentId,
              organisationId: authUser.activeOrganisationId,
            })}
            screensUpdateTable="planshet"
            empty={{
              template: 1,
              text: t('empty_tenplate.user_list'),
              btn_text: t('empty_tenplate.btn_add_user'),
              btnClick: () => navigate(userPath),
            }}
            columns={userColumns}
          />
        </Fragment>
      )}
      {tab === 'versions' && (
        <>
          <div className="py-8 md:w-1/2 xl:w-1/3">
            <InputText
              value={filter}
              onChange={(ev) => setFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.course')}
            />
          </div>

          <div className="mb-5 flex items-center">
            <FilterList
              filters={FILTERS_STATUS}
              onSelect={setSelectedStatus}
              selected={selectedStatus}
            />
          </div>
          <CourseTable
            courseType={courseType}
            refetchTable={refetchTable}
            setRefetchTable={setRefetchTable}
            setCourse={setCourse}
            filter={filter}
            screensUpdateTable="planshet"
            rowPath={(course: any) => `${coursePath}/details/${course.id}`}
            fetch={useGet.bind(null, '/membercoursedetails', {
              masterId: `${coursecontentId},OR,coursecontentId=${coursecontentId}`,
              organisationId: authUser.activeOrganisationId,
              courseStatus: filterStatus,
              courseType,
            })}
            /*
            fetch={useGet.bind(null, '/myteachedcourses', {
              masterId: `${coursecontentId},OR,coursecontentId=${coursecontentId}`,
              organisationId: authUser.activeOrganisationId,
              courseStatus: filterStatus,
              userId: authUser?.id,
            })}
            */
            defaultSort={[{ id: 'createdAt', desc: true }]}
            empty={{
              template: 2,
              text: t('empty_tenplate.course_list'),
              // btn_text: t('empty_tenplate.btn_add_version'),
              // btnClick: () => navigate(userPath),
            }}
            /*
              selectable={{
                selected: selected,
                setSelected: setSelected,
                deselect: deselect,
                setDeselect: setDeselect,
                checkboxIconMobileType: 'square',
              }}
            */
          />
        </>
      )}
      {tab === 'teams' && (
        <Fragment>
          <div className="py-8 md:w-1/2 xl:w-1/3">
            <InputText
              value={filter}
              onChange={(ev) => setFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.team')}
            />
          </div>

          {/*SELECT "id", "teamName", "teamPath", "nrOfTeamMembers", "autoGenerated", "organisationId", "masterId", "courseId" FROM "publicationteamdetails" AS "publicationteamdetails" WHERE (("publicationteamdetails"."masterId" = '74') AND ("publicationteamdetails"."organisationId" = '18')) ORDER BY "publicationteamdetails"."teamName" ASC LIMIT 10 OFFSET 0;*/}

          <TeamsTable
            filter={filter}
            rowPath={(team: any) => `${teamPath}/${team.id}`}
            defaultSort={[{ id: 'teamPath', desc: false }]}
            fetch={useGet.bind(null, '/publicationteams', {
              masterId: coursecontentId,
              organisationId: authUser.activeOrganisationId,
            })}
            screensUpdateTable="planshet"
            organisationId={organisationId}
            empty={{
              template: 1,
              text: t('empty_tenplate.team_list'),
              btn_text: t('empty_tenplate.btn_add_team'),
              btnClick: () => navigate(teamPath),
              // btn_path: teamPath,
            }}
          />
        </Fragment>
      )}
    </div>
  );
}

export default FollowUpCourseDetails;

import { cleverClone, isEmpty, isObject } from '../util';
//var cloneDeep = require('lodash.clonedeep');

export function assertPath(state, keys, action, backup = {}) {
  let temp = state;
  for (let i = 0; i < keys.length; ++i) {
    let key = action[keys[i]] || backup[keys[i]];
    if (key === undefined)
      console.error(
        'Unable to determine key',
        keys[i],
        keys,
        'for action',
        action,
        backup,
        'action should pass this data as extra'
      );
    if (!temp[key]) temp[key] = {};
    temp = temp[key];
  }
  return temp;
}

export function generateRestReducer(keys, label, actionKey, reduceExtra) {
  let key = keys[keys.length - 1];
  keys.pop();
  let modified = false;
  return (state = {}, action) => {
    switch (action.type) {
      case label + '_LIST_CLEAR': {
        let temp = assertPath(state, keys, action);
        for (let key in temp) delete temp[key];
        modified = true;
        break;
      }
      case label + '_LIST_SUCCESS': {
        let temp;
        if (action[actionKey].length === 0) {
          temp = assertPath(state, keys, action);
        } else {
          temp = assertPath(state, keys, action[actionKey][0], action);
        }
        if (!action.append) for (let key in temp) delete temp[key];
        Object.defineProperty(temp, 'serverLength', {
          enumerable: false,
          value:
            action.serverLength != undefined
              ? action.serverLength
              : action[actionKey].length,
          writable: true,
        });
        for (let obj of action[actionKey]) {
          temp[obj[key]] = obj;
        }
        modified = true;
        break;
      }
      case label + '_GET_SUCCESS':
      case label + '_POST_SUCCESS':
      case label + '_PUT_SUCCESS': {
        let temp = assertPath(state, keys, action[actionKey], action);
        if (action.type === label + '_POST_SUCCESS') temp.serverLength += 1;
        let lastKey =
          action[actionKey][key] || action[actionKey].id || action[key];
        if (!keys.length) state = action[actionKey];
        else if (isObject(action[actionKey]))
          temp[lastKey] = Object.assign({}, temp[lastKey], action[actionKey]);
        else temp[lastKey] = action[actionKey];
        modified = true;
        break;
      }
      case label + '_DELETE_SUCCESS': {
        let deleteEl = action[actionKey] || action[key];
        if (!deleteEl || isEmpty(deleteEl)) deleteEl = action;
        let temp = assertPath(state, keys, deleteEl, action);
        if (temp[deleteEl[key]]) {
          temp.serverLength -= 1;
          delete temp[deleteEl[key]];
        }
        modified = true;
        break;
      }
    }

    if (modified) state = cleverClone(state);
    if (reduceExtra)
      return reduceExtra(keys, label, actionKey, actionKey, reduceExtra);
    return state;
  };
}

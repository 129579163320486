import { useState } from 'react';
import { Button, IconButton } from '@teo/components';
import {
  ChevronLeft,
  ChevronRight,
  MoreHorizontal,
} from '@teo/components/icons';

function Pagination({
  setOffset,
  offset,
  count,
  page,
  maxPage,
  organisation,
}: any) {
  const arr = Array(maxPage)
    .fill(null)
    .map((_, i) => i + 1);

  const view = organisation ? 1 : 2;
  const maxView = organisation ? 3 : 4;
  const endView = organisation ? 2 : 3;

  return (
    <div
      className={`mx-auto my-4 flex w-full max-w-xs items-center justify-center ${
        !organisation ? 'md:w-[450px]' : 'px-3'
      } md:max-w-lg`}
    >
      <IconButton
        Icon={ChevronLeft}
        variant="outline"
        onClick={() => setOffset(Math.max(offset - count, 0))}
        size="md"
        className="mr-auto"
        disabled={page === 1}
      />
      {arr?.map((el: number, index) => {
        return el === 1 ||
          el === maxPage ||
          (page <= el + view && page >= el - view) ? (
          <span key={`${el}_${index}` as string} className="flex">
            {page > maxView && el === page - view && (
              <MoreHorizontal className="mx-2 mt-auto mb-1 hidden w-5 md:block" />
            )}
            <Button
              variant="text"
              onClick={() => setOffset((el - 1) * count, el * count)}
              key={index}
              disabled={page === el}
              size="md"
              className="hidden md:block"
            >
              {el}
            </Button>
            {page < maxPage - endView && el === page + view && (
              <MoreHorizontal className="mx-2 mt-auto mb-1 hidden w-5 md:block" />
            )}
          </span>
        ) : (
          ''
        );
      })}
      {/* &nbsp;{page}/{maxPage}&nbsp; */}
      <IconButton
        Icon={ChevronRight}
        variant="outline"
        onClick={() =>
          setOffset(Math.min(offset + count, (maxPage - 1) * count))
        }
        size="md"
        disabled={page === maxPage}
        className="ml-auto"
      />
    </div>
  );
}

export default Pagination;

import { BehaviorSubject } from 'rxjs';
//import AbstractCmi5 from "@xapi/cmi5/AbstractCmi5";
import { parseQueryString, htmlDecode, secondsToIsoDuration } from '@/util';
import { decode, encode } from '@/base64';
import { publicAxios, axios } from '@/axios';
import { config } from '@/config';

class TinCanService {
  queries;
  lastProgress;
  reportedVerbs = new Set(); // Track reported verbs

  constructor(xapidetails) {
    if (TinCanService._instance) {
      return TinCanService._instance; // Enforce the singleton
    }
    this.initialize(xapidetails);
    TinCanService._instance = this; // Store the instance
  }

  static getInstance(xapidetails) {
    if (!TinCanService._instance) {
      new TinCanService(xapidetails); // Create a new instance if none exists
    }
    return TinCanService._instance; // Return the singleton instance
  }

  reportStatus(follow, course) {
    if (this.available()) {
      if (follow.progress == 0) {
        this.reportInit(follow, course);
      } else if (follow.progress >= 0.99) {
        this.reportFinal(follow, course);
      } else {
        this.progress(follow, course);
      }
    }
  }

  generateTincanStatement(follow, course) {
    let actor;
    try {
      actor = JSON.parse(decodeURIComponent(this.queries.actor));
    } catch (e) {
      actor = {}; // Default empty actor if decoding fails
    }

    // Handle array of actors
    if (Array.isArray(actor)) {
      actor = actor[0]; // Use the first actor object
    }

    // Ensure 'name' and 'mbox' are strings in the case of arrays
    if (actor && typeof actor === 'object') {
      actor.mbox = Array.isArray(actor.mbox) ? actor.mbox[0] : actor.mbox || '';
      actor.name = Array.isArray(actor.name) ? actor.name[0] : actor.name || '';
    }

    const statement = {
      id: crypto.randomUUID(),
      timestamp: new Date().toISOString(),
      actor: {
        objectType: 'Agent',
        mbox: actor.mbox || '',
        name: actor.name || '',
      },
      object: {
        id: htmlDecode(decodeURIComponent(this.queries.activity_id)),
        objectType: 'Activity',
        definition: {
          type: `http://adlnet.gov/expapi/activities/${course.courseType}`,
          name: {
            'en-US': `${course.courseName}`,
          },
          description: {
            'en-US': `${course.courseDescription}`,
          },
        },
      },
      context: {
        registration: this.queries.registration,
        /*
        contextActivities: {
          parent: [
            {
              id: `https://app.learnupon.com/courses/${this.queries.course_id}`,
              objectType: "Activity",
              definition: {
                type: "http://adlnet.gov/expapi/activities/course",
                name: {
                  "en-US": course.courseName
                }
              }
            }
          ],
          grouping: [
            {
              id: `https://app.learnupon.com/enrollments/${this.queries.enroll_id}`,
              objectType: "Activity",
              definition: {
                type: "http://adlnet.gov/expapi/activities/session"
              }
            }
          ],
          category: [
            {
              id: `http://id.tincanapi.com/activitytype/${course.courseType}`,
              objectType: "Activity",
              definition: {
                type: `http://id.tincanapi.com/activitytype/${course.courseType}`,
                name: {
                  "en-US": course.courseType
                }
              }
            }
          ]
        },
        */
      },
    };
    return statement;
  }

  progress(follow, course) {
    if (follow.progress === this.lastProgress) return;
    this.lastProgress = follow.progress;
    if (this.available()) {
      let progressStatement = this.generateTincanStatement(follow, course);
      progressStatement.verb = {
        id: 'http://adlnet.gov/expapi/verbs/progressed',
        display: {
          'en-US': 'progressed',
        },
      };
      progressStatement.result = {
        completion: follow.progress >= 0.99,
        duration: secondsToIsoDuration(follow.totalTimeSpent),
        extensions: {
          'https://w3id.org/xapi/cmi5/result/extensions/progress':
            follow.progress,
        },
      };
      //window.parent.postMessage(JSON.stringify(progressStatement), '*');
      this.sendStatement(progressStatement);
      return;
    }
  }

  async sendStatement(statement) {
    const tincanQueries = {
      enroll_id: this.queries.enroll_id,
      component_id: this.queries.component_id,
      lup_tincandata_id: this.queries.lup_tincandata_id,
      slt: this.queries.slt,
    };
    const statementEndpoint =
      decodeURIComponent(this.queries.endpoint) +
      'statements?' +
      Object.keys(tincanQueries)
        .map((key) => `${key}=${tincanQueries[key]}`)
        .join('&');
    publicAxios
      .post(`${config.backend}/proxypost`, {
        url: statementEndpoint,
        body: statement,
        headers: { Authorization: this.queries.auth },
      })
      .catch((e) => {
        console.error('e', e);
      });
  }

  async reportFinalAgristo(follow, course) {
    if (follow.tasksRemaining === 0) {
      const scaled = follow.score / (follow.maxScore || 1);
      const minScore = course?.minScore ?? 0.5;
      const passed = scaled >= minScore;

      const result = {
        enrollmentId: parseFloat(this.queries.enroll_id),
        dateCompleted: follow.endDate,
        status: follow?.maxScore ? (passed ? 'passed' : 'failed') : 'completed',
        percentage: status != 'completed' ? Math.round(scaled * 100) : null,
      };
      axios.post(`${config.backend}/xapi/markcomplete`, result).catch((e) => {
        console.error('e', e);
      });
    }
  }

  async reportFinal(follow, course) {
    try {
      if (follow.tasksRemaining === 0) {
        let finalStatement = this.generateTincanStatement(follow, course);
        const scaled = follow.score / (follow.maxScore || 1);
        const minScore = course?.minScore ?? 0.5;
        const score = {
          scaled: scaled,
          raw: Math.round(follow.score),
          min: 0,
          max: Math.round(follow.maxScore),
        };
        const passed = scaled >= minScore;
        finalStatement.verb = {
          id: passed
            ? 'http://adlnet.gov/expapi/verbs/passed'
            : 'http://adlnet.gov/expapi/verbs/failed',
          display: { 'en-US': passed ? 'passed' : 'failed' },
        };
        finalStatement.result = {
          success: passed,
          duration: secondsToIsoDuration(follow.totalTimeSpent),
          score: {
            scaled: scaled,
            raw: Math.round(follow.score),
            min: 0,
            max: Math.round(follow.maxScore),
          },
          completion: true,
        };
        //window.parent.postMessage(JSON.stringify(finalStatement), '*');
        this.sendStatement(finalStatement);
        this.reportedVerbs.add(finalStatement.verb.id);
      }

      if (
        !this.reportedVerbs.has('http://adlnet.gov/expapi/verbs/terminated')
      ) {
        const terminateStatement = this.generateTincanStatement(follow, course);
        terminateStatement.verb = {
          id: 'http://adlnet.gov/expapi/verbs/terminated',
          display: { 'en-US': 'terminated' },
        };
        terminateStatement.result = {
          completion: true,
        };
        //window.parent.postMessage(JSON.stringify(terminateStatement), '*');
        this.sendStatement(terminateStatement);
        this.reportedVerbs.add('http://adlnet.gov/expapi/verbs/terminated');
      }
    } catch (e) {
      console.error(e);
    }
  }

  reportInit(follow, course) {
    if (!this.reportedVerbs.has('http://adlnet.gov/expapi/verbs/attempted')) {
      const initializedStatement = this.generateTincanStatement(follow, course);
      initializedStatement.verb = {
        id: 'http://adlnet.gov/expapi/verbs/attempted',
        display: { 'en-US': 'attempted' },
      };
      initializedStatement.result = {
        duration: secondsToIsoDuration(follow.totalTimeSpent),
      };
      //window.parent.postMessage(JSON.stringify(initializedStatement), '*');
      this.sendStatement(initializedStatement);
      this.reportedVerbs.add('http://adlnet.gov/expapi/verbs/attempted');
    }
  }

  available() {
    return !!this.queries?.tincan;
  }

  async initialize(xapidetails) {
    if (xapidetails) {
      this.queries = xapidetails;
      return;
    }
    this.queries = parseQueryString(document.location.search);
  }
}

export { TinCanService };

import { Navigation } from '@teo/components';
import { useContext, useEffect, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useMobile } from '@/util';
import { useTranslation } from 'react-i18next';
import { NavLink, To } from 'react-router-dom';
import clsx from 'clsx';
import Hint from './Hints/Hints';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { HintsContext } from '@/components/Contexts/HintsContext';

const NewMenuItem = ({ Icon, children, iconOnly, to, end, hint }: any) => {
  const { newPage } = useContext(PageUrlContext);
  const { hints } = useContext(HintsContext);
  const { t } = useTranslation();
  let newTo;
  if (Array.isArray(to)) {
    newTo = to[0];
  } else {
    newTo = to;
  }
  const basePath = window.location.pathname;
  const [isLinkActive, setIsLinkActive] = useState(false);

  useEffect(() => {
    if (to.length > 1) {
      const path = Array.isArray(to)
        ? to.some((path) => basePath.includes(path.slice(1)))
        : basePath.includes(to.slice(1));
      setIsLinkActive(path);
    } else {
      setIsLinkActive(false);
    }
  }, [basePath, to]);

  return (
    <div
      className={`relative ${
        hint && hints?.id == `hint_${hint}` && 'active-hint-parent !z-[100]'
      }`}
    >
      <NavLink
        className={({ isActive }) =>
          `relative flex rounded-lg px-4 py-3 font-medium text-grey-09 hover:bg-grey-01 ${
            isLinkActive ? 'bg-grey-01' : isActive && ' bg-grey-01'
          }`
        }
        to={newTo}
        end={end}
        onClick={() => {
          sessionStorage.removeItem('offsetLoc');
          sessionStorage.removeItem('prevPathname');
        }}
      >
        {({ isActive }) => (
          <>
            {hint && (
              <Hint
                key={`hint_${hint}_${newPage}`}
                id={`hint_${hint}`}
                leftDots={'-left-2'}
                title={t(`hint.title_${hint}`)}
                order={hint}
                style={{
                  left: '110%',
                  top: '-10px',
                }}
              />
            )}
            {!!Icon && (
              <Icon
                isPrimary={isLinkActive ? isLinkActive : isActive}
                className={clsx([
                  'h-6 w-6',
                  iconOnly || 'mr-4',
                  isLinkActive || 'text-grey-04',
                ])}
              />
            )}
            {!iconOnly && children}
          </>
        )}
      </NavLink>
    </div>
  );
};

const SidebarNavigation = ({ items, creatNew }: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const authUser: any = auth?.user;
  const userRole = authUser?.role || 0;
  const role = Math.max(authUser?.activeOrganisationRole || 0, userRole);
  const { collapsed, setCollapsed } = useContext(NavContext);
  const isMobile = useMobile();

  const courseType = authUser?.useCourse;
  const assessmentType = authUser?.useAssessment;
  const vacancyType = authUser?.useLeervacature;
  const instructionType = authUser?.useInstruction;

  return (
    <>
      {isMobile ? (
        <ul>
          {items?.map((el: any, i: any) => {
            if (!courseType && el?.type === 'courses') return null;
            if (!assessmentType && el?.type === 'assessments') return null;
            if (!vacancyType && el?.type === 'vacancies') return null;
            if (!instructionType && el?.type === 'instructions') return null;
            return (
              <li
                key={i}
                onClick={() => {
                  setCollapsed(!collapsed);
                  sessionStorage.removeItem('offsetLoc');
                  sessionStorage.removeItem('prevPathname');
                }}
              >
                <NewMenuItem to={el.to} Icon={el.icon} end={el?.end}>
                  {t(el.text)}
                </NewMenuItem>
              </li>
            );
          })}
        </ul>
      ) : (
        <Navigation>
          {items?.map((el: any, i: any) => {
            if (el.role && role <= 20) return null;
            if (!courseType && el?.type === 'courses') return null;
            if (!assessmentType && el?.type === 'assessments') return null;
            if (!vacancyType && el?.type === 'vacancies') return null;
            if (!instructionType && el?.type === 'instructions') return null;
            return (
              <NewMenuItem
                key={i}
                to={el.to}
                Icon={el.icon}
                end={el?.end}
                hint={el?.hint}
              >
                {t(el.text)}
              </NewMenuItem>
            );
          })}
        </Navigation>
      )}
    </>
  );
};

export default SidebarNavigation;

import { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { useGet } from '@/query/fetchHooks';
import { walkExtra, bfsWalkExtra, walk } from '@/util';
import { useParams } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function generateTree(directories: any, lessonAssets: any) {
  const directoriesById: any = {};
  const directoriesByPath: any = {};

  for (const directory of directories) {
    directoriesById[directory.id] = directory;
    directoriesByPath[directory.path] = directory;
  }

  const lessonById: any = {};
  for (const lesson of lessonAssets) {
    lessonById[lesson.lessonId] = lesson;
  }

  for (const lesson of lessonAssets) {
    if (lesson.lessonId) {
      lesson.type = 'lesson';
    }
    if (lesson.assetId) {
      lesson.type = 'asset';
    }
    lesson.key = lesson.usesId;
    if (lesson.rootDirectoryId) {
      lesson.isExtraInfo = true;
      if (!directoriesById[lesson.rootDirectoryId].extraInfo)
        directoriesById[lesson.rootDirectoryId].extraInfo = [];
      directoriesById[lesson.rootDirectoryId].extraInfo.push(lesson);
    } else if (lesson.rootLessonId) {
      lesson.isExtraInfo = true;
      if (!lessonById[lesson.rootLessonId].extraInfo)
        lessonById[lesson.rootLessonId].extraInfo = [];
      lessonById[lesson.rootLessonId].extraInfo.push(lesson);
    } else {
      let paths = lesson.path.split('/');
      paths = paths.filter((x: any) => x);
      paths.pop();
      const parentPath = '/' + paths.join('/');
      if (!directoriesByPath[parentPath].children)
        directoriesByPath[parentPath].children = [];
      directoriesByPath[parentPath].children.push(lesson);
    }
  }

  for (const directory of directories) {
    directory.type = 'directory';
    directory.key = 'directory_' + directory.id;
    if (directory.path === '/') continue;
    let paths = directory.path.split('/');
    paths = paths.filter((x: any) => x);
    paths.pop();
    const parentPath = '/' + paths.join('/');
    if (!directoriesByPath[parentPath].children)
      directoriesByPath[parentPath].children = [];
    directoriesByPath[parentPath].children.push(directory);
  }

  const prettyTree = Object.assign({}, directoriesByPath['/']);

  bfsWalkExtra(prettyTree, (node: any) => {
    if (node.children) {
      node.children = node.children.sort(
        (a: any, b: any) => a.yindex - b.yindex
      );
    }
    if (node.extraInfo) {
      node.extraInfo = node.extraInfo.sort(
        (a: any, b: any) => a.yindex - b.yindex
      );
    }
  });

  walkExtra(prettyTree, (node: any, i: any, parent: any) => {
    if (parent.depth === undefined) {
      node.depth = 0;
    } else {
      node.depth = parent.depth + 1;
    }
  });

  return prettyTree;
}

function PdfIndex() {
  const { setNavDisabled } = useContext(NavContext);
  const [lessons, setLessons] = useState<any>(null);
  const [directories, setDirectories] = useState<any>(null);
  const [prettyTree, setPrettyTree] = useState<any>(null);
  const { t } = useTranslation();

  const { auth } = useContext(AuthContext);
  const authUser: any = auth?.user;

  const { coursecontentId } = useParams();

  useGet('/usedlessonassets', {
    count: 10000,
    coursecontentId: coursecontentId,
    organisationId: authUser?.activeOrganisationId,
    onSuccess: (result: any) => {
      setLessons(result?.data || null);
    },
  });

  useGet('/directories', {
    count: 10000,
    coursecontentId: coursecontentId,
    organisationId: authUser?.activeOrganisationId,
    onSuccess: (result: any) => {
      setDirectories(result?.data || null);
    },
  });

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  }, []);

  useEffect(() => {
    if (lessons && directories) {
      setPrettyTree(generateTree(directories, lessons));
    }
  }, [lessons, directories]);

  if (!prettyTree) return <></>;

  const index: any[] = [];

  const nodes: any = [];
  walk(prettyTree, (node: any) => {
    nodes.push(node);
  });

  const extraInfoIds = new Set();
  const extraInfos: any[] = [];
  walkExtra(prettyTree, (node: any) => {
    if (node.isExtraInfo && !extraInfoIds.has(node.lessonId || node.assetId)) {
      extraInfoIds.add(node.lessonId || node.assetId);
      extraInfos.push(node);
    }
  });

  if (extraInfos.length > 0) {
    nodes.push(
      {
        name: '',
        key: 'empty',
        type: 'directory',
        depth: 1,
      },
      {
        name: t('extra_info.title'),
        key: 'extra_info',
        type: 'directory',
        depth: 1,
      }
    );
    for (const node of extraInfos) {
      node.depth = 2;
    }
    nodes.push(...extraInfos);
  }

  for (const node of nodes) {
    switch (node.type) {
      case 'directory':
        if (node.depth == 0) continue; //skip root
        index.push(
          <tr key={node.key}>
            <td>
              <span style={{ marginLeft: '' + node.depth * 20 + 'px' }}>
                <b>{node.name}</b>
              </span>
            </td>
          </tr>
        );
        break;
      case 'lesson':
        index.push(
          <tr
            className="Flex "
            key={node.key}
            id={'u' + node.usesId}
            style={{ marginLeft: '' + node.depth * 10 + 'px' }}
          >
            <td>
              <span style={{ marginLeft: '' + node.depth * 20 + 'px' }}>
                {node.name}
              </span>
            </td>
            <td className="flex justify-end">
              <span className="pageNumber"></span>
            </td>
          </tr>
        );
        break;
      case 'asset':
        index.push(
          <tr
            key={node.key}
            style={{ marginLeft: '' + node.depth * 10 + 'px' }}
            id={'u' + node.usesId}
          >
            <td>
              <span style={{ marginLeft: '' + node.depth * 20 + 'px' }}>
                {node.assetName}
              </span>
            </td>
            <td className="flex justify-end">
              <span className="pageNumber"></span>
            </td>
          </tr>
        );
        break;
    }
  }

  return (
    <div className="m-5 flex flex-col">
      <h2 className="mb-4">{i18n.t('pdfexport.index')}</h2>
      <table className="mr-4">{index}</table>
      <div className="page-loaded"></div>
    </div>
  );
}

export default PdfIndex;

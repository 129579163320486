import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithImage } from './TextWithImage';
import { getCharForNumber, v2equal, shuffle, focusWidget } from '@/util';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MagnifyImage } from './MagnifyImage';

const LeftCard = ({ leftItem, answered }) => {
  return (
    <div className="relative flex h-full min-h-16 w-full w-full items-center break-all  rounded-lg bg-white px-2 pt-5 pb-4 text-[#231f20]">
      <div
        style={{
          backgroundColor: `${leftItem?.color}`,
        }}
        className="absolute top-1 left-1 z-10 h-5 w-5 rounded-sm text-center text-sm font-semibold text-white"
      >
        {leftItem?.label}
      </div>
      <TextWithImage
        noMaxHeight
        textstyle={{ textAlign: 'center' }}
        data={leftItem}
      />
      {!answered ? (
        <div className="ml-3">
          <img
            src="/images/icons/dragHandle.svg"
            alt="dragHandle button"
            className="w-3"
          />
        </div>
      ) : null}
    </div>
  );
};

const CustomDragLayer = (props) => {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '30%',
    height: '100%',
  };
  function getItemStyles(initialOffset, currentOffset, isSnapToGrid, item) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    const element = document.getElementById(item?.uid);
    return {
      transform,
      WebkitTransform: transform,
      width: element?.offsetWidth,
    };
  }

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      test: monitor,
    }));

  function renderItem() {
    switch (itemType) {
      case 'label':
        return (
          <div className="block">
            <LeftCard leftItem={item} answered={props?.answered} />
          </div>
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(
          initialOffset,
          currentOffset,
          props.snapToGrid,
          item
        )}
      >
        {renderItem()}
      </div>
    </div>
  );
};

const CustomDragLabel = (props) => {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '30%',
    height: '100%',
  };
  function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      test: monitor,
    }));

  function renderItem() {
    switch (itemType) {
      case 'leftState':
        return (
          <div
            style={{
              backgroundColor: `${item?.item?.color}`,
            }}
            className="h-5 w-5 rounded-sm text-center text-sm font-semibold text-white"
          >
            {item?.item?.label}
          </div>
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}
      >
        {renderItem()}
      </div>
    </div>
  );
};

export const MobileConnectView = ({
  state,
  answered,
  correction,
  pastels,
  setConnections,
  connections,
  uniqueAnswer,
  randomOrder,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  const [leftNodes, setLeftNodes] = useState([]);
  const [rightNodes, setRightNodes] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  let equal = (a, b) => a[0] == b[0] && a[1] == b[1];

  const [leftState, setLeftState] = useState([]);

  useEffect(() => {
    const leftList = answered
      ? state.left
      : randomOrder
      ? shuffle(state.left)
      : state.left;
    setLeftState(
      leftList.map((el, i) => {
        return { ...el, label: getCharForNumber(i), color: pastels[i] };
      })
    );
  }, []);

  useEffect(() => {
    if (!answered) {
      const uniqueUids = connections
        ?.flat()
        ?.filter((value, index, self) => self.indexOf(value) === index);
      setLeftNodes(
        uniqueAnswer && !showAnswers
          ? leftState?.filter((obj) => !uniqueUids?.includes(obj.uid))
          : leftState
      );

      const rightList = randomOrder ? shuffle(state.right) : state.right;
      setRightNodes(
        rightList.map((el, i) => {
          let connect = [];
          const objUid = el.uid;
          const matchingSubarray = connections?.filter((subarray) =>
            subarray.includes(objUid)
          );
          if (matchingSubarray?.length > 0) {
            const secondUid = matchingSubarray?.map((el) =>
              el?.find((uid) => uid !== objUid)
            );
            const matchingObj = secondUid?.map((uid) =>
              leftState?.find((obj) => obj?.uid === uid)
            );
            connect = matchingObj;
          }
          return { ...el, connections: [...connect] };
        })
      );
    } else {
      setLeftNodes(leftState);
    }
  }, [leftState, answered]);

  useEffect(() => {
    answered &&
      setRightNodes(
        state.right.map((el, i) => {
          let anwerConnections = [];
          let correctLabels = [];
          connections.map((connection, i) =>
            connection[0] == el?.uid
              ? leftNodes?.map(
                  (x) =>
                    x.uid == connection[1] && anwerConnections.push({ ...x })
                )
              : connection[1] == el?.uid
              ? leftNodes?.map(
                  (x) =>
                    x.uid == connection[0] && anwerConnections.push({ ...x })
                )
              : ''
          );

          state?.connections?.map((connection, i) =>
            connection[0] == el?.uid
              ? leftNodes?.map(
                  (x) => x.uid == connection[1] && correctLabels.push({ ...x })
                )
              : connection[1] == el?.uid
              ? leftNodes?.map(
                  (x) => x.uid == connection[0] && correctLabels.push({ ...x })
                )
              : ''
          );

          return {
            ...el,
            connections: anwerConnections,
            correctLabels: correctLabels,
          };
        })
      );
  }, [leftNodes]);

  const correctDrop = () => {
    for (let connection of connections) {
      const con0 = document.querySelector(
        `#${CSS.escape(state.uid)} .${CSS.escape(connection[0])}`
      );
      const con1 = document.querySelector(
        `#${CSS.escape(state.uid)} .${CSS.escape(connection[1])}`
      );
      if (state?.connections.find((x) => equal(x, connection))) {
        if (con0?.classList.contains('left-node')) {
          con0?.classList.add('!border-success-04');
        } else {
          con1?.classList.add('!border-success-04');
        }
      } else {
        if (con0?.classList.contains('left-node')) {
          con0?.classList.add('!border-error-04');
          // con0?.classList.remove(
          //   !isMouseDown ? '!border-secondary-04' : '!border-error-04'
          // ),
          //   con0?.classList.add(
          //     isMouseDown ? '!border-secondary-04' : '!border-error-04'
          //   );
        } else {
          con1?.classList.add('!border-error-04');
          // con1?.classList.remove(
          //   !isMouseDown ? '!border-secondary-04' : '!border-error-04'
          // ),
          //   con1?.classList.add(
          //     isMouseDown ? '!border-secondary-04' : '!border-error-04'
          //   );
        }
      }
    }
    let missing = state?.connections?.filter(
      (x) => !connections.find((y) => equal(x, y))
    );
    for (let connection of missing) {
      const con0 = document.querySelector(
        `#${CSS.escape(state.uid)} .${CSS.escape(connection[0])}`
      );
      const con1 = document.querySelector(
        `#${CSS.escape(state.uid)} .${CSS.escape(connection[1])}`
      );
      if (con0?.classList.contains('left-node')) {
        con0?.classList.add('!border-error-04');
        // con0?.classList.remove(
        //   !isMouseDown ? '!border-secondary-04' : '!border-error-04'
        // ),
        //   con0?.classList.add(
        //     isMouseDown ? '!border-secondary-04' : '!border-error-04'
        //   );
      } else {
        con1?.classList.add('!border-error-04');
        // con1?.classList.remove(
        //   !isMouseDown ? '!border-secondary-04' : '!border-error-04'
        // ),
        //   con1?.classList.add(
        //     isMouseDown ? '!border-secondary-04' : '!border-error-04'
        //   );
      }
    }
  };
  useEffect(() => {
    {
      answered && correctDrop();
    }
  }, [answered, leftNodes]);

  const handleMouseDown = () => {
    answered && setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    answered && setIsMouseDown(false);
  };

  const DraggableComponent = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'label',
      item: props?.leftItem,
      canDrag: !answered,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));
    focusWidget(state.uid);
    return (
      <div ref={drag} className={`h-full ${isDragging && 'opacity-5'}`}>
        {props.children}
      </div>
    );
  };

  const DraggableLabelComponent = (props) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'leftState',
      item: { item: props?.labelItem, rightItem: props?.rightItem },
      canDrag: !answered,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        handleTouchUp();
      },
    });
    const handleTouchDown = (e) => {
      if (drag) {
        document
          .querySelector(`#${CSS.escape(state.uid)} #left-drop-section`)
          ?.classList.add('z-20', 'opacity-60');
        for (const box of rightNodes) {
          box?.connections;
          const existingItem = box?.connections?.find(
            (x) => x.uid === props?.labelItem.uid
          );
          if (!existingItem) {
            document
              .querySelector(`#${CSS.escape(state.uid)} #box-${box.uid}`)
              ?.classList.add('z-20', 'opacity-60');
          }
        }
      }
    };

    const handleTouchUp = (e) => {
      if (drag) {
        document
          .querySelector(`#${CSS.escape(state.uid)} #left-drop-section`)
          ?.classList.remove('z-20', 'opacity-60');
        const boxes = document.querySelectorAll(
          `#${CSS.escape(state.uid)} .right-drop-section`
        );
        for (const box of boxes) {
          box.classList.remove('z-20', 'opacity-60');
        }
      }
    };

    return (
      <div
        onTouchStart={handleTouchDown}
        onTouchEnd={handleTouchUp}
        ref={drag}
        className={`h-full ${isDragging && 'opacity-5'}`}
      >
        {props.children}
      </div>
    );
  };

  const Dropzone = (props) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'label',
      drop: (item) => {
        const dropResult = props?.item;
        if (item && dropResult) {
          uniqueAnswer &&
            setLeftNodes((prev) => prev.filter((x, i) => item?.uid !== x.uid));
          setRightNodes((prev) =>
            prev.map((el, i) =>
              el?.uid == dropResult
                ? {
                    ...el,
                    connections: !el.connections.some((x) => x.uid === item.uid)
                      ? [...el.connections, { ...item }]
                      : [...el.connections],
                  }
                : { ...el }
            )
          );
          if (!connections.find((x) => equal(x, [dropResult, item?.uid]))) {
            setConnections((prev) => [...prev, [dropResult, item?.uid]]);
          }
        }
      },
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    });
    return (
      <div
        ref={drop}
        key={isOver}
        className={`absolute inset-0 z-10
          ${
            isOver
              ? 'rounded-md border border-dashed border-grey-05'
              : undefined
          }
        `}
      >
        {props.children}
      </div>
    );
  };

  const LeftDropzone = (props) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'leftState',
      drop: ({ item, rightItem }) => {
        if (item) {
          if (!leftNodes.some((x) => x.uid === item.uid)) {
            setLeftNodes((prev) => [...prev, item]);
          }

          setRightNodes((prev) =>
            prev.map((el, i) =>
              el?.uid == rightItem
                ? {
                    ...el,
                    connections: el.connections.filter(
                      (x) => x?.uid !== item?.uid
                    ),
                  }
                : { ...el }
            )
          );
          connections = connections.filter(
            (x) => !v2equal(x, [rightItem, item?.uid])
          );
          setConnections(connections);
          // setConnections((prev) => [...prev, [dropResult, item?.uid]]);
        }
      },
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    });
    return (
      <div
        ref={drop}
        key={isOver}
        className={`h-full w-full
          ${
            isOver
              ? 'rounded-md border border-dashed border-grey-05'
              : undefined
          }
        `}
      >
        {props.children}
      </div>
    );
  };

  const RightDropzone = (props) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'leftState',
      drop: ({ item, rightItem }) => {
        const dropResult = props?.item;
        if (item) {
          setRightNodes((prev) =>
            prev.map((el, i) =>
              el?.uid == rightItem
                ? {
                    ...el,
                    connections: el.connections.filter(
                      (x) => x?.uid !== item?.uid
                    ),
                  }
                : el?.uid == dropResult
                ? {
                    ...el,
                    connections: [
                      ...el.connections,
                      {
                        ...item,
                      },
                    ],
                  }
                : { ...el }
            )
          );
          connections = connections.filter(
            (x) => !v2equal(x, [rightItem, item?.uid])
          );
          setConnections(connections);
          setConnections((prev) => [...prev, [dropResult, item?.uid]]);
        }
      },
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    });
    return (
      <div
        ref={drop}
        key={isOver}
        className={`h-full w-full
          ${
            isOver
              ? 'rounded-md border border-dashed border-grey-05'
              : undefined
          }
        `}
      >
        {props.children}
      </div>
    );
  };

  return (
    <div>
      {answered ? (
        <>
          {correction?.score < state?.score ? (
            <p className="mb-3 text-xs font-medium text-error-04">
              {t('pages.correction_result.order_widget_hint')}
            </p>
          ) : null}
        </>
      ) : null}
      <DndProvider backend={TouchBackend}>
        <div
          className="relative flex flex-row justify-between gap-6"
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
        >
          <div className="my-auto flex h-full  w-fit min-w-[40%] max-w-full flex-col gap-2">
            {!answered && (
              <div
                id="left-drop-section"
                className="absolute inset-0 -inset-1.5 z-0 h-[102%] w-[37%] rounded-md bg-white opacity-0"
              >
                <LeftDropzone></LeftDropzone>
              </div>
            )}
            {leftNodes?.map((leftItem, i) => (
              <div
                key={leftItem.uid}
                id={leftItem.uid}
                className="left-node rounded-lg rounded-lg border bg-grey-02"
              >
                {answered ? (
                  <LeftCard leftItem={leftItem} answered={answered} />
                ) : (
                  <DraggableComponent itemIndex={i} leftItem={leftItem}>
                    <LeftCard leftItem={leftItem} answered={answered} />
                  </DraggableComponent>
                )}
              </div>
            ))}
            <CustomDragLayer answered={answered} />
          </div>

          {/*<div className="grid-col grid min-w-[35%] max-w-[35%] gap-2">*/}
          <div className="my-auto flex h-full w-fit min-w-[30%] max-w-full flex-col gap-2">
            {rightNodes?.map((rightItem, i) => (
              <div
                key={rightItem.uid}
                id={rightItem.uid}
                className={`right-node relative flex min-h-16 w-full items-center break-all rounded-lg border bg-white p-2 text-[#231f20] ${
                  isMouseDown && '!border-secondary-04'
                }`}
              >
                <Dropzone item={rightItem.uid}>
                  {(rightItem?.connections?.length > 0 || answered) && (
                    <div className="flex justify-end gap-2 p-3">
                      {!isMouseDown
                        ? rightItem?.connections.map((el, i) => {
                            return (
                              <DraggableLabelComponent
                                key={`${rightItem.uid}_${i}`}
                                labelItem={el}
                                rightItem={rightItem.uid}
                              >
                                <div
                                  style={{
                                    backgroundColor: `${el?.color}`,
                                  }}
                                  className="h-5 w-5 rounded-sm text-center text-sm font-semibold text-white"
                                >
                                  {el?.label}
                                </div>
                              </DraggableLabelComponent>
                            );
                          })
                        : rightItem?.correctLabels.map((el, i) => {
                            return (
                              <div
                                key={`${rightItem.uid}_${i}`}
                                style={{
                                  backgroundColor: `${el?.color}`,
                                }}
                                className="h-5 w-5 rounded-sm pl-0.5 text-center text-sm font-semibold text-white"
                              >
                                {el?.label}
                              </div>
                            );
                          })}
                    </div>
                  )}
                </Dropzone>
                {!answered ? (
                  <div
                    id={`box-${rightItem.uid}`}
                    className="right-drop-section opacity-1 inset-0 z-0 h-full w-full rounded-md bg-white"
                  >
                    <RightDropzone item={rightItem.uid}>
                      <div className="flex h-full w-full flex-col">
                        {rightItem.file ? (
                          <div className=" h-full">
                            <MagnifyImage
                              showIcon={true}
                              className="m-auto h-full max-w-[100%] rounded object-cover object-center"
                              src={rightItem.file}
                              draggable={false}
                            />
                            {/* <img
                        alt={rightItem?.text}
                        src={rightItem.file}
                        className="m-auto h-full max-w-[100%] rounded object-cover object-center"
                      /> */}
                          </div>
                        ) : null}
                        {rightItem?.text ? (
                          <p className="text-xs text-grey-09">
                            {rightItem?.text}
                          </p>
                        ) : null}
                      </div>
                    </RightDropzone>
                  </div>
                ) : (
                  <div className="flex h-full w-full flex-col">
                    {rightItem.file ? (
                      <div className=" h-full">
                        <MagnifyImage
                          showIcon={true}
                          className="m-auto h-full max-w-[100%] rounded object-cover object-center"
                          src={rightItem.file}
                          draggable={false}
                        />
                      </div>
                    ) : null}
                    {rightItem?.text ? (
                      <p className="text-xs text-grey-09">{rightItem?.text}</p>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
            {!answered && <CustomDragLabel answered={answered} />}
          </div>
        </div>
      </DndProvider>
    </div>
  );
};

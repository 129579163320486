import React, { useState, useEffect } from 'react';
import { encode } from '@/base64';
import { newUid, deep_copy, getCharForNumber } from '@/util';
import { useTranslation } from 'react-i18next';
import { uploadImage } from '@/query/documents';
import { Button, IconButton, InputText, InputTextarea } from '@teo/components';
import { Plus, Cross } from '@teo/components/icons';
import UploadDropzoneImage from '@/components/UploadDropzoneImage/UploadDropzoneImage';
import { useDrop, useDrag } from 'react-dnd';
import clsx from 'clsx';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';

export const initialImageShowParts = () => ({
  noSubmit: true,
  title: '',
  image: null,
  labels: [{ uid: newUid(20), x: 0.1, y: 0.1, tx: 0, ty: 0, text: '' }],
  type: 'ImageShowParts',
});

export const pastels = [
  '#C25FF4',
  '#E3BF00',
  '#6EC6C0',
  '#F25850',
  '#29BAFF',
  '#AE7226',
  '#1173DD',
  '#9AD055',
  '#807EFF',
  '#FF8EA4',
];

const ExtraInfo = (props) => {
  const { index, labels, setLabels, text } = props;

  const [extraInfo, setExtraInfo] = useState(text);
  return (
    <div className="mb-3 flex flex-row items-center">
      <div
        className="mr-2 rounded-md px-2.5 pb-1 pt-0.5 text-white"
        style={{
          backgroundColor: `${pastels[index]}`,
        }}
      >
        {getCharForNumber(index)}
      </div>
      <div className="w-full">
        <textarea
          className="h-auto max-h-[65px] max-h-[65px] w-full resize-none rounded-md border border-solid border-transparent px-3 py-1.5 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02"
          id="labelContainer"
          onKeyUp={(e) => {
            if (e.key == ' ' || e.code == 'Enter' || e.keyCode == 13) {
              e.stopPropagation();
            }
          }}
          onKeyDown={(e) => {
            if (e.key == ' ' || e.code == 'Enter' || e.keyCode == 13) {
              e.stopPropagation();
            }
          }}
          onChange={({ target: { value } }) => {
            setExtraInfo(value);
          }}
          onBlur={(ev) => {
            let newLabels = deep_copy(labels);
            newLabels[index].text = ev.target.value;
            setLabels(newLabels);
          }}
          defaultValue={extraInfo}
        ></textarea>
        {/* <InputTextarea
          customStyles="max-h-[65px]"
          style={{
            border: 'none',
            backgroundColor: '#f8f8f9',
          }}
          id="labelContainer"
          onKeyUp={(e) => {
            if (e.key == ' ' || e.code == 'Enter' || e.keyCode == 13) {
              e.stopPropagation();
            }
          }}
          onKeyDown={(e) => {
            if (e.key == ' ' || e.code == 'Enter' || e.keyCode == 13) {
              e.stopPropagation();
            }
          }}
          onChange={({ target: { value } }) => {
            setExtraInfo(value);
          }}
          onBlur={(ev) => {
            let newLabels = deep_copy(labels);
            newLabels[index].text = ev.target.value;
            setLabels(newLabels);
          }}
          value={extraInfo}
        /> */}
      </div>
    </div>
  );
};

export const ImageShowPartsEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(state.title);
  const [labels, setLabels] = useState(state.labels);
  const [newLabelText, setNewLabelText] = useState();
  const [img, setImg] = useState(state.image);
  const [dragging, setDragging] = useState(false);

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );

  const uploadInputRef = React.createRef();

  useEffect(() => {
    let texts = {};
    state?.labels?.forEach((x) => {
      texts[x.uid] = x.text;
    });
    labels.forEach((x) => {
      setNewLabelText((prev) => ({ ...prev, [x.uid]: x.text }));
    });
  }, [labels]);

  useEffect(() => {
    onLoadImg();
  }, [labels]);

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    newState.labels = deep_copy(labels);
    newState.labels.forEach((x, i) => {
      x.text = newLabelText[x.uid] || labels[i].text;
    });
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.title = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });
    newState.image = img;
    return newState;
  };
  setStateFn && setStateFn(getState);

  // useEffect(() => {
  //   onModified && onModified();
  // }, [onModified, modified]);

  const onLoadImg = () => {
    const canvas = document.getElementById(state.uid + '_canvas');
    const img = document.getElementById(state.uid + '_img');

    if (!img || !canvas) return;

    canvas.width = img.width * 5;
    canvas.height = img.height * 5;

    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 20;
    labels.forEach((x, index) => {
      //const angle = Math.atan2(x.y - x.ty, x.x - x.tx); //angle it approaches the label

      const label = document.getElementById(`${state.uid}_label_${index}`);
      let bbox = label.getBoundingClientRect();
      const endY = (x.y + bbox.height / 2 / img.height) * canvas.height;
      //const endX = (x.x + (bbox.width / 2) / img.width) * canvas.width;
      const endX = (x.x + 15 / img.width) * canvas.width;
      ctx.beginPath();
      const startX = x.tx * canvas.width;
      const startY = x.ty * canvas.height;
      //const endX *= canvas.width;
      //const endY *= canvas.height;
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = pastels[index % pastels.length];
      ctx.stroke();
      ctx.closePath();
    });
  };

  const DropZone = () => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ['label', 'dot'],
      drop: (item, monitor) => {
        const type = monitor.getItemType();
        if (type === 'label') {
          const delta = monitor.getDifferenceFromInitialOffset();
          let newLabels = deep_copy(labels);
          const img = document.getElementById(state.uid + '_img');
          newLabels[item.index].y = item.y + delta.y / img.height;
          newLabels[item.index].x = item.x + delta.x / img.width;
          setLabels(newLabels);
        } else if (type === 'dot') {
          const delta = monitor.getDifferenceFromInitialOffset();
          let newLabels = deep_copy(labels);
          const img = document.getElementById(state.uid + '_img');
          newLabels[item.index].ty = item.ty + delta.y / img.height;
          newLabels[item.index].tx = item.tx + delta.x / img.width;
          setLabels(newLabels);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }));

    return (
      <div
        className="absolute h-full w-full"
        style={{
          pointerEvents: dragging ? 'auto' : 'none',
          top: 0,
          left: 0,
        }}
        ref={drop}
      ></div>
    );
  };

  const Label = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'label',
      item: props,
      canDrag: true,
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        };
      },
      end: () => {
        setDragging(false);
      },
    }));

    if (isDragging) setDragging('label_' + props.uid);
    const opacity = isDragging || 'label_' + props.uid === dragging ? 0.4 : 1;
    return (
      <div
        ref={drag}
        key={`${state.uid}_isDragging`}
        id={`${state.uid}_label_${props.index}`}
        className={clsx(
          'fr-draggable absolute flex items-center bg-grey-01 p-1'
        )}
        style={{
          top: props.y * 100 + '%',
          left: props.x * 100 + '%',
          opacity: opacity,
          pointerEvents: dragging ? 'none' : 'all',
          borderRadius: '4px',
        }}
      >
        <img
          src={`/images/icons/drug_icon.svg`}
          className="ml-1 mr-1 h-[14px] w-[14px] bg-grey-01 text-grey-01"
          alt="icon"
          style={{ pointerEvents: 'none' }}
        />
        <div
          className="rounded-md px-2.5 pb-1 pt-0.5 text-white"
          style={{
            backgroundColor: `${pastels[props.index]}`,
          }}
        >
          {getCharForNumber(props.index)}
        </div>
        {/* <InputText
          style={{
            border: 'none',
            backgroundColor: '#f8f8f9',
          }}
          id="labelContainer"
          onChange={({ target: { value } }) => {
            texts[props.uid] = value;
          }}
          onBlur={(ev) => {
            let newLabels = deep_copy(labels);
            newLabels[props.index].text = ev.target.value;
            setLabels(newLabels);
          }}
          defaultValue={props.text}
        /> */}
        <IconButton
          Icon={Cross}
          ariaLabel="delete order"
          onClick={() => {
            let newLabels = deep_copy(labels);
            newLabels.splice(props.index, 1);
            setLabels(newLabels);
          }}
          variant="ghost"
          className="text-#1e1e2052 bg-grey-01"
          style={{ color: '#1e1e2052' }}
          size="sm"
        />
      </div>
    );
  };

  const Dot = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'dot',
      item: props,
      canDrag: true,
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        };
      },
      end: () => {
        setDragging(false);
      },
    }));

    if (isDragging) setDragging('dot_' + props.uid);
    const opacity = isDragging || 'dot_' + props.uid === dragging ? 0.4 : 1;
    return (
      <span
        ref={drag}
        className="fr-draggable absolute h-3.5 w-3.5 cursor-move rounded-full border-4"
        style={{
          transform: 'translate(-50%, -50%)',
          top: props.ty * 100 + '%',
          left: props.tx * 100 + '%',
          opacity: opacity,
          pointerEvents: dragging ? 'none' : 'all',
          borderColor: `${pastels[props.index % pastels.length]}`,
        }}
      ></span>
    );
  };

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-2 lg:p-2`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <div className="wg-question">
        <QuestionSection questionVraag={title} title={'test'} />
      </div>
      {!img ? (
        <UploadDropzoneImage
          setUploadImg={(url) => {
            setImg(url);
          }}
        />
      ) : (
        <>
          <div
            className="relative h-full w-full rounded-md"
            contentEditable="false"
          >
            <img
              src={img}
              id={state.uid + '_img'}
              onLoad={onLoadImg}
              className="pointer-events-none w-full !cursor-default rounded-md"
              draggable="false"
            />
            <canvas
              id={state.uid + '_canvas'}
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              className="pointer-events-none absolute"
            ></canvas>
            <div
              className="absolute rounded-full bg-white"
              style={{
                backgroundColor: 'white',
                color: '#fdbe06',
                bottom: '10px',
                right: '10px',
              }}
            >
              <IconButton
                Icon={Plus}
                variant="secondary"
                size="lg"
                className="!rounded-full bg-white !p-5 text-primary-05 shadow-[0_0_12px_-6px_black]"
                onClick={() => {
                  let newLabels = deep_copy(labels);
                  newLabels.push({
                    uid: newUid(20),
                    x: 0.1,
                    y: 0.1,
                    tx: 0,
                    ty: 0,
                    text: '',
                  });
                  setLabels(newLabels);
                }}
              />
            </div>
            <DropZone />
            {labels.map((x, index) => (
              <>
                <Label
                  key={'label_' + state.uid + x.index}
                  {...x}
                  index={index}
                ></Label>
                <Dot
                  key={'dot_' + state.uid + x.index}
                  {...x}
                  index={index}
                ></Dot>
              </>
            ))}
          </div>
          <input
            id={state.uid + '_imginput'}
            ref={uploadInputRef}
            style={{ display: 'none' }}
            type="file"
            onChange={async (ev) => {
              const img = ev.target.files[0];
              const data = await uploadImage(img, 1200, 1000, 'jpeg');
              setImg(data);
            }}
          />
          <div>
            {labels?.map((x, index) => (
              <ExtraInfo
                key={'label_text_' + state.uid + index}
                {...x}
                index={index}
                setLabels={setLabels}
                labels={labels}
              />
            ))}
          </div>

          <div className=" flex flex-row items-center border-b border-grey-02 pb-4">
            <Button
              variant="outline"
              onClick={() => {
                uploadInputRef.current.click();
              }}
            >
              Replace image
            </Button>
          </div>
        </>
      )}
      <div data-closed="EOW"></div>
    </div>
  );
};

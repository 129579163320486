import { decode } from '@/base64';
import { MultipleChoiceView } from './MultipleChoiceView';
import { OpenQuestionView } from './OpenQuestionView';
import { NamePartsView } from './NamePartsView';
import { MatrixView } from './MatrixView';
import { OrderView } from './OrderView';
import { ConnectView } from './ConnectView';
import { MathView } from './MathView';
import { UploadView } from './UploadView';
import { InvulVraagView } from './InvulVraagView';
import { BoxQuestionView } from './BoxQuestionView';
import { BoxQuestionViewOld } from './BoxQuestionViewOld';
import { ImageShowPartsView } from './ImageShowPartsView';
import { BoxErrorView } from './BoxErrorView';
import { isString, orderWidgets } from '@/util';
import i18n from 'i18next';

import { Subject, BehaviorSubject, skip } from 'rxjs';
import React from 'react';

import ReactDOM from 'react-dom';

export function getView(state) {
  let View;
  switch (state.type) {
    case 'InvulVraag':
      View = InvulVraagView;
      break;
    case 'MeerKeuze':
      View = MultipleChoiceView;
      break;
    case 'OpenVraag':
      View = OpenQuestionView;
      break;
    case 'MatrixVraag2':
      View = MatrixView;
      break;
    case 'OrderVraag':
      View = OrderView;
      break;
    case 'Link':
      View = ConnectView;
      break;
    case 'NameImage':
    case 'NameImage2':
      View = NamePartsView;
      break;
    case 'Math':
      View = MathView;
      break;
    case 'FileUpload':
      View = UploadView;
      break;
    case 'KofferVraag2':
      View = BoxQuestionView;
      break;
    case 'KofferVraag':
      View = BoxQuestionViewOld;
      break;
    case 'ImageShowParts':
      View = ImageShowPartsView;
      break;
    case 'BoxError':
      View = BoxErrorView;
      break;
    /*
    case 'InvulVraag':
      View = InvulVraagView; break;
    case 'OrderVraag':
      View = OrderVraagView; break;
    case 'Link':
      View = LinkView; break;
    case 'MatrixVraag':
      View = MatrixVraagView; break;
    case 'MatrixVraag2':
      View = MatrixVraagView2; break;
    case 'NameImage':
      View = NameImageView; break;
    case 'NameImage2':
      View = NameImageView2; break;
    case 'Math':
      View = MathView; break;
    case 'KofferVraag':
      View = KofferVraagView; break;
    case 'KofferVraag2':
      View = KofferVraagView2; break;
    case '3D':
      View = D3View; break;
    case 'FileUpload':
      View = FileUploadView; break;
    default:
      console.error("Unknown type: ", props.state)
      return;
    */
  }
  return View;
}

export function renderWidget(
  state,
  target,
  i = 0,
  correction = null,
  { isCorrected = false, showAnswers = false, correctionType = 'automatic' }
) {
  let View = getView(state);
  let placeholder = document.getElementById(state.uid);
  if (!placeholder) {
    console.error('Unable to render', placeholder, 'placeholder not found');
    return;
  }
  placeholder.innerHTML = '';

  if (!View) {
    console.error('Error: rendering', state);
    return null;
  }

  let widget = {
    onModified: new Subject(),
    onSave: new Subject(),
    state: state,
  };

  let widgetCorrection = correction?.[state.uid];
  if (!widgetCorrection && correction) {
    const availableCorrections = Object.values(correction);
    widgetCorrection = availableCorrections.find((corr) => {
      return (
        corr?.state?.vraag === state?.vraag && corr?.state?.type === state?.type
      );
    });
  }

  let antwoord = null;
  if (state?.antwoord && isString(state?.antwoord)) {
    antwoord = state?.antwoord?.replace?.(/ contenteditable="true"/g, '');
  }

  setTimeout(() => {
    orderWidgets();
  }, 0);

  widget.portal = ReactDOM.createPortal(
    <>
      <div
        className={`${
          state?.type != 'Math' && state?.type != 'ImageShowParts'
            ? 'widget_practical '
            : ''
        }`}
      >
        <View
          correctionType={correctionType}
          setAnswerFn={(fn) => (widget.answerFn = fn)}
          setCorrectionFn={(fn) => (widget.correctionFn = fn)}
          setXApiSubmitFn={(fn) => (widget.xApiSubmitFn = fn)}
          onModified={() => widget.onModified.next()}
          onSave={(submit = false, force = false, triggerWidget = undefined) =>
            widget.onSave.next({ submit, force, triggerWidget })
          }
          state={state}
          index={i}
          answer={widgetCorrection?.answer}
          correction={widgetCorrection?.correction}
          isCorrected={isCorrected}
          showAnswers={showAnswers}
          widgetAnswered={widgetCorrection?.answered}
        />
      </div>

      {/*
      {isCorrected &&
        state?.showAnswer &&
        antwoord &&
        state?.type !== 'KofferVraag' &&
        state?.type !== 'KofferVraag2' &&
        state?.type !== 'OpenVraag' && (
          <div className="mt-[-1rem] mb-3 rounded-lg bg-[#f8f8f9] p-4">
            <p className="text-sm font-semibold text-black">
              {i18n.t('widgets.type.explanation')}:
            </p>

            <div
              className="text-sm font-normal text-grey-05"
              dangerouslySetInnerHTML={{
                __html: antwoord,
              }}
            ></div>
          </div>
        )}
      */}
      {isCorrected && correction?.[state.uid]?.correction?.feedback && (
        <div className="mt-[-1rem] rounded-lg bg-[#f8f8f9] py-2 px-3">
          <p className="text-sm font-semibold text-black">
            {i18n.t('widgets.type.feedback')}
          </p>
          <div
            className={`border-transparen rounded-md border border-solid bg-grey-transparent-01 p-6 text-sm text-grey-08`}
          >
            <div
              className="text-sm font-normal text-[#4d4d50]"
              dangerouslySetInnerHTML={{
                __html: correction?.[state.uid]?.correction?.feedback,
              }}
            ></div>
          </div>
        </div>
      )}
    </>,
    placeholder
  );

  return widget;
}

export function renderWidgets(parent, correction, props) {
  const widgets = document.getElementsByClassName('widget-placeholder');
  return [...widgets].reduce((acc, widget, i) => {
    if (widget.state) return acc; //renderWidget(widget.state, widget, i + 1);
    let node = widget.querySelectorAll('div[data-state],span[data-state]');
    if (node.length === 0) return acc;
    let stateStr = node[0].getAttribute('data-state');
    try {
      widget.state = JSON.parse(stateStr); //legacy parsing
    } catch (e) {
      widget.state = JSON.parse(decode(stateStr)); //base64 parsing
    }
    acc[widget.state.uid] = renderWidget(
      widget.state,
      widget,
      i + 1,
      correction,
      props
    );
    return acc;
  }, {});
}

export function syncStates(state, answer, correction) {
  let View = getView(state);
  return View.syncStates(state, answer, correction);
}

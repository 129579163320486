import { useContext } from 'react';
import i18n from 'i18next';

const T = (props) => {
  if (Array.isArray(props.children))
    return props.children.map((x) => i18n.t(x));
  else return i18n.t(props.children);
};

export { T };

import { TwitterPicker } from 'react-color';
import { useState } from 'react';
import { Button, IconButton, Modal } from '@teo/components';
import { ChevronDown, ChevronUp } from '@teo/components/icons';

function ColorPicker({ colorPicker, setColorPicker }: any) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div>
      <Button
        variant="ghost"
        onClick={handleClick}
        className="flex items-center pl-0"
        data-testId="handleClickBtn"
      >
        <div
          style={{
            backgroundColor: `${colorPicker}`,
            width: 64,
            height: 64,
            border: '2px solid white',
            position: 'relative',
            zIndex: '2',
            borderRadius: '50%',
          }}
        ></div>

        <IconButton
          Icon={displayColorPicker ? ChevronUp : ChevronDown}
          variant="ghost"
        />
      </Button>

      {displayColorPicker && (
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <TwitterPicker
            color={colorPicker}
            onChange={(color: any) => {
              setColorPicker(color.hex);
            }}
            triangle="hide"
          />
        </div>
      )}
    </div>
  );
}

export default ColorPicker;

import { useState, useEffect, useRef } from 'react';
import { decode } from '@/base64';
import { renderEditor } from '../widgets/renderEditor';
import { isEqual } from '@/util';

const TextItemEdit = ({ id, text, handleChangeText }) => {
  const questionRef = useRef(null);

  const [portState, setPortState] = useState([]);
  const [newText, setNewText] = useState(null);
  const [saveNewText, setSaveNewText] = useState(false);

  const onFocusSection = () => {
    var node = questionRef.current;
    const spanElement = node?.previousElementSibling;
    if (spanElement && spanElement.tagName === 'SPAN') {
      if (spanElement.classList.contains('block')) {
        spanElement.classList.remove('block');
        spanElement.classList.add('hidden');
      }
    }
    node.setAttribute('contenteditable', 'true');
    const pElements = node.querySelectorAll('p');

    const children = node.childNodes;
    let hasNonParagraphElement = false;
    let hasTextContent = false;
    children.forEach((child) => {
      if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== 'P') {
        hasNonParagraphElement = true;
      }
      if (child.nodeType === Node.TEXT_NODE && child.nodeValue.trim() !== '') {
        hasTextContent = true;
      }
    });
    if (hasNonParagraphElement || hasTextContent) {
      const pElement = document.createElement('p');
      pElement.innerHTML = node.innerHTML;
      node.innerHTML = '';
      node.appendChild(pElement);
    }
    setTimeout(() => {
      pElements.forEach((child) => {
        child.removeAttribute('contenteditable');
      });
    }, 0);
  };

  const onBlurSection = () => {
    var node = questionRef.current;
    const imgElements = node.querySelectorAll('img');
    const formulElements = node.querySelectorAll('._katex');

    if (!node?.textContent && !imgElements?.length && !formulElements?.length) {
      const spanElement = node?.previousElementSibling;
      if (spanElement && spanElement.tagName === 'SPAN') {
        if (spanElement.classList.contains('hidden')) {
          spanElement.classList.remove('hidden');
          spanElement.classList.add('block');
        }
      }
    }
    if (node) {
      setSaveNewText(true);
    }
  };

  const restoreSelection = (range) => {
    if (range) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleDivClick = (event) => {
    const divElement = questionRef.current;
    if (divElement && divElement.getAttribute('contentEditable') !== 'true') {
      const firstPElement = divElement.querySelector('p');
      if (firstPElement) {
        firstPElement.click();
        const range = document.createRange();
        range.setStart(firstPElement, 0);
        range.setEnd(firstPElement, 0);
        restoreSelection(range);
      }
    }
  };

  useEffect(() => {
    if (questionRef.current) {
      var node = questionRef.current;
      const chat = node?.querySelectorAll('.remove_me_gEE8QIPZ6LEay22SdSCU');
      chat.forEach((child) => {
        child.remove();
      });

      const imgElements = node.querySelectorAll('img');
      const formulElements = node.querySelectorAll('._katex');
      if (
        !node.textContent &&
        !imgElements?.length &&
        !formulElements?.length
      ) {
        const spanElement = node?.previousElementSibling;
        if (spanElement && spanElement.tagName === 'SPAN') {
          if (spanElement.classList.contains('hidden')) {
            spanElement.classList.remove('hidden');
            spanElement.classList.add('block');
          }
        }
        node.removeAttribute('contenteditable');
        node.innerHTML = '';
        const pElement = document.createElement('p');
        pElement.setAttribute('contenteditable', 'true');
        node.appendChild(pElement);
      } else {
        node.setAttribute('contenteditable', 'true');
      }
    }
    const widgets =
      questionRef.current.getElementsByClassName('widget-placeholder');

    const insideWidget = [];
    [...widgets]?.reduce((acc, widget, i) => {
      if (widget.state) return acc; //renderWidget(widget.state, widget, i + 1);
      let node = widget.querySelectorAll('div[data-state],span[data-state]');
      if (node.length === 0) return acc;
      let stateStr = node[0].getAttribute('data-state');
      try {
        widget.state = JSON.parse(stateStr); //legacy parsing
      } catch (e) {
        widget.state = JSON.parse(decode(stateStr)); //base64 parsing
      }

      insideWidget.push(renderEditor(widget.state, widget));
    }, {});
    setPortState(insideWidget);
  }, [questionRef]);

  const handleSaveNewText = () => {
    var node = questionRef.current;
    if (node && saveNewText && node?.textContent) {
      handleChangeText(node.innerHTML);
      setSaveNewText(false);
    }
  };

  const onKeyPressed = (ev) => {
    let scrollPosition = window.scrollY;
    var ctrlKey =
      navigator.userAgent.indexOf('Mac OS X') !== -1
        ? ev.metaKey
          ? ev.metaKey
          : ev.ctrlKey
        : ev.ctrlKey;
    if (ctrlKey) {
      switch (ev.key) {
        case 'z':
          setTimeout(() => {
            window.scrollTo({ top: scrollPosition });
          }, 0);

          break;
      }
    }
  };

  return (
    <>
      {portState.map((state) => state?.portal)}
      <div onBlur={() => handleSaveNewText()}>
        <div
          id={id}
          onFocus={onFocusSection}
          onBlur={onBlurSection}
          onClick={handleDivClick}
          onKeyDown={(e) => onKeyPressed(e)}
          ref={questionRef}
          className="question_optional fr-view min-h-7 cursor-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </>
  );
};

export { TextItemEdit };

import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { IconButton } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useParams } from 'react-router-dom';
import { newUid } from '@/util';
import EditCompetencyProfileStepsContainer from '@/pages/Protected/Manage/EditCompetencyProfilePage/EditCompetencyProfileStepsContainer';
import { useGet } from '@/query';
import { axios } from '@/axios';
import { config } from '@/config';
import { sectorsData } from '@/mocks/competencesOptionsData';

const EditCompetencyProfilePage = ({
  setIsOpenEdit,
  setActiveProfile,
  activeProfile,
  categories,
  competencyType,
  setRefetchProfile,
}: any) => {
  const { t } = useTranslation();
  const { organisationId } = useParams();

  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [competence, setCompetence] = useState('');
  const [sectors, setSectors] = useState<any>(null);
  const [addCompetence, setAddCompetence] = useState<any>(null);
  const [filter, setFilter] = useState('');
  const [allCategories, setAllCategories] = useState();
  const [allModules, setAllModules] = useState();
  const [allTypes, setAllTypes] = useState();
  const [selectedRow, setSelectedRow] = useState<Set<string | number>>(
    new Set<string | number>(
      activeProfile?.qualifications?.length > 0
        ? activeProfile?.qualifications
        : []
    )
  );
  const [deselect, setDeselect] = useState<boolean>(false);
  const [professions, setProfessions] = useState<any>(null);

  useEffect(() => {
    if (activeProfile) {
      setCompetence(activeProfile?.name ? activeProfile?.name : '');
      const sector = sectorsData?.find(
        (el) => el.id == activeProfile?.categoryId
      );
      if (sector) {
        setSectors(sector);
      }
    }
  }, [activeProfile]);

  useGet(`/profession/${activeProfile?.id}`, {
    onSuccess: (result: any) => {
      setProfessions(result?.data || {});
    },
  });

  const handleEditCompetencies = () => {
    axios
      .put(`${config.backend}/profession/${activeProfile.id}`, {
        organisationId: organisationId,
        name: competence ? competence : professions.name,
        // sectors: sectors ? sectors : professions.sectors,
        qualifications: [...selectedRow],
        categoryId: sectors?.id ? Number(sectors?.id) : null,
      })
      .then(() => {
        setRefetchProfile(newUid(20));
        setIsOpenEdit(false);
        setActiveProfile(null);
      });
  };

  if (!professions) return null;

  return (
    <div
      style={{ zIndex: 102 }}
      className="fixed top-0 left-0 z-10 h-screen w-screen overflow-y-auto bg-white"
    >
      <div className="flex min-h-16 items-center justify-start ">
        <IconButton
          className="my-auto mx-6"
          Icon={CrossFilled}
          variant="ghost"
          onClick={() => {
            setIsOpenEdit(false);
            setActiveProfile(null);
          }}
        />
        <div className="pl-2 font-semibold">
          {t('pages.manage_competencies.edit_title')}
        </div>
      </div>

      <EditCompetencyProfileStepsContainer
        competence={competence}
        setCompetence={setCompetence}
        sectors={sectors}
        setSectors={setSectors}
        addCompetence={addCompetence}
        setAddCompetence={setAddCompetence}
        filter={filter}
        setFilter={setFilter}
        allCategories={allCategories}
        setAllCategories={setAllCategories}
        allModules={allModules}
        setAllModules={setAllModules}
        allTypes={allTypes}
        setAllTypes={setAllTypes}
        refetchTable={refetchTable}
        setRefetchTable={setRefetchTable}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleEditCompetencies={handleEditCompetencies}
        professions={professions}
        setProfessions={setProfessions}
        professionId={activeProfile.id}
        deselect={deselect}
        setDeselect={setDeselect}
        categories={categories}
        competencyType={competencyType}
      />
    </div>
  );
};

export default EditCompetencyProfilePage;

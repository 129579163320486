import { useState, useEffect, useReducer, useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  InputDate,
  Label,
  InputText,
  RadioGroup,
  InputSelect,
} from '@teo/components';
import { round2, parseCourseType } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import LibraryAddOrganizations from '@/pages/Protected/Create/PublishCourse/LibraryAddOrganizations';
import { courseTypeOptions } from '@/models/courseTypeOptions';

const backgroundColor = '#EAEAED';
const blurColor = '#C2CDFE';
const focusColor = '#7089FD';

const SharingSettingsStep = forwardRef(
  (
    {
      course,
      state,
      setLoadStep,
      publicationType,
      courseTypeNoInstruction,
    }: any,
    ref
  ) => {
    const { t } = useTranslation();

    const {
      correctionType,
      setCorrectionType,
      emailCertificate,
      setEmailCertificate,
      courseAccess,
      setCourseAccess,
      courseEndDate,
      setCourseEndDate,
      inOrder,
      setInOrder,
      isMandatory,
      setIsMandatory,
      minScore,
      setMinScore,
      courseStartDate,
      setCourseStartDate,
      setPrevContentOrganisation,
      prevContentOrganisation,
      orgState,
      setOrgState,
      setCourseType,
      courseType,
    } = state;

    const { auth } = useContext(AuthContext);
    const authUser = auth?.user as Userwithorganisationwithidps;
    const userRole = auth?.user?.role || 0;

    const [trackColor, setTrackColor] = useState(blurColor);

    const percentageMinScore = round2(minScore ? minScore * 100 : 0.5 * 100);

    const initialState = orgState?.library
      ? orgState?.library
      : {
          organizations: [],
        };

    const [stateOrg, updateStateOrg] = useReducer(
      (stateOrg: any, updates: any) => ({ ...stateOrg, ...updates }),
      initialState
    );
    useEffect(() => {
      setOrgState((prev: any) => ({
        library: stateOrg,
      }));
    }, [stateOrg]);

    useEffect(() => {
      setTimeout(() => {
        setLoadStep(false);
      }, 100);
    }, []);

    const isScorm = course?.publicationType?.startsWith?.('scorm') || false;

    const typeCourse = authUser?.useCourse;
    const typeAssessment = authUser?.useAssessment;
    const typeVacancy = authUser?.useLeervacature;
    const typeInstruction = authUser?.useInstruction;

    let selectType = courseTypeOptions;

    if (!typeCourse) {
      selectType = selectType?.filter((el: any) => el?.id !== 1);
    }
    if (!typeAssessment) {
      selectType = selectType?.filter((el: any) => el?.id !== 2);
    }
    if (!typeVacancy) {
      selectType = selectType?.filter((el: any) => el?.id !== 3);
    }
    if (!typeInstruction) {
      selectType = selectType?.filter((el: any) => el?.id !== 4);
    }

    return (
      <>
        <article className="prose flex flex-col ">
          <h3 className="text-base">
            {t('pages.course_content_flow.step_3.title')}
          </h3>
        </article>
        {courseTypeNoInstruction && !isScorm && (
          <>
            <div className="mt-7 flex flex-col gap-5 pr-2 sm:flex-row lg:pr-0">
              <div className="basis-1/2" data-testid="dateStart">
                <Label htmlFor="dateStart">
                  {t('pages.course_content_flow.step_3.start_day')}{' '}
                  <span className="font-normal text-grey-05">
                    ({t('pages.course_content_flow.step_3.optional')})
                  </span>
                </Label>
                <InputDate
                  id="dateStart"
                  onChange={(date) => {
                    setCourseStartDate(date);
                  }}
                  value={
                    courseStartDate ? new Date(courseStartDate) : undefined
                  }
                  variant="date"
                />
              </div>
              <div className="basis-1/2" data-testid="dateEnd">
                <Label htmlFor="dateEnd">
                  {t('pages.course_content_flow.step_3.end_day')}{' '}
                  <span className="font-normal text-grey-05">
                    ({t('pages.course_content_flow.step_3.optional')})
                  </span>
                </Label>
                <InputDate
                  id="dateEnd"
                  onChange={(date) => {
                    setCourseEndDate(date);
                  }}
                  value={courseEndDate}
                  variant="date"
                />
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="inOrder">
                <Switch
                  checked={inOrder}
                  onChange={(isCheck) => {
                    setInOrder(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="pb-2 font-normal">
                  {t('pages.course_content_flow.step_3.tracking')}
                </h4>
                <p className="text-xs text-grey-05">
                  {t('pages.course_content_flow.step_3.tracking_description')}
                </p>
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="autoCorrect">
                <Switch
                  checked={
                    correctionType === 'immediate' ||
                    correctionType === 'autofeedback'
                  }
                  onChange={(isCheck) => {
                    setCorrectionType(isCheck ? 'immediate' : 'manual');
                  }}
                />
              </div>
              <div>
                <h4 className="pb-2 font-normal">
                  {t('pages.course_content_flow.step_3.correction')}
                </h4>
                <p className="text-xs text-grey-05">
                  {t('pages.course_content_flow.step_3.correction_description')}
                </p>
              </div>
            </div>
            {(correctionType === 'immediate' ||
              correctionType === 'autofeedback') && (
              <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div data-testid="autoCorrect2">
                  <Switch
                    checked={correctionType === 'autofeedback'}
                    onChange={(isCheck) => {
                      setCorrectionType(isCheck ? 'autofeedback' : 'immediate');
                    }}
                  />
                </div>
                <div>
                  <h4 className="pb-2 font-normal">
                    {t(
                      'pages.course_content_flow.step_3.correction_autofeedback'
                    )}
                  </h4>
                  <p className="text-xs text-grey-05">
                    {t(
                      'pages.course_content_flow.step_3.correction_autofeedback_description'
                    )}
                  </p>
                </div>
              </div>
            )}
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div className="flex w-full flex-col gap-3 sm:flex-row sm:items-center sm:gap-5">
                <p className="min-w-max text-sm">
                  {t('pages.course_content_flow.step_3.minimum_score')}
                </p>
                <div className="flex w-full items-center gap-5">
                  <input
                    className="slider w-full flex-shrink"
                    max={100}
                    min={0}
                    onChange={(e) =>
                      setMinScore(parseFloat(e?.currentTarget.value) / 100)
                    }
                    onMouseDown={() => setTrackColor(focusColor)}
                    onMouseUp={() => setTrackColor(blurColor)}
                    onTouchEnd={() => setTrackColor(blurColor)}
                    onTouchStart={() => setTrackColor(focusColor)}
                    step={5}
                    style={{
                      background: `linear-gradient(to right, ${trackColor} 0%, ${trackColor} ${percentageMinScore}%, ${backgroundColor} ${percentageMinScore}%, ${backgroundColor})`,
                    }}
                    value={percentageMinScore}
                    type="range"
                  />
                  <InputText
                    type="number"
                    value={percentageMinScore}
                    onChange={(e) =>
                      setMinScore(parseFloat(e?.currentTarget.value) / 100)
                    }
                    suffix="%"
                    className="w-[90px]"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="mandatory">
                <Switch
                  checked={isMandatory}
                  onChange={(isCheck) => {
                    setIsMandatory(isCheck);
                  }}
                />
              </div>
              <div>
                <h4 className="pb-2 font-normal">
                  {t('pages.course_content_flow.step_3.mandatory_follow')}
                </h4>
                <p className="text-xs text-grey-05">
                  {t(
                    'pages.course_content_flow.step_3.mandatory_follow_description'
                  )}
                </p>
              </div>
            </div>
          </>
        )}
        <div className="mt-5 rounded-lg border border-grey-02 p-4">
          <div className="relative z-[12] w-[240px]">
            <Label htmlFor="courseType">
              <p className="text-sm font-semibold">
                {t('pages.create_edit_course.course_type')}
              </p>
            </Label>
            <div id="courseType">
              <InputSelect
                onChange={(coursetype) => {
                  setCourseType(coursetype.name);
                }}
                value={{
                  id: 0,
                  value: parseCourseType(courseType),
                  name: parseCourseType(courseType),
                }}
                options={selectType}
              >
                {(options) => <span>{t(`courseTypes.${options.name}`)} </span>}
              </InputSelect>
            </div>
          </div>
        </div>

        <RadioGroup
          label=""
          onChange={(value: string) => {
            setCourseAccess(value);
          }}
          value={courseAccess}
        >
          <div className="flex flex-col gap-5 sm:flex-row">
            {/* <div
            className={`${
              role > 89 ? 'basis-1/3' : 'basis-1/2'
            } flex items-center rounded-lg border border-grey-02 p-3 ${
              courseAccess == 'protected' && 'border-secondary-04'
            }`}
          > */}
            <div
              className={`flex basis-1/3 items-center rounded-lg border border-grey-02 p-3 ${
                courseAccess == 'protected' && 'border-secondary-04'
              }`}
            >
              <RadioGroup.Option value="protected" className="test">
                <span className="inline-block max-w-[170px] text-sm">
                  {t('pages.course_content_flow.step_3.public')}&nbsp;
                  <span className="font-semibold">
                    {authUser?.organisationName && authUser?.organisationName}
                  </span>
                </span>
              </RadioGroup.Option>
            </div>
            <div
              // className={`${
              //   role > 89 ? 'basis-1/3' : 'basis-1/2'
              // } flex items-center rounded-lg border border-grey-02 p-3 ${
              //   courseAccess == 'private' && 'border-secondary-04'
              // }`}
              className={`flex basis-1/3 items-center rounded-lg border border-grey-02 p-3 ${
                courseAccess == 'private' && 'border-secondary-04'
              }`}
            >
              <RadioGroup.Option value="private">
                <span className="text-sm">
                  {t('pages.course_content_flow.step_3.ivite')}
                </span>
              </RadioGroup.Option>
            </div>
            <div
              className={`flex basis-1/3 items-center rounded-lg border border-grey-02 p-3 ${
                courseAccess == 'public' && 'border-secondary-04'
              }`}
            >
              <RadioGroup.Option value="public">
                <span className="text-sm">
                  {t('pages.course_content_flow.step_3.public_course')}
                </span>
              </RadioGroup.Option>
            </div>
            {/* todo improve access?
          {(role > 89 || ) && (
            <div
              className={`flex basis-1/3 items-center rounded-lg border border-grey-02 p-3 ${
                courseAccess == 'public' && 'border-secondary-04'
              }`}
            >
              <RadioGroup.Option value="public">
                <span className="text-sm">
                  {t('pages.course_content_flow.step_3.public_course')}
                </span>
              </RadioGroup.Option>
            </div>
          )} */}
          </div>
        </RadioGroup>
        {userRole >= 90 && (
          <div className="mt-6">
            <LibraryAddOrganizations
              courseSettings={true}
              auth={auth}
              setPrevContentOrganisation={setPrevContentOrganisation}
              prevContentOrganisation={prevContentOrganisation}
              state={stateOrg}
              updateState={updateStateOrg}
              getOrg={'/courseorganisationdetails'}
              payload={{ courseId: course?.id }}
              globalState={orgState}
              publicationType={publicationType}
            />
          </div>
        )}
        {courseTypeNoInstruction && (
          <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
            <div data-testid="certificate">
              <Switch
                checked={emailCertificate}
                onChange={(isCheck) => {
                  setEmailCertificate(isCheck);
                }}
              />
            </div>
            <div>
              <h4 className="pb-2 font-normal">
                {' '}
                {t('pages.course_content_flow.step_3.certificate')}
              </h4>
              <p className="text-xs text-grey-05">
                {t('pages.course_content_flow.step_3.certificate_description')}
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
);

SharingSettingsStep.displayName = 'SharingSettingsStep';

export default SharingSettingsStep;

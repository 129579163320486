import React, { useState, useEffect, useCallback } from 'react';
import { getSafe, newUid, deep_copy } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
// import { FeedbackSection } from '../FeedbackSection';
import { InputScore, Button, IconButton, Switch } from '@teo/components';
import { Plus, MoreVertical, Cross } from '@teo/components/icons';
import { T } from '@/components/T';
import UploadDropzoneImage from '@/components/UploadDropzoneImage/UploadDropzoneImage';
import { DndProvider, useDrop, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import update from 'immutability-helper';
// import { useMediaQuery } from 'react-responsive';
// import {Container} from "@/components/Froala/widgets/NamePartsQuestion/Container";
// import {CustomDragLayer} from '@/components/Froala/widgets/NamePartsQuestion/CustomDragLayer';
import { DraggableBox } from '@/components/Froala/widgets/NamePartsQuestion/DraggableBox';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { InputText } from '@teo/components';
import { uploadImage } from '@/query/documents';
import { pastels } from './NamePartsView';
import clsx from 'clsx';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialNameImage2 = () => ({
  score: 1,
  vraag: '',
  type: 'NameImage2',
  image: null,
  labels: [{ uid: newUid(20), x: 0.1, y: 0.1, tx: 0, ty: 0, text: '' }],
});

let texts = {};

export const NamePartsQuestion = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  //This makes whole body tag a dropzone, used to properly handled dropped outside events
  const [_, bodyDropRef] = useDrop(() => ({
    accept: 'label',
  }));

  useEffect(() => {
    bodyDropRef(document.body);
    return () => {
      bodyDropRef(null);
    };
  }, []);
  //end

  const { t } = useTranslation();
  const [img, setImg] = useState(state.image);
  const [labels, setLabels] = useState(state.labels);
  const [dragging, setDragging] = useState(false);

  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.name_parts_title')
  );

  const uploadInputRef = React.createRef();

  const [newLabelText, setNewLabelText] = useState();
  const [competencies, setCompetencies] = useState([]);

  useEffect(() => {
    texts = {};
    state.labels.forEach((x) => {
      texts[x.uid] = x.text;
    });
    labels.forEach((x) => {
      setNewLabelText((prev) => ({ ...prev, [x.uid]: x.text }));
    });
  }, [labels]);

  useEffect(() => {
    onLoadImg();
  }, [labels]);

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    newState.labels = deep_copy(labels);
    newState.labels.forEach((x, i) => {
      x.text = newLabelText[x.uid] || labels[i].text;
    });

    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.score = score;
    newState.titleWidget = titleWidget;
    newState.image = img;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : false
  );
  const [score, setScore] = useState(state.score || 0);

  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );

  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );

  const onLoadImg = () => {
    const canvas = document.getElementById(state.uid + '_canvas');
    const img = document.getElementById(state.uid + '_img');

    if (!img || !canvas) return;

    canvas.width = img.width * 5;
    canvas.height = img.height * 5;

    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 20;
    labels.forEach((x, index) => {
      //const angle = Math.atan2(x.y - x.ty, x.x - x.tx); //angle it approaches the label

      const label = document.getElementById(`${state.uid}_label_${index}`);
      let bbox = label.getBoundingClientRect();
      const endY = (x.y + bbox.height / 2 / img.height) * canvas.height;
      //const endX = (x.x + (bbox.width / 2) / img.width) * canvas.width;
      const endX = (x.x + 15 / img.width) * canvas.width;
      ctx.beginPath();
      const startX = x.tx * canvas.width;
      const startY = x.ty * canvas.height;
      //const endX *= canvas.width;
      //const endY *= canvas.height;
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = pastels[index % pastels.length];
      ctx.stroke();
      ctx.closePath();
    });
  };

  const DropZone = () => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ['label', 'dot'],
      drop: (item, monitor) => {
        const type = monitor.getItemType();
        if (type === 'label') {
          const delta = monitor.getDifferenceFromInitialOffset();
          let newLabels = deep_copy(labels);
          const img = document.getElementById(state.uid + '_img');
          newLabels[item.index].y = item.y + delta.y / img.height;
          newLabels[item.index].x = item.x + delta.x / img.width;
          setLabels(newLabels);
        } else if (type === 'dot') {
          const delta = monitor.getDifferenceFromInitialOffset();
          let newLabels = deep_copy(labels);
          const img = document.getElementById(state.uid + '_img');
          newLabels[item.index].ty = item.ty + delta.y / img.height;
          newLabels[item.index].tx = item.tx + delta.x / img.width;
          setLabels(newLabels);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }));

    return (
      <div
        className="absolute h-full w-full"
        style={{
          pointerEvents: dragging ? 'auto' : 'none',
          top: 0,
          left: 0,
        }}
        ref={drop}
      ></div>
    );
  };

  const Label = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'label',
      item: props,
      canDrag: true,
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        };
      },
      end: () => {
        setDragging(false);
      },
    }));

    if (isDragging) setDragging('label_' + props.uid);
    const opacity = isDragging || 'label_' + props.uid === dragging ? 0.4 : 1;

    return (
      <div
        ref={drag}
        key={`${state.uid}_isDragging`}
        id={`${state.uid}_label_${props.index}`}
        className={clsx(
          'fr-draggable absolute flex items-center bg-grey-01 p-1'
        )}
        style={{
          top: props.y * 100 + '%',
          left: props.x * 100 + '%',
          opacity: opacity,
          pointerEvents: dragging ? 'none' : 'all',
          borderRadius: '4px',
        }}
      >
        <img
          src={`/images/icons/drug_icon.svg`}
          className="ml-3 mr-2 h-[18px] w-[18px] bg-grey-01 text-grey-01"
          alt="icon"
          style={{ pointerEvents: 'none' }}
        />
        <InputText
          style={{
            border: 'none',
            backgroundColor: '#f8f8f9',
          }}
          id="labelContainer"
          onChange={({ target: { value } }) => {
            texts[props.uid] = value;
          }}
          onBlur={(ev) => {
            let newLabels = deep_copy(labels);
            newLabels[props.index].text = ev.target.value;
            setLabels(newLabels);
          }}
          defaultValue={props.text}
        />
        <IconButton
          Icon={Cross}
          ariaLabel="delete order"
          onClick={() => {
            let newLabels = deep_copy(labels);
            newLabels.splice(props.index, 1);
            setLabels(newLabels);
          }}
          variant="ghost"
          className="text-#1e1e2052 bg-grey-01"
          style={{ color: '#1e1e2052' }}
        />
      </div>
    );
  };

  const Dot = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'dot',
      item: props,
      canDrag: true,
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        };
      },
      end: () => {
        setDragging(false);
      },
    }));

    if (isDragging) setDragging('dot_' + props.uid);
    const opacity = isDragging || 'dot_' + props.uid === dragging ? 0.4 : 1;

    return (
      <span
        ref={drag}
        className="fr-draggable absolute h-3.5 w-3.5 cursor-move rounded-full border-4"
        style={{
          transform: 'translate(-50%, -50%)',
          top: props.ty * 100 + '%',
          left: props.tx * 100 + '%',
          opacity: opacity,
          pointerEvents: dragging ? 'none' : 'all',
          borderColor: `${pastels[props.index % pastels.length]}`,
        }}
      ></span>
    );
  };

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 py-4 px-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />

      {/* <span className="text-lg font-semibold text-grey-04">
        {t('Question')} ({t('Optional')})
      </span> */}
      <div className="wg-question">
        <QuestionSection questionVraag={questionVraag} />
      </div>
      <div className="my-4 flex gap-3">
        <Switch
          checked={showAnswer}
          onChange={(isCheck) => setShowAnswer(isCheck)}
        />
        <div className="mr-4">
          {t('widgets.edit.matrix_question.explain_after_submit')}
        </div>
      </div>

      <div className={`${!showAnswer ? 'hidden' : ''}`}>
        {/* <span className="text-lg font-semibold text-grey-04">
          {t('widgets.edit.matrix_question.example_answer')} (
          {t('widgets.edit.matrix_question.optional')})
        </span> */}
        <div className="wg-answer">
          <QuestionSection questionVraag={antwoordVraag} exampleAnswer={true} />
        </div>
      </div>

      {!img ? (
        <UploadDropzoneImage
          setUploadImg={(url) => {
            setImg(url);
          }}
        />
      ) : (
        <>
          <div
            className="relative h-full w-full rounded-md"
            contentEditable="false"
          >
            <img
              src={img}
              id={state.uid + '_img'}
              onLoad={onLoadImg}
              className="pointer-events-none w-full !cursor-default rounded-md"
              draggable="false"
            />
            <canvas
              id={state.uid + '_canvas'}
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              className="pointer-events-none absolute"
            ></canvas>
            <div
              className="absolute rounded-full bg-white"
              style={{
                backgroundColor: 'white',
                color: '#fdbe06',
                bottom: '10px',
                right: '10px',
              }}
            >
              <IconButton
                Icon={Plus}
                variant="secondary"
                size="lg"
                className="!rounded-full bg-white !p-5 text-primary-05 shadow-[0_0_12px_-6px_black]"
                onClick={() => {
                  let newLabels = deep_copy(labels);
                  newLabels.push({
                    uid: newUid(20),
                    x: 0.1,
                    y: 0.1,
                    tx: 0,
                    ty: 0,
                    text: '',
                  });
                  setLabels(newLabels);
                }}
              />
            </div>
            <DropZone />
            {labels.map((x, index) => (
              <>
                <Label
                  key={'label_' + state.uid + x.index}
                  {...x}
                  index={index}
                ></Label>
                <Dot
                  key={'dot_' + state.uid + x.index}
                  {...x}
                  index={index}
                ></Dot>
              </>
            ))}
          </div>
          <input
            id={state.uid + '_imginput'}
            ref={uploadInputRef}
            style={{ display: 'none' }}
            type="file"
            onChange={async (ev) => {
              const img = ev.target.files[0];
              const data = await uploadImage(img, 1200, 1000, 'jpeg');
              setImg(data);
            }}
          />

          <div>
            <div className="mt-4 flex flex-row items-center border-t border-b border-grey-02 py-2">
              <Button
                variant="outline"
                onClick={() => {
                  uploadInputRef.current.click();
                }}
              >
                {t('widgets.widgets_edit.replace_image')}
              </Button>
              <div className="mr-2 ml-7 border-l border-grey-02 pl-5">
                {t('widgets.widgets_edit.max_score')}
              </div>
              <InputScore value={score} onChange={(value) => setScore(value)} />
            </div>
            {/*
            <div className="flex gap-4 border-b border-grey-02 py-2">
              <Switch
                checked={immediateFeedback}
                onChange={(immediateFeedback) => {
                  setImmediateFeedback(immediateFeedback);
                }}
              />
              <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
            </div>
            */}
          </div>
        </>
      )}
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

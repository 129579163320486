import { useContext } from 'react';
import { Button, Dropdown } from '@teo/components';
import { Trash, MoreVertical } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { encode } from '@/base64';
import { copyToClipboard, orderWidgets, newUid } from '@/util';
import { setShowNotification } from '@/App';
import { AuthContext } from '@/components/Contexts/AuthContext';

const WidgetOptions = ({ widget, placeholder, state, undo, chatGPT }) => {
  const { t } = useTranslation();

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;

  const useTeoAi = authUser?.useTeoAi;

  const handleOpenChat = () => {
    const openChat = document.getElementById(`${state.uid}_gpt_open`);
    openChat?.click();
  };

  const handleOpenCompetencies = () => {
    const openCompetencies = document.getElementById(
      `${state.uid}_competencies`
    );
    openCompetencies?.click();
  };

  return (
    <>
      {state?.type !== 'ImageShowParts' && (
        <>
          {state?.type !== 'Math' && (
            <Dropdown>
              <Dropdown.Trigger>
                <div className="rounded-md px-4 py-2 hover:bg-grey-transparent-01">
                  <MoreVertical className="h-5 w-5" />
                </div>
              </Dropdown.Trigger>

              <Dropdown.Content
                style={{
                  top: '25px',
                }}
              >
                <Dropdown.Section className="">
                  <p className="text-xs text-grey-06">
                    {t('widgets.widget_competencies.institutions')}
                  </p>
                  <Dropdown.Actions>
                    <Dropdown.Button onClick={() => handleOpenCompetencies()}>
                      <p className="text-sm">
                        {t('widgets.widget_competencies.edit_competencies')}
                      </p>
                    </Dropdown.Button>
                  </Dropdown.Actions>
                </Dropdown.Section>
              </Dropdown.Content>
            </Dropdown>
          )}

          <Button
            Icon={Trash}
            onClick={async () => {
              const state = await widget.stateFn();
              state.uid = newUid(20);
              let stateStr = 'widget:' + encode(JSON.stringify(state));
              copyToClipboard(stateStr);
              setShowNotification(i18n.t('notifications.widget_copied'));
            }}
            variant="ghost"
            className="flex text-grey-01"
          >
            <img
              src="/images/icons/copy.svg"
              alt="delete button"
              className="w-5"
            />
          </Button>
        </>
      )}

      {chatGPT && useTeoAi && (
        <Button
          onClick={() => {
            handleOpenChat();
          }}
          variant="ghost"
          className="flex text-grey-01"
        >
          <img src="/images/icons/chatgpt.svg" alt="chatgpt" className="w-5" />
        </Button>
      )}
      <Button
        Icon={Trash}
        onClick={() => {
          placeholder.remove();
          orderWidgets();
          undo?.reset();
        }}
        variant="ghost"
        className="flex text-grey-01"
      >
        <img
          src="/images/icons/trash_icon_red.svg"
          alt="delete button"
          className="w-5"
        />
      </Button>
    </>
  );
};

export { WidgetOptions };

import { useTranslation } from 'react-i18next';
import { Button, Multiselect, IconButton } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useState, useEffect } from 'react';

const CompetencyTab = ({
  setNewCompetency,
  newCompetency,
  allCompetency,
  myCompetencies,
  setWidgetCompetency,
  widgetCompetency,
  setFilterCompetency,
  filterCompetency,
  typeCompetency,
  tab,
}) => {
  const { t } = useTranslation();
  const [selectCompetency, setSelectCompetency] = useState([]);
  const [competencyProfiles, setCompetencyProfiles] = useState([]);

  useEffect(() => {
    if (myCompetencies?.length > 0) {
      const addCompetency = widgetCompetency;
      const oldCompetency = myCompetencies;
      for (const obj2 of oldCompetency) {
        const existingObj = addCompetency.find((obj1) => obj1.key === obj2.id);
        const type = obj2?.path?.includes(`${typeCompetency};;`);
        if (!existingObj && type) {
          addCompetency.push({
            key: obj2.id,
            label: obj2.path.split(';;')[obj2.path.split(';;').length - 2],
            path: obj2.path,
          });
        }
      }
      setWidgetCompetency(addCompetency);
    }
  }, [myCompetencies]);

  useEffect(() => {
    if (allCompetency) {
      const data = allCompetency?.map((competency) => ({
        key: competency?.id,
        label:
          competency.path.split(';;')[competency.path.split(';;').length - 2],
        path: competency?.path,
      }));
      setCompetencyProfiles(data?.slice(0, 10));
    }
  }, [allCompetency]);

  const handleCompetency = () => {
    const addCompetency = widgetCompetency;
    for (const obj2 of selectCompetency) {
      const existingObj = addCompetency.find((obj1) => obj1.key === obj2.key);
      if (!existingObj) {
        addCompetency.push(obj2);
      }
    }
    const addNewCompetency = newCompetency;
    for (const obj2 of selectCompetency) {
      const existingObj = addNewCompetency.find((obj1) => obj1.id === obj2.key);
      if (!existingObj) {
        setNewCompetency((prev) => [
          ...prev,
          { id: obj2?.key, label: '', path: obj2?.path },
        ]);
      }
    }

    setWidgetCompetency(addCompetency);
    setSelectCompetency([]);
    setFilterCompetency('');
  };

  const handleRemoveCompetency = (id) => {
    const removeTabCompetency = widgetCompetency.filter(
      (item) => item.key !== id
    );
    const removeCompetency = newCompetency.filter((item) => item.id !== id);

    setWidgetCompetency(removeTabCompetency);
    setNewCompetency(removeCompetency);
  };

  if (tab !== typeCompetency) {
    return null;
  }

  return (
    <div>
      <div data-testid="competency" className="ali relative z-[11] flex">
        <span className="w-full" data-testid="organizationsSelect">
          <Multiselect
            removeButtonLabel="clear"
            toggleSelectionLabel="toggle"
            placeholder={t('widgets.widget_competencies.knowledge_placeholder')}
            options={competencyProfiles}
            onChange={(value) => setSelectCompetency(value)}
            value={selectCompetency}
            query={filterCompetency}
            setQuery={(query) => setFilterCompetency(query)}
          />
        </span>
        <Button
          className="ml-3 h-[36px] text-base font-medium"
          variant="primary"
          disabled={selectCompetency.length === 0}
          onClick={handleCompetency}
        >
          {t('pages.course_content_flow.step_1.add_btn')}
        </Button>
      </div>
      <div className="-mx-6 mt-7 max-h-[40vh] overflow-y-scroll px-6">
        {widgetCompetency?.length > 0 ? (
          <>
            <p className="mb-3 text-sm font-semibold">
              {t(`widgets.widget_competencies.added_${typeCompetency}`)}
            </p>
            {widgetCompetency?.map((item) => (
              <div
                key={item.key}
                className="mb-3 flex items-center justify-between rounded-md bg-grey-01 py-2"
              >
                <div className="flex items-center">
                  <div className="ml-4 flex flex-col">
                    <span className="text-sm font-medium text-[#231f20]">
                      {item.label}
                    </span>
                  </div>
                </div>

                <IconButton
                  size="sm"
                  className="my-auto mx-4"
                  Icon={CrossFilled}
                  variant="ghost"
                  onClick={() => handleRemoveCompetency(item.key)}
                />
              </div>
            ))}
          </>
        ) : (
          <p className="text-sm text-grey-06">
            {t(`widgets.widget_competencies.no_${typeCompetency}`)}
          </p>
        )}
      </div>
    </div>
  );
};

export default CompetencyTab;

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup, Button, ErrorMessage } from '@teo/components';
const Step6 = ({ step, setStep, setInfo, userInfo, courseInfo }: any) => {
  const { t } = useTranslation();
  const [checkValid, setCheckValid] = useState<boolean>(false);

  const [situation, setSituation] = useState<string>('');
  const [participating, setParticipating] = useState<string>('');

  useEffect(() => {
    setParticipating(userInfo?.motivation);
  }, [userInfo]);

  const nextButton = () => {
    setCheckValid(true);
    if (situation && participating) {
      setInfo((prev: any) => ({ ...prev, situation, participating }));
      setStep((prev: number) => prev + 1);
    }
  };
  const prevButton = () => {
    const nextStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_diploma_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_referral_step')
      ? 2
      : courseInfo?.onboardingSteps?.includes('onboarding_regime_step')
      ? 3
      : courseInfo?.onboardingSteps?.includes('onboarding_location_step')
      ? 4
      : 5;
    setStep((prev: number) => prev - nextStep);
  };

  const handleError = (el: any) => (el ? false : checkValid ? true : false);

  if (step !== 6) {
    return null;
  }
  return (
    <>
      <div>
        <p className="mb-2">{t(`pages.onboarding.step_6.subtitle`)}</p>
        <div>
          <RadioGroup
            label=""
            onChange={(value: string) => {
              setSituation(value);
            }}
            value={situation}
          >
            <RadioGroup.Option value="notWorking">
              {t(`pages.onboarding.step_6.NOT_WORKING`)}
            </RadioGroup.Option>
            <RadioGroup.Option value="permanentContract">
              {t(`pages.onboarding.step_6.PERMANENT_CONTRACT`)}
            </RadioGroup.Option>
            <RadioGroup.Option value="teamContract">
              {t(`pages.onboarding.step_6.TEMP_CONTRACT`)}
            </RadioGroup.Option>
          </RadioGroup>
          {handleError(situation) && (
            <ErrorMessage id="errorMessage">
              {t('pages.onboarding.error.select_one')}
            </ErrorMessage>
          )}
        </div>
        <p className="mt-10 mb-2">{t(`pages.onboarding.step_5.subtitle_2`)}</p>
        <div>
          <RadioGroup
            label=""
            onChange={(value: string) => {
              setParticipating(value);
            }}
            value={participating}
          >
            <RadioGroup.Option value="job">
              {t(`pages.onboarding.step_6.NEW_JOB`)}
            </RadioGroup.Option>
            <RadioGroup.Option value="knowledge">
              {t(`pages.onboarding.step_6.NEW_KNOWLEDGE`)}
            </RadioGroup.Option>
          </RadioGroup>
          {handleError(participating) && (
            <ErrorMessage id="errorMessage">
              {t('pages.onboarding.error.select_one')}
            </ErrorMessage>
          )}
        </div>
      </div>
      <div className="flex w-full pt-10">
        <Button
          variant="outline"
          className={`mr-2 flex-[1_1_30%] ${step === 1 && 'hidden'}`}
          onClick={prevButton}
        >
          {t(`pages.onboarding.button_prev`)}
        </Button>

        <Button
          className={`${step !== 1 && 'ml-2 flex-[1_1_30%]'}`}
          onClick={() => step < 7 && nextButton()}
        >
          {t(`pages.onboarding.button_next`)}
        </Button>
      </div>
    </>
  );
};
export default Step6;

import { useContext, useEffect, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { useParams } from 'react-router';
import { CorrectionContent } from '@/components/CorrectionContent/CorrectionContent';
import CorrectionHeader from '@/components/CorrectionContent/CorrectionHeader';
import { newUid } from '@/util';
import { ActiveCourseContext } from '@/components/Contexts/ActiveCourseContext';
import { ActiveStudentContext } from '@/components/Contexts/ActiveStudentContext';
import { ActiveLessonContext } from '@/components/Contexts/ActiveLessonContext';
import { ActiveLessonStatusesContext } from '@/components/Contexts/ActiveLessonStatusesContext';
import { Spinner } from '@teo/components';
import { XAPIService } from '@/services/xapi.service.js';
import Cmi5, { LaunchParameters } from '@xapi/cmi5';
import { TinCanService } from '@/services/tincan.service';

const ResultPage = (props: any) => {
  const { studentId, courseId, lessonId } = useParams();
  const { setNavDisabled } = useContext(NavContext);
  const [submit, setSubmit] = useState<string | undefined>();

  const { course, setCourse } = useContext(ActiveCourseContext);
  const { student, setStudent } = useContext(ActiveStudentContext);

  const { lesson, setLesson } = useContext(ActiveLessonContext);
  const { lessonStatus, setLessonStatus } = useContext(
    ActiveLessonStatusesContext
  );
  const [preloader, setPreloader] = useState(false);
  const [preloaderNext, setPreloaderNext] = useState(false);

  const initXapi = async () => {
    const xapidetails = JSON.parse(student?.xapidetails as string);
    const xapi = XAPIService.getInstance();
    const launchParameters: LaunchParameters = {
      endpoint: xapidetails.endpoint,
      fetch: xapidetails.fetch,
      actor: xapidetails.actor,
      registration: xapidetails.registration,
      activityId: xapidetails.activityId,
    };
    try {
      xapi.cmi5 = new Cmi5(launchParameters);
      await xapi.cmi5.initialize({
        authToken: xapidetails.authToken,
        initializedDate: new Date(),
      });
    } catch (e) {
      console.error('error', e);
    }
  };

  useEffect(() => {
    if (student && student?.xapidetails) {
      const xapidetails = JSON.parse(student?.xapidetails as string);
      if (student && xapidetails?.tincan) {
        TinCanService.getInstance(xapidetails);
      }
    }
  }, [student]);

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  return (
    <>
      {!preloader && props.coorectionPage && (
        <div className="fixed inset-0 z-[11] flex bg-white">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-16 !w-16 border-grey-08"
            />
          </div>
        </div>
      )}
      <CorrectionHeader
        coorectionPage={props.coorectionPage}
        submit={submit}
        isTeacher={props.isTeacher}
        studentId={studentId}
        courseId={courseId}
        lessonId={lessonId}
        course={course}
        student={student}
        lesson={lesson}
        lessonStatus={lessonStatus}
        setPreloaderNext={setPreloaderNext}
      />
      <div className="relative mt-7 flex-[1_0_auto]">
        <CorrectionContent
          isTeacher={props.isTeacher}
          studentId={studentId}
          courseId={courseId}
          lessonId={lessonId}
          lesson={lesson}
          resaltPage={props.resultPage}
          coorectionPage={props.coorectionPage}
          onSubmit={() => setSubmit(newUid(20))}
          course={course}
          setCourse={setCourse}
          student={student}
          setStudent={setStudent}
          setLesson={setLesson}
          setLessonStatus={setLessonStatus}
          lessonStatus={lessonStatus}
          setPreloader={setPreloader}
          setPreloaderNext={setPreloaderNext}
          preloaderNext={preloaderNext}
        />
      </div>
    </>
  );
};

export default ResultPage;

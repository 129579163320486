import React, { Suspense, Fragment } from 'react';
import { FroalaTextareaView } from './FroalaTextareaView';

const FroalaTextareaEdit = React.lazy(() =>
  import(/* webpackChunkName: "FroalaTextareaEdit" */ './FroalaTextAreaEdit')
);

const FroalaTextarea = (props, ref) => {
  return (
    <Suspense fallback={'...'}>
      {props.mode === 'edit' && <FroalaTextareaEdit {...props} />}
      {props.mode === 'view' && <FroalaTextareaView {...props} />}
    </Suspense>
  );
};

export { FroalaTextarea };

import { useTranslation } from 'react-i18next';
import { T } from '@/components/T';
import { useState, useContext, useEffect } from 'react';
import {
  Button,
  ButtonLink,
  ContentCardListItem,
  IconButton,
  StatsCard,
  Tabs,
} from '@teo/components';
import {
  DuoToneAssessmentOutline,
  DuoToneCourseOutline,
  DuoToneVacancyOutline,
  Account,
  DuoToneTeamOutline,
  ArrowUp,
  Plus,
  ArrowDown,
} from '@teo/components/icons';
import { useGet } from '@/query';
import { useParams } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { NotificationUpdateContex } from '@/components/Contexts/NotificationUpdateContex';
import i18n from 'i18next';
import { axios } from '@/axios';
import { parseContentRange } from '@/util';
import { config } from '@/config';
import ReportModal from './ReportModal';
import NotificationList from './NotificationList';
import Hint from '@/components/Hints/Hints';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { InstructionsIcon } from '../../../../constants/sidebar.constants';

function FollowUpIndexPage() {
  const { t } = useTranslation();

  const { organisationId } = useParams();
  const { hints } = useContext(HintsContext);
  const [tab, setTab] = useState('tasks');
  const [showReportModal, setShowReportModal] = useState(false);

  const [tasks, setTasks] = useState<any[]>([]);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [nrOfTasks, setNrOfTasks] = useState<number>(0);
  const [nrOfNotifications, setNrOfNotifications] = useState<number>(0);

  const [overviewData, setOverviewData] = useState<any>(null);
  const [overviewUpdate, setOverviewUpdate] = useState<any>(null);
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as any;
  const { notificationUpdate, setNotificationUpdate } = useContext(
    NotificationUpdateContex
  );

  const [userUpdate, setUserUpdate] = useState<any>(null);
  const [teamUpdate, setTeamUpdate] = useState<any>(null);
  const [hoursUpdate, setHoursUpdate] = useState<any>(null);
  const [lessonsUpdate, setLessonsUpdate] = useState<any>(null);

  useGet(`/organisation/${organisationId}`, {
    organisationId,
    onSuccess: (result: any) => {
      setOverviewData(result?.data || {});
    },
  });

  useGet(`/organisationstats`, {
    organisationId,
    count: 5,
    sort: '-createdAt',
    onSuccess: (result: any) => {
      setOverviewUpdate(result?.data || {});
    },
  });

  useEffect(() => {
    if (overviewUpdate && overviewData) {
      const indexOverviewUpdate = overviewUpdate?.length - 1;
      setUserUpdate(
        overviewData?.nrOfMembers -
          overviewUpdate[indexOverviewUpdate].nrOfMembers
      );
      setTeamUpdate(
        overviewData?.nrOfTeams - overviewUpdate[indexOverviewUpdate].nrOfTeams
      );
      setHoursUpdate(
        Math.round(overviewData?.organisationTimespent / 3600) -
          Math.round(overviewUpdate[indexOverviewUpdate].timespent / 3600)
      );
      setLessonsUpdate(
        overviewData?.organisationLessonLearned -
          overviewUpdate[indexOverviewUpdate].nrOfLessonLearned
      );
    }
  }, [overviewUpdate, overviewData]);

  // console.log(hoursUpdate);
  // console.log(lessonsUpdate);

  const parseNotifications = (notifications: any[]) => {
    return notifications.map((notification: any) => {
      return Object.assign({}, notification, {
        globalLink: notification?.globalLink
          ?.replace?.(
            /:organisationId/,
            notification?.text?.organisationId
              ? notification.text.organisationId
              : auth?.user?.activeOrganisationId
          )
          ?.replace?.(/:lang/, i18n.language),
      });
    });
  };

  const { refetch: refetchTasks } = useGet(`/notifications`, {
    scope: 'current',
    userId: auth?.user?.id,
    isTask: 'true',
    unread: true,
    count: 10,
    onSuccess: (result: any) => {
      setNrOfTasks(parseContentRange(result));
      setTasks(parseNotifications((result?.data as any[]) || []));
    },
  });

  const { refetch: refetchNotifications } = useGet(`/notifications`, {
    scope: 'current',
    userId: auth?.user?.id,
    isTask: 'false',
    type: 'tocorrect,RO,courseadded,RO,coursestarted,RO,coursecomplete',
    unread: true,
    count: 10,
    onSuccess: (result: any) => {
      setNrOfNotifications(parseContentRange(result));
      setNotifications(parseNotifications((result?.data as any[]) || []));
    },
  });

  useEffect(() => {
    setTasks([]);
    setNotifications([]);
    refetchNotifications();
  }, [notificationUpdate]);

  const activityResult = useGet(`/activity?count=5&sort=-createdAt`, {
    userId: auth?.user?.id,
  });

  const activities = (activityResult?.data?.data as any[]) || [];

  if (!overviewData) return null;

  return (
    <div className="flex w-full max-w-7xl flex-col justify-between xl:flex-row">
      <div className="flex flex-[1_0_70%] grow-[4] flex-col xl:pr-8">
        <div className="flex items-center justify-between">
          <h1 className="my-8 text-2xl font-bold text-black">
            <T>{t('pages.follow_up_overview.title')}</T>
          </h1>
          {showReportModal && (
            <ReportModal onClose={() => setShowReportModal(false)} />
          )}
          <div
            className={`${
              hints?.id == 'hint_12' && 'active-hint-parent !z-[100]'
            }`}
          >
            <Button
              variant="outline"
              size="md"
              onClick={() => setShowReportModal(true)}
              className="relative"
            >
              <Hint
                key={`hint_12`}
                id={'hint_12'}
                leftDots={'-left-2 leading-[26px] top-1.5'}
                title={t('hint.title_12')}
                order={12}
                style={{
                  right: '-10px',
                  top: '60px',
                }}
              />
              {t('pages.follow_up_overview.download_report_button')}
            </Button>
          </div>
        </div>

        <div className="flex flex-wrap justify-between rounded-lg border border-grey-02 py-7 px-9">
          <div className="mb-3 flex-[1_0_48%] md:mb-0 md:flex-none">
            <div className="mx-auto w-28 sm:w-auto">
              <StatsCard
                amount={overviewData?.nrOfMembers}
                label={t('pages.follow_up_overview.users')}
                change={{
                  Icon: userUpdate < 0 ? ArrowDown : ArrowUp,
                  amount: userUpdate,
                  direction: userUpdate < 0 ? 'decreasing' : 'increasing',
                }}
              />
            </div>
          </div>

          <div className="mb-3 flex-[1_0_48%] md:mb-0 md:flex-none">
            <div className="mx-auto w-28 w-28 sm:w-auto">
              <StatsCard
                amount={overviewData?.nrOfTeams}
                label={t('pages.follow_up_overview.teams')}
                change={{
                  Icon: teamUpdate < 0 ? ArrowDown : ArrowUp,
                  amount: teamUpdate,
                  direction: teamUpdate < 0 ? 'decreasing' : 'increasing',
                }}
              />
            </div>
          </div>

          {/*
          <div className="flex-[1_0_48%] md:flex-none">
            <div className="mx-auto w-28 w-28 sm:w-auto">
              <StatsCard
                amount={overviewData?.nrOfCourses}
                label={t('pages.follow_up_overview.courses')}
                // changeDirection="increasing"
              />
            </div>
          </div>
          */}

          <div className="mb-3 flex-[1_0_48%] md:mb-0 md:flex-none">
            <div className="mx-auto w-28 w-28 sm:w-auto">
              <StatsCard
                amount={Math.round(overviewData?.organisationTimespent / 3600)}
                unit={`${t('pages.follow_up_overview.hours')}`}
                label={`${t('pages.follow_up_overview.learned_at_teo')}`}
                change={{
                  Icon: hoursUpdate > 0 && Plus,
                  amount: hoursUpdate,
                  direction: hoursUpdate < 0 ? 'decreasing' : 'increasing',
                }}
              />
            </div>
          </div>

          <div className="mb-3 flex-[1_0_48%] md:mb-0 md:flex-none">
            <div className="mx-auto w-28 w-28 sm:w-auto">
              <StatsCard
                amount={overviewData?.organisationLessonLearned}
                label={`${t('pages.follow_up_overview.lessons_taken')}`}
                change={{
                  Icon: lessonsUpdate > 0 && Plus,
                  amount: lessonsUpdate,
                  direction: lessonsUpdate < 0 ? 'decreasing' : 'increasing',
                }}
              />
            </div>
          </div>

          {/*
          <div className="flex-[1_0_48%] md:flex-none">
            <div className="mx-auto w-28 w-28 sm:w-auto">
              <StatsCard
                amount={nrOfTasks}
                label={t('pages.follow_up_overview.tasks')}
                // changeDirection="increasing"
              />
            </div>
          </div>
          */}
        </div>

        <div className="mb-6 mt-3 flex flex-wrap justify-between">
          <div
            className={`mt-3 ${hints?.id == 'hint_15' && 'relative z-[100]'}`}
          >
            <Tabs activeName={tab} onChange={(tab: string) => setTab(tab)}>
              <div
                className={`relative ${
                  hints?.id == 'hint_13' && 'active-hint-parent'
                }`}
              >
                <Tabs.TabButton name="tasks">
                  <Hint
                    key={`hint_13`}
                    id={'hint_13'}
                    leftDots={'-left-2 top-1.5'}
                    title={t('hint.title_13')}
                    order={13}
                    style={{
                      left: '-10px',
                      top: '60px',
                    }}
                  />
                  <p className="relative text-base font-medium text-black">
                    {t('pages.follow_up_overview.tasks')}
                  </p>
                  <span className="ml-2 text-base text-[#919193]">
                    {nrOfTasks}
                  </span>
                </Tabs.TabButton>
              </div>
              <div
                className={`relative ${
                  hints?.id == 'hint_14' && 'active-hint-parent !z-[100]'
                } ${hints?.id == 'hint_13' && '!z-[100]'}`}
              >
                <Tabs.TabButton name="activity">
                  <Hint
                    key={`hint_14`}
                    id={'hint_14'}
                    leftDots={'-left-2 top-1.5'}
                    title={t('hint.title_14')}
                    order={14}
                    style={{
                      left: '-10px',
                      top: '60px',
                    }}
                  />
                  <p className="text-base font-medium text-black">
                    {t('pages.follow_up_overview.activity')}
                  </p>
                  <span className="ml-2 text-base text-[#919193]">
                    {nrOfNotifications}
                  </span>
                </Tabs.TabButton>
              </div>
            </Tabs>
          </div>

          <Button
            variant="outline"
            className="mt-3"
            onClick={async () => {
              const promises = [];
              if (tab === 'tasks') {
                for (const task of tasks) {
                  promises.push(
                    axios.delete(`${config.backend}/notification/${task.id}`)
                  );
                }
              } else if (tab === 'activity') {
                for (const notification of notifications) {
                  promises.push(
                    axios.delete(
                      `${config.backend}/notification/${notification.id}`
                    )
                  );
                }
              }
              await Promise.allSettled(promises);
              if (tab === 'tasks') {
                refetchTasks();
              } else if (tab === 'activity') {
                refetchNotifications();
              }
            }}
          >
            {t('pages.follow_up_overview.complete_all_tasks_button')}
          </Button>
        </div>
        <NotificationList
          tab={tab}
          tasks={tasks}
          notifications={notifications}
          setNotificationUpdate={setNotificationUpdate}
        />
      </div>
      <div className="mt-8 flex w-full flex-[1_0_30%] grow flex-wrap gap-6 xl:mt-0 xl:flex-col">
        <div className="flex flex-[1_0_40%] flex-col xl:flex-none">
          <p className="mb-3 text-base font-semibold text-black">
            {t('pages.follow_up_overview.contents')}
          </p>

          <div className="flex w-full max-w-2xl flex-col gap-2">
            {authUser?.useCourse && (
              <ButtonLink
                to="./courses"
                variant="secondary"
                className="flex items-center justify-between"
              >
                <span className="flex items-center pr-2">
                  <DuoToneCourseOutline className="m-2 w-5" />
                  <p className="pl-2 text-sm font-normal text-grey-08">
                    {t('pages.follow_up_users.active_courses')}
                  </p>
                </span>
                <p className="text-base font-semibold">
                  {overviewData.nrOfActiveCourses}
                </p>
              </ButtonLink>
            )}
            {/*
            <ButtonLink
              to="./growth-trajectories"
              variant="secondary"
              className="flex items-center justify-between"
            >
              <span className="flex items-center pr-2">
                <IconButton Icon={DuoToneTrajectoryOutline} variant="ghost" />
                <p className="pl-2 text-sm font-normal text-grey-08">
                  {t('pages.follow_up_users.active_growth_trajectory')}
                </p>
              </span>
              <p className="text-base font-semibold">16</p>
            </ButtonLink>
            */}
            {authUser?.useAssessment && (
              <ButtonLink
                to="./assessments"
                variant="secondary"
                className="flex items-center justify-between"
              >
                <span className="flex items-center pr-2">
                  <DuoToneAssessmentOutline className="m-2 w-5" />
                  <p className="pl-2 text-sm font-normal text-grey-08">
                    {t('pages.follow_up_users.active_assessments')}
                  </p>
                </span>
                <p className="text-base font-semibold">
                  {overviewData.nrOfActiveAssessments}
                </p>
              </ButtonLink>
            )}
            {authUser?.useLeervacature && (
              <ButtonLink
                to="./vacancies"
                variant="secondary"
                className="flex items-center justify-between"
              >
                <span className="flex items-center pr-2">
                  <DuoToneVacancyOutline className="m-2 w-5" />
                  <p className="pl-2 text-sm font-normal text-grey-08">
                    {t('pages.follow_up_users.active_vacancies')}
                  </p>
                </span>
                <p className="text-base font-semibold">
                  {overviewData.nrOfActiveLeervacatures}
                </p>
              </ButtonLink>
            )}
            {authUser?.useInstruction && (
              <ButtonLink
                to="./instructions"
                variant="secondary"
                className="flex items-center justify-between"
              >
                <span className="flex items-center pr-2">
                  <InstructionsIcon className="m-2 w-5 text-grey-04" />
                  <p className="pl-2 text-sm font-normal text-grey-08">
                    {t('pages.follow_up_users.active_instructions')}
                  </p>
                </span>
                <p className="text-base font-semibold">
                  {overviewData.nrOfActiveInstructions}
                </p>
              </ButtonLink>
            )}
          </div>
        </div>

        <div
          className={`relative flex-[1_0_40%] xl:flex-none ${
            hints?.id == 'hint_15' && 'active-hint-parent !z-[100]'
          }`}
        >
          <div className="relative inline-block">
            <Hint
              key={`hint_15`}
              id={'hint_15'}
              leftDots={'-right-5'}
              title={t('hint.title_15')}
              order={15}
              style={{
                right: 'calc(100% + 20px)',
                top: '-10px',
              }}
            />
            <p className={` text-base font-semibold text-black`}>
              {t('pages.follow_up_overview.recent')}
            </p>
          </div>

          <div>
            <div className="mt-5 w-full ">
              {activities.map((activity: any) => {
                //'course', 'leervacature', 'assessment', 'user', 'team', 'publication'
                switch (activity.activityType) {
                  case 'user':
                    return (
                      <ContentCardListItem
                        key={activity?.id}
                        Icon={Account}
                        title={activity?.subject}
                        to={activity?.link}
                      />
                    );
                  case 'team':
                    return (
                      <ContentCardListItem
                        key={activity?.id}
                        Icon={DuoToneTeamOutline}
                        title={activity?.subject}
                        to={activity?.link}
                      />
                    );
                  default:
                    return (
                      <ContentCardListItem
                        key={activity?.id}
                        imageUrl={
                          activity.logo || '/assets/images/opleidingen.jpg'
                        }
                        title={
                          activity.subject ||
                          t('pages.follow_up_overview.empty_title')
                        }
                        to={activity.link}
                      />
                    );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FollowUpIndexPage;

import { useState } from 'react';
import { Button, IconButton, Tag } from '@teo/components';
import { CrossFilled, Document } from '@teo/components/icons';

/** TODO: Enlarge icon doesn't look quite right there */

export const ViewUplaodElement = ({
  answered,
  el,
  type_txt,
  setUploadFile,
  onDelete,
  uploadFile,
}: any) => {
  const [maginify, setMagnify] = useState(false);
  const [maginifyVideo, setMagnifyVideo] = useState(false);

  const txt_type =
    type_txt === 'doc' || type_txt === 'docx' || type_txt === 'pdf';

  let typeTag;
  if (el?.type?.includes('image')) {
    typeTag = 'Image';
  } else if (el?.type?.includes('video')) {
    typeTag = 'Video';
  } else {
    typeTag = 'Document';
  }

  return (
    <div className="relative flex flex-row items-center rounded rounded border border-grey-02 bg-white p-3 md:max-w-[50%] md:flex-[1_0_49%]">
      {el?.type.includes('image') && (
        <>
          <Button
            variant="ghost"
            className="mr-3 !p-0"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setMagnify(!maginify);
            }}
          >
            <img
              alt={el?.name}
              src={el?.url ? el?.url : URL.createObjectURL(el)}
              className="h-16 w-16 rounded border border-tint-indigo object-cover object-center"
            />
          </Button>
          {maginify && (
            <div
              className="fixed inset-0 z-10 flex bg-[#000000cc]"
              onClick={() => setMagnify(!maginify)}
            >
              <img
                alt={el?.name}
                src={el?.url ? el?.url : URL.createObjectURL(el)}
                className="m-auto h-3/4 w-3/4 object-contain object-center sm:h-1/2 sm:w-1/2"
              />
            </div>
          )}
        </>
      )}
      {el?.type.includes('video') && (
        <>
          <Button
            variant="ghost"
            className="mr-3 !p-0"
            onClick={() => setMagnifyVideo(true)}
          >
            <video
              autoPlay
              muted
              className="h-16 w-16 rounded border border-tint-indigo object-cover object-center"
              src={el?.url}
            />
          </Button>
          {maginifyVideo && (
            <div className="fixed inset-0 z-10 flex bg-[#000000cc]">
              <div
                className="absolute inset-0"
                onClick={() => setMagnifyVideo(false)}
              ></div>
              <video
                controls
                autoPlay
                className="z-1 m-auto w-3/4 object-contain object-center sm:w-1/2"
                src={el?.url}
              />
            </div>
          )}
        </>
      )}
      {answered ? (
        <>
          {!el?.type.includes('image') && !el?.type.includes('video') && (
            <a
              href={el?.url}
              target="_blank"
              rel="noreferrer"
              className="mr-3 flex h-16 w-16 rounded border border-error-02 bg-error-01 text-error-05"
            >
              <Document className="m-auto w-10" />
            </a>
          )}
        </>
      ) : (
        <>
          {!el?.type.includes('image') && !el?.type.includes('video') && (
            <div className="mr-3 flex h-16 w-16 rounded border border-error-02 bg-error-01 text-error-05">
              <Document className="m-auto w-10" />
            </div>
          )}
        </>
      )}
      <div>
        <p className="w-[150px] max-w-[150px] overflow-hidden truncate pb-1 text-base font-semibold">
          {el?.name}
        </p>

        <Tag
          title={typeTag}
          size="md"
          variant="document"
          documentType={txt_type ? 'PDF' : 'image'}
        />
      </div>
      {!answered ? (
        <IconButton
          className="my-auto mr-2 ml-auto"
          Icon={CrossFilled}
          variant="outline"
          onClick={() => {
            setUploadFile &&
              setUploadFile(
                uploadFile?.filter((file: any) => {
                  return file?.size !== el?.size;
                })
              );
            onDelete && onDelete();
          }}
        />
      ) : null}
    </div>
  );
};

import { Table, Checkbox } from '@teo/components';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import { useMediaQuery } from 'react-responsive';
import MobileDataTable from './MobileDataTable';
import { parseFilter, newUid } from '@/util';
import EmptyTable from './EmptyTable';

type CheckboxIconMobile = 'default' | 'circle' | 'square';
type Screens = 'mobile' | 'planshet' | 'desktop';
export interface DataTablePropsType {
  columns: Array<{
    addTitleOnMobile?: boolean;
    align?: string;
    id: number | string;
    render?: JSX.Element | string | ((row: any) => JSX.Element | string);
    sortable?: boolean;
    title: string | JSX.Element;
    className?: string;
    classNameDesktop?: string;
    hidden?: boolean;
  }>;
  classTable?: string;
  empty?: {
    template?: number | null | undefined;
    text?: string | null | undefined;
    btn_text?: string | null;
    btnClick?: (...args: any[]) => void;
  };
  setContentRange?: (...args: any[]) => void;
  defaultSort?: Array<{
    desc: boolean;
    id: number | string;
  }>;
  fetch: (...args: any[]) => void;
  filter?: string;
  indent?: (...args: any[]) => number;
  page: number;
  pageSize: number;
  rowPath?: (arg0: any) => string;
  screensUpdateTable?: Screens;
  selectable?: {
    selected: Set<string | number>;
    setSelected: (...args: any[]) => void;
    setDeselect: (...args: any[]) => void;
    deselect: boolean;
    checkboxIconMobileType?: CheckboxIconMobile;
  };
  selectableRow?: {
    selected: boolean;
    selectedRow: string | number;
    setSelected: (...args: any[]) => void;
  };

  onSelect?: (selection: Set<string | number> | null) => void; //when selection is null, indicates we go to deselect mode
  onDeSelect?: (selection: Set<string | number> | null) => void; //when deselection is null, indicates we go to select mode
  uniqueKey: string | ((row: any) => string);
}

export const DataTable = (props: DataTablePropsType): JSX.Element => {
  const locOffset = sessionStorage.getItem('offsetLoc');
  const prevPathname = sessionStorage.getItem('prevPathname');

  const [selectMode, setSelectMode] = useState(true);
  const [offset, setOffset] = useState<any>(
    locOffset && locOffset != '0'
      ? prevPathname == window.location.pathname
        ? +locOffset
        : props.page * props.pageSize
      : props.page * props.pageSize
  );
  const [count, setCount] = useState(props.pageSize);

  useEffect(() => {
    if (props?.filter) {
      if (props?.filter?.trim().length > 0) {
        setOffset(0);
      }
    }
  }, [props.filter]);

  useEffect(() => {
    const locOffset = sessionStorage.getItem('offsetLoc');
    const prevPathname = sessionStorage.getItem('prevPathname');

    if (locOffset) {
      if (prevPathname == window.location.pathname) {
        sessionStorage.removeItem('offsetLoc');
        sessionStorage.removeItem('prevPathname');
      }
    }
  }, []);

  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [maxPage, setMaxPage] = useState(1);

  const [sorted, setSorted] = useState(props.defaultSort || []);
  const [sortQuery, setSortQuery] = useState(
    sorted.map((sort) => (sort.desc ? '-' : '') + sort.id).join(',')
  );

  const page = Math.floor(offset / count) + 1;

  const addSort = (colId: number | string) => {
    const index = sorted.findIndex((x) => x.id === colId);
    if (index > -1) {
      const sort = sorted[index];
      sorted.splice(index, 1);
      sorted.unshift({ id: colId, desc: !sort.desc });
    } else {
      sorted.unshift({ id: colId, desc: true });
    }
    setSorted(sorted);
    setSortQuery(
      sorted.map((sort) => (sort.desc ? '-' : '') + sort.id).join(',')
    );
  };

  const defaultRender = (row: any, col: any) => row[col.id];

  const result: any = props.fetch({
    offset: locOffset && +locOffset == 0 ? locOffset : offset,
    count,
    sort: sortQuery ? sortQuery : undefined,
    q: parseFilter(props.filter),
  });

  const screenUpdate = (screensUpdateTable: string | null) => {
    switch (screensUpdateTable) {
      case 'mobile':
        return '639px';
      case 'planshet':
        return '1199px';
      case 'desktop':
        return '1279px';
      default:
        return '1023px';
    }
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: `(max-width: ${
      props?.screensUpdateTable
        ? screenUpdate(props?.screensUpdateTable)
        : '1023px'
    })`,
  });

  useEffect(() => {
    if (result?.data) {
      setData(result?.data?.data);
      const contentRange = result?.data?.headers?.['content-range']
        ?.split(' ')?.[1]
        ?.split('/');
      const maxCount = contentRange ? Math.ceil(contentRange?.[1]) : 1;
      setMaxPage(Math.ceil(maxCount / count));
      props?.setContentRange && props?.setContentRange(contentRange?.[1]);
      setLoadData(true);
    }
  }, [result?.data]);

  const navigate = useNavigate();
  const goRouteId = (url: any) => {
    if (offset > 0) {
      sessionStorage.setItem('offsetLoc', offset);
      sessionStorage.setItem('prevPathname', window.location.pathname);
    }
    navigate(url);
  };
  const handleHeaderSelect = () => {
    if (props?.selectable?.selected) {
      if (props?.selectable?.selected?.size > 0) {
        if (props?.selectable?.deselect) {
          props?.selectable?.setDeselect(!props?.selectable?.deselect);
          props?.selectable?.setSelected(new Set());
        } else {
          props?.selectable?.setSelected(new Set());
        }
      } else {
        props?.selectable?.setDeselect(!props?.selectable?.deselect);
        props?.selectable?.setSelected(new Set());
      }
    }
  };

  const handleItemSelect = (key: any) => {
    const newSelected = new Set(props?.selectable?.selected);
    if (
      (newSelected.has(key) && selectMode) ||
      (!newSelected.has(key) && !selectMode)
    ) {
      newSelected.delete(key);
    } else {
      newSelected.add(key);
    }
    props?.selectable?.setSelected(newSelected);
  };
  /*
  props.fetch({
    offset,
    count,
    sort: sortQuery ? sortQuery : undefined,
    q: parseFilter(filter),
    onSuccess: (result: any) => {
      const contentRange = result?.headers?.['content-range']
        ?.split(' ')?.[1]
        ?.split('/');
      const maxCount = Math.ceil(contentRange?.[1]);
      setMaxPage(Math.ceil(maxCount / count));
      setData(result?.data);
    },
  });
  */

  //console.log("Columns", props.columns)

  return (
    <>
      {loadData &&
        (data?.length > 0 ? (
          <>
            <div
              className={`${props?.classTable} ${
                !isDesktopOrLaptop && 'border'
              } rounded-lg border-grey-02 text-green-500`}
            >
              {!isDesktopOrLaptop ? (
                <Table>
                  <Table.Header>
                    {props?.selectable?.selected && data?.length > 0 && (
                      <th
                        className="text-sm-medium w-11 py-2 pl-4 text-left text-black"
                        scope="col"
                      >
                        <Checkbox
                          checked={
                            (props?.selectable?.deselect &&
                              props?.selectable?.selected.size <
                                data?.length - 1) ||
                            (props?.selectable?.selected.size >
                              data?.length - 1 &&
                              !props?.selectable?.deselect)
                          }
                          id="test-box"
                          onChange={handleHeaderSelect}
                          indeterminate={
                            props?.selectable?.selected.size !== data?.length &&
                            props?.selectable?.selected.size > 0 &&
                            props?.selectable?.selected.size < data?.length
                              ? true
                              : false
                          }
                        />
                      </th>
                    )}
                    {props.columns.map((col: any) => {
                      if (col?.hidden) return null;
                      return (
                        <Table.HeaderCell
                          key={col.id}
                          onSort={
                            col.sortable === undefined || col.sortable
                              ? () => addSort(col.id)
                              : undefined
                          }
                          sortDirection={
                            sorted[0]?.id === col.id
                              ? sorted[0]?.desc
                                ? 'ascending'
                                : 'descending'
                              : undefined
                          }
                          align={col.align || 'left'}
                        >
                          {typeof col?.title === 'function'
                            ? col.title()
                            : col.title}
                        </Table.HeaderCell>
                      );
                    })}
                  </Table.Header>
                  <Table.Body>
                    {data?.map((row: any, i) => {
                      const key =
                        typeof props.uniqueKey === 'function'
                          ? props.uniqueKey(row)
                          : row[props.uniqueKey];
                      const rowPath = props?.rowPath?.(row);
                      return (
                        <tr
                          key={key + '_' + i}
                          className={`h-16 text-sm text-black hover:bg-grey-01 ${
                            (rowPath || props?.selectableRow?.selected) &&
                            'hover:cursor-pointer'
                          } ${
                            row?.id &&
                            props?.selectableRow?.selectedRow == row?.id &&
                            'bg-grey-01'
                          }`}
                          onClick={() =>
                            rowPath
                              ? goRouteId(rowPath)
                              : props?.selectableRow?.selected
                              ? props?.selectableRow?.setSelected(row?.id)
                              : ''
                          }
                          data-testid={`row-${row?.id}`}
                        >
                          {/*props.indent && [...Array(props.indent(row))].map( x =>  (<td style={{position: 'relative', width: `1em`}}></td>))*/}

                          {props?.selectable?.selected && (
                            <td
                              className="text-sm-medium w-11 py-2 pl-4 text-left text-black"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                            >
                              <Checkbox
                                checked={
                                  !props?.selectable?.deselect
                                    ? props?.selectable?.selected.has(key)
                                      ? true
                                      : false
                                    : props?.selectable?.selected.has(key)
                                    ? false
                                    : true
                                }
                                key={
                                  key +
                                  '_' +
                                  props?.selectable?.selected.has(key)
                                }
                                onChange={() => handleItemSelect(key)}
                              />
                            </td>
                          )}
                          {props.columns.map((col: any) => {
                            if (col?.hidden) return null;
                            const content = col?.render
                              ? typeof col?.render === 'function'
                                ? col.render(row)
                                : col.render
                              : defaultRender(row, col);
                            return (
                              <Table.Cell
                                key={`${row.id}_${col.id}` as string}
                                align={col.align || 'left'}
                                className={col.classNameDesktop || ''}
                              >
                                {content}
                              </Table.Cell>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </Table.Body>
                </Table>
              ) : (
                <MobileDataTable
                  setings={props}
                  data={data}
                  defaultRender={defaultRender}
                  handleItemSelect={handleItemSelect}
                  goRouteId={goRouteId}
                  props={props}
                />
              )}
            </div>
            {maxPage > 1 && (
              <Pagination
                setOffset={setOffset}
                offset={offset}
                count={count}
                page={page}
                maxPage={maxPage}
              />
            )}
          </>
        ) : (
          <EmptyTable
            template={props?.empty?.template}
            text={props?.empty?.text}
            btn_text={props?.empty?.btn_text}
            btnClick={() => props?.empty?.btnClick && props?.empty?.btnClick()}
            filter={props?.filter}
          />
        ))}
    </>
  );
};

/*
DataTable.propTypes = {
  fetch: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  filter: PropTypes.string,
  rowPath: PropTypes.func,
  page: Number,
  pageSize: Number,
  defaultSort: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.boolean,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      align: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      render: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      sortable: PropTypes.boolean,
    })
  ),
};
*/

DataTable.defaultProps = {
  page: 0,
  pageSize: 10,
  columns: [],
  uniqueKey: 'id',
};

import { axios } from '../axios';

export function createGet(path, keys, options = {}) {
  options.name ||= path.slice(path.lastIndexOf('/') + 1);
  options.type ||= options.name.toUpperCase() + '_GET';
  keys ||= ['id'];
  return _create(axios.get.bind(null, path), options);
}

export function createPost(path, keys, data, options = {}) {
  options.name ||= path.slice(path.lastIndexOf('/') + 1);
  options.type ||= options.name.toUpperCase() + '_POST';
  keys ||= ['id'];
  return _create(axios.get.bind(null, path, data), options);
}

function _create(axios_fn, options = {}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios_fn()
        .then((response) => {
          let action = {
            type: options.type + '_SUCCESS',
            [options.name]: response.data,
            ...options.extra,
          };
          try {
            if (response.headers['content-range']) {
              const serverLength = parseFloat(
                response.headers['content-range'].split('/')[1]
              );
              Object.assign(action, { serverLength });
            } else {
              if (options.extra)
                Object.assign(action[options.name], options.extra);
            }
          } catch (e) {
            console.error(e);
          }
          dispatch(action);
          resolve(action[options.name]);
        })
        .catch((e) => {
          if (options.ignore_error) {
            console.warn(e);
            return resolve(e);
          } else {
            let action = {
              type: options.type + '_FAILURE',
              [options.name]: e.data,
              ...options.extra,
            };
            dispatch(action);
            if (e && e.response) reject(e.response);
            else reject(e);
          }
        });
    });
  };
}

import { useTranslation } from 'react-i18next';
import { DocumentCard } from '@teo/components';

const Certificates = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full  md:flex">
      <div className="flex h-full w-full flex-col" id="userForm">
        <div className="flex w-full max-w-[500px] flex-col max-md:h-full">
          <h4 className="mb-5 text-base">
            {t('pages.manage_users.user_detail_page.certificates.title')}
          </h4>
          <div className="flex flex-col gap-5">
            {/* to do; implement certificates */}
            {/* <DocumentCard
              documentType="course"
              documentTypeTagTitle="Cursus"
              downloadButtonLabel="PDF Downloaden"
              downloadUrl="/TEO_logo.svg"
              title="Basis Techniek"
              collapseButtonLabel=""
            >
              <h4 className="text-sm-medium mb-4">Modules:</h4>
              <ul className="text-sm text-grey-07">
                <li className="mb-4">Basis elektriciteit en schakelingen </li>
                <li className="mb-4">Industriële elektriciteit </li>
                <li className="mb-4">Mechanica</li>
              </ul>
            </DocumentCard>
            <DocumentCard
              documentType="course"
              documentTypeTagTitle="Cursus"
              downloadButtonLabel="PDF Downloaden"
              downloadUrl="/TEO_logo.svg"
              title="Basis Techniek 2"
              collapseButtonLabel=""
            >
              <h4 className="text-sm-medium mb-4">Modules:</h4>
              <ul className="text-sm text-grey-07">
                <li className="mb-4">Basis elektriciteit en schakelingen </li>
                <li className="mb-4">Industriële elektriciteit </li>
                <li className="mb-4">Mechanica</li>
              </ul>
            </DocumentCard> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificates;

import { BehaviorSubject } from 'rxjs';
import XAPI from '@xapi/xapi';
//import AbstractCmi5 from "@xapi/cmi5/AbstractCmi5";
import Cmi5 from '@xapi/cmi5';
import {
  Deferred,
  retryWithExponentialBackoff,
  parseQueryString,
} from '@/util';
import { decode, encode } from '@/base64';
import { axios } from '@/axios';
import { config } from '@/config';

class ScormService {
  lastProgress;

  constructor() {
    if (ScormService._instance) return ScormService._instance;
    ScormService._instance = this;
    window.addEventListener('message', function (event) {
      console.warn('received welcome', event.data);
    });
  }

  progress(progress, totalTimeSpent) {
    if (progress === this.lastProgress) {
      this.lastProgress = progress;
      return;
    }
    this.lastProgress = progress;
    try {
      console.warn(
        'trying to submit',
        JSON.stringify({ progress }),
        'to',
        window.parent
      );
      window.parent.postMessage(
        JSON.stringify({
          type: 'progress',
          progress_measure: progress,
          total_time: totalTimeSpent,
        }),
        '*'
      );
    } catch (e) {
      console.error(e);
    }
  }

  async reportFinal(follow, course) {
    try {
      let status = {
        type: 'completion',
        completion_status: 'completed',
      };
      if (!follow.maxScore) {
        status.success_status = 'unknown';
      } else {
        const scaled = follow.score / (follow.maxScore || 1);
        const minScore = course?.minScore || 0.5;
        status.score = {
          scaled: scaled,
          raw: Math.round(follow.score),
          min: 0,
          max: Math.round(follow.maxScore),
        };
        if (scaled >= minScore) {
          status.success_status = 'passed';
        } else {
          status.success_status = 'failed';
        }
      }
      console.warn(
        'trying to submit',
        JSON.stringify(status),
        'to',
        window.parent
      );
      window.parent.postMessage(JSON.stringify(status), '*');
    } catch (e) {
      console.error(e);
    }
  }

  available() {
    console.warn('parent available', !!window.parent, window.parent);
    return !!window.parent;
  }
}

ScormService.getInstance = function (props) {
  return ScormService._instance || new ScormService(props);
};

export { ScormService };

//http://localhost:3000/launch?fetch=https://lms.com/fetch_url&endpoint=https://lms.com/endpoint&registration=some-id

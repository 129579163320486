import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlayFilled, TimeFilled, Eye } from '@teo/components/icons';
import { convertToTime } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { useGet } from '@/query/fetchHooks';

export const DuplicationExtraInfo = ({
  currentId,
  refresh,
  loadLessons,
  currentType,
  assesType,
  setLessons,
  setLoadLessons,
  lesType,
  showLast,
  filter,
  selectLes,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  return (
    <div
      className={`${loadLessons === true ? 'hidden' : 'flex w-full flex-col'}`}
    >
      <PaginationAnything
        key={refresh}
        className={`mt-3 flex w-full max-w-6xl flex-wrap gap-2 md:gap-3 lg:gap-2`}
        fetch={
          currentId
            ? useGet.bind(null, `/usedlessonassets`, {
                offset: 0,
                count: 12,
                canEdit: true,
                coursecontentId: currentId,
                scope: currentType?.id,
                assetType: assesType?.id,
                lessonType: lesType?.id,
                onSuccess: (result: any) => {
                  setLessons(result?.data || {});
                  setLoadLessons(false);
                },
              })
            : useGet.bind(null, '/lessonimports', {
                organisationId: `${auth?.user?.activeOrganisationId},OR,organisationId=null`,
                userId: auth?.user?.id,
                offset: 0,
                count: 12,
                canEdit: true,
                lessonType: lesType?.id,
                coursecontentId: currentId,
                onSuccess: (result: any) => {
                  setLessons(result?.data || {});
                  setLoadLessons(false);
                },
              })
        }
        defaultSort={showLast ? [{ id: '-createdAt' }] : [{ id: 'createdAt' }]}
        page={0}
        pageSize={12}
        filter={filter}
        render={(les: any) => (
          <div
            className="max-w-[49%] flex-[1_1_49%] pb-3 md:max-w-[32%] md:flex-[1_1_32%] lg:max-w-[24%] lg:flex-[1_1_24%]"
            key={les.id}
          >
            <div
              className="group min-w-[150px] max-w-[264px] cursor-pointer"
              onClick={() => selectLes(les)}
            >
              <div className="flex flex-col">
                <div className="relative mb-2 overflow-hidden rounded-lg ">
                  {les?.lessonId ? (
                    <img
                      src={
                        les?.publicationImage
                          ? les?.publicationImage
                          : '/assets/images/opleidingen.jpg'
                      }
                      alt={les?.name}
                      className="h-28 w-full rounded-lg object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                  ) : les?.assetType === 'image' ? (
                    <img
                      src={les?.assetPath}
                      alt={les?.assetName}
                      className="h-28 w-full rounded-lg object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                  ) : (
                    <div className="flex h-28 w-full rounded-lg border border-secondary-04 bg-secondary-01 object-cover transition-transform duration-500">
                      <PlayFilled className="m-auto w-7 text-secondary-04 " />
                    </div>
                  )}
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg ${
                      les?.assetType !== 'video' &&
                      ' group-hover:backdrop-blur-sm '
                    } `}
                  ></div>
                  <span className="absolute inset-0 hidden items-center justify-center group-hover:flex">
                    <span className="rounded-md bg-grey-01 p-1 text-grey-09">
                      <Eye className="h-5 w-5 "></Eye>
                    </span>
                  </span>
                </div>
                <h3 className="text-base-semibold mb-2 break-words">
                  {les?.name || les?.assetName}
                </h3>
                <div className="flex flex-row items-center gap-3">
                  <span className="text-sm capitalize text-grey-04">
                    {les?.lessonType || les?.assetType}
                  </span>
                  {les?.estimatedTime > 0 && (
                    <span className="flex flex-row items-center gap-1 text-sm text-grey-04">
                      <TimeFilled className="inline h-5 w-5" />
                      <p className="pl-1">
                        {convertToTime(
                          Math.abs(les?.estimatedTime),
                          t('pages.learn_index.hour'),
                          t('pages.learn_index.minute')
                        )}
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

import { config } from '@/config';

const PreviewMail = ({ template, courseId }: any) => {
  return (
    <>
      <iframe
        style={{
          border: '0',
          width: '100%',
          height: '100%',
          minHeight: '80vh',
        }}
        src={`${
          config.backend
        }/contact/template/${template}/${courseId}?jwt=${localStorage.getItem(
          'token'
        )}`}
      ></iframe>
    </>
  );
};

export default PreviewMail;

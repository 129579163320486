import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { IconButton } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useParams } from 'react-router-dom';
import { axios } from '@/axios';
import { config } from '@/config';
import AddCompetencyProfileStepsContainer from '@/pages/Protected/Manage/AddCompetencyProfilePage/AddCompetencyProfileStepsContainer';
import { newUid } from '@/util';

const AddCompetencyProfilePage = ({
  setIsOpenAdd,
  categories,
  competencyType,
  setRefetchProfile,
}: any) => {
  const { t } = useTranslation();
  const { organisationId } = useParams();

  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [competence, setCompetence] = useState('');
  const [sectors, setSectors] = useState<any>(null);
  const [addCompetence, setAddCompetence] = useState<any>(null);
  const [filter, setFilter] = useState('');
  const [allCategories, setAllCategories] = useState();
  const [allModules, setAllModules] = useState();
  const [allTypes, setAllTypes] = useState();
  const [selectedRow, setSelectedRow] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselect, setDeselect] = useState<boolean>(false);

  const handleAddCompetencies = () => {
    axios
      .post(`${config.backend}/professions`, {
        organisationId: organisationId,
        name: competence,
        // sectors: sectors,
        qualifications: [...selectedRow],
        categoryId: sectors?.id ? Number(sectors?.id) : null,
      })
      .then(() => {
        setRefetchProfile(newUid(20));
        setIsOpenAdd(false);
      });
  };

  return (
    <div
      style={{ zIndex: 102 }}
      className="fixed top-0 left-0 z-10 h-screen w-screen overflow-y-auto bg-white"
    >
      <div className="flex min-h-16 items-center justify-start ">
        <IconButton
          className="my-auto mx-6"
          Icon={CrossFilled}
          variant="ghost"
          onClick={() => setIsOpenAdd(false)}
        />
        <div className="pl-2 font-semibold">
          {t('pages.manage_competencies.add_competency_profile')}
        </div>
      </div>

      <AddCompetencyProfileStepsContainer
        competence={competence}
        setCompetence={setCompetence}
        sectors={sectors}
        setSectors={setSectors}
        addCompetence={addCompetence}
        setAddCompetence={setAddCompetence}
        filter={filter}
        setFilter={setFilter}
        allCategories={allCategories}
        setAllCategories={setAllCategories}
        allModules={allModules}
        setAllModules={setAllModules}
        allTypes={allTypes}
        setAllTypes={setAllTypes}
        refetchTable={refetchTable}
        setRefetchTable={setRefetchTable}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleAddCompetencies={handleAddCompetencies}
        deselect={deselect}
        setDeselect={setDeselect}
        categories={categories}
        competencyType={competencyType}
      />
    </div>
  );
};

export default AddCompetencyProfilePage;

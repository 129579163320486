import { useNavigate, useParams } from 'react-router-dom';
import { useState, Fragment, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import {
  InputText,
  Button,
  FilterList,
  FilterButton,
  FilterDropdown,
  Spinner,
} from '@teo/components';
import {
  Search,
  TimeFilled,
  DocumentsFilled,
  Eye,
} from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { parseDateTime, newUid, convertToTime } from '@/util';
import LibraryOverview from './LibraryOverview';
import { axios } from '@/axios';
import { config } from '@/config';

const LibraryCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  const queryParams = new URLSearchParams(window.location.search);
  const prevPage = queryParams.get('type');

  const [moreFilter, setMoreFilter] = useState(false);
  const [tab, setTab] = useState<any>('all');

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const basePath = window.location.pathname;
  const [refresh, setRefresh] = useState<string>(newUid(20));

  const [category, setCategory] = useState<any>();
  const [listCategories, setListCategories] = useState<any>([]);
  const [loadRecently, setLoadRecently] = useState<any>(true);

  const allCategories: any = useGet(`/categories`, {
    count: 1000,
    sort: 'tag',
    lang: auth?.user?.lang,
  });

  useEffect(() => {
    if (prevPage) {
      if (prevPage == 'lesson') {
        setTab('lessons');
        const url = new URL(window.location.href);
        url.searchParams.delete('type');
        window.history.replaceState({}, '', url.toString());
      }
      if (prevPage == 'chapter') {
        setTab('chapters');
        const url = new URL(window.location.href);
        url.searchParams.delete('type');
        window.history.replaceState({}, '', url.toString());
      }
    }
  }, [prevPage]);

  useEffect(() => {
    if (allCategories?.data) {
      const data = (allCategories?.data?.data as any[]).map((tag) => ({
        id: tag?.id,
        name: tag?.tag,
      }));
      setListCategories(data);
    }
  }, [allCategories?.data]);

  return (
    <div className="w-full max-w-6xl pb-8">
      <h1 className="my-8 text-xl font-semibold text-black">
        {t(`pages.create_library.title`)}
      </h1>
      <div className={`relative mb-8 sm:w-2/3 md:w-1/2`}>
        <InputText
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          Icon={Search}
          placeholder={t('pages.create_library.placeholder_search')}
        />
      </div>

      <div className="mb-8 flex flex-col justify-between gap-4 lg:flex-row lg:gap-0">
        <div className={`relative flex items-center`}>
          <FilterButton
            active={tab === 'all'}
            onClick={() => {
              if (tab != 'all') {
                setLoadRecently(true);
                setTab('all');
              }
            }}
            size="sm"
          >
            {t('pages.create_library.all')}
          </FilterButton>
          <div className="w-3"></div>
          <FilterButton
            active={tab === 'lessons'}
            onClick={() => {
              if (tab != 'lessons') {
                setLoadRecently(true);
                setTab('lessons');
              }
            }}
          >
            {t('pages.create_library.lessons')}
          </FilterButton>
          <div className="w-3"></div>
          <FilterButton
            active={tab === 'chapters'}
            onClick={() => {
              if (tab != 'chapters') {
                setLoadRecently(true);
                setTab('chapters');
              }
            }}
          >
            {t('pages.create_library.chapters')}
          </FilterButton>
        </div>
        <div className="flex items-center">
          <Button
            variant="outline"
            size="sm"
            className="ml-auto mr-2"
            onClick={() => {
              setMoreFilter(!moreFilter);
            }}
          >
            {moreFilter
              ? t('pages.create_edit_course.library.fewer_filters')
              : t('pages.create_edit_course.library.more_filters')}
          </Button>
        </div>
      </div>
      {moreFilter && (
        <div className="mt-5 flex flex-col">
          <div className="z-[10] mb-4 flex flex-col sm:flex-row">
            <span className="mb-4 pr-2 sm:mb-0">
              <FilterDropdown
                clearButtonLabel="clear"
                onChange={(value: any) => {
                  setLoadRecently(true);
                  setCategory(value);
                }}
                buttonText={`${t(`pages.create_library.category`)}`}
                options={listCategories}
                searchable={true}
                value={category}
              />
            </span>
            {/* <span className="pr-2">
               <FilterDropdown
                 clearButtonLabel="clear"
                 onChange={(value: any) => {
                   setLoadLessons(true);
                   setRefresh(newUid(20));
                   setLesType(value);
                 }}
                 buttonText={`${t('pages.follow_up.course_type_tag')}`}
                 options={[
                   {
                     id: 'theory',
                     name: t('pages.create_edit_course.library.theory'),
                   },
                   {
                     id: 'practice',
                     name: t(
                       'pages.create_edit_course.library.practice'
                     ),
                   },
                 ]}
                 // searchable={true}
                 value={lesType}
                 dropdownPositionMobileRight={true}
               />
             </span> */}
          </div>
        </div>
      )}
      <div>
        {tab == 'all' && (
          <LibraryOverview
            setCategory={setCategory}
            category={category}
            allCategories={allCategories}
            authUser={authUser}
            setMoreFilter={setMoreFilter}
            filter={filter}
            refresh={refresh}
          />
        )}
        {tab == 'chapters' && (
          <>
            <p className="mb-3 text-base font-semibold text-black">
              {t(`pages.create_library.chp_library`)}
            </p>
            <PaginationAnything
              key={refresh}
              className={`mt-3 -ml-2 -mr-2 flex min-h-[660px] max-w-6xl flex-wrap content-baseline ${
                loadRecently === true && ' hidden '
              }`}
              fetch={useGet.bind(null, `/directoryimports`, {
                organisationId: `${authUser.activeOrganisationId},OR,organisationId=null`,
                qtag:
                  category &&
                  `%25category:${encodeURIComponent(category?.name)}%25`,
                onSuccess: (result: any) => {
                  setLoadRecently(false);
                },
              })}
              defaultSort={[{ id: 'createdAt' }]}
              empty={{
                template: 2,
                loadContent: loadRecently,
                text: t('pages.create_library.library_empty'),
              }}
              page={0}
              pageSize={12}
              filter={filter}
              render={(folder: any) => (
                <div
                  key={folder?.id}
                  className="max-h-[220px] min-h-[220px] basis-1/2 overflow-hidden px-2 md:basis-1/3 lg:basis-1/4"
                >
                  <div
                    className="group cursor-pointer pb-4"
                    onClick={async () => {
                      await axios.post(`${config.backend}/libraryactivity`, {
                        directoryId: folder?.directoryId,
                        organisationId: authUser?.activeOrganisationId,
                        coursecontentId: folder?.coursecontentId,
                        userId: authUser.id,
                        authorId: folder?.authorId,
                      });
                      navigate(
                        `${basePath}/chapter/${folder?.directoryId}?type=chapter`
                      );
                    }}
                  >
                    <div className="flex flex-col">
                      <div className="relative mb-2 overflow-hidden rounded-lg">
                        <img
                          src={
                            folder?.publicationImage
                              ? folder?.publicationImage
                              : '/assets/images/opleidingen.jpg'
                          }
                          alt={folder?.name}
                          className="h-28 w-full rounded-md object-cover"
                        />
                        {(folder?.organisationId === 18 ||
                          folder?.organisationId == null) && (
                          <img
                            src="/images/TEO_logo.svg"
                            className="absolute bottom-1 right-1 h-8 w-8"
                          />
                        )}
                        <div className="absolute inset-0 h-full w-full rounded-lg group-hover:backdrop-blur-sm"></div>
                        <span className="absolute inset-0 hidden items-center justify-center group-hover:flex">
                          <span className="rounded-md bg-grey-01 p-1 text-grey-09">
                            <Eye className="h-5 w-5 "></Eye>
                          </span>
                        </span>
                      </div>

                      <p className="mb-2 text-base font-semibold text-black">
                        {folder?.name}
                      </p>
                      <div className="flex text-grey-04">
                        <div className="mr-3 flex">
                          <DocumentsFilled className="w-5" />

                          <span>
                            {folder?.nrOfDirectoryLessons ? (
                              <>
                                {' '}
                                {folder?.nrOfDirectoryLessons}{' '}
                                {t('pages.create_library.lessons')}
                              </>
                            ) : (
                              <>0 {t('pages.create_library.lessons')}</>
                            )}
                          </span>
                        </div>

                        {folder?.directoryEstimatedTime > 0 && (
                          <div className="flex">
                            <TimeFilled className="w-5" />
                            <span>
                              {convertToTime(
                                Math.abs(folder?.directoryEstimatedTime),
                                t('pages.learn_index.hour'),
                                t('pages.learn_index.minute')
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </>
        )}
        {tab == 'lessons' && (
          <>
            <p className="mb-3 text-base font-semibold text-black">
              {t(`pages.create_library.les_library`)}
            </p>
            <PaginationAnything
              key={refresh}
              className={`mt-3 -ml-2 -mr-2 flex min-h-[660px] max-w-6xl flex-wrap content-baseline ${
                loadRecently === true && ' hidden '
              }`}
              fetch={useGet.bind(null, `/lessonimports`, {
                organisationId: `${authUser.activeOrganisationId},OR,organisationId=null`,
                qtag:
                  category &&
                  `%25category:${encodeURIComponent(category?.name)}%25`,
                onSuccess: (result: any) => {
                  setLoadRecently(false);
                },
              })}
              defaultSort={[{ id: 'createdAt' }]}
              empty={{
                template: 2,
                loadContent: loadRecently,
                text: t('pages.create_library.library_empty'),
              }}
              page={0}
              pageSize={12}
              filter={filter}
              render={(lesson: any) => (
                <div
                  key={lesson?.id}
                  className="max-h-[220px] min-h-[220px] basis-1/2 overflow-hidden px-2 md:basis-1/3 lg:basis-1/4"
                >
                  <div
                    className="group cursor-pointer pb-4"
                    onClick={async () => {
                      await axios.post(`${config.backend}/libraryactivity`, {
                        lessonId: lesson?.lessonId,
                        organisationId: authUser?.activeOrganisationId,
                        coursecontentId: lesson?.coursecontentId,
                        userId: authUser.id,
                        authorId: lesson?.authorId,
                      });
                      navigate(
                        `${basePath}/lesson/${lesson?.lessonId}?type=lesson`
                      );
                    }}
                  >
                    <div className="flex flex-col">
                      <div className="relative mb-2 overflow-hidden rounded-lg">
                        <img
                          src={
                            lesson?.publicationImage
                              ? lesson?.publicationImage
                              : '/assets/images/opleidingen.jpg'
                          }
                          alt={lesson?.name}
                          className="h-28 w-full rounded-md object-cover"
                        />
                        {(lesson?.organisationId === 18 ||
                          lesson?.organisationId == null) && (
                          <img
                            src="/images/TEO_logo.svg"
                            className="absolute bottom-1 right-1 h-8 w-8"
                          />
                        )}
                        <div className="absolute inset-0 h-full w-full rounded-lg group-hover:backdrop-blur-sm"></div>
                        <span className="absolute inset-0 hidden items-center justify-center group-hover:flex">
                          <span className="rounded-md bg-grey-01 p-1 text-grey-09">
                            <Eye className="h-5 w-5 "></Eye>
                          </span>
                        </span>
                      </div>

                      <h3 className="text-base-semibold mb-2 break-words">
                        {lesson?.name || lesson?.assetName}
                      </h3>
                      <div className="flex flex-row items-center gap-3">
                        <span className="text-sm capitalize text-grey-04">
                          {lesson?.lessonType || lesson?.assetType}
                        </span>
                        {lesson?.estimatedTime > 0 && (
                          <span className="flex flex-row items-center gap-1 text-sm text-grey-04">
                            <TimeFilled className="inline h-5 w-5" />
                            <p className="pl-1">
                              {convertToTime(
                                Math.abs(lesson?.estimatedTime),
                                t('pages.learn_index.hour'),
                                t('pages.learn_index.minute')
                              )}
                            </p>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LibraryCreate;

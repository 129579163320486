import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getView } from '@/components/Froala/widgets/renderWidget';
import { newUid, round2 } from '@/util';
import { Tag, IconButton, Spinner } from '@teo/components';
import { ChevronUp, ChevronDown } from '@teo/components/icons';
import { FeedbackSection } from '@/components/Froala/FeedbackSection';
import { AskGPT } from './AskGPT';
import { AuthContext } from '@/components/Contexts/AuthContext';

const CorrectionWidgetSection = ({
  state,
  i,
  coorectionPage,
  resaltPage,
  score,
  setScore,
  userId,
  userLang,
  feedback,
  setFeedback,
  titleWidget,
  setXApiSubmitFn,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const authUser: any = auth?.user;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [updateFeedback, setUpdateFeedback] = useState(newUid(20));
  const [isLoading, setIsLoading] = useState(false);

  const View = getView(state.state);

  const div = document.createElement('div');
  div.innerHTML = state?.state?.vraag;
  const textContent = div.textContent || div.innerText;

  const useTeoAi = authUser?.useTeoAi;

  useEffect(() => {
    switch (state?.state?.type) {
      case 'FileUpload':
        coorectionPage && setIsOpen(true);
        break;
      case 'OpenVraag':
        coorectionPage && setIsOpen(true);
        break;
      default:
        setIsOpen(false);
        break;
    }
  }, [state]);

  let defaultTitleWidget;
  switch (state?.state?.type) {
    case 'InvulVraag':
      defaultTitleWidget = t('widgets.type.fill_blank');
      break;
    case 'MeerKeuze':
      defaultTitleWidget = t('widgets.type.multiple_choice_question');
      break;
    case 'OpenVraag':
      defaultTitleWidget = t('widgets.type.open_question');
      break;
    case 'MatrixVraag2':
      defaultTitleWidget = t('widgets.type.matrix_question');
      break;
    case 'OrderVraag':
      defaultTitleWidget = t('widgets.type.order_question');
      break;
    case 'Link':
      defaultTitleWidget = t('widgets.type.link_question');
      break;
    case 'NameImage2':
      defaultTitleWidget = t('widgets.type.name_parts_title');
      break;
    // case 'Math':
    //   View = MathView;
    //   break;
    case 'FileUpload':
      defaultTitleWidget = t('widgets.type.upload');
      break;
    case 'KofferVraag':
      defaultTitleWidget = t('widgets.type.box_question');
      break;
    case 'KofferVraag2':
      defaultTitleWidget = t('widgets.type.box_question');
      break;
    default:
      defaultTitleWidget = 'Widgets';
      break;
  }

  if (View) {
    return (
      <div className="relative my-1.5 rounded-lg border border-grey-02 bg-grey-01 py-2 px-2 sm:px-4">
        {isLoading && (
          <div className="absolute inset-0 z-[101] flex rounded-lg bg-white/75">
            <div className="m-auto">
              <Spinner
                ariaLabel="Loading spinner"
                className="!h-16 !w-16 border-grey-08"
              />
            </div>
          </div>
        )}
        <div className="flex items-center">
          <div className="mr-3 flex items-center">
            <Tag
              variant="secondary"
              size="md"
              title={i + 1}
              className="mr-2 w-fit bg-grey-02 text-grey-08"
            />
          </div>
          <div
            className={`  w-auto ${
              !isOpen ? 'mr-4 min-w-[30%] sm:hidden sm:line-clamp-1' : 'mr-auto'
            }`}
          >
            <p className="text-sm text-grey-08">
              {titleWidget || defaultTitleWidget}
            </p>
          </div>
          {!isOpen && (
            <div className="mx-auto hidden w-auto min-w-[30%] text-sm font-medium text-grey-08 sm:line-clamp-1">
              <p className="font-medium text-grey-08">{textContent}</p>
            </div>
          )}
          <div className="ml-auto flex items-center text-sm text-grey-05 sm:ml-3 md:text-base ">
            {t('pages.correction_result.score')}
            <input
              type="number"
              value={round2(score)}
              onClick={(e: any) => {
                e.target.select();
              }}
              onChange={(ev: any) => {
                setScore(parseFloat(ev.target.value));
              }}
              className="my-1 mx-1 h-8 w-10 rounded-md border border-solid border-transparent px-2 py-0 text-center text-sm text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02 sm:my-2 sm:mx-2 md:h-10 md:w-14 md:text-base"
            />
            /<span className=" mx-1 sm:mx-2">{state?.state?.score}</span>
          </div>
          <div className="ml-2 sm:ml-3">
            <IconButton
              Icon={isOpen ? ChevronUp : ChevronDown}
              variant="ghost"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          </div>
        </div>

        <div className={`${!isOpen && 'hidden'}`} id={state.state.uid}>
          <View
            setXApiSubmitFn={setXApiSubmitFn}
            key={state.state.uid}
            state={state.state}
            answer={state.answer}
            isCorrected={true}
            correction={state.correction}
            index={i + 1}
            viewOnly={resaltPage}
            resultPages={true}
            widgetAnswered={false}
          />
          {!resaltPage && state?.state?.type === 'OpenVraag' && useTeoAi && (
            <AskGPT
              state={state.state}
              answer={state.answer}
              userId={userId}
              setFeedback={setFeedback}
              setScore={setScore}
              setUpdateFeedback={setUpdateFeedback}
              setIsLoading={setIsLoading}
              userLang={userLang}
            />
          )}
          <FeedbackSection
            key={updateFeedback}
            feedback={feedback}
            setFeedback={setFeedback}
            viewOnly={resaltPage}
          />
        </div>
      </div>
    );
  } else {
    console.error('Unable to render', state.state, 'unknown view');
    return <></>;
  }
};

export { CorrectionWidgetSection };

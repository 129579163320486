import { LangData } from '@/models/langData.interface';

export const langData: LangData[] = [
  { name: 'Afrikaans', value: 'af', flag: true },
  { name: 'Albanian', value: 'sq', flag: true },
  { name: 'Amharic', value: 'am', flag: false },
  { name: 'Arabic', value: 'ar', flag: true },
  { name: 'Armenian', value: 'hy', flag: true },
  { name: 'Azerbaijani', value: 'az', flag: true },
  { name: 'Basque', value: 'eu', flag: true },
  { name: 'Belarusian', value: 'be', flag: true },
  { name: 'Bengali', value: 'bn', flag: true },
  { name: 'Bosnian', value: 'bs', flag: true },
  { name: 'Bulgarian', value: 'bg', flag: true },
  { name: 'Catalan', value: 'ca', flag: true },
  { name: 'Cebuano', value: 'ceb', flag: false },
  { name: 'Chinese (Simplified)', value: 'zh-CN', flag: true },
  { name: 'Chinese (Traditional)', value: 'zh-TW', flag: true },
  { name: 'Corsican', value: 'co', flag: true },
  { name: 'Croatian', value: 'hr', flag: true },
  { name: 'Czech', value: 'cs', flag: true },
  { name: 'Danish', value: 'da', flag: true },
  { name: 'Dutch', value: 'nl', flag: true },
  { name: 'English', value: 'en', flag: true },
  { name: 'Esperanto', value: 'eo', flag: false },
  { name: 'Estonian', value: 'et', flag: true },
  { name: 'Finnish', value: 'fi', flag: true },
  { name: 'French', value: 'fr', flag: true },
  { name: 'Frisian', value: 'fy', flag: false },
  { name: 'Galician', value: 'gl', flag: true },
  { name: 'Georgian', value: 'ka', flag: true },
  { name: 'German', value: 'de', flag: true },
  { name: 'Greek', value: 'el', flag: true },
  { name: 'Gujarati', value: 'gu', flag: true },
  { name: 'Haitian Creole', value: 'ht', flag: true },
  { name: 'Hausa', value: 'ha', flag: false },
  { name: 'Hawaiian', value: 'haw', flag: false },
  { name: 'Hebrew', value: 'he', flag: false },
  { name: 'Hindi', value: 'hi', flag: false },
  { name: 'Hmong', value: 'hmn', flag: false },
  { name: 'Hungarian', value: 'hu', flag: true },
  { name: 'Icelandic', value: 'is', flag: true },
  { name: 'Igbo', value: 'ig', flag: false },
  { name: 'Indonesian', value: 'id', flag: true },
  { name: 'Irish', value: 'ga', flag: true },
  { name: 'Italian', value: 'it', flag: true },
  { name: 'Japanese', value: 'ja', flag: true },
  { name: 'Javanese', value: 'jv', flag: false },
  { name: 'Kannada', value: 'kn', flag: true },
  { name: 'Kazakh', value: 'kk', flag: false },
  { name: 'Khmer', value: 'km', flag: true },
  { name: 'Kinyarwanda', value: 'rw', flag: true },
  { name: 'Korean', value: 'ko', flag: true },
  { name: 'Kurdish', value: 'ku', flag: false },
  { name: 'Kyrgyz', value: 'ky', flag: true },
  { name: 'Lao', value: 'lo', flag: false },
  { name: 'Latvian', value: 'lv', flag: true },
  { name: 'Lithuanian', value: 'lt', flag: true },
  { name: 'Luxembourgish', value: 'lb', flag: true },
  { name: 'Macedonian', value: 'mk', flag: true },
  { name: 'Malagasy', value: 'mg', flag: true },
  { name: 'Malay', value: 'ms', flag: true },
  { name: 'Malayalam', value: 'ml', flag: true },
  { name: 'Maltese', value: 'mt', flag: true },
  { name: 'Maori', value: 'mi', flag: false },
  { name: 'Marathi', value: 'mr', flag: true },
  { name: 'Mongolian', value: 'mn', flag: true },
  { name: 'Myanmar (Burmese)', value: 'my', flag: true },
  { name: 'Nepali', value: 'ne', flag: true },
  { name: 'Norwegian', value: 'no', flag: true },
  { name: 'Nyanja (Chichewa)', value: 'ny', flag: false },
  { name: 'Odia (Oriya)', value: 'or', flag: false },
  { name: 'Pashto', value: 'ps', flag: true },
  { name: 'Persian', value: 'fa', flag: false },
  { name: 'Polish', value: 'pl', flag: true },
  { name: 'Portuguese (Portugal)', value: 'pt', flag: true },
  { name: 'Portuguese (Brazil)', value: 'pt-br', flag: true },
  { name: 'Punjabi', value: 'pa', flag: true },
  { name: 'Romanian', value: 'ro', flag: true },
  { name: 'Russian', value: 'ru', flag: true },
  { name: 'Samoan', value: 'sm', flag: true },
  { name: 'Scots Gaelic', value: 'gd', flag: true },
  { name: 'Serbian', value: 'sr', flag: true },
  { name: 'Sesotho', value: 'st', flag: true },
  { name: 'Shona', value: 'sn', flag: true },
  { name: 'Sindhi', value: 'sd', flag: true },
  { name: 'Sinhala (Sinhalese)', value: 'si', flag: true },
  { name: 'Slovak', value: 'sk', flag: true },
  { name: 'Slovenian', value: 'sl', flag: true },
  { name: 'Somali', value: 'so', flag: true },
  { name: 'Spanish', value: 'es', flag: true },
  { name: 'Sundanese', value: 'su', flag: false },
  { name: 'Swahili', value: 'sw', flag: false },
  { name: 'Swedish', value: 'sv', flag: true },
  { name: 'Tagalog (Filipino)', value: 'tl', flag: true },
  { name: 'Tajik', value: 'tg', flag: true },
  { name: 'Tamil', value: 'ta', flag: true },
  { name: 'Tatar', value: 'tt', flag: true },
  { name: 'Telugu', value: 'te', flag: false },
  { name: 'Thai', value: 'th', flag: true },
  { name: 'Turkish', value: 'tr', flag: true },
  { name: 'Turkmen', value: 'tk', flag: true },
  { name: 'Ukrainian', value: 'uk', flag: true },
  { name: 'Urdu', value: 'ur', flag: false },
  { name: 'Uyghur', value: 'ug', flag: true },
  { name: 'Uzbek', value: 'uz', flag: true },
  { name: 'Vietnamese', value: 'vi', flag: true },
  { name: 'Welsh', value: 'cy', flag: true },
  { name: 'Xhosa', value: 'xh', flag: false },
  { name: 'Yiddish', value: 'yi', flag: false },
  { name: 'Yoruba', value: 'yo', flag: false },
  { name: 'Zulu', value: 'zu', flag: false },
].map((x, i) => Object.assign(x, { id: i })) as LangData[];

//console.log(langData.map(x => `"${x.value}": "${x.name}"`).join(",\n")); //export for translation files

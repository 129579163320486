import { XAPIService } from '@/services/xapi.service.js';

const genericXapiSubmit = (
  state,
  answer,
  correction,
  answerStr,
  correctStr,
  lessonId,
  lang,
  minScore = 0.5
) => {
  const testId = lessonId;
  const questionId = state.uid;

  const success = correction.score >= state.score * minScore;
  const name = {
    [lang]: state?.titleWidget,
  };
  const description = {
    [lang]: state?.vraag,
  };

  let xapi = XAPIService.getInstance();

  xapi.cmi5.interactionOther(
    testId,
    questionId,
    answerStr,
    correctStr,
    name,
    description,
    success
  );
};

export { genericXapiSubmit };

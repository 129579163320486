import { useGet } from '@/query/fetchHooks';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { config } from '@/config';
import { CorrectionWidgetSection } from './CorrectionWidgetSection';
import { format } from 'date-fns';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { InputDate, Button, Spinner } from '@teo/components';
import { axios } from '@/axios';
import { syncStates } from '@/components/Froala/widgets/renderWidget';
import {
  nthLastIndexOf,
  round2,
  deep_copy,
  parseCourseType,
  parseNumber,
} from '@/util';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { XAPIService } from '@/services/xapi.service.js';
import i18n from 'i18next';
import RedoModal from '../RedoModal';
import { TinCanService } from '@/services/tincan.service';

export const CorrectionContent = ({
  studentId,
  courseId,
  lessonId,
  lesson,
  coorectionPage,
  resaltPage,
  onSubmit = () => {},
  isTeacher,
  course,
  setCourse,
  student,
  setStudent,
  setLesson,
  setLessonStatus,
  lessonStatus,
  setPreloader,
  setPreloaderNext,
  preloaderNext,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [correction, setCorrection] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  const [feedback, setFeedback] = useState<any>(undefined);
  const [submitScore, setSubmitScore] = useState<any>({});
  const [widgetFeedback, setWidgetFeedback] = useState<any>({});
  const [maxScore, setMaxScore] = useState({});
  const [newData, setNewData] = useState(new Date());
  const [widgets, setWidgets] = useState(false);
  const [updateFeetback, setUpdateFeetback] = useState(false);
  const [isOpenRedoModal, setIsOpenRedoModal] = useState(false);

  const xApiSubmitFns: any = {};

  const url = useParams();

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );

  useGet(`/coursedetail/${courseId}`, {
    onSuccess: (result: any) => {
      setCourse(result?.data || null);
    },
  });
  useGet(`/student/${courseId}/${studentId}`, {
    onSuccess: (result: any) => {
      setStudent(result?.data || null);
    },
  });
  useGet(`/usedcourselesson/${courseId}/${lessonId}`, {
    onSuccess: (result: any) => {
      setLesson(result?.data || null);
    },
  });
  useGet(
    `/lessonstatuses?courseId=${courseId}&userId=${studentId}&lessonId=${lessonId}`,
    {
      onSuccess: (result: any) => {
        setLessonStatus(result?.data[0] || null);
      },
    }
  );

  useGet(
    `${config.teodoc}/corrections/list2?lessonId=${lessonId}&userId=${studentId}&courseId=${courseId}`,
    {
      enabled: lessonStatus?.status === 'corrected',
      onSuccess: (result: any) => {
        setFeedback(result?.data[0]?.correction?.feedback || null);
        setCorrection(result?.data[0] || null);
      },
    }
  );

  useGet(
    `${config.teodoc}/results/list2?lessonId=${lessonId}&userId=${studentId}&courseId=${courseId}&submitted=true`,
    {
      enabled: lessonStatus?.status !== 'corrected',
      onSuccess: (result: any) => {
        setFeedback(null);
        setResult(result?.data[0] || null);
      },
    }
  );

  useEffect(() => {
    if (result || correction) {
      setUpdateFeetback(false);
      const newWidgets = deep_copy(correction?.widgets || result?.widgets);
      const widgetArr = Object.values<any>(newWidgets).filter(
        (widget) => !widget.state.noSubmit
      );

      for (const widget of widgetArr) {
        if (widget.state.noSubmit) continue;
        widget.answer.answered = true;
        if (!widget?.correction) {
          widget.correction = {};
          syncStates(widget.state, widget.answer, widget.correction);
        }
      }
      setWidgets(deep_copy(newWidgets));
      setMaxScore(
        widgetArr.reduce((acc, widget) => {
          acc[widget.state.uid] = widget.state?.score || 0;
          return acc;
        }, {})
      );

      setSubmitScore(
        widgetArr.reduce((acc, widget) => {
          acc[widget.state.uid] = parseNumber(
            widget.correction?.score,
            widget.state?.score || 0
          );
          return acc;
        }, {})
      );
      setWidgetFeedback(
        widgetArr.reduce((acc, widget) => {
          acc[widget.state.uid] = widget.correction?.feedback;
          return acc;
        }, {})
      );
    }
  }, [result, correction]);

  useEffect(() => {
    if (widgets) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setUpdateFeetback(true);
      setPreloaderNext(true);
      setPreloader(true);
    }
  }, [widgets]);

  const correctionState = correction || result;

  const handleSubmit = async () => {
    const toSubmit = {
      ...(correction || result),
      feedback: feedback ? feedback : correction?.feedback,
      releaseDate: newData ? newData : correctionState?.releaseDate,
      teacherId: auth?.user?.id,
      teacherName: auth?.user?.username,
    };
    if (widgets) {
      toSubmit.widgets = widgets;
    }
    for (const key of Object.keys(toSubmit.widgets)) {
      if (toSubmit.widgets[key].state.noSubmit) {
        delete toSubmit.widgets[key];
        delete submitScore[key];
        delete widgetFeedback[key];
      }
    }
    for (const key in submitScore) {
      toSubmit.widgets[key].correction.score = submitScore[key];
    }
    toSubmit.score = Object.values(submitScore).reduce(
      (accumulator: any, value: any) => {
        return accumulator + parseFloat(value);
      },
      0
    );
    toSubmit.maxScore = Object.values(widgets).reduce(
      (accumulator: any, widget: any) => {
        return accumulator + parseFloat(widget?.state?.score || 0);
      },
      0
    );
    for (const key in widgetFeedback) {
      toSubmit.widgets[key].correction.feedback = widgetFeedback[key];
    }

    const xapi = XAPIService.getInstance();
    for (const key of Object.keys(toSubmit.widgets)) {
      if (xapi.available() && xApiSubmitFns[key]) {
        const submitFn = xApiSubmitFns[key];
        try {
          submitFn?.(toSubmit.lessonId, i18n.language, course?.minScore || 0.5);
        } catch (e) {
          console.error(e);
        }
      }
    }

    axios.post(`${config.backend}/notifications`, {
      text: {
        text: t('notifications.teacher_corrected', {
          nameLesson: lesson?.name,
        }),
        icon: true,
      },
      type: 'lessoncorrected',
      globalLink: `/:lang/learn/:organisationId/${parseCourseType(
        course?.courseType
      )}/${courseId}/lesson/${lessonId}`,
      userId: parseInt(studentId, 10),
      subject: parseInt(lessonId, 10),
    });

    await axios.post(`${config.teodoc}/corrections`, toSubmit);

    if (xapi.available()) {
      await axios
        .get(`${config.backend}/follow/${courseId}/${studentId}`)
        .then((result) => {
          const follows = result?.data;
          if (
            follows &&
            follows.progress >= 0.99 &&
            follows.tasksRemaining === 0
          ) {
            xapi.reportFinal(follows, course);
          }
        });
    }

    const xapidetails = JSON.parse(student?.xapidetails as string);
    const tincan = TinCanService.getInstance();
    tincan.initialize(xapidetails);

    if (tincan.available()) {
      await axios
        .get(`${config.backend}/follow/${courseId}/${studentId}`)
        .then((result) => {
          const follows = result?.data;
          if (
            follows &&
            follows.progress >= 0.99 &&
            follows.tasksRemaining === 0
          ) {
            tincan.reportFinalAgristo(follows, course);
          }
        });
    }

    onSubmit();
  };

  const goToEdit = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const viewLes = queryParams.get('view_les');
    navigate(
      `${basePath}/correction/${url?.courseId}/${url?.studentId}/${
        url?.lessonId
      }${viewLes ? '?view_les=true' : ''}`
    );
  };

  const handleComments = (ev: any) => {
    setFeedback(ev.target.value);
  };
  const handleResetTimeSpent = async () => {
    await axios.put(
      `${config.backend}/lessonstatus/${courseId}/${studentId}/${lessonId}`,
      {
        courseId: courseId && parseInt(courseId, 10),
        lessonId: lessonId && parseInt(lessonId, 10),
        timeSpent: 0,
        userId: studentId && parseInt(studentId, 10),
      }
    );
  };

  const widgets_id = correction?._id || result?._id;

  const sumMaxScore: any = Object.values(widgets).reduce(
    (accumulator: any, widget: any) => {
      return accumulator + parseFloat(widget?.state?.score || 0);
    },
    0
  );
  const sumScore: any = Object.values(submitScore).reduce(
    (accumulator: any, value: any) => {
      return accumulator + parseFloat(value || 0);
    },
    0
  );

  if (!preloaderNext)
    return (
      <div className="absolute inset-0 z-[11] flex bg-white">
        <div className="m-auto">
          <Spinner
            ariaLabel="Loading spinner"
            className="!h-16 !w-16 border-grey-08"
          />
        </div>
      </div>
    );

  return lessonStatus?.status === 'corrected' || coorectionPage ? (
    <div
      key={lessonId}
      className="mx-auto mb-8 flex w-full max-w-4xl flex-col px-3"
    >
      {Object.values(widgets || {}).map((state: any, i) => {
        if (state.state.noSubmit) return null;
        return (
          <CorrectionWidgetSection
            setXApiSubmitFn={(fn: any) => {
              xApiSubmitFns[state.state.uid] = fn;
            }}
            key={state.state.uid}
            state={state}
            i={i}
            coorectionPage={coorectionPage}
            resaltPage={resaltPage}
            score={submitScore[state.state.uid]}
            setScore={(score: any) => {
              setSubmitScore(
                Object.assign({}, submitScore, {
                  [state.state.uid]: parseFloat(score),
                })
              );
            }}
            userId={auth?.user?.id}
            userLang={auth?.user?.lang}
            feedback={widgetFeedback[state.state.uid]}
            titleWidget={state.state.titleWidget}
            setFeedback={(feedback: any) => {
              setWidgetFeedback(
                Object.assign({}, widgetFeedback, {
                  [state.state.uid]: feedback,
                })
              );
            }}
          />
        );
      })}
      {sumMaxScore ? (
        <div className="mt-7 flex items-center text-base text-secondary-04">
          {t('pages.correction_result.total_score')}
          {coorectionPage ? (
            <span className="ml-3 mr-1 font-semibold">
              {sumScore ? round2(sumScore) : 0}/{sumMaxScore}
            </span>
          ) : (
            <span className="ml-3 mr-1 font-semibold">
              {lessonStatus?.score ? round2(lessonStatus?.score) : 0}/
              {sumMaxScore}
            </span>
          )}
        </div>
      ) : null}

      {correction?.feedback && resaltPage ? (
        <div className="border-transparen my-5 my-2 rounded-md border border-solid bg-grey-transparent-01 p-3 text-sm text-grey-08">
          <h3 className="text-base text-grey-05">
            {t('pages.correction_result.comment')}
          </h3>
          <div className="my-3">
            <FroalaTextareaView value={correction?.feedback} />
          </div>
          <span className=" text-sm text-grey-06">
            {format(
              new Date(
                correction?.releaseDate || correction?.submitDate || new Date()
              ),
              'dd/MM/yyyy'
            )}
          </span>
        </div>
      ) : null}

      {coorectionPage && isTeacher ? (
        <div className="my-5">
          <p className="mb-1 text-sm font-semibold text-black">
            {t('pages.correction_result.comment_lesson')}
          </p>
          {feedback !== undefined ? (
            <div className="">
              {updateFeetback && (
                <FroalaTextarea
                  mode="edit"
                  value={feedback ? feedback : correction?.feedback}
                  onChange={(ev: any) => {
                    handleComments(ev);
                  }}
                />
              )}
            </div>
          ) : null}
          <div className="mt-5 flex max-w-[250px] flex-col">
            <p className="mb-1 text-sm font-semibold text-black">
              {t('pages.correction_result.date_release')}
            </p>
            <InputDate
              id="date-input"
              onChange={(e: any) => {
                setNewData(e);
              }}
              value={newData}
              variant="datetime-local"
            />
          </div>
          <div className="mt-5 flex max-w-min flex-col gap-3 sm:max-w-full sm:flex-row">
            <Button variant="outline" onClick={() => setIsOpenRedoModal(true)}>
              {t('pages.correction_result.btn_reset')}
            </Button>
            <Button className="sm:ml-auto" onClick={handleSubmit}>
              {t('pages.correction_result.btn_improvement')}
            </Button>
          </div>
        </div>
      ) : null}

      {resaltPage && isTeacher ? (
        <div className="my-5">
          <div className="mt-5 flex max-w-min flex-col gap-3 sm:max-w-full sm:flex-row">
            <Button variant="outline" onClick={() => setIsOpenRedoModal(true)}>
              {t('pages.correction_result.btn_reset')}
            </Button>
            <Button className="sm:ml-auto" onClick={goToEdit}>
              {t('pages.correction_result.btn_go_edit')}
            </Button>
          </div>
        </div>
      ) : null}

      {isOpenRedoModal && (
        <RedoModal
          closeModal={() => setIsOpenRedoModal(false)}
          setIsOpenRedoModal={setIsOpenRedoModal}
          isCourse={false}
          courseId={courseId}
          lessonStatus={lessonStatus}
          widgets_id={widgets_id}
          handleResetTimeSpent={handleResetTimeSpent}
          lessonId={lessonId}
          studentId={studentId}
          onSubmit={onSubmit}
        />
      )}
    </div>
  ) : (
    <p className="mx-auto mb-8 flex w-full max-w-4xl flex-col px-3 text-center text-sm font-semibold">
      The student has submitted this but it it has not yet been corrected
    </p>
  );
};

import { Button, IconButton } from '@teo/components';
import { useEffect, useState, Fragment, useContext } from 'react';
import { TheoWave } from '@teo/components/illustrations';
import { CrossFilled } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { hintsData } from './hintsData';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { newUid } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';

const HintsMessage = () => {
  const { t } = useTranslation();
  const { auth, setAuth } = useContext(AuthContext);
  const { hints, setHints } = useContext(HintsContext);
  const { setNewPage } = useContext(PageUrlContext);
  const authUser = auth?.user;

  const [showTour, setShowTour] = useState(false);

  useEffect(() => {
    const hintsPage = hints?.pageHints;
    const matchingHint: any = hintsPage?.find((div: any) => {
      const order = parseInt(div.getAttribute('data-order'), 10);
      return hints?.allHints[order] == 0; // Порівнюємо з масивом
    });
    if (matchingHint) {
      setShowTour(true);
    }
  }, [hints]);

  const handleSkipAll = async () => {
    const allHits = hintsData?.length;
    const updatedHintFlags = hints?.allHints?.map((value: any, index: any) => {
      if (index < allHits && value === '0') {
        return '1';
      }
      return value;
    });

    const promises: any = [];
    promises.push(
      axios.put(`${config.backend}/user/${authUser?.id}`, {
        hintFlags: updatedHintFlags?.join(''),
        id: authUser?.id,
        hintMessage: true,
      })
    );
    await Promise.allSettled(promises).then(() => {
      setHints((prev: any) => ({
        ...prev,
        id: null,
        active: null,
        allHints: updatedHintFlags,
        pageHints: [],
      }));
      setAuth({
        user: Object.assign({}, auth?.user, {
          hintFlags: updatedHintFlags?.join(''),
          hintMessage: true,
        }),
        token: auth?.token,
      });
      setNewPage(newUid(20));
    });
  };

  const handleStartTour = () => {
    const hintsPage = hints?.pageHints;
    const matchingHint: any = hintsPage?.find((div: any) => {
      const order = parseInt(div.getAttribute('data-order'), 10);
      return hints?.allHints[order] == 0; // Порівнюємо з масивом
    });

    if (matchingHint) {
      setHints((prev: any) => ({
        ...prev,
        id: matchingHint.id,
        active: 1,
      }));
    }

    if (authUser?.id) {
      axios.put(`${config.backend}/user/${authUser?.id}`, {
        hintMessage: true,
      });
      setAuth({
        user: Object.assign({}, auth?.user, {
          hintMessage: true,
        }),
        token: auth?.token,
      });
    }
  };

  // if (!selectList) return null;

  const formattedString = t('hint_message.titel').replace(/\/n/g, '<br>');

  return (
    <div className="pointer-events-auto absolute top-12 left-1/2 z-[103] -translate-x-1/2 overflow-hidden rounded-lg border bg-white text-left">
      <div className="flex w-[720px]">
        <IconButton
          Icon={CrossFilled}
          className="absolute top-3 right-3"
          variant="ghost"
          onClick={() => {
            if (authUser?.id) {
              axios.put(`${config.backend}/user/${authUser?.id}`, {
                hintMessage: true,
              });
              setAuth({
                user: Object.assign({}, auth?.user, {
                  hintMessage: true,
                }),
                token: auth?.token,
              });
            }
          }}
        />
        <div className="flex flex-col bg-grey-01 p-10 sm:basis-1/2">
          <h3
            className="text-xl text-black"
            dangerouslySetInnerHTML={{ __html: formattedString }}
          ></h3>
          <p className="py-4 text-sm font-normal leading-[22px] text-grey-08">
            {t('hint_message.text_1')}
          </p>
          <span className="block h-3 w-3 cursor-pointer rounded-full bg-tint-indigo"></span>
          <p className="py-4 text-sm font-normal leading-[22px] text-grey-08">
            {t('hint_message.text_2')}
          </p>
          <div className="mt-8 flex gap-2">
            {showTour && (
              <Button
                className=""
                variant="primary"
                onClick={() => handleStartTour()}
              >
                {t('hint_message.btn_start_tour')}
              </Button>
            )}
            <Button
              className=""
              variant="outline"
              onClick={() => handleSkipAll()}
            >
              {t('hint.popup_skip.btn_skip_all')}
            </Button>
          </div>
        </div>
        <div className={`order-none flex p-6 sm:order-1 sm:basis-1/2`}>
          <TheoWave className="m-auto max-h-[220px] max-w-[220px] sm:max-h-[225px] sm:max-w-[225px]" />
        </div>
      </div>
    </div>
  );
};

export default HintsMessage;

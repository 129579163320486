import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { InputText, Button } from '@teo/components';
import { Lerningpathorganisationdetails } from '@/models';
import { axios } from '@/axios';
import { config } from '@/config';

function SuppottTab({ auth }: any) {
  const [freshdeskId, setfreshdeskId] = useState<any>();
  const [creditCost, setCreditCost] = useState<any>();
  const [supportCost, setSupportCost] = useState<any>();
  const [availableCredits, setAvailableCredits] = useState<any>();
  const [send, setSend] = useState<boolean>();

  const { t } = useTranslation();
  const userRole = auth?.user?.role || 0;
  const organisationId = auth?.user?.activeOrganisationId || 0;

  const disable = userRole < 90 || !send;

  useGet<Lerningpathorganisationdetails>(`/organisation/${organisationId}`, {
    onSuccess: (result: any) => {
      setfreshdeskId(result?.data.freshdeskId || null);
      setAvailableCredits(result?.data.availableCredits || null);
    },
  });

  useGet(`/license/${organisationId}`, {
    onSuccess: (result: any) => {
      setCreditCost(result?.data.creditCost || null);
      setSupportCost(result?.data.supportCost || null);
    },
  });

  const handleEdit = async () => {
    const updateOrg = await axios.put(
      `${config.backend}/organisation/${organisationId}`,
      {
        freshdeskId: freshdeskId,
        availableCredits: availableCredits,
      }
    );
    setfreshdeskId(updateOrg?.data?.freshdeskId);
    setAvailableCredits(updateOrg?.data?.availableCredits);
    const updateLicense = await axios.put(
      `${config.backend}/license/${organisationId}`,
      {
        creditCost: creditCost,
        supportCost: supportCost,
      }
    );
    setCreditCost(updateLicense?.data?.creditCost);
    setSupportCost(updateLicense?.data?.supportCost);
    setSend(false);
  };

  return (
    <div className="mt-6 rounded-lg border border-grey-02 p-6">
      <div className="divide-y divide-solid divide-grey-02">
        <div className="pb-8">
          <p className="text-md font-semibold text-black">
            {t('pages.organisation_licensing_and_billing.support.title')}
          </p>
          <p className="mb-1 mt-6 text-sm font-semibold text-black">
            {t('pages.organisation_licensing_and_billing.support.freshdeskID')}
          </p>
          <InputText
            id="text-input"
            disabled={userRole < 90}
            onChange={(e) => {
              setfreshdeskId(e.currentTarget.value);
              setSend(true);
            }}
            value={freshdeskId}
            placeholder={t(
              'pages.organisation_licensing_and_billing.support.freshdeskID'
            )}
            className="max-w-[440px]"
          />
        </div>
        <div className="py-8">
          <p className="text-md font-semibold text-black">
            {t('pages.organisation_licensing_and_billing.support.credits')}
          </p>
          <p className="mb-1 mt-6 text-sm font-semibold text-black">
            {t('pages.organisation_licensing_and_billing.support.monthly_fee')}
          </p>
          <InputText
            Icon={() => (
              <p className="pointer-events-none absolute top-2 left-3 h-5  w-5 text-center">
                €
              </p>
            )}
            disabled={userRole < 90}
            onChange={(e) => {
              setSupportCost(e.currentTarget.value);
              setSend(true);
            }}
            value={supportCost}
            className="max-w-[440px]"
          />
          <p className="mb-1 mt-6 text-sm font-semibold text-black">
            {t('pages.organisation_licensing_and_billing.support.available')}
          </p>
          <InputText
            disabled={userRole < 90}
            onChange={(e) => {
              setAvailableCredits(e.currentTarget.value);
              setSend(true);
            }}
            value={availableCredits}
            className="max-w-[440px]"
            placeholder="0"
          />
          <p className="mb-1 mt-6 text-sm font-semibold text-black">
            {t('pages.organisation_licensing_and_billing.support.price_per')}
          </p>
          <InputText
            Icon={() => (
              <p className="pointer-events-none absolute top-2 left-3 h-5  w-5 text-center">
                €
              </p>
            )}
            disabled={userRole < 90}
            onChange={(e) => {
              setCreditCost(e.currentTarget.value);
              setSend(true);
            }}
            value={creditCost}
            className="max-w-[440px]"
          />
        </div>
        <div className="flex py-8">
          <Button onClick={handleEdit} disabled={disable}>
            {t('pages.organisation_details.button_edit')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SuppottTab;

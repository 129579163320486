import { useState, useEffect, createRef } from 'react';
import { createRoot } from 'react-dom/client';
import {
  isEmpty,
  stringRelativeDistance,
  newUid,
  realParseFloat,
  round2,
  focusWidget,
  parseNumber,
} from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { Check } from '@teo/components/icons';
import { FeedbackSection } from '../FeedbackSection';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { XAPIService } from '@/services/xapi.service.js';

const InputField = ({
  placeholder,
  baseWidth,
  answered,
  answer,
  isCorrect,
  myAnswer,
  setMyAnswer,
  showAnswers = false,
}) => {
  const [value, setValue] = useState(
    myAnswer[placeholder.uid] ? myAnswer[placeholder.uid] : ''
  );
  const { t } = useTranslation();

  const classInput =
    'my-2 h-10 w-full min-w-[95px] rounded-md border border-solid border-transparent py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02';

  let placeholderStr = placeholder.value;
  if (placeholder.type === 'numeric') {
    if (placeholder.min !== undefined && placeholder.max !== undefined) {
      if (placeholder.min === placeholder.max) {
        placeholderStr = `${placeholder.min}`;
      } else {
        placeholderStr = `${t('widgets.fillin.between')} ${placeholder.min} ${t(
          'widgets.fillin.and'
        )} ${placeholder.max}`;
      }
    } else if (placeholder.min !== undefined) {
      placeholderStr = `${t('widgets.fillin.larger')} ${placeholder.min}`;
    } else if (placeholder.max !== undefined) {
      placeholderStr = `${t('widgets.fillin.smaller')} ${placeholder.max}`;
    }
  }

  return (
    <div
      className="relative !w-full min-w-[95px] max-w-[200px] px-2 sm:max-w-none"
      style={{
        display: 'inline-block',
        width:
          '' +
          (showAnswers
            ? placeholderStr.length
            : Math.max(
                value?.length ? value?.length : 1,
                baseWidth * (answered ? 2 : 2)
              )) +
          'ch',
      }}
    >
      {placeholder.type === 'numeric' ? (
        <>
          <input
            type="text"
            pattern="\s*[+\-]?^\s*[0-9]*([.,][0-9]+)?\s*$"
            inputMode="numeric"
            //id={placeholder?.uid}
            data-id={placeholder?.uid}
            value={showAnswers ? placeholderStr : value ? value : ''}
            placeholder={
              !placeholder?.prec ? '0' : '0.' + '0'.repeat(placeholder?.prec)
            }
            onChange={({ target: { value } }) => {
              if (!/^\s*[+-]?\s*[0-9]*([.,][0-9]*)?\s*$/.test(value)) return;
              setValue(value);
              setMyAnswer((prev) => ({
                ...prev,
                [placeholder?.uid]: parseFloat(value.replace(/,/g, '.')),
              }));
            }}
            disabled={answered}
            className={`${answered ? 'pl-3 pr-8' : 'px-3'} ${classInput} ${
              answered && !isCorrect ? '!border-error-04 !bg-white' : ''
            }`}
          />
          {answered && isCorrect ? (
            <Check className="absolute top-4 right-1.5 w-6 text-success-05" />
          ) : null}
        </>
      ) : (
        <>
          <input
            //id={placeholder?.uid}
            data-id={placeholder?.uid}
            type="text"
            placeholder={t('widgets.answer')}
            value={showAnswers ? placeholderStr : value ? value : ''}
            onChange={({ target: { value } }) => {
              setValue(value);
              setMyAnswer((prev) => ({ ...prev, [placeholder?.uid]: value }));
            }}
            disabled={answered}
            className={`${answered ? 'pl-3 pr-8' : 'px-3'} ${classInput} ${
              answered && !isCorrect ? '!border-error-04 !bg-white' : ''
            }`}
          />
          {answered && isCorrect ? (
            <Check className="absolute top-4 right-1.5 w-6 text-success-05" />
          ) : null}
        </>
      )}
    </div>
  );
};

export const InvulVraagView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setXApiSubmitFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();
  const [uid, setUid] = useState(newUid(20));
  const myRef = createRef();
  const [modified, setModified] = useState(null);

  answer ||= {};
  correction ||= {};

  InvulVraagView.syncStates(state, answer, correction);

  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [score, setScore] = useState(correction?.score);

  const answered = submitted || isCorrected;

  useEffect(() => {
    if (submitted) {
      answer = getAnswer();
      correction = {};
      InvulVraagView.syncStates(state, answer, correction);
      if (!answer?.answered) {
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  const [myAnswer, setMyAnswer] = useState(
    Object.values(answer.answers).reduce((acc, answer) => {
      acc[answer.uid] = answer.answer;
      return acc;
    }, {})
  );

  const update = () => {
    let placeholders = state.placeholders;
    for (let placeholder of Object.values(placeholders)) {
      let baseWidth = 5;
      if (placeholder.type == 'text') {
        if (placeholder.value && placeholder.value.length) {
          baseWidth = placeholder.value.length;
        }
      } else if (placeholder.type == 'numeric') {
        baseWidth = 2 + (parseFloat(placeholder.prec) || 1);
        if (parseFloat(placeholder.max)) {
          baseWidth += Math.log10(Math.ceil(Math.abs(placeholder.max)));
        } else if (parseFloat(placeholder.min)) {
          baseWidth += Math.log10(Math.ceil(Math.abs(placeholder.min)));
        }
      }
      let node = window.$(
        `#${CSS.escape(uid)} #${CSS.escape(placeholder.uid)}`
      )[0];

      let labels = [];
      for (let key of Object.keys(state?.placeholders)) {
        let placeholder = state?.placeholders[key];
        labels[key] = answer?.answers[key]?.answer || '';
        if (answered && !correction?.answers[key]?.isCorrect) {
          if (placeholder.type === 'numeric') {
            if (
              state.placeholders[placeholder.uid].min ===
              state.placeholders[placeholder.uid].max
            ) {
              labels[key] = `${t('widgets.fillin.correct')} ${
                state.placeholders[placeholder.uid].min
              } (${t('widgets.fillin.not')} ${
                labels[key] ? labels[key] : t('widgets.fillin.empty')
              })`;
            } else {
              labels[key] = `${t('widgets.fillin.between')} ${
                state.placeholders[placeholder.uid].min
              } ${t('widgets.fillin.and')} ${
                state.placeholders[placeholder.uid].max
              } (${t('not')} ${
                labels[key] ? labels[key] : t('widgets.fillin.empty')
              })`;
            }
          } else {
            labels[key] = `${state.placeholders[placeholder.uid].value} (${t(
              'widgets.fillin.not'
            )} ${labels[key] ? labels[key] : t('widgets.fillin.empty')})`;
          }
        }
      }

      if (node) {
        createRoot(node).render(
          <div className="inline-block">
            <InputField
              showAnswers={showAnswers}
              placeholder={placeholder}
              baseWidth={baseWidth}
              answered={answered}
              answer={answer?.answers[placeholder?.uid]}
              isCorrect={correction?.answers?.[placeholder?.uid]?.isCorrect}
              myAnswer={myAnswer}
              setMyAnswer={(val) => {
                setMyAnswer(val);
                setModified(newUid(20));
              }}
            />
          </div>
        );
      }

      if (
        node &&
        answered &&
        !correction?.answers[placeholder?.uid]?.isCorrect
      ) {
        let node_label = window.$(
          `#${CSS.escape(answer?.answers[placeholder?.uid].uid) + '_label'}`,
          myRef.current
        )[0];

        if (!node_label) {
          const el = document.createElement('div');
          el.textContent = labels[placeholder?.uid];
          el.className = 'text-error-04 mb-3';
          el.id = answer?.answers[placeholder?.uid].uid + '_label';
          {
            node.parentNode.after(el);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (state) {
      update();
    }
  }, [state, answered]);

  //generate the answer
  const getAnswer = () => {
    const answers = Object.keys(myAnswer)
      .map((key) => ({ uid: key, answer: myAnswer[key] }))
      .reduce((acc, el) => {
        acc[el.uid] = el;
        return acc;
      }, {});
    const hasNonEmptyAnswer = Object.values(answers).some(
      (item) => item.answer !== '' && item.answer !== null
    );
    answer.answers = answers;
    answer.incomplete = !submitted && !hasNonEmptyAnswer;
    answer.submitted = submitted;
    answer.uid = state?.uid;

    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    let correction = {};
    InvulVraagView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = (lessonId, lang) => {
    let correction = {};
    let answer = getAnswer();
    InvulVraagView.syncStates(state, answer, correction);
    const testId = lessonId;
    const questionId = state.uid;

    //sorts answers by keys and pused them on array
    const answers = Object.keys(answer.answers)
      .sort()
      .reduce((obj, key) => {
        obj.push(answer.answers[key].answer || t('widgets.fillin.empty'));
        return obj;
      }, []);

    //sorts placeholders by keys and pushes them on array
    const correctAnswers = Object.keys(state.placeholders)
      .sort()
      .reduce((obj, key) => {
        let value;
        if (state.placeholders[key]?.type === 'numeric') {
          if (
            state.placeholders[key]?.min !== undefined ||
            state.placeholders[key]?.max !== undefined
          ) {
            if (state.placeholders[key]?.min === state.placeholders[key]?.max) {
              value = '' + state.placeholders[key]?.max;
            } else {
              value =
                (state.placeholders[key]?.min || '') +
                '-'(state.placeholders[key]?.max || '');
            }
          } else {
            value = t('widgets.fillin.any');
          }
        } else {
          value =
            state.placeholders[key]?.value || t('widgets.fillin.any_string');
        }
        obj.push(value);
        return obj;
      }, []);

    const success = correction.score >= state.score * 0.5;
    const name = {
      [lang]: state?.titleWidget || t('widgets.type.multiple_choice_question'),
    };
    const description = {
      [lang]: state?.vraag,
    };

    let xapi = XAPIService.getInstance();
    xapi.cmi5.interactionFillIn(
      testId,
      questionId,
      answers,
      correctAnswers,
      name,
      description,
      success
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  useEffect(() => {
    onModified && onModified();
  }, [myAnswer, onModified]);

  return (
    <div
      ref={myRef}
      id={uid}
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      onFocus={() => focusWidget(state?.uid)}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(score)}
          maxScore={state.score}
          answered={answered}
          titleWidget={state.titleWidget || t('widgets.type.fill_blank')}
        />
      )}
      <FroalaTextareaView value={state.vraag} />
      {(state.immediateFeedback || correctionType === 'autofeedback') && (
        <PartialSubmitSection
          setSubmitted={setSubmitted}
          setModified={setModified}
          answered={answered}
          onSave={onSave.bind(null, false, false, state)}
        />
      )}
      {(resultPages || showAnswers || answered || submitted) &&
      state?.antwoord ? (
        <ExplanationSection state={state} />
      ) : null}
    </div>
  );
};

//this function should work as a correction/score function
InvulVraagView.syncStates = (state, answer, correction) => {
  let answerInvalid =
    isEmpty(answer) ||
    Object.keys(answer.answers).length !=
      Object.keys(state.placeholders).length;

  if (!answerInvalid) {
    for (let uid of Object.keys(state.placeholders)) {
      if (!answer?.answers?.[uid]) {
        answerInvalid = true;
      }
    }
  }

  if (answerInvalid) {
    answer.uid = state.uid;
    answer.answers = Object.values(state.placeholders).reduce((copy, el) => {
      copy[el.uid] = { uid: el.uid, answer: null };
      return copy;
    }, {});
    answer.answered = false;
  }

  //if (isEmpty(correction)) {
  correction.uid = state.uid;
  correction.answers = {};

  let score = 0;
  let maxScore = 0;

  for (let uid of Object.keys(state.placeholders)) {
    maxScore++;
    let placeholder = state.placeholders[uid];
    let raw_result = answer.answers[uid].answer;

    if (placeholder.type === 'numeric') {
      if (placeholder.min === undefined && placeholder.max === undefined) {
        score++;
        correction.answers[uid] = { isCorrect: true };
      } else {
        let result = parseFloat(realParseFloat(raw_result));
        let correct = true;

        if (
          placeholder.min !== undefined &&
          (isNaN(result) ||
            result < parseFloat(realParseFloat(placeholder.min)))
        )
          correct = false;
        if (
          placeholder.max !== undefined &&
          (isNaN(result) ||
            result > parseFloat(realParseFloat(placeholder.max)))
        )
          correct = false;

        if (correct) {
          score++;
          correction.answers[uid] = { isCorrect: true };
        } else {
          correction.answers[uid] = { isCorrect: false };
        }
      }
    } else if (placeholder.type === 'text') {
      if (placeholder.value === undefined) {
        score++;
        correction.answers[uid] = { isCorrect: true };
      } else {
        if (raw_result && placeholder.value) {
          let distance = stringRelativeDistance(
            placeholder.value.toLowerCase(),
            raw_result.toLowerCase()
          );
          if (distance <= 0.2) {
            score++;
            correction.answers[uid] = { isCorrect: true };
          } else {
            correction.answers[uid] = { isCorrect: false };
          }
        }
      }
    } else {
      score++;
      correction.answers[uid] = { isCorrect: true };
    }
  }
  correction.score = parseNumber(
    correction?.score,
    (score / maxScore) * state.score
  );
};

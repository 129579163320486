export const notificationData = [
  {
    id: 4114,
    text: 'Mark Nuyens heeft je toegevoegd aan de cursus Basis Elektromechanica',
    type: 'general',
    releaseDate: '2023-04-25T09:35:17.225Z',
    createdAt: '2021-05-21T1: 4: 46.275Z',
    updatedAt: '2021-05-21T1: 4: 46.275Z',
    userId: 3,
    globalLink: '/nieuws/19/view',
    read: true,
  },
  {
    id: 4113,
    text: 'Mark Nuyens heeft je toegevoegd aan de cursus Basis Elektromechanica',
    type: 'message',
    releaseDate: '2023-04-25T09:35:17.225Z',
    createdAt: '2021-05-21T1: 4: 46.275Z',
    updatedAt: '2021-05-21T1: 4: 46.275Z',
    userId: 33,
    globalLink: '/nieuws/19/view',
    read: false,
  },
  {
    id: 4116,
    text: 'Je hebt 9 nieuwe taken',
    type: 'correction',
    releaseDate: '2023-04-23T13:35:17.225Z',
    createdAt: '2021-05-21T1: 4: 46.275Z',
    updatedAt: '2021-05-21T1: 4: 46.275Z',
    userId: 54,
    globalLink: '/nieuws/19/view',
    read: true,
  },
  {
    id: 4554,
    text: '12 cursisten uit onderhoudstechniekers groep A hebben je cursus  Basis Elektromechanica ingediend.',
    type: 'team',
    releaseDate: '2023-04-29T11:35:17.225Z',
    createdAt: '2021-05-21T1: 4: 46.275Z',
    updatedAt: '2021-05-21T1: 4: 46.275Z',
    userId: 54,
    globalLink: '/nieuws/19/view',
    read: true,
  },
  {
    id: 4334,
    text: 'Mark Nuyens heeft je toegevoegd aan de cursus Basis Elektromechanica',
    type: 'message',
    releaseDate: '2023-03-30T09:32:17.225Z',
    createdAt: '2021-05-21T1: 4: 46.275Z',
    updatedAt: '2021-05-21T1: 4: 46.275Z',
    userId: 544,
    globalLink: '/nieuws/19/view',
    read: false,
  },
  {
    id: 4324,
    text: 'Mark Nuyens heeft je toegevoegd aan de cursus Basis Elektromechanica',
    type: 'general',
    releaseDate: '2023-05-16T09:35:17.225Z',
    createdAt: '2021-05-21T1: 4: 46.275Z',
    updatedAt: '2021-05-21T1: 4: 46.275Z',
    userId: 60,
    globalLink: '/nieuws/19/view',
    read: false,
  },
];

import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from '@teo/components';
import { hintsData } from './hintsData';
import { axios } from '@/axios';
import { config } from '@/config';
import { newUid } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import HintsMessage from './HintsMessage';

const HintOverlay = ({ setHints, hints, block }: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const { setNewPage } = useContext(PageUrlContext);
  const authUser = auth?.user;

  useEffect(() => {
    setTimeout(() => {
      let section;
      if (block) {
        section = block.current.querySelectorAll('.hint-info');
      } else {
        section = document.querySelectorAll('.hint-info');
      }
      const hintInfoElements = section;
      const sortedElements = Array.from(hintInfoElements).sort(
        (a: any, b: any) => {
          return (
            parseInt(a.getAttribute('data-order')) -
            parseInt(b.getAttribute('data-order'))
          );
        }
      );
      setHints((prev: any) => ({
        ...prev,
        pageHints: sortedElements || [],
      }));
    }, 300);
  }, []);

  const handleSkip = async () => {
    const pageHints = hints?.pageHints?.map((h: any) => h?.dataset.order);
    const updatedHintFlags = hints?.allHints?.map((value: any, index: any) => {
      if (pageHints.includes(index.toString()) && value === '0') {
        return '1';
      }
      return value;
    });

    const promises: any = [];
    promises.push(
      axios.put(`${config.backend}/user/${authUser?.id}`, {
        hintFlags: updatedHintFlags.join(''),
        id: authUser?.id,
      })
    );
    await Promise.allSettled(promises).then(() => {
      setHints((prev: any) => ({
        ...prev,
        id: null,
        active: null,
        allHints: updatedHintFlags,
        pageHints: [],
      }));
      setNewPage(newUid(20));
    });
  };

  const handleSkipAll = async () => {
    const allHits = hintsData?.length;
    const updatedHintFlags = hints?.allHints?.map((value: any, index: any) => {
      if (index < allHits && value === '0') {
        return '1';
      }
      return value;
    });

    const promises: any = [];
    promises.push(
      axios.put(`${config.backend}/user/${authUser?.id}`, {
        hintFlags: updatedHintFlags?.join(''),
        id: authUser?.id,
      })
    );
    await Promise.allSettled(promises).then(() => {
      setHints((prev: any) => ({
        ...prev,
        id: null,
        active: null,
        allHints: updatedHintFlags,
        pageHints: [],
      }));
      setNewPage(newUid(20));
    });
  };

  return (
    <div
      className={`fixed z-[30] flex h-screen w-screen bg-black/25 ${
        hints?.id ? 'top-0 z-[30]' : 'top-0 z-[101]'
      }`}
    >
      {!hints?.id &&
        (authUser?.hintMessage ? (
          <div className="m-auto  h-max w-full max-w-xl  rounded-xl bg-white drop-shadow-m">
            <Modal.Header
              onClose={() =>
                setHints((prev: any) => ({
                  ...prev,
                  id: null,
                  active: null,
                  pageHints: [],
                }))
              }
            >
              <h2 className="text-sm font-semibold">
                {t('hint.popup_skip.title')}
              </h2>
            </Modal.Header>

            <Modal.Body>
              <div className="flex flex-col">
                <p className="mb-6 text-grey-06">{t('hint.popup_skip.text')}</p>

                <div className="mt-6 flex gap-3">
                  <Button
                    variant="ghost"
                    onClick={() =>
                      setHints((prev: any) => ({
                        ...prev,
                        id: null,
                        active: null,
                        pageHints: [],
                      }))
                    }
                  >
                    {t('button.cancel')}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => handleSkipAll()}
                    className="ml-auto"
                  >
                    {t('hint.popup_skip.btn_skip_all')}
                  </Button>
                  <Button onClick={() => handleSkip()}>
                    {t('hint.popup_skip.btn_skip')}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </div>
        ) : (
          <HintsMessage />
        ))}
    </div>
  );
};

export default HintOverlay;

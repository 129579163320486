import React, { Component, createRef } from 'react';
import {
  Sprite,
  Container,
  withFilters,
  Graphics,
  Text,
  PixiComponent,
} from '@pixi/react';
import { Draggable } from './Draggable';
import { BoxIoService } from '../BoxIoService';

import * as PIXI from 'pixi.js';

/*
const Filters = withFilters(Container, { matrix: PIXI.filters.ColorMatrixFilter })

let colorMatrix = new PIXI.filters.ColorMatrixFilter();

const SLIDER_MIN_X = 4
const SLIDER_MAX_X = 22
const SLIDER_MIN_Y = 1
const SLIDER_MAX_Y = 600
*/

const styleUnit = new PIXI.TextStyle({
  fontFamily: 'Arial',
  fontSize: 80,
  fontStyle: 'italic',
  strokeThickness: 1,
  wordWrap: true,
  wordWrapWidth: 440,
  fontWeight: 400,
});

class Slider extends Component {
  state = {
    filled: 0,
    name: '',
  };

  constructor(props) {
    super(props);
    this.service = BoxIoService.getInstance();
    this.slider = createRef();
    this.bar = createRef();
    this.container = createRef();
  }

  subscriptions = [];
  componentWillUnmount() {
    this.slider.current.off('globalpointermove', this.onMove);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.service.onAnalog.subscribe((msg) => {
        if (this.service.isStateLocked(msg.name)) return;
        if (Date.now() - this.timestamp < 50) return;
        if (msg.name === this.props.name) {
          let filled = msg.value / 24;
          if (this.props.vmin || this.props.vmax) {
            filled =
              (msg.value - this.props.vmin) /
              (this.props.vmax - this.props.vmin);
          }
          filled = Math.min(Math.max(filled, 0), 1);
          this.setState({ filled });
        }
      })
    );

    this.subscriptions.push(
      this.service.onStatelock.subscribe(({ name, locked }) => {
        if (name === this.props.name) {
          if (locked) {
            this.setState({
              filled: this.service.state[name].value / 24,
              disabled: true,
            });
          } else {
            this.setState({
              filled: this.service.state[name].value / 24,
              disabled: false,
            });
          }
        }
      })
    );

    this.setState({
      filled: this.service.state[this.props.name]
        ? this.service.state[this.props.name].value / 24
        : 0,
      disabled: this.service.isStateLocked(this.props.name),
    });
  }

  onMoveStart = (event) => {
    let sprite = event.currentTarget;
    sprite.sliding = this.props.disabled ? false : true;
    sprite.data = event.data;
    this.slider.current.on('globalpointermove', this.onMove);
    event.data.nativeEvent.target.cancelScroll = true;
  };

  onMoveEnd = (event) => {
    let sprite = event.currentTarget;
    this.slider.current.off('globalpointermove', this.onMove);
    if (sprite.sliding) sprite.sliding = false;
  };

  onMove = (event) => {
    let sprite = event.currentTarget;
    if (sprite.sliding) {
      const spritePos = event.data.getLocalPosition(sprite.parent);
      this.setFilled(spritePos.x);
      event.data.nativeEvent.target.cancelScroll = true;
    }
  };

  setFilled(sliderPos) {
    if (this.state.disabled) return;
    this.timestamp = Date.now();
    sliderPos = Math.max(0, Math.min(sliderPos, this.props.width));
    let filled = sliderPos / this.props.width;
    this.setState({ filled });

    // Sets vmin and vmax values of Slider in BoxGUI
    let value = filled * 24;
    if (this.props.vmin || this.props.vmax) {
      value = this.props.vmin + filled * (this.props.vmax - this.props.vmin);
      value = Math.min(Math.max(value, this.props.vmin), this.props.vmax);
    }

    this.service.sendAnalog(this.props.name, value);
  }

  //Enable for click slide
  pointerup = (event) => {
    let sprite = event.currentTarget;
    const spritePos = event.data.getLocalPosition(sprite.parent);
    this.setFilled(spritePos.y);
  };

  update() {
    this.forceUpdate();
  }

  render() {
    let filled =
      this.state.filled !== undefined
        ? this.state.filled
        : this.props.filled / 24;
    let disabled = this.state.disabled;

    let { x, y, width, height, isVertical } = this.props;

    y += height / 2.9;
    height /= 3.5;
    x += width / 110;
    width /= 1.02;

    if (isVertical) {
      y += width / 1.03;
      x += height * 1.05;
    }

    //console.log(x,y)
    return (
      <Container
        ref={this.container}
        anchor={0}
        angle={isVertical ? -90 : 0}
        {...{ x, y, width, height }}
      >
        <Graphics
          alpha={disabled ? 0.05 : 1}
          draw={(g) => {
            g.clear();
            g.beginFill(0xfccc4a, 10);
            let finish = filled * width;
            g.drawRoundedRect(0, 0, finish, height, 4.1);
            g.endFill();
          }}
        />

        <Graphics
          alpha={disabled ? 0.25 : 1}
          draw={(g) => {
            g.clear();
            g.lineStyle(1.5, 0x000000);
            g.drawRoundedRect(0, 0, width, height, 5);
            g.endFill();
          }}
        />

        <Sprite
          image="https://teo-s3-prod.s3.eu-west-1.amazonaws.com/PIXI/slider_button.png"
          alpha={disabled ? 0.9 : 1}
          ref={this.slider}
          height={height * 2.5}
          width={height * 2.5}
          anchor={[0.5, 0]}
          x={filled * this.props.width}
          y={-height / 1.35}
          eventMode="static"
          pointerdown={this.onMoveStart}
          pointerup={this.onMoveEnd}
          pointerupoutside={this.onMoveEnd}
        />
        <Text
          angle={isVertical ? 90 : 0}
          anchor={0.5}
          scale={0.1}
          x={filled * this.props.width}
          y={height / 2}
          text={(filled * 100).toFixed(0) + '%'}
          style={styleUnit}
        />
      </Container>
    );
  }
}

export { Slider };

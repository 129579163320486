import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText, FilterList, Spinner } from '@teo/components';
import { Search } from '@teo/components/icons';
import { SearchResult } from './SearchResult';
import { useGet } from '@/query/fetchHooks';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { newUid } from '@/util';

export interface Filter {
  label: string;
  value: string;
}

const SearchComponenet = ({ isMobile, activeSearch, setActiveSearch }: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const authUser: any = auth?.user;
  const [tagFilter, setTagFilter] = useState<any>([]);
  const [loadeResult, setLoadeResult] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState<Filter[]>([]);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const [filter, setFilter] = useState('');
  const [filterType, setFilterType] = useState<any>(null);
  const [filterCourseType, setFilterCourseType] = useState(null);
  const [filterSubType, setFilterSubType] = useState<any>(null);

  const activeOrganisationId = authUser?.activeOrganisationId;
  const userId = authUser?.id;
  const organisationRole = authUser?.activeOrganisationRole;

  const courseType = authUser?.useCourse;
  const assessmentType = authUser?.useAssessment;
  const vacancyType = authUser?.useLeervacature;
  const instructionType = authUser?.useInstruction;

  const FILTERS = [
    {
      label: t('links.users'),
      value: 'member',
    },
    {
      label: t('links.teams'),
      value: 'team',
    },
    {
      label: t('links.courses'),
      value: 'course',
    },
    {
      label: t('links.assessments'),
      value: 'assessment',
    },
    {
      label: t('links.vacancies'),
      value: 'leervacature',
    },
    {
      label: t('links.instructions'),
      value: 'instruction',
    },
  ];

  useEffect(() => {
    if (authUser?.id) {
      setFilterType(
        authUser?.activeOrganisationRole == 45
          ? 'course,OR,type=coursecontent'
          : authUser?.activeOrganisationRole < 21
          ? 'course'
          : null
      );
      setFilterSubType(
        authUser?.activeOrganisationRole == 45
          ? 'course,OR,subtype=coursecontent_create'
          : authUser?.activeOrganisationRole < 21
          ? 'course'
          : null
      );

      const newTagFilter = FILTERS?.map((el) => {
        if (!courseType && el?.value === 'course') return null;
        if (!assessmentType && el?.value === 'assessment') return null;
        if (!vacancyType && el?.value === 'leervacature') return null;
        if (!instructionType && el?.value === 'instruction') return null;
        return el;
      })?.filter((el: any) => el && el);

      if (
        authUser?.activeOrganisationRole !== 45 &&
        authUser?.activeOrganisationRole !== 20 &&
        authUser?.activeOrganisationRole !== 10 &&
        authUser?.activeOrganisationRole != null
      ) {
        setTagFilter(newTagFilter);
      } else {
        const updatedFilterTag = newTagFilter?.filter(
          (item: any) => item.value !== 'member' && item.value !== 'team'
        );
        setTagFilter(updatedFilterTag);
      }
    }
  }, []);

  const handleSearch = (value: any) => {
    setSearchText(value);
  };

  const handleFocusSearch = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveSearch(true);
    document.body.style.overflow = 'hidden';
  };
  const handleBlurSearch = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setActiveSearch(false);
      document.body.style.overflow = 'unset';
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Tab') {
      setActiveSearch(false);
      document.body.style.overflow = 'unset';
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleBlurSearch);
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('click', handleBlurSearch);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    setFilter(searchText);
  }, [searchText]);

  useEffect(() => {
    setLoadeResult(true);
    if (selected?.length > 0) {
      if (
        authUser?.activeOrganisationRole !== 45 &&
        authUser?.activeOrganisationRole !== 20 &&
        authUser?.activeOrganisationRole !== 10 &&
        authUser?.activeOrganisationRole != null
      ) {
        let isCourse = false;
        const selectedType: any = selected
          ?.map((el: any) => {
            if (
              el.value === 'course' ||
              el.value === 'assessment' ||
              el.value === 'leervacature' ||
              el.value === 'instruction'
            ) {
              if (isCourse) return null;
              isCourse = true;
              return 'course,OR,type=coursecontent';
            } else {
              return el.value;
            }
          })
          ?.filter((el: any) => el && el)
          ?.join(',OR,type=');

        setFilterType(selectedType);
      }

      let isNotCourse = false;
      const selectedCourseType: any = selected
        ?.map((el: any) => {
          if (el.value === 'member' || el.value === 'team') {
            if (isNotCourse) return null;
            isNotCourse = true;
            return 'null';
          } else {
            return el.value;
          }
        })
        ?.filter((el: any) => el && el)
        ?.join(',OR,courseType=');

      setFilterCourseType(selectedCourseType);
    } else {
      setFilterType(null);
      setFilterCourseType(null);
    }
  }, [selected]);

  return (
    <div
      className={`${
        activeSearch && !isMobile
          ? 'absolute left-0 top-5 z-[102] flex w-full'
          : isMobile
          ? 'relative z-20 -mt-1 border-b border-solid border-grey-02 bg-white pb-4'
          : 'relative z-20 mx-3 flex-auto lg:mx-4'
      }`}
    >
      <div
        onClick={() => handleFocusSearch()}
        ref={searchRef}
        className={`${
          isMobile
            ? 'w-full px-4'
            : 'relative mx-auto w-full max-w-2xl rounded-xl bg-white p-2'
        }`}
      >
        <InputText
          Icon={Search}
          id="teo_global_search"
          onChange={(ev: any) => {
            setLoadeResult(true);
            handleSearch(ev.target.value);
          }}
          placeholder={t('global_search.search_placeholder')}
          value={searchText}
          className={`${
            !activeSearch &&
            !isMobile &&
            '!absolute top-1/2 left-0 w-full -translate-y-1/2'
          }`}
          inputClassName={`${!activeSearch && !isMobile && 'pr-2 lg:pr-3'}`}
        />
        {activeSearch && (
          <div
            className={`${
              isMobile &&
              'absolute left-0 h-[calc(100vh_-_116px)] w-full bg-white px-2'
            }`}
          >
            <div className="px-2 py-4">
              <h4 className="mb-2">{t('global_search.search_for')}:</h4>
              <FilterList
                filters={tagFilter}
                onSelect={setSelected}
                selected={selected}
              />
            </div>

            <div className="z-1 relative top-px -ml-2 h-px w-[calc(100%_+_16px)] bg-[#EAEAED]"></div>

            {authUser?.activeOrganisationRole > 10 ? (
              <div className="custom-scrollbar relative -mx-2 max-h-[calc(100vh_-_245px)] overflow-y-auto sm:max-h-[calc(100vh_-_180px)]">
                {loadeResult && (
                  <div className="absolute inset-0 flex bg-white/25 p-2 backdrop-blur-sm">
                    <div className="m-auto">
                      <Spinner
                        ariaLabel="Loading spinner"
                        className="!h-12 !w-12 border-grey-06"
                      />
                    </div>
                  </div>
                )}
                <SearchResult
                  authUser={authUser}
                  filter={filter}
                  fetch={useGet.bind(null, '/myglobalindex', {
                    organisationId: `${activeOrganisationId},OR,organisationId=null`,
                    userId: userId,
                    maxrole: organisationRole,
                    courseType: filterCourseType && filterCourseType,
                    type: filterType && filterType,
                    subtype: filterSubType && filterSubType,
                    onSuccess: (result: any) => {
                      setLoadeResult(false);
                    },
                  })}
                  setActiveSearch={setActiveSearch}
                  loadeResult={loadeResult}
                />
              </div>
            ) : (
              <div className="flex min-h-24 flex-col">
                <div className="my-8 flex flex-col p-4 text-center">
                  <img
                    className="m-auto w-48"
                    src="/images/components/notifications/theo-empty-inbox.svg"
                  />
                  <p className="pt-4 text-sm text-grey-06">
                    {t('global_search.empty_result')}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { SearchComponenet };

import { useState, useEffect } from 'react';
import { Multiselect, Label, Button } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { emailRegex } from '@/constants/regex.constants';
import { parseFilter } from '@/util';

const UserInviteToCourse = (props: any) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  const result: any = props.fetch({
    offset: 0,
    count: 10,
    sort: '-name',
    //isMember: false,
    q: parseFilter(filter),
  });

  useEffect(() => {
    if (result?.data) {
      const data = (result?.data?.data as any[]).map((usr) => ({
        key: usr.userId ? usr.userId : usr.teamId,
        label: usr.name,
        courseId: usr.courseId,
        isMember: usr.isMember,
        name: usr.name,
        organisationId: usr.organisationId,
        teamId: usr.teamId,
        userId: usr.userId,
      }));
      props.setData(data);
    }
  }, [result?.data]);

  return (
    <div className="my-6">
      <Label htmlFor="invite_author">
        <p className="text-sm font-semibold">{props.multiselectLabel}</p>
      </Label>

      <div id="invite_author" className="ali flex">
        <span className="w-full">
          AA
          <Multiselect
            data-testid="userInviteMultiselect"
            removeButtonLabel="clear"
            toggleSelectionLabel="toggle"
            onChange={(param) => {
              setFilter('');
              props.setValue(param);
            }}
            onCommitQuery={(email) => {
              setFilter('');
              if (emailRegex.test(email)) {
                props.setValue([
                  ...props.value,
                  {
                    label: email,
                    key: Math.floor(Math.random() * 40),
                    isMember: false,
                    name: email,
                    teamId: null,
                    newUser: true,
                  },
                ]);
              }
            }}
            options={props.data}
            query={filter}
            setQuery={(query) => setFilter(query)}
            value={props.value || []}
            placeholder={t('pages.create_edit_course.multiselect_placeholder')}
          />
        </span>

        <Button
          className="ml-3 h-[40px] text-base font-medium"
          variant="primary"
          disabled={props.value.length === 0}
          onClick={props.handleAddUserToList}
        >
          {props.inviteButtonText}
        </Button>
      </div>
    </div>
  );
};

export { UserInviteToCourse };

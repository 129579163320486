import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, RadioGroup } from '@teo/components';
import { useTranslation } from 'react-i18next';

const ChoiceModal = ({
  closeModal,
  options,
  selectFn,
  title,
  heading,
  text,
  label,
  confirmLabel,
  cancelLabel,
}: any) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState('');

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-lg  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            <h2 className="text-sm font-semibold">{title}</h2>
          </Modal.Header>

          <Modal.Body>
            <div className="flex flex-col">
              <div className="mb-6 flex flex-col">
                <h3 className="mb-2 text-lg">{heading}</h3>
                <p className="text-base text-grey-06">{text}</p>
              </div>
              <div>
                <RadioGroup
                  label={label}
                  onChange={(value: string) => {
                    setIsChecked(value);
                  }}
                  gap="gap-2"
                  value={isChecked}
                >
                  <div className="mb-3 flex w-full flex-col gap-3">
                    {options.map((option: any) => (
                      <div
                        key={option.value}
                        className={`w-full rounded-lg border border-grey-02 p-3 ${
                          isChecked == option.value && 'border-secondary-04'
                        }`}
                      >
                        <RadioGroup.Option value={option.value}>
                          <span className="text-sm">{option.label}</span>
                        </RadioGroup.Option>
                      </div>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </div>

            <div className="-mx-6 mt-2 flex justify-between px-6 pt-6">
              <Button variant="ghost" onClick={() => closeModal()}>
                {cancelLabel || t('button.cancel')}
              </Button>
              <Button onClick={() => selectFn(isChecked)} disabled={!isChecked}>
                {confirmLabel || t('button.confirm')}
              </Button>
            </div>
          </Modal.Body>
        </div>
      </div>
    </div>
  );
};

export default ChoiceModal;

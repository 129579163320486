import { useNavigate, useParams } from 'react-router-dom';
import { useState, Fragment, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import {
  InputText,
  ContentCard,
  Button,
  IconButton,
  FilterList,
  Card,
  FilterDropdown,
  Spinner,
  Tag as ComponentTag,
} from '@teo/components';
import { Search, Tag, ChevronDown, ChevronUp } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import {
  Membercoursecontentdetails,
  Userwithorganisationwithidps,
} from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { newUid, parseQueryString } from '@/util';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import Hint from '@/components/Hints/Hints';
import TypeCourse from '@/components/CreateNewPublication/TypeCourse';

export interface Filter {
  label: string;
  value: string;
}

const CreateCoursesPage = ({ courseType }: any) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [filterJoined, setFilterJoined] = useState('');
  const [sort, setSort] = useState([{ id: 'createdAt', desc: true }]);

  const [selected, setSelected] = useState<Filter[]>([]);
  const [filterCourse, setFilterCourse] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [loadCourses, setLoadCourses] = useState<any>(true);
  const [coursesType, setCoursesType] = useState<any>();
  const [refresh, setRefresh] = useState<string>(newUid(20));
  const [listCategories, setListCategories] = useState<any>([]);
  const [category, setCategory] = useState<any>();

  const [goToCreate, setGoToCreate] = useState(false);
  const [publicationId, setPublicationId] = useState();
  const [loadeNewPublication, setLoadeNewPublication] = useState(false);
  const [publicationStatus, setPublicationStatus] = useState<any>(
    'published,OR,publicationStatus=concept,OR,publicationStatus=template'
  );
  const [selectedStatus, setSelectedStatus] = useState<Filter[]>([
    {
      label: t('pages.create_courses.active'),
      value:
        'published,OR,publicationStatus=concept,OR,publicationStatus=template',
    },
  ]);

  const navigate = useNavigate();
  const { organisationId } = useParams();
  const basePath = window.location.pathname;

  const { newPage } = useContext(PageUrlContext);
  const { hints } = useContext(HintsContext);
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const authLang: any = auth?.user.lang;
  const authOrg: any = auth?.user?.activeOrganisationId;

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };

  const [isOpenTypeModal, setIsOpenTypeModal] = useState(
    !!queries['courseType']
  );

  const [template, setTemplate] = useState('theory');
  const [typeCourse, setTypeCourse] = useState('courses');

  useEffect(() => {
    if (courseType) {
      setFilter('');
      setMoreFilter(false);
      setCoursesType(null);
      setCategory(null);
      setSelected([]);
      setRefresh(newUid(20));
      setTypeCourse(
        courseType == 'assessment'
          ? 'assessments'
          : courseType == 'leervacature'
          ? 'vacancies'
          : courseType == 'instruction'
          ? 'instructions'
          : 'courses'
      );
      setSelectedStatus([
        {
          label: t('pages.create_courses.active'),
          value:
            'published,OR,publicationStatus=concept,OR,publicationStatus=template',
        },
      ]);
    }
  }, [courseType]);

  /*
  const { data, status, refetch } = useGet<Array<Membercoursecontentdetails>>(
    '/myactivecoursecontents',
    {
      userId: authUser?.id,
      publicationCourseType: courseType,
      q: parseFilter(filterTags)
      count: 50,
      sort: !filterCourse ? 'createdAt' : '-createdAt',
    }
  );
  */

  const selectedTags = selected?.map((el) => el.value + ' ');

  /*
  useEffect(() => {
    refetch();
  }, [filter]);

  useEffect(() => {
    const myFilter = filter + (selectedTags ? ' ' + selectedTags : '');
    const cleanedStr = myFilter.split(',').join('');
    const splittedFilterValue = cleanedStr?.split(/\s+/);
    const filterValue = `%${splittedFilterValue?.join('%,AND,%')}%`;
    setFilterTags(filterValue);
  }, [filter, selectedTags]);
  */

  const allCategories: any = useGet(`/categories`, {
    count: 1000,
    sort: 'tag',
    organisationId: `${auth?.user?.activeOrganisationId},OR,organisationId=null`,
    lang: auth?.user?.lang,
  });

  useEffect(() => {
    if (allCategories?.data) {
      const data = (allCategories?.data?.data as any[]).map((tag) => ({
        id: tag?.tag,
        name: tag?.tag,
      }));
      setListCategories(data);
    }
  }, [allCategories?.data]);

  useEffect(() => {
    const newFilterJoined =
      selectedTags.join(' ') +
      (filter ? ` ${filter}` : '') +
      (category ? `category:${category?.name}` : '');
    setFilterJoined(newFilterJoined);
  }, [filter, selectedTags, category]);

  const FILTERS = [
    {
      label: t('pages.create_courses.Electricity'),
      value: t('pages.create_courses.Electricity'),
    },
    {
      label: t('pages.create_courses.Base'),
      value: t('pages.create_courses.Base'),
    },
    {
      label: t('pages.create_courses.Technology'),
      value: t('pages.create_courses.Technology'),
    },
    {
      label: t('pages.create_courses.Safety'),
      value: t('pages.create_courses.Safety'),
    },
    {
      label: t('pages.create_courses.Mechanics'),
      value: t('pages.create_courses.Mechanics'),
    },
  ];

  const FILTERSSTATUS = [
    {
      label: t('pages.create_courses.active'),
      value:
        'published,OR,publicationStatus=concept,OR,publicationStatus=template',
    },
    {
      label: t('pages.create_courses.inactive'),
      value: 'disabled',
    },
  ];

  function convertToTime(number: number) {
    const hours = Math.floor(number / 3600);
    const minutes = Math.round((number / 3600 - hours) * 60);
    return (
      hours +
      `${t('pages.learn_index.hour')} ` +
      minutes +
      `${t('pages.learn_index.minute')} `
    );
  }

  useEffect(() => {
    if (goToCreate) {
      setLoadeNewPublication(false);
      setIsOpenTypeModal(false);
      navigate(`/${authLang}/create/${authOrg}/courses/${publicationId}`);
    }
  }, [goToCreate]);

  const createMarkup = (text: any) => {
    if (text) return { __html: text };
  };

  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus?.length === 1) {
        setPublicationStatus(selectedStatus[0].value);
      } else {
        setPublicationStatus(null);
      }
    }
  }, [selectedStatus]);

  return !isOpenTypeModal ? (
    <div className="w-full max-w-6xl pb-8">
      <h1 className="my-8 text-xl font-semibold text-black">
        {t(`courseTypes.${courseType}`)}
      </h1>
      <Fragment>
        <div
          className={`relative mb-8 sm:w-2/3 md:w-1/2 ${
            hints?.id == `hint_28` && 'active-hint-parent !z-[100]'
          }`}
        >
          <Hint
            key={`hint_28_${newPage}`}
            id={`hint_28`}
            leftDots={'-left-2 top-2'}
            title={t(`hint.title_28`)}
            order={28}
            style={{
              left: '-10px',
              top: '60px',
            }}
          />
          <InputText
            value={filter}
            onChange={(ev) => setFilter(ev.target.value)}
            Icon={Search}
            placeholder={t('search.course')}
          />
        </div>

        <div className="mb-8 flex flex-col justify-between gap-4 lg:flex-row lg:gap-0">
          <div
            className={`relative flex items-center ${
              hints?.id == `hint_29` && 'active-hint-parent !z-[100]'
            }`}
          >
            <Hint
              key={`hint_29_${newPage}`}
              id={`hint_29`}
              leftDots={'-left-2 top-[3px]'}
              title={t(`hint.title_29`)}
              order={29}
              style={{
                left: '-10px',
                top: '60px',
              }}
            />
            <IconButton Icon={Tag} variant="ghost" size="sm" className="mr-2" />
            <FilterList
              filters={FILTERS}
              onSelect={setSelected}
              selected={selected}
            />
          </div>
          <div className="flex items-center">
            {/* <Button
              size="sm"
              variant="outline"
              className="mr-2"
              onClick={() => {
                axios.delete(`${config.backend}/categories/247`);
              }}
            >
              {t('pages.create_courses.more_filters')}Del
            </Button> */}
            <Button
              variant="outline"
              size="sm"
              className="ml-auto mr-2"
              onClick={() => {
                setMoreFilter(!moreFilter);
              }}
            >
              {moreFilter
                ? t('pages.create_edit_course.library.fewer_filters')
                : t('pages.create_edit_course.library.more_filters')}
            </Button>
            <Button
              className="flex items-center"
              variant="ghost"
              size="sm"
              onClick={() =>
                sort[0].desc
                  ? setSort([{ id: 'createdAt', desc: false }])
                  : setSort([{ id: 'createdAt', desc: true }])
              }
            >
              <div className="pr-2">{t('pages.create_courses.latest')}</div>
              {sort[0].desc ? (
                <ChevronUp className="w-5" />
              ) : (
                <ChevronDown className="w-5" />
              )}
            </Button>
          </div>
        </div>
        {moreFilter && (
          <div className="relative z-10 mt-5 flex flex-col">
            <div className="mb-4 flex flex-col sm:flex-row">
              <div className="mr-2">
                <FilterList
                  filters={FILTERSSTATUS}
                  onSelect={setSelectedStatus}
                  selected={selectedStatus}
                />
              </div>
              <span className="mb-4 pr-2 sm:mb-0">
                <FilterDropdown
                  key={refresh}
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setLoadCourses(true);
                    setRefresh(newUid(20));
                    setCategory(value);
                  }}
                  buttonText={`${t('pages.follow_up.all_categories_tag')}`}
                  options={listCategories}
                  searchable={true}
                  value={category}
                />
              </span>
              {courseType !== 'instruction' && (
                <span className="pr-2">
                  <FilterDropdown
                    clearButtonLabel="clear"
                    onChange={(value: any) => {
                      setLoadCourses(true);
                      setRefresh(newUid(20));
                      setCoursesType(value);
                    }}
                    buttonText={`${t('pages.follow_up.course_type_tag')}`}
                    options={[
                      {
                        id: 'theory',
                        name: t('pages.create_edit_course.library.theory'),
                      },
                      {
                        id: 'practice',
                        name: t('pages.create_edit_course.library.practice'),
                      },
                    ]}
                    // searchable={true}
                    value={coursesType}
                    dropdownPositionMobileRight={true}
                  />
                </span>
              )}
            </div>
          </div>
        )}

        {loadCourses && (
          <div className="mx-auto flex justify-center py-20 px-10">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-10 !w-10 border-grey-05"
            />
          </div>
        )}

        <PaginationAnything
          key={refresh}
          className={`mt-3 flex w-full flex-wrap gap-3 ${
            loadCourses === true && ' hidden '
          }`}
          fetch={useGet.bind(null, '/myowedpublications', {
            //minrole: 50,
            publicationCourseType: courseType,
            organisationId: authUser?.activeOrganisationId,
            publicationStatus: publicationStatus,
            userId: authUser?.id,
            template: coursesType?.id,
            onSuccess: (result: any) => {
              setLoadCourses(false);
            },
          })}
          empty={
            publicationStatus === 'disabled'
              ? {
                  template: 2,
                  loadContent: loadCourses,
                  text: t('empty_tenplate.course_list'),
                }
              : {
                  template: 2,
                  loadContent: loadCourses,
                  text: t('empty_tenplate.course_list'),
                  btn_text: t('empty_tenplate.btn_creat_course'),
                  btnClick: () => setIsOpenTypeModal(true),
                }
          }
          filter={filterJoined}
          pageSize={12}
          defaultSort={sort}
          render={(course: Membercoursecontentdetails) => (
            <div
              className="relative max-w-[calc(50%_-_6px)] basis-1/2 gap-4 sm:max-w-[calc(33.3333%_-_8px)] sm:basis-1/3 lg:max-w-[calc(25%_-_9px)] lg:basis-1/4"
              key={course.id}
            >
              <ContentCard
                customStyles="text-left whitespace-break-spaces overflow-y-hidden text-ellipsis"
                chapters={course?.nrOfLessons ? course.nrOfLessons : 0}
                duration={
                  course?.totalEstimatedTime
                    ? convertToTime(Math.abs(course?.totalEstimatedTime))
                    : ''
                }
                title={course?.name ? course?.name : ''}
                onClick={() =>
                  navigate(`${basePath}/${course.coursecontentId}`)
                }
                Image={() => (
                  <ContentCard.Image
                    src={
                      course?.image && !course?.image.indexOf('http')
                        ? course.image
                        : '/assets/images/opleidingen.jpg'
                    }
                    alt="Electrical dial"
                  />
                )}
                Tag={() => (
                  <div className="mb-3 text-left">
                    <ComponentTag title={course.publicationStatus || ''} />
                  </div>
                )}
                Actions={() => (
                  <>
                    <Card.Button
                      onClick={() =>
                        navigate(`${basePath}/${course.coursecontentId}`)
                      }
                    >
                      {t('pages.create_index.to_process')}
                    </Card.Button>
                    <div className="absolute top-0">
                      <img
                        key={course.id}
                        src={`/images/flags/${course.lang}.svg`}
                        alt={`${course.lang} flag`}
                        className="
                          mt-2
                          ml-2
                          h-[25px]
                          w-[25px] rounded-[50%]
                          bg-tint-red
                          object-cover
                          "
                      />
                    </div>
                  </>
                )}
                authors={[
                  {
                    id: `${course.authorId}`,
                    imageSrc: `${course.picture}`,
                    name: `${course?.username}`,
                  },
                ]}
              >
                <div className="mb-3 max-h-[60px] max-w-[264px] overflow-hidden text-ellipsis break-all text-left text-sm font-normal text-[#6d6d72]">
                  <div
                    dangerouslySetInnerHTML={createMarkup(course?.description)}
                  />
                </div>
              </ContentCard>
            </div>
          )}
        />

        {/*
        <div className="mt-3 flex w-full max-w-6xl flex-wrap gap-3">
          {data?.data &&

          
            data?.data?.map((course: Membercoursecontentdetails) => (
              <div className="relative max-w-[250px]" key={course.id}>
 
              </div>
            ))}
        </div>
        */}
      </Fragment>
    </div>
  ) : (
    <>
      <TypeCourse
        setIsOpenTypeModal={setIsOpenTypeModal}
        setTemplate={setTemplate}
        template={template}
        setGoToCreate={setGoToCreate}
        typeCourse={typeCourse}
        setPublicationId={setPublicationId}
        publicationId={publicationId}
        setLoadeNewPublication={setLoadeNewPublication}
      />
      {loadeNewPublication && (
        <div className="fixed inset-0 z-[201] m-auto flex min-h-screen min-w-full bg-white/75 p-10">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-14 !w-14 border-grey-06"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCoursesPage;

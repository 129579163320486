import { publicAxios } from '@/axios';
import { config } from '@/config';
import i18n from 'i18next';
import { newUid } from '@/util';

export function loadIframely() {
  let FroalaEditor = window.FroalaEditor;

  // Define popup template.
  FroalaEditor.POPUP_TEMPLATES['iframelyPlugin.popup'] = '[_INPUT_LAYER_]';
  FroalaEditor.RegisterCommand('iframelyInsert', {
    focus: false,
    refreshAfterCallback: false,
    callback: function callback(popup) {
      var $popup = this.popups.get('iframelyPlugin.popup');
      var text_inputs = $popup.find('input.fr-link-attr[type="text"]');
      var href = (text_inputs.filter('[name="href"]').val() || '').trim();

      var textarea_inputs = $popup.find('textarea.fr-link-attr[type="text"]');
      var iframe = (
        textarea_inputs.filter('[name="iframe"]').val() || ''
      ).trim();
      if (iframe) {
        this.html.insert(`<p>
          <span class="fr-video fr-deletable fr-draggable" contenteditable="false" draggable="true" id="${newUid(
            20
          )}_embed" style="display: block; clear: both; text-align: center;">
          ${iframe}
          </span>
        <br></p>`);
        textarea_inputs.filter('[name="iframe"]').val('');
      } else {
        publicAxios
          .get(
            `https://iframe.ly/api/iframely?url=${encodeURI(href)}&key=${
              config.iframely
            }`
          )
          .then(async (response) => {
            if (response.status === 200) {
              let iframeHtml = response.data.html;
              let otherHtml = '';
              try {
                let embedContent = window.$(response.data.html);
                let iframeContent = embedContent.find('iframe');
                iframeContent.attr('style', null);
                iframeContent.attr('width', 640);
                iframeContent.attr('height', 360);
                iframeHtml = iframeContent[0].outerHTML;
                embedContent.find('iframe').remove();
                otherHtml = embedContent[0].innerHTML;
              } catch (e) {
                console.error(e);
              }
              this.html.insert(`<p>
                <span class="fr-video fr-deletable fr-draggable" contenteditable="false" draggable="true" id="${newUid(
                  20
                )}_embed" style="display: block; clear: both; text-align: center;">
                  ${iframeHtml ? iframeHtml : response.data.html}
                  ${otherHtml ? otherHtml : ''}
                </span>
              <br></p>`);
              this.undo.saveStep();
            } else {
              this.html.insert(`<p>
                <span class="fr-video fr-deletable fr-draggable" contenteditable="false" draggable="true" id="${newUid(
                  20
                )}_embed" style="display: block; clear: both; text-align: center;">
                ${href}
                </span>
              <br></p>`);
              this.undo.saveStep();
            }
          })
          .catch((e) => {
            console.error(e);
            this.html.insert(`<p>
              <span class="fr-video fr-deletable fr-draggable" contenteditable="false" draggable="true" id="${newUid(
                20
              )}_embed" style="display: block; clear: both; text-align: center;">
                ${href}
              </span>
            <br></p>`);
            this.undo.saveStep();
          });
        text_inputs.filter('[name="href"]').val('');
      }
      this.popups.hide('iframelyPlugin.popup');
    },
    plugin: 'iframelyPlugin',
  });

  // The custom popup is defined inside a plugin (new or existing).
  FroalaEditor.PLUGINS.iframelyPlugin = function (editor) {
    // Create custom popup.
    function initPopup() {
      let tab_idx = 0;
      let input_layer =
        '<div class="fr-link-insert-layer fr-layer fr-active" id="fr-link-insert-layer-'.concat(
          editor.id,
          '">'
        );

      input_layer += `<p>${i18n.t('froala_extend.embed_description')}</p>`;
      input_layer +=
        '<div class="fr-input-line"><input id="fr-link-insert-layer-url-'
          .concat(
            editor.id,
            '" name="href" type="text" class="fr-link-attr" placeholder="'
          )
          .concat(editor.language.translate('URL'), '" tabIndex="')
          .concat(++tab_idx, '"></div>');

      input_layer += `<p>${i18n.t('froala_extend.embed_code_description')}</p>`;
      input_layer +=
        '<div class="fr-input-line"><textarea id="fr-iframe-insert-layer-url-'
          .concat(
            editor.id,
            '" name="iframe" type="text" class="fr-link-attr" placeholder="'
          )
          .concat(editor.language.translate('Code'), '" tabIndex="')
          .concat(++tab_idx, '"></textarea></div>');
      input_layer +=
        '<div class="fr-action-buttons"><button class="fr-command fr-submit" role="button" data-cmd="iframelyInsert" href="#" tabIndex="'
          .concat(++tab_idx, '" type="button">')
          .concat(editor.language.translate('Insert'), '</button></div></div>');
      // Load popup template.
      let template = {
        input_layer: input_layer,
      };
      // Create popup.
      let $popup = editor.popups.create('iframelyPlugin.popup', template);
      return $popup;
    }

    // Show the popup
    function showPopup() {
      let $popup = editor.popups.get('iframelyPlugin.popup');
      if (!$popup) $popup = initPopup();

      editor.popups.setContainer('iframelyPlugin.popup', editor.$tb);
      let $btn = editor.$tb.find('.fr-command[data-cmd="iframelyButton"]');

      let left = $btn.offset().left; // + $btn.outerWidth();
      let top =
        $btn.offset().top +
        (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

      editor.popups.show('iframelyPlugin.popup', left, top, $btn.outerHeight());
    }

    // Hide the custom popup.
    function hidePopup() {
      editor.popups.hide('iframelyPlugin.popup');
    }

    // Methods visible outside the plugin.
    return {
      showPopup: showPopup,
      hidePopup: hidePopup,
    };
  };

  // Define an icon and command for the button that opens the custom popup.
  FroalaEditor.DefineIcon('iframelyButton', { NAME: 'star', SVG_KEY: 'star' });
  FroalaEditor.RegisterCommand('iframelyButton', {
    title: i18n.t('froala_extend.embed_link'),
    icon: `<i class="far fa-code" style="font-size: 19px; height: 20px;" ></i>`,
    undo: false,
    focus: false,
    popup: true,
    plugin: 'iframelyPlugin',
    callback: function () {
      this.iframelyPlugin.showPopup();
    },
  });
}

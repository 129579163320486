import { useTranslation } from 'react-i18next';
import { Button, Modal, Label, InputText } from '@teo/components';
import { useState, useContext } from 'react';
import { config } from '@/config';
import { axios } from '@/axios';
import { AuthContext } from '@/components/Contexts/AuthContext';

function TagModal({
  setIsOpenTagModal,
  isOpenTagModal,
  closeModal,
  onChange,
  isAddModal,
  isTagIndex,
  data,
}: any) {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [tagName, setTagName] = useState('');

  function submitNewTag() {
    axios
      .post(`${config.backend}/tags`, {
        tag: tagName,
        organisationId: auth?.user?.activeOrganisationId,
        lang: auth?.user?.lang,
      })
      .then((data) => onChange(data));
  }

  const tagEdit = isTagIndex
    ? data?.find((el: any) => el.id === isTagIndex)
    : '';

  function editNewTag() {
    axios
      .put(`${config.backend}/tag/${tagEdit.id}`, {
        tag: tagName ? tagName : tagEdit.tag,
        updatedAt: new Date().toJSON(),
      })
      .then((data) => onChange(data));
  }
  const handleButton = () => {
    setIsOpenTagModal(false);
    isAddModal ? submitNewTag() : editNewTag();
  };

  return (
    <div className="bg-white">
      <Modal isOpen={isOpenTagModal}>
        <Modal.Header onClose={closeModal}>
          <h2 className="text-sm font-semibold" data-testid="addTagsHeader">
            {isAddModal
              ? t('pages.manage_tags.modal_title')
              : t('pages.manage_tags.edit_tag')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <h1 className="text-base font-semibold">
            {isAddModal
              ? t('pages.manage_tags.modal_title')
              : t('pages.manage_tags.edit_tag')}
          </h1>
          <p className="mt-2 text-sm font-normal text-[#7d7d81]">
            {isAddModal
              ? t('pages.manage_tags.modal_subtitle')
              : t('pages.manage_tags.modal_subtitle_edit')}
          </p>

          <form
            className="w-full max-w-[440px] flex-col max-md:h-full max-md:overflow-hidden"
            data-testid="addTagsForm"
          >
            <div>
              <Label htmlFor="tagName">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_tags.tag_name_label')}
                </p>
              </Label>
              <InputText
                id="tagName"
                type="text"
                onChange={(e) => setTagName(e.target.value)}
                placeholder={
                  isAddModal
                    ? t('pages.manage_tags.tag_name_placeholder')
                    : tagEdit?.tag
                }
                value={tagName}
                data-testid="addNameTag"
              />
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('pages.manage_tags.cancel_button')}
          onCancel={closeModal}
        >
          <div data-testid="addTagsFooter">
            <Button
              type="button"
              onClick={handleButton}
              disabled={tagName.length < 1 ? true : false}
              data-testid="addBtn"
            >
              {isAddModal
                ? t('pages.manage_tags.add_tag_button')
                : t('pages.manage_tags.edit_tag')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TagModal;

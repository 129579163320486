import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Label,
  InputText,
  InputSelect,
  Switch,
} from '@teo/components';
import { emailRegex } from '@/constants/regex.constants';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { axios } from '@/axios';
import { config } from '@/config';

const ExportPublication = ({
  closeModal,
  setIsOpenExport,
  setSelectVersion,
  publication,
  user,
  title,
  version,
}: any) => {
  const { t } = useTranslation();
  const { setShowNotification } = useContext(NotificationContext);
  const [printLessonTitles, setPrintLessonTitles] = useState(false);
  const [printChapterTitles, setPrintChapterTitles] = useState(true);
  const [showCorrectionKeys, setShowCorrectionKeys] = useState(false);

  const [email, setEmail] = useState(user?.email ? user?.email : '');

  const exportOptions = [
    {
      value: 'pdf',
      label: t('pages.create_edit_course.export_pdf'),
    },
    {
      value: 'scorm',
      label: t('pages.create_edit_course.export_scorm'),
    },
  ];

  const [exportType, setExportType] = useState(exportOptions[0]);

  const sentReport = async () => {
    if (exportType?.value === 'scorm') {
      await axios.post(`${config.backend}/export/publication/scorm`, {
        email: email,
        coursecontentId: version?.id ? version?.id : publication?.id,
        organisationId: user?.activeOrganisationId,
      });
    } else {
      await axios.post(`${config.backend}/export/publication/pdf`, {
        email: email,
        coursecontentId: version?.id ? version?.id : publication?.id,
        organisationId: user?.activeOrganisationId,
        printLessonTitles,
        printChapterTitles,
        showCorrectionKeys,
      });
    }
    setShowNotification({
      message: t('pages.report.emailed') + ` (${email})`,
      delay: 5000,
    });
    setSelectVersion(null);
    setIsOpenExport(false);
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-[600px]  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>{title}</Modal.Header>

          <Modal.Body>
            <h1 className="text-lg font-semibold">
              {t('pages.report_page.teams_tab.download_modal_heading')}
            </h1>
            <p className="mt-2 text-base font-normal text-[#7d7d81]">
              {t('pages.report_page.teams_tab.download_modal_text')}
            </p>

            <form
              className="w-full flex-col max-md:h-full max-md:overflow-hidden"
              data-testid="addTagsForm"
            >
              <div>
                <Label htmlFor="courseLang">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.create_edit_course.type_export')}
                  </p>
                </Label>
                <div id="courseLang">
                  <InputSelect
                    onChange={(newExportType) => {
                      setExportType(newExportType);
                    }}
                    value={exportType}
                    options={exportOptions}
                  >
                    {(options) => <span>{options?.label}</span>}
                  </InputSelect>
                </div>
              </div>
              {exportType.value === 'pdf' && (
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="printLessonTitles">
                    <Switch
                      checked={printLessonTitles}
                      onChange={(isCheck) => setPrintLessonTitles(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t('pages.create_edit_course.print_lesson_titles')}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t(
                        'pages.create_edit_course.print_lesson_titles_explain'
                      )}
                    </p>
                  </div>
                </div>
              )}
              {exportType.value === 'pdf' && (
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="printChapterTitles">
                    <Switch
                      checked={printChapterTitles}
                      onChange={(isCheck) => setPrintChapterTitles(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t('pages.create_edit_course.print_chapter_titles')}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t(
                        'pages.create_edit_course.print_chapter_titles_explain'
                      )}
                    </p>
                  </div>
                </div>
              )}
              {exportType.value === 'pdf' && (
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="showCorrectionKeys">
                    <Switch
                      checked={showCorrectionKeys}
                      onChange={(isCheck) => setShowCorrectionKeys(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t('pages.create_edit_course.show_correction')}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t('pages.create_edit_course.show_correction_explain')}
                    </p>
                  </div>
                </div>
              )}
              <div>
                <Label htmlFor="tagName">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.report_page.teams_tab.download_modal_label')}
                  </p>
                </Label>
                <InputText
                  id="tagName"
                  type="text"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  placeholder={''}
                  value={email}
                  data-testid="addNameTag"
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer
            cancelButtonLabel={t('pages.manage_tags.cancel_button')}
            onCancel={closeModal}
          >
            <div className="flex flex-col gap-3 sm:flex-row">
              <Button
                type="button"
                onClick={() => sentReport()}
                disabled={emailRegex.test(email) ? false : true}
              >
                {t('pages.report_page.teams_tab.btn_send_email')}
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </div>
    </div>
  );
};

export default ExportPublication;

import { useState, useContext, useReducer, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import {
  InputSelect,
  Label,
  Button,
  InputDate,
  Switch,
  IconButton,
  PersonAvatar,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { InputSelectOption } from '@/models/organisation.interface';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import DownloadReportModal from '../DownloadReportModal';
import ReportUsersDataTable from '@/components/ReportDataTable/ReportUsersDataTable';
import { languages } from '@/constants/languages';

const reportTypeList = [
  {
    value: 'courseOverview',
    label: 'report.courseOverview',
  },
  {
    value: 'courseCsv',
    label: 'report.courseCsv',
  },
  {
    value: 'studentOverview',
    label: 'report.studentOverview',
  },
  {
    value: 'coursedetailsCsv',
    label: 'report.coursedetailsCsv',
  },
  {
    value: 'certificate',
    label: 'report.certificate',
  },
  {
    value: 'usersCsv',
    label: 'report.usersCsv',
  },
];

type reportDownload = {
  usersReport: any;
  fromDate: any;
  untilDate: any;
  fileType: any;
  reportEmail: boolean;
};

function UsersReport() {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [untilDate, setUntilDate] = useState<Date | undefined>();
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [isOpenDownloadtModal, setIsOpenDownloadtModal] = useState(false);
  const [allUsers, setAllUsers] = useState(false);
  const [overviewUsers, setOverviewUsers] = useState(false);
  const [disabledAll, setDisabledAll] = useState(true);
  const [selectType, setSelectType] = useState(reportTypeList[0].value);

  const reportState = {
    usersReport: [],
    fromDate,
    untilDate,
    reportType: {
      label: reportTypeList[0].label,
      value: reportTypeList[0].value,
    },
    reportEmail: true,
    lang: languages?.find((option) => option.value === auth?.user?.lang),
  };

  const [stateReport, updateStateReport] = useReducer(
    (state: reportDownload, updates: any) => ({ ...state, ...updates }),
    reportState
  );

  const handleAddUsers = async (newValue: any) => {
    const addUser = [...stateReport.usersReport];
    for (const obj2 of newValue) {
      if (obj2?.id) {
        const existingTeam = stateReport.usersReport.find(
          (obj1: any) => obj1?.id && obj1?.id === obj2?.id
        );
        if (!existingTeam) {
          addUser.push(obj2);
        }
      }
    }
    updateStateReport({ usersReport: addUser });
    setSelectedUsers([]);
  };

  const handleRemove = (id: any) => {
    const removeUser = stateReport.usersReport?.filter(
      (item: any) => item.id !== id
    );
    updateStateReport({ usersReport: removeUser });
  };

  useEffect(() => {
    if (
      selectType == 'courseOverview' ||
      selectType == 'studentOverview' ||
      selectType == 'certificate'
    ) {
      setDisabledAll(true);
      setAllUsers(false);
    } else {
      if (selectType == 'usersCsv') {
        setOverviewUsers(true);
        setDisabledAll(true);
        setAllUsers(true);
      } else {
        setDisabledAll(false);
        setAllUsers(false);
      }
    }
  }, [selectType]);

  return (
    <div className="gap- flex flex-col">
      <div className="mt-6 rounded-lg border border-grey-02 p-6">
        <div className="flex max-w-xl flex-col">
          <p className="pb-2 text-base font-semibold text-black">
            {t('pages.report_page.teams_tab.title_1')}
          </p>
          <p className="text-base text-grey-06">
            {t('pages.report_page.user_tab.subtitle_1')}
          </p>
          <div className="mb-1 mt-4 min-w-full max-w-[300px] text-sm font-semibold text-black">
            <p className="mb-1">
              {t('pages.report_page.teams_tab.type_of_report')}
            </p>
            <div className="font-normal">
              <InputSelect<InputSelectOption>
                value={stateReport.reportType}
                onChange={(e: InputSelectOption) => {
                  setSelectType(e?.value);
                  updateStateReport({ reportType: e });
                }}
                options={reportTypeList}
              >
                {(options) => <span>{options.label && t(options.label)}</span>}
              </InputSelect>
            </div>
          </div>

          <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
            <div data-testid="reportEmail">
              <Switch
                checked={allUsers}
                disabled={disabledAll}
                onChange={(isCheck) => setAllUsers(isCheck)}
              />
            </div>
            <div>
              <h4 className="font-normal">
                {t('pages.report_page.select_users')}
              </h4>
            </div>
          </div>
          {!allUsers && (
            <div className="z-[1] mb-1 mt-4 text-sm font-semibold text-black">
              <p className="mb-1">{t('pages.report_page.user_tab.users')}</p>
              <div className="flex">
                <span className="w-full">
                  <PaginationMultiSelect
                    id="teams-report"
                    value={selectedUsers}
                    onChange={(value: any) => {
                      setSelectedUsers(value);
                    }}
                    keyFn={(row: any) => row?.id}
                    fetch={useGet.bind(
                      null,
                      `/memberdetails?organisationId=${auth?.user?.activeOrganisationId}`
                    )}
                    defaultSort={[{ id: 'username', desc: false }]}
                    render={(row: any) => {
                      return row?.username;
                    }}
                  />
                </span>
                <Button
                  className="ml-3 h-[36px] text-base font-medium"
                  variant="primary"
                  disabled={selectedUsers.length === 0}
                  onClick={async () => {
                    handleAddUsers(selectedUsers);
                  }}
                >
                  {t('button.add_btn')}
                </Button>
              </div>
              {stateReport?.usersReport?.length > 0 && (
                <div className="mt-6">
                  {stateReport?.usersReport?.map((user: any) => (
                    <div
                      key={user.id == null ? user.username : user.id}
                      className="mb-4 flex items-center justify-between"
                    >
                      <div className="flex items-center">
                        <PersonAvatar
                          inline
                          id={user.id}
                          name={user.username}
                          imageSrc={user.picture}
                        ></PersonAvatar>
                        <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                          {user.username}
                        </p>
                      </div>
                      <div data-testid="daleteItem" className="my-auto ml-6">
                        <IconButton
                          Icon={CrossFilled}
                          variant="ghost"
                          onClick={() => handleRemove(user.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="mt-3">
            <Label htmlFor="language">
              {t('pages.profile_account.language')}
            </Label>
            <div id="language">
              <InputSelect<InputSelectOption>
                value={stateReport.lang}
                onChange={async (language) => {
                  updateStateReport({ lang: language });
                }}
                options={languages}
              >
                {(options) => t(options.label)}
              </InputSelect>
            </div>
          </div>
          {!overviewUsers && (
            <div className="mt-7 flex flex-col gap-5 pr-2 sm:flex-row lg:pr-0">
              <div className="basis-1/2" data-testid="dateStart">
                <Label htmlFor="dateStart">
                  {t('pages.report_page.teams_tab.from')}
                </Label>
                <InputDate
                  id="dateStart"
                  onChange={(date) => updateStateReport({ fromDate: date })}
                  value={stateReport.fromDate}
                  variant="date"
                />
              </div>
              <div className="basis-1/2" data-testid="dateEnd">
                <Label htmlFor="dateEnd">
                  {t('pages.report_page.teams_tab.until')}
                </Label>
                <InputDate
                  id="dateEnd"
                  onChange={(date) => updateStateReport({ untilDate: date })}
                  value={stateReport.untilDate}
                  variant="date"
                />
              </div>
            </div>
          )}
          <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
            <div data-testid="reportEmail">
              <Switch
                checked={stateReport.reportEmail}
                onChange={(isCheck) =>
                  updateStateReport({ reportEmail: isCheck })
                }
              />
            </div>
            <div>
              <h4 className="pb-2 font-normal">
                {t('pages.report_page.teams_tab.toggle_email_heading')}
              </h4>
              <p className="text-xs text-grey-05">
                {t('pages.report_page.teams_tab.toggle_email_text')}
              </p>
            </div>
          </div>
          <div className="flex pt-6">
            <Button
              onClick={() => setIsOpenDownloadtModal(true)}
              disabled={stateReport.usersReport?.length < 1 && !allUsers}
            >
              {t('pages.report_page.teams_tab.btn_download')}
            </Button>
          </div>
        </div>
      </div>

      <ReportUsersDataTable reportTypeList={reportTypeList} />

      {isOpenDownloadtModal && (
        <DownloadReportModal
          report={'users'}
          isOpenDownloadtModal={isOpenDownloadtModal}
          closeModal={() => setIsOpenDownloadtModal(false)}
          setIsOpenDownloadtModal={setIsOpenDownloadtModal}
          stateReport={stateReport}
          user={auth?.user}
          updateStateReport={updateStateReport}
          allSelect={allUsers ? undefined : true}
        />
      )}
    </div>
  );
}

export default UsersReport;

import { useState, useEffect, forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  Button,
  Multiselect,
  IconButton,
  TeamAvatar,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';
import { axios } from '@/axios';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import { InviteUserOrTeam } from './InviteUserOrTeam';
import { UserInvite } from '@/components/UserInvite/UserInvite';
import { config } from '@/config';

const AddUserTeamStep = forwardRef(
  ({ state, teamInfo, student, setLoadStep, courseType }: any, ref) => {
    const {
      newUsers,
      newTeachers,
      setNewTeachers,
      setNewUsers,
      newTeams,
      setNewTeams,
      hasCustomLanding,
      setHasCustomLanding,
    } = state;

    const { t } = useTranslation();
    const [filter, setFilter] = useState('');
    const [newTeamUser, setNewTeamUser] = useState<any>([
      ...newTeams,
      ...newUsers,
    ]);
    const [value, setValue] = useState<any>([]);
    const [teachers, setTeachers] = useState<any>([]);
    const [reportSubteams, setReportSubteams] = useState<boolean>(false);

    const { auth } = useContext(AuthContext);
    const authUser = auth?.user as Userwithorganisationwithidps;

    useEffect(() => {
      const addTeamUser = [...newTeamUser];
      if (teamInfo) {
        const existingObj = addTeamUser.find(
          (team) => team?.id === teamInfo?.id
        );
        if (!existingObj) {
          addTeamUser.push({
            key: teamInfo?.id,
            id: teamInfo?.id,
            name: teamInfo?.teamName,
            teamId: teamInfo?.id,
            label: teamInfo?.teamName,
            organisationId: teamInfo?.organisationId,
            color: teamInfo?.color,
            isTeam: true,
          });
        }
      }
      if (student) {
        const existingObj = addTeamUser.find(
          (user) => user?.id === student?.id
        );
        if (!existingObj) {
          addTeamUser.push({
            key: student?.id,
            name: student?.username,
            id: student?.id,
            userId: student?.id,
            label: student?.username,
            organisationId: student?.organisationId,
            isTeam: false,
          });
        }
      }
      setNewTeamUser(addTeamUser);
      setTimeout(() => {
        setLoadStep(false);
      }, 100);
    }, []);

    useEffect(() => {
      setNewTeams(newTeamUser.filter((team: any) => team.teamId));
      setNewUsers(newTeamUser.filter((usr: any) => usr.userId));
    }, [newTeamUser]);

    const addSubTeams = async (teamId: number) => {
      const organisationId = authUser?.activeOrganisationId;
      const teamInfo: any = await axios.get(
        `${config.backend}/team/${organisationId}/${teamId}`
      );
      const addTeam = [...newTeamUser];
      const subTeams: any = await axios.get(
        `${
          config.backend
        }/teams?organisationId=${organisationId}&searchPath=${encodeURIComponent(
          teamInfo?.data?.teamPath + '%'
        )}&sort=teamPath`
      );
      if (subTeams?.data && subTeams?.data?.length > 0) {
        const teamSubteams = subTeams?.data;
        for (const sub of teamSubteams) {
          const existingTeam = addTeam.find(
            (obj1: any) => obj1?.teamId && obj1?.teamId === sub?.id
          );
          if (!existingTeam) {
            addTeam.push({
              courseId: null,
              isMember: false,
              key: `${sub?.id}_null_email`,
              label: sub?.teamName,
              name: sub?.teamName,
              organisationId: sub?.organisationId,
              teamId: sub?.id,
              userId: null,
            });
          }
        }
      }
      setNewTeamUser(addTeam);
    };

    const handleAddUserToList = async (newValue: any) => {
      const addTeam = [...newTeamUser];
      for (const obj2 of newValue) {
        const existingObj = addTeam.find(
          (obj1) =>
            (obj1?.userId && obj1?.userId === obj2?.userId) ||
            (obj1?.teamId && obj1?.teamId === obj2?.teamId)
        );
        if (!existingObj) {
          if (obj2?.teamId && reportSubteams) {
            const teamInfo: any = await axios.get(
              `${config.backend}/team/${obj2?.organisationId}/${obj2?.teamId}`
            );
            const subTeams: any = await axios.get(
              `${config.backend}/teams?organisationId=${
                obj2?.organisationId
              }&searchPath=${encodeURIComponent(
                teamInfo?.data?.teamPath + '%'
              )}&sort=teamPath`
            );
            if (subTeams?.data && subTeams?.data?.length > 0) {
              const teamSubteams = subTeams?.data;
              for (const sub of teamSubteams) {
                const existingTeam = addTeam.find(
                  (obj1) => obj1?.teamId && obj1?.teamId === sub?.id
                );
                const existingTeam_2 = newTeamUser.find(
                  (obj1: any) => obj1?.teamId && obj1?.teamId === sub?.id
                );
                if (!existingTeam && !existingTeam_2) {
                  addTeam.push({
                    courseId: null,
                    isMember: false,
                    isTeam: true,
                    color: sub?.color,
                    key: `${sub?.id}_null_email`,
                    label: sub?.teamName,
                    name: sub?.teamName,
                    organisationId: sub?.organisationId,
                    teamId: sub?.id,
                    userId: null,
                  });
                }
              }
            }
          } else {
            if (obj2.teamId) obj2.isTeam = true;
            const newItem = Object.assign({}, obj2, {
              isTeam: obj2.teamId ? true : false,
              isMember: obj2.userId ? true : false,
              color: obj2?.color,
              label: obj2?.name || obj2?.email,
            });
            addTeam.push(newItem);
          }
        }
      }
      setNewTeamUser(addTeam);
      setValue([]);
    };

    const handleAddTeacherToList = (newValue: any) => {
      const newNewTeachers = newTeachers.slice();
      for (const teacher of newValue) {
        const found = newTeachers.find(
          (oldTeacher: any) => teacher.key === oldTeacher.key
        );
        if (!found) {
          newNewTeachers.push(teacher);
        }
      }
      setNewTeachers(newNewTeachers);
      setTeachers([]);
    };

    const handleRemove = (id: any) => {
      const removeTeam = newTeamUser.filter((item: any) => item.key !== id);
      setNewTeamUser(removeTeam);
    };
    const handleRemoveTeacher = (id: any) => {
      const removeTeacher = newTeachers.filter((item: any) => item.key !== id);
      setNewTeachers(removeTeacher);
    };

    return (
      <div>
        <article className="prose flex flex-col ">
          <h3 className="text-base">
            {t('pages.course_content_flow.step_1.title')}
          </h3>
          <p className="pt-3 text-sm text-grey-07">
            {t('pages.course_content_flow.step_1.subtitle')}
          </p>
        </article>
        <div>
          <div className="mt-6">
            <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div data-testid="reportEmail">
                <Switch
                  checked={reportSubteams}
                  onChange={(isCheck) => {
                    setReportSubteams(isCheck);
                    if (isCheck) {
                      for (const team of newTeamUser.filter(
                        (x: any) => x.teamId !== null
                      )) {
                        addSubTeams(team?.teamId);
                      }
                    }
                  }}
                />
              </div>
              <div>
                <h4 className="font-normal">
                  {t('pages.manage_add_users.include_subteams')}
                </h4>
              </div>
            </div>
            <div id="invite_author" className="ali relative z-[12] flex w-full">
              <span className="w-full">
                <UserInvite
                  keyFn={(usr: any) => `${usr?.teamId}_${usr?.userId}_email`}
                  defaultSort={[{ id: 'name', asc: true }]}
                  render={(usr: any) => usr?.name || usr?.email}
                  fetch={useGet.bind(null, '/memberorteams', {
                    organisationId: authUser?.activeOrganisationId,
                    teamStatus: 'active,OR,teamStatus=null',
                  })}
                  //multiselectLabel={t('pages.create_edit_course.multiselect_label')}
                  inviteButtonText={t('pages.manage_add_users.add_button')}
                  value={value}
                  setValue={setValue}
                  handleAddUserToList={handleAddUserToList}
                  addValue={(email: string) => {
                    const newValue = [
                      ...value,
                      {
                        email: email,
                        label: email,
                        key: email,
                        id: email,
                        userId: email,
                        newUser: true,
                      },
                    ];
                    setValue(newValue);
                    return newValue;
                  }}
                />
              </span>
            </div>
          </div>
          <div>
            {newTeamUser.map((item: any) => (
              <div
                key={item.userId !== null ? item.key : item.teamId}
                className="mb-4 flex items-center justify-between"
              >
                <div className="flex items-center">
                  <TeamAvatar
                    id={item?.id}
                    name={item?.label}
                    bgColor={item?.color}
                  />

                  <div className="flex flex-col pl-4">
                    <span className="text-sm font-medium text-[#231f20]">
                      {item.label}
                    </span>
                  </div>
                </div>
                <div data-testid="daleteItem" className="my-auto mx-6">
                  <IconButton
                    Icon={CrossFilled}
                    variant="ghost"
                    onClick={() => handleRemove(item.key)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {courseType !== 'instruction' && (
          <>
            <article className="prose flex flex-col">
              <h3 className="mt-6 text-base">
                {t('pages.course_content_flow.step_1.title_teachers')}
              </h3>
              <p className="pt-3 text-sm text-grey-07">
                {t('pages.course_content_flow.step_1.subtitle_teachers')}
              </p>
            </article>
            <div>
              <div className="mt-6">
                <div
                  id="invite_teacher"
                  className="ali relative z-[11] flex w-full"
                >
                  <span className="w-full">
                    <UserInvite
                      //keyFn={(usr) => `${usr?.teamId}_${usr?.userId}_email`}
                      //defaultSort={[{id: "name", asc: true}]}
                      //render={(usr: any) => usr?.username || usr?.email}
                      fetch={useGet.bind(null, '/memberdetails', {
                        organisationId: authUser?.activeOrganisationId,
                        minrole: 70,
                      })}
                      //multiselectLabel={t('pages.create_edit_course.multiselect_label')}
                      inviteButtonText={t('pages.manage_add_users.add_button')}
                      value={teachers}
                      onChange={setTeachers}
                      handleAddUserToList={handleAddTeacherToList}
                      addValue={(email: string) => {
                        const newValue = [
                          ...value,
                          {
                            email: email,
                            label: email,
                            key: email,
                            id: email,
                            userId: email,
                            newUser: true,
                          },
                        ];
                        setTeachers(newValue);
                        return newValue;
                      }}
                    />
                  </span>
                </div>
              </div>
              <div>
                {newTeachers.map((item: any) => (
                  <div
                    key={item.userId !== null ? item.key : item.teamId}
                    className="mb-4 flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <TeamAvatar
                        id={item?.id}
                        name={item?.label}
                        bgColor={item?.color}
                      />
                      <div className="flex flex-col pl-4">
                        <span className="text-sm font-medium text-[#231f20]">
                          {item.label}
                        </span>
                      </div>
                    </div>
                    <div data-testid="daleteItem" className="my-auto mx-6">
                      <IconButton
                        Icon={CrossFilled}
                        variant="ghost"
                        onClick={() => handleRemoveTeacher(item.key)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-4 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div>
                <Switch
                  checked={hasCustomLanding}
                  onChange={(isCheck) => setHasCustomLanding(isCheck)}
                />
              </div>
              <div>
                <h4 className="pb-2 font-normal">
                  {t('pages.course_content_flow.step_1.switch')}
                </h4>
                <p className="text-xs text-grey-05">
                  {t('pages.course_content_flow.step_1.switch_description')}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

AddUserTeamStep.displayName = 'AddUserTeamStep';

export default AddUserTeamStep;

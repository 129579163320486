import { authActions } from '@/actions/auth';
import { App } from '@/App';
import { config } from '@/config';
import { parseQueryString, clearCache } from '@/util';
import ReactDOM from 'react-dom/client';
import { initLanguage } from './language';
import { worker as setupWorker } from './mocks/worker';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import meta from '@/meta.json';

axios
  .get('/meta.json', {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
  .then((response) => {
    const { version } = response.data;
    if (parseFloat(version) !== parseFloat(meta.version as unknown as string)) {
      //eslint-disable-next-line
      clearCache();
    }
  })
  .catch(console.error);

const start = async (auth: any) => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <App authData={auth} />
  );
};

initLanguage();

const queries = parseQueryString(document.location.search) as {
  [key: string]: string;
};

let token;

if (queries['JWT']) {
  token = queries['JWT'];
  localStorage.setItem('token', token);
  delete queries['JWT'];
} else {
  token = localStorage.getItem('token');
}

if (queries['koffer']) {
  localStorage.setItem('koffer', 'true');
}

if (queries['invite']) {
  localStorage.setItem('invite', queries['invite']);
  delete queries['invite'];
}

if (queries['access']) {
  localStorage.setItem('access', queries['access']);
  axios.defaults.headers.common['Authorization2'] =
    'Bearer ' + queries['access'];
  delete queries['access'];
}

if (queries['skipconsent'] === 'true') {
  localStorage.setItem('skipconsent', 'true');
}

if (queries['skipconsent'] === 'false') {
  localStorage.removeItem('skipconsent');
}

let path =
  window.location.protocol +
  '//' +
  window.location.host +
  window.location.pathname;
if (Object.values(queries).length > 0) {
  path += '?';
  for (const key of Object.keys(queries)) {
    path += key + '=' + queries[key] + '&';
  }
  path = path.slice(0, -1);
}
window.history.replaceState('', '', path);

if (config.enableMsw) {
  const worker = setupWorker();
  worker.start({ onUnhandledRequest: 'warn' });
}

if (token) {
  store
    .dispatch(authActions.loginToken(token))
    .then((auth: any) => {
      start(auth);
    })
    .catch(() => {
      start(null);
    });
} else {
  start(null);
}

import { Button } from '@teo/components';
import { TheoSearch } from '@teo/components/illustrations';
import { useTranslation } from 'react-i18next';

const EmptyTable = ({ template, text, btn_text, btnClick, filter }: any) => {
  const { t } = useTranslation();

  return (
    <>
      {template && (
        <div className="flex flex-col items-center px-4 pt-8">
          <div className="mb-8">
            {filter?.trim() ? (
              <img className="m-auto w-48" src="/images/notfound.svg" />
            ) : (
              <>
                {template == 1 && <TheoSearch className="m-auto w-48" />}
                {template == 2 && (
                  <img
                    className="m-auto w-48"
                    src="/images/components/notifications/theo-empty-inbox.svg"
                  />
                )}
              </>
            )}
          </div>
          <div className="mb-4 max-w-[320px] text-center">
            {text && !filter?.trim() ? (
              <p className="text-base font-semibold text-black">{text}</p>
            ) : (
              <p className="text-base font-semibold text-black">
                {t('empty_tenplate.empty_filter')}
              </p>
            )}
          </div>
          <div>
            {btn_text && !filter?.trim() && (
              <Button onClick={() => btnClick()}>{btn_text}</Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EmptyTable;

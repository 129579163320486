import React, { Component, Fragment } from 'react';
import { T } from '@/components/T';
import { newUid, getSafe, calculateUnit, Deferred } from '@/util';
import { ReactSVG } from 'react-svg';
import { Button, IconButton } from '@teo/components';
import { BoxIoService, digitalOutputs, analogInputs } from './BoxIoService';
import { ConfirmModal } from '@/components/ConfirmModal';
import * as PIXI from 'pixi.js';
import { Stage, Container } from '@pixi/react';
import { MultiMeter } from './Pixi/MultiMeter';
import { Slider } from './Pixi/Slider';
import RotaryButton from './Pixi/RotaryButton';
import CircularGauge from './Pixi/CircularGauge';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Svg } from './Svg';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import '@pixi/events';
import { Fullscreen, Reset } from '@teo/components/icons';

Object.defineProperty(window, 'Worker', { value: Worker });
import i18n from 'i18next';
var $ = window.$;

function hideContextMenu(event) {
  const pointerEvent = event;
  if (pointerEvent.pointerType === 'touch') {
    event.preventDefault();
    return false;
  }
  return true;
}

class BoxGUI extends Component {
  mousedown = false;
  clone = (e) => new e.constructor(e.type, e);
  forward = (e) => {
    let canvas = document.getElementById(this.uid + '_canvas');
    if (canvas) {
      canvas.dispatchEvent(this.clone(e));
      e.preventDefault();
    }
  };
  forward2 = (e) => {
    let canvas = document.getElementById(this.uid + '_canvas');
    if (canvas) {
      let clone = this.clone(e);
      let res = canvas.dispatchEvent(clone);
      if (clone.target.cancelScroll) {
        delete clone.target.cancelScroll;
        e.preventDefault();
      }
    }
  };

  updatePixiEls = (name, attrs) => {
    let pixiEls = [
      ['rotaries', this.renderRotary],
      ['sliders', this.renderSlider],
      ['gauges', this.renderGauge],
    ];

    for (let [arrName, render] of pixiEls) {
      let arr = this.state[arrName];

      if (name) {
        arr = arr.filter((x) => x.props.name === name);
      }

      let modified = false;
      for (let i in arr) {
        arr[i] = render(
          window.$(`*[data-uid='${arr[i].props.uid}']`, this.svg)[0]
        );
        modified = true;
      }

      if (modified) {
        this.setState({ [arrName]: [...arr] });
      }
    }
  };

  setLabel = (root, val) => {
    const name = root.data('name');
    const vmin = parseFloat(root.data('vmin')) || 0;
    const vmax = parseFloat(root.data('vmax')) || 24;
    const valuemin = parseFloat(root.data('minvalue')) || 0;
    const valuemax = parseFloat(root.data('maxvalue')) || 24;
    const unit = root.data('unit') || 'V';

    const value = (val - vmin) / (vmax - vmin);
    const showValue = valuemin + value * (valuemax - valuemin);

    root.find(`*[data-id="value"]`).text(showValue.toFixed(2) + ' ' + unit);
  };

  constructor(props) {
    super(props);
    PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2;
    this.app = new PIXI.Application({
      renderer: 'WebGL2',
      resizeTo: this.uid + '_canvas',
    });

    this.oldcontextmenu = window.oncontextmenu;

    props.setEvaluate && props.setEvaluate(this.evaluateExercise.bind(this));
    this.service = BoxIoService.getInstance();
    this.subscriptions = [];
    this.subscriptions.push(
      this.service.onState.subscribe((state) => {
        this.reset();
      })
    );

    this.done = new Deferred();
    this.service.waitFor.push(this.done);

    BoxGUI.instances[props.uid] = this.app;

    this.subscriptions.push(
      this.service.onStatelock.subscribe(({ name, locked }) => {
        //console.log("onStatelock", {name, locked})
        let roots = window.$(`*[data-name='${name}']`);
        roots.each((i, root) => {
          root = $(root);
          if (locked && root.attr('data-type') !== 'light') {
            root.attr('filter', 'url(#disabled)');
          } else {
            root.attr('filter', null);
          }
        });
        //this.updatePixiEls(name, {locked})
      })
    );

    //  Digital
    this.subscriptions.push(
      this.service.onDigital.subscribe((msg) => {
        let roots = window.$(`*[data-name='${msg.name}']`);
        roots.each((i, root) => {
          root = window.$(root);
          let inverted = root.data('inverted');
          let type = root.data(`type`);

          let state = this.service.state[msg.name].state;
          let buttonState = (state && !inverted) || (!state && inverted);

          if (type === 'conditional') {
            if (buttonState) root.show();
            else root.hide();
          }
          if (type === 'conditional-inverse') {
            if (buttonState) root.hide();
            else root.show();
          }

          setTimeout(() => {
            if (type) {
              root
                .find(`*[data-state="${buttonState ? 'true' : 'false'}"]`)
                .show();
              root
                .find(`*[data-state="${buttonState ? 'false' : 'true'}"]`)
                .hide();
            }
          }, 0);
        });
      })
    );

    //  Analog
    this.subscriptions.push(
      this.service.onAnalog.subscribe((msg) => {
        let roots = window.$(`*[data-name='${msg.name}']`, this.svg);
        let state = msg.value;

        roots.each((i, root) => {
          root = window.$(root);
          let inverted = root.data('inverted');
          let buttonState = (state && !inverted) || (!state && inverted);

          if (root.data('threshold')) {
            state = msg.value > root.data('threshold');
          } else state = msg.value > 9;

          //  Label
          if (root.data(`type`) === 'label') {
            this.setLabel(root, msg.value);
          }

          if (root.data(`type`) === 'conditional-inverse') {
            if (root.data('threshold')) {
              state = msg.value > root.data('threshold');
              if (!state) root.show();
              if (state) root.hide();
            }
          }

          if (root.data(`type`) === 'conditional-inverse') {
            if (root.data('threshold')) {
              state = msg.value > root.data('threshold');
              if (!state) root.show();
              if (state) root.hide();
            }
          }
          this.setLabel(root, msg.value);
          //root.find(`*[data-id="value"]`).text(calculateUnit(msg.value, root.data('unit') || 'volt', 24));
          root.find(`*[data-state="${!!buttonState}"]`).show();
          root.find(`*[data-state="${!buttonState}"]`).hide();
        });
      })
    );

    this.subscriptions.push(
      this.service.onStream.subscribe((name, values) => {
        //console.log("onStream", name, values)
      })
    );
  }

  uid = newUid(20);
  state = {
    width: 800,
    height: 600,
    rotaries: [],
    sliders: [],
    gauges: [],
    tri_state: [],
    fullscreen: this.props.fullscreen,
  };
  timeouts = {};
  timepressed = {};

  //  Slider
  renderSlider = (el, attrs) => {
    let state = this.service.state;
    let isVertical = false;

    let root = $(el);
    root.visibility = undefined;

    const tag = root.prop('tagName');
    if (tag === 'g') return;

    root.find('g').hide(); //hide the fixed svg bar
    root.find('rect').hide(); //hide the fixed svg bar

    let closest = root.closest('[data-name]');
    let name = closest.data('name');
    let vmin = parseFloat(closest.data('vmin'));
    let vmax = parseFloat(closest.data('vmax'));

    let slider = root;

    let x = parseFloat(slider.attr('x'));
    let y = parseFloat(slider.attr('y'));
    let width = parseFloat(slider.attr('width'));
    let height = parseFloat(slider.attr('height'));

    const sliderScale = 1;
    // Checks if slider is vertical and adapts values
    if (height > width) {
      isVertical = true;
    }

    let sliderNode = (
      <Slider
        key={newUid(20)}
        uid={el.dataset.uid}
        vmin={vmin || 0}
        vmax={vmax || 24}
        scale={sliderScale}
        isVertical={isVertical}
        name={name}
        height={isVertical ? width : height}
        width={isVertical ? height : width}
        x={x}
        y={y}
        {...attrs}
      />
    );

    return sliderNode;
  };

  renderRotary = (el, attrs) => {
    let state = this.service.state;
    let root = $(el);

    const name = root.data('name');
    const vmin = parseFloat(root.data('vmin'));
    const vmax = parseFloat(root.data('vmax'));

    let rotary = root;

    let x = 85 + parseFloat(rotary.attr('x'));
    let y = parseFloat(rotary.attr('y') - 25);
    let width = parseFloat(rotary.attr('width'));
    let height = parseFloat(rotary.attr('height'));

    const rotWidth = 0.2;

    return (
      <RotaryButton
        key={newUid(20)}
        uid={el.dataset.uid}
        value={state[name] ? state[name].value : 0}
        name={name}
        x={x}
        y={y}
        width={height}
        height={width}
        scaleRot={rotWidth}
        vmin={vmin || 0}
        vmax={vmax || 24}
        {...attrs}
      />
    );
  };

  renderGauge = (el, attrs) => {
    let state = this.service.state;

    let root = $(el);
    let name = root.data('name');

    let gauge = root;

    let x = 104 + parseFloat(gauge.attr('x')); //* scaleGauge;
    let y = parseFloat(gauge.attr('y') - 50); //* scaleGauge;
    let width = parseFloat(gauge.attr('width')); //* scaleGauge;
    let height = parseFloat(gauge.attr('height')); //* scaleGauge;

    const gaugeWidth = 0.1;

    return (
      <CircularGauge
        key={newUid(20)}
        uid={el.dataset.uid}
        valuemin={parseFloat(gauge.data('valuemin')) || 0} //  Sets minimum for the gauge value labels
        valuemax={parseFloat(gauge.data('valuemax')) || 0} //  Sets the maximum for the gauge value labels
        vmin={parseFloat(gauge.data('vmin')) || 0} //  Sets the minimum expected voltage from the ADC needed for calculating the arrow direction
        vmax={parseFloat(gauge.data('vmax')) || 24} //  Sets the maximum expected voltage from the ADC needed for calculating the arrow direction
        unit={parseFloat(gauge.data('unit')) || '°C'} //  Sets the expected Unit
        scale={gaugeWidth}
        name={name}
        x={x}
        y={y + 31}
        width={width}
        height={height}
        {...attrs}
      />
    );
  };

  resetDigital(type, isConditional) {
    let state = this.service.state;
    let els = window.$(`*[data-type="${type}"]`, this.svg);
    els.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      try {
        if (!name) name = root.parent().data('name');
        if (!name) name = root.parent().parent().data('name');
      } catch (e) {
        console.error(e);
      }
      if (!this.service.state[name]) this.service.assertState(name);
      let state = this.service.state[name].state;
      if (root.data('threshold')) {
        state = this.service.state[name].value;
      }
      if (type === 'conditional') {
        if (state) root.show();
        if (!state) root.hide();
      } else if (type === 'conditional-inverse') {
        if (!state) root.show();
        if (state) root.hide();
      } else {
        root.find(`*[data-state="${!!state}"]`).show();
        root.find(`*[data-state="${!state}"]`).hide();
      }
      if (this.service.isStateLocked(name))
        root.attr('filter', 'url(#disabled)');
    });
  }

  reset() {
    let state = this.service.state;
    this.resetDigital('switch');
    this.resetDigital('button');
    this.resetDigital('tri_buttons');
    this.resetDigital('light');
    this.resetDigital('conditional');
    this.resetDigital('conditional-inverse');
    let labels = window.$(`*[data-type="label"]`, this.svg);
    labels.each((i, el) => {
      /******************** Label *******************/
      let root = $(el);
      let name = root.data('name');

      if (!state[name]) {
        return console.warn(
          `name: ${name} is not valid for label use`,
          Object.keys(state).join(', ')
        );
      }

      if (this.service.isStateLocked(name))
        root.attr('filter', 'url(#disabled)');
    });
    this.updatePixiEls();
  }

  init() {
    if (!this.service.state) return;
    if (this.initialised) return;
    if (!this.svgRendered) return;

    if (!this.svg) return;
    this.initialised = true;

    let _this = this;

    //******************* Conditionals ***********************//
    let conditionals = window.$(`[data-type="conditional"]`, this.svg);
    conditionals.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      let clickable = root.data('clickable');
      let inverted = root.data('inverted');
      this.service.sendDigital(name, !!inverted);
      if (clickable) {
        root.on('pointerdown', (ev) => {
          let state = this.service.state;
          if (this.service.isStateLocked(name)) return;
          state[name].state = !state[name].state;
          this.service.sendDigital(name, state[name].state);
        });
        if (this.service.isStateLocked(name))
          root.attr('filter', 'url(#disabled)');
      }
    });

    //******************* Conditionals-inverse ***********************//
    let conditionalsInverse = window.$(
      `[data-type="conditional-inverse"]`,
      this.svg
    );
    conditionalsInverse.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      let clickable = root.data('clickable');
      if (clickable) {
        root.on('pointerdown', (ev) => {
          let state = this.service.state;
          if (this.service.isStateLocked(name)) return;
          state[name].state = !state[name].state;
          this.service.sendDigital(name, state[name].state);
        });
        if (this.service.isStateLocked(name))
          root.attr('filter', 'url(#disabled)');
      }
    });

    //********************* Switch **************************//
    let switches = window.$(`*[data-type="switch"]`, this.svg);
    switches.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      let inverted = root.data('inverted');
      this.service.sendDigital(name, !!inverted);
      root.on('pointerdown', (ev) => {
        let state = this.service.state;
        if (this.service.isStateLocked(name)) return;
        state[name].state = !state[name].state;
        this.service.sendDigital(name, state[name].state);
      });
      if (this.service.isStateLocked(name))
        root.attr('filter', 'url(#disabled)');
    });

    //*******************  Buttons ********************//
    let buttons = window.$(`*[data-type="button"]`, this.svg);
    buttons.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      let inverted = root.data('inverted');
      this.service.sendDigital(name, !!inverted);
      root.on('pointerdown touchstart', (ev) => {
        if (this.service.isStateLocked(name)) return;
        this.service.sendDigital(name, !inverted);
        _this.timepressed[name] = Date.now();
        if (_this.timeouts[name]) clearTimeout(_this.timeouts[name]);
      });
      root.on('pointerup mouseleave touchend', (ev) => {
        let timePressed = Date.now() - _this.timepressed[name];
        if (timePressed < 500) {
          _this.timeouts[name] = setTimeout(() => {
            clearTimeout(_this.timeouts[name]);
            this.service.sendDigital(name, !!inverted);
          }, 500 - timePressed);
        } else {
          this.service.sendDigital(name, !!inverted);
        }
      });
      if (this.service.isStateLocked(name))
        root.attr('filter', 'url(#disabled)');
    });

    //******************* Tri button ******************************//
    let tri_buttons = window.$(`*[data-type="tri_button"]`, this.svg);
    tri_buttons.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      root.on('pointerdown touchstart', (ev) => {
        let state = this.service.state;
        let value = state[name].state;
        root.find(`*[data-state="prestine"]`).hide();
        root.find(`*[data-state="${!!value}"]`).show();
        root.find(`*[data-state="${!value}"]`).hide();
        if (!value) {
          setTimeout(() => {
            root.find(`*[data-state="prestine"]`).show();
          }, 1000);
        }
      });
    });

    //******************* Clocks ******************************//
    let clocks = window.$(`*[data-type="clock"]`, this.svg);
    clocks.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      let high = parseFloat(root.data('high')) || 1;
      let low = parseFloat(root.data('low')) || 1;
      let delay = parseFloat(root.data('delay')) || 0;

      let cycle = () => {
        this.service.sendDigital(name, true);
        setTimeout(() => {
          this.service.sendDigital(name, false);
          setTimeout(() => {
            cycle();
          }, low * 1000);
        }, high * 1000);
      };

      setTimeout(() => {
        cycle();
      }, delay * 1000);
    });

    //*************** Init labels *****************//
    let labels = window.$(`*[data-type="label"]`, this.svg);
    labels.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      let value = getSafe(() => this.service.state[name].value, 0);
      this.setLabel(root, value);
    });

    //*************** Slider *****************//
    let slider = window.$(`*[data-type="slider"]`, this.svg);
    let sliders = [];

    let scale = 1;
    try {
      let realBox = this.svg.getBoundingClientRect();
      let origBox = this.svg.getBBox();
      scale = realBox.width / origBox.width;
    } catch (e) {
      console.error(e);
    }

    slider.each((i, el) => {
      el.setAttribute('data-uid', newUid(20));
      sliders.push(this.renderSlider(el));
    });

    this.setState({ sliders: sliders });

    //****************** Rotary button *******************//
    let rotary = window.$(`*[data-type="rotary"]`, this.svg);
    let rotaries = [];

    rotary.each((i, el) => {
      el.setAttribute('data-uid', newUid(20));
      rotaries.push(this.renderRotary(el));
    });
    this.setState({ rotaries: rotaries });

    //*********** Circular Gauge *****************//
    let gauge = window.$(`*[data-type="gauge"]`, this.svg);
    let gauges = [];

    gauge.each((i, el) => {
      el.setAttribute('data-uid', newUid(20));
      gauges.push(this.renderGauge(el));
    });
    this.setState({ gauges: gauges });

    this.reset();
    this.onInitilized && this.onInitilized();

    //** Anything with an i2c address *****************//
    let i2cs = window.$(`*[data-address]`, this.svg);
    i2cs.each((i, el) => {
      let root = $(el);
      let name = root.data('name');
      let address = root.data('address');
      let command = root.data('command');
      this.service.assertI2C(name, address, command);
    });

    this.done.resolve();
    this.service.checkLoaded();
  }

  markIncorrect = () => {
    let state = this.service.state;
    window.$(`*[data-name]`, this.svg).css('outline', 'none');

    for (let io of Object.values(this.props.state.io)) {
      if (io.state && '' + state[io.name].state != '' + io.state) {
        window
          .$(`*[data-name="${io.name}"]`, this.svg)
          .css('outline', '1px solid red');
      }
      if (io.min && state[io.name].value < io.min) {
        window
          .$(`*[data-name="${io.name}"]`, this.svg)
          .css('outline', '1px solid red');
      }
      if (io.max && state[io.name].value > io.max) {
        window
          .$(`*[data-name="${io.name}"]`, this.svg)
          .css('outline', '1px solid red');
      }
    }

    if (this.props.state.triggers) {
      for (let trigger of this.props.state.triggers) {
        if (trigger.required && !this.service.checkTrigger(trigger)) {
          window
            .$(`*[data-name="${trigger.name}"]`, this.svg)
            .css('outline', '1px solid red');
        }
      }
    }
  };

  evaluateExercise = async (incorrectMessage, showEvaluation = true) => {
    let state = this.service.state;
    let faults = [];

    if (!state)
      return console.warn('Can not evaluate, is the controller connected ?');
    let correct = true,
      free = false;

    if (this.props.state.triggers) {
      for (let trigger of this.props.state.triggers) {
        if (trigger.required) {
          let active = this.service.checkTrigger(trigger);
          if (!active) {
            correct = false;
            faults.push(trigger);
          }
        }
      }
    }

    if (this.props.state.corrections) {
      for (let correction of this.props.state.corrections) {
        let active = await this.service.checkCorrection(correction);
        if (!active) {
          correct = false;
          faults.push(correction);
          free = !!correction.free;
          break;
        }
      }
    }

    for (let io of Object.values(this.props.state.io)) {
      if (
        digitalOutputs.includes(io.name) &&
        io.state &&
        '' + state[io.name].state != '' + io.state
      ) {
        correct = false;
        faults.push({
          description: `${io.name} should be ${io.state ? 'HIGH' : 'LOW'}`,
        });
      }
      if (analogInputs.includes(io.name)) {
        let min = parseFloat(io.min),
          max = parseFloat(io.max);
        if (io.min && state[io.name].value < min) {
          correct = false;
          faults.push({
            description: `${io.name} should be more than ${io.min}V`,
          });
        }
        if (io.max && state[io.name].value > max) {
          correct = false;
          faults.push({
            description: `${io.name} should be less than ${io.max}V`,
          });
        }
      }
    }

    if (this.props.setCorrect) {
      this.props.setCorrect(correct);
    }

    if (showEvaluation) {
      if (correct) {
        this.setState({ isCorrect: true, markIncorrect: true });
      } else {
        this.setState({
          isInCorrect: true,
          markIncorrect: true,
          incorrectMessage,
          faults,
        });
      }
    }

    return { free, correct };
  };

  componentDidUpdate() {
    if (this.state.markIncorrect) setTimeout(this.markIncorrect, 0);
  }

  updateDimensions = () => {
    if (this.svg) {
      let realBox = this.svg.getBoundingClientRect();

      let width = realBox.width;
      let height = realBox.height;

      let svgWidth = parseFloat(this.svg.getAttribute('width'));
      let svgHeight = parseFloat(this.svg.getAttribute('height'));

      let aspectRatio = svgWidth / svgHeight;
      let newAspectRatio = width / height;

      if (newAspectRatio > aspectRatio) {
        width = height * aspectRatio;
      }
      if (newAspectRatio < aspectRatio) {
        height = width * aspectRatio;
      }

      let scale = width / svgWidth;

      this.setState({ width, height, scale });
    }
  };

  componentWillUnmount() {
    delete BoxGUI.instances[this.props.uid];
    this.ro.disconnect();
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    document.removeEventListener('contextmenu', hideContextMenu, true);
  }

  componentDidMount() {
    //These events and these events only are forwarded from the svg layer to the PIXI layer
    //Add to this list if you need access to other interactions
    const overlay = document.getElementById(this.uid + '_overlay');
    const canvas = document.getElementById(this.uid + '_canvas');

    if (canvas) {
      overlay.addEventListener('pointerdown', this.forward);
      overlay.addEventListener('mousedown', this.forward);
      overlay.addEventListener('touchstart', this.forward2);
      overlay.addEventListener('mouseup', this.forward);
      overlay.addEventListener('mouseupoutside', this.forward);
      overlay.addEventListener('touchend', this.forward);
      overlay.addEventListener('touchendoutside', this.forward);
      overlay.addEventListener('mousemove', this.forward);
      overlay.addEventListener('touchmove', this.forward2);
    }
    //window.addEventListener('resize', this.updateDimensions);
    this.ro = new ResizeObserver((entries) => {
      this.updateDimensions();
    });
    try {
      this.ro.observe(document.getElementById(this.uid + '_overlay'));
    } catch (e) {
      console.error(e);
    }

    document.addEventListener('contextmenu', hideContextMenu, true);
  }

  sizeFull = (svg) => {
    if (!svg) return;
    let svgW = window.innerWidth;
    let svgH = window.innerHeight;
    let bb = svg.getBBox();

    let bbx = bb.x;
    let bby = bb.y;
    let bbw = bb.width;
    let bbh = bb.height;

    //svg.setAttribute("viewBox",bbx+" "+bby+" "+bbw+" "+bbh )
    svg.setAttribute('width', svgW);
    svg.setAttribute('height', svgH - 74);
  };

  render() {
    let fullscreen = this.state.fullscreen;
    if (fullscreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return (
      <Fragment>
        <svg style={{ display: 'none' }}>
          <filter id="disabled">
            <feColorMatrix
              type="matrix"
              values="0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0.4 0 "
            ></feColorMatrix>
          </filter>
        </svg>
        {this.state.isCorrect && (
          <ConfirmModal
            open
            title="widgets.box.exercise_correct"
            description="widgets.box.exercise_correct_description"
            onConfirm={() => this.setState({ isCorrect: false })}
          />
        )}
        {this.state.isInCorrect && (
          <ConfirmModal
            open
            title="widgets.box.exercise_incorrect"
            description="widgets.box.exercise_incorrect_description"
            onConfirm={() => this.setState({ isInCorrect: false })}
          >
            <div
              className="flex-col"
              style={{ width: '100%', marginTop: '20px' }}
            >
              <div
                className="flex-column"
                style={{ alignItems: 'center', gap: 20 }}
              >
                {/*(this.state.faults || []).map(x => (<div><i className="far fa-times inline" style={{color: 'red', marginRight: 20}}></i>{x.description}</div>))*/}
                {this.state.faults && this.state.faults.length > 0 && (
                  <div>
                    <i
                      className="far fa-times inline"
                      style={{ color: 'red', marginRight: 20 }}
                    ></i>
                    {this.state.faults[0].description}
                  </div>
                )}
              </div>
              <br />
              <FroalaTextarea
                mode="view"
                value={this.state.incorrectMessage}
              ></FroalaTextarea>
            </div>
          </ConfirmModal>
        )}
        <div
          style={{
            backgroundColor: 'white',
            position: 'relative',
            pointerEvents: 'none',
          }}
        >
          <div
            className={fullscreen ? 'fullscreen-wrapper' : 'contained-wrapper'}
            id={this.uid + '_overlay'}
            style={{
              top: 0,
              zIndex: 1,
              background: 'white',
              userSelect: 'none',
              pointerEvents: 'all',
              border: 0,
            }}
          >
            <div className="pointer-events-none absolute flex w-full justify-end gap-1 p-1">
              <IconButton
                Icon={Fullscreen}
                className="pointer-events-auto bg-white"
                variant="outline"
                size="md"
                onClick={() => this.setState({ fullscreen: !fullscreen })}
              />
              <IconButton
                Icon={Reset}
                className="pointer-events-auto bg-white"
                variant="outline"
                size="md"
                onClick={() => this.service.sendReset()}
              />
            </div>
            {this.props.src && (
              <Svg
                uid={this.uid}
                src={this.props.src}
                afterInjection={(svg) => {
                  this.svgRendered = true;
                  this.svg = svg;
                  if (svg) {
                    setTimeout(() => {
                      this.updateDimensions();
                    }, 0);
                    this.init(this.service.state);
                  }
                }}
              />
            )}
          </div>
          <Stage
            className={fullscreen ? 'fullscreen-wrapper' : 'contained-wrapper'}
            id={this.uid + '_canvas'}
            width={this.state.width}
            height={this.state.height}
            app={this.app}
            options={{ resolution: 2, backgroundAlpha: 0, antialias: true }}
            style={{
              position: 'absolute',
              top: 0,
              zIndex: 2,
              pointerEvent: 'none',
            }}
          >
            <Container scale={this.state.scale || 1}>
              {this.state.rotaries}
              {this.state.sliders}
              {this.state.gauges}
              {/*this.props.box.showMultimeter && <MultiMeter name="A0" maxVolt={24} />*/}
            </Container>
          </Stage>
        </div>
      </Fragment>
    );
  }
}

BoxGUI.instances = {};

const mapStateToProps = (state, props) => {
  return { box: state.box };
};

const connectBoxGUI = connect(mapStateToProps)(BoxGUI);
connectBoxGUI.instances = BoxGUI.instances;
export { connectBoxGUI as BoxGUI };

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Document,
  Chapter,
  Pencil,
  DragHandle,
  Image,
  Play,
  Check,
} from '@teo/components/icons';
import { IconButton } from '@teo/components';
import { axios } from '@/axios';
import { config } from '@/config';
import { CustomNodeAction } from './CustomNodeAction';
import { useMobile, walk } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';

export const CustomNode = (props: any) => {
  const {
    node,
    active,
    className,
    style,
    preview,
    newTree,
    setTree,
    coursecontentId,
    setAddLesson,
    setAddFolder,
    setLessons,
    lessons,
    setDirectories,
    onRefresh,
    handleOpenLibrary,
    setUpdateLesInfo,
    setLoadVersion,
    loadVersion,
    setNewActiveNode,
    newActiveNode,
    isFroalaLoaded,
    ...other
  } = props;
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const { droppable, data } = node;
  const indent = props.depth * 24;
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState(node?.text);
  const [addNumber, setAddNumber] = useState(node?.data?.numbered);
  const [isOpenImageLibrary, setIsOpenImageLibrary] = useState(false);
  const inputRef = useRef<any>(null);
  const itemRef = useRef<any>(null);
  const isMobile = useMobile();

  const userRole = auth?.user?.role || 0;

  useEffect(() => {
    setNewName(node?.text);
  }, [node]);

  const handleBlur = () => {
    if (droppable) {
      axios.put(`${config.backend}/directory/${data?.id}`, {
        id: data?.id,
        name: newName,
      }),
        setDirectories((prev: any) =>
          prev?.map((el: any) =>
            el.id === data?.id ? { ...el, name: newName } : el
          )
        );
    } else {
      if (data?.lessonId) {
        axios.put(`${config.backend}/lesson/${data?.lessonId}`, {
          id: data?.id,
          name: newName,
        }),
          setLessons((prev: any) =>
            prev?.map((el: any) =>
              el.id === data?.id ? { ...el, name: newName } : el
            )
          );
      } else {
        axios.put(`${config.backend}/asset/${data?.assetId}`, {
          id: data?.id,
          assetName: newName,
        }),
          setLessons((prev: any) =>
            prev?.map((el: any) =>
              el.id === data?.id ? { ...el, assetName: newName } : el
            )
          );
      }
    }
    setNewActiveNode(null);
    setEditName(false);
    setTree(null);
  };

  const handleDelete = async () => {
    if (droppable) {
      const filteredLessons = lessons?.filter(
        (item: any) =>
          item?.path?.includes(data?.path) &&
          (item?.rootLessonId || item?.rootDirectoryId)
      );

      for (const el of filteredLessons) {
        await axios.delete(`${config.backend}/use/${el?.usesId}`);
        setLessons((prev: any) =>
          prev?.filter((der: any) => der?.id !== el?.id)
        );
      }

      const filteredTreeFalse = newTree?.filter(
        (item: any) =>
          item.data?.path?.includes(data?.path) && item.droppable === false
      );

      for (const el of filteredTreeFalse) {
        await axios.delete(`${config.backend}/use/${el?.data?.usesId}`);
        setLessons((prev: any) =>
          prev?.filter((der: any) => der?.id !== el?.data?.id)
        );
      }

      const filteredTreeTrue = newTree?.filter(
        (item: any) =>
          item.data?.path?.includes(data?.path) &&
          item.droppable === true &&
          item?.id != node?.id
      );

      for (const el of filteredTreeTrue) {
        await axios.delete(`${config.backend}/directory/${el?.id}`);
        setDirectories((prev: any) =>
          prev?.filter((der: any) => der?.id !== el?.id)
        );
      }

      await axios.delete(`${config.backend}/directory/${node?.id}`);
      setDirectories((prev: any) =>
        prev?.filter((der: any) => der?.id !== node?.id)
      );

      setIsOpenImageLibrary(false);
    } else {
      const filteredLessons = lessons?.filter(
        (item: any) =>
          item?.rootLessonId &&
          item?.rootLessonId === node?.data?.lessonId &&
          item?.path?.includes(data?.path)
      );

      for (const el of filteredLessons) {
        await axios.delete(`${config.backend}/use/${el?.usesId}`);
        setLessons((prev: any) =>
          prev?.filter((der: any) => der?.id !== el?.id)
        );
      }

      await axios.delete(`${config.backend}/use/${node?.data?.usesId}`);
      if (node?.data?.lessonId || node?.data?.assetId) {
        setLessons((prev: any) =>
          prev?.filter((el: any) => el?.usesId !== node?.id)
        );
      }
      setIsOpenImageLibrary(false);
    }
  };

  const handleEditClick = () => {
    setEditName(true);
  };

  useEffect(() => {
    if (node?.data?.lessonId) {
      if (isFroalaLoaded) {
        if (
          newActiveNode &&
          node?.id === newActiveNode &&
          isFroalaLoaded === newActiveNode
        ) {
          handleEditClick();
        }
      }
    } else {
      if (newActiveNode && node?.id === newActiveNode) {
        handleEditClick();
      }
    }
  }, [newActiveNode, isFroalaLoaded]);

  useEffect(() => {
    if (editName && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editName]);

  return (
    <div
      className={
        `relative my-2 flex cursor-pointer items-center justify-between rounded-md pl-2 pr-1 ` +
        className +
        (active ? ' bg-[#f8f8f9]' : '')
      }
      style={{ marginLeft: `${indent}px`, ...style }}
      {...other}
      ref={itemRef}
    >
      <span
        className={
          'flex w-full items-center ' +
          (preview || isMobile
            ? ''
            : ' hidefirstchildonhover hidelastchildonhover')
        }
      >
        <DragHandle className="absolute ml-2 w-5 min-w-5 -translate-x-full text-[#dfdfe2]" />
        {droppable || false ? (
          <Chapter className="m-2 w-6 min-w-6" />
        ) : node?.data?.lessonId ? (
          <Document
            className={`m-2 w-6 min-w-6 ${
              !node?.data?.canEdit && node?.data?.lessonId && 'text-grey-05'
            } `}
          />
        ) : node?.data?.assetType === 'image' ? (
          <Image className="m-2 w-6 min-w-6 " />
        ) : (
          <Play className="m-2 w-6 min-w-6 " />
        )}
        {!editName ? (
          <p
            className={`w-full max-w-[180px] flex-1 text-sm font-normal	text-[#231f20] ${
              !node?.data?.canEdit && node?.data?.lessonId && 'text-grey-05'
            } `}
          >
            {addNumber && node?.data?.order + '.'} {newName}
          </p>
        ) : (
          <input
            ref={inputRef}
            id={`edit_${node?.id}`}
            onChange={({ target: { value } }) => {
              setNewName(value);
            }}
            onBlur={() => handleBlur()}
            onKeyPress={(event) => {
              if (event.charCode === 13) {
                handleBlur();
              }
            }}
            value={newName}
            className="h-8 max-w-[180px] rounded-md border border-solid px-3 py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 !ring-0 focus:border-secondary-04 focus:bg-white focus:outline-secondary-01"
          />
        )}
        {active && (
          <Pencil
            className="mx-2 w-5 min-w-5 text-[#fdbe06]"
            onClick={handleEditClick}
          />
        )}
        {node?.data?.lessonVerified && (
          <Check className={`w-6 pl-2 pr-0 text-success-05 sm:w-9 sm:pr-2`} />
        )}
        {node?.data?.directoryVerified && (
          <Check className={`w-6 pl-2 pr-0 text-success-05 sm:w-9 sm:pr-2`} />
        )}
        <CustomNodeAction
          onRefresh={onRefresh}
          node={node}
          newTree={newTree}
          coursecontentId={coursecontentId}
          setAddLesson={setAddLesson}
          setAddFolder={setAddFolder}
          handleEditClick={handleEditClick}
          handleDelete={handleDelete}
          setTree={setTree}
          droppable={droppable}
          setNewName={setNewName}
          setLessons={setLessons}
          setDirectories={setDirectories}
          isMobile={isMobile}
          handleOpenLibrary={handleOpenLibrary}
          setUpdateLesInfo={setUpdateLesInfo}
          setAddNumber={setAddNumber}
          addNumber={addNumber}
          setLoadVersion={setLoadVersion}
          loadVersion={loadVersion}
          setNewActiveNode={setNewActiveNode}
          setIsOpenImageLibrary={setIsOpenImageLibrary}
          isOpenImageLibrary={isOpenImageLibrary}
          editName={editName}
          itemRef={itemRef}
        />
      </span>
    </div>
  );
};

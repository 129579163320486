import { useContext, useEffect, useState } from 'react';
import { CounterTile, ContentCardListItem } from '@teo/components';
import {
  DuoToneAssessmentOutline,
  DuoToneCourseOutline,
  DuoToneTrajectoryOutline,
  DuoToneVacancyOutline,
} from '@teo/components/icons';
import { TheoDelivery } from '@teo/components/illustrations';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import ListOfCourses from './ListOfCourses';
import { useGet } from '@/query';
// import { Learningpathprogressdetails } from '@/models'; // todo: use later for LP?
import { Followedcourses } from '@/models';
import { useNavigate } from 'react-router-dom';
import { nthLastIndexOf } from '@/util';
import EmptyTable from '@/components/DataTable/EmptyTable';
import { InstructionsIcon } from '../../../../constants/sidebar.constants';

interface User {
  firstName?: string | null;
  nrOfAssessments?: number | null;
  nrOfCourses?: number | null;
  nrOfLearningVacancies?: number | null;
}

function LearnIndexPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [isError, setIsError] = useState<any>(false);
  const [dashboardCourses, setDashboardCourses] = useState<any>(null);

  const authUser = auth?.user as any;
  const userId = auth?.user?.id;

  const originPath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );

  useEffect(() => {
    if (isError) {
      return navigate(`${originPath}/404`);
    }
  }, [isError]);

  const newCourses = {
    scope: 'nothidden',
    status: 'pending,OR,status=null',
    organisationId: authUser?.activeOrganisationId + ',OR,organisationId=null',
    sort: '-followsUpdatedAt',
  };

  const oldCourses = {
    scope: 'nothidden',
    status: 'active',
    minprogress: '0',
    maxprogress: '1',
    sort: '-updatedAt',
  };

  const activityResult = useGet(`/activity?count=5&sort=-createdAt`, {
    userId: auth?.user?.id,
    activityType: 'learn',
  });
  const recentCourses = (activityResult?.data?.data as any[]) || [];

  const basePath = window.location.pathname;

  const { data, status } = useGet<Array<Followedcourses>>('/mycourses', {
    scope: 'nothidden',
    courseStatus: 'active',
    status: 'pending',
    offset: '0',
    count: 1,
    userId: authUser?.id,
    progress: '0',
    sort: '-followsUpdatedAt',
    onError: (res: any) => {
      console.error('res', res);
      setIsError(true);
      navigate(`${originPath}/404`);
    },
  });

  const coursePath = basePath.endsWith('/')
    ? basePath.slice(0, basePath.lastIndexOf('/'))
    : basePath;

  return (
    <div className="m-auto flex w-full max-w-[49rem] flex-col items-center">
      <div className="flex w-full items-center justify-between">
        <h1 className="mb-8 text-xl font-semibold text-black">
          {t('pages.learn_index.hello')} {authUser?.firstName}, <br />
          {t('pages.learn_index.second_title')}
        </h1>
      </div>
      {[
        authUser?.useCourse,
        authUser?.useAssessment,
        authUser?.useLeervacature,
        authUser?.useInstruction,
      ].filter(Boolean).length > 1 && (
        <div className="flex w-full flex-wrap gap-2">
          {authUser?.useCourse && (
            <div className="min-w-[48%] flex-[1_0_48%] lg:min-w-max lg:flex-[1_0_23%]">
              <CounterTile
                Icon={DuoToneCourseOutline}
                count={`${authUser?.nrOfCourses ? authUser?.nrOfCourses : ''}`}
                title={t('links.courses')}
                to="./courses"
              />
            </div>
          )}
          {authUser?.useAssessment && (
            <div className="min-w-[48%] flex-[1_0_48%] lg:min-w-max lg:flex-[1_0_23%]">
              <CounterTile
                Icon={DuoToneAssessmentOutline}
                count={`${
                  authUser?.nrOfAssessments ? authUser?.nrOfAssessments : ''
                }`}
                title={t('links.assessments')}
                to="./assessments"
              />
            </div>
          )}
          {authUser?.useLeervacature && (
            <div className="min-w-[48%] flex-[1_0_48%] lg:min-w-max lg:flex-[1_0_23%]">
              <CounterTile
                Icon={DuoToneVacancyOutline}
                count={`${
                  authUser?.nrOfLearningVacancies
                    ? authUser?.nrOfLearningVacancies
                    : ''
                }`}
                title={t('links.vacancies')}
                to="./vacancies"
              />
            </div>
          )}
          {authUser?.useInstruction && (
            <div className="min-w-[48%] flex-[1_0_48%] lg:min-w-max lg:flex-[1_0_23%]">
              <CounterTile
                Icon={InstructionsIcon}
                title={t('links.instructions')}
                to="./instructions"
              />
            </div>
          )}
        </div>
      )}

      <div
        className={`w-full ${
          (!dashboardCourses || !dashboardCourses?.length) && 'hidden'
        }`}
      >
        <div className="mt-6 w-full items-center justify-between rounded bg-grey-01 p-4 text-center sm:p-6 md:flex md:text-left ">
          <div className="md:flex-[1_1_30%]">
            <TheoDelivery className="mx-auto w-36 md:mr-auto md:ml-0" />
          </div>
          <div className="md:flex-[1_1_65%]">
            {data?.data && (
              <>
                <p className="text-md font-semibold text-[#231f20]">
                  {data?.data?.map((course: any) => {
                    let typeCourse;
                    switch (course?.courseType) {
                      case 'instruction':
                        typeCourse = t('pages.learn_index.new_instruction');
                        break;
                      case 'leervacature':
                        typeCourse = t('pages.learn_index.new_vacancy');
                        break;
                      case 'assessment':
                        typeCourse = t('pages.learn_index.new_assessment');
                        break;
                      default:
                        typeCourse = t('pages.learn_index.new_courses');
                        break;
                    }
                    return typeCourse;
                  })}
                </p>
                <div className="mt-5 sm:w-full ">
                  {data?.data?.map((course: any) => {
                    let typeCourse;
                    switch (course?.courseType) {
                      case 'instruction':
                        typeCourse = 'instructions';
                        break;
                      case 'leervacature':
                        typeCourse = 'vacancies';
                        break;
                      case 'assessment':
                        typeCourse = 'assessments';
                        break;
                      default:
                        typeCourse = 'courses';
                        break;
                    }
                    return (
                      <ContentCardListItem
                        key={course.id}
                        title={course?.courseName || course?.name || ''}
                        imageUrl={course?.courseImage || course?.image || '/assets/images/opleidingen.jpg'}
                        to={`${coursePath}/${typeCourse}/${course?.courseId}`}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-full">
          <ListOfCourses
            userId={userId}
            path={'/mycourses'}
            props={newCourses}
            lable={t('pages.learn_index.label')}
            title={t('pages.learn_index.courses_to_you')}
            setDashboardCourses={setDashboardCourses}
          />
          <ListOfCourses
            props={oldCourses}
            progress={true}
            userId={userId}
            title={t('pages.learn_index.back_courses')}
            setDashboardCourses={setDashboardCourses}
          />
        </div>
      </div>

      <div
        className={`w-full ${
          dashboardCourses && !dashboardCourses?.length ? 'block' : 'hidden'
        }`}
      >
        <EmptyTable template={2} text={t('empty_tenplate.course_list')} />
      </div>
    </div>
  );
}

export default LearnIndexPage;

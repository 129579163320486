import React, { Component, Fragment, useContext } from 'react';
// import PropTypes from 'prop-types';
import { newUid, getSafe, deep_copy, last, orderWidgets } from '@/util';
import { config } from '../../config';
import { withTranslation } from 'react-i18next';
import { CameraModal } from '@/components/CameraModal/CamereModal';
import { VideoCameraModal } from '@/components/CameraModal/VideoCameraModal';
import { connect } from 'react-redux';
//import {alertActions} from '../../actions/alert.actions';
//import {vimeoActions} from '../../actions/vimeo.actions';
import { axios } from '../../axios';
import i18n from 'i18next';
import { initFroala } from './froala';
import { decode, encode } from '@/base64';
import { renderWidgets, renderWidget } from './widgets/renderWidget';
import {
  renderEditors,
  renderEditor,
  renderChatGpt,
  rerenderEditors,
} from './widgets/renderEditor';

import { uploadVideo } from '@/query/vimeo';
import { AuthContext } from '@/components/Contexts/AuthContext';
import update from 'immutability-helper';
import { LibraryModal } from '../LibraryModal/LibraryModal';

import { addCustomVideoLayer } from '@/components/Froala/insertVideoByUrl';

const MAX_SAVE_DELAY = 5000;

//we het the latest state of all widgets in the copy pasted html
//and update localStorage.setItem('fr-copied-html', updated);
//in the paste function we get our content from the same localStorage
async function handleCopy(_this, editor, ev) {
  let clipboard_html = localStorage.getItem('fr-copied-html');
  if (clipboard_html) {
    try {
      const src = window.$.parseHTML(`<div>${clipboard_html}</div>`)[0];
      const placeholders = window.$.find('.widget-placeholder', src);
      let widgets = [];

      if (!placeholders) return true;
      for (const placeholder of placeholders) {
        let stateTag = placeholder.querySelectorAll(
          'div[data-state],span[data-state]'
        );
        let stateStr = stateTag[0].getAttribute('data-state');
        let state = JSON.parse(decode(stateStr));
        if (_this.state.editors[state.uid]) {
          let widget = _this.state.editors[state.uid];
          state = await widget.stateFn();
        }

        let newStateStr = encode(JSON.stringify(state));
        //update the DOM just in case you want to copy again
        const node = document.querySelector(
          `#${CSS.escape(state.uid)} div[data-state],span[data-state]`
        );
        if (node) {
          node.setAttribute('data-state', newStateStr);
        }

        state.uid = newUid(20);
        placeholder.id = state.uid;
        placeholder.innerHTML = '';

        if (state.inline) {
          let child = document.createElement('span');
          child.setAttribute('data-open', 'SOW');
          0;
          child.setAttribute('data-state', newStateStr);
          child.innerHTML = '<span data-closed="EOW"></span>';
          placeholder.append(child);
        } else {
          let child = document.createElement('div');
          child.setAttribute('data-open', 'SOW');
          child.setAttribute('data-state', newStateStr);
          child.innerHTML = '<div data-closed="EOW"></div>';
          placeholder.append(child);
        }

        widgets.push({ placeholder, state });
      }
      localStorage.setItem('fr-copied-html', src.outerHTML);
    } catch (e) {
      console.error('pasting error', e);
    }
  }
}

async function sync() {
  return new Promise((resolve, reject) => {
    let promises = [];
    promises.push(
      new Promise((resolve) => {
        if (this.uploadPending === 0) {
          this.onUpdate();
          resolve();
        } else {
          let waitForUpload = setInterval(() => {
            if (this.uploadPending === 0) {
              this.onUpdate();
              resolve();
            }
          }, 200);
          setTimeout(() => {
            clearInterval(waitForUpload);
            console.error('Upload stil pending, giving up');
            window.$('.fr-uploading').remove();
            resolve();
          }, 20000);
        }
      })
    );

    for (let widget of Object.values(this.widgets)) {
      if (widget.refs[0].current && widget.refs[0].current.sync)
        promises.push(widget.refs[0].current.sync());
    }

    Promise.all(promises)
      .then(() => {
        let clone = window
          .$(this.myRef.current)
          .parent()
          .find('.fr-view')
          .clone();
        clone.find('.remove_me_gEE8QIPZ6LEay22SdSCU').remove();
        clone.find('div[data-open="SOW"]').empty();
        clone
          .find('div[data-open="SOW"]')
          .append('<div data-closed="EOW"></div>');
        let content = clone.html();
        if (this.state.editor) return resolve(content);
        return resolve();
      })
      .catch((e) => {
        return reject(e);
      });
  });
}

const quickInsertClick = () => {
  const quickInsert = document.getElementsByClassName('fr-quick-insert');
  const windowHeight = document.body.offsetHeight;
  if (quickInsert[0]) {
    const positionBtn = quickInsert[0].offsetTop;
    if (positionBtn + 700 > windowHeight) {
      quickInsert[0].classList.add('fr-quick-insert__bottom');
    } else {
      quickInsert[0].classList.remove('fr-quick-insert__bottom');
    }
  }
};

class FroalaTextarea extends Component {
  views = {};
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    initFroala(); //start init asap cause componentDidMount will be waiting for it
    this.myRef = React.createRef();
    this.state = {
      editor: null,
      showWebcamPicture: null,
      showWebcamVideo: null,
      showPictureLibrary: null,
      editors: {},
      chatGpt: {},
      views: {},
      contentReady: false,
      uid: newUid(20),
    };
    this.unmount = {};
    this.widgets = {};
  }

  async getContent() {
    const content = window
      .$(`#${CSS.escape(this.state.uid)}`)
      .find('.fr-view')
      .html();
    //const content = window.$(this.myRef.current).parent().find('.fr-view').html()//await this.state.editor.html.get();
    return content;
    //return window.$(this.myRef.current).parent().find('.fr-view').html();
  }

  async componentWillUnmount() {
    /*
    if (this.props.onSave && !this.props.blockSaveOnUnmount)
      await this.props.onSave();
    //Whenever we enable this, we get issues, move with extreme caution
    //for (let node of Object.values(this.unmount)) {
    //  ReactDOM.unmountComponentAtNode(node);
    //}
    */
    if (this.state.editor) this.state.editor.destroy();
  }

  async componentDidMount() {
    const useTeoAi = this.context?.auth?.user?.useTeoAi;
    this.contentChanged = 0;
    this.props.setRef && this.props.setRef(this);
    await initFroala();

    let t = this.props.t;
    this.uploadPending = 0;

    let toolbarSticky = false;
    if (this.props.toolbarSticky) toolbarSticky = true;
    let toolbarButtons;
    let toolbarButtonsSM;
    let toolbarBtn;
    let fontSize = ['14', '18', '24'];

    switch (this.props.modus) {
      case 'text-only':
        toolbarBtn = {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'textColor',
              'backgroundColor',
              'clearFormatting',
            ],
            buttonsVisible: 3,
          },
          moreParagraph: {
            buttons: ['formatOL', 'formatUL'],
            buttonsVisible: 2,
          },
          moreRich: {
            buttons: [
              'insertLink',
              'emoticons',
              'specialCharacters',
              'embedly',
              'insertHR',
              //'MathDropdown',
            ],
            buttonsVisible: 4,
          },
          moreMisc: {
            buttons: [
              'undo',
              'redo',
              'fullscreen',
              'selectAll',
              'html',
              'help',
            ], //'spellChecker',
            align: 'right',
            buttonsVisible: 3,
          },
        };
        toolbarButtons = toolbarBtn;
        toolbarButtonsSM = toolbarBtn;
        break;
      case 'advanced':
        toolbarBtn = {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'clearFormatting',
            ],
            buttonsVisible: 3,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
            buttonsVisible: 3,
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'insertVideo',
              'insertTable',
              'emoticons',
              'specialCharacters',
              'embedly',
              'insertFile',
              'insertHR',
              'MathDropdown',
            ],
            buttonsVisible: 3,
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'selectAll', 'html', 'help'], //'spellChecker',
            align: 'right',
            buttonsVisible: 2,
          },
        };
        toolbarButtons = toolbarBtn;
        toolbarButtonsSM = toolbarBtn;
        break;
      case 'InvulVraag':
        toolbarBtn = {
          placeholder: {
            buttons: ['placeholder'],
            buttonsVisible: 1,
          },
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'clearFormatting',
            ],
            buttonsVisible: 3,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
            buttonsVisible: 3,
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'insertVideo',
              'insertTable',
              'emoticons',
              'specialCharacters',
              'embedly',
              'insertFile',
              'insertHR',
              'MathDropdown',
            ],
            buttonsVisible: 3,
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'selectAll', 'html', 'help'], //'spellChecker',
            align: 'right',
            buttonsVisible: 3,
          },
        };

        toolbarButtons = toolbarBtn;
        toolbarButtonsSM = toolbarBtn;
        break;
      case 'formEdit':
      case 'lessonEdit':
        toolbarButtons = {
          moreSave: {
            buttons: ['save', 'fullscreen'],
          },
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontSize',
              'textColor',
              'backgroundColor',
              'clearFormatting',
            ], //, 'fontFamily'
            buttonsVisible: 3,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatULSimple',
              // 'formatUL',
              // 'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ], //'
            buttonsVisible: 3,
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'insertVideo',
              '3D',
              'MathDropdown',
              'Phet',
              'ImageShowParts',
              'insertTable',
              'emoticons',
              'specialCharacters',
              'embedly',
              'insertFile',
              'iframelyButton',
              'insertHR',
            ],
            buttonsVisible: 7,
          },
          moreMisc: {
            buttons: [
              'undo',
              'redo',
              /*'print', 'getPDF',*/ 'selectAll',
              'html',
              'help',
            ], //'spellChecker',
            align: 'right',
            buttonsVisible: 2,
          },
        };
        toolbarButtonsSM = {
          moreSave: {
            buttons: ['save', 'fullscreen'],
          },
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontSize',
              'textColor',
              'backgroundColor',
              'clearFormatting',
            ], //, 'fontFamily'
            buttonsVisible: 0,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatULSimple',
              // 'formatUL',
              // 'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ], //'
            buttonsVisible: 0,
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'insertVideo',
              '3D',
              'MathDropdown',
              'Phet',
              'ImageShowParts',
              'insertTable',
              'emoticons',
              'specialCharacters',
              'embedly',
              'insertFile',
              'insertHR',
            ],
            buttonsVisible: 0,
          },
          moreMisc: {
            buttons: [
              'undo',
              'redo',
              /*'print', 'getPDF',*/ 'selectAll',
              'html',
              'help',
            ], //'spellChecker',
            align: 'right',
            buttonsVisible: 2,
          },
        };
        fontSize = [
          '8',
          '9',
          '10',
          '11',
          '12',
          '14',
          '16',
          '18',
          '24',
          '30',
          '36',
          '48',
          '60',
          '72',
          '96',
        ];
        if (this.props.modus === 'lessonEdit') {
          toolbarButtons['moreQuiz'] = {
            buttons: [
              'MeerKeuze',
              'OpenVraag',
              'OrderVraag',
              'Link',
              'MatrixVraag2',
              'NameImage2',
              'InvulVraag',
              'FileUpload',
              'KofferVraag2',
              'BoxError',
              //'KofferVraag'
            ],
            buttonsVisible: 100,
          };
          toolbarButtons['moreOther'] = {
            buttons: ['pagebreak'],
            buttonsVisible: 100,
          };
        }
        if (this.props.modus === 'lessonEdit') {
          toolbarButtonsSM['moreQuiz'] = {
            buttons: [
              'MeerKeuze',
              'OpenVraag',
              'OrderVraag',
              'Link',
              'MatrixVraag2',
              'NameImage2',
              'InvulVraag',
              'FileUpload',
              'KofferVraag2',
              //'KofferVraag'
            ],
            buttonsVisible: 0,
          };
          toolbarButtonsSM['moreOther'] = {
            buttons: ['pagebreak'],
            buttonsVisible: 100,
          };
        }

        break;
      default:
        toolbarButtons = [
          'bold',
          'italic',
          'underline',
          'fontSize',
          'color',
          'formatOL',
          'formatUL',
          'clearFormatting',
          'insertLink',
          'insertImage',
          'insertVideo',
          'insertFile',
          //'MathDropdown',
          'undo',
          'redo',
        ]; //, 'getPDF'
        break;
    }

    //toolbarButtons['moreQuiz']['buttons'].splice(-1, 0, 'KofferVraag');
    //toolbarButtons['moreQuiz']['buttons'].splice(-1, 0, 'KofferVraag2');

    try {
      const user = this.context?.auth?.user;
      if (user && user.role >= 90 && toolbarButtons['moreQuiz']) {
        toolbarButtons['moreQuiz']['buttons'].splice(-1, 0, 'KofferVraag');
      }
    } catch (e) {
      console.error(e);
    }

    let policy = await config.policy;

    if (!policy) {
      //this.props.dispatch(alertActions.error("S3_ERROR"));
    }

    let i = 0;

    // eslint-disable-next-line
    const _this = this;

    let editor = new window.FroalaEditor(this.myRef.current, {
      //theme: 'dark',
      quickInsertEnabled:
        !this.props.modus || this.props.modus === 'InvulVraag' ? false : true,
      quickInsertButtons: useTeoAi
        ? ['ChatGPT', 'imageLibraryHelp', 'image', 'video']
        : ['imageLibraryHelp', 'image', 'video'],
      quickInsertTags: [
        'p',
        'span',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'pre',
        'blockquote',
      ],
      zIndex: -1,
      attribution: false,
      indentWithTabs: true,
      tabSpaces: 4,
      imageUploadToS3: policy,
      fileUploadToS3: policy,
      videoUploadToS3: policy,
      //enter: window.FroalaEditor.ENTER_BR,
      //iconsTemplate: 'font_awesome_5',
      // toolbarContainer: '#toolbarContainer',
      toolbarButtons: toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtonsSM: toolbarButtonsSM,
      toolbarButtonsXS: toolbarButtonsSM,
      imageInsertButtons: [
        'imageLibrary',
        'imageBack',
        '|',
        'imageUpload',
        'imageByURL',
        // 'imageManager',
        'imageByCamera',
      ],
      videoInsertButtons: [
        'videoBack',
        '|',
        'videoByURL',
        'videoEmbed',
        'videoUpload',
        'videoByCamera',
      ],
      //key: 'QFF4nB16A11A7A6D5C5E3fLUQZa1ASFe1EFRNc1He1BCCQDUHnD5D4B3J4C3A6D5C2C4F4==',
      key: 'VCC6kE4G3G4A2C8C7A5cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5F4F3D3G3F2B6C3==',
      //key: 'WE1B5dH4E3B2C9C10B5C5C-11VKOJ1FGULVKHXDXNDXc1d1Kg1SNdD5B4A4D3H3A2A2B7A4C3==',
      pluginsEnabled: Object.keys(window.FroalaEditor.PLUGINS).filter(
        (plugin) => plugin !== 'draggable'
      ),

      toolbarSticky: toolbarSticky,
      fileMaxSize: 1024 * 1024 * 50,
      imageMaxSize: 1024 * 1024 * 50,
      toolbarStickyOffset: 0,
      htmlAllowedAttrs: ['.*'],
      htmlAllowedTags: ['.*'],
      htmlRemoveTags: ['script'],
      lineBreakerTags: [''],
      lineBreakerOffset: 0,
      linkAlwaysBlank: false,
      linkText: true,
      videoAllowedTypes: ['mp4', 'webm', 'ogg', 'webm;codecs=vp9'],
      imageAllowedTypes: [
        'jpeg',
        'jpg',
        'png',
        'gif',
        'webp',
        'svg',
        'svg+xml',
      ],
      htmlUntouched: true,
      videoDefaultAlign: 'center',
      imageDefaultAlign: 'center',
      pastePlain: false,
      //wordPasteModal: false,
      pasteAllowedStyleProps: [],
      pasteDeniedAttrs: ['style'],
      //pasteDeniedAttrs: [],
      tableStyles: {
        'fr-no-border': t('No borders'),
        'fr-dashed-borders': t('Dashed Borders'),
        'fr-alternate-rows': t('Alternate Rows'),
      },
      //placeholderText: t('Typ iets'),
      placeholderText: '',
      //height: getSafe(() => this.props.style.height, undefined),
      width: getSafe(() => this.props.style.width, undefined),
      heightMin: getSafe(() => this.props.style.minHeight, 120),
      widthMin: getSafe(() => this.props.style.minWidth, undefined),
      heightMax: getSafe(() => this.props.style.height, undefined),
      widthMax: getSafe(() => this.props.style.maxWidth, undefined),
      charCounterCount: false,
      //imageEditButtons: ['imageDisplay', 'imageAlign', 'imageInfo', 'imageRemove'],
      htmlAllowedEmptyTags: [
        'textarea',
        'a',
        'iframe',
        'object',
        'video',
        // 'style',
        'script',
        '.fa',
        '.fr-emoticon',
        '.fr-inner',
        'path',
        'line',
        'hr',
        'i',
        'div',
        'span',
        'input',
      ],
      //enter: window.FroalaEditor.ENTER_BR,
      fontSize: fontSize,
      language: i18n.language,
      events: {
        'image.beforeUpload': () => {
          _this.uploadPending += 1;
        },
        'image.uploadedToS3': () => {
          _this.uploadPending -= 1;
        },
        'image.error': (error, response) => {
          console.error(error);
          _this.uploadPending -= 1;
          switch (error.code) {
            case 4:
              //this.props.dispatch(alertActions.fail('NETWORK_ERROR', 5000));
              window.$('.fr-uploading').remove();
              error.message = t('NETWORK_ERROR');
              break;
            case 5:
              var popup = window.$('.fr-error.fr-active .fr-message');
              popup[0].innerText = t('error.large_file');
              window.$('.fr-uploading').remove();
              break;
          }
        },
        'file.error': (error, response) => {
          _this.uploadPending -= 1;
          switch (error.code) {
            case 5:
              var popup = window.$('.fr-error.fr-active .fr-message');
              popup[0].innerText = t('error.large_file');
              window.$('.fr-uploading').remove();
              break;
          }
        },
        contentChanged: async function (ev) {
          _this.contentChanged = ++i;
          _this.onUpdate();
          _this.props.onModified && _this.props.onModified();
          _this.props.onChange &&
            _this.props.onChange({
              target: { value: await _this.getContent() },
            });
        },
        'html.set': function () {
          window.$('.remove_me_gEE8QIPZ6LEay22SdSCU').remove();
          _this.findWidgets();
        },
        'commands.before': async function (cmd, _param1, _param2) {
          switch (cmd) {
            case 'redo':
            case 'undo':
              window.undoCanDo = this.undo.canDo();
              window.undoCanRedo = this.undo.canRedo();
              break;
            default:
              break;
          }
        },
        'window.copy': function (ev) {
          handleCopy(_this, this, ev);
        },
        'window.cut': function (ev) {
          handleCopy(_this, this, ev);
        },
        'commands.after': async function (cmd, _param1, _param2) {
          switch (cmd) {
            case 'fullscreen':
              try {
                window.dispatchEvent(new Event('resize'));
              } catch (e) {
                console.error(e);
              } //some browsers don't support this
              break;
            case 'redo':
            case 'undo':
              if (window.undoCanDo) {
                const widgets = [];
                const chats = [];
                for (const widget of Object.values(_this.state.editors)) {
                  widgets.push(await widget.stateFn());
                }
                for (const chat of Object.values(_this.state.chatGpt)) {
                  chats.push(chat.state);
                }

                const newWidgets = Object.values(
                  rerenderEditors.call(null, this.el, this.undo, widgets, chats)
                ).filter((x) => x);

                if (widgets.length > 0) {
                  _this.setState({
                    contentReady: true,
                    editors: newWidgets[0].reduce((acc, el) => {
                      acc[el.state.uid] = el;
                      return acc;
                    }, {}),
                  });
                }
                setTimeout(() => {
                  _this.setState({
                    contentReady: true,
                    chatGpt: newWidgets[1].reduce((acc, el) => {
                      acc[el.state.uid] = el;
                      return acc;
                    }, {}),
                  });
                }, 10);
              }
              break;
            default:
              break;
          }
        },
        'paste.before': function (ev) {
          this.undo.saveStep();
          let clipboard_text = ev.clipboardData.getData('Text');
          if (clipboard_text) {
            if (clipboard_text.startsWith('widget:')) {
              const stateStr = clipboard_text.split(':')[1];
              const state = JSON.parse(decode(stateStr));
              state.uid = newUid(20);
              editor.insertWidget(null, state);
              _this.props.onModified();
              setTimeout(() => {
                orderWidgets();
              });
              return false;
            }
          }

          let clipboard_html = ev.clipboardData.getData('text/html');

          if (clipboard_html) {
            try {
              let src = window.$.parseHTML(`<div>${clipboard_html}</div>`)[0];
              let placeholders = window.$.find('.widget-placeholder', src);
              if (!placeholders || placeholders.length === 0) return true;

              //use the updated version that we put on the localStorage in handleCopy
              clipboard_html = localStorage.getItem('fr-copied-html');
              src = window.$.parseHTML(`<div>${clipboard_html}</div>`)[0];
              placeholders = window.$.find('.widget-placeholder', src);
              let widgets = [];

              for (const placeholder of placeholders) {
                let stateTag = placeholder.querySelectorAll(
                  'div[data-state],span[data-state]'
                );
                let stateStr = stateTag[0].getAttribute('data-state');
                let state = JSON.parse(decode(stateStr));
                state.uid = newUid(20);
                widgets.push();
                placeholder.id = state.uid;
                placeholder.innerHTML = '';
                if (state.inline) {
                  let child = document.createElement('span');
                  child.setAttribute('data-open', 'SOW');
                  child.setAttribute(
                    'data-state',
                    encode(JSON.stringify(stateStr))
                  );
                  child.innerHTML = '<span data-closed="EOW"></span>';
                  placeholder.append(child);
                } else {
                  let child = document.createElement('div');
                  child.setAttribute('data-open', 'SOW');
                  child.setAttribute(
                    'data-state',
                    encode(JSON.stringify(stateStr))
                  );
                  child.innerHTML = '<div data-closed="EOW"></div>';
                  placeholder.append(child);
                }
                widgets.push({ placeholder, state });
              }
              this.html.insert(src.outerHTML);
              setTimeout(() => {
                let editors = _this.state.editors;
                for (let widget of widgets) {
                  _this.setState({ editors }, () => {
                    _this.props.onModified();
                  });
                  editors = update(editors, {
                    [widget.state.uid]: {
                      $set: renderEditor(
                        widget.state,
                        widget.placeholder,
                        _this.state.editor.undo
                      ),
                    },
                  });
                }
                _this.setState({ editors }, () => {
                  _this.props.onModified();
                });
              }, 0);
              return false;
            } catch (e) {
              console.error('pasting error', e);
            }
          }
          return true;
        },
        'popups.show.forms.edit': function () {
          this.popups.hideAll();
          return false;
        },
        'popups.show.video.insert': function () {
          const editor = this;
          const popup = editor.popups.get('video.insert');

          if (
            popup &&
            popup[0] &&
            !popup[0].querySelector('.fr-custom-layer')
          ) {
            addCustomVideoLayer(editor);
          }
        },
        'paste.beforeCleanup': function (clipboard_html) {
          if (clipboard_html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(clipboard_html, 'text/html');
            const firstChild = doc.body.firstElementChild;
            const hasPTags = doc.querySelectorAll('p').length > 0;
            const spansTags = doc.querySelectorAll('span');
            if (
              firstChild &&
              firstChild.tagName.toLowerCase() === 'span' &&
              !hasPTags
            ) {
              let newValue;
              spansTags.forEach((child) => {
                const newSpan = newValue;
                const sourceStyles = child.getAttribute('style');
                const spanElement = document.createElement('span');
                spanElement.setAttribute('style', sourceStyles);
                spanElement.innerHTML = child.innerHTML;
                newValue = newSpan;
              });
              const htmlString = newValue.outerHTML;
              return htmlString;
            }
          }
        },
        'paste.afterCleanup': function (clipboard_html) {
          if (clipboard_html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(clipboard_html, 'text/html');
            const tables = doc.querySelectorAll('table');
            if (tables[0]?.querySelectorAll('td, th')?.length === 1) {
              return tables[0]?.querySelectorAll('td, th')[0].innerHTML;
            } else {
              return clipboard_html;
            }
          }
        },

        /*
        'paste.afterCleanup': function (clipboard_html) {
          if (clipboard_html.search('widget-placeholder')) {
            setTimeout(() => this.findWidgets(), 0);
            return `<p><br></p>${clipboard_html}<p><br></p>`;
          }
        },
        */
        keyup: async function (ev) {
          _this.contentChanged = ++i;
          var ctrlKey =
            navigator.userAgent.indexOf('Mac OS X') !== -1
              ? ev.metaKey
                ? ev.metaKey
                : ev.ctrlKey
              : ev.ctrlKey;
          if (ctrlKey) {
            switch (ev.key) {
              case 's':
                if (_this.props.onSave) await _this.props.onSave();
                break;
              case 'a':
                ev.preventDefault();
                var currentElement = editor.selection.element();
                var frElementParent = currentElement.closest('.fr-element');
                var selection = window.getSelection();
                var range = document.createRange();
                range.selectNodeContents(frElementParent);
                selection.removeAllRanges();
                selection.addRange(range);
                break;
            }
          }
          if (ev.key == 'Enter' || ev.key == 'ArrowDown') {
            quickInsertClick();
          }
          return true;
        },
        click: () => {
          quickInsertClick();
        },
        'video.error': function (error, response) {
          console.error('video error', error, response);
        },
        'video.uploadedToS3': function (url) {
          this.html.insert(
            `<p><br></p><p><span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable fr-active"><video src="${url}" style="width: 600px;" controls="" class="fr-draggable">Je browser ondersteunt geen html5-video.</video></span></p><p><br></p>`,
            true
          );
          return false;
        },
        'video.beforeUpload': function (videos) {
          if (_this.props.modus !== 'lessonEdit') return true;
          setTimeout(
            ((videos) => {
              const popup = editor.popups.get('video.insert');
              let autoPlay = false;
              let autoLoop = false;
              if (popup && popup[0]) {
                const videoUploadLayer = popup[0].querySelector(
                  '.fr-video-upload-layer'
                );
                if (videoUploadLayer) {
                  autoPlay = videoUploadLayer.querySelector(
                    '#fr-videoUploadAutoplay'
                  ).checked;
                  videoUploadLayer.querySelector(
                    '#fr-videoUploadAutoplay'
                  ).checked = false;

                  autoLoop = videoUploadLayer.querySelector(
                    '#fr-videoUploadAutoLoop'
                  ).checked;
                  videoUploadLayer.querySelector(
                    '#fr-videoUploadAutoLoop'
                  ).checked = false;
                }
              }

              let uid = newUid(20);
              // let uri = "";
              let onProgress = (_progress, percentage, status) => {
                window.$('#' + CSS.escape(uid)).width(percentage + '%');
                if (status) {
                  window.$('#' + CSS.escape(uid)).text(status);
                } else if (percentage < 100) {
                  window
                    .$('#' + CSS.escape(uid))
                    .text(
                      percentage +
                        t(
                          "% (you can continue to edit while uploading, but don't leave the lesson"
                        )
                    );
                }
              };
              let onDone = () => {
                _this.uploadPending -= 1;
                window
                  .$('#' + CSS.escape(uid))
                  .text(
                    t(
                      'Upload complete, encoding. Video will be available in 2-60 min, but you can leave the lesson'
                    )
                  );
                function checkIfVideoExists(url, callback) {
                  let id = last(url.split('/'));
                  axios
                    .get(config.backend + '/vimeo/status/' + id)
                    .then((response) => {
                      if (
                        response.data.upload.status === 'complete' &&
                        response.data.transcode.status === 'complete'
                      ) {
                        window.$('.remove_me_gEE8QIPZ6LEay22SdSCU').remove();
                        callback(response.data);
                      }
                    });
                }
                let tryLoad = () => {
                  let iframe = document.getElementById(uid + '_placeholder');
                  try {
                    checkIfVideoExists(iframe.src, function () {
                      let iframe = document.getElementById(
                        uid + '_placeholder'
                      );
                      clearInterval(tryLoadInterval);
                      setTimeout(() => {
                        window
                          .$('#' + CSS.escape(uid))
                          .parent()
                          .remove();
                        iframe.src = iframe.src; // eslint-disable-line
                      }, 30000);
                    });
                  } catch (e) {
                    console.error(e);
                  }
                };
                let tryLoadInterval = setInterval(() => {
                  tryLoad();
                }, 10000);
                window.$('#' + CSS.escape(uid)).click(() => {
                  tryLoad();
                });
              };
              _this.uploadPending += 1;
              this.html.insert(
                `<p><br /></p><p><div contenteditable="false" class="fr-deletable remove_me_gEE8QIPZ6LEay22SdSCU mx-auto" style="cursor: pointer; text-align: center; font-size: 12px; height: 20px; width: 640px; background-color: #BBBBBB";><div style="height:20px; background-color: #fccc4a; white-space: nowrap; overflow: visible;" id="${uid}"></div></div>
              <span id="${uid}_embed" class="fr-video fr-deletable fr-draggable" contenteditable="false" draggable="true" style="display: block; clear: both; text-align: center;"><iframe id="${uid}_placeholder" width="640" height="360" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe></span></p><p><br /></p>`,
                true
              );
              this.undo.saveStep();

              onProgress(1, 100, t('Preparing'));

              for (let file of videos) {
                let title;
                try {
                  title = file.name.split('.').slice(0, -1).join('.');
                } catch (e) {
                  title = document.title;
                }
                uploadVideo(file, onProgress, onDone, title)
                  .then((uri) => {
                    if (autoLoop) {
                      uri += '?&loop=1';
                      if (autoPlay) {
                        uri += '&autoplay=1&muted=1&#t=0s';
                      }
                    } else if (autoPlay) {
                      uri += '?&autoplay=1&muted=1&#t=0s';
                    }
                    document.getElementById(uid + '_placeholder').src = uri;
                  })
                  .catch((e) => console.error(e));
              }
            }).bind(this, [...videos]),
            0
          );

          this.popups.hideAll();
          return false;
        },
        blur: function () {
          _this.props.onBlur && _this.props.onBlur();
        },
      },
    });

    editor.save_cb = this.props.onSave;
    editor.placeholder_cb = this.props.onPlaceholder;
    editor.insertWidget = this.insertWidget;
    editor.insertChatGpt = this.insertChatGpt;
    editor.insertPhet = this.insertPhet;
    editor.insertInlineWidget = this.insertInlineWidget;
    editor.insertEmbedWidget = this.insertEmbedWidget;
    editor.moreExec = this.moreExec;
    editor.toggleMoreButton = this.toggleMoreButton;
    editor.insertCamera = () => this.setState({ showWebcamPicture: true });
    editor.insertLibrary = () => this.setState({ showPictureLibrary: true });
    editor.insertVideoCamera = () => this.setState({ showWebcamVideo: true });

    this.started = true;

    this.updateContent(editor, this.props.value, this.props.subeditor);
    this.setState({ editor: editor });
  }

  findWidgets = (parentUid) => {
    //renderWidgets.call(this.myRef.current);
    /*findWidgets.call(this, parentUid, this.widgets);*/
  };

  renderWidget(state, target, openEditor = false, i = 0) {
    //renderWidget.call(this, state, target, openEditor, i);
  }

  insertWidget = (_context, initialState, _cb) => {
    let uid = newUid(20);
    let _this = this;
    uid = this.props.parentuid ? this.props.parentuid + '_' + uid : uid;

    let newState = deep_copy(initialState);
    newState.uid = uid;

    let parent = this.state.editor.html.insert(
      '<p><br></p><div style="position: relative" contenteditable=false class="widget-placeholder fr-deletable" id="' +
        uid +
        '"></div><p><br></p>',
      false
    );

    this.setState(
      {
        editors: update(this.state.editors, {
          [uid]: {
            $set: renderEditor(newState, parent, this.state.editor.undo),
          },
        }),
      },
      () => {
        _this.props.onModified();
      }
    );

    //renderWidget(newState, document.getElementById(newState.uid), true);
    //this.onUpdate();
  };

  insertChatGpt = () => {
    let uid = newUid(20);
    uid = this.props.parentuid ? this.props.parentuid + '_' + uid : uid;

    let newState = {};
    newState.uid = uid;

    let parent = this.state.editor.html.insert(
      '<p><br></p><div style="position: relative" class="chat-gpt-placeholder fr-deletable" id="' +
        uid +
        '">Vad</div><p><br></p>',
      false
    );

    this.setState({
      chatGpt: update(this.state.chatGpt, {
        [uid]: {
          $set: renderChatGpt(newState, document.getElementById(newState.uid)),
        },
      }),
    });

    this.state.editor.undo.reset();
  };

  insertInlineWidget = (_context, initialState, _cb) => {
    let uid = newUid(20);
    let _this = this;
    uid = this.props.parentuid ? this.props.parentuid + '_' + uid : uid;

    let newState = deep_copy(initialState);
    newState.uid = uid;

    let parent = this.state.editor.html.insert(
      '<span style="position: relative" contenteditable=false class="widget-placeholder fr-deletable" id="' +
        uid +
        '"></span>',
      false
    );

    this.setState(
      {
        editors: update(this.state.editors, {
          [uid]: {
            $set: renderEditor(newState, parent, this.state.editor.undo),
          },
        }),
      },
      () => {
        _this.props.onModified();
      }
    );
  };

  insertPhet = (el) => {
    let uid = newUid(20);
    uid = this.props.parentuid ? this.props.parentuid + '_' + uid : uid;

    this.state.editor.html.insert(
      '<p><br></p><div style="position: relative" class="chat-gpt-placeholder fr-deletable" id="' +
        uid +
        '">' +
        el +
        '</div><p><br></p>',
      false
    );
  };

  moreExec(cmd) {
    var $btn = this.$tb.find('[data-cmd='.concat(cmd, ']'));
    this.toggleMoreButton($btn);
    this.toolbar.setMoreToolbarsHeight();
  }

  toggleMoreButton($btn) {
    var $buttonGroup = this.$tb.find(
      '.fr-more-toolbar[data-name="'.concat($btn.attr('data-group-name'), '"]')
    );
    this.$tb.find('.fr-open').not($btn).removeClass('fr-open');
    $btn.toggleClass('fr-open');

    this.$tb.find('.fr-more-toolbar').removeClass('fr-overflow-visible');

    if (this.$tb.find('.fr-expanded').not($buttonGroup).length) {
      this.$tb.find('.fr-expanded').toggleClass('fr-expanded');

      $buttonGroup.toggleClass('fr-expanded');
    } else {
      $buttonGroup.toggleClass('fr-expanded');
      this.$box.toggleClass('fr-toolbar-open');
      this.$tb.toggleClass('fr-toolbar-open');
    }
  }

  insertEmbedWidget = (_context, initialState, _cb) => {
    let editor = this.state.editor;
    let uid = newUid(20);
    uid = this.props.parentuid ? this.props.parentuid + '_' + uid : uid;
    //editor.html.insert('<span contenteditable="false" draggable="true" class="fr-video fr-deletable fr-draggable widget-placeholder" id="' + uid + '" style="display: block; clear: both; text-align: center;">HELLO WORLD</span>')
    editor.html.insert(
      '<span contenteditable="false" draggable="true" class="fr-video fr-deletable fr-draggable" id="' +
        uid +
        '" style="display: block; clear: both; text-align: center;"></span>'
    );

    editor.events.focus();
    editor.selection.setAtEnd(editor.$el.get(0));
    //editor.selection.restore();
    let newState = deep_copy(initialState);
    newState.uid = uid;
    setTimeout(
      () =>
        this.renderWidget(
          newState,
          document.getElementById(newState.uid),
          true
        ),
      0
    );
    this.onUpdate();
  };

  onUpdate = async (...params) => {
    //return onUpdate.call(this, ...params);
  };

  sync = async (...params) => {
    return sync.call(this, ...params);
  };

  updateContent = (editor, value, subeditor) => {
    if (!this.value_initialized && value !== undefined && this.myRef.current) {
      setTimeout(() => {
        if (editor) {
          this.value_initialized = true;
          window.$(editor.el).html(value);
          window.$('.remove_me_gEE8QIPZ6LEay22SdSCU').remove();
          var editor_node = editor.el;
          const imgElements = editor_node.querySelectorAll('img');
          const videoElements = editor_node.querySelectorAll('video');
          if (
            !editor_node.innerText.trim() &&
            !imgElements?.length > 0 &&
            !videoElements?.length > 0
          ) {
            editor.placeholder.show();
          } else {
            editor.placeholder.hide();
          }
          if (subeditor) {
            editor.el.parentNode.contentEditable = 'true';
            var node = editor.el;
            if (
              !node.innerHTML.trim() ||
              (node.childNodes.length === 1 &&
                node.firstChild.nodeName === 'P' &&
                !node.firstChild.getAttribute('contenteditable'))
            ) {
              const pElement = document.createElement('p');
              pElement.setAttribute('contenteditable', 'true');
              node.appendChild(pElement);
            } else {
              const imgElements = node.querySelectorAll('img');
              const pElements = node.querySelectorAll('p');

              pElements.forEach((text) => {
                const isTextAlreadyWrapped =
                  text.getAttribute('contenteditable') === 'true';

                if (isTextAlreadyWrapped) {
                  text.removeAttribute('contenteditable');
                }
              });

              imgElements.forEach((img) => {
                const isAlreadyWrapped =
                  img.parentElement.tagName === 'SPAN' &&
                  img.parentElement.getAttribute('contenteditable') === 'true';

                if (!isAlreadyWrapped) {
                  const span = document.createElement('span');
                  span.classList.add('your-custom-class');
                  span.setAttribute('contenteditable', 'true');
                  img.parentNode.replaceChild(span, img);
                  span.appendChild(img);
                }
              });
            }
          }

          const newWidgets = Object.values(
            renderEditors.call(null, editor.el, editor.undo)
          ).filter((x) => x);

          this.setState(
            {
              contentReady: true,
              editors: newWidgets.reduce((acc, el) => {
                acc[el.state.uid] = el;
                return acc;
              }, {}),
            },
            () => {
              if (this.props.onLoaded) this.props.onLoaded();
            }
          );
        }
        if (this.props.modus == 'lessonEdit') {
          editor.events.focus();
        }
      }, 0);
    }
    if (!this.value_initialized && value === undefined && this.myRef.current) {
      setTimeout(() => {
        if (editor) {
          if (subeditor) {
            editor.el.parentNode.contentEditable = 'true';
            var node = editor.el;
            if (
              !node.innerHTML.trim() ||
              (node.childNodes.length === 1 &&
                node.firstChild.nodeName === 'P' &&
                !node.firstChild.getAttribute('contenteditable'))
            ) {
              const pElement = document.createElement('p');
              pElement.setAttribute('contenteditable', 'true');
              node.appendChild(pElement);
            }
          }
        }
      }, 0);
    }
    window.$(this.myRef.current).find('.fancybox > img').unwrap();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.editors !== this.state.editors) {
      this.props.onEditors && this.props.onEditors(this.state.editors);
    }
    //this.updateContent(this.props);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return true;
    /*
    if (nextProps.value !== this.props.value) return true;
    if (nextState.showWebcamPicture != this.state.showWebcamPicture) return true;
    if (nextState.showWebcamVideo != this.state.showWebcamVideo) return true;
    return false;
    */
  };

  render() {
    let {
      mode,
      hideToolbar,
      value,
      toolbarSticky,
      t,
      tReady,
      i18n,
      dispatch,
      onSave,
      saveWidget,
      widgetsRendered,
      setsyncfunction,
      setKofferLes,
      isSubmitted,
      setRef,
      blockSaveOnUnmount,
      onModified,
      onBlur,
      classTextarea,
      ...other
    } = this.props;

    return (
      <Fragment>
        {this.state.contentReady && this.state.renderWidgets}
        {Object.values(this.state.editors).map((x) => x.portal)}
        {Object.values(this.state.views)}
        {Object.values(this.state.chatGpt).map((x) => x.portal)}
        {this.state.showWebcamPicture && (
          <CameraModal
            setIsOpenPhotoModal={(isOpen) =>
              this.setState({ showWebcamPicture: isOpen })
            }
            isOpenPhotoModal={this.state.showWebcamPicture}
            setUploadFile={(resultFn) => {
              let [blob] = resultFn([]);
              this.state.editor.image.upload([blob]);
              this.setState({ showWebcamPicture: false });
            }}
          ></CameraModal>
        )}
        {this.state.showPictureLibrary && (
          <LibraryModal
            setIsOpenLibraryModal={(isOpen) =>
              this.setState({ showPictureLibrary: isOpen })
            }
            isOpenLibraryModal={this.state.showPictureLibrary}
            lessonPage={true}
            setUploadFile={(resultFn) => {
              let [blob] = resultFn([]);
              this.state.editor.image.upload([blob]);
              this.setState({ showWebcamPicture: false });
            }}
          />
        )}
        {this.state.showWebcamVideo && (
          <VideoCameraModal
            setIsOpenVideoModal={(isOpen) => {
              this.setState({ showWebcamVideo: isOpen });
            }}
            isOpenVideoModal={this.state.showWebcamVideo}
            setUploadFile={(resultFn) => {
              let result = resultFn([]);
              let file = new File(
                result.map((x) => x.blob),
                `camera.${result[0].mimeType}`,
                {
                  type: 'video/webm;codecs=vp9',
                }
              );
              this.state.editor.video.upload([file]);
              this.setState({ showWebcamVideo: false });
            }}
          ></VideoCameraModal>
        )}
        <div
          id={this.state.uid}
          className={`${
            this.props.modus === 'lessonEdit' && 'fr-editor-block'
          } relative z-0 rounded-lg border border-grey-02 ${
            this.props.className || ''
          }`}
        >
          <textarea
            className={classTextarea}
            {...other}
            ref={this.myRef}
          ></textarea>
        </div>
      </Fragment>
    );
  }
}

const connectFroalaTextarea = withTranslation()(FroalaTextarea);
export default connectFroalaTextarea;

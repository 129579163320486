import { useTranslation } from 'react-i18next';
import { Button, Modal, Tabs } from '@teo/components';
import { useEffect, useState, useContext } from 'react';
import CoursestModal from './CoursestModal';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';

function AddContentModal({
  isOpenAddContentModal,
  closeModal,
  setIsOpenAddContent,
  setActiveCourse,
  activeCourse,
  setCourseType,
}: any) {
  const { t } = useTranslation();
  const [tab, setTab] = useState('courses');

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  useEffect(() => {
    setCourseType(tab);
  }, [tab]);

  const courseType = authUser?.useCourse;
  const assessmentType = authUser?.useAssessment;
  const vacancyType = authUser?.useLeervacature;
  const instructionType = authUser?.useInstruction;

  return (
    <div className="bg-white">
      <Modal isOpen={isOpenAddContentModal}>
        <Modal.Header onClose={closeModal}>
          <h2
            className="text-sm font-semibold"
            data-testid="addCategoriesHeader"
          >
            {t('pages.follow_up_teams.select_content')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col">
            <div className="mb-5 mt-3 flex flex-col sm:flex-row">
              <div>
                <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
                  <>
                    {courseType && (
                      <Tabs.TabButton name="courses">
                        <p className="text-base font-medium">
                          {t('pages.follow_up_add_modal.courses')}
                        </p>
                      </Tabs.TabButton>
                    )}
                    {assessmentType && (
                      <Tabs.TabButton name="assessments">
                        <p className="text-base font-medium">
                          {t('pages.follow_up_add_modal.assessments')}
                        </p>
                      </Tabs.TabButton>
                    )}
                    {vacancyType && (
                      <Tabs.TabButton name="vacancies">
                        <p className="text-base font-medium">
                          {t('pages.follow_up_add_modal.vacancies')}
                        </p>
                      </Tabs.TabButton>
                    )}
                    {instructionType && (
                      <Tabs.TabButton name="instructions">
                        <p className="text-base font-medium">
                          {t('pages.follow_up_add_modal.instructions')}
                        </p>
                      </Tabs.TabButton>
                    )}
                  </>
                </Tabs>
              </div>
            </div>

            {tab === 'courses' && (
              <div>
                <CoursestModal
                  courseType="course"
                  selected={activeCourse}
                  setSelected={setActiveCourse}
                />
              </div>
            )}
            {tab === 'assessments' && (
              <div>
                <CoursestModal
                  courseType="assessment"
                  selected={activeCourse}
                  setSelected={setActiveCourse}
                />
              </div>
            )}
            {tab === 'vacancies' && (
              <div>
                <CoursestModal
                  courseType="leervacature"
                  selected={activeCourse}
                  setSelected={setActiveCourse}
                />
              </div>
            )}
            {tab === 'instructions' && (
              <div>
                <CoursestModal
                  courseType="instruction"
                  selected={activeCourse}
                  setSelected={setActiveCourse}
                />
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('button.cancel')}
          onCancel={closeModal}
        >
          <div data-testid="addCategoriesFooter">
            <Button
              type="button"
              onClick={() => {
                setActiveCourse(activeCourse);
                setIsOpenAddContent(true);
                closeModal();
              }}
              disabled={!activeCourse ? true : false}
            >
              {t('button.next')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddContentModal;

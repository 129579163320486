import { useTranslation } from 'react-i18next';
import { T } from '@/components/T';
import { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import {
  Stepper,
  IconButton,
  Switch,
  Button,
  InputDate,
  Slider,
  RadioCard,
  InputTextarea,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useMobile } from '@/util';
import { Userwithorganisationwithidps } from '@/models';
import { Coursedetails } from '@/models/CourseDetails.interface';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';

const MAXSTEP = 2;

function CourseSettings() {
  const { t } = useTranslation();
  const { setNavDisabled } = useContext(NavContext);
  const [step, setStep] = useState(1);
  const { courseId } = useParams();
  const isMobile = useMobile();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const navigate = useNavigate();
  const [course, setCourse] = useState<Coursedetails | null>(null);
  const [courseinfo, setCourseinfo] = useState<Coursedetails | null>(null);

  const [isCustomLandingPage, setCustomLandingPage] = useState(false);
  const [isMessageSuccess, setIsMessageSuccess] = useState('');
  const [isMessageFail, setIsMessageFail] = useState('');
  const [startDate, setStartDate] = useState<any | null>(null);
  const [endDate, setEndDate] = useState<any | null>(null);
  const [isFollowInOder, setIsFollowInOder] = useState(false);
  const [isAutoCorrect, setIsAutoCorrect] = useState(false);
  const [isMandatory, setIsMandatory] = useState<any | null>(false);
  const [isCertificate, setIsCertificate] = useState(false);
  const [minimalScore, setMinimalScore] = useState<any | null>(0);
  const [isPublic, setIsPublic] = useState<any | null>('');

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  useGet(`/coursedetail/${courseId}`, {
    courseId,
    onSuccess: (result: any) => {
      setCourse(result?.data || null);
    },
  });

  useGet(`/courseinfo/${courseId}`, {
    courseId,
    onSuccess: (result: any) => {
      setCourseinfo(result?.data || null);
    },
  });

  if (!course) return null;

  const handleConfirmSettings = () => {
    if (courseinfo) {
      axios.put(`${config.backend}/courseinfo/${courseId}`, {
        courseId: courseId,
        lvCompletePassed: isMessageSuccess ? isMessageSuccess : '',
        lvCompleteFailed: isMessageFail ? isMessageFail : '',
      });
    } else {
      axios.post(`${config.backend}/courseinfos`, {
        courseId: courseId,
        lvCompletePassed: isMessageSuccess ? isMessageSuccess : '',
        lvCompleteFailed: isMessageFail ? isMessageFail : '',
      });
    }

    axios
      .put(`${config.backend}/course/${courseId}`, {
        id: courseId,
        courseStartDate: startDate ? startDate : course.courseStartDate,
        courseEndDate: endDate ? endDate : course.courseEndDate,
        hasCustomLanding: isCustomLandingPage
          ? isCustomLandingPage
          : course.hasCustomLanding,
        inOrder: isFollowInOder ? isFollowInOder : course.inOrder,
        autoCorrect: isAutoCorrect ? isAutoCorrect : course.autoCorrect,
        isMandatory: isMandatory ? isMandatory : course.isMandatory,
        emailCertificate: isCertificate
          ? isCertificate
          : course.emailCertificate,
        minScore: minimalScore ? minimalScore : course.minScore,
        courseAccess: isPublic === 'protected' ? 'protected' : 'private',
      })
      .then(() => navigate(`${basePath}`));
  };

  return (
    <div className="bg-white">
      <div className="flex min-h-16 items-center justify-start ">
        <IconButton
          className="my-auto mx-6"
          Icon={CrossFilled}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <div className="flex items-center">
          <div className="mr-5 w-20 min-w-20">
            <img
              src={course.image || '/images/courseFallback.png'}
              alt="courseFallback"
            />
          </div>

          <span>{t('pages.follow_up_courses.add_students_to_course')}</span>
          <span className="pl-2 font-semibold">{course.name}</span>
        </div>
      </div>
      <Stepper>
        <Stepper.Item
          isActive={step === 1}
          isDone={step > 1}
          onClick={() => setStep(1)}
          step="1"
        >
          {(!isMobile || step === 1) && (
            <T>pages.create_courses.communication</T>
          )}
        </Stepper.Item>
        <Stepper.Item
          isActive={step === 2}
          isDone={step > 2}
          onClick={() => setStep(2)}
          step="2"
        >
          {(!isMobile || step === 2) && <T>pages.create_courses.settings</T>}
        </Stepper.Item>
      </Stepper>

      <div className="mx-auto mt-8 flex max-w-screen-md flex-col justify-center px-2">
        {step === 1 && (
          <>
            <article className="prose flex flex-col">
              <h3>
                <b>
                  <T>pages.create_courses.communication</T>
                </b>
              </h3>
            </article>
            <div className="mt-4 flex flex-col">
              <b>
                <T>pages.create_courses.message_fail</T>
              </b>
              <InputTextarea
                value={isMessageFail ? isMessageFail : ''}
                onChange={(event) => setIsMessageFail(event.target.value)}
              />
            </div>
            <div className="mt-4 flex flex-col">
              <b>
                <T>pages.create_courses.message_success</T>
              </b>
              <InputTextarea
                value={isMessageSuccess ? isMessageSuccess : ''}
                onChange={(event) => setIsMessageSuccess(event.target.value)}
              />
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <article className="prose flex flex-col">
              <h3>
                <b>
                  <T>pages.create_courses.settings</T>
                </b>
              </h3>
            </article>
            <div className="flex flex-wrap">
              <div className="mt-4 mr-6 w-[48%] md:py-4">
                <h4>
                  <b>
                    <T>pages.create_courses.start_date</T> (
                    <T>forms.optional</T>)
                  </b>
                </h4>
                <InputDate
                  id="startDate"
                  onChange={(date) => setStartDate(date)}
                  value={
                    startDate
                      ? startDate
                      : new Date(course.courseStartDate || '')
                  }
                />
              </div>
              <div className="mt-4 w-[48%] md:py-4">
                <h4>
                  <b>
                    <T>pages.create_courses.end_date</T> (<T>forms.optional</T>)
                  </b>
                </h4>
                <InputDate
                  id="endDate"
                  onChange={(date) => setEndDate(date)}
                  value={endDate ? endDate : course.courseEndDate}
                />
              </div>
            </div>
            <div className="mt-4 flex flex-row items-center border border-grey-02 p-2">
              <Switch
                checked={
                  isCustomLandingPage
                    ? isCustomLandingPage
                    : course.hasCustomLanding
                }
                onChange={(isCheck) => setCustomLandingPage(isCheck)}
              />

              <div className="pl-4">
                <h4>
                  <b>
                    <T>pages.create_courses.start_page_title</T>
                  </b>
                </h4>
                <p>
                  <T>pages.create_courses.start_page_detail</T>
                </p>
              </div>
            </div>
            <div className="mt-4 flex flex-row items-center border border-grey-02 p-2">
              <Switch
                checked={isFollowInOder ? isFollowInOder : course.inOrder}
                onChange={(isCheck) => setIsFollowInOder(isCheck)}
              />
              <div className="pl-4">
                <h4>
                  <b>
                    <T>pages.create_courses.sequential</T>
                  </b>
                </h4>
                <p>
                  <T>pages.create_courses.sequential_detail</T>
                </p>
              </div>
            </div>
            <div className="mt-4 flex flex-row items-center border border-grey-02 p-2">
              <Switch
                checked={isAutoCorrect ? isAutoCorrect : course.autoCorrect}
                onChange={(isCheck) => setIsAutoCorrect(isCheck)}
              />
              <div className="pl-4">
                <h4>
                  <b>
                    <T>pages.create_courses.auto_correct</T>
                  </b>
                </h4>
                <p>
                  <T>pages.create_courses.auto_correct_detail</T>
                </p>
              </div>
            </div>
            <div className="mt-4 flex flex-row items-center border border-grey-02 p-2">
              <div className="m-4 flex-1">
                <Slider
                  value={minimalScore ? minimalScore : course.minScore}
                  onSlide={(value) => setMinimalScore(value)}
                  label={t('pages.create_courses.minimal_score')}
                  withInput
                  max={100}
                  min={0}
                />
              </div>
            </div>
            <div className="mt-4 flex flex-row items-center border border-grey-02 p-2">
              <Switch
                checked={isMandatory ? isMandatory : course.isMandatory}
                onChange={(isCheck) => setIsMandatory(isCheck)}
              />
              <div className="pl-4">
                <h4>
                  <b>
                    <T>pages.create_courses.mandatory</T>
                  </b>
                </h4>
                <p>
                  <T>pages.create_courses.mandatory_detail</T>
                </p>
              </div>
            </div>
            <div className="mt-4 flex flex-col items-center gap-4 md:flex-row">
              <div className="min-w-full md:min-w-fit">
                <RadioCard
                  onChange={() => setIsPublic('protected')}
                  checked={isPublic === 'protected'}
                >
                  <span>
                    <T>pages.create_courses.public</T>&nbsp;
                    <b>{authUser?.organisationName}</b>
                  </span>
                </RadioCard>
              </div>
              <div className="min-w-full flex-1 md:min-w-fit">
                <RadioCard
                  onChange={() => setIsPublic('private')}
                  checked={isPublic === 'private'}
                >
                  <T>pages.create_courses.invite_only</T>
                </RadioCard>
              </div>
            </div>
            <div className="mt-4 flex flex-row items-center border border-grey-02 p-2">
              <Switch
                checked={isCertificate}
                onChange={(isCheck) => setIsCertificate(isCheck)}
              />
              <div className="pl-4">
                <h4>
                  <b>
                    <T>pages.create_courses.certificate</T>
                  </b>
                </h4>
                <p>
                  <T>pages.create_courses.certificate_detail</T>
                </p>
              </div>
            </div>
          </>
        )}
        <div className="mt-8 mb-4 flex min-w-full justify-end">
          {step > 1 && (
            <Button
              variant="secondary"
              onClick={() => setStep(step - 1)}
              className="mr-auto"
            >
              {t('button.previous')}
            </Button>
          )}
          {step < MAXSTEP && (
            <Button onClick={() => setStep(step + 1)}>
              {t('button.next')}
            </Button>
          )}
          {step === MAXSTEP && (
            <Button onClick={() => handleConfirmSettings()}>
              {t('button.confirm')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CourseSettings;

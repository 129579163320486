import { DataTable } from '@/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { PersonAvatar, Tag } from '@teo/components';
import OptionsAction from './DataTable/OptionsAction';
import { nthLastIndexOf, newUid } from '@/util';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useContext, useState } from 'react';
import { axios } from '@/axios';
import { config } from '@/config';

export const TeamsTable = ({ organisationId, ...other }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 3)
  );
  const [resetTeamTable, setResetTeamTable] = useState(newUid(20));
  //const teamPath = `${basePath}/teams`;

  const { auth } = useContext(AuthContext);
  const authUser: any = auth?.user;

  const teamPath = `/${i18n.language}/follow-up/${organisationId}/teams`;
  const managePath = `/${i18n.language}/manage/${organisationId}/teams`;

  const teamManageath = `/${i18n.language}/manage/${organisationId}/teams`;

  const teamColumns: any = [
    {
      title: t('columns.teamName'),
      className: 'col-start-1 col-end-9',
      id: 'teamName',
      render: (team: any) => (
        <div className="flex items-center">
          <PersonAvatar inline id={team.id} name={team.teamName}></PersonAvatar>
          {team.teamName}
        </div>
      ),
    },
    {
      addTitleOnMobile: true,
      className: 'col-start-1 col-end-8 row-start-2',
      id: 'nrOfTeamMembers',
      title: t('columns.nrOfTeamMembers'),
      align: 'right',
    },
    {
      addTitleOnMobile: true,
      id: 'verified',
      title: t('columns.status'),
      sortable: false,
      className: 'col-start-7 col-end-10 row-start-2 text-right justify-end',
      align: 'right',
      classNameDesktop: 'max-w-[125px] w-[125px]',
      render: (team: any) => (
        <Tag
          title={
            team?.teamStatus === 'active'
              ? t('pages.manage_users.active')
              : t('pages.manage_users.not_active')
            // : team.created
            // ? t('pages.manage_users.active')
            // : t('pages.manage_users.not_active')
          }
          variant={team?.teamStatus === 'active' ? 'success' : 'error'}
        />
      ),
    },
  ];

  // const removeTeam = (team: any) => {
  //   if (team) {
  //     console.log(team);
  //   }
  // };

  if (
    Math.max(authUser?.role || 0, authUser?.activeOrganisationRole || 0) >= 90
  ) {
    teamColumns.push({
      id: 'actionButton',
      sortable: false,
      title: '',
      className: 'col-start-9 row-start-1 justify-end',
      classNameDesktop: 'max-w-[65px] w-[65px]',
      render: (team: any) => (
        <OptionsAction
          menuItems={[
            {
              addIcon: true,
              id: '1',
              title: t('pages.follow_up_users.manage_team'),
              onClick: () => navigate(`${managePath}/${team?.id}`),
            },
            {
              hidden: !other.teameCourse,
              id: '2',
              title: t('pages.follow_up_users.delete_team'),
              onClick: async (ev) => {
                await axios.delete(
                  `${config.backend}/courseteam/${other?.courseId}/${team?.id}/`
                );
                other?.refetch();
                setResetTeamTable(newUid(20));
              },
            },
            // todo: add follow team functionality
            // {
            //   id: '2',
            //   title: t('pages.follow_up_users.track_team'),
            // },
          ]}
          rowInfo={team}
          screensUpdateTable="desktop"
          pageDetail={{
            title: t('pages.follow_up_users.manage_team'),
            rowPath: (team) => `${teamManageath}/${team.id}`,
          }}
          mobileHeader={{
            render: (team: any) => (
              <div className="flex items-center">
                <PersonAvatar
                  inline
                  id={team.id}
                  name={team.teamName}
                ></PersonAvatar>
                <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                  {team.teamName}
                </p>
              </div>
            ),
          }}
        />
      ),
    });
  }

  return (
    <DataTable
      //filter={filter}
      // rowPath={(team) => `${teamPath}/${team.id}`}
      key={resetTeamTable}
      rowPath={other.rowPath}
      defaultSort={[{ id: 'teamPath', desc: false }]}
      //fetch={useGet.bind(null, '/teams', { organisationId })}
      screensUpdateTable="desktop"
      columns={teamColumns}
      {...other}
    />
  );
};

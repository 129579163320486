import { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  InputSelect,
  Label,
  InputDate,
  Button,
  ErrorMessage,
} from '@teo/components';
import { genderOptions } from '../../../../constants/option.constants';

type InputSelectOption = {
  label: string;
  value: string;
};

type Step = {
  firstName: string;
  name: string;
  mail: string;
  gender: InputSelectOption;
  birth: any;
};

const Step1 = ({ step, setStep, setInfo, user, userInfo, courseInfo }: any) => {
  const { t } = useTranslation();
  const [checkValid, setCheckValid] = useState<boolean>(false);

  const initialState = {
    firstName: user?.firstName ? user?.firstName : '',
    name: user?.lastName ? user?.lastName : '',
    mail: user?.email ? user?.email : '',
    gender: userInfo?.gender
      ? { label: userInfo?.gender?.toUpperCase(), value: userInfo?.gender }
      : '',
    birth: userInfo?.birthDate,
  };
  const [state, updateState] = useReducer(
    (state: Step, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  useEffect(() => {
    updateState({ birth: userInfo?.birthDate });
    updateState({
      gender: userInfo?.gender
        ? { label: userInfo?.gender?.toUpperCase(), value: userInfo?.gender }
        : '',
    });
  }, [userInfo]);

  const nextButton = () => {
    const nextStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_location_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_regime_step')
      ? 2
      : courseInfo?.onboardingSteps?.includes('onboarding_referral_step')
      ? 3
      : courseInfo?.onboardingSteps?.includes('onboarding_diploma_step')
      ? 4
      : courseInfo?.onboardingSteps?.includes('onboarding_current_work_step')
      ? 5
      : 6;
    setCheckValid(true);
    const newState = state;
    !courseInfo?.onboardingSteps?.includes('onboarding_birthday_step') &&
      delete newState.birth;
    !courseInfo?.onboardingSteps?.includes('onboarding_gender_step') &&
      delete newState.gender;
    if (Object.values(newState).every((x) => x)) {
      setInfo((prev: any) => ({ ...prev, ...state }));
      setStep((prev: number) => prev + nextStep);
    }
  };

  if (step !== 1) {
    return null;
  }

  // console.log(courseInfo);

  const handleError = (el: string) => (el ? false : checkValid ? true : false);
  return (
    <>
      <div>
        <p>{t(`pages.onboarding.step_1.subtitle`)}</p>
        <div className="mb-1 mt-6 max-w-xs text-sm font-semibold text-black">
          <Label htmlFor="firstName">
            {t(`pages.onboarding.step_1.firstName`)}
          </Label>
          <InputText
            disabled={user?.idp !== 'local'}
            id="firstName"
            onChange={(e) => updateState({ firstName: e.currentTarget.value })}
            value={state?.firstName}
            hasError={handleError(state?.firstName)}
          />
          {handleError(state?.firstName) && (
            <ErrorMessage id="errorMessage">
              {t('pages.onboarding.error.text_fiels')}
            </ErrorMessage>
          )}
        </div>

        <div className="mb-1 mt-6 max-w-xs text-sm font-semibold text-black">
          <Label htmlFor="name">{t(`pages.onboarding.step_1.name`)}</Label>
          <InputText
            disabled={user?.idp !== 'local'}
            id="name"
            onChange={(e) => updateState({ name: e.currentTarget.value })}
            value={state?.name}
            hasError={handleError(state?.name)}
          />
          {handleError(state?.name) && (
            <ErrorMessage id="errorMessage">
              {t('pages.onboarding.error.text_fiels')}
            </ErrorMessage>
          )}
        </div>

        <div className="mb-1 mt-6 max-w-xs text-sm font-semibold text-black">
          <Label htmlFor="email">{t(`pages.onboarding.step_1.email`)}</Label>
          <InputText
            id="email"
            onChange={(e) => updateState({ mail: e.currentTarget.value })}
            value={state?.mail}
            hasError={handleError(state?.mail)}
            disabled={user?.email ? true : false}
          />
          {handleError(state?.mail) && (
            <ErrorMessage id="errorMessage">
              {t('pages.onboarding.error.email')}
            </ErrorMessage>
          )}
        </div>

        {courseInfo?.onboardingSteps?.includes('onboarding_birthday_step') && (
          <div
            className="mb-1 mt-6 max-w-xs text-sm font-semibold text-black"
            data-testid="input-date"
          >
            <Label htmlFor="birth">{t(`pages.onboarding.step_1.birth`)}</Label>
            <InputDate
              id="birth"
              onChange={(date) =>
                updateState({ birth: date?.toISOString().substring(0, 10) })
              }
              value={state?.birth ? new Date(state?.birth as Date) : undefined}
              variant="date"
              hasError={handleError(state?.birth)}
            />
            {handleError(state?.birth) && (
              <ErrorMessage id="errorMessage">
                {t('pages.onboarding.error.text_fiels')}
              </ErrorMessage>
            )}
          </div>
        )}

        {courseInfo?.onboardingSteps?.includes('onboarding_gender_step') && (
          <div className="mb-1 mt-6 max-w-xs text-sm font-semibold text-black">
            <Label htmlFor="birth">{t(`pages.onboarding.step_1.gender`)}</Label>
            <div id="birth" data-testid="birth-date">
              <InputSelect<InputSelectOption>
                value={state?.gender}
                onChange={(e: InputSelectOption) => {
                  updateState({
                    gender: { label: e.value?.toUpperCase(), value: e.value },
                  });
                }}
                options={genderOptions}
                hasError={handleError(state?.gender)}
              >
                {(options) => (
                  <span>
                    {options.label &&
                      t(`pages.onboarding.step_1.${options.label}`)}
                  </span>
                )}
              </InputSelect>
              {handleError(state?.gender) && (
                <ErrorMessage id="errorMessage">
                  {t('pages.onboarding.error.select_one')}
                </ErrorMessage>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full pt-10">
        <Button onClick={() => step < 7 && nextButton()}>
          {t(`pages.onboarding.button_next`)}
        </Button>
      </div>
    </>
  );
};
export default Step1;

import { useTranslation } from 'react-i18next';
import { T } from '@/components/T';
import { Button, Tabs } from '@teo/components';
import { useGet } from '@/query';
import { newUid } from '@/util';
import { useState, useContext } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import CompetencyProfiles from './CompetencyProfiles';
import AllCompetencies from './AllCompetencies';
import AddCompetencyProfilePage from '../AddCompetencyProfilePage/AddCompetencyProfilePage';
import EditCompetencyProfilePage from '../EditCompetencyProfilePage/EditCompetencyProfilePage';

function ManageCompetenciesPage() {
  const { t } = useTranslation();
  const [tab, setTab] = useState('competencyProfiles');
  const { auth } = useContext(AuthContext);

  const [categories, setCategories] = useState([]);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [activeProfile, setActiveProfile] = useState<any>();
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));

  useGet(`/categories`, {
    // count: 10,
    sort: 'tag',
    lang: auth?.user?.lang,
    onSuccess: (result: any) => {
      setCategories(
        result?.data?.map((el: any) => ({ id: el.id, name: el.tag })) || null
      );
    },
  });

  const competencyType = [
    {
      id: '1',
      name: t('pages.manage_competencies.type_knowledge'),
      label: 'knowledge',
    },
    {
      id: '2',
      name: t('pages.manage_competencies.type_attitude'),
      label: 'attitude',
    },
    {
      id: '3',
      name: t('pages.manage_competencies.type_skill'),
      label: 'skill',
    },
  ];

  return (
    <div className=" w-full max-w-6xl">
      <div className="flex items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{t('pages.manage_competencies.title')}</T>
        </h1>

        <div className="flex items-center">
          <Button onClick={() => {}} size="md" className="hidden">
            {t('pages.manage_competencies.download_list_button')}
          </Button>
        </div>
      </div>

      <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
        <Tabs.TabButton name="competencyProfiles">
          <p className="text-base font-medium">
            {t('pages.manage_competencies.competency_profiles_tab')}
          </p>
        </Tabs.TabButton>
        <Tabs.TabButton name="allCompetencies">
          <p className="text-base font-medium">
            {t('pages.manage_competencies.all_competencies_tab')}
          </p>
        </Tabs.TabButton>
      </Tabs>
      <div className={`${isOpenAdd || (isOpenEdit && 'hidden')} `}>
        {tab === 'competencyProfiles' && (
          <CompetencyProfiles
            categories={categories}
            setIsOpenAdd={setIsOpenAdd}
            setIsOpenEdit={setIsOpenEdit}
            setActiveProfile={setActiveProfile}
            setRefetchTable={setRefetchTable}
            refetchTable={refetchTable}
          />
        )}

        {tab === 'allCompetencies' && (
          <AllCompetencies
            categories={categories}
            competencyType={competencyType}
          />
        )}
      </div>
      {isOpenAdd && (
        <AddCompetencyProfilePage
          setIsOpenAdd={setIsOpenAdd}
          categories={categories}
          competencyType={competencyType}
          setRefetchProfile={setRefetchTable}
        />
      )}
      {isOpenEdit && activeProfile && (
        <EditCompetencyProfilePage
          setIsOpenEdit={setIsOpenEdit}
          setActiveProfile={setActiveProfile}
          activeProfile={activeProfile}
          categories={categories}
          competencyType={competencyType}
          setRefetchProfile={setRefetchTable}
        />
      )}
    </div>
  );
}

export default ManageCompetenciesPage;

import { useState, useEffect } from 'react';
import {
  isEmpty,
  newUid,
  useMobile,
  shuffle,
  round2,
  focusWidget,
} from '@/util';
import { Checkbox, CheckboxGroup, RadioGroup, Radio } from '@teo/components';
import { MagnifyImage } from './MagnifyImage';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { useTranslation } from 'react-i18next';
import { WidgetHeader } from './WidgetHeader';
import { encode } from '@/base64';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { XAPIService } from '@/services/xapi.service';

export const MultipleChoiceView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setXApiSubmitFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  isCorrected,
  onSave = undefined,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  MultipleChoiceView.syncStates(state, answer, correction);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [score, setScore] = useState(correction?.score);
  const answered = submitted || isCorrected;
  const [randomOrder, setRandomOrder] = useState(
    !showAnswers &&
      !answered &&
      (state.randomOrder !== undefined ? state.randomOrder : true)
  );

  const [randomAntwoorden, setRandomAntwoorden] = useState(
    randomOrder ? shuffle(state.antwoorden.map((x) => x)) : state.antwoorden
  );

  const [prevOreder, setPrevOreder] = useState(null);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [modified, setModified] = useState(null);

  const handlePrevOrder = () => {
    setPrevOreder(randomAntwoorden);
  };
  useEffect(() => {
    if (submitted && !answer?.answered) {
      let answer = getAnswer();
      let correction = {};
      MultipleChoiceView.syncStates(state, answer, correction);
      if (!answer?.answered) {
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  let GroupTag;
  if (state.oneAnswer) {
    GroupTag = RadioGroup;
  } else {
    GroupTag = CheckboxGroup;
  }

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  let maxScore = state?.score;
  let states = prevOreder ? prevOreder : state?.antwoorden;
  let metaData;

  const parseAnswer = () => {
    const base = showAnswers ? state : answer;
    if (state.oneAnswer) {
      const index = base.antwoorden.findIndex((x) =>
        showAnswers ? x.isCorrect : x.checked
      );
      if (index === -1) return null;
      return index;
    } else {
      return base.antwoorden.reduce((acc, x, i) => {
        if (showAnswers ? x.isCorrect : x.checked) acc.push(i);
        return acc;
      }, []);
    }
  };

  const [value, setValue] = useState(parseAnswer());

  //generate the answer
  const getAnswer = () => {
    if (Array.isArray(value)) {
      answer.antwoorden.forEach((x, i) => {
        Object.assign(x, { checked: value?.includes?.(i) || false });
      });
    } else {
      answer.antwoorden.forEach((x, i) => {
        Object.assign(x, { checked: i == value ? true : false });
      });
    }
    const hasChecked = answer?.antwoorden?.some(
      (item) => item.checked === true
    );
    answer.incomplete = !submitted && !hasChecked;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    let correction = {};
    MultipleChoiceView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = (lessonId, lang) => {
    let correction = {};
    MultipleChoiceView.syncStates(state, getAnswer(), correction);
    const testId = lessonId;
    const questionId = state.uid;
    const answerIds = answer.antwoorden
      .map((x, i) => (x.checked ? i + 1 : undefined))
      .filter((x) => x);
    const correctAnswerIds = state.antwoorden
      .map((x, i) => (x.isCorrect ? i + 1 : undefined))
      .filter((x) => x);
    const success = correction.score >= state.score * 0.5;
    const choices = state.antwoorden.map((x, i) => ({
      id: '' + i + 1,
      description: {
        [lang]: x.text,
      },
    }));
    const name = {
      [lang]: state?.titleWidget || t('widgets.type.multiple_choice_question'),
    };
    const description = {
      [lang]: state?.vraag,
    };

    let xapi = XAPIService.getInstance();
    xapi.cmi5.interactionChoice(
      testId,
      questionId,
      answerIds,
      correctAnswerIds,
      choices,
      name,
      description,
      success
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  const handleMouseDown = () => {
    answered && setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    answered && setIsMouseDown(false);
  };

  let answers = getAnswer().antwoorden;
  if (answered) {
    let corrections = getCorrection().antwoorden;

    metaData = states?.map((x, i) => ({
      correctClass:
        corrections[x?.id].correct && x.isCorrect
          ? '!border-success-04'
          : !corrections[x?.id].correct && x.isCorrect
          ? isMouseDown
            ? '!border-secondary-04'
            : '!border-error-04'
          : corrections[x?.id].correct || x.isCorrect
          ? ''
          : isMouseDown
          ? '!border-secondary-04'
          : '!border-error-04',
      checked: isMouseDown ? x.isCorrect : answers[[x?.id]].checked,
    }));

    // metaData = corrections?.map((x, i) => ({
    //   correctClass:
    //     x.correct && states[i].isCorrect
    //       ? '!border-success-04'
    //       : !x.correct && states[i].isCorrect
    //       ? isMouseDown
    //         ? '!border-secondary-04'
    //         : '!border-error-04'
    //       : x.correct || states[i].isCorrect
    //       ? ''
    //       : isMouseDown
    //       ? '!border-secondary-04'
    //       : '!border-error-04',
    //   checked: isMouseDown ? states[i].isCorrect : answers[i].checked,
    // }));
  } else {
    metaData = answers?.map((x, i) => ({
      correctClass: x.checked ? 'default' : '',
      checked: answers[i].checked,
    }));
  }

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      onFocus={() => focusWidget(state?.uid)}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(score)}
          maxScore={state.score}
          answered={answered}
          titleWidget={
            state.titleWidget || t('widgets.type.multiple_choice_question')
          }
        />
      )}

      <FroalaTextareaView value={state.vraag} />
      <div>
        {answered ? (
          <>
            {score < maxScore ? (
              <p className="text-xs font-medium text-error-04">
                {t('pages.correction_result.multiplechoice_widget_hint')}
              </p>
            ) : null}

            <div className="flex flex-col gap-2">
              {(prevOreder ? prevOreder : state.antwoorden).map((option, i) => {
                return (
                  <div
                    key={i}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                  >
                    <label
                      className={`relative flex flex-1 items-center rounded-lg border border-grey-02 bg-white px-4.5 py-3.5 ${
                        answered && metaData[i]?.correctClass
                      } ${
                        isMouseDown &&
                        answers[option?.id]?.checked !== states[i]?.isCorrect &&
                        ' !border-secondary-04'
                      }`}
                    >
                      {state.oneAnswer ? (
                        <Radio
                          style={{ transitionDuration: '0s' }}
                          checked={metaData[i]?.checked}
                          className={`!transition-none ${
                            isMouseDown &&
                            answers[option?.id]?.checked !==
                              states[i]?.isCorrect &&
                            'checked:!bg-success-05'
                          }`}
                        />
                      ) : (
                        <Checkbox
                          checked={metaData[i]?.checked}
                          className={`${
                            isMouseDown &&
                            answers[option?.id]?.checked !==
                              states[i]?.isCorrect &&
                            'checked:!bg-success-05'
                          }`}
                        />
                      )}

                      <div className="ml-5 flex w-full flex-row items-center gap-4">
                        <FroalaTextareaView
                          className="mr-auto"
                          value={option.text}
                        />
                        {option.file && (
                          <div className="m-2 min-w-max overflow-hidden rounded-lg">
                            <MagnifyImage
                              showIcon={true}
                              className="h-[80px] w-[80px] object-cover"
                              src={option.file}
                              draggable={false}
                            />
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <GroupTag
            gap="gap-2"
            value={value}
            onChange={async (val) => {
              setValue(val);
              setModified(newUid(20));
            }}
          >
            {randomAntwoorden.map((option, i) => (
              <GroupTag.CardOption
                key={i}
                value={option.id}
                className={`relative ${!state.oneAnswer && '-ml-[30px]'} ${
                  answered && metaData[i]?.correctClass
                }`}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                <div className="flex flex-row items-center gap-4">
                  {/* {isMobile ? (
                    <p
                      className="mr-auto"
                      style={{
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                        overflow: 'hidden',
                        maxWidth: '121px',
                      }}
                    >
                      {option.text}
                    </p>
                  ) : ( */}
                  <FroalaTextareaView className="mr-auto" value={option.text} />
                  {/* // <p className="mr-auto">{option.text}</p> */}
                  {/* )} */}
                  {option.file && (
                    <div className="m-2 min-w-max overflow-hidden rounded-lg">
                      <MagnifyImage
                        showIcon={true}
                        className="h-[80px] w-[80px] object-cover"
                        src={option.file}
                        draggable={false}
                      />
                    </div>
                  )}
                </div>
              </GroupTag.CardOption>
            ))}
          </GroupTag>
        )}
        {(state.immediateFeedback || correctionType === 'autofeedback') && (
          <PartialSubmitSection
            setSubmitted={setSubmitted}
            setModified={setModified}
            answered={answered}
            handlePrevOrder={handlePrevOrder}
            onSave={onSave.bind(null, false, false, state)}
          />
        )}
        {(resultPages || showAnswers || answered || submitted) &&
        state?.antwoord ? (
          <ExplanationSection state={state} />
        ) : null}
      </div>
    </div>
  );
};

MultipleChoiceView.syncStates = (state, answer, correction) => {
  state.antwoorden = state.antwoorden.map((x, i) => ({ ...x, id: i }));
  if (isEmpty(answer) || answer.antwoorden.length !== state.antwoorden.length) {
    answer.uid = state.uid;
    answer.antwoorden = state.antwoorden.map((x) => ({ checked: false }));
  }
  if (
    isEmpty(correction) ||
    correction.antwoorden.length !== state.antwoorden.length
  ) {
    correction.uid = state.uid;
    if (!correction.score) {
      if (!state.scorePerAnswer) {
        correction.score = 0;
        correction.antwoorden = state.antwoorden.map((x, i) => {
          if (answer.antwoorden[i].checked) {
            if (x.isCorrect) {
              correction.score += 1;
            } else {
              correction.score -= 1;
            }
          }
          return {
            correct:
              !state.antwoorden[i].isCorrect === !answer.antwoorden[i].checked,
          };
        });
        correction.score = Math.max(0, correction.score);
        correction.score /= Math.max(
          state.antwoorden.filter((x) => x.isCorrect).length,
          1
        );
        correction.score *= state.score || 0;
      } else {
        correction.score = 0;
        correction.antwoorden = state.antwoorden.map((x, i) => {
          if (
            !state.antwoorden[i].isCorrect === !answer.antwoorden[i].checked
          ) {
            correction.score += x.score || 0;
          } else {
            correction.score -= x.score || 0;
          }
          return {
            correct:
              !state.antwoorden[i].isCorrect === !answer.antwoorden[i].checked,
          };
        });
        correction.score = Math.max(0, correction.score);
      }
    }
    if (state.strictCorrection && correction.score !== state.score) {
      correction.score = 0;
    }
  }
};

import { useContext, useEffect } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import { Userwithorganisationwithidps } from '@/models';
import i18n from 'i18next';

export default function HomePage() {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  useEffect(() => {
    if (!authUser) {
      return navigate(
        `/${i18n?.language || 'nl'}/login` + window.location.search
      );
    } else {
      const postLoginRedirect = localStorage.getItem('postLoginRedirect');
      if (postLoginRedirect) {
        localStorage.removeItem('postLoginRedirect');
        return navigate(postLoginRedirect);
      }
      return navigate(
        `/${i18n?.language || 'nl'}/learn` + window.location.search
      );
    }
  }, []);

  return null;
}

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from '@teo/components';
import { nthLastIndexOf } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const ModalRedirect = ({ course }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );
  const backPath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 2)
  );

  const handleBackLibrary = () => {
    navigate(`${backPath}`);
  };
  const handleGoCreate = () => {
    navigate(
      `${basePath}/${course?.organisationId}/courses/${course?.coursecontentId}`
    );
  };

  return (
    <div className={`fixed inset-0 z-[30] flex bg-white/75 px-4`}>
      <div className="mx-auto my-8 h-max w-full max-w-xl  rounded-xl bg-white drop-shadow-m">
        <Modal.Header onClose={() => handleBackLibrary()}>
          <h2 className="text-sm font-semibold">
            {t(`pages.create_library.redirect_modal.title`)}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col">
            <p className="mb-2 text-grey-06">
              {t(`pages.create_library.redirect_modal.text`)}
              <span className="ml-2 font-semibold">{course?.name}</span>
            </p>

            <div className="mt-6 flex gap-3">
              <Button variant="outline" onClick={() => handleBackLibrary()}>
                {t(`pages.create_library.redirect_modal.btn_back`)}
              </Button>

              <Button className="ml-auto" onClick={() => handleGoCreate()}>
                {t(`pages.create_library.redirect_modal.btn_go`)}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </div>
  );
};

export default ModalRedirect;

import { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import {
  Label,
  Button,
  InputTextarea,
  InputText,
  PersonAvatar,
  IconButton,
} from '@teo/components';
import { ChevronDown, ChevronUp } from '@teo/components/icons';
import { PublishVersion } from '@/models/publishCourse.interdace';
import { Userwithorganisationwithidps } from '@/models';
import { useGet } from '@/query/fetchHooks';
import { format } from 'date-fns';

const StepAddVersion = ({
  setStep,
  globalState,
  setGlobalState,
  publication,
  publicationType,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const [showMore, setShowMore] = useState(false);
  const divRef = useRef<any>(null);

  const initialState = globalState?.version
    ? globalState?.version
    : {
        versionName: '',
        versionComment: '',
      };

  const [state, updateState] = useReducer(
    (state: PublishVersion, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  const versions: any = useGet(`/coursecontentdetails`, {
    masterId: publication?.id,
    sort: '-createdAt,-id',
  });

  const onClick = () => {
    if (showMore) divRef?.current?.scrollTo({ top: 0 });

    setShowMore(!showMore);
  };

  useEffect(() => {
    setGlobalState((prev: any) => ({
      ...prev,
      version: state,
    }));
  }, [state]);

  return (
    <>
      <div className="h-full max-h-[calc(100vh_-_250px)] overflow-y-auto py-8">
        <div className="mx-auto flex max-w-screen-md flex-col justify-center px-3">
          <article className="prose flex flex-col">
            <h3 className="text-base">
              {t('pages.publish_course.steps_3.title')}
            </h3>
            <p className="pt-3 text-sm text-grey-07">
              {t(
                `pages.create_edit_course.type_${publicationType}.version_step_subtitle`
              )}
            </p>
          </article>

          <div className="mt-7 flex flex-col gap-4 rounded-lg border border-grey-02 p-4">
            <div className="mb-2 flex flex-wrap items-center gap-2">
              <PersonAvatar
                inline
                id={authUser?.id}
                name={authUser?.username ? authUser?.username : ''}
                imageSrc={authUser?.picture ? authUser?.picture : ''}
              ></PersonAvatar>

              <div className="mr-4 flex flex-col">
                <span className="text-sm font-medium text-[#231f20]">
                  {authUser?.username}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-base text-grey-06">
                  {format(new Date(), 'dd/MM/yyyy HH:mm ')}
                </span>
              </div>
            </div>

            <div>
              <Label htmlFor="versionComment">
                <p className="text-sm font-semibold">
                  {t('pages.publish_course.steps_3.add_comment')}
                  <span className="font-normal text-grey-05">
                    {' '}
                    ({t('pages.publish_course.steps_3.optional')})
                  </span>
                </p>
              </Label>

              <InputTextarea
                id="versionComment"
                placeholder={t('pages.publish_course.steps_3.add_placeholder')}
                onChange={(event) =>
                  updateState({ versionComment: event.currentTarget.value })
                }
                limit={300}
                value={state?.versionComment}
              />
            </div>
          </div>

          <div className="mt-7 flex flex-col gap-1 rounded-lg border border-grey-02 p-4">
            <div className="flex" data-testid="showMoreBtn">
              <h4 className="mr-auto text-base">
                {t('pages.publish_course.steps_3.existing_versions')}
              </h4>
              {versions?.data?.data?.length > 0 && (
                <IconButton
                  Icon={showMore ? ChevronUp : ChevronDown}
                  // ariaLabel="ShowMore"
                  onClick={() => {
                    onClick();
                  }}
                  variant="ghost"
                />
              )}
            </div>
            {versions?.data?.data?.length > 0 && (
              <div
                ref={divRef}
                className={`relative mt-6 mb-4 pl-2 ${
                  showMore
                    ? 'max-h-[250px] overflow-y-auto'
                    : 'max-h-[250px] overflow-hidden'
                }`}
              >
                <div className="relative flex flex-col gap-7">
                  <div className="absolute left-0 top-4 h-[calc(100%_-_32px)] w-0.5 bg-grey-02"></div>
                  {versions?.data?.data?.map((version: any) => {
                    return (
                      <div
                        key={version.id}
                        className="relative flex flex-col gap-1 pl-8"
                      >
                        <div className="absolute -left-0.5 top-2/4 -ml-px h-2 w-2 -translate-y-1/2 rounded-full bg-grey-05"></div>
                        <p className="pl-1.5 text-sm text-grey-06">
                          {t('pages.publish_course.steps_3.version')}{' '}
                          {version?.version}
                          {version?.versionName && ` - ${version?.versionName}`}
                          <span className="px-1">&#8729;</span>
                          {format(
                            new Date(version.createdAt || ''),
                            'dd/MM/yyyy HH:mm'
                          )}
                        </p>
                        {version.versionDescription && (
                          <p className="ml-1.5 rounded bg-grey-01 py-2 px-3 text-sm">
                            {version.versionDescription}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {versions?.data?.data?.length > 0 && (
              <div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    onClick();
                  }}
                >
                  {!showMore
                    ? t('pages.publish_course.steps_3.btn_more')
                    : t('pages.publish_course.steps_3.btn_less')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-auto flex border-t border-grey-02 py-5">
        <div className=" mx-auto flex w-full max-w-screen-md justify-end px-3">
          <Button
            variant="outline"
            onClick={() => setStep(1)}
            className="mr-auto"
            data-testid="prevBtn"
          >
            {t('button.previous')}
          </Button>
          <Button
            onClick={() => {
              // handleSave();
              setStep(3);
            }}
          >
            {t('button.next')}
          </Button>
        </div>
      </div>
    </>
  );
};
export default StepAddVersion;

import { axios } from '../axios';
import { config } from '../config';
import { createGet, createPost } from './generators';
import { changeLanguage } from '../language';
import { store } from '../store';
import { generateRestReducer } from '../reducers/generators.js';
import i18n from 'i18next';

export const authActions = {
  login,
  loginLocal,
  loginToken,
  logout,
  register,
};

/**
 * Retrieves user data from a login token. Login tokens can either
 * be gotten from localStorage or from query parameter after a
 * a succeful login redirect.
 *
 * @param {*} token email
 * @returns {token} on success
 */

function loginToken(token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  return (dispatch) =>
    dispatch(
      createGet(config.backend + '/auth/token', ['id'], { name: 'auth' })
    )
      .then((auth) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token;
        changeLanguage(auth.user.lang);
        return Promise.resolve(auth);
      })
      .catch((e) => {
        console.error(e);
        localStorage.setItem('token', null); //token is no longer valid
        return Promise.reject();
      });
}

export const authReducer = generateRestReducer([], 'AUTH', 'auth');
//captures the userdata
store.injectReducer('auth', authReducer);

/**
 * Trade email/password for login token
 *
 * @param {*} email email
 * @param {*} password Where to redirect the user after login
 * @returns {token} on success
 */

function loginLocal(email, password) {
  return axios.post(
    config.teologin + '/local?prompt=login',
    {
      login: email,
      password: password,
    },
    { withCredentials: true }
  );
}

/**
 * Log a user in with given idp and return path or even fully qualified uri
 *
 * For local login, it expects a login token, the result of authActions.login(email, password) => {token}
 *
 * @param {*} idp local/google/facebook/linkedin/niko/asap/cepa
 * @param {*} redirect_uri Where to redirect the user after login
 * @param {*} logintoken (optional) Local login result token
 * @param {*} state (optional) Will be returned with the redirect, can be used to pass state to the logged in user other than redirect path
 * @returns Redirects browser to complete login
 */

function login(idp, redirect_uri = '/login', logintoken = null, state = null) {
  if (!redirect_uri.startsWith('http') && !redirect_uri.startsWith('https'))
    redirect_uri = encodeURIComponent(
      window.location.protocol + '//' + window.location.host + redirect_uri
    );
  else redirect_uri = encodeURIComponent(redirect_uri);
  let token = localStorage.getItem('invite'); //possible invite token
  let path = `${config.backend}/auth?idp=${idp}&lang=${i18n.language}&token=${token}&redirect_uri=${redirect_uri}`; //&token=${token}`s
  if (logintoken) path += `&logintoken=${logintoken}`;
  if (state) path += `&state=${state}`;
  window.location.replace(path);
}

/**
 * Logout a user
 */
function logout() {
  return () => ({ type: 'USERS_LOGOUT' });
}

function register(
  user,
  loginRedirect = encodeURIComponent(window.location.origin + '/login'),
  lang = i18n.language
) {
  return createPost(
    `/local/register?lang=${lang}&loginRedirect=` +
      loginRedirect +
      (localStorage.getItem('invite')
        ? '?token=' + localStorage.getItem('invite')
        : ''),
    ['id'],
    user
  );
}

import { Modal, Button, PersonAvatar, Dropdown } from '@teo/components';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { ArrowLeft, Tag, MoreVertical } from '@teo/components/icons';
import { format } from 'date-fns';
import { convertToTime, newUid, nthLastIndexOf } from '@/util';
import { axios } from '@/axios';
import { config } from '@/config';
import { uploadLesson } from '@/query/documents';
import { ImportAction } from './ImportAction';
import ChapterTreeChildren from '@/pages/Protected/Create/Library/ChapterTreeChildren';

export const ActiveChapter = ({
  closeModal,
  setChapterSelected,
  activeChapter,
  parentPath,
  coursecontentId,
  prevTree,
  parentId,
  setIsOpenLibrary,
  setAddLesson,
  setAddFolder,
  setTree,
}: any) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<any>();
  const [numLes, setNumLes] = useState<any>(null);
  const [chapterTree, setChapterTree] = useState<any>(null);
  const [firstLesson, setFirstLesson] = useState<any>(null);
  const [chapterExtraInfo, setChapterExtraInfo] = useState<any>(null);
  const [nodePath, setNodePath] = useState<any>(null);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    if (activeChapter?.tag) {
      const data = activeChapter?.tag?.split(';;');
      const filteredTags = data.filter(
        (item: any) => item.toLowerCase() !== activeChapter?.name?.toLowerCase()
      );

      setTags(
        filteredTags
          ?.filter((x: any) => x?.startsWith('tag:'))
          ?.map((el: any) => el?.split('tag:')[1])
      );
    }
  }, [activeChapter?.tag]);

  const examplePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 1)
  );

  const handleAddChapter = async () => {
    const folderExtraInfo = chapterExtraInfo?.filter(
      (el: any) => el?.rootDirectoryId === activeChapter?.directoryId
    );

    const createDirectoryResponse = await axios.post(
      `${config.backend}/directories`,
      {
        coursecontentId: parseInt(coursecontentId, 10),
      }
    );

    const newIdFolder = createDirectoryResponse?.data?.id;
    let newYindex: any;
    let parentTree: any;
    if (parentId) {
      parentTree = prevTree.filter((item: any) => item?.parent === parentId);
    } else {
      parentTree = prevTree.filter((item: any) => item?.parent === 0);
    }

    if (parentTree?.length) {
      newYindex =
        parentTree?.reduce((max: any, obj: any) => {
          return obj?.data?.yindex > max ? obj?.data?.yindex : max;
        }, parentTree[0]?.data?.yindex) + 1;
    } else {
      newYindex = 0;
    }

    const newPath = parentPath
      ? `${parentPath}/${newUid(20)}`
      : `/${newUid(20)}`;
    setNodePath(newPath);

    if (newIdFolder) {
      await axios.put(`${config.backend}/directory/${newIdFolder}`, {
        coursecontentId: parseInt(coursecontentId, 10),
        id: newIdFolder,
        name: activeChapter?.name,
        path: newPath,
        yindex: newYindex,
      });

      setAddFolder({
        ...createDirectoryResponse?.data,
        coursecontentId: parseInt(coursecontentId, 10),
        id: newIdFolder,
        numbered: true,
        name: activeChapter?.name,
        path: newPath,
        yindex: newYindex,
      });

      for (const el of folderExtraInfo) {
        if (el?.lessonId || el?.assetId) {
          if (el?.lessonId) {
            const lesson = await axios.get(
              `${config.backend}/lesson/${el?.lessonId}?write_access=true`
            );
            const linkLes = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              lessonId: el?.lessonId,
              path: `/${el?.lessonId}`,
              yindex: 0,
            });

            await setAddLesson({
              ...linkLes?.data,
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              id: linkLes?.data?.id,
              lessonId: el?.lessonId,
              name: el?.name,
              path: `${newPath}/${linkLes?.data?.id}`,
              postData: lesson?.data.postData,
              getData: lesson?.data?.getData,
              usesId: linkLes?.data?.id,
              yindex: el?.data?.yindex,
              rootDirectoryId: newIdFolder,
            });

            await axios.put(`${config.backend}/use/${linkLes?.data?.id}`, {
              coursecontentId: parseInt(coursecontentId, 10),
              lessonId: el?.lessonId,
              path: `${newPath}/${linkLes?.data?.id}`,
              yindex: el?.data?.yindex,
              rootDirectoryId: newIdFolder,
            });
          } else {
            const newImg = await axios.post(`${config.backend}/assets`, {
              assetName: el?.assetName,
              canEdit: true,
              assetType: el?.assetType,
              assetPath: el?.assetPath,
            });
            const linkImg = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              assetId: newImg?.data?.id,
            });

            await setAddLesson({
              ...linkImg?.data,
              assetId: newImg?.data?.id,
              assetType: el?.assetType,
              assetPath: el?.assetPath,
              assetName: el?.assetName,
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              onlyTheory: true,
              id: newImg?.data?.id,
              rootDirectoryId: newIdFolder,
              path: `${newPath}/${newImg?.data?.id}`,
              usesId: linkImg?.data?.id,
              yindex: el?.data?.yindex,
            });

            await axios.put(`${config.backend}/use/${linkImg?.data?.id}`, {
              coursecontentId: parseInt(coursecontentId, 10),
              assetId: newImg?.data?.id,
              path: `${newPath}/${newImg?.data?.id}`,
              yindex: el?.data?.yindex,
              rootDirectoryId: newIdFolder,
            });
          }
        }
      }
      setIsAdd(true);
    }
  };

  useEffect(() => {
    if (isAdd) {
      handleAddSubChapter();
    }
  }, [isAdd]);

  const handleAddSubChapter = async () => {
    if (chapterTree?.length > 0) {
      let index = 0;
      for (const el of chapterTree) {
        if (el?.data?.lessonId || el?.data?.assetId) {
          if (el?.data?.lessonId) {
            const lessonExtraInfo = chapterExtraInfo?.filter((extreI: any) => {
              return extreI?.rootLessonId === el?.data?.lessonId;
            });

            const lesson = await axios.get(
              `${config.backend}/lesson/${el?.data?.lessonId}?write_access=true`
            );

            const linkLes = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              lessonId: el?.data?.lessonId,
              path: `/${el?.data?.lessonId}`,
              yindex: 0,
            });

            await setAddLesson({
              ...linkLes?.data,
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              id: linkLes?.data?.id,
              lessonId: el?.data?.lessonId,
              name: el?.data?.name,
              path: `${nodePath}${el?.data?.path?.replace(
                el?.data?.id,
                linkLes?.data?.id
              )}`,
              postData: lesson?.data.postData,
              getData: lesson?.data?.getData,
              usesId: linkLes?.data?.id,
              yindex: el?.data?.yindex,
            });

            axios.put(`${config.backend}/use/${linkLes?.data?.id}`, {
              coursecontentId: parseInt(coursecontentId, 10),
              lessonId: el?.data?.lessonId,
              path: `${nodePath}${el?.data?.path?.replace(
                el?.data?.id,
                linkLes?.data?.id
              )}`,
              yindex: el?.data?.yindex,
            });
            for (const eInfo of lessonExtraInfo) {
              if (eInfo?.lessonId || eInfo?.assetId) {
                if (eInfo?.lessonId) {
                  const lesson = await axios.get(
                    `${config.backend}/lesson/${eInfo?.lessonId}?write_access=true`
                  );

                  const linkExtraLes = await axios.post(
                    `${config.backend}/uses`,
                    {
                      canEdit: false,
                      coursecontentId: parseInt(coursecontentId, 10),
                      lessonId: eInfo?.lessonId,
                      path: `/${eInfo?.lessonId}`,
                      yindex: 0,
                    }
                  );

                  await setAddLesson({
                    ...linkExtraLes?.data,
                    canEdit: false,
                    coursecontentId: parseInt(coursecontentId, 10),
                    id: linkExtraLes?.data?.id,
                    lessonId: eInfo?.lessonId,
                    name: eInfo?.name,
                    postData: lesson?.data.postData,
                    getData: lesson?.data?.getData,
                    usesId: linkExtraLes?.data?.id,
                    path: `${nodePath}${el?.data?.path?.replace(
                      el?.data?.id,
                      linkLes?.data?.id
                    )}/${linkExtraLes?.data?.id}`,
                    yindex: eInfo?.data?.yindex,
                    rootLessonId: el?.data?.lessonId,
                  });

                  await axios.put(
                    `${config.backend}/use/${linkExtraLes?.data?.id}`,
                    {
                      coursecontentId: parseInt(coursecontentId, 10),
                      lessonId: eInfo?.lessonId,
                      path: `${nodePath}${el?.data?.path?.replace(
                        el?.data?.id,
                        linkLes?.data?.id
                      )}/${linkExtraLes?.data?.id}`,
                      yindex: eInfo?.data?.yindex,
                      rootLessonId: el?.data?.lessonId,
                    }
                  );
                } else {
                  const newImg = await axios.post(`${config.backend}/assets`, {
                    assetName: eInfo?.assetName,
                    canEdit: true,
                    assetType: eInfo?.assetType,
                    assetPath: eInfo?.assetPath,
                  });
                  const linkImg = await axios.post(`${config.backend}/uses`, {
                    canEdit: false,
                    coursecontentId: parseInt(coursecontentId, 10),
                    assetId: newImg?.data?.id,
                  });

                  await setAddLesson({
                    ...linkImg?.data,
                    assetId: newImg?.data?.id,
                    assetType: eInfo?.assetType,
                    assetPath: eInfo?.assetPath,
                    assetName: eInfo?.assetName,
                    canEdit: false,
                    coursecontentId: parseInt(coursecontentId, 10),
                    onlyTheory: true,
                    id: newImg?.data?.id,
                    path: `${nodePath}${el?.data?.path?.replace(
                      el?.data?.id,
                      linkLes?.data?.id
                    )}/${newImg?.data?.id}`,
                    yindex: eInfo?.data?.yindex,
                    rootLessonId: el?.data?.lessonId,
                    usesId: linkImg?.data?.id,
                  });
                  await axios.put(
                    `${config.backend}/use/${linkImg?.data?.id}`,
                    {
                      coursecontentId: parseInt(coursecontentId, 10),
                      assetId: newImg?.data?.id,
                      path: `${nodePath}${el?.data?.path?.replace(
                        el?.data?.id,
                        linkLes?.data?.id
                      )}/${newImg?.data?.id}`,
                      yindex: eInfo?.data?.yindex,
                      rootLessonId: el?.data?.lessonId,
                    }
                  );
                }
              }
            }
          } else {
            const newImg = await axios.post(`${config.backend}/assets`, {
              assetName: el?.data?.assetName,
              canEdit: true,
              assetType: el?.data?.assetType,
              assetPath: el?.data?.assetPath,
            });
            const linkImg = await axios.post(`${config.backend}/uses`, {
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              assetId: newImg?.data?.id,
            });

            await setAddLesson({
              ...linkImg?.data,
              assetId: newImg?.data?.id,
              assetType: el?.data?.assetType,
              assetPath: el?.data?.assetPath,
              assetName: el?.data?.assetName,
              canEdit: false,
              coursecontentId: parseInt(coursecontentId, 10),
              onlyTheory: true,
              id: newImg?.data?.id,
              path: `${nodePath}${el?.data?.path?.replace(
                el?.data?.assetId,
                linkImg?.data?.assetId
              )}`,
              usesId: linkImg?.data?.id,
              yindex: el?.data?.yindex,
            });

            axios.put(`${config.backend}/use/${linkImg?.data?.id}`, {
              coursecontentId: parseInt(coursecontentId, 10),
              assetId: newImg?.data?.assetId,
              path: `${nodePath}${el?.data?.path?.replace(
                el?.data?.assetId,
                linkImg?.data?.assetId
              )}`,
              yindex: el?.data?.yindex,
            });
          }
        } else {
          const folderExtraInfo = chapterExtraInfo?.filter((extreI: any) => {
            return (
              extreI?.rootDirectoryId === el?.data?.id && !el?.data?.lessonId
            );
          });

          const newSubChapter = await axios.post(
            `${config.backend}/directories`,
            {
              coursecontentId: parseInt(coursecontentId, 10),
            }
          );

          const newIdSubFolder = newSubChapter?.data?.id;
          if (newIdSubFolder) {
            axios.put(
              `${config.backend}/directory/${newSubChapter?.data?.id}`,
              {
                coursecontentId: parseInt(coursecontentId, 10),
                id: newSubChapter?.data?.id,
                name: el?.data?.name,
                path: `${nodePath}${el?.data?.path}`,
                yindex: el?.data?.yindex,
              }
            );
            setAddFolder({
              ...newSubChapter?.data,
              coursecontentId: parseInt(coursecontentId, 10),
              id: newIdSubFolder,
              numbered: true,
              name: el?.data?.name,
              path: `${nodePath}${el?.data?.path}`,
              yindex: el?.data?.yindex,
            });

            for (const eInfo of folderExtraInfo) {
              if (eInfo?.lessonId || eInfo?.assetId) {
                if (eInfo?.lessonId) {
                  const lesson = await axios.get(
                    `${config.backend}/lesson/${eInfo?.lessonId}?write_access=true`
                  );
                  const linkLes = await axios.post(`${config.backend}/uses`, {
                    canEdit: false,
                    coursecontentId: parseInt(coursecontentId, 10),
                    lessonId: eInfo?.lessonId,
                    path: `/${eInfo?.lessonId}`,
                    yindex: 0,
                  });

                  await setAddLesson({
                    ...linkLes?.data,
                    canEdit: false,
                    coursecontentId: parseInt(coursecontentId, 10),
                    id: linkLes?.data?.id,
                    lessonId: eInfo?.lessonId,
                    name: eInfo?.name,
                    postData: lesson?.data.postData,
                    getData: lesson?.data?.getData,
                    usesId: linkLes?.data?.id,
                    path: `${nodePath}${el?.data?.path}/${linkLes?.data?.id}`,
                    yindex: eInfo?.data?.yindex,
                    rootDirectoryId: newSubChapter?.data?.id,
                  });

                  await axios.put(
                    `${config.backend}/use/${linkLes?.data?.id}`,
                    {
                      coursecontentId: parseInt(coursecontentId, 10),
                      lessonId: eInfo?.lessonId,
                      path: `${nodePath}${el?.data?.path}/${linkLes?.data?.id}`,
                      yindex: eInfo?.data?.yindex,
                      rootDirectoryId: newSubChapter?.data?.id,
                    }
                  );
                } else {
                  const newImg = await axios.post(`${config.backend}/assets`, {
                    assetName: eInfo?.assetName,
                    canEdit: true,
                    assetType: eInfo?.assetType,
                    assetPath: eInfo?.assetPath,
                  });
                  const linkImg = await axios.post(`${config.backend}/uses`, {
                    canEdit: false,
                    coursecontentId: parseInt(coursecontentId, 10),
                    assetId: newImg?.data?.id,
                  });
                  await setAddLesson({
                    ...linkImg?.data,
                    assetId: newImg?.data?.id,
                    assetType: eInfo?.assetType,
                    assetPath: eInfo?.assetPath,
                    assetName: eInfo?.assetName,
                    canEdit: false,
                    coursecontentId: parseInt(coursecontentId, 10),
                    onlyTheory: true,
                    id: newImg?.data?.id,
                    path: `${nodePath}${el?.data?.path}/${newImg?.data?.id}`,
                    yindex: eInfo?.data?.yindex,
                    rootDirectoryId: newSubChapter?.data?.id,
                    usesId: linkImg?.data?.id,
                  });
                  await axios.put(
                    `${config.backend}/use/${linkImg?.data?.id}`,
                    {
                      coursecontentId: parseInt(coursecontentId, 10),
                      assetId: newImg?.data?.assetId,
                      path: `${nodePath}${el?.data?.path}/${newImg?.data?.id}`,
                      yindex: eInfo?.data?.yindex,
                      rootDirectoryId: newSubChapter?.data?.id,
                    }
                  );
                }
              }
            }
          }
        }
        if (index === chapterTree.length - 1) {
          setTree(null);
          setIsOpenLibrary(false);
          setChapterSelected(false);
        }

        index++;
      }
    } else {
      setIsOpenLibrary(false);
      setChapterSelected(false);
    }
    setIsAdd(false);
  };

  return (
    <div>
      <Modal.Header onClose={closeModal}>
        <div className="flex w-full flex-row pr-3">
          <Button
            className="flex items-center gap-3"
            variant="text"
            onClick={() => setChapterSelected(false)}
          >
            <ArrowLeft className="h-5 w-5" />
            <p>{t('pages.create_edit_course.library.back_library')}</p>
          </Button>
          <div className="ml-auto flex flex-row gap-3">
            <>
              <Button
                variant="outline"
                disabled={!firstLesson}
                onClick={() =>
                  window.open(
                    `${examplePath}/${activeChapter?.coursecontentId}/preview?les=${firstLesson?.usesId}&new_tab=true`,
                    '_blank'
                  )
                }
              >
                {t('pages.create_edit_course.library.preview')}
              </Button>

              <Button variant="primary" onClick={() => handleAddChapter()}>
                {t('pages.create_edit_course.library.linking')}
              </Button>
            </>
          </div>
        </div>
      </Modal.Header>
      <div className="max-h-[95vh] p-6">
        <div className="flex flex-col">
          <div className="mb-6">
            <img
              src={
                activeChapter?.publicationImage
                  ? activeChapter?.publicationImage
                  : '/assets/images/opleidingen.jpg'
              }
              alt={activeChapter?.name}
              className="max-h-60 w-full rounded-md object-cover"
            />
          </div>
          <div className="flex flex-col">
            <h1 className="mb-6 text-xl">
              <span className="pr-2 font-normal text-grey-06">
                {t(`pages.create_library.chapter`)}:
              </span>
              {activeChapter?.name}
            </h1>
            <div className="mb-6 flex flex-wrap items-center">
              <PersonAvatar
                inline
                id={activeChapter?.authorId}
                name={activeChapter?.authorName}
                imageSrc={
                  activeChapter?.authorPicture
                    ? activeChapter?.authorPicture
                    : ''
                }
              ></PersonAvatar>
              <div>
                <p className="text-xs text-grey-07">
                  {activeChapter?.authorName && (
                    <>
                      {t('pages.create_edit_course.library.written_by')}{' '}
                      {activeChapter?.authorName}
                    </>
                  )}
                  <span className="-mt-1 px-2">|</span>
                </p>
              </div>
              <p className="text-xs text-grey-07">
                {activeChapter?.updatedAt &&
                  t('pages.create_edit_course.library.last_updated') +
                    ' ' +
                    format(new Date(activeChapter?.updatedAt), 'dd MMM yyyy')}
              </p>
              {activeChapter?.directoryEstimatedTime > 0 && (
                <span className="flex flex-row items-center gap-1 text-xs text-grey-07">
                  <span className="-mt-1 px-2">|</span>
                  <p>
                    <span className="pr-1">
                      {t('pages.create_edit_course.library.reading_time')}
                    </span>
                    {convertToTime(
                      Math.abs(activeChapter?.directoryEstimatedTime),
                      t('pages.learn_index.hour'),
                      t('pages.learn_index.minute')
                    )}
                  </p>
                </span>
              )}
            </div>
            {tags?.length > 0 && (
              <div className="mb-5 flex flex-wrap items-center gap-3">
                <Tag className="h-5 w-5 text-grey-07" />
                {tags?.map((el: any, i: any) => {
                  return (
                    <div
                      key={i}
                      className="inline-flex items-center gap-1 rounded bg-grey-transparent-02 py-0.5 px-2 text-sm font-medium text-grey-08"
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="px-3">
            <p className="mt-6 mb-3 text-sm font-semibold">
              {t('extra_info.contents')}
              <span className="pl-3 text-xs font-normal text-grey-07">
                {numLes ? (
                  <>
                    {' '}
                    {numLes} {t('pages.create_library.lessons')}
                  </>
                ) : (
                  <>0 {t('pages.create_library.lessons')}</>
                )}
              </span>
            </p>
            <div className="max-h-[30vh] overflow-auto">
              <ChapterTreeChildren
                directoryId={activeChapter?.directoryId}
                directoryPath={activeChapter?.path}
                setNumLes={setNumLes}
                setChapterTree={setChapterTree}
                setFirstLesson={setFirstLesson}
                setChapterExtraInfo={setChapterExtraInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState, useEffect } from 'react';
import { Label, Button } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { emailRegex } from '@/constants/regex.constants';
import { PaginationMultiSelect } from '../PaginationMultiSelect';

const UserInvite = (props: any) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  const addValue =
    props.addValue ||
    (async (email: string) => {
      const newValue = [
        ...props.value,
        {
          email: email,
          label: email,
          role: 20,
          key: Math.floor(Math.random() * 9999999999999),
          firstName: '',
          lastName: '',
          phone: '',
          newUser: true,
          lang: props.activeLang,
        },
      ];
      await props.setValue(newValue);
      return newValue;
    });

  const render =
    props.render ||
    ((usr: any) => {
      return usr?.username || usr?.email;
    });

  useEffect(() => {
    if (filter) {
      const mailArray = filter.split(/[,\s]+/);
      if (mailArray.length > 1) {
        mailArray?.map((email) => {
          if (emailRegex.test(email)) {
            setFilter('');
            props.setValue((prev: any) => [
              ...prev,
              {
                email: email,
                label: email,
                role: 20,
                key: Math.floor(Math.random() * 9999999999999),
                firstName: '',
                lastName: '',
                phone: '',
                newUser: true,
                lang: props.activeLang,
              },
            ]);
          }
        });
      }
    }
  }, [filter]);

  return (
    <div className="my-6">
      {props.multiselectLabel && (
        <Label htmlFor="invite_author">
          <p className="text-sm font-semibold">{props.multiselectLabel}</p>
        </Label>
      )}

      <div id="invite_author" className="ali relative z-[10] flex">
        <span className="w-full">
          <PaginationMultiSelect
            data-testid="userInviteMultiselect"
            removeButtonLabel="clear"
            toggleSelectionLabel="toggle"
            filter={filter}
            onChange={props.setValue}
            setFilter={setFilter}
            onCommitQuery={(email: any) => {
              setFilter('');
              if (emailRegex.test(email)) {
                addValue(email);
              }
            }}
            render={render}
            defaultSort={props.defaultSort || [{ id: 'username', desc: false }]}
            placeholder={t('pages.create_edit_course.multiselect_placeholder')}
            {...props}
          />
        </span>

        <Button
          key={filter}
          className="ml-3 h-[40px] text-base font-medium"
          variant="primary"
          disabled={props.value.length === 0 && !emailRegex.test(filter)}
          onClick={async () => {
            let newValue = props.value;
            if (emailRegex.test(filter)) {
              newValue = await addValue(filter);
              setFilter('');
            }
            props.handleAddUserToList(newValue);
          }}
        >
          {props.inviteButtonText}
        </Button>
      </div>
    </div>
  );
};

export { UserInvite };

import { PageLayout } from '@/components/PageLayout';
import { Navigation, NavigationItem } from '@teo/components';
import { useTranslation } from 'react-i18next';
import BreadCrumbsHeader from '@/components/BreadCrumbsHeader';
import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { NavContext } from '@/components/NavContext';
import { useMobile } from '@/util';
import SidebarNavigation from '@/components/SidebarNavigation';
import { organisationSidebar } from '../../constants/sidebar.constants';

function OrganisationPage() {
  const { t } = useTranslation();
  const { collapsed, navDisabled, submenu } = useContext(NavContext);
  const isMobile = useMobile();
  const showNav = !navDisabled && ((submenu && !collapsed) || !isMobile);
  const showContent = collapsed || !isMobile;

  if (navDisabled) return <Outlet />;

  return (
    <PageLayout>
      {showNav && (
        <PageLayout.Sidebar>
          <SidebarNavigation items={organisationSidebar} />
        </PageLayout.Sidebar>
      )}
      <div
        className={`${
          showContent ? '' : 'hidden'
        } min-h-[100vh] w-full max-w-6xl lg:min-h-[calc(100vh_-_150px)]`}
      >
        <PageLayout.Content>
          <BreadCrumbsHeader />
          <Outlet />
        </PageLayout.Content>
      </div>
    </PageLayout>
  );
}

export default OrganisationPage;

import { useState, useEffect, useRef } from 'react';
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom/client';
import { newUid, deep_copy, getCharForNumber } from '@/util';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { Switch, InputScore, InputSelect, Button } from '@teo/components';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

import './InvulVraagEdit.css';

const typeAnswer = [
  { label: 'type_number', value: 'numeric' },
  { label: 'type_text', value: 'text' },
];

const pastels = [
  '#C25FF4',
  '#E3BF00',
  '#6EC6C0',
  '#F25850',
  '#29BAFF',
  '#AE7226',
  '#1173DD',
  '#9AD055',
  '#807EFF',
  '#FF8EA4',
];
const classInput =
  'h-10 w-full rounded-md border border-solid border-transparent py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02';

const ItemAnswer = ({ placeholder, index, setPlaceholders, placeholders }) => {
  const { t } = useTranslation();
  const [typePlaceholder, setTypePlaceholder] = useState(
    placeholder?.type
      ? placeholder?.type == 'numeric'
        ? { label: 'type_number', value: 'numeric' }
        : { label: 'type_text', value: 'text' }
      : ''
  );
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [precValue, setPrecValue] = useState();
  const [textValue, setTextValue] = useState();

  const updatePlaceholder = (item, value) => {
    const updatedItem = { ...placeholders };
    const targetKey = Object.keys(updatedItem).find(
      (key) => updatedItem[key].uid === placeholder.uid
    );
    if (targetKey) {
      updatedItem[targetKey] = {
        ...updatedItem[targetKey],
        [item]: value,
      };
      setPlaceholders(updatedItem);
    }
  };

  return (
    <div
      id={'uid_' + placeholder.uid}
      className="flex flex-col gap-2 lg:flex-row lg:items-center lg:gap-3"
    >
      <div className="flex flex-row items-center gap-3">
        <div
          style={{
            backgroundColor: `${pastels[index % pastels.length]}`,
          }}
          className="rounded-md py-1 px-2.5 text-white"
        >
          {getCharForNumber(index)}
        </div>
        <div className="w-44 sm:w-32">
          <InputSelect
            value={typePlaceholder}
            onChange={(e) => {
              updatePlaceholder('type', e.value);
              setTypePlaceholder(e);
            }}
            options={typeAnswer}
          >
            {(options) => (
              <span>
                {options.label && t(`widgets.edit.fill_blank.${options.label}`)}
              </span>
            )}
          </InputSelect>
        </div>
      </div>

      {typePlaceholder.value == 'text' && (
        <div className="flex-auto">
          <input
            type="text"
            value={
              textValue ? textValue : placeholder.value ? placeholder.value : ''
            }
            onChange={({ target: { value } }) => {
              updatePlaceholder('value', value);
              setTextValue(value);
            }}
            className={`px-3 ${classInput}`}
          />
        </div>
      )}
      {typePlaceholder.value == 'numeric' && (
        <div className="lg:gap:2 flex flex-col gap-2 sm:flex-row sm:items-center">
          <div className="flex flex-row gap-2 lg:gap-3">
            <div className="flex flex-row items-center gap-2">
              {t('widgets.edit.fill_blank.min')}
              <input
                type="number"
                value={
                  minValue ? minValue : placeholder.min ? placeholder.min : ''
                }
                onChange={({ target: { value } }) => {
                  updatePlaceholder('min', value);
                  setMinValue(value);
                }}
                className={`max-w-[63px] px-3 ${classInput}`}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              {t('widgets.edit.fill_blank.max')}
              <input
                type="number"
                value={
                  maxValue ? maxValue : placeholder.max ? placeholder.max : ''
                }
                onChange={({ target: { value } }) => {
                  updatePlaceholder('max', value);
                  setMaxValue(value);
                }}
                className={` max-w-[63px] px-3 ${classInput}`}
              />
            </div>
          </div>

          <div className="w-fill flex flex-row items-center gap-2 whitespace-pre">
            {t('widgets.edit.fill_blank.prec')}
            <div className="w-12">
              <input
                type="number"
                value={
                  precValue
                    ? precValue
                    : placeholder.prec
                    ? placeholder.prec
                    : ''
                }
                onChange={({ target: { value } }) => {
                  updatePlaceholder('prec', value);
                  setPrecValue(value);
                }}
                className={`px-3 ${classInput}`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const initialInvulVraag = () => ({
  score: 1,
  vraag: '',
  antwoord: '',
  showAnswer: false,
  placeholders: {},
  type: 'InvulVraag',
});

export const InvulVraagEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );
  // const [questionRef, setQuestionRef] = useState(null);
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );
  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.fill_blank')
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : false
  );
  const [score, setScore] = useState(state.score || 0);
  const [placeholders, setPlaceholders] = useState(state.placeholders);

  const [firstRenderDone, setFirstRenderDone] = useState(false);
  const [competencies, setCompetencies] = useState([]);

  const editorRef = useRef(null);

  let scrollPosition = 0;

  // generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.placeholders = placeholders;
    newState.score = score;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  const addPlaceholder = (uid) => {
    const newPlaceholders = { uid, type: null };
    setPlaceholders((prev) => ({ ...prev, [uid]: newPlaceholders }));
  };

  const restoreSelection = (range) => {
    if (range) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleMouseEnter = () => {
    scrollPosition = window.scrollY;
  };

  const handleDeletePlaceholder = (uid) => {
    let node = window.$(`#${CSS.escape(state.uid)} #${CSS.escape(uid)}`)[0];
    if (node) {
      const range = document.createRange();
      range.setStart(node, 0);
      range.setEnd(node, 0);
      node.remove();
      const delPlaceholders = placeholders;
      delete delPlaceholders[uid];
      setPlaceholders(() => ({ ...delPlaceholders }));
      restoreSelection(range);
    }

    window.scrollTo({ top: scrollPosition });
  };

  const update = () => {
    let index = 0;
    for (let placeholder of Object.values(placeholders)) {
      let node = window.$(
        `#${CSS.escape(state.uid)} #${CSS.escape(placeholder.uid)}`
      )[0];
      window
        .$(`#${CSS.escape(state.uid)} #${CSS.escape(placeholder.uid)}`)
        .attr('contenteditable', 'false');

      if (node) {
        ReactDOM.createRoot(node).render(
          <span
            className="item-edit-placeholder mb-2 inline-block rounded-md bg-grey-01 px-1 py-0.5"
            style={{
              display: 'none',
            }}
          >
            <span className="flex flex-row items-center gap-1">
              <span
                style={{
                  backgroundColor: `${pastels[index % pastels.length]}`,
                }}
                className="h-6 w-6 rounded-md py-1 px-1.5 text-center text-xs font-semibold text-white"
              >
                {getCharForNumber(index)}
              </span>
              <Button
                size="md"
                className="!px-1.5"
                onMouseDown={handleMouseEnter}
                onClick={() => handleDeletePlaceholder(placeholder.uid)}
                variant="ghost"
              >
                <img
                  src="/images/icons/trash_icon_red.svg"
                  alt="delete button"
                />
              </Button>
            </span>
          </span>
        );
      }
      ++index;
    }
  };

  useEffect(() => {
    if (firstRenderDone) {
      placeholders && update();
    } else {
      setTimeout(() => {
        setFirstRenderDone(true);
      }, '500');
    }
  }, [firstRenderDone, placeholders]);

  useEffect(() => {
    if (firstRenderDone) {
      for (let placeholder of Object.values(placeholders)) {
        let node = window.$(
          `#${CSS.escape(state.uid)} #${CSS.escape(placeholder.uid)}`
        );
        if (node) {
          if (node.length < 1) {
            const delPlaceholders = placeholders;
            delete delPlaceholders[placeholder.uid];
            setPlaceholders(() => ({ ...delPlaceholders }));
          }
        }
      }
    }
  }, [modified]);

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />
      <div className="">
        {/* <span className="text-lg font-semibold text-grey-04">
          {t('widgets.widgets_edit.question')} (
          {t('widgets.widgets_edit.optional')})
        </span> */}
        <div ref={editorRef} className="invul_editor">
          <div className="wg-question">
            <QuestionSection
              questionVraag={questionVraag}
              invulVraag={true}
              placeholders={placeholders}
              setPlaceholders={setPlaceholders}
              addPlaceholder={addPlaceholder}
            />
            {/* <FroalaTextarea
              setRef={(_ref) => {
                setQuestionRef(_ref);
              }}
              value={state.vraag}
              onModified={(ev) => {
                setModified(newUid(20));
              }}
              modus="InvulVraag"
              onPlaceholder={(uid) => {
                addPlaceholder(uid);
              }}
              subeditor="true"
            /> */}
          </div>
        </div>

        <div className="my-4 flex gap-3">
          <Switch
            checked={showAnswer}
            onChange={(isCheck) => setShowAnswer(isCheck)}
          />
          <div className="mr-4">
            {t('widgets.widgets_edit.explain_after_submit')}
          </div>
        </div>
        <div className={`${!showAnswer ? 'hidden' : ''}`}>
          {/* <span className="text-lg font-semibold text-grey-04">
            {t('widgets.widgets_edit.example_answer')} (
            {t('widgets.widgets_edit.optional')})
          </span> */}
          <div className="wg-answer">
            <QuestionSection
              questionVraag={antwoordVraag}
              exampleAnswer={true}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5 lg:gap-3">
        {placeholders &&
          Object.keys(placeholders).map((key, index) => {
            const placeholder = placeholders[key];
            return (
              <ItemAnswer
                key={key + '_' + index}
                placeholder={placeholder}
                index={index}
                setPlaceholders={setPlaceholders}
                placeholders={placeholders}
              />
            );
          })}
      </div>

      <div>
        <div className="mt-4 flex flex-row items-center border-t border-b border-grey-02 py-2">
          <div className="mr-2">{t('widgets.widgets_edit.max_score')}</div>
          <InputScore value={score} onChange={(value) => setScore(value)} />
        </div>
        {/*
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>

      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { useContext, useState, useRef, useEffect } from 'react';
import { IconButton, Tag, Button } from '@teo/components';
import { CrossFilled, DuoToneCourseFilled } from '@teo/components/icons';
import { axios } from '@/axios';
import { config } from '@/config';
import { AuthContext } from '@/components/Contexts/AuthContext';
import CompetencyProfilesModal from './CompetencyProfilesModal';
import { parseTypeCourse, useMobile } from '@/util';
import Hint from '../Hints/Hints';
import HintOverlay from '../Hints/HintOverlay';
import { HintsContext } from '@/components/Contexts/HintsContext';

let templates: any;
templates = {
  en: {
    theory: 3605,
    practice: 3606,
    instruction: 4958,
    scorm: 5267,
  },
  fr: {
    theory: 3609,
    practice: 3610,
    instruction: 4959,
    scorm: 5269,
  },
  nl: {
    theory: 3607,
    practice: 3608,
    instruction: 4957,
    scorm: 5268,
  },
  es: {
    theory: 3955,
    practice: 3608,
    instruction: 4960,
    scorm: 5270,
  },
};

if (window.location.hostname === 'teo.training') {
  templates = {
    en: {
      theory: 6741,
      practice: 6742,
      instruction: 9398,
      scorm: 9919,
    },
    fr: {
      theory: 6743,
      practice: 6744,
      instruction: 9399,
      scorm: 9920,
    },
    nl: {
      theory: 6739,
      practice: 6740,
      instruction: 9397,
      scorm: 9918,
    },
    es: {
      theory: 9395,
      practice: 9396,
      instruction: 9400,
      scorm: 9921,
    },
  };
}

const TypeCourse = ({
  setIsOpenTypeModal,
  setTemplate,
  template,
  typeCourse,
  setGoToCreate,
  setPublicationId,
  publicationId,
  setLoadeNewPublication,
}: any) => {
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const { auth } = useContext(AuthContext);
  const authLang: any = auth?.user.lang;
  const authOrg: any = auth?.user?.activeOrganisationId;
  const [loaded, setLoaded] = useState(false);
  const [isOpenCompetency, setIsOpenCompetency] = useState(false);
  const [selectCompetency, setSelectCompetency] = useState<any[]>([]);

  const isMobile = useMobile();
  const { hints, setHints } = useContext(HintsContext);

  const handleStart = async () => {
    setLoadeNewPublication && setLoadeNewPublication(true);
    const id = templates?.[authLang]?.[template];
    const newId = await axios.post(`${config.backend}/publish/copy/${id}`);
    const publicationId = newId?.data?.id;
    setPublicationId(newId?.data?.id);

    await Promise.allSettled([
      axios.post(`${config.backend}/membercoursecontents`, {
        role: 90,
        coursecontentId: publicationId,
        organisationId: authOrg,
      }),
      axios.put(`${config.backend}/coursecontent/${publicationId}`, {
        authorId: auth?.user?.id,
        publicationStatus: 'concept',
        publicationCourseType: parseTypeCourse(typeCourse),
      }),
      selectCompetency?.map((el: any, index: any) => addCompetency(el, index)),
    ]).then(() => {
      setGoToCreate(true);
    });
  };

  const addCompetency = async (el: any, index: any) => {
    const data = await axios.post(
      `${config.backend}/coursecontentprofessions`,
      {
        coursecontentId: publicationId,
        professionId: el?.key,
      }
    );
    if (selectCompetency?.length - 1 === index) {
      data?.data?.professionId && setLoaded(true);
    }
  };

  useEffect(() => {
    if (typeCourse === 'instructions') {
      setTemplate('instruction');
    }
  }, [typeCourse]);

  useEffect(() => {
    if (typeCourse === 'instructions' && template === 'instruction') {
      handleStart();
      // console.log(template);
      // console.log('typeCourse1', typeCourse);
      // console.log('typeCourse', parseTypeCourse(typeCourse));
    }
  }, [typeCourse, template]);

  if (typeCourse === 'instructions') return null;

  return (
    <div
      ref={contentRef}
      className="fixed top-0 left-0 z-[100] flex h-screen w-screen flex-col overflow-y-auto bg-white"
    >
      <div className="flex min-h-16 flex-col justify-start border-b border-grey-02 py-2 pr-2 sm:flex-row sm:items-center">
        <div
          data-testid="clouseBtn"
          className="flex min-h-16 items-center justify-start"
        >
          <IconButton
            className="my-auto mx-3 sm:mx-6"
            Icon={CrossFilled}
            variant="ghost"
            onClick={() => {
              setIsOpenTypeModal(false);
            }}
          />
          <div className="flex items-center">
            <div className="mr-8">
              <DuoToneCourseFilled className="w-6" />
            </div>
            <p className="mr-8 font-medium">
              {t(`pages.create_publication.name_${typeCourse}`)}
            </p>
            <Tag title={t('pages.create_publication.draft')} size="md" />
          </div>
        </div>
      </div>
      <div className="m-auto flex max-w-xl flex-col gap-8 p-3 py-5 md:gap-14">
        <h3 className="text-xl font-normal">
          {t(`pages.create_publication.title_maodal_${typeCourse}`)}
        </h3>
        <div
          className={`relative flex flex-wrap sm:flex-nowrap ${
            hints?.id == `hint_30` && 'active-hint-parent !z-[100]'
          }`}
        >
          <Hint
            key={`hint_30`}
            id={'hint_30'}
            leftDots={'-left-2 -top-3'}
            title={t('hint.title_30')}
            order={30}
            overlaySecond={true}
            style={{
              left: '-10px',
              bottom: 'calc(100% + 20px)',
            }}
          />
          <div
            className={`mr-5 flex-1 cursor-pointer rounded-lg  border border-grey-02 p-5 sm:basis-1/2 ${
              template == 'theory' && 'border-secondary-04'
            }`}
            onClick={() => setTemplate('theory')}
            data-testid="theory"
          >
            <h4 className="mb-3 text-base">
              {t(`pages.create_publication.type_theory_${typeCourse}`)}
            </h4>
            <p className="text-sm text-grey-07">
              {t(`pages.create_publication.theory_description_${typeCourse}`)}
            </p>
          </div>
          <div
            className={`mr-5 flex-1 cursor-pointer rounded-lg border border-grey-02 p-5 sm:basis-1/2 ${
              template == 'practice' && 'border-secondary-04'
            }`}
            onClick={() => setTemplate('practice')}
            data-testid="practice"
          >
            <h4 className="mb-3 text-base">
              {t(`pages.create_publication.type_practical_${typeCourse}`)}
            </h4>
            <p className="text-sm text-grey-07">
              {t(
                `pages.create_publication.practical_description_${typeCourse}`
              )}
            </p>
          </div>
        </div>
        <div className="flex">
          <Button
            type="button"
            variant="outline"
            className={`relative mr-auto ${
              hints?.id == `hint_31` &&
              'active-hint-create-page active-hint-parent active-hint-main-menu !z-[100]'
            }`}
            onClick={() => setIsOpenCompetency(true)}
          >
            <Hint
              key={`hint_31`}
              id={'hint_31'}
              leftDots={'-left-2'}
              title={t('hint.title_31')}
              order={31}
              overlaySecond={true}
              style={{
                left: '-5px',
                bottom: 'calc(100% + 15px)',
              }}
            />
            {t('pages.create_publication.btn_competency_profiles')}
          </Button>
          <Button type="button" onClick={handleStart}>
            {t('pages.create_publication.btn_start_editing')}
          </Button>
        </div>
      </div>
      {isOpenCompetency && (
        <CompetencyProfilesModal
          setIsOpenCompetency={setIsOpenCompetency}
          setSelectCompetency={setSelectCompetency}
          selectCompetency={selectCompetency}
        />
      )}

      {hints?.active === 2 && !isMobile && (
        <HintOverlay setHints={setHints} hints={hints} block={contentRef} />
      )}
    </div>
  );
};

export default TypeCourse;

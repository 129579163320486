import React, { useState, useContext, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Button,
  InputDate,
  InputSelect,
  InputText,
  Label,
  PersonAvatar,
} from '@teo/components';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { resetPassword } from '@/query/auth';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { ErrorBox } from '@/components/ErrorBox';
import { uploadImage } from '@/query/documents';
import { put } from '@/query';
import { changeLanguage } from '@/language';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { axios } from '@/axios';
import { config } from '@/config';

type InputSelectOption = {
  id: number;
  label: string;
  value: string;
};

const options: InputSelectOption[] = [
  {
    id: 1,
    label: 'langs.nl',
    value: 'nl',
  },
  {
    id: 2,
    label: 'langs.en',
    value: 'en',
  },
  {
    id: 3,
    label: 'langs.fr',
    value: 'fr',
  },
  {
    id: 3,
    label: 'langs.es',
    value: 'es',
  },
];

function AccountPage() {
  const { t } = useTranslation();

  const { auth, setAuth } = useContext(AuthContext);
  const userData = auth?.user as Userwithorganisationwithidps;

  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [firstName, setFirstname] = useState<string | null | undefined>(
    userData?.firstName
  );
  const [lastName, setLastname] = useState(userData?.lastName);
  const [email, setEmail] = useState(userData?.email);
  const [phone, setPhone] = useState(userData?.phone);
  const [lang, setLang] = useState(
    options.find((option) => option.value === userData?.lang)
  );
  const [birthDate, setBirthDate] = useState<any | null>(userData?.birthDate);

  const [file, setFile] = useState('');
  const [errorText, setErrorText] = useState('');
  const [userInfo, seUserInfo] = useState<any>(null);
  const { notification, setShowNotification } = useContext(NotificationContext);

  useEffect(() => {
    axios.get<any>(`${config.backend}/auth/token`).then((response) => {
      seUserInfo(response.data);
      setAuth(response.data);
    });
  }, []);

  useEffect(() => {
    if (userInfo) {
      const userData = userInfo?.user;
      setFirstname(userData?.firstName);
      setLastname(userData?.lastName);
      setEmail(userData?.email);
      setPhone(userData?.phone);
      setLang(options.find((option) => option.value === userData?.lang));
      setBirthDate(userData?.birthDate);
    }
  }, [userInfo]);

  const handleUploadPhoto = async (event: any): Promise<any> => {
    const img = event.target.files[0];
    const data = await uploadImage(img, 40, 40, 'jpeg');
    put(`/user/${userData?.id}`, { picture: data });
    setFile(data);
  };

  const handleResetPassword = () => {
    resetPassword(email)
      .then(() => {
        navigate('/auth/resetPasswordSuccess');
      })
      .catch((e: AxiosError<{ message: string }>) => {
        const message = e?.response?.data?.message || e.toString();
        switch (message) {
          case 'E-mail not found':
            setErrorText(t('error.emailNotFound'));
            break;
          default:
            setErrorText(message);
            break;
        }
        console.error('Error', e);
        console.error('redirecting');
      });
  };

  return (
    <div className="h-full  md:flex">
      <div className="flex h-full w-full md:items-center" id="userForm">
        <div className="flex w-full max-w-[440px] flex-col max-md:h-full">
          <div className="flex items-center pb-4">
            <PersonAvatar
              id={userData?.id}
              name={`${userData?.username as string}`}
              imageSrc={file ? `${file}` : `${userData?.picture}`}
            />
            <div className="pl-4">
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  fileInputRef.current?.click();
                  setShowNotification(
                    t('pages.profile_account.info_was_saved')
                  );
                }}
                disabled={userData?.idp !== 'local'}
              >
                {t('pages.profile_account.upload_photo_button')}
              </Button>

              <input
                type="file"
                ref={fileInputRef}
                onChange={handleUploadPhoto}
                accept="image/jpg,.png,"
                style={{ display: 'none' }}
                multiple={false}
              />
            </div>
          </div>

          <div className="mb-8 space-y-2 md:space-y-4">
            <div>
              <Label htmlFor="firstName">
                {t('pages.profile_account.firstName_label')}
              </Label>
              <InputText
                id="firstName"
                disabled={userData?.idp !== 'local'}
                title={`${t('mannaged_by')} ${
                  userData?.idp && t(userData?.idp)
                }`}
                onChange={(event) => {
                  setFirstname(event.target.value);
                  setShowNotification(
                    t('pages.profile_account.info_was_saved')
                  );
                }}
                onBlur={() => {
                  put(`/user/${userData?.id}`, {
                    firstName: firstName,
                    username: firstName + ' ' + lastName,
                  });
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    put(`/user/${userData?.id}`, {
                      firstName: firstName,
                      username: firstName + ' ' + lastName,
                    });
                  }
                }}
                placeholder={
                  userData?.idp !== 'local' ? (firstName as string) : ''
                }
                value={userData?.idp !== 'local' ? '' : (firstName as string)}
                required
              />
            </div>

            <div>
              <Label htmlFor="lastName">
                {t('pages.profile_account.lastname_label')}
              </Label>
              <InputText
                id="lastName"
                disabled={userData?.idp !== 'local'}
                title={`${t('mannaged_by')} ${
                  userData?.idp && t(userData?.idp)
                }`}
                onChange={(event) => {
                  setLastname(event.target.value);
                  setShowNotification(
                    t('pages.profile_account.info_was_saved')
                  );
                }}
                onBlur={() => {
                  put(`/user/${userData?.id}`, {
                    lastName: lastName,
                    username: firstName + ' ' + lastName,
                  });
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    put(`/user/${userData?.id}`, {
                      lastName: lastName,
                      username: firstName + ' ' + lastName,
                    });
                  }
                }}
                placeholder={
                  userData?.idp !== 'local' ? (lastName as string) : ''
                }
                value={userData?.idp !== 'local' ? '' : (lastName as string)}
                required
              />
            </div>

            {userData?.personAttributes && (
              <div>
                <Label htmlFor="birthDate">
                  {t('pages.profile_account.date_of_birth_label')}
                </Label>
                <InputDate
                  id="birthDate"
                  onChange={(date) => {
                    setBirthDate(date);
                    setShowNotification(
                      t('pages.profile_account.info_was_saved')
                    );
                    put(`/user/${userData?.id}`, { birthDate: date || null });
                  }}
                  value={birthDate ? new Date(birthDate as Date) : undefined}
                />
              </div>
            )}
            <div>
              <Label htmlFor="language">
                {t('pages.profile_account.language')}
              </Label>
              <div id="language">
                <InputSelect<InputSelectOption>
                  onChange={async (language) => {
                    setLang(language);
                    setAuth({
                      user: Object.assign({}, auth?.user, {
                        lang: language.value,
                      }),
                      token: auth?.token,
                    });
                    setShowNotification(
                      t('pages.profile_account.info_was_saved')
                    );
                    await put(`/user/${userData?.id}`, {
                      lang: language.value,
                    });
                    changeLanguage(language.value);
                  }}
                  value={
                    lang
                      ? lang
                      : options.find(
                          (option) => option?.value === userData?.lang
                        )
                  }
                  options={options}
                >
                  {(options) => t(options.label)}
                </InputSelect>
              </div>
            </div>

            <div>
              <Label htmlFor="phone_number">
                {t('pages.profile_account.phone_number_label')}
              </Label>

              <InputText
                id="phone_number"
                onChange={(event) => {
                  setPhone(event.target.value);
                  setShowNotification(
                    t('pages.profile_account.info_was_saved')
                  );
                }}
                onBlur={() => {
                  put(`/user/${userData?.id}`, { phone: phone });
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    put(`/user/${userData?.id}`, { phone: phone });
                  }
                }}
                value={
                  phone
                    ? phone
                    : t('pages.profile_account.phone_number_placeholder')
                }
                required
              />
            </div>

            <div>
              <Label htmlFor="email">
                {t('pages.profile_account.email_label')}
              </Label>
              <InputText
                id="email"
                disabled={userData?.idp !== 'local'}
                title={`${t('pages.profile_account.mannaged_by')} ${
                  userData?.idp && t(userData?.idp)
                }`}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setShowNotification(
                    t('pages.profile_account.info_was_saved')
                  );
                }}
                onBlur={() => {
                  //TODO: validate email
                  //TODO: send email verification
                  //TODO: error management (e-mail already exists)
                  email && put(`/user/${userData?.id}`, { email: email });
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    email && put(`/user/${userData?.id}`, { email: email });
                  }
                }}
                placeholder={`${userData?.email}`}
                required
              />
            </div>
          </div>

          <div className="border-t border-grey-03">
            <h2 className="pt-8 pb-2 font-medium">
              {t('pages.profile_account.reset_password_title')}
            </h2>

            {errorText && (
              <div className="mb-4">
                <ErrorBox text={errorText} />
              </div>
            )}
            <Button
              type="button"
              variant="outline"
              size="md"
              title={
                userData?.idp !== 'local'
                  ? `${t('pages.profile_account.mannaged_by')} ${
                      userData?.idp && t(userData?.idp)
                    }`
                  : undefined
              }
              disabled={userData?.idp !== 'local'}
              onClick={handleResetPassword}
            >
              {t('pages.profile_account.reset_password_button')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPage;

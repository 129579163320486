import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSelect, Label, Button, ErrorMessage } from '@teo/components';
import {
  diplomaConstants,
  intrestsConstants,
} from '../../../../constants/option.constants';

type InputSelectOption = {
  label: string;
  value: string;
};

const Step5 = ({ step, setStep, setInfo, userInfo, courseInfo }: any) => {
  const { t } = useTranslation();
  const [checkValid, setCheckValid] = useState<boolean>(false);

  const [degree, setDegree] = useState<InputSelectOption>();
  const [interest, setInterest] = useState<InputSelectOption>();

  useEffect(() => {
    setDegree(
      userInfo?.diploma && {
        label: userInfo?.diploma?.toUpperCase(),
        value: userInfo?.diploma,
      }
    );
    setInterest(
      userInfo?.intrest && {
        label: userInfo?.intrest,
        value: userInfo?.intrest,
      }
    );
  }, [userInfo]);

  const nextButton = () => {
    const prevStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_current_work_step'
    )
      ? 1
      : 2;
    setCheckValid(true);
    if (degree && interest) {
      setInfo((prev: any) => ({
        ...prev,
        degree: degree.value,
        interest: interest.value,
      }));
      setStep((prev: number) => prev + prevStep);
    }
  };
  const prevButton = () => {
    const nextStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_referral_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_regime_step')
      ? 2
      : courseInfo?.onboardingSteps?.includes('onboarding_location_step')
      ? 3
      : 4;
    setStep((prev: number) => prev - nextStep);
  };

  const handleError = (el: any) => (el ? false : checkValid ? true : false);

  if (step !== 5) {
    return null;
  }
  return (
    <>
      <div>
        <p>{t(`pages.onboarding.step_5.subtitle`)}</p>
        <div className="mb-6 mt-6 max-w-xs text-sm font-semibold text-black">
          <Label htmlFor="degree">{t(`pages.onboarding.step_5.DIPLOMA`)}</Label>
          <div id="degree" data-testid="degree">
            <InputSelect<InputSelectOption>
              value={degree}
              onChange={(e: InputSelectOption) => {
                setDegree(e);
              }}
              options={diplomaConstants}
            >
              {(options) => (
                <span>{t(`pages.onboarding.step_5.${options.label}`)}</span>
              )}
            </InputSelect>
            {handleError(degree) && (
              <ErrorMessage id="errorMessage">
                {t('pages.onboarding.error.select_one')}
              </ErrorMessage>
            )}
          </div>
        </div>
        <p>{t(`pages.onboarding.step_5.subtitle_2`)}</p>
        <div className="mb-1 mt-6 max-w-xs text-sm font-semibold text-black">
          <Label htmlFor="interest">
            {t(`pages.onboarding.step_5.INTRESTS`)}
          </Label>
          <div id="interest" data-testid="interest">
            <InputSelect<InputSelectOption>
              value={interest}
              onChange={(e: InputSelectOption) => {
                setInterest(e);
              }}
              options={intrestsConstants}
            >
              {(options) => (
                <span>{t(`pages.onboarding.step_5.${options.label}`)}</span>
              )}
            </InputSelect>
            {handleError(interest) && (
              <ErrorMessage id="errorMessage">
                {t('pages.onboarding.error.select_one')}
              </ErrorMessage>
            )}
          </div>
        </div>
      </div>
      <div className="flex w-full pt-10">
        <Button
          variant="outline"
          className={`mr-2 flex-[1_1_30%] ${step === 1 && 'hidden'}`}
          onClick={prevButton}
        >
          {t(`pages.onboarding.button_prev`)}
        </Button>

        <Button
          className={`${step !== 1 && 'ml-2 flex-[1_1_30%]'}`}
          onClick={() => step < 7 && nextButton()}
        >
          {t(`pages.onboarding.button_next`)}
        </Button>
      </div>
    </>
  );
};
export default Step5;

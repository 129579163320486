import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';
import { axios } from './axios';
import { Deferred } from './util';

let storePromise = new Deferred();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

function generateReducer() {
  const appReducer = combineReducers(reducers);
  const rootReducer = (state, action) => {
    switch (action.type) {
      case 'USERS_LOGOUT':
        window.localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        state = undefined;
      /*
        setTimeout(() => {
          if (localStorage.getItem('koffer')) store.dispatch(boxActions.loginData());
          history.push('/login');
        })
        */
    }
    //console.log("action", action)
    return appReducer(state, action);
  };
  return rootReducer;
}

let store = createStore(generateReducer(), enhancer);
store.injectReducer = (key, reducer) => {
  reducers[key] = reducer;
  store.replaceReducer(generateReducer());
};

store.hasReducer = (key) => {
  return !!reducers[key];
};

storePromise.resolve(store);

export { store, storePromise };

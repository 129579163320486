import { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Button } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { findElementsByKey } from '@/util';
import { fileFormats } from '@/constants/fileFormats';

export const TargetBoxCoures = ({
  onDrop,
  fileInputRef,
  onChange,
  setIsOpenPhotoModal = () => {},
  isOpenPhotoModal = false,
  setIsOpenVideoModal = () => {},
  isOpenVideoModal = false,
  state,
  multiple = false,
}) => {
  const { t } = useTranslation();
  const [photoBtn, setPhotoBtn] = useState(false);
  const [videoBtn, setVideoBtn] = useState(false);
  const [accept, setAccept] = useState('*');

  useEffect(() => {
    if (state) {
      setPhotoBtn(
        findElementsByKey(state?.supportedFormats, 'label').includes('Image')
      );
      setVideoBtn(
        findElementsByKey(state?.supportedFormats, 'label').includes('Video')
      );
      let formats = state?.supportedFormats
        .map((x) => fileFormats.find((y) => y.value === x.value))
        .map((x) => x.filter)
        .join(',');
      setAccept(formats);
    }
  }, [state]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item) {
        // console.log('canDrop', item.files, item.items);
        return true;
      },
      hover(item) {
        // console.log('hover', item.files, item.items);
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        if (item) {
          // console.log('collect', item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onDrop]
  );

  const handleCameraModal = () => {
    setIsOpenPhotoModal(!isOpenPhotoModal);
  };

  const isActive = canDrop && isOver;
  return (
    <div
      ref={drop}
      className="
                flex
                flex-col
                items-center
                rounded-lg
                border
                border-dashed
                border-[#eaeaed]
                px-3 py-8
            "
    >
      <div className="flex flex-wrap justify-center gap-3">
        <Button
          variant="primary"
          size="md"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          {t('widgets.upload_buttons.upload')}
        </Button>

        {photoBtn && (
          <Button
            variant="primary"
            size="md"
            onClick={() => setIsOpenPhotoModal(!isOpenPhotoModal)}
          >
            {t('widgets.upload_buttons.take_picture')}
          </Button>
        )}

        {videoBtn && (
          <Button
            variant="primary"
            size="md"
            onClick={() => setIsOpenVideoModal(!isOpenVideoModal)}
          >
            {t('widgets.upload_buttons.record_video')}
          </Button>
        )}

        {/*<pre>{JSON.stringify(state?.supportedFormats, null, 4)}</pre>*/}

        <input
          type="file"
          ref={fileInputRef}
          onChange={onChange}
          accept={accept}
          style={{ display: 'none' }}
          multiple={multiple}
        />
      </div>

      {isActive ? (
        <div className="mt-6 text-sm font-medium text-[#6d6d72]">
          {t('pages.upload_dropzone_section.drag_text_2')}
        </div>
      ) : (
        <div className="mt-6 text-sm font-medium text-[#6d6d72]">
          {t('pages.upload_dropzone_section.drag_text_1')}
        </div>
      )}
    </div>
  );
};

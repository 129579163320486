import { useState, useEffect, useRef, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import {
  Usedlessondetails,
  Directories,
  Lessonstatusdetails,
} from '@/models/Database';
import {
  findElementsByKey,
  convertToTime,
  buildSortedTree,
  useTraceUpdate,
  firstOfDfs,
  findOnjectByKey,
  walk,
  round2,
} from '@/util';
import { useTranslation } from 'react-i18next';
import {
  TaskCheckmarkFilled,
  ChevronUp,
  ChevronDown,
  Check,
  ArrowRight,
  PlayFilled,
  Image,
  Play,
} from '@teo/components/icons';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { Blocked } from '@/components/Images/Blocked';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';

export const TreeItem = ({
  node,
  indent = 0,
  index,
  currentLessonId,
  parentIndex = 0,
  courseOrder,
  rowPath,
  setHideContent,
  activeLessonNode,
  prevNode,
  parentStatus,
  limitedLes,
  setLimitedLes,
  isUserFollow,
  hideScore,
  status,
  isInstruction,
  setIsLessonLoaded,
}: {
  node: any;
  indent: number;
  index: number;
  currentLessonId: number;
  parentIndex?: number;
  courseOrder: boolean;
  rowPath: any;
  setHideContent?: any;
  activeLessonNode?: any;
  prevNode: any;
  parentStatus?: any;
  limitedLes?: any;
  setLimitedLes?: any;
  isUserFollow?: any;
  hideScore?: boolean;
  status?: any;
  isInstruction?: boolean;
  setIsLessonLoaded?: any;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeLesson, setActiveLesson] = useState<any>();
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 640px)' });
  const { lessonId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const prevPage = queryParams.get('details');

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  let lesStatus: any;
  let allStatus: any;
  let lessonsCourse: any;
  let lessonsAssetsCourse: any;
  let prevLesStatus: any;
  let allPrevStatus: any;
  let prevLessonsCourse: any;
  let timeCourse: any;
  let scoreCourse: any;
  let scoredLes: any;

  if (!prevNode?.lessonId) {
    prevLessonsCourse = findElementsByKey(prevNode?.children, 'lessonId');
    allPrevStatus = findElementsByKey(prevNode?.children, 'status');
  }

  if (!node?.lessonId) {
    lessonsAssetsCourse = findElementsByKey(node?.children, 'lessonId');
    lessonsCourse = findElementsByKey(node?.children, 'lessonId')?.filter(
      (el: any) => el
    );
    allStatus = findElementsByKey(node?.children, 'status').filter(
      (el) => el === 'submitted' || el === 'corrected' || el === 'completed'
    );

    timeCourse = findElementsByKey(node?.children, 'estimatedTime').reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const maxScore = findElementsByKey(node?.children, 'maxScore').reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const score = findElementsByKey(node?.children, 'score').reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    if (maxScore > 0) {
      scoreCourse = (score * 100) / maxScore;
    }
    scoredLes = Math.round(
      (allStatus?.length * 100) / lessonsAssetsCourse?.length
    );
  } else {
    if (!hideScore && status?.status === 'corrected' && status?.maxScore) {
      scoreCourse = ((status?.score || 0) * 100) / (status?.maxScore || 1);
    }
  }

  if (node?.estimatedTime) {
    timeCourse = node?.estimatedTime;
  }

  if (node?.lessonId || node?.assetId) {
    lesStatus =
      node?.status === 'submitted' ||
      node?.status === 'corrected' ||
      node?.status === 'completed' ||
      (node?.status === 'started' && node?.onlyTheory && node?.lessonId);
  } else {
    lesStatus = lessonsAssetsCourse?.length === allStatus?.length;
  }

  if (prevNode) {
    if (prevNode?.lessonId) {
      prevLesStatus =
        prevNode?.status === 'submitted' ||
        prevNode?.status === 'corrected' ||
        prevNode?.status === 'completed' ||
        (prevNode?.status === 'started' && prevNode?.onlyTheory);
    } else {
      prevLesStatus = prevLessonsCourse?.length === allPrevStatus?.length;
    }
  } else {
    prevLesStatus = false;
  }

  const lesBlock = courseOrder && !node?.unlocked;

  let disabledTopLesson = false;
  let disabledSubLesson = false;

  if (node?.lessonId && lesBlock) {
    disabledTopLesson = true;
  }

  if (node?.lessonId && lesBlock) {
    disabledSubLesson = true;
  }

  useEffect(() => {
    if (lessonId && node?.children) {
      setActiveLesson(
        findElementsByKey(node?.children, 'lessonId').includes(
          parseInt(lessonId, 10)
        ) ||
          findElementsByKey(node?.children, 'assetId').includes(
            parseInt(lessonId, 10)
          )
      );
    }
    if (activeLessonNode?.id == node?.id) {
      setLimitedLes(
        activeLessonNode?.timed === 'limited' &&
          node?.status !== 'submitted' &&
          node?.status !== 'corrected' &&
          node?.status !== 'completed'
      );
    }
  }, [lessonId, activeLessonNode]);

  useEffect(() => {
    if (activeLesson) {
      setIsOpen(true);
    }
  }, [activeLesson]);

  const openLessonsInCourse = (node: any) => {
    node?.lessonId
      ? goRouteId(node?.lessonId)
      : node?.assetId
      ? goRouteId(node?.assetId)
      : setIsOpen(!isOpen);
  };
  const spacing = isDesktopOrLaptop ? 53 : 40;
  const navigate = useNavigate();
  const goRouteId = (les: any) => {
    if (!(lessonId == node?.lessonId || lessonId == node?.assetId)) {
      setIsLessonLoaded && setIsLessonLoaded(true);
    }
    const searchString = lessonId ? 'lesson/' + lessonId : 'lesson';
    const replacementString = 'lesson/' + les;
    const modifiedString = rowPath.replace(searchString, replacementString);
    const prev = prevPage ? '&details=' + prevPage : '';
    //navigate(modifiedString + prev, { replace: true });
    let search = window.location.search + prev;
    if (search[0] === '&') search = '?' + search.substring(1);
    navigate({
      pathname: modifiedString,
      search: search,
    });

    lessonId && setHideContent(false);
  };

  return (
    <div>
      <div className="my-1 flex items-center justify-between">
        <div
          className="flex min-h-[40px] w-full items-center "
          style={{ paddingLeft: `${indent * spacing}px` }}
        >
          {(!node?.lessonId && !node?.assetId) || indent === 0 ? (
            <div
              className={`flex w-full cursor-pointer items-center rounded-md p-1 sm:p-2  
              ${
                (node?.lessonId || node?.assetId) &&
                (disabledTopLesson || !isUserFollow) &&
                'pointer-events-none'
              }
              ${limitedLes && '!cursor-not-allowed opacity-60'}
              ${
                activeLessonNode?.usesId === node?.usesId &&
                isUserFollow &&
                (node?.lessonId || node?.assetId)
                  ? '!cursor-pointer bg-primary-03 !opacity-100'
                  : 'sm:hover:bg-grey-transparent-01'
              }`}
              onClick={() => {
                return !limitedLes && openLessonsInCourse(node);
              }}
              data-testid="chapter"
            >
              {courseOrder ? (
                <>
                  {lesStatus && !isInstruction ? (
                    <>
                      <TaskCheckmarkFilled className="m-2 ml-1 mr-3 w-6 text-success-05 sm:ml-2 sm:mr-5" />
                    </>
                  ) : node?.lessonId && !isInstruction ? (
                    !lesBlock ? (
                      <div className="relative mr-2 flex h-8 min-h-8 w-8 min-w-8 rounded-full border-2 border-[#1e1e2014] pl-0.5 text-center sm:mr-4 sm:h-9 sm:min-h-9 sm:w-9 sm:min-w-9">
                        <PlayFilled className="m-auto w-4 text-secondary-04" />
                      </div>
                    ) : (
                      <Blocked className="m-2 mr-4 ml-2 w-4 text-grey-05 sm:ml-3 sm:mr-6" />
                    )
                  ) : allStatus?.length > 0 && !hideScore && !isInstruction ? (
                    <div
                      className="relative mr-2 h-8 min-h-8 w-8 min-w-8 rounded-full text-center sm:mr-4 sm:h-9 sm:min-h-9 sm:w-9 sm:min-w-9"
                      style={{
                        background: `radial-gradient(closest-side, white 85%, transparent 90% 100%),conic-gradient(#3257fc ${
                          scoredLes ? scoredLes : 0
                        }%, #1e1e2014 0)`,
                      }}
                    >
                      <p className="pt-2 text-xs text-grey-07 sm:pt-2.5">
                        {scoredLes ? scoredLes : 0}&#37;
                      </p>
                    </div>
                  ) : (
                    <div className="relative mr-2 flex h-8 min-h-8 w-8 min-w-8 rounded-full border-2 border-[#1e1e2014] pl-0.5 text-center sm:mr-4 sm:h-9 sm:min-h-9 sm:w-9 sm:min-w-9">
                      <PlayFilled className="m-auto w-4 text-secondary-04" />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {lesStatus && !isInstruction ? (
                    <>
                      <TaskCheckmarkFilled className="m-2 ml-1 mr-3 w-6 text-success-05 sm:ml-2 sm:mr-5" />
                    </>
                  ) : node?.lessonId && node?.assetId && !isInstruction ? (
                    <div className="relative mr-2 flex h-8 w-8 rounded-full border-2 border-[#1e1e2014] pl-0.5 text-center sm:mr-4 sm:h-9 sm:w-9">
                      <PlayFilled className="m-auto w-4 text-secondary-04" />
                    </div>
                  ) : allStatus?.length > 0 && !hideScore && !isInstruction ? (
                    <div
                      className="relative mr-2 h-8 min-h-8 w-8 min-w-8 rounded-full text-center sm:mr-4 sm:h-9 sm:min-h-9 sm:w-9 sm:min-w-9"
                      style={{
                        background: `radial-gradient(closest-side, white 85%, transparent 90% 100%),conic-gradient(#3257fc ${
                          scoredLes ? scoredLes : 0
                        }%, #1e1e2014 0)`,
                      }}
                    >
                      <p className="pt-2 text-xs text-grey-07 sm:pt-2.5">
                        {scoredLes ? scoredLes : 0}&#37;
                      </p>
                    </div>
                  ) : (
                    <div className="relative mr-2 flex h-8 min-h-8 w-8 min-w-8 rounded-full border-2 border-[#1e1e2014] pl-0.5 text-center sm:mr-4 sm:h-9 sm:min-h-9 sm:w-9 sm:min-w-9">
                      <PlayFilled className="m-auto w-4 text-secondary-04" />
                    </div>
                  )}
                </>
              )}
              <p
                className={`w-full flex-1 ${
                  !lessonId && 'break-all'
                } pr-2 text-xs	font-medium text-black sm:text-sm`}
              >
                {node?.numbered && (
                  <span className="pr-2 sm:pr-4">{node?.order}.</span>
                )}
                {node?.name || node?.assetName}
              </p>
              {!isNaN(scoreCourse) && !hideScore && !isInstruction ? (
                <p className="text-sm text-success-05">
                  {t('pages.course_landing.score')} {Math.round(scoreCourse)}
                  &#37;
                </p>
              ) : (
                !lessonId && (
                  <div
                    className={`flex text-xs font-normal sm:text-sm ${
                      lesStatus?.length > 0 ? 'text-success-05' : 'text-grey-06'
                    }`}
                  >
                    {lessonsAssetsCourse?.length > 0 && (
                      <p>
                        {lessonsAssetsCourse.length}{' '}
                        {t('pages.course_landing.lessons')}
                      </p>
                    )}
                    {timeCourse > 0 && !isInstruction && (
                      <p className="pl-2 sm:pl-3">
                        {convertToTime(
                          Math.abs(timeCourse),
                          t('pages.learn_index.hour'),
                          t('pages.learn_index.minute')
                        )}
                      </p>
                    )}
                  </div>
                )
              )}
              {!node?.lessonId &&
                !node?.assetId &&
                (isOpen ? (
                  <ChevronUp
                    className={`w-6 p-2 pr-0 !text-grey-06 ${
                      !lessonId && 'sm:ml-2'
                    } sm:w-9 sm:pr-2`}
                  />
                ) : (
                  <ChevronDown
                    className={`w-6 p-2 pr-0 !text-grey-06 ${
                      !lessonId && 'sm:ml-2'
                    } sm:w-9 sm:pr-2`}
                  />
                ))}
            </div>
          ) : (
            <div
              className={`flex w-full cursor-pointer items-center justify-between rounded-md p-1 sm:p-2
              ${(disabledSubLesson || !isUserFollow) && 'pointer-events-none'}
              ${
                limitedLes &&
                activeLessonNode?.id !== node?.lessonId &&
                '!cursor-not-allowed opacity-60'
              }
              ${
                activeLessonNode?.usesId === node?.usesId && isUserFollow
                  ? '!cursor-pointer bg-primary-03 !text-grey-08 !opacity-100'
                  : 'sm:hover:bg-grey-transparent-01'
              }
              `}
              onClick={() => {
                return (
                  !limitedLes &&
                  (node?.lessonId || node?.assetId) &&
                  goRouteId(node?.lessonId || node?.assetId)
                );
              }}
            >
              <p
                className={`w-full flex-1 ${
                  !lessonId && 'break-all'
                } text-xs font-normal sm:text-sm ${
                  lesStatus && !node?.assetId
                    ? '!font-bold text-grey-08'
                    : 'text-grey-05'
                } ${
                  !lesStatus &&
                  (prevLesStatus || (index === 0 && parentStatus)) &&
                  !node?.assetId &&
                  'font-semibold'
                }
                ${
                  activeLessonNode?.usesId === node?.usesId &&
                  isUserFollow &&
                  ' !text-black '
                }
                ${
                  !currentLessonId &&
                  index === 0 &&
                  parentIndex === 0 &&
                  isUserFollow &&
                  !node?.assetId &&
                  'font-semibold text-grey-08'
                }
                `}
              >
                {node?.numbered && (
                  <span className="pr-2 sm:pr-4">{node?.order}.</span>
                )}
                {node.name || node?.assetName}
              </p>
              <>
                {node?.maxScore > 0 && !hideScore && !isInstruction && (
                  <p className="text-xs text-success-05">
                    {t('pages.course_landing.score')} {round2(node?.score)}/
                    {round2(node?.maxScore)}
                  </p>
                )}
                {courseOrder ? (
                  <>
                    {lesBlock && !isInstruction ? (
                      <Blocked
                        className={`${
                          !lessonId && 'sm:ml-2'
                        } w-7 py-1 pl-3 pr-1 text-grey-04 sm:w-9 sm:pr-3`}
                      />
                    ) : lesStatus && !isInstruction ? (
                      <Check
                        className={`${
                          !lessonId && 'sm:ml-2'
                        } w-6 pl-2 pr-0 text-success-05 sm:w-9 sm:pr-2`}
                      />
                    ) : (
                      <ArrowRight
                        className={`${
                          !lessonId && 'sm:ml-2'
                        } w-6 pl-2 pr-0 text-grey-05 sm:w-9 sm:w-9 sm:pr-2`}
                      />
                    )}
                  </>
                ) : lesStatus && !isInstruction ? (
                  <Check
                    className={`${
                      !lessonId && 'sm:ml-2'
                    } w-6 pl-2 pr-0 text-success-05 sm:w-9 sm:pr-2`}
                  />
                ) : (
                  <ArrowRight
                    className={`${
                      !lessonId && 'sm:ml-2'
                    } w-6 pl-2 pr-0 text-grey-05 sm:w-9 sm:w-9 sm:pr-2`}
                  />
                )}
              </>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div>
          {node?.children &&
            node?.children?.map((x: any, subIndex: number) => (
              <TreeItem
                key={x?.id}
                node={x}
                indent={indent + 1}
                index={subIndex}
                currentLessonId={currentLessonId}
                parentIndex={parentIndex + index}
                courseOrder={courseOrder}
                activeLessonNode={activeLessonNode}
                rowPath={rowPath}
                setHideContent={setHideContent}
                prevNode={subIndex > 0 ? node?.children[subIndex - 1] : false}
                parentStatus={index > 0 ? prevLesStatus : parentStatus}
                limitedLes={limitedLes}
                setLimitedLes={setLimitedLes}
                isUserFollow={isUserFollow}
                hideScore={hideScore}
                setIsLessonLoaded={setIsLessonLoaded}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export const LessonTree = ({
  coursecontentId,
  courseId,
  userId,
  courseOrder,
  setChapters,
  rowPath,
  setActiveLesson,
  setHideContent,
  setNextLesson,
  setPreviousLesson,
  onFirstLesson,
  activeLesson,
  isUserFollow,
  status,
  statusAsset,
  hideScore,
  isTeacher,
  setAllExtraInfo,
  setRootCourseId,
  setAllDirectories,
  setAllAssets,
  setNumLesAs,
  isInstruction,
  setIsLessonLoaded,
}: {
  courseId?: number | string | undefined;
  coursecontentId: number | string;
  userId: number | string | undefined | null;
  courseOrder: boolean;
  setChapters?: any;
  rowPath: any;
  setActiveLesson?: any;
  setHideContent?: any;
  setNextLesson?: any;
  setPreviousLesson?: any;
  onFirstLesson?: any;
  activeLesson?: any;
  isUserFollow?: any;
  status?: any;
  statusAsset?: any;
  hideScore?: boolean;
  isTeacher?: boolean;
  setAllExtraInfo?: any;
  setRootCourseId?: any;
  setAllDirectories?: any;
  setAllAssets?: any;
  setNumLesAs?: any;
  isInstruction?: boolean;
  setIsLessonLoaded?: any;
}) => {
  const { lessonId } = useParams();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  let lessonPath = '/usedlessonassets';
  // let lessonPath = '/usedLessons';
  const [lessonsWithStatus, setLessonsWithStatus] = useState<any>(null);
  const [tree, setTree] = useState<any>(null);
  const [limitedLes, setLimitedLes] = useState<any>(false);
  const [stutusLes, setStutusLes] = useState<any>([]);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  // if (courseId) lessonPath = '/usedcourselessons';
  if (courseId) lessonPath = '/usedcourselessonassets';

  const lessonResult = useGet<Array<Usedlessondetails>>(lessonPath, {
    count: 10000,
    courseId,
    coursecontentId,
  });
  const lessons: Array<Usedlessondetails> | undefined =
    lessonResult?.data?.data || undefined;
  const directoriesResult = useGet<Array<Directories>>('/directories', {
    count: 10000,
    coursecontentId,
  });
  const directories: Array<Directories> | undefined =
    directoriesResult?.data?.data || undefined;

  const assetResult = useGet('/usedassets', {
    count: 10000,
    coursecontentId,
    //organisationId: authUser?.activeOrganisationId
  });

  const assets = assetResult?.data?.data || undefined;

  useEffect(() => {
    if (lessons && lessonId) {
      setActiveLesson(
        lessons.find(
          (les: any) => les?.lessonId == lessonId || les?.assetId == lessonId
        )
      );
    }
  }, [lessons, lessonId, isMobile]);

  useEffect(() => {
    const assetStatus = statusAsset || [];
    const lesStatus = status || [];
    setStutusLes([...lesStatus, ...assetStatus]);
  }, [status, statusAsset]);

  useEffect(() => {
    //const status = lessonStatus?.data?.data;
    if (directories) {
      setRootCourseId(directories?.find((el: any) => el.path === '/'));
      setAllDirectories(directories?.filter((el: any) => el.path !== '/'));
      !lessonId && setChapters && setChapters(directories?.length);
    }

    if (assets) {
      setAllAssets(assets);
    }

    if (lessons) {
      const courseLessons = lessons.filter(
        (les: any) => !les?.rootLessonId && !les?.rootDirectoryId
      );
      setNumLesAs(courseLessons?.length || 0);

      setAllExtraInfo(
        lessons.filter(
          (les: any) =>
            ((les?.rootLessonId || les?.rootDirectoryId) && les?.lessonId) ||
            ((les?.rootLessonId || les?.rootDirectoryId) && les?.assetId)
        )
      );

      setLessonsWithStatus(
        courseLessons?.map((les: any) => {
          const arrLes = stutusLes?.find(
            (obj: any) => les?.lessonId && obj?.lessonId == les?.lessonId
          );
          const arrAsset = stutusLes?.find(
            (obj: any) => les?.assetId && obj?.assetId == les?.assetId
          );
          if (arrLes) {
            return {
              ...les,
              maxScore: arrLes?.maxScore,
              score: arrLes?.score,
              status: arrLes?.status,
              timeSpent: arrLes?.timeSpent,
            };
          }
          if (arrAsset) {
            return {
              ...les,
              maxScore: arrAsset?.maxScore,
              score: arrAsset?.score,
              status: arrAsset?.status,
              timeSpent: arrAsset?.timeSpent,
            };
          }
          return les;
        })
      );
    }
  }, [directories, lessons, lessonId, stutusLes, assets]);

  useEffect(() => {
    if (lessonsWithStatus && directories) {
      const tree = buildSortedTree(lessonsWithStatus, directories || []);

      //unlock all lessons with proper status
      walk(tree, (el: any) => {
        if (
          !el?.lessonId ||
          el?.onlyTheory ||
          ['submitted', 'corrected', 'completed'].includes(el?.status)
        ) {
          el.unlocked = true;
        }
      });

      //unlock the first one without a proper status
      const firstOfBlock = firstOfDfs(tree, (el: any) => {
        return (
          el?.lessonId &&
          !['submitted', 'corrected', 'completed'].includes(el?.status) &&
          !el?.onlyTheory
        );
      });
      if (firstOfBlock) {
        firstOfBlock.unlocked = true;
      }
      setTree(tree);

      firstOfDfs(tree, (node: any) => {
        if (node?.lessonId || node?.assetId) {
          onFirstLesson && onFirstLesson(node);
          return true;
        }
      });
    }
  }, [lessonsWithStatus, directories]);

  useEffect(() => {
    if (tree && lessonId) {
      const lessonArray: any[] = [];
      walk(tree, (node: any) => {
        if (node?.lessonId || node?.assetId) {
          lessonArray.push(node);
        }
      });
      const targetIndex = lessonArray.findIndex(
        (node) => node?.lessonId == lessonId || node?.assetId == lessonId
      );
      if (targetIndex == -1) {
        setNextLesson(null);
      } else {
        //find next lesson that is not onlyThory
        let tempNext = targetIndex + 1;
        while (tempNext < lessonArray.length) {
          if (
            lessonArray[tempNext]?.onlyTheory &&
            !lessonArray[tempNext]?.assetId
          ) {
            tempNext++;
          } else {
            break;
          }
        }
        if (tempNext < lessonArray.length) {
          setNextLesson(
            lessonArray[tempNext].lessonId || lessonArray[tempNext].assetId
          );
        } else if (targetIndex < lessonArray.length - 1) {
          //setlle for an onlyTheory lesson
          setNextLesson(
            lessonArray[targetIndex + 1].lessonId ||
              lessonArray[targetIndex + 1].assetId
          );
        } else {
          setNextLesson(null);
        }
      }
    }
  }, [tree, lessonId]);

  if (!lessons || !directories) return null;

  return (
    <>
      {(tree as any)?.children?.map((x: any, index: number) => (
        <TreeItem
          key={x?.usesId ? x?.usesId : `d_${index}`}
          node={x}
          indent={0}
          index={index}
          currentLessonId={activeLesson?.id}
          courseOrder={courseOrder}
          rowPath={rowPath}
          setHideContent={setHideContent}
          activeLessonNode={activeLesson}
          prevNode={index > 0 ? tree?.children[index - 1] : false}
          limitedLes={limitedLes}
          setLimitedLes={setLimitedLes}
          isUserFollow={isUserFollow}
          hideScore={hideScore}
          status={stutusLes?.find((y: any) => y?.lessonId === x?.id)}
          isInstruction={isInstruction}
          setIsLessonLoaded={setIsLessonLoaded}
        />
      ))}
    </>
  );
};

import React, { Component, Fragment, useState, useEffect } from 'react';
import {
  isEmpty,
  deep_copy,
  newUid,
  getSafe,
  isString,
  round2,
  focusWidget,
} from '@/util';
import { Radio, Checkbox } from '@teo/components';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { TextWithImage } from './TextWithImage';
import './MatrixView.css';
import { encode } from '@/base64';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { genericXapiSubmit } from './common';

export const MatrixView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setXApiSubmitFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  MatrixView.syncStates(state, answer, correction);
  const [data, setData] = useState(getSafe(() => answer.data, []));
  const [modified, setModified] = useState(null);
  const [score, setScore] = useState(correction?.score);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    if (submitted) {
      let answer = getAnswer();
      let correction = {};
      MatrixView.syncStates(state, answer, correction);
      if (!answer?.answered) {
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  const answered = submitted || isCorrected;
  const [viewMobile, setViewMobile] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const viewVersion = queryParams.get('view');
    if (viewVersion == 'mobile') {
      setViewMobile(true);
    }
  }, []);
  // const [wrongAnswers, setwWrongAnswers] = useState(0);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  //generate the answer
  const getAnswer = () => {
    answer.data = data;
    answer.submitted = submitted;
    const hasChecked = data.some((subArray) => subArray.includes(true));
    answer.incomplete = !submitted && !hasChecked;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    let correction = {};
    MatrixView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  const handleMouseDown = () => {
    answered && setIsMouseDown(true);
  };
  const handleMouseUp = () => {
    answered && setIsMouseDown(false);
  };

  //submit the widget as xApi
  const xApiSubmit = async (lessonId, lang, minScore = 0.5) => {
    let correction = {};
    let answer = await getAnswer();
    MatrixView.syncStates(state, answer, correction);
    let answerStr = t('widgets.xapi.answer_unavailable');
    let correctStr = t('widgets.xapi.correction_unavailable');
    return genericXapiSubmit(
      state,
      answer,
      correction,
      answerStr,
      correctStr,
      lessonId,
      lang,
      minScore
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  let rows = getSafe(() => state.rows, []);
  let cols = getSafe(() => state.cols, []);

  let CheckBoxType = state.radioMode ? Radio : Checkbox;

  const colWidth = 'calc(100% /' + (cols.length + 1) + ')';
  const uid = state.uid;

  let rowScore = 0;
  try {
    //we have had reports of this crashing occasionally
    if (answered) {
      correction.data.map((el, i) =>
        correction.data[i].map((el, j) => {
          if (data[i][j] !== state.data[i][j]) {
            return (rowScore += 1);
          }
        })
      );
    }
  } catch (e) {
    console.error(e);
  }

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100%)' }}
      data-state={encode(JSON.stringify(state))}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(score)}
          maxScore={state.score}
          answered={answered}
          titleWidget={state.titleWidget || t('widgets.type.matrix_question')}
        />
      )}
      <FroalaTextareaView value={state.vraag} />

      {answered && rowScore > 0 ? (
        <div>
          <p className="text-xs font-medium text-error-04">
            {rowScore}{' '}
            {rowScore !== 1
              ? t('pages.correction_result.wrong_answers')
              : t('pages.correction_result.wrong_answer')}
          </p>
          <p className="pt-1 text-xs font-medium text-error-04">
            {t('pages.correction_result.multiplechoice_widget_hint')}
          </p>
        </div>
      ) : null}

      <div
        className={`teo-scrollbar relative overflow-auto pb-2 ${
          viewMobile
            ? 'max-w-[calc(382px_-_64px)]'
            : 'max-w-[calc(100vw_-_64px)] md:max-w-[calc(100vw_-_336px)]'
        }`}
      >
        <table
          className="widget-item w-full !border-separate !border-spacing-0 rounded-[10px] border-r border-grey-02"
          style={{ minWidth: `${cols.length * 130}px` }}
        >
          <thead>
            <tr>
              <td className="!border-y !border-l border-grey-02 bg-white"></td>
              {cols.map((col, j) => (
                <td
                  key={uid + '_col_' + j}
                  className="relative !border-y !border-l border-grey-02 bg-white p-3 text-xs font-medium"
                  style={{ width: colWidth }}
                >
                  <TextWithImage
                    className=""
                    noMaxHeight
                    textstyle={{
                      flexDirection: 'column',
                    }}
                    imgClass="!max-w-[70px] pb-2"
                    data={isString(col) ? { text: col } : col}
                  />
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={uid + '_row_' + i}>
                <td className="relative !border-l !border-b border-grey-02 bg-white p-2 text-xs font-medium">
                  <TextWithImage
                    className=""
                    noMaxHeight
                    textstyle={{
                      textAlign: 'center',
                      flexDirection: 'column',
                    }}
                    imgClass="!max-w-[70px] pb-2"
                    data={isString(row.label) ? { text: row.label } : row.label}
                  />
                </td>
                {cols?.map((col, j) => {
                  return (
                    <td
                      key={uid + '_row_' + i + '_col_' + j}
                      className={clsx(
                        'border-grey-02',
                        '!border-l',
                        '!border-b',
                        'bg-white',
                        'cursor-pointer',
                        answered && '!cursor-default',
                        !answered
                          ? ''
                          : data[i][j] == state.data[i][j]
                          ? (!state.radioMode || data[i][j]) && state.data[i][j]
                            ? '!bg-success-01'
                            : ''
                          : !state.radioMode || state.data[i][j]
                          ? isMouseDown
                            ? '!bg-[#dbefff63]'
                            : '!bg-error-01'
                          : state.radioMode
                          ? isMouseDown
                            ? '!bg-[#dbefff63]'
                            : '!bg-error-01'
                          : ''
                      )}
                      style={{ height: '100%' }}
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onTouchStart={handleMouseDown}
                      onTouchEnd={handleMouseUp}
                      onClick={(e) => {
                        e.preventDefault(); //otherwise triggers twice for some reason
                        e.stopPropagation(); //we handle the clicks, the checkbox itself shouldn't bother

                        focusWidget(state?.uid);
                        if (viewOnly || answered) return;
                        if (state.radioMode) {
                          for (let k in data[i]) {
                            data[i][k] = false;
                          }
                          data[i][j] = true;
                        } else {
                          data[i][j] = !data[i][j];
                        }
                        setData(deep_copy(data));
                        setModified(newUid(20));
                        //this.props.onAnswer && this.props.onAnswer({data : deep_copy(data)})
                      }}
                    >
                      <label
                        className={`m-3 flex cursor-pointer justify-center ${
                          answered && '!pointer-events-none'
                        }`}
                        htmlFor={uid + '_check_' + i + '_' + j}
                      >
                        <CheckBoxType
                          id={uid + '_check_' + i + '_' + j}
                          key={uid + '_check_' + i + '_' + j + data[i][j]}
                          className={`pointer-events-none m-auto ${
                            !answered
                              ? ''
                              : data[i][j] == state.data[i][j]
                              ? ''
                              : !state.radioMode || state.data[i][j]
                              ? isMouseDown
                                ? 'checked:!bg-success-05'
                                : 'checked:!bg-error-05'
                              : state.radioMode
                              ? isMouseDown
                                ? 'checked:!bg-success-05'
                                : 'checked:!bg-error-05'
                              : ''
                          }`}
                          onChange={() => {}}
                          checked={
                            showAnswers
                              ? state?.data[i][j]
                              : isMouseDown
                              ? state?.data[i][j]
                              : data[i][j]
                          }
                        />
                      </label>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {(state.immediateFeedback || correctionType === 'autofeedback') && (
        <PartialSubmitSection
          setSubmitted={setSubmitted}
          setModified={setModified}
          answered={answered}
          onSave={onSave.bind(null, false, false, state)}
        />
      )}
      {(resultPages || showAnswers || answered || submitted) &&
      state?.antwoord ? (
        <ExplanationSection state={state} />
      ) : null}
    </div>
  );
};

MatrixView.syncStates = (state, answer, correction) => {
  if (isEmpty(answer)) {
    answer.uid = state.uid;
  }
  if (
    !answer.data ||
    answer.data.length !== state.data.length ||
    (answer.data.length > 0 && answer.data[0].length !== state.data[0].length)
  ) {
    answer.data = deep_copy(state.data);
    for (let i in answer.data) {
      for (let j in answer.data[i]) {
        answer.data[i][j] = false;
      }
    }
  }

  if (isEmpty(correction)) {
    correction.uid = state.uid;
  }

  if (
    !correction.data ||
    correction.data.length !== state.data.length ||
    (correction.data.length > 0 &&
      correction.data[0].length !== state.data[0].length)
  ) {
    let useRowScores = state.rows.find((x) => x.score) !== undefined;
    correction.data = deep_copy(state.data);
    correction.score = 0;
    for (let i in correction.data) {
      let rowScore = 0;
      let maxRowScore = 0;
      for (let j in correction.data[i]) {
        if (state.data[i][j]) {
          maxRowScore++;
          if (answer.data[i][j] === state.data[i][j]) rowScore += 1;
          else rowScore -= 1;
        } else {
          if (answer.data[i][j]) rowScore -= 1;
        }
      }

      if (maxRowScore !== 0) {
        rowScore = Math.max(0, rowScore);
        rowScore /= maxRowScore;
      } else if (rowScore === 0) {
        //nothing answered, nothing correct, good job
        rowScore = 1;
        maxRowScore = 1;
      } else {
        //something answered, nothing correct, boo
        rowScore = 0;
        maxRowScore = 1;
      }

      if (useRowScores) rowScore *= state.rows[i].score;
      correction.score += Math.max(0, rowScore);
    }
    if (!useRowScores) {
      correction.score /= correction.data.length;
      correction.score *= state.score;
    }
  }
};

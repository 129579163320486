import React, { useMemo } from 'react';
import { Button, Tag } from '@teo/components';
import { useTranslation } from 'react-i18next';

function nameList(files: File[]) {
  const name = (file: File) => `${file.name}`;
  return files.map((file) => name(file));
}

function typeList(files: File[]) {
  const documentType = (file: File) => `${file.type}`;
  return files.map((file) => documentType(file));
}

function FileContainer({
  files,
  fileInputRef,
  handleUploadPhoto,
  courseImg,
  bannerLPImg,
}: any) {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fileName = useMemo(() => nameList(files), [files]);
  const documentType = useMemo(() => typeList(files), [files]);

  if (files.length === 0) {
    return null;
  }

  return (
    <div
      className="
                flex
                items-center
                justify-between
                rounded-lg
                border border-[#eaeaed] p-3
            "
    >
      <div className="flex items-center">
        <img
          alt="logo"
          src={URL.createObjectURL(files[0])}
          className={`mr-3 h-16 flex-[1_0_64px] rounded object-cover object-center ${
            courseImg && ' h-44 w-64 '
          } ${bannerLPImg && ' h-44 w-64 '}`}
        />
        <div>
          <p
            className={`
              ${courseImg || bannerLPImg ? 'w-[200px]' : 'w-[250px]'}
              overflow-hidden
              truncate
              text-base
              font-semibold
            `}
          >
            {fileName}
          </p>

          <Tag
            title={`${documentType}`}
            size="md"
            variant="document"
            documentType="image"
          />
        </div>
      </div>

      <div className="mr-2">
        <Button
          variant="outline"
          size="md"
          onClick={() => {
            fileInputRef.current?.click();
          }}
          className="text-base font-semibold"
        >
          {courseImg || bannerLPImg
            ? t('pages.upload_dropzone_section.upload_button_img_2')
            : t('pages.upload_dropzone_section.upload_button_2')}
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadPhoto}
          accept="image/jpg,.png,"
          style={{ display: 'none' }}
          multiple={false}
        />
      </div>
    </div>
  );
}

export default FileContainer;

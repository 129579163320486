import React, { Component } from 'react';
import { Container, Sprite, withPixiApp, Graphics, Text } from '@pixi/react';
import * as PIXI from 'pixi.js';
import { BoxIoService } from '../BoxIoService';

const button_image =
  'https://teo-s3-prod.s3.eu-west-1.amazonaws.com/PIXI/rotarybutton.png';
const SNAP_ANGLES = [
  0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170,
  180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290,
];

const styleUnit = new PIXI.TextStyle({
  fontFamily: 'Arial',
  fontSize: 80,
  fontStyle: 'italic',
  strokeThickness: 1,
  wordWrap: true,
  wordWrapWidth: 440,
  fontWeight: 400,
});

const RotaryButton = withPixiApp(
  class extends React.Component {
    displayName = 'RotaryButton';

    constructor(props) {
      super();
      this.state = {
        angle: 0,
        value: 0,
      };

      //  Gets value if pwm changes and sets the angle on the right direction
      this.service = BoxIoService.getInstance();

      this.subscriptions.push(
        this.service.onAnalog.subscribe((msg) => {
          if (this.service.isStateLocked(msg.name)) return;
          if (Date.now() - this.timestamp < 100) return;
          if (msg.name === props.name) {
            let value = msg.value;

            let temp = value;
            if (this.props.vmin || this.props.vmax) {
              temp =
                ((value - this.props.vmin) /
                  (this.props.vmax - this.props.vmin)) *
                24;
            }

            this.setState({ value: value, angle: (temp / 24) * 299 });
          }
        })
      );

      this.subscriptions.push(
        this.service.onStatelock.subscribe(({ name, locked }) => {
          if (name === props.name) {
            let value = this.service.state[name].value;
            if (locked) {
              this.setState({
                value: value,
                angle: (value / 24) * 299,
                disabled: true,
              });
            } else {
              this.setState({
                value: value,
                angle: (value / 24) * 299,
                disabled: false,
              });
            }
          }
        })
      );
    }

    subscriptions = [];
    componentWillUnmount() {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
      this.subscriptions = [];
    }

    componentDidMount() {
      const angle = (this.props.value / 244) * 299;
      this.setState({
        angle: angle,
        disabled: this.service.isStateLocked(this.props.name),
      });
    }

    onOrientStart = (event) => {
      let sprite = event.currentTarget;
      sprite.orienting = this.state.disabled ? false : true;
      event.data.originalEvent.target.cancelScroll = true;
    };

    onOrientEnd = (event) => {
      let sprite = event.currentTarget;
      if (sprite.orienting) sprite.orienting = false;
    };

    onOrientMove = (event) => {
      let sprite = event.currentTarget;
      if (sprite.orienting) {
        event.data.originalEvent.target.cancelScroll = true;

        let spritePos = sprite.getGlobalPosition();
        let x = event.data.global.x - spritePos.x;
        let y = event.data.global.y - spritePos.y;

        let rad = Math.atan2(y, x);
        let deg = rad * (180 / Math.PI);

        deg -= 130;
        if (deg < 0) deg += 360;

        if (deg > 320) {
          deg = 0;
        } else if (deg > 299) {
          deg = 299;
        }

        this.setState({ angle: deg });

        let value = (sprite.angle / 299) * 24;

        if (this.props.vmin || this.props.vmax) {
          let newVal =
            this.props.vmin +
            (value / 24) * (this.props.vmax - this.props.vmin);
          this.setState({ value: newVal, angle: deg });
          this.service.sendAnalog(this.props.name, newVal);
        } else {
          this.setState({ value: value, angle: deg });
          this.service.sendAnalog(this.props.name, value);
        }
      }
    };

    render() {
      const { scaleRot } = this.props;
      const disabled = this.state.disabled;
      return (
        <Container
          {...this.props}
          pivot={{ y: -350, x: 205 }}
          scale={scaleRot}
          width={87}
          height={79}
        >
          <Sprite
            alpha={disabled ? 0.6 : 1}
            angle={this.state.angle}
            image={button_image}
            anchor={0.5}
            x={0}
            y={0}
            interactive
            buttonMode
            pointerdown={this.onOrientStart}
            pointerup={this.onOrientEnd}
            pointerupoutside={this.onOrientEnd}
            pointermove={this.onOrientMove}
          />

          {SNAP_ANGLES.map((x, index) => (
            <Graphics
              key={index}
              alpha={disabled ? 0.6 : 1}
              anchor={0.5}
              angle={x + 35}
              draw={(g) => {
                g.clear();
                g.lineStyle(10, this.state.angle <= x ? 0x000000 : 0xfccc4a);
                g.drawRect(0, 200, 0, 40);
                g.endFill();
              }}
            />
          ))}
          <Text
            anchor={0.5}
            scale={0.5}
            x={0}
            y={200}
            text={((this.state.angle / 299) * 100).toFixed(0) + '%'}
            style={styleUnit}
          />
        </Container>
      );
    }
  }
);

export default RotaryButton;

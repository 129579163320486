import React, { Component, useEffect, useState, useContext } from 'react';
import { string, number } from 'prop-types';
import { T } from '@/components/T';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import './FaqSearchField.scss';
import { clsx } from 'clsx';
import i18n from 'i18next';
import { parseFilter } from '@/util';
import { useGet } from '@/query/fetchHooks';
import { InputText, Button, Panel, IconButton } from '@teo/components';
import { useNavigate, useMatches, useParams } from 'react-router-dom';
import { FaqList } from './FaqList';
import { Search, ChevronUp, ChevronDown } from '@teo/components/icons';
import { AuthContext } from '@/components/Contexts/AuthContext';

const SearchResults = ({ faqs, topic, setSearch, setSelectedId }) => {
  const [collapsed, setCollapsed] = useState(false);
  if (!Array.isArray(faqs) || !faqs.length) return null;

  return (
    <Panel className="flex flex-col break-words">
      {topic && (
        <h3
          className="flex cursor-pointer flex-row items-center justify-between p-4"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {i18n.t(`pages.faq.topic.${topic}`)}
          <IconButton
            Icon={!collapsed ? ChevronUp : ChevronDown}
            variant="ghost"
          />
        </h3>
      )}
      {!collapsed && (
        <FaqList
          faqs={faqs}
          setSearch={setSearch}
          setSelectedId={setSelectedId}
        />
      )}
    </Panel>
  );
};

const FaqSearchField = ({
  search,
  setSearch,
  selectedId,
  setSelectedId,
  topic,
  limit,
  faqs,
  setFaqs,
}) => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;

  const { refetch } = useGet(`/faqs`, {
    q: parseFilter(search),
    lang: i18n.language,
    enabled: !!(search || topic),
    topic,
    limit,
    manual: true,
    onSuccess: (results) => {
      setFaqs(results.data);
    },
  });

  useEffect(() => {
    if (search || topic) {
      refetch();
    }
  }, [search, topic]);

  const renderSearchResultSection = (section) => {
    if (topic) {
      return <SearchResults faqs={faqs} />;
    } else {
      const sections = Object.groupBy(faqs, ({ topic }) => topic);
      return Object.values(sections).map((section) => {
        return (
          <SearchResults
            key={section[0].topic}
            faqs={section}
            topic={section[0].topic}
            setSearch={setSearch}
            setSelectedId={setSelectedId}
          />
        );
      });
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="md:w-1/2 xl:w-1/3">
          <InputText
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            Icon={Search}
            placeholder={i18n.t('search.faq')}
          />
        </div>
        {(authUser?.role || 0) >= 90 && !selectedId && (
          <div className="flex">
            <Button onClick={(ev) => setTimeout(() => navigate('./new'), 0)}>
              <T>button.add_btn</T>
            </Button>
          </div>
        )}
      </div>
      {!selectedId && faqs.length > 0 && search && (
        <>
          <div className="mt-8 flex flex-col gap-4 break-words">
            {renderSearchResultSection()}
          </div>
          <div className="my-4 text-sm">
            {i18n.t('pages.faq.faq_not_found')}
          </div>
        </>
      )}
    </>
  );
};

export { FaqSearchField };

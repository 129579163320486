import { useTranslation } from 'react-i18next';
import { Button, IconButton, Modal } from '@teo/components';
import { useNavigate, useMatches, useParams } from 'react-router-dom';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useContext } from 'react';

function NoAccessPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  return (
    <div className="mx-auto mt-10 flex min-h-[calc(100vh_-_120px)] flex-col items-center justify-center">
      {/*<TheoSearch className="mb-4 w-96" />*/}
      <img className="mt-4 w-[400px]" src="/images/courseFailure.svg"></img>
      <h2 className="mx-10 mt-16 text-center">{t('pages.no_access.title')}</h2>
      {!authUser && (
        <Button
          onClick={() => {
            navigate('/login');
          }}
          size="md"
          className="mt-10 w-full max-w-xs sm:w-auto"
        >
          {t('links.sign_in')}
        </Button>
      )}
    </div>
  );
}

export default NoAccessPage;

import FroalaTextAreaEdit from '@/components/Froala/FroalaTextAreaEdit';
import { createRef, useState, useEffect, useContext } from 'react';
import { axios, publicAxios } from '@/axios';
import { config } from '@/config';
import { useGet } from '@/query/fetchHooks';
import { encode, decode } from '@/base64';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { newUid, isEmpty, deep_copy, useStateAndRef } from '@/util';

export const LessonEditFroala = ({
  newContent,
  node,
  setSaveFn,
  resetAutoSave,
  isLoading,
  isLesson,
  setIsFroalaLoaded,
}: any) => {
  const [ref, setRef, refRef] = useStateAndRef(null);
  const { setShowNotification } = useContext(NotificationContext);
  const { t } = useTranslation();
  const [content, setContent, contentRef] = useStateAndRef(null);
  const [savedContent, setSavedContent, savedContentRef] = useStateAndRef(null);

  const [isSaving, setIsSaving, isSavingRef] = useStateAndRef(false);
  const [modified, setModified, modifiedRef] = useStateAndRef(0);
  const [widgets, setWidgets, widgetsRef] = useStateAndRef({});

  const [loaded, setLoaded, loadedRef] = useStateAndRef(false);

  const saveFn = async (autosave = false) => {
    if (loadedRef.current === false) return;
    if (isLoading && !isLesson) return Promise.resolve('ok');
    // if (isLoading) return Promise.resolve('ok');
    if (isSavingRef.current) return Promise.resolve('ok'); //prevent double save
    resetAutoSave(); //reset autosave
    setIsSaving(true);
    return new Promise((resolve, reject) => {
      const ref = refRef.current;
      const clone = (window as any)
        .$(`.lesson_${node?.data?.lessonId} .fr-view`)
        .first()
        .clone();

      if (!clone) resolve('ok'); //nothing to save
      const promises = [];
      const placeholders = document.querySelectorAll(
        `#${CSS.escape(refRef?.current?.state.uid)} .widget-placeholder`
      );
      // console.log('placeholders', refRef.current?.state);

      for (const placeholder of [...placeholders]) {
        try {
          promises.push(
            refRef.current?.state?.editors[placeholder.id]?.stateFn()
          );
        } catch (e) {
          console.error('Unable to parse', placeholder, e);
        }
      }

      Promise.allSettled(promises)
        .then((results) => {
          //the number of widgets has changed
          let widgetsModfied = false;
          const widgets = [];
          for (const result of results.reverse()) {
            if (result.status === 'fulfilled') {
              const state = result.value;
              if (!state) continue;
              widgets.push(state);

              const widgetNode = clone.find(`#${CSS.escape(state.uid)}`);
              const stateStr = encode(JSON.stringify(state));

              //widget changed detection, compare their current state with their initial state
              const compareState = deep_copy(state);
              delete compareState.vraag; //this should be picked up by modified detection
              delete compareState.antwoord; //this should be picked up by modified detection

              if (
                encode(JSON.stringify(compareState)) !==
                widgetsRef.current[state.uid]
              ) {
                widgetsModfied = true;
              }
              //widget changed detection

              widgetNode.empty();
              if (state.inline) {
                widgetNode.append(
                  `<span data-open="SOW" data-state="${stateStr}" /><span data-closed="EOW"></span></span>`
                );
              } else {
                widgetNode.append(
                  `<div data-open="SOW" data-state="${stateStr}" /><div data-closed="EOW"></div></div>`
                );
              }
            }
          }

          for (const oldWidget of Object.keys(widgetsRef.current)) {
            if (widgets.findIndex((x) => x.uid === oldWidget) === -1) {
              //some widget has dissapeared
              widgetsModfied = true;
              break;
            }
          }

          return { widgetsModfied, widgets };
        })
        .then(({ widgetsModfied, widgets }) => {
          const _content = clone.html();

          if (_content === undefined || _content === 'undefined')
            return resolve('ok');
          const _orig_content = savedContentRef.current || contentRef.current;

          if (
            !widgetsModfied &&
            (_content === undefined || _orig_content === _content)
          ) {
            return resolve('ok');
          }

          setSavedContent(_content);

          const postData = node.data.postData;
          const form = new FormData();
          for (const key in postData.fields) {
            form.append(key, postData.fields[key]);
          }
          const blob = new Blob([_content], { type: 'text/html' });
          form.append('file', blob);

          publicAxios
            .post(postData.url, form)
            .then(async (response) => {
              let maxScore = 0,
                onlyTheory = true;
              let widgetStates = await Promise.allSettled(
                Object.values(ref?.state?.editors || {}).map(async (x: any) =>
                  x.stateFn()
                )
              );
              widgetStates = (
                widgetStates.filter(
                  (result) => result.status === 'fulfilled'
                ) as PromiseFulfilledResult<any>[]
              ).map((result) => result.value);
              //console.log("widgetStates", widgetStates)
              for (const widget of widgetStates) {
                const anyWidget = widget as any;
                maxScore += parseFloat(anyWidget.score) || 0;
                if (!anyWidget.noSubmit) onlyTheory = false;
              }
              axios
                .post(`${config.backend}/lessonversions`, {
                  lessonId: node?.data?.lessonId,
                  lessonType: node.data.lessonType,
                  version: response.headers['x-amz-version-id'],
                  onlyTheory,
                  maxScore,
                })
                .then(() => {
                  if (!autosave) {
                    setShowNotification(t('notifications.lesson_saved'));
                  }
                  const newWidgets: any = {};
                  //store their new states as old states
                  for (const widget of widgets) {
                    delete widget.vraag; //this should be picked up by modified detection
                    delete widget.antwoord; //this should be picked up by modified detection
                    newWidgets[widget.uid] = encode(JSON.stringify(widget));
                  }
                  setWidgets(newWidgets);
                  resolve('ok');
                });
            })
            .catch((e) => {
              console.error('error', e);
              reject(e);
            });
        });
    })
      .then(() => {
        setIsSaving(false);
        setModified(1);
      })
      .catch((e) => {
        setIsSaving(false);
        throw e; //rethrow
      });
  };

  useEffect(() => {
    setSaveFn(saveFn);
    setModified(0);
  }, [node]);

  useEffect(() => {
    setContent(newContent);
    setIsFroalaLoaded(node?.data?.usesId);
    setLoaded(false);
  }, [newContent]);

  if (!content || content != newContent) return null;

  return (
    <FroalaTextAreaEdit
      toolbarSticky
      key={node?.data?.lessonId}
      modus="lessonEdit"
      className={`form-control lesson_${node?.data?.lessonId} mx-auto w-full max-w-5xl overflow-hidden border-0`}
      mode="edit"
      style={{ minHeight: '80vh' }}
      value={content}
      setRef={setRef}
      onLoaded={() => setLoaded(true)}
      onEditors={(editors: any) => {
        const widgets: any = {};
        for (const editor of Object.values(editors)) {
          const state = (editor as any).state;
          delete state.vraag; //this should be picked up by modified detection
          delete state.antwoord; //this should be picked up by modified detection
          widgets[(editor as any).state.uid] = encode(JSON.stringify(state));
        }
        setWidgets(widgets);
      }}
      onModified={() => {
        setModified(modified + 1);
      }}
      onSave={async () => {
        saveFn();
      }}
    ></FroalaTextAreaEdit>
  );
};

import { Button } from '@teo/components';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useGet } from '@/query/fetchHooks';
import { format } from 'date-fns';
import { axios } from '@/axios';
import { config } from '@/config';
import { uploadLesson } from '@/query/documents';
import { ImportAction } from '@/components/DuplicateLessons/ImportAction';

export const LessonVersions = ({ les, onRefresh, setLoadVersion }: any) => {
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();
  const [prevVersion, setPrevVersion] = useState<any>();
  const [prevContent, setPrevContent] = useState<any>();

  const versions: any = useGet(`/versionsbylesson/${les?.lessonId}`);

  const handleRevert = async (version: any) => {
    const lesson = await axios.get(
      `${config.backend}/usedLesson/${les?.coursecontentId}/${les?.lessonId}/${version}?coursecontentId=${les?.coursecontentId}&write_access=true`
    );
    setPrevVersion(lesson?.data);
  };

  useEffect(() => {
    if (les.id && prevContent) {
      setLoadVersion(false);
      setTimeout(async () => {
        await uploadLesson(les?.postData, prevContent);
        onRefresh();
      });
    }
  }, [prevContent]);

  return (
    <div className="">
      <p className="text-sm font-normal text-[#7d7d81]">
        {t('pages.create_edit_course.versions_text_1')}{' '}
        <span className="font-bold">{les?.name}</span>
        {t('pages.create_edit_course.versions_text_2')}
      </p>
      <div className="relative mt-6 mb-4 flex flex-col gap-5">
        <div className="absolute left-0 top-4 h-[calc(100%_-_32px)] w-0.5 bg-grey-02"></div>
        {versions?.data?.data?.map((version: any) => {
          return (
            <div
              key={version.version}
              className="relative flex flex-col gap-1 pl-8"
            >
              <div className="absolute -left-0.5 top-2/4 -ml-px h-2 w-2 -translate-y-1/2 rounded-full bg-grey-05"></div>
              <p className="pl-1.5 text-sm text-grey-06">
                {format(new Date(version.updatedAt || ''), 'dd/MM/yyyy HH:mm')}
              </p>

              <div className="flex flex-row items-center">
                <Button
                  variant="text"
                  size="sm"
                  className="text-tint-blue"
                  onClick={() =>
                    window.open(
                      `/previewLesson/${les?.lessonId}/version/${version?.version}`
                    )
                  }
                >
                  {t('button.example')}
                </Button>
                <span className="px-1">&#8729;</span>
                <Button
                  variant="text"
                  size="sm"
                  className="text-tint-blue"
                  onClick={() => handleRevert(version?.version)}
                >
                  {t('pages.create_edit_course.revert_version')}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      {prevVersion?.lessonId && (
        <ImportAction les={prevVersion} setContent={setPrevContent} />
      )}
    </div>
  );
};

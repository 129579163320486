import { useEffect, useState } from 'react';
import { IconButton, PersonAvatar, ButtonLink, Button } from '@teo/components';
import { CrossFilled, ArrowLeft, ArrowRight } from '@teo/components/icons';
import { useNavigate } from 'react-router-dom';
import { nthLastIndexOf, convertToTimeSec, parseCourseType } from '@/util';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGet } from '@/query/fetchHooks';
import { config } from '@/config';
import { axios } from '@/axios';

const CorrectionHeader = ({
  submit,
  coorectionPage,
  isTeacher,
  courseId,
  lessonId,
  studentId,
  course,
  student,
  lesson,
  lessonStatus,
  setPreloaderNext,
}: any) => {
  const navigate = useNavigate();

  const [corrections, setCorrections] = useState<any[] | undefined>();
  const [nextCorrection, setNextCorrection] = useState<any>(null);
  const [previousCorrection, setPreviousCorrection] = useState<any>(null);

  const { t } = useTranslation();

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );

  useGet(
    `${config.teodoc}/results/list2?submitted=true&courseId=${courseId}&userId=${studentId}&offset=0`,
    {
      enabled: !!(courseId && studentId),
      onSuccess: (result: any) => {
        setCorrections(result?.data);
      },
    },
    [lessonId]
  );

  useEffect(() => {
    if (corrections) {
      const myCorrectionIndex = corrections.findIndex(
        (x: any) => x.lessonId == lessonId
      );
      if (myCorrectionIndex == -1)
        setNextCorrection(corrections?.[corrections.length - 1]);
      else setNextCorrection(corrections?.[myCorrectionIndex + 1]);
      if (myCorrectionIndex == -1) setPreviousCorrection(corrections?.[0]);
      else setPreviousCorrection(corrections?.[myCorrectionIndex - 1]);
    }
  }, [corrections]);

  useEffect(() => {
    if (submit) {
      if (nextCorrection) {
        setPreloaderNext(false);
        navigate(
          `${basePath}/correction/${courseId}/${studentId}/${nextCorrection.lessonId}`
        );
      } else if (previousCorrection) {
        setPreloaderNext(false);
        navigate(
          `${basePath}/correction/${courseId}/${studentId}/${previousCorrection.lessonId}`
        );
      } else {
        const queryParams = new URLSearchParams(window.location.search);
        const viewLes = queryParams.get('view_les');
        if (viewLes) {
          navigate(
            `${basePath}/result/${courseId}/${studentId}/lesson/${lessonId}`
          );
        } else {
          if (
            student?.progressLessonsTotal === student?.progressLessonsCompleted
          ) {
            axios.post(`${config.backend}/notifications`, {
              text: {
                text: t('notifications.teacher_corrected_course', {
                  nameCourse: course?.courseName || course?.name,
                }),
                courseImage: course?.courseImage
                  ? course?.courseImage
                  : course?.image && !course?.image.indexOf('http')
                  ? course.image
                  : '/assets/images/opleidingen.jpg',
              },
              type: 'coursecorrected',
              globalLink: `/:lang/learn/:organisationId/${parseCourseType(
                course?.courseType
              )}/${courseId}`,
              userId: parseInt(studentId, 10),
              subject: parseInt(courseId, 10),
            });
          }
          navigate(`${basePath}/courses/details/${courseId}`);
        }
      }
    }
  }, [submit]);

  return (
    <>
      <div className="flex items-center justify-between border-b border-solid border-grey-02 bg-white px-3 py-4">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex items-center">
            <IconButton
              className="my-auto mx-2"
              Icon={CrossFilled}
              variant="ghost"
              onClick={() =>
                navigate(
                  isTeacher
                    ? `${basePath}/result/${courseId}/${studentId}/lesson/${lessonId}`
                    : `${basePath}/courses/${courseId}/lesson/${lessonId}`
                )
              }
            />
            <div className="mr-5 w-20 min-w-20">
              <img
                src={course?.image || '/images/courseFallback.png'}
                alt="courseFallback"
                className="rounded-lg"
              />
            </div>
          </div>
          <div className="ml-10 mt-2 flex items-center pl-3 sm:ml-0 sm:mt-0 sm:pl-0">
            <span className="min-w-max">{`${t(
              'courseTypes.' + course?.courseType
            )} ${t('pages.correction.correction')}: `}</span>
            <span className="pl-2 font-semibold">{course?.name}</span>
          </div>
        </div>
      </div>

      <div className="flex items-center bg-grey-02 py-4 px-4 sm:px-8">
        <PersonAvatar
          inline
          id={student?.id}
          name={student?.username as string}
          imageSrc={student?.picture as string}
        ></PersonAvatar>
        {student?.username}
        {isTeacher && (
          <div className="ml-auto rounded-lg border border-grey-03 sm:mr-8">
            <ButtonLink
              to={`${basePath}/users/${studentId}`}
              variant="outline"
              size="md"
              className="flex items-center"
            >
              {t('pages.correction_result.btn_user')}
              <ArrowRight className="ml-2 h-5 w-5" />
            </ButtonLink>
          </div>
        )}
      </div>
      <div className="flex justify-between border-b border-solid border-grey-02 px-8 py-4">
        {previousCorrection && coorectionPage ? (
          <div
            style={{ width: '170px' }}
            className="ml-8 flex flex-row items-center"
          >
            <IconButton
              Icon={ArrowLeft}
              ariaLabel={t('pages.correction_result.prev_lesson')}
              onClick={() => {
                setPreloaderNext(false);
                navigate(
                  `${basePath}/correction/${courseId}/${studentId}/${previousCorrection.lessonId}`
                );
              }}
              variant="outline"
            />
            <p className="ml-3 hidden w-max lg:block">
              {t('pages.correction_result.prev_lesson')}
            </p>
          </div>
        ) : (
          <div style={{ width: '170px' }} className="ml-8">
            &nbsp;
          </div>
        )}

        <div className="mx-auto flex flex-col px-4">
          <div className="mb-3 flex flex-col items-center justify-center gap-3 text-center sm:flex-row">
            <h4 className="text-base sm:line-clamp-1">{lesson?.name}</h4>
            <Button
              variant="outline"
              size="sm"
              onClick={() =>
                navigate(
                  isTeacher
                    ? `${basePath}/result/${courseId}/${studentId}/lesson/${lessonId}`
                    : `${basePath}/courses/${courseId}/lesson/${lessonId}`
                )
              }
            >
              {t('pages.correction_result.btn_lesson')}
            </Button>
          </div>
          <div className="flex flex-col justify-center gap-3 text-center text-sm text-grey-06 sm:flex-row">
            {lessonStatus?.updatedAt ? (
              <span>
                {t('pages.correction_result.date_submit')}{' '}
                {format(new Date(lessonStatus?.updatedAt), 'dd/MM/yyyy H:m')}
              </span>
            ) : null}

            {lessonStatus?.timeSpent ? (
              <span>
                {t('pages.correction_result.estimated')}{' '}
                {convertToTimeSec(
                  Math.abs(lessonStatus?.timeSpent),
                  t('pages.learn_index.hour'),
                  t('pages.learn_index.minute')
                )}
              </span>
            ) : null}
          </div>
        </div>

        {nextCorrection && coorectionPage ? (
          <div
            style={{ width: '170px' }}
            className="mr-8 flex flex-row items-center"
          >
            <p className="mr-3 hidden w-max lg:block">
              {t('pages.correction_result.next_lesson')}
            </p>
            <IconButton
              Icon={ArrowRight}
              ariaLabel={t('pages.correction_result.next_lesson')}
              onClick={() => {
                setPreloaderNext(false);
                navigate(
                  `${basePath}/correction/${courseId}/${studentId}/${nextCorrection.lessonId}`
                );
              }}
              variant="outline"
            />
          </div>
        ) : (
          <div style={{ width: '170px' }} className="mr-8">
            &nbsp;
          </div>
        )}
      </div>
    </>
  );
};

export default CorrectionHeader;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@teo/components';

function nameList(files: File[]) {
  const name = (file: File) => `${file.name}`;
  return files.map((file) => name(file));
}

function typeList(files: File[]) {
  const documentType = (file: File) => `${file.type}`;
  return files.map((file) => documentType(file));
}

function FileContainerDropzone({
  files,
  fileInputRef,
  handleUploadPhoto,
}: any) {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fileName = useMemo(() => nameList(files), [files]);
  const documentType = useMemo(() => typeList(files), [files]);

  if (files.length === 0) {
    return null;
  }

  return (
    <div
      className="
    flex
    h-44
    flex-col
    items-center
    justify-center
    rounded-lg
    bg-grey-02 px-3
    py-8
"
    >
      <Spinner
        ariaLabel="Loading spinner"
        className="h-8 w-8 border-secondary-04"
      />
    </div>
  );
}

export default FileContainerDropzone;

import { Tag } from '@teo/components';

export const WidgetHeader = (props) => {
  return (
    <span className="flex items-center">
      <Tag
        variant="secondary"
        size="md"
        title={props.index}
        className={`number_widget mr-2 w-fit bg-grey-02 text-grey-08 ${
          !props.maxScore && 'mt-0.5 mb-1'
        }`}
      />
      {/* <div className="flex-auto">{props.children}</div> */}
      {!props.setTitleWidget ? (
        <>
          {props.titleWidget && (
            <div className="flex-auto">{props.titleWidget}</div>
          )}
          {props.children && <div className="flex-auto">{props.children}</div>}
          {props.maxScore && (
            <div className="flex-initial">
              <span name="score">{props.answered ? props.score || 0 : ''}</span>
              /{props.maxScore}
            </div>
          )}
        </>
      ) : (
        <input
          type="text"
          value={props.titleWidget}
          onChange={({ target: { value } }) => {
            props.setTitleWidget(value);
          }}
          className="w-full border-0 border-b border-transparent p-1 text-base !shadow-none  !ring-0 hover:border-grey-02 focus:border-0 focus:border-b focus:border-grey-02"
        />
      )}
    </span>
  );
};

import { useState, useEffect } from 'react';
import { MultiselectWithTags, Label, Button, ComboBox } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { parseFilter } from '@/util';

const PaginationMultiselectWithTags = ({
  fetch,
  defaultSort,
  render,
  keyFn,
  onChange,
  value,
  setFilter,
  filter,
  addTagLabel,
  handleAddTag,
  ...other
}: any) => {
  const { t } = useTranslation();

  const [localFilter, setLocalFilter] = useState('');
  const myFilter = filter || localFilter;
  const setMyFilter = setFilter || setLocalFilter;

  const [data, setData] = useState<any[]>(value);

  const result: any = fetch({
    offset: 0,
    count: 10,
    sort: defaultSort
      .map((sort: any) => (sort.desc ? '-' : '') + sort.id)
      .join(','),
    q: parseFilter(myFilter),
  });

  useEffect(() => {
    if (result?.data) {
      const newData = (result?.data?.data as any[]).map((x) => {
        Object.assign(x, { label: render(x) });
        return x;
      });
      newData.forEach((x) => (x.key = keyFn ? keyFn(x) : x.id));
      setData(newData);
    }
  }, [result?.data]);

  const handleValue = (newValue: any) => {
    const prevState = value?.find(
      (el: any) => el?.key === newValue[newValue.length - 1]?.key
    );
    prevState
      ? onChange((prev: any) =>
          prev?.filter((item: any) => item?.key !== prevState?.key)
        )
      : onChange(newValue);
  };

  if (value) {
    value = value.map((value: any) =>
      Object.assign({}, value, { label: render(value) })
    );
  }

  return (
    <MultiselectWithTags
      removeButtonLabel="clear"
      toggleSelectionLabel="toggle"
      onChange={(param) => {
        setMyFilter('');
        handleValue(param);
      }}
      onCommitQuery={() => {
        if (data.length >= 1) {
          onChange([...value], data[0]);
        } else {
          handleAddTag && handleAddTag(data[0]);
        }
        setMyFilter('');
      }}
      onRemoved={(value) => {
        setMyFilter('');
        onChange(value);
      }}
      options={data}
      query={myFilter}
      setQuery={(query) => setMyFilter(query)}
      value={value || []}
      addTagLabel={addTagLabel}
      handleAddTag={handleAddTag}
      {...other}
    />
  );
};

export { PaginationMultiselectWithTags };

import { useState } from 'react';
import { IconButton, Dropdown } from '@teo/components';
import { ChevronDown, ChevronUp } from '@teo/components/icons';
import { iconPickerOptionsData } from '@/mocks/iconPickerOptionsData';

function IconPicker({ iconPicker, setIconPicker }: any) {
  const [displayIconPicker, setDisplayIconPicker] = useState(false);

  const handleClick = () => {
    setDisplayIconPicker(true);
  };

  return (
    <>
      <Dropdown>
        <div onClick={handleClick}>
          <Dropdown.Trigger className="flex items-center">
            <span className="flex h-[64px] w-[64px] items-center justify-center rounded-[50%] bg-[#eaeaed]">
              <img
                src={
                  iconPicker
                    ? `/images/icons/${iconPicker}.svg`
                    : '/images/icons/milieu.svg'
                }
                className="h-[25px] w-[25px]"
                alt="icon"
              />
            </span>

            <IconButton
              Icon={displayIconPicker ? ChevronUp : ChevronDown}
              variant="ghost"
            />
          </Dropdown.Trigger>
        </div>
        <Dropdown.Content
          style={{
            width: '183px',
            height: '204px',
            overflow: 'auto',
            left: '10px',
            position: 'fixed',
            top: '452px',
          }}
        >
          <Dropdown.Actions>
            {iconPickerOptionsData.map((el) => (
              <Dropdown.Button
                key={el.id}
                onClick={() => {
                  setDisplayIconPicker(false);
                  setIconPicker(el.icon);
                }}
              >
                <img
                  src={`/images/icons/${el.icon}.svg`}
                  className="mr-3 h-[18px] w-[18px]"
                  alt="icon"
                />
                {el.label}
              </Dropdown.Button>
            ))}
          </Dropdown.Actions>
        </Dropdown.Content>
      </Dropdown>
    </>
  );
}

export default IconPicker;

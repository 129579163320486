import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  InputText,
  Button,
  ButtonLink,
  IconButton,
  Modal,
  FilterList,
  FilterDropdown,
} from '@teo/components';
import { Search, Tag } from '@teo/components/icons';
import { useState, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { DataTable } from '@/components/DataTable/DataTable';
import { format } from 'date-fns';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';

export interface Filter {
  label: string;
  value: string;
}

function CoursestModal({ selected, setSelected, courseType }: any) {
  const { t } = useTranslation();
  const [filterJoined, setFilterJoined] = useState('');
  const [filter, setFilter] = useState('');
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const { organisationId } = useParams();

  const [selectedFilter, setSelectedFilter] = useState<Filter[]>([]);
  const [listCategories, setListCategories] = useState<any>([]);
  const [category, setCategory] = useState<any>();

  const FILTERS = [
    {
      label: t('pages.create_courses.Electricity'),
      value: t('pages.create_courses.Electricity'),
    },
    {
      label: t('pages.create_courses.Base'),
      value: t('pages.create_courses.Base'),
    },
    {
      label: t('pages.create_courses.Technology'),
      value: t('pages.create_courses.Technology'),
    },
    {
      label: t('pages.create_courses.Safety'),
      value: t('pages.create_courses.Safety'),
    },
    {
      label: t('pages.create_courses.Mechanics'),
      value: t('pages.create_courses.Mechanics'),
    },
  ];

  const allCategories: any = useGet(`/categories`, {
    count: 1000,
    sort: 'tag',
    organisationId: `${auth?.user?.activeOrganisationId},OR,organisationId=null`,
    lang: auth?.user?.lang,
  });

  useEffect(() => {
    if (allCategories?.data) {
      const data = (allCategories?.data?.data as any[]).map((tag) => ({
        id: tag?.tag,
        name: tag?.tag,
      }));
      setListCategories(data);
    }
  }, [allCategories?.data]);

  useEffect(() => {
    const selectedTags = selectedFilter?.map((el: any) => el.value + ' ');
    const newFilterJoined =
      selectedTags.join(' ') +
      (filter ? ` ${filter}` : '') +
      (category ? `category:${category?.name}` : '');
    setFilterJoined(newFilterJoined);
  }, [filter, selectedFilter, category]);

  return (
    <div>
      <div className="pb-5 md:w-1/2">
        <InputText
          value={filter}
          onChange={(ev: any) => setFilter(ev.target.value)}
          Icon={Search}
          placeholder={t('search.course')}
        />
      </div>
      <div className="w-full pb-8">
        <div className="flex items-center pb-5">
          <IconButton Icon={Tag} variant="ghost" size="sm" className="mr-2" />
          <FilterList
            filters={FILTERS}
            onSelect={setSelectedFilter}
            selected={selectedFilter}
          />
        </div>
        <div className="relative z-10 flex flex-col">
          <span>
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                setCategory(value);
              }}
              buttonText={`${t('pages.follow_up.all_categories_tag')}`}
              options={listCategories}
              searchable={true}
              value={category}
            />
          </span>
        </div>
      </div>
      <div className="relative max-h-[30vh] overflow-auto rounded-lg border-grey-02 sm:max-h-[35vh] sm:border">
        <DataTable
          screensUpdateTable="mobile"
          classTable="border-0"
          filter={filterJoined}
          fetch={
            courseType === 'instruction'
              ? useGet.bind(null, '/membercoursedetails', {
                  organisationId: authUser?.activeOrganisationId,
                  courseType: courseType,
                  courseStatus: 'active',
                  publicationStatus:
                    'published,OR,publicationStatus=concept,OR,publicationStatus=template',
                })
              : useGet.bind(null, '/myactivecoursecontents', {
                  userId: authUser?.id,
                  masterId: 'null',
                  publicationCourseType: courseType,
                  publicationStatus:
                    'published,OR,publicationStatus=concept,OR,publicationStatus=template',
                })
          }
          empty={{
            template: 2,
            text: t('empty_tenplate.course_list'),
          }}
          selectableRow={{
            selected: true,
            selectedRow: selected,
            setSelected: setSelected,
          }}
          defaultSort={[
            { id: 'createdAt', desc: true },
            { id: 'name', desc: false },
          ]}
          columns={[
            {
              title: t('columns.courseName'),
              id: 'name',
              className: 'col-start-1 col-end-10',
              render: (course: any) => (
                <div className="flex items-center">
                  <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                    <img
                      src={course.image || '/images/courseFallback.png'}
                      alt={course.name}
                      className="inline h-full w-full rounded-lg object-cover sm:h-14"
                    />
                  </div>
                  {course.name}
                </div>
              ),
            },
            {
              addTitleOnMobile: true,
              title: '',
              sortable: false,
              id: 'nrOfStudents',
              className: 'col-start-1 col-end-5 row-start-2',
              align: 'right',
              render: (course: any) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/create/${organisationId}/courses/${
                    courseType !== 'instruction' ? course?.id : course?.masterId
                  }/preview?les=0`}
                  className="relative z-[2]"
                >
                  <Button variant="outline">{t('button.preview')}</Button>
                </a>
              ),
            },
            /*
            {
              addTitleOnMobile: true,
              id: 'nrOfStudents',
              title: t('columns.nrOfStudents'),
              className: 'col-start-1 col-end-5 row-start-2',
              align: 'right',
              render: (course: any) => <div>---{course?.nrOfStudents}</div>,
            },
            */
            {
              addTitleOnMobile: true,
              align: 'right',
              className:
                'col-start-5 col-end-10 row-start-2 text-right justify-end',
              id: 'createdAt',
              title: t('columns.created'),
              classNameDesktop: 'w-[140px]',
              render: (course: any) =>
                format(new Date(course.createdAt), 'dd/MM/yyyy'),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default CoursestModal;

import { useParams } from 'react-router-dom';
import { DataTable } from '@/components/DataTable/DataTable';
import { useState, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { PersonAvatar, InputText, Button } from '@teo/components';
import { Search } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { parseFilter } from '@/util';
import OptionsAction from '@/components/DataTable/OptionsAction';
import DeleteTeamModal from '@/components/DeleteModal/DeleteTeamModal';
import { TheoSearch } from '@teo/components/illustrations';
import { format } from 'date-fns';

function SubTeamTab({
  team,
  setIsOpenAddNewTeamModal,
  setRefetchSubTable,
  refetchSubTable,
}: any) {
  const { t } = useTranslation();

  const [filter, setFilter] = useState('');
  const [subTeams, setSubTeams] = useState<any>(null);
  const [isOpenDeleteTeam, setIsOpenDeleteTeam] = useState(false);
  const [isOpenTeamsDeleteModal, setIsOpenTeamsDeleteModal] = useState(false);

  const [teamDelete, setTeamDelete] = useState<any>();
  const [selectedTeam, setSelectedTeam] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselectTeam, setDeselectTeam] = useState<boolean>(false);
  const { organisationId } = useParams();

  const basePath = window.location.pathname;
  const teamPath = basePath.slice(0, basePath.lastIndexOf('/'));
  const [loade, setLoade] = useState(false);

  const newSubTeams: any = useGet(`/teams`, {
    organisationId,
    searchPath: `${encodeURIComponent(team?.teamPath + ';%')}`,
    q: parseFilter(filter),
  });

  useEffect(() => {
    if (newSubTeams?.data) {
      setSubTeams(newSubTeams?.data?.data || null);
      setLoade(true);
    }
  }, [newSubTeams]);

  return subTeams?.length > 0 ? (
    <div className="w-full max-w-6xl">
      <div className="py-8 md:w-1/2 xl:w-1/3">
        <InputText
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          Icon={Search}
          placeholder={t('search.user')}
        />
      </div>
      <div className="mb-6 flex flex-col justify-between lg:flex-row lg:items-center">
        <div className="mt-4 flex flex-row lg:mt-0">
          {selectedTeam.size > 0 && (
            <Button
              onClick={() => setIsOpenTeamsDeleteModal(true)}
              size="sm"
              variant="outline"
              className="mr-3 !text-error-04"
            >
              {t('pages.manage_users.delete_team')}
            </Button>
          )}
          <Button
            onClick={() => {
              setIsOpenAddNewTeamModal([...selectedTeam][0]);
            }}
            size="sm"
            variant="outline"
            className="mr-3"
          >
            {t('pages.manage_teams.add_sub_team_button')}
          </Button>
        </div>
      </div>

      <DataTable
        filter={filter}
        key={refetchSubTable}
        rowPath={(team) => `${teamPath}/${team.id}`}
        defaultSort={[{ id: 'teamPath', desc: false }]}
        fetch={useGet.bind(null, '/teams', {
          organisationId,
          searchPath: `${encodeURIComponent(team?.teamPath + ';%')}`,
        })}
        indent={(subteam: any) => {
          return (
            subteam.teamPath.replace(team.teamPath + ';;', '').split(';;')
              .length - 1
          );
        }}
        selectable={{
          selected: selectedTeam,
          setSelected: setSelectedTeam,
          deselect: deselectTeam,
          setDeselect: setDeselectTeam,
          checkboxIconMobileType: 'circle',
        }}
        columns={[
          {
            title: t('columns.teamName'),
            id: 'teamPath',
            className: 'col-start-1 col-end-8 row-start-1',
            classNameDesktop: 'max-w-[300px] w-[40%]',
            render: (subteam: any) => (
              <div
                style={{
                  marginLeft:
                    '' +
                    (subteam.teamPath
                      .replace(team.teamPath + ';;', '')
                      .split(';;').length -
                      1) +
                    'rem',
                }}
                className="flex items-center pl-2"
              >
                <PersonAvatar
                  inline
                  id={subteam.id}
                  name={subteam.teamName}
                  bgColor={subteam.color ? subteam.color : null}
                ></PersonAvatar>
                <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                  {subteam.teamName}
                </p>
              </div>
            ),
          },
          {
            addTitleOnMobile: true,
            id: 'nrOfTeamMembers',
            title: t('columns.nrOfTeamMembers'),
            className: 'col-start-1 col-end-6 row-start-2',
            align: 'right',
          },
          {
            addTitleOnMobile: true,
            id: 'createdAt',
            title: t('columns.date_created'),
            align: 'right',
            className:
              'col-start-4 col-end-7 row-start-2 justify-center text-center',
            render: (team: any) =>
              format(new Date(team.createdAt), 'dd/MM/yyyy'),
          },
          {
            title: '',
            id: 'edit',
            sortable: false,
            className: 'col-start-9 row-start-1 justify-end',
            render: (team: any) => (
              <OptionsAction
                delete={{
                  title: t('pages.manage_teams.delete_team'),
                  selected: selectedTeam,
                  setSelected: setSelectedTeam,
                  onClick: () => {
                    setIsOpenDeleteTeam(true);
                    setTeamDelete(team);
                  },
                }}
                setRefetchTable={setRefetchSubTable}
                rowInfo={team}
                pageDetail={{
                  title: t('pages.manage_teams.team_detail'),
                  rowPath: (team) => `${teamPath}/${team.id}`,
                }}
                mobileHeader={{
                  render: (team: any) => (
                    <div className="flex items-center">
                      <PersonAvatar
                        inline
                        id={team.id}
                        name={team.teamName}
                      ></PersonAvatar>
                      <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                        {team.teamName}
                      </p>
                    </div>
                  ),
                }}
              />
            ),
          },
        ]}
      />
      {isOpenDeleteTeam && (
        <DeleteTeamModal
          closeModal={() => setIsOpenDeleteTeam(false)}
          setIsOpenDeleteModal={setIsOpenDeleteTeam}
          title={t('delete_modal.title_team') + teamDelete?.teamName}
          heading={t('delete_modal.heading_team')}
          text={t('delete_modal.text_team')}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedItem={teamDelete}
          setRefetchTable={setRefetchSubTable}
        />
      )}
      {isOpenTeamsDeleteModal && (
        <DeleteTeamModal
          closeModal={() => setIsOpenTeamsDeleteModal(false)}
          setIsOpenDeleteModal={setIsOpenTeamsDeleteModal}
          title={t('delete_modal.title_teams')}
          heading={t('delete_modal.heading_teams')}
          text={t('delete_modal.text_teams')}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          setRefetchTable={setRefetchSubTable}
        />
      )}
    </div>
  ) : (
    <div className="mt-16 flex flex-col items-center">
      {loade ? (
        <>
          <span className="mb-6 pl-16">
            <TheoSearch className="w-48" />
          </span>
          <span className="mb-6 w-[331px] text-center text-base font-semibold text-black">
            {t('pages.manage_teams.no_subteams_text')}
          </span>
          <Button
            onClick={() => {
              setIsOpenAddNewTeamModal(team?.id);
            }}
            size="md"
          >
            {t('pages.manage_teams.add_sub_team_button')}
          </Button>
        </>
      ) : null}
    </div>
  );
}

export default SubTeamTab;

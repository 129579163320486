const OnboardingStudentImg = ({ color, ImgStep }: any) => {
  return (
    <div
      className={`sm:basis-1/2 ${color} order-none flex p-6 sm:order-1 sm:min-h-[100vh]`}
    >
      <ImgStep className="m-auto max-h-[280px] max-w-[280px] sm:max-h-[420px] sm:max-w-[420px]" />
    </div>
  );
};

export { OnboardingStudentImg };

import React, { Component, Fragment, useState } from 'react';
import { T } from '@/components/T';
import update from 'immutability-helper';
import { deep_copy, realParseFloat } from '@/util';
import { InputSelect, InputText, Modal, Button } from '@teo/components';
import i18n from 'i18next';
import { BoxIoService, digitalOutputs, analogInputs } from './BoxIoService';
import { digitalOptions, stringToBool } from '../BoxQuestionEdit';

const modeOptions = [
  {
    value: 'digital',
    label: 'digital',
  },
  {
    value: 'analog',
    label: 'analog',
  },
];

const assertionOptions = [
  {
    value: 'set',
    label: 'set_pin',
  },
  {
    value: 'reset',
    label: 'reset',
  },
];

const BoxCorrectionEditor = (props) => {
  let t = i18n.t;
  const [verifications, setVerifications] = useState(
    props.correction.verifications
  );
  const [assertions, setAssertions] = useState(props.correction.assertions);

  const updateVerification = (i, attrs) => {
    setVerifications(update(verifications, { [i]: { $merge: attrs } }));
  };
  const updateAssertion = (i, attrs) => {
    setAssertions(update(assertions, { [i]: { $merge: attrs } }));
  };

  return (
    <Modal isOpen={true}>
      <div className="fr-view flex-col" style={{ padding: 20, gap: 20 }}>
        <h3 className="modal-title inline">
          <T>widgets.box.corrections</T>
        </h3>
        <div className="mt-4 text-lg font-semibold text-grey-04">
          {t('widgets.box.assertions')} ({t('widgets.widgets_edit.optional')})
        </div>
        <div className="matrix_widget list-style-none">
          {!!(assertions || []).length && (
            <table
              className="borderless-table"
              style={{ width: '100%', borderCollapse: 'collapse' }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>
                    <T>Action</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Pin</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Mode</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Value</T>
                  </th>
                  <th style={{ width: '40px' }}></th>
                </tr>
              </thead>
              <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
                {(assertions || []).map((assertion, i) => (
                  <tr key={'assertion_' + i} style={{ alignItems: 'center' }}>
                    <td style={{ verticalAlign: 'middle' }}>
                      {/*<pre>{JSON.stringify(assertion)}</pre>*/}
                      <InputSelect
                        fullWidth
                        style={{ padding: '0 5px' }}
                        translateLabels
                        size="small"
                        notched={false}
                        options={assertionOptions}
                        value={assertionOptions.find(
                          (y) => y.value === (assertion.action || 'set')
                        )}
                        onChange={(data) => {
                          updateAssertion(i, { action: data.value });
                        }}
                      >
                        {(options) => (
                          <span>{t('widgets.box.' + options.label)}</span>
                        )}
                      </InputSelect>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {assertion.action === 'set' && (
                        <InputText
                          size="small"
                          fullWidth
                          style={{ padding: '0 5px' }}
                          onChange={(ev) => {
                            updateAssertion(i, { pin: ev.target.value });
                          }}
                          value={assertion.pin}
                        />
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {assertion.action === 'set' && (
                        <InputSelect
                          fullWidth
                          style={{ padding: '0 5px' }}
                          translateLabels
                          size="small"
                          notched={false}
                          options={modeOptions}
                          value={modeOptions.find(
                            (y) => y.value === assertion.mode
                          )}
                          onChange={(data) => {
                            updateAssertion(i, { mode: data.value });
                          }}
                        >
                          {(options) => (
                            <span>{t('widgets.box.' + options.label)}</span>
                          )}
                        </InputSelect>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {assertion.mode === 'digital' &&
                        assertion.action === 'set' && (
                          <InputSelect
                            style={{ padding: '0 5px' }}
                            size="small"
                            options={digitalOptions}
                            value={digitalOptions.find(
                              (y) => y.value === `${assertion.value}`
                            )}
                            onChange={(data) => {
                              updateAssertion(i, {
                                value: stringToBool(data.value),
                              });
                            }}
                          >
                            {(options) => <span>{t(options.label)}</span>}
                          </InputSelect>
                        )}
                      {assertion.mode === 'analog' &&
                        assertion.action === 'set' && (
                          <div
                            className="flex"
                            style={{ alignItems: 'center', padding: '5px' }}
                          >
                            <div className="inline">
                              <InputText
                                size="small"
                                type="number"
                                style={{ width: '80px' }}
                                value={assertion.value}
                                onChange={(ev) => {
                                  let newVal = realParseFloat(ev.target.value);
                                  //newVal = Math.min(Math.max(0, newVal), 24)
                                  updateAssertion(i, { value: newVal });
                                }}
                              />
                            </div>
                          </div>
                        )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        size="sm"
                        className="!px-2"
                        onClick={() => {
                          setAssertions(
                            update(assertions, { $splice: [[i, 1]] })
                          );
                        }}
                        variant="ghost"
                      >
                        <img
                          src="/images/icons/trash_icon.svg"
                          alt="delete button"
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <Button
          size="sm"
          className="mt-2 self-start"
          onClick={() => {
            let newAssertion = {
              action: 'set',
              pin: '',
              value: null,
              mode: 'digital',
            };
            setAssertions(update(assertions, { $push: [newAssertion] }));
          }}
        >
          <T>widgets.box.new_assertion</T>&nbsp;
          <i className="fal fa-plus fa-lg"></i>
        </Button>

        <div className="mt-4 text-lg font-semibold text-grey-04">
          {t('widgets.box.verifications')} ({t('widgets.widgets_edit.optional')}
          )
        </div>
        <div className="matrix_widget list-style-none">
          {!!(verifications || []).length && (
            <table
              className="borderless-table"
              style={{ width: '100%', borderCollapse: 'collapse' }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>
                    <T>Pin</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Mode</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Value</T>
                  </th>
                  <th style={{ width: '40px' }}></th>
                </tr>
              </thead>
              <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
                {(verifications || []).map((verification, i) => (
                  <tr
                    key={'verification_' + i}
                    style={{ alignItems: 'center' }}
                  >
                    <td style={{ verticalAlign: 'middle' }}>
                      <InputText
                        size="small"
                        fullWidth
                        style={{ padding: '0 5px' }}
                        onChange={(ev) => {
                          updateVerification(i, { pin: ev.target.value });
                        }}
                        value={verification.pin}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <InputSelect
                        fullWidth
                        style={{ padding: '0 5px' }}
                        translateLabels
                        size="small"
                        notched={false}
                        options={modeOptions}
                        value={modeOptions.find(
                          (y) => y.value === verification.mode
                        )}
                        onChange={(data) => {
                          updateVerification(i, { mode: data.value });
                        }}
                      >
                        {(options) => (
                          <span>{t('widgets.box.' + options.label)}</span>
                        )}
                      </InputSelect>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {verification.mode === 'digital' && (
                        <InputSelect
                          fullWidth
                          style={{ padding: '0 5px' }}
                          translateLabels
                          size="small"
                          notched={false}
                          options={digitalOptions}
                          value={digitalOptions.find(
                            (y) => y.value === `${verification.value}`
                          )}
                          onChange={(data) => {
                            updateVerification(i, {
                              value: stringToBool(data.value),
                            });
                          }}
                        >
                          {(options) => <span>{t(options.label)}</span>}
                        </InputSelect>
                      )}
                      {verification.mode === 'analog' && (
                        <div
                          className="flex"
                          style={{ alignItems: 'center', padding: '5px' }}
                        >
                          <span>
                            <T>widgets.box.min</T>:
                          </span>
                          &nbsp;
                          <div className="inline">
                            <InputText
                              size="small"
                              type="number"
                              style={{ width: '80px' }}
                              value={verification.min}
                              onChange={(ev) => {
                                let newVal = realParseFloat(ev.target.value);
                                updateVerification(i, { min: newVal });
                              }}
                            />
                          </div>
                          <span style={{ marginLeft: 10 }}>
                            <T>widgets.box.max</T>:
                          </span>
                          &nbsp;
                          <div className="inline">
                            <InputText
                              size="small"
                              type="number"
                              style={{ width: '80px' }}
                              value={verification.max}
                              onChange={(ev) => {
                                let newVal = realParseFloat(ev.target.value);
                                updateVerification(i, { max: newVal });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        size="sm"
                        className="!px-2"
                        onClick={() => {
                          setVerifications(
                            update(verifications, { $splice: [[i, 1]] })
                          );
                        }}
                        variant="ghost"
                      >
                        <img
                          src="/images/icons/trash_icon.svg"
                          alt="delete button"
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <Button
          size="sm"
          className="mt-2 self-start"
          onClick={() => {
            let newCorrection = {
              pin: '',
              value: null,
              mode: 'digital',
            };
            setVerifications(update(verifications, { $push: [newCorrection] }));
          }}
        >
          <T>widgets.box.new_verification</T>&nbsp;
          <i className="fal fa-plus fa-lg"></i>
        </Button>

        <div className="flex" style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              props.onUpdate({ verifications, assertions });
              props.onClose();
            }}
            className="btn-prim"
          >
            <T>button.confirm</T>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { BoxCorrectionEditor };

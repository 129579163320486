import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Sidebar, InformationFilled } from '@teo/components/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { IconButton, Dropdown, PersonAvatar, Button } from '@teo/components';
import { NotificationButton } from '@/components/Notifications/NotificationButton';
import { axios } from '@/axios';
import { config } from '@/config';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import i18n from 'i18next';

const LessonHeader = ({
  coursePath,
  student,
  setHideContent,
  hideContent,
  timeDisabled,
  activeLesson,
  timeSpent,
  userId,
  submitFn,
  returnPath,
  isPreview,
  handleReplaceParam,
  nextLesson,
  activeStatus,
  previewHeader,
  couresPreview,
  activeNode,
  setActiveNode,
  isTeacher,
  viewMobile,
  setShowExtraInfo,
  setExtraInfoFullScreen,
  extraInfoFullScreen,
  setActiveExtraInfo,
  lengthExtraInfo,
  iSsmallLaptop,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organisationId } = useParams();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [seconds, setSeconds] = useState(1);
  const [createStatus, setCreateStatus] = useState(false);
  const basePath = window.location.pathname;
  const prevPath = basePath.slice(0, basePath.lastIndexOf('/co'));

  const queryParams = new URLSearchParams(window.location.search);
  const prevPage = queryParams.get('details');
  const newTab = queryParams.get('new_tab');

  const userFollowPage = `/${i18n.language}/follow-up/${organisationId}/users/${userId}`;

  useEffect(() => {
    setCreateStatus(false);
  }, [activeLesson]);

  useEffect(() => {
    if (activeLesson?.timed === 'limited') {
      if (activeLesson?.estimatedTime) {
        timeSpent
          ? setSeconds(activeLesson?.estimatedTime - timeSpent)
          : setSeconds(activeLesson?.estimatedTime);
      }
    }
    if (activeLesson?.timed === 'timed') {
      timeSpent ? setSeconds(timeSpent) : setSeconds(1);
    }
  }, [
    timeSpent,
    activeLesson?.timed,
    activeLesson?.estimatedTime,
    activeLesson,
  ]);

  useEffect(() => {
    if (activeLesson?.timed === 'limited' && !timeDisabled) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
          if (seconds % 30 === 0) {
            sendRequest(activeLesson?.estimatedTime - seconds);
          }
        } else {
          clearInterval(interval);
          sendRequest(activeLesson?.estimatedTime);
          submitFn();
          /*
          submitFn
            ? submitFn(true).then(async () => {
                await handleReplaceParam(nextLesson);
              })
            : handleReplaceParam(nextLesson);
          */
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [activeLesson, seconds, timeDisabled]);

  useEffect(() => {
    if (activeLesson?.timed === 'timed' && !timeDisabled) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
        if (seconds % 30 === 0) {
          sendRequest(seconds);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [activeLesson, seconds, timeDisabled]);

  const formatTime = (totalSeconds: any) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = Math.floor(totalSeconds % 60);

    return `${String(hours).padStart(2, '0')} : ${String(minutes).padStart(
      2,
      '0'
    )} : ${String(remainingSeconds).padStart(2, '0')}`;
  };

  const sendRequest = (totalSeconds: any) => {
    if (activeStatus?.status || createStatus) {
      axios.put(
        `${config.backend}/lessonstatus/${activeLesson?.courseId}/${userId}/${activeLesson?.lessonId}`,
        {
          courseId: activeLesson?.courseId,
          lessonId: activeLesson?.lessonId,
          timeSpent: totalSeconds,
          userId: userId,
        }
      );
    } else {
      axios.post(`${config.backend}/lessonstatuses`, {
        courseId: activeLesson?.courseId,
        lessonId: activeLesson?.lessonId,
        status: 'started',
        userId: userId,
      });
      setCreateStatus(true);
    }
  };

  return (
    <header
      className="sticky top-0 z-[31] flex h-[80px] min-h-16 items-center justify-start border-b border-solid border-grey-02 bg-white p-3"
      data-testid="header"
    >
      {!isPreview && (
        <>
          {!extraInfoFullScreen ? (
            <>
              {!queryParams.get('tincan') && (
                <>
                  <div data-testid="backOnCourse">
                    {((!isMobile && !viewMobile) || hideContent) && (
                      <IconButton
                        className={`my-auto mx-2 ${newTab && '!hidden'}`}
                        Icon={ArrowLeft}
                        variant="outline"
                        onClick={() => {
                          navigate(
                            returnPath
                              ? returnPath
                              : couresPreview
                              ? `${coursePath}?les=${
                                  activeNode?.usesId ? activeNode?.usesId : 0
                                }`
                              : prevPage
                              ? userFollowPage
                              : coursePath
                          );
                        }}
                      />
                    )}
                    {(isMobile || viewMobile) &&
                      !hideContent &&
                      (!previewHeader ? (
                        <IconButton
                          className="my-auto mx-2"
                          Icon={Sidebar}
                          variant="ghost"
                          onClick={() => {
                            if (returnPath) return navigate(returnPath);
                            setHideContent(true);
                            if (couresPreview && !viewMobile) {
                              setActiveNode();
                            }
                          }}
                        />
                      ) : (
                        <IconButton
                          className={`my-auto mx-2  ${newTab && '!hidden'}`}
                          Icon={ArrowLeft}
                          variant="outline"
                          onClick={() =>
                            navigate(
                              returnPath
                                ? returnPath
                                : couresPreview
                                ? `${coursePath}?les=${
                                    activeNode?.usesId ? activeNode?.usesId : 0
                                  }`
                                : prevPage
                                ? userFollowPage
                                : coursePath
                            )
                          }
                        />
                      ))}
                  </div>
                  <b>{isTeacher ? student?.username : null}</b>
                </>
              )}
            </>
          ) : (
            <Button
              className="flex items-center gap-3"
              variant="text"
              onClick={() => {
                setShowExtraInfo(false);
                setExtraInfoFullScreen(false);
                setActiveExtraInfo(null);
              }}
            >
              <ArrowLeft className="h-5 w-5" />
              <p>
                {t('extra_info.back_to_les')} {activeLesson?.name}
              </p>
            </Button>
          )}
        </>
      )}

      {student && (
        <div
          className="relative mr-2 ml-3 h-11 w-11 rounded-full text-center sm:mr-4"
          style={{
            background: `radial-gradient(closest-side, white 85%, transparent 90% 100%),conic-gradient(#3257fc ${
              student.progress ? Math.round(student.progress * 100) : 0
            }%, #1e1e2014 0)`,
          }}
        >
          <p className="pt-3 text-sm text-grey-07">
            {student.progress ? Math.round(student.progress * 100) : 0}&#37;
          </p>
        </div>
      )}
      <div className="ml-auto">
        {activeLesson?.timed === 'limited' && !timeDisabled && (
          <span className="rounded bg-error-01 py-1 px-2 text-sm font-medium text-error-04">
            {formatTime(seconds)}
          </span>
        )}
        {activeLesson?.timed === 'timed' && !timeDisabled && (
          <span className="text-sm font-medium">{formatTime(seconds)}</span>
        )}
      </div>
      {(iSsmallLaptop || viewMobile) &&
        (!hideContent || !isMobile) &&
        lengthExtraInfo > 0 &&
        !activeLesson?.assetId && (
          <div
            className="relative ml-auto"
            onClick={() => setShowExtraInfo((prev: any) => !prev)}
          >
            <InformationFilled className="mr-4 h-6 w-6 text-grey-07" />
            <div className="absolute -top-1 right-3 h-2 w-2 rounded-full bg-error-04"></div>
          </div>
        )}
      {authUser && !isPreview && (
        <div
          className={`${
            !iSsmallLaptop && !viewMobile && 'ml-auto '
          } flex flex-row gap-4`}
        >
          <NotificationButton />
          <Dropdown>
            <Dropdown.Trigger>
              <PersonAvatar
                id={authUser.id}
                name={authUser.username as string}
                imageSrc={authUser.picture || undefined}
              />
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Section className="flex items-center gap-3">
                <PersonAvatar
                  id={authUser.id}
                  name={authUser.username as string}
                  imageSrc={authUser.picture || undefined}
                />
                {authUser?.username}
              </Dropdown.Section>
              <Dropdown.Actions>
                <Dropdown.Link to={'/' + i18n.language + '/profile/details'}>
                  {t('links.profile')}
                </Dropdown.Link>
                <Dropdown.ExternalLink
                  href={t('external_links.help') as string}
                  target="_blank"
                >
                  {t('links.help')}
                </Dropdown.ExternalLink>
                <Dropdown.Button
                  onClick={() => {
                    localStorage.removeItem('token');
                    navigate('/');
                  }}
                >
                  {t('links.logout')}
                </Dropdown.Button>
              </Dropdown.Actions>
            </Dropdown.Content>
          </Dropdown>
        </div>
      )}
    </header>
  );
};

export default LessonHeader;

import React, { Component, useEffect, useState, useContext } from 'react';
import { T } from '@/components/T';
import { FaqSearchField } from './FaqSearchField';
import i18n from 'i18next';
import { Panel, Button, IconButton, IconButtonLink } from '@teo/components';
import { FaqTopic } from './FaqTopic';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowRight, Pencil, ArrowLeft } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';
import { convertToPlain, nthLastIndexOf } from '@/util';
import { faqTopicsDisplay } from '@/constants/faqTopics';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';

const RenderDetail = ({ selectedId }) => {
  const [selected, setSelected] = useState(null);
  const [edutMode, setEditMode] = useState(false);
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;
  const navigate = useNavigate();

  useGet(`/faq/${selectedId}`, {
    enabled: !!selectedId,
    onSuccess: (results) => {
      setSelected(results.data);
    },
  });

  if (!selected) return null;

  return (
    <div className="mt-6 flex flex-col gap-4">
      <h3 className="flex justify-between">
        <T>{selected.question}</T>
        {authUser.role >= 90 && (
          <IconButton
            Icon={Pencil}
            variant="ghost"
            size="sm"
            onClick={() => {
              navigate('./edit');
            }}
          />
        )}
      </h3>
      <div>
        <FroalaTextareaView
          value={selected.answer}
          style={{ minHeight: '350px' }}
        />
      </div>
    </div>
  );
};

const FaqsOverview = () => {
  const [search, setSearch] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [examples, setExamples] = useState([]);
  const { topic, faqId } = useParams();
  const [selectedId, setSelectedId] = useState(null);
  const t = i18n.t;

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 1)
  );

  useGet('/faqs', {
    count: 3,
    lang: i18n.language,
    onSuccess: async (result) => {
      setExamples(result?.data);
    },
  });

  useEffect(() => {
    if (!faqId) {
      setSelectedId(null);
    } else {
      setSelectedId(faqId);
    }
  }, [faqId]);

  useEffect(() => {
    if (search) {
      setSelectedId(null);
    } else {
      setFaqs([]);
    }
  }, [search]);

  return (
    <div className="sane-lists">
      {!selectedId ? (
        <h1 className="my-8 text-xl font-semibold text-black">
          {i18n.t('pages.faq.title')}
          {faqId}
        </h1>
      ) : (
        <div className="my-4 flex flex-row items-center">
          <IconButtonLink
            Icon={ArrowLeft}
            ariaLabel={t('pages.correction_result.prev_lesson')}
            to={basePath}
            variant="ghost"
            size="md"
          />
          {/*<p className="ml-3 w-max lg:block">{t('button.back')}</p>*/}
        </div>
      )}
      <FaqSearchField
        faqs={faqs}
        setFaqs={setFaqs}
        search={search}
        setSearch={setSearch}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        limit={20}
      />
      {selectedId && <RenderDetail selectedId={selectedId} key={selectedId} />}
      {!selectedId && faqs.length === 0 && (
        <Panel className="mt-8 p-4">
          {faqs.length === 0 && !search && !selectedId && (
            <>
              <Panel background="grey">
                <div className="flex h-[150px] items-center">
                  <img
                    className="my-auto mx-8 h-[100px]"
                    src={'/images/search.svg'}
                  ></img>
                  <span>{i18n.t('pages.faq.description')}</span>
                </div>
              </Panel>
              <section className="mt-4 grid grid-cols-3 gap-4 max-[767px]:grid-cols-1">
                {examples.map((faq) => (
                  <Panel
                    key={faq.id}
                    className="flex flex-col justify-between gap-4 break-words"
                  >
                    <div className="flex flex-col gap-4 break-words p-4">
                      <h3>{faq.question}</h3>
                      <p className="flex: 1">
                        {convertToPlain(faq.answer, 175)}
                      </p>
                    </div>
                    <Link
                      to={`/${i18n.language}/help/faqs/${faq.id}`}
                      className="align-center flex p-4 text-sm font-normal text-[#1173dd]"
                    >
                      <span className="text-[#7d7d81]">
                        {i18n.t('pages.faq.read_more')}
                      </span>
                      <ArrowRight className="ml-4 w-6 text-[#7D7D81]" />
                    </Link>
                  </Panel>
                ))}
              </section>
              <div className="mt-4 flex flex-col gap-4">
                {faqTopicsDisplay.map((topic) => (
                  <FaqTopic key={topic} topic={topic} />
                ))}
              </div>
            </>
          )}
          {!selectedId && faqs.length === 0 && search && (
            <>
              <Panel background="grey">
                <div className="flex h-[500px] flex-col items-center justify-center gap-4">
                  <img
                    className="mx-8 h-[300px]"
                    src={'/images/notfound.svg'}
                  ></img>
                  <p>
                    <T>pages.faq.notfound</T>
                  </p>
                  <Button variant="outline" onClick={() => setSearch('')}>
                    <T>button.back</T>
                  </Button>
                </div>
              </Panel>
            </>
          )}
        </Panel>
      )}
    </div>
  );
};

export default FaqsOverview;

import React, { Component, Fragment, useState } from 'react';
import { T } from '@/components/T';
import update from 'immutability-helper';
import { deep_copy, realParseFloat } from '@/util';
import {
  InputSelect,
  InputText,
  InputTextarea,
  Modal,
  Button,
} from '@teo/components';
import i18n from 'i18next';
import { BoxIoService, digitalOutputs, analogInputs } from './BoxIoService';
import { digitalOptions, stringToBool } from '../BoxQuestionEdit';

const modeOptions = [
  {
    value: 'digital',
    label: 'Digital',
  },
  {
    value: 'analog',
    label: 'Analog',
  },
];

const frequenctieOptions = [
  {
    value: 'once',
    label: 'once',
  },
  {
    value: 'permanent',
    label: 'permanent',
  },
];

const actionOptions = [
  {
    value: 'set',
    label: 'Set pin',
  },
  {
    value: 'reset',
    label: 'Reset controller',
  },
];

const BoxTriggerEditor = (props) => {
  let t = i18n.t;
  const [actions, setActions] = useState(props.trigger.actions);
  const [conditions, setConditions] = useState(props.trigger.conditions);
  const [script, setScript] = useState(props.trigger.script);

  const updateCondition = (i, attrs) => {
    setConditions(update(conditions, { [i]: { $merge: attrs } }));
  };
  const updateAction = (i, attrs) => {
    setActions(update(actions, { [i]: { $merge: attrs } }));
  };
  const updateScript = (content) => {
    setScript(content);
  };

  return (
    <Modal isOpen={true}>
      <div className="fr-view flex-col" style={{ padding: 20, gap: 20 }}>
        <h3 className="modal-title inline">
          <T>widgets.box.triggers</T>
        </h3>
        <div className="mt-4 text-lg font-semibold text-grey-04">
          {t('widgets.box.conditions')} ({t('widgets.widgets_edit.optional')})
        </div>
        <div className="matrix_widget list-style-none">
          {!!(conditions || []).length && (
            <table
              className="borderless-table"
              style={{ width: '100%', borderCollapse: 'collapse' }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>
                    <T>Pin</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Mode</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Value</T>
                  </th>
                  <th style={{ width: '40px' }}></th>
                </tr>
              </thead>
              <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
                {(conditions || []).map((condition, i) => (
                  <tr key={'trigger_' + i} style={{ alignItems: 'center' }}>
                    <td style={{ verticalAlign: 'middle' }}>
                      <InputText
                        size="small"
                        fullWidth
                        style={{ padding: '0 5px' }}
                        onChange={(ev) => {
                          updateCondition(i, { pin: ev.target.value });
                        }}
                        value={condition.pin}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <InputSelect
                        fullWidth
                        style={{ padding: '0 5px' }}
                        translateLabels
                        size="small"
                        notched={false}
                        options={modeOptions}
                        value={modeOptions.find(
                          (y) => y.value === condition.mode
                        )}
                        onChange={(data) => {
                          updateCondition(i, { mode: data.value });
                        }}
                      >
                        {(options) => <span>{t(options.label)}</span>}
                      </InputSelect>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {condition.mode === 'digital' && (
                        <InputSelect
                          fullWidth
                          style={{ padding: '0 5px' }}
                          translateLabels
                          size="small"
                          notched={false}
                          options={digitalOptions}
                          value={digitalOptions.find(
                            (y) => y.value === `${condition.value}`
                          )}
                          onChange={(data) => {
                            updateCondition(i, {
                              value: stringToBool(data.value),
                            });
                          }}
                        >
                          {(options) => <span>{t(options.label)}</span>}
                        </InputSelect>
                      )}
                      {condition.mode === 'analog' && (
                        <div
                          className="flex"
                          style={{ alignItems: 'center', padding: '5px' }}
                        >
                          <span>
                            <T>min</T>:
                          </span>
                          &nbsp;
                          <div className="inline">
                            <InputText
                              size="small"
                              type="number"
                              style={{ width: '80px' }}
                              value={condition.min}
                              onChange={(ev) => {
                                let newVal = realParseFloat(ev.target.value);
                                updateCondition(i, { min: newVal });
                              }}
                            />
                          </div>
                          <span style={{ marginLeft: 10 }}>
                            <T>max</T>:
                          </span>
                          &nbsp;
                          <div className="inline">
                            <InputText
                              size="small"
                              type="number"
                              style={{ width: '80px' }}
                              value={condition.max}
                              onChange={(ev) => {
                                let newVal = realParseFloat(ev.target.value);
                                updateCondition(i, { max: newVal });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        size="sm"
                        className="!px-2"
                        onClick={() => {
                          setConditions(
                            update(conditions, { $splice: [[i, 1]] })
                          );
                        }}
                        variant="ghost"
                      >
                        <img
                          src="/images/icons/trash_icon.svg"
                          alt="delete button"
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <Button
          size="sm"
          className="mt-2 self-start"
          onClick={() => {
            let newTrigger = {
              pin: '',
              value: null,
              mode: 'digital',
            };
            setConditions(update(conditions, { $push: [newTrigger] }));
          }}
        >
          <T>widgets.box.new_condition</T>&nbsp;
          <i className="fal fa-plus fa-lg"></i>
        </Button>

        <div className="mt-4 text-lg font-semibold text-grey-04">
          {t('widgets.box.actions')} ({t('widgets.widgets_edit.optional')})
        </div>
        <div className="matrix_widget list-style-none">
          {!!(actions || []).length && (
            <table
              className="borderless-table"
              style={{ width: '100%', borderCollapse: 'collapse' }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>
                    <T>Action</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Pin</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Mode</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Frequency</T>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <T>Value</T>
                  </th>
                  <th style={{ width: '40px' }}></th>
                </tr>
              </thead>
              <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
                {(actions || []).map((action, i) => (
                  <tr key={'trigger_' + i} style={{ alignItems: 'center' }}>
                    <td style={{ verticalAlign: 'middle' }}>
                      <InputSelect
                        fullWidth
                        style={{ padding: '0 5px' }}
                        translateLabels
                        size="small"
                        notched={false}
                        options={actionOptions}
                        value={actionOptions.find(
                          (y) => y.value === action.action
                        )}
                        onChange={(data) => {
                          updateAction(i, { action: data.value });
                        }}
                      >
                        {(options) => <span>{t(options.label)}</span>}
                      </InputSelect>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {action.action === 'set' && (
                        <InputText
                          size="small"
                          fullWidth
                          style={{ padding: '0 5px' }}
                          onChange={(ev) => {
                            updateAction(i, { pin: ev.target.value });
                          }}
                          value={action.pin}
                        />
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {action.action === 'set' && (
                        <InputSelect
                          fullWidth
                          style={{ padding: '0 5px' }}
                          translateLabels
                          size="small"
                          notched={false}
                          options={modeOptions}
                          value={modeOptions.find(
                            (y) => y.value === action.mode
                          )}
                          onChange={(data) => {
                            updateAction(i, { mode: data.value });
                          }}
                        >
                          {(options) => <span>{t(options.label)}</span>}
                        </InputSelect>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {action.action === 'set' && (
                        <InputSelect
                          fullWidth
                          style={{ padding: '0 5px' }}
                          translateLabels
                          size="small"
                          notched={false}
                          options={frequenctieOptions}
                          value={frequenctieOptions.find(
                            (y) => y.value === `${action.frequency}`
                          )}
                          onChange={(data) => {
                            updateAction(i, { frequency: data.value });
                          }}
                        >
                          {(options) => <span>{t(options.label)}</span>}
                        </InputSelect>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {action.mode === 'digital' && action.action === 'set' && (
                        <InputSelect
                          fullWidth
                          style={{ padding: '0 5px' }}
                          translateLabels
                          size="small"
                          notched={false}
                          options={digitalOptions}
                          value={digitalOptions.find(
                            (y) => y.value === `${action.value}`
                          )}
                          onChange={(data) => {
                            updateAction(i, {
                              value: stringToBool(data.value),
                            });
                          }}
                        >
                          {(options) => <span>{t(options.label)}</span>}
                        </InputSelect>
                      )}
                      {action.mode === 'analog' && action.action === 'set' && (
                        <div
                          className="flex"
                          style={{ alignItems: 'center', padding: '5px' }}
                        >
                          <div className="inline">
                            <InputText
                              size="small"
                              type="number"
                              style={{ width: '80px' }}
                              value={action.value}
                              onChange={(ev) => {
                                let newVal = realParseFloat(ev.target.value);
                                //newVal = Math.min(Math.max(0, newVal), 24)
                                updateAction(i, { value: parseFloat(newVal) });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        size="sm"
                        className="!px-2"
                        onClick={() => {
                          setActions(update(actions, { $splice: [[i, 1]] }));
                        }}
                        variant="ghost"
                      >
                        <img
                          src="/images/icons/trash_icon.svg"
                          alt="delete button"
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <Button
          size="sm"
          className="mt-2 self-start"
          onClick={() => {
            let newAction = {
              action: 'set',
              pin: '',
              value: null,
              mode: 'digital',
              frequency: 'once',
            };
            setActions(update(actions, { $push: [newAction] }));
          }}
        >
          <T>widgets.box.new_action</T>&nbsp;
          <i className="fal fa-plus fa-lg"></i>
        </Button>

        <div className="mt-4 text-lg font-semibold text-grey-04">
          {t('widgets.box.script')} ({t('widgets.widgets_edit.optional')})
        </div>
        <InputTextarea
          id="outlined-multiline-static"
          label={t('Script')}
          value={script}
          onChange={(ev) => updateScript(ev.target.value)}
          multiline
          rows={4}
        />
        <div>
          <Button
            size="sm"
            onClick={() => {
              BoxIoService.getInstance().runScript(script);
            }}
            className="btn-prim mt-2"
          >
            <T>widgets.box.test_script</T>
          </Button>
        </div>

        <div className="flex" style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              props.onUpdate({ conditions, actions, script });
              props.onClose();
            }}
            className="btn-prim"
          >
            <T>button.confirm</T>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { BoxTriggerEditor };

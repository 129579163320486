import { Button, IconButton } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  CrossFilled,
  Document,
  ArrowLeft,
  Chapter,
  OpenExternalLink,
} from '@teo/components/icons';
import { LessonExtraInfoTree } from './LessonExtraInfoTree';
import { LessonExtraInfoView } from './LessonExtraInfoView';
import { newUid } from '@/util';
import { ImageZoom } from '../ImageZoom';
import { initMath } from '../Froala/widgets/MathView';

const FullScreen = ({ className }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3438 11.5C16.8184 11.5 17.2031 11.8848 17.2031 12.3594V14.1562C17.2031 14.9643 16.8821 15.7393 16.3107 16.3107C15.7393 16.8821 14.9643 17.2031 14.1562 17.2031H12.3594C11.8848 17.2031 11.5 16.8184 11.5 16.3438C11.5 15.8691 11.8848 15.4844 12.3594 15.4844H14.1562C14.5085 15.4844 14.8463 15.3444 15.0954 15.0954C15.3444 14.8463 15.4844 14.5085 15.4844 14.1562V12.3594C15.4844 11.8848 15.8691 11.5 16.3438 11.5Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 1.65625C11.5 1.18163 11.8848 0.796875 12.3594 0.796875H14.1562C14.9643 0.796875 15.7393 1.11788 16.3107 1.68928C16.8821 2.26068 17.2031 3.03567 17.2031 3.84375V5.64062C17.2031 6.11524 16.8184 6.5 16.3438 6.5C15.8691 6.5 15.4844 6.11524 15.4844 5.64062V3.84375C15.4844 3.49151 15.3444 3.1537 15.0954 2.90462C14.8463 2.65555 14.5085 2.51562 14.1562 2.51562H12.3594C11.8848 2.51562 11.5 2.13087 11.5 1.65625Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.65625 11.5C2.13087 11.5 2.51562 11.8848 2.51562 12.3594V14.1562C2.51562 14.5085 2.65555 14.8463 2.90462 15.0954C3.1537 15.3444 3.49151 15.4844 3.84375 15.4844H5.64062C6.11524 15.4844 6.5 15.8691 6.5 16.3438C6.5 16.8184 6.11524 17.2031 5.64062 17.2031H3.84375C3.03567 17.2031 2.26068 16.8821 1.68928 16.3107C1.11788 15.7393 0.796875 14.9643 0.796875 14.1562V12.3594C0.796875 11.8848 1.18163 11.5 1.65625 11.5Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.84375 2.51562C3.49151 2.51562 3.1537 2.65555 2.90462 2.90462C2.65555 3.1537 2.51562 3.49151 2.51562 3.84375V5.64062C2.51562 6.11524 2.13087 6.5 1.65625 6.5C1.18163 6.5 0.796875 6.11524 0.796875 5.64062V3.84375C0.796875 3.03567 1.11788 2.26068 1.68928 1.68928C2.26068 1.11788 3.03567 0.796875 3.84375 0.796875H5.64062C6.11524 0.796875 6.5 1.18163 6.5 1.65625C6.5 2.13087 6.11524 2.51562 5.64062 2.51562H3.84375Z"
      fill="#231F20"
    />
  </svg>
);

export const LesoonExtraInfo = ({
  setShowExtraInfo,
  nameLes,
  course,
  lessonExtraInfa,
  parentExtraInfa,
  rootExtraInfa,
  setExtraInfoFullScreen,
  extraInfoFullScreen,
  setActiveExtraInfo,
  activeExtraInfo,
  lengthExtraInfo,
  preview,
  isMobile,
  viewMobile,
}) => {
  const { t } = useTranslation();
  const [imageZoom, setImageZoom] = useState(false);

  const basePath = window.location.pathname;

  const handleClose = async () => {
    setExtraInfoFullScreen(false);
    setActiveExtraInfo(null);
    setShowExtraInfo(false);
  };
  useEffect(() => {
    initMath();
  }, []);

  return (
    <div
      className={`inset-0 flex bg-black/50 ${
        isMobile
          ? ' z-[20] pt-[0px] '
          : imageZoom
          ? ' z-[20] pt-[80px] '
          : ' z-[10] pt-[80px] '
      }
      ${viewMobile ? ' absolute -top-[80px] z-[100] pt-0 ' : ' fixed '}`}
      id="extra-info"
    >
      <div
        className={`flex-auto ${viewMobile && ' hidden '}`}
        onClick={() => handleClose()}
      ></div>
      <div
        className={`flex h-full w-full max-w-[380px] flex-col bg-white ${
          !activeExtraInfo
            ? isMobile
              ? ' max-w-full '
              : ' max-w-[380px] '
            : extraInfoFullScreen
            ? ' min-w-full max-w-full overflow-y-auto p-6 '
            : isMobile
            ? ' max-w-full '
            : ' min-w-[700px] max-w-[40%] '
        } ${viewMobile && ' max-w-full '}`}
      >
        {extraInfoFullScreen && isMobile && (
          <div className={`flex items-center pt-3 pb-5`}>
            {activeExtraInfo && (
              <Button
                className={`flex items-center gap-3`}
                variant="text"
                onClick={() => {
                  setActiveExtraInfo(null);
                  setExtraInfoFullScreen(false);
                }}
              >
                <ArrowLeft className="h-5 w-5" />
              </Button>
            )}
          </div>
        )}
        {!extraInfoFullScreen && (
          <div
            className={`flex items-center px-3 ${
              isMobile ? ' pt-8 pb-4' : ' border-b py-2 '
            }`}
          >
            {!activeExtraInfo ? (
              <h4 className={`${isMobile ? 'ml-auto' : ''}`}>
                {t('extra_info.title')}
                {isMobile && (
                  <span className="pl-1 text-grey-05">({lengthExtraInfo})</span>
                )}
              </h4>
            ) : (
              <Button
                name="return_extra_info"
                className={`flex items-center gap-3 ${isMobile && 'ml-3'}`}
                variant="text"
                onClick={() => setActiveExtraInfo(null)}
              >
                <ArrowLeft className="h-5 w-5" />
                {!isMobile && <p>{t('extra_info.back_to_ei')}</p>}
              </Button>
            )}

            {(!isMobile || !activeExtraInfo) && (
              <IconButton
                name="close_extra_info"
                className="my-auto ml-auto"
                Icon={CrossFilled}
                variant="ghost"
                onClick={() => {
                  handleClose();
                }}
              />
            )}
          </div>
        )}
        {!activeExtraInfo ? (
          <div
            className={`flex h-full flex-col overflow-y-auto p-3`}
            id="extra-info-tree"
          >
            {lessonExtraInfa?.length > 0 && (
              <>
                <div className="flex items-center">
                  <Document className="m-2 w-6 min-w-6 text-grey-07" />

                  <h4>{nameLes}</h4>
                </div>
                <LessonExtraInfoTree
                  extraInfoTree={lessonExtraInfa}
                  setActiveExtraInfo={setActiveExtraInfo}
                />
              </>
            )}

            {parentExtraInfa?.map((les, index) => {
              return (
                <div key={les?.chapter?.id} className="flex flex-col">
                  {les?.extraInfo?.length > 0 && (
                    <>
                      <div className="flex items-center">
                        <Chapter className="m-2 w-6 min-w-6 text-grey-07" />

                        <h4>{les?.chapter?.name}</h4>
                      </div>
                      <LessonExtraInfoTree
                        extraInfoTree={les?.extraInfo}
                        setActiveExtraInfo={setActiveExtraInfo}
                      />
                    </>
                  )}
                </div>
              );
            })}
            {rootExtraInfa?.length > 0 && (
              <>
                <div className="flex items-center">
                  <Chapter className="m-2 w-6 min-w-6 text-grey-07" />

                  <h4>{t('links.course')}</h4>
                </div>
                <LessonExtraInfoTree
                  extraInfoTree={rootExtraInfa}
                  setActiveExtraInfo={setActiveExtraInfo}
                />
              </>
            )}
          </div>
        ) : (
          <div
            className={`  ${
              extraInfoFullScreen
                ? ' mx-auto w-full max-w-2xl '
                : isMobile
                ? ' overflow-y-auto px-6 pt-2 pb-6'
                : ' overflow-y-auto p-6 '
            }`}
          >
            <div className="mb-4 flex">
              {!extraInfoFullScreen && !isMobile && (
                <div className="ml-auto">
                  <IconButton
                    Icon={FullScreen}
                    variant="outline"
                    onClick={() => setExtraInfoFullScreen(true)}
                  />
                  {!preview && (
                    <IconButton
                      Icon={OpenExternalLink}
                      className="ml-4"
                      variant="outline"
                      onClick={() =>
                        activeExtraInfo?.id &&
                        window.open(
                          basePath +
                            '/extraInfo/' +
                            (activeExtraInfo?.lessonId ||
                              activeExtraInfo?.assetId)
                        )
                      }
                    />
                  )}
                </div>
              )}
            </div>
            {activeExtraInfo?.lessonId ? (
              <LessonExtraInfoView activeExtraInfo={activeExtraInfo} />
            ) : (
              <div className="flex h-full w-full items-start py-3">
                {activeExtraInfo?.assetType == 'image' ? (
                  <ImageZoom
                    activeExtraInfo={activeExtraInfo}
                    extraInfoFullScreen={extraInfoFullScreen}
                    setImageZoom={setImageZoom}
                    imageZoom={imageZoom}
                  />
                ) : (
                  <div id={`${newUid(20)}_embed`} className="mx-auto w-full">
                    <iframe
                      id={`${newUid(20)}_placeholder`}
                      width="640"
                      height="360"
                      className="w-full"
                      src={activeExtraInfo?.assetPath}
                    ></iframe>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

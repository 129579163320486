import { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useGet } from '@/query/fetchHooks';
import { useParams } from 'react-router-dom';
import { LessonView } from '@/components/LessonView';
import LessonHeader from '@/pages/Protected/Learn/Courses/Lessons/LessonHeader';
import { nthLastIndexOf, parseQueryString, newUid } from '@/util';
import { ImageZoom } from '@/components/ImageZoom';
import { clsx } from 'clsx';

function PreviewAsset({
  returnPath = undefined,
}: {
  returnPath?: undefined | number | string;
}) {
  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );
  const { assetId } = useParams();
  const { setNavDisabled } = useContext(NavContext);
  const { auth, setAuth } = useContext(AuthContext);
  const userId = auth?.user?.id;
  const authUser = auth?.user;
  const [imageZoom, setImageZoom] = useState(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [activeNode, setActiveNode] = useState<any>(null);

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };
  const videoLink = queries['videoLink'] === 'true';

  useGet<any>(`/asset/${assetId}`, {
    onSuccess: (result: any) => {
      setActiveNode(result?.data || null);
    },
  });

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  }, []);

  if (!activeNode) return null;

  return (
    <div className="flex min-h-screen flex-col">
      <LessonHeader
        returnPath={returnPath}
        authUser={authUser}
        setAuth={setAuth}
        userId={userId}
        coursePath={basePath}
        previewHeader={true}
      />
      <div className="flex grow flex-col p-4">
        <h2 className="mb-4">{activeNode.assetName}</h2>
        <div className="fr-view w-full max-w-2xl">
          {activeNode?.assetType == 'image' ? (
            <>
              <ImageZoom
                activeExtraInfo={activeNode}
                extraInfoFullScreen={false}
                setImageZoom={setImageZoom}
                imageZoom={imageZoom}
                onLoad={() => setLoaded(true)}
              />
              <div className={clsx(loaded && 'page-loaded')}></div>
            </>
          ) : (
            <>
              {videoLink ? (
                <>
                  Video:{'\u00A0'}
                  <a href={activeNode?.assetPath}>{activeNode?.assetPath}</a>
                  <div className="page-loaded"></div>
                </>
              ) : (
                <div id={`${newUid(20)}_embed`} className="mx-auto">
                  <iframe
                    id={`${newUid(20)}_placeholder`}
                    width="640"
                    height="360"
                    className=""
                    src={activeNode?.assetPath}
                    onLoad={() => setLoaded(true)}
                  ></iframe>
                  <div className={clsx(loaded && 'page-loaded')}></div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreviewAsset;

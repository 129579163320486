import { FilterDropdown, InputText, Tag } from '@teo/components';
import { Search } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@/components/DataTable/DataTable';

function AddCompetencyStep({ props, fetch }: any) {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="text-base font-semibold">
        {t('pages.manage_competencies.add_competencies')}
      </h1>

      <p className="mt-2 text-sm font-normal text-[#7d7d81]">
        {t('pages.manage_competencies.add_competencies_subtitle')}
      </p>

      <form
        className="w-full flex-col max-md:h-full max-md:overflow-hidden"
        data-testid="addCompetencyProfileFormNextStep"
      >
        <div className="w-[75%] py-8">
          <InputText
            value={props.filter}
            onChange={(ev) => props.setFilter(ev.target.value)}
            Icon={Search}
            placeholder={t('pages.manage_competencies.search_placeholder_2')}
          />
        </div>

        <div className="mb-8 flex flex-row">
          <span className="pr-2">
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                props.setAllCategories(value);
              }}
              buttonText={`${t('pages.manage_competencies.all_categories')}`}
              options={props?.categories}
              searchable={true}
              value={props.allCategories}
            />
          </span>
          <span className="pr-2">
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                props.setAllTypes(value);
              }}
              buttonText={`${t('pages.manage_competencies.all_types')}`}
              options={props?.competencyType}
              searchable={false}
              value={props.allTypes}
            />
          </span>
        </div>

        <DataTable
          filter={props.filter}
          key={props.refetchTable}
          fetch={fetch}
          selectable={{
            selected: props.selectedRow,
            setSelected: props.setSelectedRow,
            deselect: props.deselect,
            setDeselect: props.setDeselect,
          }}
          columns={[
            {
              addTitleOnMobile: true,
              title: t('columns.category'),
              id: 'categoryId',
              className: 'col-start-2 col-end-6 break-all row-start-1',
              sortable: false,
              render: (item: any) => {
                const cat =
                  item?.categoryId &&
                  props?.categories?.find(
                    (el: any) => el.id == item?.categoryId
                  );
                return (
                  <>
                    {cat ? (
                      cat?.name
                    ) : (
                      <p className="text-xs text-grey-05">
                        {t('pages.manage_competencies.category_no')}
                      </p>
                    )}
                  </>
                );
              },
            },
            {
              addTitleOnMobile: true,
              title: t('columns.competence'),
              id: 'competence',
              className: 'col-start-1 col-end-10 row-start-2',
              classNameDesktop: 'max-w-[250px]',
              sortable: false,
              render: (item: any) => {
                return (
                  <div className="break-all">
                    {item.path.split(';;').length > 2
                      ? item.path.split(';;')[1]
                      : item.path.split(';;')[0]}
                  </div>
                );
              },
            },
            {
              addTitleOnMobile: true,
              title: t('columns.sub_competence'),
              id: 'subCompetence',
              className: 'col-start-1 col-end-10 row-start-3',
              classNameDesktop: 'max-w-[250px]',
              sortable: false,
              render: (item: any) => {
                let replacedString = item.path.replace(
                  item.path.split(';;').length > 2
                    ? /^[^;]*;;([^;]+;;)/
                    : /^[^;]*;;/,
                  ''
                );
                replacedString = replacedString.replace(/;;+$/, '');
                replacedString = replacedString.replace(/;;+/g, ' / ');
                return <div className="break-all">{replacedString}</div>;
              },
            },
            {
              addTitleOnMobile: true,
              id: 'type',
              title: t('columns.type'),
              sortable: false,
              className:
                'col-start-6 col-end-10 row-start-1 justify-end text-right',
              classNameDesktop: 'min-w-[90px]',
              render: (item: any) => {
                let typeLabel;
                let typeVariant = true;
                switch (item?.type) {
                  case 'skill':
                    typeLabel = t('pages.manage_competencies.type_skill');
                    break;
                  case 'attitude':
                    typeLabel = t('pages.manage_competencies.type_attitude');
                    break;
                  case 'knowledge':
                    typeLabel = t('pages.manage_competencies.type_knowledge');
                    break;
                  default:
                    typeLabel = t('pages.manage_competencies.type_no');
                    typeVariant = false;
                    break;
                }

                return (
                  <Tag
                    title={typeLabel}
                    variant={typeVariant ? 'success' : 'error'}
                  />
                );
              },
            },
          ]}
        />
      </form>
    </>
  );
}

export default AddCompetencyStep;

import React, { useState, useEffect } from 'react';
import {
  isEmpty,
  stringRelativeDistance,
  getCharForNumber,
  useMobile,
  getSafe,
  deep_copy,
  newUid,
  round2,
  focusWidget,
  shuffle,
} from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
//import { usePreview } from 'react-dnd-preview';
import { MoreVertical, Check } from '@teo/components/icons';
import { IconButton, InputSelect } from '@teo/components';
import { clsx } from 'clsx';
import { FeedbackSection } from '../FeedbackSection';
import { encode } from '@/base64';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { genericXapiSubmit } from './common';

export const pastels = [
  '#C25FF4',
  '#E3BF00',
  '#6EC6C0',
  '#F25850',
  '#29BAFF',
  '#AE7226',
  '#1173DD',
  '#9AD055',
  '#807EFF',
  '#FF8EA4',
];

export const NamePartsView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  isCorrected,
  viewOnly,
  setAnswerFn = undefined,
  setXApiSubmitFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  NamePartsView.syncStates(state, answer, correction);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [modified, setModified] = useState(null);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [score, setScore] = useState(correction?.score);
  const answered = submitted || isCorrected;
  const [viewMobile, setViewMobile] = useState(false);

  const [randomOrder] = useState(shuffle(state?.labels));

  useEffect(() => {
    if (submitted) {
      let answer = getAnswer();
      let correction = {};
      NamePartsView.syncStates(state, answer, correction);
      if (!answer?.answered) {
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  //This makes whole body tag a dropzone, used to properly handled dropped outside events
  const [_, bodyDropRef] = useDrop(() => ({
    accept: 'label',
  }));

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  useEffect(() => {
    bodyDropRef(document.body);
    return () => {
      bodyDropRef(null);
    };
  }, []);
  //end

  const DraggableComponent = (props) => {
    const [collected, drag, dragPreview] = useDrag(() => ({
      type: 'label',
      canDrag: !answered
        ? (item) => {
            const canDrag = props.canDrag ? props.canDrag() : true;
            if (canDrag && props.labelIndex !== undefined) {
              handleDrag(props.labelIndex);
            }
            return canDrag;
          }
        : !answered,
      end: (dropResult, monitor) => {
        handleDropOutside(dropResult);
      },
      item: {
        label: props.label,
        itemIndex: props.itemIndex,
        labelIndex: props.labelIndex,
      },
    }));
    return collected.isDragging ? (
      <div id={props.id} ref={dragPreview}>
        {props.children}
      </div>
    ) : (
      <div id={props.id} ref={drag} {...collected}>
        {props.children}
      </div>
    );
  };

  const Dropzone = (props) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: 'label',
      drop: (item) => {
        handleDrop(props.index, item);
      },
      canDrop(item) {
        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }));
    return (
      <div
        ref={drop}
        key={isOver}
        className={
          isOver
            ? 'rounded-md border-2 border-dashed border-grey-09'
            : undefined
        }
      >
        {props.children}
      </div>
    );
  };

  const isMobile = useMobile();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const viewVersion = queryParams.get('view');
    if (viewVersion == 'mobile') {
      setViewMobile(true);
    }
  }, []);

  const [answers, setAnswers] = useState(
    showAnswers ? state.labels : answer.labels
  );
  const [labelsAnswer, setLabelsAnswer] = useState(
    getSafe(() => answer.labels, [])
  );

  const getAnswer = () => {
    answer.labels = labelsAnswer;
    const hasNonEmptyLabels = labelsAnswer.some((item) => item.text !== '');
    answer.incomplete = !submitted && !hasNonEmptyLabels;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  const getCorrection = () => {
    let correction = {};
    NamePartsView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = async (lessonId, lang, minScore = 0.5) => {
    let correction = {};
    let answer = await getAnswer();
    NamePartsView.syncStates(state, answer, correction);

    let correctlabels = answer.labels
      .map((x) => (x.isCorrect ? x : null))
      .filter((x) => x);
    let inCorrectlabels = answer.labels
      .map((x) => (x.isCorrect ? null : x))
      .filter((x) => x);

    const answerStr =
      `${t('widgets.name_parts.user_correct')}: ${correctlabels
        .map((x) => x.text)
        .join(', ')}\n` +
      `${t('widgets.name_parts.user_incorrect')}: ${inCorrectlabels
        .map((x) => x.text)
        .join(', ')}`;

    const correctStr = state.labels.map((x) => x.text).join(', ');

    return genericXapiSubmit(
      state,
      answer,
      correction,
      answerStr,
      correctStr,
      lessonId,
      lang,
      minScore
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  useEffect(() => {
    onModified && onModified();
  }, [labelsAnswer, onModified]);

  let _usedLabels = new Set();
  let labelStrings = answer.labels.map((x) => x.text);
  for (let label of answer.labels) {
    if (label.text) {
      const index = labelStrings.find((x) => x === label.text);
      _usedLabels.add(index);
    }
  }
  const [usedLabels, setUsedLabels] = useState(_usedLabels);

  useEffect(() => {
    const resultSet = new Set();
    if (usedLabels?.size > 0) {
      randomOrder.forEach((item, index) => {
        if (usedLabels.has(item.text)) {
          resultSet.add(index);
        }
      });
    }
    setUsedLabels(resultSet);
  }, []);

  // const answered = false; //for future correction purposes

  // let viewOnly = answered;
  let stateLabels = randomOrder.map((x) => ({ ...x, used: false }));
  let answerLabels = answers.map((x) => x.text);

  let stateLabelOptions = stateLabels
    .map((x, i) => ({ label: x.text, value: i, used: x.used }))
    .filter((x) => !x.used);

  for (let i in answerLabels) {
    let label = answerLabels[i];
    let index = stateLabels.findIndex((x) => x.text == label);
    if (index !== -1) {
      stateLabels[index].used = true;
    }
  }

  const handleDrop = (newLabelIndex, item) => {
    if (item) {
      let newUsedLabels = new Set(usedLabels);
      window.preventDropOutside = true;

      if (answers[newLabelIndex].text) {
        //already filled
        if (item.labelIndex !== undefined) {
          //item comes from another label
          answers[item.labelIndex].text = answers[newLabelIndex].text; //flip
        } else {
          //return to unused
          let i = randomOrder.findIndex(
            (x) => x.text === answers[newLabelIndex].text
          );
          if (i !== -1) {
            newUsedLabels.delete(i);
          }
        }
      }

      //mark item as used
      if (item.itemIndex !== undefined) {
        newUsedLabels.add(item.itemIndex);
      }

      setUsedLabels(newUsedLabels);

      //fix answers
      answers[newLabelIndex].text = item.label;
      setAnswers(answers.slice());
    }
  };

  const handleDropOutside = (item) => {
    if (window.preventDropOutside) {
      delete window.preventDropOutside;
      return;
    }
    let index = randomOrder.findIndex((x) => x.text === item.label);
    if (index !== -1) {
      let newUsedLabels = new Set(usedLabels);
      newUsedLabels.delete(index);
      setUsedLabels(newUsedLabels);
    }
  };

  const handleDrag = (index) => {
    answers[index].text = '';
  };

  const onLoadImg = () => {
    const canvas = document.getElementById(state.uid + '_canvas');
    const img = document.getElementById(state.uid + '_img');
    canvas.width = img.width * 5;
    canvas.height = img.height * 5;

    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 15;
    state.labels.forEach((x, index) => {
      ctx.beginPath();
      const startX = x.tx * canvas.width;
      const startY = x.ty * canvas.height;
      const endX = x.x * canvas.width;
      const endY = x.y * canvas.height;
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = pastels[index];
      ctx.stroke();
      ctx.closePath();
    });
  };

  const handleMouseDown = () => {
    answered && setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    answered && setIsMouseDown(false);
  };

  useEffect(() => {
    if (answers)
      for (let i = 0; i < state.labels.length; i++) {
        let label = state?.labels[i]?.text;
        let userLabel = answers[i]?.text;
        let distance = stringRelativeDistance(
          userLabel?.toLowerCase() || '',
          label?.toLowerCase() || ''
        );
        distance = Math.abs(distance);

        labelsAnswer[i].reliability = Math.max((1 - distance) * 100, 0);
        labelsAnswer[i].isCorrect = false;

        if (distance <= 0.25) {
          labelsAnswer[i].isCorrect = true;
        }

        setLabelsAnswer(labelsAnswer.slice());
        setModified(newUid(20));
      }
  }, [answers]);

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      data-state={encode(JSON.stringify(state))}
      onFocus={() => focusWidget(state?.uid)}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(score)}
          maxScore={state.score}
          answered={answered}
          titleWidget={state.titleWidget || t('widgets.type.name_parts_title')}
        />
      )}
      <FroalaTextareaView value={state.vraag} className="mb-2" />

      {answered ? (
        correction?.score < state?.score ? (
          <p className="hidden text-xs font-medium text-error-04 md:block">
            {t('pages.correction_result.order_widget_hint')}
          </p>
        ) : null
      ) : null}

      <div>
        <DndProvider
          backend={HTML5Backend}
          options={{ enableMouseEvents: true, preview: true }}
        >
          {/* {!isMobile && !viewMobile && !answered && ( */}
          <div
            className={`hidden flex-col md:block ${viewMobile && '!hidden'}`}
          >
            <div
              className={'relative flex' + (showAnswers ? ' hidden' : '')}
              style={{
                gap: 10,
                whiteSpace: 'nowrap',
                flexWrap: 'wrap',
                marginBottom: 20,
              }}
            >
              {randomOrder?.map((x, index) => (
                <DraggableComponent
                  itemIndex={index}
                  key={answers[index].text + index + answers[index].isCorrect}
                  label={x.text}
                  id={x.uid}
                >
                  <div
                    className="flex items-center rounded-md bg-grey-02 py-1 pr-4"
                    style={{ cursor: 'pointer' }}
                  >
                    <IconButton
                      Icon={MoreVertical}
                      variant="secondary"
                      className="bg-grey-02"
                    />
                    <div className={clsx({ invisible: usedLabels.has(index) })}>
                      {x.text}
                    </div>
                  </div>
                </DraggableComponent>
              ))}
            </div>
          </div>
          {/* )} */}
          <div
            style={{ marginBottom: 30, position: 'relative' }}
            className="pane LinkWidget widget-item"
            id={state.uid + '_view'}
          >
            <div id={state.uid + '_view_container'}>
              <img
                id={state.uid + '_img'}
                src={state.image}
                onLoad={onLoadImg}
                style={{
                  width: '100%',
                  cursor: 'inherit',
                  pointerEvents: 'none',
                }}
              />

              <canvas
                id={state.uid + '_canvas'}
                style={{
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                className="absolute"
              ></canvas>

              {/* {isMobile || viewMobile ? ( */}
              <div className={`block md:hidden ${viewMobile && '!block'}`}>
                {state.labels.map((x, index) => (
                  <div
                    key={answers[index].text + index + answers[index].isCorrect}
                  >
                    <span
                      className="absolute h-3.5 w-3.5 rounded-full border-[3px]"
                      style={{
                        transform: 'translate(-50%, -50%)',
                        top: x.ty * 100 + '%',
                        left: x.tx * 100 + '%',
                        borderColor: `${pastels[index]}`,
                      }}
                    ></span>

                    <div
                      id={x.uid + '_view'}
                      style={{
                        top: x.y * 100 + '%',
                        left: x.x * 100 + '%',
                        transform: 'translate(-11%, -50%)',
                      }}
                      className="absolute"
                    >
                      <div>
                        <div
                          style={{
                            backgroundColor: `${pastels[index]}`,
                          }}
                          className="mr-2 rounded-md py-1 px-3 text-white"
                        >
                          {getCharForNumber(index)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* ) : ( */}
              <div className={`hidden md:block ${viewMobile && '!hidden'}`}>
                {state.labels.map((x, index) => {
                  let label = answered
                    ? state.labels[index].text +
                      (answer.labels[index].isCorrect
                        ? ''
                        : ` (${window.i18n.t('not')} ${
                            answers[index] || window.i18n.t('empty')
                          })`)
                    : answers[index].text;

                  return (
                    <div
                      key={
                        answers[index].text + index + answers[index].isCorrect
                      }
                    >
                      <span
                        className="absolute h-3.5 w-3.5 rounded-full border-[3px]"
                        style={{
                          transform: 'translate(-50%, -50%)',
                          top: x.ty * 100 + '%',
                          left: x.tx * 100 + '%',
                          borderColor: `${pastels[index]}`,
                        }}
                      ></span>
                      <div
                        id={x.uid + '_view'}
                        style={{
                          transform: 'translate(-11%, -50%)',
                          top: x.y * 100 + '%',
                          left: x.x * 100 + '%',
                        }}
                        className="drop-label absolute"
                      >
                        <Dropzone index={index}>
                          <DraggableComponent
                            label={answers[index].text}
                            labelIndex={index}
                          >
                            <div
                              style={{ minWidth: '88px', padding: '2px 5px' }}
                              title={
                                answered
                                  ? answers[index].isCorrect
                                    ? 'correct'
                                    : 'incorrect'
                                  : ''
                              }
                              value={label}
                            >
                              <div>
                                <div className="flex items-center">
                                  <div
                                    style={{
                                      backgroundColor: `${pastels[index]}`,
                                    }}
                                    className="mr-2 rounded-md py-1 px-3 text-white"
                                  >
                                    {getCharForNumber(index)}
                                  </div>
                                  <div
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    className={`flex w-full min-w-[88px] items-center rounded-md bg-grey-02 p-2 py-1 
                                    ${
                                      answered
                                        ? answers[index].isCorrect
                                          ? 'border !border-success-04 !bg-success-01'
                                          : isMouseDown
                                          ? 'border !border-secondary-04 !bg-secondary-01'
                                          : 'border !border-error-04 !bg-error-01'
                                        : ''
                                    }`}
                                  >
                                    {isMouseDown && answered
                                      ? state.labels[index].text || <>&nbsp;</>
                                      : answers[index].text || <>&nbsp;</>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </DraggableComponent>
                        </Dropzone>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* )} */}
            </div>
          </div>
          {/* {(isMobile || viewMobile) && ( */}
          <div className={`flex flex-col md:hidden ${viewMobile && '!flex'}`}>
            {randomOrder?.map((x, index) => {
              let newOptions = stateLabelOptions.filter((x, index) => {
                return !usedLabels.has(index);
              });

              if (answers[index].text) {
                newOptions.unshift({
                  label: answers[index].text,
                  value: state.labels.findIndex(
                    (el) => el.text === answers[index].text
                  ),
                });
              }

              newOptions.unshift({
                label: '',
                value: undefined,
              });

              return (
                <div
                  key={answers[index].text + index + answers[index].isCorrect}
                >
                  <div className="my-2 flex items-start">
                    <div
                      style={{
                        backgroundColor: `${pastels[index]}`,
                        // transform: 'translate(-15%, -50%)',
                      }}
                      className="mt-0.5 mr-3 w-9 rounded-md py-1.5 px-3 text-center font-semibold text-white"
                    >
                      {getCharForNumber(index)}
                    </div>

                    <div className="w-full text-sm">
                      {answered ? (
                        <>
                          <div
                            className={`flex min-h-[40px] w-full items-center rounded-md border bg-grey-transparent-02 px-3 py-1.5 text-base text-grey-08 ${
                              answers[index].isCorrect
                                ? 'bg-grey-transparent-02 text-sm'
                                : '!border-error-04 bg-white'
                            }`}
                          >
                            {answers[index].text ? answers[index].text : ''}
                            {answers[index].isCorrect && (
                              <Check className="ml-auto w-8 min-w-8 pl-3 text-success-05" />
                            )}
                          </div>
                          {!answers[index].isCorrect && (
                            <p className="mt-2 text-sm text-error-04">
                              {t('pages.correction_result.correct_answer_np')}{' '}
                              {state.labels[index].text}
                            </p>
                          )}
                        </>
                      ) : (
                        <InputSelect
                          placeholder={t('widgets.type.answer')}
                          options={newOptions}
                          value={
                            !answers[index].text ? undefined : newOptions[1]
                          }
                          onChange={(el) => {
                            const item = {
                              label: el.label,
                              itemIndex: el.value,
                            };
                            handleDrop(index, item);
                          }}
                        >
                          {(option) => (
                            <span
                              className={`min-h-5 ${
                                !option.label && 'text-grey-04'
                              }`}
                            >
                              {option.label
                                ? option.label
                                : t('widgets.type.answer')}
                            </span>
                          )}
                        </InputSelect>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* )} */}
        </DndProvider>
      </div>
      {(state.immediateFeedback || correctionType === 'autofeedback') && (
        <PartialSubmitSection
          setSubmitted={setSubmitted}
          setModified={setModified}
          answered={answered}
          onSave={onSave.bind(null, false, false, state)}
        />
      )}
      {(resultPages || showAnswers || answered || submitted) &&
      state?.antwoord ? (
        <ExplanationSection state={state} />
      ) : null}
    </div>
  );
};

//this function should work as a correction/score function
NamePartsView.syncStates = (state, answer, correction) => {
  if (isEmpty(answer) || answer?.labels?.length != state?.labels?.length) {
    answer.uid = state.uid;
    answer.labels = state.labels.map((x) => ({ text: '' }));
  }
  if (
    isEmpty(correction) ||
    !correction.labels ||
    correction.labels.length != state.labels.length
  ) {
    correction.labels = state.labels.map((x) =>
      Object.assign((x) => ({ text: '' }))
    );
    correction.uid = state.uid;
    correction.score = 0;
    for (let i = 0; i < state.labels.length; i++) {
      let label = state.labels[i].text;
      let userLabel = answer.labels[i].text;
      let distance = stringRelativeDistance(
        userLabel?.toLowerCase() || '',
        label?.toLowerCase() || ''
      );
      distance = Math.abs(distance);

      answer.labels[i].reliability = Math.max((1 - distance) * 100, 0);
      answer.labels[i].isCorrect = false;

      if (distance <= 0.25) {
        let temp = state.score / Math.max(1, state.labels.length);
        if (temp) correction.score += temp;
        answer.labels[i].isCorrect = true;
      }
    }
    correction.labels = answer.labels.map((x) => Object.assign({}, x));
  }
};

import React, { FC } from 'react';

export interface IconProps {
  className?: string;
}
export const Blocked: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.25 9H13.5V5.25C13.5 4.05653 13.0259 2.91193 12.182 2.06802C11.3381 1.22411 10.1935 0.75 9 0.75C7.80653 0.75 6.66193 1.22411 5.81802 2.06802C4.97411 2.91193 4.5 4.05653 4.5 5.25V9H3.75C2.95462 9.00087 2.19206 9.31722 1.62964 9.87964C1.06722 10.4421 0.750869 11.2046 0.75 12V20.25C0.750869 21.0454 1.06722 21.8079 1.62964 22.3704C2.19206 22.9328 2.95462 23.2491 3.75 23.25H14.25C15.0454 23.2491 15.8079 22.9328 16.3704 22.3704C16.9328 21.8079 17.2491 21.0454 17.25 20.25V12C17.2491 11.2046 16.9328 10.4421 16.3704 9.87964C15.8079 9.31722 15.0454 9.00087 14.25 9ZM12 9H6V5.25C6 4.45435 6.31607 3.69129 6.87868 3.12868C7.44129 2.56607 8.20435 2.25 9 2.25C9.79565 2.25 10.5587 2.56607 11.1213 3.12868C11.6839 3.69129 12 4.45435 12 5.25V9Z"
      fill="currentColor"
    />
  </svg>
);

import { useState, createRef, useEffect } from 'react';
import { isEmpty, loadRawScript, copyToClipboard } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';

let mathPromise;
export function initMath() {
  if (mathPromise) return mathPromise;
  mathPromise = new Promise((resolve, reject) => {
    let scripts = [
      'https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.13.11/katex.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/mathquill/0.10.1/mathquill.min.js',
    ];
    let css = [
      'https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.13.11/katex.min.css',
      'https://cdnjs.cloudflare.com/ajax/libs/mathquill/0.10.1/mathquill.css',
    ];

    window
      .loadjs([...scripts, ...css], 'math', {
        async: true,
        returnPromise: true,
      })
      .catch((depsNotFound) => {
        //load local fallbacks
        let fallbacks = depsNotFound.map((x) =>
          x.replace('https://cdnjs.cloudflare.com/ajax/libs/', '/libs/')
        );
        return window.loadjs(fallbacks, 'math_fallback', {
          async: true,
          returnPromise: true,
        });
      })
      .then((scripts) => {
        loadRawScript(`
          window.katex = katex;
          window.MathQuill = MathQuill;
        `);
        resolve();
      })
      .catch((depsNotFound) => {
        console.error('deps not found', depsNotFound);
      });
  });
  return mathPromise;
}

export const MathView = ({ state, onClick = () => {} }) => {
  const { t } = useTranslation();
  let myRef = createRef();

  useEffect(() => {
    initMath().finally(() => {
      if (!myRef.current) return;
      try {
        if (window.katex) {
          window.katex.render(state.latex, myRef.current, {
            displayMode: true,
          });
        }
      } catch (e) {
        console.error(e);
      }
    });
  }, [myRef.current, state.latex, state.inline]);

  if (state.inline) {
    return (
      <span
        className={`relative z-0 inline-block cursor-pointer  ${
          !state.latex &&
          'duration-400 ml-1 mr-1 rounded-md bg-grey-02 leading-none hover:bg-grey-01 hover:bg-grey-01'
        } p-1`}
      >
        {state?.latex?.length < 1 && (
          <span className="absolute top-1.5 left-2.5 -z-[1] text-center font-medium text-grey-04">
            ... <span className="text-lg font-normal not-italic">&fnof;</span>
          </span>
        )}
        <span
          data-open="SOW"
          className={`_katex inline-block cursor-pointer `}
          data-state={encode(JSON.stringify(state))}
          style={{ minHeight: '30px', minWidth: '40px' }}
          ref={myRef}
          onClick={() => {
            onClick();
            copyToClipboard(state.latex);
          }}
        >
          <span data-closed="EOW"></span>
        </span>
      </span>
    );
  } else {
    return (
      <div
        data-open="SOW"
        data-state={encode(JSON.stringify(state))}
        onClick={() => {
          onClick();
          copyToClipboard(state.latex);
        }}
        className={'pointer flex-col'}
        style={{
          width: '100%',
        }}
      >
        {state.title && <div className="bold mb-1">{state.title}</div>}
        <div
          className="_katex duration-400 cursor-pointer rounded-md bg-grey-02 transition-all hover:bg-grey-01"
          style={{ padding: '40px 20px', justifyContent: 'center' }}
        >
          {state?.latex?.length < 1 && (
            <p className="text-center text-sm font-medium italic text-grey-04">
              {t('widgets.math.add_formula')}{' '}
              <span className="text-lg font-normal not-italic">&fnof;</span>
            </p>
          )}
          <span style={{ minHeight: '20px' }} ref={myRef}></span>
        </div>
        <div style={{ display: 'none' }} data-closed="EOW"></div>
      </div>
    );
  }
};

MathView.syncStates = (state, answer, correction) => {};

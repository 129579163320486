import { useTranslation } from 'react-i18next';
import { Button, Modal, Label, InputText } from '@teo/components';
import { useState, useEffect } from 'react';
import { newUid } from '@/util';
import { Portal } from '@/components/Portal';

const VIDEO_CONSTRAINTS = {
  audio: false,
  video: {
    width: { ideal: 1920 },
    height: { ideal: 1080 },
    frameRate: { ideal: 30, max: 60 },
    facingMode: {
      ideal: 'environment',
    },
  },
};

export const CameraModal = ({
  setIsOpenPhotoModal,
  isOpenPhotoModal,
  setUploadFile,
}) => {
  const [showWebcamPreview, setShowWebcamPreview] = useState(false);
  const [mediaStream, setMediaStream] = useState();
  const [uid, setUid] = useState(newUid(20));
  const { t } = useTranslation();

  const start = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(VIDEO_CONSTRAINTS)
        .then((stream) => {
          const video = document.getElementById(uid);
          setMediaStream(stream);
          video.srcObject = stream;
          video.play();
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    if (!mediaStream) {
      start();
    }
  }, [mediaStream]);

  const stop = () => {
    if (!mediaStream) return;
    mediaStream.getTracks().forEach(function (track) {
      track.stop();
    });
  };
  const takePicture = () => {
    let preUid = uid;
    let canvas = document.getElementById(preUid + '_canvas');
    const video = document.getElementById(preUid);
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);
    setShowWebcamPreview(true);
  };

  const resetCamera = () => {
    setShowWebcamPreview(false);
    stop();
    start();
  };

  const insertPicture = () => {
    let preUid = uid;
    let canvas = document.getElementById(preUid + '_canvas');
    canvas.toBlob((blob) => {
      stop();
      setShowWebcamPreview(false);
      setUploadFile((prev) => [...prev, blob]);
      setIsOpenPhotoModal(!isOpenPhotoModal);
    }, 'image/jpeg');
  };

  const closeModal = () => {
    stop();
    setIsOpenPhotoModal(!isOpenPhotoModal);
  };
  return (
    <Portal root="body">
      <div className="z-10 bg-white">
        <Modal isOpen={isOpenPhotoModal}>
          <Modal.Body>
            <div className="max-h-[70vh] overflow-hidden">
              {!showWebcamPreview && (
                <video
                  autoPlay
                  style={{
                    minWidth: '100%',
                    minHeight: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                  }}
                  id={uid}
                ></video>
              )}
              <canvas
                style={{
                  minWidth: '100%',
                  minHeight: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'cover',
                  display: showWebcamPreview ? 'initial' : 'none',
                }}
                id={uid + '_canvas'}
              ></canvas>
            </div>
          </Modal.Body>

          <Modal.Footer
            cancelButtonLabel={t('widgets.upload_buttons.cancel_button')}
            onCancel={closeModal}
          >
            {!showWebcamPreview ? (
              <div>
                <Button type="button" onClick={takePicture}>
                  {t('widgets.upload_buttons.take_picture')}
                </Button>
              </div>
            ) : (
              <div className="flex gap-3">
                <Button type="button" onClick={resetCamera}>
                  {t('widgets.upload_buttons.try_again')}
                </Button>
                <Button type="button" onClick={insertPicture}>
                  {t('widgets.upload_buttons.insert')}
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </Portal>
  );
};

export const courseTypeOptions = [
  {
    id: 1,
    value: 'course',
    name: 'course',
  },
  {
    id: 2,
    value: 'assessment',
    name: 'assessment',
  },
  {
    id: 3,
    value: 'leervacature',
    name: 'leervacature',
  },
  {
    id: 4,
    value: 'instruction',
    name: 'instruction',
  },
];

import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import {
  PageHeader,
  Button,
  Tag,
  Tabs,
  InputText,
  FilterList,
  PersonAvatar,
} from '@teo/components';
import { ArrowRight, Search } from '@teo/components/icons';
import { DataTable } from '@/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import OptionsAction from '@/components/DataTable/OptionsAction';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';
import i18n from 'i18next';
import { newUid, parseRole } from '@/util';
import { Userwithorganisationwithidps } from '@/models';
import Hint from '@/components/Hints/Hints';
import { HintsContext } from '@/components/Contexts/HintsContext';
import DeleteTeamFromCourseModal from '@/components/DeleteModal/DeleteTeamFromCourseModal';

export interface Filter {
  label: string;
  value: string;
}

function TeamDetailsPage({ teamInfo, setIsOpenAddContentModal }: any) {
  const { teamId, organisationId } = useParams();
  const { t } = useTranslation();
  const { hints } = useContext(HintsContext);
  const [tab, setTab] = useState('courses');
  const [contentRange, setContentRange] = useState(0);
  const [teamContentRange, setTeamContentRange] = useState(0);
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));

  const [resetCourseTable, setResetCourseTable] = useState(newUid(20));
  const [courseInpFilter, setCourseInpFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState<any>();
  const [filter, setFilter] = useState('');
  const [isFollow, setIsFollow] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [activeCourse, setActiveCourse] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState<Filter[]>([
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
  ]);
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const navigate = useNavigate();

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  useGet(`/trackteamdetails`, {
    organisationId: organisationId,
    userId: auth?.user?.id,
    teamId: teamId,
    onSuccess: (result: any) => {
      result && result?.data?.length > 0 && setIsFollow(true);
    },
  });

  useEffect(() => {
    if (teamInfo) {
      axios.post(config.backend + '/activity', {
        userId: auth?.user?.id,
        subjectId: teamId,
        activityType: 'team',
        subject: teamInfo?.teamName,
        link: window.location.pathname,
      });
    }
  }, [teamInfo]);

  const FILTERS_STATUS = [
    {
      label: t('pages.follow_up_courses.status_active'),
      value: 'active',
    },
    {
      label: t('pages.follow_up_courses.status_inactive'),
      value: 'disabled,hidden',
    },
  ];

  useEffect(() => {
    if (selectedStatus.length == 0) {
      setFilterStatus(null);
    }
    if (selectedStatus.length == 1) {
      setFilterStatus(`${selectedStatus[0].value}`);
    }
    if (selectedStatus.length > 1) {
      const select = selectedStatus?.map((el) => el.value);
      setFilterStatus(`${select}`);
    }
    setResetCourseTable(newUid(20));
  }, [selectedStatus]);

  const coursePath = window.location.pathname + '/courses';

  return (
    <div className="max-w-6xl flex-col pb-8">
      <div className="pb-2 pt-6">
        {/*<PageHeader to={`${basePath}?tab=teams`} ariaLabel={'placeholder'}>*/}
        <PageHeader /* @ts-expect-error eslint-disable-next-line */
          to={-1}
          ariaLabel={'placeholder'}
        >
          <div className="flex w-full items-center">
            <div className="flex items-center">
              <PersonAvatar
                inline
                id={teamInfo?.id}
                name={teamInfo?.teamName}
                bgColor={teamInfo?.color ? teamInfo?.color : null}
              ></PersonAvatar>
            </div>
            <div className="mx-4 flex w-full flex-row items-center justify-between gap-4">
              <h1 className="break-all text-lg font-semibold text-black sm:text-xl">
                {teamInfo?.teamName}
              </h1>

              <div className="flex flex-wrap items-center gap-3">
                <div
                  className={`${
                    hints?.id == 'hint_16' && 'active-hint-parent'
                  }`}
                >
                  <Button
                    variant="outline"
                    size="md"
                    containsOnlyIcon={true}
                    className="relative"
                    onClick={async () => {
                      if (isFollow) {
                        await axios.delete(
                          `${config.backend}/trackteam/${auth?.user?.id}/${teamId}`
                        );
                        setIsFollow(!isFollow);
                      } else {
                        await axios.post(`${config.backend}/trackteams`, {
                          userId: auth?.user?.id,
                          teamId: teamId,
                        });
                        setIsFollow(!isFollow);
                      }
                    }}
                  >
                    <Hint
                      key={`hint_16`}
                      id={'hint_16'}
                      leftDots={'-left-2 leading-[26px] top-1.5'}
                      title={t('hint.title_16')}
                      order={16}
                      style={{
                        right: '-10px',
                        top: '60px',
                      }}
                    />
                    {isFollow ? (
                      <img
                        src="/images/components/follow_up_courses/star_icon.svg"
                        alt="star_icon"
                        className="h-5"
                      />
                    ) : (
                      <img
                        src="/images/components/follow_up_courses/star_white.svg"
                        alt="star_icon"
                        className="h-5"
                      />
                    )}
                  </Button>
                </div>

                <div className="rounded-lg">
                  {Math.max(
                    authUser?.role || 0,
                    authUser?.activeOrganisationRole || 0
                  ) >= 90 && (
                    <Button
                      onClick={() => {
                        navigate(
                          `/${i18n.language}/manage/${organisationId}/teams/${teamId}`
                        );
                      }}
                      variant="outline"
                      size="md"
                      className="flex items-center"
                    >
                      {t('pages.follow_up_teams.manage_team')}
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </Button>
                  )}
                </div>

                <div
                  className={`${
                    hints?.id == 'hint_17' && 'active-hint-parent'
                  }`}
                >
                  <Button
                    onClick={() => setIsOpenAddContentModal(true)}
                    size="md"
                    className="relative"
                  >
                    <Hint
                      key={`hint_17`}
                      id={'hint_17'}
                      leftDots={'-left-2 leading-[26px] top-1.5'}
                      title={t('hint.title_17')}
                      order={17}
                      style={{
                        right: '-10px',
                        top: '60px',
                      }}
                    />
                    {t('pages.follow_up_teams.add_to_content')}
                  </Button>
                </div>
                {/* <div className="pl-2">
                  <IconButton
                    Icon={MoreVertical}
                    variant="ghost"
                    className="text-[#919193]"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </PageHeader>

        <div className="my-6 flex flex-wrap justify-between gap-2 rounded-lg border border-grey-02 py-7 px-9">
          <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
            <span className="text-base font-semibold">
              {teamInfo?.nrOfTeamMembers}
            </span>
            <span className="text-xs font-normal text-[#7d7d81]">
              {t('pages.follow_up_teams.members')}
            </span>
          </div>
          {authUser?.useCourse && (
            <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
              <span className="text-base font-semibold">2</span>
              <span className="text-xs font-normal text-[#7d7d81]">
                {t('pages.follow_up_teams.active_courses')}
              </span>
            </div>
          )}
          {authUser?.useAssessment && (
            <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
              <span className="text-base font-semibold">0</span>
              <span className="text-xs font-normal text-[#7d7d81]">
                {t('pages.follow_up_teams.active_assessments')}
              </span>
            </div>
          )}
          {authUser?.useLeervacature && (
            <div className="flex flex-[1_0_48%] flex-col lg:flex-none">
              <span className="text-base font-semibold">0</span>
              <span className="text-xs font-normal text-[#7d7d81]">
                {t('pages.follow_up_teams.active_vacancies')}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="mb-6 mt-3">
        <Tabs activeName={tab} onChange={(tab: any) => setTab(tab)}>
          <Tabs.TabButton name="courses">
            {t('pages.follow_up_teams.courses')}
            <span className="ml-2 text-base text-[#919193]">
              {contentRange ? contentRange : ''}
            </span>
            {tab === 'courses' && (
              <span className="ml-2 h-2 w-2 rounded-full bg-[#fc6232]"></span>
            )}
          </Tabs.TabButton>
          <Tabs.TabButton name="users">
            {t('pages.follow_up_teams.users')}
            <span className="ml-2 text-base text-[#919193]">
              {teamContentRange ? teamContentRange : ''}
            </span>
          </Tabs.TabButton>
          {/*
          <Tabs.TabButton name="growth_tracks">
            {t('pages.follow_up_teams.growth_tracks')}
            <span className="ml-2 text-base text-[#919193]">4</span>
          </Tabs.TabButton>
          */}
        </Tabs>
      </div>
      {tab === 'users' && (
        <>
          <div className="pb-4 pt-2 md:w-1/2 xl:w-1/3">
            <InputText
              value={filter}
              onChange={(ev: any) => setFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.user')}
            />
          </div>
          <DataTable
            uniqueKey={(usr) => (usr.id = usr.userId)}
            filter={filter}
            rowPath={(usr) =>
              `/${i18n.language}/follow-up/${authUser?.activeOrganisationId}/users/${usr.userId}`
            }
            setContentRange={setTeamContentRange}
            key={refetchTable}
            defaultSort={[{ id: 'username', desc: false }]}
            fetch={useGet.bind(null, `/teammemberdetails`, {
              teamId,
              organisationId: authUser?.activeOrganisationId,
            })}
            empty={{
              template: 1,
              text: t('empty_tenplate.user_list'),
              // btn_text: t('empty_tenplate.btn_add_user'),
              // btnClick: () =>
              //   navigate(
              //     `/${i18n.language}/manage/${organisationId}/teams/${teamId}`
              //   ),
            }}
            screensUpdateTable="desktop"
            columns={[
              {
                title: t('columns.username'),
                id: 'username',
                className: 'col-start-1 col-end-6 break-all',
                render: (usr: any) => (
                  <div className="flex items-center">
                    <PersonAvatar
                      inline
                      id={usr.userId}
                      name={usr.username}
                      imageSrc={usr.picture}
                    ></PersonAvatar>

                    <div>
                      <p className="text-sm font-medium text-[#231f20]">
                        {usr.username}
                      </p>
                      <p className="text-xs font-normal text-[#7d7d81]">
                        {usr.email}
                      </p>
                    </div>
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-1 col-end-4 row-start-2',
                id: 'phone',
                title: t('columns.phone'),
                sortable: false,
                render: (usr: any) => <p>{usr.phone}</p>,
              },
              {
                className: 'col-start-6 col-end-9 row-start-1 justify-end',
                id: 'role',
                title: t('columns.role'),
                render: (usr: any) => {
                  switch (usr.role) {
                    case 20:
                      return (
                        <Tag
                          title={t(parseRole(usr.role))}
                          variant="secondary"
                        />
                      );
                    case 10:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="primary" />
                      );
                    case 70:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="warning" />
                      );
                    case 90:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="error" />
                      );
                    case 75:
                      return (
                        <Tag title={t(parseRole(usr.role))} variant="success" />
                      );
                    case 60:
                      return (
                        <Tag
                          title={t(parseRole(usr.role))}
                          variant="document"
                        />
                      );
                    default:
                      return (
                        <Tag
                          title={t(parseRole(usr.role))}
                          variant="secondary"
                        />
                      );
                  }
                },
              },
              {
                addTitleOnMobile: true,
                className:
                  'col-start-4 col-end-7 row-start-2 justify-center text-center',
                id: 'createdAt',
                title: t('columns.date_created'),
                align: 'right',
                render: (usr: any) =>
                  format(new Date(usr?.createdAt), 'dd/MM/yyyy'),
              },
              {
                addTitleOnMobile: true,
                className:
                  'col-start-7 col-end-10 row-start-2 text-right justify-end',
                id: 'verified',
                title: t('columns.status'),
                sortable: false,
                render: (usr: any) => (
                  <Tag
                    title={
                      usr.created
                        ? t('pages.manage_users.active')
                        : t('pages.manage_users.not_active')
                    }
                    variant={usr.created ? 'success' : 'error'}
                  />
                ),
              },
              {
                className: 'col-start-9 row-start-1 justify-end',
                title: '',
                id: 'edit',
                sortable: false,
                render: (usr: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        addIcon: true,
                        id: '1',
                        title: t('pages.manage_users.manage_user_button'),
                        onClick: () =>
                          navigate(
                            `/${i18n.language}/follow-up/${authUser?.activeOrganisationId}/users/${usr.userId}`
                          ),
                      },
                    ]}
                    edit={{
                      title: t('pages.manage_users.edit_user'),
                    }}
                    screensUpdateTable="desktop"
                    setRefetchTable={setRefetchTable}
                    pageDetail={{
                      title: t('pages.manage_users.user_detail'),
                      rowPath: (usr) => `${basePath}/users/${usr.userId}`,
                    }}
                    rowInfo={usr}
                    mobileHeader={{
                      render: (usr: any) => (
                        <div className="flex items-center">
                          <PersonAvatar
                            inline
                            id={usr.userId}
                            name={usr.username}
                            imageSrc={usr.picture}
                          ></PersonAvatar>

                          <div>
                            <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                              {usr.username}
                            </p>
                          </div>
                        </div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </>
      )}

      {tab === 'courses' && (
        <>
          <div className="pb-4 pt-2 md:w-1/2 xl:w-1/3">
            <InputText
              value={courseInpFilter}
              onChange={(ev) => setCourseInpFilter(ev.target.value)}
              Icon={Search}
              placeholder={t('search.course')}
            />
          </div>
          <div className="z-[21] flex flex-col pb-4 sm:flex-row">
            <span className="pr-2">
              <FilterList
                filters={FILTERS_STATUS}
                onSelect={setSelectedStatus}
                selected={selectedStatus}
              />
            </span>
          </div>
          <DataTable
            filter={courseInpFilter}
            key={resetCourseTable}
            setContentRange={setContentRange}
            rowPath={(course) =>
              `/${i18n.language}/follow-up/${authUser?.activeOrganisationId}/courses/details/${course.id}`
            }
            defaultSort={[
              { id: 'courseStatus', desc: false },
              { id: 'courseName', desc: false },
            ]}
            fetch={useGet.bind(null, '/teamcoursedetails', {
              teamId,
              courseStatus: filterStatus,
            })}
            empty={{
              template: 2,
              text: t('empty_tenplate.course_list'),
              btn_text: t('pages.follow_up_teams.add_to_content'),
              btnClick: () => setIsOpenAddContentModal(true),
            }}
            screensUpdateTable="desktop"
            columns={[
              {
                title: t('columns.courseName'),
                id: 'courseName',
                className: 'col-start-1 col-end-7',
                render: (course: any) => (
                  <div className="flex items-center">
                    <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                      <img
                        src={
                          course?.courseImage ||
                          course?.image ||
                          '/images/courseFallback.png'
                        }
                        alt={course.courseName || course.name}
                        className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                      />
                    </div>
                    {course.courseStatus !== 'active' ? (
                      <div className="ml-3 break-all font-medium text-[#919193]">
                        {course.courseName || course.name}
                      </div>
                    ) : (
                      <div className="ml-3 break-all font-medium">
                        {course.courseName || course.name}
                      </div>
                    )}
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-1 col-end-4 row-start-2',
                id: 'courseStartDate',
                title: t('columns.start_date'),
                align: 'right',
                render: (course: any) =>
                  course?.courseStartDate ? (
                    course?.courseStatus !== 'active' ? (
                      <div className="text-[#919193]">
                        {format(
                          new Date(course?.courseStartDate),
                          'dd/MM/yyyy'
                        )}
                      </div>
                    ) : (
                      <div>
                        {format(
                          new Date(course?.courseStartDate),
                          'dd/MM/yyyy'
                        )}
                      </div>
                    )
                  ) : (
                    ''
                  ),
              },
              {
                id: 'courseEndDate',
                title: t('columns.end_date'),
                align: 'right',
                addTitleOnMobile: true,
                className:
                  'col-start-4 col-end-7 row-start-2 justify-center text-center',
                render: (course: any) =>
                  !course?.courseEndDate ? (
                    <p className="text-[#fc6232]">
                      {t('pages.follow_up_teams.not_finished_yet')}
                    </p>
                  ) : (
                    <>
                      {course?.courseStatus !== 'active' ? (
                        <div className="text-[#919193]">
                          {format(
                            new Date(course?.courseEndDate),
                            'dd/MM/yyyy'
                          )}
                        </div>
                      ) : (
                        <div>
                          {format(
                            new Date(course?.courseEndDate),
                            'dd/MM/yyyy'
                          )}
                        </div>
                      )}
                    </>
                  ),
              },
              {
                id: 'started',
                title: t('columns.started'),
                align: 'right',
                addTitleOnMobile: true,
                className:
                  'col-start-7 col-end-10 row-start-2 text-right justify-end',
                render: (course: any) =>
                  course?.courseStatus !== 'active' ? (
                    <div className="text-[#919193]">{course?.nrOfStudents}</div>
                  ) : (
                    <div>{course?.nrOfStudents}</div>
                  ),
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-7 col-end-9 row-start-1',
                id: 'courseStatus',
                title: t('columns.status'),
                align: 'right',
                render: (course: any) => (
                  <Tag
                    title={
                      course.courseStatus === 'active'
                        ? t('pages.follow_up_teams.status_ongoing')
                        : t('pages.follow_up_teams.status_closed')
                    }
                    variant={
                      course.courseStatus === 'active' ? 'success' : 'default'
                    }
                  />
                ),
              },
              {
                id: 'actionButton',
                sortable: false,
                title: '',
                className: 'col-start-9 row-start-1 justify-end',
                render: (course: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        addOnMobile: true,
                        itemDelete: true,
                        id: '3',
                        title: t('pages.follow_up_teams.remove_course'),
                        // onClick: async (ev) => {
                        //   await axios.delete(
                        //     `${config.backend}/courseteam/${course?.id}/${teamId}/`
                        //   );
                        //   setResetCourseTable(newUid(20));
                        // },
                        onClick: () => {
                          setIsOpenDeleteModal(true);
                          setActiveCourse(course);
                        },
                      },
                    ]}
                    pageDetail={{
                      title: t('pages.follow_up_teams.share_settings'),
                      rowPath: (course) => `${coursePath}/${course.id}`,
                    }}
                    screensUpdateTable="desktop"
                    rowInfo={course}
                    mobileHeader={{
                      render: (course: any) => (
                        <div className="flex items-center">
                          <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                            <img
                              src={
                                course.courseImage ||
                                course.image ||
                                '/images/courseFallback.png'
                              }
                              alt={course.courseName || course.name}
                              className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                            />
                          </div>

                          {course.courseStatus !== 'active' ? (
                            <div className="ml-3 font-medium text-[#919193]">
                              {course.courseName || course.name}
                            </div>
                          ) : (
                            <div className="ml-3 font-medium">
                              {course.courseName || course.name}
                            </div>
                          )}
                        </div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </>
      )}

      {tab === 'growth_tracks' && <div>growth_tracks</div>}
      {isOpenDeleteModal && (
        <DeleteTeamFromCourseModal
          closeModal={() => setIsOpenDeleteModal(false)}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          activeCourse={activeCourse}
          teamInfo={teamInfo}
          organisationId={organisationId}
          setResetCourseTable={setResetCourseTable}
        />
      )}
    </div>
  );
}

export default TeamDetailsPage;

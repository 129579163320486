import { useState, useEffect } from 'react';
import { ComboBox } from '@teo/components';
import { parseFilter } from '@/util';

const ParentSelectList = ({
  fetch,
  defaultSort,
  render,
  onChange,
  value,
  disabledList,
  ...other
}: any) => {
  const [filter, setFilter] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<any>(10);
  const [newList, setNewList] = useState<any>(null);
  const [finalList, setFinalList] = useState(null);

  const result: any = fetch({
    offset: 0,
    count: count,
    sort: defaultSort
      .map((sort: any) => (sort.desc ? '-' : '') + sort.id)
      .join(','),
    q: parseFilter(filter),
  });

  useEffect(() => {
    if (newList) {
      newList?.length < 10
        ? newList?.length === result?.data?.data?.length
          ? setFinalList(newList)
          : result?.data?.data?.length >= count
          ? setCount((prev: any) => prev + (10 - newList.length))
          : setFinalList(newList)
        : setFinalList(newList);
    }
  }, [newList]);

  useEffect(() => {
    if (result?.data && disabledList) {
      const filteredArray = result?.data?.data?.filter(
        (x: any) => !disabledList.some((el: any) => el.id === x.id)
      );
      setNewList(filteredArray);
    }
  }, [result?.data, disabledList]);

  useEffect(() => {
    if (finalList) {
      const newData = (finalList as any[]).map((x) => {
        Object.assign(x, { label: render(x) });
        return x;
      });
      setData([{ id: null, label: '-unselected', teamPath: '' }, ...newData]);
    }
  }, [finalList]);

  return (
    <ComboBox
      onChange={(param) => {
        setFilter('');
        onChange(param);
      }}
      onCommitQuery={(email) => {
        setFilter('');
      }}
      options={data}
      query={filter}
      setQuery={(query) => {
        setFilter(query);
        setCount(10);
      }}
      value={value || []}
      {...other}
    />
  );
};

export { ParentSelectList };

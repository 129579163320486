import { useGet } from '@/query';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useState, useContext } from 'react';
import { Teams, Userwithorganisationwithidps } from '@/models';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonLink,
  FilterDropdown,
  InputText,
  PageHeader,
  PersonAvatar,
  Tabs,
  Tag,
} from '@teo/components';
import { Search, ArrowRight } from '@teo/components/icons';
import { TheoSearch } from '@teo/components/illustrations';
import { DataTable } from '@/components/DataTable/DataTable';
import { newUid, parseRole, nthLastIndexOf } from '@/util';
import { config } from '@/config';
import { T } from '@/components/T';
import {
  allRolesData,
  allStatusesData,
  createdData,
} from '@/mocks/manageUsersTagsData';
import OptionsAction from '@/components/DataTable/OptionsAction';
import EditTeamModal from '@/pages/Protected/Manage/EditTeamModal/EditTeamModal';
import { userData } from '@/mocks/headerData';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { axios } from '@/axios';
import { AuthContext } from '@/components/Contexts/AuthContext';
import AddNewTeamModal from '../AddNewTeamModal/AddNewTeamModal';
import SubTeamTab from './SubTeamTab';
import { format } from 'date-fns';

function ManageTeamsDetails() {
  const { t } = useTranslation();
  const [team, setTeam] = useState<any>(null);
  const [filter, setFilter] = useState('');
  const { teamId, organisationId } = useParams();
  const [tab, setTab] = useState('users');
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [refetchSubTable, setRefetchSubTable] = useState<string>(newUid(20));
  const [role, setRole] = useState<any>();
  const [created, setCreated] = useState();
  const [status, setStatus] = useState<any>();
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const [isTeamIndex, setIsTeamIndex] = useState<any>();
  const [isOpenEditTeamModal, setIsOpenEditTeamModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [usrDelete, setUsrDelete] = useState<any>();

  const [isOpenAddNewTeamModal, setIsOpenAddNewTeamModal] =
    useState<any>(false);

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 2)
  );

  const { refetch } = useGet(
    `/team/${authUser?.activeOrganisationId}/${teamId}`,
    {
      onSuccess: (result: any) => {
        setTeam(result?.data || null);
      },
    }
  );

  const handleDeleteUser = (usr: any) => {
    if (usr.isTeacher) {
      axios
        .delete(`${config.backend}/teamteacher/${usr.teamId}/${usr.userId}`)
        .then(() => {
          setRefetchTable(newUid(20));
          refetch();
        });
    } else {
      axios
        .delete(`${config.backend}/teammember/${usr.teamId}/${usr.userId}`)
        .then(() => {
          setRefetchTable(newUid(20));
          refetch();
        });
    }
    setIsOpenDeleteModal(false);
  };

  const filterUserStatus = [
    {
      id: '1',
      name: t('pages.manage_users.active'),
      value: 'true',
    },
    {
      id: '2',
      name: t('pages.manage_users.not_active'),
      value: 'false',
    },
  ];

  const filterUserRoles = [
    {
      id: 10,
      value: 10,
      name: t('role.CANDIDATE'),
    },
    {
      id: 20,
      value: 20,
      name: t('role.STUDENT'),
    },
    {
      id: 40,
      value: 40,
      name: t('role.TEAMLEAD'),
    },
    {
      id: 45,
      value: 45,
      name: t('role.CONTENT_CREATOR'),
    },
    {
      id: 70,
      value: 70,
      name: t('role.TEACHER'),
    },
    {
      id: 90,
      value: 90,
      name: t('role.ADMIN'),
    },
    {
      id: 95,
      value: 95,
      name: t('role.SUPER_ADMIN'),
    },
  ];

  if (!team) return null;

  return (
    <div className="max-w-5xl">
      <PageHeader /* @ts-expect-error eslint-disable-next-line */
        to={-1}
        ariaLabel={'placeholder'}
      >
        <div className="flex w-full flex-col justify-between lg:flex-row lg:items-center">
          <div className="flex items-center">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PersonAvatar
                inline
                id={team.id}
                name={team.teamName || ''}
                bgColor={team?.color ? team?.color : ''}
              ></PersonAvatar>
            </div>

            <h1 className="my-4 break-all text-lg font-semibold text-black sm:text-xl lg:my-8">
              <T>{team.teamName}</T>
            </h1>
          </div>

          <div className="mb-4 flex items-center lg:mb-0">
            {/*<div className="mr-2">
              <Button
                variant="ghost"
                size="sm"
                containsOnlyIcon={true}
                onClick={() => {}}
              >
                <img
                  src="/images/components/follow_up_courses/star_icon.svg"
                  alt="star_icon"
                  className="rounded-lg border border-grey-02 p-2"
                />
              </Button>
            </div>*/}

            <div className="mr-3">
              {team.teamPath !== '_organisation_' && (
                <Button
                  variant="outline"
                  size="md"
                  className="flex items-center"
                  onClick={() => {
                    setIsOpenEditTeamModal(true);
                    setIsTeamIndex(team.id);
                  }}
                >
                  {t('pages.manage_teams.team_settings_button')}
                </Button>
              )}
            </div>

            {tab === 'users' && team.teamPath !== '_organisation_' && (
              <div>
                <Button
                  onClick={() => {
                    navigate(`${window.location.pathname}/addUser`);
                  }}
                  size="md"
                >
                  {t('pages.manage_add_users.add_users_button')}
                </Button>
              </div>
            )}

            {tab === 'teachers' && (
              <div>
                <Button
                  onClick={() => {
                    navigate(`${window.location.pathname}/addUser`);
                  }}
                  size="md"
                >
                  {t('pages.manage_add_users.add_teacher')}
                </Button>
              </div>
            )}

            {tab === 'subTeams' && (
              <div>
                <Button
                  onClick={() => {
                    setIsOpenAddNewTeamModal(team?.id);
                  }}
                  size="md"
                >
                  {t('pages.manage_teams.add_sub_team_button')}
                </Button>
              </div>
            )}

            {/* <div className="pl-2">
              <IconButton
                Icon={MoreVertical}
                variant="ghost"
                className="text-[#919193]"
              />
            </div> */}
          </div>
        </div>
      </PageHeader>

      <div className="mt-2 font-medium lg:mt-6">
        <Tabs activeName={tab} onChange={(tab: any) => setTab(tab)}>
          <Tabs.TabButton name="users">
            <span>
              <span>{t('pages.manage_teams.users_tab')}</span>
              {team.nrOfTeamMembers ? (
                <span className="ml-2 text-base text-[#919193]">
                  {team.nrOfTeamMembers}
                </span>
              ) : (
                <span className="ml-2 text-base text-[#919193]">0</span>
              )}
            </span>
          </Tabs.TabButton>
          <Tabs.TabButton name="teachers">
            <span>
              <span>{t('pages.manage_teams.teachers_tab')}</span>
              {team.nrOfTeamTeachers ? (
                <span className="ml-2 text-base text-[#919193]">
                  {team.nrOfTeamTeachers}
                </span>
              ) : (
                <span className="ml-2 text-base text-[#919193]">0</span>
              )}
            </span>
          </Tabs.TabButton>
          <Tabs.TabButton name="subTeams">
            <span>{t('pages.manage_teams.subteams_tab')}</span>
          </Tabs.TabButton>
        </Tabs>
      </div>

      {tab === 'users' && (
        <Fragment>
          {team.nrOfTeamMembers ? (
            <>
              <div className="py-8 md:w-1/2 xl:w-1/3">
                <InputText
                  value={filter}
                  onChange={(ev: any) => setFilter(ev.target.value)}
                  Icon={Search}
                  placeholder={t('search.course')}
                />
              </div>
              <div className="z-[21] mb-6 flex flex-col sm:flex-row">
                <span className="mb-4 pr-2 sm:mb-0">
                  <FilterDropdown
                    clearButtonLabel="clear"
                    onChange={(value: any) => {
                      setStatus(value);
                      setRefetchTable(newUid(20));
                    }}
                    buttonText={`${t('pages.manage_users.all_statuses')}`}
                    options={filterUserStatus}
                    searchable={true}
                    value={status}
                  />
                </span>

                <span className="mb-4 pr-2 sm:mb-0">
                  <FilterDropdown
                    clearButtonLabel="clear"
                    onChange={(value: any) => {
                      setRole(value);
                      setRefetchTable(newUid(20));
                    }}
                    buttonText={`${t('pages.manage_users.all_roles')}`}
                    options={filterUserRoles}
                    searchable={true}
                    value={role}
                  />
                </span>

                {/* <span className="pr-2">
                  <FilterDropdown
                    clearButtonLabel="clear"
                    onChange={(value: any) => {
                      setCreated(value);
                    }}
                    buttonText={`${t('pages.manage_users.created')}`}
                    options={createdData}
                    searchable={false}
                    value={created}
                    dropdownPositionMobileRight={true}
                  />
                </span> */}
              </div>
              <DataTable
                uniqueKey={(usr) => (usr.id = usr.userId)}
                filter={filter}
                rowPath={(usr) => `${basePath}/users/${usr.userId}`}
                key={refetchTable}
                defaultSort={[{ id: 'username', desc: false }]}
                fetch={useGet.bind(null, `/teammemberdetails`, {
                  teamId,
                  organisationId: authUser?.activeOrganisationId,
                  created: status?.value,
                  role: role?.value,
                })}
                screensUpdateTable="desktop"
                columns={[
                  {
                    title: t('columns.username'),
                    id: 'username',
                    className: 'col-start-1 col-end-6 break-all',
                    render: (usr: any) => (
                      <div className="flex items-center">
                        <PersonAvatar
                          inline
                          id={usr.userId}
                          name={usr.username}
                          imageSrc={usr.picture}
                        ></PersonAvatar>

                        <div>
                          <p className="text-sm font-medium text-[#231f20]">
                            {usr.username}
                          </p>
                          <p className="text-xs font-normal text-[#7d7d81]">
                            {usr.email}
                          </p>
                        </div>
                      </div>
                    ),
                  },
                  {
                    addTitleOnMobile: true,
                    className: 'col-start-1 col-end-4 row-start-2',
                    id: 'phone',
                    title: t('columns.phone'),
                    sortable: false,
                    render: (usr: any) => <p>{usr.phone}</p>,
                  },
                  {
                    className: 'col-start-6 col-end-9 row-start-1 justify-end',
                    id: 'role',
                    title: t('columns.role'),
                    render: (usr: any) => {
                      switch (usr.role || usr.role === role) {
                        case 20:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="secondary"
                            />
                          );
                        case 10:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="primary"
                            />
                          );
                        case 70:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="warning"
                            />
                          );
                        case 90:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="error"
                            />
                          );
                        case 75:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="success"
                            />
                          );
                        case 60:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="document"
                            />
                          );
                        default:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="secondary"
                            />
                          );
                      }
                    },
                  },
                  {
                    addTitleOnMobile: true,
                    className:
                      'col-start-4 col-end-7 row-start-2 justify-center text-center',
                    id: 'createdAt',
                    title: t('columns.date_created'),
                    align: 'right',
                    render: (usr: any) =>
                      format(new Date(usr?.createdAt), 'dd/MM/yyyy'),
                  },
                  {
                    addTitleOnMobile: true,
                    className:
                      'col-start-7 col-end-10 row-start-2 text-right justify-end',
                    id: 'verified',
                    title: t('columns.status'),
                    sortable: false,
                    render: (usr: any) => (
                      <Tag
                        title={
                          usr.created
                            ? t('pages.manage_users.active')
                            : t('pages.manage_users.not_active')
                        }
                        variant={usr.created ? 'success' : 'error'}
                      />
                    ),
                  },
                  {
                    className: 'col-start-9 row-start-1 justify-end',
                    title: '',
                    id: 'edit',
                    sortable: false,
                    render: (usr: any) => (
                      <OptionsAction
                        menuItems={[
                          {
                            addIcon: true,
                            id: '1',
                            title: t('pages.manage_users.manage_user_button'),
                            onClick: () =>
                              navigate(`${basePath}/users/${usr.userId}`),
                          },
                        ]}
                        delete={
                          team.teamPath !== '_organisation_'
                            ? {
                                title: t('pages.manage_users.delete_user'),
                                // request: `${config.backend}/member/${usr.organisationId}/${usr.userId}`,
                                onClick: () => {
                                  setIsOpenDeleteModal(true);
                                  setUsrDelete(usr);
                                },
                              }
                            : undefined
                        }
                        /*
                        edit={{
                          title: t('pages.manage_users.edit_user'),
                        }}
                        */
                        screensUpdateTable="desktop"
                        setRefetchTable={setRefetchTable}
                        pageDetail={{
                          title: t('pages.manage_users.user_detail'),
                          rowPath: (usr) => `${basePath}/users/${usr.userId}`,
                        }}
                        rowInfo={usr}
                        mobileHeader={{
                          render: (usr: any) => (
                            <div className="flex items-center">
                              <PersonAvatar
                                inline
                                id={usr.userId}
                                name={usr.username}
                                imageSrc={usr.picture}
                              ></PersonAvatar>

                              <div>
                                <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                                  {usr.username}
                                </p>
                              </div>
                            </div>
                          ),
                        }}
                      />
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <div className="mt-16 flex flex-col items-center">
              <span className="mb-6 pl-16">
                <TheoSearch className="w-48" />
              </span>
              <span className="mb-6 w-[331px] text-center text-base font-semibold text-black">
                {t('pages.manage_teams.no_users_text')}
              </span>
              {team.teamPath !== '_organisation_' && (
                <Button
                  onClick={() => {
                    navigate(`${window.location.pathname}/addUser`);
                  }}
                  size="md"
                >
                  {t('pages.manage_teams.add_users_button')}
                </Button>
              )}
            </div>
          )}
        </Fragment>
      )}

      {tab === 'teachers' && (
        <Fragment>
          {team.nrOfTeamTeachers ? (
            <>
              <div className="py-8 md:w-1/2 xl:w-1/3">
                <InputText
                  value={filter}
                  onChange={(ev: any) => setFilter(ev.target.value)}
                  Icon={Search}
                  placeholder={t('search.course')}
                />
              </div>
              <div className="z-[21] mb-6 flex flex-col sm:flex-row">
                <span className="mb-4 pr-2 sm:mb-0">
                  <FilterDropdown
                    clearButtonLabel="clear"
                    onChange={(value: any) => {
                      setStatus(value);
                      setRefetchTable(newUid(20));
                    }}
                    buttonText={`${t('pages.manage_users.all_statuses')}`}
                    options={filterUserStatus}
                    searchable={true}
                    value={status}
                  />
                </span>

                <span className="mb-4 pr-2 sm:mb-0">
                  <FilterDropdown
                    clearButtonLabel="clear"
                    onChange={(value: any) => {
                      setRole(value);
                      setRefetchTable(newUid(20));
                    }}
                    buttonText={`${t('pages.manage_users.all_roles')}`}
                    options={filterUserRoles}
                    searchable={true}
                    value={role}
                  />
                </span>

                {/* <span className="pr-2">
                  <FilterDropdown
                    clearButtonLabel="clear"
                    onChange={(value: any) => {
                      setCreated(value);
                    }}
                    buttonText={`${t('pages.manage_users.created')}`}
                    options={createdData}
                    searchable={false}
                    value={created}
                    dropdownPositionMobileRight={true}
                  />
                </span> */}
              </div>
              <DataTable
                uniqueKey={(usr) => (usr.id = usr.userId)}
                filter={filter}
                rowPath={(usr) => `${basePath}/users/${usr.userId}`}
                key={refetchTable}
                defaultSort={[{ id: 'username', desc: false }]}
                fetch={useGet.bind(null, `/teamteacherdetails`, {
                  teamId,
                  organisationId: authUser?.activeOrganisationId,
                  created: status?.value,
                  role: role?.value,
                })}
                screensUpdateTable="desktop"
                columns={[
                  {
                    title: t('columns.username'),
                    id: 'username',
                    className: 'col-start-1 col-end-6 break-all',
                    render: (usr: any) => (
                      <div className="flex items-center">
                        <PersonAvatar
                          inline
                          id={usr.userId}
                          name={usr.username}
                          imageSrc={usr.picture}
                        ></PersonAvatar>

                        <div>
                          <p className="text-sm font-medium text-[#231f20]">
                            {usr.username}
                          </p>
                          <p className="text-xs font-normal text-[#7d7d81]">
                            {usr.email}
                          </p>
                        </div>
                      </div>
                    ),
                  },
                  {
                    addTitleOnMobile: true,
                    className: 'col-start-1 col-end-4 row-start-2',
                    id: 'phone',
                    title: t('columns.phone'),
                    sortable: false,
                    render: (usr: any) => <p>{usr.phone}</p>,
                  },
                  {
                    className: 'col-start-6 col-end-9 row-start-1 justify-end',
                    id: 'role',
                    title: t('columns.role'),
                    render: (usr: any) => {
                      switch (usr.role || usr.role === role) {
                        case 20:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="secondary"
                            />
                          );
                        case 10:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="primary"
                            />
                          );
                        case 70:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="warning"
                            />
                          );
                        case 90:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="error"
                            />
                          );
                        case 75:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="success"
                            />
                          );
                        case 60:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="document"
                            />
                          );
                        default:
                          return (
                            <Tag
                              title={t(parseRole(usr.role))}
                              variant="secondary"
                            />
                          );
                      }
                    },
                  },
                  {
                    addTitleOnMobile: true,
                    className:
                      'col-start-4 col-end-7 row-start-2 justify-center text-center',
                    id: 'createdAt',
                    title: t('columns.date_created'),
                    align: 'right',
                    render: (usr: any) =>
                      format(new Date(usr?.createdAt), 'dd/MM/yyyy'),
                  },
                  {
                    addTitleOnMobile: true,
                    className:
                      'col-start-7 col-end-10 row-start-2 text-right justify-end',
                    id: 'verified',
                    title: t('columns.status'),
                    sortable: false,
                    render: (usr: any) => (
                      <Tag
                        title={
                          usr.created
                            ? t('pages.manage_users.active')
                            : t('pages.manage_users.not_active')
                        }
                        variant={usr.created ? 'success' : 'error'}
                      />
                    ),
                  },
                  {
                    className: 'col-start-9 row-start-1 justify-end',
                    title: '',
                    id: 'edit',
                    sortable: false,
                    render: (usr: any) => (
                      <OptionsAction
                        menuItems={[
                          {
                            addIcon: true,
                            id: '1',
                            title: t('pages.manage_users.manage_user_button'),
                            onClick: () =>
                              navigate(`${basePath}/users/${usr.userId}`),
                          },
                        ]}
                        delete={{
                          title: t('pages.manage_users.delete_user'),
                          // request: `${config.backend}/member/${usr.organisationId}/${usr.userId}`,
                          onClick: () => {
                            setIsOpenDeleteModal(true);
                            setUsrDelete(
                              Object.assign({}, usr, { isTeacher: true })
                            );
                          },
                        }}
                        /*
                        edit={{
                          title: t('pages.manage_users.edit_user'),
                        }}
                        */
                        screensUpdateTable="desktop"
                        setRefetchTable={setRefetchTable}
                        pageDetail={{
                          title: t('pages.manage_users.user_detail'),
                          rowPath: (usr) => `${basePath}/users/${usr.userId}`,
                        }}
                        rowInfo={usr}
                        mobileHeader={{
                          render: (usr: any) => (
                            <div className="flex items-center">
                              <PersonAvatar
                                inline
                                id={usr.userId}
                                name={usr.username}
                                imageSrc={usr.picture}
                              ></PersonAvatar>

                              <div>
                                <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                                  {usr.username}
                                </p>
                              </div>
                            </div>
                          ),
                        }}
                      />
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <div className="mt-16 flex flex-col items-center">
              <span className="mb-6 pl-16">
                <TheoSearch className="w-48" />
              </span>
              <span className="mb-6 w-[331px] text-center text-base font-semibold text-black">
                {t('pages.manage_teams.no_teachers_text')}
              </span>
              <Button
                onClick={() => {
                  navigate(`${window.location.pathname}/addUser`);
                }}
                size="md"
              >
                {t('pages.manage_add_users.add_teacher')}
              </Button>
            </div>
          )}
        </Fragment>
      )}

      {tab === 'subTeams' && (
        <div className="flex flex-col items-center">
          <SubTeamTab
            team={team}
            setIsOpenAddNewTeamModal={setIsOpenAddNewTeamModal}
            setRefetchSubTable={setRefetchSubTable}
            refetchSubTable={refetchSubTable}
            key={refetchSubTable}
          />
        </div>
      )}

      {isTeamIndex && isOpenEditTeamModal && (
        <EditTeamModal
          isOpenEditTeam={isOpenEditTeamModal}
          closeModal={() => setIsOpenEditTeamModal(false)}
          onChange={() => setRefetchTable(newUid(20))}
          isTeamIndex={isTeamIndex}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          closeModal={() => setIsOpenDeleteModal(false)}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          title={t('delete_modal.title_user') + usrDelete?.username}
          heading={t('delete_modal.heading_user')}
          text={t('delete_modal.text_user_team')}
          handleDelete={() => handleDeleteUser(usrDelete)}
          selectedItem={usrDelete}
        />
      )}
      {isOpenAddNewTeamModal && (
        <AddNewTeamModal
          isOpenAddTeam={isOpenAddNewTeamModal}
          closeModal={() => setIsOpenAddNewTeamModal(false)}
          onChange={() => {
            setRefetchSubTable(newUid(20));
          }}
        />
      )}
    </div>
  );
}

export default ManageTeamsDetails;

import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext, useRef } from 'react';
import { Stepper, Button, Spinner } from '@teo/components';
import { useMobile } from '@/util';
import AddUserTeamStep from './Steps/AddUserTeamStep';
import HomePageStep from './Steps/HomePageStep';
import SharingSettingsStep from './Steps/SharingSettingsStep';
import CommunicationStep from './Steps/CommunicationStep';
import { axios } from '@/axios';
import { config } from '@/config';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import { useGet } from '@/query/fetchHooks';
import { initFroala } from '@/components/Froala/froala.js';

const steps = ['users', 'homepage', 'share', 'communication'];

const StepsComponent = ({
  //authUser,
  course,
  courseInfo,
  teamInfo,
  student,
  activeCourse,
  setIsOpenAddContent,
  setActiveCourse,
  create,
  activeSteps = [1, 2, 3],
  submitButtonLabel = 'pages.course_content_flow.add_team',
  onSubmit = null,
  onClose,
  setLoader,
  publicationType,
}: any) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(activeSteps[0]);
  const [loadStep, setLoadStep] = useState(false);
  const isMobile = useMobile();

  //share step state
  const [correctionType, setCorrectionType] = useState<string>(
    course?.correctionType || 'immediate'
  );
  const [emailCertificate, setEmailCertificate] = useState<boolean>(
    course?.emailCertificate || false
  );
  const [courseType, setCourseType] = useState<string>(
    course?.courseType || course?.publicationCourseType || 'course'
  );
  const [courseAccess, setCourseAccess] = useState<string>(
    course?.courseAccess ||
      (courseType == 'leervacature' ? 'public' : 'private')
  );

  const [courseEndDate, setCourseEndDate] = useState<Date | undefined>(
    course?.courseEndDate || null
  );
  const [inOrder, setInOrder] = useState<boolean>(course?.inOrder || false);
  const [isMandatory, setIsMandatory] = useState<boolean>(course?.isMandatory);
  const [minScore, setMinScore] = useState<number>(course?.minScore);
  const [courseStartDate, setCourseStartDate] = useState<Date | undefined>(
    course?.courseStartDate || new Date()
  );
  const [courseName, setCoursename] = useState<number>(
    course?.courseName || course?.name
  );
  const [courseDescription, setCourseDescription] = useState(
    course?.courseDescription || course?.description
  );
  const [courseImage, setCourseImage] = useState<string>(
    course?.courseImage || course?.image
  );

  const [prevContentOrganisation, setPrevContentOrganisation] = useState<any>(
    []
  );
  const [orgState, setOrgState] = useState<any>({
    library: { organizations: [] },
  });

  const [loadeFroala, setLoadeFroala] = useState(false);

  const handleOpen = async () => {
    await initFroala();
    setTimeout(() => {
      setLoadeFroala(true);
    }, 100);
  };
  useEffect(() => {
    handleOpen();
  }, []);

  const shareState = {
    correctionType,
    setCorrectionType,
    emailCertificate,
    setEmailCertificate,
    courseAccess,
    setCourseAccess,
    courseEndDate,
    setCourseEndDate,
    inOrder,
    setInOrder,
    isMandatory,
    setIsMandatory,
    minScore,
    setMinScore,
    courseStartDate,
    setCourseStartDate,
    courseName,
    setCoursename,
    setCourseDescription,
    courseDescription,
    setPrevContentOrganisation,
    prevContentOrganisation,
    orgState,
    setOrgState,
    courseImage,
    setCourseImage,
    courseType,
    setCourseType,
  };

  //save share settings, will create a new course when none exist
  const saveShare = async (myCourse = course) => {
    const changes: any = {};
    if (courseType !== course?.courseType) {
      changes.courseType = courseType;
    }
    if (correctionType !== course?.correctionType) {
      changes.correctionType = correctionType;
    }
    if (emailCertificate !== course?.emailCertificate) {
      changes.emailCertificate = emailCertificate;
    }
    if (courseAccess !== course?.courseAccess) {
      changes.courseAccess = courseAccess;
    }
    if (courseEndDate !== course?.courseEndDate) {
      changes.courseEndDate = courseEndDate;
    }
    if (inOrder !== course?.inOrder) {
      changes.inOrder = inOrder;
    }
    if (isMandatory !== course?.isMandatory) {
      changes.isMandatory = isMandatory;
    }
    if (minScore !== course?.minScore) {
      changes.minScore = minScore;
    }
    if (courseStartDate !== course?.courseStartDate) {
      changes.courseStartDate = courseStartDate;
    }
    if (courseName !== course?.courseName) {
      changes.courseName = courseName;
    }
    if (courseDescription !== course?.courseDescription) {
      changes.courseDescription = courseDescription;
    }
    if (picture !== courseInfo?.picture) {
      changes.courseImage = picture;
    }

    const axiosFn = myCourse.id
      ? axios.put.bind(null, `${config.backend}/course/${myCourse.id}`)
      : axios.post.bind(null, `${config.backend}/courses`);

    const result: any = await axiosFn({
      coursecontentId: myCourse.coursecontentId,
      ...changes,
    });

    myCourse = result?.data;

    if (orgState?.library?.organizations?.length > 0) {
      const selectOrg = orgState?.library?.organizations;
      for (const addObj2 of selectOrg) {
        const existingObj = prevContentOrganisation?.find(
          (obj1: any) => obj1?.key === addObj2?.key
        );
        if (!existingObj) {
          await axios.post(`${config.backend}/membercourses`, {
            courseId: myCourse.id,
            organisationId: addObj2?.key,
          });
        }
      }
      for (const removedObj of prevContentOrganisation) {
        const existingObj = orgState?.library?.organizations?.find(
          (obj1: any) => obj1?.key === removedObj?.key
        );
        if (!existingObj) {
          await axios.delete(
            `${config.backend}/membercourse/${removedObj?.key}/${myCourse.id}`
          );
        }
      }
    }
    if (
      orgState?.library?.organizations?.length < 1 &&
      prevContentOrganisation?.length > 0
    ) {
      for (const removedObj of prevContentOrganisation) {
        await axios.delete(
          `${config.backend}/membercourse/${removedObj?.key}/${myCourse.id}`
        );
      }
    }

    return result?.data;
  };
  //

  //user/teams state
  const [newUsers, setNewUsers] = useState<any[]>([]);
  const [newTeams, setNewTeams] = useState<any[]>([]);
  const [newTeachers, setNewTeachers] = useState<any[]>([]);
  const [hasCustomLanding, setHasCustomLanding] = useState<boolean>(
    course?.hasCustomLanding || false
  );

  const userState = {
    newUsers,
    setNewUsers,
    newTeachers,
    setNewTeachers,
    newTeams,
    setNewTeams,
    hasCustomLanding,
    setHasCustomLanding,
  };

  const saveUser = async (myCourse = course) => {
    const promises = [];

    //save the teams
    promises.push(
      newTeams.map((team: any) =>
        axios.post(`${config.backend}/courseteams`, {
          courseId: myCourse.id,
          teamId: team?.teamId || team?.id,
        })
      )
    );

    //save settings
    if (hasCustomLanding) {
      promises.push(
        axios.put(`${config.backend}/course/${myCourse.id}`, {
          hasCustomLanding,
        })
      );
    } else {
      promises.push(
        axios.put(`${config.backend}/course/${myCourse.id}`, {
          hasCustomLanding,
          courseDescription: course?.description,
          courseName: course?.name,
          courseImage: course?.image,
        })
      );
    }

    //save the users
    const provisionResults = newUsers.map(async (item: any) => {
      return new Promise((resolve, reject) => {
        if (item.newUser) {
          axios
            .post(
              `${config.backend}/invite/provision?lang=${auth?.user?.lang}`,
              {
                organisationId: auth?.user?.activeOrganisationId,
                email: item.label,
                firstName: item.firstName,
                lastName: item.lastName,
                phone: item.phone,
                role: Math.min(item.role, 50),
              }
            )
            .then((result) => {
              resolve(
                Object.assign({}, result?.data, {
                  key: result?.data.id,
                  role: item.role,
                })
              );
            })
            .catch(reject);
        } else {
          resolve(item);
        }
      });
    });

    const provisionTeachersResults = newTeachers.map(async (item: any) => {
      return new Promise((resolve, reject) => {
        if (item.newUser) {
          axios
            .post(
              `${config.backend}/invite/provision?lang=${auth?.user?.lang}`,
              {
                organisationId: auth?.user?.activeOrganisationId,
                email: item.label,
                firstName: item.firstName,
                lastName: item.lastName,
                phone: item.phone,
                role: Math.min(item.role, 50),
              }
            )
            .then((result) => {
              resolve(
                Object.assign({}, result?.data, {
                  key: result?.data.id,
                  role: item.role,
                })
              );
            })
            .catch(reject);
        } else {
          resolve(item);
        }
      });
    });

    const students: any[] = await Promise.all(provisionResults);
    const teachers: any[] = await Promise.all(provisionTeachersResults);

    const promiseStudentMembers: any[] = [];
    for (const student of students) {
      promiseStudentMembers.push(
        new Promise((resolve) => {
          axios
            .post(`${config.backend}/members`, {
              organisationId: authUser?.activeOrganisationId,
              userId: student?.userId || student?.id,
            })
            .finally(() => resolve(student));
        })
      );
    }

    const promiseTeacherMembers: any[] = [];
    for (const teacher of teachers) {
      promiseTeacherMembers.push(
        new Promise((resolve) => {
          axios
            .post(`${config.backend}/members`, {
              organisationId: authUser?.activeOrganisationId,
              userId: teacher?.userId || teacher?.id,
            })
            .finally(() => resolve(teacher));
        })
      );
    }

    await Promise.all(promiseStudentMembers);
    await Promise.all(promiseTeacherMembers);

    const promisesFinal: any[] = [];
    for (const student of students) {
      promisesFinal.push(
        axios.post(`${config.backend}/follows`, {
          courseId: myCourse.id,
          userId: student?.userId || student?.id,
        })
      );
    }

    for (const teacher of teachers) {
      promisesFinal.push(
        axios.post(`${config.backend}/teaches`, {
          courseId: myCourse.id,
          userId: teacher?.userId || teacher?.id,
        })
      );
    }

    return Promise.allSettled([...promises, ...promisesFinal]);
  };

  //commm step state
  const [lvCompletePassed, setLvCompletePassed] = useState<string>(
    courseInfo?.lvCompletePassed || ''
    // t('pages.course_content_flow.step_4.message_passed_default')
  );
  const [lvCompleteFailed, setLvCompleteFailed] = useState<string>(
    courseInfo?.lvCompleteFailed || ''
    // t('pages.course_content_flow.step_4.message_failed_default')
  );
  const [lvCustomMail, setLvCustomMail] = useState<string>(
    courseInfo?.lvCustomMail
  );
  const [onboardingRequired, setOnboardingRequired] = useState<string>(
    course?.onboardingRequired
  );
  const [onboardingSteps, setOnboardingSteps] = useState<string[]>(
    courseInfo?.onboardingSteps || [
      'onboarding_names_step',
      'onboarding_email_step',
      'onboarding_location_step',
      'onboarding_regime_step',
      'onboarding_referral_step',
      'onboarding_current_work_step',
      'onboarding_contact_step',
      'onboarding_diploma_step',
    ]
  );
  const [nrOfReminders, setNrOfReminders] = useState<number>(
    courseInfo?.nrOfReminders || 0
  );
  const [reminderInterval, setReminderInterval] = useState<number>(
    courseInfo?.reminderInterval || 0
  );
  const [doNotRemindProgress, setDoNotRemindProgress] = useState<number>(
    courseInfo?.doNotRemindProgress * 100 || 0
  );
  const [reminderEmail, setReminderEmail] = useState<string>(
    courseInfo?.reminderEmail
  );
  const [welcomeMessage, setWelcomeMessage] = useState<string>(
    courseInfo?.welcomeMessage ||
      t(
        'pages.course_content_flow.step_4.onboarding.message_introduction_onboardin'
      )
  );

  const commState = {
    lvCompletePassed,
    setLvCompletePassed,
    lvCompleteFailed,
    setLvCompleteFailed,
    lvCustomMail,
    setLvCustomMail,
    onboardingRequired,
    setOnboardingRequired,
    onboardingSteps,
    setOnboardingSteps,
    nrOfReminders,
    setNrOfReminders,
    reminderInterval,
    setReminderInterval,
    doNotRemindProgress,
    setDoNotRemindProgress,
    reminderEmail,
    setReminderEmail,
    welcomeMessage,
    setWelcomeMessage,
    course,
  };

  const saveComm = async (myCourse = course, myCourseInfo = courseInfo) => {
    const changes: any = {};
    if (lvCompletePassed !== courseInfo?.lvCompletePassed) {
      changes.lvCompletePassed = lvCompletePassed;
    }
    if (lvCompleteFailed !== courseInfo?.lvCompleteFailed) {
      changes.lvCompleteFailed = lvCompleteFailed;
    }
    if (lvCustomMail !== courseInfo?.lvCustomMail) {
      changes.lvCustomMail = lvCustomMail;
    }
    if (onboardingSteps !== courseInfo?.onboardingSteps) {
      changes.onboardingSteps = onboardingSteps;
    }
    if (nrOfReminders !== courseInfo?.nrOfReminders) {
      changes.nrOfReminders = nrOfReminders;
    }
    if (reminderInterval !== courseInfo?.reminderInterval) {
      changes.reminderInterval = reminderInterval;
    }
    if (doNotRemindProgress / 100 !== courseInfo?.doNotRemindProgress) {
      changes.doNotRemindProgress = doNotRemindProgress / 100;
    }
    if (reminderEmail !== courseInfo?.reminderEmail) {
      changes.reminderEmail = reminderEmail;
    }
    if (welcomeMessage !== courseInfo?.welcomeMessage) {
      changes.welcomeMessage = welcomeMessage;
    }

    const axiosFn = myCourseInfo?.id
      ? axios.put.bind(
          null,
          `${config.backend}/courseinfo/${myCourseInfo.courseId}`
        )
      : axios.post.bind(null, `${config.backend}/courseinfos`);

    const result: any = await axiosFn({
      courseId: myCourse?.id,
      ...changes,
    });

    axios.put(`${config.backend}/course/${myCourse?.id}`, {
      onboardingRequired: onboardingRequired ? onboardingRequired : false,
    });

    return result?.data;
  };

  //homepage state
  const [picture, setPicture] = useState<string>(courseInfo?.picture);
  const [abstract, setAbstract] = useState<string>(courseInfo?.abstract);
  const [bannerPicture, setBannerPicture] = useState<string>(
    courseInfo?.bannerPicture
  );
  const [subTitles, setSubTitles] = useState<string[]>(
    courseInfo?.subTitles || []
  );
  const [subImages, setSubImages] = useState<string[]>(
    courseInfo?.subImages || []
  );
  const [subTexts, setSubTexts] = useState<string[]>(
    courseInfo?.subTexts || []
  );
  const [name, setName] = useState<number>(course?.name);

  const homepageState = {
    picture,
    setPicture,
    abstract,
    setAbstract,
    subTitles,
    setSubTitles,
    subImages,
    setSubImages,
    subTexts,
    setSubTexts,
    name,
    setName,
    courseName,
    setCoursename,
    setCourseDescription,
    courseDescription,
    setBannerPicture,
    bannerPicture,
  };

  const saveHome = async (myCourse = course, myCourseInfo = courseInfo) => {
    const changes: any = {};

    if (picture !== courseInfo?.picture) {
      changes.picture = picture;
    }
    if (bannerPicture !== courseInfo?.bannerPicture) {
      changes.bannerPicture = bannerPicture;
    }
    if (abstract !== courseInfo?.abstract) {
      changes.abstract = abstract;
    }
    if (subTitles !== courseInfo?.subTitles) {
      changes.subTitles = subTitles;
    }
    if (subImages !== courseInfo?.subImages) {
      changes.subImages = subImages;
    }
    if (subTexts !== courseInfo?.subTexts) {
      changes.subTexts = subTexts;
    }

    const axiosFn = myCourseInfo.id
      ? axios.put.bind(
          null,
          `${config.backend}/courseinfo/${myCourseInfo.courseId}`
        )
      : axios.post.bind(null, `${config.backend}/courseinfos`);

    const result: any = await axiosFn({
      courseId: myCourse?.id,
      ...changes,
    });

    return result?.data;
  };

  const [saveShareFn, setSaveShareFn] = useState<any>(undefined);

  const [globalState, setGlobalState] = useState<any>({
    teams: [],
    sharingSettings: {
      autoCorrect: course?.correctionType === 'immediate' ? true : false,
      certificate: course?.emailCertificate || false,
      courseAccess: course?.courseAccess || 'protected',
      endDay: course?.courseEndDate || null,
      inOrder: course?.inOrder || 'false',
      mandatory: course?.isMandatory,
      score: course?.minScore * 100,
      startDay: course?.courseStartDate || new Date(),
    },
  });

  const shareRef = useRef(null);
  const userRef = useRef(null);

  const [customHomepage, setCustomHomepage] = useState<boolean>(false);
  const [addStep, setAddStep] = useState(0);
  const [courseId, setCourseId] = useState(null);
  const [action, setAction] = useState<boolean>(false);

  const [myActiveStaps, setMyActiveSteps] = useState(activeSteps);

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const parseStep = () => {
    const realStepIndex = step === 1 || addStep ? step : step + 1;
    return steps[realStepIndex - 1];
  };

  useEffect(() => {
    if (courseType === 'instruction') {
      setAddStep(-1);
      setMyActiveSteps(activeSteps);
    } else {
      if (hasCustomLanding) {
        setAddStep(1);
        setMyActiveSteps([
          ...activeSteps,
          activeSteps[activeSteps.length - 1] + 1,
        ]);
      } else {
        setAddStep(0);
      }
    }
  }, [hasCustomLanding, courseType]);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 0);
  }, []);

  const handleSave = async () => {
    setLoader(true);

    let result, newCourse;

    if (create) {
      //we create new courses based on the last published version, if no published version exist,
      //create one
      try {
        result = await axios.get(
          `${config.backend}/publish/lastversion/${course.id}`
        );
      } catch (e) {
        result = await axios.post(`${config.backend}/publish/${course.id}`, {});
      }
      newCourse = await saveShare(
        create
          ? {
              coursecontentId: result?.data?.id,
            }
          : undefined
      );
    } else {
      newCourse = await saveShare();
    }

    //from now on we have a course
    const promises = [];
    promises.push(saveUser(newCourse));

    const newCourseInfo = await saveComm(newCourse, courseInfo);

    //from now on we have a course and a courseinfo
    promises.push(saveHome(newCourse, newCourseInfo));

    if (create) {
      promises.push(
        axios.post(`${config.backend}/membercourses`, {
          courseId: newCourse.id,
          organisationId: auth?.user?.activeOrganisationId,
        })
      );
    }

    await Promise.allSettled(promises);

    onSubmit && onSubmit(newCourse);
  };

  return (
    <div className="relative h-full max-h-[calc(100vh_-_96px)] border-t border-grey-02">
      {loadStep && (
        <div className="fixed absolute inset-0 z-[13] z-10 flex bg-white/75">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-14 !w-14 border-grey-06"
            />
          </div>
        </div>
      )}
      <Stepper>
        {myActiveStaps.includes(1) && (
          <Stepper.Item
            isActive={step === 1}
            isDone={step > 1}
            onClick={() => {
              setStep(1);
              step !== 1 && setLoadStep(true);
            }}
            step="1"
          >
            {!isMobile && <>{t('pages.course_content_flow.users_teams')}</>}
          </Stepper.Item>
        )}
        {hasCustomLanding && courseType !== 'instruction' && (
          <Stepper.Item
            isActive={step === 2}
            isDone={step > 2}
            onClick={() => {
              setStep(2);
              step !== 2 && setLoadStep(true);
            }}
            step="2"
          >
            {!isMobile && <>{t('pages.course_content_flow.homepage')}</>}
          </Stepper.Item>
        )}
        {myActiveStaps.includes(2 + addStep) &&
          courseType !== 'instruction' && (
            <Stepper.Item
              isActive={step === 2 + addStep}
              isDone={step > 2 + addStep}
              onClick={() => {
                setStep(2 + addStep);
                step !== 2 + addStep && setLoadStep(true);
              }}
              step={`${2 + addStep}`}
            >
              {!isMobile && (
                <>{t('pages.course_content_flow.sharing_settings')}</>
              )}
            </Stepper.Item>
          )}
        {myActiveStaps.includes(3 + addStep) && (
          <Stepper.Item
            isActive={step === 3 + addStep}
            isDone={step > 3 + addStep}
            onClick={() => {
              setStep(3 + addStep);
              step !== 3 + addStep && setLoadStep(true);
            }}
            step={`${3 + addStep}`}
          >
            {!isMobile && <>{t('pages.course_content_flow.communication')}</>}
          </Stepper.Item>
        )}
      </Stepper>

      <div className="h-full max-h-[calc(100vh_-_256px)] overflow-y-auto py-8">
        <div className="mx-auto flex max-w-screen-md flex-col justify-center px-3">
          <div className="relative flex flex-col justify-center">
            {step === 1 && (
              <AddUserTeamStep
                state={userState}
                teamInfo={teamInfo}
                student={student}
                setLoadStep={setLoadStep}
                courseType={courseType}
              />
            )}
            {step === 2 && homepageState && hasCustomLanding && (
              <HomePageStep
                course={course}
                state={homepageState}
                setLoadStep={setLoadStep}
              />
            )}
            {step === 2 + addStep && (
              <SharingSettingsStep
                course={course}
                state={shareState}
                setLoadStep={setLoadStep}
                publicationType={publicationType}
                courseTypeNoInstruction={courseType !== 'instruction'}
              />
            )}
            {step === 3 + addStep && (
              <CommunicationStep
                state={commState}
                loadeFroala={loadeFroala}
                setLoadStep={setLoadStep}
                courseType={courseType}
              />
            )}
          </div>
        </div>
      </div>

      <div className="mt-auto flex border-t border-grey-02 py-5">
        <div className="mx-auto flex w-full max-w-screen-md justify-end px-3">
          <Button
            variant="outline"
            disabled={step === myActiveStaps[0]}
            onClick={() => {
              setStep(step - 1);
              setLoadStep(true);
            }}
            className="mr-auto"
            data-testid="prevBtn"
          >
            {t('button.previous')}
          </Button>
          <Button
            className="mr-2"
            variant={!create ? 'outline' : undefined}
            disabled={step === 3 + addStep}
            onClick={() => {
              setStep(step + 1);
              setLoadStep(true);
            }}
            data-testid="nextBtn"
          >
            {t('button.next')}
          </Button>
          {(!create || step === 3 + addStep) && (
            <Button
              onClick={() => {
                handleSave();
              }}
              data-testid="nextBtn"
            >
              {t('button.save')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepsComponent;

import { useState, useEffect, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Switch,
  Multiselect,
  IconButton,
  InputSelect,
  Label,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useGet, useOrganisations } from '@/query';
import { CustomCompanyAvatar } from '@/components/CustomCompanyAvatar';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import { OrganisationContext } from '@/components/Contexts/OrganisationContext';

const COURSECONTENT_ROLE_OPTIONS = [
  {
    value: 20,
    label: 'read',
  },
  {
    value: 90,
    label: 'write',
  },
];

const LibraryAddOrganizations = ({
  publication,
  state,
  updateState,
  auth,
  setPrevContentOrganisation,
  prevContentOrganisation,
  globalState,
  courseSettings,
  getOrg,
  payload,
  publicationType,
}: any) => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState<any>([]);
  const [data, seData] = useState<any>([]);
  const { myOrganisations } = useContext(OrganisationContext);

  let isAdminSomewhereElse = false,
    adminOrganisations = [];
  try {
    adminOrganisations = myOrganisations.filter(
      (org: any) =>
        (org.id !== auth?.user?.activeOrganisationId &&
          auth?.user?.role >= 90) ||
        org.role >= 90
    );
    isAdminSomewhereElse = adminOrganisations.length > 0;
  } catch (e) {
    console.error(e);
  }

  const { data: organisations } = useOrganisations({
    userId: auth?.user?.id as number,
  });
  const [contentorganisation, setContentorganisation] = useState<any>();

  useGet(`${getOrg}`, {
    ...payload,
    onSuccess: (result: any) => {
      setContentorganisation(result || null);
    },
  });

  useEffect(() => {
    if (organisations) {
      const data = (organisations as any[]).map((org) => ({
        key: org.id,
        label: org.organisationName,
        organisationName: org.organisationName,
        organisationId: org.organisationId,
        id: org.id,
        picture: org?.picture,
      }));
      seData(data);
    }
  }, [organisations]);

  useEffect(() => {
    if (
      contentorganisation?.data &&
      globalState?.library?.organizations?.length < 1 &&
      prevContentOrganisation?.length < 1
    ) {
      const data = (contentorganisation?.data as any[])?.map((org) => ({
        key: org.id,
        label: org.organisationName,
        organisationName: org.organisationName,
        organisationId: org.organisationId,
        id: org.id,
        picture: org?.picture,
        role: org?.role,
      }));
      setPrevContentOrganisation(data);
      updateState({ organizations: data });
    }
  }, [contentorganisation?.data]);

  const handleAddOrganisation = () => {
    const addOrganisation = [...state.organizations];
    for (const obj2 of organizations) {
      const existingObj = addOrganisation.find((obj1) => obj1.key === obj2.key);
      if (!existingObj) {
        addOrganisation.push(Object.assign(obj2, { role: 20 }));
      }
    }
    updateState({ organizations: addOrganisation });
    setOrganizations([]);
  };
  const handleUpdateOrganisation = (i: number, update: any) => {
    const addOrganisation = [...state.organizations];
    Object.assign(addOrganisation[i], update);
    updateState({ organizations: addOrganisation });
  };
  const handleRemoveOrganisation = (id: any) => {
    const removeOrganisation = state.organizations.filter(
      (item: any) => item.key !== id
    );
    updateState({ organizations: removeOrganisation });
  };

  return (
    <div className="flex flex-col gap-4 rounded-lg border border-grey-02 p-4">
      {!courseSettings && auth?.user?.role >= 90 && (
        <div
          className="flex flex-row items-center gap-4"
          data-testid="allOrganizations"
        >
          <Switch
            checked={state?.allOrganizations}
            onChange={(isCheck) => updateState({ allOrganizations: isCheck })}
          />
          <div>
            <h4 className="pb-2 font-normal">
              {t('pages.publish_course.steps_2.switch_allorganizations')}
            </h4>
            <p className="text-xs text-grey-05">
              {t(
                `pages.create_edit_course.type_${publicationType}.switch_allorganizations_description`
              )}
            </p>
          </div>
        </div>
      )}

      {(!state?.allOrganizations || courseSettings) &&
        (isAdminSomewhereElse || auth?.user?.role >= 90) && (
          <div>
            <h4 className="mt-1 mb-2">
              {t(
                `pages.create_edit_course.type_${publicationType}.choose_organizations_title`
              )}
            </h4>
            <div>
              <div>
                <div className="my-6">
                  <div id="invite_author" className="ali relative z-[11] flex">
                    <span className="w-full" data-testid="organizationsSelect">
                      <PaginationMultiSelect
                        value={organizations || []}
                        onChange={(value: any) => {
                          setOrganizations(value);
                        }}
                        keyFn={(row: any) => row?.id}
                        fetch={useGet.bind(
                          null,
                          auth?.user?.role < 90
                            ? `/memberorganisations?userId=${auth?.user?.id}`
                            : '/organisationdetails'
                        )}
                        filterFn={(org: any) => {
                          return (
                            state.organizations.findIndex(
                              (x: any) => x.id === org.id
                            ) == -1
                          );
                        }}
                        defaultSort={[{ id: 'organisationName', desc: false }]}
                        render={(row: any) => {
                          return row?.organisationName;
                        }}
                      />

                      {/*
                    <Multiselect
                      data-testid="userInviteMultiselect"
                      removeButtonLabel="clear"
                      toggleSelectionLabel="toggle"
                      onChange={(param) => {
                        setOrganizations(param);
                      }}
                      options={data}
                      value={organizations || []}
                      placeholder={t(
                        'pages.publish_course.steps_2.organization_placeholder'
                      )}
                    />
                    */}
                    </span>

                    <Button
                      className="ml-3 h-[36px] text-base font-medium"
                      variant="primary"
                      disabled={organizations.length === 0}
                      onClick={handleAddOrganisation}
                    >
                      {t('pages.course_content_flow.step_1.add_btn')}
                    </Button>
                  </div>
                  <div
                    className="mt-7 grid gap-2"
                    style={{
                      gridTemplateColumns: `repeat(${
                        courseSettings ? 2 : 3
                      }, auto)`,
                    }}
                  >
                    {state?.organizations?.map((item: any, i: number) => (
                      <Fragment key={item.id}>
                        <CustomCompanyAvatar item={item} />
                        {/* <div className="flex items-center">
                        <div
                          className="'h-8 mr-3 grid min-h-8 w-8 min-w-8 items-center justify-center overflow-hidden rounded-lg bg-grey-02 hover:after:bg-black"
                          title={item.organisationName}
                        >
                          <span className="z-10">
                            {' '}
                            <img
                              className="h-full object-contain"
                              src={item.picture || ''}
                              alt={item.organisationName || ''}
                            />
                          </span>
                        </div>

                        <div className="ml-7 flex flex-col">
                          <span className="text-sm font-medium text-[#231f20]">
                            {item.organisationName}
                          </span>
                        </div>
                      </div> */}
                        {!courseSettings && (
                          <div className="flex items-center">
                            <InputSelect
                              value={COURSECONTENT_ROLE_OPTIONS.find(
                                (y) => y.value === item.role
                              )}
                              options={COURSECONTENT_ROLE_OPTIONS}
                              onChange={(data) => {
                                handleUpdateOrganisation(i, {
                                  role: data.value,
                                });
                              }}
                            >
                              {(options) => (
                                <span
                                  className="min-w-50"
                                  key={item.id + '_' + options.value}
                                >
                                  {t('access_role.' + options.label)}
                                </span>
                              )}
                            </InputSelect>
                          </div>
                        )}
                        <div
                          className="flex items-center justify-self-end"
                          data-testid={item?.organisationName}
                        >
                          <IconButton
                            Icon={CrossFilled}
                            variant="ghost"
                            onClick={() => handleRemoveOrganisation(item.key)}
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
export default LibraryAddOrganizations;

import { Button } from '@teo/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LesoonExtraInfo } from '@/components/ExtraInfo/LesoonExtraInfo';

export const ExtraInfoLesson = ({
  node,
  course,
  rootCourseId,
  allExtraInfo,
  allDirectories,
  // allAssets,
  setShowExtraInfo,
  showExtraInfo,
  setExtraInfoFullScreen,
  extraInfoFullScreen,
  setActiveExtraInfo,
  activeExtraInfo,
  setLengthExtraInfo,
  lengthExtraInfo,
  preview,
  iSsmallLaptop,
  isMobile,
  viewMobile,
}) => {
  const { t } = useTranslation();
  const [rootExtraInfa, setRootExtraInfa] = useState([]);
  const [parentExtraInfa, setParentExtraInfa] = useState([]);
  const [lessonExtraInfa, setLessonExtraInfa] = useState([]);

  useEffect(() => {
    if (node) {
      if (allExtraInfo?.length > 0) {
        setLessonExtraInfa(
          allExtraInfo.filter(
            (les) =>
              les?.rootLessonId === node?.lessonId &&
              les?.path?.includes(node?.path)
          )
        );
      }
    }

    if (rootCourseId) {
      if (allExtraInfo?.length > 0) {
        setRootExtraInfa(
          allExtraInfo?.filter(
            (les) => les?.rootDirectoryId === rootCourseId?.id
          )
        );
      }
    }
    if (node && allDirectories && allExtraInfo?.length > 0) {
      const parts = node?.path?.split('/').filter((part) => part !== '');
      parts?.pop();
      const parentDirectories = allDirectories?.filter((obj) => {
        return parts?.some((part) => obj.path.endsWith(part));
      });
      const arrDirectories = parentDirectories?.map((chap) => {
        let extraLes = [];
        if (allExtraInfo?.length > 0) {
          extraLes = allExtraInfo?.filter(
            (les) => les?.rootDirectoryId === chap?.id
          );
        }
        return {
          chapter: chap,
          extraInfo: extraLes?.filter(
            (les) => les?.rootDirectoryId === chap?.id
          ),
        };
      });
      setParentExtraInfa(
        arrDirectories.sort(
          (a, b) => b?.chapter?.path?.length - a?.chapter?.path?.length
        )
      );
    }
  }, [node, allExtraInfo, allDirectories]);

  useEffect(() => {
    const directoriesLength = parentExtraInfa.reduce((accumulator, chapt) => {
      return accumulator + chapt?.extraInfo?.length;
    }, 0);
    const lengthAllExtraInfo =
      lessonExtraInfa?.length + rootExtraInfa?.length + directoriesLength;
    setLengthExtraInfo(lengthAllExtraInfo);
  }, [lessonExtraInfa, parentExtraInfa, rootExtraInfa]);

  return (
    <>
      {!iSsmallLaptop && !viewMobile && lengthExtraInfo > 0 && (
        <Button
          name="extra_info"
          variant="secondary"
          onClick={() => setShowExtraInfo(true)}
          className="sticky top-[96px]"
        >
          {t('extra_info.title')}
          <span className="pl-1 text-grey-05">({lengthExtraInfo})</span>
        </Button>
      )}

      {showExtraInfo && (
        <LesoonExtraInfo
          setShowExtraInfo={setShowExtraInfo}
          nameLes={node?.name}
          course={course}
          lessonExtraInfa={lessonExtraInfa}
          parentExtraInfa={parentExtraInfa}
          rootExtraInfa={rootExtraInfa}
          setExtraInfoFullScreen={setExtraInfoFullScreen}
          extraInfoFullScreen={extraInfoFullScreen}
          setActiveExtraInfo={setActiveExtraInfo}
          activeExtraInfo={activeExtraInfo}
          lengthExtraInfo={lengthExtraInfo}
          preview={preview}
          isMobile={isMobile}
          viewMobile={viewMobile}
        />
      )}
    </>
  );
};

import { BehaviorSubject } from 'rxjs';
import XAPI from '@xapi/xapi';
//import AbstractCmi5 from "@xapi/cmi5/AbstractCmi5";
import Cmi5 from '@xapi/cmi5';
import {
  Deferred,
  retryWithExponentialBackoff,
  parseQueryString,
  htmlDecode,
  deep_copy,
} from '@/util';
import { decode, encode } from '@/base64';
import { publicAxios } from '@/axios';
import { config } from '@/config';

class XAPIService {
  cmi5;
  xapi;
  initializedPromise = new Deferred();
  queries;
  lastProgress;

  constructor() {
    if (XAPIService._instance) return XAPIService._instance;
    XAPIService._instance = this;
  }

  generateTincanStatement() {
    //console.log("this.queries.activityId", this.queries.activity_id)
    //console.log("decodeURIComponent(this.queries.activityId)", decodeURIComponent(this.queries.activity_id))
    //console.log("htmlDecode(decodeURIComponent(this.queries.activityId))", htmlDecode(decodeURIComponent(this.queries.activity_id)))

    /*
    const statement = {
      actor: JSON.parse(decodeURIComponent(this.queries.actor)),
      context : {
        registration : this.queries.registration,
        object: {
          id : htmlDecode(decodeURIComponent(this.queries.activity_id)),
          type : "Activity",
        }
      }
    }
    */

    const statement = {
      actor: JSON.parse(decodeURIComponent(this.queries.actor)),
      object: {
        id: htmlDecode(decodeURIComponent(this.queries.activity_id)),
        definition: {
          name: {
            'en-US': 'Example Course Activity',
          },
          description: {
            'en-US': 'A learning activity to track progress.',
          },
        },
      },
      context: {
        registration: this.queries.registration,
        contextActivities: {
          parent: [
            {
              id: `http://app.learnupon.com/courses/${this.queries.component_id}`,
            },
          ],
          grouping: [
            {
              id: `http://app.learnupon.com/enrollments/${this.queries.enroll_id}`,
            },
          ],
        },
        extensions: {
          'https://learnupon.com/extensions/component_id':
            this.queries.component_id,
          'https://learnupon.com/extensions/lup_tincandata_id':
            this.queries.lup_tincandata_id,
          'https://learnupon.com/extensions/slt': this.queries.slt,
        },
      },
    };

    return statement;
  }

  /*
  {
 "actor" : {
 "mbox" : ["test.user@example.com"],
 "givenName" : ["Test"],
 "familyName" : ["User"]},
 "verb" : "completed",
 "object" : {
"id" : "http://example.com/samples/TinCan101",
"type" : "Activity",
"definition" : {
"name" : { "en" : "Example Activity, TinCan 101" },
"description" : { "en" : "Example learning activity
about Tin Can." }
}
}
}
*/

  /*
  generateTincanAxios() {
    publicAxios.defaults.headers = deep_copy(axios.defaults.headers);
    publicAxios.defaults.headers.common['Cache-Control'] = 'no-cache';
  }
  */

  progress(progress) {
    if (progress === this.lastProgress) {
      this.lastProgress = progress;
      return;
    }
    //console.log("this.queries", (this.queries.tincan === 'true'))
    if (this.queries.tincan === 'true') {
      //console.log("report progress")
      let progressStatement = this.generateTincanStatement();
      progressStatement.verb = {
        id: 'http://adlnet.gov/expapi/verbs/progressed',
        display: {
          'en-US': 'progressed',
        },
      };
      progressStatement.result = {
        completion: progress >= 0.99 ? true : false,
        progress: progress,
      };
      //console.log("sending progress statement", progressStatement)
      publicAxios
        .post(
          decodeURIComponent(this.queries.endpoint) + 'statements',
          progressStatement,
          {
            headers: { authorization: this.queries.auth },
          }
        )
        .then((result) => {
          //console.log("result", result)
        })
        .catch((e) => {
          console.warn('e', e);
        });

      //console.log("posting message", progressStatement)
      window.parent.postMessage(JSON.stringify(progressStatement), '*');
      return;
    }
    try {
      retryWithExponentialBackoff(() => {
        this.cmi5.progress(progress);
      });
    } catch (e) {
      console.error(e);
    }
    this.lastProgress = progress;
  }

  async reportFinal(follow, course) {
    try {
      try {
        await this.cmi5.complete({
          transform: (statement) => {
            //we overwrite the time spent
            const now = new Date();
            const nowMinusTimeSpent = new Date(
              now.getTime() - (follow.totalTimeSpent || 0) * 1000
            );
            statement.result.duration = XAPI.calculateISO8601Duration(
              nowMinusTimeSpent,
              now
            );
            return statement;
          },
        });
      } catch (e) {
        console.error(e);
      }
      if (!follow.maxScore) {
        this.cmi5.terminate();
        return;
      }
      const scaled = follow.score / (follow.maxScore || 1);
      const minScore = course?.minScore || 0.5;
      const score = {
        scaled: scaled,
        raw: Math.round(follow.score),
        min: 0,
        max: Math.round(follow.maxScore),
      };
      if (scaled >= minScore) {
        try {
          //await this.cmi5.pass(score);
          await this.cmi5.pass(score, {
            transform: (statement) => {
              //we overwrite the time spent
              const now = new Date();
              const nowMinusTimeSpent = new Date(
                now.getTime() - (follow.totalTimeSpent || 0) * 1000
              );
              statement.result.duration = XAPI.calculateISO8601Duration(
                nowMinusTimeSpent,
                now
              );
              return statement;
            },
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          //await this.cmi5.fail(score);
          await this.cmi5.fail(score, {
            transform: (statement) => {
              //we overwrite the time spent
              const now = new Date();
              const nowMinusTimeSpent = new Date(
                now.getTime() - (follow.totalTimeSpent || 0) * 1000
              );
              statement.result.duration = XAPI.calculateISO8601Duration(
                nowMinusTimeSpent,
                now
              );
              return statement;
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
      this.cmi5.terminate();
    } catch (e) {
      console.error(e);
    }
  }

  available() {
    return (
      this?.queries?.tincan !== 'true' && this.cmi5 && this.cmi5.isAuthenticated
    );
  }

  async initialize(sessionToken) {
    this.queries = parseQueryString(document.location.search);

    //console.log("queries", this.queries)

    const token = this.queries.token;

    //console.log("Is it available ?")

    if (this.cmi5 || this.queries.tincan === 'true')
      return this.initializedPromise.resolve();
    if (!Cmi5.isCmiAvailable) return this.initializedPromise.resolve();

    //console.log("It is available")
    this.cmi5 = new Cmi5();

    let xapitoken;
    try {
      xapitoken = await this.cmi5.initialize();
    } catch (e) {
      xapitoken = localStorage.getItem('xapitoken');
      try {
        console.error(e);
        xapitoken = await this.cmi5.initialize({
          authToken: xapitoken,
          initializedDate: new Date(),
        });
      } catch (e) {
        console.error(e);
      }
    }

    this.initializedPromise.resolve(xapitoken);
  }
}

XAPIService.getInstance = function (props) {
  return XAPIService._instance || new XAPIService(props);
};

export { XAPIService };

//http://localhost:3000/launch?fetch=https://lms.com/fetch_url&endpoint=https://lms.com/endpoint&registration=some-id

import { useState, useContext, useEffect } from 'react';
import { PageLayout } from '@/components/PageLayout';
import { Spinner } from '@teo/components';
import { Outlet, useNavigate } from 'react-router-dom';
import BreadCrumbsHeader from '@/components/BreadCrumbsHeader';
import { NavContext } from '@/components/NavContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { useMobile, parseQueryString, parseCourseType } from '@/util';
import { createSidebar } from '../../constants/sidebar.constants';
import SidebarNavigation from '@/components/SidebarNavigation';
import { CreateNewPublication } from '@/components/CreateNewPublication/CreateNewPublication';
import TypeCourse from '@/components/CreateNewPublication/TypeCourse';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import i18n from 'i18next';

function CreatePage() {
  const { collapsed, navDisabled, submenu } = useContext(NavContext);
  const { newPage } = useContext(PageUrlContext);
  const { auth } = useContext(AuthContext);

  const authLang: any = auth?.user.lang;
  const authOrg: any = auth?.user?.activeOrganisationId;

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };

  const isMobile = useMobile();
  const showNav = !navDisabled && ((submenu && !collapsed) || !isMobile);
  const showContent = collapsed || !isMobile;
  const [isOpenTypeModal, setIsOpenTypeModal] = useState(
    !!queries['courseType']
  );
  const [template, setTemplate] = useState('theory');
  const [typeCourse, setTypeCourse] = useState(
    parseCourseType(queries['courseType'] || 'course')
  );

  const [goToCreate, setGoToCreate] = useState(false);
  const [publicationId, setPublicationId] = useState();

  const [loadeNewPublication, setLoadeNewPublication] = useState(false);

  //insufficiënt role redirect
  const authUser = auth?.user as Userwithorganisationwithidps;
  const navigate = useNavigate();
  useEffect(() => {
    const role = Math.max(
      authUser?.activeOrganisationRole || 0,
      authUser?.role || 0
    );
    if (role <= 20) {
      navigate(`/${i18n.language}/noaccess`);
    }
  }, []);

  useEffect(() => {
    if (goToCreate) {
      setLoadeNewPublication(false);
      setIsOpenTypeModal(false);
      navigate(`/${authLang}/create/${authOrg}/courses/${publicationId}`);
    }
  }, [goToCreate]);

  if (navDisabled) return <Outlet />;
  return (
    <PageLayout>
      {showNav && (
        <PageLayout.Sidebar key={newPage}>
          <CreateNewPublication
            setIsOpenTypeModal={setIsOpenTypeModal}
            isMobile={isMobile}
            setTypeCourse={setTypeCourse}
            authUser={authUser}
          />
          <SidebarNavigation items={createSidebar} />
        </PageLayout.Sidebar>
      )}
      <div
        className={`${
          showContent ? '' : 'hidden'
        } min-h-[100vh] w-full max-w-6xl lg:min-h-[calc(100vh_-_150px)]`}
      >
        <PageLayout.Content>
          <BreadCrumbsHeader />
          <Outlet />
        </PageLayout.Content>
        {loadeNewPublication && (
          <div className="fixed inset-0 z-[201] m-auto flex min-h-screen min-w-full bg-white/75 p-10">
            <div className="m-auto">
              <Spinner
                ariaLabel="Loading spinner"
                className="!h-14 !w-14 border-grey-06"
              />
            </div>
          </div>
        )}
        {isOpenTypeModal && (
          <TypeCourse
            setIsOpenTypeModal={setIsOpenTypeModal}
            setTemplate={setTemplate}
            template={template}
            typeCourse={typeCourse}
            setGoToCreate={setGoToCreate}
            setPublicationId={setPublicationId}
            publicationId={publicationId}
            setLoadeNewPublication={setLoadeNewPublication}
          />
        )}
      </div>
    </PageLayout>
  );
}

export default CreatePage;

import React, { useState, useEffect } from 'react';
import { isEmpty, newUid, round2 } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import { BoxGUI } from '@/components/Froala/widgets/teo_box/BoxGUI';
import { Button } from '@teo/components';
import { T } from '@/components/T';
import { BoxIoService } from '@/components/Froala/widgets/teo_box/BoxIoService';
import { publicAxios } from '@/axios';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { KofferDisplay } from '@/components/Froala/widgets/teo_box/KofferDisplay';

export const BoxQuestionViewOld = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  BoxQuestionViewOld.syncStates(state, answer, correction);
  const [modified, setModified] = useState(null);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const answered = submitted || isCorrected;
  const [started, setStarted] = useState(false);

  useEffect(() => {
    publicAxios
      .get(
        `http://127.0.0.1:3100/api/exercise/${
          parseFloat(state.exercise || 0) || 0
        }`
      )
      .then((response) => {
        window.exercise = state.exercise;
      })
      .catch(console.error)
      .finally(() => {
        setStarted(true);
      });
  }, []);

  const getAnswer = async () => {
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    correction = {};
    BoxQuestionViewOld.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(correction.score)}
          maxScore={state.score}
          answered={answered}
          titleWidget={state.titleWidget || t('widgets.type.box_question')}
        />
      )}
      <FroalaTextarea mode="view" value={state.vraag}></FroalaTextarea>
      {started ? (
        <KofferDisplay></KofferDisplay>
      ) : (
        <div
          className="flex"
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Button
            onClick={() => {
              publicAxios
                .get(
                  `http://127.0.0.1:3100/api/exercise/${
                    parseFloat(state.exercise) || 0
                  }`
                )
                .then((response) => {
                  setStarted(true);
                  window.exercise = state.exercise;
                })
                .catch(console.error);
            }}
          >
            <T>Start exercise</T>
          </Button>
        </div>
      )}
      {(answered || showAnswers) && state?.antwoord ? (
        <div>
          <p className="text-sm font-semibold text-black text-secondary-04">
            {t('pages.correction_result.possible_answer')}
          </p>
          <div className="border-transparen rounded-md border border-solid bg-grey-transparent-01 p-3 text-sm text-grey-08">
            <FroalaTextareaView value={state?.antwoord} />
          </div>
        </div>
      ) : null}
      {(state.immediateFeedback || correctionType === 'autofeedback') && (
        <PartialSubmitSection
          setSubmitted={setSubmitted}
          setModified={setModified}
          answered={answered}
          onSave={onSave.bind(null, false, false, state)}
        />
      )}
    </div>
  );
};

BoxQuestionViewOld.syncStates = (state, answer, correction) => {
  if (isEmpty(answer)) {
    answer.uid = state.uid;
    answer.answer = '';
    answer.answered = false;
  }
  if (isEmpty(correction)) {
    correction.uid = state.uid;
    correction.score = state.score;
  }
};

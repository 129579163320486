import { ReactSVG } from 'react-svg';
import React, { Component, useState, createRef, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import './Svg.css';

const Svg = ({ src, afterInjection }) => {
  const [svgData, setSvgData] = useState('');
  const ref = createRef();

  useGet(src, {
    onSuccess: (result) => {
      setSvgData(result?.data);
    },
  });

  useEffect(() => {
    if (ref.current && svgData) {
      const svg = ref.current.childNodes[0];
      svg.setAttribute('preserveAspectRatio', 'xMinYMin meet');

      let nodes = svg.querySelectorAll('*[data-name]');
      for (let node of nodes) {
        let name = node.getAttribute('data-name');
        try {
          if (!node.getAttribute('data-type')) {
            let type = node
              .querySelector('*[data-type]')
              .getAttribute('data-type');
            node.dataset.type = type;
          }
        } catch (e) {
          console.error(e);
        }
        try {
          let title = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'title'
          );
          title.innerHTML = name;
          node.prepend(title);
        } catch (e) {
          console.error(e);
        }
      }
      svg.style.colorScheme = undefined; //messes with diagrams.net
      afterInjection(ref.current.querySelector('svg'));

      /*
      window.$(`*[data-name]`, svg).each((i, elNode) => {
        let el = window.$(elNode);
        let name = el.data('name');
        console.log("name", name)
        try {
          let typeNodes = el.find('*[data-type]');
          let type;
          if (typeNodes.length > 0) {
            for (let i = typeNodes.length - 1; i >= 0; i--) {
              const node = typeNodes[i];
              type ||= node.dataset.type;
              delete node.dataset.type;
            }
          }
          if (type) {
            el[0].dataset.type = type;
          }
        } catch (e) {
          console.error(e);
        }
        let title = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'title'
        );
        title.innerHTML = name;
        el.prepend(title);
      });
      */

      afterInjection(svg);
    }
  }, [ref.current, svgData]);

  return (
    <div
      ref={ref}
      className="svg-parent"
      dangerouslySetInnerHTML={{ __html: svgData }}
    ></div>
  );
};

export { Svg };

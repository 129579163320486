import { CompetencesOptionsInterface } from '@/models/competencesOptions.interface';

export const categoriesData: CompetencesOptionsInterface[] = [
  {
    id: '1',
    name: 'Soft skills',
  },
  {
    id: '2',
    name: 'Elektriciteit',
  },
];

export const sectorsData: CompetencesOptionsInterface[] = [
  {
    id: '1',
    name: 'General',
  },
  {
    id: '2',
    name: 'Agriculture',
  },
  {
    id: '3',
    name: 'Automotive',
  },
  {
    id: '4',
    name: 'Biotechnology',
  },
  {
    id: '5',
    name: 'Construction & real estate',
  },
  {
    id: '6',
    name: 'Consumer goods & retail',
  },
  {
    id: '7',
    name: 'Education & training',
  },
  {
    id: '8',
    name: 'Energy & utilities',
  },
  {
    id: '9',
    name: 'Entertainment & media',
  },
  {
    id: '10',
    name: 'Finance',
  },
];

export const moduleData: CompetencesOptionsInterface[] = [
  {
    id: '1',
    name: 'Basis wiskunde',
  },
  {
    id: '2',
    name: 'Algemeen redeneervermogen',
  },
  {
    id: '3',
    name: 'Technisch redeneervermogen',
  },
];

export const typeData: CompetencesOptionsInterface[] = [
  {
    id: '1',
    name: 'Kennis',
  },
  {
    id: '2',
    name: 'Vaardigheid',
  },
  {
    id: '3',
    name: 'Attitude',
  },
];

import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonLink,
  IconButton,
  InputText,
  Label,
} from '@teo/components';
import { EyeOff, Eye } from '@teo/components/icons';
import { authActions } from '@/actions/auth';
import SocialLoginButton from '@/components/Auth/SocialLoginButton/SocialLoginButton';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import { parseQueryString } from '@/util';
import { useGet } from '@/query/fetchHooks';
import { passwordRegex } from '@/constants/regex.constants';
import { axios, publicAxios } from '@/axios';
import { decode } from '@/base64';

function AuthError({ error }: { error: string }) {
  const { t } = useTranslation();
  return (
    <div className="text-sm-multiline rounded-xl bg-error-01 px-6 py-4 text-error-05">
      {t(error)}
    </div>
  );
}

function AuthLoginPage() {
  const { i18n, t } = useTranslation();
  const redirectUri = `/${i18n.language}/`;

  const [hasAuthError, setAuthError] = useState<string | false>(false);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [organisationInfo, setOrganisationInfo] = useState<any>(null);
  const [showPassword, setShowPassword] = useState(false);

  const { auth, setAuth } = useContext(AuthContext);

  let learnPath = `/${i18n.language}/learn`;
  if (auth?.user?.activeOrganisationId)
    learnPath += `/${auth?.user?.activeOrganisationId}`;

  const followupPath = `/${i18n.language}/follow-up/${auth?.user?.activeOrganisationId}`;

  const navigate = useNavigate();
  const authUser = auth?.user as Userwithorganisationwithidps;

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };

  useGet<any>(`/organisationinfo/${queries.organisation}`, {
    onSuccess: (result: any) => {
      setOrganisationInfo(result?.data);
    },
    enabled: !!queries?.organisation,
  });

  let useLocalLogin = !!organisationInfo?.loginMethods?.includes('local');

  let loginMethods = organisationInfo?.loginMethods.filter(
    (method: string) => method !== 'local'
  );

  if ((!loginMethods || loginMethods.length === 0) && !useLocalLogin) {
    loginMethods = ['google', 'facebook', 'linkedin'];
    useLocalLogin = true;
  }

  const mapLogo = (method: any) => {
    try {
      const i = organisationInfo?.loginMethods?.findIndex(
        (x: any) => x === method
      );
      if (i === -1 || i >= organisationInfo?.loginLogos.length) return null;
      return organisationInfo?.loginLogos[i];
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  useEffect(() => {
    if (localStorage.getItem('koffer')) {
      publicAxios
        .get('http://127.0.0.1:3100/api/login_data')
        .then((result: any) => {
          const logindata = result?.data;
          const match = logindata.loginUrl.match(/.*organisation=(.*?)$/);
          if (match && match.length > 1 && !queries?.organisation) {
            navigate(`/custom-login?organisation=${match[1]}`);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (authUser) {
      const postLoginRedirect = localStorage.getItem('postLoginRedirect');
      if (postLoginRedirect) {
        return navigate(postLoginRedirect);
      } else {
        return navigate(
          `/${i18n?.language || 'nl'}/learn` + window.location.search
        );
      }
    }
    if (queries['error']) {
      setAuthError('errors.' + JSON.parse(decode(queries['error'])).status);
    }
  }, []);

  async function login() {
    try {
      setAuthError(false);
      const result = await authActions.loginLocal(username, password);
      const postLoginRedirect = localStorage.getItem('postLoginRedirect');
      if (postLoginRedirect) {
        localStorage.removeItem('postLoginRedirect');
        authActions.login('local', postLoginRedirect, result.data.token);
      } else if (result.data.role >= 50) {
        authActions.login('local', followupPath, result.data.token);
      } else {
        authActions.login('local', learnPath, result.data.token);
      }
    } catch (e) {
      setAuthError('pages.auth.login.error_message');
    }
  }

  return (
    <div className="h-full overflow-hidden md:flex md:items-center">
      <div className="flex h-full overflow-hidden md:w-1/2 md:items-center md:overflow-auto">
        <form
          className="m-auto flex w-full max-w-[420px] flex-col max-md:h-full max-md:overflow-hidden"
          onSubmit={(event) => {
            event.preventDefault();
            login();
          }}
        >
          <pre></pre>
          <div className="space-y-6 px-5 pt-8 max-md:flex-grow max-md:overflow-auto md:space-y-8">
            <img className="h-12 w-12 md:h-16 md:w-16" src="/TEO_logo.svg" />
            <h1 className="text-3xl-semibold">{t('pages.auth.login.title')}</h1>
            <div className="flex space-x-3">
              {loginMethods.map((method: string) => (
                <SocialLoginButton
                  key={method}
                  img={mapLogo(method)}
                  loginMethodName={method}
                  onClick={() => authActions.login(method, redirectUri)}
                />
              ))}
            </div>
            {useLocalLogin && (
              <>
                <div className="space-y-6 md:space-y-8">
                  {hasAuthError && <AuthError error={hasAuthError} />}
                  <p className="text-sm text-grey-05">
                    {t('pages.auth.login.choose_method')}
                  </p>
                  <div>
                    <Label htmlFor="email">
                      {t('pages.auth.login.email_label')}
                    </Label>
                    <InputText
                      id="email"
                      onChange={(event) => {
                        setUsername(event.target.value);
                      }}
                      placeholder={t('pages.auth.login.email_placeholder')}
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="password">
                      {t('pages.auth.login.password_label')}
                    </Label>
                    <div className="space-y-2">
                      <div className="relative space-y-2 text-grey-08">
                        <IconButton
                          Icon={showPassword ? Eye : EyeOff}
                          variant="ghost"
                          size="sm"
                          className="absolute right-[7px] top-[13px] z-10"
                          onClick={() => {
                            if (!showPassword) {
                              setShowPassword(true);
                            } else {
                              setShowPassword(false);
                            }
                          }}
                        />
                        <input
                          id="password"
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          placeholder={t(
                            'pages.auth.register.password_placeholder'
                          )}
                          type={showPassword ? 'text' : 'password'}
                          required
                          className="h-10 w-full rounded-md border border-solid border-transparent px-3 py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02"
                        />
                      </div>
                      <Link
                        className="inline-block text-xs text-tint-blue hover:underline"
                        to="../forgot-password"
                      >
                        {t('pages.auth.login.forgot_password_link')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex px-5 py-8 max-md:flex max-md:flex-col max-md:space-y-3 md:grid md:grid-cols-2 md:gap-4">
                  <Button type="submit">
                    {t('pages.auth.login.submit_button')}
                  </Button>
                  <Button
                    onClick={() => navigate('../register')}
                    variant="outline"
                  >
                    {t('pages.auth.login.register_button')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
      <div className="h-[100vh] w-1/2 max-md:hidden">
        <img
          className="h-full max-h-screen w-full object-cover"
          role="presentation"
          src="/images/interface/auth/login.jpg"
        />
      </div>
    </div>
  );
}

export default AuthLoginPage;

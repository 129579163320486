import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from '@teo/components';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useGet } from '@/query';
import { DataTable } from '@/components/DataTable/DataTable';
import { format } from 'date-fns';
import { axios } from '@/axios';
import { config } from '@/config';

const StepUpdateContent = ({
  publication,
  setStep,
  setGlobalState,
  globalState,
  setIsOpenPublish,
  professions,
  prevContentCreators,
  prevContentOrganisation,
  setIsPiblished,
  publicationType,
  contentCreators,
  publicationName,
  publicationImage,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [selected, setSelected] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselect, setDeselect] = useState<boolean>(false);
  //const [allMemCourses, setAllMemCourses] = useState<any>();
  const [allCourses, setAllCourses] = useState<any>();
  const [showMessage, setShowMessage] = useState(false);
  const [preloader, setPreloader] = useState(false);

  const isGeneralAdmin = (auth?.user?.role || 0) >= 90;

  const [translationsList, setTranslationsList] = useState([]);

  useGet(`/membercoursedetails`, {
    masterId: publication?.id,
    organisationId: auth?.user?.activeOrganisationId,
    courseStatus: 'active,hidden',
    enabled: (auth?.user?.role || 0) < 90,
    onSuccess: (res: any) => {
      setAllCourses(res?.data || undefined);
    },
  });

  useGet(`/coursedetails`, {
    masterId: publication?.id,
    enabled: (auth?.user?.role || 0) >= 90,
    // organisationId: auth?.user?.activeOrganisationId,
    onSuccess: (res: any) => {
      setAllCourses(res?.data || undefined);
    },
  });
  const languageMasterId = publication.languageMasterId || publication.id;
  useGet(`/membercoursecontentdetails`, {
    languageMasterId: `${languageMasterId},OR,coursecontentId=${publication.id},OR,coursecontentId=${languageMasterId}`,
    organisationId: auth?.user?.activeOrganisationId,
    sort: 'createdAt',
    onSuccess: (res: any) => {
      setTranslationsList(res?.data || []);
    },
  });

  const [creatorList, setCreatorList] = useState<any>(
    contentCreators?.map((usr: any) => ({
      id: usr?.id,
      coursecontentId: usr?.coursecontentId,
    })) || []
  );

  useEffect(() => {
    if (translationsList?.length > 0) {
      translationsList?.map(async (translation: any) => {
        if (translation) {
          const test = translation?.id;
          await axios
            .get(
              `${config.backend}/contentcreators?coursecontentId=${
                test && translation?.id
              }`
            )
            .then((result: any) => {
              const newCreatorList = result?.data;
              const creatorId =
                newCreatorList.map((item: any) => ({
                  id: item?.id,
                  coursecontentId: item?.coursecontentId,
                })) || [];
              setCreatorList((prev: any) => [...prev, ...creatorId]);
            });
        }
      });
    }
  }, [translationsList]);

  // useEffect(() => {
  //   const cur: Set<string | number> = new Set(
  //     allCourses?.map((el: any) => el?.courseId)
  //   );
  //   cur && setSelected(cur);
  // }, [allCourses]);

  useEffect(() => {
    //TODO: this is an inccorrect implementation as it requires fetching all courses, should use table deselect list
    const cur: Set<string | number> = new Set(
      allCourses?.map((el: any) => el?.id)
    );
    cur && setSelected(cur);
  }, [allCourses]);

  useEffect(() => {
    if (allCourses) {
      const sel = Array.from(selected);
      if (!deselect) {
        sel?.length > 0 ? setShowMessage(true) : setShowMessage(false);
      } else {
        sel?.length < allCourses?.length
          ? setShowMessage(true)
          : setShowMessage(false);
      }
    }
  }, [selected, deselect, allCourses]);

  const handleSave = async () => {
    const changes: any = {};
    if (globalState?.basicSettingd?.name !== publication?.name) {
      changes.name = globalState?.basicSettingd?.name;
    }
    if (globalState?.basicSettingd?.description !== publication?.description) {
      changes.description = globalState?.basicSettingd?.description;
    }
    if (globalState?.basicSettingd?.lang?.value !== publication?.lang) {
      changes.lang = globalState?.basicSettingd?.lang?.value;
    }

    const promisesBeforePublish: Promise<any>[] = [];
    const promisesAnyTime: Promise<any>[] = [];
    promisesBeforePublish.push(
      axios.put(`${config.backend}/coursecontent/${publication?.id}`, {
        id: publication?.id,
        publicationAccess: globalState?.library?.publicationAccess,
        template: globalState?.basicSettingd?.typeCourse,
        ...changes,
      })
    );

    const oldTags =
      publication?.tags?.split(';;')?.filter((x: string) => {
        return x.startsWith('tag:');
      }) || [];
    const updateCategories = globalState?.basicSettingd?.selectCategories.map(
      (cat: any) => 'category:' + cat?.tag
    );

    promisesAnyTime.push(
      axios.post(`${config.backend}/tag/setcoursecontenttags`, {
        coursecontentId: publication?.id,
        tags: [...updateCategories, ...oldTags],
      })
    );

    if (globalState?.basicSettingd?.selectCompetences?.length > 0) {
      const selectCompetences = globalState?.basicSettingd?.selectCompetences;
      for (const addObj2 of selectCompetences) {
        const existingObj = professions?.data?.data?.find(
          (obj1: any) => obj1?.professionId === addObj2?.key
        );
        if (!existingObj) {
          promisesAnyTime.push(
            axios.post(`${config.backend}/coursecontentprofessions`, {
              coursecontentId: publication?.id,
              professionId: addObj2?.key,
            })
          );
        }
      }
      const removedObj = professions?.data?.data;
      for (const removedObj2 of removedObj) {
        const existingObj = globalState?.basicSettingd?.selectCompetences?.find(
          (obj1: any) => obj1?.key === removedObj2?.professionId
        );
        if (!existingObj) {
          promisesAnyTime.push(
            axios.delete(
              `${config.backend}/coursecontentprofession/${publication?.id}/${removedObj2?.professionId}`
            )
          );
        }
      }
    }

    if (
      !globalState?.library?.myOrganizations &&
      globalState?.library?.users?.length > 0
    ) {
      const selectUsers = globalState?.library?.users;
      for (const addObj2 of selectUsers) {
        const existingObj = prevContentCreators?.find(
          (obj1: any) => obj1?.key === addObj2?.key
        );
        if (!existingObj) {
          promisesAnyTime.push(
            axios.post(`${config.backend}/ownedcourses`, {
              coursecontentId: publication?.id,
              role: 70,
              userId: addObj2?.key,
            })
          );
        }
      }
      for (const removedObj of prevContentCreators) {
        const existingObj = globalState?.library?.users?.find(
          (obj1: any) => obj1?.key === removedObj?.key
        );
        if (!existingObj) {
          promisesAnyTime.push(
            axios.delete(
              `${config.backend}/ownedcourse/${publication?.id}/${removedObj?.key}`
            )
          );
        }
      }
    }
    if (
      !globalState?.library?.allOrganizations &&
      globalState?.library?.organizations?.length > 0
    ) {
      const selectOrg = globalState?.library?.organizations;
      for (const addObj2 of selectOrg) {
        const existingObj = prevContentOrganisation?.find(
          (obj1: any) => obj1?.key === addObj2?.key
        );
        if (!existingObj) {
          promisesAnyTime.push(
            axios.post(`${config.backend}/membercoursecontents`, {
              role: addObj2?.role,
              coursecontentId: publication?.id,
              organisationId: addObj2?.key,
            })
          );
        } else {
          promisesAnyTime.push(
            axios.put(
              `${config.backend}/membercoursecontent/${addObj2?.organisationId}/${publication?.id}`,
              {
                role: addObj2?.role,
              }
            )
          );
        }
      }
      for (const removedObj of prevContentOrganisation) {
        const existingObj = globalState?.library?.organizations?.find(
          (obj1: any) => obj1?.key === removedObj?.key
        );
        if (!existingObj) {
          promisesAnyTime.push(
            axios.delete(
              `${config.backend}/membercoursecontent/${removedObj?.key}/${publication?.id}`
            )
          );
        }
      }
    }

    if (
      globalState?.library?.organizations?.length < 1 &&
      prevContentOrganisation?.length > 0
    ) {
      for (const removedObj of prevContentOrganisation) {
        promisesAnyTime.push(
          axios.delete(
            `${config.backend}/membercoursecontent/${removedObj?.key}/${publication?.id}`
          )
        );
      }
    }

    await Promise.allSettled(promisesBeforePublish);
    const newPublish = await axios.post(
      `${config.backend}/publish/${publication?.id}`,
      {
        versionDescription: globalState?.version?.versionComment,
        versionName: globalState?.version?.versionName,
      }
    );

    const promisesAfterPublish: Promise<any>[] = [];
    const sel = Array.from(selected);
    deselect
      ? sel?.length > 0
        ? allCourses.map((el: any) => {
            const changes: any = {};
            if (globalState?.basicSettingd?.name !== publication?.name) {
              changes.courseName = el?.hasCustomLanding
                ? globalState?.basicSettingd?.name
                : el.courseName;
            } else {
              changes.courseName = el?.hasCustomLanding
                ? publication?.name
                : el.courseName;
            }

            if (publication?.image !== publicationImage) {
              changes.courseImage = el?.hasCustomLanding
                ? publication?.image
                : el?.courseImage;
            }

            if (!sel?.includes(el?.id)) {
              promisesAfterPublish.push(
                axios.put(`${config.backend}/course/${el?.id}`, {
                  coursecontentId: newPublish?.data?.id,
                  id: el?.id,
                  ...changes,
                })
              );
            }
          })
        : allCourses.map((el: any) => {
            const changes: any = {};
            if (globalState?.basicSettingd?.name !== publication?.name) {
              changes.courseName = el?.hasCustomLanding
                ? globalState?.basicSettingd?.name
                : el.courseName;
            } else {
              changes.courseName = el?.hasCustomLanding
                ? publication?.name
                : el.courseName;
            }

            if (publication?.image !== publicationImage) {
              changes.courseImage = el?.hasCustomLanding
                ? publication?.image
                : el?.courseImage;
            }

            promisesAfterPublish.push(
              axios.put(`${config.backend}/course/${el?.id}`, {
                coursecontentId: newPublish?.data?.id,
                id: el?.id,
                ...changes,
              })
            );
          })
      : sel?.length > 0 &&
        sel?.forEach((el: any) => {
          const elCourse = allCourses?.find((cur: any) => cur?.id === el);
          const changes: any = {};

          if (globalState?.basicSettingd?.name !== publicationName) {
            changes.courseName = !elCourse?.hasCustomLanding
              ? globalState?.basicSettingd?.name
              : elCourse.courseName;
          }

          if (publication?.image !== publicationImage) {
            changes.courseImage = !elCourse?.hasCustomLanding
              ? publication?.image
              : elCourse?.courseImage;
          }

          promisesAfterPublish.push(
            axios.put(`${config.backend}/course/${el}`, {
              coursecontentId: newPublish?.data?.id,
              id: el,
              ...changes,
            })
          );
        });

    const mergedCreator = creatorList.filter(
      (obj: any, index: any, self: any) =>
        obj.id !== auth?.user?.id &&
        self.findIndex((o: any) => o.id === obj.id) === index
    );

    const promisesNotifications: Promise<any>[] = [];
    if (mergedCreator?.length > 0) {
      for (const creator of mergedCreator) {
        promisesNotifications.push(
          axios.post(`${config.backend}/notifications`, {
            text: {
              text: t('notifications.publication_notifications', {
                namePublication: publication?.name,
              }),
              version: globalState?.version?.versionComment
                ? globalState?.version?.versionComment
                : null,
            },
            type: 'publicationupdated',
            globalLink:
              creator?.coursecontentId === publication?.id
                ? `/:lang/create/:organisationId/courses/${creator?.coursecontentId}`
                : null,
            userId: creator?.id,
            subject: publication?.id,
          })
        );
      }
    }

    await Promise.allSettled([
      ...promisesAfterPublish,
      ...promisesNotifications,
      ...promisesAnyTime,
    ]).then(() => {
      if (publication?.publicationCourseType !== 'instruction') {
        document.body.style.overflow = 'unset';
        setIsPiblished(true);
      } else {
        createCorseInstruction(newPublish);
      }
    });
  };

  const createCorseInstruction = async (newPublish: any) => {
    const hasInstruction = allCourses?.some(
      (obj: any) => obj.courseType === 'instruction'
    );
    if (allCourses?.length > 0 && hasInstruction) {
      document.body.style.overflow = 'unset';
      setIsPiblished(true);
    } else {
      if (newPublish) {
        const course = newPublish?.data;
        try {
          const newCourseResponse = await axios.post(
            `${config.backend}/courses`,
            {
              courseType: 'instruction',
              coursecontentId: course?.id,
            }
          );
          const courseId = newCourseResponse.data?.id;

          if (courseId) {
            await axios.post(`${config.backend}/courseinfos`, {
              courseId: courseId,
            });
            await axios.post(`${config.backend}/membercourses`, {
              courseId: courseId,
              organisationId: auth?.user?.activeOrganisationId,
            });
            document.body.style.overflow = 'unset';
            setIsPiblished(true);
          }
        } catch (error) {
          document.body.style.overflow = 'unset';
          setIsPiblished(true);
          console.error('Error creating course or updating courseinfo:', error);
        }
      }
    }
  };

  return (
    <>
      {preloader && (
        <div className="fixed inset-0 z-[1] flex bg-white/75">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-16 !w-16 border-grey-08"
            />
          </div>
        </div>
      )}
      <div className="h-full max-h-[calc(100vh_-_250px)] overflow-y-auto py-8">
        <div className="mx-auto flex max-w-screen-md flex-col justify-center px-3">
          <article className="prose mb-3 flex flex-col">
            <h3 className="text-base">
              {t('pages.publish_course.steps_4.title')}
            </h3>
            <p className="pt-3 text-sm text-grey-07">
              {publication?.publicationCourseType !== 'instruction'
                ? t(
                    `pages.create_edit_course.type_${publicationType}.update_step_subtitle`
                  )
                : t(`pages.publish_course.steps_4.subtitle_2`)}
            </p>
          </article>
          {publication?.publicationCourseType !== 'instruction' && (
            <>
              {showMessage && (
                <div className="my-3 rounded-lg bg-error-01 p-4">
                  <p className="text-sm text-error-05">
                    {t('pages.publish_course.steps_4.error_message')}
                  </p>
                </div>
              )}
              <div className="mt-3">
                <h4>
                  {t(
                    `pages.create_edit_course.type_${publicationType}.update_step_title_table`
                  )}
                </h4>
                <div className="mt-2 max-h-96 overflow-y-auto rounded-lg border border-grey-02">
                  <DataTable
                    defaultSort={[
                      { id: 'courseStatus', desc: false },
                      { id: 'updatedAt', desc: false },
                      { id: 'name', desc: false },
                    ]}
                    fetch={
                      isGeneralAdmin
                        ? useGet.bind(null, '/coursedetails', {
                            masterId: publication?.id,
                            courseStatus: 'active,hidden',
                          })
                        : useGet.bind(null, '/membercoursedetails', {
                            masterId: publication?.id,
                            organisationId: auth?.user?.activeOrganisationId,
                            courseStatus: 'active,hidden',
                          })
                    }
                    classTable={'!border-0'}
                    selectable={{
                      selected: selected,
                      setSelected: setSelected,
                      deselect: deselect,
                      setDeselect: setDeselect,
                      checkboxIconMobileType: 'square',
                    }}
                    pageSize={20}
                    columns={[
                      {
                        title: t(
                          `pages.create_edit_course.type_${publicationType}.update_step_col_name`
                        ),
                        id: 'name',
                        className: 'col-start-1 col-end-7 row-start-1',
                        render: (course: any) => (
                          <div className="flex items-center">
                            <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                              <img
                                src={
                                  course?.courseImage ||
                                  course?.image ||
                                  '/images/courseFallback.png'
                                }
                                alt={course.name}
                                className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                              />
                            </div>
                            {course?.courseName || course?.name || ''}
                          </div>
                        ),
                      },
                      {
                        addTitleOnMobile: true,
                        className:
                          'col-start-7 col-end-10 row-start-1 justify-center text-center',
                        id: 'createdAt',
                        title: t('columns.created'),
                        render: (course: any) =>
                          format(new Date(course.createdAt), 'dd/MM/yyyy'),
                      },
                    ]}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="mt-auto flex border-t border-grey-02 py-5">
        <div className=" mx-auto flex w-full max-w-screen-md justify-end px-3">
          <Button
            variant="outline"
            onClick={() => setStep(2)}
            className="mr-auto"
            data-testid="prevBtn"
          >
            {t('button.previous')}
          </Button>
          <Button
            onClick={() => {
              setPreloader(true);
              handleSave();
            }}
            data-testid="nextBtn"
          >
            {t(
              `pages.create_edit_course.type_${publicationType}.publish_course_btn`
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
export default StepUpdateContent;

import React, { Component, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { Sprite, Container } from '@pixi/react';

import * as PIXI from 'pixi.js';

let globalzIndex = 1;

class Draggable extends Component {
  displayName = 'Draggable';

  onDragStart = (event) => {
    let sprite = event.currentTarget;
    //sprite.alpha = 0.5;
    sprite.zIndex = globalzIndex++;
    sprite.data = event.data;
    sprite.dragging = true;
    let position = sprite.data.getLocalPosition(sprite);
    sprite.pivot.set(position.x, position.y);
    sprite.position.set(sprite.data.global.x, sprite.data.global.y);
    event.data.originalEvent.target.cancelScroll = true;
  };

  onDragEnd = (event) => {
    const sprite = event.currentTarget;
    //sprite.alpha = 1;
    sprite.dragging = false;
    sprite.data = null;
  };

  onDragMove = (event) => {
    const sprite = event.currentTarget;
    if (sprite.dragging) {
      const newPosition = sprite.data.getLocalPosition(sprite.parent);
      sprite.x = newPosition.x;
      sprite.y = newPosition.y;
      event.data.originalEvent.target.cancelScroll = true;
    }
  };

  render() {
    return (
      <Container
        interactive
        buttonMode
        pointerdown={this.onDragStart}
        //mousedown = {this.onDragStart}
        //touchstart = {this.onDragStart}
        mouseup={this.onDragEnd}
        mouseupoutside={this.onDragEnd}
        touchend={this.onDragEnd}
        touchendoutside={this.onDragEnd}
        mousemove={this.onDragMove}
        touchmove={this.onDragMove}
        /*
        pointerdown = {this.onDragStart}
        pointerup = {this.onDragEnd}
        pointerupoutside = {this.onDragMove}
        pointermove = {this.onDragMove}
        */

        ref={this.props.innerRef}
        {...this.props}
      ></Container>
    );
  }
}

let forwardedDraggable = forwardRef((props, ref) => (
  <Draggable innerRef={ref} {...props} />
));

forwardedDraggable.displayName = 'Draggable';

export { forwardedDraggable as Draggable };

import { PageLayout } from '@/components/PageLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import BreadCrumbsHeader from '@/components/BreadCrumbsHeader';
import { useContext, useEffect } from 'react';
import { NavContext } from '@/components/NavContext';
import { useMobile } from '@/util';
import { followUsSidebar } from '../../constants/sidebar.constants';
import SidebarNavigation from '@/components/SidebarNavigation';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import i18n from 'i18next';

function FollowUpPage() {
  const { collapsed, navDisabled, submenu } = useContext(NavContext);
  const isMobile = useMobile();
  const showNav = !navDisabled && ((submenu && !collapsed) || !isMobile);
  const showContent = collapsed || !isMobile;

  //insufficiënt role redirect
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const navigate = useNavigate();
  useEffect(() => {
    const role = Math.max(
      authUser?.activeOrganisationRole || 0,
      authUser?.role || 0
    );
    if (role <= 20) {
      navigate(`/${i18n.language}/learn`);
    }
  }, []);

  if (navDisabled) return <Outlet />;

  return (
    <PageLayout>
      {showNav && (
        <PageLayout.Sidebar>
          <SidebarNavigation items={followUsSidebar} />
        </PageLayout.Sidebar>
      )}
      <div
        className={`${
          showContent ? '' : 'hidden'
        } min-h-[100vh] w-full max-w-6xl lg:min-h-[calc(100vh_-_150px)]`}
      >
        <PageLayout.Content>
          <BreadCrumbsHeader />
          <Outlet />
        </PageLayout.Content>
      </div>
    </PageLayout>
  );
}

export default FollowUpPage;

import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Modal,
  Tabs,
  Spinner,
  IconButton,
  RadioGroup,
} from '@teo/components';
import { useTranslation } from 'react-i18next';
// import { QRCodeCanvas } from 'qrcode.react';
import { ArrowLeft } from '@teo/components/icons';

import { useGet } from '@/query/fetchHooks';
import { Usedlessondetails, Directories } from '@/models/Database';

import { buildSortedTree, firstOfDfs, buildTreeFlat } from '@/util';

import LessonsQRCode from './LessonsQRCode';

const QRCodeCanvas = React.lazy(() =>
  import('qrcode.react').then((mod) => ({ default: mod.QRCodeCanvas }))
);

const QRModal = ({ closeModal, setIsOpenQRModal, course, authUser }: any) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState('course');
  const [loadQR, setLoadQR] = useState(false);
  const [qrUrl, steQrUrl] = useState<any>(null);
  const [tree, setTree] = useState<any>(null);
  const [activeLesson, setActiveLesson] = useState<any>(null);
  const [firstLesson, setFirstLesson] = useState<any>(null);
  const hiddenContentRef = useRef(null);

  const isScorm = course?.publicationType?.startsWith?.('scorm') || false;

  let lessonPath = '/usedlessonassets';

  if (course?.id) lessonPath = '/usedcourselessonassets';

  const lessonResult = useGet<Array<Usedlessondetails>>(lessonPath, {
    count: 10000,
    courseId: course?.id,
    coursecontentId: course?.coursecontentId,
  });
  const lessons: Array<Usedlessondetails> | undefined =
    lessonResult?.data?.data || undefined;
  const directoriesResult = useGet<Array<Directories>>('/directories', {
    count: 10000,
    coursecontentId: course?.coursecontentId,
  });
  const directories: Array<Directories> | undefined =
    directoriesResult?.data?.data || undefined;

  useEffect(() => {
    if (lessons && directories) {
      const courseLessons = lessons.filter(
        (les: any) => !les?.rootLessonId && !les?.rootDirectoryId
      );
      const sortedTree = buildSortedTree(courseLessons, directories || []);
      const [newTree] = buildTreeFlat(courseLessons, directories) as any;

      setTree(newTree);

      firstOfDfs(sortedTree, (node: any) => {
        if (node?.lessonId || node?.assetId) {
          setFirstLesson(node);
          return true;
        }
      });
    }
  }, [lessons, directories]);

  useEffect(() => {
    let typeCourse;

    switch (course?.publicationCourseType) {
      case 'instruction':
        typeCourse = 'instructions';
        break;
      case 'leervacature':
        typeCourse = 'vacancies';
        break;
      case 'assessment':
        typeCourse = 'assessments';
        break;
      default:
        typeCourse = 'courses';
        break;
    }
    if (isChecked && tree) {
      if (isChecked === 'course') {
        if (isScorm) {
          steQrUrl(
            `${window.location.origin}/${authUser?.lang}/learn/${authUser?.activeOrganisationId}/scormCourses/${course?.id}?qr_code=true`
          );
        } else {
          steQrUrl(
            `${window.location.origin}/${authUser?.lang}/learn/${authUser?.activeOrganisationId}/${typeCourse}/${course?.id}/lesson/${firstLesson?.lessonId}?qr_code=true`
          );
        }
      }
      if (isChecked === 'details') {
        steQrUrl(
          `${window.location.origin}/${authUser?.lang}/learn/${authUser?.activeOrganisationId}/${typeCourse}/${course?.id}`
        );
      }

      if (isChecked === 'lesson' && activeLesson) {
        steQrUrl(
          `${window.location.origin}/${authUser?.lang}/learn/${
            authUser?.activeOrganisationId
          }/${typeCourse}/${course?.id}/lesson/${
            activeLesson?.lessonId || activeLesson?.assetId
          }?qr_code=true`
        );
      }
      setLoadQR(true);
    }
  }, [isChecked, tree, activeLesson]);

  const downloadQRCode = async () => {
    const { jsPDF } = await import('jspdf');
    const html2canvas = (await import('html2canvas')).default;

    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a6',
    });

    const element = hiddenContentRef.current;
    if (element) {
      const qrCodeCanvas = await html2canvas(element);
      doc.addImage(
        qrCodeCanvas.toDataURL('image/png'),
        'PNG',
        -45,
        0,
        315,
        315
      );
      doc.save('TEO_QR-Code.pdf');
    }
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-lg  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            <h2 className="text-sm font-semibold">
              {t('pages.follow_up_courses.qr_modal.title')}
            </h2>
          </Modal.Header>

          <Modal.Body>
            <div className="flex flex-col">
              <div className="mb-6 flex flex-col">
                <h3 className="mb-2 text-lg">
                  {t('pages.follow_up_courses.qr_modal.heading')}
                </h3>
                <p className="text-base text-grey-06">
                  {t('pages.follow_up_courses.qr_modal.text')}
                </p>
              </div>
              <div>
                <RadioGroup
                  label={t('pages.follow_up_courses.qr_modal.label')}
                  onChange={(value: string) => {
                    steQrUrl(null);
                    setActiveLesson(null);
                    setIsChecked(value);
                  }}
                  gap="gap-2"
                  value={isChecked}
                >
                  <div className="mb-3 flex w-full flex-col gap-3">
                    <div
                      className={`w-full rounded-lg border border-grey-02 p-3 ${
                        isChecked == 'course' && 'border-secondary-04'
                      }`}
                    >
                      <RadioGroup.Option value="course">
                        <span className="text-sm">
                          {t('pages.follow_up_courses.qr_modal.course')}
                        </span>
                      </RadioGroup.Option>
                    </div>
                    <div
                      className={`w-full rounded-lg border border-grey-02 p-3 ${
                        isChecked == 'details' && 'border-secondary-04'
                      }`}
                    >
                      <RadioGroup.Option value="details">
                        <span className="text-sm" data-testid="radio_btn">
                          {t('pages.follow_up_courses.qr_modal.details')}
                        </span>
                      </RadioGroup.Option>
                    </div>
                    {!isScorm && (
                      <div
                        className={`w-full rounded-lg border border-grey-02 p-3 ${
                          isChecked == 'lesson' && 'border-secondary-04'
                        }`}
                      >
                        <RadioGroup.Option value="lesson">
                          <span className="text-sm" data-testid="radio_btn">
                            {t('pages.follow_up_courses.qr_modal.lesson')}
                          </span>
                        </RadioGroup.Option>
                      </div>
                    )}
                  </div>
                </RadioGroup>
              </div>

              {isChecked === 'lesson' && (
                <LessonsQRCode tree={tree} setActiveLesson={setActiveLesson} />
              )}
              {(isChecked === 'course' ||
                isChecked === 'details' ||
                isChecked === 'lesson') && (
                <div className="relative h-[0px] overflow-hidden">
                  {/* <div className="relative h-[0px]"> */}
                  {qrUrl && loadQR ? (
                    <div
                      ref={hiddenContentRef}
                      className="absolute top-1/2 left-1/2 flex h-[450px] w-[450px] flex-col bg-primary-04 px-24 py-6"
                      style={{
                        transform: 'translate(-50%, -215px)',
                      }}
                    >
                      <div className="flex h-full rounded-lg bg-white">
                        <div className="m-auto flex min-h-[200px] justify-center">
                          <div id="qrCode">
                            <QRCodeCanvas
                              value={qrUrl}
                              size={200}
                              imageSettings={{
                                src: '/images/TEO_logo.svg',
                                x: undefined,
                                y: undefined,
                                height: 48,
                                width: 48,
                                opacity: 1,
                                excavate: false,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex h-[200px] w-full">
                      <div className="m-auto">
                        <Spinner
                          ariaLabel="Loading spinner"
                          className="!h-14 !w-14 border-grey-06"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="-mx-6 mt-2 flex justify-between px-6 pt-6">
              <Button variant="ghost" onClick={() => setIsOpenQRModal(false)}>
                {t('button.cancel')}
              </Button>
              <Button
                onClick={() => downloadQRCode()}
                disabled={!(qrUrl && loadQR)}
              >
                {t('pages.follow_up_courses.qr_modal.download')}
              </Button>
            </div>
          </Modal.Body>
        </div>
      </div>
    </div>
  );
};

export default QRModal;

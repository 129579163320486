import { useTranslation } from 'react-i18next';
import { Button, ButtonLink, InputText, Label } from '@teo/components';
import React, { useState } from 'react';
import { resetPassword } from '@/query/auth';
import { ErrorBox } from '@/components/ErrorBox';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

function AuthForgotPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState('');

  function submitResetPassword() {
    resetPassword(email)
      .then(() => {
        navigate('/auth/resetPasswordSuccess');
      })
      .catch((e: AxiosError<{ message: string }>) => {
        const message = e?.response?.data?.message || e.toString();
        switch (message) {
          case 'E-mail not found':
            setErrorText(t('error.emailNotFound'));
            break;
          default:
            setErrorText(message);
            break;
        }
        console.error('Error', e);
        console.error('redirecting');
      });
  }

  return (
    <div className="h-full overflow-hidden md:flex md:items-center">
      <div
        className="flex h-full overflow-hidden md:w-1/2 md:items-center md:overflow-auto"
        id="userForm"
      >
        <form
          className="m-auto flex w-full max-w-[420px] flex-col max-md:h-full max-md:overflow-hidden"
          onSubmit={(event) => {
            event.preventDefault();
            submitResetPassword();
          }}
        >
          <div className="space-y-6 px-5 pt-8 max-md:flex-grow max-md:overflow-auto md:space-y-8">
            <img
              className="h-12 w-12 md:h-16 md:w-16"
              src="/TEO_logo.svg"
              alt="Logo"
            />

            <h1 className="text-3xl-semibold">
              {t('pages.auth.forgot_password.title')}
            </h1>

            {errorText && <ErrorBox text={errorText} />}

            <div className="space-y-6 md:space-y-8">
              <p className="text-sm text-grey-05">
                {t('pages.auth.forgot_password.subtitle')}
              </p>

              <div>
                <Label htmlFor="email">
                  {t('pages.auth.forgot_password.email_label')}
                </Label>
                <InputText
                  id="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder={t(
                    'pages.auth.forgot_password.email_placeholder'
                  )}
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex px-5 py-8 max-md:flex max-md:flex-col max-md:space-y-3 md:grid md:grid-cols-2 md:gap-4">
            <Button type="submit">
              {t('pages.auth.forgot_password.reset_button')}
            </Button>

            <ButtonLink
              to="../login"
              variant="outline"
              style={{ border: '2px solid #d5d5d8' }}
            >
              {t('pages.auth.forgot_password.cancel_button')}
            </ButtonLink>
          </div>
        </form>
      </div>

      <div className="w-1/2 bg-[#e4f1ff] max-md:hidden md:flex md:h-[100vh] md:items-center md:justify-center">
        <img
          className="h-[500px] object-cover"
          role="presentation"
          src="/images/TEO_map.svg"
          alt="login_image"
        />
      </div>
    </div>
  );
}

export default AuthForgotPasswordPage;

import { Link } from 'react-router-dom';

function CookiePolicy() {
  return (
    <div className="mb-8 break-words">
      <h2 className="mt-6 text-center">COOKIE POLICY</h2>
      <p className="mb-6 text-center">29/10/2024</p>

      <div className="m-auto w-[80%]">
        <div className="sane-lists sane-paragraphs">
          <h3 className="my-4">Version History</h3>
          <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2">Date</th>
                <th className="border border-gray-400 px-4 py-2">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-400 px-4 py-2">29/10/2024</td>
                <td className="border border-gray-400 px-4 py-2">
                  Updated cookie policy with new processing activities and
                  details.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">10/06/2022</td>
                <td className="border border-gray-400 px-4 py-2">
                  Initial version of cookie policy to comply with GDPR
                  standards.
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="my-4">Introduction and Scope</h3>
          <p>
            This Cookie Policy (hereinafter <strong>“Cookie Policy”</strong>)
            serves to inform you of the cookies that are used on the platform
            (hereinafter the <strong>“Platform”</strong>) which is owned and
            operated by TEO-Teaching BV, with registered office at
            Schaarbeekstraat 42A, 9120 Beveren, registered in the Register of
            Legal Entities of Ghent, section Dendermonde, under number
            0697.679.725, and known to the VAT-administration under number BE
            0697.679.725 (<strong>“TEO”</strong>, <strong>“we”</strong>, or{' '}
            <strong>“us”</strong>), so that you can easily manage your cookie
            settings.
          </p>
          <p>
            This Cookie Policy should be read together with our{' '}
            <Link
              onClick={(ev) => {
                const element = document.getElementById('privacypolicy');
                element?.scrollIntoView();
                ev.preventDefault();
              }}
              to="../privacy"
            >
              <strong>Privacy Policy</strong>
            </Link>
            .
          </p>

          <h3 className="my-4">What Are Cookies and Why Do We Use Them?</h3>
          <p>
            Cookies are small pieces of information in the form of text files,
            created by a program on the server of the Platform, which are stored
            on the hard drive of your computer via your browser when visiting
            the Platform.
          </p>
          <p>
            The Platform uses cookies in order to identify you as a user of the
            Platform, to remember your preferred language, and to facilitate
            your navigation on the Platform. By using cookies, you do not need
            to identify yourself repeatedly, for example, for personalized
            services (such as filling in your username or password or selecting
            a language, etc.).
          </p>

          <h3 className="my-4">
            What Are Facebook Pixels and Why Do We Use Them?
          </h3>
          <p>
            Besides cookies, we are also using Facebook pixels on our Platform.
          </p>
          <p>
            Facebook pixels are pieces of software code used on a website or
            platform to measure the effectiveness of advertising campaigns and
            to gain insight into how visitors are using the website or platform.
            The pixel code is placed in the header of the website or platform.
            If someone visits the website or platform and performs an action on
            the website or platform, then the Facebook pixel is activated and it
            will report this action to the website or platform owner. This way,
            the website or platform owner is able to verify which website
            visitor is taking specific actions and is able to contact this
            person again at a later stage via Facebook advertisements, tailored
            to his/her fields of interest (also called{' '}
            <strong>“remarketing”</strong>).
          </p>
          <p>
            We are occasionally using pixel data to analyze visitor numbers and
            clicks on vacancies and training programs. More information on the
            use of Facebook pixels can be found in Facebook’s privacy policy. We
            also recommend regularly verifying and updating your Facebook
            privacy settings and setting your preferences with the means offered
            to you by Facebook.
          </p>

          <h3 className="my-4">What Cookies Does TEO Use?</h3>
          <h4 className="my-4">Types of Cookies on the Platform</h4>
          <p>
            The following categories of cookies are placed when visiting our
            Platform:
          </p>
          <ul>
            <li>
              <strong>Session cookies:</strong> These are temporary files.
              Session storage is retained during your browsing session and is
              deleted once your browser is closed.
            </li>
            <li>
              <strong>Persistent cookies:</strong> These remain on a user’s
              computer for a certain period of time (the duration depends on the
              type of persistent cookie).
            </li>
            <li>
              <strong>Analytical cookies:</strong> These compile statistics on
              certain information (e.g., popularity, number of visitors, etc.).
            </li>
            <li>
              <strong>Tracking cookies:</strong> These track your browsing
              behavior and Platform visitors in order to respond to their
              browsing behavior (e.g., by providing personalized marketing
              campaigns).
            </li>
          </ul>

          <h3 className="my-4">Specific Cookies on the Platform</h3>
          <p>
            Please find below a list of the specific cookies that TEO uses on
            its Platform:
          </p>

          <h4 className="my-4">Session Storage</h4>
          <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
            <tbody>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Name:
                </td>
                <td className="border border-gray-400 px-4 py-2">Token</td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Information collected by the cookie:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  ID Function Organization
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Purpose:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  Session storage is collected to ensure the proper functioning
                  of the Platform.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Retention period of the cookies:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  Session storage is deleted once the browsing session ends.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Issuer of the cookie (first vs. third party):
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  First party – TEO
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="my-4">Analytical Cookies</h4>
          <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
            <tbody>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Name:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  Google Analytics
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Information collected by the cookie:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  Visited pages, frequency, etc.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Purpose:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  These cookies are collected to compile statistics on the usage
                  of the Platform.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Retention period of the cookies:
                </td>
                <td className="border border-gray-400 px-4 py-2">26 months</td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Issuer of the cookie (first vs. third party):
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  Third Party – Google Analytics
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="my-4">Tracking Cookies</h4>
          <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
            <tbody>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Name:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  Cookie consent status
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Information collected by the cookie:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  Consent of the user
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Purpose:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  These cookies are collected to preserve given consents.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Retention period of the cookies:
                </td>
                <td className="border border-gray-400 px-4 py-2">1 year</td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2 font-bold">
                  Issuer of the cookie (first vs. third party):
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  First Party – TEO
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            We are required to place session cookies. You cannot choose whether
            or not we place these cookies since they are mandatory to ensure the
            proper functioning of the Platform.
          </p>
          <p>
            <strong>
              For analytical and tracking cookies we require your prior consent.
            </strong>{' '}
            You can give your consent by clicking on the relevant button in the
            cookie banner. You can withdraw your consent at any time by deleting
            the cookies stored in your browser. More information on how to do
            this can be found in section 5 below.
          </p>

          <h3 className="my-4">Management of Cookies</h3>
          <p>
            You can manage the use of cookies yourself. For example, you can
            choose to block all cookies or only accept cookies from certain
            websites. In doing so, please keep in mind that certain features on
            the Platform will only be accessible if you have accepted the
            relevant cookies. Accepting cookies makes browsing the internet much
            easier and ensures that the platforms and websites you visit perform
            better. The use of cookies is generally accepted by internet users.
          </p>
          <p>
            You can set your browser settings in such a way that you get a
            notification every time you receive a cookie, or you can prevent
            cookies from being created or stored on the hard drive of your
            computer. TEO cannot guarantee access to and operation of the
            Platform if you have disabled the creation of cookies by your
            internet browser.
          </p>

          <h4 className="my-4">Browser Settings</h4>
          <p>
            Information regarding how to manage and delete cookies via the
            settings of your browser is accessible on the following websites:
          </p>
          <table className="mb-6 w-full table-auto border-collapse border border-gray-500">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2">Browser</th>
                <th className="border border-gray-400 px-4 py-2">
                  How to manage and delete cookies via your browser:
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-400 px-4 py-2">
                  Apple Safari
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  <a
                    href="https://support.apple.com/nl-be/guide/safari/sfri11471/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.apple.com/nl-be/guide/safari/sfri11471/mac
                  </a>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">
                  Google Chrome
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  <a
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=nl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=nl
                  </a>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">
                  Internet Explorer
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  <a
                    href="https://support.microsoft.com/nl-be/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.microsoft.com/nl-be/help/17442/windows-internet-explorer-delete-manage-cookies
                  </a>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">
                  Mozilla Firefox
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  <a
                    href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            We process the information that we collect through our cookies. You
            can read in our{' '}
            <Link
              onClick={(ev) => {
                const element = document.getElementById('privacypolicy');
                element?.scrollIntoView();
                ev.preventDefault();
              }}
              to="../privacy"
            >
              <strong>Privacy Policy</strong>
            </Link>{' '}
            how this processing takes place and which rights you have.
          </p>

          <h3 className="my-4">Changes to this Cookie Policy</h3>
          <p>
            From time to time, we may need to change this Cookie Policy, for
            example, in order to update it as a result of the use of new
            functionalities on our Platform. Any changes we may make to our
            Cookie Policy will be indicated on the Platform and, when
            proportionate and in line with the significance of the changes, may
            be notified to you by e-mail or advised to you on your next log-in
            to the Platform. The date of the most recent version is shown in the
            top right-hand corner of the Cookie Policy. When we publish changes
            to this Cookie Policy, we change the date of the &quot;last
            update&quot; at the bottom of the document. The most recent version
            of this Cookie Policy will be available on our Platform at all
            times. Please review TEO’s Cookie Policy periodically to stay
            informed of changes that may affect you.
          </p>

          <h3 className="my-4">Applicable Law and Jurisdiction</h3>
          <p>
            This Cookie Policy shall be governed, interpreted, and implemented
            in accordance with Belgian laws.
          </p>
          <p>
            The Antwerp courts (division Antwerp) are exclusively competent to
            decide on any dispute that may arise from the interpretation or
            implementation of this Cookie Policy, without prejudice to the
            consumer’s right to present a dispute before a competent court on
            the basis of a mandatory statutory provision.
          </p>

          <h3 className="my-4">Contact</h3>
          <p>
            If you have any questions or concerns about this Cookie Policy, you
            can always contact us by sending an e-mail to{' '}
            <a href="mailto:info@teo.training">info@teo.training</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;

function TermsAndConditions() {
  return (
    <div className="mb-8 break-words">
      <h2 className="my-6 text-center">Terms of use</h2>

      <div className="m-auto w-[80%]">
        <div>
          <p>
            DISCLAIMER: PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING
            THE TEO PLATFORM (AS DEFINED BELOW). BY CHECKING THE I AGREE
            CHECKBOX AND USING THE TEO PLATFORM, YOU AGREE THAT YOUR USE OF THE
            TEO PLATFORM SHALL BE GOVERNED BY THESE TERMS OF USE.
          </p>

          <p className="mt-4">
            These terms of use (the <strong>Terms of Use</strong>) describe the
            terms and conditions under which users (<strong>Users</strong> or{' '}
            <strong>you</strong>) can use the TEO Platform (as defined
            hereafter). The TEO Platform is operated and managed by{' '}
            <strong>TEO-Training</strong>, a private limited company
            incorporated and existing under the laws of Belgium, with registered
            office at Schaarbeekstraat 42A, 9120 Beveren, registered in the
            Register of Legal Entities of Ghent, section Dendermonde, under
            number 0697.679.725, and known to the VAT-administration under
            number BE 0697.679.725 (hereinafter referred to as{' '}
            <strong>“TEO”</strong> or <strong>“we”</strong>). If you have any
            questions about the TEO Platform or these Terms of Use, please
            contact us at{' '}
            <a href="mailto:info@teo.training">info@teo.training</a>.
          </p>

          <p className="mt-4">
            These terms of use describes the processing of your personal data by{' '}
            <strong>TEO-Training</strong>, a private limited company
            incorporated and existing under the laws of Belgium, with registered
            office at Schaarbeekstraat 42A, 9120 Beveren, registered in the
            Register of Legal Entities of Ghent, section Dendermonde, under
            number 0697.679.725, and known to the VAT-administration under
            number BE 0697.679.725 (hereinafter referred to as{' '}
            <strong>“TEO”</strong> or <strong>“we”</strong>).
          </p>

          <h3 className="my-4">1. Definitions</h3>
          <p>
            <strong>“Account”</strong> means your account for the TEO Platform
            which shall be provided to you by the TEO Client;
          </p>
          <p>
            <strong>“Agreement“</strong> means the agreement concluded between
            TEO and the TEO Client relating to the TEO Platform;
          </p>
          <p>
            <strong>“Data Controller“</strong> means the physical or legal
            person, which, alone or jointly with others, determines the purposes
            and means of the processing of Personal Data;
          </p>
          <p>
            <strong>“Data Processor“</strong> means the physical or legal person
            which processes Personal Data on behalf of the Data Controller;
          </p>
          <p>
            <strong>“TEO Client“</strong> shall mean the organization which has
            concluded an Agreement with TEO and which has provided you with your
            Account details;
          </p>
          <p>
            <strong>“Fees“</strong> means the fees to be paid by the TEO Client
            to TEO for the use of the TEO Platform and other specific elements,
            as set out in the Agreement;
          </p>
          <p>
            <strong>“Force Majeure Event“</strong> means an event, or a series
            of related events, that is outside the reasonable control of the
            Party affected (including failures of the internet or any public
            telecommunications network, hacker attacks, denial of service
            attacks, virus or other malicious software attacks or infections,
            power failures, industrial disputes affecting any Third Party,
            social strikes or actions, changes to the law, disasters,
            explosions, fires, floods, riots, terrorist attacks and wars);
          </p>
          <p>
            <strong>“Intellectual Property Rights“</strong> means any and all
            now known or hereafter existing (a) rights associated with works of
            authorship, including copyrights, mask work rights, and moral
            rights; (b) trademark or service mark rights; (c) trade secret
            rights, know-how; (d) patents, patent rights, and industrial
            property rights; (e) layout design rights, design rights, (f) trade
            and business names, domain names, database rights, rental rights and
            any other industrial or intellectual proprietary rights or similar
            right (whether registered or unregistered); (g) all registrations,
            applications for registration, renewals, extensions, divisions,
            improvements or reissues relating to any of these rights and the
            right to apply for, maintain and enforce any of the preceding items,
            in each case in any jurisdiction throughout the world;
          </p>
          <p>
            <strong>“Personal Data“</strong> shall mean any information relating
            to an identified or identifiable natural person; an identifiable
            natural person is one who can be identified, directly or indirectly,
            in particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural
            person;
          </p>
          <p>
            <strong>“TEO Platform“</strong> means the SaaS learning platform of
            TEO;
          </p>
          <p>
            <strong>“Term“</strong> means the period as agreed upon between TEO
            and the TEO Client in the Agreement.
          </p>

          <h3 className="my-4">2. Description of the TEO Platform</h3>
          <p>
            The TEO Platform is a SaaS platform developed for companies, public
            or private employment service institutions or educational
            establishments, which have a need to train and improve the technical
            knowledge of certain individuals within their organizations (the “
            <strong>TEO Platform</strong>”).{' '}
          </p>

          <h3 className="my-4">3. Applicability</h3>
          <p>
            <strong>3.1</strong> By checking the “I agree” checkbox, you
            acknowledge and agree that your use of the TEO Platform is
            exclusively governed by these Terms of Use. If you do not agree to
            any provision of these Terms of Use, you may not use the TEO
            Platform in any manner, even if you already have an Account.
          </p>
          <p>
            <strong>3.2</strong> In the event the TEO Platform uses services of
            third parties, the terms of service and/or privacy policies of those
            third parties may apply. You shall be notified if and when such
            third party terms of services and/or privacy policies are
            applicable. By accessing such third party service, you agree to
            comply with the applicable terms and you acknowledge that you are
            the sole party to such terms. TEO cannot be held liable in any way
            with regard to the content of such third parties’ terms or privacy
            policy.
          </p>
          <p>
            <strong>3.3</strong> We reserve the right at any time, and from time
            to time, with or without cause to:
          </p>
          <p>
            <ul>
              <li>
                amend these Terms of Use. Any change will be notified thirty
                (30) days prior to entering into force. In the event any change
                of these Terms of Use is needed to comply with relevant law, TEO
                reserves the right to immediately enforce the amended Terms of
                Use without prior notice. By accessing or otherwise using the
                TEO Platform after the Terms of Use have been changed in
                accordance with this clause, you understand and agree to be
                bound by the amended Terms of Use. If you do not agree with any
                of the amended Terms of Use, you will have no right to access
                and use the TEO Platform;
              </li>
              <li>
                change the TEO Platform, including eliminating or discontinuing,
                temporarily or permanently any service or other feature of the
                TEO Platform without any liability against the User or any third
                parties; or
              </li>
              <li>
                deny or terminate, in part, temporarily or permanently, your use
                of and/or access to the TEO Platform as set forth herein.
              </li>
            </ul>
          </p>
          <p>
            Any such amendments or changes made will be effective immediately
            upon us making such changes available in the TEO Platform or
            otherwise providing notice thereof. You agree that your continued
            use of the TEO Platform after such changes constitutes your
            acceptance of such changes.
          </p>

          <h3 className="my-4">4. Use of the TEO Platform</h3>
          <p>
            <strong>4.1</strong> You shall be solely responsible for maintaining
            the confidentiality and security of your Account login information
            such as your password and shall be fully responsible for all
            activities that occur under your Account. You agree to immediately
            notify TEO of any unauthorized use, or suspected unauthorized use of
            your account or any other breach of security. TEO will not be liable
            for any loss or damage arising from your failure to comply with the
            above requirements.
          </p>
          <p>
            <strong>4.2</strong> During the Term, TEO may, in its sole
            discretion, provide you with certain updates of the TEO Platform.
            For the avoidance of doubt, TEO is not obligated to provide any
            updates of the TEO Platform.
          </p>

          <h3 className="my-4">5. License - restrictions</h3>
          <p>
            <strong>5.1 License by TEO</strong> - During the Term and subject to
            the timely payment of the Fees by the TEO Client, TEO grants you a
            non-exclusive, personal, restricted, non-sublicensable and
            non-transferable license to use the TEO Platform in accordance with
            these Terms of Use (the “License”). You are not allowed to use the
            TEO Platform for any commercial purposes or to use the TEO Platform
            in a manner not authorized by TEO. You shall use the TEO Platform
            solely in full compliance with (i) these Terms of Use; (ii) any
            additional instructions or policies issued by TEO, including, but
            not limited to, those posted within the TEO Platform and (iii) any
            applicable legislation, rules or regulations.
          </p>
          <p>
            <strong>5.2 Restrictions</strong> - You agree to use the TEO
            Platform only for its intended use as set forth in these Terms of
            Use. Within the limits of the applicable law, you are not permitted
            to (i) make the TEO Platform available or to sell or rent the TEO
            Platform to any third parties; (ii) adapt, alter, translate or
            modify in any manner the TEO Platform; (iii) sublicense, lease,
            rent, loan, distribute, or otherwise transfer the TEO Platform to
            any third party; (iv) decompile, reverse engineer, disassemble, or
            otherwise derive or determine or attempt to derive or determine the
            source code (or the underlying ideas, algorithms, structure or
            organization) of the TEO Platform, except and only to the extent
            that such activity is expressly permitted by applicable law
            notwithstanding this limitation; (v) use or copy the TEO Platform
            except as expressly allowed under this article 5; (vi) gain
            unauthorized access to accounts of other Users or use the TEO
            Platform to conduct or promote any illegal activities; (vi) use the
            TEO Platform to generate unsolicited email advertisements or spam;
            (vii) impersonate any person or entity, or otherwise misrepresent
            your affiliation with a person or entity; (viii) use any high volume
            automatic, electronic or manual process to access, search or harvest
            information from the TEO Platform (including without limitation
            robots, spiders or scripts); (ix) alter, remove, or obscure any
            copyright notice, digital watermarks, proprietary legends or other
            notice included in the TEO Platform; (x) intentionally distribute
            any worms, Trojan horses, corrupted files, or other items of a
            destructive or deceptive nature or use the TEO Platform for any
            unlawful, invasive, infringing, defamatory or fraudulent purpose; or
            (xi) remove or in any manner circumvent any technical or other
            protective measures in the TEO Platform.
          </p>
          <p>
            <strong>5.3 License by User</strong> - By uploading, creating or
            otherwise providing Personal Data, you grant TEO a non-exclusive,
            royalty-free, worldwide, sublicensable, transferable, license to
            use, copy, store, modify, transmit and display such Personal Data to
            the extent necessary to provide and maintain the TEO Platform. TEO
            reserves the right, but is not obliged, to review and remove any
            Personal Data which is deemed to be in violation with the provisions
            of these Terms of Use or otherwise inappropriate, any rights of
            third parties or any applicable legislation or regulation.
          </p>

          <h3 className="my-4">6. Ownership</h3>
          <p>
            <strong>6.1</strong> As between the User and TEO, all Intellectual
            Property Rights pertaining thereto, are the exclusive property of
            TEO and/or its licensors.
          </p>
          <p>
            <strong>6.1</strong> All rights, titles and interests in and to the
            TEO Platform or any part thereof not expressly granted to the User
            in these Terms of Use are reserved by TEO and its licensors. Except
            as expressly set forth herein, no express or implied license or
            right of any kind is granted to the User regarding the TEO Platform,
            including any right to obtain possession of any source code, data or
            other technical material related to the TEO Platform.
          </p>

          <h3 className="my-4">7. Suspension for breach</h3>
          <p>
            If TEO becomes aware or suspects, in its sole discretion, any
            violation by you of these Terms of Use, or any other instructions,
            guidelines or policies issued by TEO, then TEO may suspend or limit
            your access to the TEO Platform. The duration of any suspension by
            TEO will be until you have cured the breach which caused such
            suspension or limitation.
          </p>

          <h3 className="my-4">8. Support</h3>
          <p>
            In case you need technical support, you can contact your contact
            person within the TEO Client organization or send an e-mail to{' '}
            <a href="mailto:support@teo.training">support@teo.training</a>
          </p>

          <h3 className="my-4">9. Payment</h3>
          <p>
            In consideration for the License, the Fees shall be paid by the TEO
            Client in accordance with the Agreement.
          </p>

          <h3 className="my-4">10. Liability</h3>
          <p>
            <strong>10.1</strong> To the maximum extent permitted under
            applicable law, TEO shall only be liable for personal injury or any
            damages resulting from (i) its gross negligence; (ii) its willful
            misconduct; or (iii) any fraud committed by TEO.
          </p>
          <p>
            <strong>10.2</strong> To the extent legally permitted under
            applicable law, TEO shall not be liable to the User or any third
            party, for any special, indirect, exemplary, punitive, incidental or
            consequential damages of any nature including, but not limited to
            damages or costs due to loss of profits, data, revenue, goodwill,
            production of use, procurement of substitute services, or property
            damage arising out of or in connection with the TEO Platform under
            these Terms of Use, including but not limited to any
            miscalculations, or the use, misuse, or inability to use the TEO
            Platform, regardless of the cause of action or the theory of
            liability, whether in tort, contract, or otherwise, even if TEO have
            been notified of the likelihood of such damages.
          </p>
          <p>
            <strong>10.3</strong> You agree that TEO can only be held liable as
            per the terms of this article 10 to the extent damages suffered by
            you are directly attributable to TEO. For the avoidance of doubt,
            TEO shall not be liable for any claims resulting from (i) your
            unauthorized use of the TEO Platform, (ii) your or any third party’s
            modification of (any parts) of the TEO Platform, (iii) your failure
            to use the most recent version of the TEO Platform made available to
            you or your failure to integrate or install any corrections to the
            TEO Platform issued by TEO, or (iv) your use of the TEO Platform in
            combination with any non-TEO products or services. The exclusions
            and limitations of liability under this article shall operate to the
            benefit of any of TEO’s affiliates and subcontractors under these
            Terms of Use to the same extent such provisions operate to the
            benefit of TEO.
          </p>
          <p>
            <strong>10.4</strong> In any case and without prejudice to the
            foregoing, the liability of TEO towards each User is limited to
            1,000 EUR, as long as the damage, fault and causal link are proven.
            This limitation of liability is justified in view of the fact that
            use of the TEO Platform by the Users is free of charge.
          </p>

          <h3 className="my-4">11. Warranties and disclaimers</h3>
          <p>
            <strong>11.1 By TEO</strong> - Except as expressly provided in this
            article 11 and to the maximum extent permitted by applicable law,
            the TEO Platform is provided “AS IS,” and TEO makes no (and hereby
            disclaims all) other warranties, covenants or representations, or
            conditions, whether written, oral, express or implied including,
            without limitation, any implied warranties of satisfactory quality,
            course of dealing, trade usage or practice, merchantability,
            suitability, availability, title, non-infringement, or fitness for a
            particular use or purpose, with respect to the use, misuse, or
            inability to use the TEO Platform or any other products or services
            provided to the User by TEO. TEO does not warrant that all errors
            can be corrected, or that access to or operation of the TEO Platform
            shall be uninterrupted, secure, or error-free. The User acknowledges
            and agrees that there are risks inherent to transmitting information
            over and storing information on the internet and that TEO is not
            responsible and cannot be held liable for any losses of your data.
          </p>
          <p>
            <strong>11.2 By User</strong> - You represent and warrant to TEO
            that (a) you have the authority to enter into this binding agreement
            personally and (b) that any data provided by you is accurate and
            truthful and shall not (i) infringe any Intellectual Property Rights
            of third parties; (ii) misappropriate any trade secret; (iii) be
            deceptive, defamatory, obscene, pornographic or unlawful; (iv)
            contain any viruses, worms or other malicious computer programming
            codes intended to damage TEO’s system or data; or (v) otherwise
            violate the rights of a third party. You agree that any use of the
            TEO Platform contrary to or in violation of these representations
            and warranties shall constitute unauthorized and improper use of the
            TEO Platform for which TEO cannot be held liable.
          </p>

          <h3 className="my-4">12. Warranties and disclaimers</h3>
          <p>
            TEO recognizes and understands the importance of your privacy and
            wants to respect your desire to store and access personal
            information in a private and secure environment. Please note that
            TEO has to be considered as the “<strong>Data Processor</strong>”
            and the TEO Client as the “<strong>Data Controller</strong>” for the
            processing of any Personal Data. Please note that TEO shall only
            process any Personal Data relating to you on the documented
            instructions from the Data Controller and takes appropriate
            technical and organizational measures against any unauthorized or
            unlawful processing of your Personal Data or its accidental loss,
            destruction or any unauthorized access thereto. In the event you as
            User request TEO of a copy, correction, deletion of the Personal
            Data or you want to restrict or object to the processing activities,
            TEO shall inform the TEO Client of such request within two (2)
            calendar days. TEO shall, as Data Processor, provide the TEO Client
            with full details of such request, objection or restriction of the
            User, together with a copy of the Personal Data held by TEO.
            Furthermore, TEO shall promptly direct you as User to the TEO
            Client, who will deal with the request in accordance with the
            applicable data protection legislation.
          </p>
          <p>
            The Personal Data that shall be processed by TEO as Data Processor
            may include (without limitation) a picture that you have uploaded
            via the TEO Platform to personalize the settings of your Account. We
            shall not use your Personal Data for any other purpose than
            instructed by the Data Controller and allowing you to make use of
            the features of the TEO Platform.
          </p>

          <h3 className="my-4">13. Term and termination</h3>
          <p>
            <strong>13.1</strong> TEO shall grant you a license on the TEO
            Platform during the Term as set out under these Terms of Use, unless
            these Terms of Use are terminated in accordance with article 13.2.
            At the end of the Term, any User rights on the TEO Platform that
            were granted to you under these Terms of Use shall automatically
            expire.
          </p>
          <p>
            <strong>13.2 Termination for breach</strong> - TEO may terminate
            with immediate effect these Terms of Use and your right to access
            and use of the TEO Platform (i) if TEO believes or has reasonable
            grounds to suspect that you are violating these Terms of Use
            (including but not limited to any violation of the Intellectual
            Property Rights of TEO) or any other guidelines or policies issued
            by TEO or (ii) if the TEO Client remains suspended for non-payment
            for more than sixty (60) days.
          </p>
          <p>
            <strong>13.3 Effects of termination</strong> - Upon the termination
            of these Terms of Use for any reason whatsoever in accordance with
            the provisions of these Terms of Use, at the moment of effective
            termination: (i) you will no longer be authorized to access or use
            the TEO Platform; (ii) TEO shall sanitize and destroy the Personal
            Data related to your Account on the TEO Platform, within thirty (30)
            calendar days upon termination of these Terms of Use in a secure way
            that ensures that all Personal Data is deleted and unrecoverable.
            Personal Data that needs to be kept to comply with relevant legal
            and regulatory retention requirements may be kept by TEO beyond
            expiry of the period of thirty (30) calendar days as long as
            required by such laws or regulations, and (iii) all rights and
            obligations of TEO or User under these Terms of Use shall terminate,
            except those rights and obligations under those articles
            specifically designated in article 14.6. Upon written request
            submitted by the TEO Client to prior to the termination of the
            Agreement, TEO can provide the TEO Client, immediately prior to the
            sanitization and destruction thereof, with a readable and usable
            copy of the Personal Data and/or the systems containing Personal
            Data.
          </p>

          <h3 className="my-4">14. Miscellaneous </h3>
          <p>
            <strong>14.1 Force Majeure</strong> - TEO shall not be liable for
            any failure or delay in the performance of its obligations with
            regard to the Teo Platform if such delay or failure is due to a
            Force Majeure Event. We shall notify you of the nature of such Force
            Majeure Event and the effect on our ability to perform our
            obligations under these Terms of Use and how we plan to mitigate the
            effect of such Force Majeure Event.
          </p>
          <p>
            <strong>14.2 Severability</strong> - If any provision of these Terms
            of Use are, for any reason, held to be invalid or unenforceable, the
            other provisions of these Terms of Use will remain enforceable and
            the invalid or unenforceable provision will be deemed modified so
            that it is valid and enforceable to the maximum extent permitted by
            law.
          </p>
          <p>
            <strong>14.3 Waiver</strong> - Any failure to enforce any provision
            of the Terms of Use shall not constitute a waiver thereof or of any
            other provision.
          </p>
          <p>
            <strong>14.4 Assignment</strong> - You may not assign or transfer
            these Terms of Use or any rights or obligations to any third party.
            TEO shall be free to (i) transfer or assign (part of) its
            obligations or rights under the Terms of Use to one of its
            affiliates and (ii) to subcontract performance or the support of the
            performance of these Terms of Use to its affiliates, to individual
            contractors and to third party service providers without prior
            notification to the User.
          </p>
          <p>
            <strong>14.5 Notices</strong> - All notices from TEO intended for
            receipt by you shall be deemed delivered and effective when sent to
            the mobile number or email address provided by you on your account.
            If you change this mobile number or email address, you must update
            your contact details on your personal settings page.
          </p>
          <p>
            <strong>14.6 Survival</strong> - The provisions of these Terms of
            Use that are expressly or implicitly intended to survive
            termination, shall survive any expiration or termination of this
            Agreement.
          </p>
          <p>
            <strong>14.7 Governing law and jurisdiction</strong> - These Terms
            of Use shall be governed by and construed in accordance with the
            laws of Belgium and the Parties hereto submit to the exclusive
            jurisdiction of the competent courts of Antwerp (Antwerp division).
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;

import { Dropdown, IconButton } from '@teo/components';
import {
  Information,
  Pencil,
  PlusFilled,
  Document,
  Chapter,
  Library,
  Play,
  Image,
  ImageFilled,
} from '@teo/components/icons';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { LessonTreeEdit } from '@/components/LessonTree/LessonTreeEdit';
import { LibraryModal } from '../LibraryModal/LibraryModal';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { axios, publicAxios } from '@/axios';
import { config } from '@/config';
import { uploadLesson, uploadImage } from '@/query/documents';
import { newUid, uploadFile } from '@/util';
import { uploadVideo } from '@/query/vimeo';
import Hint from '../Hints/Hints';
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { NotificationContext } from '@/components/Contexts/NotificationContext';

export const SidebarScormStudent = ({
  coursecontentId,
  publicationType,
  publicationPath,
  activeNode,
  autoInit,
  setActiveNode,
  isDesctop,
  setNumLesAs,
}: any) => {
  const { t } = useTranslation();
  const [lessons, setLessons] = useState<any[]>([]);
  const { hints } = useContext(HintsContext);
  const { newPage } = useContext(PageUrlContext);
  const [isAddButtonBottom, setIsAddButtonBottom] = useState(false);
  const { setShowNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (coursecontentId) {
      publicAxios
        .get(
          `${config.s3root}scorm/${coursecontentId}/${publicationPath}/imsmanifest.xml`
        )
        .then((result) => {
          const parser = new XMLParser({
            ignoreAttributes: false,
            isArray: (name: string) =>
              ['organization', 'resource', 'file', 'item'].includes(name),
          });
          const json = parser.parse(result.data);

          setLessons(json.manifest.organizations.organization[0].item);
          setNumLesAs &&
            setNumLesAs(
              json.manifest.organizations.organization[0].item.length
            );
          if (autoInit) {
            setActiveNode(json.manifest.organizations.organization[0].item[0]);
          }
        });
    }
  }, [coursecontentId]);

  const renderItem: any = (item: any, indent = 0) => {
    //console.log("rendering item", indent, item)
    if (Array.isArray(item))
      return <>{item.map((x) => renderItem(x, indent + 1))}</>;
    return (
      <>
        <li style={{ marginLeft: `${indent * 24}px` }}>
          <div
            key={item['@_identifier']}
            className={
              `relative my-1 flex cursor-pointer flex-row items-center rounded-md pl-2 pr-1 ` +
              (activeNode === item['@_identifier'] ? ' bg-[#f8f8f9]' : '')
            }
            onClick={(ev) => {
              setActiveNode(item);
            }}
          >
            <Document className={`m-2 w-6 min-w-6`} />
            <p
              className={`w-full max-w-[180px] flex-1 text-sm font-normal	text-[#231f20]`}
            >
              {item.title}
            </p>
            {activeNode === item['@_identifier'] && (
              <Pencil className="mx-2 w-5 min-w-5 text-[#fdbe06]" />
            )}
          </div>
        </li>
        {!!item.item && renderItem(item.item, indent)}
      </>
    );
  };

  const renderItem2: any = (item: any, indent = 0, i = 0) => {
    if (Array.isArray(item))
      return <>{item.map((x) => renderItem2(x, indent + 1, i + 1))}</>;

    return (
      <div key={indent + '_' + i}>
        <div
          onClick={(ev) => {
            setActiveNode(item);
          }}
        >
          <div className="my-1 flex items-center justify-between">
            <div
              className="flex min-h-[40px] w-full items-center"
              style={{ paddingLeft: '0px' }}
            >
              <div
                className="flex w-full cursor-pointer items-center rounded-md p-1 sm:p-2 sm:hover:bg-grey-transparent-01"
                data-testid="chapter"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="m-2 ml-1 mr-3 w-6 text-success-05 sm:ml-2 sm:mr-5"
                >
                  <path
                    d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25Zm5.074 6.482-6.3 7.5a.748.748 0 0 1-.562.268h-.013a.75.75 0 0 1-.557-.248l-2.7-3a.751.751 0 0 1 .88-1.186c.09.045.17.107.234.182l2.123 2.36 5.747-6.84a.75.75 0 0 1 1.148.964Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <p className="false w-full flex-1 pr-2 text-xs	font-medium text-black sm:text-sm">
                  <span className="pr-2 sm:pr-4">{i}.</span>
                  {item.title}
                </p>
              </div>
            </div>
          </div>
        </div>
        {!!item.item && renderItem2(item.item, indent, 1)}
      </div>
    );
  };

  return <>{renderItem2(lessons, -1)}</>;
};

import { deep_copy } from '@/util';

const digitalWithPwm = [
  'D0',
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'D6',
  'D7',
  'D8',
  'D9',
  'D10',
  'D11',
  'D14',
  'D15',
  'D16',
];
const digitalWithoutPwm = [
  'D12',
  'D13',
  'D17',
  'D18',
  'D19',
  'D20',
  'D21',
  'D22',
  'D23',
];
const relays = [
  'R0',
  'R1',
  'R2',
  'R3',
  'R4',
  'R5',
  'R6',
  'R7',
  'R8',
  'R9',
  'R10',
  'R11',
  'R12',
  'R13',
  'R14',
  'R15',
];
const analogInputs = [
  'A0',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'A10',
  'A11',
  'A12',
  'A13',
  'A14',
  'A15',
];
const digitalInputs = ['I16', 'I17', 'I18'];
const interrupts = ['IN0', 'IN1'];

const resiDigital = [];

for (let i = 1; i <= 64; i++) {
  resiDigital.push(`IR${i}`);
}

const MODE = {
  INPUT: 0,
  OUTPUT: 1,
  ANALOG: 2,
  PWM: 3,
  SERVO: 4,
  I2C: 5,
};

let defaultControllerState = Object.assign(
  {},
  digitalWithPwm.reduce((o, name) => {
    o[name] = {
      name,
      state: false,
      type: 'pwm_output',
      mode: MODE.OUTPUT,
      value: 0,
      pwm: false,
    };
    return o;
  }, {}),
  digitalWithoutPwm.reduce((o, name) => {
    o[name] = {
      name,
      state: false,
      type: 'digital_output',
      mode: MODE.OUTPUT,
      value: 0,
      pwm: false,
    };
    return o;
  }, {}),
  relays.reduce((o, name) => {
    o[name] = {
      name,
      state: false,
      type: 'relay_output',
      mode: MODE.OUTPUT,
      value: 0,
      pwm: false,
    };
    return o;
  }, {}),
  analogInputs.reduce((o, name) => {
    o[name] = {
      name,
      state: false,
      type: 'analog_input',
      mode: MODE.INPUT,
      value: 0,
      pwm: false,
    };
    return o;
  }, {}),
  digitalInputs.reduce((o, name) => {
    o[name] = {
      name,
      state: false,
      type: 'digital_input',
      mode: MODE.INPUT,
      value: 0,
      pwm: false,
    };
    return o;
  }, {}),
  resiDigital.reduce((o, name) => {
    o[name] = {
      name,
      state: false,
      type: 'digital_input',
      mode: MODE.INPUT,
      value: 0,
      pwm: false,
    };
    return o;
  }, {}),
  interrupts.reduce((o, name) => {
    o[name] = {
      name,
      state: false,
      type: 'interrupt_input',
      mode: MODE.INPUT,
      value: 0,
      pwm: false,
    };
    return o;
  }, {})
);

function newState(attrs) {
  return Object.assign(
    {},
    {
      state: false,
      type: 'virtual_input',
      value: 0,
      mode: MODE.OUTPUT,
      pwm: false,
    },
    attrs
  );
}

function getDefaultControllerState() {
  return deep_copy(defaultControllerState);
}

export { getDefaultControllerState, newState, MODE, defaultControllerState };

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, InputText } from '@teo/components';
import { Location } from '@teo/components/icons';

const backgroundColor = '#EAEAED';
const blurColor = '#C2CDFE';
const focusColor = '#7089FD';

const Map = ({
  setLocation,
  setLocationRange,
  locationRange,
  info,
  locationStr,
  userInfo,
  setLocationStr,
  disabled,
}: any) => {
  const { t } = useTranslation();
  const [myLocation, setMyLocation] = useState(
    userInfo?.locationStr ? userInfo?.locationStr : ''
  );

  const [mapPosition, setMapPosition] = useState(
    userInfo?.locationLongitude && userInfo?.locationLatitude
      ? {
          lat: userInfo?.locationLatitude,
          lng: userInfo?.locationLongitude,
        }
      : info?.location?.geometry
      ? {
          lat: info?.location?.geometry?.location.lat(),
          lng: info?.location?.geometry?.location.lng(),
        }
      : { lat: 51.19, lng: 4.45 }
  );

  const [trackColor, setTrackColor] = useState(blurColor);

  const percentage =
    (((locationRange || 0) - (0 || 0)) / ((100 || 0) - (0 || 0))) * 100;

  const [map, setMap] = useState<any>();
  const [marker, setMareker] = useState<any>();
  const [circle, setCircle] = useState<any>();

  useEffect(() => {
    if (!map && mapPosition) {
      const newMap = new (window as any).google.maps.Map(
        document.getElementById('map') as HTMLElement,
        {
          zoom: 8,
          center: mapPosition,
          disableDefaultUI: true,
        }
      );
      setMap(newMap);
    }

    if (info?.location) {
      const geocoder = new (window as any).google.maps.Geocoder();
      geocoder
        .geocode({ placeId: info?.location?.place_id })
        .then(({ results }: any) => {
          if (results[0]) {
            map.setCenter(results[0].geometry.location);
            const newMarker = new (window as any).google.maps.Marker({
              position: results[0].geometry.location,
              map,
            });
            setMareker(newMarker);

            const newCircle = new (window as any).google.maps.Circle({
              strokeColor: '#3257FC',
              strokeOpacity: 0.4,
              strokeWeight: 2,
              fillColor: '#3257FC',
              fillOpacity: 0.4,
              map,
              center: results[0].geometry.location,
              radius: Math.sqrt(1000000) * locationRange,
            });
            setCircle(newCircle);
          } else {
            window.alert('No results found');
          }
        });
      // .catch((e: any) => window.alert('Geocoder failed due to: ' + e));
    } else {
      const newMarker = new (window as any).google.maps.Marker({
        position: mapPosition,
        map,
      });
      setMareker(newMarker);

      const newCircle = new (window as any).google.maps.Circle({
        strokeColor: '#3257FC',
        strokeOpacity: 0.4,
        strokeWeight: 2,
        fillColor: '#3257FC',
        fillOpacity: 0.4,
        map,
        center: mapPosition,
        radius: Math.sqrt(1000000) * locationRange,
      });
      setCircle(newCircle);
    }
  }, [map]);

  useEffect(() => {
    circle && circle.setRadius(Math.sqrt(1000000) * locationRange);
  }, [locationRange]);

  const autocomplete = (e: any) => {
    setMyLocation(e.target.value);
    const autoComplete = new (window as any).google.maps.places.Autocomplete(
      e.currentTarget
    );
    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        // alert('this location not available');
      }
      if (place.geometry.viewport || place.geometry.location) {
        if (map) {
          map.setCenter(place.geometry.location);
          marker && marker.setPosition(place.geometry.location);
          circle && circle.setCenter(place.geometry.location);
          setMapPosition(place.geometry.location);
          setMyLocation(place.formatted_address);
          setLocation(place);
          setLocationStr(place);
        }
      }
    });
  };

  return (
    <>
      <div className="mb-1 mt-6 w-full text-sm font-semibold text-black">
        <Label htmlFor="firstName">
          {t(`pages.onboarding.step_2.location`)}
        </Label>
        <InputText
          disabled={disabled}
          Icon={Location}
          onChange={(e) => {
            autocomplete(e);
          }}
          placeholder={t(`pages.onboarding.step_2.placeholder`)}
          value={myLocation ? myLocation : locationStr}
        />
      </div>
      <div className="mb-6 mt-6 w-full text-sm font-semibold text-black">
        <Label htmlFor="firstName">
          {t(`pages.onboarding.step_2.distance`)}
        </Label>
        <div className="flex w-full items-center ">
          <input
            disabled={disabled}
            className="slider w-full flex-shrink"
            id="km"
            max={100}
            min={0}
            onChange={(e) => setLocationRange(+e?.currentTarget.value)}
            onMouseDown={() => setTrackColor(focusColor)}
            onMouseUp={() => setTrackColor(blurColor)}
            onTouchEnd={() => setTrackColor(blurColor)}
            onTouchStart={() => setTrackColor(focusColor)}
            step={5}
            style={{
              background: `linear-gradient(to right, ${trackColor} 0%, ${trackColor} ${percentage}%, ${backgroundColor} ${percentage}%, ${backgroundColor})`,
            }}
            value={locationRange}
            type="range"
          />
          <InputText
            type="number"
            disabled={disabled}
            value={locationRange}
            onChange={(e) => setLocationRange(+e?.currentTarget.value)}
            suffix="km"
            className="ml-3 w-[90px]"
          />
        </div>
      </div>
      <div
        className="rounded-lg"
        style={{ width: '100%', height: 250 }}
        id="map"
      />
    </>
  );
};

export default Map;

import { useState, useEffect } from 'react';
import { isEmpty, newUid, deep_copy } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import { Switch, InputScore, InputText } from '@teo/components';
import { QuestionSection } from '../QuestionSection';
import { T } from '@/components/T';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialKofferVraag = () => ({
  score: 1,
  vraag: '',
  antwoord: '',
  exercise: 0,
  type: 'KofferVraag',
});

export const BoxQuestionEditOld = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );
  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.box_question')
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : state.antwoord !== ''
  );
  const [score, setScore] = useState(state.score || 0);
  const [competencies, setCompetencies] = useState([]);
  const [exercise, setExercise] = useState(state.exercise);

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;

    newState.exercise = exercise;
    newState.score = score;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />

      {/* <span className="text-lg font-semibold text-grey-04">
        {t('Question')} ({t('Optional')})
      </span> */}
      <div className="wg-question">
        <QuestionSection questionVraag={questionVraag} />
      </div>
      <span className="text-lg font-semibold text-grey-04">
        {t('Exercise')} ({t('Optional')})
      </span>
      <InputText
        type="number"
        style={{ width: '80px' }}
        value={exercise}
        onChange={(ev) => {
          setExercise(ev.target.value);
        }}
      />

      <div>
        <div className="mt-4 flex flex-row items-center border-t border-b border-grey-02 py-2">
          <Switch
            checked={showAnswer}
            onChange={(isCheck) => setShowAnswer(isCheck)}
          />
          <div className="mr-4 border-r-2 border-grey-02 px-4">
            <T>widgets.widgets_edit.explain_after_submit</T>
          </div>
          <div className="mr-2">
            <T>widgets.widgets_edit.max_score</T>
          </div>
          <InputScore value={score} onChange={(value) => setScore(value)} />
        </div>
        {/* 
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>
      <div className={`${!showAnswer ? 'hidden' : ''}`}>
        {/* <span className="text-lg font-semibold text-grey-04">
          {t('Example answer')} ({t('Optional')})
        </span> */}
        <div className="wg-answer">
          <QuestionSection questionVraag={antwoordVraag} exampleAnswer={true} />
        </div>
      </div>
      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

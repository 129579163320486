import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { InputSelect, Label, RadioGroup } from '@teo/components';
import { Userwithorganisationwithidps } from '@/models';
import { useNavigate } from 'react-router-dom';
import { put, useGet } from '@/query';
import {
  diplomaConstants,
  intrestsConstants,
  workSituationOptions,
} from '@/constants/option.constants';
import Map from '@/pages/Protected/Onboarding/Steps/Map';
import { NotificationContext } from '@/components/Contexts/NotificationContext';

type InputSelectOption = {
  label: string;
  value: string;
};

function EducationAndWorkPage({ organisationSettings, organisationId }: any) {
  const { t } = useTranslation();

  const { auth } = useContext(AuthContext);
  const [user, setUser] = useState<any>(auth?.user);
  const [userInfo, setUserInfo] = useState<any>();
  const [degree, setDegree] = useState<any>({
    label: userInfo?.diploma,
    value: '',
  });
  const [interest, setInterest] = useState<any>({
    label: userInfo?.intrest,
    value: '',
  });
  const [loading, setloading] = useState(false);
  const [location, setLocation] = useState<any>('');
  const [locationRange, setLocationRange] = useState<number>(15);
  const [regime, setRegime] = useState<any>(userInfo?.regime);
  const [myNewLocation, setNewLocation] = useState(userInfo?.locationRange);
  const [lastfunctionId, setLastfunctionId] = useState<any>();
  const [workSituation, setWorkSituation] = useState<any>({
    label: lastfunctionId?.contract,
    value: '',
  });
  const [locationStr, setLocationStr] = useState<any>();
  const { notification, setShowNotification } = useContext(NotificationContext);

  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    const googleScript = document.createElement('script');
    googleScript.type = 'text/javascript';
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBXCsHIDS0RXugO_KyoWUvE0ThKGBpAxUs&libraries=places`;
    window.document.body.appendChild(googleScript);

    googleScript.addEventListener('load', () => {
      setloading(true);
      setLocationRange(userInfo?.locationRange ? userInfo?.locationRange : 15);
    });
  }, []);

  useEffect(() => {
    if (
      myNewLocation ||
      locationStr ||
      location?.geometry?.location.lat() ||
      location?.geometry?.location.lng()
    ) {
      put(`/userinfo/${user?.id}`, {
        locationRange: myNewLocation,
        locationStr: locationStr?.formatted_address,
        locationLatitude: location?.geometry?.location.lat(),
        locationLongitude: location?.geometry?.location.lng(),
      });
    }
  }, [
    myNewLocation,
    locationStr,
    location?.geometry?.location.lat(),
    location?.geometry?.location.lng(),
  ]);

  useGet(`/userinfo/${user?.id}`, {
    onSuccess: (result: any) => {
      setUserInfo(result?.data || null);
      setNewLocation(result?.data.locationRange || 0);
      setInterest(result?.data?.interest || null);
      setDegree(result?.data?.diploma || null);
      setRegime(result?.data?.regime || null);
      setLoadingUser(true);
    },
    onError: (res: any) => {
      setInterest(null);
      setDegree(null);
      setRegime(null);
      setLoadingUser(true);
    },
  });

  useGet(`/userfunction/${userInfo?.lastFunctionId}`, {
    onSuccess: (result: any) => {
      setLastfunctionId(result?.data || null);
      setWorkSituation(result?.data.contract || null);
    },
    enabled: !!userInfo?.lastFunctionId,
  });

  function stringToBool(str: string) {
    switch (str) {
      case 'interim':
        return 'NEW_JOB';
      case 'education':
        return 'NEW_KNOWLEDGE';
      case null:
        return 'NOT_WORKING';
      case 'permanent':
        return 'PERMANENT_CONTRACT';
      case 'temporary':
        return 'TEMP_CONTRACT';
      default:
        return null;
    }
  }

  if (!userInfo) return null;
  if (!lastfunctionId) return null;
  if (!loadingUser) return null;

  return (
    <div className="h-full md:flex">
      <div className="mb-[5%] flex h-full w-full md:items-center" id="userForm">
        <div className="flex w-full max-w-[440px] flex-col max-md:h-full">
          {(organisationSettings?.educationPreferences || !organisationId) && (
            <>
              <h2 className="text-base font-semibold">
                {t('pages.profile_education_and_work.course')}
              </h2>

              <div className="mt-6 space-y-2 md:space-y-4">
                <div>
                  <Label htmlFor="degree">
                    {t('pages.profile_education_and_work.highest_degree_label')}
                  </Label>
                  <div id="degree" data-testid="degree">
                    <InputSelect
                      value={{ label: degree, value: '' }}
                      onChange={(e: InputSelectOption) => {
                        setShowNotification(
                          t('pages.profile_account.info_was_saved')
                        );
                        setDegree(t(`pages.onboarding.step_5.${e.label}`));
                        put(`/userinfo/${user?.id}`, {
                          diploma: t(`pages.onboarding.step_5.${e.label}`),
                        });
                      }}
                      options={diplomaConstants}
                    >
                      {(options) => {
                        if (options?.value === '') {
                          return options?.label;
                        } else {
                          return (
                            <span>
                              {t(`pages.onboarding.step_5.${options.label}`)}
                            </span>
                          );
                        }
                      }}
                    </InputSelect>
                  </div>
                </div>
              </div>

              <div
                className={`mb-1 mt-6 border-grey-03 pb-8 text-sm text-black ${
                  organisationSettings?.workPreferences && 'border-b'
                }`}
              >
                <Label htmlFor="interest">
                  {t('pages.profile_education_and_work.technology_is_label')}
                </Label>
                <div id="interest" data-testid="interest">
                  <InputSelect<InputSelectOption>
                    value={
                      interest
                        ? interest
                        : {
                            label: userInfo?.intrest
                              ? t(
                                  `pages.onboarding.step_5.${userInfo?.intrest}`
                                )
                              : '',
                            value: '',
                          }
                    }
                    onChange={(e: InputSelectOption) => {
                      setShowNotification(
                        t('pages.profile_account.info_was_saved')
                      );
                      setInterest(e);
                      put(`/userinfo/${user?.id}`, {
                        intrest: e.label,
                      });
                    }}
                    options={intrestsConstants}
                  >
                    {(options) => {
                      if (options.value === '') {
                        return options.label;
                      } else {
                        return (
                          <span>
                            {t(`pages.onboarding.step_5.${options.label}`)}
                          </span>
                        );
                      }
                    }}
                  </InputSelect>
                </div>
              </div>
            </>
          )}
          {organisationSettings?.workPreferences && (
            <>
              <div
                className={`space-y-2 md:space-y-4 ${
                  organisationSettings?.educationPreferences && ' mt-6'
                }`}
              >
                <h2 className="text-base font-semibold">
                  {t('pages.profile_education_and_work.work_preferences')}
                </h2>

                {loading && (
                  <Map
                    setLocation={setLocation}
                    setLocationRange={setNewLocation}
                    locationRange={
                      myNewLocation ? myNewLocation : locationRange
                    }
                    userInfo={userInfo}
                    setLocationStr={setLocationStr}
                  />
                )}
              </div>

              <div className="mt-6 space-y-2 md:space-y-4">
                <div className=" mt-6">
                  <RadioGroup
                    value={regime ? regime[0] : null}
                    onChange={(val) => {
                      setShowNotification(
                        t('pages.profile_account.info_was_saved')
                      );
                      setRegime([val]);
                      put(`/userinfo/${user?.id}`, {
                        regime: [val],
                      });
                    }}
                    label={t(
                      'pages.profile_education_and_work.preferred_shift_work'
                    )}
                  >
                    <RadioGroup.Option value="day">
                      {t(`pages.onboarding.step_3.day`)}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="2shift">
                      {t(`pages.onboarding.step_3.2shift`)}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="3shift">
                      {t(`pages.onboarding.step_3.3shift`)}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="continuous">
                      {t(`pages.onboarding.step_3.continuous`)}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="weekend">
                      {t(`pages.onboarding.step_3.weekend`)}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="night">
                      {t(`pages.onboarding.step_3.flexibel`)}
                    </RadioGroup.Option>
                  </RadioGroup>
                </div>

                <div>
                  <Label htmlFor="work_situation">
                    {t(
                      'pages.profile_education_and_work.current_work_situation'
                    )}
                  </Label>
                  <div id="work_situation" data-testid="workSituation">
                    <InputSelect<InputSelectOption>
                      value={
                        workSituation?.value
                          ? workSituation
                          : {
                              label: stringToBool(workSituation)
                                ? t(
                                    `pages.onboarding.step_6.${stringToBool(
                                      workSituation
                                    )}`
                                  )
                                : '',
                              value: '',
                            }
                      }
                      onChange={(e: InputSelectOption) => {
                        setShowNotification(
                          t('pages.profile_account.info_was_saved')
                        );
                        setWorkSituation(e);
                        if (e.label === 'NEW_JOB') {
                          put(`/userfunction/${userInfo?.lastFunctionId}`, {
                            contract: 'interim',
                            isActive: true,
                          });
                        } else if (e.label === 'NEW_KNOWLEDGE') {
                          put(`/userfunction/${userInfo?.lastFunctionId}`, {
                            contract: 'education',
                            isActive: true,
                          });
                        } else if (e.label === 'NOT_WORKING') {
                          put(`/userfunction/${userInfo?.lastFunctionId}`, {
                            contract: null,
                            isActive: true,
                          });
                        } else if (e.label === 'PERMANENT_CONTRACT') {
                          put(`/userfunction/${userInfo?.lastFunctionId}`, {
                            contract: 'permanent',
                            isActive: true,
                          });
                        } else if (e.label === 'TEMP_CONTRACT') {
                          put(`/userfunction/${userInfo?.lastFunctionId}`, {
                            contract: 'temporary',
                            isActive: true,
                          });
                        } else {
                          put(`/userfunction/${userInfo?.lastFunctionId}`, {
                            contract: 'other',
                            isActive: true,
                          });
                        }
                      }}
                      options={workSituationOptions}
                    >
                      {(options) => {
                        if (options.value === '') {
                          return options.label;
                        } else {
                          return (
                            <span>
                              {t(`pages.onboarding.step_6.${options.label}`)}
                            </span>
                          );
                        }
                      }}
                    </InputSelect>
                  </div>
                </div>

                {/*      <div>
                            <Label htmlFor="work_situation">
                                {t('pages.profile_education_and_work.available_for')}
                            </Label>
                            <div id="work_situation" data-testid="workSituation">
                                <InputSelect<InputSelectOption>
                                    value={workSituation}
                                    onChange={(e: InputSelectOption) => {
                                        setWorkSituation(e);
                                    }}
                                    options={workSituationOptions}
                                >
                                    {(options) => (
                                        <span>{t(`pages.onboarding.step_6.${options.label}`)}</span>
                                    )}
                                </InputSelect>
                            </div>
                        </div>*/}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EducationAndWorkPage;

import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Label,
  InputText,
  InputTextarea,
  InputSelect,
  ErrorMessage,
  Multiselect,
} from '@teo/components';
import { useState, useEffect, useContext } from 'react';
import { axios } from '@/axios';
import { config } from '@/config';
import { LangData } from '@/models/langData.interface';
import { useGet } from '@/query/fetchHooks';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { parseCourseType, parseFilter } from '@/util';
import { courseTypeOptions } from '@/models/courseTypeOptions';
import { Userwithorganisationwithidps } from '@/models';

function PublishModal({
  isOpenPublishSettings,
  closeModal,
  publication,
  data,
  setIsOpenPublishSettings,
  setPublication,
  publicationType,
}: any) {
  const { t } = useTranslation();

  const [courseDescription, setCourseDescription] = useState(
    publication?.description ? publication?.description : ''
  );
  const [courseTitle, setCourseTitle] = useState(
    publication?.name ? publication?.name : ''
  );
  const [isChecked, setIsChecked] = useState(
    publication?.template ? publication?.template : 'theory'
  );
  const [lang, setLang] = useState(
    data?.find((option: LangData) => option?.value === publication?.lang)
  );
  const [courseType, setCourseType] = useState(
    publication?.publicationCourseType
  );
  const [categories, setCategories] = useState<any>([]);
  const [prevCategories, setPrevCategories] = useState<any>([]);
  const [selectCategories, setSelectCategories] = useState<any>([]);
  const [selectProfessions, setSelectProfessions] = useState<any>([]);
  const [competencyProfiles, setCompetencyProfiles] = useState<any>([]);
  const [checkValid, setCheckValid] = useState<boolean>(false);
  const [addCompetencyProfiles, setAddCompetencyProfiles] = useState(false);
  const [filterCategories, setFilterCategories] = useState('');
  const [filterProfessions, setFilterProfessions] = useState('');

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  useGet(`/coursecontenttagsdetails`, {
    coursecontentId: publication?.id,
    onSuccess: (result: any) => {
      const data = result?.data
        .filter((x: any) => x?.tag?.startsWith('category:'))
        .map((x: any) => ({
          key: x.tag.split(':')[1],
          label: x.tag.split(':')[1],
          tag: x.tag.split(':')[1],
        }));
      setCategories(data);
    },
  });

  const [courseProfessions, setCourseProfessions] = useState<any>([]);
  //const [courseCategories, setCourseCategories] = useState<any>([]);

  // const allCategories: any = useGet(`/categories`, {
  //   count: 10,
  //   sort: 'tag',
  //   q: parseFilter(filterCategories)
  // });

  const allProfessions: any = useGet(`/professions`, {
    count: 10,
    sort: 'name',
    q: parseFilter(filterProfessions),
  });

  const infoProfessions: any = useGet(`/professions`, {
    count: 1000,
    sort: 'name',
  });

  // const infoCategories: any = useGet(`/categories`, {
  //   count: 1000,
  //   sort: 'tag',
  // });

  useGet(`/coursecontentprofessions`, {
    coursecontentId: publication?.id,
    onSuccess: (result: any) => {
      setCourseProfessions(result.data || []);
    },
  });

  /*
  useGet(`/coursecontenttagsdetails`, {
    coursecontentId: publication?.id,
    onSuccess: (result: any) => {
      setCourseCategories(result.data || []);
    },
  });
  */

  // useEffect(() => {
  //   if (allCategories?.data && prevCategories) {
  //     const data = prevCategories.map((category: any) => {
  //       const val = allCategories?.data?.data?.find(
  //         (el: any) => el.tag === category?.tag
  //       );
  //       if (val) {
  //         return {
  //           key: val?.id,
  //           label: category?.tag,
  //           tag: category?.tag,
  //         };
  //       }
  //     });
  //     setCategories(data);
  //   }
  // }, [allCategories?.data, prevCategories]);

  useEffect(() => {
    if (allProfessions?.data) {
      const data = (allProfessions?.data?.data as any[]).map((profession) => ({
        key: profession?.id,
        label: profession?.name,
      }));
      setCompetencyProfiles(data);
    }
  }, [allProfessions?.data]);

  /*
  useEffect(() => {
    if (infoCategories?.data && courseCategories) {
      const tags = courseCategories?.filter((x: any) => x?.tag);
      const prevCategories = tags
        .filter((x: any) => x?.tag)
        .map((x: any) =>
          infoCategories?.data?.data?.find((y: any) => y?.tag == x?.tag)
        );
      const prevCategory = prevCategories?.filter((el: any) => el);
      prevCategory?.map((x: any) => {
        const val = selectCategories?.find((el: any) => el.key === x?.id);
        if (!val) {
          setSelectCategories((prev: any) => [
            ...prev,
            {
              key: x?.id,
              label: x?.tag.charAt(9).toUpperCase() + x?.tag.slice(11),
              tag: x?.tag,
            },
          ]);
        }
      });
    }
  }, [courseCategories, infoCategories?.data]);
  */

  useEffect(() => {
    if (infoProfessions?.data && courseProfessions) {
      (courseProfessions as any[])?.map((profession) => {
        const prevProfessions = infoProfessions?.data?.data?.find(
          (el: any) => el.id === profession?.professionId
        );
        const val = selectProfessions?.find(
          (el: any) => el.key === profession?.professionId
        );
        if (!val) {
          setSelectProfessions((prev: any) => [
            ...prev,
            {
              key: prevProfessions?.id,
              label: prevProfessions?.name,
            },
          ]);
        }
      });
    }
    courseProfessions?.length > 0 && setAddCompetencyProfiles(true);
  }, [courseProfessions, infoProfessions?.data]);

  const handleError = (el: string) => (el ? false : checkValid ? true : false);

  const handleSave = () => {
    setCheckValid(true);
    if (courseTitle && courseDescription) {
      setPublication((prev: any) => ({
        ...prev,
        description: courseDescription,
        name: courseTitle,
        lang: lang?.value,
        template: isChecked,
        publicationCourseType: courseType,
      }));
      axios.put(`${config.backend}/coursecontent/${publication?.id}`, {
        id: publication?.id,
        description: courseDescription,
        name: courseTitle,
        lang: lang?.value,
        template: isChecked,
        publicationCourseType: courseType,
      });

      const oldTags =
        publication?.tags?.split(';;')?.filter((x: string) => {
          return x.startsWith('tag:');
        }) || [];
      const updateCategories = categories.map(
        (cat: any) => 'category:' + cat?.tag
      );
      axios.post(`${config.backend}/tag/setcoursecontenttags`, {
        coursecontentId: publication?.id,
        tags: [...updateCategories, ...oldTags],
      });

      if (selectProfessions?.length > 0) {
        for (const addObj2 of selectProfessions) {
          const existingObj = courseProfessions?.find(
            (obj1: any) => obj1?.professionId === addObj2?.key
          );
          if (!existingObj) {
            axios.post(`${config.backend}/coursecontentprofessions`, {
              coursecontentId: publication?.id,
              professionId: addObj2?.key,
            });
          }
        }
      }

      if (courseProfessions?.length > 0) {
        for (const removedObj2 of courseProfessions) {
          const existingObj = selectProfessions?.find(
            (obj1: any) => obj1?.key === removedObj2?.professionId
          );
          if (!existingObj) {
            axios.delete(
              `${config.backend}/coursecontentprofession/${publication?.id}/${removedObj2?.professionId}`
            );
          }
        }
      }
      setIsOpenPublishSettings(false);
    }
  };

  const handleProfiles = (value: any) => {
    setFilterProfessions('');
    const prevState = selectProfessions?.find(
      (el: any) => el?.key === value[value.length - 1]?.key
    );
    prevState
      ? setSelectProfessions((prev: any) =>
          prev?.filter((item: any) => item?.key !== prevState?.key)
        )
      : setSelectProfessions(value);
  };

  const handleCategories = (value: any) => {
    setFilterCategories('');
    const prevState = selectCategories?.find(
      (el: any) => el?.key === value[value.length - 1]?.key
    );
    prevState
      ? setSelectCategories((prev: any) =>
          prev?.filter((item: any) => item?.key !== prevState?.key)
        )
      : setSelectCategories(value);
  };

  const typeCourse = authUser?.useCourse;
  const typeAssessment = authUser?.useAssessment;
  const typeVacancy = authUser?.useLeervacature;
  const typeInstruction = authUser?.useInstruction;

  let selectType = courseTypeOptions;

  if (!typeCourse) {
    selectType = selectType?.filter((el: any) => el?.id !== 1);
  }
  if (!typeAssessment) {
    selectType = selectType?.filter((el: any) => el?.id !== 2);
  }
  if (!typeVacancy) {
    selectType = selectType?.filter((el: any) => el?.id !== 3);
  }
  if (!typeInstruction) {
    selectType = selectType?.filter((el: any) => el?.id !== 4);
  }

  return (
    <div className="overflow-hidden bg-white">
      <Modal isOpen={isOpenPublishSettings}>
        <Modal.Header onClose={closeModal}>
          <h2 className="text-sm font-semibold">
            {t('pages.create_edit_course.modal_title')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <div className="-mx-6 max-h-[70vh] overflow-y-scroll px-6">
            <h1 className="text-base font-semibold">
              {t('pages.create_edit_course.complete_basic_settings')}
            </h1>
            <p className="mt-2 text-sm font-normal text-[#7d7d81]">
              {t(
                `pages.create_edit_course.type_${publicationType}.modal_subtitle_course`
              )}
            </p>

            <div
              className="flex h-full md:w-full md:items-center"
              id="publish_course"
            >
              <form
                className="m-auto flex w-full flex-col max-md:h-full"
                onSubmit={() => {}}
              >
                <div className="mb-1 space-y-2 md:space-y-4">
                  <div>
                    <Label htmlFor="courseTitle">
                      <p className="mt-6 text-sm font-semibold">
                        {t(
                          `pages.create_edit_course.type_${publicationType}.title_course`
                        )}
                      </p>
                    </Label>
                    <InputText
                      id="courseTitle"
                      type="text"
                      onChange={(e) => setCourseTitle(e.target.value)}
                      value={courseTitle}
                      hasError={handleError(courseTitle)}
                    />
                    {handleError(courseTitle) && (
                      <div className="mt-0 mb-2 text-sm text-tint-red-dark">
                        <ErrorMessage id="errorMessage">
                          {t('pages.create_edit_course.course_title_error')}
                        </ErrorMessage>
                      </div>
                    )}
                  </div>

                  <div>
                    <Label htmlFor="courseDescription">
                      <p className="mt-6 text-sm font-semibold">
                        {t(
                          `pages.create_edit_course.type_${publicationType}.course_description`
                        )}
                      </p>
                    </Label>

                    <InputTextarea
                      id="courseDescription"
                      placeholder={t(
                        `pages.create_edit_course.type_${publicationType}.course_description_placeholder`
                      )}
                      onChange={(event) =>
                        setCourseDescription(event.target.value)
                      }
                      limit={300}
                      value={courseDescription}
                      hasError={handleError(courseDescription)}
                    />

                    {handleError(courseDescription) && (
                      <div className="mt-0 mb-2 text-sm text-tint-red-dark">
                        <ErrorMessage id="errorMessage">
                          {t(
                            'pages.create_edit_course.course_description_error'
                          )}
                        </ErrorMessage>
                      </div>
                    )}
                  </div>

                  {/*          <RadioGroup
                    label={t('pages.create_edit_course.type_of_course')}
                    onChange={(value: string) => {
                      setIsChecked(value);
                    }}
                    value={isChecked}
                  >
                    <div className="flex flex-col gap-5 sm:flex-row">
                      <div
                        className={`basis-1/2 rounded-lg border border-grey-02 p-3 ${
                          isChecked == 'theory' && 'border-secondary-04'
                        }`}
                      >
                        <RadioGroup.Option value="theory">
                          <span className="text-sm">
                            {t('pages.create_edit_course.course_checkbox_1')}
                          </span>
                        </RadioGroup.Option>
                      </div>
                      <div
                        className={`basis-1/2 rounded-lg border border-grey-02 p-3 ${
                          isChecked == 'practice' && 'border-secondary-04'
                        }`}
                      >
                        <RadioGroup.Option value="practice">
                          <span className="text-sm" data-testid="radio_btn">
                            {t('pages.create_edit_course.course_checkbox_2')}
                          </span>
                        </RadioGroup.Option>
                      </div>
                    </div>
                  </RadioGroup>*/}

                  <div className="w-[192px]">
                    <Label htmlFor="courseType">
                      <p className="mt-6 text-sm font-semibold">
                        {t('pages.create_edit_course.course_type')}
                      </p>
                    </Label>
                    <div id="courseType">
                      <InputSelect
                        onChange={(coursetype) => {
                          setCourseType(coursetype.name);
                        }}
                        value={{
                          id: 0,
                          value: parseCourseType(courseType),
                          name: parseCourseType(courseType),
                        }}
                        options={selectType}
                      >
                        {(options) => (
                          <span>{t(`courseTypes.${options.name}`)} </span>
                        )}
                      </InputSelect>
                    </div>
                  </div>

                  <div className="w-[192px]">
                    <Label htmlFor="courseLang">
                      <p className="mt-6 text-sm font-semibold">
                        {t(
                          `pages.create_edit_course.type_${publicationType}.language_course_select`
                        )}
                      </p>
                    </Label>
                    <div id="courseLang">
                      <InputSelect<LangData>
                        onChange={(lang) => {
                          setLang(lang);
                        }}
                        value={
                          lang
                            ? lang
                            : data?.find(
                                (option: LangData) =>
                                  option?.value === publication?.lang
                              )
                        }
                        options={data}
                      >
                        {(options) => (
                          <span>{t(`langs.${options?.value}`)} </span>
                        )}
                      </InputSelect>
                    </div>
                  </div>

                  <div>
                    <Label htmlFor="categories">
                      <p className="mt-6 text-sm font-semibold">
                        {t('pages.create_edit_course.categories')}
                      </p>
                    </Label>

                    <PaginationMultiSelect
                      value={categories}
                      onChange={(value: any) => {
                        setCategories(value);
                      }}
                      keyFn={(row: any) => row?.tag}
                      fetch={useGet.bind(
                        null,
                        `/categories?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                      )}
                      defaultSort={[{ id: 'tag', desc: false }]}
                      render={(row: any) => {
                        return row?.tag;
                      }}
                    />
                    {/** 
                    <PaginationMultiSelect
                      removeButtonLabel="clear"
                      toggleSelectionLabel="toggle"
                      placeholder={t(
                        'pages.create_edit_course.categories_placeholder'
                      )}
                      //options={categories}
                      //onChange={(value: any) => handleCategories(value)}
                      onRemoved={(value) => {
                        setFilterCategories('');
                        setSelectCategories(value);
                      }}
                      value={selectCategories}
                      query={filterCategories}
                      setQuery={(query) => setFilterCategories(query)}
                      positionDropdown="top"
                      hasError={selectCategories.length < 1 && checkValid}
                    />
                    */}
                  </div>

                  {/*checkValid && (
                    <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                      <ErrorMessage id="errorMessage">
                        {t('pages.create_edit_course.categories_error')}
                      </ErrorMessage>
                    </div>
                  )*/}

                  {publication?.publicationCourseType !== 'instruction' && (
                    <div>
                      <Label htmlFor="competencyProfiles">
                        <p className="mt-6 text-sm font-semibold">
                          {t('pages.create_edit_course.competency_profiles')}
                          {!addCompetencyProfiles && (
                            <span className="font-normal text-grey-05">
                              {' '}
                              ({t('pages.create_edit_course.optional')})
                            </span>
                          )}
                        </p>
                      </Label>

                      {addCompetencyProfiles ? (
                        <Multiselect
                          removeButtonLabel="clear"
                          toggleSelectionLabel="toggle"
                          placeholder={t(
                            'pages.create_edit_course.competency_profiles_placeholder'
                          )}
                          options={competencyProfiles}
                          onChange={(value: any) => handleProfiles(value)}
                          onRemoved={(value) => {
                            setFilterProfessions('');
                            setSelectProfessions(value);
                          }}
                          value={selectProfessions}
                          query={filterProfessions}
                          positionDropdown="top"
                          setQuery={(query) => setFilterProfessions(query)}
                        />
                      ) : (
                        <Button
                          onClick={() => setAddCompetencyProfiles(true)}
                          variant="outline"
                        >
                          {t(
                            'pages.create_edit_course.add_competency_profiles'
                          )}
                        </Button>
                      )}

                      {/* {isCompetencyProfilesTouched && (
                      <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                        <ErrorMessage id="errorMessage">
                          {t(
                            'pages.create_edit_course.competency_profiles_error'
                          )}
                        </ErrorMessage>
                      </div>
                    )} */}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('button.cancel')}
          onCancel={closeModal}
        >
          <div>
            <Button onClick={() => handleSave()}>{t('button.save')}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PublishModal;

import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { format } from 'date-fns';
import { DataTable } from '@/components/DataTable/DataTable';
import { Button, ButtonLink } from '@teo/components';
import { config } from '@/config';

function HelpDeskTab({ auth }: any) {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');

  const downloadLink = `${config.backend}/analytics/supportevents/csv/${auth?.user?.activeOrganisationId}?jwt=${token}`;

  return (
    <div className="">
      <div className="flex pt-8 pb-6">
        <p className="text-md font-semibold text-black">
          {t('pages.organisation_licensing_and_billing.help_desk_tab')}
        </p>
        <div className="ml-auto flex">
          <ButtonLink
            to={downloadLink}
            size="sm"
            variant="outline"
            download
            className="border-grey-o3 border"
            data-testid="downloadOverview"
          >
            {t('pages.organisation_licensing_and_billing.table.download')}
          </ButtonLink>
        </div>
      </div>
      <DataTable
        fetch={useGet.bind(null, '/supportevents', {
          organisationId: auth?.user?.activeOrganisationId,
        })}
        defaultSort={[
          { id: '-endTime', desc: false },
          { id: '-id', desc: false },
        ]}
        columns={[
          {
            addTitleOnMobile: true,
            title: t(
              'pages.organisation_licensing_and_billing.table.ticket_id'
            ),
            id: 'ticketID',
            sortable: false,
            className: 'col-start-1 col-end-4 row-start-2',
            render: (row: any) => row.ticketId,
          },
          {
            addTitleOnMobile: true,
            title: t('pages.organisation_licensing_and_billing.table.start'),
            id: '-id',
            className: 'col-start-1 col-end-5 row-start-1',
            render: (row: any) => format(new Date(row.startTime), 'dd/MM/yyyy'),
          },
          {
            addTitleOnMobile: true,
            id: '-endTime',
            title: t('pages.organisation_licensing_and_billing.table.end'),
            className:
              'col-start-5 col-end-10 row-start-1 justify-end text-right',
            render: (row: any) => format(new Date(row.endTime), 'dd/MM/yyyy'),
          },
          {
            addTitleOnMobile: true,
            id: 'type',
            title: t('pages.organisation_licensing_and_billing.table.type'),
            sortable: false,
            className:
              'col-start-4 col-end-7 row-start-2 justify-center text-center',
            render: (row: any) => row.type,
          },
          {
            addTitleOnMobile: true,
            id: 'description',
            title: t(
              'pages.organisation_licensing_and_billing.table.description'
            ),
            sortable: false,
            className: 'col-start-1 col-end-10 row-start-3',
            render: (row: any) => row.description,
          },
          {
            addTitleOnMobile: true,
            id: 'credits',
            title: t('pages.organisation_licensing_and_billing.table.credits'),
            sortable: false,
            className:
              'col-start-7 col-end-10 row-start-2 justify-end text-right',
            render: (row: any) => row.credits,
          },
        ]}
      />
    </div>
  );
}

export default HelpDeskTab;

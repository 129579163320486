/* eslint-disable import/namespace */
import { useState, useEffect, useRef, useCallback, createRef } from 'react';
import { newUid, deep_copy, relativePos, v2equal, unique } from '@/util';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import {
  Switch,
  InputScore,
  Button,
  IconButton,
  Spinner,
} from '@teo/components';
import { DragHandle, Image, Cross, Plus } from '@teo/components/icons';
import { uploadImage } from '@/query/documents';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as d3 from 'd3';
import { useMediaQuery } from 'react-responsive';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import ConnectWidgetGPT from './AIWidget/ConnectAI/ConnectWidgetGPT';
import useResizeObserver from '@react-hook/resize-observer';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

const pastels = [
  '#C25FF4',
  '#E3BF00',
  '#6EC6C0',
  '#F25850',
  '#29BAFF',
  '#AE7226',
  '#1173DD',
  '#9AD055',
  '#807EFF',
  '#FF8EA4',
];

export const initialLink = () => ({
  score: 1,
  vraag: '',
  type: 'Link',
  left: [{ uid: newUid(20) }, { uid: newUid(20) }],
  right: [{ uid: newUid(20) }, { uid: newUid(20) }],
  connections: [],
});

const CustomCardConnect = (props) => {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '35%',
    height: '100%',
  };
  function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      test: monitor,
    }));

  function renderItem() {
    switch (itemType) {
      case 'card':
        return (
          <div
            className={`flex w-full max-w-[250px] flex-row items-center gap-3 rounded-lg rounded-lg border border-grey-02 bg-white p-2`}
          >
            {item?.leftCard && (
              <DragHandle className="w-6 min-w-6 text-grey-04" />
            )}
            <div className={`flex w-full flex-col gap-2 `}>
              <div className="relative flex h-20 lg:h-24">
                {item?.file ? (
                  <>
                    <img
                      alt={item?.text}
                      src={item?.file}
                      className="m-auto h-full w-full rounded object-contain object-center"
                    />
                  </>
                ) : (
                  <div className="flex h-full w-full cursor-pointer rounded bg-grey-01">
                    <div className="m-auto">
                      <Image className="mx-2 w-6 min-w-6 text-grey-07" />
                    </div>
                  </div>
                )}
              </div>
              <input
                type="text"
                value={item?.text}
                onChange={() => {}}
                placeholder="Description (optional)"
                className="w-full border-0 border-b border-transparent p-1 text-sm !shadow-none !ring-0 focus:border-0 focus:border-b focus:border-grey-02"
              />
            </div>
            {!item?.leftCard && (
              <DragHandle className="w-6 min-w-6 text-grey-04" />
            )}
          </div>
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}
      >
        {renderItem()}
      </div>
    </div>
  );
};

const CardConnect = ({
  card,
  index,
  leftCard,
  items,
  setItems,
  moveCard,
  setConnections,
  connections,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const ref = useRef(null);
  const [cardText, setcardText] = useState(card?.text);
  const [showDelete, setShowDelete] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [loadedImage, setLoadedImage] = useState(false);

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const handleDeleteCard = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const newConnections = connections.filter(
      (subArray) => !subArray.includes(card.uid)
    );
    setConnections(newConnections);

    setItems((prev) =>
      prev?.filter((el, i) => {
        return i !== index;
      })
    );
  };

  const handleUploadImage = async (event) => {
    setLoadedImage(true);
    const img = event.target.files[0];
    const data = await uploadImage(img, 900, 900, 'jpeg');
    setItems((prev) =>
      prev.map((el, i) => (i == index ? { ...el, file: data } : el))
    );
  };

  const handleDeleteImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setItems((prev) =>
      prev.map((el, i) => (i == index ? { ...el, file: null } : el))
    );
  };

  const handleBlur = () => {
    setItems((prev) =>
      prev.map((el, i) => (i == index ? { ...el, text: cardText } : el))
    );
  };

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY * 0.1) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY * 1.9) {
        return;
      }
      if (isDesktop) {
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
    drop: (item) => {
      !isDesktop && moveCard(item.index, index);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return {
        index: index,
        text: card?.text,
        file: card?.file,
        uid: card?.uid,
        leftCard: leftCard,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging(monitor) {
      return card.uid === monitor.getItem().uid;
    },
  });
  drag(drop(ref));

  return (
    <div
      className={`${card?.uid} flex w-full max-w-[250px] rounded-lg ${
        isDesktop && 'border border-grey-02'
      } pr-2 ${!leftCard ? `ml-auto pl-2` : `pr-2`}  ${
        isDragging ? 'bg-transparent' : isDesktop && 'bg-white'
      } 
        ${isDragging && !isDesktop && 'hidden	'}
      `}
      onMouseEnter={() => setShowCloseBtn(true)}
      onMouseLeave={() => setShowCloseBtn(false)}
      //id={card?.uid}
    >
      <div
        className={`relative z-[2] flex w-full flex-row items-center gap-3 rounded-lg bg-white p-2 ${
          !isDesktop && 'border border-grey-02'
        } ${isDragging && 'opacity-0'}`}
        ref={ref}
        data-handler-id={handlerId}
      >
        {showCloseBtn && (
          <IconButton
            Icon={Cross}
            ariaLabel="Search"
            onClick={(e) => handleDeleteCard(e)}
            variant="outline"
            className={`absolute top-1 bg-grey-02 ${
              leftCard ? 'left-1' : 'right-1'
            }`}
            size="sm"
          />
        )}
        {leftCard && <DragHandle className="w-6 min-w-6 text-grey-04" />}
        <div className={`flex w-full flex-col gap-2 ${isDragging && 'pr-2'} `}>
          <div
            className="relative flex h-20 lg:h-24"
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            {card?.file ? (
              <>
                <img
                  alt={card?.text}
                  src={card?.file}
                  className="m-auto h-full w-full rounded object-contain object-center"
                />
                {showDelete && (
                  <div className="absolute inset-0 flex rounded bg-black/10">
                    <Button
                      size="md"
                      className="m-auto bg-white !p-2"
                      onClick={(e) => handleDeleteImage(e)}
                      variant="secondary"
                    >
                      <img
                        src="/images/icons/trash_icon_red.svg"
                        alt="delete button"
                      />
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <div
                className="flex h-full w-full cursor-pointer rounded bg-grey-01"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                <div className="m-auto">
                  {!loadedImage ? (
                    <Image className="mx-2 w-6 min-w-6 text-grey-07" />
                  ) : (
                    <Spinner
                      ariaLabel="Loading spinner"
                      className="h-8 w-8 border-grey-05"
                    />
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUploadImage}
                  accept="image/*"
                  style={{ display: 'none' }}
                  multiple={false}
                />
              </div>
            )}
          </div>
          <input
            type="text"
            value={cardText}
            onChange={({ target: { value } }) => {
              setcardText(value);
            }}
            onBlur={() => handleBlur()}
            placeholder={t('widgets.widgets_edit.description')}
            className="w-full border-0 border-b border-transparent p-1 text-sm !shadow-none !ring-0 focus:border-0 focus:border-b focus:border-grey-02"
          />
        </div>
        {!leftCard && <DragHandle className="w-6 min-w-6 text-grey-04" />}
      </div>
    </div>
  );
};

export const ConnectEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : false
  );
  const [randomOrder, setRandomOrder] = useState(
    state.randomOrder !== undefined ? state.randomOrder : true
  );
  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.link_question')
  );
  const [score, setScore] = useState(state.score || 0);

  const parent = createRef();
  const left = createRef();
  const right = createRef();

  const [leftItems, setLeftItems] = useState(state?.left);
  const [rightItems, setRightItems] = useState(state?.right);
  const [connections, setConnections] = useState(state?.connections);

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoadingAI, setIsLoadingAI] = useState(false);
  const [competencies, setCompetencies] = useState([]);

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.left = leftItems;
    newState.right = rightItems;
    newState.score = score;
    newState.randomOrder = randomOrder;
    newState.connections = connections;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;

    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  const FunUniqueAnswer = () => {
    let connectionsPerLeft = {};

    leftItems.map((leftitems) => {
      connectionsPerLeft[leftitems.uid] = 0;
    });
    for (let connection of connections) {
      if (connectionsPerLeft[connection[0]] !== undefined)
        connectionsPerLeft[connection[0]] += 1;
      if (connectionsPerLeft[connection[1]] !== undefined)
        connectionsPerLeft[connection[1]] += 1;
    }

    let uniqueAnswer = true;
    for (let uid of Object.keys(connectionsPerLeft)) {
      if (connectionsPerLeft[uid] > 1) uniqueAnswer = false;
    }
    // setUniqueAnswer(uniqueAnswer);
    return uniqueAnswer;
  };

  let ResizeObserverFn = () => {
    let corConnections = connections;
    const myParent = parent.current;

    try {
      document.querySelector(`#svg_${state.uid}`).remove();
    } catch (e) {
      /*ignore*/
    }

    const svg = d3
      .select(myParent)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('id', `svg_${state.uid}`)
      .style('position', 'absolute');

    const connectPath = (p1, p2) => {
      const midX = p1[0] + (p2[0] - p1[0]) / 2;
      return `M${p1[0]},${p1[1]}C${midX},${p1[1]},${midX},${p2[1]},${p2[0]},${p2[1]}`;
    };

    const connect = (uid1, uid2) => {
      let newConnection = [uid1, uid2].sort();
      if (corConnections.findIndex((x) => v2equal(x, newConnection)) !== -1)
        return; //no duplicates
      corConnections.push(newConnection);
      corConnections = corConnections.map((x) => x.sort());
      drawConnection(newConnection);
      setConnections(corConnections);
    };

    const mousemove = (ev) => {
      if (!mousedown_node) return;
      let svgRect = myParent.getBoundingClientRect();

      const pX = ev.clientX || ev.touches[0].clientX;
      const pY = ev.clientY || ev.touches[0].clientY;

      //calculate mousePos relative to svg
      let mousePos = [pX - svgRect.left, pY - svgRect.top];
      drag_line.attr('d', connectPath(mousedown_node.circlePos, mousePos));
    };

    const mouseup = (ev) => {
      if (!mousedown_node) return;
      document.onmousemove = undefined;
      document.onmouseup = undefined;
      document.ontouchend = undefined;
      document.ontouchmove = undefined;

      let conn0 = document.querySelector(`.conn_${mousedown_node.uid}`);
      if (!conn0) {
        //nothing connected to mousedown_node, hide the dot
        document
          .querySelector(`#dot_${state.uid}_${mousedown_node.uid}`)
          .classList.add('hidden');
      }
      drag_line.classed('hidden', true);

      //we handle touch end here
      let quit = ev.changedTouches;
      if (quit) {
        var elem = document.elementFromPoint(quit[0].clientX, quit[0].clientY);
        if (elem) {
          let splitted = elem.id.split('_');
          if (
            (splitted[0] === 'circle' || splitted[0] === 'dot') &&
            elem.classList.contains('right')
          ) {
            connect(mousedown_node.uid, splitted[1]);
          }
        }
      }
      setConnections(corConnections);
      mousedown_node = null;
    };

    // line displayed when dragging new nodes
    const drag_line = svg
      .append('svg:path')
      .attr('id', 'drag_line')
      .attr('stroke', 'black')
      .style('stroke-width', '2')
      .attr('fill', 'transparent')
      .attr('class', 'link dragline hidden');

    let mousedown_node = null;

    const calcCirclePos = (node, align) => {
      const pos = relativePos(node, myParent);
      return [
        align === 'left' ? pos.left : pos.left + pos.width,
        pos.top + pos.height / 2,
      ];
    };

    //draws the little circle
    const renderNode = (uid, align, color = '#919193', className) => {
      let node = document.querySelector(
        `#${CSS.escape(state.uid)} .${CSS.escape(uid)}`
      );
      //let node = document.querySelector(`#${CSS.escape(uid)}`);
      let strokeColor;

      if (!node) return;

      strokeColor = color;

      const circlePos = calcCirclePos(node, align, color);
      const g = svg
        .append('svg:g')
        .attr('ondragstart', 'return false')
        .attr('user-select', 'none')
        .attr('transform', `translate(${circlePos[0]}, ${circlePos[1]})`);

      const circle = g
        .append('svg:circle')
        .attr('id', `circle_${state.uid}_${uid}`)
        .attr('class', `node ${className}`)
        .attr('r', 8)
        .style('fill', 'white')
        .style('stroke-width', '2')
        .style('stroke', strokeColor)
        .on('mouseover', function (d) {
          //hover effect
          d3.select(this).attr('transform', 'scale(1.1)');
          // .style('stroke', 'red');
        })
        .on('mouseout', function (d) {
          //hover effect
          d3.select(this).attr('transform', '');
        });

      g.append('svg:circle')
        .style('fill', strokeColor)
        .attr('class', `hidden ${className}`)
        .attr('id', `dot_${state.uid}_${uid}`)
        .style('pointer-events', 'none')
        .attr('r', 5);

      circle.circlePos = circlePos;
      circle.uid = uid;
      circle.color = strokeColor;
      return circle;
    };

    let leftNodes = {},
      rightNodes = {};
    leftItems.forEach((x, i) => {
      let node = renderNode(
        x.uid,
        'right',
        pastels[i % pastels.length],
        'left'
      );

      if (!node) return;
      leftNodes[node.uid] = node;
      node
        .on('mousedown touchstart', function (d) {
          FunUniqueAnswer();

          //pick up line
          d.preventDefault();
          mousedown_node = node;
          document
            .querySelector(`#dot_${state.uid}_${node.uid}`)
            .classList.remove('hidden');

          // reposition drag line
          drag_line
            .style('marker-end', 'url(#end-arrow)')
            .attr('stroke', node.color)
            .classed('hidden', false);

          document.onmousemove = mousemove;
          document.onmouseup = mouseup;
          document.ontouchmove = mousemove;
          document.ontouchend = mouseup;

          mousemove(d);
        })
        .on('mouseup touchend', function (d) {
          mouseup(d);
        });
    });

    //draw the line between the nodes
    const drawConnection = (connection, corColor) => {
      try {
        const con0 = document.querySelector(
          `#circle_${state.uid}_${connection[0]}`
        );
        const con1 = document.querySelector(
          `#circle_${state.uid}_${connection[1]}`
        );

        if (!con0 || !con1) return false;
        const con0Box = con0.getBoundingClientRect();
        const con1Box = con1.getBoundingClientRect();

        let svgRect = myParent.getBoundingClientRect();

        //calculate center of circles relative to parent
        const centerLeft = [
          con0Box.left + con0Box.width / 2 - svgRect.left,
          con0Box.top + con0Box.height / 2 - svgRect.top,
        ];
        const centerRight = [
          con1Box.left + con1Box.width / 2 - svgRect.left,
          con1Box.top + con1Box.height / 2 - svgRect.top,
        ];
        let computedStyles;

        if (con0.classList.contains('left')) {
          computedStyles = window.getComputedStyle(con0);
        } else {
          computedStyles = window.getComputedStyle(con1);
        }

        document
          .querySelector(`#dot_${state.uid}_${connection[0]}`)
          .classList.remove('hidden');
        document
          .querySelector(`#dot_${state.uid}_${connection[1]}`)
          .classList.remove('hidden');

        svg
          .insert('svg:path', '#drag_line') //connect behind the dots
          .attr(
            'stroke',
            corColor ? corColor : computedStyles.getPropertyValue('stroke')
          )
          // .attr('stroke', 'transparent')
          .attr('fill', 'transparent')
          .attr('data-con0', connection[0])
          .style('stroke-width', '2')
          .attr('data-con1', connection[1])
          .attr(
            'class',
            `link dragline conn_${state.uid}_${connection[1]} conn_${state.uid}_${connection[0]}`
          )
          .attr('d', connectPath(centerLeft, centerRight));
      } catch (e) {
        console.error(e);
        return false;
      }
      return true;
    };

    const removeConnection = (connection) => {
      corConnections = corConnections.filter((x) => !v2equal(x, connection));
      //setConnections(corConnections);

      //hides the dot if no connections are connected to the endpoints
      let conn0 = document.querySelector(`.conn_${state.uid}_${connection[0]}`);
      let conn1 = document.querySelector(`.conn_${state.uid}_${connection[1]}`);
      if (!conn0) {
        document
          .querySelector(`#dot_${state.uid}_${connection[0]}`)
          ?.classList?.add('hidden');
      }
      if (!conn1) {
        document
          .querySelector(`#dot_${state.uid}_${connection[1]}`)
          ?.classList?.add('hidden');
      }
    };

    rightItems.forEach((x) => {
      let node = renderNode(x.uid, 'left', '#919193', 'right');
      if (!node) return;
      rightNodes[node.uid] = node;
      node
        .on('mouseup', function (d) {
          //drop line (connect)
          let newConnection = [mousedown_node.uid, node.uid].sort();
          if (corConnections.findIndex((x) => v2equal(x, newConnection)) !== -1)
            return; //no duplicates
          corConnections.push(newConnection);
          corConnections = corConnections.map((x) => x.sort());

          drawConnection(newConnection);

          setConnections(corConnections);
          //mouseup is executed after this to clean up the drag line
        })
        .on('mousedown touchstart', function (d) {
          //pick up existing line from endpoint
          let conns = document.querySelectorAll(
            `.conn_${state.uid}_${node.uid}`
          );
          if (conns.length === 0) return;
          d.preventDefault();

          //remove the last added line
          let toRemoveLine = conns[conns.length - 1];
          let lastLeftNode =
            leftNodes[toRemoveLine.dataset.con0] ||
            leftNodes[toRemoveLine.dataset.con1];
          toRemoveLine.remove();
          removeConnection([
            toRemoveLine.dataset.con0,
            toRemoveLine.dataset.con1,
          ]);

          //Start new drag from lastLeftNode
          document
            .querySelector(`#dot_${state.uid}_${lastLeftNode.uid}`)
            .classList.remove('hidden');
          mousedown_node = lastLeftNode;
          drag_line
            .classed('hidden', false)
            .attr('stroke', mousedown_node.color);

          document.onmousemove = mousemove;
          document.onmouseup = mouseup;
          document.ontouchmove = mousemove;
          document.ontouchend = mouseup;
        })
        .on('mouseover', function (d) {
          document.querySelector(
            `#circle_${state.uid}_${node.uid}`
          ).style.stroke = mousedown_node?.color;
          document.querySelector(`.${CSS.escape(node.uid)}`).style.borderColor =
            mousedown_node?.color;
        })
        .on('mouseout', function (d) {
          document.querySelector(
            `#circle_${state.uid}_${node.uid}`
          ).style.stroke = node?.color;
          document.querySelector(`.${CSS.escape(node.uid)}`).style.borderColor =
            '#e5e7eb';
        });
    });

    const toRemove = [];
    for (let connection of connections) {
      if (!drawConnection(connection)) {
        toRemove.push(connection);
      }
    }
    for (let connection of toRemove) {
      try {
        removeConnection(connection);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useResizeObserver(parent, ResizeObserverFn);
  //useResizeObserver(left, ResizeObserverFn)
  //useResizeObserver(right, ResizeObserverFn)

  /*
  useEffect(() => {
    let parent = parent.current;
    let myLeft = left.current;
    let myRight = right.current;

    let corConnections = connections;

    const observer = new ResizeObserver();
    parent && observer.observe(parent);
    myLeft && observer.observe(myLeft);
    myRight && observer.observe(myRight);
  }, [parent.current, leftItems, rightItems]);
  */

  const handeleAddLeftOpion = () => {
    setLeftItems((prev) => [
      ...prev,
      { uid: newUid(20), text: '', file: null },
    ]);
  };

  const handeleAddRightOpion = () => {
    setRightItems((prev) => [
      ...prev,
      { uid: newUid(20), text: '', file: null },
    ]);
  };

  const Container = ({ items, setItems, leftCard }) => {
    {
      const moveCard = useCallback((dragIndex, hoverIndex) => {
        let sliced = items
          .slice(0, dragIndex)
          .concat(items.slice(dragIndex + 1));
        let newOrderQuestions = [
          ...sliced.slice(0, hoverIndex),
          items[dragIndex],
          ...sliced.slice(hoverIndex, sliced.length),
        ];
        setItems(newOrderQuestions);
      }, []);

      const renderCard = useCallback((card, index) => {
        return (
          <CardConnect
            key={index}
            index={index}
            card={card}
            leftCard={leftCard}
            setItems={setItems}
            items={items}
            moveCard={moveCard}
            setConnections={setConnections}
            connections={connections}
          />
        );
      }, []);

      return (
        <DndProvider backend={HTML5Backend}>
          {items?.map((card, i) => {
            return renderCard(card, i);
          })}
        </DndProvider>
      );
    }
  };

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />

      {isLoadingAI ? (
        <div className="spinner-chat mt-3">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      ) : (
        <>
          <div className="">
            {/* <span className="text-lg font-semibold text-grey-04">
          {t('widgets.widgets_edit.question')} (
          {t('widgets.widgets_edit.optional')})
        </span> */}
            <div className="wg-question">
              <QuestionSection questionVraag={questionVraag} />
            </div>
            <div className="my-4 flex gap-3">
              <Switch
                checked={showAnswer}
                onChange={(isCheck) => setShowAnswer(isCheck)}
              />
              <div className="mr-4">
                {t('widgets.widgets_edit.explain_after_submit')}
              </div>
            </div>
            <div className={`${!showAnswer ? 'hidden' : ''}`}>
              {/* <span className="text-lg font-semibold text-grey-04">
            {t('widgets.widgets_edit.example_answer')} (
            {t('widgets.widgets_edit.optional')})
          </span> */}
              <div className="wg-answer">
                <QuestionSection
                  questionVraag={antwoordVraag}
                  exampleAnswer={true}
                />
              </div>
            </div>
          </div>
          <div className="relative flex flex-row justify-between" ref={parent}>
            <div className="my-auto flex h-full min-w-[40%] max-w-[35%] flex-col gap-2">
              <Container
                ref={left}
                items={leftItems}
                setItems={setLeftItems}
                leftCard={true}
              />
              <div className="relative z-[2] flex w-full max-w-[250px] flex-col gap-3 rounded-lg border border-grey-02 bg-white p-5">
                <div className="flex">
                  <IconButton
                    Icon={Plus}
                    ariaLabel="Search"
                    onClick={() => handeleAddLeftOpion()}
                    variant="secondary"
                    className="m-auto !text-grey-07"
                    size="lg"
                  />
                </div>
                <p className="text-center text-grey-08">
                  {t('widgets.widgets_edit.add_option')}
                </p>
              </div>
            </div>

            <div className="my-auto flex h-full min-w-[40%] max-w-[35%] flex-col gap-2">
              <Container
                ref={right}
                items={rightItems}
                setItems={setRightItems}
                leftCard={false}
              />
              <div className="relative z-[2] ml-auto flex w-full max-w-[250px] flex-col gap-3 rounded-lg border border-grey-02 bg-white p-5">
                <div className="flex">
                  <IconButton
                    Icon={Plus}
                    ariaLabel="Search"
                    onClick={() => handeleAddRightOpion()}
                    variant="secondary"
                    className="m-auto !text-grey-07"
                    size="lg"
                  />
                </div>
                <p className="text-center text-grey-08">
                  {t('widgets.widgets_edit.add_option')}
                </p>
              </div>
            </div>
            {!isDesktop && <CustomCardConnect />}
          </div>
          <div
            contentEditable={false}
            ref={(el) => {
              if (el) {
                el.style.setProperty('display', 'none', 'important');
              }
            }}
            id={`${state?.uid}_gpt_open`}
            onClick={() => {
              setIsChatOpen(!isChatOpen);
            }}
          ></div>
        </>
      )}

      {isChatOpen && (
        <ConnectWidgetGPT
          setQuestionVraag={setQuestionVraag}
          setIsLoadingAI={setIsLoadingAI}
          isLoadingAI={isLoadingAI}
          setIsChatOpen={setIsChatOpen}
          stateUid={state?.uid}
          questionVraag={questionVraag}
          state={state}
          connections={connections}
          setConnections={setConnections}
          rightItems={rightItems}
          setRightItems={setRightItems}
          leftItems={leftItems}
          setLeftItems={setLeftItems}
        />
      )}
      <div>
        <div className="mt-4 flex flex-col border-t border-b border-grey-02 py-4 sm:py-2">
          <div className="flex flex-row items-center">
            <div className="mr-2">{t('widgets.widgets_edit.max_score')}</div>
            <InputScore value={score} onChange={(value) => setScore(value)} />
          </div>
          <div className="mt-4 flex flex-row items-center border-t border-grey-02 pt-4 sm:mt-2 sm:pt-2">
            <Switch
              checked={randomOrder}
              onChange={(isCheck) => {
                setRandomOrder(isCheck);
              }}
            />
            <div className="mr-4 px-4">{t('widgets.edit.order_message')}</div>
          </div>
        </div>
        {/*
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>

      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { newUid } from '@/util';
import { initMath } from '../MathView';
import { axios } from '@/axios';
import { config } from '@/config';

const OptionsChat = ({ userId, setActiveContinue, activeContinue }) => {
  const { t } = useTranslation();
  const [selectedText, setSelectedText] = useState();
  const [selected, setSelected] = useState();
  const [isLoading, setIsLoading] = useState();
  const [replacementText, setReplacementText] = useState();
  const [partialAnswer, setPartialAnswer] = useState();
  const [activeText, setActiveText] = useState();
  const [uidChat, setUidChat] = useState();
  const [prevContinue, stePrevContinue] = useState();
  const [continueState, setContinueState] = useState(false);
  const [prevState, setPrevState] = useState();

  const handleDocumentMouseUp = () => {
    var selection = window.getSelection();
    const text = window.getSelection().toString();
    if (text) {
      setSelected(window.getSelection().getRangeAt(0));
      setSelectedText(text);

      const range = selection.getRangeAt(0);
      var container = document.createElement('span');
      container.appendChild(range.cloneContents());
      var selectedHTML = container.innerHTML;
      stePrevContinue(selectedHTML);
    }
  };

  const handleButtonClick = (param) => {
    setPartialAnswer('');
    setReplacementText('');
    setUidChat(newUid(20));
    setContinueState(false);
    handleSubmit(param);
    if (selected) {
      const htmlCode =
        '<div class="spinner-chat">' +
        '<div class="bounce1"></div>' +
        '<div class="bounce2"></div>' +
        '<div class="bounce3"></div>' +
        ' </div>';
      const newRange = document.createRange();
      newRange.setStart(selected.startContainer, selected.startOffset);
      newRange.setEnd(selected.endContainer, selected.endOffset);
      selected.deleteContents();
      const newNode = newRange.createContextualFragment(htmlCode);
      selected.insertNode(newNode);
    } else {
      if (activeContinue) {
        const el = document.getElementById(activeContinue.id);
        el.innerHTML =
          '<div class="spinner-chat">' +
          '<div class="bounce1"></div>' +
          '<div class="bounce2"></div>' +
          '<div class="bounce3"></div>' +
          ' </div>';
        setActiveText(el.innerHTML);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleDocumentMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };
  }, []);

  const myFunction = () => {
    setSelected();
    setSelectedText();
  };

  useEffect(() => {
    document.addEventListener('mousedown', myFunction);

    return () => {
      document.removeEventListener('mousedown', myFunction);
    };
  }, []);

  useEffect(() => {
    if (activeContinue) {
      setActiveText(activeContinue?.text);
    }
  }, [activeContinue]);

  const renderTextWithMath = (replacementText) => {
    const parts = replacementText.split(/\\\(|\\\)|\\\[|\\\]|\$|\$/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return <span key={index} className="math" data-math={part}></span>;
      }
      return <span key={index}>{part}</span>;
    });
  };

  useEffect(() => {
    if (selected && replacementText) {
      selected.deleteContents();
      const newSpan = document.createElement('span');
      newSpan.id = uidChat;
      const newText = renderTextWithMath(replacementText);
      let container = '';

      newText.forEach((element) => {
        const el = document.createElement(element.type);
        el.innerHTML = element.props.children || '';
        el.className = element.props.className || '';
        el.setAttribute('data-math', element.props['data-math'] || '');
        container = container + el.outerHTML;
      });

      const htmlString = container;

      if (prevContinue && continueState) {
        newSpan.innerHTML = prevContinue + htmlString;
      } else {
        newSpan.innerHTML = htmlString;
      }
      selected.insertNode(newSpan);
      setSelectedText(replacementText);
      setActiveContinue({
        id: uidChat,
        text: replacementText,
      });
    } else {
      if (activeContinue && replacementText && prevState) {
        const el = document.getElementById(activeContinue?.id);

        const prevText = renderTextWithMath(prevState);
        let prevContainer = '';
        prevText.forEach((element) => {
          const el = document.createElement(element.type);
          el.innerHTML = element.props.children || '';
          el.className = element.props.className || '';
          el.setAttribute('data-math', element.props['data-math'] || '');
          prevContainer = prevContainer + el.outerHTML;
        });

        const newText = renderTextWithMath(replacementText);
        let newContainer = '';
        newText.forEach((element) => {
          const el = document.createElement(element.type);
          el.innerHTML = element.props.children || '';
          el.className = element.props.className || '';
          el.setAttribute('data-math', element.props['data-math'] || '');
          newContainer = newContainer + el.outerHTML;
        });

        el.innerHTML = `<span id=${uidChat}>${prevContainer}${newContainer}</span>`;

        setActiveContinue({
          id: uidChat,
          text: replacementText,
        });
      }
    }
    initMath().then(() => {
      const mathElements = document.querySelectorAll('.math');
      mathElements.forEach((element) => {
        const mathExpression = element.getAttribute('data-math');
        window.katex.render(mathExpression, element);
      });
    });
  }, [replacementText]);

  async function handleSubmit(param) {
    setIsLoading(true);
    setContinueState(param);
    if (param) {
      if (!selectedText) {
        setPrevState(activeText);
      }
    }
    try {
      const dataOpenAI = await axios.post(`${config.backend}/openai`, {
        messages: [
          {
            role: 'system',
            content: param
              ? t('chat_gpt.system_continue')
              : t('chat_gpt.system_explain'),
          },
          {
            role: 'user',
            content: param
              ? selectedText
                ? selectedText
                : activeText
              : selectedText,
          },
        ],
        model: 'gpt-4-1106-preview',
        temperature: 0.7,
        max_tokens: 3500,
        user: `${userId}`,
      });

      const fullAnswer = param
        ? selectedText
          ? dataOpenAI?.data?.choices[0]?.message?.content || ''
          : dataOpenAI?.data?.choices[0]?.message?.content || ''
        : dataOpenAI?.data?.choices[0]?.message?.content || '';
      for (let i = 0; i < fullAnswer.length; i += 10) {
        const part = fullAnswer.substring(i, i + 10);
        setPartialAnswer((prev) => prev + part);
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      setIsLoading(false);
    } catch (e) {
      alert('Error: ', e);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (partialAnswer) {
      setReplacementText(partialAnswer);
    }
  }, [partialAnswer]);

  return (
    <div className="flex flex-col gap-3">
      <h4>Options</h4>
      <button
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        disabled={(selectedText || activeText) && !isLoading ? false : true}
        onClick={() => handleButtonClick(true)}
        className="flex w-max items-center gap-2 rounded-2xl bg-secondary-01 py-1 px-3 text-sm text-secondary-04 disabled:bg-grey-02 disabled:text-grey-04"
      >
        <img
          src="/images/icons/continue.svg"
          alt="continue button"
          className={selectedText && !isLoading ? '' : 'opacity-40 contrast-0'}
        />
        {t('chat_gpt.continue_btn')}
      </button>
      <button
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        disabled={selectedText && !isLoading ? false : true}
        className="flex w-max items-center gap-2 rounded-2xl bg-secondary-01 py-1 px-3 text-sm text-secondary-04 disabled:bg-grey-02 disabled:text-grey-04"
        onClick={() => handleButtonClick(false)}
        contentEditable={false}
      >
        <img
          src="/images/icons/explain.svg"
          alt="explain button"
          className={selectedText && !isLoading ? '' : 'opacity-40 contrast-0'}
        />
        {t('chat_gpt.explain_btn')}
      </button>
    </div>
  );
};
export default OptionsChat;

import { useTranslation } from 'react-i18next';
import { useEffect, useContext, useState } from 'react';
import { IconButton, Button, Spinner } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';
import { Coursedetails } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import StepsComponent from './StepsComponent';
import { useNavigate } from 'react-router-dom';
import { nthIndexOf } from '@/util';

const AddCourse = ({
  activeCourse,
  setActiveCourse,
  setIsOpenAddContent,
  setIsOpenAddContentModal,
  teamInfo,
  student,
  activeCourseType,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const [course, setCourse] = useState<Coursedetails | null>(null);
  const [courseInfo, setCourseInfo] = useState<any>(null);

  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [preloader, setPreloader] = useState(false);
  const [publicationType, setPublicationType] = useState('course');

  useEffect(() => {
    if (navigateTo) {
      setPreloader(false);
      navigate(navigateTo);
    }
  }, [navigateTo]);

  const navigate = useNavigate();

  const authUser = auth?.user;

  useGet(
    activeCourseType !== 'instructions'
      ? `/publish/lastversion/${activeCourse}`
      : `/coursedetail/${activeCourse}`,
    {
      courseId: activeCourseType !== 'instructions' && activeCourse,
      onSuccess: (result: any) => {
        setCourse(result?.data || null);
        setPublicationType(result?.data?.publicationCourseType || 'course');
      },
    }
  );

  useGet<any>(`/courseinfo/${activeCourse}`, {
    enabled: activeCourseType === 'instructions',
    onSuccess: (result: any) => {
      setCourseInfo(result?.data);
    },
  });

  const basePath = window.location.pathname.slice(
    0,
    nthIndexOf(window.location.pathname, '/', 3)
  );

  // if (!course) return null;

  return (
    <div className="fixed top-0 left-0 z-[100] h-screen w-screen overflow-y-auto bg-white">
      {preloader && (
        <div className="fixed inset-0 z-[11] flex bg-white/75">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-16 !w-16 border-grey-08"
            />
          </div>
        </div>
      )}
      <div className="flex min-h-20 flex-col justify-start py-2 pr-2 sm:flex-row sm:items-center">
        <div
          data-testid="clouseBtn"
          className="flex min-h-20 items-center justify-start"
        >
          <IconButton
            className="my-auto mx-3 sm:mx-6"
            Icon={CrossFilled}
            variant="ghost"
            onClick={() => {
              setActiveCourse && setActiveCourse();
              setIsOpenAddContent(false);
            }}
          />
          <div className="flex items-center">
            <div className="mr-5 w-16 min-w-16 sm:w-20 sm:min-w-20">
              <img
                src={course?.image || '/images/courseFallback.png'}
                alt="courseFallback"
                className="inline h-12 w-full rounded-lg object-cover sm:h-14"
              />
            </div>
            <div className="flex flex-wrap">
              <span className="pr-2">
                {t('pages.course_content_flow.header_title')}
              </span>
              <span className="font-semibold">{course?.name}</span>
            </div>
          </div>
        </div>

        <div className="my-2 ml-12 sm:my-0 sm:ml-auto sm:px-3">
          <Button
            onClick={() => {
              setActiveCourse();
              setIsOpenAddContent(false);
              setIsOpenAddContentModal(true);
            }}
            variant="ghost"
            size="sm"
          >
            Change course
          </Button>
        </div>
      </div>
      {course && (
        <StepsComponent
          create={course?.publicationCourseType !== 'instruction'}
          courseInfo={courseInfo}
          authUser={authUser}
          course={course}
          teamInfo={teamInfo}
          student={student}
          activeCourse={activeCourse}
          setIsOpenAddContent={setIsOpenAddContent}
          setActiveCourse={setActiveCourse}
          onSubmit={(course: any) => {
            setNavigateTo(
              `${basePath}/${activeCourseType}/details/${course.id}`
            );
            //setIsOpenAddContent(false);
          }}
          setLoader={setPreloader}
          publicationType={publicationType}
        />
      )}
    </div>
  );
};

export default AddCourse;

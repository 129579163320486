import { useState, useContext, useEffect, useRef } from 'react';
import LearnLesson from './LessonPage';
import { XAPIService } from '@/services/xapi.service';
import Cmi5, { LaunchParameters } from '@xapi/cmi5';
import {
  parseJwt,
  useStateAndRef,
  useTraceUpdate,
  parseQueryString,
} from '@/util';
import { NavContext } from '@/components/NavContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { useNavigate, useMatches, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import XAPI from '@xapi/xapi';

//this component will login with the xapi info and then redirect
function Scorm() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const queries: any = parseQueryString(document.location.search);
    const token = queries.token;

    const simplified: any = {
      actor: queries.actor,
      token,
    };

    axios
      .post(config.teologin + '/xapi', simplified, {
        withCredentials: true,
      })
      .then((result) => {
        const redirectUrl = new URL(window.location.href);

        const redirect_uri = encodeURIComponent(redirectUrl.toString()).replace(
          '%2Fscorm',
          '%2Flesson'
        );

        const logintoken = result.data.token; //possible invite token

        //console.log("succesfully loggged in, data:", result.data)
        //console.log("succesfully loggged in, redirecting to", redirect_uri)

        const path = `${config.backend}/auth?idp=local&lang=${i18n.language}&redirect_uri=${redirect_uri}&logintoken=${logintoken}`; //`s
        window.location.replace(path);
      })
      .catch((e) => {
        console.error(e);
        const error = {
          status: 'xapi_login_failed',
        };
        navigate(`/error?error=${encode(JSON.stringify(error))}`);
      });
  }, []);

  return <></>;
}

export default Scorm;

import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, Import2 } from '@teo/components/icons';
import { createNewPublication } from '../../constants/sidebar.constants';
import { Button } from '@teo/components';
import { NavContext } from '@/components/NavContext';
import Hint from '../Hints/Hints';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { CreateNewImport } from './CreateNewImport';

const CreateNewPublication = ({
  setIsOpenTypeModal,
  isMobile,
  setTypeCourse,
  authUser,
}: any) => {
  const { t } = useTranslation();
  const { hints } = useContext(HintsContext);
  const [isActive, setActive] = useState(false);
  const { collapsed, setCollapsed } = useContext(NavContext);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);

  const ref = useRef(null);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const useOutsideClick = (ref: any, callback: any) => {
    const handleClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };

  useOutsideClick(ref, () => {
    setActive(false);
  });

  const courseType = authUser?.useCourse;
  const assessmentType = authUser?.useAssessment;
  const vacancyType = authUser?.useLeervacature;
  const instructionType = authUser?.useInstruction;

  if (!courseType && !assessmentType && !vacancyType && !instructionType)
    return null;

  if (showImportModal) {
    return (
      <CreateNewImport setIsOpen={setShowImportModal} typeCourse="course" />
    );
  }

  return (
    <div
      className={`relative mb-3 w-full ${
        hints?.id == 'hint_25' &&
        'active-hint-parent active-hint-parent_sidebar !z-[100]'
      }`}
      ref={ref}
    >
      <button
        className="flex items-center gap-3 rounded-full px-7 py-2.5 shadow-[0_4px_12px_0_rgba(0,0,0,0.12)] hover:bg-grey-01"
        onClick={handleToggle}
      >
        <Hint
          key={'hint_25'}
          id={'hint_25'}
          leftDots={'-left-2 top-2.5'}
          title={t('hint.title_25')}
          order={25}
          style={{
            left: '110%',
            top: '-10px',
          }}
        />
        <Plus className="w-6 text-primary-05" />
        {t('pages.create_publication.new')}
      </button>
      {isActive && hints?.id != 'hint_25' && (
        <div
          className={`${
            !isMobile && 'absolute left-0 top-full'
          }  z-[10] mt-2 min-w-60 min-w-60 rounded-lg bg-white p-2 drop-shadow-m`}
        >
          <div className="flex flex-col gap-2">
            {createNewPublication?.map((el: any, index: any) => {
              const Icon = el.icon;
              if (!courseType && el?.type === 'courses') return null;
              if (!assessmentType && el?.type === 'assessments') return null;
              if (!vacancyType && el?.type === 'vacancies') return null;
              if (!instructionType && el?.type === 'instructions') return null;
              return (
                <Button
                  variant="ghost"
                  className="flex items-center gap-3"
                  key={index}
                  onClick={() => {
                    setTypeCourse(el?.to);
                    setIsOpenTypeModal(true);
                    isMobile && setCollapsed(!collapsed);
                    handleToggle();
                  }}
                >
                  <Icon className={'w-6 text-grey-04'} /> {t(el.text)}
                </Button>
              );
            })}
            <Button
              variant="ghost"
              className="flex items-center gap-3"
              onClick={() => {
                isMobile && setCollapsed(!collapsed);
                setShowImportModal(true);
              }}
            >
              <Import2 className="w-6 text-primary-05" />{' '}
              {t('pages.create_publication.import')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export { CreateNewPublication };

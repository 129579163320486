import { useState, useContext, useEffect } from 'react';
import { PageLayout } from '@/components/PageLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import BreadCrumbsHeader from '@/components/BreadCrumbsHeader';
import { NavContext } from '@/components/NavContext';
import { useMobile, parseQueryString, parseCourseType } from '@/util';
import { helpSidebar } from '../../constants/sidebar.constants';
import SidebarNavigation from '@/components/SidebarNavigation';
import TypeCourse from '@/components/CreateNewPublication/TypeCourse';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import i18n from 'i18next';

function CreatePage() {
  const { collapsed, navDisabled, submenu } = useContext(NavContext);
  const { auth } = useContext(AuthContext);

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };

  const isMobile = useMobile();
  const showNav = !navDisabled && ((submenu && !collapsed) || !isMobile);
  const showContent = collapsed || !isMobile;
  const [isOpenTypeModal, setIsOpenTypeModal] = useState(
    !!queries['courseType']
  );
  const [template, setTemplate] = useState('theory');
  const [typeCourse, setTypeCourse] = useState(
    parseCourseType(queries['courseType'] || 'course')
  );

  //insufficiënt role redirect
  const authUser = auth?.user as Userwithorganisationwithidps;
  const navigate = useNavigate();

  /*
  useEffect(() => {
    const role = Math.max(
      authUser?.activeOrganisationRole || 0,
      authUser?.role || 0
    );
    if (role <= 20) {
      navigate(`/${i18n.language}/noaccess`);
    }
  }, []);
  */
  //max-w-6xl max-[767px] max-w-[calc(100vw - 350px)]:
  if (navDisabled) return <Outlet />;

  let sidebar;
  if (
    Math.max(authUser?.role || 0, authUser?.activeOrganisationRole || 0) <= 20
  ) {
    sidebar = helpSidebar.filter((link) => link.to !== './tutorial');
  } else {
    sidebar = helpSidebar;
  }

  return (
    <PageLayout>
      {showNav && (
        <PageLayout.Sidebar>
          <SidebarNavigation items={sidebar} />
        </PageLayout.Sidebar>
      )}
      <div
        className={`${
          showContent ? '' : 'hidden'
        } min-h-[100vh] w-full md:max-w-[calc(100vw-272px)] lg:min-h-[calc(100vh_-_150px)] lg:max-w-6xl`}
      >
        {!isOpenTypeModal ? (
          <PageLayout.Content>
            <BreadCrumbsHeader />
            <Outlet />
          </PageLayout.Content>
        ) : (
          <TypeCourse
            setIsOpenTypeModal={setIsOpenTypeModal}
            setTemplate={setTemplate}
            template={template}
            typeCourse={typeCourse}
          />
        )}
      </div>
    </PageLayout>
  );
}

export default CreatePage;

import React, { Component, useEffect, useState, useContext } from 'react';
import { T } from '@/components/T';
import i18n from 'i18next';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { Eye, Trash } from '@teo/components/icons';
import {
  Panel,
  IconButton,
  InputSelect,
  InputText,
  Label,
  Button,
} from '@teo/components';
import FroalaTextAreaEdit from '@/components/Froala/FroalaTextAreaEdit';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { useStateAndRef } from '@/util';
import { config } from '@/config';
import { axios } from '@/axios';
import { ConfirmModal } from '@/components/ConfirmModal';
import { languages } from '@/constants/languages';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet } from '@/query/fetchHooks';

const UpdateEdit = () => {
  const { setShowNotification } = useContext(NotificationContext);
  const [ref, setRef, refRef] = useStateAndRef(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const t = i18n.t;
  const [selected, setSelected] = useState(null);
  const { updateId } = useParams();
  const navigate = useNavigate();

  const { refetch } = useGet(`/teoupdate/${updateId}`, {
    enabled: updateId !== 'new',
    onSuccess: (results) => {
      setSelected(results.data);
    },
  });

  useEffect(() => {
    if (updateId === 'new') {
      setSelected({
        lang: i18n.language,
        text: '',
      });
    }
  }, []);

  const saveContent = async () => {
    const content = await refRef.current.getContent();
    if (content === selected.text) return;
    const saveFn = selected.id
      ? axios.put.bind(this, `${config.backend}/teoupdate/${selected.id}`)
      : axios.post.bind(this, `${config.backend}/teoupdates`);
    await saveFn({
      lang: selected.lang,
      text: content,
    }).catch((e) => {
      setShowNotification(i18n.t('errors.network_error'));
    });
    await setSelected({
      ...selected,
      text: content,
    });
    setShowNotification(i18n.t('notifications.update_saved'));
  };

  if (!selected) return null;

  return (
    <div className="flex flex-col gap-4">
      {confirmDelete && (
        <ConfirmModal
          open
          title="pages.help.delete_update_title"
          description="pages.help.delete_update_description"
          onClose={() => setConfirmDelete(false)}
          onConfirm={async () => {
            try {
              await axios.delete(`${config.backend}/teoupdate/${selected.id}`);
            } catch (e) {
              console.error(e);
            }
            setConfirmDelete(false);
            setSelected({});
            navigate('../');
            setTimeout(() => {
              window.scrollTo(0, 0);
            });
          }}
        />
      )}
      <h1 className="mt-8 mb-4 flex justify-between text-xl font-semibold text-black">
        {i18n.t('pages.help.title')}
        <div>
          <IconButton
            Icon={Trash}
            variant="ghost"
            size="sm"
            onClick={async () => {
              setConfirmDelete(true);
            }}
          />
          <IconButton
            Icon={Eye}
            variant="ghost"
            size="sm"
            onClick={async () => {
              await saveContent();
              navigate('../');
            }}
          />
        </div>
      </h1>
      <div>
        <Label htmlFor="language">{t('pages.profile_account.language')}</Label>
        <div id="language">
          <InputSelect
            onChange={async (language) => {
              if (selected.id) {
                await axios.put(`${config.backend}/teoupdate/${selected.id}`, {
                  lang: language,
                });
              }
              setSelected({
                ...selected,
                lang: language,
              });
            }}
            value={selected.lang ? selected.lang : i18n.language}
            options={languages.map((lang) => lang.value)}
          >
            {(lang) => t(`langs.${lang}`)}
          </InputSelect>
        </div>
      </div>
      <div>
        <Label>{t('pages.help.update_text')}</Label>
        <div className="mt-1">
          <FroalaTextAreaEdit
            mode="edit"
            modus="lessonEdit"
            setRef={(ref) => {
              setRef(ref);
            }}
            placeholder={i18n.t('pages.help.update_text')}
            value={selected.text}
            style={{ minHeight: '350px' }}
            onSave={async () => {
              await saveContent();
            }}
          />
        </div>
      </div>
      <div>
        <Button
          onClick={async () => {
            await saveContent();
            navigate('../');
          }}
        >
          {t('button.save')}
        </Button>
      </div>
    </div>
  );
};

export default UpdateEdit;

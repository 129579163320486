import { useState, useEffect } from 'react';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { getCharForNumber } from '@/util';

export const pastels = [
  '#C25FF4',
  '#E3BF00',
  '#6EC6C0',
  '#F25850',
  '#29BAFF',
  '#AE7226',
  '#1173DD',
  '#9AD055',
  '#807EFF',
  '#FF8EA4',
];

export const ImageShowPartsView = ({
  state,
  index,
  onModified = undefined,
}) => {
  const { t } = useTranslation();

  const onLoadImg = () => {
    const canvas = document.getElementById(state.uid + '_canvas');
    const img = document.getElementById(state.uid + '_img');
    canvas.width = img.width * 5;
    canvas.height = img.height * 5;

    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 15;
    state.labels.forEach((x, index) => {
      ctx.beginPath();
      const startX = x.tx * canvas.width;
      const startY = x.ty * canvas.height;
      const endX = x.x * canvas.width;
      const endY = x.y * canvas.height;
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = pastels[index];
      ctx.stroke();
      ctx.closePath();
    });
  };

  return (
    <div
      className={`flex flex-col gap-4 rounded-lg bg-[#f8f8f9] p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
    >
      <FroalaTextareaView value={state.title} />

      <div>
        <div
          style={{ marginBottom: 30, position: 'relative' }}
          className="pane LinkWidget widget-item"
          id={state.uid + '_view'}
        >
          <div id={state.uid + '_view_container'}>
            <div className="relative">
              <img
                id={state.uid + '_img'}
                src={state.image}
                onLoad={onLoadImg}
                style={{
                  width: '100%',
                  cursor: 'inherit',
                  pointerEvents: 'none',
                }}
              />
              <canvas
                id={state.uid + '_canvas'}
                style={{
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                className="absolute"
              ></canvas>
              <div className={`block`}>
                {state.labels.map((x, index) => (
                  <div key={state.labels.text + index}>
                    <span
                      className="absolute h-3.5 w-3.5 rounded-full border-[3px]"
                      style={{
                        transform: 'translate(-50%, -50%)',
                        top: x.ty * 100 + '%',
                        left: x.tx * 100 + '%',
                        borderColor: `${pastels[index]}`,
                      }}
                    ></span>

                    <div
                      id={x.uid + '_view'}
                      style={{
                        top: x.y * 100 + '%',
                        left: x.x * 100 + '%',
                        transform: 'translate(-11%, -50%)',
                      }}
                      className="absolute"
                    >
                      <div key={state.labels.text + '_label'}>
                        <div
                          style={{
                            backgroundColor: `${pastels[index]}`,
                          }}
                          className="mr-2 rounded-md px-2.5 pb-1 pt-0.5 text-white"
                        >
                          {getCharForNumber(index)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-3 flex w-full flex-col gap-2">
              {state?.labels?.map((el, index) => {
                const htmlText = el?.text.replace(/\n/g, '<br />');
                return (
                  <div
                    key={el.text + '_' + index}
                    className="flex flex-row items-center "
                  >
                    <div
                      className="mr-2 rounded-md px-2.5 pb-1 pt-0.5 text-white"
                      style={{
                        backgroundColor: `${pastels[index]}`,
                      }}
                    >
                      {getCharForNumber(index)}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: htmlText }}
                      className="w-full rounded-lg bg-white p-2"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

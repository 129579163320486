import { parseFilter, parseCourseType } from '@/util';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonAvatar } from '@teo/components';
import { useNavigate } from 'react-router-dom';

export const SearchResult = ({
  authUser,
  filter,
  fetch,
  setActiveSearch,
  loadeResult,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [courses, setCourses] = useState([]);
  const [coursesCreate, setCoursesCreate] = useState([]);
  const [coursesFollow, setCoursesFollow] = useState([]);

  const result: any = fetch({
    count: 12,
    q: parseFilter(filter),
  });

  useEffect(() => {
    if (result?.data) {
      setData(result?.data?.data);
    }
  }, [result?.data]);

  useEffect(() => {
    if (data?.length > 0) {
      setIsEmpty(false);
      const searchUsers = data?.filter((el: any) => el.type === 'member');
      setUsers(searchUsers);
      const searchTeams = data?.filter((el: any) => el.type === 'team');
      setTeams(searchTeams);
      const searchCourse = data?.filter((el: any) => el.type === 'course');
      setCourses(searchCourse);
      const searchCoursecontent_create = data?.filter(
        (el: any) =>
          el.type === 'coursecontent' && el.subtype === 'coursecontent_create'
      );
      setCoursesCreate(searchCoursecontent_create);

      const searchCoursecontent_follow = data?.filter(
        (el: any) =>
          el.type === 'coursecontent' && el.subtype === 'coursecontent_follow'
      );
      setCoursesFollow(searchCoursecontent_follow);
    } else {
      setIsEmpty(true);
    }
  }, [data]);

  const goToPage = (item: any, ev: any) => {
    ev?.stopPropagation();
    if (item?.subtype === 'member') {
      navigate(
        `/${authUser?.lang}/follow-up/${authUser?.activeOrganisationId}/users/${item?.objectId}`
      );
      setActiveSearch(false);
      document.body.style.overflow = 'unset';
    }
    if (item?.subtype === 'team') {
      navigate(
        `/${authUser?.lang}/follow-up/${authUser?.activeOrganisationId}/teams/${item?.objectId}`
      );
      setActiveSearch(false);
      document.body.style.overflow = 'unset';
    }
    if (item?.type === 'course') {
      navigate(
        `/${authUser?.lang}/learn/${
          authUser?.activeOrganisationId
        }/${parseCourseType(item?.courseType)}/${item?.objectId}`
      );
      setActiveSearch(false);
      document.body.style.overflow = 'unset';
    }
    if (
      item.type === 'coursecontent' &&
      item.subtype === 'coursecontent_create'
    ) {
      navigate(
        `/${authUser?.lang}/create/${
          authUser?.activeOrganisationId
        }/${parseCourseType(item?.courseType)}/${item?.objectId}`
      );
      setActiveSearch(false);
      document.body.style.overflow = 'unset';
    }
    if (
      item.type === 'coursecontent' &&
      item.subtype === 'coursecontent_follow'
    ) {
      navigate(
        `/${authUser?.lang}/follow-up/${
          authUser?.activeOrganisationId
        }/${parseCourseType(item?.courseType)}/${item?.objectId}`
      );
      setActiveSearch(false);
      document.body.style.overflow = 'unset';
    }
  };

  return (
    <div className="flex min-h-24 flex-col">
      {isEmpty ? (
        <>
          {!loadeResult && (
            <div className="my-8 flex flex-col p-4 text-center">
              <img
                className="m-auto w-48"
                src="/images/components/notifications/theo-empty-inbox.svg"
              />
              <p className="pt-4 text-sm text-grey-06">
                {t('global_search.empty_result')}
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {users?.length > 0 && (
            <div className="flex flex-col gap-1 border-t border-grey-02 p-4">
              <p className="text-sm text-[#231F20]">{t('breadcrumbs.USERS')}</p>

              {users?.map((el: any) => {
                return (
                  <div
                    className="flex cursor-pointer items-center rounded-lg py-1 px-2 hover:bg-grey-01"
                    key={el?.id + '_' + el?.subtype}
                    onClick={(ev) => goToPage(el, ev)}
                  >
                    <PersonAvatar
                      inline
                      id={el.id}
                      name={el.name}
                      imageSrc={el.picture}
                    ></PersonAvatar>

                    <div>
                      <p className="text-sm text-[#231F20]">{el.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {teams?.length > 0 && (
            <div className="flex flex-col gap-1 border-t border-grey-02 p-4">
              <p className="text-sm text-[#231F20]">{t('breadcrumbs.TEAMS')}</p>

              {teams?.map((el: any) => {
                return (
                  <div
                    className="flex cursor-pointer items-center rounded-lg py-1 px-2 hover:bg-grey-01"
                    key={el?.id + '_' + el?.subtype}
                    onClick={(ev) => goToPage(el, ev)}
                  >
                    <PersonAvatar
                      inline
                      id={el?.id}
                      name={el?.name}
                      bgColor={el?.color ? el?.color : null}
                    ></PersonAvatar>

                    <p className="text-sm text-[#231F20]">{el.name}</p>
                  </div>
                );
              })}
            </div>
          )}
          {courses?.length > 0 && (
            <div className="flex flex-col gap-1 border-t border-grey-02 p-4">
              <p className="text-sm text-[#231F20]">{t('breadcrumbs.LEARN')}</p>

              {courses?.map((el: any) => {
                return (
                  <div
                    key={el?.id + '_' + el?.subtype}
                    onClick={(ev) => goToPage(el, ev)}
                    className="flex cursor-pointer items-center rounded-lg py-1 px-2 hover:bg-grey-01"
                  >
                    <div className="mr-3 w-16 min-w-16">
                      <img
                        src={el.picture || '/images/courseFallback.png'}
                        alt={el.name}
                        className="inline h-12 w-full rounded-lg object-cover"
                      />
                    </div>
                    <p className="text-sm text-[#231F20]">{el.name}</p>
                  </div>
                );
              })}
            </div>
          )}
          {coursesCreate?.length > 0 && (
            <div className="flex flex-col gap-1 border-t border-grey-02 p-4">
              <p className="text-sm text-[#231F20]">
                {t('breadcrumbs.CREATE')}
              </p>

              {coursesCreate?.map((el: any) => {
                return (
                  <div
                    key={el?.id + '_' + el?.subtype}
                    onClick={(ev) => goToPage(el, ev)}
                    className="flex cursor-pointer items-center rounded-lg py-1 px-2 hover:bg-grey-01"
                  >
                    <div className="mr-3 w-16 min-w-16">
                      <img
                        src={el.picture || '/images/courseFallback.png'}
                        alt={el.name}
                        className="inline h-12 w-full rounded-lg object-cover"
                      />
                    </div>
                    <p className="text-sm text-[#231F20]">{el.name}</p>
                  </div>
                );
              })}
            </div>
          )}
          {coursesFollow?.length > 0 && (
            <div className="flex flex-col gap-1 border-t border-grey-02 p-4">
              <p className="text-sm text-[#231F20]">
                {t('breadcrumbs.FOLLOW-UP')}
              </p>

              {coursesFollow?.map((el: any) => {
                return (
                  <div
                    key={el?.id + '_' + el?.subtype}
                    onClick={(ev) => goToPage(el, ev)}
                    className="flex cursor-pointer items-center rounded-lg py-1 px-2 hover:bg-grey-01"
                  >
                    <div className="mr-3 w-16 min-w-16">
                      <img
                        src={el.picture || '/images/courseFallback.png'}
                        alt={el.name}
                        className="inline h-12 w-full rounded-lg object-cover"
                      />
                    </div>
                    <p className="text-sm text-[#231F20]">{el.name}</p>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

import { Button, Tag } from '@teo/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

function UploadedPhoto({
  photoInfo,
  fileInputRef,
  handleUploadPhoto,
  uploadLogo,
  courseImg,
  bannerLPImg,
  setIsOpenLibraryModal,
}: any) {
  const { t } = useTranslation();

  if (!photoInfo && !uploadLogo) {
    return null;
  }

  return (
    <div
      className="
                flex
                flex-col
                items-start justify-between
                rounded-lg
                border border-[#eaeaed] p-3
                sm:flex-row sm:items-center
            "
    >
      <div
        className={`flex items-center ${
          (courseImg || bannerLPImg) &&
          'w-full flex-col !items-start sm:w-max sm:flex-row sm:!items-center'
        }`}
      >
        <img
          alt="logo"
          src={uploadLogo ? uploadLogo : URL.createObjectURL(photoInfo)}
          className={`mr-3 h-16 flex-[1_0_64px] rounded object-cover object-center ${
            courseImg &&
            ' h-44 w-full flex-[1_0_100%] sm:w-64 sm:flex-[1_0_64px] '
          } ${
            bannerLPImg &&
            'h-[96px] w-full flex-[1_0_100%] sm:w-[336px] sm:flex-[1_0_64px]'
          } `}
        />
        <div>
          {photoInfo && (
            <p
              className={`
                break-all
                text-base
                font-semibold
                sm:truncate
                ${courseImg || bannerLPImg ? 'w-[200px]' : 'sm:w-[250px]'}
                `}
            >
              {photoInfo.name}
            </p>
          )}
          {photoInfo && (
            <Tag
              title={`${photoInfo?.type}`}
              size="md"
              variant="document"
              documentType="image"
            />
          )}
        </div>
      </div>

      <div className="mr-2 mt-3 flex flex-col gap-3 sm:mt-0">
        <Button
          variant="outline"
          className=""
          onClick={() => setIsOpenLibraryModal(true)}
        >
          {t('pages.upload_dropzone_section.replace_from_library')}
        </Button>
        <Button
          variant="outline"
          size="md"
          onClick={() => {
            fileInputRef.current?.click();
          }}
          className="text-base font-semibold"
        >
          {courseImg || bannerLPImg
            ? t('pages.upload_dropzone_section.upload_button_img_2')
            : t('pages.upload_dropzone_section.upload_button_2')}
        </Button>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadPhoto}
          accept="image/*"
          style={{ display: 'none' }}
          multiple={false}
        />
      </div>
    </div>
  );
}

export default UploadedPhoto;

import { useTranslation } from 'react-i18next';
import { Button, FilterDropdown, InputText, Tag } from '@teo/components';
import { Search } from '@teo/components/icons';
import { useState } from 'react';
import { DataTable } from '@/components/DataTable/DataTable';
import { useGet } from '@/query';
import { useParams } from 'react-router-dom';
import { newUid } from '@/util';
import AllCompetenciesModal from '@/pages/Protected/Manage/Competencies/AllCompetenciesModal';
import TextWithTooltip from './TextWithTooltip';

const AllCompetencies = ({ categories, competencyType }: any) => {
  const { t } = useTranslation();
  const { organisationId } = useParams();

  const [filter, setFilter] = useState('');
  const [type, setType] = useState<any>();
  const [category, setCategory] = useState();
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [isOpenAllCompetenciesModal, setIsOpenAllCompetenciesModal] =
    useState(false);
  const [isAddModal, setIsAddModal] = useState<any>(true);
  const [isAllCompetenciesIndex, setIsAllCompetenciesIndex] =
    useState<string>('');

  return (
    <>
      <div className="w-[50%] py-8">
        <InputText
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          Icon={Search}
          placeholder={t('pages.manage_competencies.search_placeholder_2')}
        />
      </div>

      <div className="relative z-[1] mb-6 flex flex-col justify-between lg:flex-row lg:items-center">
        <div className="flex flex-row">
          <span className="pr-2">
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                setCategory(value);
              }}
              buttonText={`${t('pages.manage_competencies.all_categories')}`}
              options={categories}
              searchable={true}
              value={category}
            />
          </span>
          <span className="pr-2">
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={(value: any) => {
                setType(value);
              }}
              buttonText={`${t('pages.manage_competencies.all_types')}`}
              options={competencyType}
              searchable={false}
              value={type}
            />
          </span>
        </div>

        <Button
          onClick={() => {
            setIsOpenAllCompetenciesModal(true);
            setIsAddModal(true);
          }}
          size="sm"
          variant="outline"
          className="mt-6 hidden lg:mt-0"
        >
          {t('pages.manage_competencies.add_competence')}
        </Button>
      </div>

      <DataTable
        filter={filter}
        key={refetchTable}
        fetch={useGet.bind(null, '/qualifications', {
          type: type?.label,
          organisationId: `${organisationId},OR,organisationId=null`,
        })}
        empty={{
          template: 1,
          text: t('empty_tenplate.competencies_list'),
        }}
        columns={[
          {
            addTitleOnMobile: true,
            title: t('columns.category'),
            id: 'categoryId',
            className: 'col-start-1 col-end-6 break-all row-start-1',
            sortable: false,
            render: (item: any) => {
              const cat =
                item?.categoryId &&
                categories?.find((el: any) => el.id == item?.categoryId);
              return (
                <>
                  {cat ? (
                    cat?.name
                  ) : (
                    <p className="text-xs text-grey-05">
                      {t('pages.manage_competencies.category_no')}
                    </p>
                  )}
                </>
              );
            },
          },
          {
            addTitleOnMobile: true,
            title: t('columns.competence'),
            id: 'competence',
            className: 'col-start-1 col-end-10 row-start-2',
            classNameDesktop: 'max-w-[250px]',
            sortable: false,
            render: (item: any) => {
              return item.path.split(';;').length > 2 ? (
                <TextWithTooltip text={item.path.split(';;')[1]} />
              ) : (
                <TextWithTooltip text={item.path.split(';;')[0]} />
              );
            },
          },
          {
            addTitleOnMobile: true,
            title: t('columns.sub_competence'),
            id: 'subCompetence',
            className: 'col-start-1 col-end-10 row-start-3',
            classNameDesktop: 'max-w-[250px]',
            sortable: false,
            render: (item: any) => {
              let replacedString = item.path.replace(
                item.path.split(';;').length > 2
                  ? /^[^;]*;;([^;]+;;)/
                  : /^[^;]*;;/,
                ''
              );
              replacedString = replacedString.replace(/;;+$/, '');
              replacedString = replacedString.replace(/;;+/g, ' / ');
              return <TextWithTooltip text={replacedString} />;
            },
          },
          {
            addTitleOnMobile: true,
            id: 'type',
            title: t('columns.type'),
            sortable: false,
            className:
              'col-start-6 col-end-10 row-start-1 justify-end text-right',
            classNameDesktop: 'min-w-[90px]',
            render: (item: any) => {
              let typeLabel;
              let typeVariant = true;
              switch (item?.type) {
                case 'skill':
                  typeLabel = t('pages.manage_competencies.type_skill');
                  break;
                case 'attitude':
                  typeLabel = t('pages.manage_competencies.type_attitude');
                  break;
                case 'knowledge':
                  typeLabel = t('pages.manage_competencies.type_knowledge');
                  break;
                default:
                  typeLabel = t('pages.manage_competencies.type_no');
                  typeVariant = false;
                  break;
              }

              return (
                <Tag
                  title={typeLabel}
                  variant={typeVariant ? 'success' : 'error'}
                />
              );
            },
          },
        ]}
      />
      {isOpenAllCompetenciesModal && (
        <AllCompetenciesModal
          setIsOpenAllCompetenciesModal={setIsOpenAllCompetenciesModal}
          isOpenAllCompetenciesModal={isOpenAllCompetenciesModal}
          closeModal={() => setIsOpenAllCompetenciesModal(false)}
          onChange={() => setRefetchTable(newUid(20))}
          isAddModal={isAddModal}
          isTagIndex={isAllCompetenciesIndex}
        />
      )}
    </>
  );
};

export default AllCompetencies;

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Label, RadioGroup, InputText } from '@teo/components';
import Map from '@/pages/Protected/Onboarding/Steps/Map';

const EducationAndWorkPage = ({ userInfo, organisationSettings }: any) => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const googleScript = document.createElement('script');
    googleScript.type = 'text/javascript';
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBXCsHIDS0RXugO_KyoWUvE0ThKGBpAxUs&libraries=places`;
    window.document.body.appendChild(googleScript);

    googleScript.addEventListener('load', () => {
      setloading(true);
    });
  }, []);

  if (!userInfo) return null;
  return (
    <div className="h-full md:flex">
      <div className="flex w-full flex-col max-md:h-full">
        {organisationSettings?.educationPreferences && (
          <>
            <h4 className="mb-5 text-base">
              {t('pages.profile_education_and_work.course')}
            </h4>
            <div
              className={`space-y-2 border-grey-02 pb-8 md:space-y-5 ${
                organisationSettings?.workPreferences && 'border-b'
              }`}
            >
              <div className="w-full max-w-[500px]">
                <Label htmlFor="degree">
                  {t('pages.profile_education_and_work.highest_degree_label')}
                </Label>
                <InputText
                  id="firstName"
                  disabled
                  onChange={() => {}}
                  value={
                    userInfo?.diploma
                      ? t(
                          `pages.onboarding.step_5.${userInfo?.diploma.toUpperCase()}`
                        )
                      : ''
                  }
                />
              </div>

              <div className="w-full max-w-[500px]">
                <Label htmlFor="interest">
                  {t('pages.profile_education_and_work.technology_is_label')}
                </Label>
                <InputText
                  id="firstName"
                  disabled
                  onChange={() => {}}
                  value={
                    userInfo?.intrest
                      ? t(`pages.onboarding.step_5.${userInfo?.intrest}`)
                      : ''
                  }
                />
              </div>
            </div>
          </>
        )}
        {organisationSettings?.workPreferences && (
          <>
            <div
              className={`w-full max-w-[500px] space-y-2 md:space-y-4 ${
                organisationSettings?.educationPreferences && ' mt-6'
              }`}
            >
              <h2 className="text-base font-semibold">
                {t('pages.profile_education_and_work.work_preferences')}
              </h2>

              {loading && (
                <Map
                  locationRange={
                    userInfo?.locationRange ? userInfo?.locationRange : 15
                  }
                  userInfo={userInfo}
                  disabled={true}
                />
              )}
            </div>

            <div className="mt-6 w-full max-w-[500px] space-y-2 md:space-y-4">
              <div className=" mt-6">
                <RadioGroup
                  value={userInfo?.regime[0]}
                  onChange={(val) => {}}
                  label={t(
                    'pages.profile_education_and_work.preferred_shift_work'
                  )}
                >
                  <RadioGroup.Option value="day">
                    {t(`pages.onboarding.step_3.day`)}
                  </RadioGroup.Option>
                  <RadioGroup.Option value="2shift">
                    {t(`pages.onboarding.step_3.2shift`)}
                  </RadioGroup.Option>
                  <RadioGroup.Option value="3shift">
                    {t(`pages.onboarding.step_3.3shift`)}
                  </RadioGroup.Option>
                  <RadioGroup.Option value="continuous">
                    {t(`pages.onboarding.step_3.continuous`)}
                  </RadioGroup.Option>
                  <RadioGroup.Option value="weekend">
                    {t(`pages.onboarding.step_3.weekend`)}
                  </RadioGroup.Option>
                  <RadioGroup.Option value="night">
                    {t(`pages.onboarding.step_3.flexibel`)}
                  </RadioGroup.Option>
                </RadioGroup>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EducationAndWorkPage;

import { useTranslation } from 'react-i18next';
import { ButtonLink, Button } from '@teo/components';
import { useMobile } from '@/util';
import { useContext } from 'react';
import { NavContext } from '@/components/NavContext';
import { clsx } from 'clsx';

const Footer = () => {
  const { t } = useTranslation();
  const { collapsed } = useContext(NavContext);
  const isMobile = useMobile();
  const showContent = collapsed || !isMobile;

  return (
    <div
      id="footer_page"
      className={`${
        showContent ? '' : 'hidden'
      } bottom-0 z-[3] mt-auto flex w-full flex-col items-center justify-between bg-[#1e1e200d] p-4 md:flex-col lg:flex-row`}
    >
      <div className="text-sm font-normal text-[#1e1e20a8]">
        © {new Date().getFullYear()} TEO
      </div>
      <div className="flex flex-wrap items-center justify-center">
        <Button variant="ghost">
          {({ className: buttonClassName }) => (
            <a
              style={{
                borderRight: '1px solid #1e1e2052',
                borderRadius: 'inherit',
              }}
              href="https://teo.training/over-ons"
              className={clsx('block', buttonClassName)}
            >
              <span className="text-[#1173dd]">{t('links.about_us')}</span>
            </a>
          )}
        </Button>
        <Button variant="ghost">
          {({ className: buttonClassName }) => (
            <a
              style={{
                borderRight: '1px solid #1e1e2052',
                borderRadius: 'inherit',
              }}
              href="https://teo.training/contact"
              className={clsx('block', buttonClassName)}
            >
              <span className="text-[#1173dd]">{t('links.contact')}</span>
            </a>
          )}
        </Button>
        <ButtonLink
          to="./voorwaarden"
          variant="ghost"
          style={{
            borderRight: '1px solid #1e1e2052',
            borderRadius: 'inherit',
          }}
        >
          <span className="text-[#1173dd]">
            {t('links.terms_and_conditions')}
          </span>
        </ButtonLink>

        <ButtonLink to="./privacy" variant="ghost">
          <span className="text-[#1173dd]">{t('links.privacy')}</span>
        </ButtonLink>
        {/*
        <ButtonLink to="./cookies" variant="ghost">
          <span className="text-[#1173dd]">{t('links.cookie')}</span>
        </ButtonLink>
        */}
      </div>
      <div>
        <ButtonLink
          to="https://www.linkedin.com/company/12650098"
          variant="ghost"
          className="flex items-center text-sm font-normal text-[#1e1e20a8]"
        >
          {t('links.also_find_us_at')}
          <img
            src="/images/icons/logo-linkedin.svg"
            alt="logo-linkedin"
            className="ml-2"
          />
        </ButtonLink>
      </div>
    </div>
  );
};

export default Footer;

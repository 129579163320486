export const TryAgain = ({ className }) => (
  <svg
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.55806 0.823683C6.80214 0.579606 7.19787 0.579606 7.44194 0.823683L10.5669 3.94868C10.811 4.19276 10.811 4.58849 10.5669 4.83257L7.44194 7.95757C7.19787 8.20164 6.80214 8.20164 6.55806 7.95757C6.31398 7.71349 6.31398 7.31776 6.55806 7.07368L8.71009 4.92166C8.34554 4.88507 7.80091 4.85938 7 4.85938C5.88748 4.85938 4.79995 5.18928 3.87492 5.80736C2.94989 6.42544 2.22892 7.30395 1.80318 8.33178C1.37744 9.35962 1.26604 10.4906 1.48309 11.5818C1.70013 12.6729 2.23586 13.6752 3.02253 14.4619C3.8092 15.2485 4.81148 15.7843 5.90262 16.0013C6.99376 16.2183 8.12476 16.1069 9.1526 15.6812C10.1804 15.2555 11.0589 14.5345 11.677 13.6095C12.2951 12.6844 12.625 11.5969 12.625 10.4844C12.625 10.1392 12.9048 9.85938 13.25 9.85938C13.5952 9.85938 13.875 10.1392 13.875 10.4844C13.875 11.8441 13.4718 13.1733 12.7164 14.3039C11.9609 15.4345 10.8872 16.3157 9.63095 16.836C8.37471 17.3564 6.99238 17.4925 5.65876 17.2273C4.32514 16.962 3.10013 16.3072 2.13864 15.3457C1.17716 14.3842 0.522378 13.1592 0.257104 11.8256C-0.00816908 10.492 0.127979 9.10967 0.648331 7.85343C1.16868 6.59718 2.04987 5.52346 3.18046 4.76802C4.31105 4.01259 5.64026 3.60938 7 3.60938C7.6238 3.60938 8.11491 3.62442 8.50082 3.65033L6.55806 1.70757C6.31398 1.46349 6.31398 1.06776 6.55806 0.823683Z"
      fill="currentColor"
    />
  </svg>
);

export const MoreDifficult = ({ className }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 9C0.875 4.5142 4.5142 0.875 9 0.875C13.4858 0.875 17.125 4.5142 17.125 9C17.125 13.4858 13.4858 17.125 9 17.125C4.5142 17.125 0.875 13.4858 0.875 9ZM9 2.125C5.20455 2.125 2.125 5.20455 2.125 9C2.125 12.7954 5.20455 15.875 9 15.875C12.7954 15.875 15.875 12.7954 15.875 9C15.875 5.20455 12.7954 2.125 9 2.125ZM8.375 7.14125L6.31522 9.18506C6.07019 9.42819 5.67447 9.42665 5.43134 9.18163C5.18822 8.9366 5.18976 8.54088 5.43478 8.29775L8.55978 5.19697C8.80346 4.95518 9.19654 4.95518 9.44022 5.19697L12.5652 8.29775C12.8102 8.54088 12.8118 8.9366 12.5687 9.18163C12.3255 9.42665 11.9298 9.42819 11.6848 9.18506L9.625 7.14125V12.3594C9.625 12.7046 9.34518 12.9844 9 12.9844C8.65482 12.9844 8.375 12.7046 8.375 12.3594V7.14125Z"
      fill="currentColor"
    />
  </svg>
);

export const MoreEasier = ({ className }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 9C0.875 4.5142 4.5142 0.875 9 0.875C13.4858 0.875 17.125 4.5142 17.125 9C17.125 13.4858 13.4858 17.125 9 17.125C4.5142 17.125 0.875 13.4858 0.875 9ZM9 2.125C5.20455 2.125 2.125 5.20455 2.125 9C2.125 12.7954 5.20455 15.875 9 15.875C12.7954 15.875 15.875 12.7954 15.875 9C15.875 5.20455 12.7954 2.125 9 2.125ZM9 5.01562C9.34518 5.01562 9.625 5.29545 9.625 5.64062V10.8588L11.6848 8.81494C11.9298 8.57181 12.3255 8.57335 12.5687 8.81837C12.8118 9.0634 12.8102 9.45912 12.5652 9.70225L9.44022 12.803C9.19654 13.0448 8.80346 13.0448 8.55978 12.803L5.43478 9.70225C5.18976 9.45912 5.18822 9.0634 5.43134 8.81837C5.67447 8.57335 6.07019 8.57181 6.31522 8.81494L8.375 10.8588V5.64062C8.375 5.29545 8.65482 5.01562 9 5.01562Z"
      fill="currentColor"
    />
  </svg>
);

export const ExplainIcon = ({ className }) => (
  <svg
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.43366 2.28866C6.19185 2.17099 5.67295 2.05433 5.01016 2.0624C4.3077 2.07206 3.61377 2.22758 3.05709 2.6864M6.43366 2.28866C7.05836 2.59474 7.96875 3.19652 7.96875 4.40622C7.96875 5.73655 7.19866 6.33362 5.87179 7.24608C5.15209 7.74099 4.64606 8.26846 4.32708 8.86145C4.00527 9.45968 3.90626 10.0644 3.90626 10.6562C3.90626 11.0877 4.25604 11.4375 4.68751 11.4375C5.11898 11.4375 5.46876 11.0877 5.46876 10.6562C5.46876 10.2465 5.53537 9.9135 5.70311 9.60167C5.87368 9.28459 6.18113 8.92965 6.75713 8.53354L6.78716 8.5129C8.04542 7.64777 9.53125 6.62617 9.53125 4.40622C9.53125 2.22576 7.83219 1.23378 7.1202 0.885068L7.11892 0.884443C6.57852 0.620991 5.79013 0.490206 4.99042 0.500026L4.98934 0.50004C4.08965 0.512323 2.99551 0.712307 2.06327 1.4807C1.31309 2.09907 0.914043 2.80131 0.704172 3.35604C0.599648 3.63232 0.541784 3.87238 0.509704 4.04913C0.493631 4.13769 0.48392 4.21096 0.478063 4.2657C0.475133 4.29309 0.473159 4.31591 0.471837 4.33377C0.471175 4.3427 0.470676 4.3504 0.4703 4.35681C0.470112 4.36002 0.469955 4.3629 0.469825 4.36546L0.469648 4.36906L0.469573 4.37067C0.469482 4.37272 0.469507 4.37216 0.469507 4.37216C0.450697 4.80322 0.784893 5.16791 1.21595 5.18672C1.64426 5.20541 2.00704 4.8756 2.03012 4.44853C2.03029 4.44631 2.03076 4.44069 2.03169 4.43192C2.03381 4.41213 2.03829 4.37662 2.04708 4.32817C2.06474 4.23092 2.0993 4.08412 2.16558 3.90893C2.29731 3.56074 2.55338 3.10162 3.05709 2.6864"
      fill="currentColor"
    />
    <path
      d="M4.68751 14.8746C5.37786 14.8746 5.93751 14.3149 5.93751 13.6246C5.93751 12.9342 5.37786 12.3746 4.68751 12.3746C3.99715 12.3746 3.43751 12.9342 3.43751 13.6246C3.43751 14.3149 3.99715 14.8746 4.68751 14.8746Z"
      fill="currentColor"
    />
  </svg>
);

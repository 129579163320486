import { Button, IconButton } from '@teo/components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Image, Play } from '@teo/components/icons';

export const LessonExtraInfoTree = ({ extraInfoTree, setActiveExtraInfo }) => {
  const { t } = useTranslation();
  const [sortTree, setSortTree] = useState([]);
  useEffect(() => {
    if (extraInfoTree) {
      setSortTree(extraInfoTree.sort((a, b) => a.yindex - b.yindex));
    }
  }, [extraInfoTree]);

  return (
    <div className="mb-5 flex flex-col gap-3 pl-6">
      {sortTree?.map((les, index) => {
        return (
          <div
            key={les?.id + '_' + index}
            className="lg:min-w-ma flex w-full cursor-pointer items-center rounded-md px-2 sm:hover:bg-grey-transparent-01"
            onClick={() => setActiveExtraInfo(les)}
          >
            {les?.lessonId ? (
              <Document className="my-2 mr-3 w-5 text-grey-07 " />
            ) : les?.assetType === 'image' ? (
              <Image className="my-2 mr-3 w-5 text-grey-07 " />
            ) : (
              <Play className="my-2 mr-3 w-5 text-grey-07 " />
            )}

            <p className={`w-full flex-1 text-sm font-normal text-[#231f20]`}>
              {les?.name || les?.assetName || t('extra_info.title')}
            </p>
          </div>
        );
      })}
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { Button } from '@teo/components';
import React from 'react';
function UploadMainImageContainer({
  photoInfo,
  fileInputRef,
  handleUploadPhoto,
  uploadLogo,
  courseImg,
  setIsOpenLibraryModal,
}: any) {
  const { t } = useTranslation();
  if (!photoInfo && !uploadLogo) {
    return null;
  }
  return (
    <div className="flex flex-col p-3">
      <div className="flex items-center">
        <img
          alt="logo"
          src={uploadLogo ? uploadLogo : URL.createObjectURL(photoInfo)}
          className={`mr-3 h-16 flex-[1_0_64px] rounded object-cover object-center ${
            courseImg &&
            'h-[360px] w-full flex-[1_0_100%] sm:w-64 sm:flex-[1_0_64px]'
          }`}
        />
      </div>
      <div className="flex justify-end pt-6">
        <Button
          variant="outline"
          className="mr-2"
          onClick={() => setIsOpenLibraryModal(true)}
        >
          {t('pages.upload_dropzone_section.replace_from_library')}
        </Button>
        <Button
          variant="outline"
          size="md"
          onClick={() => {
            fileInputRef.current?.click();
          }}
          className="text-base font-semibold"
        >
          {courseImg
            ? t('pages.upload_dropzone_section.upload_button_img_2')
            : t('pages.upload_dropzone_section.upload_button_2')}
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadPhoto}
          accept="image/*"
          style={{ display: 'none' }}
          multiple={false}
        />
      </div>
    </div>
  );
}
export default UploadMainImageContainer;

import { useState, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { T } from '@/components/T';
import { Tabs } from '@teo/components';
import { AuthContext } from '@/components/Contexts/AuthContext';
import DetailsTab from './DetailsTab';
import { Lerningpathorganisationdetails } from '@/models';

function OrganisationDetailsPage() {
  const { t } = useTranslation();
  const [tab, setTab] = useState('detail');
  const { organisationId } = useParams();

  const { data, status } = useGet<Lerningpathorganisationdetails>(
    `/organisation/${organisationId}`
  );

  if (!data) return null;

  // console.log(data);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{t('pages.organisation_details.title')}</T>
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div>
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.TabButton name="detail">
              <p className="text-base font-medium">
                {t('pages.organisation_details.details.title')}
              </p>
            </Tabs.TabButton>
            {/* <Tabs.TabButton name="language">
              <p className="text-base font-medium">Language settings</p>
            </Tabs.TabButton> */}
          </Tabs>
        </div>
        {/* <div className="mt-4 lg:ml-3 lg:mt-0">
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.TabButton name="presentation">
              <p className="text-base font-medium">Presentation page</p>
            </Tabs.TabButton>
            <Tabs.TabButton name="privacy">
              <p className="text-base font-medium">Privacy declaration</p>
            </Tabs.TabButton>
          </Tabs>
        </div> */}
      </div>
      <div className="mt-6 rounded-lg border border-grey-02 p-6">
        {data?.data && tab === 'detail' && <DetailsTab data={data?.data} />}
      </div>
    </div>
  );
}

export default OrganisationDetailsPage;

import { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useGet } from '@/query/fetchHooks';
import { useParams } from 'react-router-dom';
import { LessonView } from '@/components/LessonView';
import LessonHeader from '@/pages/Protected/Learn/Courses/Lessons/LessonHeader';
import { nthLastIndexOf, parseQueryString } from '@/util';

function CreateCoursePage({
  returnPath = undefined,
}: {
  returnPath?: undefined | number | string;
}) {
  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );
  const { lessonId, versionId } = useParams();
  const { setNavDisabled } = useContext(NavContext);
  const { auth, setAuth } = useContext(AuthContext);
  const userId = auth?.user?.id;
  const authUser = auth?.user;

  const [activeNode, setActiveNode] = useState<any>(null);

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };
  const videoLink = queries['videoLink'] === 'true';

  useGet<any>(
    `/lesson/${lessonId}?write_access=true${
      versionId ? '&version=' + versionId : ''
    }`,
    {
      onSuccess: (result: any) => {
        setActiveNode(result?.data || null);
      },
    }
  );

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  }, []);

  if (!activeNode) return null;

  return (
    <div className="flex min-h-screen flex-col">
      <LessonHeader
        returnPath={returnPath}
        authUser={authUser}
        setAuth={setAuth}
        userId={userId}
        coursePath={basePath}
        previewHeader={true}
      />
      <div className="flex grow p-4">
        <div className="fr-view mx-auto w-full max-w-2xl">
          <LessonView
            node={activeNode}
            videoLink={videoLink}
            pageBreak={!queries['noPageBreak']}
            showAnswers={!!queries['showAnswers']}
          ></LessonView>
        </div>
      </div>
    </div>
  );
}

export default CreateCoursePage;

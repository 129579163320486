import { useState, useEffect, createRef } from 'react';
import {
  isEmpty,
  newUid,
  deep_copy,
  array_difference,
  array_intersect,
  realParseFloat,
  walk,
  swap,
} from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import {
  Switch,
  InputScore,
  InputText,
  InputSelect,
  IconButton,
  Button,
} from '@teo/components';
import { T } from '@/components/T';
import { Svg } from './teo_box/Svg';
import update from 'immutability-helper';
import { parse, unescapeHTML } from 'svg-parser';
import { toHtml } from 'hast-util-to-html';
import { uploadString } from '@/query/documents.js';
import { Pencil, ChevronUp, ChevronDown } from '@teo/components/icons';
import { BoxTriggerEditor } from './teo_box/BoxTriggerEditor.jsx';
import { BoxCorrectionEditor } from './teo_box/BoxCorrectionEditor.jsx';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialBoxError = () => ({
  score: 1,
  vraag: '',
  triggers: [],
  type: 'BoxError',
  noSubmit: true,
  resetFirst: false,
});

export function stringToBool(str) {
  switch (str) {
    case String(undefined):
      return undefined;
    case String(true):
      return true;
    case String(false):
      return false;
  }
}

const digitalOutputs = [
  ...[...Array(24).keys()].map((i) => 'D' + i),
  ...[...Array(16).keys()].map((i) => 'R' + i),
];
const analogInputs = [...Array(16).keys()].map((i) => 'A' + i);

export const digitalOptions = [
  {
    value: `${undefined}`,
    label: 'ANY',
  },
  {
    value: `${true}`,
    label: 'HIGH',
  },
  {
    value: `${false}`,
    label: 'LOW',
  },
];

const requiredOptions = [
  {
    value: `${false}`,
    label: 'not_required',
  },
  {
    value: `${true}`,
    label: 'required',
  },
];

const loseAttemptOptions = [
  {
    value: `${true}`,
    label: 'yes',
  },
  {
    value: `${false}`,
    label: 'no',
  },
];

export const BoxErrorEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();
  const uploadInputRef = createRef();

  const [modified, setModified] = useState(newUid(20));
  const [nrOfAttempts, setNrOfAttempts] = useState(state.nrOfAttempts);
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );
  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.box_error')
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : false
  );
  const [resetFirst, setResetFirst] = useState(state.resetFirst ? true : false);
  const [competencies, setCompetencies] = useState([]);
  const [score, setScore] = useState(state.score || 0);
  const [hidden, setHidden] = useState(state.hidden || false);

  const [corrections, setCorrections] = useState(state.corrections || []);
  const [triggers, setTriggers] = useState(state.triggers || []);

  const [activeCorrectionIndex, setActiveCorrectionIndex] = useState(null);
  const [activeTriggerIndex, setActiveTriggerIndex] = useState(null);

  const updateTrigger = (i, attrs) => {
    setTriggers(update(triggers, { [i]: { $merge: attrs } }));
  };
  const updateCorrection = (i, attrs) => {
    setCorrections(update(corrections, { [i]: { $merge: attrs } }));
  };

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = true;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.resetFirst = resetFirst;
    newState.showAnswer = showAnswer;
    newState.score = score;
    newState.corrections = corrections;
    newState.triggers = triggers;
    newState.nrOfAttempts = nrOfAttempts;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  return (
    <>
      {activeTriggerIndex !== null && (
        <BoxTriggerEditor
          onClose={() => setActiveTriggerIndex(null)}
          triggerIndex={activeTriggerIndex}
          trigger={triggers[activeTriggerIndex]}
          onUpdate={(data) => {
            updateTrigger(activeTriggerIndex, data);
          }}
        />
      )}
      {activeCorrectionIndex !== null && (
        <BoxCorrectionEditor
          onClose={() => setActiveCorrectionIndex(null)}
          correctionIndex={activeCorrectionIndex}
          correction={corrections[activeCorrectionIndex]}
          onUpdate={(data) => {
            updateCorrection(activeCorrectionIndex, data);
          }}
        />
      )}
      <div
        data-open="SOW"
        className={`flex w-full flex-col gap-4 lg:p-4`}
        style={{ maxWidth: 'calc(100vw - 32px)' }}
        data-state={encode(JSON.stringify(state))}
        id={state.uid}
      >
        <WidgetHeader
          index={index}
          titleWidget={titleWidget}
          setTitleWidget={setTitleWidget}
        />

        {/* <span className="text-lg font-semibold text-grey-04">
          {t('widgets.widgets_edit.question')} (
          {t('widgets.widgets_edit.optional')})
        </span> */}
        <div className="wg-question">
          <QuestionSection questionVraag={questionVraag} />
        </div>

        <span className="text-lg font-semibold text-grey-04">
          {t('widgets.box.triggers')}
        </span>

        {/*<div className="flex flex-row items-center border-t border-b border-grey-02 py-2">
          <Switch
            checked={resetFirst}
            onChange={(isCheck) => setResetFirst(isCheck)}
          />
          <div className="mr-4 border-r-2 border-grey-02 px-4">
            <T>widgets.widgets_edit.reset_first</T>
          </div>
        </div>*/}
        {!!(triggers || []).length && (
          <table
            className="borderless-table"
            style={{ borderCollapse: 'collapse' }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>
                  <T>NAME</T>
                </th>
                <th style={{ textAlign: 'left' }}>
                  <T>Description</T>
                </th>
                <th style={{ width: '130px' }}></th>
              </tr>
            </thead>
            <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
              {(triggers || []).map((trigger, i) => (
                <tr key={'trigger_' + i} style={{ alignItems: 'center' }}>
                  <td style={{ verticalAlign: 'middle', width: '250px' }}>
                    <InputText
                      size="small"
                      fullWidth
                      style={{ padding: '0 5px' }}
                      onChange={(ev) => {
                        updateTrigger(i, { name: ev.target.value });
                      }}
                      value={trigger.name}
                    />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <InputText
                      size="small"
                      fullWidth
                      style={{ padding: '0 5px' }}
                      onChange={(ev) => {
                        updateTrigger(i, { description: ev.target.value });
                      }}
                      value={trigger.description}
                    />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div className="flex">
                      <IconButton
                        Icon={Pencil}
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setActiveTriggerIndex(i);
                        }}
                      />
                      <Button
                        size="sm"
                        className="!px-2"
                        onClick={() => {
                          let newTrigger = deep_copy(triggers);
                          newTrigger.splice(i, 1);
                          setTriggers(newTrigger);
                        }}
                        variant="ghost"
                      >
                        <img
                          src="/images/icons/trash_icon.svg"
                          alt="delete button"
                        />
                      </Button>
                      <IconButton
                        Icon={ChevronUp}
                        variant="ghost"
                        size="sm"
                        disabled={i === 0}
                        onClick={() => {
                          let newTriggers = deep_copy(triggers);
                          swap(newTriggers, i - 1, i);
                          setTriggers(newTriggers);
                        }}
                      />
                      <IconButton
                        Icon={ChevronDown}
                        variant="ghost"
                        size="sm"
                        disabled={i === triggers.length - 1}
                        onClick={() => {
                          let newTriggers = deep_copy(triggers);
                          swap(newTriggers, i + 1, i);
                          setTriggers(newTriggers);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Button
          size="sm"
          className="self-start"
          onClick={() => {
            let newTriggers = deep_copy(triggers);
            newTriggers.push({
              name: t('NEW_TRIGGER'),
              description: '',
              conditions: [],
              actions: [],
            });
            setTriggers(newTriggers);
          }}
        >
          <T>widgets.box.new_trigger</T>&nbsp;
          <i className="fal fa-plus fa-lg"></i>
        </Button>

        <div className={`${!showAnswer ? 'hidden' : ''}`}>
          {/* <span className="text-lg font-semibold text-grey-04">
            {t('widgets.widgets_edit.example_answer')} (
            {t('widgets.widgets_edit.optional')})
          </span> */}
          <div className="wg-answer">
            <QuestionSection
              questionVraag={antwoordVraag}
              exampleAnswer={true}
            />
          </div>
        </div>
        <div data-closed="EOW"></div>
        <WidgetCompetencies
          state={state}
          setCompetencies={setCompetencies}
          competencies={competencies}
        />
      </div>
    </>
  );
};

import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@/components/DataTable/DataTable';
import { newUid } from '@/util';
import OptionsAction from '@/components/DataTable/OptionsAction';
import { OrganisationContext } from '@/components/Contexts/OrganisationContext';
import { PersonAvatar, Button } from '@teo/components';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';
import i18n from 'i18next';

function TeamsFollow() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [columns, setColumns] = useState<any>([]);

  const { myOrganisations } = useContext(OrganisationContext);
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;

  const updateActiveOrganisation = (organisation: any) => {
    return axios.put(`${config.backend}/user/${authUser?.id}`, {
      activeOrganisationId: organisation,
      id: authUser?.id,
    });
  };

  const tableColums = [
    {
      title: t('pages.profile_follow.teamname'),
      id: 'teamName',
      className: 'col-start-1 col-end-9  row-start-1',
      render: (team: any) => {
        return (
          <div className="flex items-center">
            <PersonAvatar
              inline
              id={team.id}
              name={team.teamName}
              bgColor={team.color ? team.color : null}
            ></PersonAvatar>
            <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
              {team.teamName}
            </p>
          </div>
        );
      },
    },
    {
      title: '',
      id: 'follow',
      sortable: false,
      className: 'col-start-6 col-end-10 row-start-2 justify-end',
      classNameDesktop: 'w-[190px]',
      render: (team: any) => (
        <div className="flex items-center justify-end">
          <img
            src="/images/components/follow_up_courses/star_icon.svg"
            alt="star_icon"
            className="h-5"
          />
          <Button
            variant="outline"
            size="md"
            onClick={async () => {
              await axios.delete(
                `${config.backend}/trackteam/${authUser?.id}/${team?.id}/`
              );
              setRefetchTable(newUid(20));
            }}
            className="ml-5"
          >
            {t('pages.profile_follow.btn_unfollow')}
          </Button>
        </div>
      ),
    },
    {
      title: '',
      id: 'edit',
      sortable: false,
      className: 'col-start-9 row-start-1 justify-end',
      classNameDesktop: 'w-[50px]',
      render: (team: any) => (
        <div className="flex justify-end sm:mt-1">
          <OptionsAction
            menuItems={[
              {
                addIcon: true,
                addOnMobile: true,
                id: '1',
                title: t('pages.profile_follow.go_to_team'),
                onClick: async () => {
                  const promises = [];
                  promises.push(updateActiveOrganisation(team?.organisationId));

                  await Promise.allSettled(promises).then(() => {
                    navigate(
                      `/${i18n.language}/follow-up/${team?.organisationId}/teams/${team?.id}`
                    );
                  });
                },
              },
            ]}
            rowInfo={team}
            screensUpdateTable="mobile"
            setRefetchTable={setRefetchTable}
            mobileHeader={{
              render: (team: any) => (
                <div className="flex items-center">
                  <PersonAvatar
                    inline
                    id={team.id}
                    name={team.teamName}
                  ></PersonAvatar>
                  <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                    {team.teamName}
                  </p>
                </div>
              ),
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (myOrganisations?.length > 1) {
      const newArray = [
        ...tableColums.slice(0, 1),
        {
          addTitleOnMobile: true,
          id: 'organisation',
          title: t('pages.profile_follow.organisation'),
          sortable: false,
          align: 'left',
          className: 'col-start-1 col-end-6 row-start-2',
          classNameDesktop: 'w-[250px]',
          render: (team: any) => (
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-grey-06">
                - {team?.organisationName}
              </p>
            </div>
          ),
        },
        ...tableColums.slice(1),
      ];
      setColumns(newArray);
    } else {
      setColumns(tableColums);
    }
  }, [myOrganisations]);

  return (
    <div>
      <DataTable
        key={refetchTable}
        screensUpdateTable="mobile"
        defaultSort={[{ id: 'teamName', desc: false }]}
        fetch={useGet.bind(null, `/trackteamdetails`, {
          userId: authUser?.id,
        })}
        empty={{
          template: 1,
          text: t('empty_tenplate.team_list'),
        }}
        columns={columns}
      />
    </div>
  );
}

export default TeamsFollow;

import i18n from 'i18next';

function addCustomVideoLayer(editor) {
  const popup = editor.popups.get('video.insert');

  if (popup && popup[0]) {
    const videoByURLLayer = popup[0].querySelector('.fr-video-by-url-layer');
    const videoUploadLayer = popup[0].querySelector('.fr-video-upload-layer');

    if (videoByURLLayer) {
      const blockInput = videoByURLLayer.querySelector('.fr-input-line');
      if (blockInput) {
        const oldInput = blockInput.querySelector('input');
        if (oldInput) {
          oldInput.classList.add('fr-custom-video-by-url-text');
        }
        const oldActionButtons =
          videoByURLLayer.querySelector('.fr-action-buttons');
        if (oldActionButtons) {
          const newActionButtons = document.createElement('div');
          newActionButtons.className = 'fr-action-buttons flex flex-col !h-max';
          newActionButtons.innerHTML = `
        <div class="flex justify-between pt-3 flex-row">
            <div class="fr-checkbox-line fr-autoplay-margin !flex !leading-snug items-center">
                <span class="fr-checkbox !p-0 !pr-2">
                    <input id="videoPluginAutoplay" data-checked="_blank" type="checkbox" dir="auto" value="" />
                    <span>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32">
                            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path>
                        </svg>
                    </span>
                </span>
                <label id="fr-label-target-1" class="leading-tight text-left">${i18n.t(
                  'pages.create_edit_course.autoplay'
                )}</label>
            </div>
            <div class="fr-checkbox-line !m-0 !flex items-center">
                <span class="fr-checkbox !p-0 !pr-2">
                    <input id="videoAutoCC" data-checked="_blank" type="checkbox" dir="auto" value=""/>
                    <span>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32">
                            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path>
                        </svg>
                    </span>
                </span>
                <label id="fr-label-videoAutoCC" class="leading-tight text-left">${i18n.t(
                  'pages.create_edit_course.auto_cc'
                )}</label>
            </div>
        </div>
        <div id="language-select-container"></div>
        <div class="flex justify-between items-center mt-3">
        <div class="fr-checkbox-line !m-0 !flex items-center">
                <span class="fr-checkbox !p-0 !pr-2">
                    <input id="videoAutoLoop" data-checked="_blank" type="checkbox" dir="auto" value=""/>
                    <span>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32">
                            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path>
                        </svg>
                    </span>
                </span>
                <label id="fr-label-videoAutoLoop" class="leading-tight text-left">${i18n.t(
                  'pages.create_edit_course.auto_loop'
                )}</label>
            </div>
        <button type="button" class="fr-command fr-submit" tabindex="2" role="button" id="insert_custom_video_url_btn">
            ${editor.language.translate('Insert')}
        </button>
        </div>
    `;
          if (oldActionButtons) {
            videoByURLLayer.replaceChild(newActionButtons, oldActionButtons);
          }
        }
      }

      videoByURLLayer.classList.add('fr-custom-video-by-url-layer');
      const customInsertButton = popup[0].querySelector(
        '#insert_custom_video_url_btn'
      );

      const videoAutoCC = popup[0].querySelector('#videoAutoCC');
      const videoAutoCCLabel = popup[0].querySelector('#fr-label-videoAutoCC');

      videoAutoCC.addEventListener('click', () =>
        toggleLanguageSelect(popup[0])
      );
      videoAutoCCLabel.addEventListener('click', () =>
        setTimeout(() => {
          toggleLanguageSelect(popup[0]);
        }, 0)
      );

      customInsertButton.addEventListener('click', () =>
        insertButton(editor, videoByURLLayer)
      );
    }

    if (videoUploadLayer) {
      if (!videoUploadLayer.querySelector('.custom-video-upload-layer')) {
        const actionDiv = document.createElement('div');
        const wrapperDiv = document.createElement('div');
        wrapperDiv.className = 'custom-video-upload-layer';
        actionDiv.className =
          'absolute w-[calc(100%_+_44px)] -left-[22px] p-5 bg-white rounded-md top-[calc(100%_+_4px)] shadow-[0_6px_8px_-3px_rgba(109,109,109,1)]';

        actionDiv.innerHTML = `
       <div class="flex justify-between flex-row">
            <div class="fr-checkbox-line fr-autoplay-margin !flex !leading-snug items-center">
                <span class="fr-checkbox !p-0 !pr-2">
                    <input id="fr-videoUploadAutoplay" data-checked="_blank" type="checkbox" dir="auto" value="" />
                    <span>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32">
                            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path>
                        </svg>
                    </span>
                </span>
                <label id="fr-label-target-1" class="leading-tight text-left">${i18n.t(
                  'pages.create_edit_course.autoplay'
                )}</label>
            </div>
             <div class="fr-checkbox-line !m-0 !flex items-center">
                <span class="fr-checkbox !p-0 !pr-2">
                    <input id="fr-videoUploadAutoLoop" data-checked="_blank" type="checkbox" dir="auto" value=""/>
                    <span>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32">
                            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path>
                        </svg>
                    </span>
                </span>
                <label id="fr-label-videoAutoLoop" class="leading-tight text-left">${i18n.t(
                  'pages.create_edit_course.auto_loop'
                )}</label>
            </div>
        </div>`;

        while (videoUploadLayer.firstChild) {
          wrapperDiv.appendChild(videoUploadLayer.firstChild);
        }

        videoUploadLayer.appendChild(wrapperDiv);
        videoUploadLayer.appendChild(actionDiv);
      }
    }
  }
}

function toggleLanguageSelect(popup) {
  var videoAutoCC = popup.querySelector('#videoAutoCC');
  if (videoAutoCC.checked) {
    addLanguageSelect(popup);
  } else {
    removeLanguageSelect(popup);
  }
}

function addLanguageSelect(popup) {
  var languageSelectContainer = popup.querySelector(
    '#language-select-container'
  );
  if (!popup.querySelector('#language-select')) {
    var select = document.createElement('select');
    select.id = 'language-select';
    select.name = 'language';
    select.className = 'mt-3 w-full';

    var defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.text = `${i18n.t('pages.create_edit_course.select_lng')}`;
    select.appendChild(defaultOption);

    var languages = [
      { value: 'en', text: `${i18n.t('langs.en')}` },
      { value: 'nl', text: `${i18n.t('langs.nl')}` },
      { value: 'fr', text: `${i18n.t('langs.fr')}` },
      { value: 'de', text: `${i18n.t('langs.de')}` },
      { value: 'es', text: `${i18n.t('langs.es')}` },
      { value: 'it', text: `${i18n.t('langs.it')}` },
      { value: 'pt', text: `${i18n.t('langs.pt')}` },
      { value: 'bg', text: `${i18n.t('langs.bg')}` },
    ];

    languages.forEach(function (language) {
      var option = document.createElement('option');
      option.value = language.value;
      option.text = language.text;
      select.appendChild(option);
    });

    languageSelectContainer.appendChild(select);
  }
}

function removeLanguageSelect(popup) {
  var languageSelectContainer = popup.querySelector(
    '#language-select-container'
  );
  var select = document.querySelector('#language-select');
  if (select) {
    languageSelectContainer.removeChild(select);
  }
}

function insertButton(editor, videoByURLLayer) {
  const autoCC = videoByURLLayer.querySelector('#videoAutoCC').checked;
  const autoLoop = videoByURLLayer.querySelector('#videoAutoLoop').checked;
  const autoPlay = videoByURLLayer.querySelector(
    '#videoPluginAutoplay'
  ).checked;

  const selectLng = videoByURLLayer.querySelector('#language-select');
  let url = videoByURLLayer.querySelector('.fr-custom-video-by-url-text').value;
  let vUrl = '';

  let languageCC = false;

  if (selectLng) {
    var selectedValue = selectLng.value;
    if (selectedValue) {
      languageCC = selectedValue;
    }
  }

  function extractVimeoID(url) {
    const regex = /vimeo\.com\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  if (autoCC) {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      if (url.includes('?')) {
        if (languageCC) {
          autoPlay
            ? (url += `&cc_load_policy=1&cc_lang_pref=${languageCC}&`)
            : (url += `&cc_load_policy=1&cc_lang_pref=${languageCC}`);
        } else {
          autoPlay
            ? (url += '&cc_load_policy=1&')
            : (url += '&cc_load_policy=1');
        }
      } else {
        if (languageCC) {
          autoPlay
            ? (url += `?cc_load_policy=1&cc_lang_pref=${languageCC}&`)
            : (url += `?cc_load_policy=1&cc_lang_pref=${languageCC}`);
        } else {
          autoPlay
            ? (url += '?cc_load_policy=1&')
            : (url += '?cc_load_policy=1');
        }
      }
    } else if (url.includes('vimeo.com')) {
      if (languageCC) {
        if (url.includes('?')) {
          autoPlay
            ? (vUrl += `?&texttrack=${languageCC}&autoplay=1&muted=1&#t=0s`)
            : (vUrl += `&texttrack=${languageCC}`);
        } else {
          autoPlay
            ? (vUrl += `?&texttrack=${languageCC}&autoplay=1&muted=1&#t=0s`)
            : (vUrl += `?texttrack=${languageCC}`);
        }
      }
    }
  } else {
    if (autoPlay) {
      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        url += '&';
      } else if (url.includes('vimeo.com')) {
        vUrl += '?&autoplay=1&muted=1&#t=0s';
      }
    }
  }

  function extractYouTubeID(url) {
    const regex = /(?:youtube\.com\/.*v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  if (autoLoop) {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      if (url.includes('?')) {
        url += `&playlist=${extractYouTubeID(url)}&loop=1&`;
      } else {
        url += '?playlist=${extractYouTubeID(url)}&loop=1';
      }
    } else if (url.includes('vimeo.com')) {
      if (vUrl.includes('?')) {
        vUrl = `?&loop=1` + vUrl.substring(1);
      } else {
        vUrl += `?&loop=1`;
      }
    }
  }

  const extractFileId = (url) => {
    try {
      const parsedUrl = new URL(url);

      if (parsedUrl.hostname === 'drive.google.com') {
        const regex = /\/d\/(.*?)\//;
        const matches = parsedUrl.pathname.match(regex);
        return matches ? matches[1] : null;
      } else {
        console.error('This URL does not belong to Google Drive.');
        return null;
      }
    } catch (error) {
      console.error('Invalid URL', error);
      return null;
    }
  };

  if (url.includes('drive.google')) {
    const fileId = extractFileId(url);
    editor.video.insertEmbed(
      `<iframe width="640" height="360" src="https://drive.google.com/file/d/${fileId}/preview" frameborder="0" allowfullscreen allow="autoplay; muted" title="Google Drive Video"></iframe>`
    );
  } else if (url.includes('vimeo.com')) {
    editor.video.insertEmbed(
      `<iframe width="640" height="360" src="https://player.vimeo.com/video/${extractVimeoID(
        url
      )}${vUrl}" frameborder="0" allowfullscreen></iframe>`
    );
  } else {
    editor.video.insertByURL(url);
  }

  editor.popups.hide('video.insert');
}

export { addCustomVideoLayer };

import React, { useEffect, useContext, useRef } from 'react';
import { useGet } from '@/query/fetchHooks';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import useState from 'react-usestateref';
import { axios } from '@/axios';
import { Button, PersonAvatar } from '@teo/components';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { isEmpty, toPercent, newUid, isObject } from '@/util';
import { config } from '@/config';
import { Subject, BehaviorSubject, skip } from 'rxjs';
import { XAPIService } from '@/services/xapi.service.js';

const AUTOSAVE_DELAY = 20; //20s

export const LessonView = ({
  node,
  status,
  course,
  setSubmitFn,
  student,
  timeDisabled,
  isTeacher,
  page = 0,
  setNrOfPages = () => {},
  setLoadeContent,
  nodeGetData,
  showAnswers = false,
  pageBreak = true,
  onSubmit = () => {},
  videoLink = false,
  setIsLoaded,
}: {
  node: any;
  status?: any;
  course?: any;
  setSubmitFn?: any;
  student?: any;
  timeDisabled?: boolean;
  isTeacher?: boolean;
  page?: number;
  setNrOfPages?: any;
  setLoadeContent?: any;
  nodeGetData?: any;
  showAnswers?: boolean;
  pageBreak?: boolean;
  onSubmit?: any;
  videoLink?: boolean;
  setIsLoaded?: any;
}) => {
  let doAutosave = true;
  if (
    status &&
    (status.status === 'completed' ||
      status.status === 'corrected' ||
      status.status === 'submitted')
  ) {
    doAutosave = false;
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const lessonId = node?.lessonId;
  const [splittedContent, setSplittedContent] = useState<any>(null);

  const modifiedWidgets = useRef<Set<string>>(new Set());
  const subscriptions = useRef<any[]>([]);

  const saveTimer: any = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(saveTimer.current);
    };
  }, []);

  useEffect(() => {
    return () => {
      for (const subscription of subscriptions.current) {
        subscription.unsubscribe();
      }
    };
  }, []);

  useGet(nodeGetData ? nodeGetData : node?.getData, {
    enabled: nodeGetData ? !!nodeGetData : !!node?.getData,
    onSuccess: (result: any) => {
      let splitted_html: Array<string>;
      if (pageBreak) {
        splitted_html = result?.data.split(
          /<div class="pageBreak[\s\S]*?<\/div>/gms
        );
      } else {
        splitted_html = [result?.data];
      }
      setNrOfPages(splitted_html.length);
      /*
      if (videoLink) {
        splitted_html = splitted_html.map((str) =>
          str.replace(
            /<iframe.*?src=["|'](.*?)["|'].*?\/iframe>|\/>/,
            '<a href ="$1">$1</a>'
          )
        );
      }
      */
      setSplittedContent(splitted_html);
    },
  });

  const [widgets, setWidgets, widgetsRef] = useState<any>({});
  const [result, setResult, resultRef] = useState<any>({});
  const [rerenderView, setRerenderView] = useState<any>(newUid(20));

  const isCorrected = student?.id && status?.status === 'corrected';
  const isSubmitted = student?.id && status?.status === 'submitted';

  const [resultsLoaded, setResultsLoaded] = useState<boolean>(!student);

  const followupPath = `/${i18n.language}/follow-up/${auth?.user?.activeOrganisationId}/resultaat/${course?.id}/${student?.id}/${result?.lessonId}`;
  const learnPath = `/${i18n.language}/learn/${auth?.user?.activeOrganisationId}/resultaat/${course?.id}/${student?.id}/${result?.lessonId}`;
  // let showContent =
  //   !isTeacher ||
  //   (status?.status &&
  //     status?.status != 'started' &&
  //     status?.status != 'completed');

  let showContent = !isTeacher || status?.status;

  //hide content for ass
  if (
    (status?.status === 'corrected' || status?.status === 'submitted') &&
    !isTeacher &&
    course?.courseType === 'assessment'
  ) {
    showContent = false;
  }

  const { refetch: refetchCorrection } = useGet<any>(
    `${config.teodoc}/corrections/list2`,
    {
      lessonId,
      courseId: course?.id,
      userId: student?.id,
      enabled: !!student && isCorrected,
      onSuccess: (result: any) => {
        setResultsLoaded(true);
        setLoadeContent(true);
        if (result?.data.length > 0) {
          setResult(result?.data?.[result?.data.length - 1]);
        }
        setRerenderView(newUid(20));
      },
      onError: (result: any) => {
        setResultsLoaded(true);
        setLoadeContent(true);
      },
    }
  );

  const { refetch: refetchResult } = useGet<any>(
    `${config?.teodoc}/results/list2`,
    {
      lessonId,
      submitted: status?.status === 'submitted' ? 'true' : undefined,
      courseId: course?.id,
      userId: student?.id,
      enabled: !!student && !isCorrected,
      onSuccess: (result: any) => {
        setResultsLoaded(true);
        setLoadeContent(true);
        if (result?.data.length > 0) {
          setResult(result?.data?.[result?.data.length - 1]);
        }
        setRerenderView(newUid(20));
      },
    }
  );

  useEffect(() => {
    if (status?.status === 'corrected') {
      refetchCorrection();
    } else if (status?.status === 'submitted' || status?.status === 'stored') {
      refetchResult();
    }
  }, [status]);

  //submit === true: real submit, submit: false, autosave
  //force === true: force save, force === false, cancel if incomplete
  //triggerWidget: the state of the triggering widget or undefined
  const getSubmit = async (
    submit = false,
    force = false,
    triggerWidget: any = undefined
  ) => {
    let toSubmit =
      resultRef.current && isObject(resultRef.current)
        ? resultRef.current
        : null;
    if (!toSubmit) {
      toSubmit = {
        userId: student?.id,
        coursecontentId: course?.coursecontentId,
        courseId: course?.id,
        lessonId: lessonId,
        version: node?.version,
        versionId: node?.version,
        releaseDate: new Date(),
        lessonName: node?.name,
        studentName: student?.username,
        courseName: course?.name,
        className: course?.courseName,
        widgets: {},
      };
    }

    let allSubmitted = true;
    for (const widget of Object.values(widgetsRef.current)) {
      if ((widget as any)?.answerFn) {
        const answer = await (widget as any)?.answerFn?.();
        if (!answer.submitted) {
          allSubmitted = false;
          break;
        }
      }
    }

    toSubmit.allSubmitted = allSubmitted;
    if (allSubmitted) submit = true;

    const incremental = !!toSubmit._id && !submit;

    toSubmit.userId = student?.id;
    toSubmit.courseId = course?.id;
    toSubmit.coursecontentId = course?.coursecontentId;
    toSubmit.lessonId = lessonId;
    toSubmit.version = node?.version;

    const autoCorrect =
      submit &&
      (course.correctionType === 'auto' ||
        course.correctionType === 'immediate' ||
        course.correctionType === 'autofeedback');

    if (submit) {
      toSubmit.submitted = true;
      toSubmit.submitDate = new Date();
      if (autoCorrect) {
        toSubmit.autoCorrected = true;
        toSubmit.feedback = t('Automatisch verbeterd');
        toSubmit.releaseDate = new Date();
      }
    } else {
      toSubmit.submitted = false;
    }

    toSubmit.score = 0;
    toSubmit.maxScore = 0;

    const widgetsToSave = incremental
      ? [...modifiedWidgets.current]
      : Object.keys(widgetsRef.current);

    //wether it is incremental or not, these will be saved
    clearTimeout(saveTimer.current);
    saveTimer.current = null;
    modifiedWidgets.current.clear();
    toSubmit.widgets = {};

    const incompleteWidgets = [];
    for (const key of widgetsToSave) {
      const widget = widgetsRef.current[key];
      if (!widget.state.noSubmit) {
        const correctioneWidgets = (await widget?.answerFn?.()) || {};
        incompleteWidgets.push(correctioneWidgets);
      }
    }

    let checkAnswer = false;
    for (const key of widgetsToSave) {
      const widget = widgetsRef.current[key];
      if (
        widget?.state?.type === 'OpenVraag' ||
        widget?.state?.type === 'FileUpload'
      ) {
        checkAnswer = true;
      }
    }
    for (const key of widgetsToSave) {
      const widget = widgetsRef.current[key];
      const immediateFeedback =
        widget.state.immediateFeedback ||
        course?.correctionType === 'autofeedback';
      if (!toSubmit.widgets) toSubmit.widgets = {};
      try {
        if (!widget.state.noSubmit) {
          toSubmit.widgets[key] = Object.assign(
            {},
            {
              state: widget.state,
              answer: (await widget?.answerFn?.()) || {},
            }
          );

          if (
            autoCorrect ||
            (immediateFeedback && toSubmit.widgets[key].answer.submitted)
          ) {
            toSubmit.widgets[key].correction = await widget?.correctionFn?.();
          }
          toSubmit.maxScore += parseFloat(
            toSubmit.widgets[key]?.state?.score || 0
          );
          toSubmit.score += parseFloat(
            toSubmit.widgets[key]?.correction?.score || 0
          );
          if (submit) {
            if (toSubmit.widgets[key].answer.incomplete && !force)
              return {
                status: 'incomplete',
                widget: toSubmit.widgets[key],
                uid: key,
                incompleteWidgets: incompleteWidgets
                  .filter((item) => item.incomplete === true)
                  .map((item) => item.uid),
              };
            toSubmit.widgets[key].answered = true;
            if (autoCorrect && submit && checkAnswer) {
              toSubmit.widgets[key].answer.incomplete = false;
              toSubmit.widgets[key].answer.submitted = true;
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    for (const key of Object.keys(toSubmit.widgets)) {
      const widget = widgetsRef.current[key];
      const immediateFeedback =
        widget.state.immediateFeedback ||
        course?.correctionType === 'autofeedback';
      const shouldReport =
        (immediateFeedback && triggerWidget?.uid === key) ||
        (submit && !toSubmit.widgets[key].answer.submitted);

      if (XAPIService.getInstance().available() && widget.xApiSubmitFn) {
        try {
          if (widget.xApiSubmitFn && shouldReport) {
            widget?.xApiSubmitFn?.(
              toSubmit.lessonId,
              i18n.language,
              course?.minScore || 0.5
            );
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    let result;
    if (((submit && autoCorrect) || allSubmitted) && !checkAnswer) {
      result = axios.post(`${config.teodoc}/corrections`, toSubmit);
    } else if (toSubmit._id) {
      result = axios.put(`${config.teodoc}/results/${toSubmit._id}`, toSubmit);
    } else {
      result = axios.post(`${config.teodoc}/results`, toSubmit);
    }

    const newResult = (await result)?.data;
    onSubmit(newResult);
    setResult(newResult);
    return newResult;
  };

  useEffect(() => {
    if (!setSubmitFn) return;
    if (!widgetsRef.current) return setSubmitFn(null);
    let canSubmit = false;
    for (const key of Object.keys(widgetsRef.current)) {
      if (!widgetsRef.current[key].state.noSubmit) canSubmit = true;
    }
    if (!canSubmit) return setSubmitFn(null);
    setSubmitFn(getSubmit);
  }, [node, node?.id, widgetsRef, widgetsRef.current]);

  /*
  useEffect(() => {
    const subscriptions: any[] = [];
    for (const key in widgets) {
      subscriptions.push(
        widgets[key].onModified.subscribe(async () => {
          //TODO: autosave shenaningens
          //console.log("MODIFIED", widgets[key])
        })
      );
      subscriptions.push(widgets[key].onSave.subscribe(async () => {}));
    }
    return () => {
      for (const subscription of subscriptions) {
        subscription.unsubscribe();
      }
    };
  }, [widgets]);
  */

  if (!splittedContent || !resultsLoaded) return null;

  return (
    <>
      {node?.timed === 'limited' && !timeDisabled && (
        <div className="rounded-md bg-error-01 py-3 px-3 text-sm text-error-05">
          {t('pages.course_landing.time_message')}
        </div>
      )}

      {status?.status === 'corrected' && (
        <div className="my-5 rounded-lg border p-4">
          <div className="text-sm font-semibold">
            {isTeacher
              ? t('widgets.type.student_completed_lesson')
              : t('widgets.type.title_completed_lesson')}
          </div>

          {(isTeacher || course?.courseType !== 'assessment') && (
            <>
              <div className="py-2 text-sm font-normal text-[#3257fc]">
                {t('widgets.type.final_score')}
                &nbsp;
                <span className="font-semibold">
                  {toPercent(
                    status?.score || result?.score,
                    status?.maxScore || result?.maxScore
                  )}
                  %
                </span>
              </div>

              <div className="my-3 rounded-lg bg-[#1e1e200d] py-2 px-3">
                <div className="text-xs font-normal uppercase text-[#919193]">
                  {t('widgets.type.feedback')}
                  &nbsp;
                  {result?.teacherName}
                </div>
                <div className="flex items-center py-2">
                  <PersonAvatar
                    inline
                    id={student?.id}
                    name={student?.username}
                    imageSrc={student?.picture}
                  ></PersonAvatar>
                  <div className="pl-2 text-sm font-medium">
                    {student.username}
                  </div>
                </div>
                <div
                  className="text-sm font-normal text-[#4d4d50]"
                  dangerouslySetInnerHTML={{ __html: result?.feedback }}
                ></div>
              </div>
              <Button
                className="my-2"
                variant="outline"
                size="md"
                onClick={() =>
                  navigate(
                    isTeacher ? `${followupPath}?view_les=true` : learnPath
                  )
                }
              >
                {isTeacher
                  ? t('widgets.type.view_his_answers_button')
                  : t('widgets.type.view_your_answers_button')}
              </Button>
            </>
          )}

          {!isTeacher && course?.courseType == 'assessment' && (
            <>
              <div className="my-3 rounded-lg bg-[#1e1e200d] py-2 px-3">
                <b>{t('widgets.type.result_hidden')}</b>
              </div>
            </>
          )}
        </div>
      )}

      {status?.status === 'submitted' && (
        <div className="my-5 rounded-lg border p-4">
          <div className="text-sm font-semibold">
            {isTeacher
              ? t('widgets.type.title_completed_lesson_teacher')
              : t('widgets.type.title_completed_lesson')}
          </div>

          <div className="my-3 rounded-lg bg-[#1e1e200d] py-2 px-3">
            <div className="text-xs font-normal uppercase text-[#919193]">
              {t('widgets.type.feedback')}
              &nbsp;
              {result?.teacherName}
            </div>
            <div className="flex items-center py-2">
              <PersonAvatar
                inline
                id={student?.id}
                name={student?.username}
                imageSrc={student?.picture}
              ></PersonAvatar>
              <div className="pl-2 text-sm font-medium">
                {student?.username}
              </div>
            </div>
            <div className="text-sm font-normal text-[#4d4d50]">
              {isTeacher
                ? t('widgets.type.submitted_feedback_teacher')
                : t('widgets.type.submitted_feedback')}
            </div>
          </div>
        </div>
      )}

      {isTeacher && status?.status === 'stored' && (
        <div className="my-5 rounded-lg border bg-[#1e1e200d] p-4">
          <div className="text-sm font-semibold">
            {t('widgets.type.stored')}
          </div>
        </div>
      )}

      {isTeacher &&
        (!status?.status ||
          status?.status == 'started' ||
          status?.status == 'completed') && (
          <div className="my-5 rounded-lg border bg-[#1e1e200d] p-4">
            <div className="text-sm font-semibold">
              {t('widgets.type.not_started')}
            </div>
          </div>
        )}

      {resultsLoaded && showContent && (
        <FroalaTextareaView
          correctionType={course?.correctionType}
          showAnswers={showAnswers}
          key={rerenderView}
          value={splittedContent}
          correction={result?.widgets}
          page={page}
          isCorrected={isCorrected}
          setWidgets={(widgets: any) => {
            for (const subscription of subscriptions.current) {
              subscription.unsubscribe();
            }

            const widgetValues = Object.values(widgets);
            subscriptions.current = [
              ...widgetValues.map((widget: any) =>
                widget.onModified.subscribe(
                  ((widget: any) => {
                    modifiedWidgets.current.add(widget.state.uid);
                    if (doAutosave && !saveTimer.current) {
                      //queue an autosave
                      saveTimer.current = setTimeout(() => {
                        getSubmit();
                      }, AUTOSAVE_DELAY * 1000);
                    }
                  }).bind(null, widget)
                )
              ),
              ...widgetValues.map((widget: any) =>
                widget.onSave.subscribe(
                  (({ submit, force, triggerWidget }: any) => {
                    getSubmit(submit, force, triggerWidget);
                  }).bind(null)
                )
              ),
            ];
            setWidgets(widgets);
          }}
          setIsLoaded={setIsLoaded}
        />
      )}
    </>
  );
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ErrorBox } from '@/components/ErrorBox';
import {
  Button,
  ButtonLink,
  ErrorMessage,
  IconButton,
  Label,
} from '@teo/components';
import { CloseCircle, TaskCheckmark, EyeOff, Eye } from '@teo/components/icons';
import { passwordRegex } from '@/constants/regex.constants';
import { AxiosError } from 'axios';
import { confirmResetPassword } from '@/query/auth';

function SetNewPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [errorText, setErrorText] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasAuthError, setAuthError] = useState(false);
  const [passwordWasTouched, setPasswordWasTouched] = useState(false);
  const [confirmPasswordWasTouched, setConfirmPasswordWasTouched] =
    useState(false);
  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [requiredLength] = useState(8);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const urlParam = new URLSearchParams(window.location.search);
    const token = urlParam.get('token');

    if (token) {
      setToken(token);
    }
  });

  useEffect(() => {
    setValidLength(password.length >= requiredLength);
    setUpperCase(password.toLowerCase() !== password);
    setLowerCase(password.toUpperCase() !== password);
    setHasNumber(/\d/.test(password));
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
  }, [password, requiredLength]);

  const backToTop = () => {
    (document.getElementById('userForm') as HTMLFormElement).scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function submitSetNewPassword() {
    if (
      !(
        passwordRegex &&
        validLength &&
        hasNumber &&
        upperCase &&
        lowerCase &&
        requiredLength &&
        specialChar
      ) ||
      password === '' ||
      confirmPassword === ''
    ) {
      setErrorText(t('pages.auth.forgot_password.password_Error'));
    } else if (confirmPassword !== password) {
      setAuthError(true);
      backToTop();
      setErrorText(t('pages.auth.register.password_mismatch'));
    } else {
      confirmResetPassword(password, token)
        .then(() => {
          navigate('/login');
        })
        .catch((e: AxiosError<{ message: string }>) => {
          setAuthError(true);

          const message = e?.response?.data?.message || e.toString();
          switch (message) {
            case 'User with this email already exists':
              setErrorText(t('pages.auth.register.registrationErrorMassage'));
              break;
            default:
              setErrorText(message);
              break;
          }

          console.error('Error', e);
          console.error('redirecting');
        });
    }
  }

  return (
    <div className="h-full overflow-hidden md:flex md:items-center">
      <div
        className="flex h-full overflow-hidden md:w-1/2 md:items-center md:overflow-auto"
        id="userForm"
      >
        <form
          className="m-auto flex w-full max-w-[420px] flex-col max-md:h-full max-md:overflow-hidden"
          onSubmit={(event) => {
            event.preventDefault();
            submitSetNewPassword();
          }}
        >
          <div className="space-y-6 px-5 pt-8 max-md:flex-grow max-md:overflow-auto md:space-y-8">
            <img
              className="h-12 w-12 md:h-16 md:w-16"
              src="/TEO_logo.svg"
              alt="Logo"
            />

            <h1 className="text-3xl-semibold">
              {t('pages.auth.forgot_password.set_new_password')}
            </h1>

            {errorText && <ErrorBox text={errorText} />}

            <div>
              <Label htmlFor="password">
                {t('pages.auth.forgot_password.new_password_label')}
              </Label>
              <div className="relative space-y-2 text-grey-08">
                <IconButton
                  Icon={showPassword ? Eye : EyeOff}
                  variant="ghost"
                  size="sm"
                  className="absolute right-[7px] top-[13px] z-10"
                  onClick={() => {
                    if (!showPassword) {
                      setShowPassword(true);
                    } else {
                      setShowPassword(false);
                    }
                  }}
                />
                <input
                  id="password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  placeholder={t('pages.auth.register.password_placeholder')}
                  type={showPassword ? 'text' : 'password'}
                  required
                  className="h-10 w-full rounded-md border border-solid border-transparent px-3 py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02"
                  onBlur={(e) => {
                    if (
                      !(
                        passwordRegex &&
                        validLength &&
                        hasNumber &&
                        upperCase &&
                        lowerCase &&
                        requiredLength &&
                        specialChar
                      ) ||
                      e.target.value === ''
                    ) {
                      setPasswordWasTouched(true);
                    } else {
                      setPasswordWasTouched(false);
                    }
                  }}
                  // hasError={passwordWasTouched}
                />
              </div>

              {!passwordWasTouched ||
                (!(passwordRegex && validLength && requiredLength) && (
                  <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                    <ErrorMessage id="errorMessage">
                      {t('pages.auth.register.errorMassage')}
                      &nbsp;
                      {t('pages.auth.register.characters_long')}
                    </ErrorMessage>
                  </div>
                ))}
              {!passwordWasTouched ||
                (!lowerCase && (
                  <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                    <ErrorMessage id="errorMessage">
                      {t('pages.auth.register.errorMassage')}
                      &nbsp;
                      {t('pages.auth.register.lowercase_letter')}
                    </ErrorMessage>
                  </div>
                ))}
              {!passwordWasTouched ||
                (!upperCase && (
                  <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                    <ErrorMessage id="errorMessage">
                      {t('pages.auth.register.errorMassage')}
                      &nbsp;
                      {t('pages.auth.register.capital_letter')}
                    </ErrorMessage>
                  </div>
                ))}
              {!passwordWasTouched ||
                (!hasNumber && (
                  <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                    <ErrorMessage id="errorMessage">
                      {t('pages.auth.register.errorMassage')}
                      &nbsp;
                      {t('pages.auth.register.digit_number')}
                    </ErrorMessage>
                  </div>
                ))}
              {!passwordWasTouched ||
                (!specialChar && (
                  <div className="mt-2 mb-2 text-sm text-tint-red-dark">
                    <ErrorMessage id="errorMessage">
                      {t('pages.auth.register.errorMassage')}
                      &nbsp;
                      {t('pages.auth.register.symbol_text')}
                    </ErrorMessage>
                  </div>
                ))}

              <div className="pt-3 pb-5 text-xs text-grey-07">
                {t('pages.auth.register.password_must')}
                <div className="mt-2 flex list-none flex-col">
                  <div className="flex items-center pt-2">
                    {passwordWasTouched &&
                      (!validLength || !requiredLength ? (
                        <IconButton
                          Icon={CloseCircle}
                          variant="ghost"
                          size="sm"
                          className="text-tint-red-dark"
                        />
                      ) : (
                        <IconButton
                          Icon={TaskCheckmark}
                          variant="ghost"
                          size="sm"
                          className="text-success-05"
                        />
                      ))}

                    {!passwordWasTouched && !password && (
                      <span className="pr-2">-</span>
                    )}
                    <span>{t('pages.auth.register.characters_long')}</span>
                  </div>
                  <div className="flex items-center pt-2">
                    {passwordWasTouched &&
                      (!lowerCase ? (
                        <IconButton
                          Icon={CloseCircle}
                          variant="ghost"
                          size="sm"
                          className="text-tint-red-dark"
                        />
                      ) : (
                        <IconButton
                          Icon={TaskCheckmark}
                          variant="ghost"
                          size="sm"
                          className="text-success-05"
                        />
                      ))}

                    {!password && <span className="pr-2">-</span>}
                    <span>{t('pages.auth.register.lowercase_letter')}</span>
                  </div>
                  <div className="flex items-center pt-2">
                    {passwordWasTouched &&
                      (!upperCase ? (
                        <IconButton
                          Icon={CloseCircle}
                          variant="ghost"
                          size="sm"
                          className="text-tint-red-dark"
                        />
                      ) : (
                        <IconButton
                          Icon={TaskCheckmark}
                          variant="ghost"
                          size="sm"
                          className="text-success-05"
                        />
                      ))}

                    {!password && <span className="pr-2">-</span>}
                    <span>{t('pages.auth.register.capital_letter')}</span>
                  </div>
                  <div className="flex items-center pt-2">
                    {passwordWasTouched &&
                      (!hasNumber ? (
                        <IconButton
                          Icon={CloseCircle}
                          variant="ghost"
                          size="sm"
                          className="text-tint-red-dark"
                        />
                      ) : (
                        <IconButton
                          Icon={TaskCheckmark}
                          variant="ghost"
                          size="sm"
                          className="text-success-05"
                        />
                      ))}

                    {!password && <span className="pr-2">-</span>}
                    <span>{t('pages.auth.register.digit_number')}</span>
                  </div>
                  <div className="flex items-center pt-2">
                    {passwordWasTouched &&
                      (!specialChar ? (
                        <IconButton
                          Icon={CloseCircle}
                          variant="ghost"
                          size="sm"
                          className="text-tint-red-dark"
                        />
                      ) : (
                        <IconButton
                          Icon={TaskCheckmark}
                          variant="ghost"
                          size="sm"
                          className="text-success-05"
                        />
                      ))}

                    {!password && <span className="pr-2">-</span>}
                    <span>{t('pages.auth.register.symbol_text')}</span>
                  </div>
                </div>
              </div>

              <div>
                <Label htmlFor="confirmPassword">
                  {t('pages.auth.forgot_password.repeat_new_password_label')}
                </Label>
                <div className="relative space-y-2 text-grey-08">
                  <IconButton
                    Icon={showConfirmPassword ? Eye : EyeOff}
                    variant="ghost"
                    size="sm"
                    className="absolute right-[7px] top-[13px] z-10"
                    onClick={() => {
                      if (!showConfirmPassword) {
                        setShowConfirmPassword(true);
                      } else {
                        setShowConfirmPassword(false);
                      }
                    }}
                  />
                  <input
                    id="confirmPassword"
                    onChange={(event) => {
                      setConfirmPassword(event.target.value);
                    }}
                    placeholder={t(
                      'pages.auth.register.confirm_password_placeholder'
                    )}
                    type={showConfirmPassword ? 'text' : 'password'}
                    required
                    onBlur={(e) => {
                      if (
                        confirmPassword !== password ||
                        e.target.value === ''
                      ) {
                        setConfirmPasswordWasTouched(true);
                        setErrorText(
                          t('pages.auth.register.password_mismatch')
                        );
                      } else {
                        setConfirmPasswordWasTouched(false);
                      }
                    }}
                    className="h-10 w-full rounded-md border border-solid border-transparent px-3 py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-5 py-8 max-md:flex max-md:flex-col max-md:space-y-3 md:grid md:grid-cols-2 md:gap-4">
            <Button type="submit">
              {t('pages.auth.forgot_password.save_password_button')}
            </Button>

            <ButtonLink to="../login" variant="outline">
              {t('pages.auth.forgot_password.cancel_button')}
            </ButtonLink>
          </div>
        </form>
      </div>

      <div className="w-1/2 max-md:hidden md:flex md:h-[100vh] md:items-center md:justify-center">
        <img
          className="h-full w-full object-cover"
          role="presentation"
          src="/images/interface/auth/login.jpg"
          alt="login_image"
        />
      </div>
    </div>
  );
}

export default SetNewPassword;

import { useTranslation } from 'react-i18next';
import LearnCourseOverview from './LearnCourseOverview/LearnCourseOverview';

function LearnCoursesPage() {
  const { t } = useTranslation();

  return (
    <LearnCourseOverview
      courseType={'course'}
      title={t('pages.learn_courses.title')}
    />
  );
}

export default LearnCoursesPage;

import { useState, useContext, useEffect, useReducer } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import {
  InputText,
  InputSelect,
  Label,
  Button,
  InputDate,
  Switch,
  IconButton,
  PersonAvatar,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { InputSelectOption } from '@/models/organisation.interface';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import { axios } from '@/axios';
import { config } from '@/config';
import DownloadReportModal from '../DownloadReportModal';
import ReportTeamsDataTable from '@/components/ReportDataTable/ReportTeamsDataTable';
import { languages } from '@/constants/languages';

const reportTypeList = [
  {
    value: 'studentOverview',
    label: 'report.studentOverview',
  },
  {
    value: 'courseOverview',
    label: 'report.courseOverview',
  },
  {
    value: 'courseCsv',
    label: 'report.courseCsv',
  },
  {
    value: 'coursedetailsCsv',
    label: 'report.coursedetailsCsv',
  },
  {
    value: 'certificate',
    label: 'report.certificate',
  },
  {
    value: 'usersCsv',
    label: 'report.usersCsv',
  },
];

type reportDownload = {
  reportName: string;
  teamsReport: any;
  fromDate: any;
  untilDate: any;
  fileType: any;
  reportEmail: boolean;
};

function TeamsReport() {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [untilDate, setUntilDate] = useState<Date | undefined>();
  const [reportSubteams, setReportSubteams] = useState<boolean>(true);
  const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
  const [isOpenDownloadtModal, setIsOpenDownloadtModal] = useState(false);
  const [allTeams, setAllTeams] = useState(false);
  const [disabledAll, setDisabledAll] = useState(true);
  const [selectType, setSelectType] = useState(reportTypeList[0].value);
  const [overviewUsers, setOverviewUsers] = useState(false);

  const reportState = {
    reportName: '',
    teamsReport: [],
    fromDate,
    untilDate,
    reportType: {
      label: reportTypeList[0].label,
      value: reportTypeList[0].value,
    },
    reportEmail: true,
    lang: languages?.find((option) => option.value === auth?.user?.lang),
  };

  const [stateReport, updateStateReport] = useReducer(
    (state: reportDownload, updates: any) => ({ ...state, ...updates }),
    reportState
  );

  const handleAddTeamst = async (newValue: any) => {
    if (reportSubteams) {
      const addTeam = [...stateReport.teamsReport];
      for (const obj2 of newValue) {
        if (obj2?.id) {
          const subTeams: any = await axios.get(
            `${config.backend}/teams?organisationId=${
              obj2?.organisationId
            }&searchPath=${encodeURIComponent(
              obj2?.teamPath + '%'
            )}&sort=teamPath`
          );
          if (subTeams?.data && subTeams?.data?.length > 0) {
            const teamSubteams = subTeams?.data;
            for (const sub of teamSubteams) {
              const existingTeam = addTeam.find(
                (obj1) => obj1?.id && obj1?.id === sub?.id
              );
              const existingTeam_2 = stateReport.teamsReport.find(
                (obj1: any) => obj1?.id && obj1?.id === sub?.id
              );
              if (!existingTeam && !existingTeam_2) {
                addTeam.push(sub);
              }
            }
          }
        }
      }
      addTeam.sort((a, b) => {
        const alphabetComparison = a.teamPath.localeCompare(b.teamPath);
        if (alphabetComparison !== 0) {
          return alphabetComparison;
        }
        return a.teamPath.length - b.teamPath.length;
      });
      updateStateReport({ teamsReport: addTeam });
    } else {
      const addTeam = [...stateReport.teamsReport];
      for (const obj2 of newValue) {
        if (obj2?.id) {
          const existingTeam = stateReport.teamsReport.find(
            (obj1: any) => obj1?.id && obj1?.id === obj2?.id
          );
          if (!existingTeam) {
            addTeam.push(obj2);
          }
        }
      }
      addTeam.sort((a, b) => {
        const alphabetComparison = a.teamPath.localeCompare(b.teamPath);
        if (alphabetComparison !== 0) {
          return alphabetComparison;
        }
        return a.teamPath.length - b.teamPath.length;
      });
      updateStateReport({ teamsReport: addTeam });
    }
    setSelectedTeams([]);
  };
  const handleRemove = (id: any) => {
    const removeTeam = stateReport.teamsReport?.filter(
      (item: any) => item.id !== id
    );
    updateStateReport({ teamsReport: removeTeam });
  };

  useEffect(() => {
    if (
      selectType == 'studentOverview' ||
      selectType == 'courseOverview' ||
      selectType == 'certificate'
    ) {
      setDisabledAll(true);
      setAllTeams(false);
    } else {
      if (selectType == 'usersCsv') {
        setOverviewUsers(true);
        setDisabledAll(true);
        setAllTeams(false);
      } else {
        setDisabledAll(false);
        setAllTeams(false);
      }
    }
  }, [selectType]);

  return (
    <div className="gap- flex flex-col">
      <div className="mt-6 rounded-lg border border-grey-02 p-6">
        <div className="flex max-w-xl flex-col">
          <p className="pb-2 text-base font-semibold text-black">
            {t('pages.report_page.teams_tab.title_1')}
          </p>
          <p className="text-base text-grey-06">
            {t('pages.report_page.teams_tab.subtitle_1')}
          </p>

          <div className="mb-1 mt-4 min-w-full max-w-[300px] text-sm font-semibold text-black">
            <p className="mb-1">
              {t('pages.report_page.teams_tab.type_of_report')}
            </p>
            <div className="font-normal">
              <InputSelect<InputSelectOption>
                value={stateReport.reportType}
                onChange={(e: InputSelectOption) => {
                  setSelectType(e?.value);
                  updateStateReport({ reportType: e });
                }}
                options={reportTypeList}
              >
                {(options) => <span>{options.label && t(options.label)}</span>}
              </InputSelect>
            </div>
          </div>

          <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
            <div data-testid="reportEmail">
              <Switch
                checked={allTeams}
                disabled={disabledAll}
                onChange={(isCheck) => setAllTeams(isCheck)}
              />
            </div>
            <div>
              <h4 className="font-normal">
                {t('pages.report_page.select_teams')}
              </h4>
            </div>
          </div>

          {!allTeams && (
            <>
              <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div data-testid="reportEmail">
                  <Switch
                    checked={reportSubteams}
                    onChange={(isCheck) => setReportSubteams(isCheck)}
                  />
                </div>
                <div>
                  <h4 className="font-normal">
                    {t('pages.report_page.teams_tab.include_subteams')}
                  </h4>
                </div>
              </div>
              <div className="z-[1] mb-1 mt-4 text-sm font-semibold text-black">
                <p className="mb-1">{t('pages.report_page.teams_tab.teams')}</p>
                <div className="flex">
                  <span className="w-full">
                    <PaginationMultiSelect
                      id="teams-report"
                      value={selectedTeams}
                      onChange={(value: any) => {
                        setSelectedTeams(value);
                      }}
                      keyFn={(row: any) => row?.id}
                      fetch={useGet.bind(
                        null,
                        `/teams?organisationId=${auth?.user?.activeOrganisationId}`
                      )}
                      defaultSort={[{ id: 'teamPath', desc: false }]}
                      render={(row: any) => {
                        return row?.teamName;
                      }}
                    />
                  </span>
                  <Button
                    className="ml-3 h-[36px] text-base font-medium"
                    variant="primary"
                    disabled={selectedTeams.length === 0}
                    onClick={async () => {
                      handleAddTeamst(selectedTeams);
                    }}
                  >
                    {t('button.add_btn')}
                  </Button>
                </div>
                {stateReport?.teamsReport?.length > 0 && (
                  <div className="mt-6">
                    {stateReport?.teamsReport?.map((team: any) => (
                      <div
                        key={team.id !== null ? team.teamName : team.id}
                        className="mb-4 flex items-center justify-between"
                      >
                        <div
                          style={{
                            marginLeft:
                              '' +
                              (team.teamPath.split(';;').length - 1) +
                              'rem',
                          }}
                          className="flex items-center"
                        >
                          <PersonAvatar
                            inline
                            id={team.id}
                            name={team.teamName}
                            bgColor={team.color ? team.color : null}
                          ></PersonAvatar>
                          <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                            {team.teamName}
                          </p>
                        </div>
                        <div data-testid="daleteItem" className="my-auto ml-6">
                          <IconButton
                            Icon={CrossFilled}
                            variant="ghost"
                            onClick={() => handleRemove(team.id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="mt-3">
            <Label htmlFor="language">
              {t('pages.profile_account.language')}
            </Label>
            <div id="language">
              <InputSelect<InputSelectOption>
                value={stateReport.lang}
                onChange={async (language) => {
                  updateStateReport({ lang: language });
                }}
                options={languages}
              >
                {(options) => t(options.label)}
              </InputSelect>
            </div>
          </div>

          {!overviewUsers && (
            <div className="mt-7 flex flex-col gap-5 pr-2 sm:flex-row lg:pr-0">
              <div className="basis-1/2" data-testid="dateStart">
                <Label htmlFor="dateStart">
                  {t('pages.report_page.teams_tab.from')}
                </Label>
                <InputDate
                  id="dateStart"
                  onChange={(date) => updateStateReport({ fromDate: date })}
                  value={stateReport.fromDate}
                  variant="date"
                />
              </div>
              <div className="basis-1/2" data-testid="dateEnd">
                <Label htmlFor="dateEnd">
                  {t('pages.report_page.teams_tab.until')}
                </Label>
                <InputDate
                  id="dateEnd"
                  onChange={(date) => updateStateReport({ untilDate: date })}
                  value={stateReport.untilDate}
                  variant="date"
                />
              </div>
            </div>
          )}

          <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
            <div data-testid="reportEmail">
              <Switch
                checked={stateReport.reportEmail}
                onChange={(isCheck) =>
                  updateStateReport({ reportEmail: isCheck })
                }
              />
            </div>
            <div>
              <h4 className="pb-2 font-normal">
                {t('pages.report_page.teams_tab.toggle_email_heading')}
              </h4>
              <p className="text-xs text-grey-05">
                {t('pages.report_page.teams_tab.toggle_email_text')}
              </p>
            </div>
          </div>
          <div className="flex pt-6">
            <Button
              onClick={() => setIsOpenDownloadtModal(true)}
              disabled={stateReport.teamsReport?.length < 1 && !allTeams}
            >
              {t('pages.report_page.teams_tab.btn_download')}
            </Button>
          </div>
        </div>
      </div>

      <ReportTeamsDataTable reportTypeList={reportTypeList} />

      {isOpenDownloadtModal && (
        <DownloadReportModal
          report={'teams'}
          isOpenDownloadtModal={isOpenDownloadtModal}
          closeModal={() => setIsOpenDownloadtModal(false)}
          setIsOpenDownloadtModal={setIsOpenDownloadtModal}
          stateReport={stateReport}
          user={auth?.user}
          updateStateReport={updateStateReport}
          allSelect={allTeams ? undefined : true}
        />
      )}
    </div>
  );
}

export default TeamsReport;

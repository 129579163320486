import { useTranslation } from 'react-i18next';
import LearnCourseOverview from '@/pages/Protected/Learn/LearnCourseOverview/LearnCourseOverview';

function LearnVacanciesPage() {
  const { t } = useTranslation();

  return (
    <LearnCourseOverview
      courseType={'leervacature'}
      title={t('pages.learn_vacancies.title')}
    />
  );
}

export default LearnVacanciesPage;

import { useEffect, useContext, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavContext } from '@/components/NavContext';
import {
  IconButton,
  InputText,
  InputSelect,
  Label,
  Button,
  ErrorMessage,
} from '@teo/components';
import { CrossFilled, Location } from '@teo/components/icons';
import {
  businessFormOptions,
  organisationTypeOptions,
} from '@/constants/option.constants';
import UploadDropzoneSection from '@/components/UploadDropzoneSection/UploadDropzoneSection';

import { axios } from '@/axios';
import { config } from '@/config';
import {
  InputSelectOption,
  OrganisationState,
  ContactState,
} from '@/models/organisation.interface';
import { AxiosError } from 'axios';
import { ErrorBox } from '@/components/ErrorBox';

function CreateOrganisation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNavDisabled } = useContext(NavContext);
  const [companyType, setCompanyType] = useState<InputSelectOption>();
  const [organisationType, setOrganisationType] = useState<InputSelectOption>();
  const [uploadLogo, setUploadLogo] = useState();
  const [checkValid, setCheckValid] = useState<boolean>(false);
  const [newOrganization, setNewOrganization] = useState<any>(null);

  const [errorText, setErrorText] = useState('');
  const [hasError, setError] = useState(false);

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  const initialState = {
    organizationName: '',
    tradeName: '',
    enterprise: '',
    address: '',
    correspondence: '',
  };

  const stateContact = {
    department: '',
    name: '',
    email: '',
    phoneNumber: '',
    function: '',
  };

  const [state, updateState] = useReducer(
    (state: OrganisationState, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  const [contact, updateContact] = useReducer(
    (state: ContactState, updates: any) => ({ ...state, ...updates }),
    stateContact
  );

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  const handleCreate = () => {
    setCheckValid(true);
    if (state?.organizationName.length > 0) {
      const promises = [];
      promises.push(
        axios.post(`${config.backend}/organisations`, {
          id: null,
          picture: uploadLogo ? uploadLogo : null,
          organisationName: state?.organizationName,
          organisationCommercialName: state?.tradeName,
          entityNumber: state?.enterprise,
          form: companyType?.value,
          type: organisationType?.value,
          seat: { street: '', city: state?.address },
          contact: {
            name: contact?.name,
            department: contact?.department,
            function: contact?.function,
            email: contact?.email,
            phoneNumber: contact?.phoneNumber,
            correspondenceAddress: { street: '', city: state?.correspondence },
          },
        })
      );

      Promise.allSettled(promises).then((results: any) => {
        if (results[0]?.status === 'fulfilled') {
          setNewOrganization(results[0]?.value?.data);
        }
      });
      // navigate(-1);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (newOrganization) {
      axios
        .post(`${config.backend}/teams`, {
          autoGenerated: true,
          createdAt: new Date().toJSON(),
          organisationId: newOrganization?.id,
          color: '#FCB900',
          teamPath: '_organisation_',
          teamName: newOrganization?.organisationName,
        })
        .catch((e: AxiosError<{ message: string }>) => {
          setError(true);

          const message = e?.response?.data?.message || e.toString();
          switch (message) {
            case 'Validation error':
              setErrorText(t('pages.manage_teams.warm_message'));
              break;
            default:
              setErrorText(message);
              break;
          }

          console.error('Error', e);
          console.error('redirecting');
        });
      setNewOrganization(null);
      navigate(-1);
    }
  }, [newOrganization]);

  return (
    <div className="bg-white">
      <div
        className="flex min-h-16 items-center justify-start"
        data-testid="header"
      >
        <IconButton
          className="my-auto mx-6"
          Icon={CrossFilled}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <div className="flex items-center">
          <div className="mr-5 h-12 w-12 rounded-full bg-secondary-04 p-3">
            <img
              src="/images/components/organisation/organisation.svg"
              className="mx-auto w-72 "
            />
          </div>
          <span> {t('links.organisation_create')}</span>
        </div>
      </div>
      <div className="flex w-full border-t border-grey-02 px-8">
        <div className="mx-auto w-full max-w-lg divide-y divide-solid divide-grey-02 pt-8">
          <div className="pb-8">
            <p className="text-md font-semibold text-black">
              {t('pages.organisation_details.details.title')}
            </p>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="organisation-name">
                {t('pages.organisation_details.details.organisation_name')}
              </Label>
              <InputText
                id="organisation-name"
                onChange={(e) =>
                  updateState({ organizationName: e.currentTarget.value })
                }
                value={state?.organizationName}
                hasError={state?.organizationName.length < 1 && checkValid}
              />
              {state?.organizationName.length < 1 && checkValid && (
                <div className="mt-2s">
                  <ErrorMessage id="errorMessage">
                    {t('pages.organisation_details.error_text_fiels')}
                  </ErrorMessage>
                </div>
              )}
            </div>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="trade-name">
                {t('pages.organisation_details.details.trade_name')}
              </Label>
              <InputText
                id="trade-name"
                onChange={(e) =>
                  updateState({ tradeName: e.currentTarget.value })
                }
                value={state?.tradeName}
              />
            </div>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="enterprise-name">
                {t('pages.organisation_details.details.enterprise_number')}
              </Label>
              <InputText
                id="enterprise-name"
                onChange={(e) =>
                  updateState({ enterprise: e.currentTarget.value })
                }
                value={state?.enterprise}
                prefix="BE"
              />
            </div>
            <p className="mb-1 mt-6 text-sm font-semibold text-black">
              {t('pages.organisation_details.details.company_type')}
            </p>
            <div className="sm:max-w-[285px]" data-testid="company-type">
              <InputSelect<InputSelectOption>
                value={companyType}
                onChange={(e: InputSelectOption) => setCompanyType(e)}
                options={businessFormOptions}
              >
                {(options) => (
                  <span>
                    {options.label && t(`company_type.${options.label}`)}
                  </span>
                )}
              </InputSelect>
            </div>

            <p className="mb-1 mt-6 text-sm font-semibold text-black">
              {t('pages.organisation_details.details.organisation_type')}
            </p>
            <div className="sm:max-w-[285px]" data-testid="organisation-type">
              <InputSelect<InputSelectOption>
                value={organisationType}
                onChange={(e: InputSelectOption) => setOrganisationType(e)}
                options={organisationTypeOptions}
              >
                {(options) => (
                  <span>
                    {options.label &&
                      t(`pages.organisation.type.${options.label}`)}
                  </span>
                )}
              </InputSelect>
            </div>
            <div>
              <p className="mb-1 mt-6 text-sm font-semibold text-black">
                {t('pages.organisation_details.details.add_logo')}
              </p>
              <UploadDropzoneSection setUploadLogo={setUploadLogo} />
            </div>
          </div>
          <div className="py-8">
            <p className="text-md font-semibold text-black">
              {t('pages.organisation_details.details.seat')}
            </p>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="address">
                {t('pages.organisation_details.details.address')}
              </Label>
              <InputText
                id="address"
                Icon={Location}
                placeholder={t(
                  'pages.organisation_details.details.location_placeholder'
                )}
                onChange={(e) =>
                  updateState({ address: e.currentTarget.value })
                }
                value={state?.address}
                data-testid="address"
              />
            </div>
          </div>
          <div className="py-8">
            <p className="text-md font-semibold text-black">
              {t('pages.organisation_details.details.correspondence_address')}
            </p>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="correspondence-address">
                {t('pages.organisation_details.details.address')}
              </Label>
              <InputText
                Icon={Location}
                id="correspondence-address"
                placeholder={t(
                  'pages.organisation_details.details.location_placeholder'
                )}
                onChange={(e) =>
                  updateState({ correspondence: e.currentTarget.value })
                }
                value={state?.correspondence}
                data-testid="correspondence-address"
              />
            </div>
          </div>
          <div className="py-8">
            <p className="text-md font-semibold text-black">
              {t('pages.organisation_details.details.contact')}
            </p>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="contact-name">
                {t('pages.organisation_details.details.contact_name')}
              </Label>
              <InputText
                id="contact-name"
                onChange={(e) => updateContact({ name: e.currentTarget.value })}
                value={contact?.name}
              />
            </div>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="function">
                {t('pages.organisation_details.details.function')}
              </Label>
              <InputText
                id="function"
                onChange={(e) =>
                  updateContact({ function: e.currentTarget.value })
                }
                value={contact?.function}
              />
            </div>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="department">
                {t('pages.organisation_details.details.department')}
              </Label>
              <InputText
                id="department"
                onChange={(e) =>
                  updateContact({ department: e.currentTarget.value })
                }
                value={contact?.department}
              />
            </div>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="phone">
                {t('pages.organisation_details.details.phone')}
              </Label>
              <InputText
                id="phone"
                onChange={(e) =>
                  updateContact({ phoneNumber: e.currentTarget.value })
                }
                value={contact?.phoneNumber}
              />
            </div>
            <div className="mb-1 mt-6 text-sm font-semibold text-black">
              <Label htmlFor="email">
                {t('pages.organisation_details.details.email')}
              </Label>
              <InputText
                id="email"
                onChange={(e) =>
                  updateContact({ email: e.currentTarget.value })
                }
                value={contact?.email}
              />
            </div>
            {hasError && <ErrorBox text={errorText} />}
            <div className="flex justify-between py-8">
              <Button variant="outline" onClick={() => navigate(-1)}>
                {t('pages.organisation_details.button_cancel')}
              </Button>
              <Button onClick={handleCreate}>
                {t('pages.organisation_details.button_add')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateOrganisation;

import { useState, Fragment, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@/components/DataTable/DataTable';
import { format } from 'date-fns';
import { config } from '@/config';
import { T } from '@/components/T';
import { Button, InputText } from '@teo/components';
import { Search } from '@teo/components/icons';
import { newUid } from '@/util';
import TagModal from './TagModal';
import OptionsAction from '@/components/DataTable/OptionsAction';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';

function ManageTagsPage() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [isOpenTagModal, setIsOpenTagModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState<any>(true);
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [tags, setTags] = useState<any>(null);
  const [isTagIndex, setIsTagIndex] = useState<string>('');

  const [selected, setSelected] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselect, setDeselect] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);

  useGet('/tags', {
    onSuccess: (result: any) => {
      setTags(result?.data || {});
    },
  });

  const handleEdit = (id: any) => {
    setIsOpenTagModal(true);
    setIsAddModal(false);
    setIsTagIndex(id);
  };

  return (
    <div className="max-w-6xl pb-8">
      <div className="flex w-full items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{t('pages.manage_tags.title')}</T>
        </h1>

        <div className="flex items-center">
          <Button
            data-testid="addTagModal"
            onClick={() => {
              setIsOpenTagModal(true);
              setIsAddModal(true);
            }}
            size="md"
          >
            {t('pages.manage_tags.add_tag_button')}
          </Button>
          {/* <div className="pl-2">
            <IconButton
              Icon={MoreVertical}
              variant="ghost"
              className="text-[#919193]"
            />
          </div> */}
        </div>
      </div>
      <Fragment>
        <div className="pb-8 sm:w-2/3 md:w-1/2" data-testid="tagsTable">
          <InputText
            value={filter}
            onChange={(ev) => setFilter(ev.target.value)}
            Icon={Search}
            placeholder={t('search.course')}
            data-testid="searchTag"
          />
        </div>
        <DataTable
          filter={filter}
          fetch={useGet.bind(
            null,
            `/tags?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
          )}
          empty={{
            template: 1,
            text: t('empty_tenplate.tag_list'),
            btn_text: t('pages.manage_tags.add_tag_button'),
            btnClick: () => {
              setIsOpenTagModal(true);
              setIsAddModal(true);
            },
          }}
          key={refetchTable}
          screensUpdateTable="mobile"
          selectable={{
            selected: selected,
            setSelected: setSelected,
            deselect: deselect,
            setDeselect: setDeselect,
          }}
          defaultSort={[
            { id: 'tag', desc: false },
            { id: 'createdAt', desc: false },
          ]}
          columns={[
            {
              title: 'Tag',
              id: 'tag',
              className: 'col-start-2 col-end-9  row-start-1',
              render: (tag: any) => (
                <div className="flex items-center text-sm font-medium">
                  {tag.tag}
                </div>
              ),
            },
            {
              addTitleOnMobile: true,
              id: 'createdAt',
              title: t('columns.date_created'),
              align: 'right',
              className:
                'col-start-1 col-end-10 row-start-2 justify-end text-right',
              render: (tag: any) =>
                format(new Date(tag.createdAt), 'dd/MM/yyyy'),
            },
            {
              title: '',
              id: 'edit',
              sortable: false,
              className: 'col-start-9 row-start-1 justify-end',
              render: (tag: any) => (
                <div className="flex justify-end">
                  {tag.organisationId !== null && (
                    <OptionsAction
                      delete={{
                        title: t('pages.manage_tags.delete_tag'),
                        request: `${config.backend}/tag/${tag.id}?organisationId=${auth?.user?.activeOrganisationId}`,
                        selected: selected,
                        setSelected: setSelected,
                      }}
                      edit={{
                        title: t('pages.manage_tags.edit_tag'),
                        request: () => handleEdit(tag.id),
                      }}
                      rowInfo={tag}
                      screensUpdateTable="mobile"
                      setRefetchTable={setRefetchTable}
                      mobileHeader={{
                        render: (tag: any) => (
                          <div className="flex items-center text-sm font-medium">
                            {tag.tag}
                          </div>
                        ),
                      }}
                    />
                  )}
                </div>
              ),
            },
          ]}
        />
      </Fragment>
      {isOpenTagModal && (
        <TagModal
          setIsOpenTagModal={setIsOpenTagModal}
          isOpenTagModal={isOpenTagModal}
          closeModal={() => setIsOpenTagModal(false)}
          onChange={() => setRefetchTable(newUid(20))}
          isAddModal={isAddModal}
          isTagIndex={isTagIndex}
          data={tags}
        />
      )}
    </div>
  );
}

export default ManageTagsPage;

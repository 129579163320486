import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@teo/components';
import TeamsReport from './Teams/TeamsReport';
import UsersReport from './Users/UsersReport';
import CoursesReport from './Courses/CoursesReport';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';

function ReportPage() {
  const { t } = useTranslation();
  const [tab, setTab] = useState('teams');
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          {t('pages.report_page.title')}
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-row gap-5">
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.TabButton name="teams">
              <p className="text-base font-medium">
                {t('pages.report_page.teams')}
              </p>
            </Tabs.TabButton>
          </Tabs>
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.TabButton name="users">
              <p className="text-base font-medium">
                {t('pages.report_page.users')}
              </p>
            </Tabs.TabButton>
          </Tabs>
          {authUser?.useCourse && (
            <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
              <Tabs.TabButton name="courses">
                <p className="text-base font-medium">
                  {t('pages.report_page.course')}
                </p>
              </Tabs.TabButton>
            </Tabs>
          )}
        </div>

        <div className="mt-4 flex flex-row gap-5 lg:ml-5 lg:mt-0">
          {authUser?.useAssessment && (
            <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
              <Tabs.TabButton name="assessments">
                <p className="text-base font-medium">
                  {t('pages.report_page.assessment')}
                </p>
              </Tabs.TabButton>
            </Tabs>
          )}
          {authUser?.useLeervacature && (
            <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
              <Tabs.TabButton name="leervacatures">
                <p className="text-base font-medium">
                  {t('pages.report_page.leervacature')}
                </p>
              </Tabs.TabButton>
            </Tabs>
          )}
        </div>
      </div>
      {tab === 'teams' && <TeamsReport />}
      {tab === 'users' && <UsersReport />}
      {tab === 'courses' && <CoursesReport courseType="course" />}
      {tab === 'assessments' && <CoursesReport courseType="assessment" />}
      {tab === 'leervacatures' && <CoursesReport courseType="leervacature" />}
    </div>
  );
}

export default ReportPage;

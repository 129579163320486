import { useState, useEffect } from 'react';
import { newUid, deep_copy } from '@/util';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { Switch, InputScore, Multiselect, Label } from '@teo/components';
import { fileFormats } from '@/constants/fileFormats';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialFileUpload = () => ({
  supportedFormats: [],
  type: 'FileUpload',
  nrOfFiles: 1,
  urls: [],
  score: 1,
  vraag: '',
});

export const UploadEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : state.antwoord !== ''
  );

  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );

  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.upload')
  );

  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );

  const [score, setScore] = useState(state.score || 0);
  const [nrOfFiles, setNrOfFiles] = useState(state.nrOfFiles || 0);
  const [type, setType] = useState(
    (state.supportedFormats &&
      state.supportedFormats.map((el) => ({
        ...el,
        key: el?.value,
        filter: el?.filter,
      }))) ||
      []
  );
  const [supportedFormats, setSupportedFormats] = useState(
    state.supportedFormats || []
  );

  const [competencies, setCompetencies] = useState([]);

  useEffect(() => {
    setSupportedFormats(
      type?.map((el) => ({
        label: el.label,
        value: el.value,
        filter: el?.filter,
      }))
    );
  }, [type]);

  // generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.titleWidget = titleWidget;
    newState.score = score;
    newState.nrOfFiles = nrOfFiles;
    newState.supportedFormats = supportedFormats;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state?.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />
      <div className="">
        {/* <span className="text-lg font-semibold text-grey-04">
          {t('widgets.widgets_edit.question')} (
          {t('widgets.widgets_edit.optional')})
        </span> */}
        <div className="wg-question">
          <QuestionSection questionVraag={questionVraag} />
        </div>
        <div className="my-4 flex gap-3">
          <Switch
            checked={showAnswer}
            onChange={(isCheck) => setShowAnswer(isCheck)}
          />
          <div className="mr-4">
            {t('widgets.widgets_edit.explain_after_submit')}
          </div>
        </div>
        <div className={`${!showAnswer ? 'hidden' : ''}`}>
          {/* <span className="text-lg font-semibold text-grey-04">
            {t('widgets.widgets_edit.example_answer')} (
            {t('widgets.widgets_edit.optional')})
          </span> */}
          <div className="wg-answer">
            <QuestionSection
              questionVraag={antwoordVraag}
              exampleAnswer={true}
            />
          </div>
        </div>
      </div>

      <div className="relative z-[2] mb-4">
        <p className="mb-2 text-sm font-semibold">
          {t('widgets.edit.upload.label')}
        </p>
        <Multiselect
          onChange={(param) => setType(param)}
          // onCommitQuery={function noRefCheck() {}}
          options={fileFormats}
          value={type || []}
          placeholder={t('widgets.edit.upload.placeholder')}
          id="fileFormats"
        />
        <p className="mt-2 text-sm text-grey-05">
          {t('widgets.edit.upload.message')}
        </p>
      </div>

      <div>
        <div className="relative z-[1] mt-4 flex flex-row items-center gap-7 border-t border-b border-grey-02 py-2">
          <div className="flex-gap3 flex items-center">
            <div className="mr-2">{t('widgets.widgets_edit.max_file')}</div>
            <InputScore
              value={nrOfFiles}
              onChange={(value) => setNrOfFiles(value)}
              disabled
            />
          </div>

          <div className="flex-gap3 flex items-center">
            <div className="mr-2">{t('widgets.widgets_edit.max_score')}</div>
            <InputScore
              value={score}
              onChange={(value) => setScore(value)}
              disabled
            />
          </div>
        </div>
        {/*
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>
      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

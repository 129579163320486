import { PageLayout } from '@/components/PageLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import BreadCrumbsHeader from '@/components/BreadCrumbsHeader';
import { useState, useContext, useEffect } from 'react';
import { NavContext } from '@/components/NavContext';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { nthLastIndexOf, useMobile } from '@/util';
import SidebarNavigation from '@/components/SidebarNavigation';
import { learnSidebar } from '../../constants/sidebar.constants';
import { Header, Button } from '@teo/components';
import { useTranslation } from 'react-i18next';
// import AdminOnboardingModal from './AdminOnboardingModal';
import { Userwithorganisationwithidps } from '@/models';
import i18n from 'i18next';

function LearnPage() {
  const { t } = useTranslation();
  const { collapsed, navDisabled, submenu } = useContext(NavContext);
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const isMobile = useMobile();
  const showNav = !navDisabled && ((submenu && !collapsed) || !isMobile);
  const showContent = collapsed || !isMobile;
  const navigate = useNavigate();

  const originPath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );

  useEffect(() => {
    if (!authUser) {
      localStorage.setItem(
        'postLoginRedirect',
        window.location.pathname + window.location.search
      );
      return navigate(
        `/${i18n?.language || 'nl'}/login` + window.location.search
      );
    }
  }, []);

  if (navDisabled) return <Outlet />;

  return (
    <>
      {!auth && (
        <Header
          imgSrc="/images/TEO_logo.svg"
          navigation={<></>}
          rightComponent={
            <>
              {/* preview needed? */}
              {/* <Button
                variant="outline"
                onClick={() => {
                  navigate('/');
                }}
              >
                {t('links.example')}
              </Button> */}
              <Button
                onClick={() => {
                  const futurePostLoginRedirect = localStorage.getItem(
                    'futurePostLoginRedirect'
                  );
                  if (futurePostLoginRedirect) {
                    localStorage.removeItem('futurePostLoginRedirect');
                    localStorage.setItem(
                      'postLoginRedirect',
                      futurePostLoginRedirect
                    );
                  }
                  const futurePostLoginOrganisation = localStorage.getItem(
                    'futurePostLoginOrganisation'
                  );
                  if (futurePostLoginOrganisation) {
                    localStorage.removeItem('futurePostLoginOrganisation');
                    localStorage.setItem(
                      'postLoginOrganisation',
                      futurePostLoginOrganisation as string
                    );
                  }
                  const futurePostLoginCourseRegister = localStorage.getItem(
                    'futurePostLoginCourseRegister'
                  );
                  if (futurePostLoginCourseRegister) {
                    localStorage.removeItem('futurePostLoginCourseRegister');
                    localStorage.setItem(
                      'postLoginCourseRegister',
                      futurePostLoginCourseRegister as string
                    );
                  }
                  navigate(originPath + '/login');
                }}
              >
                {t('links.sign_in')}
              </Button>
            </>
          }
        />
      )}
      <PageLayout>
        {showNav && auth && (
          <PageLayout.Sidebar>
            <SidebarNavigation items={learnSidebar} />
          </PageLayout.Sidebar>
        )}
        <div
          className={`${showContent ? '' : 'hidden'}  w-full ${
            auth
              ? 'min-h-[100vh] max-w-6xl lg:min-h-[calc(100vh_-_150px)]'
              : 'mx-auto max-w-4xl'
          }`}
        >
          <PageLayout.Content>
            {/* <BreadCrumbsHeader /> */}
            <Outlet />
          </PageLayout.Content>
        </div>
        {/* {showAdminOnboardingModal && (
            <AdminOnboardingModal onClose={() => setShowAdminOnboardingModal(false)} />
          )} */}
        {/*<AdminOnboardingModal />*/}
      </PageLayout>
    </>
  );
}

export default LearnPage;

import { Button } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { parseQueryString, clearCache } from '@/util';

function CookieWarning() {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(true);
  const cookie_status = localStorage.getItem('cookie_status');
  const skipconsent = localStorage.getItem('skipconsent') === 'true';

  if (cookie_status === 'true' || !isOpenModal || skipconsent) return null;

  return (
    <div
      className={`fixed bottom-0 z-[50] m-auto flex w-full flex-col items-center justify-around rounded-lg border bg-[#1e1e20f5] px-6 md:flex-col lg:flex-row`}
    >
      <img src="/images/cookie.svg" alt="Cookie" />
      <div className="my-4 flex flex-col items-center md:flex-col lg:my-0 lg:flex-row">
        <div className="mb-4 text-white lg:mb-0">
          <h2 className="text-xl font-semibold">
            {t('cookie.teo_uses_cookies')}
          </h2>
          <p className="text-sm font-normal">
            {t('cookie.use_of_cookies')}
            <a href="/privacy" className="ml-2 text-[#1173dd]">
              {t('cookie.cookie_policy')}
            </a>
          </p>
        </div>
        <Button
          variant="outline"
          size="lg"
          className="text-white lg:ml-12"
          onClick={() => {
            setIsOpenModal(false);
            localStorage.setItem('cookie_status', String(true));
          }}
        >
          {t('cookie.accept')}
        </Button>
      </div>
    </div>
  );
}

export default CookieWarning;

import i18n from 'i18next';

function Tips() {
  return (
    <>
      <h1 className="my-8 text-xl font-semibold text-black">
        {i18n.t('pages.tips.title')}
      </h1>
    </>
  );
}

export default Tips;

import { useTranslation } from 'react-i18next';
import { Modal, Tabs } from '@teo/components';
import { useContext, useState } from 'react';
import { Portal } from '@/components/Portal';
import { ImageAI } from './ImageAI';
import { LibraryTab } from './LibraryTab';
import { AuthContext } from '@/components/Contexts/AuthContext';

export const LibraryModal = ({
  setIsOpenLibraryModal,
  isOpenLibraryModal,
  lessonPage,
  setUploadFile,
}) => {
  const { t } = useTranslation();

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;

  const useTeoAi = authUser?.useTeoAi;

  const [tab, setTab] = useState(useTeoAi ? 'ai-teo' : 'library');

  const closeModal = () => {
    setIsOpenLibraryModal(!isOpenLibraryModal);
  };

  const tagsFilter = [
    {
      label: t('modal_library.filter.electricity'),
      value: 'electricity',
    },
    {
      label: t('modal_library.filter.base'),
      value: 'base',
    },
    {
      label: t('modal_library.filter.technology'),
      value: 'technology',
    },
    {
      label: t('modal_library.filter.safety'),
      value: 'safety',
    },
    {
      label: t('modal_library.filter.mechanics'),
      value: 'mechanics',
    },
  ];

  return (
    <Portal root="body">
      <div className="z-10 bg-white">
        <Modal isOpen={isOpenLibraryModal}>
          <Modal.Header onClose={() => closeModal()}>
            {t('modal_library.title')}
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col lg:flex-row">
              <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
                {useTeoAi && (
                  <Tabs.TabButton name="ai-teo">
                    <p className="text-base font-medium">TEO AI</p>
                  </Tabs.TabButton>
                )}
                <Tabs.TabButton name="library">
                  <p className="text-base font-medium">Library</p>
                </Tabs.TabButton>
              </Tabs>
            </div>
            {tab === 'library' && (
              <LibraryTab
                setIsOpenLibraryModal={setIsOpenLibraryModal}
                isOpenLibraryModal={isOpenLibraryModal}
                tagsFilter={tagsFilter}
                lessonPage={lessonPage}
                setUploadFile={setUploadFile}
              />
            )}

            {tab === 'ai-teo' && useTeoAi && (
              <ImageAI
                setIsOpenLibraryModal={setIsOpenLibraryModal}
                isOpenLibraryModal={isOpenLibraryModal}
                tagsFilter={tagsFilter}
                lessonPage={lessonPage}
                setUploadFile={setUploadFile}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </Portal>
  );
};

import { sleep, deep_copy } from '@/util';

/** Cobbeled together from
 *
 * https://github.com/rwaldron/johnny-five/blob/main/lib/board.pins.js
 * https://github.com/firmata/arduino/blob/main/Boards.h
 */
export let translatePins = {
  A0: 54,
  A1: 55,
  A2: 56,
  A3: 57,
  A4: 58,
  A5: 59,
  A6: 60,
  A7: 61,
  A8: 62,
  A9: 63,
  A10: 64,
  A11: 65,
  A12: 66,
  A13: 67,
  A14: 68,
  A15: 69,
  I16: 38,
  I17: 39,
  I18: 40,
  IN0: 18,
  IN1: 19,
  D0: 2,
  D1: 3,
  D2: 4,
  D3: 5,
  D4: 6,
  D5: 7,
  D6: 8,
  D7: 9,
  D8: 10,
  D9: 11,
  D10: 12,
  D11: 13,
  D12: 42,
  D13: 43,
  D14: 44,
  D15: 45,
  D16: 46,
  D17: 47,
  D18: 48,
  D19: 49,
  //D20: "PD4",
  //D21: "PD5",
  //D22: "PD6",
  //D23: "PJ4",
  R0: 22,
  R1: 23,
  R2: 24,
  R3: 25,
  R4: 26,
  R5: 27,
  R6: 28,
  R7: 29,
  R8: 30,
  R9: 31,
  R10: 32,
  R11: 33,
  R12: 34,
  R13: 35,
  R14: 36,
  R15: 37,
  TX3: 14,
  RX3: 15,
};

const pinModes = {};
const synced = {};

let channels = [0x00, 0x40, 0x10, 0x50, 0x20, 0x60, 0x30, 0x70];
let addresses = [0x00, 0x01, 0x02]; //0x03

let psensors = [];
let i = 0;
for (let address of addresses) {
  for (let channel of channels) {
    psensors.push({
      address: 0x48 ^ address,
      command: 0x88 ^ channel,
      name: 'P' + i,
    });
    i++;
  }
}

import SerialPort from './SerialPort';
import { Firmata } from 'firmata-io';
//const Firmata = require("firmata-io").Firmata;

// Custom sysex commands
const OVL_SWITCH = 0x05;
const CONTROLLINO_D20_PLUS = 0x06;
const CONTROLLINO_PWM_FREQ = 0x07;
const I2C_REGISTER_NOT_SPECIFIED = -1;
const RESI_DI = 0x08;

const SENSOR_DELAY = 50;

Firmata.SYSEX_RESPONSE[OVL_SWITCH] = function (board) {
  let state = board.buffer[2];
  board.emit('overload', state);
};

function setBit(buffer, i, bit, value) {
  if (value == 0) {
    buffer[i] &= ~(1 << bit);
  } else {
    buffer[i] |= 1 << bit;
  }
}

const DIGITAL_TRESHOLD = 24;
const EPSILON = 0.02;

class WebSerial {
  lastBits = new Array(64).fill(false);
  masks = [1, 2, 4, 8, 10, 20, 40, 80];

  constructor(parent) {
    const _this = this;
    this.parent = parent;
    this.subscriptions = [];
    this.subscriptions.push(
      _this.parent.onState.subscribe(async (state) => {
        _this.sync();
      })
    );
    this.currentSensorIndex = 0;
    this.scheduleTimeout = null;
    Firmata.SYSEX_RESPONSE[RESI_DI] = function (board) {
      let bits = new Array(64).fill(false);

      const buf = Buffer.from(board.buffer.slice(5));
      const lost_bits1 = board.buffer[3];
      const lost_bits2 = board.buffer[4];

      setBit(buf, 0, 7, lost_bits1 & 1);
      setBit(buf, 1, 7, lost_bits1 & 2);
      setBit(buf, 2, 7, lost_bits1 & 4);
      setBit(buf, 3, 7, lost_bits1 & 8);
      setBit(buf, 4, 7, lost_bits1 & 16);
      setBit(buf, 5, 7, lost_bits1 & 32);
      setBit(buf, 6, 7, lost_bits1 & 64);
      setBit(buf, 7, 7, lost_bits2 & 1);

      for (let i = 0; i < 4; i++) {
        let uintdata = buf.readUInt16LE(2 * i);
        for (let j = 0; j < 16; j++) {
          bits[i * 16 + j] = (uintdata & Math.pow(2, j)) !== 0;
          if (bits[i * 16 + j] !== _this.lastBits[i * 16 + j]) {
            _this.parseAnalog(`IR${1 + i * 16 + j}`, bits[i * 16 + j] * 24);
          }
        }
      }
      _this.lastBits = bits;
    };
  }

  schedule = () => {
    let _this = this;
    let sensor = _this.i2cs[_this.currentSensorIndex];
    if (!sensor)
      return (_this.scheduleTimeout = setTimeout(_this.schedule, SENSOR_DELAY));
    _this.board.i2cWrite(sensor.address, [sensor.command]);
    _this.board.i2cReadOnce(sensor.address, 2, async function (data) {
      let value = (data[0] << 8) | data[1];
      //console.log(sensor, value/1000)
      if (_this.parent.state[sensor.name]) {
        _this.parseAnalog(sensor.name, (value / 4095) * 5);
      }
      _this.currentSensorIndex++;
      _this.currentSensorIndex %= psensors.length;
      _this.scheduleTimeout = setTimeout(_this.schedule, SENSOR_DELAY);
    });
  };

  sync = async () => {
    let _this = this;

    if (!this.board) return;

    //await this.board.reset();
    //await this.board.i2cConfig();

    //if (this.scheduleTimeout) clearTimeout(this.scheduleTimeout);
    //this.scheduleTimeout = null;

    this.i2cs = []; //psensors;

    for (let key of Object.keys(this.parent.state)) {
      let pin = this.parent.state[key];
      let pinName = translatePins[pin.name];
      if (!pinName && pin.type != 'i2c') continue; //can't work with this pin on the board)
      switch (pin.type) {
        case 'analog_input':
          if (!synced[pin.name]) {
            this.board.pinMode(pinName - 54, this.board.MODES.ANALOG);
            this.board.analogRead(pinName - 54, (val) => {
              this.parseAnalog(pin.name, (val / 800) * 24);
            });
            synced[pin.name] = true;
          }
          break;
        case 'digital_input':
          if (!synced[pin.name]) {
            this.board.pinMode(pinName, this.board.MODES.INPUT);
            this.board.digitalRead(pinName, (state) => {
              this.parseAnalog(pin.name, state * 24);
            });
            synced[pin.name] = true;
          }
          break;
        case 'pwm_output':
          this.emit('sendAnalog', pin.name, pin.value);
          //this.board.pinMode(pinName, this.board.MODES.PWM);
          break;
        case 'digital_output':
        case 'relay_output':
          this.emit('sendDigital', pin.name, pin.state);
          //this.board.pinMode(pinName, this.board.MODES.OUTPUT);
          break;
        /*
        case 'i2c':
          this.i2cs.push({
            address: pin.address,
            command: pin.command,
            name: pin.name,
          });
          break;
        */
      }
    }

    /*
    if (!this.scheduleTimeout) {
      this.scheduleTimeout = setTimeout(this.schedule, 1000);
    }
    */
  };

  emit = async (type, name, value) => {
    let _this = this;
    let pinName = translatePins[name];
    try {
      switch (type) {
        case 'sendPwmFrequency':
          //TODO
          break;
        case 'sendAnalog':
          if (pinModes[pinName] !== this.board.MODES.PWM) {
            this.board.pinMode(pinName, this.board.MODES.PWM);
            pinModes[pinName] = this.board.MODES.PWM;
          }
          this.board.analogWrite(pinName, (value / 24) * 255);
          break;
        case 'sendDigital':
          //console.log("i2cwrite")
          //await _this.board.i2cWrite(0x90)
          switch (name) {
            case 'D20':
              if (value) {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x01]);
              } else {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x02]);
              }
              break;
            case 'D21':
              if (value) {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x03]);
              } else {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x04]);
              }
              break;
            case 'D22':
              if (value) {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x05]);
              } else {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x06]);
              }
              break;
            case 'D23':
              if (value) {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x07]);
              } else {
                this.board.sysexCommand([CONTROLLINO_D20_PLUS, 0x08]);
              }
              break;
            default:
              if (this.board && pinName) {
                let val = (value / 24) * 800;
                if (isNaN(val)) val = false;
                if (pinModes[pinName] !== this.board.MODES.OUTPUT) {
                  this.board.pinMode(pinName, this.board.MODES.OUTPUT);
                  pinModes[pinName] = this.board.MODES.OUTPUT;
                }
                //console.log("dwrite", pinName, val)
                this.board.digitalWrite(pinName, val);
              }
          }
          break;
        case 'reset':
          break;
        default:
          console.warn('Unknown type: ', type);
          break;
      }
    } catch (e) {
      console.error(e);
    }
  };

  //  Initialize and check if Web serial is supported
  initWebSerial() {
    if ('serial' in navigator) {
      // The Web Serial API is supported.
      navigator.serial.addEventListener('connect', (e) => {
        this.parent.webConnected.next(false);
      });
      navigator.serial.addEventListener('disconnect', (e) => {
        this.parent.webConnected.next(true);
        this.parent.onInitialize.next(false);
      });
    } else {
      console.error(
        "The Web serial API doesn't seem to be enabled in your browser."
      );
    }
  }

  //  Connect to controllino
  Connect = () => {
    return new Promise((resolve, reject) => {
      let _this = this;
      const filter = { usbVendorId: 0x2341 };
      if ('serial' in navigator) {
        const filter = { usbVendorId: 0x2341 }; //  Filters devices it can connect to, by Vendor id of Arduino
        navigator.serial
          .requestPort({ filters: [filter] })
          .then((port) => {
            _this.port = port;
            return _this.port.open({ baudRate: 250000 });
          })
          .then(() => {
            let serialPort = new SerialPort(_this.port, {
              onError: () => {
                _this.parent.onInitialize.next(false);
                _this.parent.boxConnected.next(false);
              },
            });
            this.board = new Firmata(serialPort);
            return serialPort.open();
          })
          .then((serialPort) => {
            this.board.on('close', () => {
              _this.parent.onInitialize.next(false);
              _this.parent.boxConnected.next(false);
            });

            this.board.on('error', () => {
              _this.parent.onInitialize.next(false);
              _this.parent.boxConnected.next(false);
            });

            this.board.on('string', (data) => {
              console.warn(data);
            });

            this.board.on('overload', async (state) => {
              switch (state) {
                case 0x00:
                  if (_this.parent.OVLWarning.getValue())
                    await _this.parent.OVLWarning.next(false);
                  break;
                case 0x01:
                  if (!_this.parent.OVLWarning.getValue()) {
                    await _this.parent.OVLWarning.next(true);
                    //await _this.parent.sendReset();
                    break;
                  }
              }
            });

            this.board.on('ready', function () {
              _this.sync().then(() => {
                _this.parent.boxConnected.next(true);
                _this.parent.onInitialize.next(true);
                resolve();
              });
            });
          })
          .catch((err) => {
            console.error('There was an error opening the serial port:', err);
            this.parent.onInitialize.next(false);
            reject();
          });
      } else {
        console.error(
          "The Web serial API doesn't seem to be enabled in your browser."
        );
        this.isSupported = false;
      }
    });
  };

  parseAnalog(name, val) {
    let lastVal = this.parent.state[name].value;
    if (Math.abs(lastVal - val) > EPSILON || (val === 0 && lastVal !== 0)) {
      this.parent.setAnalog(name, val);
    }
    if (
      (lastVal < DIGITAL_TRESHOLD && val >= DIGITAL_TRESHOLD) ||
      (lastVal >= DIGITAL_TRESHOLD && val < DIGITAL_TRESHOLD)
    ) {
      this.parent.setDigital(name, val >= DIGITAL_TRESHOLD);
    }
  }
}

export default WebSerial;

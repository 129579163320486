import { useState, useEffect, useRef } from 'react';
import { decode } from '@/base64';
import { renderEditor } from './widgets/renderEditor';
import { Button } from '@teo/components';
import { newUid, isEqual } from '@/util';
import { useTranslation } from 'react-i18next';

const QuestionSection = ({
  questionVraag,
  exampleAnswer,
  invulVraag,
  placeholders,
  setPlaceholders,
  addPlaceholder,
  title,
}) => {
  const questionRef = useRef(null);
  const { t } = useTranslation();

  const [portState, setPortState] = useState([]);
  const [rangePosition, setRangePosition] = useState(null);
  const [rangeSelection, setRangeSelection] = useState(null);
  const [focuseVraag, setFocuseVraag] = useState(false);
  const [visible, setVisible] = useState(true);
  const [newPlaceholders, setNewPlaceholders] = useState(null);

  // console.log(questionVraag);

  function removeEmptyPTags(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const pTags = doc.querySelectorAll('p');
    if (
      doc.body.children.length === 1 &&
      pTags.length === 1 &&
      pTags[0].getAttribute('contenteditable') === 'true' &&
      pTags[0].innerHTML.trim() === ''
    ) {
      return htmlString;
    }

    pTags.forEach((p) => {
      const isContentEditable = p.getAttribute('contenteditable') === 'true';
      const isEmpty = p.innerHTML.trim() === '';

      if (isEmpty && !(isContentEditable && doc.body.children.length === 1)) {
        p.remove();
      }
    });

    return doc.body.innerHTML;
  }

  const onFocusSection = () => {
    var node = questionRef.current;
    const spanElement = node?.previousElementSibling;
    if (spanElement && spanElement.tagName === 'SPAN') {
      if (spanElement.classList.contains('block')) {
        spanElement.classList.remove('block');
        spanElement.classList.add('hidden');
      }
    }
    node.textContent;
    node.setAttribute('contenteditable', 'true');
    const pElements = node.querySelectorAll('p');
    // const plac = node.querySelector('.question_placeholder');

    const children = node.childNodes;
    let hasNonParagraphElement = false;
    let hasTextContent = false;

    children.forEach((child) => {
      if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== 'P') {
        hasNonParagraphElement = true;
      }
      if (child.nodeType === Node.TEXT_NODE && child.nodeValue.trim() !== '') {
        hasTextContent = true;
      }
    });

    if (hasNonParagraphElement || hasTextContent) {
      const pElement = document.createElement('p');
      pElement.innerHTML = node.innerHTML;
      node.innerHTML = '';
      node.appendChild(pElement);
    }

    setTimeout(() => {
      pElements.forEach((child) => {
        child.removeAttribute('contenteditable');
      });
      // if (plac) {
      //   plac.remove();
      // }
    }, 0);
  };

  const onBlurSection = () => {
    var node = questionRef.current;
    const imgElements = node.querySelectorAll('img');
    const formulElements = node.querySelectorAll('._katex');

    if (!node?.textContent && !imgElements?.length && !formulElements?.length) {
      const spanElement = node?.previousElementSibling;
      if (spanElement && spanElement.tagName === 'SPAN') {
        if (spanElement.classList.contains('hidden')) {
          spanElement.classList.remove('hidden');
          spanElement.classList.add('block');
        }
      }
    }
  };

  const restoreSelection = (range) => {
    if (range) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleDivClick = (event) => {
    const divElement = questionRef.current;
    if (divElement && divElement.getAttribute('contentEditable') !== 'true') {
      const firstPElement = divElement.querySelector('p');
      if (firstPElement) {
        firstPElement.click();
        const range = document.createRange();
        range.setStart(firstPElement, 0);
        range.setEnd(firstPElement, 0);
        restoreSelection(range);
      }
    }
  };

  useEffect(() => {
    if (questionRef.current) {
      var node = questionRef.current;
      const chat = node?.querySelectorAll('.remove_me_gEE8QIPZ6LEay22SdSCU');
      chat.forEach((child) => {
        child.remove();
      });

      const imgElements = node.querySelectorAll('img');
      const formulElements = node.querySelectorAll('._katex');
      if (
        !node.textContent &&
        !imgElements?.length &&
        !formulElements?.length
      ) {
        const spanElement = node?.previousElementSibling;
        if (spanElement && spanElement.tagName === 'SPAN') {
          if (spanElement.classList.contains('hidden')) {
            spanElement.classList.remove('hidden');
            spanElement.classList.add('block');
          }
        }
        node.removeAttribute('contenteditable');
        node.innerHTML = '';
        const pElement = document.createElement('p');
        pElement.setAttribute('contenteditable', 'true');
        node.appendChild(pElement);
      } else {
        node.setAttribute('contenteditable', 'true');
      }
    }
    const widgets =
      questionRef.current.getElementsByClassName('widget-placeholder');

    const insideWidget = [];
    [...widgets]?.reduce((acc, widget, i) => {
      if (widget.state) return acc; //renderWidget(widget.state, widget, i + 1);
      let node = widget.querySelectorAll('div[data-state],span[data-state]');
      if (node.length === 0) return acc;
      let stateStr = node[0].getAttribute('data-state');
      try {
        widget.state = JSON.parse(stateStr); //legacy parsing
      } catch (e) {
        widget.state = JSON.parse(decode(stateStr)); //base64 parsing
      }

      insideWidget.push(renderEditor(widget.state, widget));
    }, {});
    setPortState(insideWidget);
  }, [questionRef]);

  const handleMouseEnter = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setRangePosition(selection.getRangeAt(0));
      setRangeSelection(selection);
      setVisible(false);
    }
  };

  const handleMouseClick = () => {
    if (rangePosition) {
      const range = rangePosition;
      const uid = newUid(20);
      const spanElement = document.createElement('span');

      const textNode = document.createTextNode(`\u00A0`);

      spanElement.id = uid;
      spanElement.className = 'invulvraag_placeholder';
      range.deleteContents();
      range.insertNode(textNode);
      range.insertNode(spanElement);

      range.setStartAfter(textNode);
      range.collapse(true);

      rangeSelection.removeAllRanges();
      rangeSelection.addRange(range);

      questionRef.current.focus();

      addPlaceholder(uid);
      setVisible(true);
    }
  };
  useEffect(() => {
    const handleContentChange = () => {
      const newContent = questionRef.current;
      let invulvraag;
      setTimeout(() => {
        invulvraag = newContent?.querySelectorAll('.invulvraag_placeholder');
        if (invulvraag) {
          const arrInvulvraag = [...invulvraag]?.map((el) => el.id);
          if (
            arrInvulvraag &&
            arrInvulvraag.length != Object.keys(placeholders).length
          ) {
            const filteredObj = arrInvulvraag.reduce((acc, key) => {
              if (placeholders[key]) {
                acc[key] = placeholders[key];
              } else {
                acc[key] = { uid: key, type: null };
              }
              return acc;
            }, {});
            setNewPlaceholders(filteredObj);
          }
        }
      }, 0);
    };

    if (invulVraag) {
      const containerElement = questionRef.current;
      containerElement.addEventListener(
        'DOMSubtreeModified',
        handleContentChange
      );
      return () => {
        containerElement.removeEventListener(
          'DOMSubtreeModified',
          handleContentChange
        );
      };
    }
  }, [placeholders]);

  useEffect(() => {
    if (newPlaceholders && invulVraag) {
      if (!isEqual(placeholders, newPlaceholders)) {
        setPlaceholders(newPlaceholders);
      }
    }
  }, [newPlaceholders]);

  const onKeyPressed = (ev) => {
    let scrollPosition = window.scrollY;
    var ctrlKey =
      navigator.userAgent.indexOf('Mac OS X') !== -1
        ? ev.metaKey
          ? ev.metaKey
          : ev.ctrlKey
        : ev.ctrlKey;
    if (ctrlKey) {
      switch (ev.key) {
        case 'z':
          setTimeout(() => {
            window.scrollTo({ top: scrollPosition });
          }, 0);

          break;
      }
    }
  };

  return (
    <>
      {portState.map((state) => state?.portal)}
      <div
        onFocus={() => setFocuseVraag(true)}
        onBlur={() => visible && setFocuseVraag(false)}
      >
        <div className="relative">
          <span className="question_placeholder remove_me_gEE8QIPZ6LEay22SdSCU absolute inset-0 -z-[1] hidden text-grey-04">
            {title
              ? t('widgets.widgets_edit.placeholder_title')
              : t(
                  `widgets.widgets_edit.${
                    exampleAnswer
                      ? 'placeholder_answer'
                      : 'placeholder_question'
                  }`
                )}
          </span>
          <div
            onFocus={onFocusSection}
            onBlur={onBlurSection}
            onClick={handleDivClick}
            onKeyDown={(e) => onKeyPressed(e)}
            ref={questionRef}
            className="question_optional fr-view min-h-12 cursor-text"
            dangerouslySetInnerHTML={{
              __html: questionVraag
                ? removeEmptyPTags(questionVraag)
                : questionVraag,
            }}
          />
        </div>
      </div>
      {invulVraag && (
        <div className="mt-4">
          <Button
            size="md"
            onClick={handleMouseClick}
            onMouseDown={handleMouseEnter}
            disabled={!focuseVraag}
          >
            {t('widgets.fillin.add_input')}
          </Button>
        </div>
      )}
    </>
  );
};

export { QuestionSection };

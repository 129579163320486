export const genderOptions = [
  { label: 'MALE', value: 'male' },
  { label: 'FEMALE', value: 'female' },
  { label: 'X', value: 'x' },
];

export const diplomaConstants = [
  { label: 'UNEDUCATED', value: 'uneducated' },
  { label: 'BUSO', value: 'buso' },
  { label: 'DBSO', value: 'dbso' },
  { label: 'BSO', value: 'bso' },
  { label: 'TSO', value: 'tso' },
  { label: 'KSO', value: 'kso' },
  { label: 'ASO', value: 'aso' },
  { label: 'ASSOCIATES', value: 'associates' },
  { label: 'BACHELOR', value: 'bachelor' },
  { label: 'MASTER', value: 'master' },
  { label: 'PHD', value: 'phd' },
  { label: 'OTHER', value: 'other' },
];

export const intrestsConstants = [
  { label: 'passion', value: 'passion' },
  { label: 'skill', value: 'skill' },
  { label: 'hobby', value: 'hobby' },
  { label: 'interest', value: 'interest' },
];

export const businessFormOptions = [
  { label: 'INDEPENDENT', value: 'INDEPENDENT' },
  { label: 'BV', value: 'BV' },
  { label: 'COMMV', value: 'CommV' },
  { label: 'CV', value: 'CV' },
  { label: 'NV', value: 'NV' },
  { label: 'VOF', value: 'VOF' },
];

export const organisationTypeOptions = [
  { label: 'Company', value: 'Company' },
  { label: 'School', value: 'School' },
];

export const workSituationOptions = [
  { label: 'NEW_JOB', value: 'new_job' },
  { label: 'NEW_KNOWLEDGE', value: 'new_knowledge' },
  { label: 'NOT_WORKING', value: 'not_working' },
  { label: 'PERMANENT_CONTRACT', value: 'permanent_contract' },
  { label: 'TEMP_CONTRACT', value: 'temp_contract' },
];

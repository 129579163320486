import { useState, useEffect } from 'react';
import { Button, IconButton } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { CrossFilled } from '@teo/components/icons';
import { OnboardingStudentImg } from './OnboardingStudentImg';
import {
  TheoDeadline,
  TheoWave,
  TheoDelivery,
  TheoSearch,
} from '@teo/components/illustrations';
import { OnboardingStudentText } from './OnboardingStudentText';
import { axios } from '@/axios';
import { config } from '@/config';

const OnboardingStudent = ({
  setShowOnboardingStudent,
  authUser,
  setHidePrevEl,
}: any) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  const activeClass =
    'relative bg-secondary-04 before:content-[""] before:absolute before:w-[20px] before:h-[20px] before:rounded-full before:border-[1px] before:border-secondary-04 before:-top-[2px] before:-left-[2px]';

  useEffect(() => {
    setHidePrevEl(true);
  }, []);
  const handleIntroComplete = () => {
    setHidePrevEl(false);
    const promises = [];
    promises.push(
      axios.put(`${config.backend}/user/${authUser?.id}`, {
        id: authUser?.id,
        introComplete: true,
      })
    );
    Promise.allSettled(promises).then(() => {
      setShowOnboardingStudent(true);
    });
  };
  return (
    <div className="absolute z-[100] flex min-h-[100vh] min-w-[100vw] flex-col bg-white">
      <div className="start-0 top absolute flex w-full flex-row items-center justify-center p-6 sm:justify-between">
        <img className="w-12" src="/images/TEO_logo.svg" />
        <IconButton
          Icon={CrossFilled}
          className="hidden sm:block"
          variant="ghost"
          onClick={() => {
            handleIntroComplete();
          }}
        />
      </div>
      <div className="flex min-h-[100vh] flex-col pt-24 sm:flex-row sm:pt-0">
        <div className="order-1 flex grow flex-col sm:order-none sm:min-h-[100vh] sm:grow-0 sm:basis-1/2">
          <div className="mx-auto flex max-w-md grow flex-col p-6 sm:m-auto sm:grow-0">
            {step === 1 && (
              <OnboardingStudentText
                title={t('pages.onboarding_student.title_1')}
                text={t('pages.onboarding_student.text_1')}
              />
            )}
            {step === 2 && (
              <OnboardingStudentText
                title={t('pages.onboarding_student.title_2')}
                text={t('pages.onboarding_student.text_2')}
              />
            )}
            {step === 3 && (
              <OnboardingStudentText
                title={t('pages.onboarding_student.title_3')}
                text={t('pages.onboarding_student.text_3')}
              />
            )}
            {step === 4 && (
              <OnboardingStudentText
                title={t('pages.onboarding_student.title_4')}
                text={t('pages.onboarding_student.text_4')}
              />
            )}
            {step === 5 && (
              <OnboardingStudentText
                title={t('pages.onboarding_student.title_5')}
                text={t('pages.onboarding_student.text_5')}
              />
            )}
            <div className="mt-auto mb-4 flex flex-col sm:mt-10 sm:mb-0">
              <div className="mb-5 flex flex-row justify-center gap-5">
                <div
                  className={`relative h-[16px] w-[16px] cursor-pointer rounded-full ${
                    step === 1 ? 'bg-secondary-04' : 'bg-grey-02'
                  }`}
                  onClick={() => setStep(1)}
                >
                  {step === 1 && (
                    <div className="absolute h-[20px] w-[20px] -translate-x-0.5 -translate-y-0.5 transform rounded-full border-[1px] border-secondary-04"></div>
                  )}
                </div>
                <div
                  className={`relative h-[16px] w-[16px] cursor-pointer rounded-full ${
                    step === 2 ? 'bg-secondary-04' : 'bg-grey-02'
                  }`}
                  onClick={() => setStep(2)}
                >
                  {step === 2 && (
                    <div className="absolute h-[20px] w-[20px] -translate-x-0.5 -translate-y-0.5 transform rounded-full border-[1px] border-secondary-04"></div>
                  )}
                </div>
                <div
                  className={`relative h-[16px] w-[16px] cursor-pointer rounded-full ${
                    step === 3 ? 'bg-secondary-04' : 'bg-grey-02'
                  }`}
                  onClick={() => setStep(3)}
                >
                  {step === 3 && (
                    <div className="absolute h-[20px] w-[20px] -translate-x-0.5 -translate-y-0.5 transform rounded-full border-[1px] border-secondary-04"></div>
                  )}
                </div>
                <div
                  className={`relative h-[16px] w-[16px] cursor-pointer rounded-full ${
                    step === 4 ? 'bg-secondary-04' : 'bg-grey-02'
                  }`}
                  onClick={() => setStep(4)}
                >
                  {step === 4 && (
                    <div className="absolute h-[20px] w-[20px] -translate-x-0.5 -translate-y-0.5 transform rounded-full border-[1px] border-secondary-04"></div>
                  )}
                </div>
                <div
                  className={`relative h-[16px] w-[16px] cursor-pointer rounded-full ${
                    step === 5 ? 'bg-secondary-04' : 'bg-grey-02'
                  }`}
                  onClick={() => setStep(5)}
                >
                  {step === 5 && (
                    <div className="absolute h-[20px] w-[20px] -translate-x-0.5 -translate-y-0.5 transform rounded-full border-[1px] border-secondary-04"></div>
                  )}
                </div>
              </div>
              <div
                className={`flex ${
                  step === 5 ? 'justify-center' : 'justify-between'
                }`}
              >
                {step !== 5 && (
                  <Button
                    variant="outline"
                    onClick={() => {
                      handleIntroComplete();
                    }}
                  >
                    {t('button.skip')}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    step === 5
                      ? handleIntroComplete()
                      : setStep((prev) => prev + 1);
                  }}
                  className={`${step === 5 && 'min-w-44'}`}
                >
                  {step === 5
                    ? t('pages.onboarding_student.go_teo')
                    : t('button.next')}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {step === 1 && (
          <OnboardingStudentImg ImgStep={TheoWave} color="sm:bg-grey-01" />
        )}
        {step === 2 && (
          <OnboardingStudentImg ImgStep={TheoDelivery} color="sm:bg-error-01" />
        )}
        {step === 3 && (
          <OnboardingStudentImg ImgStep={TheoWave} color="sm:bg-grey-01" />
        )}
        {step === 4 && (
          <OnboardingStudentImg
            ImgStep={TheoDeadline}
            color="sm:bg-success-01"
          />
        )}
        {step === 5 && (
          <OnboardingStudentImg
            ImgStep={TheoSearch}
            color="sm:bg-secondary-01"
          />
        )}
      </div>
    </div>
  );
};

export { OnboardingStudent };

import { useTranslation } from 'react-i18next';
import { T } from '@/components/T';
import { useEffect, useContext, useState } from 'react';
import { NavContext } from '@/components/NavContext';
import { Stepper, IconButton, Button } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useMobile } from '@/util';
import { Coursedetails } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import SearchUsersContainer from '@/pages/Protected/FollowUp/AddUsersToCourse/SearchUsersContainer';
import { axios } from '@/axios';
import { config } from '@/config';

function AddUsersToCourse() {
  const { t } = useTranslation();
  const { setNavDisabled } = useContext(NavContext);
  const [step, setStep] = useState(1);
  const { courseId } = useParams();
  const isMobile = useMobile();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [value, setValue] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [course, setCourse] = useState<Coursedetails | null>(null);

  const [newUser, setNewUser] = useState<any>([]);
  const [students, setStudents] = useState<any>(null);

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  useGet(`/coursedetail/${courseId}`, {
    courseId,
    onSuccess: (result: any) => {
      setCourse(result?.data || null);
    },
  });

  if (!course) return null;

  const handleAddUserToList = (newValue: any[]) => {
    setNewUser(newValue);
    setValue([]);
  };

  const handleRemove = (id: any) => {
    const newList = newUser.filter((item: any) => item.key !== id);
    setNewUser(newList);
  };

  const handleAddUserToCourse = async () => {
    newUser.map(async (item: any) => {
      if (item.newUser) {
        const newUser = await axios.post(
          `${config.backend}/invite/provision?lang=${auth?.user?.lang}`,
          {
            organisationId: auth?.user?.activeOrganisationId,
            email: item.label,
          }
        );
        item.key = newUser?.data?.id;
      }
    });

    await Promise.allSettled(
      newUser.map((user: any) => {
        if (user.userId !== null) {
          axios.post(`${config.backend}/follows`, {
            courseId: courseId,
            userId: user.userId,
            role: 20,
          });
        } else {
          axios.post(`${config.backend}/courseteams`, {
            courseId: courseId,
            teamId: user.teamId,
          });
        }
      })
    ).then(() => navigate(`${basePath}`));
  };

  return (
    <div className="bg-white">
      <div className="flex min-h-16 items-center justify-start ">
        <IconButton
          className="my-auto mx-6"
          Icon={CrossFilled}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <div className="flex items-center">
          <div className="mr-5 w-20 min-w-20">
            <img
              src={course.image || '/images/courseFallback.png'}
              alt="courseFallback"
            />
          </div>

          <span>{t('pages.follow_up_courses.add_students_to_course')}</span>
          <span className="pl-2 font-semibold">{course.name}</span>
        </div>
      </div>
      <Stepper>
        <Stepper.Item
          isActive={step === 1}
          isDone={step > 1}
          onClick={() => setStep(1)}
          step="1"
        >
          {(!isMobile || step === 1) && (
            <T>pages.create_courses.users_and_teams</T>
          )}
        </Stepper.Item>
      </Stepper>

      <div className="mx-auto mt-8 flex max-w-screen-md flex-col justify-center px-2">
        {step === 1 && (
          <>
            <article className="prose flex flex-col ">
              <h3>
                <b>
                  <T>pages.create_courses.users_teams_title</T>
                </b>
              </h3>
              <p>
                <T>pages.create_courses.users_teams_detail</T>
              </p>
            </article>
            <SearchUsersContainer
              value={value}
              setValue={setValue}
              data={data}
              setData={setData}
              handleAddUserToList={handleAddUserToList}
              newUser={newUser}
              handleRemove={handleRemove}
              setStudents={setStudents}
              students={students}
            />
          </>
        )}
        <div className="mt-8 mb-4 flex min-w-full justify-end">
          {
            <Button
              disabled={newUser.length === 0}
              onClick={() => handleAddUserToCourse()}
              data-testId="addUsersButton"
            >
              {t('pages.manage_add_users.add_users_button')}
            </Button>
          }
        </div>
      </div>
    </div>
  );
}

export default AddUsersToCourse;

import { Button, FilterList, Spinner } from '@teo/components';
import { Tag, Plus, ArrowRight } from '@teo/components/icons';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { axios } from '@/axios';
import { config } from '@/config';

export const ImageAI = ({
  setIsOpenLibraryModal,
  isOpenLibraryModal,
  tagsFilter,
  lessonPage,
  setUploadFile,
}) => {
  const { setShowNotification } = useContext(NotificationContext);

  const { t } = useTranslation();

  const classInput =
    'px-11 h-10 w-full rounded-md border border-solid border-transparent py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02';

  const [selected, setSelected] = useState([]);
  const [valueInput, setValueInput] = useState('');
  const [selectedTags, setSelectedTags] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selected?.length > 0) {
      const select = selected?.map((el) => el.value).join(' and ');
      setSelectedTags(`Image related to: ${select}`);
    } else {
      setSelectedTags();
    }
  }, [selected]);

  const generateImages = async (prompt) => {
    setImageUrls([]);
    setLoading(true);
    try {
      const dataOpenAI = await axios.post(`${config.backend}/openaiimage`, {
        model: 'dall-e-2',
        prompt: prompt,
        n: 2,
        size: '512x512',
        response_format: 'b64_json',
      });

      const image_b64 = dataOpenAI?.data?.data?.map((img) => img.b64_json);
      const imgAI = image_b64?.map((img) => {
        const decodedImageData = atob(img);
        const arrayBuffer = new ArrayBuffer(decodedImageData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < decodedImageData.length; i++) {
          uint8Array[i] = decodedImageData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'image/png' });
        return { url: URL.createObjectURL(blob), blob: blob };
      });

      setImageUrls(imgAI);
      setLoading(false);
    } catch (e) {
      setShowNotification({
        message: t('pages.create_edit_course.library.ai_ErrorMassage'),
        delay: 10000,
      });
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (!loading) {
      if (e.keyCode === 13 && valueInput.length > 2) {
        generateImages(valueInput);
        setValueInput('');
      }
    }
  };

  const handleSend = (e) => {
    if (loading && valueInput.length > 2) {
      generateImages(valueInput);
      setValueInput('');
    }
  };

  const handleChange = (e) => {
    if (!loading) {
      setValueInput(e.target.value);
    }
  };

  const uploadeImage = async (img) => {
    if (lessonPage) {
      setUploadFile((prev) => [...prev, img?.blob]);
      setIsOpenLibraryModal(!isOpenLibraryModal);
    } else {
      setUploadFile(img?.url);
      setIsOpenLibraryModal(!isOpenLibraryModal);
    }
  };

  return (
    <div className="-mx-6 max-h-[65vh] overflow-hidden pt-5">
      <div className="px-6 pb-5">
        <div className="flex flex-col">
          <p className="text-sm text-grey-06">
            {t('pages.create_edit_course.library.ai_select')}
          </p>
          <div className="mt-4 flex items-center">
            <Tag className="mr-4 w-5 text-grey-07" />
            <FilterList
              filters={tagsFilter}
              onSelect={setSelected}
              selected={selected}
            />
          </div>

          <div className="mt-4 flex flex-col">
            <div className="ml-auto">
              <Button
                onClick={() => generateImages(selectedTags)}
                disabled={loading || selected?.length < 1}
              >
                {loading
                  ? t('pages.create_edit_course.library.ai_btn_2')
                  : t('pages.create_edit_course.library.ai_btn')}
              </Button>
            </div>
          </div>

          <p className="mt-8 text-sm text-grey-06">
            {t('pages.create_edit_course.library.ai_describe')}
          </p>

          <div className="relative mt-4 w-full">
            <img
              src="/images/icons/chatgpt.svg"
              alt="chatgpt"
              className="!absolute top-3 left-2 z-[1] w-6"
            />
            <input
              disabled={loading}
              id="chat-gpt-image"
              value={valueInput}
              onChange={(e) => handleChange(e)}
              onKeyUp={handleKeyPress}
              placeholder={t('pages.create_edit_course.library.ai_placeholder')}
              className={classInput}
            />
            {loading ? (
              <div className="absolute top-2 right-3">
                <Spinner
                  ariaLabel="Loading spinner"
                  className="!h-6 !w-6 border-grey-08"
                />
              </div>
            ) : (
              <div
                className="absolute top-2.5 right-2 z-[1] cursor-pointer"
                onClick={() => handleSend()}
              >
                <ArrowRight
                  className={` w-6 pl-2 pr-0 text-grey-05 sm:w-9 sm:w-9 sm:pr-2`}
                />
              </div>
            )}
          </div>

          {loading && (
            <div className="mx-auto p-8">
              <div className="spinner-chat mt-3">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex max-h-[30vh] w-full flex-wrap overflow-y-scroll px-4">
        {imageUrls?.length > 0 &&
          imageUrls?.map((img, index) => {
            return (
              <div key={'image_' + index} className="basis-1/2 p-2">
                <div className="relative h-full w-full">
                  <div
                    className="absolute inset-0 z-[1] flex cursor-pointer rounded-lg bg-zinc-400/70 opacity-0 hover:opacity-100"
                    onClick={() => {
                      uploadeImage(img);
                    }}
                  >
                    <Plus className="m-auto h-8 w-8 rounded-full bg-grey-02 text-grey-08" />
                  </div>
                  <img
                    src={img?.url}
                    alt={'image TEO'}
                    className="h-full w-full rounded-lg object-cover"
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

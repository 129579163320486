import { Dropdown, IconButton } from '@teo/components';
import {
  Information,
  Pencil,
  PlusFilled,
  Document,
  Chapter,
  Library,
  Play,
  Image,
  ImageFilled,
} from '@teo/components/icons';
import { useState, useEffect, useRef } from 'react';
import { LessonTreeEdit } from '@/components/LessonTree/LessonTreeEdit';
import { LibraryModal } from '../LibraryModal/LibraryModal';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { axios } from '@/axios';
import { config } from '@/config';
import { uploadLesson, uploadImage } from '@/query/documents';
import { newUid } from '@/util';
import { uploadVideo } from '@/query/vimeo';
import Hint from '../Hints/Hints';

export const SidebarLessonTree = ({
  authUser,
  isDesctop,
  setMobileSidebar,
  setActiveNode,
  setLesQueryParams,
  activeNode,
  coursecontentId,
  setPrevTree,
  addLesson,
  setAddLesson,
  addFolder,
  setAddFolder,
  isRefreshPage,
  setTree,
  tree,
  prevTree,
  handleOpenLibrary,
  setDirectories,
  directories,
  setLessons,
  lessons,
  setUpdateLesInfo,
  setLoadVersion,
  loadVersion,
  isFroalaLoaded,
  hints,
  newPage,
  publicationType,
}: any) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<any>(null);
  const fileInputVideoRef = useRef<any>(null);

  const [newLesson, setNewLesson] = useState<any>();
  const [newFolder, setNewFolder] = useState<any>();
  const [newAsset, setNewAsset] = useState<any>();
  const [newActiveNode, setNewActiveNode] = useState<any>(null);

  const [isOpenImageLibrary, setIsOpenImageLibrary] = useState(false);
  const [isAddButtonBottom, setIsAddButtonBottom] = useState(false);

  const handleNewLesson = async () => {
    const data = await axios.post(`${config.backend}/lessons`, {
      name: t('pages.create_edit_course.new_lesson_button'),
      canEdit: true,
      content: '',
      description: '',
    });
    setNewLesson(data?.data);
  };

  const createLesson = async () => {
    const result = await axios.post(`${config.backend}/uses`, {
      canEdit: true,
      coursecontentId: parseInt(coursecontentId, 10),
      lessonId: newLesson?.id,
    });

    const createLessonId = result?.data?.lessonId;
    const createExtraInfoUsesId = result?.data?.id;

    if (createLessonId) {
      let newYindex;
      const topTree: any = prevTree.filter((item: any) => item?.parent === 0);
      if (topTree?.length) {
        newYindex =
          topTree?.reduce((max: any, obj: any) => {
            return obj?.data?.yindex > max ? obj?.data?.yindex : max;
          }, topTree[0]?.data?.yindex) + 1;
      } else {
        newYindex = 0;
      }
      axios.put(`${config.backend}/use/${result?.data?.id}`, {
        coursecontentId: parseInt(coursecontentId, 10),
        lessonId: createLessonId,
        path: `/${createLessonId}`,
        yindex: newYindex,
      });
      setAddLesson({
        ...newLesson,
        canEdit: true,
        coursecontentId: parseInt(coursecontentId, 10),
        numbered: true,
        lessonId: createLessonId,
        path: `/${createLessonId}`,
        usesId: createExtraInfoUsesId,
        yindex: newYindex,
      });
      setNewActiveNode(createExtraInfoUsesId);
      setNewLesson(null);
    }
  };

  const checkPositionAddButton = () => {
    const blockTree = document.getElementById('course-lessons');
    const contentTree = document.getElementById('course-lessons-list');
    if (blockTree && contentTree) {
      const blockTreeHeight = blockTree.offsetHeight;
      const contentTreeHeight = contentTree.offsetHeight;
      if (blockTreeHeight > contentTreeHeight + 220) {
        setIsAddButtonBottom(false);
      } else {
        setIsAddButtonBottom(true);
      }
    }
  };

  useEffect(() => {
    if (newActiveNode && tree) {
      const newNode = tree?.find((el: any) => el?.id === newActiveNode);
      setActiveNode(newNode);
      window.scrollTo(0, 0);
    }
    checkPositionAddButton();
  }, [tree]);

  useEffect(() => {
    if (newLesson?.id) {
      uploadLesson(newLesson?.postData, '');
      createLesson();
    }
  }, [newLesson]);

  const createFolder = async () => {
    const data = await axios.post(`${config.backend}/directories`, {
      coursecontentId: parseInt(coursecontentId, 10),
      name: t('pages.create_edit_course.new_chapter_button'),
    });
    setNewFolder(data?.data);
  };

  useEffect(() => {
    if (newFolder?.id) {
      const newPath = `/${newUid(20)}`;
      let newYindex;
      const topTree: any = prevTree.filter((item: any) => item?.parent === 0);
      if (topTree?.length) {
        newYindex =
          topTree?.reduce((max: any, obj: any) => {
            return obj?.data?.yindex > max ? obj?.data?.yindex : max;
          }, topTree[0]?.data?.yindex) + 1;
      } else {
        newYindex = 0;
      }
      axios.put(`${config.backend}/directory/${newFolder?.id}`, {
        coursecontentId: parseInt(coursecontentId, 10),
        id: newFolder?.id,
        name: t('pages.create_edit_course.new_folder'),
        path: newPath,
        yindex: newYindex,
      });
      setAddFolder({
        ...newFolder,
        coursecontentId: parseInt(coursecontentId, 10),
        id: newFolder?.id,
        numbered: true,
        name: t('pages.create_edit_course.new_folder'),
        path: newPath,
        yindex: newYindex,
      });
      setNewActiveNode(newFolder?.id);
      setNewFolder(null);
    }
  }, [newFolder?.id]);

  const handleUploadImage = async (event: any) => {
    const img = event.target.files[0];
    const size = 900;
    const data = await uploadImage(img, size, size, 'jpeg');

    const dataImage = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'image',
      assetPath: data,
    });
    setNewAsset(dataImage?.data);
  };

  const handleUploadImageLibrary = async (image: any) => {
    const res = await fetch(image);
    const blob = await res.blob();
    const mime = blob.type;
    const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);
    const img = new File([blob], `${blob.size}.${ext}`, {
      type: mime,
    });
    const size = 900;
    const data = await uploadImage(img, size, size, 'jpeg');

    const dataImage = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'image',
      assetPath: data,
    });
    setNewAsset(dataImage?.data);
  };

  const handleUploadVideo = async (event: any) => {
    const file = event.target.files[0];
    let title;
    try {
      title = file.name.split('.').slice(0, -1).join('.');
    } catch (e) {
      title = document.title;
    }
    const data = await uploadVideo(
      file,
      (progress: number, percent: number): any => {
        console.warn(percent, '%');
      },
      () => {
        console.warn('All done');
      },
      title
    );
    const dataVideo = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'video',
      assetPath: data,
    });
    setNewAsset(dataVideo?.data);
  };

  useEffect(() => {
    if (newAsset) {
      createImageInfo();
    }
  }, [newAsset]);

  const createImageInfo = async () => {
    const result = await axios.post(`${config.backend}/uses`, {
      coursecontentId: parseInt(coursecontentId, 10),
      assetId: newAsset?.id,
    });

    const createAssetId = result?.data?.assetId;
    const createAssetUsesId = result?.data?.id;

    if (createAssetId) {
      let newYindex: any;
      const topTree: any = prevTree.filter((item: any) => item?.parent === 0);
      if (topTree?.length) {
        newYindex =
          topTree?.reduce((max: any, obj: any) => {
            return obj?.data?.yindex > max ? obj?.data?.yindex : max;
          }, topTree[0]?.data?.yindex) + 1;
      } else {
        newYindex = 0;
      }
      axios.put(`${config.backend}/use/${result?.data?.id}`, {
        assetId: createAssetId,
        canEdit: true,
        coursecontentId: parseInt(coursecontentId, 10),
        onlyTheory: true,
        path: `/${createAssetId}`,
        yindex: newYindex,
      });
      setAddLesson({
        ...newAsset,
        canEdit: true,
        coursecontentId: parseInt(coursecontentId, 10),
        onlyTheory: true,
        numbered: true,
        assetId: createAssetId,
        path: `/${createAssetId}`,
        usesId: createAssetUsesId,
        yindex: newYindex,
      });
      setNewActiveNode(createAssetUsesId);
      setNewAsset(null);
    }
  };

  useEffect(() => {
    if (isOpenImageLibrary) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpenImageLibrary]);

  return (
    <div className="min-h-[calc(100vh_-_190px)]">
      <p className="text-base font-medium text-[#6d6d72]">
        {t(`pages.create_edit_course.sidebar_title_${publicationType}`)}
      </p>

      <div
        onClick={(ev) => {
          setMobileSidebar(false);
          setActiveNode(null);
          setLesQueryParams(null);
        }}
        className={clsx(
          ` relative flex cursor-pointer items-center justify-between rounded-md`,
          !activeNode && 'bg-[#f8f8f9]',
          hints?.id == 'hint_39' &&
            'active-hint-create-page active-hint-parent active-hint-main-menu'
        )}
      >
        <span className="flex items-center pl-2">
          <Hint
            key={`hint_39_${newPage}`}
            id={'hint_39'}
            leftDots={'left-0 top-2'}
            title={t('hint.title_39')}
            order={39}
            style={{
              left: 'calc(100% + 20px)',
              top: '0px',
            }}
          />
          <Information className="m-2 w-6 min-w-6" />
          <p className="text-sm font-normal text-[#231f20]">
            {t(`courseTypes.${publicationType}`)}
          </p>
        </span>
        {!activeNode && <Pencil className="mx-2 w-5 min-w-5 text-[#fdbe06]" />}
      </div>
      <div
        className={`${
          hints?.id == 'hint_40' && 'max-h-[440px] overflow-hidden'
        }`}
      >
        <LessonTreeEdit
          onRefresh={async () => {
            if (activeNode?.data?.lessonId && authUser?.id) {
              await axios.delete(
                config.backend + '/lessonlock/' + activeNode.data.lessonId
              );
              window.location.reload();
            } else {
              setLoadVersion(true);
            }
          }}
          coursecontentId={coursecontentId as string}
          activeNode={activeNode}
          onClickNode={(ev, node) => {
            window.scrollTo(0, 0);
            setActiveNode(node);
            setMobileSidebar(false);
          }}
          setPrevTree={setPrevTree}
          addLesson={addLesson}
          setAddLesson={setAddLesson}
          addFolder={addFolder}
          setAddFolder={setAddFolder}
          isRefreshPage={isRefreshPage}
          setTree={setTree}
          tree={tree}
          handleOpenLibrary={handleOpenLibrary}
          setDirectories={setDirectories}
          directories={directories}
          setLessons={setLessons}
          lessons={lessons}
          setUpdateLesInfo={setUpdateLesInfo}
          setLoadVersion={setLoadVersion}
          loadVersion={loadVersion}
          setNewActiveNode={setNewActiveNode}
          newActiveNode={newActiveNode}
          isFroalaLoaded={isFroalaLoaded}
        />
      </div>
      <div
        className={`relative mt-1 ml-3 flex w-min items-center sm:w-auto ${
          hints?.id == 'hint_40' && 'active-hint-parent'
        }`}
      >
        <Hint
          key={`hint_40_${newPage}`}
          id={'hint_40'}
          leftDots={'-left-2 top-0.5'}
          title={t('hint.title_40')}
          order={40}
          style={{
            left: 'calc(100% + 20px)',
            bottom: '-100px',
          }}
        />
        <Dropdown>
          <Dropdown.Trigger>
            <IconButton
              Icon={PlusFilled}
              size="sm"
              className={`mr-3 bg-[#eaeaed] text-[#231f20] ${
                hints?.id == 'hint_40' && 'active-hint-button'
              }`}
            />
          </Dropdown.Trigger>
          <Dropdown.Content
            style={
              !isDesctop
                ? {
                    left: '35px',
                    bottom: '50%',
                    top: 'auto',
                    transform:
                      tree?.length > 2
                        ? isAddButtonBottom
                          ? 'translateY(10%)'
                          : 'translateY(50%)'
                        : 'translateY(80%)',
                  }
                : {
                    left: '25px',
                    bottom: '50%',
                    top: 'auto',
                    transform:
                      tree?.length > 2
                        ? isAddButtonBottom
                          ? 'translateY(10%)'
                          : 'translateY(50%)'
                        : 'translateY(80%)',
                  }
            }
          >
            <Dropdown.Actions>
              <Dropdown.Button
                onClick={() => {
                  handleNewLesson();
                }}
              >
                <IconButton
                  Icon={Document}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p>{t('pages.create_edit_course.new_lesson_button')}</p>
              </Dropdown.Button>

              <Dropdown.Button onClick={() => createFolder()}>
                <IconButton
                  Icon={Chapter}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p>{t('pages.create_edit_course.new_chapter_button')}</p>
              </Dropdown.Button>

              <Dropdown.Button
                onClick={() => {
                  fileInputRef?.current?.click();
                }}
              >
                <IconButton
                  Icon={Image}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p>{t('extra_info.upload_img')}</p>
              </Dropdown.Button>

              <Dropdown.Button
                onClick={() => fileInputVideoRef?.current?.click()}
              >
                <IconButton
                  Icon={Play}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p>{t('extra_info.upload_video')}</p>
              </Dropdown.Button>

              <Dropdown.Button
                onClick={() => {
                  setIsOpenImageLibrary(true);
                }}
              >
                <IconButton
                  Icon={ImageFilled}
                  variant="ghost"
                  className="mr-2 text-tint-blue"
                />
                <p>{t('pages.create_edit_course.from_image_library_button')}</p>
              </Dropdown.Button>

              <Dropdown.Button
                onClick={() => {
                  handleOpenLibrary(null, null);
                }}
              >
                <IconButton
                  Icon={Library}
                  variant="ghost"
                  className="mr-2 text-tint-blue"
                />
                <p>{t('pages.create_edit_course.from_library_button')}</p>
              </Dropdown.Button>
            </Dropdown.Actions>
          </Dropdown.Content>
        </Dropdown>

        <p className="w-52 text-xs font-normal text-[#919193] sm:w-auto">
          {t('pages.create_edit_course.start_adding_text')}
        </p>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadImage}
          accept="image/*"
          style={{ display: 'none' }}
          multiple={false}
        />
        <input
          type="file"
          ref={fileInputVideoRef}
          onChange={handleUploadVideo}
          accept="video/mp4,video/x-m4v,video/*"
          style={{ display: 'none' }}
          multiple={false}
        />
      </div>
      {isOpenImageLibrary && (
        <LibraryModal
          setIsOpenLibraryModal={setIsOpenImageLibrary}
          isOpenLibraryModal={isOpenImageLibrary}
          lessonPage={false}
          setUploadFile={handleUploadImageLibrary}
        />
      )}
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useGet } from '@/query';

import { IconButton, PersonAvatar } from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useParams } from 'react-router-dom';
import { UserInviteToCourse } from '@/components/UserInviteToCourse/UserInviteToCourse';

function SearchUsersContainer(props: any) {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const activeOrganisationId = auth?.user?.activeOrganisationId;
  const { courseId } = useParams();

  useGet(`/studentswithteam`, {
    courseId,
    onSuccess: (result: any) => {
      props.setStudents(result?.data || null);
    },
  });

  if (!props.students) return null;

  return (
    <>
      <UserInviteToCourse
        fetch={useGet.bind(null, `/inviteteamorstudents`, {
          courseId: courseId,
          organisationId: activeOrganisationId,
        })}
        multiselectLabel=""
        inviteButtonText={t('pages.manage_add_users.add_button')}
        value={props.value}
        setValue={props.setValue}
        data={props.data}
        setData={props.setData}
        handleAddUserToList={props.handleAddUserToList}
      />

      <div>
        {props.newUser.map((item: any) => (
          <div
            key={item.userId !== null ? item.key : item.teamId}
            className="mb-4 flex items-center justify-between"
          >
            <div className="flex items-center">
              <PersonAvatar
                inline
                id={item.userId !== null ? item.key : item.teamId}
                name={item.label}
                imageSrc={item.picture}
              ></PersonAvatar>

              <div className="flex flex-col">
                <span className="text-sm font-medium text-[#231f20]">
                  {item.name}
                </span>
              </div>
            </div>

            <IconButton
              className="my-auto mx-6"
              Icon={CrossFilled}
              variant="ghost"
              onClick={() => props.handleRemove(item.key)}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default SearchUsersContainer;

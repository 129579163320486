import { useState } from 'react';
import { getInitialsFromName } from '@/util';

const CustomCompanyAvatar = ({ item }: any) => {
  const [fetchFailed, setFetchFailed] = useState(false);

  return (
    <div className="flex items-center">
      <div
        className="'h-8 mr-3 grid min-h-8 w-8 min-w-8 items-center justify-center overflow-hidden rounded-lg bg-grey-02 hover:after:bg-black"
        title={item.organisationName}
      >
        <span className="z-10">
          {item?.picture && !fetchFailed ? (
            <img
              className="h-full object-contain"
              onError={() => setFetchFailed(true)}
              src={item.picture}
              alt={item.organisationName || ''}
            />
          ) : (
            <span className="text-sm font-medium text-[#231f20]">
              {getInitialsFromName(
                item.organisationName ? item.organisationName : 'A'
              )}
            </span>
          )}
        </span>
      </div>

      <div className="ml-7 flex flex-col">
        <span className="text-sm font-medium text-[#231f20]">
          {item.organisationName}
        </span>
      </div>
    </div>
  );
};

export { CustomCompanyAvatar };

import {
  Button,
  Modal,
  PersonAvatar,
  InputText,
  InputTextarea,
  Label,
} from '@teo/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { format } from 'date-fns';
import { axios } from '@/axios';
import { config } from '@/config';

function OpenCommentModal({
  closeModal,
  setIsOpenCommentModal,
  usrCourseId,
  usr,
  course,
  authUser,
}: any) {
  const { t } = useTranslation();
  const [commentDetail, setCommentDetail] = useState<any>(null);
  const [studentComment, setStudentComment] = useState<any>('');
  const [editUserComment, setEditUserComment] = useState<any>('');
  const [edit, setEdit] = useState<any>();

  useGet(`/studentcommentdetails`, {
    studentId: usr?.userId,
    courseId: usrCourseId,
    onSuccess: (result: any) => {
      setCommentDetail(result?.data || null);
    },
  });

  const handleAddComment = () => {
    axios
      .post(`${config.backend}/studentcomments`, {
        authorId: authUser?.id,
        comment: studentComment,
        courseId: usrCourseId,
        studentId: usr?.userId,
        authorName: authUser?.username,
      })
      .then(() => window.location.reload());
  };

  if (!commentDetail) return null;

  return (
    <Modal isOpen={true}>
      <Modal.Header onClose={closeModal}>
        {t('pages.follow_up_teams.comments_by_user')}&nbsp; {usr?.username}
      </Modal.Header>
      {usr?.nrOfStudentComments !== 0 && (
        <Modal.Body>
          <div className="max-h-96 overflow-y-auto">
            {commentDetail?.map((el: any) => (
              <div key={el.id}>
                {el.comment && (
                  <div>
                    <div className="ml-2 mt-3 mb-2 flex flex-wrap items-center gap-2 text-sm font-medium text-grey-10">
                      <span>{course?.courseName}</span>
                      <span className="px-1">&#8729;</span>
                      <div className="flex flex-col">
                        <span>
                          {format(
                            new Date(el.createdAt || ''),
                            'dd/MM/yyyy HH:mm'
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="ml-2 mt-3 mb-2 flex flex-wrap items-center">
                      <PersonAvatar
                        inline
                        id={el?.authorId}
                        name={el?.authorName ? el?.authorName : ''}
                        imageSrc={el?.authorPicture ? el?.authorPicture : ''}
                      ></PersonAvatar>
                      <span className="text-sm font-normal text-grey-10">
                        {el?.authorName}
                      </span>
                    </div>

                    {edit === el.id ? (
                      <InputText
                        id="userComment"
                        onChange={async (event) => {
                          setEditUserComment(event.target.value);
                        }}
                        onBlur={async () => {
                          await axios.put(
                            `${config.backend}/studentcomment/${course.id}/${el.id}`,
                            {
                              comment: editUserComment,
                            }
                          );
                        }}
                        onKeyPress={async () => {
                          await axios.put(
                            `${config.backend}/studentcomment/${course.id}/${el.id}`,
                            {
                              comment: editUserComment,
                            }
                          );
                        }}
                        placeholder={
                          editUserComment ? editUserComment : el.comment
                        }
                        className="text-grey-07"
                      />
                    ) : (
                      <p className="mt-1 ml-1.5 rounded bg-grey-01 py-2 px-3 text-sm font-normal text-grey-07">
                        {el.comment}
                      </p>
                    )}
                    <div>
                      <div className="mt-1 flex flex-row items-center">
                        <Button
                          variant="text"
                          size="sm"
                          className="text-tint-blue"
                          onClick={() => setEdit(el?.id)}
                        >
                          {t('pages.follow_up_teams.to_process')}
                        </Button>
                        <span className="px-1">&#8729;</span>
                        <Button
                          variant="text"
                          size="sm"
                          className="text-tint-blue"
                          onClick={() =>
                            axios
                              .delete(
                                `${config.backend}/studentcomment/${course.id}/${el.id}`
                              )
                              .then(() => window.location.reload())
                          }
                        >
                          {t('pages.follow_up_teams.to_delete')}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div className="flex w-full flex-col">
          <div className="mb-4">
            <Label htmlFor="comment">
              <p className="mt-6 text-sm font-semibold">
                {t('pages.follow_up_teams.add_comment')}
              </p>
            </Label>
            <InputTextarea
              id="comment"
              placeholder={t('pages.follow_up_teams.write_your_comment')}
              onChange={(e) => setStudentComment(e.target.value)}
              limit={300}
              value={studentComment}
            />
          </div>
          <div className="flex justify-between">
            <Button onClick={() => closeModal}>{t('button.cancel')}</Button>
            <Button onClick={() => handleAddComment()}>
              {t('pages.follow_up_teams.add_comment')}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default OpenCommentModal;

import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@teo/components';

export const ConfirmTranslationModal = ({
  isOpenConfirmModal,
  setIsOpenConfirmModal,
  handleTranslate,
  setLang,
  setSelectLang,
  selectLang,
}: any) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpenConfirmModal}>
      <Modal.Header
        onClose={() => {
          setSelectLang({
            id: '',
            name: '',
            value: '',
          });
          setIsOpenConfirmModal(false);
        }}
      >
        {t('pages.create_edit_course.confirmation_window')}
      </Modal.Header>
      <Modal.Body>
        {t('pages.create_edit_course.confirm_modal_text')}
      </Modal.Body>
      <Modal.Footer
        cancelButtonLabel={t('pages.create_edit_course.cancel_button')}
        onCancel={() => {
          setSelectLang({
            id: '',
            name: '',
            value: '',
          });
          setIsOpenConfirmModal(false);
        }}
      >
        <div>
          <Button
            onClick={async () => {
              await setLang(selectLang);
              handleTranslate(selectLang);
            }}
          >
            {t('pages.auth.register.yes')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

import { createContext } from 'react';

export const NavContext = createContext<{
  collapsed: boolean;
  setCollapsed: (state: boolean) => void;
  navDisabled: boolean;
  setNavDisabled: (state: boolean) => void;
  submenu: boolean;
  setSubmenu: (state: boolean) => void;
}>({
  collapsed: false,
  setCollapsed: () => {},
  navDisabled: false,
  setNavDisabled: () => {},
  submenu: false,
  setSubmenu: () => {},
});

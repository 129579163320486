import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useContext, useState } from 'react';
import { Panel, IconButton, IconButtonLink } from '@teo/components';
import { T } from '@/components/T';
import { parseDateTime, parseDate, newUid } from '@/util';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { Pencil, ArrowRight } from '@teo/components/icons';
import i18n from 'i18next';

function UpdateOverview() {
  const [refresh, setRefresh] = useState<string>(newUid(20));
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;
  const navigate = useNavigate();

  const isAdmin = (authUser?.role || 0) >= 90;

  return (
    <>
      <h1 className="my-8 text-xl font-semibold text-black">
        {i18n.t('pages.help.title')}
      </h1>
      <Panel background="grey" className="relative p-8">
        <PaginationAnything
          key={refresh}
          className={`flex flex-col gap-4`}
          fetch={useGet.bind(null, '/teoupdates', { lang: i18n.language })}
          pageSize={12}
          defaultSort={[{ id: 'createdAt', desc: true }]}
          render={(update: any) => (
            <div key={update.id}>
              <div className="flex items-center gap-4">
                <div className="text-sm">{parseDate(update.createdAt)}</div>
                {isAdmin && (
                  <IconButton
                    Icon={Pencil}
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setTimeout(() => navigate(`../updates/${update.id}`), 0);
                    }}
                  />
                )}
              </div>
              <div
                className="fr-view sane-lists"
                dangerouslySetInnerHTML={{ __html: update.text }}
              />
            </div>
          )}
        />
      </Panel>
    </>
  );
}

export default UpdateOverview;

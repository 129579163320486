import { config } from '@/config';
import { Memberdetails, Memberorganisations } from '@/models';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { axios, publicAxios } from '@/axios';

export const useAuth = (): { activeOrganisationId: string } => {
  const token = localStorage.getItem('token');
  // if token is undefined, should redirect to login?
  return token ? jwtDecode(token) : { activeOrganisationId: '18' };
};

export const useMembers = (params: { count: number; offset: number }) => {
  const auth = useAuth();

  return useQuery({
    keepPreviousData: true,
    queryFn: async () => {
      const { data }: AxiosResponse<Memberdetails[]> = await axios.get(
        `${config.backend}/memberdetails`,
        { params: { ...params, organisationId: auth.activeOrganisationId } }
      );
      return data;
    },
    queryKey: ['memberdetails', params.offset, params.count],
  });
};

const _parseQueryString = (params: any, basePath: string) => {
  let path = '',
    i = basePath.includes('?') ? 1 : 0;
  for (const key of Object.keys(params)) {
    if (!key || !params[key]) continue;
    if (i === 0) path += '?';
    if (i > 0) path += '&';
    i++;
    path += key + '=' + params[key];
  }
  return path;
};

export const useGet = <Type>(path: string, ...params: any[]) => {
  const combined = Object.assign({}, ...params);
  const { onSuccess, onError, onSettled, enabled, retry, ...other } = combined; // manual
  return useQuery({
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (path.startsWith('https://')) {
        if (path.startsWith(config.teodoc))
          return await axios.get<Type>(
            `${path}${_parseQueryString(other, path)}`
          );
        return await publicAxios.get<Type>(
          `${path}${_parseQueryString(other, path)}`
        );
      }
      return await axios.get<Type>(
        `${config.backend}${path}${_parseQueryString(other, path)}`
      );
    },
    queryKey: [path, ...Object.keys(other), ...Object.values(other)],
    onSuccess,
    onError,
    onSettled,
    enabled,
    retry,
    //manual
  });
};

async function _usePostPut(
  axiosFn: any,
  path: string,
  combined: any = undefined
) {
  return await axiosFn(`${config.backend}` + path, combined);
}

//Most common usage post(path, data)
export const post = (path: string, ...data: any[]) =>
  _usePostPut(axios.post, path, Object.assign({}, ...data));

//Most common usage put(path, data)
export const put = (path: string, ...data: any[]) =>
  _usePostPut(axios.put, path, Object.assign({}, ...data));

//Most common usage get(path)
export const get = (path: string) => _usePostPut(axios.get, path);

/*
//Most common usage usePostPromise(path, data).then().catch()
export const usePostPromise = (path: string, ...data: any[]) => {
  return new Promise((resolve, reject) => {
    _usePostPut(
      axios.post,
      path,
      Object.assign({}, ...data, {
        onSuccess: resolve,
        onFailure: reject,
      })
    );
  });
};

//Most common usage usePutPromise(path, data).then().catch()
export const usePutPromise = (path: string, ...data: any[]) => {
  return new Promise((resolve, reject) => {
    _usePostPut(
      axios.put,
      path,
      Object.assign({}, ...data, {
        onSuccess: resolve,
        onFailure: reject,
      })
    );
  });
};
*/

export const useOrganisations = ({ userId }: { userId: number }) => {
  return useQuery({
    queryFn: async () => {
      const { data }: AxiosResponse<Memberorganisations[]> = await axios.get(
        `${config.backend}/memberorganisations`,
        { params: { userId } }
      );

      return data;
    },
    queryKey: ['memberorganisations', userId],
  });
};

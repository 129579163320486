import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { format } from 'date-fns';
import { DataTable } from '@/components/DataTable/DataTable';
import { Button, ButtonLink } from '@teo/components';
import { config } from '@/config';
import CreateOverviewModal from './CreateOverviewModal';
import { newUid } from '@/util';

function OverviewsTab({ auth }: any) {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const token = localStorage.getItem('token');

  const downloadLink = `${config.backend}/analytics/supportinvoices/csv/${auth?.user?.activeOrganisationId}?jwt=${token}`;

  return (
    <div className="">
      <div className="flex flex-col pt-8 pb-6 md:flex-row">
        <p className="text-md font-semibold text-black">
          {t('pages.organisation_licensing_and_billing.overviews_tab')}
        </p>
        <div className="ml-0 mt-4 flex md:ml-auto md:mt-0">
          <Button
            onClick={() => {
              setIsOpenModal(true);
            }}
            size="sm"
            variant="outline"
            className="mr-3"
            data-testid="createOverview"
          >
            {t('pages.organisation_licensing_and_billing.table.create')}
          </Button>

          <ButtonLink
            to={downloadLink}
            size="sm"
            variant="outline"
            download
            className="border-grey-o3 border"
          >
            {t('pages.organisation_licensing_and_billing.table.download')}
          </ButtonLink>
        </div>
      </div>
      <DataTable
        fetch={useGet.bind(null, '/supportinvoices', {
          organisationId: auth?.user?.activeOrganisationId,
        })}
        key={refetchTable}
        defaultSort={[
          { id: '-endTime', desc: false },
          { id: '-id', desc: false },
        ]}
        columns={[
          {
            addTitleOnMobile: true,
            title: t('pages.organisation_licensing_and_billing.table.start'),
            id: '-id',
            className: 'col-start-1 col-end-4 row-start-1',
            render: (row: any) => format(new Date(row.startTime), 'dd/MM/yyyy'),
          },
          {
            addTitleOnMobile: true,
            id: '-endTime',
            title: t('pages.organisation_licensing_and_billing.table.end'),
            className:
              'col-start-4 col-end-7 row-start-1 justify-center text-center',
            render: (row: any) => format(new Date(row.endTime), 'dd/MM/yyyy'),
          },
          {
            addTitleOnMobile: true,
            id: 'credits',
            title: t('pages.organisation_licensing_and_billing.table.credits'),
            sortable: false,
            className:
              'col-start-7 col-end-10 row-start-1 justify-end text-right',
            render: (row: any) => row.credits,
          },
          {
            addTitleOnMobile: true,
            id: 'description',
            title: t(
              'pages.organisation_licensing_and_billing.table.description'
            ),
            sortable: false,
            className: 'col-start-1 col-end-9 row-start-2 break-all',
            render: (row: any) => row.description,
          },
        ]}
      />

      <CreateOverviewModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        organisationId={auth?.user?.activeOrganisationId}
        onChange={() => setRefetchTable(newUid(20))}
      />
    </div>
  );
}

export default OverviewsTab;

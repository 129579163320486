import io from 'socket.io-client';
import { Subject, BehaviorSubject, skip } from 'rxjs';
import {
  getDefaultControllerState,
  newState,
  MODE,
  defaultControllerState,
} from './defaultControllerState';
import WebSerial from './webSerial/WebSerial';
import { sleep, deep_copy } from '@/util';

//["D0", ..., "D23", "R0", ..., "R15"]
const digitalOutputs = [
  ...[...Array(24).keys()].map((i) => 'D' + i),
  ...[...Array(16).keys()].map((i) => 'R' + i),
];
//["A0", ..., "A15"]
const analogInputs = [...Array(16).keys()].map((i) => 'A' + i);

class NetworkService {
  constructor(parent) {
    this.socket = io('ws://localhost:3099', {
      forceNew: true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 60,
      path: '/socket.io',
      transports: ['websocket'],
      autoConnect: true,
    });

    this.socket.on('state', parent.setState);
    this.socket.on('onDigital', parent.setDigital);
    this.socket.on('onAnalog', parent.setAnalog);
    this.socket.on('onStream', parent.setStream);
    this.socket.on('onModeAnalog', parent.setModeAnalog);
    this.socket.on('onModeDigital', parent.setModeDigital);
    this.socket.on('ovlWarning', parent.setOvlWarning);
    this.socket.on('pwmFrequency', parent.sendPwmFrequency);
    this.socket.on('reset', parent.sendReset);

    this.socket.on('connect', () => {
      parent.initialized = true;
      parent.boxConnected.next(true);
      parent.onInitialize.next(true);
    });
    this.socket.on('connect_error', (err) => {
      parent.initialized = false;
      console.warn(`connect_error due to ${err.message}`);
    });
    this.socket.io.on('reconnection_attempt', (err) => {
      console.warn(`reconnection_attempt ${err.message}`);
    });
    this.socket.on('onOpen', function () {
      console.warn('SOCKET OPEN');
    });
  }

  emit = (...params) => {
    this.socket.emit(...params);
  };
}

/**Singleton dat met box BE communiceert*/

class BoxIoService {
  state = getDefaultControllerState();

  boxConnected = new BehaviorSubject();
  webConnected = new BehaviorSubject();
  onInitialize = new BehaviorSubject();
  OVLWarning = new BehaviorSubject();
  onState = new Subject();
  onDigital = new Subject();
  onAnalog = new Subject();
  onModeAnalog = new Subject();
  onModeDigital = new Subject();
  onStream = new Subject();
  onTrigger = new Subject();
  onStatelock = new Subject();

  triggers = {};
  triggersActive = {};
  triggersStates = {};
  lockedStates = new Set();
  waitFor = [];

  checkTrigger = (trigger) => {
    let active = true;
    if (trigger.conditions) {
      for (let condition of trigger.conditions) {
        let pinState = this.state[condition.pin];
        if (!pinState) {
          active = false;
          break;
        }
        if (condition.mode === 'digital') {
          if (pinState && pinState.state !== condition.value) active = false;
        } else if (condition.mode === 'analog') {
          if (condition.max && pinState.value > parseFloat(condition.max))
            active = false;
          if (condition.min && pinState.value < parseFloat(condition.min))
            active = false;
        }
      }
    }
    return active;
  };

  checkCorrection = async (correction) => {
    let toUndo = {};
    if (correction.assertions) {
      for (let assertion of correction.assertions) {
        switch (assertion.action) {
          case 'set':
            switch (assertion.mode) {
              case 'analog':
                toUndo[assertion.pin] = {
                  value: this.state[assertion.pin].value,
                };
                this.sendAnalog(assertion.pin, parseFloat(assertion.value));
                break;
              case 'digital':
                toUndo[assertion.pin] = {
                  state: this.state[assertion.pin].state,
                };
                this.sendDigital(assertion.pin, assertion.value);
                break;
            }
            break;
        }
      }
    }

    await sleep(100);

    let active = true;
    if (correction.verifications) {
      for (let verification of correction.verifications) {
        let pinState = this.state[verification.pin];
        if (!pinState) {
          active = false;
          break;
        }
        if (verification.mode === 'digital') {
          if (pinState && pinState.state !== verification.value) active = false;
        } else if (verification.mode === 'analog') {
          if (verification.max && pinState.value > parseFloat(verification.max))
            active = false;
          if (verification.min && pinState.value < parseFloat(verification.min))
            active = false;
        }
      }
    }

    //undo actions
    for (let pin of Object.keys(toUndo)) {
      let state = toUndo[pin];
      if (state.state !== undefined && state.state != this.state[pin].state) {
        this.sendDigital(pin, state.state);
      }
      if (state.value !== undefined && state.value != this.state[pin].value) {
        this.sendAnalog(pin, parseFloat(state.value));
      }
    }

    return active;
  };

  assertState = (name, attrs) => {
    if (!this.state[name]) this.state[name] = newState(attrs);
  };

  assertI2C = (name, address, command) => {
    //let name = '' + address + '_' + command;
    if (!defaultControllerState[name]) {
      let state = {
        name,
        state: false,
        type: 'i2c',
        address: parseFloat(address),
        command: parseFloat(command),
        mode: MODE.I2C,
        value: 0,
        pwm: false,
      };
      defaultControllerState[name] = state;
      this.state[name] = state;
    }
  };

  resetAll() {
    for (let uid of Object.keys(this.triggersStates)) {
      this.stopTriggers(uid);
    }
    this.triggersActive = {};
    this.triggersStates = {};

    this.lockedStates = new Set();
    this.waitFor = [];

    this.setState(deep_copy(getDefaultControllerState()));
  }

  checkLoaded() {
    let done = true;
    for (let defer of this.waitFor) {
      if (!defer.isResolved && !defer.isRejected) done = false;
    }
    if (done) {
      this.startAllTriggers();
    }
  }

  queueTriggers(uid, triggers) {
    this.triggersStates[uid] = triggers;
    this.triggersActive[uid] = [];
  }

  _processTriggers(uid, triggers) {
    if (!triggers) return;
    if (!this.triggersStates[uid]) {
      this.queueTriggers(uid, triggers);
    }

    for (const [i, trigger] of triggers.entries()) {
      this.triggersActive[uid].push(false);
      let active = true; //no condition => active
      if (trigger.conditions) {
        if (!this.checkTrigger(trigger)) active = false;
      }
      if (!this.triggersActive[uid]) this.triggersActive[uid] = [];
      if (active && !this.triggersActive[uid][i]) {
        this.triggersActive[uid][i] = true;

        if (trigger.actions) {
          for (let action of trigger.actions) {
            switch (action.action) {
              case 'set':
                switch (action.mode) {
                  case 'analog':
                    this.sendAnalog(action.pin, parseFloat(action.value));
                    if (action.frequency === 'permanent') {
                      this.lockedStates.add(action.pin);
                      this.onStatelock.next({ name: action.pin, locked: true });
                    }
                    break;
                  case 'digital':
                    this.sendDigital(action.pin, action.value);
                    if (action.frequency === 'permanent') {
                      this.lockedStates.add(action.pin);
                      this.onStatelock.next({ name: action.pin, locked: true });
                    }
                    break;
                }
                break;
              case 'reset':
                this.sendReset();
                break;
            }
          }
        }
        if (trigger.script) {
          try {
            this.runScript(trigger.script);
          } catch (e) {
            console.error(e);
          }
        }
        this.onTrigger.next({ trigger, state: true });
        this.sendDigital(trigger.name, true);
      } else if (!active && this.triggersActive[uid][i]) {
        this.triggersActive[uid][i] = false;
        this.onTrigger.next({ trigger, state: false });

        for (let action of trigger.actions) {
          if (action.frequency === 'permanent') {
            this.lockedStates.delete(action.pin);
            this.onStatelock.next({ name: action.pin, locked: false });
          }
          switch (action.action) {
            case 'set':
              switch (action.mode) {
                case 'analog':
                  this.sendAnalog(action.pin, 0);
                  if (action.frequency === 'permanent') {
                    this.lockedStates.add(action.pin);
                    this.onStatelock.next({ name: action.pin, locked: false });
                  }
                  break;
                case 'digital':
                  this.sendDigital(action.pin, !action.value);
                  if (action.frequency === 'permanent') {
                    this.lockedStates.remove(action.pin);
                    this.onStatelock.next({ name: action.pin, locked: false });
                  }
                  break;
              }
              break;
            case 'reset':
              this.sendReset();
              break;
          }
        }

        this.sendDigital(trigger.name, false);
      }
    }
  }

  sleep = sleep;
  runScript = async (script) => {
    var result = function (str) {
      eval(`(async () => {
        try {
          ${str}
        } catch(e) {
          console.error(e);
        }
      })()`);
    }.call(this, script);
  };

  stopTriggers(uid) {
    try {
      for (let trigger of this.triggersStates[uid]) {
        clearInterval(trigger);
      }
    } catch (e) {
      console.error(e);
    }
    delete this.triggersActive[uid];
    delete this.triggersStates[uid];
  }

  setState = (state) => {
    this.state = state;

    this.initialized = true;
    this.onState.next(state);
  };

  setDigital = (name, state) => {
    if (!this.state[name]) this.assertState(name);

    this.state[name].state = state && state !== 'false' ? true : false;
    this.onDigital.next({ name, state: this.state[name].state });
  };

  setAnalog = (name, value) => {
    if (!this.state[name]) this.assertState(name);
    this.state[name].value = value;
    this.onAnalog.next({ name, value });
  };

  setStream = (name, values) => {
    if (!this.state.streams[name]) this.state.streams[name] = {};
    this.state.streams[name] = values;
    this.onStream.next({ name, values: values });
  };

  setModeAnalog = (name, mode) => {
    if (!this.state[name]) this.assertState(name);
    this.state[name].mode = mode;
    this.onModeAnalog.next({ name, mode });
  };

  setModeDigital = (name, mode) => {
    if (!this.state[name]) this.assertState(name);
    this.state[name].mode = mode;
    this.onModeDigital.next({ name, mode });
  };

  setOvlWarning = (value) => {
    this.OVLWarning.next(value);
    //this.sendReset();
  };

  constructor(props) {
    this.boxConnected.next(false);
    this.netWorkService = new WebSerial(this);

    let boxConnectedSubscription = this.boxConnected.subscribe(
      (boxConnected) => {
        if (boxConnected) {
          const triggerStates = deep_copy(this.triggersStates);
          for (let uid of Object.keys(triggerStates)) {
            this._processTriggers(uid, triggerStates[uid]);
          }
        }
      }
    );

    const checkAllTriggers = () => {
      this.startAllTriggers();
    };

    this.onDigital.subscribe(checkAllTriggers);
    this.onAnalog.subscribe(checkAllTriggers);
    this.onState.subscribe(checkAllTriggers);

    if (BoxIoService._instance) return BoxIoService._instance;
    BoxIoService._instance = this;
  }

  isStateLocked = (name) => {
    return this.lockedStates.has(name);
  };

  sendAnalog = (name, value) => {
    if (this.lockedStates.has(name))
      return console.error('State', name, 'is locked by a trigger');
    if (!this.state[name]) this.assertState(name);
    this.state[name].value = value;
    this.netWorkService.emit('sendAnalog', name, value);
    this.setAnalog(name, value);
    this.setDigital(name, value > (this.state.threshold || 4));
  };

  sendDigital = (name, state) => {
    //console.trace()
    if (this.lockedStates.has(name))
      return console.error('State', name, 'is locked by a trigger');
    if (!this.state[name]) this.assertState(name);
    this.state[name].state = state;
    this.netWorkService.emit('sendDigital', name, state);
    this.setDigital(name, state);
    this.setAnalog(name, state * 24);
  };

  sendStream = (name) => {
    this.socket.emit('sendStream', name);
    if (!this.socket.connected) this.setStream(name, []); //temporary should be done by network
  };

  sendModeAnalog = (name, mode) => {
    if (this.lockedStates.has(name))
      return console.error('State', name, 'is locked by a trigger');
    if (!this.state[name]) this.assertState(name);
    this.state[name].mode = mode;
    this.socket.emit('sendModeAnalog', name, mode);
    this.sendModeAnalog(name, mode);
  };

  sendModeDigital = (name, mode) => {
    if (this.lockedStates.has(name))
      return console.error('State', name, 'is locked by a trigger');
    if (!this.state[name]) this.assertState(name);
    this.state[name].mode = mode;
    this.socket.emit('sendModeDigital', name, mode);
    this.sendModeDigital(name, mode);
  };

  sendReset = async () => {
    let triggerStates = deep_copy(this.triggersStates);
    for (let uid of Object.keys(triggerStates)) {
      this.stopTriggers(uid);
    }
    this.lockedStates = new Set();
    this.setState(deep_copy(getDefaultControllerState()));
    for (let uid of Object.keys(triggerStates)) {
      this._processTriggers(uid, triggerStates[uid]);
    }
  };

  startAllTriggers() {
    let triggerStates = deep_copy(this.triggersStates);
    for (let uid of Object.keys(triggerStates)) {
      this._processTriggers(uid, triggerStates[uid]);
    }
  }

  sendPwmFrequency = (name, value) => {
    //this.netWorkService.emit("sendPwmFrequency", name, value);
  };
}

BoxIoService.getInstance = function (props) {
  return BoxIoService._instance || new BoxIoService(props);
};

export { BoxIoService, digitalOutputs, analogInputs };

import { useTranslation } from 'react-i18next';
import { Switch } from '@teo/components';
import { useState } from 'react';
import { axios } from '@/axios';
import { config } from '@/config';

function LicenseTab({ auth, setAuth }: any) {
  const { t } = useTranslation();

  const authUser = auth?.user;
  const [courseType, setCourseType] = useState(authUser?.useCourse);
  const [assessmentType, setAssessmentType] = useState(authUser?.useAssessment);
  const [vacancyType, setVacancyType] = useState(authUser?.useLeervacature);
  const [instructionType, setInstructionType] = useState(
    authUser?.useInstruction
  );
  const [teoAiType, setTeoAiType] = useState(authUser?.useTeoAi);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-6 text-xl font-semibold text-black">
          {t('pages.organisation_licensing_and_billing.license.tab_title')}
        </h1>
      </div>
      <div className="flex flex-col">
        <div className="rounded-lg border border-grey-02 p-6">
          <div className="divide-y divide-solid divide-grey-02">
            <div className="pb-8">
              <div className="flex max-w-xl flex-col">
                <p className="pb-2 text-base font-semibold text-black">
                  {t(
                    'pages.organisation_licensing_and_billing.license.product_section'
                  )}
                </p>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="product-ccourse">
                    <Switch
                      checked={courseType}
                      onChange={(isCheck) => {
                        setCourseType(isCheck);
                        axios.put(
                          `${config.backend}/organisation/${authUser?.activeOrganisationId}`,
                          {
                            useCourse: isCheck,
                          }
                        );
                        setAuth({
                          user: Object.assign({}, auth?.user, {
                            useCourse: isCheck,
                          }),
                          token: auth?.token,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="font-normal">{t('courseTypes.course')}</h4>
                  </div>
                </div>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="product-assessment">
                    <Switch
                      checked={assessmentType}
                      onChange={(isCheck) => {
                        setAssessmentType(isCheck);
                        axios.put(
                          `${config.backend}/organisation/${authUser?.activeOrganisationId}`,
                          {
                            useAssessment: isCheck,
                          }
                        );
                        setAuth({
                          user: Object.assign({}, auth?.user, {
                            useAssessment: isCheck,
                          }),
                          token: auth?.token,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="font-normal">
                      {t('courseTypes.assessment')}
                    </h4>
                  </div>
                </div>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="product-leervacature">
                    <Switch
                      checked={vacancyType}
                      onChange={(isCheck) => {
                        setVacancyType(isCheck);
                        axios.put(
                          `${config.backend}/organisation/${authUser?.activeOrganisationId}`,
                          {
                            useLeervacature: isCheck,
                          }
                        );
                        setAuth({
                          user: Object.assign({}, auth?.user, {
                            useLeervacature: isCheck,
                          }),
                          token: auth?.token,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="font-normal">
                      {t('courseTypes.leervacature')}
                    </h4>
                  </div>
                </div>
                {/* <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="product-instruction">
                    <Switch
                      checked={instructionType}
                      onChange={(isCheck) => {
                        setInstructionType(isCheck);
                        axios.put(
                          `${config.backend}/organisation/${authUser?.activeOrganisationId}`,
                          {
                            useInstruction: isCheck,
                          }
                        );
                        setAuth({
                          user: Object.assign({}, auth?.user, {
                            useInstruction: isCheck,
                          }),
                          token: auth?.token,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="font-normal">
                      {t('courseTypes.instruction')}
                    </h4>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="rounded-lg border border-grey-02 p-6">
          <div className="divide-y divide-solid divide-grey-02">
            <div className="pb-8">
              <div className="flex max-w-xl flex-col">
                <p className="pb-2 text-base font-semibold text-black">
                  {t(
                    'pages.organisation_licensing_and_billing.license.fitches_section'
                  )}
                </p>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="product-ccourse">
                    <Switch
                      checked={teoAiType}
                      onChange={(isCheck) => {
                        setTeoAiType(isCheck);
                        axios.put(
                          `${config.backend}/organisation/${authUser?.activeOrganisationId}`,
                          {
                            useTeoAi: isCheck,
                          }
                        );
                        setAuth({
                          user: Object.assign({}, auth?.user, {
                            useTeoAi: isCheck,
                          }),
                          token: auth?.token,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="font-normal">
                      {t(
                        'pages.organisation_licensing_and_billing.license.use_teo_ai'
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LicenseTab;

import React, { useState, useEffect } from 'react';
import { isEmpty, newUid, round2 } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import { BoxGUI } from '@/components/Froala/widgets/teo_box/BoxGUI';
import { Button, Modal } from '@teo/components';
import { T } from '@/components/T';
import { BoxIoService } from '@/components/Froala/widgets/teo_box/BoxIoService';
import { Check } from '@teo/components/icons';
import { ExplanationSection } from '../ExplanationSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { XAPIService } from '@/services/xapi.service';
import { genericXapiSubmit } from './common';

export const BoxQuestionView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setXApiSubmitFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  BoxQuestionView.syncStates(state, answer, correction);
  const [modified, setModified] = useState(null);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const answered = submitted || isCorrected;

  const [ref, setRef] = useState(newUid(20));
  const [loading, setLoading] = useState(false);
  const service = BoxIoService.getInstance();

  const [initialised, setInitialised] = useState(false);
  const [boxConnected, setBoxConnected] = useState(false);
  const [ovlWarning, setOvlWarning] = useState(false);
  const [evaluate, setEvaluate] = useState(null);
  const [attempts, setAttempts] = useState(answer.attempts);
  const [correct, setCorrect] = useState(answer.correct);
  const [tempCorrect, setTempCorrect] = useState(answer.correct);
  const [webSerialMissing, setWebserialMissing] = useState(false);

  //generate the answer
  const getAnswer = () => {
    answer.attempts = attempts;
    answer.correct = correct;
    answer.incomplete = !submitted && !correct && attempts < state.nrOfAttempts;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = () => {
    correction = {};
    BoxQuestionView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = async (lessonId, lang, minScore = 0.5) => {
    let correction = {};
    let answer = await getAnswer();
    BoxQuestionView.syncStates(state, answer, correction);

    const answerStr = answer.correct
      ? t('widgets.box.exercise_correct')
      : t('widgets.box.exercise_incorrect') +
        ` (${attempts}/${state.nrOfAttempts} ${t('widgets.box.attempts')})`;
    const correctStr = t('widgets.box.exercise_correct');

    return genericXapiSubmit(
      state,
      answer,
      correction,
      answerStr,
      correctStr,
      lessonId,
      lang,
      minScore
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  useEffect(() => {
    service.queueTriggers(state.uid, state.triggers);

    const initializedSubscription = service.onInitialize.subscribe(
      (initialized) => {
        setInitialised(initialized);
      }
    );
    const boxConnectedSubscription = service.boxConnected.subscribe(
      (boxConnected) => {
        setBoxConnected(boxConnected);
      }
    );
    const ovlSubscription = service.OVLWarning.subscribe((ovlWarning) => {
      setOvlWarning(ovlWarning);
    });
    return () => {
      service.stopTriggers(state.uid);
      initializedSubscription.unsubscribe();
      boxConnectedSubscription.unsubscribe();
      ovlSubscription.unsubscribe();
    };
  }, []);

  const evaluateDisabled = false;
  const fullscreen = false;

  let boxGui = (
    <BoxGUI
      key={state.uid}
      fullscreen={fullscreen}
      src={state.schema}
      state={state}
      setCorrect={(newCorrect) => {
        /*
        console.log(newCorrect, correct)
        if (!newCorrect && !correct) {
          setAttempts(Math.max(0, attempts - 1))
        }
        setTempCorrect(newCorrect)
        if (newCorrect && !correct) setCorrect(correct);
        */
      }}
      setEvaluate={(fn) => setEvaluate(() => fn)}
    />
  );

  if (state.hidden) return null;

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
    >
      {webSerialMissing && (
        <Modal isOpen>
          <Modal.Header onClose={() => setWebserialMissing(false)}>
            {t('widgets.box.webserial_missing_title')}
          </Modal.Header>
          <Modal.Body>{t('widgets.box.webserial_missing_content')}</Modal.Body>
          <Modal.Footer>
            <div className="flex w-full justify-between">
              <Button onClick={() => setWebserialMissing(false)}>
                {t('button.close')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(correction.score)}
          maxScore={state.score}
          answered={answered}
        >
          <div className="flex w-full items-center justify-between">
            {state.titleWidget || t('widgets.type.box_question')}
          </div>
        </WidgetHeader>
      )}
      <FroalaTextarea mode="view" value={state.vraag}></FroalaTextarea>
      {(initialised || boxConnected) && boxGui}
      <div
        className={`flex gap-4 ${
          initialised || boxConnected ? 'mt-7' : 'm-auto'
        }`}
      >
        {!boxConnected && (
          <Button
            className="box-connect-button"
            onClick={async () => {
              if (!('serial' in navigator)) {
                return setWebserialMissing(true);
              }
              setLoading(true);
              await service.netWorkService.Connect();
              setLoading(false);
            }}
          >
            <T>widgets.box.connect</T>
          </Button>
        )}
        {!initialised && (
          <Button
            variant="ghost"
            onClick={async () => {
              setInitialised(true);
            }}
          >
            <T>widgets.box.preview</T>
          </Button>
        )}
        {initialised && (
          <Button
            className="mr-1"
            disabled={evaluateDisabled}
            title={evaluateDisabled ? t('MAX_ATTEMPTS') : ''}
            onClick={async () => {
              if (!evaluate) return;
              let { free, correct: newCorrect } = await evaluate(
                state.incorrectMessage
              );
              if (!free && !correct && !newCorrect) {
                setAttempts(Math.min(state.nrOfAttempts, (attempts || 0) + 1));
              }
              if (!correct && newCorrect) setCorrect(true);
              setSubmitted(true);
              setModified(newUid(20));
            }}
          >
            <div className="flex flex-row items-center">
              {correct && <Check className="mr-2 w-5" />}
              {correct ? (
                <T>widgets.box.correct</T>
              ) : (
                <T>widgets.box.evaluate</T>
              )}
              {Number.isInteger(state.nrOfAttempts) ? (
                <span>
                  &nbsp;({Math.min((attempts || 0) + 1, state.nrOfAttempts)}/
                  {state.nrOfAttempts})
                </span>
              ) : (
                ''
              )}
            </div>
          </Button>
        )}
      </div>
      {showAnswers && (
        <>
          <span className="text-lg font-semibold text-grey-04">
            {t('widgets.box.corrections')}
          </span>
          <table
            className="borderless-table"
            style={{ borderCollapse: 'collapse' }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '20%' }}>
                  <T>widgets.box.name</T>
                </th>
                <th style={{ textAlign: 'left' }}>
                  <T>widgets.box.description</T>
                </th>
                <th style={{ width: '40px' }} title={t('COUNT_TOWARDS_SCORE')}>
                  <T>widgets.box.free</T>
                </th>
              </tr>
            </thead>
            <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
              {state?.corrections?.map((corr, i) => (
                <tr key={'correction_' + i} style={{ alignItems: 'center' }}>
                  <td style={{ verticalAlign: 'middle', width: '250px' }}>
                    {corr.name}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {corr.description}
                  </td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    {corr.free ? 'x' : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {(resultPages ||
        submitted ||
        showAnswers ||
        answered ||
        correct ||
        (attempts && attempts >= state.nrOfAttempts)) &&
      state?.antwoord ? (
        <ExplanationSection state={state} />
      ) : null}
    </div>
  );
};

BoxQuestionView.syncStates = (state, answer, correction) => {
  if (isEmpty(answer)) {
    answer.uid = state.uid;
    answer.answered = false;
    answer.correct = false;
    answer.attempts = 0;
  }
  if (isEmpty(correction)) {
    if (BoxGUI.instances[state.uid]) {
      BoxGUI.instances[state.uid].evaluate(state.incorrectMessage, false);
    }

    correction.uid = state.uid;

    if (answer.correct) {
      if (
        state.nrOfAttempts &&
        Number.isInteger(parseFloat(state.nrOfAttempts))
      ) {
        correction.score =
          (state.score / state.nrOfAttempts) *
          (state.nrOfAttempts - Math.min(answer.attempts, state.nrOfAttempts));
      } else {
        correction.score = state.score;
      }
    } else {
      correction.score = 0;
    }
  }
};

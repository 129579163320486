import {
  IconButton,
  Button,
  ButtonLink,
  FilterDropdown,
  Spinner,
  Modal,
  PersonAvatar,
} from '@teo/components';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { OpenExternalLink } from '@teo/components/icons';
import { langData } from '@/mocks/landData';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { Membercoursecontentdetails } from '@/models/Database';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { ConfirmTranslationModal } from '@/components/ConfirmTranslationModal/ConfirmTranslationModal';

export const TranslatePublication = (props: any) => {
  const { auth } = useContext(AuthContext);

  const languageMasterId =
    props.publication.languageMasterId || props.publication.id;

  const translationsResult = useGet<Array<Membercoursecontentdetails>>(
    `/membercoursecontentdetails`,
    {
      languageMasterId: `${languageMasterId},OR,coursecontentId=${props.publication.id},OR,coursecontentId=${languageMasterId}`,
      organisationId: auth?.user?.activeOrganisationId,
      sort: 'createdAt',
    }
  );

  const translations: Array<Membercoursecontentdetails> | [] =
    translationsResult?.data?.data || [];

  const { t } = useTranslation();
  const [lang, setLang] = useState({
    id: '',
    name: '',
    value: '',
  });

  const [selectLang, setSelectLang] = useState({
    id: '',
    name: '',
    value: '',
  });
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const basePath = window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf('/')
  );

  const handleTranslate = async (newLang: any) => {
    setIsOpenConfirmModal(false);
    await axios
      .post(
        `${config.backend}/api/translate/${languageMasterId}?lang=${newLang?.value}&service=google`,
        { organisationId: auth?.user?.activeOrganisationId }
      )
      .then(() => {
        translationsResult.refetch().then(() => {
          setLang({ id: '', name: '', value: '' });
          setSelectLang({ id: '', name: '', value: '' });
        });
      });
  };

  return (
    <>
      <Modal isOpen={true}>
        <Modal.Header
          onClose={() => {
            props.onClose();
          }}
        >
          {t('pages.create_edit_course.language')}
        </Modal.Header>
        <Modal.Body>
          <div className="text-sm font-normal">
            <span className="text-[#7d7d81]">
              {t(
                `pages.create_edit_course.type_${props?.publicationType}.modal_text`
              )}
            </span>
            <Link
              to="https://teo.training/en/help/faqs/53"
              className="ml-2 text-[#1173dd]"
              target="_blank"
            >
              {t('pages.create_edit_course.modal_linked_text')}
              <IconButton
                Icon={OpenExternalLink}
                variant="ghost"
                size="sm"
                className="text-[#1173dd]"
              />
            </Link>
          </div>

          <div className="mt-6">
            <h2 className="mb-6 text-sm font-semibold text-[#6d6d72]">
              {t('pages.create_edit_course.available_languages')}
            </h2>

            <div>
              {translations.map((translation) => (
                <div key={translation.id}>
                  <div className="mb-6 flex items-center justify-between">
                    <div className="flex items-center">
                      <span>
                        {langData.find(
                          (lang) => lang.value === translation.lang && lang.flag
                        ) ? (
                          <img
                            src={`/images/flags/${translation.lang}.svg`}
                            alt={`${translation.lang} flag`}
                            className="
                                h-[32px] w-[32px]
                                rounded-[50%]
                                object-cover
                                "
                          />
                        ) : (
                          <PersonAvatar
                            id={translation.id}
                            name={translation.name as string}
                            imageSrc={
                              `/images/flags/${translation.lang}.svg` ||
                              undefined
                            }
                          />
                        )}
                      </span>
                      <span className="ml-3">
                        {t(`langs.${translation.lang}`)}
                      </span>
                    </div>

                    <div className="text-sm font-medium">
                      {props.publication.lang === translation.lang ? (
                        <Button variant="ghost">
                          {t('pages.create_edit_course.current_version')}
                        </Button>
                      ) : (
                        <ButtonLink
                          to={`${basePath}/${translation.id}`}
                          variant="primary"
                          onClick={() => props.setRefreshPage(true)}
                        >
                          {t('pages.create_edit_course.go_to_button')}
                          <span className="mx-1">
                            {t(`langs.${translation.lang}`)}
                          </span>
                          {t('pages.create_edit_course.go_to_button_2')}
                        </ButtonLink>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {lang.value && (
                <div>
                  <div className="mb-6 flex items-center justify-between">
                    <div className="flex items-center">
                      <span>
                        {langData.find(
                          (data) => data.value === lang.value && data.flag
                        ) ? (
                          <img
                            src={`/images/flags/${lang.value}.svg`}
                            alt={`${lang.value} flag`}
                            className="
                                h-[32px] w-[32px]
                                rounded-[50%]
                                object-cover
                                "
                          />
                        ) : (
                          <PersonAvatar
                            id={lang.id}
                            name={lang.name as string}
                            imageSrc={
                              `/images/flags/${lang.value}.svg` || undefined
                            }
                          />
                        )}
                      </span>

                      <span className="ml-3">{t(`langs.${lang.value}`)}</span>
                    </div>

                    <div className="text-sm font-medium">
                      {props.publication.lang === lang.value ? (
                        <Button variant="ghost">
                          {t('pages.create_edit_course.current_version')}
                        </Button>
                      ) : (
                        <div className="item-center flex">
                          <Spinner
                            ariaLabel="Loading spinner"
                            className="my-auto mr-2 h-5 w-5 border-grey-05"
                          />
                          <Button disabled variant="primary">
                            {t('pages.create_edit_course.go_to_button')}
                            <span className="mx-1">
                              {t(`langs.${lang.value}`)}
                            </span>
                            {t('pages.create_edit_course.go_to_button_2')}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          cancelButtonLabel={t('pages.create_edit_course.cancel_button')}
          onCancel={() => {
            props.onClose();
          }}
        >
          <div>
            <FilterDropdown
              clearButtonLabel="clear"
              onChange={async (lang: any) => {
                setIsOpenConfirmModal(true);
                setSelectLang(lang);
              }}
              buttonText={`${t(
                'pages.create_edit_course.translate_automatically_button'
              )}`}
              options={langData}
              searchable={true}
              value={selectLang?.name ? selectLang : undefined}
            />
          </div>
        </Modal.Footer>
      </Modal>

      <ConfirmTranslationModal
        isOpenConfirmModal={isOpenConfirmModal}
        setIsOpenConfirmModal={setIsOpenConfirmModal}
        handleTranslate={handleTranslate}
        setLang={setLang}
        selectLang={selectLang}
        setSelectLang={setSelectLang}
      />
    </>
  );
};

import './app.css';
import { config } from '@/config';
import './language';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './router';
import { store } from './store';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { ActiveLessonContext } from '@/components/Contexts/ActiveLessonContext';
import { ActiveCourseContext } from '@/components/Contexts/ActiveCourseContext';
import { ActiveStudentContext } from '@/components/Contexts/ActiveStudentContext';
import { ActiveLessonStatusesContext } from '@/components/Contexts/ActiveLessonStatusesContext';
import { NotificationUpdateContex } from '@/components/Contexts/NotificationUpdateContex';
import { OrganisationContext } from '@/components/Contexts/OrganisationContext';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { axios } from '@/axios';
import jwt_decode from 'jwt-decode';
import { useMediaQuery } from 'react-responsive';
import { User } from '@/models/user.interface';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { parseJwt, useStateAndRef, useTraceUpdate } from '@/util';
import { XAPIService } from '@/services/xapi.service.js';

const languageCodeRegex = new RegExp(
  '(.*?:\\/\\/.*?\\/)(' + config.langs.join('|') + ')?\\/?(.*)',
  'gi'
);
const queryClient = new QueryClient();

let exportSetShowNotification;
let exportAuth;

const App = ({ authData }: any) => {
  const [auth, setAuth, authRef] = useStateAndRef(authData);
  const [newPage, setNewPage] = useState<any>(null);
  const [lesson, setLesson] = useState<any>(null);
  const [course, setCourse] = useState<any>(null);
  const [student, setStudent] = useState<any>(null);
  const [lessonStatus, setLessonStatus] = useState<any>(null);
  const [notification, setShowNotification] = useState<any>(null);
  const [myOrganisations, setMyOrganisations] = useState<any>(null);
  const [notificationUpdate, setNotificationUpdate] = useState<any>(null);
  const [hints, setHints] = useState<any>(null);
  //const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  let isDesktop = true;
  //no hover === no mouse detected
  if (window.matchMedia('(any-hover: none)').matches) {
    isDesktop = false;
  }
  //small screen
  if (!useMediaQuery({ query: '(min-width: 768px)' })) {
    isDesktop = false;
  }

  exportSetShowNotification = setShowNotification;
  exportAuth = auth;

  useEffect(() => {
    const processToken = async (token: string) => {
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const newUser = parseJwt(token);
      const auth = authRef.current;

      let updateUser =
        auth?.user?.activeOrganisationId != newUser?.activeOrganisationId;
      const parsedUser = Object.assign({}, auth?.user, newUser);

      if (localStorage.getItem('invite')) {
        try {
          await axios.post<any>(`${config.backend}/invite/token`, {
            inviteToken: localStorage.getItem('invite'),
          });
        } catch (e) {
          console.error();
        }
        localStorage.removeItem('invite');
      }

      if (!parsedUser.username) updateUser = true;

      //handle postlogin tokens
      //handle a future add to organisation
      const postLoginOrganisation = localStorage.getItem(
        'postLoginOrganisation'
      );
      if (postLoginOrganisation && postLoginOrganisation !== 'undefined') {
        try {
          await axios.post(`${config.backend}/members`, {
            userId: parsedUser.id,
            organisationId: parseFloat(postLoginOrganisation),
          });
        } catch (e) {
          console.error(e);
        }
        //switch organisation
        try {
          if (postLoginOrganisation && postLoginOrganisation !== 'undefined') {
            await axios.put(`${config.backend}/user/${parsedUser.id}`, {
              activeOrganisationId: parseFloat(postLoginOrganisation),
              id: parsedUser.id,
            });
            updateUser = true;
          }
        } catch (e) {
          console.error(e);
        }
      }
      localStorage.removeItem('postLoginOrganisation');

      //handle a future register to course
      const postLoginCourseRegister = localStorage.getItem(
        'postLoginCourseRegister'
      );
      if (postLoginCourseRegister) {
        localStorage.removeItem('postLoginCourseRegister');
        try {
          await axios.post(`${config.backend}/follows`, {
            userId: parsedUser.id,
            courseId: parseFloat(postLoginCourseRegister),
          });
        } catch (e) {
          console.error(e);
        }
      }

      if (updateUser) {
        try {
          const response = await axios.get<{ token: string; user: User }>(
            `${config.backend}/auth/token`
          );
          setAuth(response.data);
        } catch (e) {
          localStorage.removeItem('token');
        }
      } else {
        await setAuth({ user: parsedUser, token: token });
      }
    };

    const extractToken = async (response: any) => {
      if (
        response?.headers?.token &&
        response?.headers?.token !== localStorage.getItem('token')
      ) {
        await processToken(response.headers.token);
      }
    };

    //watch for changed tokens
    axios.interceptors.response.use(
      async (response) => {
        await extractToken(response);
        return response;
      },
      async (error) => {
        await extractToken(error.response);
        return Promise.reject(error);
      }
    );

    //initialise token
    const token = localStorage.getItem('token');
    if (token) processToken(token);
  }, []);

  const router = createBrowserRouter(routes);

  return (
    <Suspense>
      <Provider store={store}>
        <DndProvider
          backend={isDesktop ? HTML5Backend : TouchBackend}
          options={{ enableMouseEvents: true, preview: true }}
        >
          <QueryClientProvider client={queryClient}>
            {createPortal(
              <>
                {config.langs.map((lang: string) => (
                  <link
                    key={lang}
                    rel="alternate"
                    hrefLang={lang}
                    href={window.location.href.replace(
                      languageCodeRegex,
                      '$1' + lang + '/$3'
                    )}
                  />
                ))}
                <link
                  rel="alternate"
                  hrefLang="x-default"
                  href={window.location.href.replace(languageCodeRegex, '$1$3')}
                />
              </>,
              document.head
            )}
            <PageUrlContext.Provider value={{ newPage, setNewPage }}>
              <NotificationContext.Provider
                value={{ notification, setShowNotification }}
              >
                <HintsContext.Provider value={{ hints, setHints }}>
                  <NotificationUpdateContex.Provider
                    value={{ notificationUpdate, setNotificationUpdate }}
                  >
                    <OrganisationContext.Provider
                      value={{ myOrganisations, setMyOrganisations }}
                    >
                      <ActiveStudentContext.Provider
                        value={{ student, setStudent }}
                      >
                        <ActiveCourseContext.Provider
                          value={{ course, setCourse }}
                        >
                          <ActiveLessonContext.Provider
                            value={{ lesson, setLesson }}
                          >
                            <ActiveLessonStatusesContext.Provider
                              value={{ lessonStatus, setLessonStatus }}
                            >
                              <AuthContext.Provider value={{ auth, setAuth }}>
                                <RouterProvider router={router} />
                              </AuthContext.Provider>
                            </ActiveLessonStatusesContext.Provider>
                          </ActiveLessonContext.Provider>
                        </ActiveCourseContext.Provider>
                      </ActiveStudentContext.Provider>
                    </OrganisationContext.Provider>
                  </NotificationUpdateContex.Provider>
                </HintsContext.Provider>
              </NotificationContext.Provider>
            </PageUrlContext.Provider>
          </QueryClientProvider>
        </DndProvider>
      </Provider>
    </Suspense>
  );
};

export {
  App,
  exportSetShowNotification as setShowNotification,
  exportAuth as auth,
};

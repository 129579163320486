import { useTranslation } from 'react-i18next';
import { Button, Modal, Tabs } from '@teo/components';
import { useState, useEffect } from 'react';
import { parseFilter } from '@/util';
import CompetencyTab from './CompetencyTab';
import { useParams } from 'react-router-dom';
import { axios } from '@/axios';
import { config } from '@/config';

const EditCompetencies = ({
  state,
  setIsOpenCompetencies,
  isOpenCompetencies,
  competencies,
  setCompetencies,
}) => {
  const { t } = useTranslation();
  const [newCompetency, setNewCompetency] = useState([]);
  const [knowledgeCompetency, setKnowledgeCompetency] = useState([]);
  const [attitudesCompetency, setAttitudesCompetency] = useState([]);
  const [skillsCompetency, setSkillsCompetency] = useState([]);
  const [allCompetency, setAllCompetency] = useState([]);
  const [tabCompetency, setTabCompetency] = useState([]);
  const [filterCompetency, setFilterCompetency] = useState('');
  const [professions, setProfessions] = useState();
  const [tab, setTab] = useState('Kennis');
  const { coursecontentId } = useParams();

  useEffect(() => {
    if (competencies?.length > 0) {
      setNewCompetency(competencies);
    }
  }, []);

  useEffect(() => {
    setProfessions([]);
    axios
      .get(
        `${config.backend}/coursecontentprofessions?coursecontentId=${coursecontentId}`
      )
      .then((result) => {
        setProfessions(result?.data);
      });
  }, []);

  useEffect(() => {
    if (professions && professions?.length > 0) {
      setAllCompetency([]);
      if (filterCompetency?.length < 1) {
        professions?.map((el) => {
          if (el?.professionId) {
            axios
              .get(
                `${config.backend}/professionqualifications?professionId=${el?.professionId}
                 `
              )
              .then((result) => {
                const newC = result?.data ? result?.data : [];
                setAllCompetency((prev) => [
                  ...prev,
                  ...newC.filter(
                    (obj2) => !prev.some((obj1) => obj1.id === obj2.id)
                  ),
                ]);
              });
          }
        });
      }
    }
  }, [professions]);

  useEffect(() => {
    if (professions && professions?.length > 0) {
      if (allCompetency?.length > 0) {
        setTabCompetency([]);
        if (filterCompetency?.length > 0) {
          professions?.map((el) => {
            if (el?.professionId) {
              axios
                .get(
                  `${config.backend}/professionqualifications?professionId=${
                    el?.professionId
                  }&count=10&q=${parseFilter(tab)}%2CAND%2C${parseFilter(
                    filterCompetency
                  )}
                 `
                )
                .then((result) => {
                  const newC = result?.data ? result?.data : [];
                  setTabCompetency((prev) => [
                    ...prev,
                    ...newC.filter(
                      (obj2) => !prev.some((obj1) => obj1.id === obj2.id)
                    ),
                  ]);
                });
            }
          });
        } else {
          setTabCompetency(allCompetency);
        }
      }
    }
  }, [professions, filterCompetency, allCompetency]);

  const handleSave = () => {
    handleRemoveAcnive();
    setCompetencies(newCompetency);
    setIsOpenCompetencies(false);
  };

  const handleRemoveAcnive = () => {
    let placeholder = document.getElementById(state.uid);
    const divsToRemove = placeholder.getElementsByClassName('active-widget');
    Array.from(divsToRemove).forEach((div) => {
      div.remove();
    });
  };

  return (
    <div className="bg-white">
      <Modal isOpen={isOpenCompetencies}>
        <Modal.Header
          onClose={() => {
            handleRemoveAcnive();
            setIsOpenCompetencies(false);
          }}
        >
          <h2 className="text-sm font-semibold">
            {t('widgets.widget_competencies.edit_competencies')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-1 space-y-2 md:space-y-4">
            <div className="mb-8 flex flex-col lg:flex-row">
              <div>
                <Tabs
                  activeName={tab}
                  onChange={(tab) => {
                    setTab(tab);
                    setFilterCompetency('');
                  }}
                >
                  <Tabs.TabButton name="Kennis">
                    <p className="text-xs font-medium md:text-base lg:text-base">
                      {t('widgets.widget_competencies.knowledge')}
                    </p>
                    <span className="pl-2 text-grey-05">
                      {knowledgeCompetency?.length}
                    </span>
                  </Tabs.TabButton>
                  <Tabs.TabButton name="Attitudes">
                    <p className="text-xs font-medium md:text-base lg:text-base">
                      {t('widgets.widget_competencies.attitudes')}
                    </p>
                    <span className="pl-2 text-grey-05">
                      {attitudesCompetency?.length}
                    </span>
                  </Tabs.TabButton>
                  <Tabs.TabButton name="Vaardigheden">
                    <p className="text-xs font-medium md:text-base lg:text-base">
                      {t('widgets.widget_competencies.skills')}
                    </p>
                    <span className="pl-2 text-grey-05">
                      {skillsCompetency?.length}
                    </span>
                  </Tabs.TabButton>
                </Tabs>
              </div>
            </div>
            <CompetencyTab
              setNewCompetency={setNewCompetency}
              newCompetency={newCompetency}
              myCompetencies={competencies}
              setWidgetCompetency={setKnowledgeCompetency}
              widgetCompetency={knowledgeCompetency}
              setFilterCompetency={setFilterCompetency}
              filterCompetency={filterCompetency}
              allCompetency={tabCompetency.filter((el) =>
                el?.path?.includes('Kennis;;')
              )}
              typeCompetency={'Kennis'}
              tab={tab}
            />
            <CompetencyTab
              setNewCompetency={setNewCompetency}
              newCompetency={newCompetency}
              myCompetencies={competencies}
              setWidgetCompetency={setAttitudesCompetency}
              widgetCompetency={attitudesCompetency}
              setFilterCompetency={setFilterCompetency}
              filterCompetency={filterCompetency}
              allCompetency={tabCompetency.filter((el) =>
                el?.path?.includes('Attitudes;;')
              )}
              typeCompetency={'Attitudes'}
              tab={tab}
            />
            <CompetencyTab
              setNewCompetency={setNewCompetency}
              newCompetency={newCompetency}
              myCompetencies={competencies}
              setWidgetCompetency={setSkillsCompetency}
              widgetCompetency={skillsCompetency}
              setFilterCompetency={setFilterCompetency}
              filterCompetency={filterCompetency}
              allCompetency={tabCompetency.filter((el) =>
                el?.path?.includes('Vaardigheden;;')
              )}
              typeCompetency={'Vaardigheden'}
              tab={tab}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="flex w-full">
            <Button
              variant="ghost"
              onClick={() => {
                handleRemoveAcnive();
                setIsOpenCompetencies(false);
              }}
            >
              {t('button.cancel')}
            </Button>
            <Button onClick={() => handleSave()} className="ml-auto">
              {t('widgets.widget_competencies.save_competencies')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditCompetencies;

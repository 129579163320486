import { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { IconButton, InputScore } from '@teo/components';
import { ArrowRight, CrossFilled } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import 'isomorphic-fetch';
import { handleAgainGeneration, handleNewGeneration } from './requestAI';

import { TryAgain, ExplainIcon } from '../../ChatGPT/Icon';

const OrderAI = ({
  setQuestionVraag,
  setOrderQuestions,
  orderQuestions,
  setIsLoadingAI,
  isLoadingAI,
  setIsLoadingAIAnswer,
  isLoadingAIAnswer,
  setIsChatOpen,
  stateUid,
  questionVraag,
  state,
}) => {
  const { auth } = useContext(AuthContext);
  const userId = auth?.user?.id;

  const userLang = auth?.user?.lang;

  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [completion, setCompletion] = useState(null);
  const [completionAdd, setCompletionAdd] = useState(null);
  const [answerNumber, setAnswerNumber] = useState(2);
  const [prevResult, setPrevResult] = useState(null);
  const [prevOrderQuestions, setPrevOrderQuestions] = useState(null);
  const [existingObject, setExistingObject] = useState({
    score: state?.score,
    vraag: questionVraag,
    type: 'OrderVraag',
    orderQuestions: orderQuestions,
  });

  const removeParagraphs = (htmlString) => {
    const withoutParagraphs = htmlString.replace(/<p[^>]*>|<\/p>/g, '');
    return withoutParagraphs;
  };
  useEffect(() => {
    const resultString = questionVraag ? removeParagraphs(questionVraag) : '';
    setExistingObject({
      score: state?.score,
      vraag: resultString,
      type: 'OrderVraag',
      orderQuestions: orderQuestions,
    });
  }, [orderQuestions, questionVraag]);

  useEffect(() => {
    const placeholder = document.querySelector(
      `#${CSS.escape(stateUid)} .wg-question .question_optional`
    );
    const handleContentChange = () => {
      const placeholderText = placeholder.innerText;
      setExistingObject((prev) => ({
        ...prev,
        vraag: placeholderText,
      }));
    };

    const observer = new MutationObserver(handleContentChange);

    observer.observe(placeholder, {
      subtree: true,
      characterData: true,
      childList: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [questionVraag]);

  useEffect(() => {
    if (completion) {
      const resultString = removeParagraphs(completion?.vraag);
      setQuestionVraag(`<p>${resultString}</p>`);
      setOrderQuestions(completion?.orderQuestions);
      setIsLoadingAI(false);
      setIsLoadingAIAnswer(false);
      setCompletion(null);
      setTimeout(() => {
        const placeholder = document.querySelector(
          `#${CSS.escape(stateUid)} .wg-question .question_optional`
        );
        placeholder.focus();
      }, 100);
    }
  }, [completion]);

  useEffect(() => {
    if (completionAdd) {
      setPrevOrderQuestions((prev) => (prev ? prev : orderQuestions));
      const newOrderQuestions = completionAdd?.orderQuestions;
      setOrderQuestions(() => newOrderQuestions);
      setIsLoadingAIAnswer(false);
      setCompletionAdd(null);
      setTimeout(() => {
        const placeholder = document.querySelector(
          `#${CSS.escape(stateUid)} .wg-question .question_optional`
        );
        placeholder.focus();
      }, 100);
    }
  }, [completionAdd]);

  function handleSubmit() {
    handleNewGeneration(
      setIsLoadingAI,
      setIsLoadingAIAnswer,
      userLang,
      answerNumber,
      userId,
      prompt,
      setPrompt,
      setCompletion,
      existingObject,
      setPrevResult
    );
  }

  function handleAgain() {
    if (prevResult?.answer) {
      setOrderQuestions(prevOrderQuestions);
    }
    handleAgainGeneration(
      setIsLoadingAI,
      setIsLoadingAIAnswer,
      userId,
      setCompletion,
      setCompletionAdd,
      prevResult
    );
  }

  function handleAddAnswers() {
    const prompt =
      answerNumber > 1
        ? t('widgets.widget_chat_gpt.add_answers', {
            answerNumber: answerNumber,
          })
        : t('widgets.widget_chat_gpt.add_answer', {
            answerNumber: answerNumber,
          });
    handleNewGeneration(
      setIsLoadingAI,
      setIsLoadingAIAnswer,
      userLang,
      answerNumber,
      userId,
      prompt,
      setPrompt,
      setCompletion,
      existingObject,
      setPrevResult
    );
  }

  const handleKeyPress = (e) => {
    if (!isLoadingAI) {
      if (e.keyCode === 13 && prompt.length > 2) {
        handleSubmit();
        setPrompt('');
      }
    }
  };

  const handleChange = (e) => {
    if (!isLoadingAI) {
      setPrompt(e.target.value);
    }
  };

  const handleClose = () => {
    const placeholder = document.querySelector(`#${CSS.escape(stateUid)}`);
    const divsToRemove = placeholder.getElementsByClassName('active-widget');
    Array.from(divsToRemove).forEach((div) => {
      div.remove();
    });
    setIsChatOpen(false);
  };

  const classInput =
    'px-11 h-10 w-full rounded-md border border-solid border-transparent py-0 text-base text-grey-08 !shadow-none outline outline-2 !outline-offset-0 outline-transparent !ring-0 placeholder:text-sm placeholder:text-grey-04 enabled:bg-grey-transparent-01 enabled:hover:border-grey-transparent-03 enabled:hover:bg-white enabled:hover:outline-grey-transparent-01 enabled:focus:border-secondary-04 enabled:focus:bg-white enabled:focus:outline-secondary-01 enabled:active:border-secondary-04 enabled:active:bg-white enabled:active:outline-secondary-01 disabled:border-grey-03 disabled:bg-grey-transparent-02';

  return (
    <div className="chat-action remove_me_gEE8QIPZ6LEay22SdSCU">
      <div className="relative my-3 w-full">
        <div className="relative flex w-full">
          <div className="relative mr-14 w-full">
            <img
              src="/images/icons/chatgpt.svg"
              alt="chatgpt"
              className="!absolute top-3 left-2 z-[1] w-6"
            />
            <input
              disabled={isLoadingAI}
              id="chat-gpt"
              value={prompt}
              onChange={(e) => handleChange(e)}
              placeholder={t('chat_gpt.placeholder')}
              className={classInput}
              ref={inputRef}
              onKeyUp={handleKeyPress}
            />
            <div
              className="absolute top-2.5 right-2 z-[1] cursor-pointer"
              onClick={() => handleSubmit()}
            >
              <ArrowRight
                className={` w-6 pl-2 pr-0 text-grey-05 sm:w-9 sm:w-9 sm:pr-2`}
              />
            </div>
          </div>
          <IconButton
            className="!absolute top-0.5 right-1"
            Icon={CrossFilled}
            variant="ghost"
            onClick={() => {
              handleClose();
            }}
          />
        </div>

        <p className="mt-3 text-sm text-grey-05">
          {t('chat_gpt.help_message')}
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <h4>{t('widgets.multiple_choice_gpt.options')}</h4>
        <button
          disabled={
            prevResult && !isLoadingAI && !isLoadingAIAnswer ? false : true
          }
          className="chat_gpt_btn flex w-max items-center gap-2 rounded-2xl bg-secondary-01 py-1 pl-3 pr-4 text-sm text-secondary-04 hover:bg-secondary-04 hover:text-secondary-01 disabled:bg-grey-02 disabled:text-grey-04"
          onClick={() => handleAgain()}
        >
          <TryAgain
            className={`w-4
              ${
                prevResult && !isLoadingAI && !isLoadingAIAnswer
                  ? 'text-secondary-04'
                  : '!text-grey-04'
              }
            `}
          />
          {t('widgets.multiple_choice_gpt.try_again')}
        </button>
        <button
          disabled={!isLoadingAI && !isLoadingAIAnswer ? false : true}
          className="chat_gpt_btn flex w-max items-center gap-2 rounded-2xl bg-secondary-01 py-1 pl-3 pr-4 text-sm text-secondary-04 hover:bg-secondary-04 hover:text-secondary-01 disabled:bg-grey-02 disabled:text-grey-04"
          onClick={() => handleAddAnswers()}
        >
          <ExplainIcon
            className={`w-[11px]
              ${
                !isLoadingAI && !isLoadingAIAnswer
                  ? 'text-secondary-04'
                  : '!text-grey-04'
              }
            `}
          />
          {t('widgets.multiple_choice_gpt.new_answers')}
        </button>
      </div>
      <div className="mt-5 flex flex-col gap-2">
        <h4>{t('widgets.multiple_choice_gpt.answer_options')}</h4>
        <div className="mt-2 flex w-full flex-row items-center lg:mt-0 lg:w-auto">
          <p className="mr-1 text-sm">
            {t('widgets.multiple_choice_gpt.number_options')}
          </p>
          <InputScore
            value={answerNumber}
            onChange={(value) => value > 0 && setAnswerNumber(value)}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
export default OrderAI;

import {
  Button,
  IconButton,
  InputSelect,
  InputText,
  PersonAvatar,
  Stepper,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { T } from '@/components/T';
import { UserInvite } from '@/components/UserInvite/UserInvite';
import { useGet } from '@/query';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseRole, useMobile, deep_copy } from '@/util';
import { AuthContext } from '@/components/Contexts/AuthContext';

const MAXSTEP = 2;

const roles = [10, 20, 40, 45, 70, 90];

const langs = [
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'nl',
    label: 'NL',
  },
  {
    value: 'fr',
    label: 'FR',
  },
  {
    value: 'es',
    label: 'ES',
  },
];

function AddUsersStepsContainer(props: any) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { auth } = useContext(AuthContext);
  const activeOrganisationId = auth?.user?.activeOrganisationId;
  const activeLang = auth?.user?.lang;

  const [step, setStep] = useState(1);

  return (
    <div className="flex h-full flex-col border-t border-grey-02">
      <Stepper>
        <Stepper.Item
          isActive={step === 1}
          isDone={step > 1}
          onClick={() => setStep(1)}
          step="1"
        >
          {(!isMobile || step === 1) && (
            <T>{t('pages.manage_add_users.add_users_step_1')}</T>
          )}
        </Stepper.Item>
        <Stepper.Item
          isActive={step === 2}
          isDone={step > 2}
          onClick={() => setStep(2)}
          step="2"
        >
          {(!isMobile || step === 2) && (
            <T>{t('pages.manage_add_users.add_users_step_2')}</T>
          )}
        </Stepper.Item>
      </Stepper>
      <div className="h-full max-h-[calc(100vh_-_225px)] overflow-y-auto py-8">
        <div className="mx-auto flex max-w-screen-md flex-col justify-center px-3">
          {step === 1 && (
            <>
              <p className="text-base font-semibold">
                {t('pages.manage_add_users.add_users_step_1')}
              </p>
              <p className="text-[#7d7d81]">
                {t('pages.manage_add_users.subtitle')}
              </p>

              <UserInvite
                fetch={useGet.bind(null, '/memberdetails', {
                  organisationId: activeOrganisationId,
                })}
                multiselectLabel=""
                inviteButtonText={t('pages.manage_add_users.add_button')}
                value={props.value}
                setValue={props.setValue}
                newUser={props.newUser}
                setNewUser={props.setNewUser}
                data={props.data}
                setData={props.setData}
                handleAddUserToList={props.handleAddUserToList}
                activeLang={activeLang}
              />

              <div>
                {props.newUser.map((item: any) => (
                  <div
                    key={item.key + '_1'}
                    className="mb-4 flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <PersonAvatar
                        inline
                        id={item.key}
                        name={item.label}
                        imageSrc={item.picture}
                      ></PersonAvatar>

                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-[#231f20]">
                          {item.label}
                        </span>
                      </div>
                    </div>

                    <IconButton
                      className="my-auto mx-6"
                      Icon={CrossFilled}
                      variant="ghost"
                      onClick={() => props.handleRemove(item.key)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="flex flex-col">
                <span className="pb-2 text-base font-semibold">
                  {t('pages.manage_add_users.add_users_to_your_organization')}
                </span>
                <span className="text-sm font-normal text-grey-06">
                  {t('pages.manage_add_users.add_additional_info')}
                </span>
              </div>
              {isMobile ? (
                <div className="mt-8 flex flex-col gap-3">
                  {props?.newUser.map(
                    (el: any, i: number) =>
                      el?.newUser && (
                        <div
                          key={el.key + '_2'}
                          className="flex max-w-[450px] flex-col rounded-lg border border-grey-02 p-4"
                        >
                          <p className="pb-1 text-sm font-semibold text-black">
                            {t('pages.manage_add_users.email_address')}
                          </p>
                          <div>
                            {el.newUser && (
                              <InputText
                                disabled
                                id="email"
                                value={el.label}
                                onChange={() => {}}
                                className="pb-3"
                              />
                            )}
                          </div>
                          <p className="pb-1 text-sm font-semibold text-black">
                            {t('pages.manage_add_users.firstname')}
                          </p>
                          <div>
                            {el.newUser && (
                              <InputText
                                id="firstname"
                                onChange={(event: any) => {
                                  const newUsers = deep_copy(props.newUser);
                                  newUsers[i].firstName = event.target.value;
                                  props.setNewUser(newUsers);
                                }}
                                value={el.firstName}
                                className="pb-3"
                              />
                            )}
                          </div>
                          <p className="pb-1 text-sm font-semibold text-black">
                            {t('pages.manage_add_users.lastname')}
                          </p>
                          <div>
                            {el.newUser && (
                              <InputText
                                id="lastname"
                                onChange={(event: any) => {
                                  const newUsers = deep_copy(props.newUser);
                                  newUsers[i].lastName = event.target.value;
                                  props.setNewUser(newUsers);
                                }}
                                value={el.lastName}
                                className="pb-3"
                              />
                            )}
                          </div>
                          <p className="pb-1 text-sm font-semibold text-black">
                            {t('pages.manage_add_users.role')}
                          </p>
                          <div className="mb-3">
                            {el.newUser && (
                              <InputSelect
                                onChange={(role: number) => {
                                  const newUsers = deep_copy(props.newUser);
                                  newUsers[i].role = role;
                                  props.setNewUser(newUsers);
                                }}
                                value={el.role}
                                options={roles}
                              >
                                {(option) => (
                                  <span key={option}>
                                    {t(parseRole(option))}
                                  </span>
                                )}
                              </InputSelect>
                            )}
                          </div>
                          <p className="pb-1 text-sm font-semibold text-black">
                            {t('pages.manage_add_users.phone_number')}
                          </p>
                          <div className="mr-2">
                            {el.newUser && (
                              <InputText
                                id="phone"
                                onChange={(event: any) => {
                                  const newUsers = deep_copy(props.newUser);
                                  newUsers[i].phone = event.target.value;
                                  props.setNewUser(newUsers);
                                }}
                                value={el.phone}
                                className="pb-3"
                              />
                            )}
                          </div>
                          <p className="pb-1 text-sm font-semibold text-black">
                            {t('pages.manage_add_users.language')}
                          </p>
                          <div className="mb-3">
                            {el.newUser && (
                              <InputSelect
                                onChange={(lang) => {
                                  const newUsers = deep_copy(props.newUser);
                                  newUsers[i].lang = lang.value;
                                  props.setNewUser(newUsers);
                                }}
                                value={
                                  el.lang
                                    ? langs.find(
                                        (lang) => lang?.value === el.lang
                                      )
                                    : langs.find(
                                        (lang) => lang?.value === activeLang
                                      )
                                }
                                options={langs}
                              >
                                {(option) => option.label}
                              </InputSelect>
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              ) : (
                <table className="mt-10 gap-2">
                  <tbody>
                    <tr>
                      <td className="text-sm font-semibold text-black">
                        {t('pages.manage_add_users.email_address')}
                      </td>
                      <td className="text-sm font-semibold text-black">
                        {t('pages.manage_add_users.firstname')}
                      </td>
                      <td className="text-sm font-semibold text-black">
                        {t('pages.manage_add_users.lastname')}
                      </td>
                      <td className="text-sm font-semibold text-black">
                        {t('pages.manage_add_users.role')}
                      </td>
                      <td className="text-sm font-semibold text-black">
                        {t('pages.manage_add_users.phone_number')}
                      </td>
                      <td className="text-sm font-semibold text-black">
                        {t('pages.manage_add_users.language')}
                      </td>
                    </tr>
                    {props?.newUser.map(
                      (el: any, i: number) =>
                        el?.newUser && (
                          <tr key={el.key + '_2'}>
                            <td>
                              <div>
                                {el.newUser && (
                                  <InputText
                                    disabled
                                    id="email"
                                    value={el.label}
                                    onChange={() => {}}
                                    className="pb-5 pr-2"
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {el.newUser && (
                                  <InputText
                                    id="firstname"
                                    onChange={(event: any) => {
                                      const newUsers = deep_copy(props.newUser);
                                      newUsers[i].firstName =
                                        event.target.value;
                                      props.setNewUser(newUsers);
                                    }}
                                    value={el.firstName}
                                    className="pb-5 pr-2"
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {el.newUser && (
                                  <InputText
                                    id="lastname"
                                    onChange={(event: any) => {
                                      const newUsers = deep_copy(props.newUser);
                                      newUsers[i].lastName = event.target.value;
                                      props.setNewUser(newUsers);
                                    }}
                                    value={el.lastName}
                                    className="pb-5 pr-2"
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="mb-[22px] mr-2 w-[160px]">
                                {el.newUser && (
                                  <InputSelect
                                    onChange={(role: number) => {
                                      const newUsers = deep_copy(props.newUser);
                                      newUsers[i].role = role;
                                      props.setNewUser(newUsers);
                                    }}
                                    value={el.role}
                                    options={roles}
                                  >
                                    {(option) => (
                                      <span key={option}>
                                        {t(parseRole(option))}
                                      </span>
                                    )}
                                  </InputSelect>
                                )}
                              </div>
                            </td>

                            <td>
                              <div className="mr-2">
                                {el.newUser && (
                                  <InputText
                                    id="phone"
                                    onChange={(event: any) => {
                                      const newUsers = deep_copy(props.newUser);
                                      newUsers[i].phone = event.target.value;
                                      props.setNewUser(newUsers);
                                    }}
                                    value={el.phone}
                                    className="pb-5"
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="mb-[22px] ">
                                {el.newUser && (
                                  <InputSelect
                                    onChange={(lang) => {
                                      const newUsers = deep_copy(props.newUser);
                                      newUsers[i].lang = lang.value;
                                      props.setNewUser(newUsers);
                                    }}
                                    value={
                                      el.lang
                                        ? langs.find(
                                            (lang) => lang?.value === el.lang
                                          )
                                        : langs.find(
                                            (lang) => lang?.value === activeLang
                                          )
                                    }
                                    options={langs}
                                  >
                                    {(option) => option.label}
                                  </InputSelect>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>

      <div className="mt-auto flex border-t border-grey-02 py-5">
        <div className="mx-auto flex w-full max-w-screen-md justify-end px-3">
          {step > 1 && (
            <Button
              variant="secondary"
              onClick={() => setStep(step - 1)}
              className="mr-auto"
            >
              {t('button.previous')}
            </Button>
          )}
          {step < MAXSTEP && (
            <Button
              disabled={props.newUser.length === 0}
              onClick={() => setStep(step + 1)}
            >
              {t('pages.manage_add_users.next_button')}
            </Button>
          )}
          {step === MAXSTEP && (
            <Button
              onClick={() => {
                setStep(step + 1);
                props.handleAddUserToOrganization();
              }}
            >
              {t('pages.manage_add_users.add_users_button')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddUsersStepsContainer;

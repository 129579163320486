import { EventEmitter } from 'events';
import { Buffer } from 'buffer';

window.Buffer = Buffer;

export default class SerialPort extends EventEmitter {
  constructor(port, options) {
    super(options);
    this.options = options || {};

    this.browser = true;
    this.path = this.options.path;
    this.isOpen = false;
    this.port = port;
    this.writer = null;
    this.reader = null;
    this.baudRate = this.options.baudRate;
    this.requestOptions = this.options.requestOptions || {};
    this.onError = this.options.onError || (() => {});

    if (this.options.autoOpen) this.open();
  }

  list(callback) {
    return navigator.serial
      .getPorts()
      .then((list) => {
        if (callback) {
          return callback(null, list);
        }
      })
      .catch((error) => {
        if (callback) {
          return callback(error);
        }
      });
  }

  open(callback) {
    const _this = this;
    return new Promise((resolve, reject) => {
      Promise.allSettled([
        this.port.writable.getWriter(),
        this.port.readable.getReader(),
      ]).then(([writer, reader]) => {
        if (writer.status === 'rejected') return reject(writer.reason);
        if (writer.status === 'rejected') return reject(reader.reason);

        _this.writer = writer.value;
        _this.reader = reader.value;

        _this.emit('open');
        _this.isOpen = true;

        callback && callback();
        resolve();

        function loop() {
          _this.reader
            .read()
            .then(({ value, done }) => {
              if (done) return;
              _this.emit('data', Buffer.from(value));
              return loop();
            })
            .catch((e) => {
              console.error(e);
              return _this.onError(e);
            });
        }
        loop();
      });
    });
  }

  async close(callback) {
    try {
      await this.reader.releaseLock();
      await this.writer.releaseLock();
      await this.port.close();
      this.isOpen = false;
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    callback && callback(null);
  }

  async set(props = {}, callback) {
    try {
      const signals = {};
      if (Object.prototype.hasOwnProperty.call(props, 'dtr')) {
        signals.dataTerminalReady = props.dtr;
      }
      if (Object.prototype.hasOwnProperty.call(props, 'rts')) {
        signals.requestToSend = props.rts;
      }
      if (Object.prototype.hasOwnProperty.call(props, 'brk')) {
        signals.break = props.brk;
      }
      if (Object.keys(signals).length > 0) {
        await this.port.setSignals(signals);
      }
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    if (callback) return callback(null);
  }

  write(buffer, callback) {
    this.writer.write(buffer);
    if (callback) return callback(null);
  }

  async read(callback) {
    let buffer;
    try {
      buffer = await this.reader.read();
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    if (callback) callback(null, buffer);
  }
}

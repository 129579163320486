import { Button, Modal } from '@teo/components';
import { useTranslation } from 'react-i18next';

const DeleteModalLesson = ({
  les,
  closeModal,
  setIsOpenDeleteModal,
  handleDelete,
  title,
  heading,
  text,
}: any) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={true} className="max-w-lg">
      <div
        className=""
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <Modal.Header onClose={closeModal}>
          <h2 className="text-sm font-semibold">
            {title} - {les?.assetId ? les?.assetName : les?.name}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col gap-5">
            <div>
              <h3 className="mb-3 text-lg">{heading}</h3>
              <p className="text-base text-grey-06">{text}</p>
            </div>
            <div className="flex justify-between">
              <Button
                variant="ghost"
                onClick={() => setIsOpenDeleteModal(false)}
              >
                {t('button.cancel')}
              </Button>
              <Button variant="destruct" onClick={() => handleDelete()}>
                {t('button.delete')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default DeleteModalLesson;

import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { buildTreeFlat, newUid, deep_copy } from '@/util';
import { useGet } from '@/query/fetchHooks';
import styles from './App.module.css';
import { DndProvider } from 'react-dnd';
import { Tree, MultiBackend, getBackendOptions } from 'react-dnd-treeview';
import TreeItem from './TreeItem';

const NewTreeList = ({ treeRef, tree, setTree, setOutsideRefresh }: any) => {
  const { t } = useTranslation();
  const [isDraggingOutside, setIsDraggingOutside] = useState(false);
  const [previousTree, setPreviousTree] = useState(tree);
  const leaveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleDragEnter = () => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = null;
    }
    setIsDraggingOutside(false);
  };

  const handleDragLeave = () => {
    leaveTimeoutRef.current = setTimeout(() => {
      setIsDraggingOutside(true);
    }, 50);
  };

  const handleDragOver = () => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = null;
    }
    setIsDraggingOutside(false);
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      <Tree
        ref={treeRef}
        initialOpen={true}
        tree={tree}
        rootId={0}
        render={(node: any, { depth, isOpen, onToggle }) => (
          <TreeItem
            node={node}
            depth={depth}
            isOpen={isOpen}
            onToggle={onToggle}
          />
        )}
        dragPreviewRender={(monitorProps) => {
          const width = (
            monitorProps.item.ref.current?.firstChild as HTMLElement
          )?.offsetWidth;
          return (
            <div
              className="preview flex -translate-y-[50%] items-center rounded-lg border border-secondary-04 bg-white px-4 py-3"
              style={{
                width: `${width}px`,
                boxShadow: '0 12px 24px -6px rgba(0, 0, 0, 0.25)',
              }}
            >
              <div className="flex w-full  items-center ">
                <img
                  src={`/images/icons/drug_dark.svg`}
                  className="mr-3 h-6 w-6"
                  alt="icon"
                />
                <p>{monitorProps?.item?.text}</p>
              </div>
            </div>
          );
        }}
        onDrop={(
          treeData,
          { dropTargetId, dragSource, dropTarget, destinationIndex }
        ) => {
          const node = treeData[destinationIndex || 0] as any;

          const siblings = treeData.filter(
            (x) => x.parent === treeData[destinationIndex || 0].parent
          );

          //find decendants
          const parentList = [node.id];
          const decendants = treeData.filter((x) => {
            if (parentList.includes(x.parent)) {
              parentList.push(x.id);
              return true;
            }
            return false;
          });

          const srcPath = `${(dragSource?.data as any)?.path || '/'}`;
          const dstPath =
            `${(dropTarget?.data as any)?.path || ''}` +
            node.data.path.slice(node.data.path.lastIndexOf('/'));

          //re-path descendants
          for (const i in decendants) {
            const decendant = decendants[i] as any;
            const path = decendant.data.path.replace(srcPath, dstPath);
            if (path === decendant.data.path) continue;
            decendant.data.path = path;
          }

          //re-index siblings and I
          for (const i in siblings) {
            const sibling = siblings[i] as any;
            const yindex = parseFloat(i) + 1;
            const path = sibling.id === node.id ? dstPath : sibling.data.path;
            if (path === sibling.data.path && yindex === sibling.data.yindex)
              continue;
            sibling.data.yindex = yindex;
            sibling.data.path = path;
          }
          setTree(treeData);
          setTimeout(() => {
            (treeRef.current as any).open(dropTargetId);
          });
        }}
        classes={{
          root: `${styles.treeRoot} py-3`,
          draggingSource: tree.some(
            (obj: any) => obj.data && obj.data.yindex === 0
          )
            ? `${styles.draggingSource0}`
            : `${styles.draggingSource}`,
          placeholder: `${styles.placeholderContainer}`,
        }}
        onDragStart={() => {
          setPreviousTree(tree);
        }}
        onDragEnd={() => {
          if (isDraggingOutside) {
            setTimeout(() => {
              setTree(previousTree);
              setOutsideRefresh(newUid(20));
            }, 50);
          }
        }}
        sort={false}
        insertDroppableFirst={false}
        canDrag={(node: any) => {
          if (node?.data?.newNode) {
            return true;
          } else {
            return false;
          }
        }}
        canDrop={(tree, { dragSource, dropTargetId }) => {
          if (dragSource?.parent === dropTargetId) {
            return true;
          }
        }}
        dropTargetOffset={5}
        placeholderRender={(node, { depth }) => (
          <div
            className={`mb-2 flex items-center rounded-lg border border-grey-02 bg-grey-01 px-4 py-3 text-grey-01`}
            style={{ marginLeft: `${depth * 24}px` }}
          >
            {node?.text}
          </div>
        )}
      />
    </div>
  );
};

const LibraryCourseTree = ({
  course,
  node,
  setCheckAdd,
  checkAdd,
  setTree,
  tree,
  setOldTree,
}: any) => {
  const { t } = useTranslation();
  const [outsideRefresh, setOutsideRefresh] = useState<any>(newUid(20));
  const treeRef = useRef(null);
  const [lessons, setLessons] = useState<any>(null);
  const [directories, setDirectories] = useState<any>(null);

  useGet('/usedlessonassets', {
    count: 10000,
    courseId: course?.id,
    coursecontentId: course?.coursecontentId,
    onSuccess: (result: any) => {
      setLessons(result?.data || null);
    },
  });
  useGet('/directories', {
    count: 10000,
    coursecontentId: course?.coursecontentId,
    onSuccess: (result: any) => {
      setDirectories(result?.data || null);
    },
  });

  useEffect(() => {
    if (lessons && directories) {
      const adddirectories = directories;
      const addlesson: any = lessons;
      if (node?.lessonId) {
        addlesson?.push({
          newNode: true,
          id: node?.id,
          path: `/${node?.id}`,
          name: node?.name,
          lessonId: node?.lessonId && node?.lessonId,
          yindex: -1,
          numbered: true,
          createdAt: node?.createdAt,
          updatedAt: node?.updatedAt,
          coursecontentId: course.coursecontentId,
          order: 0,
          children: [],
          usesId: node?.id,
        });
      } else {
        adddirectories?.push({
          newNode: true,
          id: node?.id,
          path: `/${newUid(20)}`,
          name: node?.name,
          lessonId: node?.lessonId && node?.lessonId,
          yindex: -1,
          numbered: true,
          createdAt: node?.createdAt,
          updatedAt: node?.updatedAt,
          coursecontentId: course.coursecontentId,
          order: 0,
          children: [],
        });
      }

      const courseLessons = lessons.filter(
        (les: any) => !les?.rootLessonId && !les?.rootDirectoryId
      );

      const [newTree] = buildTreeFlat(courseLessons, adddirectories) as any;
      setOldTree(deep_copy(newTree));
      setTree(newTree);
    }
  }, [lessons, directories]);

  useEffect(() => {
    if (tree) {
      const hasYindexZero = tree.some(
        (obj: any) => obj.data && obj.data.yindex === -1 && obj.data.newNode
      );
      setCheckAdd(hasYindexZero);
    }
  }, [tree]);

  if (!tree) return null;

  return (
    <div className="mt-8 rounded-lg border border-grey-02 px-6 py-4">
      <p className="text-sm font-semibold text-black">
        {t(`pages.create_library.step_add.drag_content`)}
      </p>
      {!checkAdd && (
        <div>
          <div
            className={`mt-3 flex items-center rounded-lg border border-grey-02 bg-grey-02 px-4 py-3 text-grey-02`}
          >
            <p>{node?.name}</p>
          </div>
          <p className={`my-2 text-sm font-semibold text-black`}>
            {t(`pages.create_library.step_add.course_content`)}
          </p>
        </div>
      )}
      <DndProvider
        key={outsideRefresh}
        backend={MultiBackend}
        options={getBackendOptions()}
      >
        <NewTreeList
          treeRef={treeRef}
          tree={tree}
          setTree={setTree}
          setOutsideRefresh={setOutsideRefresh}
        />
      </DndProvider>
    </div>
  );
};

export default LibraryCourseTree;

import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText, IconButton, Button } from '@teo/components';
import { axios } from '@/axios';
import { config } from '@/config';
import {
  Document,
  Chapter,
  ChevronLeft,
  ChevronRight,
  Image,
  Play,
  Tag,
} from '@teo/components/icons';
import ExtraInfo from '@/components/ExtraInfo/ExtraInfo';
import { findElementsByKey } from '@/util';
import { useGet } from '@/query/fetchHooks';
import { PaginationMultiselectWithTags } from '@/components/PaginationMultiselectWithTags';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import Hint from './Hints/Hints';

export const TreeItem = ({ node }: any) => {
  return (
    <div className="pb-3">
      <div className="flex items-center rounded-lg bg-grey-01 px-4 py-2 text-sm text-grey-08">
        {!node?.lessonId || false ? (
          node?.assetId ? (
            node?.assetType === 'image' ? (
              <Image className="m-1 w-5 min-w-5 " />
            ) : (
              <Play className="m-1 w-5 min-w-5 " />
            )
          ) : (
            <Chapter className="m-1 w-5 min-w-5" />
          )
        ) : (
          <Document className={`m-1 w-5 min-w-5`} />
        )}
        <p className="pl-2">{node?.name || node?.assetName}</p>
      </div>
      {node?.children?.length > 0 && (
        <div className="pl-4 pt-3">
          {node?.children?.map((el: any, i: any) => (
            <TreeItem key={i} node={el} />
          ))}
        </div>
      )}
    </div>
  );
};

const ChapterPage = ({
  node,
  publicationName,
  publication,
  prevTree,
  setDirectories,
  setTree,
  setAddLesson,
  setLessons,
  lessons,
  setActiveNode,
  setParentNode,
  setMobileSidebar,
  setExtraInfoEdit,
  extraInfoEdit,
  updateLesInfo,
  isFroalaLoaded,
  hints,
  newPage,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const [name, setName] = useState<string>(node?.data?.name || '');
  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(false);
  const [numLessons, setNumLessons] = useState(0);
  const [extraInfoLessons, setExtraInfoLessons] = useState<any>([]);
  const [parentDirectories, setParentDirectories] = useState<any>([]);
  const [nodeIndex, setNodeIndex] = useState(0);

  const [filter, setFilter] = useState('');
  const [selectTags, setSelectTags] = useState<any>([]);
  const [chapterTags, setChapterTags] = useState<any>([]);
  const [activeTags, setActiveTags] = useState<any>([]);

  useEffect(() => {
    const newNode = prevTree?.find((el: any) => el?.id === node?.id);
    if (newNode?.data?.name !== name) {
      setName(newNode?.data?.name);
    }
    prevTree?.map(
      (el: any, index: any) => el?.id === node?.id && setNodeIndex(index)
    );
  }, [prevTree, node]);

  const findParents = (array: any, targetId: any) => {
    const parents: any = [];

    const findParent = (parent: any) => {
      const parentObj = array.find((obj: any) => obj.id === parent);
      if (parentObj) {
        parents.push(parentObj);
        if (parentObj.parent !== 0) {
          findParent(parentObj.parent);
        }
      }
    };

    const targetObj = array.find((obj: any) => obj.id === targetId);
    if (targetObj) {
      findParent(targetObj.parent);
    }

    parents.sort(
      (a: any, b: any) => a?.data?.path?.length - b?.data?.path?.length
    );

    return parents;
  };

  useEffect(() => {
    const parentFolder = findParents(prevTree, node?.id);
    setParentDirectories(parentFolder);
    setSelectTags([]);
    setActiveTags([]);

    if (node?.droppable && node?.id) {
      axios
        .get(
          `${config.backend}/directorytags/?directoryId=${node?.id}&search=tag:%`
        )
        .then((response) => {
          setChapterTags(response.data || []);
        });
    }
  }, [node, updateLesInfo]);

  useEffect(() => {
    if (chapterTags?.length > 0) {
      chapterTags?.map((tag: any) => {
        const val = selectTags?.find((el: any) => el.id === tag?.id);
        if (!val) {
          const lesTag = {
            id: tag?.id,
            key: tag?.tag.split('tag:')[1],
            label: tag?.tag.split('tag:')[1],
            tag: tag?.tag.split('tag:')[1],
          };
          setActiveTags((prev: any) => [...prev, lesTag]);
          setSelectTags((prev: any) => [...prev, lesTag]);
        }
      });
    }
  }, [chapterTags]);

  useEffect(() => {
    handleTags();
  }, [selectTags]);

  const handleTags = () => {
    if (selectTags?.length > 0) {
      for (const addObj2 of selectTags) {
        const existingObj = activeTags?.find(
          (obj1: any) => obj1?.id === addObj2?.id
        );
        if (!existingObj) {
          axios.post(`${config.backend}/directorytags`, {
            directoryId: node?.id,
            tag: 'tag:' + addObj2?.label,
            id: addObj2?.id,
          });
          setActiveTags((prev: any) => [
            ...prev,
            {
              id: addObj2?.id,
              key: addObj2?.label,
              label: addObj2?.label,
              tag: addObj2?.label,
            },
          ]);
        }
      }
    }

    if (activeTags?.length > 0) {
      for (const removedTag of activeTags) {
        const existingObj = selectTags?.find(
          (obj1: any) => obj1?.id === removedTag?.id
        );
        if (!existingObj) {
          axios.delete(
            `${config.backend}/directorytag/${node?.id}/${removedTag?.id}`
          );
          setActiveTags((prev: any) =>
            prev?.filter((el: any) => el?.id !== removedTag?.id)
          );
        }
      }
    }
  };

  const handleAddTag = () => {
    const promises = [];
    promises.push(
      axios.post(`${config.backend}/tags`, {
        tag: filter,
        label: filter,
        key: Math.floor(Math.random() * 9999999999999),
        organisationId: auth?.user?.activeOrganisationId,
        lang: auth?.user?.lang,
      })
    );
    Promise.allSettled(promises).then((results: any) => {
      setSelectTags((prev: any) => [
        ...prev,
        {
          key: filter,
          label: filter,
          tag: filter,
          id: results[0].value?.data?.id,
        },
      ]);
      setFilter('');
    });
  };

  useEffect(() => {
    const lessons = findElementsByKey(node?.data?.children, 'lessonId');
    setNumLessons(lessons?.length);
  }, [prevTree]);

  useEffect(() => {
    if (lessons) {
      setExtraInfoLessons(
        lessons?.filter((les: any) => les?.rootDirectoryId === node?.id)
      );
    }
  }, [lessons, node]);

  const saveChapter = async () => {
    const newNode = prevTree?.find((el: any) => el.id === node.id);
    if (name !== newNode?.data?.name) {
      axios.put(`${config.backend}/directory/${node?.id}`, {
        id: node?.id,
        name: name,
      });
      setDirectories((prev: any) =>
        prev?.map((el: any) =>
          el.id === node?.id ? { ...el, name: name } : el
        )
      );
    }
  };

  return (
    <div className="flex w-full justify-center">
      <main className="max-w-[1044px] grow py-10">
        <div className="flex items-start px-3">
          <div className="inline-block text-sm text-grey-06">
            <div
              className="my-2 inline-block cursor-pointer hover:text-grey-08"
              onClick={() => {
                setActiveNode(null);
              }}
            >
              {publicationName}
            </div>
            <span className="px-2">/</span>
            {parentDirectories?.length > 0 &&
              parentDirectories?.map((direct: any) => {
                return (
                  <div key={direct?.id} className="my-2 inline-block">
                    <div
                      className="inline-block cursor-pointer hover:text-grey-08"
                      onClick={() => setActiveNode(direct)}
                    >
                      {direct?.data?.name}
                    </div>
                    <span className="px-2">/</span>
                  </div>
                );
              })}
            <div className="my-2 inline-block text-black">{name}</div>
          </div>
          <div className="ml-auto flex gap-3 pl-6">
            <IconButton
              className=""
              disabled={nodeIndex == 0}
              Icon={ChevronLeft}
              variant="outline"
              size="sm"
              onClick={() => setActiveNode(prevTree[nodeIndex - 1])}
            />
            <IconButton
              className=""
              disabled={prevTree?.length - 1 === nodeIndex}
              Icon={ChevronRight}
              variant="outline"
              size="sm"
              onClick={() => setActiveNode(prevTree[nodeIndex + 1])}
            />
          </div>
        </div>
        <div className="mt-6 mb-6 flex pr-3">
          <div className="flex-auto pr-6">
            <InputText
              value={name}
              onChange={(ev: any) => setName(ev.target.value)}
              onBlur={saveChapter}
              customStyles="bg-white inputWhiteBg !text-2xl !text-black placeholder:text-xl"
              placeholder={t('pages.create_edit_course.course_title_chapter')}
            />
          </div>
          <Button
            variant="secondary"
            onClick={() => setShowExtraInfo(true)}
            className={`relative ${
              hints?.id == 'hint_44' &&
              'active-hint-parent active-hint-main-menu'
            }`}
          >
            <Hint
              key={`hint_44_${newPage}`}
              id={'hint_44'}
              leftDots={'-left-2 top-[11px]'}
              title={t('hint.title_44')}
              order={44}
              style={{
                right: '2px',
                top: '70px',
              }}
            />
            {t('extra_info.title')}
            <span className="pl-1 text-grey-05">
              ({extraInfoLessons?.length})
            </span>
          </Button>
        </div>

        <div className="relative z-[10] flex items-center px-4">
          <Tag className="mr-2 h-[17.5px] w-[17.5px] text-[#6d6d72]" />

          <div className="w-full">
            <PaginationMultiselectWithTags
              value={selectTags}
              onChange={(value: any) => {
                setSelectTags(value);
                handleTags();
              }}
              filter={filter}
              setFilter={setFilter}
              keyFn={(row: any) => row?.tag}
              fetch={useGet.bind(
                null,
                `/tags?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
              )}
              defaultSort={[{ id: 'tag', desc: false }]}
              render={(row: any) => {
                return row?.tag;
              }}
              customStyles="bg-white"
              addTagLabel={`${filter} ${t(
                'pages.create_edit_course.addTagLabel'
              )}`}
              handleAddTag={handleAddTag}
              placeholder={
                selectTags?.length > 0
                  ? ''
                  : t('pages.create_edit_course.tags_placeholder')
              }
            />
          </div>
        </div>

        <div className="px-3">
          <p className="mt-6 mb-3 text-sm font-semibold">
            {t('extra_info.contents')}
            <span className="pl-3 text-xs font-normal text-grey-07">
              {numLessons} lesons
            </span>
          </p>
          <div>
            {node?.data?.children?.length > 0 &&
              node?.data?.children?.map((el: any, i: any) => (
                <TreeItem key={i} node={el} />
              ))}
          </div>
        </div>
      </main>
      {showExtraInfo && (
        <ExtraInfo
          setShowExtraInfo={setShowExtraInfo}
          activeNode={node}
          node={node}
          publication={publication}
          prevTree={prevTree}
          setTree={setTree}
          setAddLesson={setAddLesson}
          setLessons={setLessons}
          nameItem={name}
          extraInfoLessons={extraInfoLessons}
          setActiveNode={setActiveNode}
          setParentNode={setParentNode}
          setMobileSidebar={setMobileSidebar}
          setExtraInfoEdit={setExtraInfoEdit}
          extraInfoEdit={extraInfoEdit}
          isFroalaLoaded={isFroalaLoaded}
        />
      )}
    </div>
  );
};

export default ChapterPage;

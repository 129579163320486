import {
  Label,
  InputText,
  RadioGroup,
  InputScore,
  Multiselect,
  Switch,
} from '@teo/components';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useGet } from '@/query/fetchHooks';
import { parseFilter } from '@/util';

import { Tag } from '@teo/components/icons';

import { PaginationMultiselectWithTags } from '@/components/PaginationMultiselectWithTags';
import i18n from 'i18next';
import { axios } from '@/axios';
import { config } from '@/config';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';

export const SettingsLesson = ({
  les,
  setLesTitle,
  lesTitle,
  setOnlyTheory,
  onlyTheory,
  setLessonVerified,
  lessonVerified,
  setIsCheckedType,
  isCheckedType,
  setIsCheckedTimer,
  isCheckedTimer,
  setEstimatedTime,
  estimatedTime,
  setSelectTags,
  selectTags,
  setSelectCategories,
  selectCategories,
  setLesTags,
  lesCategories,
  setLesCategories,
  lesTags,
  submitByTeacher,
  setSubmitByTeacher,
  setSettetingAddNumber,
  settingAddNumber,
}: any) => {
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();
  const [filterTag, setFilterTag] = useState('');
  const [tags, setTags] = useState<any>([]);

  const [filter, setFilter] = useState<any>('');
  const [categoryFilter, setCategoryFilter] = useState<any>('');

  const allTags: any = useGet(`/tags`, {
    enabled: !!les?.lessonId,
    count: 10,
    sort: 'tag',
    lang: auth?.user?.lang,
    q: parseFilter(filterTag),
  });

  useGet(`/lessontags`, {
    enabled: !!les?.lessonId,
    lessonId: les?.lessonId,
    search: 'tag:%',
    onSuccess: (result: any) => {
      setLesTags(result.data || {});
    },
  });

  useGet(`/lessontags`, {
    enabled: !!les?.lessonId,
    lessonId: les?.lessonId,
    search: 'category:%',
    onSuccess: (result: any) => {
      setLesCategories(result.data || {});
    },
  });

  useGet(`/directorytags`, {
    enabled: !les?.assetId && !les?.lessonId,
    directoryId: les?.id,
    search: 'tag:%',
    onSuccess: (result: any) => {
      setLesTags(result.data || {});
    },
  });

  useGet(`/directorytags`, {
    enabled: !les?.assetId && !les?.lessonId,
    directoryId: les?.id,
    search: 'category:%',
    onSuccess: (result: any) => {
      setLesCategories(result.data || {});
    },
  });

  useEffect(() => {
    if (allTags?.data) {
      const data = (allTags?.data?.data as any[])?.map((tag) => ({
        id: tag?.id,
        key: tag?.tag,
        label: tag?.tag,
        tag: tag?.tag,
      }));
      setTags(data);
    }
  }, [allTags?.data]);

  useEffect(() => {
    if (lesTags) {
      (lesTags as any[])?.map((tag) => {
        const val = selectTags?.find((el: any) => el.id === tag?.id);
        if (!val) {
          setSelectTags((prev: any) => [
            ...prev,
            {
              id: tag?.id,
              key: tag?.tag.split('tag:')[1],
              label: tag?.tag.split('tag:')[1],
              tag: tag?.tag.split('tag:')[1],
            },
          ]);
        }
      });
    }
  }, [lesTags]);

  useEffect(() => {
    if (lesCategories) {
      (lesCategories as any[])?.map((tag) => {
        const val = selectCategories?.find((el: any) => el.id === tag?.id);
        if (!val) {
          setSelectCategories((prev: any) => [
            ...prev,
            {
              id: tag?.id,
              key: tag?.tag.split('category:')[1],
              label: tag?.tag.split('category:')[1],
              tag: tag?.tag.split('category:')[1],
            },
          ]);
        }
      });
    }
  }, [lesCategories]);

  const handleAddTag = () => {
    axios.post(`${config.backend}/tags`, {
      tag: filter,
      label: filter,
      key: Math.floor(Math.random() * 9999999999999),
      organisationId: auth?.user?.activeOrganisationId,
      lang: auth?.user?.lang,
    });
    setSelectTags((prev: any) => [
      ...prev,
      {
        key: filter,
        label: filter,
        tag: filter,
      },
    ]);
    setFilter('');
  };

  const handleAddCategory = () => {
    axios.post(`${config.backend}/categories`, {
      tag: categoryFilter,
      label: categoryFilter,
      key: Math.floor(Math.random() * 9999999999999),
      organisationId: auth?.user?.activeOrganisationId,
      lang: auth?.user?.lang,
    });
    setSelectCategories((prev: any) => [
      ...prev,
      {
        key: categoryFilter,
        label: categoryFilter,
        tag: categoryFilter,
      },
    ]);
    setCategoryFilter('');
  };

  return (
    <div className="">
      <h1 className="text-base font-semibold">
        {les?.lessonId
          ? t('pages.create_edit_course.lesson_settings_title')
          : les?.assetId
          ? t('pages.create_edit_course.asset_settings_title')
          : t('pages.create_edit_course.folder_settings_title')}
      </h1>
      <p className="mt-2 text-sm font-normal text-[#7d7d81]">
        {les?.lessonId
          ? t('pages.create_edit_course.lesson_settings_subtitle')
          : les?.assetId
          ? t('pages.create_edit_course.asset_settings_subtitle')
          : t('pages.create_edit_course.folder_settings_subtitle')}
      </p>
      <div className="flex h-full w-full md:w-full md:items-center">
        <div className="mb-1 w-full space-y-4 md:space-y-6">
          <div>
            <Label htmlFor="lesTitle">
              <p className="mt-6 text-sm font-semibold">
                {les?.lessonId
                  ? t('pages.create_edit_course.title_lesson')
                  : les?.assetId
                  ? t('pages.create_edit_course.title_asset')
                  : t('pages.create_edit_course.title_folder')}
              </p>
            </Label>
            <InputText
              disabled={!les.canEdit && (les?.lessonId || les?.assetId)}
              title={
                !les.canEdit && (les?.lessonId || les?.assetId)
                  ? t('pages.create_edit_course.title_lesson_disabled')
                  : undefined
              }
              id="lesTitle"
              type="text"
              onChange={(e) => setLesTitle(e.target.value)}
              value={lesTitle}
            />
          </div>

          {les?.lessonId && ( //lesson
            <>
              <div className="relative z-[12]">
                <Label htmlFor="categories">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.create_edit_course.tags')}
                  </p>
                </Label>
                <div className="relative z-[12] flex items-center">
                  <Tag className="mr-2 h-[17.5px] w-[17.5px] text-[#6d6d72]" />

                  <div className="w-full">
                    <PaginationMultiselectWithTags
                      value={selectTags}
                      onChange={(value: any) => {
                        setSelectTags(value);
                      }}
                      filter={filter}
                      setFilter={setFilter}
                      keyFn={(row: any) => row?.tag}
                      fetch={useGet.bind(
                        null,
                        `/tags?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                      )}
                      defaultSort={[{ id: 'tag', desc: false }]}
                      render={(row: any) => {
                        return row?.tag;
                      }}
                      customStyles="bg-white"
                      addTagLabel={`${filter} ${t(
                        'pages.create_edit_course.addTagLabel'
                      )}`}
                      handleAddTag={handleAddTag}
                      placeholder={
                        selectTags?.length > 0
                          ? ''
                          : t('pages.create_edit_course.tags_placeholder')
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="relative z-[11]">
                <Label htmlFor="categories">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.create_edit_course.categories')}
                  </p>
                </Label>

                <PaginationMultiSelect
                  value={selectCategories}
                  onChange={(value: any) => {
                    setSelectCategories(value);
                  }}
                  keyFn={(row: any) => row?.tag}
                  fetch={useGet.bind(
                    null,
                    `/categories?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                  )}
                  defaultSort={[{ id: 'tag', desc: false }]}
                  render={(row: any) => {
                    return row?.tag;
                  }}
                />
              </div>

              {/* <div className="relative">
                <Label htmlFor="categories">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.create_edit_course.categories')}
                  </p>
                </Label>
                <div className="relative z-[10] flex items-center">
                  <Tag className="mr-2 h-[17.5px] w-[17.5px] text-[#6d6d72]" />

                  <div className="w-full">
                    <PaginationMultiselectWithTags
                      value={selectCategories}
                      onChange={(value: any) => {
                        setSelectCategories(value);
                      }}
                      filter={categoryFilter}
                      setFilter={setCategoryFilter}
                      keyFn={(row: any) => row?.tag}
                      fetch={useGet.bind(
                        null,
                        `/categories?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                      )}
                      defaultSort={[{ id: 'tag', desc: false }]}
                      render={(row: any) => {
                        return row?.tag;
                      }}
                      customStyles="bg-white"
                      addTagLabel={`${categoryFilter} ${t(
                        'pages.create_edit_course.addTagLabel'
                      )}`}
                      handleAddTag={handleAddCategory}
                      placeholder={
                        selectCategories?.length > 0
                          ? ''
                          : t('pages.create_edit_course.tags_placeholder')
                      }
                    />
                  </div>
                </div>
              </div> */}
              <div className="mt-4 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div>
                  <Switch
                    checked={settingAddNumber}
                    onChange={(isCheck) => setSettetingAddNumber(isCheck)}
                  />
                </div>
                <div>
                  <h4 className=" font-normal">
                    {t('pages.create_edit_course.numbering_lesson')}
                  </h4>
                  {/* <p className="text-xs text-grey-05">
                    {t('pages.create_edit_course.verified_content_description')}
                  </p> */}
                </div>
              </div>
              <div className="mt-4 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div>
                  <Switch
                    checked={lessonVerified}
                    onChange={(isCheck) => setLessonVerified(isCheck)}
                  />
                </div>
                <div>
                  <h4 className="pb-2 font-normal">
                    {t('pages.create_edit_course.verified_content')}
                  </h4>
                  <p className="text-xs text-grey-05">
                    {t('pages.create_edit_course.verified_content_description')}
                  </p>
                </div>
              </div>

              <div className="mt-4 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div>
                  <Switch
                    checked={!onlyTheory}
                    onChange={(isCheck) => setOnlyTheory(!isCheck)}
                  />
                </div>
                <div>
                  <h4 className="pb-2 font-normal">
                    {t('pages.create_edit_course.progress')}
                  </h4>
                  <p className="text-xs text-grey-05">
                    {t('pages.create_edit_course.progress_description')}
                  </p>
                </div>
              </div>

              <RadioGroup
                label={t('pages.create_edit_course.type_lesson')}
                onChange={(value: string) => {
                  setIsCheckedType(value);
                }}
                value={isCheckedType}
                gap={'gap-1'}
              >
                <div className="flex flex-col gap-5 sm:flex-row">
                  <div
                    className={`basis-1/3 rounded-lg border border-grey-02 p-3 ${
                      isCheckedType == 'theory' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="theory">
                      <span className="text-sm">
                        {t('pages.create_edit_course.type_lesson_theory')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                  <div
                    className={`basis-1/3 rounded-lg border border-grey-02 p-3 ${
                      isCheckedType == 'practice' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="practice">
                      <span className="text-sm" data-testid="radio_btn">
                        {t('pages.create_edit_course.type_lesson_practice')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                  <div
                    className={`basis-1/3 rounded-lg border border-grey-02 p-3 ${
                      isCheckedType == 'box' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="box">
                      <span className="text-sm" data-testid="radio_btn">
                        {t('pages.create_edit_course.type_lesson_box')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                </div>
              </RadioGroup>

              <div className="mt-4 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                <div>
                  <Switch
                    checked={submitByTeacher}
                    onChange={(isCheck) => setSubmitByTeacher(isCheck)}
                  />
                </div>
                <div>
                  <h4 className="font-normal">
                    {t('pages.create_edit_course.submit_by_teacher')}
                  </h4>
                  <p className="pt-2 text-xs text-grey-05">
                    {t(
                      'pages.create_edit_course.submit_by_teacher_description'
                    )}
                  </p>
                </div>
              </div>

              <RadioGroup
                label={t('pages.create_edit_course.timer')}
                onChange={(value: string) => {
                  setIsCheckedTimer(value);
                }}
                value={isCheckedTimer}
                gap={'gap-1'}
              >
                <div className="flex flex-col gap-5 sm:flex-row">
                  <div
                    className={`basis-1/3 rounded-lg border border-grey-02 p-3 ${
                      isCheckedTimer == 'default' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="default">
                      <span className="text-sm">
                        {t('pages.create_edit_course.timer_no')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                  <div
                    className={`basis-1/3 rounded-lg border border-grey-02 p-3 ${
                      isCheckedTimer == 'timed' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="timed">
                      <span className="text-sm" data-testid="radio_btn">
                        {t('pages.create_edit_course.timer_counting')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                  <div
                    className={`basis-1/3 rounded-lg border border-grey-02 p-3 ${
                      isCheckedTimer == 'limited' && 'border-secondary-04'
                    }`}
                  >
                    <RadioGroup.Option value="limited">
                      <span className="text-sm" data-testid="radio_btn">
                        {t('pages.create_edit_course.timer_countdown')}
                      </span>
                    </RadioGroup.Option>
                  </div>
                </div>
              </RadioGroup>

              <div
                className="mt-5 flex flex-col"
                data-testid="reminderMemories"
              >
                <Label htmlFor="reminder_num_memories">
                  {t('pages.create_edit_course.estimated_time')}
                </Label>
                <InputScore
                  onChange={(value) => setEstimatedTime(value)}
                  step={1}
                  value={estimatedTime}
                />
              </div>
            </>
          )}

          {!les?.assetId &&
            !les?.lessonId && ( //directory
              <div className="relative z-[11]">
                <Label htmlFor="categories">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.create_edit_course.tags')}
                  </p>
                </Label>
                <div className="relative z-[11] flex items-center">
                  <Tag className="mr-2 h-[17.5px] w-[17.5px] text-[#6d6d72]" />

                  <div className="w-full">
                    <PaginationMultiselectWithTags
                      value={selectTags}
                      onChange={(value: any) => {
                        setSelectTags(value);
                      }}
                      filter={filter}
                      setFilter={setFilter}
                      keyFn={(row: any) => row?.tag}
                      fetch={useGet.bind(
                        null,
                        `/tags?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                      )}
                      defaultSort={[{ id: 'tag', desc: false }]}
                      render={(row: any) => {
                        return row?.tag;
                      }}
                      customStyles="bg-white"
                      positionDropdown="top"
                      addTagLabel={`${filter} ${t(
                        'pages.create_edit_course.addTagLabel'
                      )}`}
                      handleAddTag={handleAddTag}
                      placeholder={
                        selectTags?.length > 0
                          ? ''
                          : t('pages.create_edit_course.tags_placeholder')
                      }
                    />
                  </div>
                </div>
                <div className="relative z-[11]">
                  <Label htmlFor="categories">
                    <p className="mt-6 text-sm font-semibold">
                      {t('pages.create_edit_course.categories')}
                    </p>
                  </Label>

                  <PaginationMultiSelect
                    value={selectCategories}
                    onChange={(value: any) => {
                      setSelectCategories(value);
                    }}
                    keyFn={(row: any) => row?.tag}
                    fetch={useGet.bind(
                      null,
                      `/categories?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                    )}
                    defaultSort={[{ id: 'tag', desc: false }]}
                    render={(row: any) => {
                      return row?.tag;
                    }}
                  />
                </div>
                <div className="mt-6 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div>
                    <Switch
                      checked={settingAddNumber}
                      onChange={(isCheck) => setSettetingAddNumber(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className=" font-normal">
                      {t('pages.create_edit_course.numbering_lesson')}
                    </h4>
                    {/* <p className="text-xs text-grey-05">
                    {t('pages.create_edit_course.verified_content_description')}
                  </p> */}
                  </div>
                </div>
                <div className="mt-6 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div>
                    <Switch
                      checked={lessonVerified}
                      onChange={(isCheck) => setLessonVerified(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t('pages.create_edit_course.verified_content')}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t(
                        'pages.create_edit_course.verified_content_description'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}

          {les?.assetId && ( //asset
            <div className="mt-4 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
              <div>
                <Switch
                  checked={settingAddNumber}
                  onChange={(isCheck) => setSettetingAddNumber(isCheck)}
                />
              </div>
              <div>
                <h4 className=" font-normal">
                  {t('pages.create_edit_course.numbering_lesson')}
                </h4>
                {/* <p className="text-xs text-grey-05">
                  {t('pages.create_edit_course.verified_content_description')}
                </p> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { Fragment, createRef, useEffect, useState } from 'react';
import { renderWidgets } from '@/components/Froala/widgets/renderWidget';
import { useGet } from '@/query/fetchHooks';
import { clsx } from 'clsx';
import { fancyWrapper } from '@/util';

const FroalaTextareaView = ({
  value,
  correction,
  className,
  setWidgets,
  isCorrected,
  page = 0,
  showAnswers = false,
  correctionType = 'automatic',
  setIsLoaded,
}: {
  value: string;
  correction?: any;
  className?: string;
  setWidgets?: any;
  isCorrected?: boolean;
  page?: number;
  showAnswers?: boolean;
  correctionType?: string;
  setIsLoaded?: any;
}) => {
  const [widgets, setMyWidgets] = useState<any>({});
  const [loaded, setLoaded] = useState<boolean>(false);

  const myRef = createRef<HTMLDivElement>();

  const valueArr = Array.isArray(value) ? value : [value];

  useEffect(() => {
    setTimeout(() => {
      setMyWidgets(Object.assign({}, widgets));
    }, 0);
  }, [page]);

  useEffect(() => {
    const newWidgets = renderWidgets(myRef.current, correction, {
      isCorrected,
      showAnswers,
      correctionType,
    }) as any;
    for (const key in newWidgets) {
      if (newWidgets[key]) widgets[key] = newWidgets[key];
    }
    const temp = { ...widgets };
    setMyWidgets(temp);
    setWidgets && setWidgets(temp);
  }, [value, correction]);

  useEffect(() => {
    setTimeout(() => {
      fancyWrapper(myRef.current);
      setIsLoaded && setIsLoaded(false);
    }, 0);
    //TODO: this tries it again as the first one sometimes doesn't work
    setTimeout(() => {
      fancyWrapper(myRef.current);
      setLoaded(true);
      setIsLoaded && setIsLoaded(false);
    }, 250);
    //TODO: this tries it again as the first one sometimes doesn't work
    setTimeout(() => {
      fancyWrapper(myRef.current);
    }, 1000);
  }, [widgets]);

  if (!value) return null;

  return (
    <>
      {valueArr.map((value, i) => (
        <div
          key={i}
          ref={myRef}
          style={{ display: page !== i ? 'none' : undefined }}
          className={clsx('fr-view', className)}
          dangerouslySetInnerHTML={{
            __html: value
              ?.replace?.(/ contenteditable="true"/g, '')
              ?.replace?.(/remove_me_gEE8QIPZ6LEay22SdSCU/g, 'hidden'),
          }}
        />
      ))}
      {Object.values(widgets).map((x: any) => x.portal)}
      <div className={clsx(loaded && 'page-loaded')}></div>
    </>
  );
};

export { FroalaTextareaView }; //initFroalaView

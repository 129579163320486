import { useState, useEffect, createRef } from 'react';
import {
  isEmpty,
  newUid,
  deep_copy,
  array_difference,
  array_intersect,
  realParseFloat,
  walk,
  swap,
} from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import {
  Switch,
  InputScore,
  InputText,
  InputSelect,
  IconButton,
  Button,
} from '@teo/components';
import { T } from '@/components/T';
import { Svg } from './teo_box/Svg';
import update from 'immutability-helper';
import { parse, unescapeHTML } from 'svg-parser';
import { toHtml } from 'hast-util-to-html';
import { uploadString } from '@/query/documents.js';
import { Pencil, ChevronUp, ChevronDown } from '@teo/components/icons';
import { BoxTriggerEditor } from './teo_box/BoxTriggerEditor.jsx';
import { BoxCorrectionEditor } from './teo_box/BoxCorrectionEditor.jsx';
import { QuestionSection } from '../QuestionSection';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialKofferVraag2 = () => ({
  score: 1,
  vraag: '',
  schema: '',
  io: {},
  triggers: [],
  corrections: [],
  type: 'KofferVraag2',
});

//does some preprocessing of the svg. It somewhat 'fixes' draw.io svg exports
//to be precise it extracts the svg components that are added by means of <image 'xlink:href'='data:image/svg+xml;base64,...' />
//parses the base64 data  and adds it to the svg as a proper <g>....</g> tag with all internal attributes intact.
//strips metadata
function svgprocess(data) {
  let parsed = parse(data);
  //delete the xml metadata that takes up most of the file
  if (parsed.children[0].properties.content)
    delete parsed.children[0].properties.content;
  walk(parsed, (node, i, parent) => {
    if (
      node.properties &&
      node.properties['xlink:href'] &&
      node.properties['xlink:href'].startsWith('data:image/svg+xml;base64,')
    ) {
      let data = node.properties['xlink:href'].split(
        'data:image/svg+xml;base64,'
      )[1];
      delete node.properties['xlink:href'];
      let parsedNode = parse(atob(data));
      //parsedNode.children[0].tagName = 'g';
      Object.assign(
        parsedNode.children[0].properties,
        node.properties,
        parent.properties
      );
      delete parent.properties['data-name'];
      delete parent.properties['data-type'];
      parent.children[i] = parsedNode.children[0];
      if (parent.children[i].properties['data-type'] === 'slider')
        parent.children[i].properties['visibility'] = 'hidden';
      if (parent.children[i].properties['data-type'] === 'rotary')
        parent.children[i].properties['visibility'] = 'hidden';
      if (parent.children[i].properties['data-type'] === 'gauge')
        parent.children[i].properties['visibility'] = 'hidden';
    }

    //transform on image tags is broken in ReactSVG to hoist the transform to the parent g tag
    if (node.tagName === 'image') {
      if (node.properties.transform) {
        parent.properties.transform = node.properties.transform;
      }
    }
  });
  return toHtml(parsed, {
    allowDangerousCharacters: true,
    allowDangerousHtml: true,
  });
}

export function stringToBool(str) {
  switch (str) {
    case String(undefined):
      return undefined;
    case String(true):
      return true;
    case String(false):
      return false;
  }
}

const digitalOutputs = [
  ...[...Array(24).keys()].map((i) => 'D' + i),
  ...[...Array(16).keys()].map((i) => 'R' + i),
];
const analogInputs = [...Array(16).keys()].map((i) => 'A' + i);

export const digitalOptions = [
  {
    value: `${undefined}`,
    label: 'ANY',
  },
  {
    value: `${true}`,
    label: 'HIGH',
  },
  {
    value: `${false}`,
    label: 'LOW',
  },
];

const requiredOptions = [
  {
    value: `${false}`,
    label: 'not_required',
  },
  {
    value: `${true}`,
    label: 'required',
  },
];

const loseAttemptOptions = [
  {
    value: `${true}`,
    label: 'yes',
  },
  {
    value: `${false}`,
    label: 'no',
  },
];

export const BoxQuestionEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();
  const uploadInputRef = createRef();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(true);
  const [nrOfAttempts, setNrOfAttempts] = useState(state.nrOfAttempts);
  const [svgLoaded, setSvgLoaded] = useState(false);
  const [io, setIo] = useState(state.io);
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );
  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.box_question')
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : false
  );
  const [competencies, setCompetencies] = useState([]);
  const [score, setScore] = useState(state.score || 0);
  const [schema, setSchema] = useState(state.schema);
  const [hidden, setHidden] = useState(state.hidden || false);

  const [corrections, setCorrections] = useState(state.corrections || []);
  const [triggers, setTriggers] = useState(state.triggers || []);

  const [activeCorrectionIndex, setActiveCorrectionIndex] = useState(null);
  const [activeTriggerIndex, setActiveTriggerIndex] = useState(null);

  const updateTrigger = (i, attrs) => {
    setTriggers(update(triggers, { [i]: { $merge: attrs } }));
  };
  const updateCorrection = (i, attrs) => {
    setCorrections(update(corrections, { [i]: { $merge: attrs } }));
  };

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = true;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.score = score;
    newState.schema = schema;
    newState.hidden = hidden;
    newState.corrections = corrections;
    newState.triggers = triggers;
    newState.nrOfAttempts = nrOfAttempts;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  return (
    <>
      {activeTriggerIndex !== null && (
        <BoxTriggerEditor
          onClose={() => setActiveTriggerIndex(null)}
          triggerIndex={activeTriggerIndex}
          trigger={triggers[activeTriggerIndex]}
          onUpdate={(data) => {
            updateTrigger(activeTriggerIndex, data);
          }}
        />
      )}
      {activeCorrectionIndex !== null && (
        <BoxCorrectionEditor
          onClose={() => setActiveCorrectionIndex(null)}
          correctionIndex={activeCorrectionIndex}
          correction={corrections[activeCorrectionIndex]}
          onUpdate={(data) => {
            updateCorrection(activeCorrectionIndex, data);
          }}
        />
      )}
      <div
        data-open="SOW"
        className={`flex w-full flex-col gap-4 lg:p-4`}
        style={{ maxWidth: 'calc(100vw - 32px)' }}
        data-state={encode(JSON.stringify(state))}
        id={state.uid}
      >
        <WidgetHeader
          index={index}
          titleWidget={titleWidget}
          setTitleWidget={setTitleWidget}
        />

        {/* <span className="text-lg font-semibold text-grey-04">
          {t('widgets.widgets_edit.question')} (
          {t('widgets.widgets_edit.optional')})
        </span> */}
        <div className="wg-question">
          <QuestionSection questionVraag={questionVraag} />
        </div>

        <div>
          <div className="flex flex-row items-center border-t border-b border-grey-02 py-2">
            <Switch
              checked={showAnswer}
              onChange={(isCheck) => setShowAnswer(isCheck)}
            />
            <div className="mr-4 border-r-2 border-grey-02 px-4">
              <T>widgets.widgets_edit.explain_after_submit</T>
            </div>
            <Switch
              checked={hidden}
              onChange={(isCheck) => setHidden(isCheck)}
            />
            <div className="mr-4 border-r-2 border-grey-02 px-4">
              <T>widgets.widgets_edit.hidden</T>
            </div>
            <div className="mr-2">
              <T>widgets.widgets_edit.max_score</T>
            </div>
            <InputScore value={score} onChange={(value) => setScore(value)} />
          </div>
          {/** //box questions always have immediate feedback
          <div className="flex gap-4 border-b border-grey-02 py-2">
            <Switch
              checked={immediateFeedback}
              onChange={(immediateFeedback) => {
                setImmediateFeedback(immediateFeedback);
              }}
            />
            <div className="mr-4">
              {t('widgets.edit.immediate_feedback')}
            </div>
          </div>
          */}
        </div>

        <input
          id={state.uid + '_svginput'}
          ref={uploadInputRef}
          style={{ display: 'none' }}
          type="file"
          onChange={async (ev) => {
            var reader = new FileReader();
            reader.readAsText(ev.target.files[0], 'UTF-8');
            reader.onload = (evt) => {
              const processed = svgprocess(evt.target.result);
              uploadString('schemas/', processed, 'image/svg+xml').then(
                (schema) => {
                  setSvgLoaded(false);
                  setSchema(schema);
                }
              );
            };
          }}
        />

        <span className="text-lg font-semibold text-grey-04">
          {t('widgets.box.schema')}
        </span>
        {schema && (
          <div
            className="bg-white"
            onClick={() => {
              uploadInputRef.current.click();
            }}
          >
            <Svg
              src={schema}
              afterInjection={(error, svg) => {
                if (svgLoaded) return; //this keeps updating, adding key: didn't work
                setSvgLoaded(true);

                let pins = {};
                window.$(`*[data-name]`, svg).each((i, elNode) => {
                  let el = window.$(elNode);
                  let name = el.data('name');
                  el.attr('title', name);
                  let elType = el.data('type');

                  if (!elType) return;
                  pins[name] = pins[name] || { name, types: [] };
                  pins[name].types.push(elType);
                });

                let removed = array_difference(
                  Object.keys(io),
                  Object.keys(pins)
                );
                let added = array_difference(
                  Object.keys(pins),
                  Object.keys(io)
                );
                let common = array_intersect(
                  Object.keys(pins),
                  Object.keys(io)
                );

                for (let el of common) {
                  io[el].types = pins[el].types;
                }
                for (let el of added) {
                  io[el] = pins[el];
                }
                for (let el of removed) {
                  delete io[el];
                }

                setIo(deep_copy(io));
              }}
            />
          </div>
        )}

        <div className="mt-4 flex flex-row items-center border-t border-b border-grey-02 py-2">
          <Button
            variant="outline"
            onClick={() => {
              uploadInputRef.current.click();
            }}
          >
            {t('widgets.box.upload_schematic')}
          </Button>
        </div>

        <div
          className="flex"
          style={{ alignItems: 'center', marginTop: 5, width: '50%' }}
        >
          <label style={{ paddingRight: 30 }}>
            <T>widgets.box.nr_of_attempts</T>
          </label>
          <InputText
            value={nrOfAttempts || ''}
            fullWidth
            type="number"
            step="1"
            onChange={(ev) => {
              let newVal = parseFloat(realParseFloat(ev.target.value));
              setNrOfAttempts(newVal);
            }}
          />
        </div>

        <span className="text-lg font-semibold text-grey-04">
          {t('widgets.box.elements')}
        </span>
        <table
          className="borderless-table bg-white"
          style={{ borderCollapse: 'collapse' }}
        >
          <thead>
            <tr>
              <th style={{ width: '40px' }}>Nr</th>
              <th style={{ width: '50px' }}>Pin</th>
              <th style={{ width: '200px' }}>Type</th>
              <th>
                <T>widgets.box.expected_value</T>
              </th>
            </tr>
          </thead>
          <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
            {Object.values(io).map((x, i) => (
              <tr
                key={x.uid}
                style={{ alignItems: 'center', verticalAlign: 'middle' }}
              >
                <td style={{ verticalAlign: 'middle' }}>{i + 1}</td>
                <td
                  style={{
                    width: '50px',
                    paddingRight: 20,
                    verticalAlign: 'middle',
                  }}
                >
                  <div>{x.name}</div>
                </td>
                <td
                  style={{
                    width: '200px',
                    paddingRight: 20,
                    verticalAlign: 'middle',
                  }}
                >
                  {x.types.map((x) => t('widgets.box.element_' + x)).join(', ')}
                </td>
                <td>
                  {digitalOutputs.includes(x.name) && (
                    <div className="flex items-center	">
                      <InputSelect
                        value={digitalOptions.find(
                          (y) => y.value === `${x.state}`
                        )}
                        options={digitalOptions}
                        onChange={(data) => {
                          let newVal = stringToBool(data.value);
                          setIo(
                            update(io, {
                              [x.name]: { state: { $set: newVal } },
                            })
                          );
                        }}
                      >
                        {(options) => (
                          <span>{t('widgets.box.' + options.label)}</span>
                        )}
                      </InputSelect>
                    </div>
                  )}
                  {analogInputs.includes(x.name) && (
                    <div className="flex items-center	">
                      <span>
                        <T>min</T>&nbsp;(V):
                      </span>
                      &nbsp;
                      <div className="inline" style={{ width: '50%' }}>
                        <InputText
                          value={x.min !== undefined ? x.min : ''}
                          fullWidth
                          onChange={(ev) => {
                            let newVal = realParseFloat(ev.target.value);
                            setIo(
                              update(io, {
                                [x.name]: { min: { $set: newVal } },
                              })
                            );
                          }}
                        />
                      </div>
                      <span style={{ marginLeft: 10 }}>
                        <T>max</T>&nbsp;(V):
                      </span>
                      &nbsp;
                      <div className="inline" style={{ width: '50%' }}>
                        <InputText
                          value={x.max !== undefined ? x.max : ''}
                          fullWidth
                          onChange={(ev) => {
                            let newVal = realParseFloat(ev.target.value);
                            setIo(
                              update(io, {
                                [x.name]: { max: { $set: newVal } },
                              })
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            {triggers.map((x, i) => (
              <tr key={i}>
                <td colSpan="2">{x.name}</td>
                <td colSpan="2">
                  <InputSelect
                    style={{ padding: '0 5px' }}
                    size="small"
                    options={requiredOptions}
                    value={requiredOptions.find(
                      (y) => y.value === `${x.required || false}`
                    )}
                    onChange={(data) => {
                      let newVal = stringToBool(data.value);
                      updateTrigger(i, { required: newVal });
                    }}
                  >
                    {(options) => (
                      <span>{t('widgets.box.' + options.label)}</span>
                    )}
                  </InputSelect>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <span className="text-lg font-semibold text-grey-04">
          {t('widgets.box.triggers')}
        </span>
        {!!(triggers || []).length && (
          <table
            className="borderless-table"
            style={{ borderCollapse: 'collapse' }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>
                  <T>NAME</T>
                </th>
                <th style={{ textAlign: 'left' }}>
                  <T>Description</T>
                </th>
                <th style={{ width: '130px' }}></th>
              </tr>
            </thead>
            <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
              {(triggers || []).map((trigger, i) => (
                <tr key={'trigger_' + i} style={{ alignItems: 'center' }}>
                  <td style={{ verticalAlign: 'middle', width: '250px' }}>
                    <InputText
                      size="small"
                      fullWidth
                      style={{ padding: '0 5px' }}
                      onChange={(ev) => {
                        updateTrigger(i, { name: ev.target.value });
                      }}
                      value={trigger.name}
                    />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <InputText
                      size="small"
                      fullWidth
                      style={{ padding: '0 5px' }}
                      onChange={(ev) => {
                        updateTrigger(i, { description: ev.target.value });
                      }}
                      value={trigger.description}
                    />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div className="flex">
                      <IconButton
                        Icon={Pencil}
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setActiveTriggerIndex(i);
                        }}
                      />
                      <Button
                        size="sm"
                        className="!px-2"
                        onClick={() => {
                          let newTrigger = deep_copy(triggers);
                          newTrigger.splice(i, 1);
                          setTriggers(newTrigger);
                        }}
                        variant="ghost"
                      >
                        <img
                          src="/images/icons/trash_icon.svg"
                          alt="delete button"
                        />
                      </Button>
                      <IconButton
                        Icon={ChevronUp}
                        variant="ghost"
                        size="sm"
                        disabled={i === 0}
                        onClick={() => {
                          let newTriggers = deep_copy(triggers);
                          swap(newTriggers, i - 1, i);
                          setTriggers(newTriggers);
                        }}
                      />
                      <IconButton
                        Icon={ChevronDown}
                        variant="ghost"
                        size="sm"
                        disabled={i === triggers.length - 1}
                        onClick={() => {
                          let newTriggers = deep_copy(triggers);
                          swap(newTriggers, i + 1, i);
                          setTriggers(newTriggers);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Button
          size="sm"
          className="self-start"
          onClick={() => {
            let newTriggers = deep_copy(triggers);
            newTriggers.push({
              name: t('NEW_TRIGGER'),
              description: '',
              conditions: [],
              actions: [],
            });
            setTriggers(newTriggers);
          }}
        >
          <T>widgets.box.new_trigger</T>&nbsp;
          <i className="fal fa-plus fa-lg"></i>
        </Button>

        <span className="text-lg font-semibold text-grey-04">
          {t('widgets.box.corrections')}
        </span>
        {!!(corrections || []).length && (
          <table
            className="borderless-table"
            style={{ borderCollapse: 'collapse' }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '20%' }}>
                  <T>NAME</T>
                </th>
                <th style={{ textAlign: 'left' }}>
                  <T>Description</T>
                </th>
                <th style={{ width: '40px' }} title={t('COUNT_TOWARDS_SCORE')}>
                  <T>Free</T>
                </th>
                <th style={{ width: '130px' }}></th>
              </tr>
            </thead>
            <tbody style={{ marginBottom: 10, alignItems: 'center' }}>
              {(corrections || []).map((correction, i) => (
                <tr key={'correction_' + i} style={{ alignItems: 'center' }}>
                  <td style={{ verticalAlign: 'middle', width: '250px' }}>
                    <InputText
                      size="small"
                      fullWidth
                      style={{ padding: '0 5px' }}
                      onChange={(ev) => {
                        updateCorrection(i, { name: ev.target.value });
                      }}
                      value={correction.name}
                    />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <InputText
                      size="small"
                      fullWidth
                      style={{ padding: '0 5px' }}
                      onChange={(ev) => {
                        updateCorrection(i, { description: ev.target.value });
                      }}
                      value={correction.description}
                    />
                  </td>
                  <td>
                    <InputSelect
                      fullWidth
                      style={{ padding: '0 5px' }}
                      size="small"
                      options={loseAttemptOptions}
                      value={loseAttemptOptions.find(
                        (y) => y.value === `${!!correction.free}`
                      )}
                      onChange={(data) => {
                        let newVal = stringToBool(data.value);
                        updateCorrection(i, { free: newVal });
                      }}
                    >
                      {(options) => (
                        <span>{t('widgets.box.' + options.label)}</span>
                      )}
                    </InputSelect>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div className="flex">
                      <IconButton
                        Icon={Pencil}
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setActiveCorrectionIndex(i);
                        }}
                      />
                      <Button
                        size="sm"
                        className="!px-2"
                        onClick={() => {
                          let newCorrections = deep_copy(corrections);
                          newCorrections.splice(i, 1);
                          setCorrections(newCorrections);
                        }}
                        variant="ghost"
                      >
                        <img
                          src="/images/icons/trash_icon.svg"
                          alt="delete button"
                        />
                      </Button>
                      <IconButton
                        Icon={ChevronUp}
                        variant="ghost"
                        size="sm"
                        disabled={i === 0}
                        onClick={() => {
                          let newCorrections = deep_copy(corrections);
                          swap(newCorrections, i - 1, i);
                          setCorrections(newCorrections);
                        }}
                      />
                      <IconButton
                        Icon={ChevronDown}
                        variant="ghost"
                        size="sm"
                        disabled={i === corrections.length - 1}
                        onClick={() => {
                          let newCorrections = deep_copy(corrections);
                          swap(newCorrections, i + 1, i);
                          setCorrections(newCorrections);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Button
          size="sm"
          className="self-start"
          onClick={() => {
            let newCorrections = deep_copy(corrections);
            newCorrections.push({
              name: t('widgets.box.new_correction'),
              description: '',
              assertions: [],
              verifications: [],
            });
            setCorrections(newCorrections);
          }}
        >
          <T>widgets.box.new_correction</T>&nbsp;
          <i className="fal fa-plus fa-lg"></i>
        </Button>

        <div className={`${!showAnswer ? 'hidden' : ''}`}>
          {/* <span className="text-lg font-semibold text-grey-04">
            {t('widgets.widgets_edit.example_answer')} (
            {t('widgets.widgets_edit.optional')})
          </span> */}
          <div className="wg-answer">
            <QuestionSection
              questionVraag={antwoordVraag}
              exampleAnswer={true}
            />
          </div>
        </div>
        <div data-closed="EOW"></div>
        <WidgetCompetencies
          state={state}
          setCompetencies={setCompetencies}
          competencies={competencies}
        />
      </div>
    </>
  );
};

import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Document,
  Chapter,
  MoreHorizontal,
  Plus,
  Pencil,
  Options,
  Library,
  Play,
  Image,
  ImageFilled,
} from '@teo/components/icons';
import { IconButton, Dropdown } from '@teo/components';
import { axios } from '@/axios';
import { config } from '@/config';
import { newUid } from '@/util';
import { uploadLesson, uploadImage } from '@/query/documents';
import { LessonSettingsMoadel } from './LessonSettings/LessonSettingsMoadel';
import { uploadVideo } from '@/query/vimeo';
import { LibraryModal } from '../LibraryModal/LibraryModal';
import DeleteModalLesson from '../DeleteModal/DeleteModalLesson';
import Hint from '../Hints/Hints';

export const CustomNodeAction = (props: any) => {
  const {
    node,
    newTree,
    coursecontentId,
    setAddLesson,
    setAddFolder,
    handleEditClick,
    handleDelete,
    setTree,
    setNewName,
    setLessons,
    setDirectories,
    isMobile,
    onRefresh,
    handleOpenLibrary,
    setUpdateLesInfo,
    setAddNumber,
    addNumber,
    setLoadVersion,
    loadVersion,
    setNewActiveNode,
    setIsOpenImageLibrary,
    isOpenImageLibrary,
    editName,
    itemRef,
  } = props;

  const { t } = useTranslation();

  const fileInputRef = useRef<any>(null);
  const fileInputVideoRef = useRef<any>(null);

  const { droppable, data } = node;
  const [newLesson, setNewLesson] = useState<any>();
  const [newFolder, setNewFolder] = useState<any>();
  const [newAsset, setNewAsset] = useState<any>();
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isItemBottom, setIsItemBottom] = useState(false);

  const handleNewLesson = async () => {
    const data = await axios.post(`${config.backend}/lessons`, {
      name: t('pages.create_edit_course.new_lesson_button'),
      canEdit: true,
      content: '',
      description: '',
    });
    setNewLesson(data?.data);
  };

  const createLesson = async () => {
    const result = await axios.post(`${config.backend}/uses`, {
      canEdit: true,
      coursecontentId: parseInt(coursecontentId, 10),
      lessonId: newLesson?.id,
    });

    const createLessonId = result?.data?.lessonId;
    const createExtraInfoUsesId = result?.data?.id;

    if (createLessonId) {
      let newYindex;
      const parentTree: any = newTree.filter(
        (item: any) => item?.parent === node?.id
      );
      if (parentTree?.length) {
        newYindex =
          parentTree?.reduce((max: any, obj: any) => {
            return obj?.data?.yindex > max ? obj?.data?.yindex : max;
          }, parentTree[0]?.data?.yindex) + 1;
      } else {
        newYindex = 0;
      }
      axios.put(`${config.backend}/use/${result?.data?.id}`, {
        coursecontentId: parseInt(coursecontentId, 10),
        lessonId: createLessonId,
        path: `${data?.path}/${createLessonId}`,
        yindex: newYindex,
      });
      setAddLesson({
        ...newLesson,
        canEdit: true,
        coursecontentId: parseInt(coursecontentId, 10),
        numbered: true,
        lessonId: createLessonId,
        path: `${data?.path}/${createLessonId}`,
        usesId: createExtraInfoUsesId,
        yindex: newYindex,
      });
      setNewActiveNode(createExtraInfoUsesId);
      setTree(null);
    }
  };

  useEffect(() => {
    if (newLesson?.id) {
      uploadLesson(newLesson?.postData, '');
      createLesson();
    }
  }, [newLesson?.id]);

  const quickInsertClick = () => {
    const itemTree = itemRef.current;
    const contentTree = document.getElementById('course-lessons-list');
    const blockTree = document.getElementById('course-lessons');
    if (itemTree && contentTree && blockTree) {
      const positionItem = itemTree.offsetTop;
      const contentTreeHeight = contentTree.offsetHeight;
      const blockTreeHeight = blockTree.offsetHeight;

      const parentHeight =
        blockTreeHeight > contentTreeHeight
          ? blockTreeHeight
          : contentTreeHeight;

      if (positionItem + 350 > parentHeight) {
        setIsItemBottom(true);
      } else {
        setIsItemBottom(false);
      }
    }
  };

  useEffect(() => {
    quickInsertClick();
  }, [newTree]);

  const createFolder = async () => {
    const data = await axios.post(`${config.backend}/directories`, {
      coursecontentId: parseInt(coursecontentId, 10),
      name: t('pages.create_edit_course.new_chapter_button'),
    });
    setNewFolder(data?.data);
  };

  useEffect(() => {
    if (newFolder?.id) {
      const newPath = `${newUid(20)}`;
      let newYindex;
      const parentTree: any = newTree.filter(
        (item: any) => item?.parent === node?.id
      );
      if (parentTree?.length) {
        newYindex =
          parentTree?.reduce((max: any, obj: any) => {
            return obj?.data?.yindex > max ? obj?.data?.yindex : max;
          }, parentTree[0]?.data?.yindex) + 1;
      } else {
        newYindex = 0;
      }
      axios.put(`${config.backend}/directory/${newFolder?.id}`, {
        coursecontentId: parseInt(coursecontentId, 10),
        id: newFolder?.id,
        name: t('pages.create_edit_course.new_folder'),
        path: `${data?.path}/${newPath}`,
        yindex: newYindex,
      });
      setAddFolder({
        ...newFolder,
        coursecontentId: parseInt(coursecontentId, 10),
        id: newFolder?.id,
        numbered: true,
        name: t('pages.create_edit_course.new_folder'),
        path: `${data?.path}/${newPath}`,
        yindex: newYindex,
      });
      setNewActiveNode(newFolder?.id);
      setTree(null);
    }
  }, [newFolder?.id]);

  const handleUploadImage = async (event: any) => {
    const img = event.target.files[0];
    const size = 900;
    const data = await uploadImage(img, size, size, 'jpeg');

    const dataImage = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'image',
      assetPath: data,
    });
    setNewAsset(dataImage?.data);
  };

  const handleUploadImageLibrary = async (image: any) => {
    const res = await fetch(image);
    const blob = await res.blob();
    const mime = blob.type;
    const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);
    const img = new File([blob], `${blob.size}.${ext}`, {
      type: mime,
    });
    const size = 900;
    const data = await uploadImage(img, size, size, 'jpeg');

    const dataImage = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'image',
      assetPath: data,
    });
    setNewAsset(dataImage?.data);
  };

  const handleUploadVideo = async (event: any) => {
    const file = event.target.files[0];
    let title;
    try {
      title = file.name.split('.').slice(0, -1).join('.');
    } catch (e) {
      title = document.title;
    }
    const data = await uploadVideo(file, true, true, title);
    const dataVideo = await axios.post(`${config.backend}/assets`, {
      assetName: t('pages.create_edit_course.new_asset'),
      assetType: 'video',
      assetPath: data,
    });
    setNewAsset(dataVideo?.data);
  };

  useEffect(() => {
    if (newAsset) {
      createImageInfo();
    }
  }, [newAsset]);

  const createImageInfo = async () => {
    const result = await axios.post(`${config.backend}/uses`, {
      coursecontentId: parseInt(coursecontentId, 10),
      assetId: newAsset?.id,
    });

    const createAssetId = result?.data?.assetId;
    const createAssetUsesId = result?.data?.id;

    if (createAssetId) {
      let newYindex: any;
      const parentTree: any = newTree.filter(
        (item: any) => item?.parent === node?.id
      );
      if (parentTree?.length) {
        newYindex =
          parentTree?.reduce((max: any, obj: any) => {
            return obj?.data?.yindex > max ? obj?.data?.yindex : max;
          }, parentTree[0]?.data?.yindex) + 1;
      } else {
        newYindex = 0;
      }
      axios.put(`${config.backend}/use/${result?.data?.id}`, {
        assetId: createAssetId,
        canEdit: true,
        coursecontentId: parseInt(coursecontentId, 10),
        onlyTheory: true,
        path: `${data?.path}/${createAssetId}`,
        yindex: newYindex,
      });
      setAddLesson({
        ...newAsset,
        canEdit: true,
        coursecontentId: parseInt(coursecontentId, 10),
        onlyTheory: true,
        numbered: true,
        assetId: createAssetId,
        path: `${data?.path}/${createAssetId}`,
        usesId: createAssetUsesId,
        yindex: newYindex,
      });
      setNewActiveNode(createAssetUsesId);
      setTree(null);
      setNewAsset(null);
    }
  };

  useEffect(() => {
    if (isOpenSettings || isOpenImageLibrary) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpenSettings, isOpenImageLibrary]);

  return (
    <div className="ml-auto">
      {droppable && (
        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="relative inline-block"
        >
          {!editName && (
            <Dropdown>
              <Dropdown.Trigger>
                <IconButton
                  Icon={Plus}
                  size="sm"
                  variant="ghost"
                  className="w-5 min-w-5 text-[#7d7d81]"
                />
              </Dropdown.Trigger>
              <Dropdown.Content
                style={
                  !isMobile
                    ? {
                        visibility: 'visible',
                        right: '0',
                        top: isItemBottom ? 'auto' : '20px',
                        bottom: isItemBottom ? 'calc(100% + 10px)' : 'auto',
                        position: 'absolute',
                      }
                    : {
                        visibility: 'visible',
                        right: '0',
                        top: isItemBottom ? 'auto' : '20px',
                        bottom: isItemBottom ? 'calc(100% + 10px)' : 'auto',
                        position: 'absolute',
                      }
                }
              >
                <Dropdown.Actions>
                  <Dropdown.Button onClick={() => handleNewLesson()}>
                    <IconButton
                      Icon={Document}
                      variant="ghost"
                      className="mr-2 text-[#231f20]"
                    />
                    <p>{t('pages.create_edit_course.new_lesson_button')}</p>
                  </Dropdown.Button>

                  <Dropdown.Button onClick={() => createFolder()}>
                    <IconButton
                      Icon={Chapter}
                      variant="ghost"
                      className="mr-2 text-[#231f20]"
                    />
                    <p>{t('pages.create_edit_course.new_chapter_button')}</p>
                  </Dropdown.Button>

                  <Dropdown.Button
                    onClick={() => {
                      fileInputRef?.current?.click();
                    }}
                  >
                    <IconButton
                      Icon={Image}
                      variant="ghost"
                      className="mr-2 text-[#231f20]"
                    />
                    <p>{t('extra_info.upload_img')}</p>
                  </Dropdown.Button>

                  <Dropdown.Button
                    onClick={() => fileInputVideoRef?.current?.click()}
                  >
                    <IconButton
                      Icon={Play}
                      variant="ghost"
                      className="mr-2 text-[#231f20]"
                    />
                    <p>{t('extra_info.upload_video')}</p>
                  </Dropdown.Button>

                  <Dropdown.Button
                    onClick={() => {
                      setIsOpenImageLibrary(true);
                    }}
                  >
                    <IconButton
                      Icon={ImageFilled}
                      variant="ghost"
                      className="mr-2 text-tint-blue"
                    />
                    <p>
                      {t('pages.create_edit_course.from_image_library_button')}
                    </p>
                  </Dropdown.Button>

                  <Dropdown.Button
                    onClick={() => {
                      handleOpenLibrary(data?.path, data?.id);
                    }}
                  >
                    <IconButton
                      Icon={Library}
                      variant="ghost"
                      className="mr-2 text-tint-blue"
                    />
                    <p>{t('pages.create_edit_course.from_library_button')}</p>
                  </Dropdown.Button>
                </Dropdown.Actions>
              </Dropdown.Content>
            </Dropdown>
          )}
        </div>
      )}

      {!editName && (
        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="relative inline-block"
        >
          <Dropdown>
            <Dropdown.Trigger>
              {/* {node?.parent === 0 && node?.data?.order === 1 && (
                <Hint
                  key={`hint_41`}
                  id={'hint_41'}
                  leftDots={'-left-2 top-0.5'}
                  title={t('hint.title_41')}
                  order={41}
                  style={{
                    left: '2px',
                    bottom: '70px',
                  }}
                />
              )} */}
              <IconButton
                className="w-5 min-w-5 text-[#7d7d81]"
                Icon={MoreHorizontal}
                variant="ghost"
                size="sm"
              />
            </Dropdown.Trigger>
            <Dropdown.Content
              style={
                !isMobile
                  ? {
                      visibility: 'visible',
                      right: '0',
                      top: isItemBottom ? 'auto' : '20px',
                      bottom: isItemBottom ? 'calc(100% + 10px)' : 'auto',
                      position: 'absolute',
                    }
                  : {
                      visibility: 'visible',
                      right: '0',
                      top: isItemBottom ? 'auto' : '20px',
                      bottom: isItemBottom ? 'calc(100% + 10px)' : 'auto',
                      position: 'absolute',
                    }
              }
            >
              <Dropdown.Actions>
                {(node.data.canEdit || !node.data.lessonId) && ( //directories and non linked lessons
                  <Dropdown.Button onClick={() => handleEditClick()}>
                    <Pencil className="mr-4 w-5 text-[#231f20]" />
                    <p>{t('pages.create_edit_course.rename')}</p>
                  </Dropdown.Button>
                )}

                <Dropdown.Button onClick={() => setIsOpenDeleteModal(true)}>
                  <img
                    src="/images/icons/trash_icon_red.svg"
                    alt="delete button"
                    className="mr-5 w-4"
                  />
                  <p>{t('pages.create_edit_course.remove')}</p>
                </Dropdown.Button>
                {/* {!droppable && (node.data.lessonId || node.data.assetId) && ( */}
                <Dropdown.Button
                  onClick={() => {
                    setIsOpenSettings(true);
                  }}
                >
                  <Options className="mr-4 w-5 text-[#231f20]" />
                  <p>{t('pages.create_edit_course.settings')}</p>
                </Dropdown.Button>
                {/* )} */}
              </Dropdown.Actions>
            </Dropdown.Content>
          </Dropdown>
        </div>
      )}

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadImage}
        accept="image/*"
        style={{ display: 'none' }}
        multiple={false}
      />
      <input
        type="file"
        ref={fileInputVideoRef}
        onChange={handleUploadVideo}
        accept="video/mp4,video/x-m4v,video/*"
        style={{ display: 'none' }}
        multiple={false}
      />
      {isOpenSettings && (
        <LessonSettingsMoadel
          onRefresh={onRefresh}
          coursecontentId={coursecontentId}
          closeModal={() => setIsOpenSettings(false)}
          les={node?.data}
          setIsOpenSettings={setIsOpenSettings}
          setLessons={setLessons}
          setDirectories={setDirectories}
          setNewName={setNewName}
          setUpdateLesInfo={setUpdateLesInfo}
          setAddNumber={setAddNumber}
          addNumber={addNumber}
          setLoadVersion={setLoadVersion}
          loadVersion={loadVersion}
        />
      )}
      {isOpenImageLibrary && (
        <LibraryModal
          setIsOpenLibraryModal={setIsOpenImageLibrary}
          isOpenLibraryModal={isOpenImageLibrary}
          lessonPage={false}
          setUploadFile={handleUploadImageLibrary}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteModalLesson
          title={
            node?.data?.lessonId
              ? t('pages.create_edit_course.delete_modal.title_les')
              : node?.data?.assetId
              ? t('pages.create_edit_course.delete_modal.title_asset')
              : t('pages.create_edit_course.delete_modal.title_chapter')
          }
          heading={
            node?.data?.lessonId
              ? t('pages.create_edit_course.delete_modal.heading_les')
              : node?.data?.assetId
              ? t('pages.create_edit_course.delete_modal.heading_asset')
              : t('pages.create_edit_course.delete_modal.heading_chapter')
          }
          text={
            node?.data?.lessonId
              ? t('pages.create_edit_course.delete_modal.subtitle_les')
              : node?.data?.assetId
              ? t('pages.create_edit_course.delete_modal.subtitle_assey')
              : t('pages.create_edit_course.delete_modal.subtitle_chapter')
          }
          les={node?.data}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          closeModal={() => setIsOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

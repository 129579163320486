import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@teo/components';
import React, { useState } from 'react';
import { ErrorBox } from '@/components/ErrorBox';

function AuthResetPasswordSuccess() {
  const { t } = useTranslation();

  const [errorText, setErrorText] = useState('');

  return (
    <div className="h-full overflow-hidden md:flex md:items-center">
      <div
        className="flex h-full overflow-hidden md:w-1/2 md:items-center md:overflow-auto"
        id="userForm"
      >
        <form className="m-auto flex w-full max-w-[420px] flex-col max-md:h-full max-md:overflow-hidden">
          <div className="space-y-6 px-5 pt-8 max-md:flex-grow max-md:overflow-auto md:space-y-8">
            <img
              className="h-12 w-12 md:h-16 md:w-16"
              src="/TEO_logo.svg"
              alt="Logo"
            />

            <h1 className="text-3xl-semibold">
              {t('pages.auth.forgot_password.got_mail_title')}
            </h1>

            {errorText && <ErrorBox text={errorText} />}

            <div className="space-y-6 md:space-y-8">
              <p className="text-sm text-grey-05">
                {t('pages.auth.forgot_password.got_mail_subtitle')}
              </p>
            </div>
          </div>

          <div className="flex px-5 py-8 max-md:flex max-md:flex-col max-md:space-y-3 md:grid md:grid-cols-2 md:gap-4">
            <ButtonLink
              to="../forgot-password"
              variant="outline"
              style={{ border: '2px solid #d5d5d8' }}
            >
              {t('pages.auth.forgot_password.resend_link')}
            </ButtonLink>
          </div>
        </form>
      </div>

      <div className="w-1/2 max-md:hidden md:flex md:h-[100vh] md:items-center md:justify-center">
        <img
          className="h-full w-full object-cover"
          role="presentation"
          src="/images/interface/auth/login.jpg"
          alt="login_image"
        />
      </div>
    </div>
  );
}

export default AuthResetPasswordSuccess;

import { Button, Modal, Spinner } from '@teo/components';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { format } from 'date-fns';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { nthIndexOf } from '@/util';
import { useNavigate } from 'react-router-dom';

export const VersionPublication = ({
  closeModal,
  publication,
  setIsOpenVersionModal,
  activeNode,
  setLoadVersion,
  loadVersion,
  setIsOpenExportModal,
  setSelectVersion,
  publicationType,
}: any) => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const basePath = window.location.pathname.slice(
    0,
    nthIndexOf(window.location.pathname, '/', 3)
  );

  const versions: any = useGet(`/coursecontentdetails`, {
    masterId: publication?.id,
    sort: '-createdAt,-id',
  });

  const handleRevert = async (data: any) => {
    setLoadVersion(false);
    await axios
      .post(`${config.backend}/publish/restore/${data.id}`, {
        ...data,
      })
      .then(() => {
        setLoadVersion(true);
      })
      .catch(() => {
        setLoadVersion(true);
      });
    setIsOpenVersionModal(false);
    window.location.reload();
    /*
    setTimeout(() => {
      window.location.reload();
    }, 300);
    */
  };

  return (
    <Modal isOpen={loadVersion}>
      <Modal.Header onClose={closeModal}>
        {t('pages.create_edit_course.course_versions')}{' '}
        {`"${publication?.name}"`}
      </Modal.Header>
      <Modal.Body>
        <div className="-mx-6 max-h-[70vh] overflow-y-scroll px-6">
          <h4 className="mb-2 text-base">
            {t('pages.create_edit_course.versions_title')}
          </h4>
          <p className="text-sm text-grey-06">
            {t(
              `pages.create_edit_course.type_${publicationType}.versions_subtitle`,
              {
                courseName: publication?.name,
              }
            )}
          </p>
          <div className="relative mt-6 mb-4 flex flex-col gap-5">
            <div className="absolute left-0 top-4 h-[calc(100%_-_32px)] w-0.5 bg-grey-02"></div>
            {versions?.data?.data?.map((version: any) => {
              return (
                <div
                  key={version.id}
                  className="relative flex flex-col gap-1 pl-8"
                >
                  <div className="absolute -left-0.5 top-2/4 -ml-px h-2 w-2 -translate-y-1/2 rounded-full bg-grey-05"></div>
                  <p className="pl-1.5 text-sm text-grey-06">
                    {format(
                      new Date(version.createdAt || ''),
                      'dd/MM/yyyy HH:mm'
                    )}
                  </p>
                  {version.versionDescription && (
                    <p className="pl-1.5 text-sm">
                      {version.versionDescription}
                    </p>
                  )}
                  <div className="flex flex-row items-center">
                    <Button
                      variant="text"
                      size="sm"
                      className="text-tint-blue"
                      onClick={async () => {
                        navigate(
                          `${basePath}/courses/${publication?.id}/preview?les=${
                            activeNode?.id ? activeNode?.id : 0
                          }`
                        );
                      }}
                    >
                      {t('button.example')}
                    </Button>
                    <span className="px-1">&#8729;</span>
                    <Button
                      variant="text"
                      size="sm"
                      className="text-tint-blue"
                      onClick={() => handleRevert(version)}
                    >
                      {t('pages.create_edit_course.revert_version')}
                    </Button>
                    <span className="px-1">&#8729;</span>
                    <Button
                      variant="text"
                      size="sm"
                      className="text-tint-blue"
                      onClick={() => {
                        setSelectVersion(version);
                        setIsOpenExportModal(true);
                        setIsOpenVersionModal(false);
                      }}
                    >
                      {t('pages.create_edit_course.export')}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        cancelButtonLabel={t('button.cancel')}
        onCancel={closeModal}
      >
        <div>
          <Button onClick={() => setIsOpenVersionModal(false)}>
            {t('button.finished')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

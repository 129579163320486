import { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, InputText, InputTextarea, IconButton } from '@teo/components';
import UploadDropzoneSection from '@/components/UploadDropzoneSection/UploadDropzoneSection';
import { StepHomePage } from '@/models/addCourseStep';
import FroalaTextAreaEdit from '@/components/Froala/FroalaTextAreaEdit';
import { Plus, Trash } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';

const HomePageStep = ({
  course,
  setGlobalState,
  globalState,
  state,
  setLoadStep,
}: any) => {
  const { t } = useTranslation();

  const {
    picture,
    setPicture,
    abstract,
    setAbstract,
    subTitles,
    setSubTitles,
    subImages,
    setSubImages,
    subTexts,
    setSubTexts,
    name,
    setName,
    courseName,
    setCoursename,
    setCourseDescription,
    courseDescription,
    setBannerPicture,
    bannerPicture,
  } = state;

  useEffect(() => {
    setTimeout(() => {
      setLoadStep(false);
    }, 100);
  }, []);

  return (
    <div className="flex flex-col">
      <article className="prose flex flex-col ">
        <h3 className="text-base">
          {t('pages.course_content_flow.step_2.title')}
        </h3>
        <p className="pt-3 text-sm text-grey-07">
          {t('pages.course_content_flow.step_2.subtitle')}
        </p>
      </article>

      <div className="">
        <p className="mb-1 mt-6 text-sm font-semibold text-black">
          {t('pages.course_content_flow.step_2.main_image')}
        </p>
        <UploadDropzoneSection
          setUploadLogo={setPicture}
          uploadLogo={picture}
          courseImg={true}
        />
      </div>

      <div className="">
        <p className="mb-1 mt-8 text-sm font-semibold text-black">
          {t('pages.course_content_flow.step_2.learning_page_banner')}
        </p>
        <p className=" mt-1 text-sm text-grey-08">
          {t(
            'pages.course_content_flow.step_2.learning_page_banner_description'
          )}
        </p>
        <UploadDropzoneSection
          setUploadLogo={setBannerPicture}
          uploadLogo={bannerPicture}
          bannerLPImg={true}
        />
      </div>

      <div className="mb-1 mt-6 text-sm text-black">
        <Label htmlFor="name">
          {t('pages.course_content_flow.step_2.title_course')}
        </Label>
        <InputText
          id="name"
          onChange={(e) => setCoursename(e.currentTarget.value)}
          value={courseName || name}
          className="w-full"
        />
      </div>

      <div className="mb-1 mt-6 text-sm text-black">
        <Label htmlFor="name">
          {t('pages.course_content_flow.step_2.short_description')}
        </Label>
        <InputTextarea
          id="text-input"
          minRows={2}
          onChange={(e) => setCourseDescription(e.currentTarget.value)}
          value={courseDescription}
        />
      </div>

      <div className="mb-1 mt-6 text-sm text-black">
        <Label htmlFor="description">
          {t('pages.course_content_flow.step_2.description_course')}
        </Label>
        <FroalaTextAreaEdit
          id="description"
          onChange={(e: any) => setAbstract(e.target.value)}
          value={abstract}
        />
      </div>

      <div className="mb-1 mt-6 text-sm text-black">
        <Label htmlFor="description">
          {t('pages.course_content_flow.step_2.description_extra')}
        </Label>

        {subTexts.map((x: any, i: number) => {
          const titleContent = (
            <InputText
              value={subTitles[i]}
              onChange={(ev: any) => {
                setSubTitles([
                  ...subTitles.slice(0, i),
                  ev.target.value,
                  ...subTitles.slice(i + 1),
                ]);
              }}
              placeholder={t('pages.course_content_flow.step_2.subtitles')}
            />
          );
          const imageContent = (
            <UploadDropzoneSection
              setUploadLogo={(url: any) => {
                setSubImages([
                  ...subImages.slice(0, i),
                  url,
                  ...subImages.slice(i + 1),
                ]);
              }}
              uploadLogo={subImages[i]}
              courseImg={true}
            />
          );
          const textContent = (
            <div className="mt-2">
              <FroalaTextAreaEdit
                value={subTexts[i]}
                onChange={(e: any) => {
                  setSubTexts([
                    ...subTexts.slice(0, i),
                    e.target.value,
                    ...subTexts.slice(i + 1),
                  ]);
                }}
              ></FroalaTextAreaEdit>
            </div>
          );
          return (
            <div key={i} className="mt-4 flex items-center">
              <div className="w-full">
                {titleContent}
                {imageContent}
                {textContent}
              </div>
              <div>
                <IconButton
                  Icon={Trash}
                  variant="ghost"
                  onClick={() => {
                    setSubTexts([
                      ...subTexts.slice(0, i),
                      ...subTexts.slice(i + 1),
                    ]);
                    setSubImages([
                      ...subImages.slice(0, i),
                      ...subImages.slice(i + 1),
                    ]);
                    setSubTitles([
                      ...subTitles.slice(0, i),
                      ...subTitles.slice(i + 1),
                    ]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <IconButton
          Icon={Plus}
          variant="ghost"
          onClick={() => {
            setSubImages([...subImages.slice(), null]);
            setSubTexts([...subTexts.slice(), '']);
            setSubTitles([...subTitles.slice(), '']);
          }}
        />
      </div>
    </div>
  );
};

export default HomePageStep;

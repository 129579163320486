import { Button, Modal, RadioGroup } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';

import { axios } from '@/axios';
import { config } from '@/config';
import { newUid } from '@/util';
import { useEffect, useState } from 'react';

const DeleteTeamFromCourseModal = ({
  closeModal,
  setIsOpenDeleteModal,
  activeCourse,
  teamInfo,
  organisationId,
  setResetCourseTable,
}: any) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState('yes');

  const [courseTeams, setCourseTeams] = useState(null);
  const [teamSubteams, setTeamSubteams] = useState(null);
  const [deleteSubTeams, setDeleteSubTeams] = useState<any>(null);
  const [showDeleteSubtems, setShowDeleteSubtems] = useState(false);

  useGet(`/courseteamdetails`, {
    courseId: activeCourse?.id,
    onSuccess: (result: any) => {
      setCourseTeams(result?.data || []);
    },
  });
  useGet(`/teams`, {
    organisationId,
    searchPath: `${encodeURIComponent(teamInfo?.teamPath + ';%')}`,
    onSuccess: (result: any) => {
      setTeamSubteams(result?.data || []);
    },
  });

  const findCommonIds = (arr1: any, arr2: any) => {
    const ids1 = new Set(arr1.map((obj: any) => obj.id));
    const ids2 = new Set(arr2.map((obj: any) => obj.id));

    return [...ids1].filter((id) => ids2.has(id));
  };

  useEffect(() => {
    if (courseTeams && teamSubteams) {
      const commonIds = findCommonIds(courseTeams, teamSubteams);
      setDeleteSubTeams(commonIds);
      if (commonIds?.length > 0) {
        setShowDeleteSubtems(true);
      } else {
        setShowDeleteSubtems(false);
      }
    }
  }, [courseTeams, teamSubteams]);

  const handleDeleteCourse = async () => {
    if (isChecked === 'no' || deleteSubTeams?.length < 1) {
      await axios.delete(
        `${config.backend}/courseteam/${activeCourse?.id}/${teamInfo?.id}/`
      );
      setResetCourseTable(newUid(20));
      setIsOpenDeleteModal(false);
    } else {
      const promises: any = [];
      deleteSubTeams?.map((teamId: any) => {
        promises.push(
          axios.delete(
            `${config.backend}/courseteam/${activeCourse?.id}/${teamId}/`
          )
        );
      });

      Promise.allSettled(promises).then(async () => {
        await axios.delete(
          `${config.backend}/courseteam/${activeCourse?.id}/${teamInfo?.id}/`
        );
        setResetCourseTable(newUid(20));
        setIsOpenDeleteModal(false);
      });
    }
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-lg  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            <h2 className="text-sm font-semibold">
              {t('delete_modal.title_course') + activeCourse.courseName ||
                activeCourse.name}
            </h2>
          </Modal.Header>

          <Modal.Body>
            <div className="-mx-6 flex flex-col gap-4">
              <div className="px-6">
                <h3 className="mb-1 text-[16px]">
                  {t('delete_modal.heading_course')}
                </h3>
                {/* <p className="text-base text-grey-06">
                  {t('delete_modal.text_course')}
                </p> */}
              </div>
              {showDeleteSubtems && (
                <div className="border-t border-b px-6 pt-4 pb-2">
                  <h3 className="text-[16px] font-semibold">
                    {t('delete_modal.heading_subteam')}
                  </h3>

                  <RadioGroup
                    label={''}
                    onChange={(value: string) => {
                      setIsChecked(value);
                    }}
                    value={isChecked}
                  >
                    <div className="mb-3 flex w-full flex-col gap-3">
                      <div
                        className={`w-full rounded-lg border border-grey-02 p-3 ${
                          isChecked == 'yes' && 'border-secondary-04'
                        }`}
                      >
                        <RadioGroup.Option value="yes">
                          <span className="text-sm" data-testid="radio_btn">
                            {t('delete_modal.yes')}
                          </span>
                        </RadioGroup.Option>
                      </div>
                      <div
                        className={`w-full rounded-lg border border-grey-02 p-3 ${
                          isChecked == 'no' && 'border-secondary-04'
                        }`}
                      >
                        <RadioGroup.Option value="no">
                          <span className="text-sm">
                            {t('delete_modal.no')}
                          </span>
                        </RadioGroup.Option>
                      </div>
                    </div>
                  </RadioGroup>
                </div>
              )}

              <div className="flex justify-between px-6">
                <Button
                  variant="ghost"
                  onClick={() => setIsOpenDeleteModal(false)}
                >
                  {t('button.cancel')}
                </Button>
                <Button variant="destruct" onClick={() => handleDeleteCourse()}>
                  {t('button.delete')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </div>
  );
};

export default DeleteTeamFromCourseModal;

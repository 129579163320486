import { useTranslation } from 'react-i18next';
import { Modal, Button } from '@teo/components';

const ConfirmModal = ({
  onClose,
  onConfirm,
  children,
  title,
  cancelLabel,
  confirmLabel,
  description,
  open,
}: any) => {
  cancelLabel = cancelLabel || 'forms.cancel';
  confirmLabel = confirmLabel || 'forms.confirm';
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <>
      <Modal isOpen>
        <Modal.Header onClose={onClose ? onClose : onConfirm}>
          {t(title)}
        </Modal.Header>
        <Modal.Body>
          {t(description)}
          {children}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex w-full justify-between">
            {onClose && (
              <Button variant="ghost" onClick={onClose}>
                {t(cancelLabel)}
              </Button>
            )}
            {onConfirm && (
              <Button onClick={onConfirm}>{t(confirmLabel)}</Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { ConfirmModal };

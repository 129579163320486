import { useState, useContext, useEffect, useRef } from 'react';
import LearnLesson from './LessonPage';
import { XAPIService } from '@/services/xapi.service';
import Cmi5 from '@xapi/cmi5';
import { parseJwt, useStateAndRef, useTraceUpdate } from '@/util';
import { NavContext } from '@/components/NavContext';

function FirstLesson() {
  const [loaded, setLoaded] = useState<any>(false);
  const { setNavDisabled } = useContext(NavContext);
  /*
  useEffect(() => {
    setNavDisabled(true);
    const xapi = XAPIService.getInstance();
    xapi
      .initialize()
      .then(() => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!Initialized")
        setLoaded(true);
      })
      .catch((e: any) => {
        console.error(e);
        setLoaded(true);
      });
  }, []);

  //return <>HELLO WORLD</>;


  return loaded ? <LearnLesson /> : <>HELLO WORLD</>;
  */

  return <LearnLesson />;
}

export default FirstLesson;

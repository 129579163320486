import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Label, InputText } from '@teo/components';
import { emailRegex } from '@/constants/regex.constants';
import { axios } from '@/axios';
import { config } from '@/config';
import { downloadLink } from '@/util';
import { NotificationContext } from '@/components/Contexts/NotificationContext';

const DownloadReportModal = ({
  report,
  closeModal,
  setIsOpenDownloadtModal,
  stateReport,
  user,
  updateStateReport,
  allSelect,
  courseType,
}: any) => {
  const { t } = useTranslation();
  const { setShowNotification } = useContext(NotificationContext);

  const [email, setEmail] = useState(user?.email ? user?.email : '');
  let overviewCur: any;
  if (stateReport?.reportType?.value === 'overviewCourse') {
    overviewCur = true;
  }

  const sentReport = (reportLink: any) => {
    const promises = [];
    promises.push(
      axios.post(`${config.teoreport}/report`, {
        email: email,
        organisationId: user?.activeOrganisationId,
        download: reportLink,
        lang: stateReport?.lang?.value ? stateReport?.lang?.value : user?.lang,
        teamIds: allSelect && stateReport?.teamsReport?.map((el: any) => el.id),
        userIds: allSelect && stateReport?.usersReport?.map((el: any) => el.id),
        courseIds:
          allSelect && stateReport?.coursesReport?.map((el: any) => el.id),
        formType:
          stateReport?.reportType?.value === 'overviewCourse'
            ? 'courseOverview'
            : stateReport?.reportType?.value,
        startDate: stateReport?.fromDate ? stateReport?.fromDate : null,
        endDate: stateReport?.untilDate ? stateReport?.untilDate : null,
        courseType: courseType,
        noBirth: overviewCur,
        noDiploma: overviewCur,
        noLessons: overviewCur,
      })
    );

    Promise.allSettled(promises).then((results: any) => {
      if (reportLink) {
        results[0]?.value?.data?.link &&
          downloadLink(results[0]?.value?.data?.link);
      }
    });
    if (reportLink) {
      setShowNotification({
        message: t('pages.report_page.report_download_message'),
        delay: 5000,
      });
    } else {
      setShowNotification({
        message: t('pages.report.emailed') + ` (${email})`,
        delay: 5000,
      });
    }
    updateStateReport(
      report == 'teams'
        ? { teamsReport: [] }
        : report == 'users'
        ? { usersReport: [] }
        : { coursesReport: [] }
    );
    setIsOpenDownloadtModal(false);
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-[600px]  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            {t('pages.report_page.teams_tab.title_1')}
          </Modal.Header>

          <Modal.Body>
            <h1 className="text-lg font-semibold">
              {t('pages.report_page.teams_tab.download_modal_heading')}
            </h1>
            <p className="mt-2 text-base font-normal text-[#7d7d81]">
              {t('pages.report_page.teams_tab.download_modal_text')}
            </p>

            <form
              className="w-full flex-col max-md:h-full max-md:overflow-hidden"
              data-testid="addTagsForm"
            >
              <div>
                <Label htmlFor="tagName">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.report_page.teams_tab.download_modal_label')}
                  </p>
                </Label>
                <InputText
                  id="tagName"
                  type="text"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  placeholder={''}
                  value={email}
                  data-testid="addNameTag"
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer
            cancelButtonLabel={t('pages.manage_tags.cancel_button')}
            onCancel={closeModal}
          >
            <div className="flex flex-col gap-3 sm:flex-row">
              <Button
                variant="outline"
                type="button"
                onClick={() => sentReport(true)}
              >
                {t('pages.report_page.teams_tab.btn_download_now')}
              </Button>
              <Button
                type="button"
                onClick={() => sentReport(null)}
                disabled={emailRegex.test(email) ? false : true}
              >
                {t('pages.report_page.teams_tab.btn_send_email')}
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </div>
    </div>
  );
};

export default DownloadReportModal;

import { Button, Modal, Tabs } from '@teo/components';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';
import { SettingsLesson } from './SettingsLesson';
import { LessonVersions } from './LessonVersions';
import { newUid } from '@/util';
import { organisationData } from '@/mocks/organisationData';
import { useGet } from '@/query/fetchHooks';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';

export const LessonSettingsMoadel = ({
  closeModal,
  les,
  setIsOpenSettings,
  setLessons,
  setDirectories,
  setNewName,
  onRefresh,
  setUpdateLesInfo,
  setAddNumber,
  addNumber,
  setLoadVersion,
  loadVersion,
  coursecontentId,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const [tab, setTab] = useState('settings');
  const [lesTitle, setLesTitle] = useState(
    les?.name || les?.assetName ? les?.name || les?.assetName : ''
  );
  const [onlyTheory, setOnlyTheory] = useState(les?.onlyTheory);

  const [lessonVerified, setLessonVerified] = useState(
    les?.lessonId ? !!les?.lessonVerified : !!les?.directoryVerified
  );
  const [verifiedData, setVerifiedData] = useState<any>(null);

  useGet(`/verifiedlessons`, {
    enabled: !!les?.lessonId,
    lessonId: les?.lessonId,
    organisationId: `${authUser?.activeOrganisationId}${
      (authUser?.role || 0) >= 90 ? `,OR,organisationId=${null}` : ''
    }`,
    onSuccess: async (result: any) => {
      if (result?.data?.length == 0) return;
      setVerifiedData(result?.data?.[0]);
    },
  });

  useGet(`/verifieddirectories`, {
    enabled: !les?.lessonId || !les?.assetId,
    directoryId: les?.id,
    organisationId: `${authUser?.activeOrganisationId}${
      (authUser?.role || 0) >= 90 ? `,OR,organisationId=${null}` : ''
    }`,
    onSuccess: async (result: any) => {
      if (result?.data?.length == 0) return;
      setVerifiedData(result?.data?.[0]);
    },
  });

  const [submitByTeacher, setSubmitByTeacher] = useState(
    les?.submitByTeacher ? les?.submitByTeacher : false
  );

  const [isCheckedType, setIsCheckedType] = useState(
    les?.lessonType ? les?.lessonType : 'theory'
  );
  const [isCheckedTimer, setIsCheckedTimer] = useState(
    les?.timed ? les?.timed : 'default'
  );
  const [estimatedTime, setEstimatedTime] = useState(
    les?.estimatedTime ? les?.estimatedTime : 0
  );
  const [selectTags, setSelectTags] = useState<any>([]);
  const [lesTags, setLesTags] = useState<any>([]);
  const [selectCategories, setSelectCategories] = useState<any>([]);
  const [lesCategories, setLesCategories] = useState<any>([]);
  const [settingAddNumber, setSettetingAddNumber] = useState(addNumber);
  const handleSave = async () => {
    if (les?.lessonId) {
      const actions = [
        axios.put(`${config.backend}/lesson/${les?.lessonId}`, {
          id: les?.id,
          lessonVerified: lessonVerified,
          name: lesTitle,
          timed: isCheckedTimer,
          lessonType: isCheckedType,
          estimatedTime: estimatedTime * 60,
        }),
        axios.put(`${config.backend}/use/${les?.usesId}`, {
          onlyTheory: onlyTheory,
          submitByTeacher: submitByTeacher,
          numbered: settingAddNumber,
        }),
      ];

      if (!lessonVerified && verifiedData) {
        actions.push(
          axios.delete(`${config.backend}/verifiedlesson/${verifiedData?.id}`)
        );
      } else if (lessonVerified && !verifiedData) {
        actions.push(
          axios.post(`${config.backend}/verifiedlessons`, {
            lessonId: les?.lessonId,
            organisationId:
              (authUser?.role || 0) >= 90 &&
              authUser?.activeOrganisationId === 18
                ? null
                : authUser?.activeOrganisationId,
            coursecontentId,
          })
        );
      }

      const [r2, r1, verifiedlessonResult] = await Promise.allSettled(actions);
      if (verifiedlessonResult && verifiedlessonResult.status === 'fulfilled') {
        setVerifiedData(verifiedlessonResult.value.data);
      }
    }

    if (les?.assetId) {
      await Promise.allSettled([
        axios.put(`${config.backend}/asset/${les?.assetId}`, {
          id: les?.id,
          assetName: lesTitle,
          numbered: settingAddNumber,
        }),
        axios.put(`${config.backend}/use/${les?.usesId}`, {
          numbered: settingAddNumber,
        }),
      ]);
    }

    if (!les?.assetId && !les?.lessonId) {
      const actions = [
        axios.put(`${config.backend}/directory/${les?.id}`, {
          id: les?.id,
          name: lesTitle,
          numbered: settingAddNumber,
        }),
      ];

      if (!lessonVerified && verifiedData) {
        actions.push(
          axios.delete(
            `${config.backend}/verifieddirectory/${verifiedData?.id}`
          )
        );
      } else if (lessonVerified && !verifiedData) {
        actions.push(
          axios.post(`${config.backend}/verifieddirectories`, {
            directoryId: les?.id,
            organisationId:
              (authUser?.role || 0) >= 90 &&
              authUser?.activeOrganisationId === 18
                ? null
                : authUser?.activeOrganisationId,
            coursecontentId,
          })
        );
      }

      const [r2, r1, verifieddirectoryResult] = await Promise.allSettled(
        actions
      );
      if (
        verifieddirectoryResult &&
        verifieddirectoryResult.status === 'fulfilled'
      ) {
        setVerifiedData(verifieddirectoryResult.value.data);
      }

      setDirectories((prev: any) =>
        prev?.map((el: any) =>
          el.id === les?.id
            ? {
                ...el,
                name: lesTitle,
                numbered: settingAddNumber,
                directoryVerified: lessonVerified,
              }
            : el
        )
      );
    }

    const promises: any = [];

    if (
      selectTags?.length > 0 &&
      (les?.lessonId || (!les?.assetId && !les?.lessonId))
    ) {
      for (const addObj2 of selectTags) {
        const existingObj = lesTags?.find(
          (obj1: any) => obj1?.id === addObj2?.id
        );
        if (!existingObj) {
          promises.push(
            les?.lessonId
              ? axios.post(`${config.backend}/lessontags`, {
                  lessonId: les?.lessonId,
                  tag: 'tag:' + addObj2?.label,
                  id: addObj2?.id,
                })
              : axios.post(`${config.backend}/directorytags`, {
                  directoryId: les?.id,
                  tag: 'tag:' + addObj2?.label,
                  id: addObj2?.id,
                })
          );
        }
      }
    }

    if (
      selectCategories?.length > 0 &&
      (les?.lessonId || (!les?.assetId && !les?.lessonId))
    ) {
      for (const addObj2 of selectCategories) {
        const existingObj = lesCategories?.find(
          (obj1: any) => obj1?.id === addObj2?.id
        );
        if (!existingObj) {
          les?.lessonId
            ? axios.post(`${config.backend}/lessontags`, {
                lessonId: les?.lessonId,
                tag: 'category:' + addObj2?.label,
                id: addObj2?.id,
              })
            : axios.post(`${config.backend}/directorytags`, {
                directoryId: les?.id,
                tag: 'category:' + addObj2?.label,
                id: addObj2?.id,
              });
        }
      }
    }

    if (
      lesTags?.length > 0 &&
      (les?.lessonId || (!les?.assetId && !les?.lessonId))
    ) {
      for (const removedTag of lesTags) {
        const existingObj = selectTags?.find(
          (obj1: any) => obj1?.id === removedTag?.id
        );
        if (!existingObj) {
          promises.push(
            axios.delete(
              les?.lessonId
                ? `${config.backend}/lessontag/${les?.lessonId}/${removedTag?.id}`
                : `${config.backend}/directorytag/${les?.id}/${removedTag?.id}`
            )
          );
        }
      }
    }

    if (
      lesCategories?.length > 0 &&
      (les?.lessonId || (!les?.assetId && !les?.lessonId))
    ) {
      for (const removedTag of lesCategories) {
        const existingObj = selectCategories?.find(
          (obj1: any) => obj1?.id === removedTag?.id
        );
        if (!existingObj) {
          axios.delete(
            les?.lessonId
              ? `${config.backend}/lessontag/${les?.lessonId}/${removedTag?.id}`
              : `${config.backend}/directorytag/${les?.id}/${removedTag?.id}`
          );
        }
      }
    }
    if (les?.lessonId) {
      setLessons((prev: any) =>
        prev?.map((el: any) =>
          el.id === les?.id
            ? {
                ...el,
                name: lesTitle,
                numbered: settingAddNumber,
                timed: isCheckedTimer,
                lessonType: isCheckedType,
                lessonVerified: lessonVerified,
                estimatedTime: estimatedTime,
                onlyTheory: onlyTheory,
                submitByTeacher: submitByTeacher,
              }
            : el
        )
      );
    }
    if (les?.assetId) {
      setLessons((prev: any) =>
        prev?.map((el: any) =>
          el.id === les?.id
            ? { ...el, assetName: lesTitle, numbered: settingAddNumber }
            : el
        )
      );
    }

    Promise.allSettled(promises).then(() => {
      setNewName(lesTitle);
      setAddNumber(settingAddNumber);
      setIsOpenSettings(false);
      setUpdateLesInfo(newUid(20));
    });
  };

  return (
    <div className="overflow-hidden bg-white">
      <Modal isOpen={loadVersion}>
        <div
          className=""
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Modal.Header onClose={closeModal}>
            {les?.lessonId
              ? t('pages.create_edit_course.lesson_settings')
              : les?.assetId
              ? t('pages.create_edit_course.asset_settings')
              : t('pages.create_edit_course.folder_settings')}
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col lg:flex-row">
              <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
                <Tabs.TabButton name="settings">
                  <p className="text-base font-medium">
                    {t('pages.create_edit_course.settings')}
                  </p>
                </Tabs.TabButton>
                {les?.lessonId && (
                  <Tabs.TabButton name="versions">
                    <p className="text-base font-medium">
                      {t('pages.create_edit_course.versions_title')}
                    </p>
                  </Tabs.TabButton>
                )}
              </Tabs>
            </div>
            <div className="-mx-6 mt-5 max-h-[62vh] overflow-y-scroll px-6">
              {tab === 'settings' && (
                <SettingsLesson
                  les={les}
                  setLesTitle={setLesTitle}
                  lesTitle={lesTitle}
                  setOnlyTheory={setOnlyTheory}
                  onlyTheory={onlyTheory}
                  setLessonVerified={setLessonVerified}
                  lessonVerified={lessonVerified}
                  setIsCheckedType={setIsCheckedType}
                  isCheckedType={isCheckedType}
                  setIsCheckedTimer={setIsCheckedTimer}
                  isCheckedTimer={isCheckedTimer}
                  setEstimatedTime={setEstimatedTime}
                  estimatedTime={estimatedTime}
                  setSelectTags={setSelectTags}
                  selectTags={selectTags}
                  setSelectCategories={setSelectCategories}
                  selectCategories={selectCategories}
                  setLesTags={setLesTags}
                  lesCategories={lesCategories}
                  setLesCategories={setLesCategories}
                  lesTags={lesTags}
                  submitByTeacher={submitByTeacher}
                  setSubmitByTeacher={setSubmitByTeacher}
                  setSettetingAddNumber={setSettetingAddNumber}
                  settingAddNumber={settingAddNumber}
                />
              )}
              {tab === 'versions' && (
                <LessonVersions
                  les={les}
                  onRefresh={async () => {
                    await onRefresh();
                    closeModal();
                  }}
                  setLoadVersion={setLoadVersion}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer
            cancelButtonLabel={t('button.cancel')}
            onCancel={closeModal}
          >
            <div>
              <Button onClick={() => handleSave()}>{t('button.save')}</Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

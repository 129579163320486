import { DataTable } from '@/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button } from '@teo/components';
import { useNavigate, useParams } from 'react-router-dom';
import OptionsAction from './DataTable/OptionsAction';
import i18n from 'i18next';
import { useMediaQuery } from 'react-responsive';

export const PublicationTable = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organisationId } = useParams();

  const basePath = window.location.pathname;

  const isDesktop = useMediaQuery({ query: '(min-width: 640px)' });

  const goRouteId = (url: any) => {
    navigate(url);
  };

  return (
    <DataTable
      screensUpdateTable="mobile"
      key={props?.courseType}
      defaultSort={[
        { id: '-updatedAt', desc: false },
        { id: 'name', desc: false },
      ]}
      columns={[
        {
          title: t('columns.courseName'),
          id: 'name',
          className: 'col-start-1 col-end-9',
          classNameDesktop: 'max-w-[300px] w-[65%]',
          render: (course: any) => (
            <div className="flex items-center">
              <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                <img
                  src={course.image || '/images/courseFallback.png'}
                  alt={course.name}
                  className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                />
              </div>
              {course.name}
            </div>
          ),
        },
        {
          addTitleOnMobile: true,
          className: 'col-start-1 col-end-4 row-start-2',
          id: 'createdAt',
          title: t('columns.created'),
          render: (usr: any) => format(new Date(usr.createdAt), 'dd/MM/yyyy'),
        },
        {
          className: 'col-start-6 col-end-10 row-start-2 justify-end',
          id: 'actionButton',
          // align: 'right',
          sortable: false,
          render: (course: any) => (
            <>
              {isDesktop ? (
                <OptionsAction
                  menuItems={[
                    {
                      addIcon: true,
                      id: '1',
                      title:
                        props?.courseType == 'assessment'
                          ? t('pages.follow_up.go_to_career_assessment')
                          : props?.courseType == 'leervacature'
                          ? t('pages.follow_up.go_to_career_vacancy')
                          : props?.courseType == 'instruction'
                          ? t('pages.follow_up.go_to_career_instruction')
                          : t('pages.follow_up.go_to_career_course'),
                      onClick: (ev) => {
                        navigate(
                          `/${i18n.language}/create/${organisationId}/${
                            props.courseType == 'leervacature'
                              ? 'vacancie'
                              : props.courseType
                          }s/${
                            props.courseType !== 'instruction'
                              ? course?.id
                              : course?.masterId
                          }`
                        );
                      },
                    },
                    // {
                    //   id: '2',
                    //   title: t('pages.follow_up.follow_courses'),
                    // },
                    /*
                {
                  addOnMobile: true,
                  itemDelete: true,
                  id: '3',
                  onClick: async (ev) => {
                    await axios.delete(
                      `${config.backend}/follow/${course?.id}/${usr?.userId}/`
                    );
                    setResetUserTable(newUid(20));
                  },
                  title: t('pages.follow_up.delete_course'),
                },
                */
                  ]}
                  pageDetail={{
                    title:
                      'AA' + props?.courseType == 'assessment'
                        ? t('pages.follow_up.go_to_career_assessment')
                        : props?.courseType == 'leervacature'
                        ? t('pages.follow_up.go_to_career_vacancy')
                        : props?.courseType == 'instruction'
                        ? t('pages.follow_up.go_to_career_instruction')
                        : t('pages.follow_up.go_to_career_course'),
                    rowPath: (course) => `${basePath}/${course.id}`,
                  }}
                  screensUpdateTable="mobile"
                  rowInfo={course}
                  mobileHeader={{
                    render: (course: any) => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                          <img
                            src={
                              course?.courseImage || course?.image || '/images/courseFallback.png'
                            }
                            alt={course.name}
                            className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                          />
                        </div>
                        {course.name}
                      </div>
                    ),
                  }}
                />
              ) : (
                <Button
                  variant="outline"
                  size="sm"
                  className="z-[2] flex items-center"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const rowPath = props?.rowPath?.(course);
                    goRouteId(rowPath);
                    window.scrollTo(0, 0);
                  }}
                >
                  {t('pages.follow_up.to_versions')}
                </Button>
              )}
            </>
          ),
        },
      ]}
      {...props}
    />
  );
};

import { useGet } from '@/query/fetchHooks';
import {
  Learningpathprogressdetails,
  Userwithorganisationwithidps,
} from '@/models';
import { EndUserCard } from '@teo/components';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';

function ListOfCourses({
  props,
  progress,
  lable,
  courses,
  title,
  path,
  setDashboardCourses,
}: any) {
  const { t } = useTranslation();
  const basePath = window.location.pathname;

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const [myCourses, setMyCourses] = useState<any[]>(courses);

  const { data, status } = useGet<Array<Learningpathprogressdetails>>(
    path || '/followedCourses',
    {
      courseStatus: 'active',
      status: 'active',
      offset: '0',
      count: 3,
      userId: authUser?.id,
      ...props,
      enabled: !courses,
      onSuccess: (result: any) => {
        setMyCourses(result?.data);
        setDashboardCourses((prev: any) =>
          prev ? [...prev, ...(result?.data || [])] : [...(result?.data || [])]
        );
      },
    }
  );

  const coursePath = basePath.endsWith('/')
    ? basePath.slice(0, basePath.lastIndexOf('/'))
    : basePath;

  function convertToTime(number: number) {
    const hours = Math.floor(number / 3600);
    const minutes = Math.round((number / 3600 - hours) * 60);
    return (
      hours +
      `${t('pages.learn_index.hour')} ` +
      minutes +
      `${t('pages.learn_index.minute')} `
    );
  }
  return (
    <>
      {myCourses?.length > 0 && (
        <>
          <p className="text-md mt-6 font-semibold text-[#231f20]">{title}</p>
          <div className="mt-3 flex w-full flex-row flex-wrap gap-3 sm:flex-nowrap">
            {myCourses?.map((course: any) => {
              let typeCourse;
              switch (course?.courseType) {
                case 'instruction':
                  typeCourse = 'instructions';
                  break;
                case 'leervacature':
                  typeCourse = 'vacancies';
                  break;
                case 'assessment':
                  typeCourse = 'assessments';
                  break;
                default:
                  typeCourse = 'courses';
                  break;
              }
              return (
                <div
                  key={course.id}
                  className="max-w-[calc(50%_-_6px)] basis-1/2 sm:max-w-none sm:basis-1/3"
                >
                  <EndUserCard
                    chapters={course?.nrOfLessons ? course.nrOfLessons : 0}
                    duration={
                      course?.totalEstimatedTime
                        ? convertToTime(Math.abs(course?.totalEstimatedTime))
                        : ''
                    }
                    imageAlt="Course preview image"
                    imageSrc={
                      course?.courseImage ||
                      course?.image ||
                      '/assets/images/opleidingen.jpg'
                    }
                    lable={lable}
                    progress={
                      progress
                        ? course.progress
                          ? Math.round(course.progress * 100)
                          : 0
                        : 0
                    }
                    showDetails
                    showProgress={progress ? progress : false}
                    subtitle={`${
                      course?.courseEndDate
                        ? `${t('pages.learn_index.last_open')}` +
                          format(new Date(course?.courseEndDate), 'dd/MM')
                        : ''
                    }`}
                    title={course?.courseName || course?.name || ''}
                    to={`${coursePath}/${typeCourse}/${course?.courseId}`}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default ListOfCourses;

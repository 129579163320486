import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';
import { newUid } from '@/util';
import { useGet } from '@/query/fetchHooks';

const DeleteTeamModal = ({
  closeModal,
  setIsOpenDeleteModal,
  title,
  heading,
  text,
  selectedItem,
  selectedTeam,
  setSelectedTeam,
  setRefetchTable,
}: any) => {
  const { t } = useTranslation();
  const [teams, setTeams] = useState<any>([]);
  const [teamsSelected, setTeamsSelected] = useState<any>([]);
  const [subTeams, setSubTeams] = useState<any>([]);
  const [isDelete, setIsDelete] = useState<any>(false);
  const { organisationId } = useParams();

  useGet(`/teams`, {
    organisationId,
    // searchPath: `${selectedItem?.teamPath}%`,
    onSuccess: (result: any) => {
      setTeams(result?.data || null);
    },
  });

  useEffect(() => {
    if (teams?.length > 0 && selectedItem) {
      setSubTeams(
        teams?.filter(
          (el: any) =>
            el?.teamPath &&
            el?.teamPath?.includes(selectedItem?.teamPath + ';;')
        )
      );
    }
    if (teams?.length > 0 && !selectedItem) {
      [...selectedTeam]?.map((team: any) => {
        setTeamsSelected((prev: any) => [
          ...prev,
          teams?.find((el: any) => team === el.id),
        ]);
      });
    }
  }, [teams]);

  useEffect(() => {
    if (teamsSelected?.length > 0 && !selectedItem && teams) {
      setSubTeams((prev: any) => [...prev, ...teamsSelected]);
      teamsSelected?.forEach((team: any) => {
        if (teams?.length > 0) {
          setSubTeams((prev: any) => [
            ...prev,
            ...teams.filter(
              (el: any) =>
                el?.teamPath.includes(team?.teamPath + ';;') &&
                prev?.every((obj: any) => obj.id !== el?.id)
            ),
          ]);
        }
      });
    }
  }, [teamsSelected]);

  useEffect(() => {
    if (subTeams?.length < 1 && isDelete) {
      if (selectedItem) {
        const deleteSelected = new Set(selectedTeam);
        if (selectedTeam) {
          deleteSelected.delete(selectedItem?.id);
          setSelectedTeam(deleteSelected);
        }
        axios
          .delete(
            `${config.backend}/team/${organisationId}/${selectedItem?.id}`
          )
          .then(() => {
            setRefetchTable(newUid(20));
            setIsOpenDeleteModal(false);
          });
      } else {
        setRefetchTable(newUid(20));
        setIsOpenDeleteModal(false);
      }
    }
  }, [isDelete, subTeams]);

  const handleDeleteTeam = async () => {
    const promises = [];
    if (subTeams?.length > 0) {
      const deleteSelected = new Set(selectedTeam);
      for (const team of subTeams) {
        if (selectedTeam) {
          deleteSelected.delete(team?.id);
          setSelectedTeam(deleteSelected);
        }
        promises.push(
          axios
            .delete(`${config.backend}/team/${organisationId}/${team?.id}`)
            .then(() =>
              setSubTeams((prev: any) =>
                prev?.filter((sTeam: any) => sTeam?.id != team?.id)
              )
            )
        );
      }
    }
    Promise.allSettled(promises).then(() => {
      setIsDelete(true);
    });
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-lg  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            <h2 className="text-sm font-semibold">{title}</h2>
          </Modal.Header>

          <Modal.Body>
            <div className="flex flex-col gap-5">
              <div>
                <h3 className="mb-3 text-lg">{heading}</h3>
                <p className="text-base text-grey-06">{text}</p>
              </div>
              <div className="flex justify-between">
                <Button
                  variant="ghost"
                  onClick={() => setIsOpenDeleteModal(false)}
                >
                  {t('button.cancel')}
                </Button>
                <Button variant="destruct" onClick={() => handleDeleteTeam()}>
                  {t('button.delete')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </div>
  );
};

export default DeleteTeamModal;

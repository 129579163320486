import React, { Component, useEffect, useState } from 'react';
import { string, number } from 'prop-types';
import { T } from '@/components/T';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import './FaqSearchField.scss';
import { clsx } from 'clsx';
import i18n from 'i18next';
import { parseFilter } from '@/util';
import { useGet } from '@/query/fetchHooks';
import { InputText, Button, Panel, IconButton } from '@teo/components';
import { Search, ChevronUp, ChevronDown } from '@teo/components/icons';
import { useNavigate, useMatches, useParams } from 'react-router-dom';
import { FaqList } from './FaqList';

const FaqTopic = ({ topic, limit }) => {
  const [faqs, setFaqs] = useState([]);
  const [collapsed, setCollapsed] = useState(true);

  const { refetch } = useGet(`/faqs`, {
    enabled: !collapsed,
    lang: i18n.language,
    topic,
    limit,
    onSuccess: (results) => {
      setFaqs(results.data);
    },
  });

  return (
    <Panel className="flex flex-col break-words">
      <h3
        className="flex cursor-pointer flex-row items-center justify-between p-4"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <T>{`pages.faq.topic.${topic}`}</T>
        <IconButton
          Icon={!collapsed ? ChevronUp : ChevronDown}
          variant="ghost"
        />
      </h3>
      {!collapsed && <FaqList faqs={faqs} />}
    </Panel>
  );
};

export { FaqTopic };

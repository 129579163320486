import { useState, useEffect, useRef } from 'react';
import { newUid, deep_copy } from '@/util';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { uploadImage } from '@/query/documents';
import {
  Switch,
  InputScore,
  Radio,
  Checkbox,
  Button,
  IconButton,
  Spinner,
} from '@teo/components';
import { Image, Cross, Plus } from '@teo/components/icons';
import { QuestionSection } from '../QuestionSection';
import ChatGPTWidget from './AIWidget/MultipleChoiceAI/GPTWidget';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';
import { TextItemEdit } from './TextItemEdit';

export const initialMeerkeuze = () => ({
  score: 1,
  vraag: '',
  type: 'MeerKeuze',
  antwoorden: [
    { text: '', isCorrect: true },
    { text: '', isCorrect: false },
  ],
});

const ItemChoice = ({
  item,
  index,
  setAntwoorden,
  oneAnswer,
  scorePerAnswer,
  uidState,
}) => {
  const [maginify, setMagnify] = useState(false);
  const [text, setText] = useState(item?.text);
  const [loadedImage, setLoadedImage] = useState(false);

  const fileInputRef = useRef(null);
  let CheckBoxType = oneAnswer ? Radio : Checkbox;

  const handleChangeText = (value) => {
    setText(value);
    setAntwoorden((prev) =>
      prev.map((el, i) => (i == index ? { ...el, text: value } : el))
    );
  };
  const handleScoreAnswer = (value) => {
    setAntwoorden((prev) =>
      prev.map((el, i) => (i == index ? { ...el, score: value } : el))
    );
  };
  const handleUploadPhoto = async (event) => {
    setLoadedImage(true);
    const img = event.target.files[0];
    const data = await uploadImage(img, 900, 900, 'jpeg');

    setAntwoorden((prev) =>
      prev.map((el, i) => (i == index ? { ...el, file: data } : el))
    );
  };

  const handleDeleteItem = (index) => {
    setAntwoorden((prev) =>
      prev?.filter((el, i) => {
        return i !== index;
      })
    );
  };
  const handleDeleteImg = () => {
    setLoadedImage(false);
    setAntwoorden((prev) =>
      prev.map((el, i) => (i == index ? { ...el, file: null } : el))
    );
  };
  const handleChecked = (isCheck) => {
    oneAnswer
      ? setAntwoorden((prev) =>
          prev.map((el, i) =>
            i == index
              ? { ...el, isCorrect: isCheck }
              : { ...el, isCorrect: false }
          )
        )
      : setAntwoorden((prev) =>
          prev.map((el, i) => (i == index ? { ...el, isCorrect: isCheck } : el))
        );
  };

  const handleOpenImage = () => {
    setMagnify(!maginify);
    const editorElement = document.querySelector('.fr-editor-block');
    const editorToolbar = document.querySelector('.fr-toolbar');

    if (editorElement) {
      editorElement.style.setProperty('z-index', '11', 'important');
    }
    if (editorElement) {
      editorToolbar.style.setProperty('z-index', '0', 'important');
      editorToolbar.style.setProperty('opacity', '0', 'important');
    }
  };
  const handleCloseImage = () => {
    setMagnify(!maginify);

    const editorElement = document.querySelector('.fr-editor-block');
    const editorToolbar = document.querySelector('.fr-toolbar');
    if (editorElement) {
      editorElement.style.setProperty('z-index', '0', 'important');
    }
    if (editorElement) {
      editorToolbar.style.setProperty('z-index', '5', 'important');
      editorToolbar.style.setProperty('opacity', '1', 'important');
    }
  };

  return (
    <div
      className={`fr-draggable flex w-full flex-row items-center gap-3 rounded-lg border border-grey-02 bg-white px-4 ${
        item.file ? 'py-1' : 'py-3'
      }`}
    >
      <label className="flex cursor-pointer justify-center">
        <CheckBoxType
          className="m-auto"
          onChange={(isCheck) => handleChecked(isCheck)}
          key={index}
          checked={item.isCorrect}
        />
      </label>
      <div className="w-full">
        {/* <input
          id={'uid_' + uidState + '_' + index}
          type="text"
          value={text}
          onChange={({ target: { value } }) => {
            handleChangeText(value);
          }}
          className="w-full border-0 border-b border-transparent p-1 text-sm !shadow-none !ring-0 focus:border-0 focus:border-b focus:border-grey-02"
        /> */}
        <TextItemEdit
          id={'uid_' + uidState + '_' + index}
          text={text}
          handleChangeText={handleChangeText}
        />
      </div>

      {item.file ? (
        <div className="relative flex flex-row items-center gap-2 rounded border border-grey-02 bg-grey-02 p-1">
          <Button
            size="sm"
            className="!px-1"
            onClick={() => handleDeleteImg()}
            variant="ghost"
          >
            <img src="/images/icons/trash_icon.svg" alt="delete button" />
          </Button>
          <Button
            variant="ghost"
            className="!bg-white !p-0"
            onClick={() => handleOpenImage()}
          >
            <img
              alt={item.text}
              src={item.file}
              className="h-14 w-14 rounded border border-tint-indigo object-cover object-center"
            />
          </Button>
          {maginify && (
            <div
              className="fixed inset-0 z-10 flex bg-[#000000cc]"
              onClick={() => handleCloseImage()}
            >
              <img
                alt={item.text}
                src={item.file}
                className="m-auto h-3/4 w-3/4 object-contain object-center sm:h-1/2 sm:w-1/2"
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {loadedImage ? (
            <div className="relative flex flex-row items-center gap-2 rounded border border-grey-02 bg-grey-02 p-1">
              <div className="flex h-11 w-11">
                <Spinner
                  ariaLabel="Loading spinner"
                  className="m-auto h-6 w-6 border-grey-05"
                />
              </div>
            </div>
          ) : (
            <IconButton
              Icon={Image}
              ariaLabel="add image"
              onClick={() => {
                fileInputRef.current?.click();
              }}
              variant="ghost"
              className="!text-grey-07"
            />
          )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleUploadPhoto}
            accept="image/*"
            style={{ display: 'none' }}
            multiple={false}
          />
        </>
      )}
      {scorePerAnswer && (
        <div>
          <InputScore
            value={item?.score ? item?.score : 0}
            onChange={(value) => handleScoreAnswer(value)}
          />
        </div>
      )}
      <IconButton
        Icon={Cross}
        ariaLabel="delete order"
        onClick={() => handleDeleteItem(index)}
        variant="ghost"
        className="!text-grey-07"
      />
    </div>
  );
};

export const MultipleChoiceEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );

  // const [questionRef, setQuestionRef] = useState(null);
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );

  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );
  // const [answerRef, setAnswerRef] = useState(null);
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : false
  );
  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget
      ? state?.titleWidget
      : t('widgets.type.multiple_choice_question')
  );
  const [score, setScore] = useState(state.score || 0);
  const [oneAnswer, setOneAnswer] = useState(state?.oneAnswer);
  const [scorePerAnswer, setScorePerAnswer] = useState(state?.scorePerAnswer);
  const [antwoorden, setAntwoorden] = useState(state.antwoorden || []);
  const [randomOrder, setRandomOrder] = useState(
    state.randomOrder !== undefined ? state.randomOrder : true
  );

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoadingAI, setIsLoadingAI] = useState(false);
  const [isLoadingAIAnswer, setIsLoadingAIAnswer] = useState(false);
  const [competencies, setCompetencies] = useState([]);

  useEffect(() => {
    let skipped = 0;
    let newAntwoorden = deep_copy(antwoorden);
    if (oneAnswer) {
      for (let antwoord of newAntwoorden) {
        if (antwoord.isCorrect) {
          if (skipped === 0) {
            skipped++;
            continue;
          } else {
            antwoord.isCorrect = false;
          }
        }
      }
    }
    setAntwoorden(newAntwoorden);
  }, [oneAnswer]);

  // generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.score = score;
    newState.oneAnswer = oneAnswer;
    newState.scorePerAnswer = scorePerAnswer;
    newState.antwoorden = antwoorden;

    //antwoorden is only updated onBlur, which is not triggered when you press save while in a option field
    for (let i in newState.antwoorden) {
      newState.antwoorden[i].text =
        document.getElementById('uid_' + state.uid + '_' + i)?.innerHTML ||
        newState.antwoorden[i].text;
    }

    newState.randomOrder = randomOrder;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;

    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  let CheckBoxType = oneAnswer ? Radio : Checkbox;

  const handleAddItem = () => {
    const newItem = { text: '', file: null, isCorrect: false };
    setAntwoorden((prev) => [...prev, newItem]);
    setTimeout(() => {
      let placeholder = document.getElementById(state.uid);
      const labelElement = placeholder.querySelector(
        `#uid_${state.uid}_${antwoorden?.length}`
      );
      if (labelElement) {
        labelElement.click();
      }
    }, 0);
  };
  useEffect(() => {
    if (scorePerAnswer) {
      if (oneAnswer) {
        setScore(
          antwoorden.reduce(
            (max, item) => (item.score > max ? item.score : max),
            -Infinity
          )
        );
      } else {
        setScore(antwoorden.reduce((acc, item) => acc + (item.score || 0), 0));
      }
    } else {
      setScore(state.score);
    }
  }, [scorePerAnswer, antwoorden, oneAnswer]);

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state?.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />

      {isLoadingAI ? (
        <div className="spinner-chat mt-3">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      ) : (
        <>
          <div>
            <div className="wg-question">
              <QuestionSection questionVraag={questionVraag} />
            </div>
            <div className="my-4 flex gap-3">
              <Switch
                checked={showAnswer}
                onChange={(isCheck) => setShowAnswer(isCheck)}
              />
              <div className="mr-4">
                {t('widgets.widgets_edit.explain_after_submit')}
              </div>
            </div>
            <div className={`${!showAnswer ? 'hidden' : ''}`}>
              <div className="wg-answer">
                <QuestionSection
                  questionVraag={antwoordVraag}
                  exampleAnswer={true}
                />
              </div>
            </div>
          </div>

          <div key={antwoorden.length} className="flex flex-col gap-3">
            {antwoorden?.map((item, index) => (
              <ItemChoice
                key={index}
                item={item}
                index={index}
                setAntwoorden={setAntwoorden}
                oneAnswer={oneAnswer}
                scorePerAnswer={scorePerAnswer}
                uidState={state.uid}
              />
            ))}

            {isLoadingAIAnswer ? (
              <div className="spinner-chat mt-3">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            ) : (
              <div
                className="flex w-full cursor-pointer flex-row items-center gap-3 rounded-lg border border-grey-02 bg-white px-4 py-2.5"
                onClick={() => handleAddItem()}
              >
                <Plus className="w-8 text-grey-06" />
                <div className="mr-auto text-sm text-grey-06">
                  {t('widgets.widgets_edit.add_option')}
                </div>
              </div>
            )}
          </div>
          <div
            contentEditable={false}
            ref={(el) => {
              if (el) {
                el.style.setProperty('display', 'none', 'important');
              }
            }}
            id={`${state?.uid}_gpt_open`}
            onClick={() => {
              setIsChatOpen(!isChatOpen);
            }}
          ></div>
        </>
      )}
      {isChatOpen && (
        <ChatGPTWidget
          setQuestionVraag={setQuestionVraag}
          setAntwoorden={setAntwoorden}
          antwoorden={antwoorden}
          setIsLoadingAI={setIsLoadingAI}
          isLoadingAI={isLoadingAI}
          setIsLoadingAIAnswer={setIsLoadingAIAnswer}
          isLoadingAIAnswer={isLoadingAIAnswer}
          setIsChatOpen={setIsChatOpen}
          oneAnswer={oneAnswer}
          stateUid={state?.uid}
          questionVraag={questionVraag}
          state={state}
        />
      )}

      <div>
        <div className="mt-4 flex flex-col border-t border-b border-grey-02 py-4 sm:py-2">
          <div className="flex flex-row flex-wrap items-center lg:flex-nowrap">
            <div className="flex flex-row items-center">
              <Switch
                checked={oneAnswer}
                onChange={(isCheck) => {
                  setOneAnswer(isCheck);
                }}
              />
              <div className="mr-4 border-r-2 border-grey-02 px-4">
                {t('widgets.widgets_edit.one_answer')}
              </div>
            </div>
            <div className="mt-5 flex w-full flex-row items-center sm:mt-0 sm:w-auto">
              <Switch
                checked={scorePerAnswer}
                onChange={(isCheck) => setScorePerAnswer(isCheck)}
              />
              <div className="mr-4 border-r-2 border-grey-02 px-4">
                {t('widgets.widgets_edit.score_answer')}
              </div>
            </div>

            <div className="mt-5 flex w-full flex-row items-center lg:mt-0 lg:w-auto">
              <div className="mr-2">{t('widgets.widgets_edit.max_score')}</div>
              <InputScore
                value={score}
                onChange={(value) => {
                  !scorePerAnswer && setScore(value);
                }}
                disabled
              />
            </div>
          </div>
          <div className="mt-4 flex flex-row items-center border-t border-grey-02 pt-4 sm:mt-2 sm:pt-2">
            <Switch
              checked={randomOrder}
              onChange={(isCheck) => {
                setRandomOrder(isCheck);
              }}
            />
            <div className="mr-4 px-4">{t('widgets.edit.order_message')}</div>
          </div>
        </div>
        {/*
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>
      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

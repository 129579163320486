import { useState, useEffect } from 'react';
import { Button, IconButton } from '@teo/components';
import { useTranslation } from 'react-i18next';
import { CrossFilled } from '@teo/components/icons';

const OnboardingStudentText = ({ title, text }: any) => {
  const { t } = useTranslation();

  const formattedString = text.replace(/\/n/g, '<br>');
  return (
    <div className="m-auto flex min-h-[185px] flex-col text-center sm:m-0 sm:text-left">
      <h2 className="pb-7">{title}</h2>
      <p
        className="mb-5 text-grey-07 sm:mb-0"
        dangerouslySetInnerHTML={{ __html: formattedString }}
      />
    </div>
  );
};

export { OnboardingStudentText };

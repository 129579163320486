import { useState, useContext } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { T } from '@/components/T';
import { Tabs } from '@teo/components';
import { AuthContext } from '@/components/Contexts/AuthContext';
import SuppottTab from './SupportTab';
import OverviewsTab from './OverviewsTab';
import HelpDeskTab from './HelpDeskTab';
import LicenseTab from './LicenseTab';

function OrganisationLicensingAndBillingPage() {
  const { t } = useTranslation();
  const [tab, setTab] = useState('license');
  const { auth, setAuth } = useContext(AuthContext);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{t('pages.organisation_licensing_and_billing.title')}</T>
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div>
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.TabButton name="license">
              <p className="text-base font-medium">
                {t(
                  'pages.organisation_licensing_and_billing.license.tab_title'
                )}
              </p>
            </Tabs.TabButton>
            <Tabs.TabButton name="support">
              <p className="text-base font-medium">
                {t(
                  'pages.organisation_licensing_and_billing.support.tab_title'
                )}
              </p>
            </Tabs.TabButton>
          </Tabs>
        </div>
        <div className="mt-4 lg:ml-3 lg:mt-0">
          <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.TabButton name="overviews">
              <p className="text-base font-medium">
                {' '}
                {t('pages.organisation_licensing_and_billing.overviews_tab')}
              </p>
            </Tabs.TabButton>
            <Tabs.TabButton name="help-desk">
              <p className="text-base font-medium">
                {' '}
                {t('pages.organisation_licensing_and_billing.help_desk_tab')}
              </p>
            </Tabs.TabButton>
          </Tabs>
        </div>
      </div>
      {tab === 'license' && <LicenseTab auth={auth} setAuth={setAuth} />}
      {tab === 'support' && <SuppottTab auth={auth} />}
      {tab === 'overviews' && <OverviewsTab auth={auth} />}
      {tab === 'help-desk' && <HelpDeskTab auth={auth} />}
    </div>
  );
}

export default OrganisationLicensingAndBillingPage;

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@teo/components';
import Map from './Map';
const Step2 = ({ step, setStep, setInfo, userInfo, info, courseInfo }: any) => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [location, setLocation] = useState<any>('');
  const [locationRange, setLocationRange] = useState<number>(15);

  useEffect(() => {
    const googleScript = document.createElement('script');
    googleScript.type = 'text/javascript';
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBXCsHIDS0RXugO_KyoWUvE0ThKGBpAxUs&libraries=places`;
    window.document.body.appendChild(googleScript);

    googleScript.addEventListener('load', () => {
      setloading(true);
      setLocationRange(userInfo?.locationRange ? userInfo?.locationRange : 15);
    });
  }, []);

  const nextButton = () => {
    const nextStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_regime_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_referral_step')
      ? 2
      : courseInfo?.onboardingSteps?.includes('onboarding_diploma_step')
      ? 3
      : courseInfo?.onboardingSteps?.includes('onboarding_current_work_step')
      ? 4
      : 5;
    setStep((prev: number) => prev + nextStep);
    setInfo((prev: any) => ({ ...prev, location, locationRange }));
  };
  const prevButton = () => {
    setStep((prev: number) => prev - 1);
  };

  if (step !== 2) {
    return null;
  }

  return (
    <>
      <div>
        <p>{t(`pages.onboarding.step_2.subtitle`)}</p>
      </div>
      {loading && (
        <Map
          setLocation={setLocation}
          setLocationRange={setLocationRange}
          locationRange={locationRange}
          userInfo={userInfo}
          info={info}
        />
      )}
      <div className="flex w-full pt-10">
        <Button
          variant="outline"
          className={`mr-2 flex-[1_1_30%]`}
          onClick={prevButton}
        >
          {t(`pages.onboarding.button_prev`)}
        </Button>

        <Button
          className={`${step !== 1 && 'ml-2 flex-[1_1_30%]'}`}
          onClick={() => step < 7 && nextButton()}
        >
          {t(`pages.onboarding.button_next`)}
        </Button>
      </div>
    </>
  );
};
export default Step2;

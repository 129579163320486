import React, { Component, useEffect, useState } from 'react';
import { string, number } from 'prop-types';
import { T } from '@/components/T';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import './FaqSearchField.scss';
import { clsx } from 'clsx';
import i18n from 'i18next';
import { parseFilter } from '@/util';
import { useGet } from '@/query/fetchHooks';
import { InputText, Button, Panel } from '@teo/components';
import { Search } from '@teo/components/icons';
import { useNavigate, useMatches, useParams } from 'react-router-dom';

const FaqList = ({ faqs, setSelectedId }) => {
  const navigate = useNavigate();

  const renderItem = (item, i) => {
    const answerParagrahps =
      item.answer && item.answer.match(/^.*?<p>(.*?)<\/p>/);
    const parsedAnswer = Array.isArray(answerParagrahps)
      ? answerParagrahps[1]
      : null;

    return (
      <li
        key={item.id}
        className="mt-1 cursor-pointer"
        onClick={() => {
          navigate(
            `${i18n?.language ? '/' + i18n.language : ''}/help/faqs/` + item.id
          );
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 0);
        }}
      >
        <div className="search-result-list-item-question">{item.question}</div>
      </li>
    );
  };

  return (
    <>
      {faqs.length > 0 && (
        <ol className="mx-8 my-0 mb-4 flex flex-col p-0">
          {faqs.map(renderItem)}
        </ol>
      )}
    </>
  );
};

export { FaqList };

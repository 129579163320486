import { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { isEmpty, newUid, round2, focusWidget } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { TargetBoxCoures } from '@/components/UploadDropzoneSection/TargetBoxCoures';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { uploadImage, uploadFile as uploadFileFn } from '@/query/documents';
import { CameraModal } from '@/components/CameraModal/CamereModal';
import { VideoCameraModal } from '@/components/CameraModal/VideoCameraModal';
import { encode } from '@/base64';
import { ViewUplaodElement } from '@/components/ViewUplaodElement';
import { FeedbackSection } from '../FeedbackSection';
import { ExplanationSection } from '../ExplanationSection';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { genericXapiSubmit } from './common';

export const UploadView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setXApiSubmitFn = undefined,
  setCorrectionFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
  widgetAnswered,
}) => {
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState([]);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [isOpenPhotoModal, setIsOpenPhotoModal] = useState(false);
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const { setShowNotification } = useContext(NotificationContext);
  const [urls, setUrls] = useState(answer?.urls || []);
  const [uploadPromises, setUploadPromises] = useState([]);
  const [modified, setModified] = useState(null);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [score, setScore] = useState(correction?.score);
  const answered = submitted || isCorrected;
  const [answeredTeacher, setAnsweredTeacher] = useState(answer?.answered);

  answer ||= {};
  correction ||= {};

  UploadView.syncStates(state, answer, correction);

  useEffect(() => {
    if (submitted) {
      let answer = getAnswer();
      if (!answeredTeacher) {
        let correction = {};
        UploadView.syncStates(state, answer, correction);
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  //generate the answer
  const getAnswer = async () => {
    await Promise.allSettled(uploadPromises);
    answer.urls = urls;
    answer.incomplete = !submitted && urls?.length < 1;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  //generate the correction
  const getCorrection = async () => {
    let correction = {};
    UploadView.syncStates(state, await getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = async (lessonId, lang, minScore = 0.5) => {
    let correction = {};
    let answer = await getAnswer();
    UploadView.syncStates(state, answer, correction);
    let answerStr = t('widgets.xapi.answer_unavailable');
    let correctStr = t('widgets.xapi.correction_unavailable');
    return genericXapiSubmit(
      state,
      answer,
      correction,
      answerStr,
      correctStr,
      lessonId,
      lang,
      minScore
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  const handleUpload = (file) => {
    let uploadPromise = new Promise((resolve, reject) => {
      uploadFileFn('upload/', file)
        .then((result) => {
          return resolve({
            url: result,
            type: file.type,
          });
        })
        .catch((e) => {
          setShowNotification(t('notifications.file_submit_failed'));
          return reject(e);
        });
    });
    setUploadPromises([...uploadPromises, uploadPromise]);
    return uploadPromise;
  };

  const handleFileDrop = async (item) => {
    Promise.allSettled(item.files.map((file) => handleUpload(file))).then(
      (results) => {
        const newUrls = results
          .filter((result) => result.status === 'fulfilled')
          .map((result) => result.value);
        setUrls([...urls, ...newUrls]);
        setModified(newUid(20));
      }
    );
  };

  const handleUploadPhoto = async (event) => {
    Promise.allSettled(
      [...event.target.files].map((file) => handleUpload(file))
    ).then((results) => {
      const newUrls = results
        .filter((result) => result.status === 'fulfilled')
        .map((result) => result.value);
      setUrls([...urls, ...newUrls]);
      setModified(newUid(20));
    });
  };

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      onFocus={() => focusWidget(state?.uid)}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(score)}
          maxScore={state.score}
          answered={(answered && !submitted) || answer?.answered}
          titleWidget={state.titleWidget || t('widgets.type.upload')}
        />
      )}
      <FroalaTextareaView value={state.vraag} />
      {answered ? (
        <div className="flex flex-col flex-wrap gap-3 sm:flex-row">
          {answer
            ? urls.map((el, i) => {
                const type_txt = el?.name
                  ?.substr(el.name.lastIndexOf('.') + 1)
                  .toLowerCase();
                return (
                  <ViewUplaodElement
                    key={i}
                    answered={answered}
                    el={el}
                    type_txt={type_txt}
                  />
                );
              })
            : null}
        </div>
      ) : (
        <>
          <div>
            <div className="flex flex-wrap gap-3 p-1">
              {answer
                ? urls.map((el, i) => {
                    const type_txt = el?.name
                      ?.substr(el.name.lastIndexOf('.') + 1)
                      .toLowerCase();
                    return (
                      <ViewUplaodElement
                        key={i}
                        answered={answered}
                        el={el}
                        type_txt={type_txt}
                        onDelete={() => {
                          const newUrls = urls.slice();
                          newUrls.splice(i, 1);
                          setUrls(newUrls);
                          setModified(newUid(20));
                        }}
                      />
                    );
                  })
                : null}
              {uploadFile.map((el, index) => {
                const type_txt = el?.name
                  ?.substr(el.name.lastIndexOf('.') + 1)
                  .toLowerCase();
                return (
                  <ViewUplaodElement
                    key={index}
                    answered={answered}
                    el={el}
                    type_txt={type_txt}
                    setUploadFile={setUploadFile}
                    uploadFile={uploadFile}
                  />
                );
              })}
            </div>

            {(!state?.nrOfFiles || urls.length < state?.nrOfFiles) && (
              <div id="uploadDropzone" className="mt-3">
                <TargetBoxCoures
                  onDrop={handleFileDrop}
                  fileInputRef={fileInputRef}
                  onChange={handleUploadPhoto}
                  setIsOpenPhotoModal={setIsOpenPhotoModal}
                  isOpenPhotoModal={isOpenPhotoModal}
                  setIsOpenVideoModal={setIsOpenVideoModal}
                  isOpenVideoModal={isOpenVideoModal}
                  state={state}
                />
              </div>
            )}
          </div>
          {isOpenPhotoModal && (
            <CameraModal
              setIsOpenPhotoModal={setIsOpenPhotoModal}
              isOpenPhotoModal={isOpenPhotoModal}
              setUploadFile={async (resultFn) => {
                const [blob] = resultFn([]);
                const url = await handleUpload(blob);
                setUrls([...urls, url]);
                setModified(newUid(20));
              }}
            />
          )}
          {isOpenVideoModal && (
            <VideoCameraModal
              setIsOpenVideoModal={setIsOpenVideoModal}
              isOpenVideoModal={isOpenVideoModal}
              setUploadFile={async (resultFn) => {
                let result = resultFn([]);
                let file = new File(
                  result.map((x) => x.blob),
                  `camera.${result[0].mimeType}`,
                  {
                    type:
                      result[0].mimeType === 'mp4'
                        ? 'video/mp4;codecs:h264'
                        : 'video/webm;codecs=vp9',
                  }
                );
                const url = await handleUpload(file);
                setUrls([...urls, url]);
                setModified(newUid(20));
              }}
            />
          )}
        </>
      )}
      {(state.immediateFeedback || correctionType === 'autofeedback') &&
        !widgetAnswered && (
          <PartialSubmitSection
            setSubmitted={setSubmitted}
            setModified={setModified}
            answered={answered}
            onSave={onSave.bind(null, false, false, state)}
          />
        )}
      {((widgetAnswered &&
        (correctionType === 'immediate' ||
          correctionType === 'autofeedback' ||
          correctionType === 'auto') &&
        !answered) ||
        (submitted && answered && !answer?.answered)) && (
        <div className="mt-4 text-sm font-normal text-[#4d4d50]">
          {t('widgets.type.teacher_needs_correct')}
        </div>
      )}
      {(resultPages || showAnswers || answered || submitted) &&
      state?.antwoord ? (
        <ExplanationSection state={state} />
      ) : null}
    </div>
  );
};

//this function should work as a correction/score function
UploadView.syncStates = (state, answer, correction) => {
  if (isEmpty(answer)) {
    answer.uid = state.uid;
    answer.urls = [];
    answer.connections = [];
  }
  if (isEmpty(correction)) {
    correction.uid = state.uid;
    correction.score = state?.score;
  }
};

import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Button } from '@teo/components';
import { useTranslation } from 'react-i18next';

export const TargetBox = ({
  onDrop,
  fileInputRef,
  handleUploadPhoto,
  courseImg,
  bannerLPImg,
  setIsOpenLibraryModal,
}: any) => {
  const { t } = useTranslation();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item: any) {
        // console.log('canDrop', item.files, item.items);
        return true;
      },
      hover(item: any) {
        // console.log('hover', item.files, item.items);
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;
        if (item) {
          // console.log('collect', item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onDrop]
  );

  const isActive = canDrop && isOver;
  return (
    <div
      ref={drop}
      className="
                flex
                flex-col
                items-center
                rounded-lg
                border
                border-dashed
                border-[#eaeaed]
                px-3 py-8
            "
    >
      <div className="">
        <Button
          variant="outline"
          className="mr-2"
          onClick={() => setIsOpenLibraryModal(true)}
        >
          {t('pages.upload_dropzone_section.upload_from_library')}
        </Button>
        <Button
          variant="primary"
          size="md"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          {courseImg || bannerLPImg
            ? t('pages.upload_dropzone_section.upload_button_img')
            : t('pages.upload_dropzone_section.upload_button')}
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadPhoto}
          accept="image/jpg,.png,"
          style={{ display: 'none' }}
          multiple={false}
        />
      </div>

      {isActive ? (
        <div className="mt-6 text-sm font-medium text-[#6d6d72]">
          {t('pages.upload_dropzone_section.drag_text_2')}
        </div>
      ) : (
        <div className="mt-6 text-sm font-medium text-[#6d6d72]">
          {courseImg || bannerLPImg
            ? t('pages.upload_dropzone_section.drag_text_image')
            : t('pages.upload_dropzone_section.drag_text_1')}
        </div>
      )}
    </div>
  );
};

export const userRoles = [
  {
    value: 10,
    label: 'role.CANDIDATE',
  },
  {
    value: 20,
    label: 'role.STUDENT',
  },
  {
    value: 40,
    label: 'role.TEAMLEAD',
  },
  {
    value: 45,
    label: 'role.CONTENT_CREATOR',
  },
  {
    value: 70,
    label: 'role.TEACHER',
  },
  {
    value: 90,
    label: 'role.ADMIN',
  },
  {
    value: 95,
    label: 'role.SUPER_ADMIN',
  },
];

import React, { Component, useEffect, useState, useContext } from 'react';
import { T } from '@/components/T';
import i18n from 'i18next';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { Eye, Trash } from '@teo/components/icons';
import {
  Panel,
  IconButton,
  InputSelect,
  InputText,
  Label,
  Button,
} from '@teo/components';
import FroalaTextAreaEdit from '@/components/Froala/FroalaTextAreaEdit';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { useStateAndRef } from '@/util';
import { config } from '@/config';
import { axios } from '@/axios';
import { ConfirmModal } from '@/components/ConfirmModal';
import { languages } from '@/constants/languages';
import { useParams, useNavigate } from 'react-router-dom';
import { faqTopics } from '@/constants/faqTopics';
import { useGet } from '@/query/fetchHooks';

const FaqsDetailsEdit = () => {
  const { setShowNotification } = useContext(NotificationContext);
  const [ref, setRef, refRef] = useStateAndRef(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const t = i18n.t;
  const [selected, setSelected] = useState(null);
  const { topic, faqId } = useParams();

  const { refetch } = useGet(`/faq/${faqId}`, {
    enabled: faqId !== 'new',
    onSuccess: (results) => {
      setSelected(results.data);
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!faqId || faqId === 'new') {
      setSelected({
        lang: i18n.language,
        answer: '',
        question: '',
        topic: 'general',
      });
    }
  }, [faqId]);

  const save = async () => {
    const content = await refRef.current.getContent();
    let saveContent = true;
    if (content === selected.answer) {
      saveContent = false;
    }
    const saveFn = selected.id
      ? axios.put.bind(this, `${config.backend}/faq/${selected.id}`)
      : axios.post.bind(this, `${config.backend}/faqs`);
    await saveFn({
      answer: saveContent ? content : undefined,
      lang: selected.lang,
      question: selected.question,
      topic: selected.topic,
    }).catch((e) => {
      setShowNotification(i18n.t('errors.network_error'));
    });
    setSelected({
      ...selected,
      answer: content,
    });
    setShowNotification(i18n.t('notifications.faq_saved'));
  };

  if (!selected) return null;

  return (
    <div className="flex flex-col gap-4">
      {confirmDelete && (
        <ConfirmModal
          open
          title="pages.faq.delete_title"
          description="pages.faq.delete_description"
          onClose={() => setConfirmDelete(false)}
          onConfirm={async () => {
            if (selected.id) {
              try {
                await axios.delete(`${config.backend}/faq/${selected.id}`);
              } catch (e) {
                console.error(e);
              }
            }
            setConfirmDelete(false);
            setSelected({});
            if (faqId === 'new') {
              navigate('..');
            } else {
              navigate('../..');
            }
            setTimeout(() => {
              window.scrollTo(0, 0);
            });
          }}
        />
      )}
      <div>
        <h1 className="my-8 text-xl font-semibold text-black">
          <T>{i18n.t('pages.faq.edit_title')}</T>
        </h1>
        <Label>{t('pages.faq.question')}</Label>
        <h3 className="flex justify-between">
          <div className="mr-2 w-full">
            <InputText
              value={selected.question}
              onChange={async (ev) => {
                setSelected({
                  ...selected,
                  question: ev.target.value,
                });
              }}
              placeholder={i18n.t('pages.faq.question')}
            />
          </div>
          <IconButton
            Icon={Trash}
            variant="ghost"
            size="sm"
            onClick={async () => {
              setConfirmDelete(true);
            }}
          />
          <IconButton
            Icon={Eye}
            variant="ghost"
            size="sm"
            onClick={async () => {
              await save();
              navigate('..');
              //setEditMode(false);
            }}
          />
        </h3>
      </div>
      <div>
        <Label htmlFor="language">{t('pages.profile_account.language')}</Label>
        <div id="language">
          <InputSelect
            onChange={async (language) => {
              setSelected({
                ...selected,
                lang: language,
              });
            }}
            value={selected.lang ? selected.lang : i18n.language}
            options={languages.map((lang) => lang.value)}
          >
            {(lang) => t(`langs.${lang}`)}
          </InputSelect>
        </div>
      </div>
      <div>
        <Label>{t('pages.faq.topic_title')}</Label>
        <InputSelect
          value={selected.topic}
          options={faqTopics}
          onChange={async (data) => {
            setSelected({
              ...selected,
              topic: data,
            });
          }}
        >
          {(topic) => t(`pages.faq.topic.${topic}`)}
        </InputSelect>
      </div>
      <div>
        <Label>{t('pages.faq.answer')}</Label>
        <div className="mt-1">
          <FroalaTextAreaEdit
            mode="edit"
            modus="lessonEdit"
            setRef={(ref) => {
              setRef(ref);
            }}
            placeholder={i18n.t('pages.faq.answer')}
            value={selected.answer}
            style={{ minHeight: '350px' }}
            onSave={async () => {
              await save();
            }}
          />
        </div>
      </div>
      <div>
        <Button
          onClick={async () => {
            await save();
            navigate('..');
          }}
        >
          {t('button.save')}
        </Button>
      </div>
    </div>
  );
};

export default FaqsDetailsEdit;

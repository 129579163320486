import React, { useState, useEffect, useRef } from 'react';

const TextWithTooltip = ({ text }: any) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const currentBlock: any = textRef.current;
      setIsTruncated(currentBlock?.scrollHeight > currentBlock?.clientHeight);
    }
  }, [text]);

  return (
    <div className="group relative">
      <div
        ref={textRef}
        className="overflow-hidden overflow-ellipsis"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {text}
        {isTruncated && (
          <span className="absolute top-full left-0 z-10 mt-1.5 hidden w-full rounded-lg bg-white p-3 text-black shadow-[0_0_8px_-1px_rgba(0,0,0,0.5)] group-hover:block">
            {text}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextWithTooltip;

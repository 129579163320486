import { Button, IconButton, Dropdown } from '@teo/components';
import { Document, Image, Play } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { TheoSearch } from '@teo/components/illustrations';

const ExtraInfoEmpty = ({
  handleNewExtraIndo,
  handleOpenLibrary,
  fileInputRef,
  fileInputVideoRef,
  setIsOpenLibraryModal,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex py-8">
        <TheoSearch className="m-auto w-32" />
      </div>
      <p className="px-3 text-sm text-grey-07">{t('extra_info.text_empty')}</p>
      <div className="my-5 flex justify-center gap-3">
        <Dropdown>
          <Dropdown.Trigger>
            <Button>{t('extra_info.btn_add_info')}</Button>
          </Dropdown.Trigger>
          <Dropdown.Content
            style={{
              top: '25px',
              left: '0',
            }}
          >
            <Dropdown.Actions>
              <Dropdown.Button
                onClick={() => {
                  handleNewExtraIndo();
                }}
              >
                <IconButton
                  Icon={Document}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.new_lesson')}</p>
              </Dropdown.Button>

              <Dropdown.Button
                onClick={() => {
                  handleOpenLibrary();
                }}
              >
                <IconButton
                  Icon={Document}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.les_library')}</p>
              </Dropdown.Button>
              <Dropdown.Button onClick={() => setIsOpenLibraryModal(true)}>
                <IconButton
                  Icon={Image}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.upload_img')}</p>
              </Dropdown.Button>
              <Dropdown.Button
                onClick={() => {
                  fileInputVideoRef?.current?.click();
                }}
              >
                <IconButton
                  Icon={Play}
                  variant="ghost"
                  className="mr-2 text-[#231f20]"
                />
                <p className="text-sm">{t('extra_info.upload_video')}</p>
              </Dropdown.Button>
            </Dropdown.Actions>
          </Dropdown.Content>
        </Dropdown>
        <Button
          variant="outline"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          {t('extra_info.btn_upload')}
        </Button>
      </div>
    </>
  );
};

export default ExtraInfoEmpty;

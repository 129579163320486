import i18n from 'i18next';
import {
  InputText,
  InputPhoneNumber,
  InputTextarea,
  Button,
  Checkbox,
  IconButton,
} from '@teo/components';
import { Cross } from '@teo/components/icons';
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { emailRegex } from '@/constants/regex.constants';

const Contact = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [message, setMessage] = useState('');
  const [privacyPolicy, setpPrivacyPolicy] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const formattedString = i18n.t('pages.contact.text').replace(/\/n/g, '<br>');
  const [emailWasTouched, setEmailWasTouched] = useState(false);

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;

  const countryData = [
    {
      callingCode: '+32',
      countryCode: 'BE',
    },
    {
      callingCode: '+31',
      countryCode: 'NL',
    },
    {
      callingCode: '+351',
      countryCode: 'PT',
    },
    {
      callingCode: '+44',
      countryCode: 'UK',
    },
  ];

  useEffect(() => {
    setValidEmail(emailRegex.test(email));
  }, [email]);

  const getCountryInfo = (countryCode: any) => {
    return (
      countryData.find((country) => country.countryCode === countryCode) || null
    );
  };

  useEffect(() => {
    if (authUser?.id) {
      authUser?.firstName && setName(authUser?.firstName);
      authUser?.lastName && setLastName(authUser?.lastName);
      authUser?.email && setEmail(authUser?.email);
      if (authUser?.entityNumber) {
        const parseString = () => {
          const countryCode = authUser?.entityNumber?.slice(0, 2);
          const phone = authUser?.entityNumber?.slice(2);
          const countryInfo = getCountryInfo(countryCode);
          return {
            country: countryInfo,
            phone,
          };
        };

        const parsedObject: any = parseString();
        setPhoneNumber(parsedObject);
      }
    }
  }, [authUser]);

  const sendMessage = () => {
    const promises: any = [];
    promises.push(
      axios.post(`${config.backend}/contact/send`, {
        message: message,
        firstName: name,
        lastName: lastName,
        email: email,
      })
    );

    Promise.allSettled(promises).then(() => {
      setShowMessage(true);
      setMessage('');
    });
  };

  return (
    <>
      {showMessage && (
        <div className="relative mt-6 max-w-[720px] rounded-lg border border-[#FFF0B3] bg-[#FFF7D9] px-7 py-5">
          <h4 className="mb-3 text-base text-black">
            {i18n.t('pages.contact.successful_message_title')}
          </h4>
          <p className="text-sm text-grey-08">
            {i18n.t('pages.contact.successful_message_text')}
          </p>
          <IconButton
            className="absolute right-2 top-2"
            Icon={Cross}
            variant="ghost"
            onClick={() => setShowMessage(false)}
          />
        </div>
      )}

      <div className="mt-6 rounded-lg border border-grey-02 p-6">
        <h1 className="mb-8 text-xl font-semibold text-black">
          {i18n.t('pages.contact.title')}
        </h1>
        {/*
        <p
          className="mb-8 text-sm text-black"
          dangerouslySetInnerHTML={{ __html: formattedString }}
        />
        */}
        <form className="flex w-full max-w-md flex-col gap-4">
          <InputText
            id="nameUser"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={i18n.t('pages.contact.name')}
          />
          <InputText
            id="setLastName"
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            placeholder={i18n.t('pages.contact.lastName')}
          />
          {/* <InputPhoneNumber
          countryCodes={countryData}
          onChange={(val: any) => {
            setPhoneNumber(val);
          }}
          value={
            phoneNumber
              ? phoneNumber
              : {
                  country: {
                    callingCode: '+32',
                    countryCode: 'BE',
                  },
                  phone: '',
                }
          }
          placeholder={i18n.t('pages.contact.phoneNumber')}
        /> */}
          <InputText
            id="email"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder={i18n.t('pages.contact.email')}
            onBlur={(e) => {
              if (!validEmail || e.target.value === '') {
                setEmailWasTouched(true);
              } else {
                setEmailWasTouched(false);
              }
            }}
            hasError={emailWasTouched}
          />

          <InputTextarea
            id="message"
            onChange={(e) => setMessage(e.target.value)}
            placeholder={i18n.t('pages.contact.message')}
            value={message}
          />
          <div className="flex items-center">
            <Checkbox
              id="agreedPrivacyPolicy"
              onChange={() => setpPrivacyPolicy(!privacyPolicy)}
              checked={privacyPolicy}
            />

            <label
              htmlFor="agreedPrivacyPolicy"
              className="pl-2.5 text-sm text-black"
            >
              {i18n.t('pages.contact.privacyPolicy_1')}
              <Link
                className="inline-block text-sm text-black underline"
                to="https://teo.training/privacy-en-cookiebeleid"
                target="_blank"
              >
                {i18n.t('pages.contact.privacyPolicy_link')}
              </Link>
              {i18n.t('pages.contact.privacyPolicy_2')}
            </label>
          </div>
          <div>
            <Button
              disabled={!privacyPolicy || !validEmail}
              className="mt-4"
              onClick={() => sendMessage()}
            >
              {i18n.t('pages.contact.btnSend')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact;

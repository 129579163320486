import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Label,
  InputTextarea,
  Switch,
  Button,
  InputScore,
  CollapsibleCheckboxCard,
  Spinner,
} from '@teo/components';
import { StepCommunication } from '@/models/addCourseStep';
import FroalaTextAreaEdit from '@/components/Froala/FroalaTextAreaEdit';
import WelcomeMessageModal from '@/pages/Protected/FollowUp/AddCourse/WelcomeMessageModal/WelcomeMessageModal';
import { axios } from '@/axios';
import { config } from '@/config';

const allOnboardingSteps = [
  { value: 'onboarding_names_step', disabled: true },
  { value: 'onboarding_birthday_step', disabled: false },
  { value: 'onboarding_gender_step', disabled: false },
  { value: 'onboarding_location_step', disabled: false },
  { value: 'onboarding_regime_step', disabled: false },
  { value: 'onboarding_referral_step', disabled: false },
  { value: 'onboarding_diploma_step', disabled: false },
  { value: 'onboarding_current_work_step', disabled: false },
  // { value: 'onboarding_languages_step', disabled: false },
  // { value: 'onboarding_drivers_license_step', disabled: false },
  { value: 'onboarding_contact_step', disabled: true },
];

const CommunicationStep = ({
  state,
  loadeFroala,
  setLoadStep,
  courseType,
}: any) => {
  const { t } = useTranslation();

  const {
    lvCompletePassed,
    setLvCompletePassed,
    lvCompleteFailed,
    setLvCompleteFailed,
    lvCustomMail,
    setLvCustomMail,
    onboardingRequired,
    setOnboardingRequired,
    onboardingSteps,
    setOnboardingSteps,
    nrOfReminders,
    setNrOfReminders,
    reminderInterval,
    setReminderInterval,
    doNotRemindProgress,
    setDoNotRemindProgress,
    reminderEmail,
    setReminderEmail,
    welcomeMessage,
    setWelcomeMessage,
    course,
  } = state;

  const [isOpenWelcomeMessageModal, setIsOpenWelcomeMessageModal] =
    useState(false);
  const [template, setTemplate] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setLoadStep(false);
    }, 100);
  }, []);

  return (
    <div className="flex flex-col">
      {courseType !== 'instruction' && (
        <>
          <article className="prose flex flex-col ">
            <h3 className="text-base">
              {t('pages.course_content_flow.step_4.title')}
            </h3>
          </article>

          <div className="flex flex-col">
            <div className="mb-1 mt-6 text-sm text-black">
              <Label htmlFor="message-passed">
                {t('pages.course_content_flow.step_4.message_passed')}
              </Label>
              {loadeFroala ? (
                <FroalaTextAreaEdit
                  id="message-passed"
                  onChange={(e: any) => setLvCompletePassed(e.target.value)}
                  value={lvCompletePassed}
                  limit={300}
                  classTextarea={
                    'min-h-[171px] w-full border-0 rounded-lg resize-none'
                  }
                  placeholder={t(
                    'pages.course_content_flow.step_4.message_passed_default'
                  )}
                />
              ) : (
                <div className="flex min-h-[171px] w-full rounded-lg border border-grey-02">
                  <div className="m-auto">
                    <Spinner
                      ariaLabel="Loading spinner"
                      className="!h-10 !w-10 border-grey-04"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mb-1 mt-6 text-sm text-black">
              <Label htmlFor="message-failed">
                {t('pages.course_content_flow.step_4.message_failed')}
              </Label>
              {loadeFroala ? (
                <FroalaTextAreaEdit
                  id="message-failed"
                  onChange={(e: any) => setLvCompleteFailed(e.target.value)}
                  value={lvCompleteFailed}
                  limit={300}
                  classTextarea={
                    'min-h-[171px] w-full border-0 rounded-lg resize-none'
                  }
                  placeholder={t(
                    'pages.course_content_flow.step_4.message_failed_default'
                  )}
                />
              ) : (
                <div className="flex min-h-[171px] w-full rounded-lg border border-grey-02">
                  <div className="m-auto">
                    <Spinner
                      ariaLabel="Loading spinner"
                      className="!h-10 !w-10 border-grey-04"
                    />
                  </div>
                </div>
              )}
              {/** 
          <InputTextarea
            id="message-failed"
            onChange={(e) => setLvCompleteFailed(e.currentTarget.value)}
            value={lvCompleteFailed}
            limit={300}
          />
          */}
            </div>
          </div>

          <div className="mt-7 flex flex-col border-t border-grey-02 pt-7">
            <article className="prose flex flex-col ">
              <h3 className="text-base">
                {t('pages.course_content_flow.step_4.onboarding.title')}
              </h3>
            </article>
            <div className="mt-4 flex flex-col gap-4 rounded-lg border border-grey-02 p-4">
              <div
                className="flex flex-row items-center gap-4"
                data-testid="onboarding"
              >
                <Switch
                  checked={onboardingRequired}
                  onChange={(isCheck) => setOnboardingRequired(isCheck)}
                />
                <div>
                  <h4 className="pb-2 font-normal">
                    {t('pages.course_content_flow.step_4.onboarding.switch')}
                  </h4>
                  <p className="text-xs text-grey-05">
                    {t(
                      'pages.course_content_flow.step_4.onboarding.switch_description'
                    )}
                  </p>
                </div>
              </div>

              {onboardingRequired && (
                <div>
                  <div className="flex flex-col">
                    <Label htmlFor="message-welcom">
                      {t(
                        'pages.course_content_flow.step_4.onboarding.introduction_onboarding'
                      )}
                    </Label>
                    {loadeFroala ? (
                      <FroalaTextAreaEdit
                        id="message-welcom"
                        onChange={(e: any) => setWelcomeMessage(e.target.value)}
                        value={welcomeMessage}
                        limit={300}
                        placeholder={t(
                          'pages.course_content_flow.step_4.emails.welcome_message_placeholder'
                        )}
                        classTextarea={
                          'min-h-[171px] w-full border-0 rounded-lg resize-none'
                        }
                      />
                    ) : (
                      <div className="flex min-h-[171px] w-full rounded-lg border border-grey-02">
                        <div className="m-auto">
                          <Spinner
                            ariaLabel="Loading spinner"
                            className="!h-10 !w-10 border-grey-04"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <article className="prose flex flex-col ">
                    <h3 className="mt-7 text-base">
                      {t(
                        'pages.course_content_flow.step_4.questionnaire.title'
                      )}
                    </h3>
                  </article>
                  <div className="mt-5 flex flex-col gap-5">
                    {allOnboardingSteps.map((step) => (
                      <CollapsibleCheckboxCard
                        checked={onboardingSteps.includes(step?.value)}
                        disabled={step?.disabled}
                        key={step?.value}
                        id={step?.value}
                        label={t(
                          `pages.course_content_flow.step_4.questionnaire.${step?.value}`
                        )}
                        onChange={(isCheck) => {
                          if (isCheck) {
                            const newOnboardingSteps = [
                              ...onboardingSteps,
                              step?.value,
                            ];
                            //sort by the order in the original array
                            newOnboardingSteps.sort(
                              (a, b) =>
                                allOnboardingSteps.indexOf(a) -
                                allOnboardingSteps.indexOf(b)
                            );
                            setOnboardingSteps(newOnboardingSteps);
                          } else {
                            setOnboardingSteps(
                              onboardingSteps.filter(
                                (x: string) => x !== step?.value
                              )
                            );
                          }
                        }}
                      >
                        <div className="whitespace-pre-line pb-3 pl-12 pr-3 text-sm leading-7 text-grey-07">
                          {t(
                            `pages.course_content_flow.step_4.questionnaire.${step?.value}_question`
                          )}
                        </div>
                      </CollapsibleCheckboxCard>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div
        className={`flex flex-col ${
          courseType !== 'instruction'
            ? 'mt-7 border-t border-grey-02 pt-7'
            : ''
        }`}
      >
        <article className="prose flex flex-col ">
          <h3 className="text-base">
            {t('pages.course_content_flow.step_4.emails.title')}
          </h3>
        </article>
        <div className="mt-4 flex flex-col gap-5 rounded-lg border border-grey-02 p-4">
          <div
            className="flex flex-row items-center gap-4"
            data-testid="welcomeEmail"
          >
            <Switch
              checked={lvCustomMail}
              onChange={(isCheck) => {
                if (isCheck) {
                  setLvCustomMail(
                    lvCustomMail ||
                      t(
                        'pages.course_content_flow.step_4.emails.welcome_message_placeholder'
                      )
                  );
                } else {
                  setLvCustomMail(null);
                }
              }}
            />
            <div>
              <h4 className="pb-2 font-normal">
                {t(
                  'pages.course_content_flow.step_4.emails.switch_welcome_email'
                )}
              </h4>
              <p className="text-xs text-grey-05">
                {t(
                  'pages.course_content_flow.step_4.emails.switch_welcome_email_description'
                )}
              </p>
            </div>
          </div>

          {lvCustomMail && (
            <div className="flex flex-col">
              <Label htmlFor="message-welcom">
                {t(
                  'pages.course_content_flow.step_4.emails.welcome_message_label'
                )}
              </Label>
              {loadeFroala ? (
                <FroalaTextAreaEdit
                  id="message-welcom"
                  onChange={(e: any) => setLvCustomMail(e.target.value)}
                  value={lvCustomMail}
                  limit={300}
                />
              ) : (
                <div className="flex min-h-[171px] w-full rounded-lg border border-grey-02">
                  <div className="m-auto">
                    <Spinner
                      ariaLabel="Loading spinner"
                      className="!h-10 !w-10 border-grey-04"
                    />
                  </div>
                </div>
              )}
              <Button
                variant="outline"
                onClick={async () => {
                  await axios.put(
                    `${config.backend}/courseinfo/${course?.id}`,
                    {
                      lvCustomMail: lvCustomMail,
                    }
                  );
                  setTemplate('invite');
                  setIsOpenWelcomeMessageModal(true);
                }}
                className="mr-auto mt-3"
                size="sm"
              >
                {t('pages.course_content_flow.step_4.emails.btn_view_example')}
              </Button>
            </div>
          )}
        </div>
        {courseType !== 'instruction' && (
          <div className="mt-4 flex flex-col gap-4 rounded-lg border border-grey-02 p-4">
            <div
              className="flex flex-row items-center gap-4"
              data-testid="reminderEmail"
            >
              <Switch
                checked={reminderEmail}
                onChange={(isCheck) => {
                  if (isCheck) {
                    setReminderEmail(
                      reminderEmail ||
                        t(
                          'pages.course_content_flow.step_4.emails.reminder_message_placeholder'
                        )
                    );
                  } else {
                    setReminderEmail(null);
                  }
                }}
              />
              <div>
                <h4 className="pb-2 font-normal">
                  {t(
                    'pages.course_content_flow.step_4.emails.switch_reminder_email'
                  )}
                </h4>
                <p className="text-xs text-grey-05">
                  {t(
                    'pages.course_content_flow.step_4.emails.switch_reminder_email_description'
                  )}
                </p>
              </div>
            </div>

            {reminderEmail && (
              <div className="flex flex-col">
                <Label htmlFor="message-reminder">
                  {t(
                    'pages.course_content_flow.step_4.emails.reminder_message_label'
                  )}
                </Label>
                {loadeFroala ? (
                  <FroalaTextAreaEdit
                    id="message-reminder"
                    onChange={(e: any) => setReminderEmail(e.target.value)}
                    value={reminderEmail}
                    limit={300}
                    placeholder={t(
                      'pages.course_content_flow.step_4.emails.reminder_message_placeholder'
                    )}
                  />
                ) : (
                  <div className="flex min-h-[171px] w-full rounded-lg border border-grey-02">
                    <div className="m-auto">
                      <Spinner
                        ariaLabel="Loading spinner"
                        className="!h-10 !w-10 border-grey-04"
                      />
                    </div>
                  </div>
                )}

                <Button
                  variant="outline"
                  onClick={async () => {
                    await axios.put(
                      `${config.backend}/courseinfo/${course?.id}`,
                      {
                        reminderEmail: reminderEmail,
                      }
                    );
                    setTemplate('reminder');
                    setIsOpenWelcomeMessageModal(true);
                  }}
                  className="mr-auto mt-3"
                  size="sm"
                >
                  {t(
                    'pages.course_content_flow.step_4.emails.btn_view_example'
                  )}
                </Button>
                <div className="mt-5 flex flex-col" data-testid="reminderDays">
                  <Label htmlFor="reminder_days">
                    {t('pages.course_content_flow.step_4.emails.reminder_days')}
                  </Label>
                  <InputScore
                    onChange={(value) => setReminderInterval(value)}
                    step={1}
                    value={reminderInterval}
                  />
                  <p className="pt-1 text-sm text-grey-05">
                    {t(
                      'pages.course_content_flow.step_4.emails.reminder_days_placeholder'
                    )}
                  </p>
                </div>
                <div
                  className="mt-5 flex flex-col"
                  data-testid="reminderMemories"
                >
                  <Label htmlFor="reminder_num_memories">
                    {t(
                      'pages.course_content_flow.step_4.emails.reminder_num_memories'
                    )}
                  </Label>
                  <InputScore
                    onChange={(value) => setNrOfReminders(value)}
                    step={1}
                    value={nrOfReminders}
                  />
                  <p className="pt-1 text-sm text-grey-05">
                    {t(
                      'pages.course_content_flow.step_4.emails.reminder_num_memories_placeholder'
                    )}
                  </p>
                </div>
                <div
                  className="mt-5 flex flex-col"
                  data-testid="reminderProgress"
                >
                  <Label htmlFor="reminder_progress">
                    {t(
                      'pages.course_content_flow.step_4.emails.reminder_progress'
                    )}
                  </Label>
                  <InputScore
                    onChange={(value) => setDoNotRemindProgress(value)}
                    step={1}
                    value={doNotRemindProgress}
                  />
                  <p className="pt-1 text-sm text-grey-05">
                    {t(
                      'pages.course_content_flow.step_4.emails.reminder_progress_placeholder'
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {isOpenWelcomeMessageModal && (
        <WelcomeMessageModal
          isOpenWelcomeMessageModal={isOpenWelcomeMessageModal}
          closeModal={() => setIsOpenWelcomeMessageModal(false)}
          courseId={course?.id}
          template={template}
        />
      )}
    </div>
  );
};

export default CommunicationStep;

import { Dropdown, IconButton } from '@teo/components';
import {
  Information,
  Pencil,
  PlusFilled,
  Document,
  Chapter,
  Library,
  Play,
  Image,
  ImageFilled,
} from '@teo/components/icons';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { LessonTreeEdit } from '@/components/LessonTree/LessonTreeEdit';
import { LibraryModal } from '../LibraryModal/LibraryModal';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { axios, publicAxios } from '@/axios';
import { config } from '@/config';
import { uploadLesson, uploadImage } from '@/query/documents';
import { newUid, uploadFile } from '@/util';
import { uploadVideo } from '@/query/vimeo';
import Hint from '../Hints/Hints';
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { NotificationContext } from '@/components/Contexts/NotificationContext';

export const SidebarScorm = ({
  coursecontentId,
  publicationPath,
  publicationType,
  activeNode,
  setActiveNode,
  isDesctop,
}: any) => {
  const { t } = useTranslation();
  const [lessons, setLessons] = useState<any[]>([]);
  const { hints } = useContext(HintsContext);
  const { newPage } = useContext(PageUrlContext);
  const [isAddButtonBottom, setIsAddButtonBottom] = useState(false);
  const { setShowNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (coursecontentId) {
      publicAxios
        .get(
          `${config.s3root}scorm/${coursecontentId}/${publicationPath}/imsmanifest.xml`
        )
        .then((result) => {
          const parser = new XMLParser({
            ignoreAttributes: false,
            isArray: (name: string) =>
              ['organization', 'resource', 'file', 'item'].includes(name),
          });
          const json = parser.parse(result.data);
          //console.log('manifest', json)

          setLessons(json.manifest.organizations.organization[0].item);
        });
    }
  }, [coursecontentId]);

  const renderItem: any = (item: any, indent = 0) => {
    //console.log("rendering item", indent, item)
    if (Array.isArray(item))
      return <>{item.map((x) => renderItem(x, indent + 1))}</>;
    return (
      <>
        <li style={{ marginLeft: `${indent * 24}px` }}>
          <div
            key={item['@_identifier']}
            className={
              `relative my-1 flex cursor-pointer flex-row items-center rounded-md pl-2 pr-1 ` +
              (activeNode === item['@_identifier'] ? ' bg-[#f8f8f9]' : '')
            }
            onClick={(ev) => {
              setActiveNode(item);
            }}
          >
            <Document className={`m-2 w-6 min-w-6`} />
            <p
              className={`w-full max-w-[180px] flex-1 text-sm font-normal	text-[#231f20]`}
            >
              {item.title}
            </p>
            {activeNode === item['@_identifier'] && (
              <Pencil className="mx-2 w-5 min-w-5 text-[#fdbe06]" />
            )}
          </div>
        </li>
        {!!item.item && renderItem(item.item, indent)}
      </>
    );
  };

  return (
    <>
      <div
        onClick={(ev) => {
          setActiveNode(null);
        }}
        className={clsx(
          ` relative flex cursor-pointer items-center justify-between rounded-md`,
          !activeNode && 'bg-[#f8f8f9]',
          hints?.id == 'hint_39' &&
            'active-hint-create-page active-hint-parent active-hint-main-menu'
        )}
      >
        <span className="flex items-center pl-2">
          <Hint
            key={`hint_39_${newPage}`}
            id={'hint_39'}
            leftDots={'left-0 top-2'}
            title={t('hint.title_39')}
            order={39}
            style={{
              left: 'calc(100% + 20px)',
              top: '0px',
            }}
          />
          <Information className="m-2 w-6 min-w-6" />
          <p className="text-sm font-normal text-[#231f20]">
            {t(`courseTypes.${publicationType}`)}
          </p>
        </span>
        {!activeNode && <Pencil className="mx-2 w-5 min-w-5 text-[#fdbe06]" />}
      </div>
      <ul className="flex flex-col">{renderItem(lessons, -1)}</ul>
    </>
  );
};

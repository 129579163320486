import { useState, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { LessonEditFroala } from './LessonEditFroala';
import { Spinner } from '@teo/components';
import { LessonView } from './LessonView';
import { useTranslation } from 'react-i18next';
import { initFroala } from '@/components/Froala/froala.js';
import ExtraInfo from '@/components/ExtraInfo/ExtraInfo';
import { Cross } from '@teo/components/icons';
import { LessonEditTitleBar } from './LessonEditTitleBar';
import { newUid } from '@/util';

export const LessonEdit = ({
  node,
  setSaveFn,
  resetAutoSave,
  publicationName,
  publication,
  setTree,
  prevTree,
  setAddLesson,
  setLessons,
  lessons,
  setActiveNode,
  setParentNode,
  setMobileSidebar,
  setExtraInfoEdit,
  extraInfoEdit,
  updateLesInfo,
  setIsFroalaLoaded,
  isFroalaLoaded,
  hints,
  newPage,
}: {
  node: any;
  setSaveFn: any;
  resetAutoSave: any;
  setTree: any;
  publicationName: any;
  publication: any;
  prevTree: any;
  setAddLesson: any;
  setLessons: any;
  lessons: any;
  setActiveNode: any;
  setParentNode: any;
  setMobileSidebar: any;
  setExtraInfoEdit: any;
  extraInfoEdit: any;
  updateLesInfo?: any;
  setIsFroalaLoaded?: any;
  isFroalaLoaded?: any;
  hints?: any;
  newPage?: any;
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState<any>(null);
  const [loadLessons, setLoadLessons] = useState<any>(true);
  const [isLesson, setIsLesson] = useState<any>(true);
  const [showMessage, setShowMessage] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(false);
  const [extraInfoLessons, setExtraInfoLessons] = useState<any>([]);
  const [isAssetPath, setIsAssetPath] = useState(null);

  useEffect(() => {
    if (node) {
      setIsLoaded(false);
      setIsLesson(!!node?.data?.lessonId);
      setIsAssetPath(node?.data?.assetPath ? node?.data?.assetPath : false);
      setLoadLessons(true);
      setIsFroalaLoaded(null);
    }
  }, [node]);

  useEffect(() => {
    if (!isLesson && isAssetPath) {
      setLoadLessons(false);
    }
  }, [isLesson, isAssetPath]);

  useEffect(() => {
    if (lessons) {
      setExtraInfoLessons(
        lessons?.filter(
          (les: any) =>
            les?.rootLessonId === node?.data?.lessonId &&
            les?.path?.includes(node?.data?.path)
        )
      );
    }
  }, [lessons, node]);

  const lessonResult = useGet<any>(node?.data?.getData, {
    enabled: !!node?.data?.getData,
    onSuccess: async (result: any) => {
      setContent(result?.data || '<p></p>');
      await initFroala();
      setIsLoaded(true);
      setLoadLessons(false);
    },
  });

  return (
    <>
      {loadLessons && (isLesson || isAssetPath) && (
        <div className="m-auto flex min-h-[calc(100vh_-_150px)] p-10">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-14 !w-14 border-grey-06"
            />
          </div>
        </div>
      )}

      {!extraInfoEdit && !loadLessons && (
        <LessonEditTitleBar
          key={updateLesInfo}
          node={node}
          publicationName={publicationName}
          prevTree={prevTree}
          setLessons={setLessons}
          setShowExtraInfo={setShowExtraInfo}
          extraInfoLessons={extraInfoLessons}
          setActiveNode={setActiveNode}
          hints={hints}
          newPage={newPage}
        />
      )}

      {!loadLessons &&
        isLesson &&
        (node?.data?.canEdit ? (
          <div className="px-3 pt-3">
            <LessonEditFroala
              key={node?.data?.lessonId}
              isLoading={!isLoaded}
              newContent={content}
              setSaveFn={(fn: any) => setSaveFn(fn)}
              resetAutoSave={resetAutoSave}
              node={node}
              isLesson={isLesson}
              setIsFroalaLoaded={setIsFroalaLoaded}
            />
          </div>
        ) : (
          <div className="flex">
            <div className="mx-auto max-w-2xl">
              {showMessage && (
                <div className="relative mb-5 rounded-lg bg-[#f8f8f9] p-4 pr-6">
                  <div
                    className="absolute right-0 top-0 cursor-pointer p-2"
                    onClick={() => setShowMessage(false)}
                  >
                    <Cross className="w-5" />
                  </div>
                  <h3 className="mb-3 text-base">
                    {t('pages.create_edit_course.linking_message.title')}
                  </h3>
                  <p className="mb-2 text-sm text-grey-08">
                    {t('pages.create_edit_course.linking_message.message')}
                  </p>
                  {/* <div>
                <Button variant="outline" onClick={() => setShowMessage(false)}>
                  {t('pages.create_edit_course.linking_message.button')}
                </Button>
              </div> */}
                </div>
              )}

              <LessonView node={node?.data}></LessonView>
            </div>
          </div>
        ))}

      {!loadLessons && !isLesson && isAssetPath && (
        <div className="flex w-full items-start p-3">
          {node?.data?.assetType == 'image' ? (
            <img
              src={node?.data?.assetPath}
              alt={node?.data?.assetName}
              className="mx-auto max-h-[100vh] w-full object-contain"
            />
          ) : (
            <div id={`${newUid(20)}_embed`} className="mx-auto">
              <iframe
                id={`${newUid(20)}_placeholder`}
                width="640"
                height="360"
                className=""
                src={node?.data?.assetPath}
              ></iframe>
            </div>
          )}
        </div>
      )}

      {showExtraInfo && (
        <ExtraInfo
          setShowExtraInfo={setShowExtraInfo}
          activeNode={node}
          node={node}
          publication={publication}
          prevTree={prevTree}
          setTree={setTree}
          setAddLesson={setAddLesson}
          setLessons={setLessons}
          nameItem={node?.text}
          extraInfoLessons={extraInfoLessons}
          setActiveNode={setActiveNode}
          setParentNode={setParentNode}
          setMobileSidebar={setMobileSidebar}
          setExtraInfoEdit={setExtraInfoEdit}
          extraInfoEdit={extraInfoEdit}
          isFroalaLoaded={isFroalaLoaded}
        />
      )}
    </>
  );
};

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, Button, ErrorMessage } from '@teo/components';
const Step3 = ({ step, setStep, setInfo, userInfo, courseInfo }: any) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<any>([]);
  const [checkValid, setCheckValid] = useState<boolean>(false);

  const nextButton = () => {
    const prevStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_referral_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_diploma_step')
      ? 2
      : courseInfo?.onboardingSteps?.includes('onboarding_current_work_step')
      ? 3
      : 4;
    setCheckValid(true);
    if (value?.length > 0) {
      setInfo((prev: any) => ({ ...prev, working_hours: value }));
      setStep((prev: number) => prev + prevStep);
    }
  };
  const prevButton = () => {
    const prevStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_location_step'
    )
      ? 1
      : 2;
    setStep((prev: number) => prev - prevStep);
  };

  useEffect(() => {
    setValue(userInfo?.regime);
  }, [userInfo]);

  if (step !== 3) {
    return null;
  }
  return (
    <>
      <div>
        <p>{t(`pages.onboarding.step_3.subtitle`)}</p>
        <div className=" mt-6">
          <CheckboxGroup onChange={(val) => setValue(val)} value={value}>
            <CheckboxGroup.Option value="day">
              {t(`pages.onboarding.step_3.day`)}
            </CheckboxGroup.Option>
            <CheckboxGroup.Option value="2shift">
              {t(`pages.onboarding.step_3.2shift`)}
            </CheckboxGroup.Option>
            <CheckboxGroup.Option value="3shift">
              {t(`pages.onboarding.step_3.3shift`)}
            </CheckboxGroup.Option>
            <CheckboxGroup.Option value="continuous">
              {t(`pages.onboarding.step_3.continuous`)}
            </CheckboxGroup.Option>
            <CheckboxGroup.Option value="weekend">
              {t(`pages.onboarding.step_3.weekend`)}
            </CheckboxGroup.Option>
            <CheckboxGroup.Option value="night">
              {t(`pages.onboarding.step_3.flexibel`)}
            </CheckboxGroup.Option>
          </CheckboxGroup>

          {value?.length < 1 && checkValid && (
            <div className="mt-4">
              <ErrorMessage id="errorMessage">
                {t('pages.onboarding.error.select')}
              </ErrorMessage>
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full pt-10">
        <Button
          variant="outline"
          className={`mr-2 flex-[1_1_30%]`}
          onClick={prevButton}
        >
          {t(`pages.onboarding.button_prev`)}
        </Button>

        <Button
          className={`${step !== 1 && 'ml-2 flex-[1_1_30%]'}`}
          onClick={() => step < 7 && nextButton()}
        >
          {t(`pages.onboarding.button_next`)}
        </Button>
      </div>
    </>
  );
};
export default Step3;

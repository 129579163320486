import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

interface PageLayoutSubcomponents {
  Content: typeof PageLayoutContent;
  Sidebar: typeof PageLayoutSidebar;
}

const PageLayout: FC<PropsWithChildren> & PageLayoutSubcomponents = (props) => (
  <div className={'flex grow '}>{props.children}</div>
);

const PageLayoutContent: FC<PropsWithChildren & { className?: string }> = (
  props
) => (
  <main className={clsx('grow p-4 ', props.className)}>{props.children}</main>
);

const PageLayoutSidebar: FC<PropsWithChildren & { className?: string }> = (
  props
) => (
  <div
    className={clsx(
      'w-full min-w-max p-5 md:w-[272px] lg:w-[350px]',
      props.className
    )}
  >
    {props.children}
  </div>
);

PageLayout.Content = PageLayoutContent;
PageLayout.Sidebar = PageLayoutSidebar;

export { PageLayout };

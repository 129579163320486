import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Label,
  InputText,
  InputDate,
  InputSelect,
  Switch,
  PersonAvatar,
  IconButton,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import { useGet } from '@/query/fetchHooks';
import { InputSelectOption } from '@/models/organisation.interface';
import { axios } from '@/axios';
import { config } from '@/config';
import { emailRegex } from '@/constants/regex.constants';
import { newUid } from '@/util';
import { languages } from '@/constants/languages';

const autoReportFrequency = [
  {
    value: '86400',
    label: 'Daily',
  },
  {
    value: '604800',
    label: 'Weekly',
  },
  {
    value: '2592000',
    label: 'Monthly',
  },
  {
    value: '31536000',
    label: 'Yearly',
  },
];

const AutoReportModal = ({
  setIsOpenAutoReport,
  closeModal,
  user,
  reportTypeList,
  setRefetchTable,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const [emailValue, setEmailValue] = useState('');
  const [sentEmails, setSentEmails] = useState<any>(
    auth?.user?.email ? [auth?.user?.email] : []
  );
  const [reportName, setReportName] = useState('');
  const [teamsAutoReport, setTeamsAutoReport] = useState<any>([]);
  const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
  const [reportSubteams, setReportSubteams] = useState<boolean>(true);
  const [fromDateAuto, setFromDateAuto] = useState<Date | undefined>();
  const [frequency, setFrequency] = useState({
    label: autoReportFrequency[2].label,
    value: autoReportFrequency[2].value,
  });
  const [autoReportEmail, setAutoReportEmail] = useState<boolean>(true);

  const [addEamilValid, setAddEamilValid] = useState(true);

  const [reportType, setReportType] = useState({
    label: reportTypeList[0].label,
    value: reportTypeList[0].value,
  });

  const [language, setLanguage] = useState(
    languages?.find((option) => option.value === auth?.user?.lang)
  );

  const handleAddTeamst = async (newValue: any) => {
    if (reportSubteams) {
      const addTeam = [...teamsAutoReport];
      for (const obj2 of newValue) {
        if (obj2?.id) {
          const subTeams: any = await axios.get(
            `${config.backend}/teams?organisationId=${
              obj2?.organisationId
            }&searchPath=${encodeURIComponent(
              obj2?.teamPath + '%'
            )}&sort=teamPath`
          );
          if (subTeams?.data && subTeams?.data?.length > 0) {
            const teamSubteams = subTeams?.data;
            for (const sub of teamSubteams) {
              const existingTeam = addTeam.find(
                (obj1: any) => obj1?.id && obj1?.id === sub?.id
              );
              const existingTeam_2 = teamsAutoReport.find(
                (obj1: any) => obj1?.id && obj1?.id === sub?.id
              );
              if (!existingTeam && !existingTeam_2) {
                addTeam.push(sub);
              }
            }
          }
        }
      }
      addTeam.sort((a, b) => {
        const alphabetComparison = a.teamPath.localeCompare(b.teamPath);
        if (alphabetComparison !== 0) {
          return alphabetComparison;
        }
        return a.teamPath.length - b.teamPath.length;
      });
      setTeamsAutoReport(addTeam);
    } else {
      const addTeam = [...teamsAutoReport];
      for (const obj2 of newValue) {
        if (obj2?.id) {
          const existingTeam = teamsAutoReport.find(
            (obj1: any) => obj1?.id && obj1?.id === obj2?.id
          );
          if (!existingTeam) {
            addTeam.push(obj2);
          }
        }
      }
      addTeam.sort((a, b) => {
        const alphabetComparison = a.teamPath.localeCompare(b.teamPath);
        if (alphabetComparison !== 0) {
          return alphabetComparison;
        }
        return a.teamPath.length - b.teamPath.length;
      });
      setTeamsAutoReport(addTeam);
    }
    setSelectedTeams([]);
  };
  const handleRemove = (id: any) => {
    const removeTeam = teamsAutoReport?.filter((item: any) => item.id !== id);
    setTeamsAutoReport(removeTeam);
  };

  useEffect(() => {
    if (emailValue) {
      const emails = emailValue.split(',').map((email) => email.trim());
      const isValidEmail = (email: any) => {
        return emailRegex.test(email);
      };
      const allEmailsValid = emails.every(isValidEmail);
      if (allEmailsValid) {
        setAddEamilValid(false);
      } else {
        setAddEamilValid(true);
      }
    }
  }, [emailValue]);

  const handleAddEmails = () => {
    if (!addEamilValid) {
      const emails = emailValue.split(',').map((email) => email.trim());
      const uniqueEmails = new Set(sentEmails.concat(emails));
      const result = Array.from(uniqueEmails);
      setSentEmails(result);
      setEmailValue('');
      setAddEamilValid(true);
    }
  };

  const handleRemoveEmail = (index: any) => {
    const removeTeam = sentEmails?.filter((el: any, i: any) => index != i);
    setSentEmails(removeTeam);
  };
  const sentReport = () => {
    const promises: any = [];
    promises.push(
      axios.post(`${config.backend}/autoreports`, {
        name: reportName || selectedTeams.map((x) => x.teamName).join(', '),
        email: [...new Set(sentEmails.concat(emailValue))].map((x) => x),
        organisationId: user?.activeOrganisationId,
        lang: language?.value ? language?.value : user?.lang,
        teamIds: teamsAutoReport?.map((el: any) => el.id),
        formType: reportType?.value,
        startDate: fromDateAuto ? fromDateAuto : null,
        interval: parseInt(frequency?.value, 10),
        username: auth?.user.username,
      })
    );

    Promise.allSettled(promises).then(() => {
      setRefetchTable(newUid(20));
      setTimeout(() => {
        setIsOpenAutoReport(false);
      }, 100);
    });
  };

  return (
    <div className="bg-white">
      <div className="fixed inset-0 z-[100] flex rounded-xl bg-white/75 px-3">
        <div className="mx-auto my-8 h-max w-full max-w-[600px]  rounded-xl bg-white drop-shadow-m">
          <Modal.Header onClose={closeModal}>
            {t('pages.report_page.teams_tab.title_auto_modal')}
          </Modal.Header>
          <div className="max-h-[calc(100vh_-_200px)] overflow-auto">
            <div className="p-6">
              <h1 className="text-lg font-semibold">
                {t('pages.report_page.teams_tab.auto_modal_heading')}
              </h1>
              <div className="flex flex-col">
                <div className="mb-1 mt-5 text-sm font-semibold text-black">
                  <Label htmlFor="name-autoreport">
                    {t('pages.report_page.teams_tab.report_name')}
                  </Label>
                  <InputText
                    id="name-autoreport"
                    onChange={(e) => setReportName(e.currentTarget.value)}
                    value={reportName}
                    className="w-full"
                  />
                </div>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="reportEmail">
                    <Switch
                      checked={reportSubteams}
                      onChange={(isCheck) => setReportSubteams(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="font-normal">
                      {t('pages.report_page.teams_tab.include_subteams')}
                    </h4>
                  </div>
                </div>
                <div className="z-[1] mb-1 mt-4 text-sm font-semibold text-black">
                  <p className="mb-1">
                    {t('pages.report_page.teams_tab.teams')}
                  </p>
                  <div className="flex">
                    <span className="w-full">
                      <PaginationMultiSelect
                        id="teams-autoreport"
                        value={selectedTeams}
                        onChange={(value: any) => {
                          setSelectedTeams(value);
                        }}
                        keyFn={(row: any) => row?.id}
                        fetch={useGet.bind(
                          null,
                          `/teams?organisationId=${auth?.user?.activeOrganisationId}`
                        )}
                        defaultSort={[{ id: 'teamPath', desc: false }]}
                        render={(row: any) => {
                          return row?.teamName;
                        }}
                      />
                    </span>
                    <Button
                      className="ml-3 h-[36px] text-base font-medium"
                      variant="primary"
                      disabled={selectedTeams.length === 0}
                      onClick={async () => {
                        handleAddTeamst(selectedTeams);
                      }}
                    >
                      {t('button.add_btn')}
                    </Button>
                  </div>
                  {teamsAutoReport?.length > 0 && (
                    <div className="mt-6">
                      {teamsAutoReport?.map((team: any) => (
                        <div
                          key={team.id !== null ? team.teamName : team.id}
                          className="mb-4 flex items-center justify-between"
                        >
                          <div
                            style={{
                              marginLeft:
                                '' +
                                (team.teamPath.split(';;').length - 1) +
                                'rem',
                            }}
                            className="flex items-center"
                          >
                            <PersonAvatar
                              inline
                              id={team.id}
                              name={team.teamName}
                              bgColor={team.color ? team.color : null}
                            ></PersonAvatar>
                            <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                              {team.teamName}
                            </p>
                          </div>
                          <div
                            data-testid="daleteItem"
                            className="my-auto ml-6"
                          >
                            <IconButton
                              Icon={CrossFilled}
                              variant="ghost"
                              onClick={() => handleRemove(team.id)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="mb-1 mt-4 min-w-full max-w-[300px] text-sm font-semibold text-black">
                  <p className="mb-1">
                    {t('pages.report_page.teams_tab.type_of_report')}
                  </p>
                  <div className="font-normal">
                    <InputSelect<InputSelectOption>
                      value={reportType}
                      onChange={(e: InputSelectOption) => setReportType(e)}
                      options={reportTypeList}
                    >
                      {(options) => (
                        <span>{options.label && t(options.label)}</span>
                      )}
                    </InputSelect>
                  </div>
                </div>

                <div className="mb-1 mt-4 text-sm font-semibold text-black sm:w-1/2">
                  <Label htmlFor="dateAutoFrom">
                    {t('pages.report_page.teams_tab.reports_from')}
                  </Label>
                  <InputDate
                    id="dateAutoFrom"
                    onChange={(date) => setFromDateAuto(date)}
                    value={fromDateAuto}
                    variant="date"
                  />
                </div>
                <div className="mb-1 mt-4 text-sm font-semibold text-black sm:w-1/2">
                  <Label htmlFor="frequency">
                    {t('pages.report_page.teams_tab.frequency')}
                  </Label>
                  <InputSelect<InputSelectOption>
                    value={frequency}
                    onChange={(e: InputSelectOption) => setFrequency(e)}
                    options={autoReportFrequency}
                  >
                    {(options) => <span>{options.label && options.label}</span>}
                  </InputSelect>
                </div>
                <div className="mb-1 mt-4 text-sm font-semibold text-black sm:w-1/2">
                  <Label htmlFor="language">
                    {t('pages.profile_account.language')}
                  </Label>
                  <div id="language">
                    <InputSelect<InputSelectOption>
                      value={language}
                      onChange={async (lang) => {
                        setLanguage(lang);
                      }}
                      options={languages}
                    >
                      {(options) => t(options.label)}
                    </InputSelect>
                  </div>
                </div>
                <p className="pt-6 text-base font-semibold text-black">
                  {t('pages.report_page.teams_tab.email_reports')}
                </p>
                <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
                  <div data-testid="autoReportEmail">
                    <Switch
                      checked={autoReportEmail}
                      onChange={(isCheck) => setAutoReportEmail(isCheck)}
                    />
                  </div>
                  <div>
                    <h4 className="pb-2 font-normal">
                      {t(
                        'pages.report_page.teams_tab.toggle_email_auto_heading'
                      )}
                    </h4>
                    <p className="text-xs text-grey-05">
                      {t('pages.report_page.teams_tab.toggle_email_auto_text')}
                    </p>
                  </div>
                </div>
                <div className="mb-1 mt-4 text-sm font-semibold text-black">
                  <Label htmlFor="sent_email">
                    {t('pages.report_page.teams_tab.sent_email')}
                  </Label>
                  <InputText
                    id="sent_email"
                    onChange={(e) => setEmailValue(e.currentTarget.value)}
                    value={emailValue}
                    className="w-full"
                  />
                </div>
                <div className="flex pt-6">
                  <Button
                    variant="outline"
                    onClick={handleAddEmails}
                    disabled={addEamilValid}
                  >
                    {t('pages.report_page.teams_tab.add_email')}
                  </Button>
                </div>
                {sentEmails?.length > 0 && (
                  <div className="mt-6">
                    {sentEmails?.map((email: any, index: any) => (
                      <div
                        key={index + email}
                        className="mb-1 flex items-center justify-between"
                      >
                        <p className="text-[12px] font-medium text-[#231f20] sm:text-sm">
                          {email}
                        </p>
                        <div data-testid="daleteItem" className="my-auto ml-6">
                          <IconButton
                            Icon={CrossFilled}
                            variant="ghost"
                            onClick={() => handleRemoveEmail(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="border-t border-solid border-grey-02">
            <Modal.Footer
              cancelButtonLabel={t('pages.manage_tags.cancel_button')}
              onCancel={closeModal}
            >
              <div className="flex gap-3">
                <Button
                  type="button"
                  disabled={teamsAutoReport?.length < 1}
                  onClick={() => sentReport()}
                >
                  {t('pages.report_page.teams_tab.btn_add_auto_report')}
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoReportModal;

import { Button, InputText, FilterList } from '@teo/components';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Search,
  ChevronDown,
  ChevronUp,
  Plus,
  Tag,
} from '@teo/components/icons';
import { stAxios } from '@/axios';
import i18n from 'i18next';

export const LibraryTab = ({
  setIsOpenLibraryModal,
  isOpenLibraryModal,
  tagsFilter,
  lessonPage,
  setUploadFile,
}) => {
  const [filterInput, setFilterInput] = useState('');
  const [showLast, setShowLast] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [filter, setFilter] = useState(null);

  const [selected, setSelected] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    stAxios
      .get(
        `https://api.shutterstock.com/v2/images/search?language=${
          i18n.language
        }${filter ? filter : ''}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer v2/OHh4Q3NkTktkdm1LR1BIdnVHbnhVWWtVbzV2TGpwazcvMjYyNTI3MDE2L2N1c3RvbWVyLzQvd05wZ0RvUDJQcXk4djdEeWJUR3VUeG9CTVRpWE8zM0dla19YX2pHbmJoS3FRWTE0VWJqUldOZWNSUlZaeWlxa28yRGZqM0daeFFBZ3hPLUt0bVBvRC1KME02YTJ2RTczNURFLWJxZnZIeHdYZ21yekltWXRuUFAydVNiRURVbW1SRDNnWFE4NWlTUDAxOXRUXzVMbkhKWnRrRTlCaFZLUEU3cS1NUGFpWlQ1MVBBOGlEYnYxYm5nbXY0VEdIeTFfaFM3b1JXMmxZVTBxZ0NndkRqd3pkQS9DNldxeVdXYnByaXBTcFdDcE5vcUhB`,
          },
        }
      )
      .then(({ data }) => {
        setImageData(data?.data);
      });
  }, [filter]);

  useEffect(() => {
    if (filterInput?.length > 2 || selected?.length > 0) {
      const select = selected?.map((el) => el.value).join(' ');
      setFilter(`&query=${filterInput} ${select}`);
    } else {
      setFilter();
    }
  }, [selected, filterInput]);

  const uploadeImage = async (image) => {
    if (lessonPage) {
      const res = await fetch(image?.assets?.preview_1000?.url);
      const blob = await res.blob();
      setUploadFile((prev) => [...prev, blob]);
      setIsOpenLibraryModal(!isOpenLibraryModal);
    } else {
      setUploadFile(image?.assets?.preview_1000?.url);
      setIsOpenLibraryModal(!isOpenLibraryModal);
    }
  };

  return (
    <div className="-mx-6 max-h-[80vh] overflow-hidden pt-5">
      <div className="px-6 pb-5">
        <InputText
          value={filterInput}
          onChange={(ev) => {
            setFilterInput(ev.target.value);
          }}
          Icon={Search}
          placeholder={t('modal_library.placeholder')}
        />
      </div>
      <div className="mb-6 flex flex-row px-6">
        <div>
          <div className="flex items-center">
            <Tag className="mr-4 w-5 text-grey-07" />
            <FilterList
              filters={tagsFilter}
              onSelect={setSelected}
              selected={selected}
            />
          </div>
        </div>
        <Button
          variant="outline"
          size="sm"
          className="ml-auto"
          onClick={() => {
            setMoreFilter(!moreFilter);
          }}
        >
          {moreFilter
            ? t('pages.create_edit_course.library.fewer_filters')
            : t('pages.create_edit_course.library.more_filters')}
        </Button>
      </div>
      <div className="flex flex-row items-center px-6">
        <h4 className="mb-2 text-base">{t('modal_library.heading')}</h4>
        <span
          className="ml-auto flex cursor-pointer items-center text-xs"
          onClick={() => setShowLast(!showLast)}
        >
          {t('pages.create_edit_course.library.latest_btn')}
          {showLast ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </span>
      </div>
      <div className="flex max-h-[40vh] w-full flex-wrap overflow-y-scroll px-6">
        {imageData?.length > 0 &&
          imageData?.map((el, index) => {
            return (
              <div key={'image_' + index} className="basis-1/3 p-2">
                <div className="relative h-full w-full">
                  <div
                    className="absolute inset-0 z-[1] flex cursor-pointer rounded-lg bg-zinc-400/70 opacity-0 hover:opacity-100"
                    onClick={() => {
                      uploadeImage(el);
                    }}
                  >
                    <Plus className="m-auto h-8 w-8 rounded-full bg-grey-02 text-grey-08" />
                  </div>
                  <img
                    src={el?.assets?.preview_1000?.url}
                    alt={el?.description}
                    className="h-full w-full rounded-lg object-cover"
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

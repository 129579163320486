import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import { Stage, Text, Sprite, Container, TextStyle } from '@pixi/react';
import { BoxIoService } from '../BoxIoService';
import { Draggable } from './Draggable';
import { calculateUnit, round2 } from '@/util';

const SNAP_ANGLES = [270, 294, 314, 338, 0, 25, 45, 70, 90];
const SYMBOLS = ['V', 'V', 'V', 'V', 'Ω', 'A', 'A', 'A', 'A', 'A'];

//console.log(SNAP_ANGLES)
//const SNAP_ANGLES = [0, 24, 44, 68, 90, 115, 135, 160, 180]

class MultiMeter extends Component {
  state = {
    voltage: 0,
    option: 0,
  };

  constructor(props) {
    super(props);
    this.service = BoxIoService.getInstance();
    this.service.onAnalog.subscribe((msg) => {
      if (msg.name === props.name) {
        let value = calculateUnit(msg.value, 'volt', this.props.maxVolt);
        this.setState({ voltage: value });
      }
    });
    this.switch = createRef();
  }

  componentDidMount() {
    this.switch.current.angle = -90;
  }

  onOrientStart = (event) => {
    let sprite = event.currentTarget;
    sprite.orienting = true;
    event.stopPropagation();
  };

  onOrientEnd = (event) => {
    let sprite = event.currentTarget;
    if (sprite.orienting) sprite.orienting = false;
  };

  onOrientMove = (event) => {
    let sprite = event.currentTarget;
    if (sprite.orienting) {
      let spritePos = sprite.getGlobalPosition();
      let x = event.data.global.x - spritePos.x;
      let y = event.data.global.y - spritePos.y;

      let rad = Math.atan2(y, x);
      let deg = rad * (180 / Math.PI);
      if (deg < 0) deg += 360;

      let min_distance = 360,
        snap_angle = SNAP_ANGLES[0],
        option = this.state.option;
      for (let i in SNAP_ANGLES) {
        let angle = SNAP_ANGLES[i];
        let dist_sq = Math.abs(angle - deg);
        if (dist_sq < min_distance) {
          min_distance = dist_sq;
          snap_angle = angle;
          option = i;
        }
      }

      if (option != this.state.option) {
        sprite.angle = snap_angle;
        this.setState({ option });
      }
    }
  };

  render() {
    return (
      <Draggable scale={[0.3, 0.3]}>
        <Sprite image="https://teo-s3-prod.s3.eu-west-1.amazonaws.com/PIXI/multimeter.png" />
        <Sprite
          image="https://teo-s3-prod.s3.eu-west-1.amazonaws.com/PIXI/multimeter_button.png"
          anchor={0.5}
          pivot={[0.5, 0.5]}
          x={167}
          y={512}
          ref={this.switch}
          interactive
          buttonMode
          pointerdown={this.onOrientStart}
          pointerup={this.onOrientEnd}
          pointerupoutside={this.onOrientEnd}
          pointermove={this.onOrientMove}
        />
        <Text
          x={80}
          y={130}
          text={this.state.voltage.toFixed(2)}
          style={{
            align: 'center',
            fontFamily: '"Poppins", sans-serif',
            fontSize: 80,
          }}
        />
        <Text
          x={300}
          y={130}
          text={SYMBOLS[this.state.option]}
          style={{
            align: 'center',
            fontFamily: '"Poppins", sans-serif',
            fontSize: 80,
          }}
        />
      </Draggable>
    );
  }
}

MultiMeter.displayName = 'MultiMeter';

export { MultiMeter };

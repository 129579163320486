import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { axios } from '@/axios';
import { config } from '@/config';
import {
  RadioGroup,
  InputPhoneNumber,
  Checkbox,
  Label,
  Button,
  ErrorMessage,
} from '@teo/components';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useOrganisations } from '@/query';

type Country = {
  callingCode: string;
  countryCode: string;
};

export type PhoneNumber = {
  country: Country;
  phone: string;
};

const Step7 = ({
  step,
  setStep,
  setInfo,
  user,
  info,
  userInfo,
  lessonInfo,
  followInfo,
  courseInfo,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [checkValid, setCheckValid] = useState<boolean>(false);
  const [mailPhone, setMailPhone] = useState<string>('phone');
  const [pnumber, setPnumber] = useState<PhoneNumber>();
  const [agreedToConditions, setAgreedToConditions] = useState(false);
  const [addressesId, setAddressesId] = useState();
  const [action, setAction] = useState<boolean>(false);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const { courseId, organisationId } = useParams();

  const { data: organisations } = useOrganisations({
    userId: user?.id as number,
  });

  const basePath = window.location.pathname;
  const coursePath = basePath.slice(0, basePath.lastIndexOf('/'));

  const checkOrg = organisations?.find((obj) => obj.id == organisationId)
    ? true
    : false;

  useEffect(() => {
    if (agreedToConditions && pnumber) {
      setInfo((prev: any) => ({
        ...prev,
        agreedToConditions,
        contactPref: mailPhone,
        phone:
          pnumber?.country?.callingCode &&
          pnumber?.country?.callingCode + pnumber?.phone,
      }));
    }
  }, [agreedToConditions, pnumber]);

  useEffect(() => {
    if (action) {
      const longitude = info?.location?.geometry?.location.lng();
      const lattitude = info?.location?.geometry?.location.lat();
      const locationValue =
        info?.location?.address_components &&
        `${
          info?.location?.address_components?.find(
            (obj: any) => obj?.types[0] == 'locality'
          )?.long_name
        } - ${
          info?.location?.address_components?.find(
            (obj: any) => obj?.types[0] == 'country'
          )?.long_name
        }`;
      if (userInfo) {
        axios.put(`${config.backend}/userinfo/${user?.id}`, {
          id: user?.id,
          // lastFunctionId: 5446,
          locationRange: info?.locationRange,
          locationStr: locationValue,
          locationLatitude: lattitude,
          locationLongitude: longitude,
          structured: {
            street: null,
            number: null,
            zip: null,
            bus: null,
            city: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'locality'
            )?.long_name,
            country: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'country'
            )?.long_name,
            countryCode: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'country'
            )?.short_name,
            region: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'administrative_area_level_1'
            )?.long_name,
            province: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'administrative_area_level_2'
            )?.long_name,
            googlePlaceId: info?.location?.place_id,
            longitude: longitude,
            lattitude: lattitude,
          },
          regime: info?.working_hours,
          motivation: info?.participating,
          birthDate: info?.birth,
          diploma: info?.degree,
          intrest: info?.interest,
          gender: info?.gender?.value,
          addressId: addressesId ? addressesId : null,
        });
      } else {
        axios.post(`${config.backend}/userinfos`, {
          id: user?.id,
          // lastFunctionId: 5446,
          locationRange: info?.locationRange,
          locationStr: `${
            info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'locality'
            )?.long_name
          } - ${
            info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'country'
            )?.long_name
          }`,
          locationLatitude: lattitude,
          locationLongitude: longitude,
          structured: {
            street: null,
            number: null,
            zip: null,
            bus: null,
            city: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'locality'
            )?.long_name,
            country: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'country'
            )?.long_name,
            countryCode: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'country'
            )?.short_name,
            region: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'administrative_area_level_1'
            )?.long_name,
            province: info?.location?.address_components?.find(
              (obj: any) => obj?.types[0] == 'administrative_area_level_2'
            )?.long_name,
            googlePlaceId: info?.location?.place_id,
            longitude: longitude,
            lattitude: lattitude,
          },
          regime: info?.working_hours,
          motivation: info?.participating,
          birthDate: info?.birth,
          diploma: info?.degree,
          intrest: info?.interest,
          gender: info?.gender,
          addressId: addressesId ? addressesId : null,
        });
      }
    }
  }, [action, addressesId]);

  useEffect(() => {
    if (isOnboardingComplete && action) {
      setTimeout(() => {
        navigate(`${coursePath}/lesson/${lessonInfo[0]?.id}`);
      }, 300);
    }
  }, [isOnboardingComplete, action]);

  const nextButton = async () => {
    setCheckValid(true);
    if (agreedToConditions && (pnumber || mailPhone !== 'phone')) {
      setAction(true);
      const longitude = info?.location?.geometry?.location.lng();
      const lattitude = info?.location?.geometry?.location.lat();
      //user
      axios.put(`${config.backend}/user/${user?.id}`, {
        id: user?.id,
        firstName: info?.firstName,
        lastName: info?.name,
        phone: info?.phone,
        agreedToConditions: info?.agreedToConditions,
        username: info?.firstName + info?.name,
        preferredContact: mailPhone,
      });

      if (info?.location) {
        const newAddresses = await axios.post(`${config.backend}/addresses`, {
          street: null,
          number: null,
          zip: null,
          bus: null,
          city: info?.location?.address_components?.find(
            (obj: any) => obj?.types[0] == 'locality'
          )?.long_name,
          country: info?.location?.address_components?.find(
            (obj: any) => obj?.types[0] == 'country'
          )?.long_name,
          countryCode: info?.location?.address_components?.find(
            (obj: any) => obj?.types[0] == 'country'
          )?.short_name,
          region: info?.location?.address_components?.find(
            (obj: any) => obj?.types[0] == 'administrative_area_level_1'
          )?.long_name,
          province: info?.location?.address_components?.find(
            (obj: any) => obj?.types[0] == 'administrative_area_level_2'
          )?.long_name,
          googlePlaceId: info?.location?.place_id,
          longitude,
          lattitude,
        });
        setAddressesId(newAddresses?.data?.id);
      }

      // add user to organisation
      !checkOrg &&
        axios.post(`${config.backend}/members`, {
          organisationId: organisationId,
          userId: user?.id,
          role: 10,
        });

      // add user to the course
      const completeOnboarding: any = await axios.put(
        `${config.backend}/follow/${courseId}/${user?.id}`,
        {
          userId: user?.id,
          courseId: courseId && parseInt(courseId, 10),
          referrer: info?.referrer,
          contactPref: info?.contactPref,
          onboardingComplete: true,
        }
      );
      setIsOnboardingComplete(completeOnboarding?.data?.onboardingComplete);
    }
  };
  const prevButton = () => {
    const nextStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_current_work_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_diploma_step')
      ? 2
      : courseInfo?.onboardingSteps?.includes('onboarding_referral_step')
      ? 3
      : courseInfo?.onboardingSteps?.includes('onboarding_regime_step')
      ? 4
      : courseInfo?.onboardingSteps?.includes('onboarding_location_step')
      ? 5
      : 6;
    setStep((prev: number) => prev - nextStep);
  };

  const handleError = (el: any) => (el ? false : checkValid ? true : false);

  if (step !== 7) {
    return null;
  }

  return (
    <div>
      <p className="mb-2">{t(`pages.onboarding.step_7.subtitle`)}</p>
      <div>
        <RadioGroup
          label=""
          onChange={(value: string) => {
            setMailPhone(value);
          }}
          value={mailPhone}
        >
          <RadioGroup.Option value="phone">
            {t(`pages.onboarding.step_7.email_phone`)}
          </RadioGroup.Option>
          <div className="my-2 text-sm font-semibold text-black">
            <Label htmlFor="phoneNumber">
              {t(`pages.onboarding.step_7.phone`)}
            </Label>
            <div
              id="phoneNumber"
              data-testid="phoneNumber"
              className="text-base"
            >
              <InputPhoneNumber
                countryCodes={[
                  {
                    callingCode: '+32',
                    countryCode: 'BE',
                  },
                  {
                    callingCode: '+31',
                    countryCode: 'NL',
                  },
                  {
                    callingCode: '+351',
                    countryCode: 'PT',
                  },
                  {
                    callingCode: '+44',
                    countryCode: 'UK',
                  },
                ]}
                onChange={(val: PhoneNumber) => {
                  setPnumber(val);
                }}
                value={
                  pnumber
                    ? pnumber
                    : {
                        country: {
                          callingCode: '+32',
                          countryCode: 'BE',
                        },
                        phone: '',
                      }
                }
                placeholder="12345"
              />
            </div>
            {mailPhone == 'phone' && handleError(pnumber?.phone) && (
              <ErrorMessage id="errorMessage">
                {t('pages.onboarding.error.text_fiels')}
              </ErrorMessage>
            )}
          </div>
          <RadioGroup.Option value="email">
            {t(`pages.onboarding.step_7.only_email`)}
          </RadioGroup.Option>
        </RadioGroup>
      </div>

      <div className="mt-10">
        <p>{t(`pages.onboarding.step_7.subtitle_3`)}</p>

        <div className="mt-4 flex items-center">
          <Checkbox
            id="agreedToConditions"
            ariaLabel="test"
            onChange={() => setAgreedToConditions(!agreedToConditions)}
            checked={agreedToConditions}
          />

          <label
            htmlFor="agreedToConditions"
            className="pl-2.5 text-sm text-grey-08"
          >
            {t('pages.auth.register.agreement_text')}
            <Link
              className="inline-block pl-2 pr-2 text-sm text-tint-blue hover:underline"
              to="../privacy"
            >
              {t('pages.auth.register.privacy')}
            </Link>
            {t('pages.auth.register.agreement_text2')}
            <Link
              className="inline-block text-sm text-tint-blue hover:underline"
              to="../voorwaarden"
            >
              {t('pages.auth.register.terms_of_use')}
            </Link>
          </label>
        </div>
        {!agreedToConditions && checkValid && (
          <ErrorMessage id="errorMessage">
            {t('pages.onboarding.error.agree')}
          </ErrorMessage>
        )}
      </div>

      <div className="flex w-full pt-10">
        <Button
          variant="outline"
          className={`mr-2 flex-[1_1_30%] ${step === 1 && 'hidden'}`}
          onClick={prevButton}
        >
          {t(`pages.onboarding.button_prev`)}
        </Button>

        <Button
          className={`${step !== 1 && 'ml-2 flex-[1_1_30%]'}`}
          onClick={nextButton}
        >
          {t(`pages.onboarding.button_go`)}
        </Button>
      </div>
    </div>
  );
};
export default Step7;

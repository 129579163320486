export const faqTopics = [
  'general',
  'learn',
  'create',
  'follow_up',
  'manage',
  'organisation',
];

export const faqTopicsDisplay = [
  'general',
  'learn',
  'create',
  'follow_up',
  'manage',
  //'organisation',
];

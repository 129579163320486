import { useState, useContext, useEffect, useRef } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { NavContext } from '@/components/NavContext';
import { Button, IconButton, Spinner } from '@teo/components';
import { Sidebar } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { LessonTree } from '@/components/LessonTree/LessonTree';
import { LessonView } from '@/components/LessonView';
import {
  newUid,
  isEmpty,
  deep_copy,
  useStateAndRef,
  useAsyncState,
  scrollIntoViewWithOffset,
  nthIndexOf,
} from '@/util';
import { config } from '@/config';
import { axios } from '@/axios';
import { XAPIService } from '@/services/xapi.service';
import { ScormService } from '@/services/scorm.service';
import { TinCanService } from '@/services/tincan.service';
import { useMediaQuery } from 'react-responsive';
import { Userwithorganisationwithidps } from '@/models';
import LessonHeader from './LessonHeader';
import { CourseSuccess } from './CourseSuccess';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import NotificationMessage from '@/components/NotificationMessage/NotificationMessage';
import i18n from 'i18next';
import IncompleteModal from '@/components/IncompleteModal';
import { ExtraInfoLesson } from './ExtraInfoLesson';
import { ImageZoom } from '@/components/ImageZoom';
import ScormLessonPage from '../ScormCourse/ScormLessonPage';
import Cmi5, { LaunchParameters } from '@xapi/cmi5';

function LessonPage({ result }: any) {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search);
  const prevPage = queryParams.get('details');
  const qr_share = queryParams.get('qr_code');
  const authUser = auth?.user as Userwithorganisationwithidps;

  const { setNavDisabled } = useContext(NavContext);
  const { courseId, lessonId, organisationId } = useParams();

  let { studentId } = useParams();

  let itSMe = false;
  if (!studentId) {
    itSMe = true;
    studentId = authUser?.id as unknown as string;
  }
  const isTeacher = !itSMe;

  const navigate = useNavigate();
  const [course, setCourse] = useState<any>(null);

  const [student, setstudent] = useState<any>(null);
  const [activeLesson, setActiveLesson, activeLessonRef] = useStateAndRef(null);
  const [lastTickTime, setLastTickTime] = useState<any>(new Date());
  const [isScormScourse, setIsScormScourse] = useState<boolean>(false);

  const isPreview =
    window.location.pathname.includes('/preview/') || !studentId;
  const [statuses, setStatuses, statusRef] = useStateAndRef(null);
  const [statusesAssets, setStatusesAssets, statusesAssetsRef] =
    useStateAndRef(null);
  const [showCourseSuccess, setShowCourseSuccess] = useState(false);

  const expectAStudent = !isPreview;
  const expectAStatus = expectAStudent && !isTeacher;

  const [loaded, setLoaded, setLoadedRef] = useStateAndRef(null);
  const [nextLesson, setNextLesson] = useState<any>(null);
  const [previousLesson, setPreviousLesson] = useState<any>(null);

  const [hideSidebar, setHideSidebar] = useState<boolean>(false);
  const [hideContent, setHideContent] = useState<boolean>(false);
  const [submitFn, setSubmitFn, submitFnRef] = useStateAndRef(undefined);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [nrOfPages, setNrOfPages] = useState(1);
  const [page, setPage] = useState(0);

  const [loadeContent, setLoadeContent] = useState(false);

  const [isIncomplete, setIsIncomplete] = useState(false);
  const [incompleteWidgets, setIncompleteWidgets] = useState(null);

  const [allExtraInfo, setAllExtraInfo] = useState(null);
  const [rootCourseId, setRootCourseId] = useState(null);
  const [allDirectories, setAllDirectories] = useState(null);
  const [allAssets, setAllAssets] = useState(null);

  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [extraInfoFullScreen, setExtraInfoFullScreen] = useState(false);
  const [activeExtraInfo, setActiveExtraInfo] = useState(null);
  const [lengthExtraInfo, setLengthExtraInfo] = useState(0);
  const [numLesAs, setNumLesAs] = useState<any>(0);
  const [hasWidgets, setHasWidgets] = useState<boolean>(false);
  const [isLessonLoaded, setIsLessonLoaded] = useState(true);
  const [isLessonLoadedSub, setIsLessonLoadedSub] = useState(false);

  const [imageZoom, setImageZoom] = useState(false);

  const basePath = window.location.pathname;
  const coursePath = isTeacher
    ? `/${i18n.language}/follow-up/${organisationId}/courses/details/${courseId}`
    : basePath.slice(0, basePath.lastIndexOf('/lesson'));

  const { notification, setShowNotification } = useContext(NotificationContext);
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const iSsmallLaptop = useMediaQuery({
    query: '(max-width: 1199px)',
  });

  const activeStatus = useRef<any>(null);
  const activeStatusAsset = useRef<any>(null);

  //this part registers time, it reports the time spent in ticks every 60s or 5s depending
  //on whether a lesson is explicitly timed or not. When leavind a lesson the difference
  //between lastTickTime and Date.now() is added;
  //started by timetickTimerReset.current()
  //stopped by clearInterval(timetickTimer.current)
  const timetickTimer: any = useRef(null);
  const timetickTimerReset: any = useRef(null);

  useEffect(() => {
    try {
      const xapi = XAPIService.getInstance();
      const sessionState = xapi.initialize();
      sessionState.then(() => {
        if (!xapi.available()) return;
        const authToken = xapi.cmi5.getAuthToken();
        if (authToken) {
          const launchParameters = xapi.cmi5.getLaunchParameters();
          launchParameters.authToken = authToken;
          axios.put(`${config.backend}/follow/${courseId}/${authUser.id}`, {
            xapidetails: JSON.stringify(launchParameters),
          });
          localStorage.setItem('xapitoken', authToken);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    timetickTimerReset.current = () => {
      clearInterval(timetickTimer.current);
      const activeLesson = activeLessonRef?.current;
      const delay = activeLesson.timed == 'default' ? 60 : 5; //TODO
      if (activeLesson?.lessonId) {
        timetickTimer.current = setInterval(async () => {
          //!important functions inside intervaal do not have access to regular state
          const activeLesson = activeLessonRef?.current;
          const newStatuses = deep_copy(statusRef?.current);
          const i = newStatuses?.findIndex(
            (x: any) => x?.lessonId === activeLesson?.lessonId
          );
          const status = newStatuses[i];
          setLastTickTime(new Date());

          if (status.id) {
            await axios.put(`${config.backend}/follows/timetick/${status.id}`, {
              seconds: delay,
            });
          }
        }, delay * 1000);
      }
    };
    return () => {
      clearInterval(timetickTimer.current);
    };
  }, []);

  /*
  //this part registers autosave, which saves the results automatically
  //if not submitted.
  //Start by autosaveTimerReset.current()
  //stopped by clearInterval(autosaveTimer.current)
  const autosaveTimer: any = useRef(null);
  const autosaveTimerReset: any = useRef(null);

  useEffect(() => {
    autosaveTimerReset.current = () => {
      clearInterval(autosaveTimer.current);
      autosaveTimer.current = setInterval(async () => {
        const doAutosave = doAutosaveRef.current;
        if (submitFnRef?.current && doAutosave) {
          submitFnRef.current(false);
        }
      }, 2 * 60 * 1000);
    };
    return () => {
      clearInterval(autosaveTimer.current);
    };
  }, []);
  */

  const userId = studentId ? studentId : auth?.user?.id;

  // const showContent =
  //   !isTeacher ||
  //   (activeStatus?.current?.status &&
  //     activeStatus?.current?.status != 'started' &&
  //     activeStatus?.current?.status != 'completed');
  const showContent = !isTeacher || activeStatus?.current?.status;

  const hideScore = !isTeacher && course?.courseType === 'assessment';

  useEffect(() => {
    if (activeLesson && (student || !expectAStudent)) {
      if ((statuses || !expectAStatus) && activeLesson?.lessonId) {
        const newStatus =
          statuses?.find((x: any) => x?.lessonId == activeLesson?.lessonId) ||
          null;
        activeStatus.current = newStatus;
      }
      if ((statusesAssets || !expectAStatus) && activeLesson?.assetId) {
        const newStatus =
          statusesAssets?.find(
            (x: any) => x?.assetId == activeLesson?.assetId
          ) || null;
        activeStatusAsset.current = newStatus;
      }
      if (!setLoadedRef.current) setLoaded(newUid(20));
    }
  }, [statuses, statusesAssets, activeLesson, student]);

  useEffect(() => {
    if (loaded) {
      enterLesson();
    }
  }, [loaded]);

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  useEffect(() => {
    if (showExtraInfo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showExtraInfo]);

  useEffect(() => {
    hideContent && setActiveLesson({});
  }, [hideContent]);

  useEffect(() => {
    if (activeLesson?.assetId) {
      setLoadeContent(true);
      setSubmitFn(null);
    }
  }, [activeLesson]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight - windowHeight;
      const scrolled = window.scrollY;
      const persentage = (scrolled / scrollHeight) * 100;
      setScrollPercentage(persentage);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (loaded) leaveLesson();
    };
  }, []);

  useGet(`/coursedetail/${courseId}`, {
    onSuccess: (result: any) => {
      if (result?.data?.startsWith?.('scorm')) {
        setIsScormScourse(true);
        const basePath = window.location.pathname.slice(
          0,
          nthIndexOf(window.location.pathname, '/', 3)
        );
        return navigate(`${basePath}/scormCourses/${result?.data?.id}`);
      }
      setCourse(result?.data || {});
    },
  });

  const [isFollow, setIsFollow] = useState(false);

  useGet(`/follow/${courseId}/${auth?.user?.id}`, {
    retry: false,
    onSuccess: (result: any) => {
      setIsFollow(true);

      const currentUrl = new URL(window.location.href);

      if (currentUrl.searchParams.has('qr_code')) {
        currentUrl.searchParams.delete('qr_code');

        window.history.replaceState(null, '', currentUrl.toString());
      }
    },
    onError: (res: any) => {
      if (isTeacher) {
        setIsFollow(true);
      }
      if (qr_share) {
        navigate(basePath.slice(0, basePath.lastIndexOf('/les')));
      }
    },
  });

  const { refetch: refetchStudentInfo } = useGet(
    `/student/${courseId}/${userId}`,
    {
      enabled: !!userId && isFollow,
      onSuccess: async (result: any) => {
        const xapi = XAPIService.getInstance();
        const scorm = ScormService.getInstance();
        const tincan = TinCanService.getInstance();
        const newFollow = result?.data;

        try {
          if (scorm.available()) {
            if (newFollow.progress >= 0.99 && newFollow.tasksRemaining === 0) {
              scorm.reportFinal(newFollow, course);
            } else {
              scorm.progress(newFollow.progress, newFollow.totalTimeSpent);
            }
          }
        } catch (e) {
          console.warn(e);
        }

        try {
          xapi.initialize();
          await xapi.initializedPromise;
          if (xapi.available()) {
            if (newFollow.progress >= 0.99 && newFollow.tasksRemaining === 0) {
              xapi.reportFinal(newFollow, course);
            } else {
              xapi.progress(newFollow.progress);
            }
          }
        } catch (e) {
          console.error(e);
        }

        try {
          tincan.reportStatus(newFollow, course);
        } catch (e) {
          console.error(e);
        }

        setstudent(result?.data || {});
      },
    }
  );

  useGet(`/lessonstatuses`, {
    courseId: courseId,
    userId: userId,
    enabled: !isPreview,
    onSuccess: (result: any) => {
      setStatuses(result?.data);
    },
  });

  useGet(`/assetstatuses`, {
    courseId: courseId,
    userId: userId,
    enabled: !isPreview,
    onSuccess: (result: any) => {
      setStatusesAssets(result?.data);
    },
  });

  const handleSidebar = () => {
    setHideSidebar(!hideSidebar);
  };

  useEffect(() => {
    if (activeLesson) {
      setIsLessonLoadedSub(false);
    }
  }, [activeLesson]);

  const handleReplaceParam = async (lesson: any) => {
    const searchString = 'lesson/' + lessonId;
    const replacementString = 'lesson/' + lesson;
    const modifiedString = basePath.replace(searchString, replacementString);
    if (lesson) {
      navigate({
        pathname: lesson ? modifiedString : window.location.pathname,
        search: window.location.search,
      });
    } else {
      setShowCourseSuccess(true);
    }
  };

  const submit = (force = false, widget: any = undefined) => {
    setIsLessonLoadedSub(true);
    setIsIncomplete(false);
    submitFn(true, force, widget).then(async (result: any) => {
      if (result && result?.status === 'incomplete') {
        try {
          scrollIntoViewWithOffset(`#${result?.uid}`, 100);
        } catch (e) {
          console.error(e);
        }
        // setShowNotification(t('notifications.result_incomplete'));
        setIsIncomplete(true);
        setIncompleteWidgets(result);
        setIsLessonLoadedSub(false);
        return;
      }
      if (!result) {
        console.error(result);
        setShowNotification(t('notifications.lesson_submit_failed'));
        return;
      }
      setShowNotification(t('notifications.lesson_submitted'));
      //status update is handled by BE, but fetch the new status
      await axios
        .get(`${config.backend}/lessonstatus/${courseId}/${userId}/${lessonId}`)
        .then(async (result) => {
          await updateStatus(result?.data);
          return result?.data;
        });

      if (expectAStatus) {
        const status = activeStatus?.current;
        if (status?.id) {
          await axios.put(`${config.backend}/follows/timetick/${status.id}`, {
            seconds: Math.abs(
              new Date().getSeconds() - lastTickTime.getSeconds()
            ),
          });
        }

        setLastTickTime(new Date());
        clearInterval(timetickTimer.current);
      }
      gotoLesson(nextLesson, false);
    });
  };

  const gotoLesson = async (lessson: any, autoSave = true) => {
    setIsLessonLoaded(true);
    if (loaded && lessson?.id !== activeLesson?.lessonId && autoSave) {
      await leaveLesson();
    }
    setLoaded(null);
    refetchStudentInfo();
    handleReplaceParam(lessson);
  };

  const updateStatus = (status: any) => {
    const newStatuses = deep_copy(statusRef?.current); //!important functions inside intervaal do not have access to regular state
    const i = newStatuses?.findIndex(
      (x: any) => x?.lessonId == status?.lessonId
    );
    if (i !== -1) {
      newStatuses[i] = status;
    } else {
      newStatuses.push(status);
    }
    setStatuses(newStatuses);
  };

  const updateStatusAssets = (status: any) => {
    const newStatuses = deep_copy(statusesAssetsRef?.current);

    const i = newStatuses?.findIndex((x: any) => x?.assetId == status?.assetId);
    if (i !== -1) {
      newStatuses[i] = status;
    } else {
      newStatuses.push(status);
    }
    setStatusesAssets(newStatuses);
  };

  const enterLesson = async () => {
    const activeLesson = activeLessonRef?.current;
    const status = activeStatus?.current;
    const statusAsset = activeStatusAsset?.current;

    const lessonFinished =
      status?.status === 'corrected' ||
      status?.status === 'submitted' ||
      status?.status === 'completed';

    //clearInterval(autosaveTimer.current);
    clearInterval(timetickTimer.current);
    setLastTickTime(new Date());

    window.scrollTo(0, 0);
    setScrollPercentage(0);
    //triggered when entering a lesson

    if (
      (activeLesson?.lessonId || activeLesson?.assetId) &&
      student &&
      !isTeacher
    ) {
      axios.put(`${config.backend}/follow/${courseId}/${userId}`, {
        courseId: courseId,
        currentLessonId: activeLesson.lessonId || activeLesson?.assetId,
        userId: userId,
      });

      //if ((!status || status.lessonId !== activeLesson?.lessonId) && expectAStatus) {

      if (!status && expectAStatus && activeLesson?.lessonId) {
        await axios
          .post(`${config.backend}/lessonstatuses`, {
            status: 'started',
            version: activeLesson?.version,
            coursecontentId: course?.coursecontentId,
            courseId: courseId,
            userId: userId,
            lessonId: activeLesson?.lessonId,
          })
          .then((result) => {
            if (result?.data) {
              updateStatus(result?.data);
            }
          });
      }
      if (!statusAsset && expectAStatus && activeLesson?.assetId) {
        await axios
          .post(`${config.backend}/assetstatuses`, {
            status: 'started',
            coursecontentId: course?.coursecontentId,
            courseId: courseId,
            userId: userId,
            assetId: activeLesson?.assetId,
          })
          .then((result) => {
            if (result?.data) {
              updateStatusAssets(result?.data);
            }
          });
      }

      //we have an accurate status
      if (!lessonFinished && expectAStatus) {
        timetickTimerReset.current();
        //autosaveTimerReset.current();
      }
    }
  };

  const submitDisabled =
    activeStatus?.current?.status === 'corrected' ||
    activeStatus?.current?.status === 'submitted' ||
    (!isTeacher && activeLesson?.submitByTeacher);

  const leaveLesson = async () => {
    setLoaded(null);
    const status = activeStatus?.current;
    const statusAsset = activeStatusAsset?.current;

    if (activeLesson?.lessonId) {
      if (!status) return;

      const lessonFinished =
        status?.status === 'corrected' ||
        status?.status === 'submitted' ||
        status?.status === 'completed';

      clearInterval(timetickTimer.current);
      if (!lessonFinished && expectAStatus) {
        if (status.id) {
          const result = axios.put(
            `${config.backend}/follows/timetick/${status.id}`,
            {
              seconds: Math.abs(
                new Date().getSeconds() - lastTickTime.getSeconds()
              ),
            }
          );
        }
      }
      setLastTickTime(new Date());

      const submitDisabled =
        status?.status === 'corrected' || status?.status === 'submitted';
      //clearInterval(autosaveTimer.current);

      if (submitFn && !lessonFinished && !submitDisabled) {
        await submitFnRef.current(false);
      }

      if (
        status &&
        status?.status === 'started' &&
        !submitFn &&
        expectAStatus
      ) {
        const result = await axios.put(
          `${config.backend}/lessonstatus/${courseId}/${userId}/${activeLesson?.lessonId}`,
          {
            courseId: status?.courseId,
            userId: status?.userId,
            status: 'completed',
          }
        );
        updateStatus(result?.data);
      }
    }
    if (activeLesson?.assetId) {
      if (!statusAsset) return;

      if (
        statusAsset &&
        statusAsset?.status === 'started' &&
        !submitFn &&
        expectAStatus
      ) {
        const result = await axios.put(
          `${config.backend}/assetstatus/${courseId}/${userId}/${activeLesson?.assetId}`,
          {
            assetId: activeLesson?.assetId,
            courseId: courseId,
            userId: userId,
            status: 'completed',
          }
        );
        updateStatusAssets(result?.data);
      }
    }
  };

  const timeSpent = activeStatus?.current?.timeSpent;
  if (showCourseSuccess) {
    return (
      <CourseSuccess
        onClose={() => setShowCourseSuccess(false)}
        studentId={studentId || userId}
        course={course}
      />
    );
  }

  const lessonFinished =
    activeStatus?.current?.status === 'corrected' ||
    activeStatus?.current?.status === 'submitted' ||
    activeStatus?.current?.status === 'completed';

  return (
    <div className="flex w-full flex-col lg:min-h-[calc(100vh_-_70px)]">
      {!loadeContent && result && (
        <div className="fixed inset-0 z-[12] flex bg-white">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-16 !w-16 border-grey-08"
            />
          </div>
        </div>
      )}
      {!statuses && !isPreview ? null : (
        <>
          <LessonHeader
            isPreview={isPreview}
            coursePath={coursePath}
            isTeacher={isTeacher}
            student={student}
            course={course}
            setHideContent={setHideContent}
            hideContent={hideContent}
            timeDisabled={lessonFinished}
            activeLesson={activeLesson}
            timeSpent={timeSpent}
            userId={userId}
            submitFn={() => submit(true)}
            handleReplaceParam={gotoLesson}
            nextLesson={nextLesson}
            activeStatus={activeStatus?.current}
            setShowExtraInfo={setShowExtraInfo}
            setExtraInfoFullScreen={setExtraInfoFullScreen}
            extraInfoFullScreen={extraInfoFullScreen}
            setActiveExtraInfo={setActiveExtraInfo}
            lengthExtraInfo={lengthExtraInfo}
            iSsmallLaptop={iSsmallLaptop}
          />
          {notification && <NotificationMessage />}
          <div
            className="progress-bar"
            style={{
              width: `${scrollPercentage}%`,
              height: '3px',
              backgroundColor: scrollPercentage > 99 ? '#42e466' : '#3257fc',
              position: 'fixed',
              top: '80px',
              zIndex: 12,
            }}
          ></div>
          <div className="flex grow">
            <div
              className={`left-0 flex w-full flex-col border-r border-solid border-grey-02 p-5 px-2 duration-500 ease-out md:!max-w-[380px]
          ${hideSidebar && !isMobile ? '!absolute !-left-full' : null} ${
                isMobile && !hideContent ? '!hidden' : null
              }`}
            >
              <div className="p-5 md:sticky md:top-[80px] md:max-h-[calc(100vh_-_80px)] md:w-[380px] md:overflow-y-auto">
                <p className="mt-2 mb-4 pl-2 text-base font-medium">
                  {t('pages.course_landing.contents')}
                </p>
                {isMobile && (
                  <div className="flex items-center px-2 pb-4">
                    <div className="mr-3 w-16 min-w-16">
                      <img
                        src={course?.image || '/images/courseFallback.png'}
                        alt={course?.name}
                        className="inline h-12 w-full rounded-lg object-cover"
                      />
                    </div>
                    <p className="text-sm font-medium text-black">
                      {course?.name}
                    </p>
                    {student ? (
                      <div
                        className="relative ml-auto h-8 w-8 min-w-[32px] rounded-full text-center"
                        style={{
                          background: `radial-gradient(closest-side, white 85%, transparent 90% 100%),conic-gradient(#3257fc ${
                            student?.progress
                              ? Math.round(student?.progress * 100)
                              : 0
                          }%, #1e1e2014 0)`,
                        }}
                      >
                        <p className="pt-2 text-xs text-grey-07">
                          {student?.progress
                            ? Math.round(student?.progress * 100)
                            : 0}
                          &#37;
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}
                {course && (
                  <LessonTree
                    hideScore={hideScore}
                    coursecontentId={
                      student?.coursecontentId || course?.coursecontentId
                    }
                    courseId={student?.coursecontentId ? undefined : courseId}
                    userId={userId}
                    activeLesson={activeLesson}
                    courseOrder={course?.inOrder}
                    rowPath={basePath}
                    setActiveLesson={async (newActiveLesson: any) => {
                      if (
                        loaded &&
                        newActiveLesson?.lessonId !== activeLesson?.lessonId
                      ) {
                        leaveLesson();
                        setLoaded(null);
                      }
                      setActiveLesson(newActiveLesson);
                    }}
                    onFirstLesson={async (node: any) => {
                      if (
                        (!activeLesson && node?.lessonId) ||
                        (!activeLesson && node?.assetId)
                      ) {
                        const prev = prevPage ? '&details=' + prevPage : '';
                        //navigate(modifiedString + prev, { replace: true });
                        let search = window.location.search + prev;
                        let myStudent: any;
                        if (!student) {
                          await axios
                            .get(
                              `${config.backend}/follow/${course.id}/${authUser.id}`
                            )
                            .then((result) => {
                              myStudent = result.data;
                            });
                        } else {
                          myStudent = student;
                        }
                        const searchString = lessonId
                          ? 'lesson/' + lessonId
                          : 'lesson';

                        const replacementString =
                          'lesson/' +
                          (myStudent?.currentLessonId ||
                            node?.lessonId ||
                            node?.assetId);

                        const modifiedString = basePath.replace(
                          searchString,
                          replacementString
                        );

                        if (search[0] === '&')
                          search = '?' + search.substring(1);

                        //console.log("coming from", window.location)
                        /*console.log("navigating to", {
                          pathname: modifiedString,
                          search: search,
                        })*/
                        navigate({
                          pathname: modifiedString,
                          search: search,
                        });
                      }
                    }}
                    setHideContent={setHideContent}
                    setNextLesson={setNextLesson}
                    setPreviousLesson={setPreviousLesson}
                    isUserFollow={expectAStudent}
                    isTeacher={isTeacher}
                    status={statuses}
                    statusAsset={statusesAssets}
                    setAllExtraInfo={setAllExtraInfo}
                    setRootCourseId={setRootCourseId}
                    setAllDirectories={setAllDirectories}
                    setAllAssets={setAllAssets}
                    setNumLesAs={setNumLesAs}
                    setIsLessonLoaded={setIsLessonLoaded}
                  />
                )}
              </div>
            </div>
            <div
              className={`w-full ${isMobile && hideContent && '!hidden'}
        `}
            >
              <div className="relative flex h-full grow p-4 ">
                {(isLessonLoaded || isLessonLoadedSub) && !isTeacher && (
                  <div className="absolute inset-0 z-[3] flex flex bg-white/75">
                    <div className="relative mx-auto">
                      <div className="sticky top-1/2 m-auto h-16">
                        <Spinner
                          ariaLabel="Loading spinner"
                          className="!h-16 !w-16 border-grey-08"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="fr-view mx-auto w-full max-w-2xl">
                  {activeLesson &&
                    (student || isPreview) &&
                    (activeLesson?.lessonId ? (
                      <LessonView
                        key={activeLesson.id}
                        node={activeLesson}
                        course={course}
                        student={student}
                        isTeacher={isTeacher}
                        status={activeStatus?.current}
                        page={page}
                        setNrOfPages={(nrOfPages: number) => {
                          setPage(0);
                          setNrOfPages(nrOfPages);
                        }}
                        setSubmitFn={(fn: any) => {
                          setSubmitFn(() => fn);
                          if (
                            activeStatus?.current &&
                            activeStatus?.current?.status === 'completed' &&
                            fn
                          ) {
                            //we are in an invalid state
                            axios
                              .put(
                                //fix
                                `${config.backend}/lessonstatus/${courseId}/${userId}/${activeLesson?.lessonId}`,
                                {
                                  courseId: activeStatus?.current?.courseId,
                                  userId: activeStatus?.current?.userId,
                                  status: 'started',
                                }
                              )
                              .then((result) => {
                                updateStatus(result?.data);
                              });
                          }
                          const widgets =
                            document?.querySelectorAll('.widget_practical');
                          if (widgets?.length > 0) {
                            setHasWidgets(true);
                          } else {
                            setHasWidgets(false);
                          }
                        }}
                        onSubmit={async (submitData: any) => {
                          if (submitData.allSubmitted) {
                            const isCorrected = Object.values(
                              submitData?.widgets
                            ).some(
                              (widget: any) =>
                                widget?.state?.type === 'OpenVraag' ||
                                widget?.state?.type === 'FileUpload'
                            )
                              ? 'submitted'
                              : 'corrected';

                            axios
                              .put(
                                //fix
                                `${config.backend}/lessonstatus/${courseId}/${userId}/${activeLesson?.lessonId}`,
                                {
                                  courseId: activeStatus?.current?.courseId,
                                  userId: activeStatus?.current?.userId,
                                  status: isCorrected,
                                }
                              )
                              .then((result) => {
                                updateStatus(result?.data);
                                //gotoLesson(nextLesson, false);
                                refetchStudentInfo();
                              });
                          }
                        }}
                        timeDisabled={lessonFinished}
                        setLoadeContent={setLoadeContent}
                        nodeGetData={
                          lessonFinished &&
                          activeStatus?.current?.getData &&
                          activeStatus?.current?.status !== 'completed' &&
                          activeStatus?.current?.version !==
                            activeLesson?.version
                            ? activeStatus?.current.getData
                            : activeLesson?.getData
                        }
                        setIsLoaded={setIsLessonLoaded}
                      ></LessonView>
                    ) : (
                      activeLesson?.assetId &&
                      activeLesson?.assetPath && (
                        <div className="flex w-full items-start p-3">
                          {activeLesson?.assetType == 'image' ? (
                            <ImageZoom
                              activeExtraInfo={activeLesson}
                              extraInfoFullScreen={true}
                              setImageZoom={setImageZoom}
                              imageZoom={imageZoom}
                              onLoad={() => {
                                setIsLessonLoaded(false);
                              }}
                            />
                          ) : (
                            <div id={`${newUid(20)}_embed`} className="mx-auto">
                              <iframe
                                id={`${newUid(20)}_placeholder`}
                                width="640"
                                height="360"
                                className=""
                                src={activeLesson?.assetPath}
                                onLoad={() => {
                                  setIsLessonLoaded(false);
                                }}
                              ></iframe>
                            </div>
                          )}
                        </div>
                      )
                    ))}
                  {showContent && (
                    <div className="mt-6 flex w-full justify-between">
                      <div>
                        {previousLesson && (
                          <Button
                            onClick={() => gotoLesson(previousLesson)}
                            variant="secondary"
                            disabled={activeLesson?.timed === 'limited'}
                          >
                            {t('pages.course_landing.previous')}
                          </Button>
                        )}
                        {page > 0 && (
                          <Button
                            onClick={async () => {
                              setPage(page - 1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {t('pages.course_landing.previous_page')}
                          </Button>
                        )}
                      </div>
                      <div className="flex gap-2">
                        {page < nrOfPages - 1 && (
                          <Button
                            name="next_page"
                            onClick={async () => {
                              setPage(page + 1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {t('pages.course_landing.next_page')}
                          </Button>
                        )}
                        {submitFn &&
                          authUser &&
                          !isPreview &&
                          page == nrOfPages - 1 &&
                          course?.correctionType !== 'autofeedback' && (
                            <Button
                              disabled={
                                submitDisabled
                                // todo implement correction by a teacher first
                                // ||
                                // activeLesson?.lessonType === 'practice'
                              }
                              title={
                                submitDisabled
                                  ? !isTeacher && activeLesson?.submitByTeacher
                                    ? t(
                                        'pages.course_landing.submit_unavailable_by_teacher'
                                      )
                                    : t(
                                        'pages.course_landing.submit_unavailable'
                                      )
                                  : undefined
                              }
                              onClick={() => {
                                submit();
                              }}
                            >
                              {t('pages.course_landing.submit')}
                            </Button>
                          )}
                        {(isPreview ||
                          !submitFn ||
                          course?.correctionType === 'autofeedback') &&
                          nextLesson &&
                          page == nrOfPages - 1 && (
                            <Button
                              name="next_lesson"
                              disabled={activeLesson?.timed === 'limited'}
                              onClick={async () => {
                                if (
                                  course?.correctionType === 'autofeedback' &&
                                  !submitDisabled &&
                                  hasWidgets
                                ) {
                                  submit();
                                } else {
                                  gotoLesson(nextLesson);
                                }
                              }}
                            >
                              {t('pages.course_landing.next')}
                            </Button>
                          )}
                        {/*(isPreview ||
                          !submitFn ||
                          course?.correctionType === 'autofeedback') &&
                          !nextLesson &&
                          page == nrOfPages - 1 && (
                            <Button
                              name="next_lesson"
                              disabled={activeLesson?.timed === 'limited'}
                              onClick={async () => {
                                if (
                                  course?.correctionType === 'autofeedback' &&
                                  !submitDisabled &&
                                  hasWidgets
                                ) {
                                  submit();
                                } else {
                                  gotoLesson(nextLesson);
                                }
                              }}
                            >
                              {t('pages.course_landing.finish')}2
                            </Button>
                          )*/}
                        {!submitFn &&
                          authUser &&
                          !isPreview &&
                          !nextLesson &&
                          page == nrOfPages - 1 && (
                            <Button
                              disabled={activeLesson?.timed === 'limited'}
                              onClick={async () => {
                                setIsLessonLoaded(true);
                                await leaveLesson();
                                setShowCourseSuccess(true);
                              }}
                            >
                              {t('pages.course_landing.finish')}
                            </Button>
                          )}
                      </div>
                    </div>
                  )}
                </div>
                {!activeLesson?.assetId && (
                  <div className={` ${!iSsmallLaptop && 'pl-3'}`}>
                    <ExtraInfoLesson
                      node={activeLesson}
                      course={course}
                      rootCourseId={rootCourseId}
                      allExtraInfo={allExtraInfo}
                      allDirectories={allDirectories}
                      // allAssets={allAssets}
                      setShowExtraInfo={setShowExtraInfo}
                      showExtraInfo={showExtraInfo}
                      setExtraInfoFullScreen={setExtraInfoFullScreen}
                      extraInfoFullScreen={extraInfoFullScreen}
                      setActiveExtraInfo={setActiveExtraInfo}
                      activeExtraInfo={activeExtraInfo}
                      setLengthExtraInfo={setLengthExtraInfo}
                      lengthExtraInfo={lengthExtraInfo}
                      preview={false}
                      iSsmallLaptop={iSsmallLaptop}
                      isMobile={isMobile}
                      viewMobile={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <div data-testid="hide-sidebar" className="hidden md:block">
        <IconButton
          className="fixed left-2 bottom-5 z-[4] bg-white hover:bg-[#f4f4f4]"
          Icon={Sidebar}
          variant="outline"
          onClick={handleSidebar}
        />
      </div>
      {isIncomplete && (
        <IncompleteModal
          setIsIncomplete={setIsIncomplete}
          submit={submit}
          incompleteWidgets={incompleteWidgets}
          setIsLessonLoadedSub={setIsLessonLoadedSub}
        />
      )}
    </div>
  );
}

export default LessonPage;

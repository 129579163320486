import { loadRawScript } from '@/util';

let tusPromise;
function initTus() {
  if (tusPromise) return tusPromise;
  tusPromise = new Promise((resolve, reject) => {
    let scripts = ['/libs/tus.min.js'];
    window
      .loadjs([...scripts], 'tus', {
        async: false,
        returnPromise: true,
      })
      .then(() => {
        return loadRawScript(`
          window.tus = tus;
        `);
      })
      .then(() => {
        resolve(window.tus);
      })
      .catch((e) => {
        reject(e);
      });
  });
  return tusPromise;
}

export { initTus };

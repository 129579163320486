import { useTranslation } from 'react-i18next';
import { Button, Modal, Label, InputText } from '@teo/components';
import React, { useState, useEffect } from 'react';
import ColorPicker from '@/components/ColorPicker';
import { config } from '@/config';
import { axios } from '@/axios';
import { useParams } from 'react-router-dom';
import { useGet } from '@/query/fetchHooks';
import { PaginationSingleSelect } from '@/components/PaginationSingleSelect';
import { ErrorBox } from '@/components/ErrorBox';
import { AxiosError } from 'axios';

function AddNewTeamModal({ isOpenAddTeam, closeModal, onChange }: any) {
  const { t } = useTranslation();
  const { organisationId } = useParams();

  const [teamName, setTeamName] = useState('');
  const [mainTeam, setMainTeam] = useState<any>(null);
  const [colorPicker, setColorPicker] = useState('#009e7a');
  const [errorText, setErrorText] = useState('');
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (Number.isInteger(isOpenAddTeam)) {
      axios
        .get(`${config.backend}/team/${organisationId}/${isOpenAddTeam}`)
        .then((result: any) => {
          const team = result?.data;
          team.label = team.teamName;
          setMainTeam(team);
        });
    }
  }, [isOpenAddTeam]);

  function submitNewTeam() {
    axios
      .post(`${config.backend}/teams`, {
        createdAt: new Date().toJSON(),
        organisationId: organisationId,
        color: colorPicker,
        teamPath:
          (mainTeam?.teamPath ? mainTeam?.teamPath : '_organisation_') +
          ';;' +
          teamName,
        teamName: teamName,
      })
      .then((data) => {
        onChange(data);
        closeModal();
      })
      .catch((e: AxiosError<{ message: string }>) => {
        setError(true);

        const message = e?.response?.data?.message || e.toString();
        switch (message) {
          case 'Validation error':
            setErrorText(t('pages.manage_teams.warm_message'));
            break;
          default:
            setErrorText(message);
            break;
        }

        console.error('Error', e);
        console.error('redirecting');
      });
  }

  return (
    <div className="bg-white">
      <Modal isOpen={isOpenAddTeam}>
        <Modal.Header onClose={closeModal}>
          <h2 className="text-sm font-semibold" data-testid="addNewTeamHeader">
            {t('pages.manage_teams.add_team_button')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <h1 className="text-base font-semibold">
            {t('pages.manage_teams.add_team_button')}
          </h1>
          <p className="mt-2 text-sm font-normal text-[#7d7d81]">
            {t('pages.manage_teams.modal_subtitle')}
          </p>

          {hasError && <ErrorBox text={errorText} />}

          <form
            className="w-full max-w-[440px] flex-col max-md:h-full max-md:overflow-hidden"
            data-testid="addNewTeamForm"
          >
            <div>
              <Label htmlFor="teamName">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_teams.team_name_label')}
                </p>
              </Label>
              <InputText
                id="teamName"
                type="text"
                onChange={(e) => setTeamName(e.target.value)}
                value={teamName}
              />
            </div>

            {/*TODO: when we will have sub teams*/}
            <div>
              <Label htmlFor="teamName">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_teams.parent_team_label')}
                </p>
              </Label>
              <PaginationSingleSelect
                value={mainTeam}
                onChange={(value: any) => {
                  setMainTeam(value);
                }}
                fetch={useGet.bind(null, '/teams', {
                  organisationId,
                })}
                defaultSort={[{ id: 'teamPath', desc: false }]}
                render={(row: any) => {
                  return row?.teamName;
                }}
              />
            </div>
            {/*
            <div>
                <Label htmlFor="mainTeamName">
                    <p className="mt-6 text-sm font-semibold">
                        {t('pages.manage_teams.subteam_label')}
                    </p>
                </Label>
                <InputText
                    id="mainTeamName"
                    type="text"
                    onChange={(e) => setMainTeam(e.target.value)}
                    value={mainTeam}
                />
            </div>
            */}

            <div>
              <Label htmlFor="colour">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.manage_teams.colour_label')}
                </p>
              </Label>
              <div id="colour">
                <ColorPicker
                  colorPicker={colorPicker}
                  setColorPicker={setColorPicker}
                />
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('pages.manage_teams.cancel_button')}
          onCancel={closeModal}
        >
          <div data-testid="addNewTeamFooter">
            <Button
              type="button"
              onClick={() => submitNewTeam()}
              disabled={teamName.length < 1 ? true : false}
            >
              {t('pages.manage_teams.add_button')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddNewTeamModal;

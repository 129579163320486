import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RadioGroup,
  Button,
  ErrorMessage,
  Label,
  InputText,
} from '@teo/components';

const Step4 = ({ step, setStep, setInfo, followInfo, courseInfo }: any) => {
  const { t } = useTranslation();
  const [radioBox, setRadioBox] = useState<string>('');
  const [otherOption, setOtherOption] = useState<string>('');
  const [checkValid, setCheckValid] = useState<boolean>(false);

  const onChange = (value: string) => {
    setRadioBox(value);
  };

  useEffect(() => {
    setRadioBox(followInfo?.referrer);
  }, [followInfo]);

  const nextButton = () => {
    const prevStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_diploma_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_current_work_step')
      ? 2
      : 3;
    setCheckValid(true);
    if (radioBox?.length > 1 && radioBox !== 'other') {
      setInfo((prev: any) => ({ ...prev, referrer: radioBox }));
      setStep((prev: number) => prev + prevStep);
    }
    if (radioBox === 'other' && otherOption.length > 1) {
      setInfo((prev: any) => ({ ...prev, referrer: radioBox }));
      setStep((prev: number) => prev + prevStep);
    }
  };

  const prevButton = () => {
    const prevStep = courseInfo?.onboardingSteps?.includes(
      'onboarding_regime_step'
    )
      ? 1
      : courseInfo?.onboardingSteps?.includes('onboarding_location_step')
      ? 2
      : 3;
    setStep((prev: number) => prev - prevStep);
  };

  const handleError = (el: string) => (el ? false : checkValid ? true : false);

  if (step !== 4) {
    return null;
  }
  const showOther =
    (radioBox === 'other' || radioBox === otherOption) && radioBox.length > 0;
  return (
    <>
      <div>
        <RadioGroup label="" onChange={onChange} value={radioBox}>
          <RadioGroup.Option value="teo">
            {t(`pages.onboarding.step_4.TEO`)}
          </RadioGroup.Option>
          <RadioGroup.Option value="facebook">
            {t(`pages.onboarding.step_4.REFERRAL_SOCIAL`)}
          </RadioGroup.Option>
          <RadioGroup.Option value="linkedin">
            {t(`pages.onboarding.step_4.REFERRAL_LINKEDIN`)}
          </RadioGroup.Option>
          <RadioGroup.Option value="jobsite">
            {t(`pages.onboarding.step_4.REFERRAL_JOBBOARDS`)}
          </RadioGroup.Option>
          <RadioGroup.Option value="other">
            {t(`pages.onboarding.step_4.REFERRAL_OTHER`)}
          </RadioGroup.Option>
        </RadioGroup>
        {!radioBox && checkValid && (
          <div className="mt-4">
            <ErrorMessage id="errorMessage">
              {t('pages.onboarding.error.select_one')}
            </ErrorMessage>
          </div>
        )}

        {showOther && (
          <div className="mt-4 max-w-xs text-sm font-semibold text-black">
            <InputText
              id="name"
              onChange={(e) => setOtherOption(e.currentTarget.value)}
              value={otherOption}
              hasError={handleError(otherOption)}
              placeholder={t(`pages.onboarding.step_4.REFERRAL_OTHER`)}
            />
            {handleError(otherOption) && (
              <ErrorMessage id="errorMessage">
                {t('pages.onboarding.error.text_fiels')}
              </ErrorMessage>
            )}
          </div>
        )}
      </div>
      <div className="flex w-full pt-10">
        <Button
          variant="outline"
          className={`mr-2 flex-[1_1_30%]`}
          onClick={prevButton}
        >
          {t(`pages.onboarding.button_prev`)}
        </Button>

        <Button
          className={`${step !== 1 && 'ml-2 flex-[1_1_30%]'}`}
          onClick={() => step < 7 && nextButton()}
        >
          {t(`pages.onboarding.button_next`)}
        </Button>
      </div>
    </>
  );
};
export default Step4;

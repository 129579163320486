import { useTranslation } from 'react-i18next';
import { Button, Modal, Switch } from '@teo/components';
import { useState, useEffect, useRef } from 'react';
import { Portal } from '@/components/Portal';

const VIDEO_CONSTRAINTS = {
  audio: true,
  video: {
    width: { ideal: 1920 },
    height: { ideal: 1080 },
    facingMode: {
      ideal: 'environment',
    },
  },
};

let VIDEO_OPTIONS = {
  mimeType: 'video/webm;codecs=vp9',
};
let mimeType = 'webm';

let videoType = 'video/webm';

if (typeof MediaRecorder !== 'undefined') {
  if (!MediaRecorder.isTypeSupported(VIDEO_OPTIONS.mimeType)) {
    console.warn(
      `${VIDEO_OPTIONS.mimeType} is not supported, switching to a supported format.`
    );
    VIDEO_OPTIONS = { mimeType: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' };
    mimeType = 'mp4';
    videoType = 'video/mp4';
  }
}

export const VideoCameraModal = ({
  setIsOpenVideoModal,
  isOpenVideoModal,
  setUploadFile,
}) => {
  //   const [showWebcamPreview, setShowWebcamPreview] = useState(false);
  const videoRef = useRef(null);
  const recordedVideoRef = useRef(null);
  const [mediaStream, setMediaStream] = useState();
  const [recording, setRecording] = useState(false);
  const [hasRecording, setHasRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState();
  const [recordedChunks, setRecordedChunks] = useState();
  const [turnOnSound, setTurnOnSound] = useState(false);

  const { t } = useTranslation();

  const start = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(VIDEO_CONSTRAINTS)
        .then((stream) => {
          setMediaStream(stream);
          videoRef.current.srcObject = stream;
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    if (!mediaStream) {
      start();
    }
  }, [mediaStream]);

  const startRecording = async () => {
    setHasRecording(false);
    try {
      if (!turnOnSound) {
        mediaStream.getAudioTracks().forEach((track) => track.stop());
      }
      const myMediaRecorder = new MediaRecorder(mediaStream, VIDEO_OPTIONS);
      let chunks = [];
      myMediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      myMediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: videoType });
        const videoURL = URL.createObjectURL(blob);
        recordedVideoRef.current.src = videoURL;
        mediaStream.getTracks().forEach((track) => track.stop());
      };

      myMediaRecorder.start();
      setMediaRecorder(myMediaRecorder);
      setRecordedChunks(chunks);
      setRecording(true);
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  const stopRecording = async () => {
    setRecording(false);
    setHasRecording(true);
    if (
      mediaRecorder &&
      mediaRecorder?.state !== 'inactive' &&
      mediaRecorder.stop
    ) {
      await mediaRecorder.stop();
    }
    setMediaStream();
  };

  const stop = async () => {
    mediaStream.getTracks().forEach(function (track) {
      track.stop();
    });
  };

  const insertVideo = async () => {
    // await stopRecording();
    // this.props.onInsert(this.recordedChunks)
    setUploadFile((prev) => [
      ...prev,
      {
        blob: recordedChunks[0],
        video: true,
        size: recordedChunks[0]?.size,
        type: VIDEO_OPTIONS.mimeType,
        mimeType: mimeType,
      },
    ]);
    stop();
    setIsOpenVideoModal(!isOpenVideoModal);
  };

  const closeModal = () => {
    stop();
    setIsOpenVideoModal(!isOpenVideoModal);
  };
  return (
    <Portal root="body">
      <div className="z-10 bg-white">
        <Modal isOpen={isOpenVideoModal}>
          <Modal.Body>
            <div className="max-h-[70vh] overflow-hidden">
              <video
                autoPlay
                muted
                style={{
                  minWidth: '100%',
                  minHeight: '100%',
                  maxWidth: '100%',
                  maxHeight: '65vh',
                  objectFit: 'contain',
                }}
                className={`${hasRecording && 'hidden'}`}
                ref={videoRef}
              ></video>

              <video
                style={{
                  minWidth: '100%',
                  minHeight: '100%',
                  maxWidth: '100%',
                  maxHeight: '65vh',
                  objectFit: 'contain',
                }}
                ref={recordedVideoRef}
                controls
                className={`${!hasRecording && 'hidden'}`}
              ></video>
            </div>
          </Modal.Body>

          {!recording && (
            <div className="px-6">
              <div className="flex flex-row items-center gap-4">
                <div data-testid="reportEmail">
                  <Switch
                    checked={turnOnSound}
                    onChange={(isCheck) => {
                      setTurnOnSound(isCheck);
                    }}
                  />
                </div>
                <div>
                  <h4 className="font-normal">
                    {t('widgets.upload_buttons.turn_on')}
                  </h4>
                </div>
              </div>
            </div>
          )}

          <Modal.Footer
            cancelButtonLabel={t('widgets.upload_buttons.cancel_button')}
            onCancel={closeModal}
          >
            <div className="flex gap-3">
              {!recording ? (
                <Button type="button" onClick={startRecording}>
                  {t('widgets.upload_buttons.record')}
                </Button>
              ) : (
                <Button type="button" onClick={stopRecording}>
                  {t('widgets.upload_buttons.stop')}
                </Button>
              )}
              {!recording && hasRecording && (
                <Button type="button" onClick={insertVideo}>
                  {t('widgets.upload_buttons.insert')}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </Portal>
  );
};

import { useTranslation } from 'react-i18next';
import {
  InputDate,
  InputText,
  Label,
  PersonAvatar,
  RadioGroup,
} from '@teo/components';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useContext } from 'react';
import { axios } from '@/axios';
import { config } from '@/config';

const AccountPage = ({ userData, setUserData, userInfo, setUserInfo }: any) => {
  const { t } = useTranslation();

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const canModifyUserinfo: boolean =
    Math.max(authUser?.role || 0, authUser?.activeOrganisationRole || 0) >= 90;

  return (
    <div className="h-full  md:flex">
      <div className="flex h-full w-full flex-col" id="userForm">
        <div className="flex w-full max-w-[500px] flex-col max-md:h-full">
          <h4 className="mb-5 text-base">
            {t('pages.manage_users.user_detail_page.account.title')}
          </h4>
          <div className="mb-5 flex items-center">
            <PersonAvatar
              id={userData.id}
              name={`${userData?.username as string}`}
              imageSrc={`${userData?.picture}`}
            />
          </div>

          <div className="mb-8 space-y-2 md:space-y-5">
            <div>
              <Label htmlFor="firstName">
                {t('pages.profile_account.firstName_label')}
              </Label>
              <InputText
                id="firstName"
                disabled={!canModifyUserinfo}
                onChange={(ev) =>
                  setUserData({
                    ...userData,
                    firstName: ev.target.value,
                    username: ev.target.value + ' ' + userData.lastName,
                  })
                }
                value={userData?.firstName}
                onBlur={() => {
                  axios.put(`${config.backend}/user/${userData.id}`, {
                    firstName: userData.firstName,
                    username: userData.username,
                    personalInfoModified: true,
                    organisationId: authUser.activeOrganisationId,
                  });
                }}
              />
            </div>

            <div>
              <Label htmlFor="lastName">
                {t('pages.profile_account.lastname_label')}
              </Label>
              <InputText
                disabled={!canModifyUserinfo}
                id="lastName"
                onChange={(ev) =>
                  setUserData({
                    ...userData,
                    lastName: ev.target.value,
                    username: userData.firstName + ' ' + ev.target.value,
                  })
                }
                value={userData?.lastName}
                onBlur={() => {
                  axios.put(`${config.backend}/user/${userData.id}`, {
                    lastName: userData.lastName,
                    username: userData.username,
                    personalInfoModified: true,
                    organisationId: authUser.activeOrganisationId,
                  });
                }}
              />
            </div>

            <div>
              <Label htmlFor="email">
                {t('pages.profile_account.email_label')}
              </Label>
              <InputText
                id="email"
                disabled
                onChange={() => {}}
                placeholder={`${userData?.email}`}
              />
            </div>

            <div>
              <Label htmlFor="phone_number">
                {t('pages.profile_account.phone_number_label')}
              </Label>

              <InputText
                id="phone_number"
                onChange={() => {}}
                disabled={!canModifyUserinfo}
                value={userData?.phone}
              />
            </div>

            {authUser?.personAttributes && (
              <div>
                <Label htmlFor="birthDate">
                  {t('pages.profile_account.date_of_birth_label')}
                </Label>
                <InputDate
                  id="birthDate"
                  onChange={() => {}}
                  disabled={!canModifyUserinfo}
                  value={
                    userInfo?.birthDate
                      ? new Date(userInfo?.birthDate as Date)
                      : undefined
                  }
                  variant="date"
                />
              </div>
            )}

            {authUser?.personAttributes && (
              <div>
                <Label htmlFor="gender">
                  {t('pages.manage_users.user_detail_page.account.gender')}
                </Label>
                <InputText
                  disabled={!canModifyUserinfo}
                  id="gender"
                  onChange={() => {}}
                  value={
                    userInfo?.gender
                      ? t(
                          `pages.onboarding.step_1.${userInfo?.gender.toUpperCase()}`
                        )
                      : ''
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="border-t border-grey-03"></div>
        <div className="mt-6 flex w-full max-w-[500px] flex-col max-md:h-full">
          <h4 className="mb-5 text-base">
            {t('pages.manage_users.user_detail_page.account.preferences')}
          </h4>
          <div className="mb-6">
            <p className="">{t(`pages.onboarding.step_7.title`)}</p>
            <div>
              <RadioGroup
                label=""
                onChange={() => {}}
                value={userData?.preferredContact}
              >
                <RadioGroup.Option value="email">
                  {t(`pages.onboarding.step_7.only_email`)}
                </RadioGroup.Option>
                <RadioGroup.Option value="phone">
                  {t(`pages.onboarding.step_7.email_phone`)}
                </RadioGroup.Option>
              </RadioGroup>
            </div>
          </div>
          <div>
            <p className="">{t(`pages.onboarding.step_7.subtitle_2`)}</p>
            <div>
              <RadioGroup
                label=""
                onChange={() => {}}
                value={userData?.emailUpdates ? 'yes' : 'no'}
              >
                <RadioGroup.Option value="yes">
                  {t(`pages.onboarding.step_7.yes`)}
                </RadioGroup.Option>
                <RadioGroup.Option value="no">
                  {t(`pages.onboarding.step_7.no`)}
                </RadioGroup.Option>
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;

import axios from 'axios';
import { deep_copy } from './util';
import jwt_decode from 'jwt-decode';
import { store } from './store';

// do not configure auth header
const publicAxios = axios.create();
publicAxios.defaults.headers = deep_copy(axios.defaults.headers);
publicAxios.defaults.headers.common['Cache-Control'] = 'no-cache';

const stAxios = axios.create();
stAxios.defaults.headers = deep_copy(axios.defaults.headers);

// axios with auth header
const privateAxios = axios.create();
privateAxios.defaults.headers = deep_copy(axios.defaults.headers);

const token = localStorage.getItem('token');
const access = localStorage.getItem('access');
if (token)
  privateAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
if (access)
  privateAxios.defaults.headers.common['Authorization2'] = 'Bearer ' + access;

export { privateAxios as axios, publicAxios, stAxios };

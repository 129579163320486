import { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  InputSelect,
  Label,
  Button,
  ErrorMessage,
  Switch,
} from '@teo/components';
import { Location } from '@teo/components/icons';
import { axios } from '@/axios';
import { config } from '@/config';
import { useNavigate } from 'react-router-dom';
import {
  businessFormOptions,
  organisationTypeOptions,
} from '@/constants/option.constants';

import {
  InputSelectOption,
  OrganisationState,
  ContactState,
} from '@/models/organisation.interface';
import UploadDropzoneSection from '@/components/UploadDropzoneSection/UploadDropzoneSection';

function DetailsTab({ data }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkValid, setCheckValid] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [updatedAt, setUpdatedAt] = useState<any>();
  const [uploadLogo, setUploadLogo] = useState();
  // const [educationPreferences, setEducationPreferences] = useState<any>();
  // const [workPreferences, setWorkPreferences] = useState<any>();
  const [companyType, setCompanyType] = useState<InputSelectOption>({
    label: '',
    value: '',
  });
  const [organisationType, setOrganisationType] = useState<InputSelectOption>({
    label: '',
    value: '',
  });

  const initialState = {
    organisationName: data?.organisationName ? data?.organisationName : '',
    organisationCommercialName: data?.organisationCommercialName
      ? data?.organisationCommercialName
      : '',
    entityNumber: data?.entityNumber ? data?.entityNumber : '',
    address: data?.seat?.city ? data?.seat?.city + data?.seat?.street : '',
    correspondence: data?.contact?.correspondenceAddress?.city
      ? data?.contact?.correspondenceAddress?.city +
        data?.contact?.correspondenceAddress?.street
      : '',
    workPreferences: data?.workPreferences,
    educationPreferences: data?.educationPreferences,
    personAttributes: data?.personAttributes,
  };

  const stateContact = {
    department: data?.contact?.department ? data?.contact?.department : '',
    name: data?.contact?.name ? data?.contact?.name : '',
    email: data?.contact?.email ? data?.contact?.email : '',
    phoneNumber: data?.contact?.phoneNumber ? data?.contact?.phoneNumber : '',
    function: data?.contact?.function ? data?.contact?.function : '',
  };

  const [state, updateState] = useReducer(
    (state: OrganisationState, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  const [contact, updateContact] = useReducer(
    (state: ContactState, updates: any) => ({ ...state, ...updates }),
    stateContact
  );
  useEffect(() => {
    updateState(initialState);
    updateContact(stateContact);
    setUploadLogo(data?.picture);
    setCompanyType({
      label: data?.form,
      value: data?.form,
    });
    setOrganisationType({
      label: data?.type,
      value: data?.type,
    });
  }, [data]);

  useEffect(() => {
    if (update && updatedAt) {
      navigate(0);
    }
  }, [updatedAt, update]);

  const handleEdit = async () => {
    setCheckValid(true);
    if (state?.organisationName.length > 0) {
      const updateOrg = await axios.put(
        `${config.backend}/organisation/${data?.id}`,
        {
          organisationName: state?.organisationName,
          organisationCommercialName: state?.organisationCommercialName,
          entityNumber: state?.entityNumber,
          form: companyType?.value,
          type: organisationType?.value,
          seat: { street: '', city: state?.address },
          picture: uploadLogo ? uploadLogo : null,
          educationPreferences: state?.educationPreferences,
          workPreferences: state?.workPreferences,
          personAttributes: state?.personAttributes,
          contact: {
            name: contact?.name,
            department: contact?.department,
            email: contact?.email,
            phoneNumber: contact?.phoneNumber,
            function: contact?.function,
            correspondenceAddress: { street: '', city: state?.correspondence },
          },
        }
      );
      setUpdate(true);
      setUpdatedAt(updateOrg?.data?.updatedAt);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const dataKey = Object.keys(data);
  const stateKey = Object.keys(state);
  const DataContactKey = Object.keys(data?.contact);
  const contactKey = Object.keys(contact);

  const stateKeys = stateKey.filter((key) => dataKey.includes(key));
  const contactKeys = contactKey.filter((key) => DataContactKey.includes(key));

  let stateEqual = true;
  let contactEqual = true;

  for (const key of stateKeys) {
    if (state[key] !== data[key]) {
      stateEqual = false;
      break;
    }
  }

  for (const key of contactKeys) {
    if (contact[key] !== data.contact[key]) {
      contactEqual = false;
      break;
    }
  }

  const editEqual =
    contactEqual &&
    stateEqual &&
    data?.form === companyType?.value &&
    data?.type === organisationType?.value &&
    data?.picture === uploadLogo;

  return (
    <div className="divide-y divide-solid divide-grey-02">
      <div className="pb-8">
        <p className="text-md font-semibold text-black">
          {t('pages.organisation_details.details.title')}
        </p>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="organisation-name">
            {t('pages.organisation_details.details.organisation_name')}
          </Label>
          <InputText
            id="text-input"
            onChange={(e) =>
              updateState({ organisationName: e.currentTarget.value })
            }
            value={state?.organisationName}
            hasError={state?.organisationName?.length < 1 && checkValid}
            className="max-w-[440px]"
          />
          {state?.organisationName.length < 1 && checkValid && (
            <div className="mt-2s">
              <ErrorMessage id="errorMessage">
                {t('pages.organisation_details.error_text_fiels')}
              </ErrorMessage>
            </div>
          )}
        </div>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="trade-name">
            {t('pages.organisation_details.details.trade_name')}
          </Label>
          <InputText
            id="trade-name"
            onChange={(e) =>
              updateState({ organisationCommercialName: e.currentTarget.value })
            }
            value={state?.organisationCommercialName}
            className="max-w-[440px]"
          />
        </div>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="enterprise-name">
            {t('pages.organisation_details.details.enterprise_number')}
          </Label>
          <InputText
            id="enterprise-name"
            onChange={(e) =>
              updateState({ entityNumber: e.currentTarget.value })
            }
            value={state?.entityNumber}
            className="max-w-[440px]"
          />
        </div>

        <p className="mb-1 mt-6 text-sm font-semibold text-black">
          {t('pages.organisation_details.details.company_type')}
        </p>
        <div className="max-w-[440px] sm:max-w-[285px]">
          <InputSelect<InputSelectOption>
            value={companyType}
            onChange={(e: InputSelectOption) => setCompanyType(e)}
            options={businessFormOptions}
          >
            {(options) => (
              <span>{options.label && t(`company_type.${options.label}`)}</span>
            )}
          </InputSelect>
        </div>

        <p className="mb-1 mt-6 text-sm font-semibold text-black">
          {t('pages.organisation_details.details.organisation_type')}
        </p>
        <div className="max-w-[440px] sm:max-w-[285px]">
          <InputSelect<InputSelectOption>
            value={organisationType}
            onChange={(e: InputSelectOption) => setOrganisationType(e)}
            options={organisationTypeOptions}
          >
            {(options) => (
              <span>
                {options.label && t(`pages.organisation.type.${options.label}`)}
              </span>
            )}
          </InputSelect>
        </div>
        <div className="max-w-[500px]">
          <p className="mb-1 mt-6 text-sm font-semibold text-black">
            {t('pages.organisation_details.details.update_logo')}
          </p>
          <UploadDropzoneSection
            setUploadLogo={setUploadLogo}
            uploadLogo={uploadLogo}
          />
        </div>
      </div>
      <div className="py-8">
        <p className="text-md font-semibold text-black">
          {t('pages.organisation_details.details.seat')}
        </p>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="address">
            {t('pages.organisation_details.details.address')}
          </Label>
          <InputText
            id="address"
            Icon={Location}
            placeholder={t(
              'pages.organisation_details.details.location_placeholder'
            )}
            onChange={(e) => updateState({ address: e.currentTarget.value })}
            value={state?.address}
            className="max-w-[440px]"
            data-testid="address"
          />
        </div>
      </div>
      <div className="py-8">
        <p className="text-md font-semibold text-black">
          {t('pages.organisation_details.details.correspondence_address')}
        </p>

        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="correspondence-address">
            {t('pages.organisation_details.details.address')}
          </Label>
          <InputText
            id="correspondence-address"
            Icon={Location}
            placeholder={t(
              'pages.organisation_details.details.location_placeholder'
            )}
            onChange={(e) =>
              updateState({ correspondence: e.currentTarget.value })
            }
            value={state?.correspondence}
            className="max-w-[440px]"
            data-testid="correspondence-address"
          />
        </div>
      </div>
      <div className="py-8">
        <p className="text-md font-semibold text-black">
          {t('pages.organisation_details.details.contact')}
        </p>

        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="contact-name">
            {t('pages.organisation_details.details.contact_name')}
          </Label>
          <InputText
            id="contact-name"
            onChange={(e) => updateContact({ name: e.currentTarget.value })}
            value={contact?.name}
            className="max-w-[440px]"
          />
        </div>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="function">
            {t('pages.organisation_details.details.function')}
          </Label>
          <InputText
            id="function"
            onChange={(e) => updateContact({ function: e.currentTarget.value })}
            value={contact?.function}
            className="max-w-[440px]"
          />
        </div>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="department">
            {t('pages.organisation_details.details.department')}
          </Label>
          <InputText
            id="department"
            onChange={(e) =>
              updateContact({ department: e.currentTarget.value })
            }
            value={contact?.department}
            className="max-w-[440px]"
          />
        </div>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="phone">
            {t('pages.organisation_details.details.phone')}
          </Label>
          <InputText
            id="phone"
            onChange={(e) =>
              updateContact({ phoneNumber: e.currentTarget.value })
            }
            value={contact?.phoneNumber}
            className="max-w-[440px]"
          />
        </div>
        <div className="mb-1 mt-6 text-sm font-semibold text-black">
          <Label htmlFor="email">
            {t('pages.organisation_details.details.email')}
          </Label>
          <InputText
            id="email"
            onChange={(e) => updateContact({ email: e.currentTarget.value })}
            value={contact?.email}
            className="max-w-[440px]"
          />
        </div>
      </div>

      <div className="py-8">
        <p className="text-md font-semibold text-black">
          {t('pages.organisation_details.details.privacy_settings')}
        </p>
        <div className="mt-5 flex max-w-[440px] flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
          <div>
            <Switch
              checked={state?.educationPreferences}
              onChange={(isCheck) =>
                updateState({ educationPreferences: isCheck })
              }
            />
          </div>
          <div>
            <h4 className="font-normal">
              {t('pages.organisation_details.details.educationPreferences')}
            </h4>
          </div>
        </div>
        <div className="mt-5 flex max-w-[440px] flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
          <div>
            <Switch
              checked={state?.workPreferences}
              onChange={(isCheck) => updateState({ workPreferences: isCheck })}
            />
          </div>
          <div>
            <h4 className="font-normal">
              {t('pages.organisation_details.details.workPreferences')}
            </h4>
          </div>
        </div>
        <div className="mt-5 flex max-w-[440px] flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
          <div>
            <Switch
              checked={state?.personAttributes}
              onChange={(isCheck) => updateState({ personAttributes: isCheck })}
            />
          </div>
          <div>
            <h4 className="font-normal">
              {t('pages.organisation_details.details.personAttributes')}
            </h4>
          </div>
        </div>
      </div>

      <div className="flex py-8">
        <Button onClick={handleEdit} disabled={editEqual}>
          {t('pages.organisation_details.button_edit')}
        </Button>
      </div>
    </div>
  );
}

export default DetailsTab;

import { useTranslation } from 'react-i18next';
import { Task, PersonAvatar } from '@teo/components';
import {
  DuoToneAssessmentOutline,
  DuoToneCourseOutline,
  DuoToneVacancyOutline,
} from '@teo/components/icons';
import { useParams, useNavigate } from 'react-router-dom';

import { format } from 'date-fns';
import { axios } from '@/axios';
import { newUid } from '@/util';
import { config } from '@/config';
import i18n from 'i18next';
import EmptyTable from '@/components/DataTable/EmptyTable';

function NotificationList({
  tab,
  tasks,
  notifications,
  setNotificationUpdate,
}: any) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { organisationId } = useParams();

  return (
    <div className="">
      {tab === 'tasks' && (
        <>
          {/*
            <div className="mb-6 flex flex-row">
              <span className="mr-3">
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setAllTasks(value);
                  }}
                  buttonText={t('pages.follow_up_overview.all_tasks')}
                  options={allTeamsData}
                  searchable={true}
                  value={allTasks}
                />
              </span>

              <span>
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setAllUsers(value);
                  }}
                  buttonText={t('pages.follow_up_overview.all_users')}
                  options={teamsStatusesData}
                  searchable={false}
                  value={allUsers}
                />
              </span>
            </div>
            */}
          {tasks?.length ? (
            tasks.map((task: any) => {
              let label, actionLabel, image;
              switch (task.type) {
                case 'tocorrect':
                  (label = (
                    <>
                      {t('notifications.corrections')}{' '}
                      <b>{task.text.courseName}</b>
                    </>
                  )),
                    (actionLabel = t('notifications.to_course'));
                  image =
                    task.text.courseImage || '/assets/images/opleidingen.jpg';
                  break;
                default:
                  label = task.text.text;
                  actionLabel = t('notifications.go');
                  break;
              }

              return (
                <div
                  key={task.type + '_' + task.subject + '_' + task.id}
                  className="mb-2"
                >
                  <Task
                    actionButtonLabel="More Actions"
                    resolveButtonLabel="Resolve"
                    Icon={DuoToneCourseOutline}
                    timeStamp={format(new Date(task.createdAt), 'dd/MM/yyyy')}
                    avatar={
                      <div className="flex h-14 w-18 justify-center overflow-hidden rounded bg-grey-01">
                        <img className="h-full max-w-none" src={image} />
                      </div>
                    }
                    onResolve={async () => {
                      await axios.delete(
                        `${config.backend}/notification/${task.id}`
                      );
                      setNotificationUpdate(newUid(20));
                    }}
                    mainAction={{
                      label: actionLabel,
                      onAction: () => navigate(task.globalLink),
                    }}
                    /*
                  moreActions={[
                    { label: 'Inplannen', onAction: () => {} },
                    { label: 'Bekijken', onAction: () => {} },
                  ]}
                  */
                  >
                    {label}
                  </Task>
                </div>
              );
            })
          ) : (
            <EmptyTable template={2} text={t('empty_tenplate.list_tasks')} />
          )}

          {/*
            <div className="mb-5">  
              <Task
                actionButtonLabel="More Actions"
                resolveButtonLabel="Resolve"
                Icon={DuoToneCourseOutline}
                timeStamp="Vandaag 19:43"
                avatar={<TeamAvatar name="Luke Skywalker" id="1" />}
                onResolve={() => {}}
                mainAction={{
                  label: t('pages.follow_up_overview.go_to_course'),
                  onAction: () => {},
                }}
                moreActions={[
                  { label: 'Inplannen', onAction: () => {} },
                  { label: 'Bekijken', onAction: () => {} },
                ]}
              >
                <b>Andrea Fornelli</b> heeft de cursus <b>Basis Mechanica 2</b>{' '}
                ingestuurd voor verbetering
              </Task>
            </div>
            <div className="mb-5">
              <Task
                actionButtonLabel="More Actions"
                resolveButtonLabel="Resolve"
                Icon={DuoToneCourseOutline}
                timeStamp="Vandaag 19:43"
                avatar={<TeamAvatar name="Luke Skywalker" id="1" />}
                onResolve={() => {}}
                mainAction={{
                  label: t('pages.follow_up_overview.improve'),
                  onAction: () => {},
                }}
                moreActions={[
                  { label: 'Inplannen', onAction: () => {} },
                  { label: 'Bekijken', onAction: () => {} },
                ]}
              >
                <b>Andrea Fornelli</b> heeft de cursus <b>Basis Mechanica 2</b>{' '}
                ingestuurd voor verbetering
              </Task>
            </div>
            <div className="mb-5">
              <Task
                actionButtonLabel="More Actions"
                resolveButtonLabel="Resolve"
                Icon={DuoToneCourseOutline}
                timeStamp="Vandaag 19:43"
                avatar={<TeamAvatar name="Luke Skywalker" id="1" />}
                onResolve={() => {}}
                mainAction={{
                  label: t('pages.follow_up_overview.schedule'),
                  onAction: () => {},
                }}
                moreActions={[
                  { label: 'Inplannen', onAction: () => {} },
                  { label: 'Bekijken', onAction: () => {} },
                ]}
              >
                <b>Andrea Fornelli</b> heeft de cursus <b>Basis Mechanica 2</b>{' '}
                ingestuurd voor verbetering
              </Task>
            </div>
            */}
        </>
      )}

      {tab === 'activity' && (
        <>
          {/*
            <div className="mb-6 flex flex-row">
              <span className="mr-3">
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setAllActivity(value);
                  }}
                  buttonText={t('pages.follow_up_overview.all_activity')}
                  options={allTeamsData}
                  searchable={true}
                  value={allActivity}
                />
              </span>

              <span>
                <FilterDropdown
                  clearButtonLabel="clear"
                  onChange={(value: any) => {
                    setAllUsers(value);
                  }}
                  buttonText={t('pages.follow_up_overview.all_users')}
                  options={teamsStatusesData}
                  searchable={false}
                  value={allUsers}
                />
              </span>
            </div>
            */}
          {notifications?.length ? (
            notifications?.map((task: any) => {
              let label, image;
              switch (task.type) {
                case 'tocorrect':
                  (label = (
                    <>
                      {t('notifications.corrections')}{' '}
                      <b>{task.text.courseName}</b>
                    </>
                  )),
                    (image =
                      task.text.courseImage ||
                      '/assets/images/opleidingen.jpg');
                  break;
                case 'courseadded':
                case 'coursestarted':
                case 'coursecomplete':
                  (label = (
                    <>
                      <b>{task.text.username}</b>{' '}
                      {t(
                        `notifications.${
                          task.type == 'coursestarted'
                            ? 'been_start_course'
                            : task.type == 'coursecomplete'
                            ? 'been_complet_course'
                            : 'been_add_course'
                        }`
                      )}{' '}
                      {task.text.courseType &&
                        t(`notifications.${task.text.courseType}`)}{' '}
                      <b>{task.text.courseName}</b>
                    </>
                  )),
                    (image = task.text.userImage);
                  break;
                default:
                  label = task.text.text;
                  break;
              }

              return (
                <div
                  key={task.type + '_' + task.subject + '_' + task.id}
                  className="mb-5"
                >
                  <Task
                    actionButtonLabel="More Actions"
                    resolveButtonLabel="Resolve"
                    Icon={
                      task.text.courseType == 'leervacature'
                        ? DuoToneVacancyOutline
                        : task.text.courseType == 'assessment'
                        ? DuoToneAssessmentOutline
                        : DuoToneCourseOutline
                    }
                    timeStamp={format(new Date(task.createdAt), 'dd/MM/yyyy')}
                    avatar={
                      <PersonAvatar
                        inline
                        id={task.text.userId}
                        name={task.text.username}
                        imageSrc={image}
                      ></PersonAvatar>
                    }
                    onResolve={async () => {
                      await axios.delete(
                        `${config.backend}/notification/${task.id}`
                      );
                      setNotificationUpdate(newUid(20));
                    }}
                    // mainAction={{
                    //   label: actionLabel,
                    //   onAction: () => navigate(task.globalLink),
                    // }}
                    /*
                  moreActions={[
                    { label: 'Inplannen', onAction: () => {} },
                    { label: 'Bekijken', onAction: () => {} },
                  ]}
                  */
                  >
                    {label}
                  </Task>
                </div>
              );
            })
          ) : (
            <EmptyTable
              template={2}
              text={t('empty_tenplate.list_activities')}
            />
          )}
        </>
      )}
    </div>
  );
}

export default NotificationList;

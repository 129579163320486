import React, { useEffect, useState } from 'react';
import { initMath } from '../MathView';

const ResultItem = ({ text, uid }) => {
  useEffect(() => {
    initMath().then(() => {
      const mathElements = document.querySelectorAll('.math');
      mathElements.forEach((element) => {
        const mathExpression = element.getAttribute('data-math');
        window.katex.render(mathExpression, element);
      });
    });
  }, [text]);

  const renderTextWithMath = () => {
    const parts = text.split(/\\\(|\\\)|\\\[|\\\]|\$|\$/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return <span key={index} className="math" data-math={part}></span>;
      }
      return <span key={index}>{part}</span>;
    });
  };

  if (!text) return null;

  return (
    <p id={uid} className="chat-item" style={{ whiteSpace: 'pre-line' }}>
      {renderTextWithMath()}
    </p>
  );
};

const ResultatField = ({ arrPrompt, chatContent }) => {
  const [oldContent, setOldContent] = useState(null);
  useEffect(() => {
    if (chatContent) {
      setOldContent(new XMLSerializer().serializeToString(chatContent));
    }
  }, [chatContent]);

  return (
    <div className="chat chat_resultat">
      {oldContent ? (
        <div dangerouslySetInnerHTML={{ __html: oldContent }} />
      ) : null}
      {arrPrompt &&
        arrPrompt?.map((list, i) => (
          <ResultItem key={i} uid={list.uid} text={list.text} />
        ))}
    </div>
  );
};
export default ResultatField;

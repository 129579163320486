import { Subject } from 'rxjs';

const events = [
  'Ready',
  'Initialize',
  'Terminate',
  'GetValue',
  'SetValue',
  'Commit',
  'GetLastError',
  'GetErrorString',
  'GetDiagnostic',
];

/* You can subscribe to events like this:
  ScormService.getInstance().Initialize.subscribe((...params) => {})
*/

class ScormService {
  constructor(props) {
    if (ScormService._instance) return ScormService._instance;
    ScormService._instance = this;

    for (let name of events) {
      this[name] = new Subject();
    }

    //communicated with iframe
    console.warn('initialising iframe listener');
    window.addEventListener(
      'message',
      (event) => {
        console.warn('received event', event);
        if (event.data.type === 'scorm') {
          console.warn('forwarding to', event.data.name);
          this[event.data.name].next(event.data.params);
        }
      },
      false
    );
  }
}

ScormService.getInstance = function (props) {
  return ScormService._instance || new ScormService(props);
};

export { ScormService };

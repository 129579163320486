import { useState, useEffect } from 'react';
import ExtraInfo from '@/components/ExtraInfo/ExtraInfo';
import { findElementsByKey } from '@/util';

const SidebarExtraInfoTree = ({
  node,
  publication,
  activeNode,
  prevTree,
  setTree,
  setAddLesson,
  setLessons,
  lessons,
  // assetTree,
  // setAsset,
  setActiveNode,
  setParentNode,
  setMobileSidebar,
  setExtraInfoEdit,
  extraInfoEdit,
  publicationName,
  isFroalaLoaded,
}: any) => {
  const [name, setName] = useState<string>(
    node?.data?.name || publicationName || ''
  );
  const [extraInfoLessons, setExtraInfoLessons] = useState<any>([]);
  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(false);

  useEffect(() => {
    const newNode = prevTree?.find((el: any) => el.id === node.id);
    if (newNode?.data?.name) {
      if (newNode?.data?.name !== name) {
        setName(newNode?.data?.name);
      }
    }
  }, [prevTree]);

  useEffect(() => {
    if (lessons) {
      setExtraInfoLessons(
        lessons?.filter((les: any) =>
          node?.data?.lessonId
            ? les?.rootLessonId === node?.data?.lessonId
            : les?.rootDirectoryId === node?.id
        )
      );
      setShowExtraInfo(true);
    }
  }, [lessons, node]);

  if (!showExtraInfo) return null;

  return (
    <div className="min-h-[calc(100vh_-_150px)]">
      <ExtraInfo
        setShowExtraInfo={setShowExtraInfo}
        activeNode={activeNode}
        node={node}
        publication={publication}
        prevTree={prevTree}
        setTree={setTree}
        setAddLesson={setAddLesson}
        setLessons={setLessons}
        // setAsset={setAsset}
        nameItem={name}
        extraInfoLessons={extraInfoLessons}
        setActiveNode={setActiveNode}
        setParentNode={setParentNode}
        setMobileSidebar={setMobileSidebar}
        setExtraInfoEdit={setExtraInfoEdit}
        extraInfoEdit={extraInfoEdit}
        isFroalaLoaded={isFroalaLoaded}
      />
    </div>
  );
};

export default SidebarExtraInfoTree;

import { useState, useEffect } from 'react';
import { isEmpty, newUid, deep_copy } from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import FroalaTextarea from '@/components/Froala/FroalaTextAreaEdit';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import { Switch, InputScore } from '@teo/components';
import { T } from '@/components/T';
import { QuestionSection } from '../QuestionSection';
import OpenQuestionGPT from './AIWidget/OpenQuestionAI/OpenQuestionGPT';
import retry from 'async-await-retry';
import WidgetCompetencies from './widgetOptions/WidgetCompetencies';

export const initialOpenVraag = () => ({
  score: 1,
  vraag: '',
  antwoord: '',
  showAnswer: true,
  type: 'OpenVraag',
});

export const OpenQuestionEdit = ({
  state,
  index,
  onModified = undefined,
  setStateFn = undefined,
}) => {
  const { t } = useTranslation();

  const [modified, setModified] = useState(newUid(20));
  const [immediateFeedback, setImmediateFeedback] = useState(
    !!state.immediateFeedback
  );
  const [showAnswer, setShowAnswer] = useState(
    state.showAnswer !== undefined ? state.showAnswer : state.antwoord !== ''
  );
  const [score, setScore] = useState(state.score || 0);
  const [questionVraag, setQuestionVraag] = useState(
    state?.vraag ? state?.vraag : null
  );
  const [antwoordVraag, setAntwoordVraag] = useState(
    state?.antwoord ? state?.antwoord : null
  );

  const [titleWidget, setTitleWidget] = useState(
    state?.titleWidget ? state?.titleWidget : t('widgets.type.open_question')
  );

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoadingAI, setIsLoadingAI] = useState(false);

  const [competencies, setCompetencies] = useState([]);

  //generate the state
  const getState = async () => {
    let newState = deep_copy(state);
    newState.immediateFeedback = immediateFeedback;
    await retry(() => {
      let questionNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-question .question_optional`
      );
      if (questionNode) {
        newState.vraag = questionNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    await retry(() => {
      let answerNode = document.querySelector(
        `#${CSS.escape(state.uid)} .wg-answer .question_optional`
      );
      if (answerNode) {
        newState.antwoord = answerNode.innerHTML;
        return Promise.resolve();
      }
      return Promise.reject();
    });

    newState.showAnswer = showAnswer;
    newState.score = score;
    newState.titleWidget = titleWidget;
    newState.competences = competencies;
    return newState;
  };
  setStateFn && setStateFn(getState);

  useEffect(() => {
    onModified && onModified();
  }, [onModified, modified]);

  return (
    <div
      data-open="SOW"
      className={`flex w-full flex-col gap-4 lg:p-4`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      id={state.uid}
    >
      <WidgetHeader
        index={index}
        titleWidget={titleWidget}
        setTitleWidget={setTitleWidget}
      />
      {/* <span className="text-lg font-semibold text-grey-04">
        {t('Question')} ({t('Optional')})
      </span> */}
      {isLoadingAI ? (
        <div className="spinner-chat mt-3">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      ) : (
        <div className="wg-question">
          <QuestionSection questionVraag={questionVraag} />
        </div>
      )}
      <div
        contentEditable={false}
        ref={(el) => {
          if (el) {
            el.style.setProperty('display', 'none', 'important');
          }
        }}
        id={`${state?.uid}_gpt_open`}
        onClick={() => {
          setIsChatOpen(!isChatOpen);
        }}
      ></div>
      {isChatOpen && (
        <div>
          <OpenQuestionGPT
            setQuestionVraag={setQuestionVraag}
            questionVraag={questionVraag}
            setIsChatOpen={setIsChatOpen}
            isLoadingAI={isLoadingAI}
            setIsLoadingAI={setIsLoadingAI}
            stateUid={state?.uid}
            state={state}
          />
        </div>
      )}

      <div>
        <div className="flex flex-row items-center border-t border-b border-grey-02 py-2">
          <Switch
            checked={showAnswer}
            onChange={(isCheck) => setShowAnswer(isCheck)}
          />
          <div className="mr-4 border-r-2 border-grey-02 px-4">
            <T>widgets.widgets_edit.explain_after_submit</T>
          </div>
          <div className="mr-2">
            <T>widgets.widgets_edit.max_score</T>
          </div>
          <InputScore value={score} onChange={(value) => setScore(value)} />
        </div>
        {/*
        <div className="flex gap-4 border-b border-grey-02 py-2">
          <Switch
            checked={immediateFeedback}
            onChange={(immediateFeedback) => {
              setImmediateFeedback(immediateFeedback);
            }}
          />
          <div className="mr-4">{t('widgets.edit.immediate_feedback')}</div>
        </div>
        */}
      </div>

      <div className={`${!showAnswer ? 'hidden' : ''}`}>
        {/* <span className="text-lg font-semibold text-grey-04">
          {t('Example answer')} ({t('Optional')})
        </span> */}
        <div className="wg-answer">
          <QuestionSection questionVraag={antwoordVraag} exampleAnswer={true} />
        </div>
      </div>
      <div data-closed="EOW"></div>
      <WidgetCompetencies
        state={state}
        setCompetencies={setCompetencies}
        competencies={competencies}
      />
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { NavContext } from '@/components/NavContext';
import { PageLayout } from '@/components/PageLayout';
import { Spinner } from '@teo/components';
import { axios } from '@/axios';
import { config } from '@/config';
import { newUid, useStateAndRef, nthIndexOf } from '@/util';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Contentcreators } from '@/models/Database';
import PublishModal from '@/pages/Protected/Create/PublishModal';
import { langData } from '@/mocks/landData';
import { LessonEdit } from '@/components/LessonEdit';
import { TranslatePublication } from '@/components/TranslatePublication';
import { ContentCreatorInviteModal } from '@/components/ContentCreatorInviteModal/ContentCreatorInviteModal';
import EditCourseHeader from './EditCourseHeader';
import { VersionPublication } from '@/components/VersionPublication';
import PublishCourse from '../PublishCourse/PublishCourse';
import { DuplicateLibrary } from '@/components/DuplicateLessons/DuplicateLibrary';
import NotificationMessage from '@/components/NotificationMessage/NotificationMessage';
import EditPublicationHomepage from './EditPublicationHomepage';
import { initMath } from '@/components/Froala/widgets/MathView';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { ConfirmModal } from '@/components/ConfirmModal';
import i18n from 'i18next';
import ChapterPage from '@/components/ChapterPage';
import { SidebarLessonTree } from '@/components/LessonTree/SidebarLessonTree';
import { SidebarScorm } from '@/components/LessonTree/SidebarScorm';
import SidebarExtraInfoTree from '@/components/ExtraInfo/SidebarExtraInfoTree';
import ShareModal from '@/pages/Protected/Create/ShareModal';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import ExportPublication from '@/components/ExportPublication';
import { version } from 'os';
import ScormLessonPage from '@/pages/Protected/Learn/Courses/ScormCourse/ScormLessonPage';
import ScormLessonContent from '@/pages/Protected/Learn/Courses/ScormCourse/ScormLessonContent';

const LOCK_TIMER = 2 * 60 * 1000;
const IDLE_TIMER = 15 * 60 * 1000;

function CreateCoursePage() {
  const { t } = useTranslation();
  const { setNavDisabled } = useContext(NavContext);
  const { newPage } = useContext(PageUrlContext);
  const [isOpenPublish, setIsOpenPublish] = useState(false);
  const [publication, setPublication] = useState<any>();
  const [isOpenPublishSettings, setIsOpenPublishSettings] = useState(false);
  const { coursecontentId }: any = useParams();
  const [isOpenChangeLang, setIsOpenChangeLang] = useState(false);
  const [isOpenVersionModal, setIsOpenVersionModal] = useState(false);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isOpenLibrary, setIsOpenLibrary] = useState(false);
  const { notification, setShowNotification } = useContext(NotificationContext);
  const [refresh, setRefresh] = useState<string>(newUid(20));
  const [locked, setLocked, lockedRef] = useStateAndRef(true);
  const [lockedOut, setLockedOut] = useState<boolean>(false);
  const [isOpenShareSettings, setIsOpenShareSettings] = useState(false);
  const [loadVersion, setLoadVersion] = useState<any>(true);
  const [selectVersion, setSelectVersion] = useState(null);
  const [publicationType, setPublicationType] = useState();
  const [refreshScorm, setRefreshScorm] = useState<string>(newUid(20));

  const [allPublicationCourses, setAllPublicationCourses] = useState([]);

  const [activeNode, realSetActiveNode, activeNodeRef] = useStateAndRef(null);
  const [isRefreshPage, setRefreshPage] = useState(false);
  const navigate = useNavigate();

  const { auth, setAuth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const [tree, setTree] = useState<any>(null);

  const [isFroalaLoaded, setIsFroalaLoaded] = useState(null);
  const { hints, setHints } = useContext(HintsContext);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  const isScorm = publication?.publicationType.startsWith('scorm');

  const idleTimer: any = useRef(null);
  const handleIdle = () => {
    clearInterval(lockTimer.current);
    clearTimeout(idleTimer.current);
    clearInterval(autosaveTimer.current);
    const lessonId = activeNodeRef?.current.data?.lessonId;
    axios.delete(config.backend + '/lessonlock/' + lessonId);
    setLocked(false);
  };

  const detectIdle = async (ev: any) => {
    if (document.visibilityState !== 'visible') {
      idleTimer.current = setTimeout(() => {
        clearTimeout(idleTimer.current);
        handleIdle();
      }, IDLE_TIMER);
    } else {
      const lessonId = activeNodeRef?.current.data?.lessonId;
      clearTimeout(idleTimer.current);
      if (lessonId && !lockedRef.current) {
        await axios
          .post(config.backend + '/lessonlocks', {
            lessonId,
            userId: authUser?.id,
          })
          .catch((e) => {
            setLockedOut(true);
            setActiveNode(null);
          });
        autosaveTimerReset.current();
        lockTimerReset.current();
        setLocked(true);
        setRefresh(newUid(20));
      }
    }
  };

  useEffect(() => {
    const lessonId = activeNode?.data?.lessonId;
    if (lessonId) {
      document.addEventListener('visibilitychange', detectIdle, false);
    } else {
      document.removeEventListener('visibilitychange', detectIdle, false);
    }
    return () => {
      clearTimeout(idleTimer.current);
      document.removeEventListener('visibilitychange', detectIdle, false);
    };
  }, [activeNode]);

  const lockTimer: any = useRef(null);
  const lockTimerReset: any = useRef(null);
  useEffect(() => {
    initMath();
    lockTimerReset.current = () => {
      clearInterval(lockTimer.current);
      lockTimer.current = setInterval(async () => {
        const lessonId = activeNodeRef?.current?.data?.lessonId;
        if (lessonId && authUser?.id) {
          axios.put(config.backend + '/lessonlock/' + lessonId, {
            lessonId,
            userId: authUser?.id,
          });
        }
      }, LOCK_TIMER - 30 * 1000);
    };
    lockTimerReset.current();
    return () => {
      const lessonId = activeNodeRef?.current?.data?.lessonId;
      if (lessonId && lockedRef.current) {
        axios.delete(config.backend + '/lessonlock/' + lessonId);
        setLocked(false);
      }
      clearInterval(lockTimer.current);
    };
  }, []);

  const setActiveNode = async (node: any) => {
    saveFn && (await saveFn());
    const newLessonId = node?.data?.id;
    const oldLessonId = activeNode?.data?.id;

    if (oldLessonId === newLessonId) return;

    const enter = () => {
      //we have permission to enter
      if (activeNodeRef?.current?.data?.lessonId && authUser?.id) {
        //release previous lock if any
        const lessonId = activeNodeRef?.current?.data?.lessonId;
        if (lessonId && lockedRef.current) {
          axios.delete(config.backend + '/lessonlock/' + lessonId);
          setLocked(false);
        }
      }
      if (node?.data?.lessonId && authUser?.id) {
        //acquire new lock if any
        axios
          .post(config.backend + '/lessonlocks', {
            lessonId: node.data.lessonId,
            userId: authUser?.id,
          })
          .catch((error) => {
            if (!navigator.onLine || error.message === 'Network Error') {
              alert('No internet connection');
              window.location.reload();
            } else {
              console.error('Error during request:', error);
            }
          });
        setLocked(true);
        lockTimerReset.current(); //refresh the lock
      } else {
        clearInterval(lockTimer.current); //cancel the lock referesh as we have no lock
      }
      realSetActiveNode(node);
    };

    if (!node?.data?.lessonId) {
      return enter();
    } //no need to acquire log as we're not entering a lesson
    axios
      .get(config.backend + '/lessonlock/' + node?.data?.lessonId)
      .then(() => {
        setShowNotification(t('notifications.lesson_locked'));
        return;
      })
      .catch((e) => {
        enter();
      });
  };

  const [prevTree, setPrevTree] = useState([]);
  const [addLesson, setAddLesson] = useState<any>();
  const [addFolder, setAddFolder] = useState<any>();
  const [directories, setDirectories] = useState<any>(null);
  const [lessons, setLessons] = useState<any>(null);
  // const [asset, setAsset] = useState<any>(null);
  // const [assetTree, setAssetTree] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const [lesQueryParams, setLesQueryParams] = useState<any>(
    queryParams.get('les')
  );

  const [saveFn, setSaveFn, saveFnRef] = useStateAndRef();

  const [mobileSidebar, setMobileSidebar] = useState(true);

  const [parentPath, setParentPath] = useState<any>(null);
  const [parentId, setParentId] = useState<any>(null);

  const [extraInfoEdit, setExtraInfoEdit] = useState(false);
  const [parentNode, setParentNode] = useState(null);
  const [updateLesInfo, setUpdateLesInfo] = useState(newUid(20));
  const [contentCreators, setContentCreators] = useState<any>(null);
  const [isOwner, setIsOwner] = useState<any>(false);

  const [isPiblished, setIsPiblished] = useState(false);

  const [publicationName, setPublicationName] = useState<any>(null);
  const [publicationImage, setPublicationImage] = useState<any>(null);

  useGet(`/coursecontent/${coursecontentId}`, {
    retry: false,
    onSuccess: (res: any) => {
      if (res?.data?.masterId !== null) {
        setTimeout(() => {
          return navigate(`/${i18n.language}/noaccess`);
        }, 0);
      }
      setPublication(res?.data || undefined);
      setPublicationType(res?.data?.publicationCourseType || 'course');
      setPublicationName(res?.data?.name);
      setPublicationImage(res?.data?.image);
    },
    onError: (res: any) => {
      setTimeout(() => {
        return navigate(`/${i18n.language}/noaccess`);
      }, 0);
    },
    refetch: refreshScorm,
  });

  useEffect(() => {
    setActiveNode(null);
  }, [refreshScorm]);

  const { refetch: refetchCreatorResults }: any = useGet<
    Array<Contentcreators>
  >(`/contentcreators`, {
    coursecontentId,
    onSuccess: (result: any) => {
      setContentCreators(result?.data || null);
    },
  });

  useGet(`/coursedetails`, {
    masterId: publication?.id,
    enabled: (auth?.user?.role || 0) >= 90,
    // organisationId: auth?.user?.activeOrganisationId,
    onSuccess: (res: any) => {
      setAllPublicationCourses(res?.data || []);
    },
  });

  useEffect(() => {
    if (contentCreators) {
      const myUser = contentCreators?.find(
        (user: any) => user?.id === authUser?.id
      );
      if (
        (myUser && myUser?.role >= 90) ||
        (authUser?.activeOrganisationRole || 0) >= 90
      ) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [contentCreators]);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const isDesctop = useMediaQuery({
    query: '(max-width: 1023px)',
  });

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  }, []);

  useEffect(() => {
    if (
      isOpenPublishSettings ||
      isOpenChangeLang ||
      isOpenVersionModal ||
      isOpenInviteModal ||
      isOpenPublish ||
      isOpenLibrary ||
      isOpenShareSettings
    ) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [
    isOpenPublishSettings,
    isOpenChangeLang,
    isOpenVersionModal,
    isOpenInviteModal,
    isOpenPublish,
    isOpenLibrary,
    isOpenShareSettings,
  ]);

  useEffect(() => {
    if (prevTree && !activeNode) {
      if (prevTree?.length > 0) {
        const queryParams = new URLSearchParams(window.location.search);
        const activeLes: any = queryParams.get('les');
        setActiveNode(
          activeLes != 0
            ? prevTree?.find((el: any) => el?.data?.id == activeLes)
            : prevTree?.find((el: any) => el.lessonId)
        );
      }
    }
  }, [prevTree]);

  useEffect(() => {
    if (hints) {
      const lastHint = hints?.allHints?.[hints?.allHints?.length - 1];
      if (lastHint == 0) {
        setShowWelcomeMessage(true);
      }
    }

    setPublicationType(publication?.publicationCourseType || 'course');
    const handleScroll = () => {
      const scrollSection = document.getElementById('course-lessons');
      const footer = document.getElementById('footer_page');
      if (footer) {
        const windowHeight = document.body.offsetHeight;
        const footerHeight = footer.offsetHeight;
        const scrollY = window.scrollY;
        const windowVisisble = window.innerHeight;
        const footerPosition = windowVisisble + scrollY + footerHeight;
        const isFooterVisible = footerPosition > windowHeight;
        let visibleFooterHeight = 0;
        if (isFooterVisible) {
          visibleFooterHeight = footerPosition - windowHeight;
        }
        if (scrollSection) {
          scrollSection.style.maxHeight =
            windowVisisble - visibleFooterHeight + 'px';
          scrollSection.style.minHeight =
            windowVisisble - visibleFooterHeight + 'px';
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [publication]);

  //this part registers autosave, which saves the results automatically
  //if not submitted.
  //Start by autosaveTimerReset.current()
  //stopped by clearInterval(autosaveTimer.current)
  const autosaveTimer: any = useRef(null);
  const autosaveTimerReset: any = useRef(null);
  useEffect(() => {
    initMath();
    autosaveTimerReset.current = () => {
      clearInterval(autosaveTimer.current);
      autosaveTimer.current = setInterval(async () => {
        if (saveFnRef.current) {
          saveFnRef.current(true);
        }
      }, 2 * 60 * 1000);
    };
    autosaveTimerReset.current();
    return () => {
      clearInterval(autosaveTimer.current);
    };
  }, []);

  const handleOpenLibrary = (path: any, id: any) => {
    setIsOpenLibrary(true);
    setParentPath(path);
    setParentId(id);
  };

  // console.log(isFroalaLoaded);

  useEffect(() => {
    const redirectPath = window.location.pathname.slice(
      0,
      nthIndexOf(window.location.pathname, '/', 3)
    );
    if (isPiblished) {
      navigate(redirectPath);
    }
  }, [isPiblished]);

  if (!publication) return null;

  return (
    <div className="flex flex-col">
      {!loadVersion && (
        <div className="fixed z-[201] m-auto flex min-h-screen min-w-full bg-white/75 p-10">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-14 !w-14 border-grey-06"
            />
          </div>
        </div>
      )}
      {lockedOut && (
        <ConfirmModal
          open
          title="pages.create_courses.locked_out_title"
          description="pages.create_courses.locked_out_description"
          onConfirm={() => setLockedOut(false)}
        />
      )}
      <EditCourseHeader
        publication={publication}
        setPublication={setPublication}
        allPublicationCourses={allPublicationCourses}
        setIsOpenChangeLang={setIsOpenChangeLang}
        setIsOpenInviteModal={setIsOpenInviteModal}
        setIsOpenVersionModal={setIsOpenVersionModal}
        contentCreators={contentCreators}
        setIsOpenPublish={setIsOpenPublish}
        setIsOpenPublishSettings={setIsOpenPublishSettings}
        setIsOpenShareSettings={setIsOpenShareSettings}
        setIsOpenExportModal={setIsOpenExportModal}
        setSelectVersion={setSelectVersion}
        activeNode={activeNode}
        setActiveNode={setActiveNode}
        coursecontentId={coursecontentId}
        setLesQueryParams={setLesQueryParams}
        setMobileSidebar={setMobileSidebar}
        mobileSidebar={mobileSidebar}
        newPage={newPage}
        isOwner={isOwner}
        onNavigate={async () => {
          try {
            if (saveFn) {
              await saveFn();
            }
          } catch (e) {
            console.error(e);
          }
        }}
      />
      {notification && <NotificationMessage />}

      <PageLayout>
        {/* {(!isMobile || (mobileSidebar && isMobile)) && ( */}
        <div
          style={{
            minHeight: 'calc(100vh - 150px)',
            maxHeight: isScorm ? 'calc(100vh - 150px)' : undefined,
          }}
          className={
            isScorm
              ? `max-h-[calc(100vh - 150px)] `
              : '' +
                `min-h-[calc(100vh - 150px)] relative border-r border-solid border-grey-02 !p-0 md:max-w-[350px] ${
                  !isMobile || (mobileSidebar && isMobile) ? '' : ' hidden '
                } `
          }
        >
          <div
            id="course-lessons"
            className={`${
              !extraInfoEdit && 'p-5'
            } sticky top-0 overflow-y-auto  md:w-[300px] lg:w-[350px] 
            ${
              (hints?.id == 'hint_39' || hints?.id == 'hint_40') &&
              'active-hints-tree z-[101] !overflow-y-visible bg-white'
            }
            `}
          >
            <>
              <div
                className={`${extraInfoEdit && 'hidden'}`}
                id="course-lessons-list"
              >
                {isScorm && (
                  <SidebarScorm
                    key={coursecontentId + '_' + publication?.publicationPath}
                    coursecontentId={coursecontentId}
                    publicationType={publicationType}
                    publicationPath={publication?.publicationPath}
                    activeNode={activeNode}
                    setActiveNode={realSetActiveNode}
                    isDesctop={isDesctop}
                  />
                )}

                {publication?.publicationType == 'teo' && (
                  <SidebarLessonTree
                    authUser={authUser}
                    isDesctop={isDesctop}
                    setMobileSidebar={setMobileSidebar}
                    setActiveNode={setActiveNode}
                    setLesQueryParams={setLesQueryParams}
                    activeNode={activeNode}
                    coursecontentId={coursecontentId}
                    setPrevTree={setPrevTree}
                    addLesson={addLesson}
                    setAddLesson={setAddLesson}
                    addFolder={addFolder}
                    setAddFolder={setAddFolder}
                    isRefreshPage={isRefreshPage}
                    setTree={setTree}
                    tree={tree}
                    prevTree={prevTree}
                    handleOpenLibrary={handleOpenLibrary}
                    setDirectories={setDirectories}
                    directories={directories}
                    setLessons={setLessons}
                    lessons={lessons}
                    setUpdateLesInfo={setUpdateLesInfo}
                    setLoadVersion={setLoadVersion}
                    loadVersion={loadVersion}
                    isFroalaLoaded={isFroalaLoaded}
                    hints={hints}
                    newPage={newPage}
                    publicationType={publicationType}
                  />
                )}
              </div>

              {extraInfoEdit && (
                <SidebarExtraInfoTree
                  node={parentNode}
                  publication={publication}
                  activeNode={activeNode}
                  prevTree={prevTree}
                  setTree={setTree}
                  setAddLesson={setAddLesson}
                  setLessons={setLessons}
                  lessons={lessons}
                  setActiveNode={setActiveNode}
                  setParentNode={setParentNode}
                  setMobileSidebar={setMobileSidebar}
                  setExtraInfoEdit={setExtraInfoEdit}
                  extraInfoEdit={extraInfoEdit}
                  publicationName={publication?.name}
                  isFroalaLoaded={isFroalaLoaded}
                />
              )}
            </>
          </div>
        </div>
        <div
          style={{
            minHeight: 'calc(100vh - 150px)',
            maxHeight: isScorm ? 'calc(100vh - 150px)' : undefined,
            width: '100%',
          }}
        >
          {isScorm && activeNode && (
            <ScormLessonContent
              width="calc(-350px + 100vw)"
              coursecontentId={coursecontentId}
              publicationPath={publication?.publicationPath}
              autoInit={true}
              node={activeNode}
              itemId={activeNode['@_identifier']}
            />
          )}
          {activeNode?.data?.id &&
            (!mobileSidebar || !isMobile) &&
            (activeNode?.droppable ? (
              <div className="w-full">
                <ChapterPage
                  node={activeNode}
                  publicationName={publication?.name}
                  publication={publication}
                  prevTree={prevTree}
                  setDirectories={setDirectories}
                  setTree={setTree}
                  setAddLesson={setAddLesson}
                  setLessons={setLessons}
                  lessons={lessons}
                  setActiveNode={setActiveNode}
                  setParentNode={setParentNode}
                  setMobileSidebar={setMobileSidebar}
                  setExtraInfoEdit={setExtraInfoEdit}
                  extraInfoEdit={extraInfoEdit}
                  updateLesInfo={updateLesInfo}
                  isFroalaLoaded={isFroalaLoaded}
                  hints={hints}
                  newPage={newPage}
                />
              </div>
            ) : (
              <div
                className={`w-full pb-2 ${
                  !activeNode?.data?.canEdit &&
                  activeNode?.data?.lessonId &&
                  'p-5'
                }`}
              >
                {
                  <LessonEdit
                    node={activeNode}
                    key={refresh}
                    setSaveFn={(fn: any) => {
                      setSaveFn(() => fn);
                    }}
                    resetAutoSave={() => autosaveTimerReset.current()}
                    publicationName={publication?.name}
                    publication={publication}
                    setTree={setTree}
                    prevTree={prevTree}
                    setAddLesson={setAddLesson}
                    setLessons={setLessons}
                    lessons={lessons}
                    setActiveNode={setActiveNode}
                    setParentNode={setParentNode}
                    setMobileSidebar={setMobileSidebar}
                    setExtraInfoEdit={setExtraInfoEdit}
                    extraInfoEdit={extraInfoEdit}
                    updateLesInfo={updateLesInfo}
                    setIsFroalaLoaded={setIsFroalaLoaded}
                    isFroalaLoaded={isFroalaLoaded}
                    hints={hints}
                    newPage={newPage}
                  ></LessonEdit>
                }
              </div>
            ))}
          {!activeNode &&
            publication?.id &&
            !lesQueryParams &&
            (!mobileSidebar || !isMobile) && (
              <EditPublicationHomepage
                setRefreshScorm={setRefreshScorm}
                coursecontentId={publication?.id}
                currentPublication={publication}
                setPublication={setPublication}
                directories={directories}
                setTree={setTree}
                setAddLesson={setAddLesson}
                setLessons={setLessons}
                lessons={lessons}
                prevTree={prevTree}
                setActiveNode={setActiveNode}
                setParentNode={setParentNode}
                setMobileSidebar={setMobileSidebar}
                setExtraInfoEdit={setExtraInfoEdit}
                extraInfoEdit={extraInfoEdit}
                isFroalaLoaded={isFroalaLoaded}
                hints={hints}
                setHints={setHints}
                showWelcomeMessage={showWelcomeMessage}
                setShowWelcomeMessage={setShowWelcomeMessage}
                newPage={newPage}
                publicationType={publicationType}
                isOwner={isOwner}
              />
            )}
        </div>
      </PageLayout>

      {isOpenPublish && (
        <PublishCourse
          publication={publication}
          data={langData}
          setIsOpenPublish={setIsOpenPublish}
          setIsPiblished={setIsPiblished}
          publicationType={publicationType}
          contentCreators={contentCreators}
          publicationName={publicationName}
          publicationImage={publicationImage}
        />
      )}

      {isOpenPublishSettings && (
        <PublishModal
          isOpenPublishSettings={isOpenPublishSettings}
          closeModal={() => setIsOpenPublishSettings(false)}
          publication={publication}
          data={langData}
          setIsOpenPublishSettings={setIsOpenPublishSettings}
          setPublication={setPublication}
          publicationType={publicationType}
        />
      )}
      {isOpenShareSettings && (
        <ShareModal
          isOpenShareSettings={isOpenShareSettings}
          closeModal={() => setIsOpenShareSettings(false)}
          publication={publication}
          data={langData}
          setIsOpenShareSettings={setIsOpenShareSettings}
          setPublication={setPublication}
          publicationType={publicationType}
        />
      )}
      {isOpenInviteModal && isOwner && (
        <ContentCreatorInviteModal
          onClose={() => setIsOpenInviteModal(false)}
          publication={publication}
          refetchCreatorResults={refetchCreatorResults}
          setPublication={setPublication}
          contentCreators={contentCreators}
          publicationType={publicationType}
          onNavigate={async () => {
            try {
              if (saveFn) {
                await saveFn();
              }
            } catch (e) {
              console.error(e);
            }
          }}
        />
      )}

      {isOpenVersionModal && (
        <VersionPublication
          closeModal={() => setIsOpenVersionModal(false)}
          publication={publication}
          setIsOpenVersionModal={setIsOpenVersionModal}
          activeNode={activeNode}
          setLoadVersion={setLoadVersion}
          loadVersion={loadVersion}
          setIsOpenExportModal={setIsOpenExportModal}
          setSelectVersion={setSelectVersion}
          publicationType={publicationType}
        />
      )}

      {isOpenChangeLang && (
        <TranslatePublication
          onClose={() => setIsOpenChangeLang(false)}
          publication={publication}
          setRefreshPage={setRefreshPage}
          publicationType={publicationType}
        />
      )}
      {isOpenLibrary && (
        <DuplicateLibrary
          closeModal={() => setIsOpenLibrary(false)}
          coursecontentId={coursecontentId}
          prevTree={prevTree}
          setAddLesson={setAddLesson}
          setAddFolder={setAddFolder}
          setIsOpenLibrary={setIsOpenLibrary}
          parentPath={parentPath}
          parentId={parentId}
          setTree={setTree}
          publication={publication}
        />
      )}
      {isOpenExportModal && (
        <ExportPublication
          closeModal={() => {
            setSelectVersion(null);
            setIsOpenExportModal(false);
          }}
          setIsOpenExport={setIsOpenExportModal}
          setSelectVersion={setSelectVersion}
          publication={publication}
          user={authUser}
          title={t('pages.create_edit_course.export')}
          version={selectVersion}
        />
      )}
    </div>
  );
}

export default CreateCoursePage;

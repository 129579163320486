import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Button, IconButton } from '@teo/components';
import { ImageFilled } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';

export const TargetBoxForUpload = ({
  onDrop,
  fileInputRef,
  handleUploadPhoto,
}: any) => {
  const { t } = useTranslation();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item: any) {
        // console.log('canDrop', item.files, item.items);
        return true;
      },
      hover(item: any) {
        // console.log('hover', item.files, item.items);
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;
        if (item) {
          // console.log('collect', item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onDrop]
  );
  const isActive = canDrop && isOver;

  return (
    <div
      ref={drop}
      contentEditable="false"
      className="
                flex
                flex-col
                items-center
                justify-center
                rounded-lg
                bg-grey-02
                px-3 py-8
            "
    >
      <div className="flex items-center" contentEditable="false">
        <img
          src="/images/icons/image.svg"
          alt="image"
          className="mr-3"
          contentEditable="false"
        />
        <span
          className="text-base font-black font-medium"
          contentEditable="false"
        >
          {t('widgets.type.add_image')}
        </span>
      </div>

      {isActive ? (
        <div
          className="my-4 text-sm font-normal text-grey-08"
          contentEditable="false"
        >
          {t('pages.upload_dropzone_section.drag_text_2')}
        </div>
      ) : (
        <div
          className="my-4 text-sm font-normal text-grey-08"
          contentEditable="false"
        >
          {t('widgets.type.select_an_image_or_drag')}
        </div>
      )}
      <Button
        contentEditable="false"
        variant="outline"
        size="md"
        onClick={() => {
          fileInputRef.current?.click();
        }}
        className="bg-grey-02"
      >
        {t('widgets.type.select_image')}
      </Button>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadPhoto}
        accept="image/jpg,.png,"
        style={{ display: 'none' }}
        multiple={false}
      />
    </div>
  );
};

import { rest } from 'msw';
import { config } from '@/config';
import { memberFactory } from './factories/members';

export const handlers = [
  rest.get('http://localhost/locales/en.json', (req, res, ctx) => {
    return res(ctx.json([{}]));
  }),
  rest.get('http://localhost/locales/nl.json', (req, res, ctx) => {
    return res(ctx.json([{}]));
  }),
  rest.get('http://localhost:8001/locales/en.json', (req, res, ctx) => {
    return res(ctx.json([{}]));
  }),
  rest.get('http://localhost:8001/locales/nl.json', (req, res, ctx) => {
    return res(ctx.json([{}]));
  }),
  rest.get(`${config.backend}/memberdetails`, (req, res, ctx) => {
    const queryParams = Object.fromEntries(req.url.searchParams);
    if (
      !queryParams.organisationId ||
      !queryParams.count ||
      !queryParams.offset
    ) {
      return res(ctx.status(400));
    }

    return res(
      ctx.json(Array.from(Array(Number(queryParams.count))).map(memberFactory))
    );
  }),
];

import {
  Modal,
  InputText,
  FilterButton,
  Button,
  FilterDropdown,
  Spinner,
} from '@teo/components';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import {
  Search,
  Eye,
  TimeFilled,
  ChevronDown,
  ChevronUp,
  Image,
  PlayFilled,
} from '@teo/components/icons';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { ActiveLesson } from './ActiveLesson';
import { convertToTime, newUid } from '@/util';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { DuplicationExtraInfo } from './DuplicationExtraInfo';
import { DuplicateCourse } from './DuplicateCourse';
import { ActiveChapter } from './ActiveChapter';

export const DuplicateLibrary = ({
  closeModal,
  coursecontentId,
  prevTree,
  setAddLesson,
  setAddFolder,
  setIsOpenLibrary,
  parentPath,
  parentId,
  setTree,
  extraInfo,
  isHomePage,
  droppable,
  extraInfoTree,
  publication,
}: any) => {
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user;
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [showLast, setShowLast] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [filterCurrentType, setFilterCurrentType] = useState<any>(null);

  const [category, setCategory] = useState<any>();
  const [lesType, setLesType] = useState<any>();
  const [currentType, setCurrentType] = useState<any>();
  const [assesType, setAssesType] = useState<any>();
  const [listCategories, setListCategories] = useState<any>([]);
  const [lessonSelected, setLessonSelected] = useState<any>(false);
  const [activeLesson, setActiveLesson] = useState<any>();
  const [chapterSelected, setChapterSelected] = useState<any>(false);
  const [activeChapter, setActiveChapter] = useState<any>();
  const [lessons, setLessons] = useState<any>();
  const [loadLessons, setLoadLessons] = useState<any>(true);
  const [isExtraInfo, setIsExtraInfo] = useState(false);
  const [tab, setTab] = useState<string>('current');
  const [currentId, setCurrentId] = useState(null);
  const [refresh, setRefresh] = useState<string>(newUid(20));

  const allCategories: any = useGet(`/categories`, {
    count: 1000,
    sort: 'tag',
    lang: auth?.user?.lang,
  });

  useEffect(() => {
    if (allCategories?.data) {
      const data = (allCategories?.data?.data as any[]).map((tag) => ({
        id: tag?.id,
        name: tag?.tag,
      }));
      setListCategories(data);
    }
  }, [allCategories?.data]);

  useEffect(() => {
    if (!filterInput) {
      setLoadLessons(false);
    }
  }, [filterInput]);

  useEffect(() => {
    switch (currentType?.id) {
      case 1:
        setFilterCurrentType(
          '?assetId=null&rootDirectoryId=null&rootLessonId=null'
        );
        break;
      case 2:
        setFilterCurrentType('?assetId=null&scope=extrainfo');
        break;
      case 3:
        setFilterCurrentType(`?lessonId=null&assetType=image`);
        break;
      case 4:
        setFilterCurrentType(`?lessonId=null&assetType=video`);
        break;
      default:
        setFilterCurrentType('');
        break;
    }
  }, [currentType]);

  const selectLes = (les: any) => {
    setLessonSelected(true);
    setActiveLesson(les);
  };

  const selectChapter = (chapter: any) => {
    setChapterSelected(true);
    setActiveChapter(chapter);
  };

  useEffect(() => {
    if (extraInfo) {
      setIsExtraInfo(true);
      setTab('current');
    } else {
      setIsExtraInfo(false);
      setTab('all');
    }
  }, [extraInfo]);

  useEffect(() => {
    if (extraInfo && tab == 'current' && coursecontentId) {
      setCurrentId(coursecontentId);
    } else {
      setCurrentId(null);
    }
  }, [extraInfo, tab, coursecontentId]);

  return (
    <Modal isOpen={true}>
      <div className={`${(lessonSelected || chapterSelected) && 'hidden'} `}>
        <Modal.Header onClose={closeModal}>
          {t('pages.create_edit_course.library.title')}
        </Modal.Header>
        <Modal.Body>
          <div className="-mx-6 max-h-[70vh] overflow-y-scroll px-6">
            <div className="pb-5">
              <InputText
                value={filterInput}
                onChange={(ev) => {
                  setFilterInput(ev.target.value);
                  setLoadLessons(true);
                }}
                Icon={Search}
                placeholder={t(
                  'pages.create_edit_course.library.placeholder_search'
                )}
              />
            </div>
            <div className="relative z-[1] mb-6 flex flex-col">
              <div className="flex flex-row">
                {isExtraInfo && (
                  <div className="flex gap-3">
                    <FilterButton
                      active={tab === 'all'}
                      onClick={() => setTab('all')}
                      size="sm"
                    >
                      {t('pages.create_edit_course.library.lessons_library')}
                    </FilterButton>
                    <FilterButton
                      active={tab === 'current'}
                      onClick={() => setTab('current')}
                      size="sm"
                    >
                      {t('pages.create_edit_course.library.current_course')}
                    </FilterButton>
                  </div>
                )}

                {!isExtraInfo && (
                  <div className={`relative flex items-center`}>
                    <FilterButton
                      active={tab === 'all'}
                      onClick={() => {
                        if (tab != 'all') {
                          setLoadLessons(true);
                          setTab('all');
                        }
                      }}
                      size="sm"
                    >
                      {t('pages.create_library.all')}
                    </FilterButton>
                    <div className="w-3"></div>
                    <FilterButton
                      active={tab === 'lessons'}
                      onClick={() => {
                        if (tab != 'lessons') {
                          setLoadLessons(true);
                          setTab('lessons');
                        }
                      }}
                    >
                      {t('pages.create_library.lessons')}
                    </FilterButton>
                    <div className="w-3"></div>
                    <FilterButton
                      active={tab === 'chapters'}
                      onClick={() => {
                        if (tab != 'chapters') {
                          setLoadLessons(true);
                          setTab('chapters');
                        }
                      }}
                    >
                      {t('pages.create_library.chapters')}
                    </FilterButton>
                  </div>
                )}

                <Button
                  variant="outline"
                  size="sm"
                  className="ml-auto"
                  onClick={() => {
                    setMoreFilter(!moreFilter);
                  }}
                >
                  {moreFilter
                    ? t('pages.create_edit_course.library.fewer_filters')
                    : t('pages.create_edit_course.library.more_filters')}
                </Button>
              </div>
              {moreFilter && (
                <div className="mt-5 flex flex-col">
                  <div className="mb-4 flex flex-col sm:flex-row">
                    <span className="mb-4 pr-2 sm:mb-0">
                      <FilterDropdown
                        clearButtonLabel="clear"
                        onChange={(value: any) => {
                          value && setLoadLessons(true);
                          setCategory(value);
                        }}
                        buttonText={`${t(
                          'pages.follow_up.all_categories_tag'
                        )}`}
                        options={listCategories}
                        searchable={true}
                        value={category}
                      />
                    </span>
                    {/* <span className="pr-2">
                      <FilterDropdown
                        clearButtonLabel="clear"
                        onChange={(value: any) => {
                          setLoadLessons(true);
                          setRefresh(newUid(20));
                          setLesType(value);
                        }}
                        buttonText={`${t('pages.follow_up.course_type_tag')}`}
                        options={[
                          {
                            id: 'theory',
                            name: t('pages.create_edit_course.library.theory'),
                          },
                          {
                            id: 'practice',
                            name: t(
                              'pages.create_edit_course.library.practice'
                            ),
                          },
                        ]}
                        // searchable={true}
                        value={lesType}
                        dropdownPositionMobileRight={true}
                      />
                    </span> */}
                  </div>

                  {isExtraInfo && (
                    <div className="flex flex-col sm:flex-row">
                      <span className="mb-4 pr-2 sm:mb-0">
                        <FilterDropdown
                          clearButtonLabel="clear"
                          onChange={(value: any) => {
                            setLoadLessons(true);
                            setRefresh(newUid(20));
                            setCurrentType(value);
                          }}
                          buttonText={`${t(
                            'pages.create_edit_course.library.les_type'
                          )}`}
                          options={[
                            {
                              id: 'notextrainfo',
                              name: t(
                                'pages.create_edit_course.library.lessons'
                              ),
                            },
                            {
                              id: 'extrainfo',
                              name: t(
                                'pages.create_edit_course.library.extra_info'
                              ),
                            },
                          ]}
                          value={currentType}
                          dropdownPositionMobileRight={true}
                        />
                      </span>
                      <span className="pr-2">
                        <FilterDropdown
                          clearButtonLabel="clear"
                          onChange={(value: any) => {
                            setLoadLessons(true);
                            setRefresh(newUid(20));
                            setAssesType(value);
                          }}
                          buttonText={`${t(
                            'pages.create_edit_course.library.asset_type'
                          )}`}
                          options={[
                            {
                              id: 'image',
                              name: t('pages.create_edit_course.library.image'),
                            },
                            {
                              id: 'video',
                              name: t('pages.create_edit_course.library.video'),
                            },
                          ]}
                          value={assesType}
                          dropdownPositionMobileRight={true}
                        />
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-row items-center">
              {isExtraInfo ? (
                <h4 className="mb-2 text-base">
                  {tab === 'all'
                    ? t(`pages.create_library.les_library`)
                    : t(`pages.create_library.cur_library`)}
                </h4>
              ) : (
                <h4 className="mb-2 text-base">
                  {tab === 'lessons'
                    ? t(`pages.create_library.les_library`)
                    : tab === 'chapters'
                    ? t(`pages.create_library.chp_library`)
                    : category || !!filter
                    ? t(`pages.create_library.title`)
                    : t('pages.create_edit_course.library.recently_viewed')}
                </h4>
              )}
              <span
                className="ml-auto flex cursor-pointer items-center text-xs"
                onClick={() => setShowLast(!showLast)}
              >
                {t('pages.create_edit_course.library.latest_btn')}
                {showLast ? (
                  <ChevronUp className="h-4 w-4" />
                ) : (
                  <ChevronDown className="h-4 w-4" />
                )}
              </span>
            </div>
            <div className="mt-3 flex w-full max-w-6xl flex-col flex-wrap gap-2 md:gap-3 lg:gap-2">
              {loadLessons && (
                <div className="mx-auto p-10">
                  <Spinner
                    ariaLabel="Loading spinner"
                    className="!h-10 !w-10 border-grey-05"
                  />
                </div>
              )}
              {isExtraInfo && (
                <DuplicationExtraInfo
                  currentId={currentId}
                  refresh={refresh}
                  loadLessons={loadLessons}
                  currentType={currentType}
                  assesType={assesType}
                  setLessons={setLessons}
                  setLoadLessons={setLoadLessons}
                  lesType={lesType}
                  showLast={showLast}
                  filter={filterInput}
                  selectLes={selectLes}
                />
              )}
              {!isExtraInfo && (
                <DuplicateCourse
                  refresh={refresh}
                  loadLessons={loadLessons}
                  setLessons={setLessons}
                  setLoadLessons={setLoadLessons}
                  showLast={showLast}
                  filter={filterInput}
                  selectLes={selectLes}
                  selectChapter={selectChapter}
                  category={category}
                  setCategory={setCategory}
                  setMoreFilter={setMoreFilter}
                  tab={tab}
                  allCategories={allCategories}
                  authUser={authUser}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </div>

      {lessonSelected && (
        <ActiveLesson
          closeModal={() => setLessonSelected(false)}
          activeLesson={activeLesson}
          setLessonSelected={setLessonSelected}
          coursecontentId={coursecontentId}
          prevTree={prevTree}
          setAddLesson={setAddLesson}
          setIsOpenLibrary={setIsOpenLibrary}
          parentPath={parentPath}
          parentId={parentId}
          setTree={setTree}
          isExtraInfo={isExtraInfo}
          isHomePage={isHomePage}
          droppable={droppable}
          extraInfoTree={extraInfoTree}
          publication={publication}
          currentId={currentId}
          auth={auth}
        />
      )}
      {chapterSelected && (
        <ActiveChapter
          closeModal={() => setChapterSelected(false)}
          setChapterSelected={setChapterSelected}
          activeChapter={activeChapter}
          coursecontentId={coursecontentId}
          prevTree={prevTree}
          parentPath={parentPath}
          parentId={parentId}
          setIsOpenLibrary={setIsOpenLibrary}
          setAddLesson={setAddLesson}
          setAddFolder={setAddFolder}
          setTree={setTree}
        />
      )}
    </Modal>
  );
};

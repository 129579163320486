export const hintsData = [
  { id: 'hint_00', completed: false },
  { id: 'hint_01', completed: false },
  { id: 'hint_02', completed: false },
  { id: 'hint_03', completed: false },
  { id: 'hint_04', completed: false },
  { id: 'hint_05', completed: false },
  { id: 'hint_06', completed: false },
  { id: 'hint_7', completed: false },
  { id: 'hint_8', completed: false },
  { id: 'hint_09', completed: false },
  { id: 'hint_10', completed: false },
  { id: 'hint_11', completed: false },
  { id: 'hint_12', completed: false },
  { id: 'hint_13', completed: false },
  { id: 'hint_14', completed: false },
  { id: 'hint_15', completed: false },
  { id: 'hint_16', completed: false },
  { id: 'hint_17', completed: false },
  { id: 'hint_18', completed: false },
  { id: 'hint_19', completed: false },
  { id: 'hint_20', completed: false },
  { id: 'hint_21', completed: false },
  { id: 'hint_22', completed: false },
  { id: 'hint_23', completed: false },
  { id: 'hint_24', completed: false },
  { id: 'hint_25', completed: false },
  { id: 'hint_26', completed: false },
  { id: 'hint_27', completed: false },
  { id: 'hint_28', completed: false },
  { id: 'hint_29', completed: false },
  { id: 'hint_30', completed: false },
  { id: 'hint_31', completed: false },
  { id: 'hint_32', completed: false },
  { id: 'hint_33', completed: false },
  { id: 'hint_34', completed: false },
  { id: 'hint_35', completed: false },
  { id: 'hint_36', completed: false },
  { id: 'hint_37', completed: false },
  { id: 'hint_38', completed: false },
  { id: 'hint_39', completed: false },
  { id: 'hint_40', completed: false },
  { id: 'hint_41', completed: false },
  { id: 'hint_42', completed: false },
  { id: 'hint_43', completed: false },
  { id: 'hint_44', completed: false },
];

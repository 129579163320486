//import { DndProvider } from 'react-dnd';
//import { HTML5Backend } from 'react-dnd-html5-backend';
import React, { useRef, useState, useCallback } from 'react';
import { Label } from '@teo/components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TargetBox } from './TargetBox';
import { useTranslation } from 'react-i18next';
import UploadedPhoto from '@/components/UploadDropzoneSection/UploadedPhoto';
import FileContainer from './FileContainer';
import { uploadImage } from '@/query/documents';
import { LibraryModal } from '../LibraryModal/LibraryModal';

const UploadDropzoneSection = ({
  setUploadLogo,
  uploadLogo,
  courseImg,
  onBlur,
  bannerLPImg,
}: any) => {
  const [photoInfo, setPhotoInfo] = useState();
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const [isOpenLibraryModal, setIsOpenLibraryModal] = useState(false);

  const handleFileDrop = useCallback(
    async (item: { files: any }) => {
      if (item) {
        const files = item.files;
        setDroppedFiles(files);
        const size = courseImg || bannerLPImg ? 900 : 100;
        const data = await uploadImage(item.files[0], size, size, 'jpeg');
        setUploadLogo(data);
      }
    },
    [setDroppedFiles, setUploadLogo]
  );

  const handleUploadPhoto = async (event: any): Promise<any> => {
    const img = event.target.files[0];
    setPhotoInfo(img);
    const size = courseImg || bannerLPImg ? 900 : 100;
    const data = await uploadImage(img, size, size, 'jpeg');
    setUploadLogo(data);
  };

  const handleUploadPhotoLibrary = async (image: any): Promise<any> => {
    const res = await fetch(image);
    const blob = await res.blob();
    const mime = blob.type;
    const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);
    const img: any = new File([blob], `${blob.size}.${ext}`, {
      type: mime,
    });
    setPhotoInfo(img);
    const size = courseImg || bannerLPImg ? 900 : 100;
    const data = await uploadImage(img, size, size, 'jpeg');
    setUploadLogo(data);
  };

  return (
    <>
      <div>
        <div id="uploadDropzone" className="mt-3">
          <DndProvider backend={HTML5Backend}>
            {!photoInfo && droppedFiles.length === 0 && !uploadLogo && (
              <TargetBox
                onDrop={handleFileDrop}
                fileInputRef={fileInputRef}
                handleUploadPhoto={handleUploadPhoto}
                courseImg={courseImg}
                bannerLPImg={bannerLPImg}
                setIsOpenLibraryModal={setIsOpenLibraryModal}
              />
            )}
            {droppedFiles.length > 0 && !photoInfo && (
              <FileContainer
                files={droppedFiles}
                fileInputRef={fileInputRef}
                handleUploadPhoto={handleUploadPhoto}
                courseImg={courseImg}
                bannerLPImg={bannerLPImg}
              />
            )}
            {(photoInfo || uploadLogo) && (
              <UploadedPhoto
                photoInfo={photoInfo}
                fileInputRef={fileInputRef}
                handleUploadPhoto={handleUploadPhoto}
                uploadLogo={uploadLogo}
                courseImg={courseImg}
                bannerLPImg={bannerLPImg}
                setIsOpenLibraryModal={setIsOpenLibraryModal}
              />
            )}
          </DndProvider>
        </div>
      </div>
      {isOpenLibraryModal && (
        <LibraryModal
          setIsOpenLibraryModal={setIsOpenLibraryModal}
          isOpenLibraryModal={isOpenLibraryModal}
          lessonPage={false}
          setUploadFile={handleUploadPhotoLibrary}
        />
      )}
    </>
  );
};

export default UploadDropzoneSection;

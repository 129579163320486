export const IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'bmp'];
export const VIDEO_EXTENSIONS = [
  'mp4',
  'mov',
  'avi',
  'wmv',
  'flv',
  'webm',
  'mkv',
];

export const fileFormats = [
  {
    label: 'Image',
    value: IMAGE_EXTENSIONS.join(';'),
    key: IMAGE_EXTENSIONS.join(';'),
    filter: 'image/*',
  },
  {
    label: 'Video',
    value: VIDEO_EXTENSIONS.join(';'),
    key: VIDEO_EXTENSIONS.join(';'),
    filter: 'video/*',
  },
  {
    label: 'PDF Portable document format',
    value: 'pdf',
    key: 'pdf',
    filter: 'application/pdf',
  },
  {
    label: 'DOC/DOCX MS Word',
    value: 'doc;docx',
    key: 'doc;docx',
    filter: 'application/doc,application/docx',
  },
  {
    label: 'ODT Open document format',
    value: 'odt',
    key: 'odt',
    filter: 'application/odt',
  },
  {
    label: 'XLS/XLSX MS Excell',
    value: 'xls;xlsx',
    key: 'xls;xlsx',
    filter: 'application/xls,application/xlsx',
  },
  {
    label: 'ODS Open document format',
    value: 'ods',
    key: 'ods',
    filter: 'application/ods',
  },
  {
    label: 'PPT/PPTX MS Powerpoint',
    value: 'ppt;pptx',
    key: 'ppt;pptx',
    filter: 'application/ppt,application/pptx',
  },
  { label: 'TXT text', value: 'txt', key: 'txt', filter: 'text/plain' },
  { label: 'All', value: 'all', key: 'all', filter: '*' },
];

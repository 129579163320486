import { useGet } from '@/query/fetchHooks';
import { useState, useContext, useEffect } from 'react';

export const ImportAction = ({ les, setContent }: any) => {
  const [copyContent, setCopyContent] = useState<any>();
  useGet<any>(les?.getData, {
    enabled: !!les?.getData,
    onSuccess: (result: any) => {
      setCopyContent(result || null);
      // setContent(result?.data || null);
    },
  });

  useEffect(() => {
    if (copyContent) {
      setContent(copyContent?.data ? copyContent?.data : '<p></p>');
    }
  }, [copyContent]);

  return <></>;
};

import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { useNavigate, useParams } from 'react-router-dom';
import ListOfPublications from './ListOfPublications';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useContext, useState, useEffect } from 'react';
import EmptyTable from '@/components/DataTable/EmptyTable';
import { parseQueryString } from '@/util';
import TypeCourse from '@/components/CreateNewPublication/TypeCourse';

function CreateIndexPage() {
  const { t } = useTranslation();
  const { organisationId } = useParams();
  const basePath = window.location.pathname;
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const authLang: any = auth?.user.lang;
  const authOrg: any = auth?.user?.activeOrganisationId;
  const [courses, setCourses] = useState<any>(null);
  const [vacancies, setVacancies] = useState<any>(null);
  const [assessments, setAssessments] = useState<any>(null);

  const [goToCreate, setGoToCreate] = useState(false);
  const [publicationId, setPublicationId] = useState();

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };

  const [isOpenTypeModal, setIsOpenTypeModal] = useState(
    !!queries['courseType']
  );

  const [template, setTemplate] = useState('theory');
  // const [typeCourse, setTypeCourse] = useState('courses');

  useGet('/myowedpublications', {
    userId: authUser?.id,
    organisationId,
    publicationCourseType: 'course',
    publicationStatus:
      'published,OR,publicationStatus=concept,OR,publicationStatus=template',
    offset: 0,
    count: 4,
    sort: '-updatedAt',
    onSuccess: (result: any) => {
      setCourses(result?.data || []);
    },
  });

  useGet('/myowedpublications', {
    userId: authUser?.id,
    organisationId,
    publicationCourseType: 'assessment',
    publicationStatus:
      'published,OR,publicationStatus=concept,OR,publicationStatus=template',
    offset: 0,
    count: 4,
    sort: '-updatedAt',
    onSuccess: (result: any) => {
      setAssessments(result?.data || []);
    },
  });

  useGet('/myowedpublications', {
    userId: authUser?.id,
    organisationId,
    publicationCourseType: 'leervacature',
    publicationStatus:
      'published,OR,publicationStatus=concept,OR,publicationStatus=template',
    offset: 0,
    count: 4,
    sort: '-updatedAt',
    onSuccess: (result: any) => {
      setVacancies(result?.data || []);
    },
  });

  useEffect(() => {
    if (goToCreate) {
      setIsOpenTypeModal(false);
      navigate(`/${authLang}/create/${authOrg}/courses/${publicationId}`);
    }
  }, [goToCreate]);

  const courseType = authUser?.useCourse;
  const assessmentType = authUser?.useAssessment;
  const vacancyType = authUser?.useLeervacature;

  if (!courses || !assessments || !vacancies) return null;

  return !isOpenTypeModal ? (
    <div className="w-full pb-8">
      <div className="mb-6 flex w-full justify-between">
        <div
          className="w-full"
          style={{
            background:
              'url("/images/components/create/create_index_img.svg") no-repeat center right',
            backgroundSize: 'contain',
          }}
        >
          <h1 className="mt-8 text-2xl font-bold text-black">
            {t('pages.create_index.title')}
          </h1>
          <div className="mt-4 max-w-[532px] text-sm font-normal text-[#7d7d81] md:mt-8">
            {t('pages.create_index.description')}
          </div>
        </div>
      </div>

      {courses?.length || assessments?.length || vacancies?.length ? (
        <>
          {courseType && (
            <ListOfPublications
              basePath={`${basePath}/courses`}
              courses={courses}
              type={'courses'}
            />
          )}

          {assessmentType && (
            <ListOfPublications
              basePath={`${basePath}/assessments`}
              courses={assessments}
              type={'assessments'}
            />
          )}

          {vacancyType && (
            <ListOfPublications
              basePath={`${basePath}/vacancies`}
              courses={vacancies}
              type={'leervacatures'}
            />
          )}
        </>
      ) : (
        <EmptyTable
          template={2}
          text={t('empty_tenplate.course_list')}
          btn_text={t('empty_tenplate.btn_creat_course')}
          btnClick={() => setIsOpenTypeModal(true)}
        />
      )}
    </div>
  ) : (
    <TypeCourse
      setIsOpenTypeModal={setIsOpenTypeModal}
      setTemplate={setTemplate}
      template={template}
      setGoToCreate={setGoToCreate}
      typeCourse={'courses'}
      setPublicationId={setPublicationId}
      publicationId={publicationId}
    />
  );
}

export default CreateIndexPage;

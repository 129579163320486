import { useTranslation } from 'react-i18next';
import { useContext, useState, useRef, useEffect } from 'react';
import {
  CrossFilled,
  DuoToneCourseFilled,
  Plus,
  Import2,
} from '@teo/components/icons';
import { axios } from '@/axios';
import { config } from '@/config';
import { AuthContext } from '@/components/Contexts/AuthContext';
import CompetencyProfilesModal from './CompetencyProfilesModal';
import { parseTypeCourse, useMobile, newUid, uploadFile } from '@/util';
import Hint from '../Hints/Hints';
import HintOverlay from '../Hints/HintOverlay';
import { HintsContext } from '@/components/Contexts/HintsContext';
import FileContainer from '@/components/UploadDropzoneSection/FileContainer';
import FileContainerDropzone from '@/components/UploadDropzoneImage/FileContainerDropzone';
import { TargetBoxCoures } from '@/components/UploadDropzoneSection/TargetBoxCoures';
import {
  InputText,
  Label,
  InputTextarea,
  Spinner,
  IconButton,
  Tag,
  Button,
  InputSelect,
} from '@teo/components';
import { useNavigate } from 'react-router-dom';
import { Userwithorganisationwithidps } from '@/models';
import { uploadImage, uploadFile as uploadFileFn } from '@/query/documents';
import { ViewUplaodElement } from '@/components/ViewUplaodElement';
import { UploadFileBox } from '@/components/UploadDropzoneSection/UploadFileBox';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { courseTypeOptions } from '@/models/courseTypeOptions';

let templates: any;
templates = {
  en: {
    theory: 3605,
    practice: 3606,
    instruction: 4958,
    scorm: 5267,
  },
  fr: {
    theory: 3609,
    practice: 3610,
    instruction: 4959,
    scorm: 5269,
  },
  nl: {
    theory: 3607,
    practice: 3608,
    instruction: 4957,
    scorm: 5268,
  },
  es: {
    theory: 3955,
    practice: 3608,
    instruction: 4960,
    scorm: 5270,
  },
};

if (window.location.hostname === 'teo.training') {
  templates = {
    en: {
      theory: 6741,
      practice: 6742,
      instruction: 9398,
      scorm: 9919,
    },
    fr: {
      theory: 6743,
      practice: 6744,
      instruction: 9399,
      scorm: 9920,
    },
    nl: {
      theory: 6739,
      practice: 6740,
      instruction: 9397,
      scorm: 9918,
    },
    es: {
      theory: 9395,
      practice: 9396,
      instruction: 9400,
      scorm: 9921,
    },
  };
}

export const CreateNewImport = ({
  setIsOpen,
  typeCourse,
  setGoToCreate,
  setPublicationId,
  publicationId,
  setLoadeNewPublication,
}: any) => {
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const { auth } = useContext(AuthContext);
  const authLang: any = auth?.user.lang;
  const authOrg: any = auth?.user?.activeOrganisationId;
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isOpenCompetency, setIsOpenCompetency] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState<null | string>(null);
  const [courseType, setCourseType] = useState<string>('course');

  const [selectCompetency, setSelectCompetency] = useState<any[]>([]);
  const { setShowNotification } = useContext(NotificationContext);

  const [files, setFiles] = useState<File[]>([]);

  const authUser = auth?.user as Userwithorganisationwithidps;
  const navigate = useNavigate();

  const [importType, setImportType] = useState('scorm');

  const isMobile = useMobile();
  const { hints, setHints } = useContext(HintsContext);

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigateTo]);

  const handleStart = async () => {
    try {
      setLoading(true);
      //setLoadeNewPublication && setLoadeNewPublication(true);
      const id = templates?.[authLang]?.[importType];
      const newId = await axios.post(`${config.backend}/publish/copy/${id}`);
      const publicationId = newId?.data?.id;
      const path = newUid(20);

      await Promise.allSettled([
        axios.post(`${config.backend}/membercoursecontents`, {
          role: 90,
          coursecontentId: publicationId,
          organisationId: authOrg,
        }),
        axios.put(`${config.backend}/coursecontent/${publicationId}`, {
          authorId: auth?.user?.id,
          publicationStatus: 'concept',
          publicationCourseType: courseType,
          name: name || undefined,
          publicationPath: path,
          description: description || undefined,
        }),
        selectCompetency?.map((el: any, index: any) =>
          addCompetency(el, index)
        ),
      ]);

      //uploadFile().then((files) => {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('coursecontentId', publicationId);
      formData.append('publicationPath', path);

      await axios.post(`${config.backend}/import/publication/scorm`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      //setIsOpen(false);
      setNavigateTo(`/${authLang}/create/${authOrg}/courses/${publicationId}`);
      //setTimeout(() => navigate(`/${authLang}/create/${authOrg}/courses/${publicationId}`), 0);
    } catch (e: any) {
      console.error(e);
      setLoading(false);
      setShowNotification({
        message: e?.response?.data?.message || e?.message,
        delay: 10000,
      });
    }
  };

  const addCompetency = async (el: any, index: any) => {
    const data = await axios.post(
      `${config.backend}/coursecontentprofessions`,
      {
        coursecontentId: publicationId,
        professionId: el?.key,
      }
    );
    if (selectCompetency?.length - 1 === index) {
      data?.data?.professionId && setLoaded(true);
    }
  };

  return (
    <div
      ref={contentRef}
      className="fixed top-0 left-0 z-[100] flex h-screen w-screen flex-col overflow-y-auto bg-white"
    >
      <div className="flex min-h-16 flex-col justify-start border-b border-grey-02 py-2 pr-2 sm:flex-row sm:items-center">
        <div
          data-testid="clouseBtn"
          className="flex min-h-16 items-center justify-start"
        >
          <IconButton
            className="my-auto mx-3 sm:mx-6"
            Icon={CrossFilled}
            variant="ghost"
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <div className="flex items-center">
            <div className="mr-8">
              <Import2 className="w-6" />
            </div>
            <p className="mr-8 font-medium">
              {t(`pages.create_publication.import_new`)}
            </p>
            <Tag title={t('pages.create_publication.draft')} size="md" />
          </div>
        </div>
      </div>
      <div className="mx-auto flex max-w-xl flex-col gap-8 p-3 py-5 md:mt-[50px] md:min-w-[650px] md:gap-6">
        {loading ? (
          <div className="flex justify-center">
            <Spinner
              ariaLabel="Loading spinner"
              className="my-auto !h-12 !w-12 border-grey-08"
            />
          </div>
        ) : (
          <>
            <div>
              <h3 className="mb-2 text-xl font-normal">
                {t(`pages.create_publication.upload_type_title`)}
              </h3>
              <div className={`center relative flex flex-wrap sm:flex-nowrap`}>
                <div
                  className={`mr-5 max-w-[190px] flex-1 cursor-pointer rounded-lg border border-grey-02 p-5  ${
                    importType == 'scorm' && 'border-secondary-04'
                  }`}
                  onClick={() => setImportType('scorm')}
                  data-testid="theory"
                >
                  <h4 className="text-base">
                    {t(`pages.create_publication.type_scorm`)} (2004/1.2)
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <h3 className="mb-2 text-xl font-normal">
                {t(`pages.create_publication.upload_box_title`)}
              </h3>
              <UploadFileBox files={files} setFiles={setFiles} />
            </div>
            <div className="flex-auto pr-6">
              <h3 className="mb-2 text-xl font-normal">
                {t(`pages.create_publication.upload_title`)}
              </h3>
              <InputText
                value={name}
                onChange={(ev: any) => setName(ev.target.value)}
                placeholder={t(
                  `pages.create_edit_course.type_course.title_course_placeholder`
                )}
              />
            </div>
            <div>
              <h3 className="mb-2 text-xl font-normal">
                {t(`pages.create_publication.upload_description`)}
              </h3>
              <div>
                <InputTextarea
                  id="description"
                  placeholder={t(
                    `pages.create_edit_course.type_course.course_description_placeholder`
                  )}
                  onChange={(ev: any) => setDescription(ev.target.value)}
                  value={description}
                  limit={300}
                />
              </div>
            </div>

            <div>
              <h3 className="mb-2 text-xl font-normal">
                {t(`pages.create_publication.type_course`)}
              </h3>
              <div id="courseType">
                <InputSelect
                  onChange={(courseType) => {
                    setCourseType(courseType.value);
                  }}
                  value={courseTypeOptions.find((x) => x.value === courseType)}
                  options={courseTypeOptions}
                >
                  {(options) => <span>{options?.name}</span>}
                </InputSelect>
              </div>
            </div>

            <div className="flex">
              <Button
                type="button"
                variant="outline"
                className={`relative mr-auto`}
                onClick={() => setIsOpenCompetency(true)}
              >
                {t('pages.create_publication.btn_competency_profiles')}
              </Button>
              <Button
                disabled={files.length === 0 || loading}
                type="button"
                onClick={handleStart}
              >
                {t('pages.create_publication.btn_upload_course')}
              </Button>
            </div>
          </>
        )}
      </div>
      {isOpenCompetency && (
        <CompetencyProfilesModal
          setIsOpenCompetency={setIsOpenCompetency}
          setSelectCompetency={setSelectCompetency}
          selectCompetency={selectCompetency}
        />
      )}

      {hints?.active === 2 && !isMobile && (
        <HintOverlay setHints={setHints} hints={hints} block={contentRef} />
      )}
    </div>
  );
};

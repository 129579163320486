import { useState, useEffect, useMemo } from 'react';
import {
  isEmpty,
  newUid,
  round2,
  focusWidget,
  extractTextFromElement,
} from '@/util';
import { FroalaTextareaView } from '@/components/Froala/FroalaTextareaView';
import { FroalaTextarea } from '@/components/Froala/FroalaTextarea';
import { WidgetHeader } from './WidgetHeader';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import { FeedbackSection } from '../FeedbackSection';
import { PartialSubmitSection } from '../PartialSubmitSection';
import { genericXapiSubmit } from './common';

export const OpenQuestionView = ({
  state,
  answer,
  correction,
  correctionType = undefined,
  index,
  setAnswerFn = undefined,
  setCorrectionFn = undefined,
  setXApiSubmitFn = undefined,
  onModified = undefined,
  onSave = undefined,
  isCorrected,
  viewOnly,
  resultPages,
  showAnswers = false,
  widgetAnswered,
}) => {
  const { t } = useTranslation();

  answer ||= {};
  correction ||= {};

  OpenQuestionView.syncStates(state, answer, correction);
  const [modified, setModified] = useState(null);
  const [submitted, setSubmitted] = useState(!!answer?.submitted);
  const [score, setScore] = useState(correction?.score);
  const [answerContent, setAnswerContent] = useState(answer.answer);
  const [answeredTeacher, setAnsweredTeacher] = useState(answer?.answered);
  const [antwoordIs, setAntwoordIs] = useState(false);

  const answered = submitted || isCorrected;
  const [ref, setRef] = useState(null);

  //generate the answer
  const getAnswer = async () => {
    if (ref?.getContent) {
      const newanswer = await ref?.getContent();
      if (newanswer) {
        answer.answer = newanswer;
      } else {
        answer.answer = answerContent;
      }
      // answer.answer = await ref?.getContent();
    } else {
      answer.answer = answerContent;
    }
    answer.incomplete = !submitted && answer.answer?.length < 1;
    answer.submitted = submitted;
    return answer;
  };
  setAnswerFn && setAnswerFn(getAnswer);

  useMemo(() => {
    if (submitted) {
      ref?.getContent().then((content) => {
        setAnswerContent(content);
      });
      let answer = getAnswer();
      if (!answeredTeacher) {
        let correction = {};
        OpenQuestionView.syncStates(state, answer, correction);
        setScore(correction?.score);
      }
    }
  }, [submitted]);

  //generate the correction
  const getCorrection = () => {
    let correction = {};
    OpenQuestionView.syncStates(state, getAnswer(), correction);
    return correction;
  };
  setCorrectionFn && setCorrectionFn(getCorrection);

  //submit the widget as xApi
  const xApiSubmit = async (lessonId, lang, minScore = 0.5) => {
    let correction = {};
    let answer = await getAnswer();
    OpenQuestionView.syncStates(state, answer, correction);
    let answerStr = answer?.answer || '';
    let correctStr = state?.antwoord || '';
    return genericXapiSubmit(
      state,
      answer,
      correction,
      answerStr,
      correctStr,
      lessonId,
      lang,
      minScore
    );
  };
  setXApiSubmitFn && setXApiSubmitFn(xApiSubmit);

  useEffect(() => {
    modified && onModified && onModified();
  }, [onModified, modified]);

  useEffect(() => {
    if (state?.antwoord) {
      const antwoordDiv = document.createElement('div');
      antwoordDiv.innerHTML = state?.antwoord;
      const readableAntwoord = extractTextFromElement(antwoordDiv);
      const antwoordText = readableAntwoord.replace(/\n\s*\n/g, '\n').trim();
      const antwoordImgList = state?.antwoord.match(/<img[^>]+src="([^">]+)"/);
      setAntwoordIs(antwoordText || antwoordImgList ? true : false);
    }
  }, []);

  return (
    <div
      className={`flex flex-col gap-4 py-4 ${
        !resultPages ? 'rounded-lg bg-[#f8f8f9] px-4' : 'mt-2'
      }`}
      style={{ maxWidth: 'calc(100vw - 32px)' }}
      data-state={encode(JSON.stringify(state))}
      onFocus={() => focusWidget(state?.uid)}
    >
      {!resultPages && (
        <WidgetHeader
          index={index}
          score={round2(score)}
          maxScore={state.score}
          answered={(answered && !submitted) || answer?.answered}
          titleWidget={state.titleWidget || t('widgets.type.open_question')}
        />
      )}
      <FroalaTextareaView value={state.vraag} />
      {!answered || submitted || answerContent ? (
        <div>
          <p className="text-sm font-semibold text-black">{t('Answer')}</p>
          <div
            className={`${
              answered &&
              !submitted &&
              'border-transparen rounded-md border border-solid bg-grey-transparent-01 p-6 text-sm text-grey-08'
            }`}
          >
            <FroalaTextarea
              key={state.uid + 'ANSWER_FIELD' + answered}
              setRef={(_ref) => {
                setRef(_ref);
              }}
              mode={answered || widgetAnswered ? 'view' : 'edit'}
              value={answerContent}
              className="z-[1] w-full border-0"
              onModified={() => {
                setModified(newUid(20));
              }}
            />
          </div>
        </div>
      ) : null}
      {(answered || showAnswers) && antwoordIs ? (
        <div>
          <p className="text-sm font-semibold text-black text-secondary-04">
            {t('pages.correction_result.possible_answer')}
          </p>
          <div className="border-transparen rounded-md border border-solid bg-grey-transparent-01 p-3 text-sm text-grey-08">
            <FroalaTextareaView value={state?.antwoord} />
          </div>
        </div>
      ) : null}
      {(state.immediateFeedback || correctionType === 'autofeedback') &&
        !widgetAnswered && (
          <PartialSubmitSection
            setSubmitted={setSubmitted}
            setModified={setModified}
            answered={answered}
            onSave={onSave.bind(null, false, false, state)}
          />
        )}

      {((widgetAnswered &&
        (correctionType === 'immediate' ||
          correctionType === 'autofeedback' ||
          correctionType === 'auto') &&
        !answered) ||
        (submitted && answered && !answer?.answered)) && (
        <div className="mt-4 text-sm font-normal text-[#4d4d50]">
          {t('widgets.type.teacher_needs_correct')}
        </div>
      )}
    </div>
  );
};

OpenQuestionView.syncStates = (state, answer, correction) => {
  if (isEmpty(answer)) {
    answer.uid = state.uid;
    answer.answer = '';
    answer.answered = false;
  }
  if (isEmpty(correction)) {
    correction.uid = state.uid;
    if (!correction.score && !answer?.answered) {
      correction.score = state?.score;
    }
  }
};
